import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { authEndpoints } from '../../../utils/constants';
import { validateEmail } from '../../../utils/helpers';
import { paths } from '../../../utils/routeUtils';
import { NotificationsContext } from '../../Notifications';
import { Input, PrimaryButton } from '../components';
import { useForm } from '../hooks';
import { getRedir } from '../utils';

const formConfig = {
  email: {
    default: '',
    validator: (value: unknown) => {
      if (value === '') {
        return true;
      }

      if (!value) {
        return false;
      }
      if (typeof value !== 'string') {
        return false;
      }

      return validateEmail(value);
    },
  },
  password: {
    default: '',
  },
} as const;

interface SignInEmailPasswordProps {
  loginCallback: (redirectUrl: string) => void;
}

const SignInEmailPassword = (props: SignInEmailPasswordProps): JSX.Element => {
  const { showErrorNotification } = React.useContext(NotificationsContext);

  const [signInFormState, onChangeHandler] = useForm(formConfig);
  const navigate = useNavigate();
  const { search } = useLocation();
  const redir = getRedir(search);
  const [requestLoadingStatus, updateRequestLoadingStatus] = React.useState(
    false
  );

  const onFormSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const loginBody = {
      email: signInFormState.email.value.toLowerCase().trim(),
      password: signInFormState.password.value.trim(),
    };

    const options: RequestInit = {
      credentials: 'include',
      body: JSON.stringify(loginBody),
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
    };

    let requestUrl = authEndpoints.login;
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get('redirect_url');

    if (
      redirectUrl &&
      (redirectUrl.includes('console.hasura.io') ||
        redirectUrl.includes('console.arusah.com'))
    ) {
      // const queryStr = new URLSearchParams({ redirect_url: redirectUrl });
      requestUrl = `${requestUrl}?redir=${redir}?platform=hasura_v3_console`;
    } else {
      requestUrl = `${requestUrl}?redir=${redir}`;
    }

    updateRequestLoadingStatus(true);

    return fetch(`${requestUrl}`, options)
      .then(resp => {
        updateRequestLoadingStatus(false);
        return resp.json();
      })
      .then(resp => {
        if ('status' in resp && resp.status === 'failure') {
          if ('error' in resp) {
            throw new Error(resp.error);
          }
          if ('message' in resp) {
            throw new Error(resp.message);
          }
          throw new Error(
            'Unexpected error. Get in touch with us for more info'
          );
        }
        props.loginCallback(resp.location);
      })
      .catch(err => {
        if (err && err.message && err.message.includes('Failed to fetch')) {
          showErrorNotification(
            'Sign in failed: Please check your internet connection'
          );
        } else {
          showErrorNotification(`Sign in failed: ${err}`);
        }
      });
  };

  const isInputsEmpty = React.useMemo(() => {
    return (
      !signInFormState.email.value.trim() ||
      !signInFormState.password.value.trim()
    );
  }, [signInFormState]);

  return (
    <div className="flex flex-col">
      <form onSubmit={onFormSubmit} className="contents">
        <Input
          placeholder="Email"
          type="email"
          value={signInFormState.email.value}
          onChange={onChangeHandler('email')}
          isErrored={!signInFormState.email.isValid}
          errorText="Please enter a valid email address"
        />
        <Input
          placeholder="Password"
          type="password"
          elementStyles="mt-4"
          value={signInFormState.password.value}
          onChange={onChangeHandler('password')}
        />
        <span
          onClick={() =>
            navigate({ pathname: paths.v3.login.forgotPassword(), search })
          }
          className="w-max self-end mt-2 mb-2 font-inter font-medium text-[#1E56E3] text-sm text-right hover:cursor-pointer"
        >
          Forgot your password?
          {/* TODO: resend confirmation email button */}
        </span>
        <PrimaryButton
          disabled={isInputsEmpty}
          variant="primary"
          style={{ marginTop: '14px' }}
          type="submit"
          loading={requestLoadingStatus}
        />
      </form>
    </div>
  );
};

export default SignInEmailPassword;
