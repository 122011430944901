import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Map: any;
  Timestamp: any;
  UUID: any;
  _date: any;
  _int4: any;
  _int8: any;
  _text: any;
  _uuid: any;
  bigint: any;
  bytea: any;
  citext: any;
  date: any;
  float8: any;
  float64: any;
  invoice_item_type: any;
  json: any;
  jsonb: any;
  numeric: any;
  opentelemetry_connection_type_enum: any;
  project_activity_status_enum: any;
  reminder_type_enum: any;
  smallint: any;
  string: any;
  timestamp: any;
  timestamptz: any;
  timetz: any;
  u_int8: any;
  u_int16: any;
  u_int64: any;
  user_action: any;
  uuid: any;
};

export type AcceptProjectCollaborationRequestOutput = {
  __typename?: 'AcceptProjectCollaborationRequestOutput';
  status: Scalars['String'];
};

export type AcceptVpcCollaboratorInvitationResponse = {
  __typename?: 'AcceptVPCCollaboratorInvitationResponse';
  message: Scalars['String'];
  status: Scalars['String'];
};

/** The agent through which the action is executed */
export enum ActionAgent {
  CloudDashboard = 'CloudDashboard',
  HasuraConsole = 'HasuraConsole',
  Unknown = 'Unknown',
}

export type ActiveStatusReasonResponse = {
  __typename?: 'ActiveStatusReasonResponse';
  status: Scalars['String'];
};

export type AddCustomDomainOutput = {
  __typename?: 'AddCustomDomainOutput';
  cert?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  dns_validation?: Maybe<Scalars['String']>;
  fqdn: Scalars['String'];
  id: Scalars['uuid'];
  tenant_id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['String']>;
};

export type AddProjectInput = {
  endpoint: Scalars['String'];
  name: Scalars['String'];
};

export type AddProjectResponse = {
  __typename?: 'AddProjectResponse';
  hasura_pro_key: Scalars['String'];
  id: Scalars['uuid'];
};

export type AddVpcOwnerAsProjectAdminResponse = {
  __typename?: 'AddVPCOwnerAsProjectAdminResponse';
  status: Scalars['String'];
  updated_dedicated_vpc_owners: Array<Maybe<UpdatedVpcProjectPrivilegeList>>;
};

export type AllowedProjectResponse = {
  __typename?: 'AllowedProjectResponse';
  id: Scalars['uuid'];
  name: Scalars['String'];
};

export type ApiDiff = {
  __typename?: 'ApiDiff';
  graphql_schema_diff_v1: GraphQlSchemaDiffV1;
};

export type AzureMonitorConfigResponse = {
  __typename?: 'AzureMonitorConfigResponse';
  project_id: Scalars['uuid'];
};

export type BillingManagerInvitation = {
  __typename?: 'BillingManagerInvitation';
  id: Scalars['uuid'];
  invited_at: Scalars['String'];
  invited_by: Scalars['uuid'];
  manager_email: Scalars['String'];
  project_id: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type BuildKiteBuild = {
  __typename?: 'BuildKiteBuild';
  id: Scalars['String'];
  url: Scalars['String'];
};

export type BuildVersion = {
  __typename?: 'BuildVersion';
  GitCommit: Scalars['String'];
  Tag: Scalars['String'];
};

export type Change = {
  __typename?: 'Change';
  criticality: Criticality;
  message: Scalars['String'];
  meta: Scalars['String'];
  path?: Maybe<Scalars['String']>;
  type: ChangeType;
};

export enum ChangeType {
  DirectiveAdded = 'DirectiveAdded',
  DirectiveArgumentAdded = 'DirectiveArgumentAdded',
  DirectiveArgumentDefaultValueChanged = 'DirectiveArgumentDefaultValueChanged',
  DirectiveArgumentDescriptionChanged = 'DirectiveArgumentDescriptionChanged',
  DirectiveArgumentRemoved = 'DirectiveArgumentRemoved',
  DirectiveArgumentTypeChanged = 'DirectiveArgumentTypeChanged',
  DirectiveDescriptionChanged = 'DirectiveDescriptionChanged',
  DirectiveLocationAdded = 'DirectiveLocationAdded',
  DirectiveLocationRemoved = 'DirectiveLocationRemoved',
  DirectiveRemoved = 'DirectiveRemoved',
  DirectiveUsageArgumentDefinitionAdded = 'DirectiveUsageArgumentDefinitionAdded',
  DirectiveUsageArgumentDefinitionRemoved = 'DirectiveUsageArgumentDefinitionRemoved',
  DirectiveUsageEnumAdded = 'DirectiveUsageEnumAdded',
  DirectiveUsageEnumRemoved = 'DirectiveUsageEnumRemoved',
  DirectiveUsageEnumValueAdded = 'DirectiveUsageEnumValueAdded',
  DirectiveUsageEnumValueRemoved = 'DirectiveUsageEnumValueRemoved',
  DirectiveUsageFieldAdded = 'DirectiveUsageFieldAdded',
  DirectiveUsageFieldDefinitionAdded = 'DirectiveUsageFieldDefinitionAdded',
  DirectiveUsageFieldDefinitionRemoved = 'DirectiveUsageFieldDefinitionRemoved',
  DirectiveUsageFieldRemoved = 'DirectiveUsageFieldRemoved',
  DirectiveUsageInputObjectAdded = 'DirectiveUsageInputObjectAdded',
  DirectiveUsageInputObjectRemoved = 'DirectiveUsageInputObjectRemoved',
  DirectiveUsageInterfaceAdded = 'DirectiveUsageInterfaceAdded',
  DirectiveUsageInterfaceRemoved = 'DirectiveUsageInterfaceRemoved',
  DirectiveUsageObjectAdded = 'DirectiveUsageObjectAdded',
  DirectiveUsageObjectRemoved = 'DirectiveUsageObjectRemoved',
  DirectiveUsageScalarAdded = 'DirectiveUsageScalarAdded',
  DirectiveUsageScalarRemoved = 'DirectiveUsageScalarRemoved',
  DirectiveUsageSchemaAdded = 'DirectiveUsageSchemaAdded',
  DirectiveUsageSchemaRemoved = 'DirectiveUsageSchemaRemoved',
  DirectiveUsageUnionMemberAdded = 'DirectiveUsageUnionMemberAdded',
  DirectiveUsageUnionMemberRemoved = 'DirectiveUsageUnionMemberRemoved',
  EnumValueAdded = 'EnumValueAdded',
  EnumValueDeprecationReasonAdded = 'EnumValueDeprecationReasonAdded',
  EnumValueDeprecationReasonChanged = 'EnumValueDeprecationReasonChanged',
  EnumValueDeprecationReasonRemoved = 'EnumValueDeprecationReasonRemoved',
  EnumValueDescriptionChanged = 'EnumValueDescriptionChanged',
  EnumValueRemoved = 'EnumValueRemoved',
  FieldAdded = 'FieldAdded',
  FieldArgumentAdded = 'FieldArgumentAdded',
  FieldArgumentDefaultChanged = 'FieldArgumentDefaultChanged',
  FieldArgumentDescriptionChanged = 'FieldArgumentDescriptionChanged',
  FieldArgumentRemoved = 'FieldArgumentRemoved',
  FieldArgumentTypeChanged = 'FieldArgumentTypeChanged',
  FieldDeprecationAdded = 'FieldDeprecationAdded',
  FieldDeprecationReasonAdded = 'FieldDeprecationReasonAdded',
  FieldDeprecationReasonChanged = 'FieldDeprecationReasonChanged',
  FieldDeprecationReasonRemoved = 'FieldDeprecationReasonRemoved',
  FieldDeprecationRemoved = 'FieldDeprecationRemoved',
  FieldDescriptionAdded = 'FieldDescriptionAdded',
  FieldDescriptionChanged = 'FieldDescriptionChanged',
  FieldDescriptionRemoved = 'FieldDescriptionRemoved',
  FieldRemoved = 'FieldRemoved',
  FieldTypeChanged = 'FieldTypeChanged',
  InputFieldAdded = 'InputFieldAdded',
  InputFieldDefaultValueChanged = 'InputFieldDefaultValueChanged',
  InputFieldDescriptionAdded = 'InputFieldDescriptionAdded',
  InputFieldDescriptionChanged = 'InputFieldDescriptionChanged',
  InputFieldDescriptionRemoved = 'InputFieldDescriptionRemoved',
  InputFieldRemoved = 'InputFieldRemoved',
  InputFieldTypeChanged = 'InputFieldTypeChanged',
  ObjectTypeInterfaceAdded = 'ObjectTypeInterfaceAdded',
  ObjectTypeInterfaceRemoved = 'ObjectTypeInterfaceRemoved',
  SchemaMutationTypeChanged = 'SchemaMutationTypeChanged',
  SchemaQueryTypeChanged = 'SchemaQueryTypeChanged',
  SchemaSubscriptionTypeChanged = 'SchemaSubscriptionTypeChanged',
  TypeAdded = 'TypeAdded',
  TypeDescriptionAdded = 'TypeDescriptionAdded',
  TypeDescriptionChanged = 'TypeDescriptionChanged',
  TypeDescriptionRemoved = 'TypeDescriptionRemoved',
  TypeKindChanged = 'TypeKindChanged',
  TypeRemoved = 'TypeRemoved',
  UnionMemberAdded = 'UnionMemberAdded',
  UnionMemberRemoved = 'UnionMemberRemoved',
}

export type ChangeUserEmailRequest = {
  __typename?: 'ChangeUserEmailRequest';
  id: Scalars['uuid'];
  new_email: Scalars['String'];
  status: Scalars['String'];
  user_id: Scalars['uuid'];
  verified: Scalars['Boolean'];
};

export type ChangeUserEmailRequestInput = {
  new_email: Scalars['String'];
};

export type ChangeUserEmailRequestKeyInput = {
  key: Scalars['String'];
};

export type CheckDbLatencyOutput = {
  __typename?: 'CheckDBLatencyOutput';
  db_latency_job_id: Scalars['uuid'];
};

export enum CloudProvider {
  Aws = 'aws',
  Azure = 'azure',
  Gcp = 'gcp',
}

export type ComputeUnitCountResponse = {
  __typename?: 'ComputeUnitCountResponse';
  cloud: CloudProvider;
  cluster_id: Scalars['String'];
  values: Array<Maybe<GranularComputeUnitCount>>;
};

export type ComputeUnitUsageResponse = {
  __typename?: 'ComputeUnitUsageResponse';
  cloud: CloudProvider;
  cluster_id: Scalars['String'];
  values?: Maybe<Array<Maybe<GranularComputeUnitUsagePercentage>>>;
};

export type Continent = {
  __typename?: 'Continent';
  code: Scalars['ID'];
  countries: Array<Country>;
  name: Scalars['String'];
};

export type ContinentFilterInput = {
  code?: InputMaybe<StringQueryOperatorInput>;
};

export type ConvertPlanResponse = {
  __typename?: 'ConvertPlanResponse';
  status: Scalars['String'];
};

export type Country = {
  __typename?: 'Country';
  awsRegion: Scalars['String'];
  capital?: Maybe<Scalars['String']>;
  code: Scalars['ID'];
  continent: Continent;
  currencies: Array<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  emoji: Scalars['String'];
  emojiU: Scalars['String'];
  languages: Array<Language>;
  name: Scalars['String'];
  native: Scalars['String'];
  phone: Scalars['String'];
  phones: Array<Scalars['String']>;
  states: Array<State>;
  subdivisions: Array<Subdivision>;
};

export type CountryNameArgs = {
  lang?: InputMaybe<Scalars['String']>;
};

export type CountryFilterInput = {
  code?: InputMaybe<StringQueryOperatorInput>;
  continent?: InputMaybe<StringQueryOperatorInput>;
  currency?: InputMaybe<StringQueryOperatorInput>;
  name?: InputMaybe<StringQueryOperatorInput>;
};

export type CouponStatus = {
  __typename?: 'CouponStatus';
  status: Scalars['String'];
};

export type CreateDedicatedVpcResponse = {
  __typename?: 'CreateDedicatedVPCResponse';
  id: Scalars['uuid'];
};

export type CreateGithubPreviewAppInputPayload = {
  githubPersonalAccessToken: Scalars['String'];
  githubRepoDetails: GithubRepoDetails;
  projectOptions: ProjectOptions;
};

export type CreateHttpTunnelsOutput = {
  __typename?: 'CreateHttpTunnelsOutput';
  tunnels: Array<DdnHttpTunnel>;
};

export type CreateInvoiceInput = {
  collection_method: Scalars['String'];
  customer: Scalars['String'];
  invoice: Scalars['String'];
  month?: InputMaybe<Scalars['Int']>;
  subscription: Scalars['String'];
  year?: InputMaybe<Scalars['Int']>;
};

export type CreateInvoiceResponse = {
  __typename?: 'CreateInvoiceResponse';
  message: Scalars['String'];
  status: InvoiceCreateStatus;
};

export type CreateJobInput = {
  payload: Scalars['Map'];
  type: JobTypeEnum;
};

export type CreateJobResponse = {
  __typename?: 'CreateJobResponse';
  job_id: Scalars['uuid'];
};

export type CreateOneClickDeploymentInputPayload = {
  additional_info: Scalars['jsonb'];
  branch?: InputMaybe<Scalars['String']>;
  github_url: Scalars['String'];
  hasura_dir: Scalars['String'];
};

export type CreateOneClickDeploymentOutput = {
  __typename?: 'CreateOneClickDeploymentOutput';
  project?: Maybe<Projects>;
  project_id: Scalars['uuid'];
};

export type CreateTenantResponse = {
  __typename?: 'CreateTenantResponse';
  id: Scalars['uuid'];
  name: Scalars['String'];
  tenant?: Maybe<Tenant>;
};

export type CreateTunnelOutput = {
  __typename?: 'CreateTunnelOutput';
  tunnel?: Maybe<DdnTunnel>;
};

export type Criticality = {
  __typename?: 'Criticality';
  isSafeBasedOnUsage?: Maybe<Scalars['Boolean']>;
  level: CriticalityLevel;
  reason?: Maybe<Scalars['String']>;
};

export enum CriticalityLevel {
  Breaking = 'BREAKING',
  Dangerous = 'DANGEROUS',
  NonBreaking = 'NON_BREAKING',
}

export type DdnApplyMetadataOutput = {
  __typename?: 'DDNApplyMetadataOutput';
  build?: Maybe<Ddn_Build>;
  build_id?: Maybe<Scalars['String']>;
  graphql_api_endpoint: Scalars['String'];
};

export type DdnBackfillOutput = {
  __typename?: 'DDNBackfillOutput';
  encountered_errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  error_count: Scalars['Int'];
  success_count: Scalars['Int'];
  total_count: Scalars['Int'];
};

export type DdnBillingStateResponse = {
  __typename?: 'DDNBillingStateResponse';
  status: Scalars['String'];
};

export type DdnClientSecretInfo = {
  __typename?: 'DDNClientSecretInfo';
  client_secret: Scalars['String'];
};

export type DdnCreateEnvironmentResponse = {
  __typename?: 'DDNCreateEnvironmentResponse';
  current_build_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  fqdn: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  project_id: Scalars['uuid'];
};

export type DdnCreateInvoiceInput = {
  collection_method: Scalars['String'];
  customer: Scalars['String'];
  invoice: Scalars['String'];
  month?: InputMaybe<Scalars['Int']>;
  subscription: Scalars['String'];
  year?: InputMaybe<Scalars['Int']>;
};

export type DdnCreateInvoiceResponse = {
  __typename?: 'DDNCreateInvoiceResponse';
  message: Scalars['String'];
  status: DdnInvoiceCreateStatus;
};

export type DdnCreateProjectResponse = {
  __typename?: 'DDNCreateProjectResponse';
  id: Scalars['uuid'];
  name: Scalars['String'];
  project?: Maybe<Ddn_Projects>;
};

export type DdnCreateSubgraphResponse = {
  __typename?: 'DDNCreateSubgraphResponse';
  id: Scalars['uuid'];
  name: Scalars['String'];
  project_id: Scalars['uuid'];
};

export type DdnDefaultPaymentResponse = {
  __typename?: 'DDNDefaultPaymentResponse';
  status: Scalars['String'];
};

export type DdnDeleteProjectResponse = {
  __typename?: 'DDNDeleteProjectResponse';
  message: Scalars['String'];
};

export type DdnHttpTunnel = {
  __typename?: 'DDNHttpTunnel';
  id: Scalars['uuid'];
  internal_endpoint: Scalars['String'];
  owner_id: Scalars['uuid'];
  public_endpoint: Scalars['String'];
};

export type DdnInvoiceAndReceiptUrLsResponse = {
  __typename?: 'DDNInvoiceAndReceiptURLsResponse';
  invoice_url?: Maybe<Scalars['String']>;
  receipt_url?: Maybe<Scalars['String']>;
};

export enum DdnInvoiceCreateStatus {
  Failed = 'failed',
  Success = 'success',
}

export type DdnIsProjectCreationLimitReachedOutput = {
  __typename?: 'DDNIsProjectCreationLimitReachedOutput';
  is_limit_reached: Scalars['Boolean'];
};

export type DdnPayInvoiceStatus = {
  __typename?: 'DDNPayInvoiceStatus';
  status: Scalars['String'];
};

export enum DdnPlan {
  DdnAdvanced = 'ddn_advanced',
  DdnAdvancedTrial = 'ddn_advanced_trial',
  DdnBase = 'ddn_base',
  DdnBaseTrial = 'ddn_base_trial',
  DdnFree = 'ddn_free',
}

export type DdnPlanResponse = {
  __typename?: 'DDNPlanResponse';
  message: Scalars['String'];
  newPlan: DdnPlan;
  oldPlan: DdnPlan;
  projectId: Scalars['uuid'];
  status: Scalars['String'];
};

export type DdnTunnel = {
  __typename?: 'DDNTunnel';
  id: Scalars['uuid'];
  owner_id: Scalars['uuid'];
  reserved_port: Scalars['Int'];
  tunnel_cluster: DdnTunnelCluster;
};

export type DdnTunnelCluster = {
  __typename?: 'DDNTunnelCluster';
  internal_fqdn: Scalars['String'];
  name: Scalars['String'];
  public_fqdn: Scalars['String'];
};

export type DatadogConfigResponse = {
  __typename?: 'DatadogConfigResponse';
  project_id: Scalars['uuid'];
};

export type DdnDeleteEnvironmentResponse = {
  __typename?: 'DdnDeleteEnvironmentResponse';
  message: Scalars['String'];
};

export type DdnDeleteSubgraphResponse = {
  __typename?: 'DdnDeleteSubgraphResponse';
  message: Scalars['String'];
};

export type DeclineVpcCollaboratorInvitationResponse = {
  __typename?: 'DeclineVPCCollaboratorInvitationResponse';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type DedicatedCloudClusterBillsOutput = {
  __typename?: 'DedicatedCloudClusterBillsOutput';
  compute_unit_config_id: Scalars['uuid'];
  cu_usage_amount: Scalars['Int'];
  data_usage_amount: Scalars['Int'];
  month: Scalars['Int'];
  year: Scalars['Int'];
};

export type DefaultPaymentResponse = {
  __typename?: 'DefaultPaymentResponse';
  status: Scalars['String'];
};

export type DeleteBuildResponse = {
  __typename?: 'DeleteBuildResponse';
  message: Scalars['String'];
};

export type DeleteCardResponse = {
  __typename?: 'DeleteCardResponse';
  msg: Scalars['String'];
};

export type DeleteGithubIntegrationInput = {
  integration_id: Scalars['String'];
};

export type DeleteGithubIntegrationStatus = {
  __typename?: 'DeleteGithubIntegrationStatus';
  status: Scalars['String'];
};

export type DeleteProjectInput = {
  id: Scalars['uuid'];
};

export type DeleteProjectResponse = {
  __typename?: 'DeleteProjectResponse';
  id: Scalars['uuid'];
};

export type DeleteSlackAppOutput = {
  __typename?: 'DeleteSlackAppOutput';
  status: Scalars['String'];
};

export type DelteSlackAppPayload = {
  projectID: Scalars['uuid'];
};

export type DeployLatestCommitStatus = {
  __typename?: 'DeployLatestCommitStatus';
  status: Scalars['String'];
};

export type DeployLatestGithubCommitInput = {
  github_intergation_config_id: Scalars['String'];
};

export type EnableCloudflareProxyResponse = {
  __typename?: 'EnableCloudflareProxyResponse';
  tenant_id: Scalars['uuid'];
};

export type EntityCost = {
  __typename?: 'EntityCost';
  cost?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['String']>;
  entity_name?: Maybe<Scalars['String']>;
  entity_type?: Maybe<Scalars['String']>;
  environment_name?: Maybe<Scalars['String']>;
  subgraph?: Maybe<Scalars['String']>;
};

export type EnvironmentVariablesV3 = {
  subgraph_environment_variables?: InputMaybe<
    Array<InputMaybe<SubgraphEnvironmentVariable>>
  >;
  supergraph_environment_variables: Scalars['jsonb'];
};

export type ExperimentsCohortActivityPayload = {
  error_code?: InputMaybe<Scalars['String']>;
  kind: Scalars['String'];
  project_id: Scalars['uuid'];
};

export type FeatureRequest = {
  details: Scalars['jsonb'];
  type: Scalars['String'];
};

export type FeatureRequestResponse = {
  __typename?: 'FeatureRequestResponse';
  status: Scalars['String'];
};

export type FetchUserFacingMetadataResponse = {
  __typename?: 'FetchUserFacingMetadataResponse';
  metadata: Scalars['String'];
};

export type GenerateMetadataArtifactsOutput = {
  __typename?: 'GenerateMetadataArtifactsOutput';
  auth_config: Scalars['String'];
  metadata: Scalars['String'];
  opendd: Scalars['String'];
};

export type GetReportUrlResponse = {
  __typename?: 'GetReportURLResponse';
  url: Scalars['String'];
};

export type GithubAppInstallation = {
  __typename?: 'GithubAppInstallation';
  app_slug: Scalars['String'];
  id: Scalars['Int'];
};

export type GithubAppInstallations = {
  __typename?: 'GithubAppInstallations';
  installations: Array<GithubAppInstallation>;
};

export type GithubAppIntegrationAccessibleRepo = {
  __typename?: 'GithubAppIntegrationAccessibleRepo';
  installation_id: Scalars['Int'];
  name: Scalars['String'];
  owner_login: Scalars['String'];
};

export type GithubAppIntegrationAccessibleRepos = {
  __typename?: 'GithubAppIntegrationAccessibleRepos';
  repositories: Array<GithubAppIntegrationAccessibleRepo>;
  total_count: Scalars['Int'];
};

export type GithubBranch = {
  __typename?: 'GithubBranch';
  name: Scalars['String'];
};

export type GithubBranches = {
  __typename?: 'GithubBranches';
  branches: Array<GithubBranch>;
};

export type GithubIntegrationInput = {
  branch: Scalars['String'];
  directory?: InputMaybe<Scalars['String']>;
  email_status: Scalars['String'];
  installation_id?: InputMaybe<Scalars['Int']>;
  mode?: InputMaybe<ModeEnum>;
  org: Scalars['String'];
  project_id: Scalars['String'];
  repo: Scalars['String'];
};

export type GithubIntegrationStatus = {
  __typename?: 'GithubIntegrationStatus';
  status: Scalars['String'];
};

export type GithubLoginResponse = {
  __typename?: 'GithubLoginResponse';
  login: Scalars['String'];
};

export type GithubRepoDetails = {
  branch: Scalars['String'];
  directory: Scalars['String'];
  owner: Scalars['String'];
  repo: Scalars['String'];
};

export type GithubSession = {
  __typename?: 'GithubSession';
  status: Scalars['String'];
};

export type GranularComputeUnitCount = {
  __typename?: 'GranularComputeUnitCount';
  count: Scalars['Int'];
  epoch: Scalars['Int'];
};

export type GranularComputeUnitUsagePercentage = {
  __typename?: 'GranularComputeUnitUsagePercentage';
  epoch: Scalars['Int'];
  usage: Scalars['Float'];
};

export type GraphQlSchemaDiffV1 = {
  __typename?: 'GraphQLSchemaDiffV1';
  changes?: Maybe<Array<Change>>;
};

export type HandleInactiveProjectsResult = {
  __typename?: 'HandleInactiveProjectsResult';
  done?: Maybe<Scalars['Boolean']>;
};

export type HasuraSecretList = {
  __typename?: 'HasuraSecretList';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  environment: Scalars['String'];
  is_supergraph_secret: Scalars['Boolean'];
  key: Scalars['String'];
  subgraph?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};

export type HasuraSecretMessage = {
  __typename?: 'HasuraSecretMessage';
  message: Scalars['String'];
};

export type HerokuSession = {
  __typename?: 'HerokuSession';
  access_token: Scalars['String'];
  expires_in: Scalars['Int'];
  refresh_token: Scalars['String'];
  token_type: Scalars['String'];
};

export type HerokuSessionStatus = {
  __typename?: 'HerokuSessionStatus';
  status: Scalars['String'];
};

export type HerokuTokenExchangeInput = {
  type: HerokuTokenExchangeType;
  value: Scalars['String'];
};

export enum HerokuTokenExchangeType {
  Code = 'code',
  Refresh = 'refresh',
}

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_BigQuery_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type InvitationResponse = {
  __typename?: 'InvitationResponse';
  status: Scalars['String'];
  team?: Maybe<Ddn_Team>;
  team_id: Scalars['uuid'];
};

export type InviteColloboratorRequest = {
  collaborator_email: Scalars['String'];
  privileges: Array<Scalars['String']>;
  project_id: Scalars['uuid'];
};

export type InviteProjectCollaboratorInput = {
  access_levels: Array<Scalars['String']>;
  invitee_email: Scalars['String'];
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
};

export type InviteVpcCollaboratorResponse = {
  __typename?: 'InviteVPCCollaboratorResponse';
  message: Scalars['String'];
  status: Scalars['String'];
};

export enum InvoiceCreateStatus {
  Failed = 'failed',
  Success = 'success',
}

export type IsCollaborationRequestPresentOutput = {
  __typename?: 'IsCollaborationRequestPresentOutput';
  invite_exists: Scalars['Boolean'];
  setting_enabled: Scalars['Boolean'];
};

export enum JobTypeEnum {
  PreviewApp = 'preview_app',
  RegionMigration = 'region_migration',
  V1ToV2Upgrade = 'v1_to_v2_upgrade',
}

export type Language = {
  __typename?: 'Language';
  code: Scalars['ID'];
  name: Scalars['String'];
  native: Scalars['String'];
  rtl: Scalars['Boolean'];
};

export type LanguageFilterInput = {
  code?: InputMaybe<StringQueryOperatorInput>;
};

export type LetsEncryptStatus = {
  __typename?: 'LetsEncryptStatus';
  is_active: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
};

export enum ModeEnum {
  Automatic = 'automatic',
  Manual = 'manual',
  PreviewApp = 'previewApp',
}

export type MoveProjectRegionResponse = {
  __typename?: 'MoveProjectRegionResponse';
  project?: Maybe<Projects>;
  projectId: Scalars['uuid'];
};

export type NeonCreateDatabaseResponse = {
  __typename?: 'NeonCreateDatabaseResponse';
  databaseUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  envVar?: Maybe<Scalars['String']>;
  integrationId?: Maybe<Scalars['uuid']>;
  isAuthenticated: Scalars['Boolean'];
  neon_db_integration?: Maybe<Neon_Db_Integration>;
};

export type NeonExchangeTokenResponse = {
  __typename?: 'NeonExchangeTokenResponse';
  accessToken: Scalars['String'];
  email: Scalars['String'];
};

export type NeonGetUserInfoResponse = {
  __typename?: 'NeonGetUserInfoResponse';
  email?: Maybe<Scalars['String']>;
  isAuthenticated: Scalars['Boolean'];
};

export type NewPlansProjectOutput = {
  __typename?: 'NewPlansProjectOutput';
  data_usage?: Maybe<Scalars['Int']>;
  data_usage_amount?: Maybe<Scalars['Int']>;
  db_usage?: Maybe<Array<Maybe<ProjectDbUsage>>>;
  project_id?: Maybe<Scalars['uuid']>;
  project_name?: Maybe<Scalars['String']>;
};

export type NewPlansProjectUsageOutput = {
  __typename?: 'NewPlansProjectUsageOutput';
  data_usage: Scalars['Int'];
  data_usage_amount: Scalars['Int'];
  db_usage: Array<ProjectDbUsage>;
  project_id: Scalars['uuid'];
  project_name?: Maybe<Scalars['String']>;
};

export type NewrelicConfigResponse = {
  __typename?: 'NewrelicConfigResponse';
  project_id: Scalars['uuid'];
};

export type NumberOfConnectedSourcesOutput = {
  __typename?: 'NumberOfConnectedSourcesOutput';
  databases_connected?: Maybe<Scalars['Int']>;
  plan_name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  project_info?: Maybe<Projects>;
  unsupported?: Maybe<Scalars['Boolean']>;
};

export type OnboardingActivityPayload = {
  error_code?: InputMaybe<Scalars['String']>;
  kind: Scalars['String'];
  project_id: Scalars['uuid'];
  subkind?: InputMaybe<Scalars['String']>;
};

export type OneClickDeployResponse = {
  __typename?: 'OneClickDeployResponse';
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type OpentelemetryConfigResponse = {
  __typename?: 'OpentelemetryConfigResponse';
  project_id: Scalars['uuid'];
};

export type PatchOnEnv = {
  build_type: PatchBuildType;
  name: Scalars['String'];
};

export type PayInvoiceStatus = {
  __typename?: 'PayInvoiceStatus';
  status: Scalars['String'];
};

export type PersonalAccessToken = {
  __typename?: 'PersonalAccessToken';
  description: Scalars['String'];
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  token: Scalars['String'];
  user_id: Scalars['uuid'];
};

export type PersonalAccessTokenInput = {
  description: Scalars['String'];
};

export enum Plan {
  CloudFree = 'cloud_free',
  CloudFreeV2 = 'cloud_free_v2',
  CloudPayg = 'cloud_payg',
  CloudShared = 'cloud_shared',
}

export type PlanResponse = {
  __typename?: 'PlanResponse';
  activity_id: Scalars['uuid'];
  newPlan: Plan;
  oldPlan: Plan;
  project?: Maybe<Projects>;
  projectId: Scalars['uuid'];
  status: Scalars['String'];
};

export type PreviewAppResponse = {
  __typename?: 'PreviewAppResponse';
  githubPreviewAppJobID: Scalars['uuid'];
  github_preview_app_job?: Maybe<Jobs>;
};

export type PrivilegedProjectInput = {
  privileges?: InputMaybe<Array<Scalars['String']>>;
};

export type ProjectCollaboratorInvitation = {
  __typename?: 'ProjectCollaboratorInvitation';
  collaborator_email: Scalars['String'];
  id: Scalars['uuid'];
  invited_at: Scalars['String'];
  invited_by: Scalars['uuid'];
  key: Scalars['String'];
  privileges: Array<Scalars['String']>;
  project_id: Scalars['uuid'];
};

export type ProjectDbUsage = {
  __typename?: 'ProjectDBUsage';
  amount: Scalars['Int'];
  type: Scalars['String'];
  usage: Scalars['Int'];
};

export type ProjectEntitiesCostPerMonthOutput = {
  __typename?: 'ProjectEntitiesCostPerMonthOutput';
  entities_cost?: Maybe<Array<Maybe<EntityCost>>>;
  free_usage_exhausted?: Maybe<Scalars['Int']>;
  hard_threshold?: Maybe<Scalars['Int']>;
  soft_threshold?: Maybe<Scalars['Int']>;
  total_active_commands?: Maybe<Scalars['Int']>;
  total_active_models?: Maybe<Scalars['Int']>;
  total_cost?: Maybe<Scalars['Int']>;
};

export type ProjectMigrationStatus = {
  __typename?: 'ProjectMigrationStatus';
  datasource: Scalars['String'];
  migration: Scalars['String'];
};

export type ProjectMigrationStatusInput = {
  project_id: Scalars['String'];
};

export type ProjectMiscMetadata = {
  herokuAppName?: InputMaybe<Scalars['String']>;
};

export type ProjectOptions = {
  cloud: Scalars['String'];
  envVars?: InputMaybe<Array<InputMaybe<UpdateEnvsObject>>>;
  name: Scalars['String'];
  plan?: InputMaybe<Scalars['String']>;
  region: Scalars['String'];
};

export type ProjectOwnershipInvitation = {
  __typename?: 'ProjectOwnershipInvitation';
  id: Scalars['uuid'];
  invitedAt: Scalars['String'];
  invitedBy: Scalars['String'];
  isBillable: Scalars['Boolean'];
  isBillingManager: Scalars['Boolean'];
  key: Scalars['String'];
  projectName: Scalars['String'];
  projectPlan: Scalars['String'];
};

export type ProjectOwnershipTransferInvitation = {
  __typename?: 'ProjectOwnershipTransferInvitation';
  id: Scalars['uuid'];
  invited_at: Scalars['String'];
  invited_by: Scalars['uuid'];
  invitee_email: Scalars['String'];
  key: Scalars['String'];
  project_id: Scalars['uuid'];
};

export type ProjectReactivationResponse = {
  __typename?: 'ProjectReactivationResponse';
  project?: Maybe<Projects>;
  projectId: Scalars['uuid'];
  success: Scalars['Boolean'];
};

export type ProjectUsage = {
  __typename?: 'ProjectUsage';
  additional_data_usage: Scalars['Int'];
  approx_cost: Scalars['Int'];
  downgrade_dates: Array<Scalars['Int']>;
  no_pay_days: Scalars['Int'];
  project_id: Scalars['uuid'];
  project_name: Scalars['String'];
  tier_at_month_start: Scalars['String'];
  upgrade_dates: Array<Scalars['Int']>;
  usage: Scalars['Int'];
};

export type ProjectUsageInput = {
  from_date?: InputMaybe<Scalars['timetz']>;
  to_date?: InputMaybe<Scalars['timetz']>;
  user_id: Scalars['uuid'];
};

export type PrometheusConfigResponse = {
  __typename?: 'PrometheusConfigResponse';
  access_token?: Maybe<Scalars['String']>;
  project_id: Scalars['uuid'];
};

export type PrometheusQueryBetween = {
  end: Scalars['timestamptz'];
  start: Scalars['timestamptz'];
};

export type PrometheusQueryTime = {
  between?: InputMaybe<PrometheusQueryBetween>;
  last?: InputMaybe<PrometheusTimeDuration>;
};

export type PrometheusTimeDuration = {
  number: Scalars['Int'];
  unit: PrometheusTimeDurationUnit;
};

export enum PrometheusTimeDurationUnit {
  D = 'd',
  H = 'h',
  M = 'm',
  Ms = 'ms',
  S = 's',
  W = 'w',
  Y = 'y',
}

export enum PulumiMode {
  Destroy = 'destroy',
  Preview = 'preview',
  Refresh = 'refresh',
  Up = 'up',
}

export type QuestionAnswers = {
  answer?: InputMaybe<Scalars['String']>;
  optionsSelected?: InputMaybe<Array<InputMaybe<Scalars['uuid']>>>;
  question_id: Scalars['uuid'];
};

export type RegenerateKeyInput = {
  id: Scalars['uuid'];
};

export type RejectProjectCollaborationRequestOutput = {
  __typename?: 'RejectProjectCollaborationRequestOutput';
  status: Scalars['String'];
};

export type RemoveVpcCollaboratorResponse = {
  __typename?: 'RemoveVPCCollaboratorResponse';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type RequestProjectCollaborationOutput = {
  __typename?: 'RequestProjectCollaborationOutput';
  status: Scalars['String'];
};

export type ResendInvitationRequest = {
  collaborator_email: Scalars['String'];
  project_id: Scalars['uuid'];
};

export type ResendTransferOwnershipInvitationRequest = {
  invitee_email: Scalars['String'];
  project_id: Scalars['uuid'];
};

export type RevokeVpcCollaboratorInvitationResponse = {
  __typename?: 'RevokeVPCCollaboratorInvitationResponse';
  message: Scalars['String'];
  status: Scalars['String'];
};

export type SaveSurveyAnswerV2Payload = {
  projectID?: InputMaybe<Scalars['uuid']>;
  responses: Array<InputMaybe<SurveyResponseV2>>;
  surveyName: Scalars['String'];
};

export type SetVercelIntegrationInput = {
  configuration_id: Scalars['String'];
  connections: Array<VercelIntegrationConnectionsInput>;
  team_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['String']>;
};

export type SetVercelIntegrationOutput = {
  __typename?: 'SetVercelIntegrationOutput';
  id: Scalars['String'];
};

export type SlackExchangeTokenResponse = {
  __typename?: 'SlackExchangeTokenResponse';
  channel_name: Scalars['String'];
  team_name: Scalars['String'];
};

export type State = {
  __typename?: 'State';
  code?: Maybe<Scalars['String']>;
  country: Country;
  name: Scalars['String'];
};

export type StringQueryOperatorInput = {
  eq?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  ne?: InputMaybe<Scalars['String']>;
  nin?: InputMaybe<Array<Scalars['String']>>;
  regex?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_BigQuery_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['String']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['String']>>;
  _eq?: InputMaybe<Array<Scalars['String']>>;
  _gt?: InputMaybe<Array<Scalars['String']>>;
  _gte?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Array<Scalars['String']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['String']>>;
  _lte?: InputMaybe<Array<Scalars['String']>>;
  _neq?: InputMaybe<Array<Scalars['String']>>;
  _nin?: InputMaybe<Array<Array<Scalars['String']>>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type StripeCardResponse = {
  __typename?: 'StripeCardResponse';
  id: Scalars['String'];
  status: Scalars['String'];
};

export type Subdivision = {
  __typename?: 'Subdivision';
  code: Scalars['ID'];
  emoji?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type SubgraphEnvironmentVariable = {
  environment_variables: Scalars['jsonb'];
  subgraph: Scalars['String'];
};

export type SuccessOrError = {
  __typename?: 'SuccessOrError';
  status: Scalars['String'];
};

export type SupportTicketDetails = {
  __typename?: 'SupportTicketDetails';
  body: Scalars['String'];
  created_at: Scalars['String'];
  id: Scalars['Int'];
  project_id?: Maybe<Scalars['uuid']>;
  project_name?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  subject: Scalars['String'];
  total_tickets: Scalars['Int'];
  zendesk_link: Scalars['String'];
};

export enum SupportTicketPriority {
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL',
  Urgent = 'URGENT',
}

export type SurveyAnswersPayload = {
  projectID?: InputMaybe<Scalars['uuid']>;
  responses: Array<InputMaybe<QuestionAnswers>>;
  surveyName: Scalars['String'];
};

export type SurveyResponseV2 = {
  additionalInfo?: InputMaybe<Scalars['String']>;
  answer?: InputMaybe<Scalars['String']>;
  optionSelected?: InputMaybe<Scalars['uuid']>;
  questionId: Scalars['uuid'];
};

export type TenantDeleteResponse = {
  __typename?: 'TenantDeleteResponse';
  status: Scalars['String'];
};

export type TenantEnv = {
  __typename?: 'TenantEnv';
  envVars: Scalars['jsonb'];
  hash: Scalars['String'];
};

export type TenantEnvConfigInfo = {
  __typename?: 'TenantEnvConfigInfo';
  hash: Scalars['String'];
  isAdminSecretSet: Scalars['Boolean'];
  isMetadataAPIEnabled: Scalars['Boolean'];
  userRole: Scalars['String'];
};

/** Boolean expression to compare columns of type "Timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_BigQuery_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Timestamp']>;
  _gt?: InputMaybe<Scalars['Timestamp']>;
  _gte?: InputMaybe<Scalars['Timestamp']>;
  _in?: InputMaybe<Array<Scalars['Timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Timestamp']>;
  _lte?: InputMaybe<Scalars['Timestamp']>;
  _neq?: InputMaybe<Scalars['Timestamp']>;
  _nin?: InputMaybe<Array<Scalars['Timestamp']>>;
};

export type ToggleRequestAccessSettingOutput = {
  __typename?: 'ToggleRequestAccessSettingOutput';
  message: Scalars['String'];
};

export type TrackOnboardingSampleDbCohortActivityResponse = {
  __typename?: 'TrackOnboardingSampleDbCohortActivityResponse';
  status: Scalars['String'];
};

export type TransferOwnershipRequest = {
  invitee_email: Scalars['String'];
  project_id: Scalars['uuid'];
};

/** Boolean expression to compare columns of type "UUID". All fields are combined with logical 'AND'. */
export type Uuid_Clickhouse_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['UUID']>;
  _gt?: InputMaybe<Scalars['UUID']>;
  _gte?: InputMaybe<Scalars['UUID']>;
  _in?: InputMaybe<Array<Scalars['UUID']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['UUID']>;
  _lte?: InputMaybe<Scalars['UUID']>;
  _neq?: InputMaybe<Scalars['UUID']>;
  _nin?: InputMaybe<Array<Scalars['UUID']>>;
};

export type UpdateBillingManagerResponse = {
  __typename?: 'UpdateBillingManagerResponse';
  status: Scalars['String'];
};

export type UpdateEnvObject = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateEnvsObject = {
  key: Scalars['String'];
  value: Scalars['String'];
};

export type UpdateGithubIntegrationInput = {
  branch: Scalars['String'];
  directory?: InputMaybe<Scalars['String']>;
  email_status: Scalars['String'];
  installation_id: Scalars['Int'];
  integration_id: Scalars['String'];
  mode?: InputMaybe<ModeEnum>;
  org: Scalars['String'];
  repo: Scalars['String'];
};

export type UpdateGithubIntegrationStatus = {
  __typename?: 'UpdateGithubIntegrationStatus';
  status: Scalars['String'];
};

export type UpdateServerEndpointInput = {
  endpoint: Scalars['String'];
  id: Scalars['uuid'];
};

export type UpdateServerEndpointResponse = {
  __typename?: 'UpdateServerEndpointResponse';
  console_oauth_id: Scalars['String'];
  created_at: Scalars['String'];
  endpoint: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  owner_id: Scalars['uuid'];
  server_oauth_id: Scalars['String'];
};

export type UpdateTenantNameResponse = {
  __typename?: 'UpdateTenantNameResponse';
  tenant?: Maybe<Tenant>;
  tenant_id: Scalars['uuid'];
};

export type UpdateTenantStatusInputPayload = {
  active: Scalars['Boolean'];
  active_status_reason?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['uuid']>>>;
  project_ids?: InputMaybe<Array<InputMaybe<Scalars['uuid']>>>;
};

export type UpdateTenantStatusResponse = {
  __typename?: 'UpdateTenantStatusResponse';
  affected_rows: Scalars['Int'];
};

export type UpdatedVpcProjectPrivilegeList = {
  __typename?: 'UpdatedVPCProjectPrivilegeList';
  dedicated_vpc_owner_id: Scalars['uuid'];
  new_admin_collab_project_ids: Array<Scalars['uuid']>;
  updated_privilege_project_ids: Array<Scalars['uuid']>;
};

export type UserInvitation = {
  __typename?: 'UserInvitation';
  id: Scalars['uuid'];
  invitedAt: Scalars['String'];
  invitedBy: Scalars['String'];
  isBillable: Scalars['Boolean'];
  isBillingInvitee: Scalars['Boolean'];
  key: Scalars['String'];
  privileges: Array<Scalars['String']>;
  projectID: Scalars['uuid'];
  projectName: Scalars['String'];
};

export enum VpcCollaboratorAccessLevel {
  Manager = 'manager',
  Viewer = 'viewer',
}

export type VpcPlanNameResponse = {
  __typename?: 'VPCPlanNameResponse';
  Status: Scalars['String'];
};

export type ValidTenantNameResponse = {
  __typename?: 'ValidTenantNameResponse';
  message: Scalars['String'];
  valid: Scalars['Boolean'];
};

export type VercelGetProjectsOutput = {
  __typename?: 'VercelGetProjectsOutput';
  vercelProjects: Array<VercelProjectInfo>;
};

export type VercelIntegrationConnectionsInput = {
  hasura_cloud_project_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  vercel_project_id?: InputMaybe<Scalars['String']>;
};

export type VercelProjectInfo = {
  __typename?: 'VercelProjectInfo';
  projectId: Scalars['String'];
  projectName: Scalars['String'];
};

export type VercelTokenInputPayload = {
  code: Scalars['String'];
  configuration_id: Scalars['String'];
  redirect_uri: Scalars['String'];
  team_id?: InputMaybe<Scalars['String']>;
};

export type WebhookRegisterStatus = {
  __typename?: 'WebhookRegisterStatus';
  status: Scalars['String'];
};

export type WebhookUnregisterStatus = {
  __typename?: 'WebhookUnregisterStatus';
  status: Scalars['String'];
};

export type YearInReportActivityPayload = {
  kind: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** Alert configuration for Hasura Cloud projects */
export type Alert_Config = {
  __typename?: 'alert_config';
  /** An array relationship */
  alert_config_services: Array<Alert_Config_Service>;
  /** An aggregate relationship */
  alert_config_services_aggregate: Alert_Config_Service_Aggregate;
  /** An array relationship */
  alert_types: Array<Alert_Config_Alert_Type>;
  /** An aggregate relationship */
  alert_types_aggregate: Alert_Config_Alert_Type_Aggregate;
  created_at: Scalars['timestamptz'];
  enabled: Scalars['Boolean'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** An array relationship */
  slack_alert_configs: Array<Slack_Config>;
  /** An aggregate relationship */
  slack_alert_configs_aggregate: Slack_Config_Aggregate;
  /** An object relationship */
  tenant?: Maybe<Tenant>;
  updated_at: Scalars['timestamptz'];
};

/** Alert configuration for Hasura Cloud projects */
export type Alert_ConfigAlert_Config_ServicesArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Service_Order_By>>;
  where?: InputMaybe<Alert_Config_Service_Bool_Exp>;
};

/** Alert configuration for Hasura Cloud projects */
export type Alert_ConfigAlert_Config_Services_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Service_Order_By>>;
  where?: InputMaybe<Alert_Config_Service_Bool_Exp>;
};

/** Alert configuration for Hasura Cloud projects */
export type Alert_ConfigAlert_TypesArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Alert_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Alert_Type_Order_By>>;
  where?: InputMaybe<Alert_Config_Alert_Type_Bool_Exp>;
};

/** Alert configuration for Hasura Cloud projects */
export type Alert_ConfigAlert_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Alert_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Alert_Type_Order_By>>;
  where?: InputMaybe<Alert_Config_Alert_Type_Bool_Exp>;
};

/** Alert configuration for Hasura Cloud projects */
export type Alert_ConfigSlack_Alert_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Slack_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Slack_Config_Order_By>>;
  where?: InputMaybe<Slack_Config_Bool_Exp>;
};

/** Alert configuration for Hasura Cloud projects */
export type Alert_ConfigSlack_Alert_Configs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Slack_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Slack_Config_Order_By>>;
  where?: InputMaybe<Slack_Config_Bool_Exp>;
};

/** aggregated selection of "alert_config" */
export type Alert_Config_Aggregate = {
  __typename?: 'alert_config_aggregate';
  aggregate?: Maybe<Alert_Config_Aggregate_Fields>;
  nodes: Array<Alert_Config>;
};

/** aggregate fields of "alert_config" */
export type Alert_Config_Aggregate_Fields = {
  __typename?: 'alert_config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Alert_Config_Max_Fields>;
  min?: Maybe<Alert_Config_Min_Fields>;
};

/** aggregate fields of "alert_config" */
export type Alert_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Alert_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Enabled alert types for Hasura Cloud projects */
export type Alert_Config_Alert_Type = {
  __typename?: 'alert_config_alert_type';
  /** An object relationship */
  alert_config: Alert_Config;
  /** An object relationship */
  alert_type: Alert_Type;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id: Scalars['uuid'];
  rules?: Maybe<Scalars['json']>;
  /** An object relationship */
  slack_config?: Maybe<Slack_Config>;
  type: Scalars['String'];
};

/** Enabled alert types for Hasura Cloud projects */
export type Alert_Config_Alert_TypeRulesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "alert_config_alert_type" */
export type Alert_Config_Alert_Type_Aggregate = {
  __typename?: 'alert_config_alert_type_aggregate';
  aggregate?: Maybe<Alert_Config_Alert_Type_Aggregate_Fields>;
  nodes: Array<Alert_Config_Alert_Type>;
};

export type Alert_Config_Alert_Type_Aggregate_Bool_Exp = {
  count?: InputMaybe<Alert_Config_Alert_Type_Aggregate_Bool_Exp_Count>;
};

export type Alert_Config_Alert_Type_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Alert_Config_Alert_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Alert_Config_Alert_Type_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "alert_config_alert_type" */
export type Alert_Config_Alert_Type_Aggregate_Fields = {
  __typename?: 'alert_config_alert_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Alert_Config_Alert_Type_Max_Fields>;
  min?: Maybe<Alert_Config_Alert_Type_Min_Fields>;
};

/** aggregate fields of "alert_config_alert_type" */
export type Alert_Config_Alert_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Alert_Config_Alert_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "alert_config_alert_type" */
export type Alert_Config_Alert_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Alert_Config_Alert_Type_Max_Order_By>;
  min?: InputMaybe<Alert_Config_Alert_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "alert_config_alert_type" */
export type Alert_Config_Alert_Type_Arr_Rel_Insert_Input = {
  data: Array<Alert_Config_Alert_Type_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Alert_Config_Alert_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "alert_config_alert_type". All fields are combined with a logical 'AND'. */
export type Alert_Config_Alert_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Alert_Config_Alert_Type_Bool_Exp>>;
  _not?: InputMaybe<Alert_Config_Alert_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Alert_Config_Alert_Type_Bool_Exp>>;
  alert_config?: InputMaybe<Alert_Config_Bool_Exp>;
  alert_type?: InputMaybe<Alert_Type_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  rules?: InputMaybe<Json_Comparison_Exp>;
  slack_config?: InputMaybe<Slack_Config_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "alert_config_alert_type" */
export enum Alert_Config_Alert_Type_Constraint {
  /** unique or primary key constraint on columns "project_id", "type" */
  AlertConfigAlertTypePkey = 'alert_config_alert_type_pkey',
}

/** input type for inserting data into table "alert_config_alert_type" */
export type Alert_Config_Alert_Type_Insert_Input = {
  alert_config?: InputMaybe<Alert_Config_Obj_Rel_Insert_Input>;
  alert_type?: InputMaybe<Alert_Type_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rules?: InputMaybe<Scalars['json']>;
  slack_config?: InputMaybe<Slack_Config_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Alert_Config_Alert_Type_Max_Fields = {
  __typename?: 'alert_config_alert_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "alert_config_alert_type" */
export type Alert_Config_Alert_Type_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Alert_Config_Alert_Type_Min_Fields = {
  __typename?: 'alert_config_alert_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "alert_config_alert_type" */
export type Alert_Config_Alert_Type_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "alert_config_alert_type" */
export type Alert_Config_Alert_Type_Mutation_Response = {
  __typename?: 'alert_config_alert_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Alert_Config_Alert_Type>;
};

/** on_conflict condition type for table "alert_config_alert_type" */
export type Alert_Config_Alert_Type_On_Conflict = {
  constraint: Alert_Config_Alert_Type_Constraint;
  update_columns?: Array<Alert_Config_Alert_Type_Update_Column>;
  where?: InputMaybe<Alert_Config_Alert_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "alert_config_alert_type". */
export type Alert_Config_Alert_Type_Order_By = {
  alert_config?: InputMaybe<Alert_Config_Order_By>;
  alert_type?: InputMaybe<Alert_Type_Order_By>;
  created_at?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  rules?: InputMaybe<Order_By>;
  slack_config?: InputMaybe<Slack_Config_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: alert_config_alert_type */
export type Alert_Config_Alert_Type_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
  type: Scalars['String'];
};

/** select columns of table "alert_config_alert_type" */
export enum Alert_Config_Alert_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Rules = 'rules',
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "alert_config_alert_type" */
export type Alert_Config_Alert_Type_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rules?: InputMaybe<Scalars['json']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "alert_config_alert_type" */
export type Alert_Config_Alert_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Alert_Config_Alert_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Alert_Config_Alert_Type_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rules?: InputMaybe<Scalars['json']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "alert_config_alert_type" */
export enum Alert_Config_Alert_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Rules = 'rules',
  /** column name */
  Type = 'type',
}

export type Alert_Config_Alert_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Alert_Config_Alert_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Alert_Config_Alert_Type_Bool_Exp;
};

/** Boolean expression to filter rows from the table "alert_config". All fields are combined with a logical 'AND'. */
export type Alert_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Alert_Config_Bool_Exp>>;
  _not?: InputMaybe<Alert_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Alert_Config_Bool_Exp>>;
  alert_config_services?: InputMaybe<Alert_Config_Service_Bool_Exp>;
  alert_config_services_aggregate?: InputMaybe<
    Alert_Config_Service_Aggregate_Bool_Exp
  >;
  alert_types?: InputMaybe<Alert_Config_Alert_Type_Bool_Exp>;
  alert_types_aggregate?: InputMaybe<
    Alert_Config_Alert_Type_Aggregate_Bool_Exp
  >;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  slack_alert_configs?: InputMaybe<Slack_Config_Bool_Exp>;
  slack_alert_configs_aggregate?: InputMaybe<Slack_Config_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "alert_config" */
export enum Alert_Config_Constraint {
  /** unique or primary key constraint on columns "project_id" */
  AlertConfigPkey = 'alert_config_pkey',
}

/** input type for inserting data into table "alert_config" */
export type Alert_Config_Insert_Input = {
  alert_config_services?: InputMaybe<Alert_Config_Service_Arr_Rel_Insert_Input>;
  alert_types?: InputMaybe<Alert_Config_Alert_Type_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  slack_alert_configs?: InputMaybe<Slack_Config_Arr_Rel_Insert_Input>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Alert_Config_Max_Fields = {
  __typename?: 'alert_config_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Alert_Config_Min_Fields = {
  __typename?: 'alert_config_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "alert_config" */
export type Alert_Config_Mutation_Response = {
  __typename?: 'alert_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Alert_Config>;
};

/** input type for inserting object relation for remote table "alert_config" */
export type Alert_Config_Obj_Rel_Insert_Input = {
  data: Alert_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Alert_Config_On_Conflict>;
};

/** on_conflict condition type for table "alert_config" */
export type Alert_Config_On_Conflict = {
  constraint: Alert_Config_Constraint;
  update_columns?: Array<Alert_Config_Update_Column>;
  where?: InputMaybe<Alert_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "alert_config". */
export type Alert_Config_Order_By = {
  alert_config_services_aggregate?: InputMaybe<
    Alert_Config_Service_Aggregate_Order_By
  >;
  alert_types_aggregate?: InputMaybe<
    Alert_Config_Alert_Type_Aggregate_Order_By
  >;
  created_at?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  slack_alert_configs_aggregate?: InputMaybe<Slack_Config_Aggregate_Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: alert_config */
export type Alert_Config_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
};

/** select columns of table "alert_config" */
export enum Alert_Config_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Alert notification service configs for Hasura Cloud projects */
export type Alert_Config_Service = {
  __typename?: 'alert_config_service';
  /** An object relationship */
  alert_config: Alert_Config;
  created_at: Scalars['timestamptz'];
  enabled: Scalars['Boolean'];
  metadata?: Maybe<Scalars['json']>;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id: Scalars['uuid'];
  rules?: Maybe<Scalars['jsonb']>;
  type: Alert_Service_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** Alert notification service configs for Hasura Cloud projects */
export type Alert_Config_ServiceMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Alert notification service configs for Hasura Cloud projects */
export type Alert_Config_ServiceRulesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "alert_config_service" */
export type Alert_Config_Service_Aggregate = {
  __typename?: 'alert_config_service_aggregate';
  aggregate?: Maybe<Alert_Config_Service_Aggregate_Fields>;
  nodes: Array<Alert_Config_Service>;
};

export type Alert_Config_Service_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Alert_Config_Service_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Alert_Config_Service_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Alert_Config_Service_Aggregate_Bool_Exp_Count>;
};

export type Alert_Config_Service_Aggregate_Bool_Exp_Bool_And = {
  arguments: Alert_Config_Service_Select_Column_Alert_Config_Service_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Alert_Config_Service_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Alert_Config_Service_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Alert_Config_Service_Select_Column_Alert_Config_Service_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Alert_Config_Service_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Alert_Config_Service_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Alert_Config_Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Alert_Config_Service_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "alert_config_service" */
export type Alert_Config_Service_Aggregate_Fields = {
  __typename?: 'alert_config_service_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Alert_Config_Service_Max_Fields>;
  min?: Maybe<Alert_Config_Service_Min_Fields>;
};

/** aggregate fields of "alert_config_service" */
export type Alert_Config_Service_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Alert_Config_Service_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "alert_config_service" */
export type Alert_Config_Service_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Alert_Config_Service_Max_Order_By>;
  min?: InputMaybe<Alert_Config_Service_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Alert_Config_Service_Append_Input = {
  rules?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "alert_config_service" */
export type Alert_Config_Service_Arr_Rel_Insert_Input = {
  data: Array<Alert_Config_Service_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Alert_Config_Service_On_Conflict>;
};

/** Boolean expression to filter rows from the table "alert_config_service". All fields are combined with a logical 'AND'. */
export type Alert_Config_Service_Bool_Exp = {
  _and?: InputMaybe<Array<Alert_Config_Service_Bool_Exp>>;
  _not?: InputMaybe<Alert_Config_Service_Bool_Exp>;
  _or?: InputMaybe<Array<Alert_Config_Service_Bool_Exp>>;
  alert_config?: InputMaybe<Alert_Config_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enabled?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Json_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  rules?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<Alert_Service_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "alert_config_service" */
export enum Alert_Config_Service_Constraint {
  /** unique or primary key constraint on columns "project_id", "type" */
  AlertConfigServicePkey = 'alert_config_service_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Alert_Config_Service_Delete_At_Path_Input = {
  rules?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Alert_Config_Service_Delete_Elem_Input = {
  rules?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Alert_Config_Service_Delete_Key_Input = {
  rules?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "alert_config_service" */
export type Alert_Config_Service_Insert_Input = {
  alert_config?: InputMaybe<Alert_Config_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['json']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rules?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Alert_Service_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Alert_Config_Service_Max_Fields = {
  __typename?: 'alert_config_service_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "alert_config_service" */
export type Alert_Config_Service_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Alert_Config_Service_Min_Fields = {
  __typename?: 'alert_config_service_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "alert_config_service" */
export type Alert_Config_Service_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "alert_config_service" */
export type Alert_Config_Service_Mutation_Response = {
  __typename?: 'alert_config_service_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Alert_Config_Service>;
};

/** on_conflict condition type for table "alert_config_service" */
export type Alert_Config_Service_On_Conflict = {
  constraint: Alert_Config_Service_Constraint;
  update_columns?: Array<Alert_Config_Service_Update_Column>;
  where?: InputMaybe<Alert_Config_Service_Bool_Exp>;
};

/** Ordering options when selecting data from "alert_config_service". */
export type Alert_Config_Service_Order_By = {
  alert_config?: InputMaybe<Alert_Config_Order_By>;
  created_at?: InputMaybe<Order_By>;
  enabled?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  rules?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: alert_config_service */
export type Alert_Config_Service_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
  type: Alert_Service_Type_Enum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Alert_Config_Service_Prepend_Input = {
  rules?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "alert_config_service" */
export enum Alert_Config_Service_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Rules = 'rules',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "alert_config_service_aggregate_bool_exp_bool_and_arguments_columns" columns of table "alert_config_service" */
export enum Alert_Config_Service_Select_Column_Alert_Config_Service_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Enabled = 'enabled',
}

/** select "alert_config_service_aggregate_bool_exp_bool_or_arguments_columns" columns of table "alert_config_service" */
export enum Alert_Config_Service_Select_Column_Alert_Config_Service_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Enabled = 'enabled',
}

/** input type for updating data in table "alert_config_service" */
export type Alert_Config_Service_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['json']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rules?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Alert_Service_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "alert_config_service" */
export type Alert_Config_Service_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Alert_Config_Service_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Alert_Config_Service_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['json']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rules?: InputMaybe<Scalars['jsonb']>;
  type?: InputMaybe<Alert_Service_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "alert_config_service" */
export enum Alert_Config_Service_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Rules = 'rules',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Alert_Config_Service_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Alert_Config_Service_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Alert_Config_Service_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Alert_Config_Service_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Alert_Config_Service_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Alert_Config_Service_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Alert_Config_Service_Set_Input>;
  /** filter the rows which have to be updated */
  where: Alert_Config_Service_Bool_Exp;
};

/** input type for updating data in table "alert_config" */
export type Alert_Config_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "alert_config" */
export type Alert_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Alert_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Alert_Config_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enabled?: InputMaybe<Scalars['Boolean']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "alert_config" */
export enum Alert_Config_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Enabled = 'enabled',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Alert_Config_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Alert_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Alert_Config_Bool_Exp;
};

/** Alert notification service type enum */
export type Alert_Service_Type = {
  __typename?: 'alert_service_type';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "alert_service_type" */
export type Alert_Service_Type_Aggregate = {
  __typename?: 'alert_service_type_aggregate';
  aggregate?: Maybe<Alert_Service_Type_Aggregate_Fields>;
  nodes: Array<Alert_Service_Type>;
};

/** aggregate fields of "alert_service_type" */
export type Alert_Service_Type_Aggregate_Fields = {
  __typename?: 'alert_service_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Alert_Service_Type_Max_Fields>;
  min?: Maybe<Alert_Service_Type_Min_Fields>;
};

/** aggregate fields of "alert_service_type" */
export type Alert_Service_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Alert_Service_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "alert_service_type". All fields are combined with a logical 'AND'. */
export type Alert_Service_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Alert_Service_Type_Bool_Exp>>;
  _not?: InputMaybe<Alert_Service_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Alert_Service_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "alert_service_type" */
export enum Alert_Service_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  AlertServiceTypePkey = 'alert_service_type_pkey',
}

export enum Alert_Service_Type_Enum {
  /** Mail */
  Mail = 'mail',
  /** Slack Notifier */
  Slack = 'slack',
}

/** Boolean expression to compare columns of type "alert_service_type_enum". All fields are combined with logical 'AND'. */
export type Alert_Service_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Alert_Service_Type_Enum>;
  _in?: InputMaybe<Array<Alert_Service_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Alert_Service_Type_Enum>;
  _nin?: InputMaybe<Array<Alert_Service_Type_Enum>>;
};

/** input type for inserting data into table "alert_service_type" */
export type Alert_Service_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Alert_Service_Type_Max_Fields = {
  __typename?: 'alert_service_type_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Alert_Service_Type_Min_Fields = {
  __typename?: 'alert_service_type_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "alert_service_type" */
export type Alert_Service_Type_Mutation_Response = {
  __typename?: 'alert_service_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Alert_Service_Type>;
};

/** on_conflict condition type for table "alert_service_type" */
export type Alert_Service_Type_On_Conflict = {
  constraint: Alert_Service_Type_Constraint;
  update_columns?: Array<Alert_Service_Type_Update_Column>;
  where?: InputMaybe<Alert_Service_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "alert_service_type". */
export type Alert_Service_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: alert_service_type */
export type Alert_Service_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "alert_service_type" */
export enum Alert_Service_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "alert_service_type" */
export type Alert_Service_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "alert_service_type" */
export type Alert_Service_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Alert_Service_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Alert_Service_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "alert_service_type" */
export enum Alert_Service_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type Alert_Service_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Alert_Service_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Alert_Service_Type_Bool_Exp;
};

/** Alert type enum for alert configuration */
export type Alert_Type = {
  __typename?: 'alert_type';
  always_enabled: Scalars['Boolean'];
  description: Scalars['String'];
  hidden: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  template?: Maybe<Scalars['String']>;
};

/** aggregated selection of "alert_type" */
export type Alert_Type_Aggregate = {
  __typename?: 'alert_type_aggregate';
  aggregate?: Maybe<Alert_Type_Aggregate_Fields>;
  nodes: Array<Alert_Type>;
};

/** aggregate fields of "alert_type" */
export type Alert_Type_Aggregate_Fields = {
  __typename?: 'alert_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Alert_Type_Max_Fields>;
  min?: Maybe<Alert_Type_Min_Fields>;
};

/** aggregate fields of "alert_type" */
export type Alert_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Alert_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "alert_type". All fields are combined with a logical 'AND'. */
export type Alert_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Alert_Type_Bool_Exp>>;
  _not?: InputMaybe<Alert_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Alert_Type_Bool_Exp>>;
  always_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  hidden?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  template?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "alert_type" */
export enum Alert_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  AlertTypePkey = 'alert_type_pkey',
}

/** input type for inserting data into table "alert_type" */
export type Alert_Type_Insert_Input = {
  always_enabled?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Alert_Type_Max_Fields = {
  __typename?: 'alert_type_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Alert_Type_Min_Fields = {
  __typename?: 'alert_type_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "alert_type" */
export type Alert_Type_Mutation_Response = {
  __typename?: 'alert_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Alert_Type>;
};

/** input type for inserting object relation for remote table "alert_type" */
export type Alert_Type_Obj_Rel_Insert_Input = {
  data: Alert_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Alert_Type_On_Conflict>;
};

/** on_conflict condition type for table "alert_type" */
export type Alert_Type_On_Conflict = {
  constraint: Alert_Type_Constraint;
  update_columns?: Array<Alert_Type_Update_Column>;
  where?: InputMaybe<Alert_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "alert_type". */
export type Alert_Type_Order_By = {
  always_enabled?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  hidden?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  template?: InputMaybe<Order_By>;
};

/** primary key columns input for table: alert_type */
export type Alert_Type_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "alert_type" */
export enum Alert_Type_Select_Column {
  /** column name */
  AlwaysEnabled = 'always_enabled',
  /** column name */
  Description = 'description',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Template = 'template',
}

/** input type for updating data in table "alert_type" */
export type Alert_Type_Set_Input = {
  always_enabled?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "alert_type" */
export type Alert_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Alert_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Alert_Type_Stream_Cursor_Value_Input = {
  always_enabled?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  hidden?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  template?: InputMaybe<Scalars['String']>;
};

/** update columns of table "alert_type" */
export enum Alert_Type_Update_Column {
  /** column name */
  AlwaysEnabled = 'always_enabled',
  /** column name */
  Description = 'description',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Template = 'template',
}

export type Alert_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Alert_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Alert_Type_Bool_Exp;
};

/** Azure Monitor Config for Metrics Exporter */
export type Azuremonitor_Config = {
  __typename?: 'azuremonitor_config';
  activedirectory_client_id: Scalars['String'];
  activedirectory_client_secret: Scalars['String'];
  activedirectory_tenant_id: Scalars['String'];
  attributes: Scalars['jsonb'];
  azure_region: Scalars['String'];
  azuremonitor_workspace_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  instrumentation_key?: Maybe<Scalars['String']>;
  log_type: Scalars['String'];
  logs_last_logged: Scalars['timestamptz'];
  logs_response_code?: Maybe<Scalars['Int']>;
  metric_namespace: Scalars['String'];
  metrics_last_logged: Scalars['timestamptz'];
  metrics_response_code?: Maybe<Scalars['Int']>;
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  resource_id: Scalars['String'];
  shared_key: Scalars['String'];
  traces_last_logged: Scalars['timestamptz'];
  traces_response_code?: Maybe<Scalars['Int']>;
  traces_response_message?: Maybe<Scalars['String']>;
};

/** Azure Monitor Config for Metrics Exporter */
export type Azuremonitor_ConfigAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "azuremonitor_config" */
export type Azuremonitor_Config_Aggregate = {
  __typename?: 'azuremonitor_config_aggregate';
  aggregate?: Maybe<Azuremonitor_Config_Aggregate_Fields>;
  nodes: Array<Azuremonitor_Config>;
};

/** aggregate fields of "azuremonitor_config" */
export type Azuremonitor_Config_Aggregate_Fields = {
  __typename?: 'azuremonitor_config_aggregate_fields';
  avg?: Maybe<Azuremonitor_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Azuremonitor_Config_Max_Fields>;
  min?: Maybe<Azuremonitor_Config_Min_Fields>;
  stddev?: Maybe<Azuremonitor_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Azuremonitor_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Azuremonitor_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Azuremonitor_Config_Sum_Fields>;
  var_pop?: Maybe<Azuremonitor_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Azuremonitor_Config_Var_Samp_Fields>;
  variance?: Maybe<Azuremonitor_Config_Variance_Fields>;
};

/** aggregate fields of "azuremonitor_config" */
export type Azuremonitor_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Azuremonitor_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Azuremonitor_Config_Append_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Azuremonitor_Config_Avg_Fields = {
  __typename?: 'azuremonitor_config_avg_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "azuremonitor_config". All fields are combined with a logical 'AND'. */
export type Azuremonitor_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Azuremonitor_Config_Bool_Exp>>;
  _not?: InputMaybe<Azuremonitor_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Azuremonitor_Config_Bool_Exp>>;
  activedirectory_client_id?: InputMaybe<String_Comparison_Exp>;
  activedirectory_client_secret?: InputMaybe<String_Comparison_Exp>;
  activedirectory_tenant_id?: InputMaybe<String_Comparison_Exp>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  azure_region?: InputMaybe<String_Comparison_Exp>;
  azuremonitor_workspace_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  instrumentation_key?: InputMaybe<String_Comparison_Exp>;
  log_type?: InputMaybe<String_Comparison_Exp>;
  logs_last_logged?: InputMaybe<Timestamptz_Comparison_Exp>;
  logs_response_code?: InputMaybe<Int_Comparison_Exp>;
  metric_namespace?: InputMaybe<String_Comparison_Exp>;
  metrics_last_logged?: InputMaybe<Timestamptz_Comparison_Exp>;
  metrics_response_code?: InputMaybe<Int_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  resource_id?: InputMaybe<String_Comparison_Exp>;
  shared_key?: InputMaybe<String_Comparison_Exp>;
  traces_last_logged?: InputMaybe<Timestamptz_Comparison_Exp>;
  traces_response_code?: InputMaybe<Int_Comparison_Exp>;
  traces_response_message?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "azuremonitor_config" */
export enum Azuremonitor_Config_Constraint {
  /** unique or primary key constraint on columns "project_id" */
  AzuremonitorConfigPkey = 'azuremonitor_config_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Azuremonitor_Config_Delete_At_Path_Input = {
  attributes?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Azuremonitor_Config_Delete_Elem_Input = {
  attributes?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Azuremonitor_Config_Delete_Key_Input = {
  attributes?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "azuremonitor_config" */
export type Azuremonitor_Config_Inc_Input = {
  logs_response_code?: InputMaybe<Scalars['Int']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  traces_response_code?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "azuremonitor_config" */
export type Azuremonitor_Config_Insert_Input = {
  activedirectory_client_id?: InputMaybe<Scalars['String']>;
  activedirectory_client_secret?: InputMaybe<Scalars['String']>;
  activedirectory_tenant_id?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  azure_region?: InputMaybe<Scalars['String']>;
  azuremonitor_workspace_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  instrumentation_key?: InputMaybe<Scalars['String']>;
  log_type?: InputMaybe<Scalars['String']>;
  logs_last_logged?: InputMaybe<Scalars['timestamptz']>;
  logs_response_code?: InputMaybe<Scalars['Int']>;
  metric_namespace?: InputMaybe<Scalars['String']>;
  metrics_last_logged?: InputMaybe<Scalars['timestamptz']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  resource_id?: InputMaybe<Scalars['String']>;
  shared_key?: InputMaybe<Scalars['String']>;
  traces_last_logged?: InputMaybe<Scalars['timestamptz']>;
  traces_response_code?: InputMaybe<Scalars['Int']>;
  traces_response_message?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Azuremonitor_Config_Max_Fields = {
  __typename?: 'azuremonitor_config_max_fields';
  activedirectory_client_id?: Maybe<Scalars['String']>;
  activedirectory_client_secret?: Maybe<Scalars['String']>;
  activedirectory_tenant_id?: Maybe<Scalars['String']>;
  azure_region?: Maybe<Scalars['String']>;
  azuremonitor_workspace_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  instrumentation_key?: Maybe<Scalars['String']>;
  log_type?: Maybe<Scalars['String']>;
  logs_last_logged?: Maybe<Scalars['timestamptz']>;
  logs_response_code?: Maybe<Scalars['Int']>;
  metric_namespace?: Maybe<Scalars['String']>;
  metrics_last_logged?: Maybe<Scalars['timestamptz']>;
  metrics_response_code?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['String']>;
  shared_key?: Maybe<Scalars['String']>;
  traces_last_logged?: Maybe<Scalars['timestamptz']>;
  traces_response_code?: Maybe<Scalars['Int']>;
  traces_response_message?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Azuremonitor_Config_Min_Fields = {
  __typename?: 'azuremonitor_config_min_fields';
  activedirectory_client_id?: Maybe<Scalars['String']>;
  activedirectory_client_secret?: Maybe<Scalars['String']>;
  activedirectory_tenant_id?: Maybe<Scalars['String']>;
  azure_region?: Maybe<Scalars['String']>;
  azuremonitor_workspace_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  instrumentation_key?: Maybe<Scalars['String']>;
  log_type?: Maybe<Scalars['String']>;
  logs_last_logged?: Maybe<Scalars['timestamptz']>;
  logs_response_code?: Maybe<Scalars['Int']>;
  metric_namespace?: Maybe<Scalars['String']>;
  metrics_last_logged?: Maybe<Scalars['timestamptz']>;
  metrics_response_code?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  resource_id?: Maybe<Scalars['String']>;
  shared_key?: Maybe<Scalars['String']>;
  traces_last_logged?: Maybe<Scalars['timestamptz']>;
  traces_response_code?: Maybe<Scalars['Int']>;
  traces_response_message?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "azuremonitor_config" */
export type Azuremonitor_Config_Mutation_Response = {
  __typename?: 'azuremonitor_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Azuremonitor_Config>;
};

/** input type for inserting object relation for remote table "azuremonitor_config" */
export type Azuremonitor_Config_Obj_Rel_Insert_Input = {
  data: Azuremonitor_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Azuremonitor_Config_On_Conflict>;
};

/** on_conflict condition type for table "azuremonitor_config" */
export type Azuremonitor_Config_On_Conflict = {
  constraint: Azuremonitor_Config_Constraint;
  update_columns?: Array<Azuremonitor_Config_Update_Column>;
  where?: InputMaybe<Azuremonitor_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "azuremonitor_config". */
export type Azuremonitor_Config_Order_By = {
  activedirectory_client_id?: InputMaybe<Order_By>;
  activedirectory_client_secret?: InputMaybe<Order_By>;
  activedirectory_tenant_id?: InputMaybe<Order_By>;
  attributes?: InputMaybe<Order_By>;
  azure_region?: InputMaybe<Order_By>;
  azuremonitor_workspace_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  instrumentation_key?: InputMaybe<Order_By>;
  log_type?: InputMaybe<Order_By>;
  logs_last_logged?: InputMaybe<Order_By>;
  logs_response_code?: InputMaybe<Order_By>;
  metric_namespace?: InputMaybe<Order_By>;
  metrics_last_logged?: InputMaybe<Order_By>;
  metrics_response_code?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  resource_id?: InputMaybe<Order_By>;
  shared_key?: InputMaybe<Order_By>;
  traces_last_logged?: InputMaybe<Order_By>;
  traces_response_code?: InputMaybe<Order_By>;
  traces_response_message?: InputMaybe<Order_By>;
};

/** primary key columns input for table: azuremonitor_config */
export type Azuremonitor_Config_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Azuremonitor_Config_Prepend_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "azuremonitor_config" */
export enum Azuremonitor_Config_Select_Column {
  /** column name */
  ActivedirectoryClientId = 'activedirectory_client_id',
  /** column name */
  ActivedirectoryClientSecret = 'activedirectory_client_secret',
  /** column name */
  ActivedirectoryTenantId = 'activedirectory_tenant_id',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  AzureRegion = 'azure_region',
  /** column name */
  AzuremonitorWorkspaceId = 'azuremonitor_workspace_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  InstrumentationKey = 'instrumentation_key',
  /** column name */
  LogType = 'log_type',
  /** column name */
  LogsLastLogged = 'logs_last_logged',
  /** column name */
  LogsResponseCode = 'logs_response_code',
  /** column name */
  MetricNamespace = 'metric_namespace',
  /** column name */
  MetricsLastLogged = 'metrics_last_logged',
  /** column name */
  MetricsResponseCode = 'metrics_response_code',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  SharedKey = 'shared_key',
  /** column name */
  TracesLastLogged = 'traces_last_logged',
  /** column name */
  TracesResponseCode = 'traces_response_code',
  /** column name */
  TracesResponseMessage = 'traces_response_message',
}

/** input type for updating data in table "azuremonitor_config" */
export type Azuremonitor_Config_Set_Input = {
  activedirectory_client_id?: InputMaybe<Scalars['String']>;
  activedirectory_client_secret?: InputMaybe<Scalars['String']>;
  activedirectory_tenant_id?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  azure_region?: InputMaybe<Scalars['String']>;
  azuremonitor_workspace_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  instrumentation_key?: InputMaybe<Scalars['String']>;
  log_type?: InputMaybe<Scalars['String']>;
  logs_last_logged?: InputMaybe<Scalars['timestamptz']>;
  logs_response_code?: InputMaybe<Scalars['Int']>;
  metric_namespace?: InputMaybe<Scalars['String']>;
  metrics_last_logged?: InputMaybe<Scalars['timestamptz']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  resource_id?: InputMaybe<Scalars['String']>;
  shared_key?: InputMaybe<Scalars['String']>;
  traces_last_logged?: InputMaybe<Scalars['timestamptz']>;
  traces_response_code?: InputMaybe<Scalars['Int']>;
  traces_response_message?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Azuremonitor_Config_Stddev_Fields = {
  __typename?: 'azuremonitor_config_stddev_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Azuremonitor_Config_Stddev_Pop_Fields = {
  __typename?: 'azuremonitor_config_stddev_pop_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Azuremonitor_Config_Stddev_Samp_Fields = {
  __typename?: 'azuremonitor_config_stddev_samp_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "azuremonitor_config" */
export type Azuremonitor_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Azuremonitor_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Azuremonitor_Config_Stream_Cursor_Value_Input = {
  activedirectory_client_id?: InputMaybe<Scalars['String']>;
  activedirectory_client_secret?: InputMaybe<Scalars['String']>;
  activedirectory_tenant_id?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  azure_region?: InputMaybe<Scalars['String']>;
  azuremonitor_workspace_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  instrumentation_key?: InputMaybe<Scalars['String']>;
  log_type?: InputMaybe<Scalars['String']>;
  logs_last_logged?: InputMaybe<Scalars['timestamptz']>;
  logs_response_code?: InputMaybe<Scalars['Int']>;
  metric_namespace?: InputMaybe<Scalars['String']>;
  metrics_last_logged?: InputMaybe<Scalars['timestamptz']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  resource_id?: InputMaybe<Scalars['String']>;
  shared_key?: InputMaybe<Scalars['String']>;
  traces_last_logged?: InputMaybe<Scalars['timestamptz']>;
  traces_response_code?: InputMaybe<Scalars['Int']>;
  traces_response_message?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Azuremonitor_Config_Sum_Fields = {
  __typename?: 'azuremonitor_config_sum_fields';
  logs_response_code?: Maybe<Scalars['Int']>;
  metrics_response_code?: Maybe<Scalars['Int']>;
  traces_response_code?: Maybe<Scalars['Int']>;
};

/** update columns of table "azuremonitor_config" */
export enum Azuremonitor_Config_Update_Column {
  /** column name */
  ActivedirectoryClientId = 'activedirectory_client_id',
  /** column name */
  ActivedirectoryClientSecret = 'activedirectory_client_secret',
  /** column name */
  ActivedirectoryTenantId = 'activedirectory_tenant_id',
  /** column name */
  Attributes = 'attributes',
  /** column name */
  AzureRegion = 'azure_region',
  /** column name */
  AzuremonitorWorkspaceId = 'azuremonitor_workspace_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  InstrumentationKey = 'instrumentation_key',
  /** column name */
  LogType = 'log_type',
  /** column name */
  LogsLastLogged = 'logs_last_logged',
  /** column name */
  LogsResponseCode = 'logs_response_code',
  /** column name */
  MetricNamespace = 'metric_namespace',
  /** column name */
  MetricsLastLogged = 'metrics_last_logged',
  /** column name */
  MetricsResponseCode = 'metrics_response_code',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ResourceId = 'resource_id',
  /** column name */
  SharedKey = 'shared_key',
  /** column name */
  TracesLastLogged = 'traces_last_logged',
  /** column name */
  TracesResponseCode = 'traces_response_code',
  /** column name */
  TracesResponseMessage = 'traces_response_message',
}

export type Azuremonitor_Config_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Azuremonitor_Config_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Azuremonitor_Config_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Azuremonitor_Config_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Azuremonitor_Config_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Azuremonitor_Config_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Azuremonitor_Config_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Azuremonitor_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Azuremonitor_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Azuremonitor_Config_Var_Pop_Fields = {
  __typename?: 'azuremonitor_config_var_pop_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Azuremonitor_Config_Var_Samp_Fields = {
  __typename?: 'azuremonitor_config_var_samp_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Azuremonitor_Config_Variance_Fields = {
  __typename?: 'azuremonitor_config_variance_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** Address used for billing paid Hasura Cloud services */
export type Billing_Address = {
  __typename?: 'billing_address';
  address_line_1: Scalars['String'];
  address_line_2?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  country: Scalars['String'];
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['String'];
  /** An array relationship */
  ddn_invoice_billing_address: Array<Ddn_Invoice>;
  /** An aggregate relationship */
  ddn_invoice_billing_address_aggregate: Ddn_Invoice_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  invoice_billing_address: Array<Invoice>;
  /** An aggregate relationship */
  invoice_billing_address_aggregate: Invoice_Aggregate;
  is_active: Scalars['Boolean'];
  last_updated_to_stripe: Scalars['timestamptz'];
  name: Scalars['String'];
  pin_code?: Maybe<Scalars['String']>;
  state: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
};

/** Address used for billing paid Hasura Cloud services */
export type Billing_AddressDdn_Invoice_Billing_AddressArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Bool_Exp>;
};

/** Address used for billing paid Hasura Cloud services */
export type Billing_AddressDdn_Invoice_Billing_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Bool_Exp>;
};

/** Address used for billing paid Hasura Cloud services */
export type Billing_AddressInvoice_Billing_AddressArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

/** Address used for billing paid Hasura Cloud services */
export type Billing_AddressInvoice_Billing_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

/** aggregated selection of "billing_address" */
export type Billing_Address_Aggregate = {
  __typename?: 'billing_address_aggregate';
  aggregate?: Maybe<Billing_Address_Aggregate_Fields>;
  nodes: Array<Billing_Address>;
};

/** aggregate fields of "billing_address" */
export type Billing_Address_Aggregate_Fields = {
  __typename?: 'billing_address_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Billing_Address_Max_Fields>;
  min?: Maybe<Billing_Address_Min_Fields>;
};

/** aggregate fields of "billing_address" */
export type Billing_Address_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Billing_Address_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "billing_address". All fields are combined with a logical 'AND'. */
export type Billing_Address_Bool_Exp = {
  _and?: InputMaybe<Array<Billing_Address_Bool_Exp>>;
  _not?: InputMaybe<Billing_Address_Bool_Exp>;
  _or?: InputMaybe<Array<Billing_Address_Bool_Exp>>;
  address_line_1?: InputMaybe<String_Comparison_Exp>;
  address_line_2?: InputMaybe<String_Comparison_Exp>;
  city?: InputMaybe<String_Comparison_Exp>;
  country?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  ddn_invoice_billing_address?: InputMaybe<Ddn_Invoice_Bool_Exp>;
  ddn_invoice_billing_address_aggregate?: InputMaybe<
    Ddn_Invoice_Aggregate_Bool_Exp
  >;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_billing_address?: InputMaybe<Invoice_Bool_Exp>;
  invoice_billing_address_aggregate?: InputMaybe<Invoice_Aggregate_Bool_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  last_updated_to_stripe?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pin_code?: InputMaybe<String_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "billing_address" */
export enum Billing_Address_Constraint {
  /** unique or primary key constraint on columns "customer_id" */
  BillingAddressCustomerIdKey = 'billing_address_customer_id_key',
  /** unique or primary key constraint on columns "id" */
  BillingAddressPkey = 'billing_address_pkey',
}

/** input type for inserting data into table "billing_address" */
export type Billing_Address_Insert_Input = {
  address_line_1?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  ddn_invoice_billing_address?: InputMaybe<Ddn_Invoice_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_billing_address?: InputMaybe<Invoice_Arr_Rel_Insert_Input>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_updated_to_stripe?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  pin_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Billing_Address_Max_Fields = {
  __typename?: 'billing_address_max_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_updated_to_stripe?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  pin_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Billing_Address_Min_Fields = {
  __typename?: 'billing_address_min_fields';
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_updated_to_stripe?: Maybe<Scalars['timestamptz']>;
  name?: Maybe<Scalars['String']>;
  pin_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "billing_address" */
export type Billing_Address_Mutation_Response = {
  __typename?: 'billing_address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Billing_Address>;
};

/** input type for inserting object relation for remote table "billing_address" */
export type Billing_Address_Obj_Rel_Insert_Input = {
  data: Billing_Address_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Billing_Address_On_Conflict>;
};

/** on_conflict condition type for table "billing_address" */
export type Billing_Address_On_Conflict = {
  constraint: Billing_Address_Constraint;
  update_columns?: Array<Billing_Address_Update_Column>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};

/** Ordering options when selecting data from "billing_address". */
export type Billing_Address_Order_By = {
  address_line_1?: InputMaybe<Order_By>;
  address_line_2?: InputMaybe<Order_By>;
  city?: InputMaybe<Order_By>;
  country?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  ddn_invoice_billing_address_aggregate?: InputMaybe<
    Ddn_Invoice_Aggregate_Order_By
  >;
  id?: InputMaybe<Order_By>;
  invoice_billing_address_aggregate?: InputMaybe<Invoice_Aggregate_Order_By>;
  is_active?: InputMaybe<Order_By>;
  last_updated_to_stripe?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pin_code?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: billing_address */
export type Billing_Address_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "billing_address" */
export enum Billing_Address_Select_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastUpdatedToStripe = 'last_updated_to_stripe',
  /** column name */
  Name = 'name',
  /** column name */
  PinCode = 'pin_code',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "billing_address" */
export type Billing_Address_Set_Input = {
  address_line_1?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_updated_to_stripe?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  pin_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "billing_address" */
export type Billing_Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Billing_Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Billing_Address_Stream_Cursor_Value_Input = {
  address_line_1?: InputMaybe<Scalars['String']>;
  address_line_2?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_updated_to_stripe?: InputMaybe<Scalars['timestamptz']>;
  name?: InputMaybe<Scalars['String']>;
  pin_code?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "billing_address" */
export enum Billing_Address_Update_Column {
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  City = 'city',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastUpdatedToStripe = 'last_updated_to_stripe',
  /** column name */
  Name = 'name',
  /** column name */
  PinCode = 'pin_code',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Billing_Address_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Billing_Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Billing_Address_Bool_Exp;
};

/** Boolean expression to compare columns of type "bytea". All fields are combined with logical 'AND'. */
export type Bytea_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bytea']>;
  _gt?: InputMaybe<Scalars['bytea']>;
  _gte?: InputMaybe<Scalars['bytea']>;
  _in?: InputMaybe<Array<Scalars['bytea']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bytea']>;
  _lte?: InputMaybe<Scalars['bytea']>;
  _neq?: InputMaybe<Scalars['bytea']>;
  _nin?: InputMaybe<Array<Scalars['bytea']>>;
};

/** columns and relationships of "central_cloud" */
export type Central_Cloud = {
  __typename?: 'central_cloud';
  cloud: Cloud_Enum;
  created_at: Scalars['timestamptz'];
  infra_status: Infra_Status_Enum;
  input_variables: Scalars['jsonb'];
  metrics_fqdn?: Maybe<Scalars['String']>;
  output_variables?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "central_cloud" */
export type Central_CloudInput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "central_cloud" */
export type Central_CloudOutput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "central_cloud" */
export type Central_Cloud_Aggregate = {
  __typename?: 'central_cloud_aggregate';
  aggregate?: Maybe<Central_Cloud_Aggregate_Fields>;
  nodes: Array<Central_Cloud>;
};

/** aggregate fields of "central_cloud" */
export type Central_Cloud_Aggregate_Fields = {
  __typename?: 'central_cloud_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Central_Cloud_Max_Fields>;
  min?: Maybe<Central_Cloud_Min_Fields>;
};

/** aggregate fields of "central_cloud" */
export type Central_Cloud_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Central_Cloud_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Central_Cloud_Append_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "central_cloud". All fields are combined with a logical 'AND'. */
export type Central_Cloud_Bool_Exp = {
  _and?: InputMaybe<Array<Central_Cloud_Bool_Exp>>;
  _not?: InputMaybe<Central_Cloud_Bool_Exp>;
  _or?: InputMaybe<Array<Central_Cloud_Bool_Exp>>;
  cloud?: InputMaybe<Cloud_Enum_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  infra_status?: InputMaybe<Infra_Status_Enum_Comparison_Exp>;
  input_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  metrics_fqdn?: InputMaybe<String_Comparison_Exp>;
  output_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "central_cloud" */
export enum Central_Cloud_Constraint {
  /** unique or primary key constraint on columns "cloud" */
  CentralCloudPkey = 'central_cloud_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Central_Cloud_Delete_At_Path_Input = {
  input_variables?: InputMaybe<Array<Scalars['String']>>;
  output_variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Central_Cloud_Delete_Elem_Input = {
  input_variables?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Central_Cloud_Delete_Key_Input = {
  input_variables?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "central_cloud" */
export type Central_Cloud_Insert_Input = {
  cloud?: InputMaybe<Cloud_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  metrics_fqdn?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Central_Cloud_Max_Fields = {
  __typename?: 'central_cloud_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  metrics_fqdn?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Central_Cloud_Min_Fields = {
  __typename?: 'central_cloud_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  metrics_fqdn?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "central_cloud" */
export type Central_Cloud_Mutation_Response = {
  __typename?: 'central_cloud_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Central_Cloud>;
};

/** input type for inserting object relation for remote table "central_cloud" */
export type Central_Cloud_Obj_Rel_Insert_Input = {
  data: Central_Cloud_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Central_Cloud_On_Conflict>;
};

/** on_conflict condition type for table "central_cloud" */
export type Central_Cloud_On_Conflict = {
  constraint: Central_Cloud_Constraint;
  update_columns?: Array<Central_Cloud_Update_Column>;
  where?: InputMaybe<Central_Cloud_Bool_Exp>;
};

/** Ordering options when selecting data from "central_cloud". */
export type Central_Cloud_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  infra_status?: InputMaybe<Order_By>;
  input_variables?: InputMaybe<Order_By>;
  metrics_fqdn?: InputMaybe<Order_By>;
  output_variables?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: central_cloud */
export type Central_Cloud_Pk_Columns_Input = {
  cloud: Cloud_Enum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Central_Cloud_Prepend_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "central_cloud" */
export enum Central_Cloud_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  MetricsFqdn = 'metrics_fqdn',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "central_cloud" */
export type Central_Cloud_Set_Input = {
  cloud?: InputMaybe<Cloud_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  metrics_fqdn?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "central_cloud" */
export type Central_Cloud_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Central_Cloud_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Central_Cloud_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Cloud_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  metrics_fqdn?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "central_cloud" */
export enum Central_Cloud_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  MetricsFqdn = 'metrics_fqdn',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Central_Cloud_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Central_Cloud_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Central_Cloud_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Central_Cloud_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Central_Cloud_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Central_Cloud_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Central_Cloud_Set_Input>;
  /** filter the rows which have to be updated */
  where: Central_Cloud_Bool_Exp;
};

/** Request to change user email */
export type Change_Email_Request = {
  __typename?: 'change_email_request';
  id: Scalars['uuid'];
  key: Scalars['String'];
  new_email: Scalars['String'];
  old_email: Scalars['String'];
  resend_count: Scalars['Int'];
  status: Scalars['String'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['uuid'];
  verified: Scalars['Boolean'];
};

/** aggregated selection of "change_email_request" */
export type Change_Email_Request_Aggregate = {
  __typename?: 'change_email_request_aggregate';
  aggregate?: Maybe<Change_Email_Request_Aggregate_Fields>;
  nodes: Array<Change_Email_Request>;
};

/** aggregate fields of "change_email_request" */
export type Change_Email_Request_Aggregate_Fields = {
  __typename?: 'change_email_request_aggregate_fields';
  avg?: Maybe<Change_Email_Request_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Change_Email_Request_Max_Fields>;
  min?: Maybe<Change_Email_Request_Min_Fields>;
  stddev?: Maybe<Change_Email_Request_Stddev_Fields>;
  stddev_pop?: Maybe<Change_Email_Request_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Change_Email_Request_Stddev_Samp_Fields>;
  sum?: Maybe<Change_Email_Request_Sum_Fields>;
  var_pop?: Maybe<Change_Email_Request_Var_Pop_Fields>;
  var_samp?: Maybe<Change_Email_Request_Var_Samp_Fields>;
  variance?: Maybe<Change_Email_Request_Variance_Fields>;
};

/** aggregate fields of "change_email_request" */
export type Change_Email_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Change_Email_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Change_Email_Request_Avg_Fields = {
  __typename?: 'change_email_request_avg_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "change_email_request". All fields are combined with a logical 'AND'. */
export type Change_Email_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Change_Email_Request_Bool_Exp>>;
  _not?: InputMaybe<Change_Email_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Change_Email_Request_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  new_email?: InputMaybe<String_Comparison_Exp>;
  old_email?: InputMaybe<String_Comparison_Exp>;
  resend_count?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  verified?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "change_email_request" */
export enum Change_Email_Request_Constraint {
  /** unique or primary key constraint on columns "key" */
  ChangeEmailRequestKeyKey = 'change_email_request_key_key',
  /** unique or primary key constraint on columns "id" */
  ChangeEmailRequestPkey = 'change_email_request_pkey',
}

/** input type for incrementing numeric columns in table "change_email_request" */
export type Change_Email_Request_Inc_Input = {
  resend_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "change_email_request" */
export type Change_Email_Request_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  new_email?: InputMaybe<Scalars['String']>;
  old_email?: InputMaybe<Scalars['String']>;
  resend_count?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Change_Email_Request_Max_Fields = {
  __typename?: 'change_email_request_max_fields';
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  new_email?: Maybe<Scalars['String']>;
  old_email?: Maybe<Scalars['String']>;
  resend_count?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Change_Email_Request_Min_Fields = {
  __typename?: 'change_email_request_min_fields';
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  new_email?: Maybe<Scalars['String']>;
  old_email?: Maybe<Scalars['String']>;
  resend_count?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "change_email_request" */
export type Change_Email_Request_Mutation_Response = {
  __typename?: 'change_email_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Change_Email_Request>;
};

/** on_conflict condition type for table "change_email_request" */
export type Change_Email_Request_On_Conflict = {
  constraint: Change_Email_Request_Constraint;
  update_columns?: Array<Change_Email_Request_Update_Column>;
  where?: InputMaybe<Change_Email_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "change_email_request". */
export type Change_Email_Request_Order_By = {
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  new_email?: InputMaybe<Order_By>;
  old_email?: InputMaybe<Order_By>;
  resend_count?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  verified?: InputMaybe<Order_By>;
};

/** primary key columns input for table: change_email_request */
export type Change_Email_Request_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "change_email_request" */
export enum Change_Email_Request_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OldEmail = 'old_email',
  /** column name */
  ResendCount = 'resend_count',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Verified = 'verified',
}

/** input type for updating data in table "change_email_request" */
export type Change_Email_Request_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  new_email?: InputMaybe<Scalars['String']>;
  old_email?: InputMaybe<Scalars['String']>;
  resend_count?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Change_Email_Request_Stddev_Fields = {
  __typename?: 'change_email_request_stddev_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Change_Email_Request_Stddev_Pop_Fields = {
  __typename?: 'change_email_request_stddev_pop_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Change_Email_Request_Stddev_Samp_Fields = {
  __typename?: 'change_email_request_stddev_samp_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "change_email_request" */
export type Change_Email_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Change_Email_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Change_Email_Request_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  new_email?: InputMaybe<Scalars['String']>;
  old_email?: InputMaybe<Scalars['String']>;
  resend_count?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  verified?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Change_Email_Request_Sum_Fields = {
  __typename?: 'change_email_request_sum_fields';
  resend_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "change_email_request" */
export enum Change_Email_Request_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Key = 'key',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OldEmail = 'old_email',
  /** column name */
  ResendCount = 'resend_count',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Verified = 'verified',
}

export type Change_Email_Request_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Change_Email_Request_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Change_Email_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Change_Email_Request_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Change_Email_Request_Var_Pop_Fields = {
  __typename?: 'change_email_request_var_pop_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Change_Email_Request_Var_Samp_Fields = {
  __typename?: 'change_email_request_var_samp_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Change_Email_Request_Variance_Fields = {
  __typename?: 'change_email_request_variance_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** Connects branch(es)/PR to a particular deployment pool */
export type Cicd_Deployment_Pipeline_Config = {
  __typename?: 'cicd_deployment_pipeline_config';
  approval_required?: Maybe<Scalars['Boolean']>;
  branch_regexp?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An object relationship */
  pool?: Maybe<Cicd_Deployment_Pool>;
  pool_name: Scalars['String'];
  pull_request_id?: Maybe<Scalars['String']>;
  tag_regexp?: Maybe<Scalars['String']>;
};

/** aggregated selection of "cicd.deployment_pipeline_config" */
export type Cicd_Deployment_Pipeline_Config_Aggregate = {
  __typename?: 'cicd_deployment_pipeline_config_aggregate';
  aggregate?: Maybe<Cicd_Deployment_Pipeline_Config_Aggregate_Fields>;
  nodes: Array<Cicd_Deployment_Pipeline_Config>;
};

/** aggregate fields of "cicd.deployment_pipeline_config" */
export type Cicd_Deployment_Pipeline_Config_Aggregate_Fields = {
  __typename?: 'cicd_deployment_pipeline_config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cicd_Deployment_Pipeline_Config_Max_Fields>;
  min?: Maybe<Cicd_Deployment_Pipeline_Config_Min_Fields>;
};

/** aggregate fields of "cicd.deployment_pipeline_config" */
export type Cicd_Deployment_Pipeline_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cicd_Deployment_Pipeline_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cicd.deployment_pipeline_config". All fields are combined with a logical 'AND'. */
export type Cicd_Deployment_Pipeline_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Cicd_Deployment_Pipeline_Config_Bool_Exp>>;
  _not?: InputMaybe<Cicd_Deployment_Pipeline_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Cicd_Deployment_Pipeline_Config_Bool_Exp>>;
  approval_required?: InputMaybe<Boolean_Comparison_Exp>;
  branch_regexp?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  pool?: InputMaybe<Cicd_Deployment_Pool_Bool_Exp>;
  pool_name?: InputMaybe<String_Comparison_Exp>;
  pull_request_id?: InputMaybe<String_Comparison_Exp>;
  tag_regexp?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "cicd.deployment_pipeline_config" */
export enum Cicd_Deployment_Pipeline_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  DeploymentPipelineConfigPkey = 'deployment_pipeline_config_pkey',
}

/** input type for inserting data into table "cicd.deployment_pipeline_config" */
export type Cicd_Deployment_Pipeline_Config_Insert_Input = {
  approval_required?: InputMaybe<Scalars['Boolean']>;
  branch_regexp?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  pool?: InputMaybe<Cicd_Deployment_Pool_Obj_Rel_Insert_Input>;
  pool_name?: InputMaybe<Scalars['String']>;
  pull_request_id?: InputMaybe<Scalars['String']>;
  tag_regexp?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Cicd_Deployment_Pipeline_Config_Max_Fields = {
  __typename?: 'cicd_deployment_pipeline_config_max_fields';
  branch_regexp?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  pool_name?: Maybe<Scalars['String']>;
  pull_request_id?: Maybe<Scalars['String']>;
  tag_regexp?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Cicd_Deployment_Pipeline_Config_Min_Fields = {
  __typename?: 'cicd_deployment_pipeline_config_min_fields';
  branch_regexp?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  pool_name?: Maybe<Scalars['String']>;
  pull_request_id?: Maybe<Scalars['String']>;
  tag_regexp?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "cicd.deployment_pipeline_config" */
export type Cicd_Deployment_Pipeline_Config_Mutation_Response = {
  __typename?: 'cicd_deployment_pipeline_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cicd_Deployment_Pipeline_Config>;
};

/** on_conflict condition type for table "cicd.deployment_pipeline_config" */
export type Cicd_Deployment_Pipeline_Config_On_Conflict = {
  constraint: Cicd_Deployment_Pipeline_Config_Constraint;
  update_columns?: Array<Cicd_Deployment_Pipeline_Config_Update_Column>;
  where?: InputMaybe<Cicd_Deployment_Pipeline_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "cicd.deployment_pipeline_config". */
export type Cicd_Deployment_Pipeline_Config_Order_By = {
  approval_required?: InputMaybe<Order_By>;
  branch_regexp?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pool?: InputMaybe<Cicd_Deployment_Pool_Order_By>;
  pool_name?: InputMaybe<Order_By>;
  pull_request_id?: InputMaybe<Order_By>;
  tag_regexp?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cicd.deployment_pipeline_config */
export type Cicd_Deployment_Pipeline_Config_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "cicd.deployment_pipeline_config" */
export enum Cicd_Deployment_Pipeline_Config_Select_Column {
  /** column name */
  ApprovalRequired = 'approval_required',
  /** column name */
  BranchRegexp = 'branch_regexp',
  /** column name */
  Id = 'id',
  /** column name */
  PoolName = 'pool_name',
  /** column name */
  PullRequestId = 'pull_request_id',
  /** column name */
  TagRegexp = 'tag_regexp',
}

/** input type for updating data in table "cicd.deployment_pipeline_config" */
export type Cicd_Deployment_Pipeline_Config_Set_Input = {
  approval_required?: InputMaybe<Scalars['Boolean']>;
  branch_regexp?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  pool_name?: InputMaybe<Scalars['String']>;
  pull_request_id?: InputMaybe<Scalars['String']>;
  tag_regexp?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "cicd_deployment_pipeline_config" */
export type Cicd_Deployment_Pipeline_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cicd_Deployment_Pipeline_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cicd_Deployment_Pipeline_Config_Stream_Cursor_Value_Input = {
  approval_required?: InputMaybe<Scalars['Boolean']>;
  branch_regexp?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  pool_name?: InputMaybe<Scalars['String']>;
  pull_request_id?: InputMaybe<Scalars['String']>;
  tag_regexp?: InputMaybe<Scalars['String']>;
};

/** update columns of table "cicd.deployment_pipeline_config" */
export enum Cicd_Deployment_Pipeline_Config_Update_Column {
  /** column name */
  ApprovalRequired = 'approval_required',
  /** column name */
  BranchRegexp = 'branch_regexp',
  /** column name */
  Id = 'id',
  /** column name */
  PoolName = 'pool_name',
  /** column name */
  PullRequestId = 'pull_request_id',
  /** column name */
  TagRegexp = 'tag_regexp',
}

export type Cicd_Deployment_Pipeline_Config_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cicd_Deployment_Pipeline_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cicd_Deployment_Pipeline_Config_Bool_Exp;
};

/** columns and relationships of "cicd.deployment_pool" */
export type Cicd_Deployment_Pool = {
  __typename?: 'cicd_deployment_pool';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  is_exclusive_pool?: Maybe<Scalars['Boolean']>;
  /** An array relationship */
  members: Array<Cicd_Deployment_Pool_Member>;
  /** An aggregate relationship */
  members_aggregate: Cicd_Deployment_Pool_Member_Aggregate;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "cicd.deployment_pool" */
export type Cicd_Deployment_PoolMembersArgs = {
  distinct_on?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
};

/** columns and relationships of "cicd.deployment_pool" */
export type Cicd_Deployment_PoolMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
};

/** aggregated selection of "cicd.deployment_pool" */
export type Cicd_Deployment_Pool_Aggregate = {
  __typename?: 'cicd_deployment_pool_aggregate';
  aggregate?: Maybe<Cicd_Deployment_Pool_Aggregate_Fields>;
  nodes: Array<Cicd_Deployment_Pool>;
};

/** aggregate fields of "cicd.deployment_pool" */
export type Cicd_Deployment_Pool_Aggregate_Fields = {
  __typename?: 'cicd_deployment_pool_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cicd_Deployment_Pool_Max_Fields>;
  min?: Maybe<Cicd_Deployment_Pool_Min_Fields>;
};

/** aggregate fields of "cicd.deployment_pool" */
export type Cicd_Deployment_Pool_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cicd_Deployment_Pool_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cicd.deployment_pool". All fields are combined with a logical 'AND'. */
export type Cicd_Deployment_Pool_Bool_Exp = {
  _and?: InputMaybe<Array<Cicd_Deployment_Pool_Bool_Exp>>;
  _not?: InputMaybe<Cicd_Deployment_Pool_Bool_Exp>;
  _or?: InputMaybe<Array<Cicd_Deployment_Pool_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  is_exclusive_pool?: InputMaybe<Boolean_Comparison_Exp>;
  members?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
  members_aggregate?: InputMaybe<
    Cicd_Deployment_Pool_Member_Aggregate_Bool_Exp
  >;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "cicd.deployment_pool" */
export type Cicd_Deployment_Pool_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  is_exclusive_pool?: InputMaybe<Scalars['Boolean']>;
  members?: InputMaybe<Cicd_Deployment_Pool_Member_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Cicd_Deployment_Pool_Max_Fields = {
  __typename?: 'cicd_deployment_pool_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "cicd.deployment_pool_member" */
export type Cicd_Deployment_Pool_Member = {
  __typename?: 'cicd_deployment_pool_member';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  pool?: Maybe<Cicd_Deployment_Pool>;
  pool_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  tenant_group?: Maybe<Tenant_Group>;
  tenant_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "cicd.deployment_pool_member" */
export type Cicd_Deployment_Pool_Member_Aggregate = {
  __typename?: 'cicd_deployment_pool_member_aggregate';
  aggregate?: Maybe<Cicd_Deployment_Pool_Member_Aggregate_Fields>;
  nodes: Array<Cicd_Deployment_Pool_Member>;
};

export type Cicd_Deployment_Pool_Member_Aggregate_Bool_Exp = {
  count?: InputMaybe<Cicd_Deployment_Pool_Member_Aggregate_Bool_Exp_Count>;
};

export type Cicd_Deployment_Pool_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "cicd.deployment_pool_member" */
export type Cicd_Deployment_Pool_Member_Aggregate_Fields = {
  __typename?: 'cicd_deployment_pool_member_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cicd_Deployment_Pool_Member_Max_Fields>;
  min?: Maybe<Cicd_Deployment_Pool_Member_Min_Fields>;
};

/** aggregate fields of "cicd.deployment_pool_member" */
export type Cicd_Deployment_Pool_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "cicd.deployment_pool_member" */
export type Cicd_Deployment_Pool_Member_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Cicd_Deployment_Pool_Member_Max_Order_By>;
  min?: InputMaybe<Cicd_Deployment_Pool_Member_Min_Order_By>;
};

/** input type for inserting array relation for remote table "cicd.deployment_pool_member" */
export type Cicd_Deployment_Pool_Member_Arr_Rel_Insert_Input = {
  data: Array<Cicd_Deployment_Pool_Member_Insert_Input>;
};

/** Boolean expression to filter rows from the table "cicd.deployment_pool_member". All fields are combined with a logical 'AND'. */
export type Cicd_Deployment_Pool_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Bool_Exp>>;
  _not?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  pool?: InputMaybe<Cicd_Deployment_Pool_Bool_Exp>;
  pool_name?: InputMaybe<String_Comparison_Exp>;
  tenant_group?: InputMaybe<Tenant_Group_Bool_Exp>;
  tenant_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "cicd.deployment_pool_member" */
export type Cicd_Deployment_Pool_Member_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  pool?: InputMaybe<Cicd_Deployment_Pool_Obj_Rel_Insert_Input>;
  pool_name?: InputMaybe<Scalars['String']>;
  tenant_group?: InputMaybe<Tenant_Group_Obj_Rel_Insert_Input>;
  tenant_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Cicd_Deployment_Pool_Member_Max_Fields = {
  __typename?: 'cicd_deployment_pool_member_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  pool_name?: Maybe<Scalars['String']>;
  tenant_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "cicd.deployment_pool_member" */
export type Cicd_Deployment_Pool_Member_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  pool_name?: InputMaybe<Order_By>;
  tenant_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Cicd_Deployment_Pool_Member_Min_Fields = {
  __typename?: 'cicd_deployment_pool_member_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  pool_name?: Maybe<Scalars['String']>;
  tenant_group_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "cicd.deployment_pool_member" */
export type Cicd_Deployment_Pool_Member_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  pool_name?: InputMaybe<Order_By>;
  tenant_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "cicd.deployment_pool_member" */
export type Cicd_Deployment_Pool_Member_Mutation_Response = {
  __typename?: 'cicd_deployment_pool_member_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cicd_Deployment_Pool_Member>;
};

/** Ordering options when selecting data from "cicd.deployment_pool_member". */
export type Cicd_Deployment_Pool_Member_Order_By = {
  created_at?: InputMaybe<Order_By>;
  pool?: InputMaybe<Cicd_Deployment_Pool_Order_By>;
  pool_name?: InputMaybe<Order_By>;
  tenant_group?: InputMaybe<Tenant_Group_Order_By>;
  tenant_group_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "cicd.deployment_pool_member" */
export enum Cicd_Deployment_Pool_Member_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PoolName = 'pool_name',
  /** column name */
  TenantGroupId = 'tenant_group_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "cicd.deployment_pool_member" */
export type Cicd_Deployment_Pool_Member_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  pool_name?: InputMaybe<Scalars['String']>;
  tenant_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "cicd_deployment_pool_member" */
export type Cicd_Deployment_Pool_Member_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cicd_Deployment_Pool_Member_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cicd_Deployment_Pool_Member_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  pool_name?: InputMaybe<Scalars['String']>;
  tenant_group_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Cicd_Deployment_Pool_Member_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cicd_Deployment_Pool_Member_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cicd_Deployment_Pool_Member_Bool_Exp;
};

/** aggregate min on columns */
export type Cicd_Deployment_Pool_Min_Fields = {
  __typename?: 'cicd_deployment_pool_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "cicd.deployment_pool" */
export type Cicd_Deployment_Pool_Mutation_Response = {
  __typename?: 'cicd_deployment_pool_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cicd_Deployment_Pool>;
};

/** input type for inserting object relation for remote table "cicd.deployment_pool" */
export type Cicd_Deployment_Pool_Obj_Rel_Insert_Input = {
  data: Cicd_Deployment_Pool_Insert_Input;
};

/** Ordering options when selecting data from "cicd.deployment_pool". */
export type Cicd_Deployment_Pool_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  is_exclusive_pool?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<
    Cicd_Deployment_Pool_Member_Aggregate_Order_By
  >;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "cicd.deployment_pool" */
export enum Cicd_Deployment_Pool_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  IsExclusivePool = 'is_exclusive_pool',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "cicd.deployment_pool" */
export type Cicd_Deployment_Pool_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  is_exclusive_pool?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "cicd_deployment_pool" */
export type Cicd_Deployment_Pool_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cicd_Deployment_Pool_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cicd_Deployment_Pool_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  is_exclusive_pool?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

export type Cicd_Deployment_Pool_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cicd_Deployment_Pool_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cicd_Deployment_Pool_Bool_Exp;
};

export type Cicd_Get_Matching_Pipeline_Config_Args = {
  branch?: InputMaybe<Scalars['String']>;
  pull_request?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']>;
  _gt?: InputMaybe<Scalars['citext']>;
  _gte?: InputMaybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']>;
  _in?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']>;
  _lt?: InputMaybe<Scalars['citext']>;
  _lte?: InputMaybe<Scalars['citext']>;
  _neq?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']>;
  _nin?: InputMaybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']>;
};

/** column ordering options */
export enum Clickhouse_Order_By {
  /** in ascending order */
  Asc = 'asc',
  /** in descending order */
  Desc = 'desc',
}

export type ClientSecretInfo = {
  __typename?: 'clientSecretInfo';
  client_secret: Scalars['String'];
};

/** Hasura enum to define cloud provider. This enum is used in the tenant table. */
export type Cloud = {
  __typename?: 'cloud';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "cloud" */
export type Cloud_Aggregate = {
  __typename?: 'cloud_aggregate';
  aggregate?: Maybe<Cloud_Aggregate_Fields>;
  nodes: Array<Cloud>;
};

/** aggregate fields of "cloud" */
export type Cloud_Aggregate_Fields = {
  __typename?: 'cloud_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cloud_Max_Fields>;
  min?: Maybe<Cloud_Min_Fields>;
};

/** aggregate fields of "cloud" */
export type Cloud_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cloud_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cloud". All fields are combined with a logical 'AND'. */
export type Cloud_Bool_Exp = {
  _and?: InputMaybe<Array<Cloud_Bool_Exp>>;
  _not?: InputMaybe<Cloud_Bool_Exp>;
  _or?: InputMaybe<Array<Cloud_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "cloud" */
export enum Cloud_Constraint {
  /** unique or primary key constraint on columns "name" */
  CloudPkey = 'cloud_pkey',
}

export enum Cloud_Enum {
  /** Amazon Web Services */
  Aws = 'aws',
  /** Microsoft Azure */
  Azure = 'azure',
  /** Digital Ocean */
  Do = 'do',
  /** Google Cloud Platform */
  Gcp = 'gcp',
}

/** Boolean expression to compare columns of type "cloud_enum". All fields are combined with logical 'AND'. */
export type Cloud_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Cloud_Enum>;
  _in?: InputMaybe<Array<Cloud_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Cloud_Enum>;
  _nin?: InputMaybe<Array<Cloud_Enum>>;
};

/** input type for inserting data into table "cloud" */
export type Cloud_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Cloud_Max_Fields = {
  __typename?: 'cloud_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** columns and relationships of "cloud_metadata" */
export type Cloud_Metadata = {
  __typename?: 'cloud_metadata';
  /** Get AWS Account ID for Hasura Cloud */
  aws_account_id?: Maybe<Scalars['String']>;
  cloud: Cloud_Enum;
  metadata: Scalars['jsonb'];
};

/** columns and relationships of "cloud_metadata" */
export type Cloud_MetadataMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "cloud_metadata" */
export type Cloud_Metadata_Aggregate = {
  __typename?: 'cloud_metadata_aggregate';
  aggregate?: Maybe<Cloud_Metadata_Aggregate_Fields>;
  nodes: Array<Cloud_Metadata>;
};

/** aggregate fields of "cloud_metadata" */
export type Cloud_Metadata_Aggregate_Fields = {
  __typename?: 'cloud_metadata_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cloud_Metadata_Max_Fields>;
  min?: Maybe<Cloud_Metadata_Min_Fields>;
};

/** aggregate fields of "cloud_metadata" */
export type Cloud_Metadata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cloud_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Cloud_Metadata_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "cloud_metadata". All fields are combined with a logical 'AND'. */
export type Cloud_Metadata_Bool_Exp = {
  _and?: InputMaybe<Array<Cloud_Metadata_Bool_Exp>>;
  _not?: InputMaybe<Cloud_Metadata_Bool_Exp>;
  _or?: InputMaybe<Array<Cloud_Metadata_Bool_Exp>>;
  aws_account_id?: InputMaybe<String_Comparison_Exp>;
  cloud?: InputMaybe<Cloud_Enum_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "cloud_metadata" */
export enum Cloud_Metadata_Constraint {
  /** unique or primary key constraint on columns "cloud" */
  CloudMetadataPkey = 'cloud_metadata_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Cloud_Metadata_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Cloud_Metadata_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Cloud_Metadata_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "cloud_metadata" */
export type Cloud_Metadata_Insert_Input = {
  cloud?: InputMaybe<Cloud_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Cloud_Metadata_Max_Fields = {
  __typename?: 'cloud_metadata_max_fields';
  /** Get AWS Account ID for Hasura Cloud */
  aws_account_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Cloud_Metadata_Min_Fields = {
  __typename?: 'cloud_metadata_min_fields';
  /** Get AWS Account ID for Hasura Cloud */
  aws_account_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "cloud_metadata" */
export type Cloud_Metadata_Mutation_Response = {
  __typename?: 'cloud_metadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cloud_Metadata>;
};

/** on_conflict condition type for table "cloud_metadata" */
export type Cloud_Metadata_On_Conflict = {
  constraint: Cloud_Metadata_Constraint;
  update_columns?: Array<Cloud_Metadata_Update_Column>;
  where?: InputMaybe<Cloud_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "cloud_metadata". */
export type Cloud_Metadata_Order_By = {
  aws_account_id?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cloud_metadata */
export type Cloud_Metadata_Pk_Columns_Input = {
  cloud: Cloud_Enum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Cloud_Metadata_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "cloud_metadata" */
export enum Cloud_Metadata_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Metadata = 'metadata',
}

/** input type for updating data in table "cloud_metadata" */
export type Cloud_Metadata_Set_Input = {
  cloud?: InputMaybe<Cloud_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "cloud_metadata" */
export type Cloud_Metadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cloud_Metadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cloud_Metadata_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Cloud_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "cloud_metadata" */
export enum Cloud_Metadata_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Metadata = 'metadata',
}

export type Cloud_Metadata_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Cloud_Metadata_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Cloud_Metadata_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Cloud_Metadata_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Cloud_Metadata_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Cloud_Metadata_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cloud_Metadata_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cloud_Metadata_Bool_Exp;
};

/** aggregate min on columns */
export type Cloud_Min_Fields = {
  __typename?: 'cloud_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "cloud" */
export type Cloud_Mutation_Response = {
  __typename?: 'cloud_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Cloud>;
};

/** on_conflict condition type for table "cloud" */
export type Cloud_On_Conflict = {
  constraint: Cloud_Constraint;
  update_columns?: Array<Cloud_Update_Column>;
  where?: InputMaybe<Cloud_Bool_Exp>;
};

/** Ordering options when selecting data from "cloud". */
export type Cloud_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: cloud */
export type Cloud_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** columns and relationships of "cloud_regions_by_plan" */
export type Cloud_Regions_By_Plan = {
  __typename?: 'cloud_regions_by_plan';
  cloud?: Maybe<Scalars['String']>;
  infra_status?: Maybe<Scalars['String']>;
  input_variables?: Maybe<Scalars['jsonb']>;
  is_active?: Maybe<Scalars['Boolean']>;
  metrics_fqdn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nat_ip?: Maybe<Scalars['String']>;
  output_variables?: Maybe<Scalars['jsonb']>;
  plan_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  region?: Maybe<Region>;
};

/** columns and relationships of "cloud_regions_by_plan" */
export type Cloud_Regions_By_PlanInput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "cloud_regions_by_plan" */
export type Cloud_Regions_By_PlanOutput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "cloud_regions_by_plan" */
export type Cloud_Regions_By_Plan_Aggregate = {
  __typename?: 'cloud_regions_by_plan_aggregate';
  aggregate?: Maybe<Cloud_Regions_By_Plan_Aggregate_Fields>;
  nodes: Array<Cloud_Regions_By_Plan>;
};

/** aggregate fields of "cloud_regions_by_plan" */
export type Cloud_Regions_By_Plan_Aggregate_Fields = {
  __typename?: 'cloud_regions_by_plan_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Cloud_Regions_By_Plan_Max_Fields>;
  min?: Maybe<Cloud_Regions_By_Plan_Min_Fields>;
};

/** aggregate fields of "cloud_regions_by_plan" */
export type Cloud_Regions_By_Plan_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Cloud_Regions_By_Plan_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "cloud_regions_by_plan". All fields are combined with a logical 'AND'. */
export type Cloud_Regions_By_Plan_Bool_Exp = {
  _and?: InputMaybe<Array<Cloud_Regions_By_Plan_Bool_Exp>>;
  _not?: InputMaybe<Cloud_Regions_By_Plan_Bool_Exp>;
  _or?: InputMaybe<Array<Cloud_Regions_By_Plan_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  infra_status?: InputMaybe<String_Comparison_Exp>;
  input_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  metrics_fqdn?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nat_ip?: InputMaybe<String_Comparison_Exp>;
  output_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  plan_name?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<Region_Bool_Exp>;
};

/** aggregate max on columns */
export type Cloud_Regions_By_Plan_Max_Fields = {
  __typename?: 'cloud_regions_by_plan_max_fields';
  cloud?: Maybe<Scalars['String']>;
  infra_status?: Maybe<Scalars['String']>;
  metrics_fqdn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nat_ip?: Maybe<Scalars['String']>;
  plan_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Cloud_Regions_By_Plan_Min_Fields = {
  __typename?: 'cloud_regions_by_plan_min_fields';
  cloud?: Maybe<Scalars['String']>;
  infra_status?: Maybe<Scalars['String']>;
  metrics_fqdn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nat_ip?: Maybe<Scalars['String']>;
  plan_name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "cloud_regions_by_plan". */
export type Cloud_Regions_By_Plan_Order_By = {
  cloud?: InputMaybe<Order_By>;
  infra_status?: InputMaybe<Order_By>;
  input_variables?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  metrics_fqdn?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nat_ip?: InputMaybe<Order_By>;
  output_variables?: InputMaybe<Order_By>;
  plan_name?: InputMaybe<Order_By>;
  region?: InputMaybe<Region_Order_By>;
};

/** select columns of table "cloud_regions_by_plan" */
export enum Cloud_Regions_By_Plan_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MetricsFqdn = 'metrics_fqdn',
  /** column name */
  Name = 'name',
  /** column name */
  NatIp = 'nat_ip',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  PlanName = 'plan_name',
}

/** Streaming cursor of the table "cloud_regions_by_plan" */
export type Cloud_Regions_By_Plan_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cloud_Regions_By_Plan_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cloud_Regions_By_Plan_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  infra_status?: InputMaybe<Scalars['String']>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  metrics_fqdn?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nat_ip?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  plan_name?: InputMaybe<Scalars['String']>;
};

/** select columns of table "cloud" */
export enum Cloud_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "cloud" */
export type Cloud_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "cloud" */
export type Cloud_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Cloud_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Cloud_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "cloud" */
export enum Cloud_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type Cloud_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Cloud_Set_Input>;
  /** filter the rows which have to be updated */
  where: Cloud_Bool_Exp;
};

export type Compute_Project_Agg_Db_Usage_User_Args = {
  from_time?: InputMaybe<Scalars['timestamptz']>;
  to_time?: InputMaybe<Scalars['timestamptz']>;
  user_ids?: InputMaybe<Scalars['_uuid']>;
};

export type Compute_Project_Agg_Usage_Args = {
  from_time?: InputMaybe<Scalars['timestamptz']>;
  project_ids?: InputMaybe<Scalars['_uuid']>;
  to_time?: InputMaybe<Scalars['timestamptz']>;
};

export type Compute_Project_Agg_Usage_Prometheus_Args = {
  from_time?: InputMaybe<Scalars['timestamptz']>;
  project_ids?: InputMaybe<Scalars['_uuid']>;
  to_time?: InputMaybe<Scalars['timestamptz']>;
};

export type Compute_Project_Agg_Usage_Prometheus_User_Args = {
  from_time?: InputMaybe<Scalars['timestamptz']>;
  to_time?: InputMaybe<Scalars['timestamptz']>;
  user_ids?: InputMaybe<Scalars['_uuid']>;
};

export type Compute_Project_Agg_Usage_User_Args = {
  from_time?: InputMaybe<Scalars['timestamptz']>;
  to_time?: InputMaybe<Scalars['timestamptz']>;
  user_ids?: InputMaybe<Scalars['_uuid']>;
};

/** capture compute unit configuration per cluster and allows for sharing between cluster. Compute units are adhered to by the node pools or cluster autoscaling configuration */
export type Compute_Unit_Config = {
  __typename?: 'compute_unit_config';
  /** An object relationship */
  cluster?: Maybe<Hasura_Cluster>;
  config?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  usage: Array<Dedicated_Cloud_Bills>;
  /** An aggregate relationship */
  usage_aggregate: Dedicated_Cloud_Bills_Aggregate;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['uuid'];
};

/** capture compute unit configuration per cluster and allows for sharing between cluster. Compute units are adhered to by the node pools or cluster autoscaling configuration */
export type Compute_Unit_ConfigConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** capture compute unit configuration per cluster and allows for sharing between cluster. Compute units are adhered to by the node pools or cluster autoscaling configuration */
export type Compute_Unit_ConfigUsageArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
};

/** capture compute unit configuration per cluster and allows for sharing between cluster. Compute units are adhered to by the node pools or cluster autoscaling configuration */
export type Compute_Unit_ConfigUsage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
};

/** aggregated selection of "compute_unit_config" */
export type Compute_Unit_Config_Aggregate = {
  __typename?: 'compute_unit_config_aggregate';
  aggregate?: Maybe<Compute_Unit_Config_Aggregate_Fields>;
  nodes: Array<Compute_Unit_Config>;
};

/** aggregate fields of "compute_unit_config" */
export type Compute_Unit_Config_Aggregate_Fields = {
  __typename?: 'compute_unit_config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Compute_Unit_Config_Max_Fields>;
  min?: Maybe<Compute_Unit_Config_Min_Fields>;
};

/** aggregate fields of "compute_unit_config" */
export type Compute_Unit_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Compute_Unit_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Compute_Unit_Config_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "compute_unit_config". All fields are combined with a logical 'AND'. */
export type Compute_Unit_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Compute_Unit_Config_Bool_Exp>>;
  _not?: InputMaybe<Compute_Unit_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Compute_Unit_Config_Bool_Exp>>;
  cluster?: InputMaybe<Hasura_Cluster_Bool_Exp>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  usage?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
  usage_aggregate?: InputMaybe<Dedicated_Cloud_Bills_Aggregate_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "compute_unit_config" */
export enum Compute_Unit_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  ComputeUnitConfigPkey = 'compute_unit_config_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Compute_Unit_Config_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Compute_Unit_Config_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Compute_Unit_Config_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "compute_unit_config" */
export type Compute_Unit_Config_Insert_Input = {
  cluster?: InputMaybe<Hasura_Cluster_Obj_Rel_Insert_Input>;
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  usage?: InputMaybe<Dedicated_Cloud_Bills_Arr_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Compute_Unit_Config_Max_Fields = {
  __typename?: 'compute_unit_config_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Compute_Unit_Config_Min_Fields = {
  __typename?: 'compute_unit_config_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "compute_unit_config" */
export type Compute_Unit_Config_Mutation_Response = {
  __typename?: 'compute_unit_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Compute_Unit_Config>;
};

/** input type for inserting object relation for remote table "compute_unit_config" */
export type Compute_Unit_Config_Obj_Rel_Insert_Input = {
  data: Compute_Unit_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Compute_Unit_Config_On_Conflict>;
};

/** on_conflict condition type for table "compute_unit_config" */
export type Compute_Unit_Config_On_Conflict = {
  constraint: Compute_Unit_Config_Constraint;
  update_columns?: Array<Compute_Unit_Config_Update_Column>;
  where?: InputMaybe<Compute_Unit_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "compute_unit_config". */
export type Compute_Unit_Config_Order_By = {
  cluster?: InputMaybe<Hasura_Cluster_Order_By>;
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  usage_aggregate?: InputMaybe<Dedicated_Cloud_Bills_Aggregate_Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: compute_unit_config */
export type Compute_Unit_Config_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Compute_Unit_Config_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "compute_unit_config" */
export enum Compute_Unit_Config_Select_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "compute_unit_config" */
export type Compute_Unit_Config_Set_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "compute_unit_config" */
export type Compute_Unit_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Compute_Unit_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Compute_Unit_Config_Stream_Cursor_Value_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "compute_unit_config" */
export enum Compute_Unit_Config_Update_Column {
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id',
}

export type Compute_Unit_Config_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Compute_Unit_Config_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Compute_Unit_Config_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Compute_Unit_Config_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Compute_Unit_Config_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Compute_Unit_Config_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Compute_Unit_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Compute_Unit_Config_Bool_Exp;
};

/** Metadata for tenant config in vault */
export type Config = {
  __typename?: 'config';
  hash: Scalars['String'];
  /** An array relationship */
  status: Array<Config_Status>;
  /** An aggregate relationship */
  status_aggregate: Config_Status_Aggregate;
  /** An object relationship */
  tenant: Tenant;
  tenant_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  vault_path: Scalars['String'];
};

/** Metadata for tenant config in vault */
export type ConfigStatusArgs = {
  distinct_on?: InputMaybe<Array<Config_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Config_Status_Order_By>>;
  where?: InputMaybe<Config_Status_Bool_Exp>;
};

/** Metadata for tenant config in vault */
export type ConfigStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Config_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Config_Status_Order_By>>;
  where?: InputMaybe<Config_Status_Bool_Exp>;
};

/** aggregated selection of "config" */
export type Config_Aggregate = {
  __typename?: 'config_aggregate';
  aggregate?: Maybe<Config_Aggregate_Fields>;
  nodes: Array<Config>;
};

/** aggregate fields of "config" */
export type Config_Aggregate_Fields = {
  __typename?: 'config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Config_Max_Fields>;
  min?: Maybe<Config_Min_Fields>;
};

/** aggregate fields of "config" */
export type Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "config". All fields are combined with a logical 'AND'. */
export type Config_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Bool_Exp>>;
  _not?: InputMaybe<Config_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Bool_Exp>>;
  hash?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Config_Status_Bool_Exp>;
  status_aggregate?: InputMaybe<Config_Status_Aggregate_Bool_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vault_path?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config" */
export enum Config_Constraint {
  /** unique or primary key constraint on columns "tenant_id" */
  ConfigPkey = 'config_pkey',
  /** unique or primary key constraint on columns "tenant_id" */
  ConfigTenantIdKey = 'config_tenant_id_key',
}

/** input type for inserting data into table "config" */
export type Config_Insert_Input = {
  hash?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Config_Status_Arr_Rel_Insert_Input>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vault_path?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Config_Max_Fields = {
  __typename?: 'config_max_fields';
  hash?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vault_path?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Config_Min_Fields = {
  __typename?: 'config_min_fields';
  hash?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vault_path?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "config" */
export type Config_Mutation_Response = {
  __typename?: 'config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Config>;
};

/** input type for inserting object relation for remote table "config" */
export type Config_Obj_Rel_Insert_Input = {
  data: Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_On_Conflict>;
};

/** on_conflict condition type for table "config" */
export type Config_On_Conflict = {
  constraint: Config_Constraint;
  update_columns?: Array<Config_Update_Column>;
  where?: InputMaybe<Config_Bool_Exp>;
};

/** Ordering options when selecting data from "config". */
export type Config_Order_By = {
  hash?: InputMaybe<Order_By>;
  status_aggregate?: InputMaybe<Config_Status_Aggregate_Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vault_path?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config */
export type Config_Pk_Columns_Input = {
  tenant_id: Scalars['uuid'];
};

/** select columns of table "config" */
export enum Config_Select_Column {
  /** column name */
  Hash = 'hash',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VaultPath = 'vault_path',
}

/** input type for updating data in table "config" */
export type Config_Set_Input = {
  hash?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vault_path?: InputMaybe<Scalars['String']>;
};

/** Status of a given tenant config by hasura worker */
export type Config_Status = {
  __typename?: 'config_status';
  hash: Scalars['String'];
  is_active: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  /** An object relationship */
  tenant: Tenant;
  tenant_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  worker_id: Scalars['uuid'];
  /** A computed field, executes function "config_status_worker_state" */
  worker_state?: Maybe<Scalars['String']>;
};

/** aggregated selection of "config_status" */
export type Config_Status_Aggregate = {
  __typename?: 'config_status_aggregate';
  aggregate?: Maybe<Config_Status_Aggregate_Fields>;
  nodes: Array<Config_Status>;
};

export type Config_Status_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Config_Status_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Config_Status_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Config_Status_Aggregate_Bool_Exp_Count>;
};

export type Config_Status_Aggregate_Bool_Exp_Bool_And = {
  arguments: Config_Status_Select_Column_Config_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Config_Status_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Config_Status_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Config_Status_Select_Column_Config_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Config_Status_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Config_Status_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Config_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Config_Status_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "config_status" */
export type Config_Status_Aggregate_Fields = {
  __typename?: 'config_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Config_Status_Max_Fields>;
  min?: Maybe<Config_Status_Min_Fields>;
};

/** aggregate fields of "config_status" */
export type Config_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Config_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "config_status" */
export type Config_Status_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Config_Status_Max_Order_By>;
  min?: InputMaybe<Config_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "config_status" */
export type Config_Status_Arr_Rel_Insert_Input = {
  data: Array<Config_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Config_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "config_status". All fields are combined with a logical 'AND'. */
export type Config_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Config_Status_Bool_Exp>>;
  _not?: InputMaybe<Config_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Config_Status_Bool_Exp>>;
  hash?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  worker_id?: InputMaybe<Uuid_Comparison_Exp>;
  worker_state?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "config_status" */
export enum Config_Status_Constraint {
  /** unique or primary key constraint on columns "worker_id", "tenant_id", "hash" */
  ConfigStatusPkey = 'config_status_pkey',
}

/** input type for inserting data into table "config_status" */
export type Config_Status_Insert_Input = {
  hash?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Config_Status_Max_Fields = {
  __typename?: 'config_status_max_fields';
  hash?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  worker_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "config_status_worker_state" */
  worker_state?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "config_status" */
export type Config_Status_Max_Order_By = {
  hash?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Config_Status_Min_Fields = {
  __typename?: 'config_status_min_fields';
  hash?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  worker_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "config_status_worker_state" */
  worker_state?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "config_status" */
export type Config_Status_Min_Order_By = {
  hash?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "config_status" */
export type Config_Status_Mutation_Response = {
  __typename?: 'config_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Config_Status>;
};

/** on_conflict condition type for table "config_status" */
export type Config_Status_On_Conflict = {
  constraint: Config_Status_Constraint;
  update_columns?: Array<Config_Status_Update_Column>;
  where?: InputMaybe<Config_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "config_status". */
export type Config_Status_Order_By = {
  hash?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
  worker_state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: config_status */
export type Config_Status_Pk_Columns_Input = {
  hash: Scalars['String'];
  tenant_id: Scalars['uuid'];
  worker_id: Scalars['uuid'];
};

/** select columns of table "config_status" */
export enum Config_Status_Select_Column {
  /** column name */
  Hash = 'hash',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Message = 'message',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkerId = 'worker_id',
}

/** select "config_status_aggregate_bool_exp_bool_and_arguments_columns" columns of table "config_status" */
export enum Config_Status_Select_Column_Config_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** select "config_status_aggregate_bool_exp_bool_or_arguments_columns" columns of table "config_status" */
export enum Config_Status_Select_Column_Config_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** input type for updating data in table "config_status" */
export type Config_Status_Set_Input = {
  hash?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "config_status" */
export type Config_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Status_Stream_Cursor_Value_Input = {
  hash?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "config_status" */
export enum Config_Status_Update_Column {
  /** column name */
  Hash = 'hash',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Message = 'message',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkerId = 'worker_id',
}

export type Config_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Status_Bool_Exp;
};

/** Streaming cursor of the table "config" */
export type Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Config_Stream_Cursor_Value_Input = {
  hash?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vault_path?: InputMaybe<Scalars['String']>;
};

/** update columns of table "config" */
export enum Config_Update_Column {
  /** column name */
  Hash = 'hash',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VaultPath = 'vault_path',
}

export type Config_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Config_Bool_Exp;
};

/** This table contains configuration of custom connectors added by users.  */
export type Connector_Config = {
  __typename?: 'connector_config';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  deployment_url?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_deleted: Scalars['Boolean'];
  name: Scalars['String'];
  project_id: Scalars['uuid'];
  source_url: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "connector_config" */
export type Connector_Config_Aggregate = {
  __typename?: 'connector_config_aggregate';
  aggregate?: Maybe<Connector_Config_Aggregate_Fields>;
  nodes: Array<Connector_Config>;
};

/** aggregate fields of "connector_config" */
export type Connector_Config_Aggregate_Fields = {
  __typename?: 'connector_config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Connector_Config_Max_Fields>;
  min?: Maybe<Connector_Config_Min_Fields>;
};

/** aggregate fields of "connector_config" */
export type Connector_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Connector_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "connector_config". All fields are combined with a logical 'AND'. */
export type Connector_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Connector_Config_Bool_Exp>>;
  _not?: InputMaybe<Connector_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Connector_Config_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  deployment_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  source_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "connector_config" */
export enum Connector_Config_Constraint {
  /** unique or primary key constraint on columns "name" */
  ConnectorConfigNameKey = 'connector_config_name_key',
  /** unique or primary key constraint on columns "id" */
  ConnectorConfigPkey = 'connector_config_pkey',
}

/** input type for inserting data into table "connector_config" */
export type Connector_Config_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deployment_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  source_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Connector_Config_Max_Fields = {
  __typename?: 'connector_config_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deployment_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  source_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Connector_Config_Min_Fields = {
  __typename?: 'connector_config_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deployment_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  source_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "connector_config" */
export type Connector_Config_Mutation_Response = {
  __typename?: 'connector_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Connector_Config>;
};

/** on_conflict condition type for table "connector_config" */
export type Connector_Config_On_Conflict = {
  constraint: Connector_Config_Constraint;
  update_columns?: Array<Connector_Config_Update_Column>;
  where?: InputMaybe<Connector_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "connector_config". */
export type Connector_Config_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deployment_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_deleted?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  source_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: connector_config */
export type Connector_Config_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "connector_config" */
export enum Connector_Config_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeploymentUrl = 'deployment_url',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SourceUrl = 'source_url',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "connector_config" */
export type Connector_Config_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deployment_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  source_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "connector_config" */
export type Connector_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Connector_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Connector_Config_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deployment_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_deleted?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  source_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "connector_config" */
export enum Connector_Config_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeploymentUrl = 'deployment_url',
  /** column name */
  Id = 'id',
  /** column name */
  IsDeleted = 'is_deleted',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SourceUrl = 'source_url',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Connector_Config_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Connector_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Connector_Config_Bool_Exp;
};

/** Each entry in this table corresponds to a deployment made for a custom connector configure by the user. */
export type Connector_Deployments = {
  __typename?: 'connector_deployments';
  cloud_run_url?: Maybe<Scalars['String']>;
  connector_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  gcs_url?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image_url?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "connector_deployments" */
export type Connector_Deployments_Aggregate = {
  __typename?: 'connector_deployments_aggregate';
  aggregate?: Maybe<Connector_Deployments_Aggregate_Fields>;
  nodes: Array<Connector_Deployments>;
};

/** aggregate fields of "connector_deployments" */
export type Connector_Deployments_Aggregate_Fields = {
  __typename?: 'connector_deployments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Connector_Deployments_Max_Fields>;
  min?: Maybe<Connector_Deployments_Min_Fields>;
};

/** aggregate fields of "connector_deployments" */
export type Connector_Deployments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Connector_Deployments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "connector_deployments". All fields are combined with a logical 'AND'. */
export type Connector_Deployments_Bool_Exp = {
  _and?: InputMaybe<Array<Connector_Deployments_Bool_Exp>>;
  _not?: InputMaybe<Connector_Deployments_Bool_Exp>;
  _or?: InputMaybe<Array<Connector_Deployments_Bool_Exp>>;
  cloud_run_url?: InputMaybe<String_Comparison_Exp>;
  connector_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  gcs_url?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "connector_deployments" */
export enum Connector_Deployments_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConnectorDeploymentsPkey = 'connector_deployments_pkey',
}

/** input type for inserting data into table "connector_deployments" */
export type Connector_Deployments_Insert_Input = {
  cloud_run_url?: InputMaybe<Scalars['String']>;
  connector_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  gcs_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Connector_Deployments_Max_Fields = {
  __typename?: 'connector_deployments_max_fields';
  cloud_run_url?: Maybe<Scalars['String']>;
  connector_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  gcs_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Connector_Deployments_Min_Fields = {
  __typename?: 'connector_deployments_min_fields';
  cloud_run_url?: Maybe<Scalars['String']>;
  connector_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  gcs_url?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image_url?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "connector_deployments" */
export type Connector_Deployments_Mutation_Response = {
  __typename?: 'connector_deployments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Connector_Deployments>;
};

/** on_conflict condition type for table "connector_deployments" */
export type Connector_Deployments_On_Conflict = {
  constraint: Connector_Deployments_Constraint;
  update_columns?: Array<Connector_Deployments_Update_Column>;
  where?: InputMaybe<Connector_Deployments_Bool_Exp>;
};

/** Ordering options when selecting data from "connector_deployments". */
export type Connector_Deployments_Order_By = {
  cloud_run_url?: InputMaybe<Order_By>;
  connector_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  gcs_url?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: connector_deployments */
export type Connector_Deployments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "connector_deployments" */
export enum Connector_Deployments_Select_Column {
  /** column name */
  CloudRunUrl = 'cloud_run_url',
  /** column name */
  ConnectorId = 'connector_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GcsUrl = 'gcs_url',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "connector_deployments" */
export type Connector_Deployments_Set_Input = {
  cloud_run_url?: InputMaybe<Scalars['String']>;
  connector_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  gcs_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "connector_deployments" */
export type Connector_Deployments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Connector_Deployments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Connector_Deployments_Stream_Cursor_Value_Input = {
  cloud_run_url?: InputMaybe<Scalars['String']>;
  connector_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  gcs_url?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image_url?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "connector_deployments" */
export enum Connector_Deployments_Update_Column {
  /** column name */
  CloudRunUrl = 'cloud_run_url',
  /** column name */
  ConnectorId = 'connector_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  GcsUrl = 'gcs_url',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Connector_Deployments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Connector_Deployments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Connector_Deployments_Bool_Exp;
};

/** Coupon for Hasura Cloud discount */
export type Coupon = {
  __typename?: 'coupon';
  /** email of users which are allowed to use the coupon */
  allowed_customer?: Maybe<Scalars['jsonb']>;
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: Maybe<Scalars['Int']>;
  /** case insensitive text that represents a unique customer facing code that will be used to get the benefit */
  code: Scalars['citext'];
  /** unique coupon id, represents coupon id at stripe. Internal column */
  coupon_id?: Maybe<Scalars['String']>;
  /** only USD is supported at the moment. Default to USD */
  currency?: Maybe<Scalars['String']>;
  /** represents the duration applicable for a particular coupon. Valid values `once`, `forever`, `repeating` */
  duration?: Maybe<Coupon_Duration_Enum>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: Maybe<Scalars['Int']>;
  expires_at: Scalars['timestamptz'];
  /** randomly generated uuid */
  id: Scalars['uuid'];
  /** represents the state of a particular coupon, whether it is active and can be redeemed or not. This is a global flag to mark a particular coupon active/inactive probably in the middle of a campaign etc. Defaults to true */
  is_active: Scalars['Boolean'];
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions: Scalars['Int'];
  /** represents human readable name for a particular coupon */
  name: Scalars['String'];
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption: Scalars['Int'];
  /** percentage off on the entire invoice amount */
  percentage_off?: Maybe<Scalars['numeric']>;
  /** unique id, represents promotion id at stripe. Internal column */
  promotional_code_id?: Maybe<Scalars['String']>;
  stripe_error?: Maybe<Scalars['String']>;
  /** represents the type of a coupon. Valid values are `credit` or `coupon` */
  type: Coupon_Type_Enum;
  /** An array relationship */
  user_coupons: Array<User_Coupon>;
  /** An aggregate relationship */
  user_coupons_aggregate: User_Coupon_Aggregate;
};

/** Coupon for Hasura Cloud discount */
export type CouponAllowed_CustomerArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Coupon for Hasura Cloud discount */
export type CouponUser_CouponsArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

/** Coupon for Hasura Cloud discount */
export type CouponUser_Coupons_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

/** aggregated selection of "coupon" */
export type Coupon_Aggregate = {
  __typename?: 'coupon_aggregate';
  aggregate?: Maybe<Coupon_Aggregate_Fields>;
  nodes: Array<Coupon>;
};

export type Coupon_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Coupon_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Coupon_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Coupon_Aggregate_Bool_Exp_Count>;
};

export type Coupon_Aggregate_Bool_Exp_Bool_And = {
  arguments: Coupon_Select_Column_Coupon_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Coupon_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Coupon_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Coupon_Select_Column_Coupon_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Coupon_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Coupon_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Coupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Coupon_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "coupon" */
export type Coupon_Aggregate_Fields = {
  __typename?: 'coupon_aggregate_fields';
  avg?: Maybe<Coupon_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Coupon_Max_Fields>;
  min?: Maybe<Coupon_Min_Fields>;
  stddev?: Maybe<Coupon_Stddev_Fields>;
  stddev_pop?: Maybe<Coupon_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Coupon_Stddev_Samp_Fields>;
  sum?: Maybe<Coupon_Sum_Fields>;
  var_pop?: Maybe<Coupon_Var_Pop_Fields>;
  var_samp?: Maybe<Coupon_Var_Samp_Fields>;
  variance?: Maybe<Coupon_Variance_Fields>;
};

/** aggregate fields of "coupon" */
export type Coupon_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Coupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "coupon" */
export type Coupon_Aggregate_Order_By = {
  avg?: InputMaybe<Coupon_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Coupon_Max_Order_By>;
  min?: InputMaybe<Coupon_Min_Order_By>;
  stddev?: InputMaybe<Coupon_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Coupon_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Coupon_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Coupon_Sum_Order_By>;
  var_pop?: InputMaybe<Coupon_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Coupon_Var_Samp_Order_By>;
  variance?: InputMaybe<Coupon_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Coupon_Append_Input = {
  /** email of users which are allowed to use the coupon */
  allowed_customer?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "coupon" */
export type Coupon_Arr_Rel_Insert_Input = {
  data: Array<Coupon_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Coupon_On_Conflict>;
};

/** aggregate avg on columns */
export type Coupon_Avg_Fields = {
  __typename?: 'coupon_avg_fields';
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: Maybe<Scalars['Float']>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: Maybe<Scalars['Float']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: Maybe<Scalars['Float']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: Maybe<Scalars['Float']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "coupon" */
export type Coupon_Avg_Order_By = {
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Order_By>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Order_By>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Order_By>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Order_By>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "coupon". All fields are combined with a logical 'AND'. */
export type Coupon_Bool_Exp = {
  _and?: InputMaybe<Array<Coupon_Bool_Exp>>;
  _not?: InputMaybe<Coupon_Bool_Exp>;
  _or?: InputMaybe<Array<Coupon_Bool_Exp>>;
  allowed_customer?: InputMaybe<Jsonb_Comparison_Exp>;
  amount_off?: InputMaybe<Int_Comparison_Exp>;
  code?: InputMaybe<Citext_Comparison_Exp>;
  coupon_id?: InputMaybe<String_Comparison_Exp>;
  currency?: InputMaybe<String_Comparison_Exp>;
  duration?: InputMaybe<Coupon_Duration_Enum_Comparison_Exp>;
  duration_in_months?: InputMaybe<Int_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  max_redemptions?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  per_customer_max_redemption?: InputMaybe<Int_Comparison_Exp>;
  percentage_off?: InputMaybe<Numeric_Comparison_Exp>;
  promotional_code_id?: InputMaybe<String_Comparison_Exp>;
  stripe_error?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Coupon_Type_Enum_Comparison_Exp>;
  user_coupons?: InputMaybe<User_Coupon_Bool_Exp>;
  user_coupons_aggregate?: InputMaybe<User_Coupon_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "coupon" */
export enum Coupon_Constraint {
  /** unique or primary key constraint on columns "code" */
  CouponCodeKey = 'coupon_code_key',
  /** unique or primary key constraint on columns "coupon_id" */
  CouponCouponIdKey = 'coupon_coupon_id_key',
  /** unique or primary key constraint on columns "id" */
  CouponPkey = 'coupon_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Coupon_Delete_At_Path_Input = {
  /** email of users which are allowed to use the coupon */
  allowed_customer?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Coupon_Delete_Elem_Input = {
  /** email of users which are allowed to use the coupon */
  allowed_customer?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Coupon_Delete_Key_Input = {
  /** email of users which are allowed to use the coupon */
  allowed_customer?: InputMaybe<Scalars['String']>;
};

/** Hasura enum to capture supported duration values. This enum is used in coupon table */
export type Coupon_Duration = {
  __typename?: 'coupon_duration';
  comment: Scalars['String'];
  /** An array relationship */
  coupon: Array<Coupon>;
  /** An aggregate relationship */
  coupon_aggregate: Coupon_Aggregate;
  duration: Scalars['String'];
};

/** Hasura enum to capture supported duration values. This enum is used in coupon table */
export type Coupon_DurationCouponArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

/** Hasura enum to capture supported duration values. This enum is used in coupon table */
export type Coupon_DurationCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

/** aggregated selection of "coupon_duration" */
export type Coupon_Duration_Aggregate = {
  __typename?: 'coupon_duration_aggregate';
  aggregate?: Maybe<Coupon_Duration_Aggregate_Fields>;
  nodes: Array<Coupon_Duration>;
};

/** aggregate fields of "coupon_duration" */
export type Coupon_Duration_Aggregate_Fields = {
  __typename?: 'coupon_duration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Coupon_Duration_Max_Fields>;
  min?: Maybe<Coupon_Duration_Min_Fields>;
};

/** aggregate fields of "coupon_duration" */
export type Coupon_Duration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Coupon_Duration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "coupon_duration". All fields are combined with a logical 'AND'. */
export type Coupon_Duration_Bool_Exp = {
  _and?: InputMaybe<Array<Coupon_Duration_Bool_Exp>>;
  _not?: InputMaybe<Coupon_Duration_Bool_Exp>;
  _or?: InputMaybe<Array<Coupon_Duration_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  coupon?: InputMaybe<Coupon_Bool_Exp>;
  coupon_aggregate?: InputMaybe<Coupon_Aggregate_Bool_Exp>;
  duration?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "coupon_duration" */
export enum Coupon_Duration_Constraint {
  /** unique or primary key constraint on columns "duration" */
  CouponDurationPkey = 'coupon_duration_pkey',
}

export enum Coupon_Duration_Enum {
  /** coupon will be applied forever */
  Forever = 'forever',
  /** coupon will be applied once */
  Once = 'once',
  /** coupon will be applied for mutiple times */
  Repeating = 'repeating',
}

/** Boolean expression to compare columns of type "coupon_duration_enum". All fields are combined with logical 'AND'. */
export type Coupon_Duration_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Coupon_Duration_Enum>;
  _in?: InputMaybe<Array<Coupon_Duration_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Coupon_Duration_Enum>;
  _nin?: InputMaybe<Array<Coupon_Duration_Enum>>;
};

/** input type for inserting data into table "coupon_duration" */
export type Coupon_Duration_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  coupon?: InputMaybe<Coupon_Arr_Rel_Insert_Input>;
  duration?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Coupon_Duration_Max_Fields = {
  __typename?: 'coupon_duration_max_fields';
  comment?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Coupon_Duration_Min_Fields = {
  __typename?: 'coupon_duration_min_fields';
  comment?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "coupon_duration" */
export type Coupon_Duration_Mutation_Response = {
  __typename?: 'coupon_duration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coupon_Duration>;
};

/** on_conflict condition type for table "coupon_duration" */
export type Coupon_Duration_On_Conflict = {
  constraint: Coupon_Duration_Constraint;
  update_columns?: Array<Coupon_Duration_Update_Column>;
  where?: InputMaybe<Coupon_Duration_Bool_Exp>;
};

/** Ordering options when selecting data from "coupon_duration". */
export type Coupon_Duration_Order_By = {
  comment?: InputMaybe<Order_By>;
  coupon_aggregate?: InputMaybe<Coupon_Aggregate_Order_By>;
  duration?: InputMaybe<Order_By>;
};

/** primary key columns input for table: coupon_duration */
export type Coupon_Duration_Pk_Columns_Input = {
  duration: Scalars['String'];
};

/** select columns of table "coupon_duration" */
export enum Coupon_Duration_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Duration = 'duration',
}

/** input type for updating data in table "coupon_duration" */
export type Coupon_Duration_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "coupon_duration" */
export type Coupon_Duration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Coupon_Duration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Coupon_Duration_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  duration?: InputMaybe<Scalars['String']>;
};

/** update columns of table "coupon_duration" */
export enum Coupon_Duration_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Duration = 'duration',
}

export type Coupon_Duration_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Coupon_Duration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Coupon_Duration_Bool_Exp;
};

/** input type for incrementing numeric columns in table "coupon" */
export type Coupon_Inc_Input = {
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Scalars['Int']>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Scalars['Int']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Scalars['Int']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Scalars['Int']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "coupon" */
export type Coupon_Insert_Input = {
  /** email of users which are allowed to use the coupon */
  allowed_customer?: InputMaybe<Scalars['jsonb']>;
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Scalars['Int']>;
  /** case insensitive text that represents a unique customer facing code that will be used to get the benefit */
  code?: InputMaybe<Scalars['citext']>;
  /** unique coupon id, represents coupon id at stripe. Internal column */
  coupon_id?: InputMaybe<Scalars['String']>;
  /** only USD is supported at the moment. Default to USD */
  currency?: InputMaybe<Scalars['String']>;
  /** represents the duration applicable for a particular coupon. Valid values `once`, `forever`, `repeating` */
  duration?: InputMaybe<Coupon_Duration_Enum>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Scalars['Int']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  /** randomly generated uuid */
  id?: InputMaybe<Scalars['uuid']>;
  /** represents the state of a particular coupon, whether it is active and can be redeemed or not. This is a global flag to mark a particular coupon active/inactive probably in the middle of a campaign etc. Defaults to true */
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Scalars['Int']>;
  /** represents human readable name for a particular coupon */
  name?: InputMaybe<Scalars['String']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Scalars['Int']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Scalars['numeric']>;
  /** unique id, represents promotion id at stripe. Internal column */
  promotional_code_id?: InputMaybe<Scalars['String']>;
  stripe_error?: InputMaybe<Scalars['String']>;
  /** represents the type of a coupon. Valid values are `credit` or `coupon` */
  type?: InputMaybe<Coupon_Type_Enum>;
  user_coupons?: InputMaybe<User_Coupon_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Coupon_Max_Fields = {
  __typename?: 'coupon_max_fields';
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: Maybe<Scalars['Int']>;
  /** case insensitive text that represents a unique customer facing code that will be used to get the benefit */
  code?: Maybe<Scalars['citext']>;
  /** unique coupon id, represents coupon id at stripe. Internal column */
  coupon_id?: Maybe<Scalars['String']>;
  /** only USD is supported at the moment. Default to USD */
  currency?: Maybe<Scalars['String']>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: Maybe<Scalars['Int']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  /** randomly generated uuid */
  id?: Maybe<Scalars['uuid']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: Maybe<Scalars['Int']>;
  /** represents human readable name for a particular coupon */
  name?: Maybe<Scalars['String']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: Maybe<Scalars['Int']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: Maybe<Scalars['numeric']>;
  /** unique id, represents promotion id at stripe. Internal column */
  promotional_code_id?: Maybe<Scalars['String']>;
  stripe_error?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "coupon" */
export type Coupon_Max_Order_By = {
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Order_By>;
  /** case insensitive text that represents a unique customer facing code that will be used to get the benefit */
  code?: InputMaybe<Order_By>;
  /** unique coupon id, represents coupon id at stripe. Internal column */
  coupon_id?: InputMaybe<Order_By>;
  /** only USD is supported at the moment. Default to USD */
  currency?: InputMaybe<Order_By>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  /** randomly generated uuid */
  id?: InputMaybe<Order_By>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Order_By>;
  /** represents human readable name for a particular coupon */
  name?: InputMaybe<Order_By>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Order_By>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Order_By>;
  /** unique id, represents promotion id at stripe. Internal column */
  promotional_code_id?: InputMaybe<Order_By>;
  stripe_error?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Coupon_Min_Fields = {
  __typename?: 'coupon_min_fields';
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: Maybe<Scalars['Int']>;
  /** case insensitive text that represents a unique customer facing code that will be used to get the benefit */
  code?: Maybe<Scalars['citext']>;
  /** unique coupon id, represents coupon id at stripe. Internal column */
  coupon_id?: Maybe<Scalars['String']>;
  /** only USD is supported at the moment. Default to USD */
  currency?: Maybe<Scalars['String']>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: Maybe<Scalars['Int']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  /** randomly generated uuid */
  id?: Maybe<Scalars['uuid']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: Maybe<Scalars['Int']>;
  /** represents human readable name for a particular coupon */
  name?: Maybe<Scalars['String']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: Maybe<Scalars['Int']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: Maybe<Scalars['numeric']>;
  /** unique id, represents promotion id at stripe. Internal column */
  promotional_code_id?: Maybe<Scalars['String']>;
  stripe_error?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "coupon" */
export type Coupon_Min_Order_By = {
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Order_By>;
  /** case insensitive text that represents a unique customer facing code that will be used to get the benefit */
  code?: InputMaybe<Order_By>;
  /** unique coupon id, represents coupon id at stripe. Internal column */
  coupon_id?: InputMaybe<Order_By>;
  /** only USD is supported at the moment. Default to USD */
  currency?: InputMaybe<Order_By>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  /** randomly generated uuid */
  id?: InputMaybe<Order_By>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Order_By>;
  /** represents human readable name for a particular coupon */
  name?: InputMaybe<Order_By>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Order_By>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Order_By>;
  /** unique id, represents promotion id at stripe. Internal column */
  promotional_code_id?: InputMaybe<Order_By>;
  stripe_error?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "coupon" */
export type Coupon_Mutation_Response = {
  __typename?: 'coupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coupon>;
};

/** input type for inserting object relation for remote table "coupon" */
export type Coupon_Obj_Rel_Insert_Input = {
  data: Coupon_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Coupon_On_Conflict>;
};

/** on_conflict condition type for table "coupon" */
export type Coupon_On_Conflict = {
  constraint: Coupon_Constraint;
  update_columns?: Array<Coupon_Update_Column>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

/** Ordering options when selecting data from "coupon". */
export type Coupon_Order_By = {
  allowed_customer?: InputMaybe<Order_By>;
  amount_off?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  coupon_id?: InputMaybe<Order_By>;
  currency?: InputMaybe<Order_By>;
  duration?: InputMaybe<Order_By>;
  duration_in_months?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  max_redemptions?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  per_customer_max_redemption?: InputMaybe<Order_By>;
  percentage_off?: InputMaybe<Order_By>;
  promotional_code_id?: InputMaybe<Order_By>;
  stripe_error?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_coupons_aggregate?: InputMaybe<User_Coupon_Aggregate_Order_By>;
};

/** primary key columns input for table: coupon */
export type Coupon_Pk_Columns_Input = {
  /** randomly generated uuid */
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Coupon_Prepend_Input = {
  /** email of users which are allowed to use the coupon */
  allowed_customer?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "coupon" */
export enum Coupon_Select_Column {
  /** column name */
  AllowedCustomer = 'allowed_customer',
  /** column name */
  AmountOff = 'amount_off',
  /** column name */
  Code = 'code',
  /** column name */
  CouponId = 'coupon_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  Duration = 'duration',
  /** column name */
  DurationInMonths = 'duration_in_months',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MaxRedemptions = 'max_redemptions',
  /** column name */
  Name = 'name',
  /** column name */
  PerCustomerMaxRedemption = 'per_customer_max_redemption',
  /** column name */
  PercentageOff = 'percentage_off',
  /** column name */
  PromotionalCodeId = 'promotional_code_id',
  /** column name */
  StripeError = 'stripe_error',
  /** column name */
  Type = 'type',
}

/** select "coupon_aggregate_bool_exp_bool_and_arguments_columns" columns of table "coupon" */
export enum Coupon_Select_Column_Coupon_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** select "coupon_aggregate_bool_exp_bool_or_arguments_columns" columns of table "coupon" */
export enum Coupon_Select_Column_Coupon_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** input type for updating data in table "coupon" */
export type Coupon_Set_Input = {
  /** email of users which are allowed to use the coupon */
  allowed_customer?: InputMaybe<Scalars['jsonb']>;
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Scalars['Int']>;
  /** case insensitive text that represents a unique customer facing code that will be used to get the benefit */
  code?: InputMaybe<Scalars['citext']>;
  /** unique coupon id, represents coupon id at stripe. Internal column */
  coupon_id?: InputMaybe<Scalars['String']>;
  /** only USD is supported at the moment. Default to USD */
  currency?: InputMaybe<Scalars['String']>;
  /** represents the duration applicable for a particular coupon. Valid values `once`, `forever`, `repeating` */
  duration?: InputMaybe<Coupon_Duration_Enum>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Scalars['Int']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  /** randomly generated uuid */
  id?: InputMaybe<Scalars['uuid']>;
  /** represents the state of a particular coupon, whether it is active and can be redeemed or not. This is a global flag to mark a particular coupon active/inactive probably in the middle of a campaign etc. Defaults to true */
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Scalars['Int']>;
  /** represents human readable name for a particular coupon */
  name?: InputMaybe<Scalars['String']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Scalars['Int']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Scalars['numeric']>;
  /** unique id, represents promotion id at stripe. Internal column */
  promotional_code_id?: InputMaybe<Scalars['String']>;
  stripe_error?: InputMaybe<Scalars['String']>;
  /** represents the type of a coupon. Valid values are `credit` or `coupon` */
  type?: InputMaybe<Coupon_Type_Enum>;
};

/** aggregate stddev on columns */
export type Coupon_Stddev_Fields = {
  __typename?: 'coupon_stddev_fields';
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: Maybe<Scalars['Float']>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: Maybe<Scalars['Float']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: Maybe<Scalars['Float']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: Maybe<Scalars['Float']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "coupon" */
export type Coupon_Stddev_Order_By = {
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Order_By>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Order_By>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Order_By>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Order_By>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Coupon_Stddev_Pop_Fields = {
  __typename?: 'coupon_stddev_pop_fields';
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: Maybe<Scalars['Float']>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: Maybe<Scalars['Float']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: Maybe<Scalars['Float']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: Maybe<Scalars['Float']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "coupon" */
export type Coupon_Stddev_Pop_Order_By = {
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Order_By>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Order_By>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Order_By>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Order_By>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Coupon_Stddev_Samp_Fields = {
  __typename?: 'coupon_stddev_samp_fields';
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: Maybe<Scalars['Float']>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: Maybe<Scalars['Float']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: Maybe<Scalars['Float']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: Maybe<Scalars['Float']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "coupon" */
export type Coupon_Stddev_Samp_Order_By = {
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Order_By>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Order_By>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Order_By>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Order_By>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "coupon" */
export type Coupon_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Coupon_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Coupon_Stream_Cursor_Value_Input = {
  /** email of users which are allowed to use the coupon */
  allowed_customer?: InputMaybe<Scalars['jsonb']>;
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Scalars['Int']>;
  /** case insensitive text that represents a unique customer facing code that will be used to get the benefit */
  code?: InputMaybe<Scalars['citext']>;
  /** unique coupon id, represents coupon id at stripe. Internal column */
  coupon_id?: InputMaybe<Scalars['String']>;
  /** only USD is supported at the moment. Default to USD */
  currency?: InputMaybe<Scalars['String']>;
  /** represents the duration applicable for a particular coupon. Valid values `once`, `forever`, `repeating` */
  duration?: InputMaybe<Coupon_Duration_Enum>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Scalars['Int']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  /** randomly generated uuid */
  id?: InputMaybe<Scalars['uuid']>;
  /** represents the state of a particular coupon, whether it is active and can be redeemed or not. This is a global flag to mark a particular coupon active/inactive probably in the middle of a campaign etc. Defaults to true */
  is_active?: InputMaybe<Scalars['Boolean']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Scalars['Int']>;
  /** represents human readable name for a particular coupon */
  name?: InputMaybe<Scalars['String']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Scalars['Int']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Scalars['numeric']>;
  /** unique id, represents promotion id at stripe. Internal column */
  promotional_code_id?: InputMaybe<Scalars['String']>;
  stripe_error?: InputMaybe<Scalars['String']>;
  /** represents the type of a coupon. Valid values are `credit` or `coupon` */
  type?: InputMaybe<Coupon_Type_Enum>;
};

/** aggregate sum on columns */
export type Coupon_Sum_Fields = {
  __typename?: 'coupon_sum_fields';
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: Maybe<Scalars['Int']>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: Maybe<Scalars['Int']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: Maybe<Scalars['Int']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: Maybe<Scalars['Int']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "coupon" */
export type Coupon_Sum_Order_By = {
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Order_By>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Order_By>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Order_By>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Order_By>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Order_By>;
};

/** Hasura enum to capture supported coupon type values. This enum is used in coupon table */
export type Coupon_Type = {
  __typename?: 'coupon_type';
  comment: Scalars['String'];
  /** An array relationship */
  coupon: Array<Coupon>;
  /** An aggregate relationship */
  coupon_aggregate: Coupon_Aggregate;
  type: Scalars['String'];
};

/** Hasura enum to capture supported coupon type values. This enum is used in coupon table */
export type Coupon_TypeCouponArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

/** Hasura enum to capture supported coupon type values. This enum is used in coupon table */
export type Coupon_TypeCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

/** aggregated selection of "coupon_type" */
export type Coupon_Type_Aggregate = {
  __typename?: 'coupon_type_aggregate';
  aggregate?: Maybe<Coupon_Type_Aggregate_Fields>;
  nodes: Array<Coupon_Type>;
};

/** aggregate fields of "coupon_type" */
export type Coupon_Type_Aggregate_Fields = {
  __typename?: 'coupon_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Coupon_Type_Max_Fields>;
  min?: Maybe<Coupon_Type_Min_Fields>;
};

/** aggregate fields of "coupon_type" */
export type Coupon_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Coupon_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "coupon_type". All fields are combined with a logical 'AND'. */
export type Coupon_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Coupon_Type_Bool_Exp>>;
  _not?: InputMaybe<Coupon_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Coupon_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  coupon?: InputMaybe<Coupon_Bool_Exp>;
  coupon_aggregate?: InputMaybe<Coupon_Aggregate_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "coupon_type" */
export enum Coupon_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  CouponTypePkey = 'coupon_type_pkey',
}

export enum Coupon_Type_Enum {
  /** stripe coupon */
  Coupon = 'coupon',
  /** add balance to customers account */
  Credit = 'credit',
}

/** Boolean expression to compare columns of type "coupon_type_enum". All fields are combined with logical 'AND'. */
export type Coupon_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Coupon_Type_Enum>;
  _in?: InputMaybe<Array<Coupon_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Coupon_Type_Enum>;
  _nin?: InputMaybe<Array<Coupon_Type_Enum>>;
};

/** input type for inserting data into table "coupon_type" */
export type Coupon_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  coupon?: InputMaybe<Coupon_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Coupon_Type_Max_Fields = {
  __typename?: 'coupon_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Coupon_Type_Min_Fields = {
  __typename?: 'coupon_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "coupon_type" */
export type Coupon_Type_Mutation_Response = {
  __typename?: 'coupon_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Coupon_Type>;
};

/** on_conflict condition type for table "coupon_type" */
export type Coupon_Type_On_Conflict = {
  constraint: Coupon_Type_Constraint;
  update_columns?: Array<Coupon_Type_Update_Column>;
  where?: InputMaybe<Coupon_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "coupon_type". */
export type Coupon_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  coupon_aggregate?: InputMaybe<Coupon_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: coupon_type */
export type Coupon_Type_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "coupon_type" */
export enum Coupon_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "coupon_type" */
export type Coupon_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "coupon_type" */
export type Coupon_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Coupon_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Coupon_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "coupon_type" */
export enum Coupon_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Type = 'type',
}

export type Coupon_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Coupon_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Coupon_Type_Bool_Exp;
};

/** update columns of table "coupon" */
export enum Coupon_Update_Column {
  /** column name */
  AllowedCustomer = 'allowed_customer',
  /** column name */
  AmountOff = 'amount_off',
  /** column name */
  Code = 'code',
  /** column name */
  CouponId = 'coupon_id',
  /** column name */
  Currency = 'currency',
  /** column name */
  Duration = 'duration',
  /** column name */
  DurationInMonths = 'duration_in_months',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MaxRedemptions = 'max_redemptions',
  /** column name */
  Name = 'name',
  /** column name */
  PerCustomerMaxRedemption = 'per_customer_max_redemption',
  /** column name */
  PercentageOff = 'percentage_off',
  /** column name */
  PromotionalCodeId = 'promotional_code_id',
  /** column name */
  StripeError = 'stripe_error',
  /** column name */
  Type = 'type',
}

export type Coupon_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Coupon_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Coupon_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Coupon_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Coupon_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Coupon_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Coupon_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Coupon_Set_Input>;
  /** filter the rows which have to be updated */
  where: Coupon_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Coupon_Var_Pop_Fields = {
  __typename?: 'coupon_var_pop_fields';
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: Maybe<Scalars['Float']>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: Maybe<Scalars['Float']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: Maybe<Scalars['Float']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: Maybe<Scalars['Float']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "coupon" */
export type Coupon_Var_Pop_Order_By = {
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Order_By>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Order_By>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Order_By>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Order_By>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Coupon_Var_Samp_Fields = {
  __typename?: 'coupon_var_samp_fields';
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: Maybe<Scalars['Float']>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: Maybe<Scalars['Float']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: Maybe<Scalars['Float']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: Maybe<Scalars['Float']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "coupon" */
export type Coupon_Var_Samp_Order_By = {
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Order_By>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Order_By>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Order_By>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Order_By>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Coupon_Variance_Fields = {
  __typename?: 'coupon_variance_fields';
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: Maybe<Scalars['Float']>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: Maybe<Scalars['Float']>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: Maybe<Scalars['Float']>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: Maybe<Scalars['Float']>;
  /** percentage off on the entire invoice amount */
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "coupon" */
export type Coupon_Variance_Order_By = {
  /** amount off in USD. Either amount_off or percentage_off will be there and they cannot exist together */
  amount_off?: InputMaybe<Order_By>;
  /** when duration is set to `repeating`, this column will represent no of months the discount should be applied on the invoice. Should be NULL for other type of coupon */
  duration_in_months?: InputMaybe<Order_By>;
  /** represents how many times this coupon can be redeemed. If a coupon needs to be used by 100 folks, set this to 100 */
  max_redemptions?: InputMaybe<Order_By>;
  /** represents how many times a single coupon can be redeemed by a single customer. Set it to 1 if a user can use this coupon only once */
  per_customer_max_redemption?: InputMaybe<Order_By>;
  /** percentage off on the entire invoice amount */
  percentage_off?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** information about custom domains that are added for tenants */
export type Custom_Domain = {
  __typename?: 'custom_domain';
  /** indicates how certificate is handled: 1) letsencrypt: gateway should use LE to issue cert, 2) vault://<path>: cert is stored on Vault at this path, 3) none: cert is not handled */
  cert: Scalars['String'];
  /** Get origin server DNS record created for custom domain */
  cloudflare_origin_dns_record?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  custom_domain_cloudflare?: Maybe<Custom_Domain_Cloudflare>;
  /** An object relationship */
  custom_domain_cloudflare_dns?: Maybe<Custom_Domain_Cloudflare_Dns>;
  /** indicates if the dns resolves to <slug>.hasura.app: 1) pending: the validation is peniding, 2) succeeded: dns is configured correctly, 3) failed: dns validation failed */
  dns_validation: Scalars['String'];
  /** the custom domain being added */
  fqdn: Scalars['String'];
  id: Scalars['uuid'];
  /** An array relationship */
  letsencrypt_statuses: Array<Letsencrypt_Status>;
  /** An aggregate relationship */
  letsencrypt_statuses_aggregate: Letsencrypt_Status_Aggregate;
  /** Get the SSL info/error messages, if any */
  ssl_message?: Maybe<Scalars['String']>;
  /** Get the SSL status for custom domain */
  ssl_status?: Maybe<Scalars['String']>;
  /** An object relationship */
  tenant: Tenant;
  tenant_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** information about custom domains that are added for tenants */
export type Custom_DomainLetsencrypt_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Letsencrypt_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Letsencrypt_Status_Order_By>>;
  where?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
};

/** information about custom domains that are added for tenants */
export type Custom_DomainLetsencrypt_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Letsencrypt_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Letsencrypt_Status_Order_By>>;
  where?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
};

/** aggregated selection of "custom_domain" */
export type Custom_Domain_Aggregate = {
  __typename?: 'custom_domain_aggregate';
  aggregate?: Maybe<Custom_Domain_Aggregate_Fields>;
  nodes: Array<Custom_Domain>;
};

export type Custom_Domain_Aggregate_Bool_Exp = {
  count?: InputMaybe<Custom_Domain_Aggregate_Bool_Exp_Count>;
};

export type Custom_Domain_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Custom_Domain_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Custom_Domain_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "custom_domain" */
export type Custom_Domain_Aggregate_Fields = {
  __typename?: 'custom_domain_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Custom_Domain_Max_Fields>;
  min?: Maybe<Custom_Domain_Min_Fields>;
};

/** aggregate fields of "custom_domain" */
export type Custom_Domain_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Custom_Domain_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "custom_domain" */
export type Custom_Domain_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Custom_Domain_Max_Order_By>;
  min?: InputMaybe<Custom_Domain_Min_Order_By>;
};

/** input type for inserting array relation for remote table "custom_domain" */
export type Custom_Domain_Arr_Rel_Insert_Input = {
  data: Array<Custom_Domain_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Custom_Domain_On_Conflict>;
};

/** Boolean expression to filter rows from the table "custom_domain". All fields are combined with a logical 'AND'. */
export type Custom_Domain_Bool_Exp = {
  _and?: InputMaybe<Array<Custom_Domain_Bool_Exp>>;
  _not?: InputMaybe<Custom_Domain_Bool_Exp>;
  _or?: InputMaybe<Array<Custom_Domain_Bool_Exp>>;
  cert?: InputMaybe<String_Comparison_Exp>;
  cloudflare_origin_dns_record?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_domain_cloudflare?: InputMaybe<Custom_Domain_Cloudflare_Bool_Exp>;
  custom_domain_cloudflare_dns?: InputMaybe<
    Custom_Domain_Cloudflare_Dns_Bool_Exp
  >;
  dns_validation?: InputMaybe<String_Comparison_Exp>;
  fqdn?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  letsencrypt_statuses?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
  letsencrypt_statuses_aggregate?: InputMaybe<
    Letsencrypt_Status_Aggregate_Bool_Exp
  >;
  ssl_message?: InputMaybe<String_Comparison_Exp>;
  ssl_status?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Status of cloudflare custom domain setup */
export type Custom_Domain_Cloudflare = {
  __typename?: 'custom_domain_cloudflare';
  certificate_verification_path?: Maybe<Scalars['String']>;
  certificate_verification_value?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  custom_domain: Custom_Domain;
  custom_hostname_cloudflare?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  dns?: Maybe<Custom_Domain_Cloudflare_Dns>;
  force_update: Scalars['Boolean'];
  fqdn: Scalars['String'];
  id: Scalars['uuid'];
  message?: Maybe<Scalars['String']>;
  origin_server_cloudflare: Scalars['String'];
  ownership_verification_path?: Maybe<Scalars['String']>;
  ownership_verification_value?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** Status of cloudflare custom domain setup */
export type Custom_Domain_CloudflareCustom_Hostname_CloudflareArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "custom_domain_cloudflare" */
export type Custom_Domain_Cloudflare_Aggregate = {
  __typename?: 'custom_domain_cloudflare_aggregate';
  aggregate?: Maybe<Custom_Domain_Cloudflare_Aggregate_Fields>;
  nodes: Array<Custom_Domain_Cloudflare>;
};

/** aggregate fields of "custom_domain_cloudflare" */
export type Custom_Domain_Cloudflare_Aggregate_Fields = {
  __typename?: 'custom_domain_cloudflare_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Custom_Domain_Cloudflare_Max_Fields>;
  min?: Maybe<Custom_Domain_Cloudflare_Min_Fields>;
};

/** aggregate fields of "custom_domain_cloudflare" */
export type Custom_Domain_Cloudflare_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Custom_Domain_Cloudflare_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Custom_Domain_Cloudflare_Append_Input = {
  custom_hostname_cloudflare?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "custom_domain_cloudflare". All fields are combined with a logical 'AND'. */
export type Custom_Domain_Cloudflare_Bool_Exp = {
  _and?: InputMaybe<Array<Custom_Domain_Cloudflare_Bool_Exp>>;
  _not?: InputMaybe<Custom_Domain_Cloudflare_Bool_Exp>;
  _or?: InputMaybe<Array<Custom_Domain_Cloudflare_Bool_Exp>>;
  certificate_verification_path?: InputMaybe<String_Comparison_Exp>;
  certificate_verification_value?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_domain?: InputMaybe<Custom_Domain_Bool_Exp>;
  custom_hostname_cloudflare?: InputMaybe<Jsonb_Comparison_Exp>;
  dns?: InputMaybe<Custom_Domain_Cloudflare_Dns_Bool_Exp>;
  force_update?: InputMaybe<Boolean_Comparison_Exp>;
  fqdn?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  origin_server_cloudflare?: InputMaybe<String_Comparison_Exp>;
  ownership_verification_path?: InputMaybe<String_Comparison_Exp>;
  ownership_verification_value?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "custom_domain_cloudflare" */
export enum Custom_Domain_Cloudflare_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomDomainCloudflarePkey = 'custom_domain_cloudflare_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Custom_Domain_Cloudflare_Delete_At_Path_Input = {
  custom_hostname_cloudflare?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Custom_Domain_Cloudflare_Delete_Elem_Input = {
  custom_hostname_cloudflare?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Custom_Domain_Cloudflare_Delete_Key_Input = {
  custom_hostname_cloudflare?: InputMaybe<Scalars['String']>;
};

/** DNS record required for cloudflare custom domain */
export type Custom_Domain_Cloudflare_Dns = {
  __typename?: 'custom_domain_cloudflare_dns';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  custom_domain: Custom_Domain;
  dns_name: Scalars['String'];
  force_update: Scalars['Boolean'];
  id: Scalars['uuid'];
  message?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "custom_domain_cloudflare_dns" */
export type Custom_Domain_Cloudflare_Dns_Aggregate = {
  __typename?: 'custom_domain_cloudflare_dns_aggregate';
  aggregate?: Maybe<Custom_Domain_Cloudflare_Dns_Aggregate_Fields>;
  nodes: Array<Custom_Domain_Cloudflare_Dns>;
};

/** aggregate fields of "custom_domain_cloudflare_dns" */
export type Custom_Domain_Cloudflare_Dns_Aggregate_Fields = {
  __typename?: 'custom_domain_cloudflare_dns_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Custom_Domain_Cloudflare_Dns_Max_Fields>;
  min?: Maybe<Custom_Domain_Cloudflare_Dns_Min_Fields>;
};

/** aggregate fields of "custom_domain_cloudflare_dns" */
export type Custom_Domain_Cloudflare_Dns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Custom_Domain_Cloudflare_Dns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "custom_domain_cloudflare_dns". All fields are combined with a logical 'AND'. */
export type Custom_Domain_Cloudflare_Dns_Bool_Exp = {
  _and?: InputMaybe<Array<Custom_Domain_Cloudflare_Dns_Bool_Exp>>;
  _not?: InputMaybe<Custom_Domain_Cloudflare_Dns_Bool_Exp>;
  _or?: InputMaybe<Array<Custom_Domain_Cloudflare_Dns_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_domain?: InputMaybe<Custom_Domain_Bool_Exp>;
  dns_name?: InputMaybe<String_Comparison_Exp>;
  force_update?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "custom_domain_cloudflare_dns" */
export enum Custom_Domain_Cloudflare_Dns_Constraint {
  /** unique or primary key constraint on columns "dns_name" */
  CustomDomainCloudflareDnsDnsNameKey = 'custom_domain_cloudflare_dns_dns_name_key',
  /** unique or primary key constraint on columns "id", "dns_name" */
  CustomDomainCloudflareDnsIdDnsNameKey = 'custom_domain_cloudflare_dns_id_dns_name_key',
  /** unique or primary key constraint on columns "id" */
  CustomDomainCloudflareDnsPkey = 'custom_domain_cloudflare_dns_pkey',
}

/** input type for inserting data into table "custom_domain_cloudflare_dns" */
export type Custom_Domain_Cloudflare_Dns_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_domain?: InputMaybe<Custom_Domain_Obj_Rel_Insert_Input>;
  dns_name?: InputMaybe<Scalars['String']>;
  force_update?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Custom_Domain_Cloudflare_Dns_Max_Fields = {
  __typename?: 'custom_domain_cloudflare_dns_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dns_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Custom_Domain_Cloudflare_Dns_Min_Fields = {
  __typename?: 'custom_domain_cloudflare_dns_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  dns_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "custom_domain_cloudflare_dns" */
export type Custom_Domain_Cloudflare_Dns_Mutation_Response = {
  __typename?: 'custom_domain_cloudflare_dns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Custom_Domain_Cloudflare_Dns>;
};

/** input type for inserting object relation for remote table "custom_domain_cloudflare_dns" */
export type Custom_Domain_Cloudflare_Dns_Obj_Rel_Insert_Input = {
  data: Custom_Domain_Cloudflare_Dns_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Custom_Domain_Cloudflare_Dns_On_Conflict>;
};

/** on_conflict condition type for table "custom_domain_cloudflare_dns" */
export type Custom_Domain_Cloudflare_Dns_On_Conflict = {
  constraint: Custom_Domain_Cloudflare_Dns_Constraint;
  update_columns?: Array<Custom_Domain_Cloudflare_Dns_Update_Column>;
  where?: InputMaybe<Custom_Domain_Cloudflare_Dns_Bool_Exp>;
};

/** Ordering options when selecting data from "custom_domain_cloudflare_dns". */
export type Custom_Domain_Cloudflare_Dns_Order_By = {
  created_at?: InputMaybe<Order_By>;
  custom_domain?: InputMaybe<Custom_Domain_Order_By>;
  dns_name?: InputMaybe<Order_By>;
  force_update?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: custom_domain_cloudflare_dns */
export type Custom_Domain_Cloudflare_Dns_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "custom_domain_cloudflare_dns" */
export enum Custom_Domain_Cloudflare_Dns_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DnsName = 'dns_name',
  /** column name */
  ForceUpdate = 'force_update',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "custom_domain_cloudflare_dns" */
export type Custom_Domain_Cloudflare_Dns_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dns_name?: InputMaybe<Scalars['String']>;
  force_update?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "custom_domain_cloudflare_dns" */
export type Custom_Domain_Cloudflare_Dns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Custom_Domain_Cloudflare_Dns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Custom_Domain_Cloudflare_Dns_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dns_name?: InputMaybe<Scalars['String']>;
  force_update?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "custom_domain_cloudflare_dns" */
export enum Custom_Domain_Cloudflare_Dns_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DnsName = 'dns_name',
  /** column name */
  ForceUpdate = 'force_update',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Custom_Domain_Cloudflare_Dns_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Custom_Domain_Cloudflare_Dns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Custom_Domain_Cloudflare_Dns_Bool_Exp;
};

/** input type for inserting data into table "custom_domain_cloudflare" */
export type Custom_Domain_Cloudflare_Insert_Input = {
  certificate_verification_path?: InputMaybe<Scalars['String']>;
  certificate_verification_value?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_domain?: InputMaybe<Custom_Domain_Obj_Rel_Insert_Input>;
  custom_hostname_cloudflare?: InputMaybe<Scalars['jsonb']>;
  dns?: InputMaybe<Custom_Domain_Cloudflare_Dns_Obj_Rel_Insert_Input>;
  force_update?: InputMaybe<Scalars['Boolean']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  origin_server_cloudflare?: InputMaybe<Scalars['String']>;
  ownership_verification_path?: InputMaybe<Scalars['String']>;
  ownership_verification_value?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Custom_Domain_Cloudflare_Max_Fields = {
  __typename?: 'custom_domain_cloudflare_max_fields';
  certificate_verification_path?: Maybe<Scalars['String']>;
  certificate_verification_value?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  origin_server_cloudflare?: Maybe<Scalars['String']>;
  ownership_verification_path?: Maybe<Scalars['String']>;
  ownership_verification_value?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Custom_Domain_Cloudflare_Min_Fields = {
  __typename?: 'custom_domain_cloudflare_min_fields';
  certificate_verification_path?: Maybe<Scalars['String']>;
  certificate_verification_value?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  origin_server_cloudflare?: Maybe<Scalars['String']>;
  ownership_verification_path?: Maybe<Scalars['String']>;
  ownership_verification_value?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "custom_domain_cloudflare" */
export type Custom_Domain_Cloudflare_Mutation_Response = {
  __typename?: 'custom_domain_cloudflare_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Custom_Domain_Cloudflare>;
};

/** input type for inserting object relation for remote table "custom_domain_cloudflare" */
export type Custom_Domain_Cloudflare_Obj_Rel_Insert_Input = {
  data: Custom_Domain_Cloudflare_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Custom_Domain_Cloudflare_On_Conflict>;
};

/** on_conflict condition type for table "custom_domain_cloudflare" */
export type Custom_Domain_Cloudflare_On_Conflict = {
  constraint: Custom_Domain_Cloudflare_Constraint;
  update_columns?: Array<Custom_Domain_Cloudflare_Update_Column>;
  where?: InputMaybe<Custom_Domain_Cloudflare_Bool_Exp>;
};

/** Ordering options when selecting data from "custom_domain_cloudflare". */
export type Custom_Domain_Cloudflare_Order_By = {
  certificate_verification_path?: InputMaybe<Order_By>;
  certificate_verification_value?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_domain?: InputMaybe<Custom_Domain_Order_By>;
  custom_hostname_cloudflare?: InputMaybe<Order_By>;
  dns?: InputMaybe<Custom_Domain_Cloudflare_Dns_Order_By>;
  force_update?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  origin_server_cloudflare?: InputMaybe<Order_By>;
  ownership_verification_path?: InputMaybe<Order_By>;
  ownership_verification_value?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: custom_domain_cloudflare */
export type Custom_Domain_Cloudflare_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Custom_Domain_Cloudflare_Prepend_Input = {
  custom_hostname_cloudflare?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "custom_domain_cloudflare" */
export enum Custom_Domain_Cloudflare_Select_Column {
  /** column name */
  CertificateVerificationPath = 'certificate_verification_path',
  /** column name */
  CertificateVerificationValue = 'certificate_verification_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomHostnameCloudflare = 'custom_hostname_cloudflare',
  /** column name */
  ForceUpdate = 'force_update',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  OriginServerCloudflare = 'origin_server_cloudflare',
  /** column name */
  OwnershipVerificationPath = 'ownership_verification_path',
  /** column name */
  OwnershipVerificationValue = 'ownership_verification_value',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "custom_domain_cloudflare" */
export type Custom_Domain_Cloudflare_Set_Input = {
  certificate_verification_path?: InputMaybe<Scalars['String']>;
  certificate_verification_value?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_hostname_cloudflare?: InputMaybe<Scalars['jsonb']>;
  force_update?: InputMaybe<Scalars['Boolean']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  origin_server_cloudflare?: InputMaybe<Scalars['String']>;
  ownership_verification_path?: InputMaybe<Scalars['String']>;
  ownership_verification_value?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "custom_domain_cloudflare" */
export type Custom_Domain_Cloudflare_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Custom_Domain_Cloudflare_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Custom_Domain_Cloudflare_Stream_Cursor_Value_Input = {
  certificate_verification_path?: InputMaybe<Scalars['String']>;
  certificate_verification_value?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_hostname_cloudflare?: InputMaybe<Scalars['jsonb']>;
  force_update?: InputMaybe<Scalars['Boolean']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  origin_server_cloudflare?: InputMaybe<Scalars['String']>;
  ownership_verification_path?: InputMaybe<Scalars['String']>;
  ownership_verification_value?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "custom_domain_cloudflare" */
export enum Custom_Domain_Cloudflare_Update_Column {
  /** column name */
  CertificateVerificationPath = 'certificate_verification_path',
  /** column name */
  CertificateVerificationValue = 'certificate_verification_value',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomHostnameCloudflare = 'custom_hostname_cloudflare',
  /** column name */
  ForceUpdate = 'force_update',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  OriginServerCloudflare = 'origin_server_cloudflare',
  /** column name */
  OwnershipVerificationPath = 'ownership_verification_path',
  /** column name */
  OwnershipVerificationValue = 'ownership_verification_value',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Custom_Domain_Cloudflare_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Custom_Domain_Cloudflare_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Custom_Domain_Cloudflare_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Custom_Domain_Cloudflare_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Custom_Domain_Cloudflare_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Custom_Domain_Cloudflare_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Custom_Domain_Cloudflare_Set_Input>;
  /** filter the rows which have to be updated */
  where: Custom_Domain_Cloudflare_Bool_Exp;
};

/** unique or primary key constraints on table "custom_domain" */
export enum Custom_Domain_Constraint {
  /** unique or primary key constraint on columns "fqdn" */
  CustomDomainFqdnKey = 'custom_domain_fqdn_key',
  /** unique or primary key constraint on columns "fqdn", "id" */
  CustomDomainIdFqdnKey = 'custom_domain_id_fqdn_key',
  /** unique or primary key constraint on columns "id" */
  CustomDomainPkey = 'custom_domain_pkey',
}

/** input type for inserting data into table "custom_domain" */
export type Custom_Domain_Insert_Input = {
  /** indicates how certificate is handled: 1) letsencrypt: gateway should use LE to issue cert, 2) vault://<path>: cert is stored on Vault at this path, 3) none: cert is not handled */
  cert?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_domain_cloudflare?: InputMaybe<
    Custom_Domain_Cloudflare_Obj_Rel_Insert_Input
  >;
  custom_domain_cloudflare_dns?: InputMaybe<
    Custom_Domain_Cloudflare_Dns_Obj_Rel_Insert_Input
  >;
  /** indicates if the dns resolves to <slug>.hasura.app: 1) pending: the validation is peniding, 2) succeeded: dns is configured correctly, 3) failed: dns validation failed */
  dns_validation?: InputMaybe<Scalars['String']>;
  /** the custom domain being added */
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  letsencrypt_statuses?: InputMaybe<Letsencrypt_Status_Arr_Rel_Insert_Input>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Custom_Domain_Max_Fields = {
  __typename?: 'custom_domain_max_fields';
  /** indicates how certificate is handled: 1) letsencrypt: gateway should use LE to issue cert, 2) vault://<path>: cert is stored on Vault at this path, 3) none: cert is not handled */
  cert?: Maybe<Scalars['String']>;
  /** Get origin server DNS record created for custom domain */
  cloudflare_origin_dns_record?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** indicates if the dns resolves to <slug>.hasura.app: 1) pending: the validation is peniding, 2) succeeded: dns is configured correctly, 3) failed: dns validation failed */
  dns_validation?: Maybe<Scalars['String']>;
  /** the custom domain being added */
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** Get the SSL info/error messages, if any */
  ssl_message?: Maybe<Scalars['String']>;
  /** Get the SSL status for custom domain */
  ssl_status?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "custom_domain" */
export type Custom_Domain_Max_Order_By = {
  /** indicates how certificate is handled: 1) letsencrypt: gateway should use LE to issue cert, 2) vault://<path>: cert is stored on Vault at this path, 3) none: cert is not handled */
  cert?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** indicates if the dns resolves to <slug>.hasura.app: 1) pending: the validation is peniding, 2) succeeded: dns is configured correctly, 3) failed: dns validation failed */
  dns_validation?: InputMaybe<Order_By>;
  /** the custom domain being added */
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Custom_Domain_Min_Fields = {
  __typename?: 'custom_domain_min_fields';
  /** indicates how certificate is handled: 1) letsencrypt: gateway should use LE to issue cert, 2) vault://<path>: cert is stored on Vault at this path, 3) none: cert is not handled */
  cert?: Maybe<Scalars['String']>;
  /** Get origin server DNS record created for custom domain */
  cloudflare_origin_dns_record?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** indicates if the dns resolves to <slug>.hasura.app: 1) pending: the validation is peniding, 2) succeeded: dns is configured correctly, 3) failed: dns validation failed */
  dns_validation?: Maybe<Scalars['String']>;
  /** the custom domain being added */
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** Get the SSL info/error messages, if any */
  ssl_message?: Maybe<Scalars['String']>;
  /** Get the SSL status for custom domain */
  ssl_status?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "custom_domain" */
export type Custom_Domain_Min_Order_By = {
  /** indicates how certificate is handled: 1) letsencrypt: gateway should use LE to issue cert, 2) vault://<path>: cert is stored on Vault at this path, 3) none: cert is not handled */
  cert?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** indicates if the dns resolves to <slug>.hasura.app: 1) pending: the validation is peniding, 2) succeeded: dns is configured correctly, 3) failed: dns validation failed */
  dns_validation?: InputMaybe<Order_By>;
  /** the custom domain being added */
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "custom_domain" */
export type Custom_Domain_Mutation_Response = {
  __typename?: 'custom_domain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Custom_Domain>;
};

/** input type for inserting object relation for remote table "custom_domain" */
export type Custom_Domain_Obj_Rel_Insert_Input = {
  data: Custom_Domain_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Custom_Domain_On_Conflict>;
};

/** on_conflict condition type for table "custom_domain" */
export type Custom_Domain_On_Conflict = {
  constraint: Custom_Domain_Constraint;
  update_columns?: Array<Custom_Domain_Update_Column>;
  where?: InputMaybe<Custom_Domain_Bool_Exp>;
};

/** Ordering options when selecting data from "custom_domain". */
export type Custom_Domain_Order_By = {
  cert?: InputMaybe<Order_By>;
  cloudflare_origin_dns_record?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_domain_cloudflare?: InputMaybe<Custom_Domain_Cloudflare_Order_By>;
  custom_domain_cloudflare_dns?: InputMaybe<
    Custom_Domain_Cloudflare_Dns_Order_By
  >;
  dns_validation?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  letsencrypt_statuses_aggregate?: InputMaybe<
    Letsencrypt_Status_Aggregate_Order_By
  >;
  ssl_message?: InputMaybe<Order_By>;
  ssl_status?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: custom_domain */
export type Custom_Domain_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "custom_domain" */
export enum Custom_Domain_Select_Column {
  /** column name */
  Cert = 'cert',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DnsValidation = 'dns_validation',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "custom_domain" */
export type Custom_Domain_Set_Input = {
  /** indicates how certificate is handled: 1) letsencrypt: gateway should use LE to issue cert, 2) vault://<path>: cert is stored on Vault at this path, 3) none: cert is not handled */
  cert?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** indicates if the dns resolves to <slug>.hasura.app: 1) pending: the validation is peniding, 2) succeeded: dns is configured correctly, 3) failed: dns validation failed */
  dns_validation?: InputMaybe<Scalars['String']>;
  /** the custom domain being added */
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "custom_domain" */
export type Custom_Domain_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Custom_Domain_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Custom_Domain_Stream_Cursor_Value_Input = {
  /** indicates how certificate is handled: 1) letsencrypt: gateway should use LE to issue cert, 2) vault://<path>: cert is stored on Vault at this path, 3) none: cert is not handled */
  cert?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** indicates if the dns resolves to <slug>.hasura.app: 1) pending: the validation is peniding, 2) succeeded: dns is configured correctly, 3) failed: dns validation failed */
  dns_validation?: InputMaybe<Scalars['String']>;
  /** the custom domain being added */
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "custom_domain" */
export enum Custom_Domain_Update_Column {
  /** column name */
  Cert = 'cert',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DnsValidation = 'dns_validation',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Custom_Domain_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Custom_Domain_Set_Input>;
  /** filter the rows which have to be updated */
  where: Custom_Domain_Bool_Exp;
};

/** Price (amount) usage by a customer */
export type Customer_Usage = {
  __typename?: 'customer_usage';
  amount: Scalars['float8'];
  code?: Maybe<Scalars['citext']>;
  /** An object relationship */
  coupon?: Maybe<Coupon>;
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['String'];
  id: Scalars['uuid'];
  invoice_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "customer_usage" */
export type Customer_Usage_Aggregate = {
  __typename?: 'customer_usage_aggregate';
  aggregate?: Maybe<Customer_Usage_Aggregate_Fields>;
  nodes: Array<Customer_Usage>;
};

export type Customer_Usage_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Customer_Usage_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Customer_Usage_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Customer_Usage_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Customer_Usage_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Customer_Usage_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Customer_Usage_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Customer_Usage_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Customer_Usage_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Customer_Usage_Aggregate_Bool_Exp_Var_Samp>;
};

export type Customer_Usage_Aggregate_Bool_Exp_Avg = {
  arguments: Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Usage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customer_Usage_Aggregate_Bool_Exp_Corr = {
  arguments: Customer_Usage_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Usage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customer_Usage_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Customer_Usage_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Customer_Usage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Usage_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Customer_Usage_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Customer_Usage_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Usage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customer_Usage_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Customer_Usage_Aggregate_Bool_Exp_Max = {
  arguments: Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Usage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customer_Usage_Aggregate_Bool_Exp_Min = {
  arguments: Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Usage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customer_Usage_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Usage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customer_Usage_Aggregate_Bool_Exp_Sum = {
  arguments: Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Usage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Customer_Usage_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Customer_Usage_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "customer_usage" */
export type Customer_Usage_Aggregate_Fields = {
  __typename?: 'customer_usage_aggregate_fields';
  avg?: Maybe<Customer_Usage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Customer_Usage_Max_Fields>;
  min?: Maybe<Customer_Usage_Min_Fields>;
  stddev?: Maybe<Customer_Usage_Stddev_Fields>;
  stddev_pop?: Maybe<Customer_Usage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Customer_Usage_Stddev_Samp_Fields>;
  sum?: Maybe<Customer_Usage_Sum_Fields>;
  var_pop?: Maybe<Customer_Usage_Var_Pop_Fields>;
  var_samp?: Maybe<Customer_Usage_Var_Samp_Fields>;
  variance?: Maybe<Customer_Usage_Variance_Fields>;
};

/** aggregate fields of "customer_usage" */
export type Customer_Usage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Customer_Usage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customer_usage" */
export type Customer_Usage_Aggregate_Order_By = {
  avg?: InputMaybe<Customer_Usage_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Customer_Usage_Max_Order_By>;
  min?: InputMaybe<Customer_Usage_Min_Order_By>;
  stddev?: InputMaybe<Customer_Usage_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Customer_Usage_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Customer_Usage_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Customer_Usage_Sum_Order_By>;
  var_pop?: InputMaybe<Customer_Usage_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Customer_Usage_Var_Samp_Order_By>;
  variance?: InputMaybe<Customer_Usage_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customer_usage" */
export type Customer_Usage_Arr_Rel_Insert_Input = {
  data: Array<Customer_Usage_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Customer_Usage_On_Conflict>;
};

/** aggregate avg on columns */
export type Customer_Usage_Avg_Fields = {
  __typename?: 'customer_usage_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customer_usage" */
export type Customer_Usage_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customer_usage". All fields are combined with a logical 'AND'. */
export type Customer_Usage_Bool_Exp = {
  _and?: InputMaybe<Array<Customer_Usage_Bool_Exp>>;
  _not?: InputMaybe<Customer_Usage_Bool_Exp>;
  _or?: InputMaybe<Array<Customer_Usage_Bool_Exp>>;
  amount?: InputMaybe<Float8_Comparison_Exp>;
  code?: InputMaybe<Citext_Comparison_Exp>;
  coupon?: InputMaybe<Coupon_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_id?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "customer_usage" */
export enum Customer_Usage_Constraint {
  /** unique or primary key constraint on columns "id" */
  CustomerUsagePkey = 'customer_usage_pkey',
}

/** input type for incrementing numeric columns in table "customer_usage" */
export type Customer_Usage_Inc_Input = {
  amount?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "customer_usage" */
export type Customer_Usage_Insert_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  code?: InputMaybe<Scalars['citext']>;
  coupon?: InputMaybe<Coupon_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Customer_Usage_Max_Fields = {
  __typename?: 'customer_usage_max_fields';
  amount?: Maybe<Scalars['float8']>;
  code?: Maybe<Scalars['citext']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customer_usage" */
export type Customer_Usage_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customer_Usage_Min_Fields = {
  __typename?: 'customer_usage_min_fields';
  amount?: Maybe<Scalars['float8']>;
  code?: Maybe<Scalars['citext']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customer_usage" */
export type Customer_Usage_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customer_usage" */
export type Customer_Usage_Mutation_Response = {
  __typename?: 'customer_usage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Customer_Usage>;
};

/** on_conflict condition type for table "customer_usage" */
export type Customer_Usage_On_Conflict = {
  constraint: Customer_Usage_Constraint;
  update_columns?: Array<Customer_Usage_Update_Column>;
  where?: InputMaybe<Customer_Usage_Bool_Exp>;
};

/** Ordering options when selecting data from "customer_usage". */
export type Customer_Usage_Order_By = {
  amount?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  coupon?: InputMaybe<Coupon_Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: customer_usage */
export type Customer_Usage_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "customer_usage" */
export enum Customer_Usage_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
}

/** select "customer_usage_aggregate_bool_exp_avg_arguments_columns" columns of table "customer_usage" */
export enum Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Amount = 'amount',
}

/** select "customer_usage_aggregate_bool_exp_corr_arguments_columns" columns of table "customer_usage" */
export enum Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Amount = 'amount',
}

/** select "customer_usage_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "customer_usage" */
export enum Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount',
}

/** select "customer_usage_aggregate_bool_exp_max_arguments_columns" columns of table "customer_usage" */
export enum Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Amount = 'amount',
}

/** select "customer_usage_aggregate_bool_exp_min_arguments_columns" columns of table "customer_usage" */
export enum Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Amount = 'amount',
}

/** select "customer_usage_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "customer_usage" */
export enum Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount',
}

/** select "customer_usage_aggregate_bool_exp_sum_arguments_columns" columns of table "customer_usage" */
export enum Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Amount = 'amount',
}

/** select "customer_usage_aggregate_bool_exp_var_samp_arguments_columns" columns of table "customer_usage" */
export enum Customer_Usage_Select_Column_Customer_Usage_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Amount = 'amount',
}

/** input type for updating data in table "customer_usage" */
export type Customer_Usage_Set_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  code?: InputMaybe<Scalars['citext']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Customer_Usage_Stddev_Fields = {
  __typename?: 'customer_usage_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customer_usage" */
export type Customer_Usage_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customer_Usage_Stddev_Pop_Fields = {
  __typename?: 'customer_usage_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customer_usage" */
export type Customer_Usage_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customer_Usage_Stddev_Samp_Fields = {
  __typename?: 'customer_usage_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customer_usage" */
export type Customer_Usage_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customer_usage" */
export type Customer_Usage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Customer_Usage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customer_Usage_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['float8']>;
  code?: InputMaybe<Scalars['citext']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Customer_Usage_Sum_Fields = {
  __typename?: 'customer_usage_sum_fields';
  amount?: Maybe<Scalars['float8']>;
};

/** order by sum() on columns of table "customer_usage" */
export type Customer_Usage_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "customer_usage" */
export enum Customer_Usage_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
}

export type Customer_Usage_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Customer_Usage_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Customer_Usage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Customer_Usage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customer_Usage_Var_Pop_Fields = {
  __typename?: 'customer_usage_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customer_usage" */
export type Customer_Usage_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customer_Usage_Var_Samp_Fields = {
  __typename?: 'customer_usage_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customer_usage" */
export type Customer_Usage_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customer_Usage_Variance_Fields = {
  __typename?: 'customer_usage_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customer_usage" */
export type Customer_Usage_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** columns and relationships of "data_connector_type" */
export type Data_Connector_Type = {
  __typename?: 'data_connector_type';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** aggregated selection of "data_connector_type" */
export type Data_Connector_Type_Aggregate = {
  __typename?: 'data_connector_type_aggregate';
  aggregate?: Maybe<Data_Connector_Type_Aggregate_Fields>;
  nodes: Array<Data_Connector_Type>;
};

/** aggregate fields of "data_connector_type" */
export type Data_Connector_Type_Aggregate_Fields = {
  __typename?: 'data_connector_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Data_Connector_Type_Max_Fields>;
  min?: Maybe<Data_Connector_Type_Min_Fields>;
};

/** aggregate fields of "data_connector_type" */
export type Data_Connector_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Data_Connector_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "data_connector_type". All fields are combined with a logical 'AND'. */
export type Data_Connector_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Data_Connector_Type_Bool_Exp>>;
  _not?: InputMaybe<Data_Connector_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Data_Connector_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "data_connector_type" */
export enum Data_Connector_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  DataConnectorTypePkey = 'data_connector_type_pkey',
}

export enum Data_Connector_Type_Enum {
  MongodbConnector = 'mongodb_connector',
  SuperConnector = 'super_connector',
}

/** Boolean expression to compare columns of type "data_connector_type_enum". All fields are combined with logical 'AND'. */
export type Data_Connector_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Data_Connector_Type_Enum>;
  _in?: InputMaybe<Array<Data_Connector_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Data_Connector_Type_Enum>;
  _nin?: InputMaybe<Array<Data_Connector_Type_Enum>>;
};

/** input type for inserting data into table "data_connector_type" */
export type Data_Connector_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Data_Connector_Type_Max_Fields = {
  __typename?: 'data_connector_type_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Data_Connector_Type_Min_Fields = {
  __typename?: 'data_connector_type_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "data_connector_type" */
export type Data_Connector_Type_Mutation_Response = {
  __typename?: 'data_connector_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Data_Connector_Type>;
};

/** on_conflict condition type for table "data_connector_type" */
export type Data_Connector_Type_On_Conflict = {
  constraint: Data_Connector_Type_Constraint;
  update_columns?: Array<Data_Connector_Type_Update_Column>;
  where?: InputMaybe<Data_Connector_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "data_connector_type". */
export type Data_Connector_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: data_connector_type */
export type Data_Connector_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "data_connector_type" */
export enum Data_Connector_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "data_connector_type" */
export type Data_Connector_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "data_connector_type" */
export type Data_Connector_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Data_Connector_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Data_Connector_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "data_connector_type" */
export enum Data_Connector_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type Data_Connector_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Data_Connector_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Data_Connector_Type_Bool_Exp;
};

/** Datadog exporter configuration by project */
export type Datadog_Config = {
  __typename?: 'datadog_config';
  created_at: Scalars['timestamptz'];
  /** region of the user's Datadog API key; this determines which Datadog API to use */
  datadog_region: Scalars['String'];
  host?: Maybe<Scalars['String']>;
  /** base64 encoded datadog api key */
  key: Scalars['String'];
  logs_last_logged: Scalars['timestamptz'];
  logs_response_code?: Maybe<Scalars['Int']>;
  metrics_last_logged: Scalars['timestamptz'];
  metrics_response_code?: Maybe<Scalars['Int']>;
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  service_name?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  traces_last_logged: Scalars['timestamptz'];
  traces_response_code?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "datadog_config" */
export type Datadog_Config_Aggregate = {
  __typename?: 'datadog_config_aggregate';
  aggregate?: Maybe<Datadog_Config_Aggregate_Fields>;
  nodes: Array<Datadog_Config>;
};

/** aggregate fields of "datadog_config" */
export type Datadog_Config_Aggregate_Fields = {
  __typename?: 'datadog_config_aggregate_fields';
  avg?: Maybe<Datadog_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Datadog_Config_Max_Fields>;
  min?: Maybe<Datadog_Config_Min_Fields>;
  stddev?: Maybe<Datadog_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Datadog_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Datadog_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Datadog_Config_Sum_Fields>;
  var_pop?: Maybe<Datadog_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Datadog_Config_Var_Samp_Fields>;
  variance?: Maybe<Datadog_Config_Variance_Fields>;
};

/** aggregate fields of "datadog_config" */
export type Datadog_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Datadog_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Datadog_Config_Avg_Fields = {
  __typename?: 'datadog_config_avg_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "datadog_config". All fields are combined with a logical 'AND'. */
export type Datadog_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Datadog_Config_Bool_Exp>>;
  _not?: InputMaybe<Datadog_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Datadog_Config_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  datadog_region?: InputMaybe<String_Comparison_Exp>;
  host?: InputMaybe<String_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  logs_last_logged?: InputMaybe<Timestamptz_Comparison_Exp>;
  logs_response_code?: InputMaybe<Int_Comparison_Exp>;
  metrics_last_logged?: InputMaybe<Timestamptz_Comparison_Exp>;
  metrics_response_code?: InputMaybe<Int_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  service_name?: InputMaybe<String_Comparison_Exp>;
  tags?: InputMaybe<String_Comparison_Exp>;
  traces_last_logged?: InputMaybe<Timestamptz_Comparison_Exp>;
  traces_response_code?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "datadog_config" */
export enum Datadog_Config_Constraint {
  /** unique or primary key constraint on columns "project_id" */
  DatadogConfigPkey = 'datadog_config_pkey',
}

/** input type for incrementing numeric columns in table "datadog_config" */
export type Datadog_Config_Inc_Input = {
  logs_response_code?: InputMaybe<Scalars['Int']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  traces_response_code?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "datadog_config" */
export type Datadog_Config_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** region of the user's Datadog API key; this determines which Datadog API to use */
  datadog_region?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  /** base64 encoded datadog api key */
  key?: InputMaybe<Scalars['String']>;
  logs_last_logged?: InputMaybe<Scalars['timestamptz']>;
  logs_response_code?: InputMaybe<Scalars['Int']>;
  metrics_last_logged?: InputMaybe<Scalars['timestamptz']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  service_name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
  traces_last_logged?: InputMaybe<Scalars['timestamptz']>;
  traces_response_code?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Datadog_Config_Max_Fields = {
  __typename?: 'datadog_config_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** region of the user's Datadog API key; this determines which Datadog API to use */
  datadog_region?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  /** base64 encoded datadog api key */
  key?: Maybe<Scalars['String']>;
  logs_last_logged?: Maybe<Scalars['timestamptz']>;
  logs_response_code?: Maybe<Scalars['Int']>;
  metrics_last_logged?: Maybe<Scalars['timestamptz']>;
  metrics_response_code?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  service_name?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  traces_last_logged?: Maybe<Scalars['timestamptz']>;
  traces_response_code?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Datadog_Config_Min_Fields = {
  __typename?: 'datadog_config_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** region of the user's Datadog API key; this determines which Datadog API to use */
  datadog_region?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  /** base64 encoded datadog api key */
  key?: Maybe<Scalars['String']>;
  logs_last_logged?: Maybe<Scalars['timestamptz']>;
  logs_response_code?: Maybe<Scalars['Int']>;
  metrics_last_logged?: Maybe<Scalars['timestamptz']>;
  metrics_response_code?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  service_name?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  traces_last_logged?: Maybe<Scalars['timestamptz']>;
  traces_response_code?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "datadog_config" */
export type Datadog_Config_Mutation_Response = {
  __typename?: 'datadog_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Datadog_Config>;
};

/** input type for inserting object relation for remote table "datadog_config" */
export type Datadog_Config_Obj_Rel_Insert_Input = {
  data: Datadog_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Datadog_Config_On_Conflict>;
};

/** on_conflict condition type for table "datadog_config" */
export type Datadog_Config_On_Conflict = {
  constraint: Datadog_Config_Constraint;
  update_columns?: Array<Datadog_Config_Update_Column>;
  where?: InputMaybe<Datadog_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "datadog_config". */
export type Datadog_Config_Order_By = {
  created_at?: InputMaybe<Order_By>;
  datadog_region?: InputMaybe<Order_By>;
  host?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  logs_last_logged?: InputMaybe<Order_By>;
  logs_response_code?: InputMaybe<Order_By>;
  metrics_last_logged?: InputMaybe<Order_By>;
  metrics_response_code?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  service_name?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  traces_last_logged?: InputMaybe<Order_By>;
  traces_response_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: datadog_config */
export type Datadog_Config_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
};

/** select columns of table "datadog_config" */
export enum Datadog_Config_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DatadogRegion = 'datadog_region',
  /** column name */
  Host = 'host',
  /** column name */
  Key = 'key',
  /** column name */
  LogsLastLogged = 'logs_last_logged',
  /** column name */
  LogsResponseCode = 'logs_response_code',
  /** column name */
  MetricsLastLogged = 'metrics_last_logged',
  /** column name */
  MetricsResponseCode = 'metrics_response_code',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ServiceName = 'service_name',
  /** column name */
  Tags = 'tags',
  /** column name */
  TracesLastLogged = 'traces_last_logged',
  /** column name */
  TracesResponseCode = 'traces_response_code',
}

/** input type for updating data in table "datadog_config" */
export type Datadog_Config_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** region of the user's Datadog API key; this determines which Datadog API to use */
  datadog_region?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  /** base64 encoded datadog api key */
  key?: InputMaybe<Scalars['String']>;
  logs_last_logged?: InputMaybe<Scalars['timestamptz']>;
  logs_response_code?: InputMaybe<Scalars['Int']>;
  metrics_last_logged?: InputMaybe<Scalars['timestamptz']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  service_name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
  traces_last_logged?: InputMaybe<Scalars['timestamptz']>;
  traces_response_code?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Datadog_Config_Stddev_Fields = {
  __typename?: 'datadog_config_stddev_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Datadog_Config_Stddev_Pop_Fields = {
  __typename?: 'datadog_config_stddev_pop_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Datadog_Config_Stddev_Samp_Fields = {
  __typename?: 'datadog_config_stddev_samp_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "datadog_config" */
export type Datadog_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Datadog_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Datadog_Config_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** region of the user's Datadog API key; this determines which Datadog API to use */
  datadog_region?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  /** base64 encoded datadog api key */
  key?: InputMaybe<Scalars['String']>;
  logs_last_logged?: InputMaybe<Scalars['timestamptz']>;
  logs_response_code?: InputMaybe<Scalars['Int']>;
  metrics_last_logged?: InputMaybe<Scalars['timestamptz']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  service_name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
  traces_last_logged?: InputMaybe<Scalars['timestamptz']>;
  traces_response_code?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Datadog_Config_Sum_Fields = {
  __typename?: 'datadog_config_sum_fields';
  logs_response_code?: Maybe<Scalars['Int']>;
  metrics_response_code?: Maybe<Scalars['Int']>;
  traces_response_code?: Maybe<Scalars['Int']>;
};

/** update columns of table "datadog_config" */
export enum Datadog_Config_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DatadogRegion = 'datadog_region',
  /** column name */
  Host = 'host',
  /** column name */
  Key = 'key',
  /** column name */
  LogsLastLogged = 'logs_last_logged',
  /** column name */
  LogsResponseCode = 'logs_response_code',
  /** column name */
  MetricsLastLogged = 'metrics_last_logged',
  /** column name */
  MetricsResponseCode = 'metrics_response_code',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ServiceName = 'service_name',
  /** column name */
  Tags = 'tags',
  /** column name */
  TracesLastLogged = 'traces_last_logged',
  /** column name */
  TracesResponseCode = 'traces_response_code',
}

export type Datadog_Config_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Datadog_Config_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Datadog_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Datadog_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Datadog_Config_Var_Pop_Fields = {
  __typename?: 'datadog_config_var_pop_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Datadog_Config_Var_Samp_Fields = {
  __typename?: 'datadog_config_var_samp_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Datadog_Config_Variance_Fields = {
  __typename?: 'datadog_config_variance_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Clickhouse_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** Table to store whether the calculated db latency was displayed to the user or not */
export type Db_Latency = {
  __typename?: 'db_latency';
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_change_region_clicked: Scalars['Boolean'];
  is_latency_displayed: Scalars['Boolean'];
  job_id: Scalars['uuid'];
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "db_latency" */
export type Db_Latency_Aggregate = {
  __typename?: 'db_latency_aggregate';
  aggregate?: Maybe<Db_Latency_Aggregate_Fields>;
  nodes: Array<Db_Latency>;
};

/** aggregate fields of "db_latency" */
export type Db_Latency_Aggregate_Fields = {
  __typename?: 'db_latency_aggregate_fields';
  avg?: Maybe<Db_Latency_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Db_Latency_Max_Fields>;
  min?: Maybe<Db_Latency_Min_Fields>;
  stddev?: Maybe<Db_Latency_Stddev_Fields>;
  stddev_pop?: Maybe<Db_Latency_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Db_Latency_Stddev_Samp_Fields>;
  sum?: Maybe<Db_Latency_Sum_Fields>;
  var_pop?: Maybe<Db_Latency_Var_Pop_Fields>;
  var_samp?: Maybe<Db_Latency_Var_Samp_Fields>;
  variance?: Maybe<Db_Latency_Variance_Fields>;
};

/** aggregate fields of "db_latency" */
export type Db_Latency_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Db_Latency_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Db_Latency_Avg_Fields = {
  __typename?: 'db_latency_avg_fields';
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "db_latency". All fields are combined with a logical 'AND'. */
export type Db_Latency_Bool_Exp = {
  _and?: InputMaybe<Array<Db_Latency_Bool_Exp>>;
  _not?: InputMaybe<Db_Latency_Bool_Exp>;
  _or?: InputMaybe<Array<Db_Latency_Bool_Exp>>;
  console_check_duration?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_change_region_clicked?: InputMaybe<Boolean_Comparison_Exp>;
  is_latency_displayed?: InputMaybe<Boolean_Comparison_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "db_latency" */
export enum Db_Latency_Constraint {
  /** unique or primary key constraint on columns "id" */
  DbLatencyPkey = 'db_latency_pkey',
}

/** input type for incrementing numeric columns in table "db_latency" */
export type Db_Latency_Inc_Input = {
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "db_latency" */
export type Db_Latency_Insert_Input = {
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_change_region_clicked?: InputMaybe<Scalars['Boolean']>;
  is_latency_displayed?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Db_Latency_Max_Fields = {
  __typename?: 'db_latency_max_fields';
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Db_Latency_Min_Fields = {
  __typename?: 'db_latency_min_fields';
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "db_latency" */
export type Db_Latency_Mutation_Response = {
  __typename?: 'db_latency_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Db_Latency>;
};

/** on_conflict condition type for table "db_latency" */
export type Db_Latency_On_Conflict = {
  constraint: Db_Latency_Constraint;
  update_columns?: Array<Db_Latency_Update_Column>;
  where?: InputMaybe<Db_Latency_Bool_Exp>;
};

/** Ordering options when selecting data from "db_latency". */
export type Db_Latency_Order_By = {
  console_check_duration?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_change_region_clicked?: InputMaybe<Order_By>;
  is_latency_displayed?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: db_latency */
export type Db_Latency_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "db_latency" */
export enum Db_Latency_Select_Column {
  /** column name */
  ConsoleCheckDuration = 'console_check_duration',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsChangeRegionClicked = 'is_change_region_clicked',
  /** column name */
  IsLatencyDisplayed = 'is_latency_displayed',
  /** column name */
  JobId = 'job_id',
  /** column name */
  ProjectId = 'project_id',
}

/** input type for updating data in table "db_latency" */
export type Db_Latency_Set_Input = {
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_change_region_clicked?: InputMaybe<Scalars['Boolean']>;
  is_latency_displayed?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Db_Latency_Stddev_Fields = {
  __typename?: 'db_latency_stddev_fields';
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Db_Latency_Stddev_Pop_Fields = {
  __typename?: 'db_latency_stddev_pop_fields';
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Db_Latency_Stddev_Samp_Fields = {
  __typename?: 'db_latency_stddev_samp_fields';
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "db_latency" */
export type Db_Latency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Db_Latency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Db_Latency_Stream_Cursor_Value_Input = {
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_change_region_clicked?: InputMaybe<Scalars['Boolean']>;
  is_latency_displayed?: InputMaybe<Scalars['Boolean']>;
  job_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Db_Latency_Sum_Fields = {
  __typename?: 'db_latency_sum_fields';
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: Maybe<Scalars['Int']>;
};

/** update columns of table "db_latency" */
export enum Db_Latency_Update_Column {
  /** column name */
  ConsoleCheckDuration = 'console_check_duration',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsChangeRegionClicked = 'is_change_region_clicked',
  /** column name */
  IsLatencyDisplayed = 'is_latency_displayed',
  /** column name */
  JobId = 'job_id',
  /** column name */
  ProjectId = 'project_id',
}

export type Db_Latency_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Db_Latency_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Db_Latency_Set_Input>;
  /** filter the rows which have to be updated */
  where: Db_Latency_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Db_Latency_Var_Pop_Fields = {
  __typename?: 'db_latency_var_pop_fields';
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Db_Latency_Var_Samp_Fields = {
  __typename?: 'db_latency_var_samp_fields';
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Db_Latency_Variance_Fields = {
  __typename?: 'db_latency_variance_fields';
  /** duration it took for the console to send a request to API and wait for the result by polling jobs table in ms */
  console_check_duration?: Maybe<Scalars['Float']>;
};

export type DdnApplySubgraphBuildOutput = {
  __typename?: 'ddnApplySubgraphBuildOutput';
  graphql_api_endpoint: Scalars['String'];
  supergraph_build_id: Scalars['String'];
};

export type DdnCreateBuildOutput = {
  __typename?: 'ddnCreateBuildOutput';
  build?: Maybe<Ddn_Build>;
  build_id: Scalars['String'];
  build_version: Scalars['String'];
  graphql_api_endpoint: Scalars['String'];
  metadata: Scalars['String'];
};

export type DdnCreateSubgraphBuildOutput = {
  __typename?: 'ddnCreateSubgraphBuildOutput';
  subgraph_build_id: Scalars['String'];
};

/** columns and relationships of "ddn.applied_build_changelog" */
export type Ddn_Applied_Build_Changelog = {
  __typename?: 'ddn_applied_build_changelog';
  build_id: Scalars['uuid'];
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  environment: Scalars['String'];
  id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** aggregated selection of "ddn.applied_build_changelog" */
export type Ddn_Applied_Build_Changelog_Aggregate = {
  __typename?: 'ddn_applied_build_changelog_aggregate';
  aggregate?: Maybe<Ddn_Applied_Build_Changelog_Aggregate_Fields>;
  nodes: Array<Ddn_Applied_Build_Changelog>;
};

/** aggregate fields of "ddn.applied_build_changelog" */
export type Ddn_Applied_Build_Changelog_Aggregate_Fields = {
  __typename?: 'ddn_applied_build_changelog_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Applied_Build_Changelog_Max_Fields>;
  min?: Maybe<Ddn_Applied_Build_Changelog_Min_Fields>;
};

/** aggregate fields of "ddn.applied_build_changelog" */
export type Ddn_Applied_Build_Changelog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Applied_Build_Changelog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.applied_build_changelog". All fields are combined with a logical 'AND'. */
export type Ddn_Applied_Build_Changelog_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Applied_Build_Changelog_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Applied_Build_Changelog_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Applied_Build_Changelog_Bool_Exp>>;
  build_id?: InputMaybe<Uuid_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.applied_build_changelog" */
export enum Ddn_Applied_Build_Changelog_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppliedBuildChangelogPkey = 'applied_build_changelog_pkey',
}

/** input type for inserting data into table "ddn.applied_build_changelog" */
export type Ddn_Applied_Build_Changelog_Insert_Input = {
  build_id?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ddn_Applied_Build_Changelog_Max_Fields = {
  __typename?: 'ddn_applied_build_changelog_max_fields';
  build_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  environment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Ddn_Applied_Build_Changelog_Min_Fields = {
  __typename?: 'ddn_applied_build_changelog_min_fields';
  build_id?: Maybe<Scalars['uuid']>;
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  environment?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "ddn.applied_build_changelog" */
export type Ddn_Applied_Build_Changelog_Mutation_Response = {
  __typename?: 'ddn_applied_build_changelog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Applied_Build_Changelog>;
};

/** on_conflict condition type for table "ddn.applied_build_changelog" */
export type Ddn_Applied_Build_Changelog_On_Conflict = {
  constraint: Ddn_Applied_Build_Changelog_Constraint;
  update_columns?: Array<Ddn_Applied_Build_Changelog_Update_Column>;
  where?: InputMaybe<Ddn_Applied_Build_Changelog_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.applied_build_changelog". */
export type Ddn_Applied_Build_Changelog_Order_By = {
  build_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  environment?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.applied_build_changelog */
export type Ddn_Applied_Build_Changelog_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.applied_build_changelog" */
export enum Ddn_Applied_Build_Changelog_Select_Column {
  /** column name */
  BuildId = 'build_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Environment = 'environment',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
}

/** input type for updating data in table "ddn.applied_build_changelog" */
export type Ddn_Applied_Build_Changelog_Set_Input = {
  build_id?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ddn_applied_build_changelog" */
export type Ddn_Applied_Build_Changelog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Applied_Build_Changelog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Applied_Build_Changelog_Stream_Cursor_Value_Input = {
  build_id?: InputMaybe<Scalars['uuid']>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "ddn.applied_build_changelog" */
export enum Ddn_Applied_Build_Changelog_Update_Column {
  /** column name */
  BuildId = 'build_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Environment = 'environment',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
}

export type Ddn_Applied_Build_Changelog_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Applied_Build_Changelog_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Applied_Build_Changelog_Bool_Exp;
};

/** Log of all subgraph builds marked as applied */
export type Ddn_Applied_Subgraph_Build_Changelog = {
  __typename?: 'ddn_applied_subgraph_build_changelog';
  base_supergraph_build_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  project_id: Scalars['uuid'];
  result_supergraph_build_id: Scalars['uuid'];
  subgraph_build_id: Scalars['uuid'];
};

/** aggregated selection of "ddn.applied_subgraph_build_changelog" */
export type Ddn_Applied_Subgraph_Build_Changelog_Aggregate = {
  __typename?: 'ddn_applied_subgraph_build_changelog_aggregate';
  aggregate?: Maybe<Ddn_Applied_Subgraph_Build_Changelog_Aggregate_Fields>;
  nodes: Array<Ddn_Applied_Subgraph_Build_Changelog>;
};

/** aggregate fields of "ddn.applied_subgraph_build_changelog" */
export type Ddn_Applied_Subgraph_Build_Changelog_Aggregate_Fields = {
  __typename?: 'ddn_applied_subgraph_build_changelog_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Applied_Subgraph_Build_Changelog_Max_Fields>;
  min?: Maybe<Ddn_Applied_Subgraph_Build_Changelog_Min_Fields>;
};

/** aggregate fields of "ddn.applied_subgraph_build_changelog" */
export type Ddn_Applied_Subgraph_Build_Changelog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Ddn_Applied_Subgraph_Build_Changelog_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.applied_subgraph_build_changelog". All fields are combined with a logical 'AND'. */
export type Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp>>;
  base_supergraph_build_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  result_supergraph_build_id?: InputMaybe<Uuid_Comparison_Exp>;
  subgraph_build_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.applied_subgraph_build_changelog" */
export enum Ddn_Applied_Subgraph_Build_Changelog_Constraint {
  /** unique or primary key constraint on columns "id" */
  AppliedSubgraphBuildChangelogPkey = 'applied_subgraph_build_changelog_pkey',
}

/** input type for inserting data into table "ddn.applied_subgraph_build_changelog" */
export type Ddn_Applied_Subgraph_Build_Changelog_Insert_Input = {
  base_supergraph_build_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  result_supergraph_build_id?: InputMaybe<Scalars['uuid']>;
  subgraph_build_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ddn_Applied_Subgraph_Build_Changelog_Max_Fields = {
  __typename?: 'ddn_applied_subgraph_build_changelog_max_fields';
  base_supergraph_build_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  result_supergraph_build_id?: Maybe<Scalars['uuid']>;
  subgraph_build_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Ddn_Applied_Subgraph_Build_Changelog_Min_Fields = {
  __typename?: 'ddn_applied_subgraph_build_changelog_min_fields';
  base_supergraph_build_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  result_supergraph_build_id?: Maybe<Scalars['uuid']>;
  subgraph_build_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "ddn.applied_subgraph_build_changelog" */
export type Ddn_Applied_Subgraph_Build_Changelog_Mutation_Response = {
  __typename?: 'ddn_applied_subgraph_build_changelog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Applied_Subgraph_Build_Changelog>;
};

/** on_conflict condition type for table "ddn.applied_subgraph_build_changelog" */
export type Ddn_Applied_Subgraph_Build_Changelog_On_Conflict = {
  constraint: Ddn_Applied_Subgraph_Build_Changelog_Constraint;
  update_columns?: Array<Ddn_Applied_Subgraph_Build_Changelog_Update_Column>;
  where?: InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.applied_subgraph_build_changelog". */
export type Ddn_Applied_Subgraph_Build_Changelog_Order_By = {
  base_supergraph_build_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  result_supergraph_build_id?: InputMaybe<Order_By>;
  subgraph_build_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.applied_subgraph_build_changelog */
export type Ddn_Applied_Subgraph_Build_Changelog_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.applied_subgraph_build_changelog" */
export enum Ddn_Applied_Subgraph_Build_Changelog_Select_Column {
  /** column name */
  BaseSupergraphBuildId = 'base_supergraph_build_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ResultSupergraphBuildId = 'result_supergraph_build_id',
  /** column name */
  SubgraphBuildId = 'subgraph_build_id',
}

/** input type for updating data in table "ddn.applied_subgraph_build_changelog" */
export type Ddn_Applied_Subgraph_Build_Changelog_Set_Input = {
  base_supergraph_build_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  result_supergraph_build_id?: InputMaybe<Scalars['uuid']>;
  subgraph_build_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ddn_applied_subgraph_build_changelog" */
export type Ddn_Applied_Subgraph_Build_Changelog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Applied_Subgraph_Build_Changelog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Applied_Subgraph_Build_Changelog_Stream_Cursor_Value_Input = {
  base_supergraph_build_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  result_supergraph_build_id?: InputMaybe<Scalars['uuid']>;
  subgraph_build_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "ddn.applied_subgraph_build_changelog" */
export enum Ddn_Applied_Subgraph_Build_Changelog_Update_Column {
  /** column name */
  BaseSupergraphBuildId = 'base_supergraph_build_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ResultSupergraphBuildId = 'result_supergraph_build_id',
  /** column name */
  SubgraphBuildId = 'subgraph_build_id',
}

export type Ddn_Applied_Subgraph_Build_Changelog_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp;
};

/** (Temporary Table) automatically approve project request access until public project feature is live */
export type Ddn_Auto_Approve_Project_Request = {
  __typename?: 'ddn_auto_approve_project_request';
  auto_approve_project_request: Scalars['Boolean'];
  project_id: Scalars['uuid'];
};

/** aggregated selection of "ddn.auto_approve_project_request" */
export type Ddn_Auto_Approve_Project_Request_Aggregate = {
  __typename?: 'ddn_auto_approve_project_request_aggregate';
  aggregate?: Maybe<Ddn_Auto_Approve_Project_Request_Aggregate_Fields>;
  nodes: Array<Ddn_Auto_Approve_Project_Request>;
};

/** aggregate fields of "ddn.auto_approve_project_request" */
export type Ddn_Auto_Approve_Project_Request_Aggregate_Fields = {
  __typename?: 'ddn_auto_approve_project_request_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Auto_Approve_Project_Request_Max_Fields>;
  min?: Maybe<Ddn_Auto_Approve_Project_Request_Min_Fields>;
};

/** aggregate fields of "ddn.auto_approve_project_request" */
export type Ddn_Auto_Approve_Project_Request_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Auto_Approve_Project_Request_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.auto_approve_project_request". All fields are combined with a logical 'AND'. */
export type Ddn_Auto_Approve_Project_Request_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Auto_Approve_Project_Request_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Auto_Approve_Project_Request_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Auto_Approve_Project_Request_Bool_Exp>>;
  auto_approve_project_request?: InputMaybe<Boolean_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.auto_approve_project_request" */
export enum Ddn_Auto_Approve_Project_Request_Constraint {
  /** unique or primary key constraint on columns "project_id", "auto_approve_project_request" */
  AutoApproveProjectRequestPkey = 'auto_approve_project_request_pkey',
}

/** input type for inserting data into table "ddn.auto_approve_project_request" */
export type Ddn_Auto_Approve_Project_Request_Insert_Input = {
  auto_approve_project_request?: InputMaybe<Scalars['Boolean']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ddn_Auto_Approve_Project_Request_Max_Fields = {
  __typename?: 'ddn_auto_approve_project_request_max_fields';
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Ddn_Auto_Approve_Project_Request_Min_Fields = {
  __typename?: 'ddn_auto_approve_project_request_min_fields';
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "ddn.auto_approve_project_request" */
export type Ddn_Auto_Approve_Project_Request_Mutation_Response = {
  __typename?: 'ddn_auto_approve_project_request_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Auto_Approve_Project_Request>;
};

/** on_conflict condition type for table "ddn.auto_approve_project_request" */
export type Ddn_Auto_Approve_Project_Request_On_Conflict = {
  constraint: Ddn_Auto_Approve_Project_Request_Constraint;
  update_columns?: Array<Ddn_Auto_Approve_Project_Request_Update_Column>;
  where?: InputMaybe<Ddn_Auto_Approve_Project_Request_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.auto_approve_project_request". */
export type Ddn_Auto_Approve_Project_Request_Order_By = {
  auto_approve_project_request?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.auto_approve_project_request */
export type Ddn_Auto_Approve_Project_Request_Pk_Columns_Input = {
  auto_approve_project_request: Scalars['Boolean'];
  project_id: Scalars['uuid'];
};

/** select columns of table "ddn.auto_approve_project_request" */
export enum Ddn_Auto_Approve_Project_Request_Select_Column {
  /** column name */
  AutoApproveProjectRequest = 'auto_approve_project_request',
  /** column name */
  ProjectId = 'project_id',
}

/** input type for updating data in table "ddn.auto_approve_project_request" */
export type Ddn_Auto_Approve_Project_Request_Set_Input = {
  auto_approve_project_request?: InputMaybe<Scalars['Boolean']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ddn_auto_approve_project_request" */
export type Ddn_Auto_Approve_Project_Request_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Auto_Approve_Project_Request_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Auto_Approve_Project_Request_Stream_Cursor_Value_Input = {
  auto_approve_project_request?: InputMaybe<Scalars['Boolean']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "ddn.auto_approve_project_request" */
export enum Ddn_Auto_Approve_Project_Request_Update_Column {
  /** column name */
  AutoApproveProjectRequest = 'auto_approve_project_request',
  /** column name */
  ProjectId = 'project_id',
}

export type Ddn_Auto_Approve_Project_Request_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Auto_Approve_Project_Request_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Auto_Approve_Project_Request_Bool_Exp;
};

/** columns and relationships of "ddn.build" */
export type Ddn_Build = {
  __typename?: 'ddn_build';
  /** An object relationship */
  build_author?: Maybe<Ddn_User_Project_Access>;
  /** An array relationship */
  build_revisions: Array<Ddn_Build_Revision>;
  /** An aggregate relationship */
  build_revisions_aggregate: Ddn_Build_Revision_Aggregate;
  /** An array relationship */
  build_sync_statuses: Array<Ddn_Build_Sync_Status>;
  /** An aggregate relationship */
  build_sync_statuses_aggregate: Ddn_Build_Sync_Status_Aggregate;
  /** An array relationship */
  connector_deployments: Array<Ddn_Connector_Deployment>;
  /** An aggregate relationship */
  connector_deployments_aggregate: Ddn_Connector_Deployment_Aggregate;
  connector_links: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  environment?: Maybe<Ddn_Environment>;
  environment_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  environments: Array<Ddn_Environment>;
  /** An aggregate relationship */
  environments_aggregate: Ddn_Environment_Aggregate;
  fqdn: Scalars['String'];
  id: Scalars['uuid'];
  /** this will be later moved to a blob storage */
  metadata_binary?: Maybe<Scalars['bytea']>;
  metadata_raw: Scalars['String'];
  ndc_configs: Scalars['jsonb'];
  parent_span_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  patched_on?: Maybe<Ddn_Build>;
  patched_on_build_id?: Maybe<Scalars['uuid']>;
  patched_with_metadata?: Maybe<Scalars['String']>;
  /** An object relationship */
  project: Ddn_Projects;
  project_id: Scalars['uuid'];
  schema_diff_enabled: Scalars['Boolean'];
  trace_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  version: Scalars['String'];
};

/** columns and relationships of "ddn.build" */
export type Ddn_BuildBuild_RevisionsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Revision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Revision_Order_By>>;
  where?: InputMaybe<Ddn_Build_Revision_Bool_Exp>;
};

/** columns and relationships of "ddn.build" */
export type Ddn_BuildBuild_Revisions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Revision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Revision_Order_By>>;
  where?: InputMaybe<Ddn_Build_Revision_Bool_Exp>;
};

/** columns and relationships of "ddn.build" */
export type Ddn_BuildBuild_Sync_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
};

/** columns and relationships of "ddn.build" */
export type Ddn_BuildBuild_Sync_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
};

/** columns and relationships of "ddn.build" */
export type Ddn_BuildConnector_DeploymentsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Connector_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Connector_Deployment_Order_By>>;
  where?: InputMaybe<Ddn_Connector_Deployment_Bool_Exp>;
};

/** columns and relationships of "ddn.build" */
export type Ddn_BuildConnector_Deployments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Connector_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Connector_Deployment_Order_By>>;
  where?: InputMaybe<Ddn_Connector_Deployment_Bool_Exp>;
};

/** columns and relationships of "ddn.build" */
export type Ddn_BuildConnector_LinksArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ddn.build" */
export type Ddn_BuildEnvironmentsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Environment_Order_By>>;
  where?: InputMaybe<Ddn_Environment_Bool_Exp>;
};

/** columns and relationships of "ddn.build" */
export type Ddn_BuildEnvironments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Environment_Order_By>>;
  where?: InputMaybe<Ddn_Environment_Bool_Exp>;
};

/** columns and relationships of "ddn.build" */
export type Ddn_BuildNdc_ConfigsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "ddn.build" */
export type Ddn_Build_Aggregate = {
  __typename?: 'ddn_build_aggregate';
  aggregate?: Maybe<Ddn_Build_Aggregate_Fields>;
  nodes: Array<Ddn_Build>;
};

export type Ddn_Build_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Ddn_Build_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Ddn_Build_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Ddn_Build_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Build_Aggregate_Bool_Exp_Bool_And = {
  arguments: Ddn_Build_Select_Column_Ddn_Build_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Build_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ddn_Build_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Ddn_Build_Select_Column_Ddn_Build_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Build_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ddn_Build_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Build_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Build_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.build" */
export type Ddn_Build_Aggregate_Fields = {
  __typename?: 'ddn_build_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Build_Max_Fields>;
  min?: Maybe<Ddn_Build_Min_Fields>;
};

/** aggregate fields of "ddn.build" */
export type Ddn_Build_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Build_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.build" */
export type Ddn_Build_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Build_Max_Order_By>;
  min?: InputMaybe<Ddn_Build_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ddn_Build_Append_Input = {
  connector_links?: InputMaybe<Scalars['jsonb']>;
  ndc_configs?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "ddn.build" */
export type Ddn_Build_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Build_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Build_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.build". All fields are combined with a logical 'AND'. */
export type Ddn_Build_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Build_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Build_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Build_Bool_Exp>>;
  build_author?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
  build_revisions?: InputMaybe<Ddn_Build_Revision_Bool_Exp>;
  build_revisions_aggregate?: InputMaybe<Ddn_Build_Revision_Aggregate_Bool_Exp>;
  build_sync_statuses?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
  build_sync_statuses_aggregate?: InputMaybe<
    Ddn_Build_Sync_Status_Aggregate_Bool_Exp
  >;
  connector_deployments?: InputMaybe<Ddn_Connector_Deployment_Bool_Exp>;
  connector_deployments_aggregate?: InputMaybe<
    Ddn_Connector_Deployment_Aggregate_Bool_Exp
  >;
  connector_links?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  environment?: InputMaybe<Ddn_Environment_Bool_Exp>;
  environment_id?: InputMaybe<Uuid_Comparison_Exp>;
  environments?: InputMaybe<Ddn_Environment_Bool_Exp>;
  environments_aggregate?: InputMaybe<Ddn_Environment_Aggregate_Bool_Exp>;
  fqdn?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata_binary?: InputMaybe<Bytea_Comparison_Exp>;
  metadata_raw?: InputMaybe<String_Comparison_Exp>;
  ndc_configs?: InputMaybe<Jsonb_Comparison_Exp>;
  parent_span_id?: InputMaybe<String_Comparison_Exp>;
  patched_on?: InputMaybe<Ddn_Build_Bool_Exp>;
  patched_on_build_id?: InputMaybe<Uuid_Comparison_Exp>;
  patched_with_metadata?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  schema_diff_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  trace_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.build" */
export enum Ddn_Build_Constraint {
  /** unique or primary key constraint on columns "fqdn" */
  BuildFqdnKey = 'build_fqdn_key',
  /** unique or primary key constraint on columns "id" */
  BuildPkey = 'build_pkey',
  /** unique or primary key constraint on columns "project_id", "id" */
  UniqueProjectAndBuild = 'unique_project_and_build',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ddn_Build_Delete_At_Path_Input = {
  connector_links?: InputMaybe<Array<Scalars['String']>>;
  ndc_configs?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ddn_Build_Delete_Elem_Input = {
  connector_links?: InputMaybe<Scalars['Int']>;
  ndc_configs?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ddn_Build_Delete_Key_Input = {
  connector_links?: InputMaybe<Scalars['String']>;
  ndc_configs?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ddn.build_details" */
export type Ddn_Build_Details = {
  __typename?: 'ddn_build_details';
  build_id: Scalars['uuid'];
  command_count: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  details?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  model_count: Scalars['Int'];
  project_id: Scalars['uuid'];
  subgraph: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "ddn.build_details" */
export type Ddn_Build_DetailsDetailsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "ddn.build_details" */
export type Ddn_Build_Details_Aggregate = {
  __typename?: 'ddn_build_details_aggregate';
  aggregate?: Maybe<Ddn_Build_Details_Aggregate_Fields>;
  nodes: Array<Ddn_Build_Details>;
};

/** aggregate fields of "ddn.build_details" */
export type Ddn_Build_Details_Aggregate_Fields = {
  __typename?: 'ddn_build_details_aggregate_fields';
  avg?: Maybe<Ddn_Build_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ddn_Build_Details_Max_Fields>;
  min?: Maybe<Ddn_Build_Details_Min_Fields>;
  stddev?: Maybe<Ddn_Build_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Ddn_Build_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ddn_Build_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Ddn_Build_Details_Sum_Fields>;
  var_pop?: Maybe<Ddn_Build_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Ddn_Build_Details_Var_Samp_Fields>;
  variance?: Maybe<Ddn_Build_Details_Variance_Fields>;
};

/** aggregate fields of "ddn.build_details" */
export type Ddn_Build_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Build_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ddn_Build_Details_Append_Input = {
  details?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Ddn_Build_Details_Avg_Fields = {
  __typename?: 'ddn_build_details_avg_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ddn.build_details". All fields are combined with a logical 'AND'. */
export type Ddn_Build_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Build_Details_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Build_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Build_Details_Bool_Exp>>;
  build_id?: InputMaybe<Uuid_Comparison_Exp>;
  command_count?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  details?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  model_count?: InputMaybe<Int_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  subgraph?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.build_details" */
export enum Ddn_Build_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  BuildDetailsPkey = 'build_details_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ddn_Build_Details_Delete_At_Path_Input = {
  details?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ddn_Build_Details_Delete_Elem_Input = {
  details?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ddn_Build_Details_Delete_Key_Input = {
  details?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "ddn.build_details" */
export type Ddn_Build_Details_Inc_Input = {
  command_count?: InputMaybe<Scalars['Int']>;
  model_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ddn.build_details" */
export type Ddn_Build_Details_Insert_Input = {
  build_id?: InputMaybe<Scalars['uuid']>;
  command_count?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  model_count?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  subgraph?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Build_Details_Max_Fields = {
  __typename?: 'ddn_build_details_max_fields';
  build_id?: Maybe<Scalars['uuid']>;
  command_count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  model_count?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  subgraph?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Ddn_Build_Details_Min_Fields = {
  __typename?: 'ddn_build_details_min_fields';
  build_id?: Maybe<Scalars['uuid']>;
  command_count?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  model_count?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  subgraph?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ddn.build_details" */
export type Ddn_Build_Details_Mutation_Response = {
  __typename?: 'ddn_build_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Build_Details>;
};

/** on_conflict condition type for table "ddn.build_details" */
export type Ddn_Build_Details_On_Conflict = {
  constraint: Ddn_Build_Details_Constraint;
  update_columns?: Array<Ddn_Build_Details_Update_Column>;
  where?: InputMaybe<Ddn_Build_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.build_details". */
export type Ddn_Build_Details_Order_By = {
  build_id?: InputMaybe<Order_By>;
  command_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_count?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  subgraph?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.build_details */
export type Ddn_Build_Details_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ddn_Build_Details_Prepend_Input = {
  details?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "ddn.build_details" */
export enum Ddn_Build_Details_Select_Column {
  /** column name */
  BuildId = 'build_id',
  /** column name */
  CommandCount = 'command_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  ModelCount = 'model_count',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Subgraph = 'subgraph',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.build_details" */
export type Ddn_Build_Details_Set_Input = {
  build_id?: InputMaybe<Scalars['uuid']>;
  command_count?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  model_count?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  subgraph?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Ddn_Build_Details_Stddev_Fields = {
  __typename?: 'ddn_build_details_stddev_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ddn_Build_Details_Stddev_Pop_Fields = {
  __typename?: 'ddn_build_details_stddev_pop_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ddn_Build_Details_Stddev_Samp_Fields = {
  __typename?: 'ddn_build_details_stddev_samp_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ddn_build_details" */
export type Ddn_Build_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Build_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Build_Details_Stream_Cursor_Value_Input = {
  build_id?: InputMaybe<Scalars['uuid']>;
  command_count?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  model_count?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  subgraph?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Ddn_Build_Details_Sum_Fields = {
  __typename?: 'ddn_build_details_sum_fields';
  command_count?: Maybe<Scalars['Int']>;
  model_count?: Maybe<Scalars['Int']>;
};

/** update columns of table "ddn.build_details" */
export enum Ddn_Build_Details_Update_Column {
  /** column name */
  BuildId = 'build_id',
  /** column name */
  CommandCount = 'command_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  ModelCount = 'model_count',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Subgraph = 'subgraph',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Build_Details_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ddn_Build_Details_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ddn_Build_Details_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ddn_Build_Details_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ddn_Build_Details_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ddn_Build_Details_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ddn_Build_Details_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Build_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Build_Details_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ddn_Build_Details_Var_Pop_Fields = {
  __typename?: 'ddn_build_details_var_pop_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ddn_Build_Details_Var_Samp_Fields = {
  __typename?: 'ddn_build_details_var_samp_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ddn_Build_Details_Variance_Fields = {
  __typename?: 'ddn_build_details_variance_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "ddn.build" */
export type Ddn_Build_Insert_Input = {
  build_author?: InputMaybe<Ddn_User_Project_Access_Obj_Rel_Insert_Input>;
  build_revisions?: InputMaybe<Ddn_Build_Revision_Arr_Rel_Insert_Input>;
  build_sync_statuses?: InputMaybe<Ddn_Build_Sync_Status_Arr_Rel_Insert_Input>;
  connector_deployments?: InputMaybe<
    Ddn_Connector_Deployment_Arr_Rel_Insert_Input
  >;
  connector_links?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Ddn_Environment_Obj_Rel_Insert_Input>;
  environment_id?: InputMaybe<Scalars['uuid']>;
  environments?: InputMaybe<Ddn_Environment_Arr_Rel_Insert_Input>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** this will be later moved to a blob storage */
  metadata_binary?: InputMaybe<Scalars['bytea']>;
  metadata_raw?: InputMaybe<Scalars['String']>;
  ndc_configs?: InputMaybe<Scalars['jsonb']>;
  parent_span_id?: InputMaybe<Scalars['String']>;
  patched_on?: InputMaybe<Ddn_Build_Obj_Rel_Insert_Input>;
  patched_on_build_id?: InputMaybe<Scalars['uuid']>;
  patched_with_metadata?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  schema_diff_enabled?: InputMaybe<Scalars['Boolean']>;
  trace_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Each row in this table locks the builds in a ddn project in a particular environment */
export type Ddn_Build_Lock = {
  __typename?: 'ddn_build_lock';
  acquired_at: Scalars['timestamptz'];
  environment_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** aggregated selection of "ddn.build_lock" */
export type Ddn_Build_Lock_Aggregate = {
  __typename?: 'ddn_build_lock_aggregate';
  aggregate?: Maybe<Ddn_Build_Lock_Aggregate_Fields>;
  nodes: Array<Ddn_Build_Lock>;
};

/** aggregate fields of "ddn.build_lock" */
export type Ddn_Build_Lock_Aggregate_Fields = {
  __typename?: 'ddn_build_lock_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Build_Lock_Max_Fields>;
  min?: Maybe<Ddn_Build_Lock_Min_Fields>;
};

/** aggregate fields of "ddn.build_lock" */
export type Ddn_Build_Lock_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Build_Lock_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.build_lock". All fields are combined with a logical 'AND'. */
export type Ddn_Build_Lock_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Build_Lock_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Build_Lock_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Build_Lock_Bool_Exp>>;
  acquired_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.build_lock" */
export enum Ddn_Build_Lock_Constraint {
  /** unique or primary key constraint on columns "project_id", "environment_id" */
  BuildLockPkey = 'build_lock_pkey',
}

/** input type for inserting data into table "ddn.build_lock" */
export type Ddn_Build_Lock_Insert_Input = {
  acquired_at?: InputMaybe<Scalars['timestamptz']>;
  environment_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ddn_Build_Lock_Max_Fields = {
  __typename?: 'ddn_build_lock_max_fields';
  acquired_at?: Maybe<Scalars['timestamptz']>;
  environment_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Ddn_Build_Lock_Min_Fields = {
  __typename?: 'ddn_build_lock_min_fields';
  acquired_at?: Maybe<Scalars['timestamptz']>;
  environment_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "ddn.build_lock" */
export type Ddn_Build_Lock_Mutation_Response = {
  __typename?: 'ddn_build_lock_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Build_Lock>;
};

/** on_conflict condition type for table "ddn.build_lock" */
export type Ddn_Build_Lock_On_Conflict = {
  constraint: Ddn_Build_Lock_Constraint;
  update_columns?: Array<Ddn_Build_Lock_Update_Column>;
  where?: InputMaybe<Ddn_Build_Lock_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.build_lock". */
export type Ddn_Build_Lock_Order_By = {
  acquired_at?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.build_lock */
export type Ddn_Build_Lock_Pk_Columns_Input = {
  environment_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** select columns of table "ddn.build_lock" */
export enum Ddn_Build_Lock_Select_Column {
  /** column name */
  AcquiredAt = 'acquired_at',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  ProjectId = 'project_id',
}

/** input type for updating data in table "ddn.build_lock" */
export type Ddn_Build_Lock_Set_Input = {
  acquired_at?: InputMaybe<Scalars['timestamptz']>;
  environment_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ddn_build_lock" */
export type Ddn_Build_Lock_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Build_Lock_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Build_Lock_Stream_Cursor_Value_Input = {
  acquired_at?: InputMaybe<Scalars['timestamptz']>;
  environment_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "ddn.build_lock" */
export enum Ddn_Build_Lock_Update_Column {
  /** column name */
  AcquiredAt = 'acquired_at',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  ProjectId = 'project_id',
}

export type Ddn_Build_Lock_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Build_Lock_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Build_Lock_Bool_Exp;
};

/** aggregate max on columns */
export type Ddn_Build_Max_Fields = {
  __typename?: 'ddn_build_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  environment_id?: Maybe<Scalars['uuid']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata_raw?: Maybe<Scalars['String']>;
  parent_span_id?: Maybe<Scalars['String']>;
  patched_on_build_id?: Maybe<Scalars['uuid']>;
  patched_with_metadata?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  trace_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "ddn.build" */
export type Ddn_Build_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata_raw?: InputMaybe<Order_By>;
  parent_span_id?: InputMaybe<Order_By>;
  patched_on_build_id?: InputMaybe<Order_By>;
  patched_with_metadata?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  trace_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Build_Min_Fields = {
  __typename?: 'ddn_build_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  environment_id?: Maybe<Scalars['uuid']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  metadata_raw?: Maybe<Scalars['String']>;
  parent_span_id?: Maybe<Scalars['String']>;
  patched_on_build_id?: Maybe<Scalars['uuid']>;
  patched_with_metadata?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  trace_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "ddn.build" */
export type Ddn_Build_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata_raw?: InputMaybe<Order_By>;
  parent_span_id?: InputMaybe<Order_By>;
  patched_on_build_id?: InputMaybe<Order_By>;
  patched_with_metadata?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  trace_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** columns and relationships of "ddn.build_model_count" */
export type Ddn_Build_Model_Count = {
  __typename?: 'ddn_build_model_count';
  build_id?: Maybe<Scalars['uuid']>;
  command_count?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  model_count?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "ddn.build_model_count" */
export type Ddn_Build_Model_Count_Aggregate = {
  __typename?: 'ddn_build_model_count_aggregate';
  aggregate?: Maybe<Ddn_Build_Model_Count_Aggregate_Fields>;
  nodes: Array<Ddn_Build_Model_Count>;
};

/** aggregate fields of "ddn.build_model_count" */
export type Ddn_Build_Model_Count_Aggregate_Fields = {
  __typename?: 'ddn_build_model_count_aggregate_fields';
  avg?: Maybe<Ddn_Build_Model_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ddn_Build_Model_Count_Max_Fields>;
  min?: Maybe<Ddn_Build_Model_Count_Min_Fields>;
  stddev?: Maybe<Ddn_Build_Model_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Ddn_Build_Model_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ddn_Build_Model_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Ddn_Build_Model_Count_Sum_Fields>;
  var_pop?: Maybe<Ddn_Build_Model_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Ddn_Build_Model_Count_Var_Samp_Fields>;
  variance?: Maybe<Ddn_Build_Model_Count_Variance_Fields>;
};

/** aggregate fields of "ddn.build_model_count" */
export type Ddn_Build_Model_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Build_Model_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ddn_Build_Model_Count_Avg_Fields = {
  __typename?: 'ddn_build_model_count_avg_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ddn.build_model_count". All fields are combined with a logical 'AND'. */
export type Ddn_Build_Model_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Build_Model_Count_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Build_Model_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Build_Model_Count_Bool_Exp>>;
  build_id?: InputMaybe<Uuid_Comparison_Exp>;
  command_count?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  model_count?: InputMaybe<Bigint_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Ddn_Build_Model_Count_Max_Fields = {
  __typename?: 'ddn_build_model_count_max_fields';
  build_id?: Maybe<Scalars['uuid']>;
  command_count?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  model_count?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Ddn_Build_Model_Count_Min_Fields = {
  __typename?: 'ddn_build_model_count_min_fields';
  build_id?: Maybe<Scalars['uuid']>;
  command_count?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  model_count?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "ddn.build_model_count". */
export type Ddn_Build_Model_Count_Order_By = {
  build_id?: InputMaybe<Order_By>;
  command_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  model_count?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** select columns of table "ddn.build_model_count" */
export enum Ddn_Build_Model_Count_Select_Column {
  /** column name */
  BuildId = 'build_id',
  /** column name */
  CommandCount = 'command_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ModelCount = 'model_count',
  /** column name */
  ProjectId = 'project_id',
}

/** aggregate stddev on columns */
export type Ddn_Build_Model_Count_Stddev_Fields = {
  __typename?: 'ddn_build_model_count_stddev_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ddn_Build_Model_Count_Stddev_Pop_Fields = {
  __typename?: 'ddn_build_model_count_stddev_pop_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ddn_Build_Model_Count_Stddev_Samp_Fields = {
  __typename?: 'ddn_build_model_count_stddev_samp_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ddn_build_model_count" */
export type Ddn_Build_Model_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Build_Model_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Build_Model_Count_Stream_Cursor_Value_Input = {
  build_id?: InputMaybe<Scalars['uuid']>;
  command_count?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  model_count?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Ddn_Build_Model_Count_Sum_Fields = {
  __typename?: 'ddn_build_model_count_sum_fields';
  command_count?: Maybe<Scalars['bigint']>;
  model_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Ddn_Build_Model_Count_Var_Pop_Fields = {
  __typename?: 'ddn_build_model_count_var_pop_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ddn_Build_Model_Count_Var_Samp_Fields = {
  __typename?: 'ddn_build_model_count_var_samp_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ddn_Build_Model_Count_Variance_Fields = {
  __typename?: 'ddn_build_model_count_variance_fields';
  command_count?: Maybe<Scalars['Float']>;
  model_count?: Maybe<Scalars['Float']>;
};

/** response of any mutation on the table "ddn.build" */
export type Ddn_Build_Mutation_Response = {
  __typename?: 'ddn_build_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Build>;
};

/** input type for inserting object relation for remote table "ddn.build" */
export type Ddn_Build_Obj_Rel_Insert_Input = {
  data: Ddn_Build_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Build_On_Conflict>;
};

/** on_conflict condition type for table "ddn.build" */
export type Ddn_Build_On_Conflict = {
  constraint: Ddn_Build_Constraint;
  update_columns?: Array<Ddn_Build_Update_Column>;
  where?: InputMaybe<Ddn_Build_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.build". */
export type Ddn_Build_Order_By = {
  build_author?: InputMaybe<Ddn_User_Project_Access_Order_By>;
  build_revisions_aggregate?: InputMaybe<Ddn_Build_Revision_Aggregate_Order_By>;
  build_sync_statuses_aggregate?: InputMaybe<
    Ddn_Build_Sync_Status_Aggregate_Order_By
  >;
  connector_deployments_aggregate?: InputMaybe<
    Ddn_Connector_Deployment_Aggregate_Order_By
  >;
  connector_links?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  environment?: InputMaybe<Ddn_Environment_Order_By>;
  environment_id?: InputMaybe<Order_By>;
  environments_aggregate?: InputMaybe<Ddn_Environment_Aggregate_Order_By>;
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata_binary?: InputMaybe<Order_By>;
  metadata_raw?: InputMaybe<Order_By>;
  ndc_configs?: InputMaybe<Order_By>;
  parent_span_id?: InputMaybe<Order_By>;
  patched_on?: InputMaybe<Ddn_Build_Order_By>;
  patched_on_build_id?: InputMaybe<Order_By>;
  patched_with_metadata?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  schema_diff_enabled?: InputMaybe<Order_By>;
  trace_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.build */
export type Ddn_Build_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ddn_Build_Prepend_Input = {
  connector_links?: InputMaybe<Scalars['jsonb']>;
  ndc_configs?: InputMaybe<Scalars['jsonb']>;
};

/** A build can have multiple revisions. This table tracks revisions for builds */
export type Ddn_Build_Revision = {
  __typename?: 'ddn_build_revision';
  /** An object relationship */
  build: Ddn_Build;
  build_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  multitenant_sha?: Maybe<Scalars['String']>;
  status: Ddn_Build_Revision_Status_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ddn.build_revision" */
export type Ddn_Build_Revision_Aggregate = {
  __typename?: 'ddn_build_revision_aggregate';
  aggregate?: Maybe<Ddn_Build_Revision_Aggregate_Fields>;
  nodes: Array<Ddn_Build_Revision>;
};

export type Ddn_Build_Revision_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Build_Revision_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Build_Revision_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Build_Revision_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Build_Revision_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.build_revision" */
export type Ddn_Build_Revision_Aggregate_Fields = {
  __typename?: 'ddn_build_revision_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Build_Revision_Max_Fields>;
  min?: Maybe<Ddn_Build_Revision_Min_Fields>;
};

/** aggregate fields of "ddn.build_revision" */
export type Ddn_Build_Revision_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Build_Revision_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.build_revision" */
export type Ddn_Build_Revision_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Build_Revision_Max_Order_By>;
  min?: InputMaybe<Ddn_Build_Revision_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.build_revision" */
export type Ddn_Build_Revision_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Build_Revision_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Build_Revision_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.build_revision". All fields are combined with a logical 'AND'. */
export type Ddn_Build_Revision_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Build_Revision_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Build_Revision_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Build_Revision_Bool_Exp>>;
  build?: InputMaybe<Ddn_Build_Bool_Exp>;
  build_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  multitenant_sha?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Ddn_Build_Revision_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.build_revision" */
export enum Ddn_Build_Revision_Constraint {
  /** unique or primary key constraint on columns "id" */
  BuildRevisionPkey = 'build_revision_pkey',
}

/** input type for inserting data into table "ddn.build_revision" */
export type Ddn_Build_Revision_Insert_Input = {
  build?: InputMaybe<Ddn_Build_Obj_Rel_Insert_Input>;
  build_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  multitenant_sha?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ddn_Build_Revision_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Build_Revision_Max_Fields = {
  __typename?: 'ddn_build_revision_max_fields';
  build_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  multitenant_sha?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ddn.build_revision" */
export type Ddn_Build_Revision_Max_Order_By = {
  build_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  multitenant_sha?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Build_Revision_Min_Fields = {
  __typename?: 'ddn_build_revision_min_fields';
  build_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  multitenant_sha?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ddn.build_revision" */
export type Ddn_Build_Revision_Min_Order_By = {
  build_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  multitenant_sha?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.build_revision" */
export type Ddn_Build_Revision_Mutation_Response = {
  __typename?: 'ddn_build_revision_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Build_Revision>;
};

/** on_conflict condition type for table "ddn.build_revision" */
export type Ddn_Build_Revision_On_Conflict = {
  constraint: Ddn_Build_Revision_Constraint;
  update_columns?: Array<Ddn_Build_Revision_Update_Column>;
  where?: InputMaybe<Ddn_Build_Revision_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.build_revision". */
export type Ddn_Build_Revision_Order_By = {
  build?: InputMaybe<Ddn_Build_Order_By>;
  build_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  multitenant_sha?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.build_revision */
export type Ddn_Build_Revision_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.build_revision" */
export enum Ddn_Build_Revision_Select_Column {
  /** column name */
  BuildId = 'build_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MultitenantSha = 'multitenant_sha',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.build_revision" */
export type Ddn_Build_Revision_Set_Input = {
  build_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  multitenant_sha?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ddn_Build_Revision_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "ddn.build_revision_status" */
export type Ddn_Build_Revision_Status = {
  __typename?: 'ddn_build_revision_status';
  description: Scalars['String'];
  status: Scalars['String'];
};

/** aggregated selection of "ddn.build_revision_status" */
export type Ddn_Build_Revision_Status_Aggregate = {
  __typename?: 'ddn_build_revision_status_aggregate';
  aggregate?: Maybe<Ddn_Build_Revision_Status_Aggregate_Fields>;
  nodes: Array<Ddn_Build_Revision_Status>;
};

/** aggregate fields of "ddn.build_revision_status" */
export type Ddn_Build_Revision_Status_Aggregate_Fields = {
  __typename?: 'ddn_build_revision_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Build_Revision_Status_Max_Fields>;
  min?: Maybe<Ddn_Build_Revision_Status_Min_Fields>;
};

/** aggregate fields of "ddn.build_revision_status" */
export type Ddn_Build_Revision_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Build_Revision_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.build_revision_status". All fields are combined with a logical 'AND'. */
export type Ddn_Build_Revision_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Build_Revision_Status_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Build_Revision_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Build_Revision_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.build_revision_status" */
export enum Ddn_Build_Revision_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  BuildRevisionStatusPkey = 'build_revision_status_pkey',
}

export enum Ddn_Build_Revision_Status_Enum {
  /** creating revision failed */
  Failed = 'failed',
  /** revision is being processed */
  Queued = 'queued',
  /** creating revision was successful */
  Success = 'success',
}

/** Boolean expression to compare columns of type "ddn_build_revision_status_enum". All fields are combined with logical 'AND'. */
export type Ddn_Build_Revision_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ddn_Build_Revision_Status_Enum>;
  _in?: InputMaybe<Array<Ddn_Build_Revision_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ddn_Build_Revision_Status_Enum>;
  _nin?: InputMaybe<Array<Ddn_Build_Revision_Status_Enum>>;
};

/** input type for inserting data into table "ddn.build_revision_status" */
export type Ddn_Build_Revision_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ddn_Build_Revision_Status_Max_Fields = {
  __typename?: 'ddn_build_revision_status_max_fields';
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ddn_Build_Revision_Status_Min_Fields = {
  __typename?: 'ddn_build_revision_status_min_fields';
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ddn.build_revision_status" */
export type Ddn_Build_Revision_Status_Mutation_Response = {
  __typename?: 'ddn_build_revision_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Build_Revision_Status>;
};

/** on_conflict condition type for table "ddn.build_revision_status" */
export type Ddn_Build_Revision_Status_On_Conflict = {
  constraint: Ddn_Build_Revision_Status_Constraint;
  update_columns?: Array<Ddn_Build_Revision_Status_Update_Column>;
  where?: InputMaybe<Ddn_Build_Revision_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.build_revision_status". */
export type Ddn_Build_Revision_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.build_revision_status */
export type Ddn_Build_Revision_Status_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "ddn.build_revision_status" */
export enum Ddn_Build_Revision_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "ddn.build_revision_status" */
export type Ddn_Build_Revision_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ddn_build_revision_status" */
export type Ddn_Build_Revision_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Build_Revision_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Build_Revision_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ddn.build_revision_status" */
export enum Ddn_Build_Revision_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Status = 'status',
}

export type Ddn_Build_Revision_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Build_Revision_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Build_Revision_Status_Bool_Exp;
};

/** Streaming cursor of the table "ddn_build_revision" */
export type Ddn_Build_Revision_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Build_Revision_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Build_Revision_Stream_Cursor_Value_Input = {
  build_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  multitenant_sha?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Ddn_Build_Revision_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.build_revision" */
export enum Ddn_Build_Revision_Update_Column {
  /** column name */
  BuildId = 'build_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  MultitenantSha = 'multitenant_sha',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Build_Revision_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Build_Revision_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Build_Revision_Bool_Exp;
};

/** select columns of table "ddn.build" */
export enum Ddn_Build_Select_Column {
  /** column name */
  ConnectorLinks = 'connector_links',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  MetadataBinary = 'metadata_binary',
  /** column name */
  MetadataRaw = 'metadata_raw',
  /** column name */
  NdcConfigs = 'ndc_configs',
  /** column name */
  ParentSpanId = 'parent_span_id',
  /** column name */
  PatchedOnBuildId = 'patched_on_build_id',
  /** column name */
  PatchedWithMetadata = 'patched_with_metadata',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SchemaDiffEnabled = 'schema_diff_enabled',
  /** column name */
  TraceId = 'trace_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version',
}

/** select "ddn_build_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ddn.build" */
export enum Ddn_Build_Select_Column_Ddn_Build_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  SchemaDiffEnabled = 'schema_diff_enabled',
}

/** select "ddn_build_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ddn.build" */
export enum Ddn_Build_Select_Column_Ddn_Build_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  SchemaDiffEnabled = 'schema_diff_enabled',
}

/** input type for updating data in table "ddn.build" */
export type Ddn_Build_Set_Input = {
  connector_links?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  environment_id?: InputMaybe<Scalars['uuid']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** this will be later moved to a blob storage */
  metadata_binary?: InputMaybe<Scalars['bytea']>;
  metadata_raw?: InputMaybe<Scalars['String']>;
  ndc_configs?: InputMaybe<Scalars['jsonb']>;
  parent_span_id?: InputMaybe<Scalars['String']>;
  patched_on_build_id?: InputMaybe<Scalars['uuid']>;
  patched_with_metadata?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  schema_diff_enabled?: InputMaybe<Scalars['Boolean']>;
  trace_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ddn_build" */
export type Ddn_Build_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Build_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Build_Stream_Cursor_Value_Input = {
  connector_links?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  environment_id?: InputMaybe<Scalars['uuid']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** this will be later moved to a blob storage */
  metadata_binary?: InputMaybe<Scalars['bytea']>;
  metadata_raw?: InputMaybe<Scalars['String']>;
  ndc_configs?: InputMaybe<Scalars['jsonb']>;
  parent_span_id?: InputMaybe<Scalars['String']>;
  patched_on_build_id?: InputMaybe<Scalars['uuid']>;
  patched_with_metadata?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  schema_diff_enabled?: InputMaybe<Scalars['Boolean']>;
  trace_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ddn.build_sync_status" */
export type Ddn_Build_Sync_Status = {
  __typename?: 'ddn_build_sync_status';
  /** An object relationship */
  build: Ddn_Build;
  build_id: Scalars['uuid'];
  /** An object relationship */
  build_sync_worker: Ddn_Build_Sync_Worker;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  environment?: Maybe<Ddn_Environment>;
  environment_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  status?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  worker_id: Scalars['uuid'];
};

/** aggregated selection of "ddn.build_sync_status" */
export type Ddn_Build_Sync_Status_Aggregate = {
  __typename?: 'ddn_build_sync_status_aggregate';
  aggregate?: Maybe<Ddn_Build_Sync_Status_Aggregate_Fields>;
  nodes: Array<Ddn_Build_Sync_Status>;
};

export type Ddn_Build_Sync_Status_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Build_Sync_Status_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Build_Sync_Status_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Build_Sync_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.build_sync_status" */
export type Ddn_Build_Sync_Status_Aggregate_Fields = {
  __typename?: 'ddn_build_sync_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Build_Sync_Status_Max_Fields>;
  min?: Maybe<Ddn_Build_Sync_Status_Min_Fields>;
};

/** aggregate fields of "ddn.build_sync_status" */
export type Ddn_Build_Sync_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Build_Sync_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.build_sync_status" */
export type Ddn_Build_Sync_Status_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Build_Sync_Status_Max_Order_By>;
  min?: InputMaybe<Ddn_Build_Sync_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.build_sync_status" */
export type Ddn_Build_Sync_Status_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Build_Sync_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Build_Sync_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.build_sync_status". All fields are combined with a logical 'AND'. */
export type Ddn_Build_Sync_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Build_Sync_Status_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Build_Sync_Status_Bool_Exp>>;
  build?: InputMaybe<Ddn_Build_Bool_Exp>;
  build_id?: InputMaybe<Uuid_Comparison_Exp>;
  build_sync_worker?: InputMaybe<Ddn_Build_Sync_Worker_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment?: InputMaybe<Ddn_Environment_Bool_Exp>;
  environment_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  worker_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.build_sync_status" */
export enum Ddn_Build_Sync_Status_Constraint {
  /** unique or primary key constraint on columns "build_id", "environment_id", "worker_id" */
  BuildSyncStatusBuildIdWorkerIdEnvironmentIdKey = 'build_sync_status_build_id_worker_id_environment_id_key',
  /** unique or primary key constraint on columns "id" */
  BuildSyncStatusPkey = 'build_sync_status_pkey',
}

/** input type for inserting data into table "ddn.build_sync_status" */
export type Ddn_Build_Sync_Status_Insert_Input = {
  build?: InputMaybe<Ddn_Build_Obj_Rel_Insert_Input>;
  build_id?: InputMaybe<Scalars['uuid']>;
  build_sync_worker?: InputMaybe<Ddn_Build_Sync_Worker_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment?: InputMaybe<Ddn_Environment_Obj_Rel_Insert_Input>;
  environment_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ddn_Build_Sync_Status_Max_Fields = {
  __typename?: 'ddn_build_sync_status_max_fields';
  build_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  environment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ddn.build_sync_status" */
export type Ddn_Build_Sync_Status_Max_Order_By = {
  build_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Build_Sync_Status_Min_Fields = {
  __typename?: 'ddn_build_sync_status_min_fields';
  build_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  environment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ddn.build_sync_status" */
export type Ddn_Build_Sync_Status_Min_Order_By = {
  build_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.build_sync_status" */
export type Ddn_Build_Sync_Status_Mutation_Response = {
  __typename?: 'ddn_build_sync_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Build_Sync_Status>;
};

/** on_conflict condition type for table "ddn.build_sync_status" */
export type Ddn_Build_Sync_Status_On_Conflict = {
  constraint: Ddn_Build_Sync_Status_Constraint;
  update_columns?: Array<Ddn_Build_Sync_Status_Update_Column>;
  where?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.build_sync_status". */
export type Ddn_Build_Sync_Status_Order_By = {
  build?: InputMaybe<Ddn_Build_Order_By>;
  build_id?: InputMaybe<Order_By>;
  build_sync_worker?: InputMaybe<Ddn_Build_Sync_Worker_Order_By>;
  created_at?: InputMaybe<Order_By>;
  environment?: InputMaybe<Ddn_Environment_Order_By>;
  environment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.build_sync_status */
export type Ddn_Build_Sync_Status_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.build_sync_status" */
export enum Ddn_Build_Sync_Status_Select_Column {
  /** column name */
  BuildId = 'build_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkerId = 'worker_id',
}

/** input type for updating data in table "ddn.build_sync_status" */
export type Ddn_Build_Sync_Status_Set_Input = {
  build_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ddn_build_sync_status" */
export type Ddn_Build_Sync_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Build_Sync_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Build_Sync_Status_Stream_Cursor_Value_Input = {
  build_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "ddn.build_sync_status" */
export enum Ddn_Build_Sync_Status_Update_Column {
  /** column name */
  BuildId = 'build_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkerId = 'worker_id',
}

export type Ddn_Build_Sync_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Build_Sync_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Build_Sync_Status_Bool_Exp;
};

/** columns and relationships of "ddn.build_sync_worker" */
export type Ddn_Build_Sync_Worker = {
  __typename?: 'ddn_build_sync_worker';
  /** An array relationship */
  build_sync_statuses: Array<Ddn_Build_Sync_Status>;
  /** An aggregate relationship */
  build_sync_statuses_aggregate: Ddn_Build_Sync_Status_Aggregate;
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  region?: Maybe<Scalars['String']>;
  /** An object relationship */
  regionByCloudRegion?: Maybe<Region>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "ddn.build_sync_worker" */
export type Ddn_Build_Sync_WorkerBuild_Sync_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
};

/** columns and relationships of "ddn.build_sync_worker" */
export type Ddn_Build_Sync_WorkerBuild_Sync_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
};

/** aggregated selection of "ddn.build_sync_worker" */
export type Ddn_Build_Sync_Worker_Aggregate = {
  __typename?: 'ddn_build_sync_worker_aggregate';
  aggregate?: Maybe<Ddn_Build_Sync_Worker_Aggregate_Fields>;
  nodes: Array<Ddn_Build_Sync_Worker>;
};

/** aggregate fields of "ddn.build_sync_worker" */
export type Ddn_Build_Sync_Worker_Aggregate_Fields = {
  __typename?: 'ddn_build_sync_worker_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Build_Sync_Worker_Max_Fields>;
  min?: Maybe<Ddn_Build_Sync_Worker_Min_Fields>;
};

/** aggregate fields of "ddn.build_sync_worker" */
export type Ddn_Build_Sync_Worker_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Build_Sync_Worker_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.build_sync_worker". All fields are combined with a logical 'AND'. */
export type Ddn_Build_Sync_Worker_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Build_Sync_Worker_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Build_Sync_Worker_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Build_Sync_Worker_Bool_Exp>>;
  build_sync_statuses?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
  build_sync_statuses_aggregate?: InputMaybe<
    Ddn_Build_Sync_Status_Aggregate_Bool_Exp
  >;
  cloud?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  regionByCloudRegion?: InputMaybe<Region_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.build_sync_worker" */
export enum Ddn_Build_Sync_Worker_Constraint {
  /** unique or primary key constraint on columns "id" */
  BuildSyncWorkerPkey = 'build_sync_worker_pkey',
}

/** input type for inserting data into table "ddn.build_sync_worker" */
export type Ddn_Build_Sync_Worker_Insert_Input = {
  build_sync_statuses?: InputMaybe<Ddn_Build_Sync_Status_Arr_Rel_Insert_Input>;
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['String']>;
  regionByCloudRegion?: InputMaybe<Region_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Build_Sync_Worker_Max_Fields = {
  __typename?: 'ddn_build_sync_worker_max_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Ddn_Build_Sync_Worker_Min_Fields = {
  __typename?: 'ddn_build_sync_worker_min_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ddn.build_sync_worker" */
export type Ddn_Build_Sync_Worker_Mutation_Response = {
  __typename?: 'ddn_build_sync_worker_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Build_Sync_Worker>;
};

/** input type for inserting object relation for remote table "ddn.build_sync_worker" */
export type Ddn_Build_Sync_Worker_Obj_Rel_Insert_Input = {
  data: Ddn_Build_Sync_Worker_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Build_Sync_Worker_On_Conflict>;
};

/** on_conflict condition type for table "ddn.build_sync_worker" */
export type Ddn_Build_Sync_Worker_On_Conflict = {
  constraint: Ddn_Build_Sync_Worker_Constraint;
  update_columns?: Array<Ddn_Build_Sync_Worker_Update_Column>;
  where?: InputMaybe<Ddn_Build_Sync_Worker_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.build_sync_worker". */
export type Ddn_Build_Sync_Worker_Order_By = {
  build_sync_statuses_aggregate?: InputMaybe<
    Ddn_Build_Sync_Status_Aggregate_Order_By
  >;
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  regionByCloudRegion?: InputMaybe<Region_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.build_sync_worker */
export type Ddn_Build_Sync_Worker_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.build_sync_worker" */
export enum Ddn_Build_Sync_Worker_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.build_sync_worker" */
export type Ddn_Build_Sync_Worker_Set_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_build_sync_worker" */
export type Ddn_Build_Sync_Worker_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Build_Sync_Worker_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Build_Sync_Worker_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.build_sync_worker" */
export enum Ddn_Build_Sync_Worker_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Build_Sync_Worker_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Build_Sync_Worker_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Build_Sync_Worker_Bool_Exp;
};

/** update columns of table "ddn.build" */
export enum Ddn_Build_Update_Column {
  /** column name */
  ConnectorLinks = 'connector_links',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  MetadataBinary = 'metadata_binary',
  /** column name */
  MetadataRaw = 'metadata_raw',
  /** column name */
  NdcConfigs = 'ndc_configs',
  /** column name */
  ParentSpanId = 'parent_span_id',
  /** column name */
  PatchedOnBuildId = 'patched_on_build_id',
  /** column name */
  PatchedWithMetadata = 'patched_with_metadata',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SchemaDiffEnabled = 'schema_diff_enabled',
  /** column name */
  TraceId = 'trace_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version',
}

export type Ddn_Build_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ddn_Build_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ddn_Build_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ddn_Build_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ddn_Build_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ddn_Build_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Build_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Build_Bool_Exp;
};

/** columns and relationships of "ddn.collaboration_invitee_project_view" */
export type Ddn_Collaboration_Invitee_Project_View = {
  __typename?: 'ddn_collaboration_invitee_project_view';
  /** An array relationship */
  default_teams: Array<Ddn_Team>;
  /** An aggregate relationship */
  default_teams_aggregate: Ddn_Team_Aggregate;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** columns and relationships of "ddn.collaboration_invitee_project_view" */
export type Ddn_Collaboration_Invitee_Project_ViewDefault_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Order_By>>;
  where?: InputMaybe<Ddn_Team_Bool_Exp>;
};

/** columns and relationships of "ddn.collaboration_invitee_project_view" */
export type Ddn_Collaboration_Invitee_Project_ViewDefault_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Order_By>>;
  where?: InputMaybe<Ddn_Team_Bool_Exp>;
};

/** aggregated selection of "ddn.collaboration_invitee_project_view" */
export type Ddn_Collaboration_Invitee_Project_View_Aggregate = {
  __typename?: 'ddn_collaboration_invitee_project_view_aggregate';
  aggregate?: Maybe<Ddn_Collaboration_Invitee_Project_View_Aggregate_Fields>;
  nodes: Array<Ddn_Collaboration_Invitee_Project_View>;
};

/** aggregate fields of "ddn.collaboration_invitee_project_view" */
export type Ddn_Collaboration_Invitee_Project_View_Aggregate_Fields = {
  __typename?: 'ddn_collaboration_invitee_project_view_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Collaboration_Invitee_Project_View_Max_Fields>;
  min?: Maybe<Ddn_Collaboration_Invitee_Project_View_Min_Fields>;
};

/** aggregate fields of "ddn.collaboration_invitee_project_view" */
export type Ddn_Collaboration_Invitee_Project_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Ddn_Collaboration_Invitee_Project_View_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.collaboration_invitee_project_view". All fields are combined with a logical 'AND'. */
export type Ddn_Collaboration_Invitee_Project_View_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Collaboration_Invitee_Project_View_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Collaboration_Invitee_Project_View_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Collaboration_Invitee_Project_View_Bool_Exp>>;
  default_teams?: InputMaybe<Ddn_Team_Bool_Exp>;
  default_teams_aggregate?: InputMaybe<Ddn_Team_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "ddn.collaboration_invitee_project_view" */
export type Ddn_Collaboration_Invitee_Project_View_Insert_Input = {
  default_teams?: InputMaybe<Ddn_Team_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ddn_Collaboration_Invitee_Project_View_Max_Fields = {
  __typename?: 'ddn_collaboration_invitee_project_view_max_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ddn_Collaboration_Invitee_Project_View_Min_Fields = {
  __typename?: 'ddn_collaboration_invitee_project_view_min_fields';
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ddn.collaboration_invitee_project_view" */
export type Ddn_Collaboration_Invitee_Project_View_Mutation_Response = {
  __typename?: 'ddn_collaboration_invitee_project_view_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Collaboration_Invitee_Project_View>;
};

/** input type for inserting object relation for remote table "ddn.collaboration_invitee_project_view" */
export type Ddn_Collaboration_Invitee_Project_View_Obj_Rel_Insert_Input = {
  data: Ddn_Collaboration_Invitee_Project_View_Insert_Input;
};

/** Ordering options when selecting data from "ddn.collaboration_invitee_project_view". */
export type Ddn_Collaboration_Invitee_Project_View_Order_By = {
  default_teams_aggregate?: InputMaybe<Ddn_Team_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** select columns of table "ddn.collaboration_invitee_project_view" */
export enum Ddn_Collaboration_Invitee_Project_View_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "ddn.collaboration_invitee_project_view" */
export type Ddn_Collaboration_Invitee_Project_View_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ddn_collaboration_invitee_project_view" */
export type Ddn_Collaboration_Invitee_Project_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Collaboration_Invitee_Project_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Collaboration_Invitee_Project_View_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

export type Ddn_Collaboration_Invitee_Project_View_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Collaboration_Invitee_Project_View_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Collaboration_Invitee_Project_View_Bool_Exp;
};

/** A supergraph build can have multiple connector builds. This table tracks the mapping between supergraph and connector builds. */
export type Ddn_Connector_Deployment = {
  __typename?: 'ddn_connector_deployment';
  /** An object relationship */
  build: Ddn_Build;
  connector_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  subgraph_name?: Maybe<Scalars['String']>;
  supergraph_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Aggregate = {
  __typename?: 'ddn_connector_deployment_aggregate';
  aggregate?: Maybe<Ddn_Connector_Deployment_Aggregate_Fields>;
  nodes: Array<Ddn_Connector_Deployment>;
};

export type Ddn_Connector_Deployment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Connector_Deployment_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Connector_Deployment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Connector_Deployment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Connector_Deployment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Aggregate_Fields = {
  __typename?: 'ddn_connector_deployment_aggregate_fields';
  avg?: Maybe<Ddn_Connector_Deployment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ddn_Connector_Deployment_Max_Fields>;
  min?: Maybe<Ddn_Connector_Deployment_Min_Fields>;
  stddev?: Maybe<Ddn_Connector_Deployment_Stddev_Fields>;
  stddev_pop?: Maybe<Ddn_Connector_Deployment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ddn_Connector_Deployment_Stddev_Samp_Fields>;
  sum?: Maybe<Ddn_Connector_Deployment_Sum_Fields>;
  var_pop?: Maybe<Ddn_Connector_Deployment_Var_Pop_Fields>;
  var_samp?: Maybe<Ddn_Connector_Deployment_Var_Samp_Fields>;
  variance?: Maybe<Ddn_Connector_Deployment_Variance_Fields>;
};

/** aggregate fields of "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Connector_Deployment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Aggregate_Order_By = {
  avg?: InputMaybe<Ddn_Connector_Deployment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Connector_Deployment_Max_Order_By>;
  min?: InputMaybe<Ddn_Connector_Deployment_Min_Order_By>;
  stddev?: InputMaybe<Ddn_Connector_Deployment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ddn_Connector_Deployment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ddn_Connector_Deployment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ddn_Connector_Deployment_Sum_Order_By>;
  var_pop?: InputMaybe<Ddn_Connector_Deployment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ddn_Connector_Deployment_Var_Samp_Order_By>;
  variance?: InputMaybe<Ddn_Connector_Deployment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Connector_Deployment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Connector_Deployment_On_Conflict>;
};

/** aggregate avg on columns */
export type Ddn_Connector_Deployment_Avg_Fields = {
  __typename?: 'ddn_connector_deployment_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ddn.connector_deployment". All fields are combined with a logical 'AND'. */
export type Ddn_Connector_Deployment_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Connector_Deployment_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Connector_Deployment_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Connector_Deployment_Bool_Exp>>;
  build?: InputMaybe<Ddn_Build_Bool_Exp>;
  connector_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  subgraph_name?: InputMaybe<String_Comparison_Exp>;
  supergraph_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.connector_deployment" */
export enum Ddn_Connector_Deployment_Constraint {
  /** unique or primary key constraint on columns "id" */
  ConnectorDeploymentPkey = 'connector_deployment_pkey',
}

/** input type for incrementing numeric columns in table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Insert_Input = {
  build?: InputMaybe<Ddn_Build_Obj_Rel_Insert_Input>;
  connector_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  subgraph_name?: InputMaybe<Scalars['String']>;
  supergraph_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Connector_Deployment_Max_Fields = {
  __typename?: 'ddn_connector_deployment_max_fields';
  connector_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  subgraph_name?: Maybe<Scalars['String']>;
  supergraph_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Max_Order_By = {
  connector_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subgraph_name?: InputMaybe<Order_By>;
  supergraph_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Connector_Deployment_Min_Fields = {
  __typename?: 'ddn_connector_deployment_min_fields';
  connector_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  subgraph_name?: Maybe<Scalars['String']>;
  supergraph_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Min_Order_By = {
  connector_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subgraph_name?: InputMaybe<Order_By>;
  supergraph_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Mutation_Response = {
  __typename?: 'ddn_connector_deployment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Connector_Deployment>;
};

/** on_conflict condition type for table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_On_Conflict = {
  constraint: Ddn_Connector_Deployment_Constraint;
  update_columns?: Array<Ddn_Connector_Deployment_Update_Column>;
  where?: InputMaybe<Ddn_Connector_Deployment_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.connector_deployment". */
export type Ddn_Connector_Deployment_Order_By = {
  build?: InputMaybe<Ddn_Build_Order_By>;
  connector_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  subgraph_name?: InputMaybe<Order_By>;
  supergraph_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.connector_deployment */
export type Ddn_Connector_Deployment_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "ddn.connector_deployment" */
export enum Ddn_Connector_Deployment_Select_Column {
  /** column name */
  ConnectorId = 'connector_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SubgraphName = 'subgraph_name',
  /** column name */
  SupergraphId = 'supergraph_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Set_Input = {
  connector_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  subgraph_name?: InputMaybe<Scalars['String']>;
  supergraph_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Ddn_Connector_Deployment_Stddev_Fields = {
  __typename?: 'ddn_connector_deployment_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ddn_Connector_Deployment_Stddev_Pop_Fields = {
  __typename?: 'ddn_connector_deployment_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ddn_Connector_Deployment_Stddev_Samp_Fields = {
  __typename?: 'ddn_connector_deployment_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ddn_connector_deployment" */
export type Ddn_Connector_Deployment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Connector_Deployment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Connector_Deployment_Stream_Cursor_Value_Input = {
  connector_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  subgraph_name?: InputMaybe<Scalars['String']>;
  supergraph_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Ddn_Connector_Deployment_Sum_Fields = {
  __typename?: 'ddn_connector_deployment_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "ddn.connector_deployment" */
export enum Ddn_Connector_Deployment_Update_Column {
  /** column name */
  ConnectorId = 'connector_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SubgraphName = 'subgraph_name',
  /** column name */
  SupergraphId = 'supergraph_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Connector_Deployment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ddn_Connector_Deployment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Connector_Deployment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Connector_Deployment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ddn_Connector_Deployment_Var_Pop_Fields = {
  __typename?: 'ddn_connector_deployment_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ddn_Connector_Deployment_Var_Samp_Fields = {
  __typename?: 'ddn_connector_deployment_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ddn_Connector_Deployment_Variance_Fields = {
  __typename?: 'ddn_connector_deployment_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ddn.connector_deployment" */
export type Ddn_Connector_Deployment_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ddn.ddn" */
export type Ddn_Ddn = {
  __typename?: 'ddn_ddn';
  description: Scalars['String'];
  kind: Scalars['String'];
};

/** aggregated selection of "ddn.ddn" */
export type Ddn_Ddn_Aggregate = {
  __typename?: 'ddn_ddn_aggregate';
  aggregate?: Maybe<Ddn_Ddn_Aggregate_Fields>;
  nodes: Array<Ddn_Ddn>;
};

/** aggregate fields of "ddn.ddn" */
export type Ddn_Ddn_Aggregate_Fields = {
  __typename?: 'ddn_ddn_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Ddn_Max_Fields>;
  min?: Maybe<Ddn_Ddn_Min_Fields>;
};

/** aggregate fields of "ddn.ddn" */
export type Ddn_Ddn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Ddn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.ddn". All fields are combined with a logical 'AND'. */
export type Ddn_Ddn_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Ddn_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Ddn_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Ddn_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.ddn" */
export enum Ddn_Ddn_Constraint {
  /** unique or primary key constraint on columns "kind" */
  DdnPkey = 'ddn_pkey',
}

export enum Ddn_Ddn_Enum {
  /** Private DDN */
  Private = 'private',
  /** Public DDN */
  Public = 'public',
}

/** Boolean expression to compare columns of type "ddn_ddn_enum". All fields are combined with logical 'AND'. */
export type Ddn_Ddn_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ddn_Ddn_Enum>;
  _in?: InputMaybe<Array<Ddn_Ddn_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ddn_Ddn_Enum>;
  _nin?: InputMaybe<Array<Ddn_Ddn_Enum>>;
};

/** input type for inserting data into table "ddn.ddn" */
export type Ddn_Ddn_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ddn_Ddn_Max_Fields = {
  __typename?: 'ddn_ddn_max_fields';
  description?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ddn_Ddn_Min_Fields = {
  __typename?: 'ddn_ddn_min_fields';
  description?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ddn.ddn" */
export type Ddn_Ddn_Mutation_Response = {
  __typename?: 'ddn_ddn_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Ddn>;
};

/** input type for inserting object relation for remote table "ddn.ddn" */
export type Ddn_Ddn_Obj_Rel_Insert_Input = {
  data: Ddn_Ddn_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Ddn_On_Conflict>;
};

/** on_conflict condition type for table "ddn.ddn" */
export type Ddn_Ddn_On_Conflict = {
  constraint: Ddn_Ddn_Constraint;
  update_columns?: Array<Ddn_Ddn_Update_Column>;
  where?: InputMaybe<Ddn_Ddn_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.ddn". */
export type Ddn_Ddn_Order_By = {
  description?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.ddn */
export type Ddn_Ddn_Pk_Columns_Input = {
  kind: Scalars['String'];
};

/** select columns of table "ddn.ddn" */
export enum Ddn_Ddn_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Kind = 'kind',
}

/** input type for updating data in table "ddn.ddn" */
export type Ddn_Ddn_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ddn_ddn" */
export type Ddn_Ddn_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Ddn_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Ddn_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ddn.ddn" */
export enum Ddn_Ddn_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Kind = 'kind',
}

export type Ddn_Ddn_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Ddn_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Ddn_Bool_Exp;
};

/** columns and relationships of "ddn.environment" */
export type Ddn_Environment = {
  __typename?: 'ddn_environment';
  /** An object relationship */
  build?: Maybe<Ddn_Build>;
  /** An array relationship */
  build_sync_statuses: Array<Ddn_Build_Sync_Status>;
  /** An aggregate relationship */
  build_sync_statuses_aggregate: Ddn_Build_Sync_Status_Aggregate;
  /** An array relationship */
  builds: Array<Ddn_Build>;
  /** An aggregate relationship */
  builds_aggregate: Ddn_Build_Aggregate;
  created_at: Scalars['timestamptz'];
  current_build_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  fqdn: Scalars['String'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  project: Ddn_Projects;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "ddn.environment" */
export type Ddn_EnvironmentBuild_Sync_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
};

/** columns and relationships of "ddn.environment" */
export type Ddn_EnvironmentBuild_Sync_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
};

/** columns and relationships of "ddn.environment" */
export type Ddn_EnvironmentBuildsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Order_By>>;
  where?: InputMaybe<Ddn_Build_Bool_Exp>;
};

/** columns and relationships of "ddn.environment" */
export type Ddn_EnvironmentBuilds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Order_By>>;
  where?: InputMaybe<Ddn_Build_Bool_Exp>;
};

/** aggregated selection of "ddn.environment" */
export type Ddn_Environment_Aggregate = {
  __typename?: 'ddn_environment_aggregate';
  aggregate?: Maybe<Ddn_Environment_Aggregate_Fields>;
  nodes: Array<Ddn_Environment>;
};

export type Ddn_Environment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Environment_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Environment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Environment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Environment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.environment" */
export type Ddn_Environment_Aggregate_Fields = {
  __typename?: 'ddn_environment_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Environment_Max_Fields>;
  min?: Maybe<Ddn_Environment_Min_Fields>;
};

/** aggregate fields of "ddn.environment" */
export type Ddn_Environment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Environment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.environment" */
export type Ddn_Environment_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Environment_Max_Order_By>;
  min?: InputMaybe<Ddn_Environment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.environment" */
export type Ddn_Environment_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Environment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Environment_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.environment". All fields are combined with a logical 'AND'. */
export type Ddn_Environment_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Environment_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Environment_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Environment_Bool_Exp>>;
  build?: InputMaybe<Ddn_Build_Bool_Exp>;
  build_sync_statuses?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
  build_sync_statuses_aggregate?: InputMaybe<
    Ddn_Build_Sync_Status_Aggregate_Bool_Exp
  >;
  builds?: InputMaybe<Ddn_Build_Bool_Exp>;
  builds_aggregate?: InputMaybe<Ddn_Build_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_build_id?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  fqdn?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.environment" */
export enum Ddn_Environment_Constraint {
  /** unique or primary key constraint on columns "id" */
  EnvironmentPkey = 'environment_pkey',
  /** unique or primary key constraint on columns "project_id", "name" */
  UniqueEnvNameWithinProject = 'unique_env_name_within_project',
}

/** input type for inserting data into table "ddn.environment" */
export type Ddn_Environment_Insert_Input = {
  build?: InputMaybe<Ddn_Build_Obj_Rel_Insert_Input>;
  build_sync_statuses?: InputMaybe<Ddn_Build_Sync_Status_Arr_Rel_Insert_Input>;
  builds?: InputMaybe<Ddn_Build_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_build_id?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Environment_Max_Fields = {
  __typename?: 'ddn_environment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current_build_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ddn.environment" */
export type Ddn_Environment_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current_build_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Environment_Min_Fields = {
  __typename?: 'ddn_environment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current_build_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ddn.environment" */
export type Ddn_Environment_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  current_build_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.environment" */
export type Ddn_Environment_Mutation_Response = {
  __typename?: 'ddn_environment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Environment>;
};

/** input type for inserting object relation for remote table "ddn.environment" */
export type Ddn_Environment_Obj_Rel_Insert_Input = {
  data: Ddn_Environment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Environment_On_Conflict>;
};

/** on_conflict condition type for table "ddn.environment" */
export type Ddn_Environment_On_Conflict = {
  constraint: Ddn_Environment_Constraint;
  update_columns?: Array<Ddn_Environment_Update_Column>;
  where?: InputMaybe<Ddn_Environment_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.environment". */
export type Ddn_Environment_Order_By = {
  build?: InputMaybe<Ddn_Build_Order_By>;
  build_sync_statuses_aggregate?: InputMaybe<
    Ddn_Build_Sync_Status_Aggregate_Order_By
  >;
  builds_aggregate?: InputMaybe<Ddn_Build_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_build_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.environment */
export type Ddn_Environment_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.environment" */
export enum Ddn_Environment_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentBuildId = 'current_build_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.environment" */
export type Ddn_Environment_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_build_id?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_environment" */
export type Ddn_Environment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Environment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Environment_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_build_id?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.environment" */
export enum Ddn_Environment_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentBuildId = 'current_build_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Environment_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Environment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Environment_Bool_Exp;
};

/** Logs the invocation of the ddnGenerateMetadataArtifacts action */
export type Ddn_Generate_Metadata_Artifacts_Log = {
  __typename?: 'ddn_generate_metadata_artifacts_log';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  metadata_raw_json: Scalars['String'];
  /** An object relationship */
  project?: Maybe<Ddn_Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "ddn.generate_metadata_artifacts_log" */
export type Ddn_Generate_Metadata_Artifacts_Log_Aggregate = {
  __typename?: 'ddn_generate_metadata_artifacts_log_aggregate';
  aggregate?: Maybe<Ddn_Generate_Metadata_Artifacts_Log_Aggregate_Fields>;
  nodes: Array<Ddn_Generate_Metadata_Artifacts_Log>;
};

/** aggregate fields of "ddn.generate_metadata_artifacts_log" */
export type Ddn_Generate_Metadata_Artifacts_Log_Aggregate_Fields = {
  __typename?: 'ddn_generate_metadata_artifacts_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Generate_Metadata_Artifacts_Log_Max_Fields>;
  min?: Maybe<Ddn_Generate_Metadata_Artifacts_Log_Min_Fields>;
};

/** aggregate fields of "ddn.generate_metadata_artifacts_log" */
export type Ddn_Generate_Metadata_Artifacts_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Ddn_Generate_Metadata_Artifacts_Log_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.generate_metadata_artifacts_log". All fields are combined with a logical 'AND'. */
export type Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata_raw_json?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.generate_metadata_artifacts_log" */
export enum Ddn_Generate_Metadata_Artifacts_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  GenerateMetadataArtifactsLogPkey = 'generate_metadata_artifacts_log_pkey',
}

/** input type for inserting data into table "ddn.generate_metadata_artifacts_log" */
export type Ddn_Generate_Metadata_Artifacts_Log_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata_raw_json?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ddn_Generate_Metadata_Artifacts_Log_Max_Fields = {
  __typename?: 'ddn_generate_metadata_artifacts_log_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  metadata_raw_json?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Ddn_Generate_Metadata_Artifacts_Log_Min_Fields = {
  __typename?: 'ddn_generate_metadata_artifacts_log_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  metadata_raw_json?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "ddn.generate_metadata_artifacts_log" */
export type Ddn_Generate_Metadata_Artifacts_Log_Mutation_Response = {
  __typename?: 'ddn_generate_metadata_artifacts_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Generate_Metadata_Artifacts_Log>;
};

/** on_conflict condition type for table "ddn.generate_metadata_artifacts_log" */
export type Ddn_Generate_Metadata_Artifacts_Log_On_Conflict = {
  constraint: Ddn_Generate_Metadata_Artifacts_Log_Constraint;
  update_columns?: Array<Ddn_Generate_Metadata_Artifacts_Log_Update_Column>;
  where?: InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.generate_metadata_artifacts_log". */
export type Ddn_Generate_Metadata_Artifacts_Log_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata_raw_json?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.generate_metadata_artifacts_log */
export type Ddn_Generate_Metadata_Artifacts_Log_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.generate_metadata_artifacts_log" */
export enum Ddn_Generate_Metadata_Artifacts_Log_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetadataRawJson = 'metadata_raw_json',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "ddn.generate_metadata_artifacts_log" */
export type Ddn_Generate_Metadata_Artifacts_Log_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata_raw_json?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ddn_generate_metadata_artifacts_log" */
export type Ddn_Generate_Metadata_Artifacts_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Generate_Metadata_Artifacts_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Generate_Metadata_Artifacts_Log_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata_raw_json?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "ddn.generate_metadata_artifacts_log" */
export enum Ddn_Generate_Metadata_Artifacts_Log_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  MetadataRawJson = 'metadata_raw_json',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Ddn_Generate_Metadata_Artifacts_Log_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp;
};

export type Ddn_Get_Token_Access_Level_Args = {
  fqdn?: InputMaybe<Scalars['String']>;
  hashed_token?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ddn.invoice" */
export type Ddn_Invoice = {
  __typename?: 'ddn_invoice';
  attempt_count?: Maybe<Scalars['Int']>;
  collection_method: Scalars['String'];
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['String'];
  description: Scalars['String'];
  /** An array relationship */
  invoice_items: Array<Ddn_Invoice_Item>;
  /** An aggregate relationship */
  invoice_items_aggregate: Ddn_Invoice_Item_Aggregate;
  invoice_url?: Maybe<Scalars['String']>;
  month: Scalars['Int'];
  payment_method_id?: Maybe<Scalars['String']>;
  receipt_url?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  stripe_invoice_id: Scalars['String'];
  subscription_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user: Array<Users>;
  /** An aggregate relationship */
  user_aggregate: Users_Aggregate;
  year: Scalars['Int'];
};

/** columns and relationships of "ddn.invoice" */
export type Ddn_InvoiceInvoice_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Item_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
};

/** columns and relationships of "ddn.invoice" */
export type Ddn_InvoiceInvoice_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Item_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
};

/** columns and relationships of "ddn.invoice" */
export type Ddn_InvoiceUserArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "ddn.invoice" */
export type Ddn_InvoiceUser_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** aggregated selection of "ddn.invoice" */
export type Ddn_Invoice_Aggregate = {
  __typename?: 'ddn_invoice_aggregate';
  aggregate?: Maybe<Ddn_Invoice_Aggregate_Fields>;
  nodes: Array<Ddn_Invoice>;
};

export type Ddn_Invoice_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Invoice_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Invoice_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Invoice_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.invoice" */
export type Ddn_Invoice_Aggregate_Fields = {
  __typename?: 'ddn_invoice_aggregate_fields';
  avg?: Maybe<Ddn_Invoice_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ddn_Invoice_Max_Fields>;
  min?: Maybe<Ddn_Invoice_Min_Fields>;
  stddev?: Maybe<Ddn_Invoice_Stddev_Fields>;
  stddev_pop?: Maybe<Ddn_Invoice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ddn_Invoice_Stddev_Samp_Fields>;
  sum?: Maybe<Ddn_Invoice_Sum_Fields>;
  var_pop?: Maybe<Ddn_Invoice_Var_Pop_Fields>;
  var_samp?: Maybe<Ddn_Invoice_Var_Samp_Fields>;
  variance?: Maybe<Ddn_Invoice_Variance_Fields>;
};

/** aggregate fields of "ddn.invoice" */
export type Ddn_Invoice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.invoice" */
export type Ddn_Invoice_Aggregate_Order_By = {
  avg?: InputMaybe<Ddn_Invoice_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Invoice_Max_Order_By>;
  min?: InputMaybe<Ddn_Invoice_Min_Order_By>;
  stddev?: InputMaybe<Ddn_Invoice_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ddn_Invoice_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ddn_Invoice_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ddn_Invoice_Sum_Order_By>;
  var_pop?: InputMaybe<Ddn_Invoice_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ddn_Invoice_Var_Samp_Order_By>;
  variance?: InputMaybe<Ddn_Invoice_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.invoice" */
export type Ddn_Invoice_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Invoice_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Invoice_On_Conflict>;
};

/** aggregate avg on columns */
export type Ddn_Invoice_Avg_Fields = {
  __typename?: 'ddn_invoice_avg_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ddn.invoice" */
export type Ddn_Invoice_Avg_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ddn.invoice". All fields are combined with a logical 'AND'. */
export type Ddn_Invoice_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Invoice_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Invoice_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Invoice_Bool_Exp>>;
  attempt_count?: InputMaybe<Int_Comparison_Exp>;
  collection_method?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  invoice_items?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
  invoice_items_aggregate?: InputMaybe<Ddn_Invoice_Item_Aggregate_Bool_Exp>;
  invoice_url?: InputMaybe<String_Comparison_Exp>;
  month?: InputMaybe<Int_Comparison_Exp>;
  payment_method_id?: InputMaybe<String_Comparison_Exp>;
  receipt_url?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  stripe_invoice_id?: InputMaybe<String_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_aggregate?: InputMaybe<Users_Aggregate_Bool_Exp>;
  year?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.invoice" */
export enum Ddn_Invoice_Constraint {
  /** unique or primary key constraint on columns "stripe_invoice_id" */
  InvoicePkey = 'invoice_pkey',
}

/** input type for incrementing numeric columns in table "ddn.invoice" */
export type Ddn_Invoice_Inc_Input = {
  attempt_count?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ddn.invoice" */
export type Ddn_Invoice_Insert_Input = {
  attempt_count?: InputMaybe<Scalars['Int']>;
  collection_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  invoice_items?: InputMaybe<Ddn_Invoice_Item_Arr_Rel_Insert_Input>;
  invoice_url?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  receipt_url?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stripe_invoice_id?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Arr_Rel_Insert_Input>;
  year?: InputMaybe<Scalars['Int']>;
};

/** columns and relationships of "ddn.invoice_item" */
export type Ddn_Invoice_Item = {
  __typename?: 'ddn_invoice_item';
  amount: Scalars['numeric'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  error?: Maybe<Scalars['String']>;
  has_updated_to_stripe: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** An object relationship */
  invoice: Ddn_Invoice;
  invoice_id: Scalars['String'];
  month: Scalars['Int'];
  /** An object relationship */
  project: Ddn_Projects;
  project_id: Scalars['uuid'];
  quantity?: Maybe<Scalars['Int']>;
  subgraph: Scalars['String'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  year: Scalars['Int'];
};

/** aggregated selection of "ddn.invoice_item" */
export type Ddn_Invoice_Item_Aggregate = {
  __typename?: 'ddn_invoice_item_aggregate';
  aggregate?: Maybe<Ddn_Invoice_Item_Aggregate_Fields>;
  nodes: Array<Ddn_Invoice_Item>;
};

export type Ddn_Invoice_Item_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Ddn_Invoice_Item_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Ddn_Invoice_Item_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Ddn_Invoice_Item_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Invoice_Item_Aggregate_Bool_Exp_Bool_And = {
  arguments: Ddn_Invoice_Item_Select_Column_Ddn_Invoice_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ddn_Invoice_Item_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Ddn_Invoice_Item_Select_Column_Ddn_Invoice_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ddn_Invoice_Item_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Invoice_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.invoice_item" */
export type Ddn_Invoice_Item_Aggregate_Fields = {
  __typename?: 'ddn_invoice_item_aggregate_fields';
  avg?: Maybe<Ddn_Invoice_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ddn_Invoice_Item_Max_Fields>;
  min?: Maybe<Ddn_Invoice_Item_Min_Fields>;
  stddev?: Maybe<Ddn_Invoice_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Ddn_Invoice_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ddn_Invoice_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Ddn_Invoice_Item_Sum_Fields>;
  var_pop?: Maybe<Ddn_Invoice_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Ddn_Invoice_Item_Var_Samp_Fields>;
  variance?: Maybe<Ddn_Invoice_Item_Variance_Fields>;
};

/** aggregate fields of "ddn.invoice_item" */
export type Ddn_Invoice_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Invoice_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Ddn_Invoice_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Invoice_Item_Max_Order_By>;
  min?: InputMaybe<Ddn_Invoice_Item_Min_Order_By>;
  stddev?: InputMaybe<Ddn_Invoice_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ddn_Invoice_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ddn_Invoice_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ddn_Invoice_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Ddn_Invoice_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ddn_Invoice_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Ddn_Invoice_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Invoice_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Invoice_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Ddn_Invoice_Item_Avg_Fields = {
  __typename?: 'ddn_invoice_item_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ddn.invoice_item". All fields are combined with a logical 'AND'. */
export type Ddn_Invoice_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Invoice_Item_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Invoice_Item_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  has_updated_to_stripe?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Ddn_Invoice_Bool_Exp>;
  invoice_id?: InputMaybe<String_Comparison_Exp>;
  month?: InputMaybe<Int_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  quantity?: InputMaybe<Int_Comparison_Exp>;
  subgraph?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  year?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.invoice_item" */
export enum Ddn_Invoice_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceItemPkey = 'invoice_item_pkey',
  /** unique or primary key constraint on columns "project_id", "year", "type", "month", "subgraph" */
  InvoiceItemYearMonthTypeProjectIdSubgraphKey = 'invoice_item_year_month_type_project_id_subgraph_key',
}

/** input type for incrementing numeric columns in table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  month?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['String']>;
  has_updated_to_stripe?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Ddn_Invoice_Obj_Rel_Insert_Input>;
  invoice_id?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['Int']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  subgraph?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Ddn_Invoice_Item_Max_Fields = {
  __typename?: 'ddn_invoice_item_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  subgraph?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  subgraph?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Invoice_Item_Min_Fields = {
  __typename?: 'ddn_invoice_item_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_id?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  quantity?: Maybe<Scalars['Int']>;
  subgraph?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  subgraph?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Mutation_Response = {
  __typename?: 'ddn_invoice_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Invoice_Item>;
};

/** on_conflict condition type for table "ddn.invoice_item" */
export type Ddn_Invoice_Item_On_Conflict = {
  constraint: Ddn_Invoice_Item_Constraint;
  update_columns?: Array<Ddn_Invoice_Item_Update_Column>;
  where?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.invoice_item". */
export type Ddn_Invoice_Item_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  has_updated_to_stripe?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Ddn_Invoice_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  subgraph?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.invoice_item */
export type Ddn_Invoice_Item_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.invoice_item" */
export enum Ddn_Invoice_Item_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Error = 'error',
  /** column name */
  HasUpdatedToStripe = 'has_updated_to_stripe',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Month = 'month',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Subgraph = 'subgraph',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

/** select "ddn_invoice_item_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ddn.invoice_item" */
export enum Ddn_Invoice_Item_Select_Column_Ddn_Invoice_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasUpdatedToStripe = 'has_updated_to_stripe',
}

/** select "ddn_invoice_item_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ddn.invoice_item" */
export enum Ddn_Invoice_Item_Select_Column_Ddn_Invoice_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasUpdatedToStripe = 'has_updated_to_stripe',
}

/** input type for updating data in table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['String']>;
  has_updated_to_stripe?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  subgraph?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Ddn_Invoice_Item_Stddev_Fields = {
  __typename?: 'ddn_invoice_item_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ddn_Invoice_Item_Stddev_Pop_Fields = {
  __typename?: 'ddn_invoice_item_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ddn_Invoice_Item_Stddev_Samp_Fields = {
  __typename?: 'ddn_invoice_item_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ddn_invoice_item" */
export type Ddn_Invoice_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Invoice_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Invoice_Item_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['String']>;
  has_updated_to_stripe?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
  subgraph?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Ddn_Invoice_Item_Sum_Fields = {
  __typename?: 'ddn_invoice_item_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  month?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** update columns of table "ddn.invoice_item" */
export enum Ddn_Invoice_Item_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Error = 'error',
  /** column name */
  HasUpdatedToStripe = 'has_updated_to_stripe',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Month = 'month',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Subgraph = 'subgraph',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

export type Ddn_Invoice_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ddn_Invoice_Item_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Invoice_Item_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Invoice_Item_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ddn_Invoice_Item_Var_Pop_Fields = {
  __typename?: 'ddn_invoice_item_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ddn_Invoice_Item_Var_Samp_Fields = {
  __typename?: 'ddn_invoice_item_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ddn_Invoice_Item_Variance_Fields = {
  __typename?: 'ddn_invoice_item_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ddn.invoice_item" */
export type Ddn_Invoice_Item_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Ddn_Invoice_Max_Fields = {
  __typename?: 'ddn_invoice_max_fields';
  attempt_count?: Maybe<Scalars['Int']>;
  collection_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  invoice_url?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['String']>;
  receipt_url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stripe_invoice_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ddn.invoice" */
export type Ddn_Invoice_Max_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  collection_method?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  invoice_url?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  receipt_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripe_invoice_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Invoice_Min_Fields = {
  __typename?: 'ddn_invoice_min_fields';
  attempt_count?: Maybe<Scalars['Int']>;
  collection_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  invoice_url?: Maybe<Scalars['String']>;
  month?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['String']>;
  receipt_url?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stripe_invoice_id?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ddn.invoice" */
export type Ddn_Invoice_Min_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  collection_method?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  invoice_url?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  receipt_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripe_invoice_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.invoice" */
export type Ddn_Invoice_Mutation_Response = {
  __typename?: 'ddn_invoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Invoice>;
};

/** input type for inserting object relation for remote table "ddn.invoice" */
export type Ddn_Invoice_Obj_Rel_Insert_Input = {
  data: Ddn_Invoice_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Invoice_On_Conflict>;
};

/** on_conflict condition type for table "ddn.invoice" */
export type Ddn_Invoice_On_Conflict = {
  constraint: Ddn_Invoice_Constraint;
  update_columns?: Array<Ddn_Invoice_Update_Column>;
  where?: InputMaybe<Ddn_Invoice_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.invoice". */
export type Ddn_Invoice_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  collection_method?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  invoice_items_aggregate?: InputMaybe<Ddn_Invoice_Item_Aggregate_Order_By>;
  invoice_url?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  receipt_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripe_invoice_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_aggregate?: InputMaybe<Users_Aggregate_Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.invoice */
export type Ddn_Invoice_Pk_Columns_Input = {
  stripe_invoice_id: Scalars['String'];
};

/** select columns of table "ddn.invoice" */
export enum Ddn_Invoice_Select_Column {
  /** column name */
  AttemptCount = 'attempt_count',
  /** column name */
  CollectionMethod = 'collection_method',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Description = 'description',
  /** column name */
  InvoiceUrl = 'invoice_url',
  /** column name */
  Month = 'month',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  ReceiptUrl = 'receipt_url',
  /** column name */
  Status = 'status',
  /** column name */
  StripeInvoiceId = 'stripe_invoice_id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

/** input type for updating data in table "ddn.invoice" */
export type Ddn_Invoice_Set_Input = {
  attempt_count?: InputMaybe<Scalars['Int']>;
  collection_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  invoice_url?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  receipt_url?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stripe_invoice_id?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Ddn_Invoice_Stddev_Fields = {
  __typename?: 'ddn_invoice_stddev_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ddn.invoice" */
export type Ddn_Invoice_Stddev_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ddn_Invoice_Stddev_Pop_Fields = {
  __typename?: 'ddn_invoice_stddev_pop_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ddn.invoice" */
export type Ddn_Invoice_Stddev_Pop_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ddn_Invoice_Stddev_Samp_Fields = {
  __typename?: 'ddn_invoice_stddev_samp_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ddn.invoice" */
export type Ddn_Invoice_Stddev_Samp_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ddn_invoice" */
export type Ddn_Invoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Invoice_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Invoice_Stream_Cursor_Value_Input = {
  attempt_count?: InputMaybe<Scalars['Int']>;
  collection_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  invoice_url?: InputMaybe<Scalars['String']>;
  month?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  receipt_url?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stripe_invoice_id?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Ddn_Invoice_Sum_Fields = {
  __typename?: 'ddn_invoice_sum_fields';
  attempt_count?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ddn.invoice" */
export type Ddn_Invoice_Sum_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** update columns of table "ddn.invoice" */
export enum Ddn_Invoice_Update_Column {
  /** column name */
  AttemptCount = 'attempt_count',
  /** column name */
  CollectionMethod = 'collection_method',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Description = 'description',
  /** column name */
  InvoiceUrl = 'invoice_url',
  /** column name */
  Month = 'month',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  ReceiptUrl = 'receipt_url',
  /** column name */
  Status = 'status',
  /** column name */
  StripeInvoiceId = 'stripe_invoice_id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

export type Ddn_Invoice_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ddn_Invoice_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Invoice_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Invoice_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ddn_Invoice_Var_Pop_Fields = {
  __typename?: 'ddn_invoice_var_pop_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ddn.invoice" */
export type Ddn_Invoice_Var_Pop_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ddn_Invoice_Var_Samp_Fields = {
  __typename?: 'ddn_invoice_var_samp_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ddn.invoice" */
export type Ddn_Invoice_Var_Samp_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ddn_Invoice_Variance_Fields = {
  __typename?: 'ddn_invoice_variance_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ddn.invoice" */
export type Ddn_Invoice_Variance_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** entitlements associated with the Hasura v3 plans */
export type Ddn_Plan_Entitlement_Access = {
  __typename?: 'ddn_plan_entitlement_access';
  created_at: Scalars['timestamptz'];
  entitlement_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  plan: Ddn_Plans;
  /** An object relationship */
  plan_entitlement_access: Ddn_Project_Entitlement_Catalogue;
  plan_id: Scalars['uuid'];
};

/** aggregated selection of "ddn.plan_entitlement_access" */
export type Ddn_Plan_Entitlement_Access_Aggregate = {
  __typename?: 'ddn_plan_entitlement_access_aggregate';
  aggregate?: Maybe<Ddn_Plan_Entitlement_Access_Aggregate_Fields>;
  nodes: Array<Ddn_Plan_Entitlement_Access>;
};

export type Ddn_Plan_Entitlement_Access_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Plan_Entitlement_Access_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Plan_Entitlement_Access_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Plan_Entitlement_Access_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.plan_entitlement_access" */
export type Ddn_Plan_Entitlement_Access_Aggregate_Fields = {
  __typename?: 'ddn_plan_entitlement_access_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Plan_Entitlement_Access_Max_Fields>;
  min?: Maybe<Ddn_Plan_Entitlement_Access_Min_Fields>;
};

/** aggregate fields of "ddn.plan_entitlement_access" */
export type Ddn_Plan_Entitlement_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.plan_entitlement_access" */
export type Ddn_Plan_Entitlement_Access_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Plan_Entitlement_Access_Max_Order_By>;
  min?: InputMaybe<Ddn_Plan_Entitlement_Access_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.plan_entitlement_access" */
export type Ddn_Plan_Entitlement_Access_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Plan_Entitlement_Access_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Plan_Entitlement_Access_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.plan_entitlement_access". All fields are combined with a logical 'AND'. */
export type Ddn_Plan_Entitlement_Access_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Plan_Entitlement_Access_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  entitlement_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  plan?: InputMaybe<Ddn_Plans_Bool_Exp>;
  plan_entitlement_access?: InputMaybe<
    Ddn_Project_Entitlement_Catalogue_Bool_Exp
  >;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.plan_entitlement_access" */
export enum Ddn_Plan_Entitlement_Access_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlanEntitlementAccessPkey = 'plan_entitlement_access_pkey',
}

/** input type for inserting data into table "ddn.plan_entitlement_access" */
export type Ddn_Plan_Entitlement_Access_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan?: InputMaybe<Ddn_Plans_Obj_Rel_Insert_Input>;
  plan_entitlement_access?: InputMaybe<
    Ddn_Project_Entitlement_Catalogue_Obj_Rel_Insert_Input
  >;
  plan_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ddn_Plan_Entitlement_Access_Max_Fields = {
  __typename?: 'ddn_plan_entitlement_access_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entitlement_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ddn.plan_entitlement_access" */
export type Ddn_Plan_Entitlement_Access_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Plan_Entitlement_Access_Min_Fields = {
  __typename?: 'ddn_plan_entitlement_access_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entitlement_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ddn.plan_entitlement_access" */
export type Ddn_Plan_Entitlement_Access_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.plan_entitlement_access" */
export type Ddn_Plan_Entitlement_Access_Mutation_Response = {
  __typename?: 'ddn_plan_entitlement_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Plan_Entitlement_Access>;
};

/** on_conflict condition type for table "ddn.plan_entitlement_access" */
export type Ddn_Plan_Entitlement_Access_On_Conflict = {
  constraint: Ddn_Plan_Entitlement_Access_Constraint;
  update_columns?: Array<Ddn_Plan_Entitlement_Access_Update_Column>;
  where?: InputMaybe<Ddn_Plan_Entitlement_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.plan_entitlement_access". */
export type Ddn_Plan_Entitlement_Access_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan?: InputMaybe<Ddn_Plans_Order_By>;
  plan_entitlement_access?: InputMaybe<
    Ddn_Project_Entitlement_Catalogue_Order_By
  >;
  plan_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.plan_entitlement_access */
export type Ddn_Plan_Entitlement_Access_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.plan_entitlement_access" */
export enum Ddn_Plan_Entitlement_Access_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntitlementId = 'entitlement_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'plan_id',
}

/** input type for updating data in table "ddn.plan_entitlement_access" */
export type Ddn_Plan_Entitlement_Access_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ddn_plan_entitlement_access" */
export type Ddn_Plan_Entitlement_Access_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Plan_Entitlement_Access_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Plan_Entitlement_Access_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "ddn.plan_entitlement_access" */
export enum Ddn_Plan_Entitlement_Access_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntitlementId = 'entitlement_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'plan_id',
}

export type Ddn_Plan_Entitlement_Access_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Plan_Entitlement_Access_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Plan_Entitlement_Access_Bool_Exp;
};

/** plans available for Hasura V3 */
export type Ddn_Plans = {
  __typename?: 'ddn_plans';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  plan_entitlement_accesses: Array<Ddn_Plan_Entitlement_Access>;
  /** An aggregate relationship */
  plan_entitlement_accesses_aggregate: Ddn_Plan_Entitlement_Access_Aggregate;
  /** An array relationship */
  project_plan_changelogs: Array<Ddn_Project_Plan_Changelog>;
  /** An aggregate relationship */
  project_plan_changelogs_aggregate: Ddn_Project_Plan_Changelog_Aggregate;
  updated_at: Scalars['timestamptz'];
};

/** plans available for Hasura V3 */
export type Ddn_PlansPlan_Entitlement_AccessesArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Ddn_Plan_Entitlement_Access_Bool_Exp>;
};

/** plans available for Hasura V3 */
export type Ddn_PlansPlan_Entitlement_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Ddn_Plan_Entitlement_Access_Bool_Exp>;
};

/** plans available for Hasura V3 */
export type Ddn_PlansProject_Plan_ChangelogsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Project_Plan_Changelog_Bool_Exp>;
};

/** plans available for Hasura V3 */
export type Ddn_PlansProject_Plan_Changelogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Project_Plan_Changelog_Bool_Exp>;
};

/** aggregated selection of "ddn.plans" */
export type Ddn_Plans_Aggregate = {
  __typename?: 'ddn_plans_aggregate';
  aggregate?: Maybe<Ddn_Plans_Aggregate_Fields>;
  nodes: Array<Ddn_Plans>;
};

/** aggregate fields of "ddn.plans" */
export type Ddn_Plans_Aggregate_Fields = {
  __typename?: 'ddn_plans_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Plans_Max_Fields>;
  min?: Maybe<Ddn_Plans_Min_Fields>;
};

/** aggregate fields of "ddn.plans" */
export type Ddn_Plans_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Plans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.plans". All fields are combined with a logical 'AND'. */
export type Ddn_Plans_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Plans_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Plans_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Plans_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  plan_entitlement_accesses?: InputMaybe<Ddn_Plan_Entitlement_Access_Bool_Exp>;
  plan_entitlement_accesses_aggregate?: InputMaybe<
    Ddn_Plan_Entitlement_Access_Aggregate_Bool_Exp
  >;
  project_plan_changelogs?: InputMaybe<Ddn_Project_Plan_Changelog_Bool_Exp>;
  project_plan_changelogs_aggregate?: InputMaybe<
    Ddn_Project_Plan_Changelog_Aggregate_Bool_Exp
  >;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.plans" */
export enum Ddn_Plans_Constraint {
  /** unique or primary key constraint on columns "name" */
  PlansNameKey = 'plans_name_key',
  /** unique or primary key constraint on columns "id" */
  PlansPkey = 'plans_pkey',
}

/** input type for inserting data into table "ddn.plans" */
export type Ddn_Plans_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  plan_entitlement_accesses?: InputMaybe<
    Ddn_Plan_Entitlement_Access_Arr_Rel_Insert_Input
  >;
  project_plan_changelogs?: InputMaybe<
    Ddn_Project_Plan_Changelog_Arr_Rel_Insert_Input
  >;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Plans_Max_Fields = {
  __typename?: 'ddn_plans_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Ddn_Plans_Min_Fields = {
  __typename?: 'ddn_plans_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ddn.plans" */
export type Ddn_Plans_Mutation_Response = {
  __typename?: 'ddn_plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Plans>;
};

/** input type for inserting object relation for remote table "ddn.plans" */
export type Ddn_Plans_Obj_Rel_Insert_Input = {
  data: Ddn_Plans_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Plans_On_Conflict>;
};

/** on_conflict condition type for table "ddn.plans" */
export type Ddn_Plans_On_Conflict = {
  constraint: Ddn_Plans_Constraint;
  update_columns?: Array<Ddn_Plans_Update_Column>;
  where?: InputMaybe<Ddn_Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.plans". */
export type Ddn_Plans_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  plan_entitlement_accesses_aggregate?: InputMaybe<
    Ddn_Plan_Entitlement_Access_Aggregate_Order_By
  >;
  project_plan_changelogs_aggregate?: InputMaybe<
    Ddn_Project_Plan_Changelog_Aggregate_Order_By
  >;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.plans */
export type Ddn_Plans_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.plans" */
export enum Ddn_Plans_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.plans" */
export type Ddn_Plans_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_plans" */
export type Ddn_Plans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Plans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Plans_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.plans" */
export enum Ddn_Plans_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Plans_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Plans_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Plans_Bool_Exp;
};

/** columns and relationships of "ddn.private_ddn" */
export type Ddn_Private_Ddn = {
  __typename?: 'ddn_private_ddn';
  aws_config?: Maybe<Scalars['jsonb']>;
  cloud: Array<Scalars['String']>;
  control_plane_config: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  ddn: Ddn_Ddn;
  ddn_kind: Ddn_Ddn_Enum;
  fqdn: Scalars['String'];
  gcp_config?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  ndc_hosts?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  private_ddn_regions: Array<Ddn_Private_Ddn_Region>;
  /** An aggregate relationship */
  private_ddn_regions_aggregate: Ddn_Private_Ddn_Region_Aggregate;
  /** An array relationship */
  projects: Array<Ddn_Projects>;
  /** An aggregate relationship */
  projects_aggregate: Ddn_Projects_Aggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "ddn.private_ddn" */
export type Ddn_Private_DdnAws_ConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ddn.private_ddn" */
export type Ddn_Private_DdnControl_Plane_ConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ddn.private_ddn" */
export type Ddn_Private_DdnGcp_ConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ddn.private_ddn" */
export type Ddn_Private_DdnNdc_HostsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "ddn.private_ddn" */
export type Ddn_Private_DdnPrivate_Ddn_RegionsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Private_Ddn_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Private_Ddn_Region_Order_By>>;
  where?: InputMaybe<Ddn_Private_Ddn_Region_Bool_Exp>;
};

/** columns and relationships of "ddn.private_ddn" */
export type Ddn_Private_DdnPrivate_Ddn_Regions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Private_Ddn_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Private_Ddn_Region_Order_By>>;
  where?: InputMaybe<Ddn_Private_Ddn_Region_Bool_Exp>;
};

/** columns and relationships of "ddn.private_ddn" */
export type Ddn_Private_DdnProjectsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Projects_Order_By>>;
  where?: InputMaybe<Ddn_Projects_Bool_Exp>;
};

/** columns and relationships of "ddn.private_ddn" */
export type Ddn_Private_DdnProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Projects_Order_By>>;
  where?: InputMaybe<Ddn_Projects_Bool_Exp>;
};

/** aggregated selection of "ddn.private_ddn" */
export type Ddn_Private_Ddn_Aggregate = {
  __typename?: 'ddn_private_ddn_aggregate';
  aggregate?: Maybe<Ddn_Private_Ddn_Aggregate_Fields>;
  nodes: Array<Ddn_Private_Ddn>;
};

/** aggregate fields of "ddn.private_ddn" */
export type Ddn_Private_Ddn_Aggregate_Fields = {
  __typename?: 'ddn_private_ddn_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Private_Ddn_Max_Fields>;
  min?: Maybe<Ddn_Private_Ddn_Min_Fields>;
};

/** aggregate fields of "ddn.private_ddn" */
export type Ddn_Private_Ddn_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Private_Ddn_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ddn_Private_Ddn_Append_Input = {
  aws_config?: InputMaybe<Scalars['jsonb']>;
  control_plane_config?: InputMaybe<Scalars['jsonb']>;
  gcp_config?: InputMaybe<Scalars['jsonb']>;
  ndc_hosts?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "ddn.private_ddn". All fields are combined with a logical 'AND'. */
export type Ddn_Private_Ddn_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Private_Ddn_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Private_Ddn_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Private_Ddn_Bool_Exp>>;
  aws_config?: InputMaybe<Jsonb_Comparison_Exp>;
  cloud?: InputMaybe<String_Array_Comparison_Exp>;
  control_plane_config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ddn?: InputMaybe<Ddn_Ddn_Bool_Exp>;
  ddn_kind?: InputMaybe<Ddn_Ddn_Enum_Comparison_Exp>;
  fqdn?: InputMaybe<String_Comparison_Exp>;
  gcp_config?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  ndc_hosts?: InputMaybe<Jsonb_Comparison_Exp>;
  private_ddn_regions?: InputMaybe<Ddn_Private_Ddn_Region_Bool_Exp>;
  private_ddn_regions_aggregate?: InputMaybe<
    Ddn_Private_Ddn_Region_Aggregate_Bool_Exp
  >;
  projects?: InputMaybe<Ddn_Projects_Bool_Exp>;
  projects_aggregate?: InputMaybe<Ddn_Projects_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.private_ddn" */
export enum Ddn_Private_Ddn_Constraint {
  /** unique or primary key constraint on columns "id" */
  PrivateDdnPkey = 'private_ddn_pkey',
  /** unique or primary key constraint on columns "ddn_kind" */
  PublicDdn = 'public_ddn',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ddn_Private_Ddn_Delete_At_Path_Input = {
  aws_config?: InputMaybe<Array<Scalars['String']>>;
  control_plane_config?: InputMaybe<Array<Scalars['String']>>;
  gcp_config?: InputMaybe<Array<Scalars['String']>>;
  ndc_hosts?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ddn_Private_Ddn_Delete_Elem_Input = {
  aws_config?: InputMaybe<Scalars['Int']>;
  control_plane_config?: InputMaybe<Scalars['Int']>;
  gcp_config?: InputMaybe<Scalars['Int']>;
  ndc_hosts?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ddn_Private_Ddn_Delete_Key_Input = {
  aws_config?: InputMaybe<Scalars['String']>;
  control_plane_config?: InputMaybe<Scalars['String']>;
  gcp_config?: InputMaybe<Scalars['String']>;
  ndc_hosts?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "ddn.private_ddn" */
export type Ddn_Private_Ddn_Insert_Input = {
  aws_config?: InputMaybe<Scalars['jsonb']>;
  cloud?: InputMaybe<Array<Scalars['String']>>;
  control_plane_config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ddn?: InputMaybe<Ddn_Ddn_Obj_Rel_Insert_Input>;
  ddn_kind?: InputMaybe<Ddn_Ddn_Enum>;
  fqdn?: InputMaybe<Scalars['String']>;
  gcp_config?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ndc_hosts?: InputMaybe<Scalars['jsonb']>;
  private_ddn_regions?: InputMaybe<Ddn_Private_Ddn_Region_Arr_Rel_Insert_Input>;
  projects?: InputMaybe<Ddn_Projects_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Private_Ddn_Max_Fields = {
  __typename?: 'ddn_private_ddn_max_fields';
  cloud?: Maybe<Array<Scalars['String']>>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Ddn_Private_Ddn_Min_Fields = {
  __typename?: 'ddn_private_ddn_min_fields';
  cloud?: Maybe<Array<Scalars['String']>>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ddn.private_ddn" */
export type Ddn_Private_Ddn_Mutation_Response = {
  __typename?: 'ddn_private_ddn_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Private_Ddn>;
};

/** input type for inserting object relation for remote table "ddn.private_ddn" */
export type Ddn_Private_Ddn_Obj_Rel_Insert_Input = {
  data: Ddn_Private_Ddn_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Private_Ddn_On_Conflict>;
};

/** on_conflict condition type for table "ddn.private_ddn" */
export type Ddn_Private_Ddn_On_Conflict = {
  constraint: Ddn_Private_Ddn_Constraint;
  update_columns?: Array<Ddn_Private_Ddn_Update_Column>;
  where?: InputMaybe<Ddn_Private_Ddn_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.private_ddn". */
export type Ddn_Private_Ddn_Order_By = {
  aws_config?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  control_plane_config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ddn?: InputMaybe<Ddn_Ddn_Order_By>;
  ddn_kind?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  gcp_config?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  ndc_hosts?: InputMaybe<Order_By>;
  private_ddn_regions_aggregate?: InputMaybe<
    Ddn_Private_Ddn_Region_Aggregate_Order_By
  >;
  projects_aggregate?: InputMaybe<Ddn_Projects_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.private_ddn */
export type Ddn_Private_Ddn_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ddn_Private_Ddn_Prepend_Input = {
  aws_config?: InputMaybe<Scalars['jsonb']>;
  control_plane_config?: InputMaybe<Scalars['jsonb']>;
  gcp_config?: InputMaybe<Scalars['jsonb']>;
  ndc_hosts?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region = {
  __typename?: 'ddn_private_ddn_region';
  cloud: Scalars['String'];
  config: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  /** The name enum of the private ddn region */
  name?: Maybe<Scalars['String']>;
  /** An object relationship */
  private_ddn: Ddn_Private_Ddn;
  private_ddn_id: Scalars['uuid'];
  region: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_RegionConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region_Aggregate = {
  __typename?: 'ddn_private_ddn_region_aggregate';
  aggregate?: Maybe<Ddn_Private_Ddn_Region_Aggregate_Fields>;
  nodes: Array<Ddn_Private_Ddn_Region>;
};

export type Ddn_Private_Ddn_Region_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Private_Ddn_Region_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Private_Ddn_Region_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Private_Ddn_Region_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Private_Ddn_Region_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region_Aggregate_Fields = {
  __typename?: 'ddn_private_ddn_region_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Private_Ddn_Region_Max_Fields>;
  min?: Maybe<Ddn_Private_Ddn_Region_Min_Fields>;
};

/** aggregate fields of "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Private_Ddn_Region_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Private_Ddn_Region_Max_Order_By>;
  min?: InputMaybe<Ddn_Private_Ddn_Region_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ddn_Private_Ddn_Region_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Private_Ddn_Region_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Private_Ddn_Region_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.private_ddn_region". All fields are combined with a logical 'AND'. */
export type Ddn_Private_Ddn_Region_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Private_Ddn_Region_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Private_Ddn_Region_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Private_Ddn_Region_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  private_ddn?: InputMaybe<Ddn_Private_Ddn_Bool_Exp>;
  private_ddn_id?: InputMaybe<Uuid_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.private_ddn_region" */
export enum Ddn_Private_Ddn_Region_Constraint {
  /** unique or primary key constraint on columns "region", "cloud", "private_ddn_id" */
  PrivateDdnRegionPkey = 'private_ddn_region_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ddn_Private_Ddn_Region_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ddn_Private_Ddn_Region_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ddn_Private_Ddn_Region_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region_Insert_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  private_ddn?: InputMaybe<Ddn_Private_Ddn_Obj_Rel_Insert_Input>;
  private_ddn_id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Private_Ddn_Region_Max_Fields = {
  __typename?: 'ddn_private_ddn_region_max_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** The name enum of the private ddn region */
  name?: Maybe<Scalars['String']>;
  private_ddn_id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region_Max_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  private_ddn_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Private_Ddn_Region_Min_Fields = {
  __typename?: 'ddn_private_ddn_region_min_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** The name enum of the private ddn region */
  name?: Maybe<Scalars['String']>;
  private_ddn_id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region_Min_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  private_ddn_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region_Mutation_Response = {
  __typename?: 'ddn_private_ddn_region_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Private_Ddn_Region>;
};

/** input type for inserting object relation for remote table "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region_Obj_Rel_Insert_Input = {
  data: Ddn_Private_Ddn_Region_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Private_Ddn_Region_On_Conflict>;
};

/** on_conflict condition type for table "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region_On_Conflict = {
  constraint: Ddn_Private_Ddn_Region_Constraint;
  update_columns?: Array<Ddn_Private_Ddn_Region_Update_Column>;
  where?: InputMaybe<Ddn_Private_Ddn_Region_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.private_ddn_region". */
export type Ddn_Private_Ddn_Region_Order_By = {
  cloud?: InputMaybe<Order_By>;
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  private_ddn?: InputMaybe<Ddn_Private_Ddn_Order_By>;
  private_ddn_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.private_ddn_region */
export type Ddn_Private_Ddn_Region_Pk_Columns_Input = {
  cloud: Scalars['String'];
  private_ddn_id: Scalars['uuid'];
  region: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ddn_Private_Ddn_Region_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "ddn.private_ddn_region" */
export enum Ddn_Private_Ddn_Region_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PrivateDdnId = 'private_ddn_id',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.private_ddn_region" */
export type Ddn_Private_Ddn_Region_Set_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  private_ddn_id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_private_ddn_region" */
export type Ddn_Private_Ddn_Region_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Private_Ddn_Region_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Private_Ddn_Region_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  private_ddn_id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.private_ddn_region" */
export enum Ddn_Private_Ddn_Region_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PrivateDdnId = 'private_ddn_id',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Private_Ddn_Region_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ddn_Private_Ddn_Region_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ddn_Private_Ddn_Region_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ddn_Private_Ddn_Region_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ddn_Private_Ddn_Region_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ddn_Private_Ddn_Region_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Private_Ddn_Region_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Private_Ddn_Region_Bool_Exp;
};

/** select columns of table "ddn.private_ddn" */
export enum Ddn_Private_Ddn_Select_Column {
  /** column name */
  AwsConfig = 'aws_config',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  ControlPlaneConfig = 'control_plane_config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DdnKind = 'ddn_kind',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  GcpConfig = 'gcp_config',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NdcHosts = 'ndc_hosts',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.private_ddn" */
export type Ddn_Private_Ddn_Set_Input = {
  aws_config?: InputMaybe<Scalars['jsonb']>;
  cloud?: InputMaybe<Array<Scalars['String']>>;
  control_plane_config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ddn_kind?: InputMaybe<Ddn_Ddn_Enum>;
  fqdn?: InputMaybe<Scalars['String']>;
  gcp_config?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ndc_hosts?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_private_ddn" */
export type Ddn_Private_Ddn_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Private_Ddn_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Private_Ddn_Stream_Cursor_Value_Input = {
  aws_config?: InputMaybe<Scalars['jsonb']>;
  cloud?: InputMaybe<Array<Scalars['String']>>;
  control_plane_config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ddn_kind?: InputMaybe<Ddn_Ddn_Enum>;
  fqdn?: InputMaybe<Scalars['String']>;
  gcp_config?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  ndc_hosts?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.private_ddn" */
export enum Ddn_Private_Ddn_Update_Column {
  /** column name */
  AwsConfig = 'aws_config',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  ControlPlaneConfig = 'control_plane_config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DdnKind = 'ddn_kind',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  GcpConfig = 'gcp_config',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NdcHosts = 'ndc_hosts',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Private_Ddn_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ddn_Private_Ddn_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ddn_Private_Ddn_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ddn_Private_Ddn_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ddn_Private_Ddn_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ddn_Private_Ddn_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Private_Ddn_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Private_Ddn_Bool_Exp;
};

/** Enum table for kind of DDN project access level */
export type Ddn_Project_Access_Level = {
  __typename?: 'ddn_project_access_level';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ddn.project_access_level" */
export type Ddn_Project_Access_Level_Aggregate = {
  __typename?: 'ddn_project_access_level_aggregate';
  aggregate?: Maybe<Ddn_Project_Access_Level_Aggregate_Fields>;
  nodes: Array<Ddn_Project_Access_Level>;
};

/** aggregate fields of "ddn.project_access_level" */
export type Ddn_Project_Access_Level_Aggregate_Fields = {
  __typename?: 'ddn_project_access_level_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Project_Access_Level_Max_Fields>;
  min?: Maybe<Ddn_Project_Access_Level_Min_Fields>;
};

/** aggregate fields of "ddn.project_access_level" */
export type Ddn_Project_Access_Level_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Project_Access_Level_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.project_access_level". All fields are combined with a logical 'AND'. */
export type Ddn_Project_Access_Level_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Project_Access_Level_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Project_Access_Level_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Project_Access_Level_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.project_access_level" */
export enum Ddn_Project_Access_Level_Constraint {
  /** unique or primary key constraint on columns "name" */
  ProjectAccessLevelPkey = 'project_access_level_pkey',
}

/** input type for inserting data into table "ddn.project_access_level" */
export type Ddn_Project_Access_Level_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ddn_Project_Access_Level_Max_Fields = {
  __typename?: 'ddn_project_access_level_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ddn_Project_Access_Level_Min_Fields = {
  __typename?: 'ddn_project_access_level_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ddn.project_access_level" */
export type Ddn_Project_Access_Level_Mutation_Response = {
  __typename?: 'ddn_project_access_level_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Project_Access_Level>;
};

/** on_conflict condition type for table "ddn.project_access_level" */
export type Ddn_Project_Access_Level_On_Conflict = {
  constraint: Ddn_Project_Access_Level_Constraint;
  update_columns?: Array<Ddn_Project_Access_Level_Update_Column>;
  where?: InputMaybe<Ddn_Project_Access_Level_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.project_access_level". */
export type Ddn_Project_Access_Level_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.project_access_level */
export type Ddn_Project_Access_Level_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "ddn.project_access_level" */
export enum Ddn_Project_Access_Level_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "ddn.project_access_level" */
export type Ddn_Project_Access_Level_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ddn_project_access_level" */
export type Ddn_Project_Access_Level_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Project_Access_Level_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Project_Access_Level_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ddn.project_access_level" */
export enum Ddn_Project_Access_Level_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type Ddn_Project_Access_Level_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Project_Access_Level_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Project_Access_Level_Bool_Exp;
};

/** columns and relationships of "ddn.project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog = {
  __typename?: 'ddn_project_active_status_changelog';
  active_status_reason: Ddn_Project_Active_Status_Reason_Enum;
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  /** An object relationship */
  project: Ddn_Projects;
  /** An object relationship */
  project_active_status_reason: Ddn_Project_Active_Status_Reason;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "ddn.project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog_Aggregate = {
  __typename?: 'ddn_project_active_status_changelog_aggregate';
  aggregate?: Maybe<Ddn_Project_Active_Status_Changelog_Aggregate_Fields>;
  nodes: Array<Ddn_Project_Active_Status_Changelog>;
};

export type Ddn_Project_Active_Status_Changelog_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<
    Ddn_Project_Active_Status_Changelog_Aggregate_Bool_Exp_Bool_And
  >;
  bool_or?: InputMaybe<
    Ddn_Project_Active_Status_Changelog_Aggregate_Bool_Exp_Bool_Or
  >;
  count?: InputMaybe<
    Ddn_Project_Active_Status_Changelog_Aggregate_Bool_Exp_Count
  >;
};

export type Ddn_Project_Active_Status_Changelog_Aggregate_Bool_Exp_Bool_And = {
  arguments: Ddn_Project_Active_Status_Changelog_Select_Column_Ddn_Project_Active_Status_Changelog_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Project_Active_Status_Changelog_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ddn_Project_Active_Status_Changelog_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Ddn_Project_Active_Status_Changelog_Select_Column_Ddn_Project_Active_Status_Changelog_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Project_Active_Status_Changelog_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ddn_Project_Active_Status_Changelog_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Ddn_Project_Active_Status_Changelog_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Project_Active_Status_Changelog_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog_Aggregate_Fields = {
  __typename?: 'ddn_project_active_status_changelog_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Project_Active_Status_Changelog_Max_Fields>;
  min?: Maybe<Ddn_Project_Active_Status_Changelog_Min_Fields>;
};

/** aggregate fields of "ddn.project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Ddn_Project_Active_Status_Changelog_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Project_Active_Status_Changelog_Max_Order_By>;
  min?: InputMaybe<Ddn_Project_Active_Status_Changelog_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Project_Active_Status_Changelog_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Project_Active_Status_Changelog_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.project_active_status_changelog". All fields are combined with a logical 'AND'. */
export type Ddn_Project_Active_Status_Changelog_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Project_Active_Status_Changelog_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Project_Active_Status_Changelog_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Project_Active_Status_Changelog_Bool_Exp>>;
  active_status_reason?: InputMaybe<
    Ddn_Project_Active_Status_Reason_Enum_Comparison_Exp
  >;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_active_status_reason?: InputMaybe<
    Ddn_Project_Active_Status_Reason_Bool_Exp
  >;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.project_active_status_changelog" */
export enum Ddn_Project_Active_Status_Changelog_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectActiveStatusChangelogPkey = 'project_active_status_changelog_pkey',
}

/** input type for inserting data into table "ddn.project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog_Insert_Input = {
  active_status_reason?: InputMaybe<Ddn_Project_Active_Status_Reason_Enum>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_active_status_reason?: InputMaybe<
    Ddn_Project_Active_Status_Reason_Obj_Rel_Insert_Input
  >;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ddn_Project_Active_Status_Changelog_Max_Fields = {
  __typename?: 'ddn_project_active_status_changelog_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ddn.project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Project_Active_Status_Changelog_Min_Fields = {
  __typename?: 'ddn_project_active_status_changelog_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ddn.project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog_Mutation_Response = {
  __typename?: 'ddn_project_active_status_changelog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Project_Active_Status_Changelog>;
};

/** on_conflict condition type for table "ddn.project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog_On_Conflict = {
  constraint: Ddn_Project_Active_Status_Changelog_Constraint;
  update_columns?: Array<Ddn_Project_Active_Status_Changelog_Update_Column>;
  where?: InputMaybe<Ddn_Project_Active_Status_Changelog_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.project_active_status_changelog". */
export type Ddn_Project_Active_Status_Changelog_Order_By = {
  active_status_reason?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_active_status_reason?: InputMaybe<
    Ddn_Project_Active_Status_Reason_Order_By
  >;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.project_active_status_changelog */
export type Ddn_Project_Active_Status_Changelog_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.project_active_status_changelog" */
export enum Ddn_Project_Active_Status_Changelog_Select_Column {
  /** column name */
  ActiveStatusReason = 'active_status_reason',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  ProjectId = 'project_id',
}

/** select "ddn_project_active_status_changelog_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ddn.project_active_status_changelog" */
export enum Ddn_Project_Active_Status_Changelog_Select_Column_Ddn_Project_Active_Status_Changelog_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** select "ddn_project_active_status_changelog_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ddn.project_active_status_changelog" */
export enum Ddn_Project_Active_Status_Changelog_Select_Column_Ddn_Project_Active_Status_Changelog_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** input type for updating data in table "ddn.project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog_Set_Input = {
  active_status_reason?: InputMaybe<Ddn_Project_Active_Status_Reason_Enum>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ddn_project_active_status_changelog" */
export type Ddn_Project_Active_Status_Changelog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Project_Active_Status_Changelog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Project_Active_Status_Changelog_Stream_Cursor_Value_Input = {
  active_status_reason?: InputMaybe<Ddn_Project_Active_Status_Reason_Enum>;
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "ddn.project_active_status_changelog" */
export enum Ddn_Project_Active_Status_Changelog_Update_Column {
  /** column name */
  ActiveStatusReason = 'active_status_reason',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  ProjectId = 'project_id',
}

export type Ddn_Project_Active_Status_Changelog_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Project_Active_Status_Changelog_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Project_Active_Status_Changelog_Bool_Exp;
};

/** columns and relationships of "ddn.project_active_status_reason" */
export type Ddn_Project_Active_Status_Reason = {
  __typename?: 'ddn_project_active_status_reason';
  description: Scalars['String'];
  reason: Scalars['String'];
};

/** aggregated selection of "ddn.project_active_status_reason" */
export type Ddn_Project_Active_Status_Reason_Aggregate = {
  __typename?: 'ddn_project_active_status_reason_aggregate';
  aggregate?: Maybe<Ddn_Project_Active_Status_Reason_Aggregate_Fields>;
  nodes: Array<Ddn_Project_Active_Status_Reason>;
};

/** aggregate fields of "ddn.project_active_status_reason" */
export type Ddn_Project_Active_Status_Reason_Aggregate_Fields = {
  __typename?: 'ddn_project_active_status_reason_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Project_Active_Status_Reason_Max_Fields>;
  min?: Maybe<Ddn_Project_Active_Status_Reason_Min_Fields>;
};

/** aggregate fields of "ddn.project_active_status_reason" */
export type Ddn_Project_Active_Status_Reason_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Project_Active_Status_Reason_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.project_active_status_reason". All fields are combined with a logical 'AND'. */
export type Ddn_Project_Active_Status_Reason_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Project_Active_Status_Reason_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Project_Active_Status_Reason_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Project_Active_Status_Reason_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.project_active_status_reason" */
export enum Ddn_Project_Active_Status_Reason_Constraint {
  /** unique or primary key constraint on columns "reason" */
  ProjectActiveStatusReasonPkey = 'project_active_status_reason_pkey',
}

export enum Ddn_Project_Active_Status_Reason_Enum {
  /** activated after invoice being paid */
  ActivatedBillingInvoicePaid = 'activated_billing_invoice_paid',
  /** activated based on user request  */
  ActivatedByUser = 'activated_by_user',
  /** deactivated due to dunning */
  DeactivatedDunning = 'deactivated_dunning',
  /** deactivated due to project inactivity */
  DeactivatedInactivity = 'deactivated_inactivity',
  /** manually activating the project */
  ManuallyActivated = 'manually_activated',
  /** manually deactivating the project */
  ManuallyDeactivated = 'manually_deactivated',
  /** new project is created in active state */
  NewActiveProject = 'new_active_project',
}

/** Boolean expression to compare columns of type "ddn_project_active_status_reason_enum". All fields are combined with logical 'AND'. */
export type Ddn_Project_Active_Status_Reason_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ddn_Project_Active_Status_Reason_Enum>;
  _in?: InputMaybe<Array<Ddn_Project_Active_Status_Reason_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ddn_Project_Active_Status_Reason_Enum>;
  _nin?: InputMaybe<Array<Ddn_Project_Active_Status_Reason_Enum>>;
};

/** input type for inserting data into table "ddn.project_active_status_reason" */
export type Ddn_Project_Active_Status_Reason_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ddn_Project_Active_Status_Reason_Max_Fields = {
  __typename?: 'ddn_project_active_status_reason_max_fields';
  description?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ddn_Project_Active_Status_Reason_Min_Fields = {
  __typename?: 'ddn_project_active_status_reason_min_fields';
  description?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ddn.project_active_status_reason" */
export type Ddn_Project_Active_Status_Reason_Mutation_Response = {
  __typename?: 'ddn_project_active_status_reason_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Project_Active_Status_Reason>;
};

/** input type for inserting object relation for remote table "ddn.project_active_status_reason" */
export type Ddn_Project_Active_Status_Reason_Obj_Rel_Insert_Input = {
  data: Ddn_Project_Active_Status_Reason_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Project_Active_Status_Reason_On_Conflict>;
};

/** on_conflict condition type for table "ddn.project_active_status_reason" */
export type Ddn_Project_Active_Status_Reason_On_Conflict = {
  constraint: Ddn_Project_Active_Status_Reason_Constraint;
  update_columns?: Array<Ddn_Project_Active_Status_Reason_Update_Column>;
  where?: InputMaybe<Ddn_Project_Active_Status_Reason_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.project_active_status_reason". */
export type Ddn_Project_Active_Status_Reason_Order_By = {
  description?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.project_active_status_reason */
export type Ddn_Project_Active_Status_Reason_Pk_Columns_Input = {
  reason: Scalars['String'];
};

/** select columns of table "ddn.project_active_status_reason" */
export enum Ddn_Project_Active_Status_Reason_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Reason = 'reason',
}

/** input type for updating data in table "ddn.project_active_status_reason" */
export type Ddn_Project_Active_Status_Reason_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ddn_project_active_status_reason" */
export type Ddn_Project_Active_Status_Reason_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Project_Active_Status_Reason_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Project_Active_Status_Reason_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  reason?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ddn.project_active_status_reason" */
export enum Ddn_Project_Active_Status_Reason_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Reason = 'reason',
}

export type Ddn_Project_Active_Status_Reason_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Project_Active_Status_Reason_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Project_Active_Status_Reason_Bool_Exp;
};

/** entitlements associated with the Hasura v3 projects */
export type Ddn_Project_Entitlement_Access = {
  __typename?: 'ddn_project_entitlement_access';
  created_at: Scalars['timestamp'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  entitlement: Ddn_Project_Entitlement_Catalogue;
  entitlement_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Ddn_Projects;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "ddn.project_entitlement_access" */
export type Ddn_Project_Entitlement_Access_Aggregate = {
  __typename?: 'ddn_project_entitlement_access_aggregate';
  aggregate?: Maybe<Ddn_Project_Entitlement_Access_Aggregate_Fields>;
  nodes: Array<Ddn_Project_Entitlement_Access>;
};

export type Ddn_Project_Entitlement_Access_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Project_Entitlement_Access_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Project_Entitlement_Access_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Project_Entitlement_Access_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.project_entitlement_access" */
export type Ddn_Project_Entitlement_Access_Aggregate_Fields = {
  __typename?: 'ddn_project_entitlement_access_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Project_Entitlement_Access_Max_Fields>;
  min?: Maybe<Ddn_Project_Entitlement_Access_Min_Fields>;
};

/** aggregate fields of "ddn.project_entitlement_access" */
export type Ddn_Project_Entitlement_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.project_entitlement_access" */
export type Ddn_Project_Entitlement_Access_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Project_Entitlement_Access_Max_Order_By>;
  min?: InputMaybe<Ddn_Project_Entitlement_Access_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.project_entitlement_access" */
export type Ddn_Project_Entitlement_Access_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Project_Entitlement_Access_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Project_Entitlement_Access_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.project_entitlement_access". All fields are combined with a logical 'AND'. */
export type Ddn_Project_Entitlement_Access_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Project_Entitlement_Access_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  entitlement?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Bool_Exp>;
  entitlement_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.project_entitlement_access" */
export enum Ddn_Project_Entitlement_Access_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectEntitlementAccessPkey = 'project_entitlement_access_pkey',
}

/** input type for inserting data into table "ddn.project_entitlement_access" */
export type Ddn_Project_Entitlement_Access_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  entitlement?: InputMaybe<
    Ddn_Project_Entitlement_Catalogue_Obj_Rel_Insert_Input
  >;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Ddn_Project_Entitlement_Access_Max_Fields = {
  __typename?: 'ddn_project_entitlement_access_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  entitlement_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "ddn.project_entitlement_access" */
export type Ddn_Project_Entitlement_Access_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Project_Entitlement_Access_Min_Fields = {
  __typename?: 'ddn_project_entitlement_access_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  entitlement_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "ddn.project_entitlement_access" */
export type Ddn_Project_Entitlement_Access_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.project_entitlement_access" */
export type Ddn_Project_Entitlement_Access_Mutation_Response = {
  __typename?: 'ddn_project_entitlement_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Project_Entitlement_Access>;
};

/** on_conflict condition type for table "ddn.project_entitlement_access" */
export type Ddn_Project_Entitlement_Access_On_Conflict = {
  constraint: Ddn_Project_Entitlement_Access_Constraint;
  update_columns?: Array<Ddn_Project_Entitlement_Access_Update_Column>;
  where?: InputMaybe<Ddn_Project_Entitlement_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.project_entitlement_access". */
export type Ddn_Project_Entitlement_Access_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  entitlement?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.project_entitlement_access */
export type Ddn_Project_Entitlement_Access_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.project_entitlement_access" */
export enum Ddn_Project_Entitlement_Access_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EntitlementId = 'entitlement_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.project_entitlement_access" */
export type Ddn_Project_Entitlement_Access_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "ddn_project_entitlement_access" */
export type Ddn_Project_Entitlement_Access_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Project_Entitlement_Access_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Project_Entitlement_Access_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "ddn.project_entitlement_access" */
export enum Ddn_Project_Entitlement_Access_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EntitlementId = 'entitlement_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Project_Entitlement_Access_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Project_Entitlement_Access_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Project_Entitlement_Access_Bool_Exp;
};

/** Stores all versions of all types of v3 project entitlements. */
export type Ddn_Project_Entitlement_Catalogue = {
  __typename?: 'ddn_project_entitlement_catalogue';
  base_cost?: Maybe<Scalars['Int']>;
  config_is_enabled: Scalars['Boolean'];
  config_limit?: Maybe<Scalars['Int']>;
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  type: Ddn_Project_Entitlement_Types_Enum;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "ddn.project_entitlement_catalogue" */
export type Ddn_Project_Entitlement_Catalogue_Aggregate = {
  __typename?: 'ddn_project_entitlement_catalogue_aggregate';
  aggregate?: Maybe<Ddn_Project_Entitlement_Catalogue_Aggregate_Fields>;
  nodes: Array<Ddn_Project_Entitlement_Catalogue>;
};

/** aggregate fields of "ddn.project_entitlement_catalogue" */
export type Ddn_Project_Entitlement_Catalogue_Aggregate_Fields = {
  __typename?: 'ddn_project_entitlement_catalogue_aggregate_fields';
  avg?: Maybe<Ddn_Project_Entitlement_Catalogue_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ddn_Project_Entitlement_Catalogue_Max_Fields>;
  min?: Maybe<Ddn_Project_Entitlement_Catalogue_Min_Fields>;
  stddev?: Maybe<Ddn_Project_Entitlement_Catalogue_Stddev_Fields>;
  stddev_pop?: Maybe<Ddn_Project_Entitlement_Catalogue_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ddn_Project_Entitlement_Catalogue_Stddev_Samp_Fields>;
  sum?: Maybe<Ddn_Project_Entitlement_Catalogue_Sum_Fields>;
  var_pop?: Maybe<Ddn_Project_Entitlement_Catalogue_Var_Pop_Fields>;
  var_samp?: Maybe<Ddn_Project_Entitlement_Catalogue_Var_Samp_Fields>;
  variance?: Maybe<Ddn_Project_Entitlement_Catalogue_Variance_Fields>;
};

/** aggregate fields of "ddn.project_entitlement_catalogue" */
export type Ddn_Project_Entitlement_Catalogue_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Project_Entitlement_Catalogue_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Ddn_Project_Entitlement_Catalogue_Avg_Fields = {
  __typename?: 'ddn_project_entitlement_catalogue_avg_fields';
  base_cost?: Maybe<Scalars['Float']>;
  config_limit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "ddn.project_entitlement_catalogue". All fields are combined with a logical 'AND'. */
export type Ddn_Project_Entitlement_Catalogue_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Project_Entitlement_Catalogue_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Project_Entitlement_Catalogue_Bool_Exp>>;
  base_cost?: InputMaybe<Int_Comparison_Exp>;
  config_is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  config_limit?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Ddn_Project_Entitlement_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.project_entitlement_catalogue" */
export enum Ddn_Project_Entitlement_Catalogue_Constraint {
  /** unique or primary key constraint on columns "name" */
  ProjectEntitlementCatalogueNameKey = 'project_entitlement_catalogue_name_key',
  /** unique or primary key constraint on columns "id" */
  ProjectEntitlementCataloguePkey = 'project_entitlement_catalogue_pkey',
}

/** input type for incrementing numeric columns in table "ddn.project_entitlement_catalogue" */
export type Ddn_Project_Entitlement_Catalogue_Inc_Input = {
  base_cost?: InputMaybe<Scalars['Int']>;
  config_limit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ddn.project_entitlement_catalogue" */
export type Ddn_Project_Entitlement_Catalogue_Insert_Input = {
  base_cost?: InputMaybe<Scalars['Int']>;
  config_is_enabled?: InputMaybe<Scalars['Boolean']>;
  config_limit?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Ddn_Project_Entitlement_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Ddn_Project_Entitlement_Catalogue_Max_Fields = {
  __typename?: 'ddn_project_entitlement_catalogue_max_fields';
  base_cost?: Maybe<Scalars['Int']>;
  config_limit?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Ddn_Project_Entitlement_Catalogue_Min_Fields = {
  __typename?: 'ddn_project_entitlement_catalogue_min_fields';
  base_cost?: Maybe<Scalars['Int']>;
  config_limit?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "ddn.project_entitlement_catalogue" */
export type Ddn_Project_Entitlement_Catalogue_Mutation_Response = {
  __typename?: 'ddn_project_entitlement_catalogue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Project_Entitlement_Catalogue>;
};

/** input type for inserting object relation for remote table "ddn.project_entitlement_catalogue" */
export type Ddn_Project_Entitlement_Catalogue_Obj_Rel_Insert_Input = {
  data: Ddn_Project_Entitlement_Catalogue_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Project_Entitlement_Catalogue_On_Conflict>;
};

/** on_conflict condition type for table "ddn.project_entitlement_catalogue" */
export type Ddn_Project_Entitlement_Catalogue_On_Conflict = {
  constraint: Ddn_Project_Entitlement_Catalogue_Constraint;
  update_columns?: Array<Ddn_Project_Entitlement_Catalogue_Update_Column>;
  where?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.project_entitlement_catalogue". */
export type Ddn_Project_Entitlement_Catalogue_Order_By = {
  base_cost?: InputMaybe<Order_By>;
  config_is_enabled?: InputMaybe<Order_By>;
  config_limit?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.project_entitlement_catalogue */
export type Ddn_Project_Entitlement_Catalogue_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.project_entitlement_catalogue" */
export enum Ddn_Project_Entitlement_Catalogue_Select_Column {
  /** column name */
  BaseCost = 'base_cost',
  /** column name */
  ConfigIsEnabled = 'config_is_enabled',
  /** column name */
  ConfigLimit = 'config_limit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.project_entitlement_catalogue" */
export type Ddn_Project_Entitlement_Catalogue_Set_Input = {
  base_cost?: InputMaybe<Scalars['Int']>;
  config_is_enabled?: InputMaybe<Scalars['Boolean']>;
  config_limit?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Ddn_Project_Entitlement_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Ddn_Project_Entitlement_Catalogue_Stddev_Fields = {
  __typename?: 'ddn_project_entitlement_catalogue_stddev_fields';
  base_cost?: Maybe<Scalars['Float']>;
  config_limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Ddn_Project_Entitlement_Catalogue_Stddev_Pop_Fields = {
  __typename?: 'ddn_project_entitlement_catalogue_stddev_pop_fields';
  base_cost?: Maybe<Scalars['Float']>;
  config_limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Ddn_Project_Entitlement_Catalogue_Stddev_Samp_Fields = {
  __typename?: 'ddn_project_entitlement_catalogue_stddev_samp_fields';
  base_cost?: Maybe<Scalars['Float']>;
  config_limit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "ddn_project_entitlement_catalogue" */
export type Ddn_Project_Entitlement_Catalogue_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Project_Entitlement_Catalogue_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Project_Entitlement_Catalogue_Stream_Cursor_Value_Input = {
  base_cost?: InputMaybe<Scalars['Int']>;
  config_is_enabled?: InputMaybe<Scalars['Boolean']>;
  config_limit?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Ddn_Project_Entitlement_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Ddn_Project_Entitlement_Catalogue_Sum_Fields = {
  __typename?: 'ddn_project_entitlement_catalogue_sum_fields';
  base_cost?: Maybe<Scalars['Int']>;
  config_limit?: Maybe<Scalars['Int']>;
};

/** update columns of table "ddn.project_entitlement_catalogue" */
export enum Ddn_Project_Entitlement_Catalogue_Update_Column {
  /** column name */
  BaseCost = 'base_cost',
  /** column name */
  ConfigIsEnabled = 'config_is_enabled',
  /** column name */
  ConfigLimit = 'config_limit',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Project_Entitlement_Catalogue_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Project_Entitlement_Catalogue_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ddn_Project_Entitlement_Catalogue_Var_Pop_Fields = {
  __typename?: 'ddn_project_entitlement_catalogue_var_pop_fields';
  base_cost?: Maybe<Scalars['Float']>;
  config_limit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Ddn_Project_Entitlement_Catalogue_Var_Samp_Fields = {
  __typename?: 'ddn_project_entitlement_catalogue_var_samp_fields';
  base_cost?: Maybe<Scalars['Float']>;
  config_limit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Ddn_Project_Entitlement_Catalogue_Variance_Fields = {
  __typename?: 'ddn_project_entitlement_catalogue_variance_fields';
  base_cost?: Maybe<Scalars['Float']>;
  config_limit?: Maybe<Scalars['Float']>;
};

/** Enums representing types/categories of entitlements for a Hasura v3 project */
export type Ddn_Project_Entitlement_Types = {
  __typename?: 'ddn_project_entitlement_types';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "ddn.project_entitlement_types" */
export type Ddn_Project_Entitlement_Types_Aggregate = {
  __typename?: 'ddn_project_entitlement_types_aggregate';
  aggregate?: Maybe<Ddn_Project_Entitlement_Types_Aggregate_Fields>;
  nodes: Array<Ddn_Project_Entitlement_Types>;
};

/** aggregate fields of "ddn.project_entitlement_types" */
export type Ddn_Project_Entitlement_Types_Aggregate_Fields = {
  __typename?: 'ddn_project_entitlement_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Project_Entitlement_Types_Max_Fields>;
  min?: Maybe<Ddn_Project_Entitlement_Types_Min_Fields>;
};

/** aggregate fields of "ddn.project_entitlement_types" */
export type Ddn_Project_Entitlement_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.project_entitlement_types". All fields are combined with a logical 'AND'. */
export type Ddn_Project_Entitlement_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Project_Entitlement_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.project_entitlement_types" */
export enum Ddn_Project_Entitlement_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  ProjectEntitlementTypesPkey = 'project_entitlement_types_pkey',
}

export enum Ddn_Project_Entitlement_Types_Enum {
  /** maximum number of hits for command */
  ActiveCommandHits = 'active_command_hits',
  /** maximum number of hits for models */
  ActiveModelHits = 'active_model_hits',
  /** Maximum number of builds a v3 project can have at once */
  BuildLimit = 'build_limit',
  /** Decides if adding collaborators are allowed for a project */
  CollaborationAccess = 'collaboration_access',
  /** Maximum number of builds a v3 project can have at once */
  EnvironmentLimit = 'environment_limit',
  /** hard usage threshold */
  HardThreshold = 'hard_threshold',
  /** Maximum size in for a serialized metadata artifact */
  MetadataSizeLimit = 'metadata_size_limit',
  /** The access period for observability and traces i.e what period of data is shown to the user */
  ObservabilityRetentionAccess = 'observability_retention_access',
  /** Determines if project APIs can be accessed */
  ProjectApiAccess = 'project_api_access',
  /** Decides if access to schema diff API is enabled for a project */
  SchemaDiffApiAccess = 'schema_diff_api_access',
  /** soft usage threshold */
  SoftThreshold = 'soft_threshold',
  /** Decides how long a project has on a trial plan */
  TrialDays = 'trial_days',
}

/** Boolean expression to compare columns of type "ddn_project_entitlement_types_enum". All fields are combined with logical 'AND'. */
export type Ddn_Project_Entitlement_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Ddn_Project_Entitlement_Types_Enum>;
  _in?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Ddn_Project_Entitlement_Types_Enum>;
  _nin?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Enum>>;
};

/** input type for inserting data into table "ddn.project_entitlement_types" */
export type Ddn_Project_Entitlement_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ddn_Project_Entitlement_Types_Max_Fields = {
  __typename?: 'ddn_project_entitlement_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ddn_Project_Entitlement_Types_Min_Fields = {
  __typename?: 'ddn_project_entitlement_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ddn.project_entitlement_types" */
export type Ddn_Project_Entitlement_Types_Mutation_Response = {
  __typename?: 'ddn_project_entitlement_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Project_Entitlement_Types>;
};

/** on_conflict condition type for table "ddn.project_entitlement_types" */
export type Ddn_Project_Entitlement_Types_On_Conflict = {
  constraint: Ddn_Project_Entitlement_Types_Constraint;
  update_columns?: Array<Ddn_Project_Entitlement_Types_Update_Column>;
  where?: InputMaybe<Ddn_Project_Entitlement_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.project_entitlement_types". */
export type Ddn_Project_Entitlement_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.project_entitlement_types */
export type Ddn_Project_Entitlement_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "ddn.project_entitlement_types" */
export enum Ddn_Project_Entitlement_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "ddn.project_entitlement_types" */
export type Ddn_Project_Entitlement_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ddn_project_entitlement_types" */
export type Ddn_Project_Entitlement_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Project_Entitlement_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Project_Entitlement_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ddn.project_entitlement_types" */
export enum Ddn_Project_Entitlement_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Ddn_Project_Entitlement_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Project_Entitlement_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Project_Entitlement_Types_Bool_Exp;
};

/** captures changelog of project association with plans over time */
export type Ddn_Project_Plan_Changelog = {
  __typename?: 'ddn_project_plan_changelog';
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  plan: Ddn_Plans;
  plan_id: Scalars['uuid'];
  /** An object relationship */
  project: Ddn_Projects;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "ddn.project_plan_changelog" */
export type Ddn_Project_Plan_Changelog_Aggregate = {
  __typename?: 'ddn_project_plan_changelog_aggregate';
  aggregate?: Maybe<Ddn_Project_Plan_Changelog_Aggregate_Fields>;
  nodes: Array<Ddn_Project_Plan_Changelog>;
};

export type Ddn_Project_Plan_Changelog_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Project_Plan_Changelog_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Project_Plan_Changelog_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Project_Plan_Changelog_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.project_plan_changelog" */
export type Ddn_Project_Plan_Changelog_Aggregate_Fields = {
  __typename?: 'ddn_project_plan_changelog_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Project_Plan_Changelog_Max_Fields>;
  min?: Maybe<Ddn_Project_Plan_Changelog_Min_Fields>;
};

/** aggregate fields of "ddn.project_plan_changelog" */
export type Ddn_Project_Plan_Changelog_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.project_plan_changelog" */
export type Ddn_Project_Plan_Changelog_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Project_Plan_Changelog_Max_Order_By>;
  min?: InputMaybe<Ddn_Project_Plan_Changelog_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.project_plan_changelog" */
export type Ddn_Project_Plan_Changelog_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Project_Plan_Changelog_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Project_Plan_Changelog_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.project_plan_changelog". All fields are combined with a logical 'AND'. */
export type Ddn_Project_Plan_Changelog_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Project_Plan_Changelog_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  plan?: InputMaybe<Ddn_Plans_Bool_Exp>;
  plan_id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.project_plan_changelog" */
export enum Ddn_Project_Plan_Changelog_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectPlanChangelogPkey = 'project_plan_changelog_pkey',
}

/** input type for inserting data into table "ddn.project_plan_changelog" */
export type Ddn_Project_Plan_Changelog_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan?: InputMaybe<Ddn_Plans_Obj_Rel_Insert_Input>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ddn_Project_Plan_Changelog_Max_Fields = {
  __typename?: 'ddn_project_plan_changelog_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ddn.project_plan_changelog" */
export type Ddn_Project_Plan_Changelog_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Project_Plan_Changelog_Min_Fields = {
  __typename?: 'ddn_project_plan_changelog_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  plan_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ddn.project_plan_changelog" */
export type Ddn_Project_Plan_Changelog_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.project_plan_changelog" */
export type Ddn_Project_Plan_Changelog_Mutation_Response = {
  __typename?: 'ddn_project_plan_changelog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Project_Plan_Changelog>;
};

/** on_conflict condition type for table "ddn.project_plan_changelog" */
export type Ddn_Project_Plan_Changelog_On_Conflict = {
  constraint: Ddn_Project_Plan_Changelog_Constraint;
  update_columns?: Array<Ddn_Project_Plan_Changelog_Update_Column>;
  where?: InputMaybe<Ddn_Project_Plan_Changelog_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.project_plan_changelog". */
export type Ddn_Project_Plan_Changelog_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan?: InputMaybe<Ddn_Plans_Order_By>;
  plan_id?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.project_plan_changelog */
export type Ddn_Project_Plan_Changelog_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.project_plan_changelog" */
export enum Ddn_Project_Plan_Changelog_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  ProjectId = 'project_id',
}

/** input type for updating data in table "ddn.project_plan_changelog" */
export type Ddn_Project_Plan_Changelog_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ddn_project_plan_changelog" */
export type Ddn_Project_Plan_Changelog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Project_Plan_Changelog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Project_Plan_Changelog_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "ddn.project_plan_changelog" */
export enum Ddn_Project_Plan_Changelog_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlanId = 'plan_id',
  /** column name */
  ProjectId = 'project_id',
}

export type Ddn_Project_Plan_Changelog_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Project_Plan_Changelog_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Project_Plan_Changelog_Bool_Exp;
};

/** Table storing requests to collaborate on a project. */
export type Ddn_Project_Request_Access = {
  __typename?: 'ddn_project_request_access';
  access_level: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Ddn_Projects;
  project_id: Scalars['uuid'];
  requested_by: Scalars['uuid'];
  /** An object relationship */
  requesting_user?: Maybe<Users_Public>;
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ddn.project_request_access" */
export type Ddn_Project_Request_Access_Aggregate = {
  __typename?: 'ddn_project_request_access_aggregate';
  aggregate?: Maybe<Ddn_Project_Request_Access_Aggregate_Fields>;
  nodes: Array<Ddn_Project_Request_Access>;
};

export type Ddn_Project_Request_Access_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Project_Request_Access_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Project_Request_Access_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Project_Request_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Project_Request_Access_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.project_request_access" */
export type Ddn_Project_Request_Access_Aggregate_Fields = {
  __typename?: 'ddn_project_request_access_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Project_Request_Access_Max_Fields>;
  min?: Maybe<Ddn_Project_Request_Access_Min_Fields>;
};

/** aggregate fields of "ddn.project_request_access" */
export type Ddn_Project_Request_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Project_Request_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.project_request_access" */
export type Ddn_Project_Request_Access_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Project_Request_Access_Max_Order_By>;
  min?: InputMaybe<Ddn_Project_Request_Access_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.project_request_access" */
export type Ddn_Project_Request_Access_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Project_Request_Access_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Project_Request_Access_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.project_request_access". All fields are combined with a logical 'AND'. */
export type Ddn_Project_Request_Access_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Project_Request_Access_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Project_Request_Access_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Project_Request_Access_Bool_Exp>>;
  access_level?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  requested_by?: InputMaybe<Uuid_Comparison_Exp>;
  requesting_user?: InputMaybe<Users_Public_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.project_request_access" */
export enum Ddn_Project_Request_Access_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectRequestAccessPkey = 'project_request_access_pkey',
}

/** input type for inserting data into table "ddn.project_request_access" */
export type Ddn_Project_Request_Access_Insert_Input = {
  access_level?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  requested_by?: InputMaybe<Scalars['uuid']>;
  requesting_user?: InputMaybe<Users_Public_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Project_Request_Access_Max_Fields = {
  __typename?: 'ddn_project_request_access_max_fields';
  access_level?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  requested_by?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ddn.project_request_access" */
export type Ddn_Project_Request_Access_Max_Order_By = {
  access_level?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  requested_by?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Project_Request_Access_Min_Fields = {
  __typename?: 'ddn_project_request_access_min_fields';
  access_level?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  requested_by?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ddn.project_request_access" */
export type Ddn_Project_Request_Access_Min_Order_By = {
  access_level?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  requested_by?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.project_request_access" */
export type Ddn_Project_Request_Access_Mutation_Response = {
  __typename?: 'ddn_project_request_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Project_Request_Access>;
};

/** input type for inserting object relation for remote table "ddn.project_request_access" */
export type Ddn_Project_Request_Access_Obj_Rel_Insert_Input = {
  data: Ddn_Project_Request_Access_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Project_Request_Access_On_Conflict>;
};

/** on_conflict condition type for table "ddn.project_request_access" */
export type Ddn_Project_Request_Access_On_Conflict = {
  constraint: Ddn_Project_Request_Access_Constraint;
  update_columns?: Array<Ddn_Project_Request_Access_Update_Column>;
  where?: InputMaybe<Ddn_Project_Request_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.project_request_access". */
export type Ddn_Project_Request_Access_Order_By = {
  access_level?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  requested_by?: InputMaybe<Order_By>;
  requesting_user?: InputMaybe<Users_Public_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.project_request_access */
export type Ddn_Project_Request_Access_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.project_request_access" */
export enum Ddn_Project_Request_Access_Select_Column {
  /** column name */
  AccessLevel = 'access_level',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RequestedBy = 'requested_by',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.project_request_access" */
export type Ddn_Project_Request_Access_Set_Input = {
  access_level?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  requested_by?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_project_request_access" */
export type Ddn_Project_Request_Access_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Project_Request_Access_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Project_Request_Access_Stream_Cursor_Value_Input = {
  access_level?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  requested_by?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.project_request_access" */
export enum Ddn_Project_Request_Access_Update_Column {
  /** column name */
  AccessLevel = 'access_level',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RequestedBy = 'requested_by',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Project_Request_Access_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Project_Request_Access_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Project_Request_Access_Bool_Exp;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_Projects = {
  __typename?: 'ddn_projects';
  /** A computed field, executes function "ddn.get_active_status_reason" */
  active_status_reason?: Maybe<Scalars['String']>;
  /** An array relationship */
  builds: Array<Ddn_Build>;
  /** An aggregate relationship */
  builds_aggregate: Ddn_Build_Aggregate;
  can_request_access: Scalars['Boolean'];
  /** An array relationship */
  collaboration_requests: Array<Ddn_Project_Request_Access>;
  /** An aggregate relationship */
  collaboration_requests_aggregate: Ddn_Project_Request_Access_Aggregate;
  created_at: Scalars['timestamptz'];
  ddn_id: Scalars['uuid'];
  /** An array relationship */
  default_teams: Array<Ddn_Team>;
  /** An aggregate relationship */
  default_teams_aggregate: Ddn_Team_Aggregate;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  entitlements: Array<Ddn_Project_Entitlement_Access>;
  /** An aggregate relationship */
  entitlements_aggregate: Ddn_Project_Entitlement_Access_Aggregate;
  /** An array relationship */
  environments: Array<Ddn_Environment>;
  /** An aggregate relationship */
  environments_aggregate: Ddn_Environment_Aggregate;
  id: Scalars['uuid'];
  /** A computed field, executes function "ddn.get_if_project_active" */
  is_active?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** An object relationship */
  owner: Users;
  owner_id: Scalars['uuid'];
  /** project's current plan's activation timestamp */
  plan_activation_timestamp?: Maybe<Scalars['timestamptz']>;
  /** project's current plan name */
  plan_name?: Maybe<Scalars['String']>;
  /** An object relationship */
  private_ddn: Ddn_Private_Ddn;
  /** An array relationship */
  project_active_status_changelog: Array<Ddn_Project_Active_Status_Changelog>;
  /** An aggregate relationship */
  project_active_status_changelog_aggregate: Ddn_Project_Active_Status_Changelog_Aggregate;
  /** An array relationship */
  subgraphs: Array<Ddn_Subgraph>;
  /** An aggregate relationship */
  subgraphs_aggregate: Ddn_Subgraph_Aggregate;
  /** An array relationship */
  team_accesses: Array<Ddn_Team_Project_Access>;
  /** An aggregate relationship */
  team_accesses_aggregate: Ddn_Team_Project_Access_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_accesses: Array<Ddn_User_Project_Access>;
  /** An aggregate relationship */
  user_accesses_aggregate: Ddn_User_Project_Access_Aggregate;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsBuildsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Order_By>>;
  where?: InputMaybe<Ddn_Build_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsBuilds_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Order_By>>;
  where?: InputMaybe<Ddn_Build_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsCollaboration_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Request_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Request_Access_Order_By>>;
  where?: InputMaybe<Ddn_Project_Request_Access_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsCollaboration_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Request_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Request_Access_Order_By>>;
  where?: InputMaybe<Ddn_Project_Request_Access_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsDefault_TeamsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Order_By>>;
  where?: InputMaybe<Ddn_Team_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsDefault_Teams_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Order_By>>;
  where?: InputMaybe<Ddn_Team_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsEntitlementsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Access_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsEntitlements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Access_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsEnvironmentsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Environment_Order_By>>;
  where?: InputMaybe<Ddn_Environment_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsEnvironments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Environment_Order_By>>;
  where?: InputMaybe<Ddn_Environment_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsProject_Active_Status_ChangelogArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Active_Status_Changelog_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Active_Status_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Project_Active_Status_Changelog_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsProject_Active_Status_Changelog_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Active_Status_Changelog_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Active_Status_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Project_Active_Status_Changelog_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsSubgraphsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Subgraph_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Subgraph_Order_By>>;
  where?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsSubgraphs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Subgraph_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Subgraph_Order_By>>;
  where?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsTeam_AccessesArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsTeam_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsUser_AccessesArgs = {
  distinct_on?: InputMaybe<Array<Ddn_User_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_User_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
};

/** columns and relationships of "ddn.projects" */
export type Ddn_ProjectsUser_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_User_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_User_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
};

/** aggregated selection of "ddn.projects" */
export type Ddn_Projects_Aggregate = {
  __typename?: 'ddn_projects_aggregate';
  aggregate?: Maybe<Ddn_Projects_Aggregate_Fields>;
  nodes: Array<Ddn_Projects>;
};

export type Ddn_Projects_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Ddn_Projects_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Ddn_Projects_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Ddn_Projects_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Projects_Aggregate_Bool_Exp_Bool_And = {
  arguments: Ddn_Projects_Select_Column_Ddn_Projects_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Projects_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ddn_Projects_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Ddn_Projects_Select_Column_Ddn_Projects_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Projects_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ddn_Projects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Projects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.projects" */
export type Ddn_Projects_Aggregate_Fields = {
  __typename?: 'ddn_projects_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Projects_Max_Fields>;
  min?: Maybe<Ddn_Projects_Min_Fields>;
};

/** aggregate fields of "ddn.projects" */
export type Ddn_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.projects" */
export type Ddn_Projects_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Projects_Max_Order_By>;
  min?: InputMaybe<Ddn_Projects_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.projects" */
export type Ddn_Projects_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Projects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Projects_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.projects". All fields are combined with a logical 'AND'. */
export type Ddn_Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Projects_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Projects_Bool_Exp>>;
  active_status_reason?: InputMaybe<String_Comparison_Exp>;
  builds?: InputMaybe<Ddn_Build_Bool_Exp>;
  builds_aggregate?: InputMaybe<Ddn_Build_Aggregate_Bool_Exp>;
  can_request_access?: InputMaybe<Boolean_Comparison_Exp>;
  collaboration_requests?: InputMaybe<Ddn_Project_Request_Access_Bool_Exp>;
  collaboration_requests_aggregate?: InputMaybe<
    Ddn_Project_Request_Access_Aggregate_Bool_Exp
  >;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ddn_id?: InputMaybe<Uuid_Comparison_Exp>;
  default_teams?: InputMaybe<Ddn_Team_Bool_Exp>;
  default_teams_aggregate?: InputMaybe<Ddn_Team_Aggregate_Bool_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  entitlements?: InputMaybe<Ddn_Project_Entitlement_Access_Bool_Exp>;
  entitlements_aggregate?: InputMaybe<
    Ddn_Project_Entitlement_Access_Aggregate_Bool_Exp
  >;
  environments?: InputMaybe<Ddn_Environment_Bool_Exp>;
  environments_aggregate?: InputMaybe<Ddn_Environment_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  plan_activation_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  plan_name?: InputMaybe<String_Comparison_Exp>;
  private_ddn?: InputMaybe<Ddn_Private_Ddn_Bool_Exp>;
  project_active_status_changelog?: InputMaybe<
    Ddn_Project_Active_Status_Changelog_Bool_Exp
  >;
  project_active_status_changelog_aggregate?: InputMaybe<
    Ddn_Project_Active_Status_Changelog_Aggregate_Bool_Exp
  >;
  subgraphs?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
  subgraphs_aggregate?: InputMaybe<Ddn_Subgraph_Aggregate_Bool_Exp>;
  team_accesses?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
  team_accesses_aggregate?: InputMaybe<
    Ddn_Team_Project_Access_Aggregate_Bool_Exp
  >;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_accesses?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
  user_accesses_aggregate?: InputMaybe<
    Ddn_User_Project_Access_Aggregate_Bool_Exp
  >;
};

/** unique or primary key constraints on table "ddn.projects" */
export enum Ddn_Projects_Constraint {
  /** unique or primary key constraint on columns "name" */
  ProjectsNameKey = 'projects_name_key',
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey',
}

/** input type for inserting data into table "ddn.projects" */
export type Ddn_Projects_Insert_Input = {
  builds?: InputMaybe<Ddn_Build_Arr_Rel_Insert_Input>;
  can_request_access?: InputMaybe<Scalars['Boolean']>;
  collaboration_requests?: InputMaybe<
    Ddn_Project_Request_Access_Arr_Rel_Insert_Input
  >;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ddn_id?: InputMaybe<Scalars['uuid']>;
  default_teams?: InputMaybe<Ddn_Team_Arr_Rel_Insert_Input>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  entitlements?: InputMaybe<
    Ddn_Project_Entitlement_Access_Arr_Rel_Insert_Input
  >;
  environments?: InputMaybe<Ddn_Environment_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owner?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  private_ddn?: InputMaybe<Ddn_Private_Ddn_Obj_Rel_Insert_Input>;
  project_active_status_changelog?: InputMaybe<
    Ddn_Project_Active_Status_Changelog_Arr_Rel_Insert_Input
  >;
  subgraphs?: InputMaybe<Ddn_Subgraph_Arr_Rel_Insert_Input>;
  team_accesses?: InputMaybe<Ddn_Team_Project_Access_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_accesses?: InputMaybe<Ddn_User_Project_Access_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ddn_Projects_Max_Fields = {
  __typename?: 'ddn_projects_max_fields';
  /** A computed field, executes function "ddn.get_active_status_reason" */
  active_status_reason?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ddn_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  /** project's current plan's activation timestamp */
  plan_activation_timestamp?: Maybe<Scalars['timestamptz']>;
  /** project's current plan name */
  plan_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ddn.projects" */
export type Ddn_Projects_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ddn_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Projects_Min_Fields = {
  __typename?: 'ddn_projects_min_fields';
  /** A computed field, executes function "ddn.get_active_status_reason" */
  active_status_reason?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ddn_id?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  /** project's current plan's activation timestamp */
  plan_activation_timestamp?: Maybe<Scalars['timestamptz']>;
  /** project's current plan name */
  plan_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ddn.projects" */
export type Ddn_Projects_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ddn_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.projects" */
export type Ddn_Projects_Mutation_Response = {
  __typename?: 'ddn_projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Projects>;
};

/** input type for inserting object relation for remote table "ddn.projects" */
export type Ddn_Projects_Obj_Rel_Insert_Input = {
  data: Ddn_Projects_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Projects_On_Conflict>;
};

/** on_conflict condition type for table "ddn.projects" */
export type Ddn_Projects_On_Conflict = {
  constraint: Ddn_Projects_Constraint;
  update_columns?: Array<Ddn_Projects_Update_Column>;
  where?: InputMaybe<Ddn_Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.projects". */
export type Ddn_Projects_Order_By = {
  active_status_reason?: InputMaybe<Order_By>;
  builds_aggregate?: InputMaybe<Ddn_Build_Aggregate_Order_By>;
  can_request_access?: InputMaybe<Order_By>;
  collaboration_requests_aggregate?: InputMaybe<
    Ddn_Project_Request_Access_Aggregate_Order_By
  >;
  created_at?: InputMaybe<Order_By>;
  ddn_id?: InputMaybe<Order_By>;
  default_teams_aggregate?: InputMaybe<Ddn_Team_Aggregate_Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  entitlements_aggregate?: InputMaybe<
    Ddn_Project_Entitlement_Access_Aggregate_Order_By
  >;
  environments_aggregate?: InputMaybe<Ddn_Environment_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  plan_activation_timestamp?: InputMaybe<Order_By>;
  plan_name?: InputMaybe<Order_By>;
  private_ddn?: InputMaybe<Ddn_Private_Ddn_Order_By>;
  project_active_status_changelog_aggregate?: InputMaybe<
    Ddn_Project_Active_Status_Changelog_Aggregate_Order_By
  >;
  subgraphs_aggregate?: InputMaybe<Ddn_Subgraph_Aggregate_Order_By>;
  team_accesses_aggregate?: InputMaybe<
    Ddn_Team_Project_Access_Aggregate_Order_By
  >;
  updated_at?: InputMaybe<Order_By>;
  user_accesses_aggregate?: InputMaybe<
    Ddn_User_Project_Access_Aggregate_Order_By
  >;
};

/** primary key columns input for table: ddn.projects */
export type Ddn_Projects_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.projects" */
export enum Ddn_Projects_Select_Column {
  /** column name */
  CanRequestAccess = 'can_request_access',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DdnId = 'ddn_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "ddn_projects_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ddn.projects" */
export enum Ddn_Projects_Select_Column_Ddn_Projects_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CanRequestAccess = 'can_request_access',
}

/** select "ddn_projects_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ddn.projects" */
export enum Ddn_Projects_Select_Column_Ddn_Projects_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CanRequestAccess = 'can_request_access',
}

/** input type for updating data in table "ddn.projects" */
export type Ddn_Projects_Set_Input = {
  can_request_access?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ddn_id?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_projects" */
export type Ddn_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Projects_Stream_Cursor_Value_Input = {
  can_request_access?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ddn_id?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.projects" */
export enum Ddn_Projects_Update_Column {
  /** column name */
  CanRequestAccess = 'can_request_access',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DdnId = 'ddn_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Projects_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Projects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Projects_Bool_Exp;
};

export type Ddn_Search_Tunnel_Cluster_Args = {
  regions?: InputMaybe<Scalars['_text']>;
};

/** Table to hold the secret keys of namespaces by environment */
export type Ddn_Secret_Key = {
  __typename?: 'ddn_secret_key';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An object relationship */
  environment: Ddn_Environment;
  environment_id: Scalars['uuid'];
  id: Scalars['uuid'];
  is_supergraph_secret: Scalars['Boolean'];
  key: Scalars['String'];
  /** An object relationship */
  project: Ddn_Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  subgraph?: Maybe<Ddn_Subgraph>;
  subgraph_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ddn.secret_key" */
export type Ddn_Secret_Key_Aggregate = {
  __typename?: 'ddn_secret_key_aggregate';
  aggregate?: Maybe<Ddn_Secret_Key_Aggregate_Fields>;
  nodes: Array<Ddn_Secret_Key>;
};

export type Ddn_Secret_Key_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Ddn_Secret_Key_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Ddn_Secret_Key_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Ddn_Secret_Key_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Secret_Key_Aggregate_Bool_Exp_Bool_And = {
  arguments: Ddn_Secret_Key_Select_Column_Ddn_Secret_Key_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ddn_Secret_Key_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Ddn_Secret_Key_Select_Column_Ddn_Secret_Key_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Ddn_Secret_Key_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Secret_Key_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.secret_key" */
export type Ddn_Secret_Key_Aggregate_Fields = {
  __typename?: 'ddn_secret_key_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Secret_Key_Max_Fields>;
  min?: Maybe<Ddn_Secret_Key_Min_Fields>;
};

/** aggregate fields of "ddn.secret_key" */
export type Ddn_Secret_Key_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Secret_Key_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.secret_key" */
export type Ddn_Secret_Key_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Secret_Key_Max_Order_By>;
  min?: InputMaybe<Ddn_Secret_Key_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.secret_key" */
export type Ddn_Secret_Key_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Secret_Key_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Secret_Key_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.secret_key". All fields are combined with a logical 'AND'. */
export type Ddn_Secret_Key_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Secret_Key_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Secret_Key_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  environment?: InputMaybe<Ddn_Environment_Bool_Exp>;
  environment_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_supergraph_secret?: InputMaybe<Boolean_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  subgraph?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
  subgraph_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.secret_key" */
export enum Ddn_Secret_Key_Constraint {
  /** unique or primary key constraint on columns "key", "subgraph_id", "environment_id" */
  SecretKeyEnvironmentIdNamespaceIdKeyKey = 'secret_key_environment_id_namespace_id_key_key',
  /** unique or primary key constraint on columns "id" */
  SecretKeyPkey = 'secret_key_pkey',
}

/** input type for inserting data into table "ddn.secret_key" */
export type Ddn_Secret_Key_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Ddn_Environment_Obj_Rel_Insert_Input>;
  environment_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_supergraph_secret?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  subgraph?: InputMaybe<Ddn_Subgraph_Obj_Rel_Insert_Input>;
  subgraph_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Secret_Key_Max_Fields = {
  __typename?: 'ddn_secret_key_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  environment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  subgraph_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ddn.secret_key" */
export type Ddn_Secret_Key_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  subgraph_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Secret_Key_Min_Fields = {
  __typename?: 'ddn_secret_key_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  environment_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  subgraph_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ddn.secret_key" */
export type Ddn_Secret_Key_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  environment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  subgraph_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.secret_key" */
export type Ddn_Secret_Key_Mutation_Response = {
  __typename?: 'ddn_secret_key_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Secret_Key>;
};

/** on_conflict condition type for table "ddn.secret_key" */
export type Ddn_Secret_Key_On_Conflict = {
  constraint: Ddn_Secret_Key_Constraint;
  update_columns?: Array<Ddn_Secret_Key_Update_Column>;
  where?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.secret_key". */
export type Ddn_Secret_Key_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  environment?: InputMaybe<Ddn_Environment_Order_By>;
  environment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_supergraph_secret?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  subgraph?: InputMaybe<Ddn_Subgraph_Order_By>;
  subgraph_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.secret_key */
export type Ddn_Secret_Key_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.secret_key" */
export enum Ddn_Secret_Key_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsSupergraphSecret = 'is_supergraph_secret',
  /** column name */
  Key = 'key',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SubgraphId = 'subgraph_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "ddn_secret_key_aggregate_bool_exp_bool_and_arguments_columns" columns of table "ddn.secret_key" */
export enum Ddn_Secret_Key_Select_Column_Ddn_Secret_Key_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsSupergraphSecret = 'is_supergraph_secret',
}

/** select "ddn_secret_key_aggregate_bool_exp_bool_or_arguments_columns" columns of table "ddn.secret_key" */
export enum Ddn_Secret_Key_Select_Column_Ddn_Secret_Key_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsSupergraphSecret = 'is_supergraph_secret',
}

/** input type for updating data in table "ddn.secret_key" */
export type Ddn_Secret_Key_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  environment_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_supergraph_secret?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  subgraph_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_secret_key" */
export type Ddn_Secret_Key_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Secret_Key_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Secret_Key_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  environment_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_supergraph_secret?: InputMaybe<Scalars['Boolean']>;
  key?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  subgraph_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.secret_key" */
export enum Ddn_Secret_Key_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsSupergraphSecret = 'is_supergraph_secret',
  /** column name */
  Key = 'key',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SubgraphId = 'subgraph_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Secret_Key_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Secret_Key_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Secret_Key_Bool_Exp;
};

/** columns and relationships of "ddn.stripe_subscription" */
export type Ddn_Stripe_Subscription = {
  __typename?: 'ddn_stripe_subscription';
  collection_method: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  customer?: Maybe<Users>;
  customer_id: Scalars['String'];
  deleted_at: Scalars['timestamptz'];
  /** An object relationship */
  payment_method: Payment_Method;
  payment_method_id: Scalars['String'];
  remarks: Scalars['String'];
  status: Scalars['String'];
  subscription_id: Scalars['String'];
};

/** aggregated selection of "ddn.stripe_subscription" */
export type Ddn_Stripe_Subscription_Aggregate = {
  __typename?: 'ddn_stripe_subscription_aggregate';
  aggregate?: Maybe<Ddn_Stripe_Subscription_Aggregate_Fields>;
  nodes: Array<Ddn_Stripe_Subscription>;
};

/** aggregate fields of "ddn.stripe_subscription" */
export type Ddn_Stripe_Subscription_Aggregate_Fields = {
  __typename?: 'ddn_stripe_subscription_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Stripe_Subscription_Max_Fields>;
  min?: Maybe<Ddn_Stripe_Subscription_Min_Fields>;
};

/** aggregate fields of "ddn.stripe_subscription" */
export type Ddn_Stripe_Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Stripe_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.stripe_subscription". All fields are combined with a logical 'AND'. */
export type Ddn_Stripe_Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Stripe_Subscription_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Stripe_Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Stripe_Subscription_Bool_Exp>>;
  collection_method?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Users_Bool_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  payment_method?: InputMaybe<Payment_Method_Bool_Exp>;
  payment_method_id?: InputMaybe<String_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.stripe_subscription" */
export enum Ddn_Stripe_Subscription_Constraint {
  /** unique or primary key constraint on columns "customer_id", "subscription_id" */
  StripeSubscriptionPkey = 'stripe_subscription_pkey',
}

/** input type for inserting data into table "ddn.stripe_subscription" */
export type Ddn_Stripe_Subscription_Insert_Input = {
  collection_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  customer_id?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  payment_method?: InputMaybe<Payment_Method_Obj_Rel_Insert_Input>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ddn_Stripe_Subscription_Max_Fields = {
  __typename?: 'ddn_stripe_subscription_max_fields';
  collection_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  payment_method_id?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ddn_Stripe_Subscription_Min_Fields = {
  __typename?: 'ddn_stripe_subscription_min_fields';
  collection_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  payment_method_id?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ddn.stripe_subscription" */
export type Ddn_Stripe_Subscription_Mutation_Response = {
  __typename?: 'ddn_stripe_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Stripe_Subscription>;
};

/** on_conflict condition type for table "ddn.stripe_subscription" */
export type Ddn_Stripe_Subscription_On_Conflict = {
  constraint: Ddn_Stripe_Subscription_Constraint;
  update_columns?: Array<Ddn_Stripe_Subscription_Update_Column>;
  where?: InputMaybe<Ddn_Stripe_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.stripe_subscription". */
export type Ddn_Stripe_Subscription_Order_By = {
  collection_method?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer?: InputMaybe<Users_Order_By>;
  customer_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Payment_Method_Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.stripe_subscription */
export type Ddn_Stripe_Subscription_Pk_Columns_Input = {
  customer_id: Scalars['String'];
  subscription_id: Scalars['String'];
};

/** select columns of table "ddn.stripe_subscription" */
export enum Ddn_Stripe_Subscription_Select_Column {
  /** column name */
  CollectionMethod = 'collection_method',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
}

/** input type for updating data in table "ddn.stripe_subscription" */
export type Ddn_Stripe_Subscription_Set_Input = {
  collection_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ddn_stripe_subscription" */
export type Ddn_Stripe_Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Stripe_Subscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Stripe_Subscription_Stream_Cursor_Value_Input = {
  collection_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ddn.stripe_subscription" */
export enum Ddn_Stripe_Subscription_Update_Column {
  /** column name */
  CollectionMethod = 'collection_method',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
}

export type Ddn_Stripe_Subscription_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Stripe_Subscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Stripe_Subscription_Bool_Exp;
};

/** columns and relationships of "ddn.stripe_webhook_events" */
export type Ddn_Stripe_Webhook_Events = {
  __typename?: 'ddn_stripe_webhook_events';
  created_at: Scalars['timestamptz'];
  error?: Maybe<Scalars['String']>;
  event_type: Scalars['String'];
  invoice_id: Scalars['String'];
  is_handled: Scalars['Boolean'];
  request_body_invoice_object: Scalars['jsonb'];
  stripe_event_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "ddn.stripe_webhook_events" */
export type Ddn_Stripe_Webhook_EventsRequest_Body_Invoice_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "ddn.stripe_webhook_events" */
export type Ddn_Stripe_Webhook_Events_Aggregate = {
  __typename?: 'ddn_stripe_webhook_events_aggregate';
  aggregate?: Maybe<Ddn_Stripe_Webhook_Events_Aggregate_Fields>;
  nodes: Array<Ddn_Stripe_Webhook_Events>;
};

/** aggregate fields of "ddn.stripe_webhook_events" */
export type Ddn_Stripe_Webhook_Events_Aggregate_Fields = {
  __typename?: 'ddn_stripe_webhook_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Stripe_Webhook_Events_Max_Fields>;
  min?: Maybe<Ddn_Stripe_Webhook_Events_Min_Fields>;
};

/** aggregate fields of "ddn.stripe_webhook_events" */
export type Ddn_Stripe_Webhook_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Stripe_Webhook_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ddn_Stripe_Webhook_Events_Append_Input = {
  request_body_invoice_object?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "ddn.stripe_webhook_events". All fields are combined with a logical 'AND'. */
export type Ddn_Stripe_Webhook_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Stripe_Webhook_Events_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Stripe_Webhook_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Stripe_Webhook_Events_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  event_type?: InputMaybe<String_Comparison_Exp>;
  invoice_id?: InputMaybe<String_Comparison_Exp>;
  is_handled?: InputMaybe<Boolean_Comparison_Exp>;
  request_body_invoice_object?: InputMaybe<Jsonb_Comparison_Exp>;
  stripe_event_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.stripe_webhook_events" */
export enum Ddn_Stripe_Webhook_Events_Constraint {
  /** unique or primary key constraint on columns "stripe_event_id" */
  StripeWebhookEventsPkey = 'stripe_webhook_events_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ddn_Stripe_Webhook_Events_Delete_At_Path_Input = {
  request_body_invoice_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ddn_Stripe_Webhook_Events_Delete_Elem_Input = {
  request_body_invoice_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ddn_Stripe_Webhook_Events_Delete_Key_Input = {
  request_body_invoice_object?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "ddn.stripe_webhook_events" */
export type Ddn_Stripe_Webhook_Events_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  is_handled?: InputMaybe<Scalars['Boolean']>;
  request_body_invoice_object?: InputMaybe<Scalars['jsonb']>;
  stripe_event_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Stripe_Webhook_Events_Max_Fields = {
  __typename?: 'ddn_stripe_webhook_events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['String']>;
  stripe_event_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Ddn_Stripe_Webhook_Events_Min_Fields = {
  __typename?: 'ddn_stripe_webhook_events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['String']>;
  stripe_event_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ddn.stripe_webhook_events" */
export type Ddn_Stripe_Webhook_Events_Mutation_Response = {
  __typename?: 'ddn_stripe_webhook_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Stripe_Webhook_Events>;
};

/** on_conflict condition type for table "ddn.stripe_webhook_events" */
export type Ddn_Stripe_Webhook_Events_On_Conflict = {
  constraint: Ddn_Stripe_Webhook_Events_Constraint;
  update_columns?: Array<Ddn_Stripe_Webhook_Events_Update_Column>;
  where?: InputMaybe<Ddn_Stripe_Webhook_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.stripe_webhook_events". */
export type Ddn_Stripe_Webhook_Events_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  is_handled?: InputMaybe<Order_By>;
  request_body_invoice_object?: InputMaybe<Order_By>;
  stripe_event_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.stripe_webhook_events */
export type Ddn_Stripe_Webhook_Events_Pk_Columns_Input = {
  stripe_event_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ddn_Stripe_Webhook_Events_Prepend_Input = {
  request_body_invoice_object?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "ddn.stripe_webhook_events" */
export enum Ddn_Stripe_Webhook_Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Error = 'error',
  /** column name */
  EventType = 'event_type',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  IsHandled = 'is_handled',
  /** column name */
  RequestBodyInvoiceObject = 'request_body_invoice_object',
  /** column name */
  StripeEventId = 'stripe_event_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.stripe_webhook_events" */
export type Ddn_Stripe_Webhook_Events_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  is_handled?: InputMaybe<Scalars['Boolean']>;
  request_body_invoice_object?: InputMaybe<Scalars['jsonb']>;
  stripe_event_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_stripe_webhook_events" */
export type Ddn_Stripe_Webhook_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Stripe_Webhook_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Stripe_Webhook_Events_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  is_handled?: InputMaybe<Scalars['Boolean']>;
  request_body_invoice_object?: InputMaybe<Scalars['jsonb']>;
  stripe_event_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.stripe_webhook_events" */
export enum Ddn_Stripe_Webhook_Events_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Error = 'error',
  /** column name */
  EventType = 'event_type',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  IsHandled = 'is_handled',
  /** column name */
  RequestBodyInvoiceObject = 'request_body_invoice_object',
  /** column name */
  StripeEventId = 'stripe_event_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Stripe_Webhook_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ddn_Stripe_Webhook_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ddn_Stripe_Webhook_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ddn_Stripe_Webhook_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ddn_Stripe_Webhook_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ddn_Stripe_Webhook_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Stripe_Webhook_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Stripe_Webhook_Events_Bool_Exp;
};

/** Table to hold the namespaces of the projects */
export type Ddn_Subgraph = {
  __typename?: 'ddn_subgraph';
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An object relationship */
  project: Ddn_Projects;
  project_id: Scalars['uuid'];
  /** An array relationship */
  secret_keys: Array<Ddn_Secret_Key>;
  /** An aggregate relationship */
  secret_keys_aggregate: Ddn_Secret_Key_Aggregate;
  updated_at: Scalars['timestamptz'];
};

/** Table to hold the namespaces of the projects */
export type Ddn_SubgraphSecret_KeysArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Secret_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Secret_Key_Order_By>>;
  where?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
};

/** Table to hold the namespaces of the projects */
export type Ddn_SubgraphSecret_Keys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Secret_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Secret_Key_Order_By>>;
  where?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
};

/** aggregated selection of "ddn.subgraph" */
export type Ddn_Subgraph_Aggregate = {
  __typename?: 'ddn_subgraph_aggregate';
  aggregate?: Maybe<Ddn_Subgraph_Aggregate_Fields>;
  nodes: Array<Ddn_Subgraph>;
};

export type Ddn_Subgraph_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Subgraph_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Subgraph_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Subgraph_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.subgraph" */
export type Ddn_Subgraph_Aggregate_Fields = {
  __typename?: 'ddn_subgraph_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Subgraph_Max_Fields>;
  min?: Maybe<Ddn_Subgraph_Min_Fields>;
};

/** aggregate fields of "ddn.subgraph" */
export type Ddn_Subgraph_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Subgraph_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.subgraph" */
export type Ddn_Subgraph_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Subgraph_Max_Order_By>;
  min?: InputMaybe<Ddn_Subgraph_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.subgraph" */
export type Ddn_Subgraph_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Subgraph_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Subgraph_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.subgraph". All fields are combined with a logical 'AND'. */
export type Ddn_Subgraph_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Subgraph_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Subgraph_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  secret_keys?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
  secret_keys_aggregate?: InputMaybe<Ddn_Secret_Key_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Basic build information for all project subgraphs */
export type Ddn_Subgraph_Build = {
  __typename?: 'ddn_subgraph_build';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  project: Ddn_Projects;
  project_id: Scalars['uuid'];
  /** An object relationship */
  subgraph: Ddn_Subgraph;
  subgraph_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  version: Scalars['String'];
};

/** aggregated selection of "ddn.subgraph_build" */
export type Ddn_Subgraph_Build_Aggregate = {
  __typename?: 'ddn_subgraph_build_aggregate';
  aggregate?: Maybe<Ddn_Subgraph_Build_Aggregate_Fields>;
  nodes: Array<Ddn_Subgraph_Build>;
};

/** aggregate fields of "ddn.subgraph_build" */
export type Ddn_Subgraph_Build_Aggregate_Fields = {
  __typename?: 'ddn_subgraph_build_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Subgraph_Build_Max_Fields>;
  min?: Maybe<Ddn_Subgraph_Build_Min_Fields>;
};

/** aggregate fields of "ddn.subgraph_build" */
export type Ddn_Subgraph_Build_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Subgraph_Build_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.subgraph_build". All fields are combined with a logical 'AND'. */
export type Ddn_Subgraph_Build_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Subgraph_Build_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Subgraph_Build_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Subgraph_Build_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  subgraph?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
  subgraph_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  version?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.subgraph_build" */
export enum Ddn_Subgraph_Build_Constraint {
  /** unique or primary key constraint on columns "id" */
  SubgraphBuildPkey = 'subgraph_build_pkey',
  /** unique or primary key constraint on columns "project_id", "version" */
  SubgraphBuildVersionProjectIdKey = 'subgraph_build_version_project_id_key',
}

/** input type for inserting data into table "ddn.subgraph_build" */
export type Ddn_Subgraph_Build_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  subgraph?: InputMaybe<Ddn_Subgraph_Obj_Rel_Insert_Input>;
  subgraph_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ddn_Subgraph_Build_Max_Fields = {
  __typename?: 'ddn_subgraph_build_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  subgraph_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ddn_Subgraph_Build_Min_Fields = {
  __typename?: 'ddn_subgraph_build_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  subgraph_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  version?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ddn.subgraph_build" */
export type Ddn_Subgraph_Build_Mutation_Response = {
  __typename?: 'ddn_subgraph_build_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Subgraph_Build>;
};

/** on_conflict condition type for table "ddn.subgraph_build" */
export type Ddn_Subgraph_Build_On_Conflict = {
  constraint: Ddn_Subgraph_Build_Constraint;
  update_columns?: Array<Ddn_Subgraph_Build_Update_Column>;
  where?: InputMaybe<Ddn_Subgraph_Build_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.subgraph_build". */
export type Ddn_Subgraph_Build_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  subgraph?: InputMaybe<Ddn_Subgraph_Order_By>;
  subgraph_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  version?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.subgraph_build */
export type Ddn_Subgraph_Build_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.subgraph_build" */
export enum Ddn_Subgraph_Build_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SubgraphId = 'subgraph_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version',
}

/** input type for updating data in table "ddn.subgraph_build" */
export type Ddn_Subgraph_Build_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  subgraph_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ddn_subgraph_build" */
export type Ddn_Subgraph_Build_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Subgraph_Build_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Subgraph_Build_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  subgraph_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  version?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ddn.subgraph_build" */
export enum Ddn_Subgraph_Build_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SubgraphId = 'subgraph_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Version = 'version',
}

export type Ddn_Subgraph_Build_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Subgraph_Build_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Subgraph_Build_Bool_Exp;
};

/** unique or primary key constraints on table "ddn.subgraph" */
export enum Ddn_Subgraph_Constraint {
  /** unique or primary key constraint on columns "id" */
  NamespacePkey = 'namespace_pkey',
  /** unique or primary key constraint on columns "project_id", "name" */
  UniqueNamespaceNameWithinProject = 'unique_namespace_name_within_project',
}

/** input type for inserting data into table "ddn.subgraph" */
export type Ddn_Subgraph_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  secret_keys?: InputMaybe<Ddn_Secret_Key_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Subgraph_Max_Fields = {
  __typename?: 'ddn_subgraph_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ddn.subgraph" */
export type Ddn_Subgraph_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Subgraph_Min_Fields = {
  __typename?: 'ddn_subgraph_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ddn.subgraph" */
export type Ddn_Subgraph_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.subgraph" */
export type Ddn_Subgraph_Mutation_Response = {
  __typename?: 'ddn_subgraph_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Subgraph>;
};

/** input type for inserting object relation for remote table "ddn.subgraph" */
export type Ddn_Subgraph_Obj_Rel_Insert_Input = {
  data: Ddn_Subgraph_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Subgraph_On_Conflict>;
};

/** on_conflict condition type for table "ddn.subgraph" */
export type Ddn_Subgraph_On_Conflict = {
  constraint: Ddn_Subgraph_Constraint;
  update_columns?: Array<Ddn_Subgraph_Update_Column>;
  where?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.subgraph". */
export type Ddn_Subgraph_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  secret_keys_aggregate?: InputMaybe<Ddn_Secret_Key_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.subgraph */
export type Ddn_Subgraph_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.subgraph" */
export enum Ddn_Subgraph_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.subgraph" */
export type Ddn_Subgraph_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_subgraph" */
export type Ddn_Subgraph_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Subgraph_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Subgraph_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.subgraph" */
export enum Ddn_Subgraph_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Subgraph_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Subgraph_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Subgraph_Bool_Exp;
};

/** columns and relationships of "ddn.team" */
export type Ddn_Team = {
  __typename?: 'ddn_team';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  default_project?: Maybe<Ddn_Projects>;
  default_project_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  default_project_invitee_view?: Maybe<Ddn_Collaboration_Invitee_Project_View>;
  description: Scalars['String'];
  id: Scalars['uuid'];
  kind: Scalars['String'];
  /** An array relationship */
  memberships: Array<Ddn_Team_Membership>;
  /** An aggregate relationship */
  memberships_aggregate: Ddn_Team_Membership_Aggregate;
  name: Scalars['String'];
  owner_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  project_accesses: Array<Ddn_Team_Project_Access>;
  /** An aggregate relationship */
  project_accesses_aggregate: Ddn_Team_Project_Access_Aggregate;
  /** An array relationship */
  team_invitations: Array<Ddn_Team_Invitation>;
  /** An aggregate relationship */
  team_invitations_aggregate: Ddn_Team_Invitation_Aggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "ddn.team" */
export type Ddn_TeamMembershipsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Membership_Order_By>>;
  where?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
};

/** columns and relationships of "ddn.team" */
export type Ddn_TeamMemberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Membership_Order_By>>;
  where?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
};

/** columns and relationships of "ddn.team" */
export type Ddn_TeamProject_AccessesArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
};

/** columns and relationships of "ddn.team" */
export type Ddn_TeamProject_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
};

/** columns and relationships of "ddn.team" */
export type Ddn_TeamTeam_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Invitation_Order_By>>;
  where?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
};

/** columns and relationships of "ddn.team" */
export type Ddn_TeamTeam_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Invitation_Order_By>>;
  where?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
};

/** aggregated selection of "ddn.team" */
export type Ddn_Team_Aggregate = {
  __typename?: 'ddn_team_aggregate';
  aggregate?: Maybe<Ddn_Team_Aggregate_Fields>;
  nodes: Array<Ddn_Team>;
};

export type Ddn_Team_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Team_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Team_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Team_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Team_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.team" */
export type Ddn_Team_Aggregate_Fields = {
  __typename?: 'ddn_team_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Team_Max_Fields>;
  min?: Maybe<Ddn_Team_Min_Fields>;
};

/** aggregate fields of "ddn.team" */
export type Ddn_Team_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Team_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.team" */
export type Ddn_Team_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Team_Max_Order_By>;
  min?: InputMaybe<Ddn_Team_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.team" */
export type Ddn_Team_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Team_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Team_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.team". All fields are combined with a logical 'AND'. */
export type Ddn_Team_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Team_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Team_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Team_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  default_project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  default_project_id?: InputMaybe<Uuid_Comparison_Exp>;
  default_project_invitee_view?: InputMaybe<
    Ddn_Collaboration_Invitee_Project_View_Bool_Exp
  >;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
  memberships?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
  memberships_aggregate?: InputMaybe<Ddn_Team_Membership_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_accesses?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
  project_accesses_aggregate?: InputMaybe<
    Ddn_Team_Project_Access_Aggregate_Bool_Exp
  >;
  team_invitations?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
  team_invitations_aggregate?: InputMaybe<
    Ddn_Team_Invitation_Aggregate_Bool_Exp
  >;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.team" */
export enum Ddn_Team_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamPkey = 'team_pkey',
}

/** input type for inserting data into table "ddn.team" */
export type Ddn_Team_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default_project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  default_project_id?: InputMaybe<Scalars['uuid']>;
  default_project_invitee_view?: InputMaybe<
    Ddn_Collaboration_Invitee_Project_View_Obj_Rel_Insert_Input
  >;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Scalars['String']>;
  memberships?: InputMaybe<Ddn_Team_Membership_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  project_accesses?: InputMaybe<Ddn_Team_Project_Access_Arr_Rel_Insert_Input>;
  team_invitations?: InputMaybe<Ddn_Team_Invitation_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Stores the invitations sent to users to join a team */
export type Ddn_Team_Invitation = {
  __typename?: 'ddn_team_invitation';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  invalidation_reason?: Maybe<Scalars['String']>;
  invited_by: Scalars['uuid'];
  /** An object relationship */
  invitee?: Maybe<Users_Public>;
  invitee_email: Scalars['citext'];
  /** An object relationship */
  inviter?: Maybe<Users_Public>;
  resend_count: Scalars['Int'];
  status: Scalars['String'];
  /** An object relationship */
  target_team: Ddn_Team;
  target_team_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ddn.team_invitation" */
export type Ddn_Team_Invitation_Aggregate = {
  __typename?: 'ddn_team_invitation_aggregate';
  aggregate?: Maybe<Ddn_Team_Invitation_Aggregate_Fields>;
  nodes: Array<Ddn_Team_Invitation>;
};

export type Ddn_Team_Invitation_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Team_Invitation_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Team_Invitation_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Team_Invitation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.team_invitation" */
export type Ddn_Team_Invitation_Aggregate_Fields = {
  __typename?: 'ddn_team_invitation_aggregate_fields';
  avg?: Maybe<Ddn_Team_Invitation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ddn_Team_Invitation_Max_Fields>;
  min?: Maybe<Ddn_Team_Invitation_Min_Fields>;
  stddev?: Maybe<Ddn_Team_Invitation_Stddev_Fields>;
  stddev_pop?: Maybe<Ddn_Team_Invitation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ddn_Team_Invitation_Stddev_Samp_Fields>;
  sum?: Maybe<Ddn_Team_Invitation_Sum_Fields>;
  var_pop?: Maybe<Ddn_Team_Invitation_Var_Pop_Fields>;
  var_samp?: Maybe<Ddn_Team_Invitation_Var_Samp_Fields>;
  variance?: Maybe<Ddn_Team_Invitation_Variance_Fields>;
};

/** aggregate fields of "ddn.team_invitation" */
export type Ddn_Team_Invitation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Team_Invitation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Aggregate_Order_By = {
  avg?: InputMaybe<Ddn_Team_Invitation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Team_Invitation_Max_Order_By>;
  min?: InputMaybe<Ddn_Team_Invitation_Min_Order_By>;
  stddev?: InputMaybe<Ddn_Team_Invitation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ddn_Team_Invitation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ddn_Team_Invitation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ddn_Team_Invitation_Sum_Order_By>;
  var_pop?: InputMaybe<Ddn_Team_Invitation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ddn_Team_Invitation_Var_Samp_Order_By>;
  variance?: InputMaybe<Ddn_Team_Invitation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Team_Invitation_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Team_Invitation_On_Conflict>;
};

/** aggregate avg on columns */
export type Ddn_Team_Invitation_Avg_Fields = {
  __typename?: 'ddn_team_invitation_avg_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Avg_Order_By = {
  resend_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ddn.team_invitation". All fields are combined with a logical 'AND'. */
export type Ddn_Team_Invitation_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Team_Invitation_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Team_Invitation_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invalidation_reason?: InputMaybe<String_Comparison_Exp>;
  invited_by?: InputMaybe<Uuid_Comparison_Exp>;
  invitee?: InputMaybe<Users_Public_Bool_Exp>;
  invitee_email?: InputMaybe<Citext_Comparison_Exp>;
  inviter?: InputMaybe<Users_Public_Bool_Exp>;
  resend_count?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  target_team?: InputMaybe<Ddn_Team_Bool_Exp>;
  target_team_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.team_invitation" */
export enum Ddn_Team_Invitation_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamInvitationPkey = 'team_invitation_pkey',
}

/** input type for incrementing numeric columns in table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Inc_Input = {
  resend_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invalidation_reason?: InputMaybe<Scalars['String']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  invitee?: InputMaybe<Users_Public_Obj_Rel_Insert_Input>;
  invitee_email?: InputMaybe<Scalars['citext']>;
  inviter?: InputMaybe<Users_Public_Obj_Rel_Insert_Input>;
  resend_count?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  target_team?: InputMaybe<Ddn_Team_Obj_Rel_Insert_Input>;
  target_team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Team_Invitation_Max_Fields = {
  __typename?: 'ddn_team_invitation_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invalidation_reason?: Maybe<Scalars['String']>;
  invited_by?: Maybe<Scalars['uuid']>;
  invitee_email?: Maybe<Scalars['citext']>;
  resend_count?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  target_team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invalidation_reason?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  invitee_email?: InputMaybe<Order_By>;
  resend_count?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  target_team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Team_Invitation_Min_Fields = {
  __typename?: 'ddn_team_invitation_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invalidation_reason?: Maybe<Scalars['String']>;
  invited_by?: Maybe<Scalars['uuid']>;
  invitee_email?: Maybe<Scalars['citext']>;
  resend_count?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  target_team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invalidation_reason?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  invitee_email?: InputMaybe<Order_By>;
  resend_count?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  target_team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Mutation_Response = {
  __typename?: 'ddn_team_invitation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Team_Invitation>;
};

/** on_conflict condition type for table "ddn.team_invitation" */
export type Ddn_Team_Invitation_On_Conflict = {
  constraint: Ddn_Team_Invitation_Constraint;
  update_columns?: Array<Ddn_Team_Invitation_Update_Column>;
  where?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.team_invitation". */
export type Ddn_Team_Invitation_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invalidation_reason?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  invitee?: InputMaybe<Users_Public_Order_By>;
  invitee_email?: InputMaybe<Order_By>;
  inviter?: InputMaybe<Users_Public_Order_By>;
  resend_count?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  target_team?: InputMaybe<Ddn_Team_Order_By>;
  target_team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.team_invitation */
export type Ddn_Team_Invitation_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.team_invitation" */
export enum Ddn_Team_Invitation_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvalidationReason = 'invalidation_reason',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  InviteeEmail = 'invitee_email',
  /** column name */
  ResendCount = 'resend_count',
  /** column name */
  Status = 'status',
  /** column name */
  TargetTeamId = 'target_team_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invalidation_reason?: InputMaybe<Scalars['String']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  invitee_email?: InputMaybe<Scalars['citext']>;
  resend_count?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  target_team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Enum table for different statuses of team invitation */
export type Ddn_Team_Invitation_Status = {
  __typename?: 'ddn_team_invitation_status';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ddn.team_invitation_status" */
export type Ddn_Team_Invitation_Status_Aggregate = {
  __typename?: 'ddn_team_invitation_status_aggregate';
  aggregate?: Maybe<Ddn_Team_Invitation_Status_Aggregate_Fields>;
  nodes: Array<Ddn_Team_Invitation_Status>;
};

/** aggregate fields of "ddn.team_invitation_status" */
export type Ddn_Team_Invitation_Status_Aggregate_Fields = {
  __typename?: 'ddn_team_invitation_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Team_Invitation_Status_Max_Fields>;
  min?: Maybe<Ddn_Team_Invitation_Status_Min_Fields>;
};

/** aggregate fields of "ddn.team_invitation_status" */
export type Ddn_Team_Invitation_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Team_Invitation_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.team_invitation_status". All fields are combined with a logical 'AND'. */
export type Ddn_Team_Invitation_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Team_Invitation_Status_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Team_Invitation_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Team_Invitation_Status_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.team_invitation_status" */
export enum Ddn_Team_Invitation_Status_Constraint {
  /** unique or primary key constraint on columns "name" */
  TeamInvitationStatusPkey = 'team_invitation_status_pkey',
}

/** input type for inserting data into table "ddn.team_invitation_status" */
export type Ddn_Team_Invitation_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ddn_Team_Invitation_Status_Max_Fields = {
  __typename?: 'ddn_team_invitation_status_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ddn_Team_Invitation_Status_Min_Fields = {
  __typename?: 'ddn_team_invitation_status_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ddn.team_invitation_status" */
export type Ddn_Team_Invitation_Status_Mutation_Response = {
  __typename?: 'ddn_team_invitation_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Team_Invitation_Status>;
};

/** on_conflict condition type for table "ddn.team_invitation_status" */
export type Ddn_Team_Invitation_Status_On_Conflict = {
  constraint: Ddn_Team_Invitation_Status_Constraint;
  update_columns?: Array<Ddn_Team_Invitation_Status_Update_Column>;
  where?: InputMaybe<Ddn_Team_Invitation_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.team_invitation_status". */
export type Ddn_Team_Invitation_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.team_invitation_status */
export type Ddn_Team_Invitation_Status_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "ddn.team_invitation_status" */
export enum Ddn_Team_Invitation_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "ddn.team_invitation_status" */
export type Ddn_Team_Invitation_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ddn_team_invitation_status" */
export type Ddn_Team_Invitation_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Team_Invitation_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Team_Invitation_Status_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ddn.team_invitation_status" */
export enum Ddn_Team_Invitation_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type Ddn_Team_Invitation_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Team_Invitation_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Team_Invitation_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Ddn_Team_Invitation_Stddev_Fields = {
  __typename?: 'ddn_team_invitation_stddev_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Stddev_Order_By = {
  resend_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ddn_Team_Invitation_Stddev_Pop_Fields = {
  __typename?: 'ddn_team_invitation_stddev_pop_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Stddev_Pop_Order_By = {
  resend_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ddn_Team_Invitation_Stddev_Samp_Fields = {
  __typename?: 'ddn_team_invitation_stddev_samp_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Stddev_Samp_Order_By = {
  resend_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ddn_team_invitation" */
export type Ddn_Team_Invitation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Team_Invitation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Team_Invitation_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invalidation_reason?: InputMaybe<Scalars['String']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  invitee_email?: InputMaybe<Scalars['citext']>;
  resend_count?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  target_team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Ddn_Team_Invitation_Sum_Fields = {
  __typename?: 'ddn_team_invitation_sum_fields';
  resend_count?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Sum_Order_By = {
  resend_count?: InputMaybe<Order_By>;
};

/** update columns of table "ddn.team_invitation" */
export enum Ddn_Team_Invitation_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvalidationReason = 'invalidation_reason',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  InviteeEmail = 'invitee_email',
  /** column name */
  ResendCount = 'resend_count',
  /** column name */
  Status = 'status',
  /** column name */
  TargetTeamId = 'target_team_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Team_Invitation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ddn_Team_Invitation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Team_Invitation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Team_Invitation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ddn_Team_Invitation_Var_Pop_Fields = {
  __typename?: 'ddn_team_invitation_var_pop_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Var_Pop_Order_By = {
  resend_count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ddn_Team_Invitation_Var_Samp_Fields = {
  __typename?: 'ddn_team_invitation_var_samp_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Var_Samp_Order_By = {
  resend_count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ddn_Team_Invitation_Variance_Fields = {
  __typename?: 'ddn_team_invitation_variance_fields';
  resend_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ddn.team_invitation" */
export type Ddn_Team_Invitation_Variance_Order_By = {
  resend_count?: InputMaybe<Order_By>;
};

/** columns and relationships of "ddn.team_kind" */
export type Ddn_Team_Kind = {
  __typename?: 'ddn_team_kind';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "ddn.team_kind" */
export type Ddn_Team_Kind_Aggregate = {
  __typename?: 'ddn_team_kind_aggregate';
  aggregate?: Maybe<Ddn_Team_Kind_Aggregate_Fields>;
  nodes: Array<Ddn_Team_Kind>;
};

/** aggregate fields of "ddn.team_kind" */
export type Ddn_Team_Kind_Aggregate_Fields = {
  __typename?: 'ddn_team_kind_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Team_Kind_Max_Fields>;
  min?: Maybe<Ddn_Team_Kind_Min_Fields>;
};

/** aggregate fields of "ddn.team_kind" */
export type Ddn_Team_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Team_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.team_kind". All fields are combined with a logical 'AND'. */
export type Ddn_Team_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Team_Kind_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Team_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Team_Kind_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.team_kind" */
export enum Ddn_Team_Kind_Constraint {
  /** unique or primary key constraint on columns "name" */
  TeamKindPkey = 'team_kind_pkey',
}

/** input type for inserting data into table "ddn.team_kind" */
export type Ddn_Team_Kind_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ddn_Team_Kind_Max_Fields = {
  __typename?: 'ddn_team_kind_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ddn_Team_Kind_Min_Fields = {
  __typename?: 'ddn_team_kind_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ddn.team_kind" */
export type Ddn_Team_Kind_Mutation_Response = {
  __typename?: 'ddn_team_kind_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Team_Kind>;
};

/** on_conflict condition type for table "ddn.team_kind" */
export type Ddn_Team_Kind_On_Conflict = {
  constraint: Ddn_Team_Kind_Constraint;
  update_columns?: Array<Ddn_Team_Kind_Update_Column>;
  where?: InputMaybe<Ddn_Team_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.team_kind". */
export type Ddn_Team_Kind_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.team_kind */
export type Ddn_Team_Kind_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "ddn.team_kind" */
export enum Ddn_Team_Kind_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "ddn.team_kind" */
export type Ddn_Team_Kind_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ddn_team_kind" */
export type Ddn_Team_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Team_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Team_Kind_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ddn.team_kind" */
export enum Ddn_Team_Kind_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type Ddn_Team_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Team_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Team_Kind_Bool_Exp;
};

/** aggregate max on columns */
export type Ddn_Team_Max_Fields = {
  __typename?: 'ddn_team_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_project_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ddn.team" */
export type Ddn_Team_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  default_project_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Many to many mappings between users and teams */
export type Ddn_Team_Membership = {
  __typename?: 'ddn_team_membership';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  member?: Maybe<Users_Public>;
  status: Scalars['String'];
  /** An object relationship */
  team: Ddn_Team;
  team_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "ddn.team_membership" */
export type Ddn_Team_Membership_Aggregate = {
  __typename?: 'ddn_team_membership_aggregate';
  aggregate?: Maybe<Ddn_Team_Membership_Aggregate_Fields>;
  nodes: Array<Ddn_Team_Membership>;
};

export type Ddn_Team_Membership_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Team_Membership_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Team_Membership_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Team_Membership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.team_membership" */
export type Ddn_Team_Membership_Aggregate_Fields = {
  __typename?: 'ddn_team_membership_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Team_Membership_Max_Fields>;
  min?: Maybe<Ddn_Team_Membership_Min_Fields>;
};

/** aggregate fields of "ddn.team_membership" */
export type Ddn_Team_Membership_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Team_Membership_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.team_membership" */
export type Ddn_Team_Membership_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Team_Membership_Max_Order_By>;
  min?: InputMaybe<Ddn_Team_Membership_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.team_membership" */
export type Ddn_Team_Membership_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Team_Membership_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Team_Membership_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.team_membership". All fields are combined with a logical 'AND'. */
export type Ddn_Team_Membership_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Team_Membership_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Team_Membership_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  member?: InputMaybe<Users_Public_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Ddn_Team_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.team_membership" */
export enum Ddn_Team_Membership_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamMembershipPkey = 'team_membership_pkey',
}

/** input type for inserting data into table "ddn.team_membership" */
export type Ddn_Team_Membership_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  member?: InputMaybe<Users_Public_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  team?: InputMaybe<Ddn_Team_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ddn_Team_Membership_Max_Fields = {
  __typename?: 'ddn_team_membership_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ddn.team_membership" */
export type Ddn_Team_Membership_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Team_Membership_Min_Fields = {
  __typename?: 'ddn_team_membership_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ddn.team_membership" */
export type Ddn_Team_Membership_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.team_membership" */
export type Ddn_Team_Membership_Mutation_Response = {
  __typename?: 'ddn_team_membership_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Team_Membership>;
};

/** on_conflict condition type for table "ddn.team_membership" */
export type Ddn_Team_Membership_On_Conflict = {
  constraint: Ddn_Team_Membership_Constraint;
  update_columns?: Array<Ddn_Team_Membership_Update_Column>;
  where?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.team_membership". */
export type Ddn_Team_Membership_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  member?: InputMaybe<Users_Public_Order_By>;
  status?: InputMaybe<Order_By>;
  team?: InputMaybe<Ddn_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.team_membership */
export type Ddn_Team_Membership_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.team_membership" */
export enum Ddn_Team_Membership_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "ddn.team_membership" */
export type Ddn_Team_Membership_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "ddn_team_membership" */
export type Ddn_Team_Membership_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Team_Membership_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Team_Membership_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "ddn.team_membership" */
export enum Ddn_Team_Membership_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Ddn_Team_Membership_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Team_Membership_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Team_Membership_Bool_Exp;
};

/** aggregate min on columns */
export type Ddn_Team_Min_Fields = {
  __typename?: 'ddn_team_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_project_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  kind?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ddn.team" */
export type Ddn_Team_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  default_project_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.team" */
export type Ddn_Team_Mutation_Response = {
  __typename?: 'ddn_team_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Team>;
};

/** input type for inserting object relation for remote table "ddn.team" */
export type Ddn_Team_Obj_Rel_Insert_Input = {
  data: Ddn_Team_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Team_On_Conflict>;
};

/** on_conflict condition type for table "ddn.team" */
export type Ddn_Team_On_Conflict = {
  constraint: Ddn_Team_Constraint;
  update_columns?: Array<Ddn_Team_Update_Column>;
  where?: InputMaybe<Ddn_Team_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.team". */
export type Ddn_Team_Order_By = {
  created_at?: InputMaybe<Order_By>;
  default_project?: InputMaybe<Ddn_Projects_Order_By>;
  default_project_id?: InputMaybe<Order_By>;
  default_project_invitee_view?: InputMaybe<
    Ddn_Collaboration_Invitee_Project_View_Order_By
  >;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  memberships_aggregate?: InputMaybe<Ddn_Team_Membership_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  project_accesses_aggregate?: InputMaybe<
    Ddn_Team_Project_Access_Aggregate_Order_By
  >;
  team_invitations_aggregate?: InputMaybe<
    Ddn_Team_Invitation_Aggregate_Order_By
  >;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.team */
export type Ddn_Team_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** Access level of a team on a project */
export type Ddn_Team_Project_Access = {
  __typename?: 'ddn_team_project_access';
  access_level: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Ddn_Projects;
  project_id: Scalars['uuid'];
  status: Scalars['String'];
  /** An object relationship */
  team: Ddn_Team;
  team_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ddn.team_project_access" */
export type Ddn_Team_Project_Access_Aggregate = {
  __typename?: 'ddn_team_project_access_aggregate';
  aggregate?: Maybe<Ddn_Team_Project_Access_Aggregate_Fields>;
  nodes: Array<Ddn_Team_Project_Access>;
};

export type Ddn_Team_Project_Access_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Team_Project_Access_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Team_Project_Access_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Team_Project_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.team_project_access" */
export type Ddn_Team_Project_Access_Aggregate_Fields = {
  __typename?: 'ddn_team_project_access_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Team_Project_Access_Max_Fields>;
  min?: Maybe<Ddn_Team_Project_Access_Min_Fields>;
};

/** aggregate fields of "ddn.team_project_access" */
export type Ddn_Team_Project_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Team_Project_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.team_project_access" */
export type Ddn_Team_Project_Access_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Team_Project_Access_Max_Order_By>;
  min?: InputMaybe<Ddn_Team_Project_Access_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.team_project_access" */
export type Ddn_Team_Project_Access_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Team_Project_Access_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Team_Project_Access_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.team_project_access". All fields are combined with a logical 'AND'. */
export type Ddn_Team_Project_Access_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Team_Project_Access_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Team_Project_Access_Bool_Exp>>;
  access_level?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  team?: InputMaybe<Ddn_Team_Bool_Exp>;
  team_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.team_project_access" */
export enum Ddn_Team_Project_Access_Constraint {
  /** unique or primary key constraint on columns "id" */
  TeamProjectAccessPkey = 'team_project_access_pkey',
}

/** input type for inserting data into table "ddn.team_project_access" */
export type Ddn_Team_Project_Access_Insert_Input = {
  access_level?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team?: InputMaybe<Ddn_Team_Obj_Rel_Insert_Input>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Team_Project_Access_Max_Fields = {
  __typename?: 'ddn_team_project_access_max_fields';
  access_level?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ddn.team_project_access" */
export type Ddn_Team_Project_Access_Max_Order_By = {
  access_level?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Team_Project_Access_Min_Fields = {
  __typename?: 'ddn_team_project_access_min_fields';
  access_level?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ddn.team_project_access" */
export type Ddn_Team_Project_Access_Min_Order_By = {
  access_level?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.team_project_access" */
export type Ddn_Team_Project_Access_Mutation_Response = {
  __typename?: 'ddn_team_project_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Team_Project_Access>;
};

/** on_conflict condition type for table "ddn.team_project_access" */
export type Ddn_Team_Project_Access_On_Conflict = {
  constraint: Ddn_Team_Project_Access_Constraint;
  update_columns?: Array<Ddn_Team_Project_Access_Update_Column>;
  where?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.team_project_access". */
export type Ddn_Team_Project_Access_Order_By = {
  access_level?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team?: InputMaybe<Ddn_Team_Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.team_project_access */
export type Ddn_Team_Project_Access_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.team_project_access" */
export enum Ddn_Team_Project_Access_Select_Column {
  /** column name */
  AccessLevel = 'access_level',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.team_project_access" */
export type Ddn_Team_Project_Access_Set_Input = {
  access_level?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_team_project_access" */
export type Ddn_Team_Project_Access_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Team_Project_Access_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Team_Project_Access_Stream_Cursor_Value_Input = {
  access_level?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.team_project_access" */
export enum Ddn_Team_Project_Access_Update_Column {
  /** column name */
  AccessLevel = 'access_level',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Team_Project_Access_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Team_Project_Access_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Team_Project_Access_Bool_Exp;
};

/** select columns of table "ddn.team" */
export enum Ddn_Team_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultProjectId = 'default_project_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.team" */
export type Ddn_Team_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default_project_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_team" */
export type Ddn_Team_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Team_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Team_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  default_project_id?: InputMaybe<Scalars['uuid']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.team" */
export enum Ddn_Team_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DefaultProjectId = 'default_project_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Team_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Team_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Team_Bool_Exp;
};

/** columns and relationships of "ddn.token_access_level_output" */
export type Ddn_Token_Access_Level_Output = {
  __typename?: 'ddn_token_access_level_output';
  access_level?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type Ddn_Token_Access_Level_Output_Aggregate = {
  __typename?: 'ddn_token_access_level_output_aggregate';
  aggregate?: Maybe<Ddn_Token_Access_Level_Output_Aggregate_Fields>;
  nodes: Array<Ddn_Token_Access_Level_Output>;
};

/** aggregate fields of "ddn.token_access_level_output" */
export type Ddn_Token_Access_Level_Output_Aggregate_Fields = {
  __typename?: 'ddn_token_access_level_output_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Token_Access_Level_Output_Max_Fields>;
  min?: Maybe<Ddn_Token_Access_Level_Output_Min_Fields>;
};

/** aggregate fields of "ddn.token_access_level_output" */
export type Ddn_Token_Access_Level_Output_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.token_access_level_output". All fields are combined with a logical 'AND'. */
export type Ddn_Token_Access_Level_Output_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Token_Access_Level_Output_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Bool_Exp>>;
  access_level?: InputMaybe<String_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Ddn_Token_Access_Level_Output_Max_Fields = {
  __typename?: 'ddn_token_access_level_output_max_fields';
  access_level?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Ddn_Token_Access_Level_Output_Min_Fields = {
  __typename?: 'ddn_token_access_level_output_min_fields';
  access_level?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "ddn.token_access_level_output". */
export type Ddn_Token_Access_Level_Output_Order_By = {
  access_level?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "ddn.token_access_level_output" */
export enum Ddn_Token_Access_Level_Output_Select_Column {
  /** column name */
  AccessLevel = 'access_level',
  /** column name */
  Error = 'error',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "ddn_token_access_level_output" */
export type Ddn_Token_Access_Level_Output_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Token_Access_Level_Output_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Token_Access_Level_Output_Stream_Cursor_Value_Input = {
  access_level?: InputMaybe<Scalars['String']>;
  error?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "ddn.tunnel" */
export type Ddn_Tunnel = {
  __typename?: 'ddn_tunnel';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  last_connection_at: Scalars['timestamptz'];
  owner_id: Scalars['uuid'];
  reserved_cluster: Scalars['uuid'];
  reserved_port?: Maybe<Scalars['Int']>;
  /** An object relationship */
  tunnel_cluster: Ddn_Tunnel_Cluster;
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "ddn.tunnel" */
export type Ddn_Tunnel_Aggregate = {
  __typename?: 'ddn_tunnel_aggregate';
  aggregate?: Maybe<Ddn_Tunnel_Aggregate_Fields>;
  nodes: Array<Ddn_Tunnel>;
};

export type Ddn_Tunnel_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Tunnel_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Tunnel_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Tunnel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Tunnel_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.tunnel" */
export type Ddn_Tunnel_Aggregate_Fields = {
  __typename?: 'ddn_tunnel_aggregate_fields';
  avg?: Maybe<Ddn_Tunnel_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Ddn_Tunnel_Max_Fields>;
  min?: Maybe<Ddn_Tunnel_Min_Fields>;
  stddev?: Maybe<Ddn_Tunnel_Stddev_Fields>;
  stddev_pop?: Maybe<Ddn_Tunnel_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Ddn_Tunnel_Stddev_Samp_Fields>;
  sum?: Maybe<Ddn_Tunnel_Sum_Fields>;
  var_pop?: Maybe<Ddn_Tunnel_Var_Pop_Fields>;
  var_samp?: Maybe<Ddn_Tunnel_Var_Samp_Fields>;
  variance?: Maybe<Ddn_Tunnel_Variance_Fields>;
};

/** aggregate fields of "ddn.tunnel" */
export type Ddn_Tunnel_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Tunnel_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.tunnel" */
export type Ddn_Tunnel_Aggregate_Order_By = {
  avg?: InputMaybe<Ddn_Tunnel_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Tunnel_Max_Order_By>;
  min?: InputMaybe<Ddn_Tunnel_Min_Order_By>;
  stddev?: InputMaybe<Ddn_Tunnel_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ddn_Tunnel_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ddn_Tunnel_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ddn_Tunnel_Sum_Order_By>;
  var_pop?: InputMaybe<Ddn_Tunnel_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ddn_Tunnel_Var_Samp_Order_By>;
  variance?: InputMaybe<Ddn_Tunnel_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.tunnel" */
export type Ddn_Tunnel_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Tunnel_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Tunnel_On_Conflict>;
};

/** aggregate avg on columns */
export type Ddn_Tunnel_Avg_Fields = {
  __typename?: 'ddn_tunnel_avg_fields';
  reserved_port?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "ddn.tunnel" */
export type Ddn_Tunnel_Avg_Order_By = {
  reserved_port?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ddn.tunnel". All fields are combined with a logical 'AND'. */
export type Ddn_Tunnel_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Tunnel_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Tunnel_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Tunnel_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_connection_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  reserved_cluster?: InputMaybe<Uuid_Comparison_Exp>;
  reserved_port?: InputMaybe<Int_Comparison_Exp>;
  tunnel_cluster?: InputMaybe<Ddn_Tunnel_Cluster_Bool_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** columns and relationships of "ddn.tunnel_cluster" */
export type Ddn_Tunnel_Cluster = {
  __typename?: 'ddn_tunnel_cluster';
  cloud: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  internal_fqdn: Scalars['String'];
  machine_type: Scalars['String'];
  name: Scalars['String'];
  public_fqdn: Scalars['String'];
  region: Scalars['String'];
  /** An object relationship */
  regionByRegionCloud?: Maybe<Region>;
  /** An array relationship */
  tunnels: Array<Ddn_Tunnel>;
  /** An aggregate relationship */
  tunnels_aggregate: Ddn_Tunnel_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zone: Scalars['String'];
};

/** columns and relationships of "ddn.tunnel_cluster" */
export type Ddn_Tunnel_ClusterTunnelsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Bool_Exp>;
};

/** columns and relationships of "ddn.tunnel_cluster" */
export type Ddn_Tunnel_ClusterTunnels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Bool_Exp>;
};

export type Ddn_Tunnel_Cluster_Aggregate = {
  __typename?: 'ddn_tunnel_cluster_aggregate';
  aggregate?: Maybe<Ddn_Tunnel_Cluster_Aggregate_Fields>;
  nodes: Array<Ddn_Tunnel_Cluster>;
};

/** aggregate fields of "ddn.tunnel_cluster" */
export type Ddn_Tunnel_Cluster_Aggregate_Fields = {
  __typename?: 'ddn_tunnel_cluster_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Tunnel_Cluster_Max_Fields>;
  min?: Maybe<Ddn_Tunnel_Cluster_Min_Fields>;
};

/** aggregate fields of "ddn.tunnel_cluster" */
export type Ddn_Tunnel_Cluster_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Tunnel_Cluster_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.tunnel_cluster". All fields are combined with a logical 'AND'. */
export type Ddn_Tunnel_Cluster_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Tunnel_Cluster_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Tunnel_Cluster_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Tunnel_Cluster_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_fqdn?: InputMaybe<String_Comparison_Exp>;
  machine_type?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  public_fqdn?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  regionByRegionCloud?: InputMaybe<Region_Bool_Exp>;
  tunnels?: InputMaybe<Ddn_Tunnel_Bool_Exp>;
  tunnels_aggregate?: InputMaybe<Ddn_Tunnel_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zone?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.tunnel_cluster" */
export enum Ddn_Tunnel_Cluster_Constraint {
  /** unique or primary key constraint on columns "internal_fqdn" */
  TunnelClusterInternalFqdnKey = 'tunnel_cluster_internal_fqdn_key',
  /** unique or primary key constraint on columns "name" */
  TunnelClusterNameKey = 'tunnel_cluster_name_key',
  /** unique or primary key constraint on columns "id" */
  TunnelClusterPkey = 'tunnel_cluster_pkey',
  /** unique or primary key constraint on columns "public_fqdn" */
  TunnelClusterPublicFqdnKey = 'tunnel_cluster_public_fqdn_key',
}

/** input type for inserting data into table "ddn.tunnel_cluster" */
export type Ddn_Tunnel_Cluster_Insert_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  internal_fqdn?: InputMaybe<Scalars['String']>;
  machine_type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  public_fqdn?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  regionByRegionCloud?: InputMaybe<Region_Obj_Rel_Insert_Input>;
  tunnels?: InputMaybe<Ddn_Tunnel_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zone?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Ddn_Tunnel_Cluster_Max_Fields = {
  __typename?: 'ddn_tunnel_cluster_max_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  internal_fqdn?: Maybe<Scalars['String']>;
  machine_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  public_fqdn?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zone?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Ddn_Tunnel_Cluster_Min_Fields = {
  __typename?: 'ddn_tunnel_cluster_min_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  internal_fqdn?: Maybe<Scalars['String']>;
  machine_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  public_fqdn?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zone?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "ddn.tunnel_cluster" */
export type Ddn_Tunnel_Cluster_Mutation_Response = {
  __typename?: 'ddn_tunnel_cluster_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Tunnel_Cluster>;
};

/** input type for inserting object relation for remote table "ddn.tunnel_cluster" */
export type Ddn_Tunnel_Cluster_Obj_Rel_Insert_Input = {
  data: Ddn_Tunnel_Cluster_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Tunnel_Cluster_On_Conflict>;
};

/** on_conflict condition type for table "ddn.tunnel_cluster" */
export type Ddn_Tunnel_Cluster_On_Conflict = {
  constraint: Ddn_Tunnel_Cluster_Constraint;
  update_columns?: Array<Ddn_Tunnel_Cluster_Update_Column>;
  where?: InputMaybe<Ddn_Tunnel_Cluster_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.tunnel_cluster". */
export type Ddn_Tunnel_Cluster_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_fqdn?: InputMaybe<Order_By>;
  machine_type?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  public_fqdn?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  regionByRegionCloud?: InputMaybe<Region_Order_By>;
  tunnels_aggregate?: InputMaybe<Ddn_Tunnel_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.tunnel_cluster */
export type Ddn_Tunnel_Cluster_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.tunnel_cluster" */
export enum Ddn_Tunnel_Cluster_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InternalFqdn = 'internal_fqdn',
  /** column name */
  MachineType = 'machine_type',
  /** column name */
  Name = 'name',
  /** column name */
  PublicFqdn = 'public_fqdn',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zone = 'zone',
}

/** input type for updating data in table "ddn.tunnel_cluster" */
export type Ddn_Tunnel_Cluster_Set_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  internal_fqdn?: InputMaybe<Scalars['String']>;
  machine_type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  public_fqdn?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zone?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "ddn_tunnel_cluster" */
export type Ddn_Tunnel_Cluster_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Tunnel_Cluster_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Tunnel_Cluster_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  internal_fqdn?: InputMaybe<Scalars['String']>;
  machine_type?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  public_fqdn?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zone?: InputMaybe<Scalars['String']>;
};

/** update columns of table "ddn.tunnel_cluster" */
export enum Ddn_Tunnel_Cluster_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InternalFqdn = 'internal_fqdn',
  /** column name */
  MachineType = 'machine_type',
  /** column name */
  Name = 'name',
  /** column name */
  PublicFqdn = 'public_fqdn',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zone = 'zone',
}

export type Ddn_Tunnel_Cluster_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Tunnel_Cluster_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Tunnel_Cluster_Bool_Exp;
};

/** unique or primary key constraints on table "ddn.tunnel" */
export enum Ddn_Tunnel_Constraint {
  /** unique or primary key constraint on columns "id" */
  TunnelPkey = 'tunnel_pkey',
  /** unique or primary key constraint on columns "reserved_port", "reserved_cluster" */
  TunnelReservedPortReservedClusterKey = 'tunnel_reserved_port_reserved_cluster_key',
}

/** List of secure HTTP tunnels */
export type Ddn_Tunnel_Http = {
  __typename?: 'ddn_tunnel_http';
  cluster_id: Scalars['uuid'];
  config?: Maybe<Scalars['json']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  owner: Users;
  owner_id: Scalars['uuid'];
  /** An object relationship */
  tunnel_cluster: Ddn_Tunnel_Http_Cluster;
  updated_at: Scalars['timestamptz'];
};

/** List of secure HTTP tunnels */
export type Ddn_Tunnel_HttpConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "ddn.tunnel_http" */
export type Ddn_Tunnel_Http_Aggregate = {
  __typename?: 'ddn_tunnel_http_aggregate';
  aggregate?: Maybe<Ddn_Tunnel_Http_Aggregate_Fields>;
  nodes: Array<Ddn_Tunnel_Http>;
};

export type Ddn_Tunnel_Http_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_Tunnel_Http_Aggregate_Bool_Exp_Count>;
};

export type Ddn_Tunnel_Http_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_Tunnel_Http_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_Tunnel_Http_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.tunnel_http" */
export type Ddn_Tunnel_Http_Aggregate_Fields = {
  __typename?: 'ddn_tunnel_http_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Tunnel_Http_Max_Fields>;
  min?: Maybe<Ddn_Tunnel_Http_Min_Fields>;
};

/** aggregate fields of "ddn.tunnel_http" */
export type Ddn_Tunnel_Http_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Tunnel_Http_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.tunnel_http" */
export type Ddn_Tunnel_Http_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_Tunnel_Http_Max_Order_By>;
  min?: InputMaybe<Ddn_Tunnel_Http_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.tunnel_http" */
export type Ddn_Tunnel_Http_Arr_Rel_Insert_Input = {
  data: Array<Ddn_Tunnel_Http_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Tunnel_Http_On_Conflict>;
};

/** Boolean expression to filter rows from the table "ddn.tunnel_http". All fields are combined with a logical 'AND'. */
export type Ddn_Tunnel_Http_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Tunnel_Http_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Tunnel_Http_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Tunnel_Http_Bool_Exp>>;
  cluster_id?: InputMaybe<Uuid_Comparison_Exp>;
  config?: InputMaybe<Json_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  tunnel_cluster?: InputMaybe<Ddn_Tunnel_Http_Cluster_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** List of secure HTTP tunnel clusters */
export type Ddn_Tunnel_Http_Cluster = {
  __typename?: 'ddn_tunnel_http_cluster';
  cloud: Scalars['String'];
  config?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  ddn_id: Scalars['uuid'];
  /** An object relationship */
  ddn_region?: Maybe<Ddn_Private_Ddn_Region>;
  id: Scalars['uuid'];
  internal_fqdn: Scalars['String'];
  name: Scalars['String'];
  /** An object relationship */
  private_ddn: Ddn_Private_Ddn;
  public_fqdn: Scalars['String'];
  region: Scalars['String'];
  /** An array relationship */
  tunnels: Array<Ddn_Tunnel_Http>;
  /** An aggregate relationship */
  tunnels_aggregate: Ddn_Tunnel_Http_Aggregate;
  updated_at: Scalars['timestamptz'];
};

/** List of secure HTTP tunnel clusters */
export type Ddn_Tunnel_Http_ClusterConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** List of secure HTTP tunnel clusters */
export type Ddn_Tunnel_Http_ClusterTunnelsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Http_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Http_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Http_Bool_Exp>;
};

/** List of secure HTTP tunnel clusters */
export type Ddn_Tunnel_Http_ClusterTunnels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Http_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Http_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Http_Bool_Exp>;
};

/** aggregated selection of "ddn.tunnel_http_cluster" */
export type Ddn_Tunnel_Http_Cluster_Aggregate = {
  __typename?: 'ddn_tunnel_http_cluster_aggregate';
  aggregate?: Maybe<Ddn_Tunnel_Http_Cluster_Aggregate_Fields>;
  nodes: Array<Ddn_Tunnel_Http_Cluster>;
};

/** aggregate fields of "ddn.tunnel_http_cluster" */
export type Ddn_Tunnel_Http_Cluster_Aggregate_Fields = {
  __typename?: 'ddn_tunnel_http_cluster_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_Tunnel_Http_Cluster_Max_Fields>;
  min?: Maybe<Ddn_Tunnel_Http_Cluster_Min_Fields>;
};

/** aggregate fields of "ddn.tunnel_http_cluster" */
export type Ddn_Tunnel_Http_Cluster_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_Tunnel_Http_Cluster_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Ddn_Tunnel_Http_Cluster_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "ddn.tunnel_http_cluster". All fields are combined with a logical 'AND'. */
export type Ddn_Tunnel_Http_Cluster_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_Tunnel_Http_Cluster_Bool_Exp>>;
  _not?: InputMaybe<Ddn_Tunnel_Http_Cluster_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_Tunnel_Http_Cluster_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ddn_id?: InputMaybe<Uuid_Comparison_Exp>;
  ddn_region?: InputMaybe<Ddn_Private_Ddn_Region_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_fqdn?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  private_ddn?: InputMaybe<Ddn_Private_Ddn_Bool_Exp>;
  public_fqdn?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  tunnels?: InputMaybe<Ddn_Tunnel_Http_Bool_Exp>;
  tunnels_aggregate?: InputMaybe<Ddn_Tunnel_Http_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ddn.tunnel_http_cluster" */
export enum Ddn_Tunnel_Http_Cluster_Constraint {
  /** unique or primary key constraint on columns "region", "cloud", "ddn_id" */
  TunnelHttpClusterDdnIdCloudRegionKey = 'tunnel_http_cluster_ddn_id_cloud_region_key',
  /** unique or primary key constraint on columns "public_fqdn" */
  TunnelHttpClusterFqdnKey = 'tunnel_http_cluster_fqdn_key',
  /** unique or primary key constraint on columns "internal_fqdn" */
  TunnelHttpClusterInternalFqdnKey = 'tunnel_http_cluster_internal_fqdn_key',
  /** unique or primary key constraint on columns "id" */
  TunnelHttpClusterPkey = 'tunnel_http_cluster_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Ddn_Tunnel_Http_Cluster_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Ddn_Tunnel_Http_Cluster_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Ddn_Tunnel_Http_Cluster_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "ddn.tunnel_http_cluster" */
export type Ddn_Tunnel_Http_Cluster_Insert_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ddn_id?: InputMaybe<Scalars['uuid']>;
  ddn_region?: InputMaybe<Ddn_Private_Ddn_Region_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  internal_fqdn?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  private_ddn?: InputMaybe<Ddn_Private_Ddn_Obj_Rel_Insert_Input>;
  public_fqdn?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  tunnels?: InputMaybe<Ddn_Tunnel_Http_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Tunnel_Http_Cluster_Max_Fields = {
  __typename?: 'ddn_tunnel_http_cluster_max_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ddn_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  internal_fqdn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  public_fqdn?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Ddn_Tunnel_Http_Cluster_Min_Fields = {
  __typename?: 'ddn_tunnel_http_cluster_min_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ddn_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  internal_fqdn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  public_fqdn?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ddn.tunnel_http_cluster" */
export type Ddn_Tunnel_Http_Cluster_Mutation_Response = {
  __typename?: 'ddn_tunnel_http_cluster_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Tunnel_Http_Cluster>;
};

/** input type for inserting object relation for remote table "ddn.tunnel_http_cluster" */
export type Ddn_Tunnel_Http_Cluster_Obj_Rel_Insert_Input = {
  data: Ddn_Tunnel_Http_Cluster_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Ddn_Tunnel_Http_Cluster_On_Conflict>;
};

/** on_conflict condition type for table "ddn.tunnel_http_cluster" */
export type Ddn_Tunnel_Http_Cluster_On_Conflict = {
  constraint: Ddn_Tunnel_Http_Cluster_Constraint;
  update_columns?: Array<Ddn_Tunnel_Http_Cluster_Update_Column>;
  where?: InputMaybe<Ddn_Tunnel_Http_Cluster_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.tunnel_http_cluster". */
export type Ddn_Tunnel_Http_Cluster_Order_By = {
  cloud?: InputMaybe<Order_By>;
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ddn_id?: InputMaybe<Order_By>;
  ddn_region?: InputMaybe<Ddn_Private_Ddn_Region_Order_By>;
  id?: InputMaybe<Order_By>;
  internal_fqdn?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  private_ddn?: InputMaybe<Ddn_Private_Ddn_Order_By>;
  public_fqdn?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  tunnels_aggregate?: InputMaybe<Ddn_Tunnel_Http_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.tunnel_http_cluster */
export type Ddn_Tunnel_Http_Cluster_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Ddn_Tunnel_Http_Cluster_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "ddn.tunnel_http_cluster" */
export enum Ddn_Tunnel_Http_Cluster_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DdnId = 'ddn_id',
  /** column name */
  Id = 'id',
  /** column name */
  InternalFqdn = 'internal_fqdn',
  /** column name */
  Name = 'name',
  /** column name */
  PublicFqdn = 'public_fqdn',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.tunnel_http_cluster" */
export type Ddn_Tunnel_Http_Cluster_Set_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ddn_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  internal_fqdn?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  public_fqdn?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_tunnel_http_cluster" */
export type Ddn_Tunnel_Http_Cluster_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Tunnel_Http_Cluster_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Tunnel_Http_Cluster_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  config?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ddn_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  internal_fqdn?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  public_fqdn?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.tunnel_http_cluster" */
export enum Ddn_Tunnel_Http_Cluster_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DdnId = 'ddn_id',
  /** column name */
  Id = 'id',
  /** column name */
  InternalFqdn = 'internal_fqdn',
  /** column name */
  Name = 'name',
  /** column name */
  PublicFqdn = 'public_fqdn',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Tunnel_Http_Cluster_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Ddn_Tunnel_Http_Cluster_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Ddn_Tunnel_Http_Cluster_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Ddn_Tunnel_Http_Cluster_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Ddn_Tunnel_Http_Cluster_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Ddn_Tunnel_Http_Cluster_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Tunnel_Http_Cluster_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Tunnel_Http_Cluster_Bool_Exp;
};

/** unique or primary key constraints on table "ddn.tunnel_http" */
export enum Ddn_Tunnel_Http_Constraint {
  /** unique or primary key constraint on columns "id" */
  TunnelHttpPkey = 'tunnel_http_pkey',
}

/** input type for inserting data into table "ddn.tunnel_http" */
export type Ddn_Tunnel_Http_Insert_Input = {
  cluster_id?: InputMaybe<Scalars['uuid']>;
  config?: InputMaybe<Scalars['json']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  tunnel_cluster?: InputMaybe<Ddn_Tunnel_Http_Cluster_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ddn_Tunnel_Http_Max_Fields = {
  __typename?: 'ddn_tunnel_http_max_fields';
  cluster_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "ddn.tunnel_http" */
export type Ddn_Tunnel_Http_Max_Order_By = {
  cluster_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Tunnel_Http_Min_Fields = {
  __typename?: 'ddn_tunnel_http_min_fields';
  cluster_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  owner_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "ddn.tunnel_http" */
export type Ddn_Tunnel_Http_Min_Order_By = {
  cluster_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.tunnel_http" */
export type Ddn_Tunnel_Http_Mutation_Response = {
  __typename?: 'ddn_tunnel_http_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Tunnel_Http>;
};

/** on_conflict condition type for table "ddn.tunnel_http" */
export type Ddn_Tunnel_Http_On_Conflict = {
  constraint: Ddn_Tunnel_Http_Constraint;
  update_columns?: Array<Ddn_Tunnel_Http_Update_Column>;
  where?: InputMaybe<Ddn_Tunnel_Http_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.tunnel_http". */
export type Ddn_Tunnel_Http_Order_By = {
  cluster_id?: InputMaybe<Order_By>;
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  tunnel_cluster?: InputMaybe<Ddn_Tunnel_Http_Cluster_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ddn.tunnel_http */
export type Ddn_Tunnel_Http_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.tunnel_http" */
export enum Ddn_Tunnel_Http_Select_Column {
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ddn.tunnel_http" */
export type Ddn_Tunnel_Http_Set_Input = {
  cluster_id?: InputMaybe<Scalars['uuid']>;
  config?: InputMaybe<Scalars['json']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ddn_tunnel_http" */
export type Ddn_Tunnel_Http_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Tunnel_Http_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Tunnel_Http_Stream_Cursor_Value_Input = {
  cluster_id?: InputMaybe<Scalars['uuid']>;
  config?: InputMaybe<Scalars['json']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ddn.tunnel_http" */
export enum Ddn_Tunnel_Http_Update_Column {
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  Config = 'config',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ddn_Tunnel_Http_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Tunnel_Http_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Tunnel_Http_Bool_Exp;
};

/** input type for incrementing numeric columns in table "ddn.tunnel" */
export type Ddn_Tunnel_Inc_Input = {
  reserved_port?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "ddn.tunnel" */
export type Ddn_Tunnel_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_connection_at?: InputMaybe<Scalars['timestamptz']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  reserved_cluster?: InputMaybe<Scalars['uuid']>;
  reserved_port?: InputMaybe<Scalars['Int']>;
  tunnel_cluster?: InputMaybe<Ddn_Tunnel_Cluster_Obj_Rel_Insert_Input>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Ddn_Tunnel_Max_Fields = {
  __typename?: 'ddn_tunnel_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_connection_at?: Maybe<Scalars['timestamptz']>;
  owner_id?: Maybe<Scalars['uuid']>;
  reserved_cluster?: Maybe<Scalars['uuid']>;
  reserved_port?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "ddn.tunnel" */
export type Ddn_Tunnel_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_connection_at?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  reserved_cluster?: InputMaybe<Order_By>;
  reserved_port?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_Tunnel_Min_Fields = {
  __typename?: 'ddn_tunnel_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  last_connection_at?: Maybe<Scalars['timestamptz']>;
  owner_id?: Maybe<Scalars['uuid']>;
  reserved_cluster?: Maybe<Scalars['uuid']>;
  reserved_port?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "ddn.tunnel" */
export type Ddn_Tunnel_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_connection_at?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  reserved_cluster?: InputMaybe<Order_By>;
  reserved_port?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "ddn.tunnel" */
export type Ddn_Tunnel_Mutation_Response = {
  __typename?: 'ddn_tunnel_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ddn_Tunnel>;
};

/** on_conflict condition type for table "ddn.tunnel" */
export type Ddn_Tunnel_On_Conflict = {
  constraint: Ddn_Tunnel_Constraint;
  update_columns?: Array<Ddn_Tunnel_Update_Column>;
  where?: InputMaybe<Ddn_Tunnel_Bool_Exp>;
};

/** Ordering options when selecting data from "ddn.tunnel". */
export type Ddn_Tunnel_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_connection_at?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  reserved_cluster?: InputMaybe<Order_By>;
  reserved_port?: InputMaybe<Order_By>;
  tunnel_cluster?: InputMaybe<Ddn_Tunnel_Cluster_Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: ddn.tunnel */
export type Ddn_Tunnel_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ddn.tunnel" */
export enum Ddn_Tunnel_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastConnectionAt = 'last_connection_at',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ReservedCluster = 'reserved_cluster',
  /** column name */
  ReservedPort = 'reserved_port',
}

/** input type for updating data in table "ddn.tunnel" */
export type Ddn_Tunnel_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_connection_at?: InputMaybe<Scalars['timestamptz']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  reserved_cluster?: InputMaybe<Scalars['uuid']>;
  reserved_port?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Ddn_Tunnel_Stddev_Fields = {
  __typename?: 'ddn_tunnel_stddev_fields';
  reserved_port?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "ddn.tunnel" */
export type Ddn_Tunnel_Stddev_Order_By = {
  reserved_port?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Ddn_Tunnel_Stddev_Pop_Fields = {
  __typename?: 'ddn_tunnel_stddev_pop_fields';
  reserved_port?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "ddn.tunnel" */
export type Ddn_Tunnel_Stddev_Pop_Order_By = {
  reserved_port?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Ddn_Tunnel_Stddev_Samp_Fields = {
  __typename?: 'ddn_tunnel_stddev_samp_fields';
  reserved_port?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "ddn.tunnel" */
export type Ddn_Tunnel_Stddev_Samp_Order_By = {
  reserved_port?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ddn_tunnel" */
export type Ddn_Tunnel_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_Tunnel_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_Tunnel_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_connection_at?: InputMaybe<Scalars['timestamptz']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  reserved_cluster?: InputMaybe<Scalars['uuid']>;
  reserved_port?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Ddn_Tunnel_Sum_Fields = {
  __typename?: 'ddn_tunnel_sum_fields';
  reserved_port?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "ddn.tunnel" */
export type Ddn_Tunnel_Sum_Order_By = {
  reserved_port?: InputMaybe<Order_By>;
};

/** update columns of table "ddn.tunnel" */
export enum Ddn_Tunnel_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastConnectionAt = 'last_connection_at',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ReservedCluster = 'reserved_cluster',
  /** column name */
  ReservedPort = 'reserved_port',
}

export type Ddn_Tunnel_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Ddn_Tunnel_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ddn_Tunnel_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ddn_Tunnel_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Ddn_Tunnel_Var_Pop_Fields = {
  __typename?: 'ddn_tunnel_var_pop_fields';
  reserved_port?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "ddn.tunnel" */
export type Ddn_Tunnel_Var_Pop_Order_By = {
  reserved_port?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Ddn_Tunnel_Var_Samp_Fields = {
  __typename?: 'ddn_tunnel_var_samp_fields';
  reserved_port?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "ddn.tunnel" */
export type Ddn_Tunnel_Var_Samp_Order_By = {
  reserved_port?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Ddn_Tunnel_Variance_Fields = {
  __typename?: 'ddn_tunnel_variance_fields';
  reserved_port?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "ddn.tunnel" */
export type Ddn_Tunnel_Variance_Order_By = {
  reserved_port?: InputMaybe<Order_By>;
};

/** columns and relationships of "ddn.user_project_access" */
export type Ddn_User_Project_Access = {
  __typename?: 'ddn_user_project_access';
  access_level?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  project?: Maybe<Ddn_Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users_Public>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "ddn.user_project_access" */
export type Ddn_User_Project_Access_Aggregate = {
  __typename?: 'ddn_user_project_access_aggregate';
  aggregate?: Maybe<Ddn_User_Project_Access_Aggregate_Fields>;
  nodes: Array<Ddn_User_Project_Access>;
};

export type Ddn_User_Project_Access_Aggregate_Bool_Exp = {
  count?: InputMaybe<Ddn_User_Project_Access_Aggregate_Bool_Exp_Count>;
};

export type Ddn_User_Project_Access_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Ddn_User_Project_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "ddn.user_project_access" */
export type Ddn_User_Project_Access_Aggregate_Fields = {
  __typename?: 'ddn_user_project_access_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_User_Project_Access_Max_Fields>;
  min?: Maybe<Ddn_User_Project_Access_Min_Fields>;
};

/** aggregate fields of "ddn.user_project_access" */
export type Ddn_User_Project_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_User_Project_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "ddn.user_project_access" */
export type Ddn_User_Project_Access_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ddn_User_Project_Access_Max_Order_By>;
  min?: InputMaybe<Ddn_User_Project_Access_Min_Order_By>;
};

/** input type for inserting array relation for remote table "ddn.user_project_access" */
export type Ddn_User_Project_Access_Arr_Rel_Insert_Input = {
  data: Array<Ddn_User_Project_Access_Insert_Input>;
};

/** Boolean expression to filter rows from the table "ddn.user_project_access". All fields are combined with a logical 'AND'. */
export type Ddn_User_Project_Access_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_User_Project_Access_Bool_Exp>>;
  _not?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_User_Project_Access_Bool_Exp>>;
  access_level?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  project?: InputMaybe<Ddn_Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Public_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "ddn.user_project_access" */
export type Ddn_User_Project_Access_Insert_Input = {
  access_level?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  project?: InputMaybe<Ddn_Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Public_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Ddn_User_Project_Access_Max_Fields = {
  __typename?: 'ddn_user_project_access_max_fields';
  access_level?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "ddn.user_project_access" */
export type Ddn_User_Project_Access_Max_Order_By = {
  access_level?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Ddn_User_Project_Access_Min_Fields = {
  __typename?: 'ddn_user_project_access_min_fields';
  access_level?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "ddn.user_project_access" */
export type Ddn_User_Project_Access_Min_Order_By = {
  access_level?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** input type for inserting object relation for remote table "ddn.user_project_access" */
export type Ddn_User_Project_Access_Obj_Rel_Insert_Input = {
  data: Ddn_User_Project_Access_Insert_Input;
};

/** Ordering options when selecting data from "ddn.user_project_access". */
export type Ddn_User_Project_Access_Order_By = {
  access_level?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  project?: InputMaybe<Ddn_Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Public_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "ddn.user_project_access" */
export enum Ddn_User_Project_Access_Select_Column {
  /** column name */
  AccessLevel = 'access_level',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "ddn_user_project_access" */
export type Ddn_User_Project_Access_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_User_Project_Access_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_User_Project_Access_Stream_Cursor_Value_Input = {
  access_level?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "ddn.v3_customer" */
export type Ddn_V3_Customer = {
  __typename?: 'ddn_v3_customer';
  customer_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  invoice: Array<Ddn_Invoice>;
  /** An aggregate relationship */
  invoice_aggregate: Ddn_Invoice_Aggregate;
  /** An array relationship */
  projects: Array<Ddn_Projects>;
  /** An aggregate relationship */
  projects_aggregate: Ddn_Projects_Aggregate;
  /** An object relationship */
  stripe_subscription?: Maybe<Ddn_Stripe_Subscription>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "ddn.v3_customer" */
export type Ddn_V3_CustomerInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Bool_Exp>;
};

/** columns and relationships of "ddn.v3_customer" */
export type Ddn_V3_CustomerInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Bool_Exp>;
};

/** columns and relationships of "ddn.v3_customer" */
export type Ddn_V3_CustomerProjectsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Projects_Order_By>>;
  where?: InputMaybe<Ddn_Projects_Bool_Exp>;
};

/** columns and relationships of "ddn.v3_customer" */
export type Ddn_V3_CustomerProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Projects_Order_By>>;
  where?: InputMaybe<Ddn_Projects_Bool_Exp>;
};

/** aggregated selection of "ddn.v3_customer" */
export type Ddn_V3_Customer_Aggregate = {
  __typename?: 'ddn_v3_customer_aggregate';
  aggregate?: Maybe<Ddn_V3_Customer_Aggregate_Fields>;
  nodes: Array<Ddn_V3_Customer>;
};

/** aggregate fields of "ddn.v3_customer" */
export type Ddn_V3_Customer_Aggregate_Fields = {
  __typename?: 'ddn_v3_customer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ddn_V3_Customer_Max_Fields>;
  min?: Maybe<Ddn_V3_Customer_Min_Fields>;
};

/** aggregate fields of "ddn.v3_customer" */
export type Ddn_V3_Customer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ddn_V3_Customer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ddn.v3_customer". All fields are combined with a logical 'AND'. */
export type Ddn_V3_Customer_Bool_Exp = {
  _and?: InputMaybe<Array<Ddn_V3_Customer_Bool_Exp>>;
  _not?: InputMaybe<Ddn_V3_Customer_Bool_Exp>;
  _or?: InputMaybe<Array<Ddn_V3_Customer_Bool_Exp>>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  invoice?: InputMaybe<Ddn_Invoice_Bool_Exp>;
  invoice_aggregate?: InputMaybe<Ddn_Invoice_Aggregate_Bool_Exp>;
  projects?: InputMaybe<Ddn_Projects_Bool_Exp>;
  projects_aggregate?: InputMaybe<Ddn_Projects_Aggregate_Bool_Exp>;
  stripe_subscription?: InputMaybe<Ddn_Stripe_Subscription_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Ddn_V3_Customer_Max_Fields = {
  __typename?: 'ddn_v3_customer_max_fields';
  customer_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Ddn_V3_Customer_Min_Fields = {
  __typename?: 'ddn_v3_customer_min_fields';
  customer_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "ddn.v3_customer". */
export type Ddn_V3_Customer_Order_By = {
  customer_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  invoice_aggregate?: InputMaybe<Ddn_Invoice_Aggregate_Order_By>;
  projects_aggregate?: InputMaybe<Ddn_Projects_Aggregate_Order_By>;
  stripe_subscription?: InputMaybe<Ddn_Stripe_Subscription_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "ddn.v3_customer" */
export enum Ddn_V3_Customer_Select_Column {
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Email = 'email',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "ddn_v3_customer" */
export type Ddn_V3_Customer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ddn_V3_Customer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ddn_V3_Customer_Stream_Cursor_Value_Input = {
  customer_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Table to keep track of dedicated cloud bills. These are not part of automated Stripe invoicing.  */
export type Dedicated_Cloud_Bills = {
  __typename?: 'dedicated_cloud_bills';
  compute_unit_config_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  cu_cost: Scalars['Int'];
  data_passthrough_cost: Scalars['Int'];
  /** An array relationship */
  details: Array<Dedicated_Cloud_Bills_Details>;
  /** An aggregate relationship */
  details_aggregate: Dedicated_Cloud_Bills_Details_Aggregate;
  id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  month: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
  year: Scalars['Int'];
};

/** Table to keep track of dedicated cloud bills. These are not part of automated Stripe invoicing.  */
export type Dedicated_Cloud_BillsDetailsArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Details_Bool_Exp>;
};

/** Table to keep track of dedicated cloud bills. These are not part of automated Stripe invoicing.  */
export type Dedicated_Cloud_BillsDetails_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Details_Bool_Exp>;
};

/** Table to keep track of dedicated cloud bills. These are not part of automated Stripe invoicing.  */
export type Dedicated_Cloud_BillsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Aggregate = {
  __typename?: 'dedicated_cloud_bills_aggregate';
  aggregate?: Maybe<Dedicated_Cloud_Bills_Aggregate_Fields>;
  nodes: Array<Dedicated_Cloud_Bills>;
};

export type Dedicated_Cloud_Bills_Aggregate_Bool_Exp = {
  count?: InputMaybe<Dedicated_Cloud_Bills_Aggregate_Bool_Exp_Count>;
};

export type Dedicated_Cloud_Bills_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dedicated_Cloud_Bills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Aggregate_Fields = {
  __typename?: 'dedicated_cloud_bills_aggregate_fields';
  avg?: Maybe<Dedicated_Cloud_Bills_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dedicated_Cloud_Bills_Max_Fields>;
  min?: Maybe<Dedicated_Cloud_Bills_Min_Fields>;
  stddev?: Maybe<Dedicated_Cloud_Bills_Stddev_Fields>;
  stddev_pop?: Maybe<Dedicated_Cloud_Bills_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dedicated_Cloud_Bills_Stddev_Samp_Fields>;
  sum?: Maybe<Dedicated_Cloud_Bills_Sum_Fields>;
  var_pop?: Maybe<Dedicated_Cloud_Bills_Var_Pop_Fields>;
  var_samp?: Maybe<Dedicated_Cloud_Bills_Var_Samp_Fields>;
  variance?: Maybe<Dedicated_Cloud_Bills_Variance_Fields>;
};

/** aggregate fields of "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dedicated_Cloud_Bills_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Aggregate_Order_By = {
  avg?: InputMaybe<Dedicated_Cloud_Bills_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dedicated_Cloud_Bills_Max_Order_By>;
  min?: InputMaybe<Dedicated_Cloud_Bills_Min_Order_By>;
  stddev?: InputMaybe<Dedicated_Cloud_Bills_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dedicated_Cloud_Bills_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dedicated_Cloud_Bills_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dedicated_Cloud_Bills_Sum_Order_By>;
  var_pop?: InputMaybe<Dedicated_Cloud_Bills_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dedicated_Cloud_Bills_Var_Samp_Order_By>;
  variance?: InputMaybe<Dedicated_Cloud_Bills_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Dedicated_Cloud_Bills_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Arr_Rel_Insert_Input = {
  data: Array<Dedicated_Cloud_Bills_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dedicated_Cloud_Bills_On_Conflict>;
};

/** aggregate avg on columns */
export type Dedicated_Cloud_Bills_Avg_Fields = {
  __typename?: 'dedicated_cloud_bills_avg_fields';
  cu_cost?: Maybe<Scalars['Float']>;
  data_passthrough_cost?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Avg_Order_By = {
  cu_cost?: InputMaybe<Order_By>;
  data_passthrough_cost?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dedicated_cloud_bills". All fields are combined with a logical 'AND'. */
export type Dedicated_Cloud_Bills_Bool_Exp = {
  _and?: InputMaybe<Array<Dedicated_Cloud_Bills_Bool_Exp>>;
  _not?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
  _or?: InputMaybe<Array<Dedicated_Cloud_Bills_Bool_Exp>>;
  compute_unit_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  cu_cost?: InputMaybe<Int_Comparison_Exp>;
  data_passthrough_cost?: InputMaybe<Int_Comparison_Exp>;
  details?: InputMaybe<Dedicated_Cloud_Bills_Details_Bool_Exp>;
  details_aggregate?: InputMaybe<
    Dedicated_Cloud_Bills_Details_Aggregate_Bool_Exp
  >;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  month?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  year?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "dedicated_cloud_bills" */
export enum Dedicated_Cloud_Bills_Constraint {
  /** unique or primary key constraint on columns "compute_unit_config_id", "year", "month" */
  DedicatedCloudBillsComputeUnitConfigIdMonthYearKey = 'dedicated_cloud_bills_compute_unit_config_id_month_year_key',
  /** unique or primary key constraint on columns "id" */
  DedicatedCloudBillsPkey = 'dedicated_cloud_bills_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Dedicated_Cloud_Bills_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Dedicated_Cloud_Bills_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Dedicated_Cloud_Bills_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** Granular details about compute unit usage based bills  */
export type Dedicated_Cloud_Bills_Details = {
  __typename?: 'dedicated_cloud_bills_details';
  bill_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  details?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  month: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  year: Scalars['Int'];
};

/** Granular details about compute unit usage based bills  */
export type Dedicated_Cloud_Bills_DetailsDetailsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Aggregate = {
  __typename?: 'dedicated_cloud_bills_details_aggregate';
  aggregate?: Maybe<Dedicated_Cloud_Bills_Details_Aggregate_Fields>;
  nodes: Array<Dedicated_Cloud_Bills_Details>;
};

export type Dedicated_Cloud_Bills_Details_Aggregate_Bool_Exp = {
  count?: InputMaybe<Dedicated_Cloud_Bills_Details_Aggregate_Bool_Exp_Count>;
};

export type Dedicated_Cloud_Bills_Details_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Dedicated_Cloud_Bills_Details_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Aggregate_Fields = {
  __typename?: 'dedicated_cloud_bills_details_aggregate_fields';
  avg?: Maybe<Dedicated_Cloud_Bills_Details_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dedicated_Cloud_Bills_Details_Max_Fields>;
  min?: Maybe<Dedicated_Cloud_Bills_Details_Min_Fields>;
  stddev?: Maybe<Dedicated_Cloud_Bills_Details_Stddev_Fields>;
  stddev_pop?: Maybe<Dedicated_Cloud_Bills_Details_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dedicated_Cloud_Bills_Details_Stddev_Samp_Fields>;
  sum?: Maybe<Dedicated_Cloud_Bills_Details_Sum_Fields>;
  var_pop?: Maybe<Dedicated_Cloud_Bills_Details_Var_Pop_Fields>;
  var_samp?: Maybe<Dedicated_Cloud_Bills_Details_Var_Samp_Fields>;
  variance?: Maybe<Dedicated_Cloud_Bills_Details_Variance_Fields>;
};

/** aggregate fields of "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Aggregate_Order_By = {
  avg?: InputMaybe<Dedicated_Cloud_Bills_Details_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Dedicated_Cloud_Bills_Details_Max_Order_By>;
  min?: InputMaybe<Dedicated_Cloud_Bills_Details_Min_Order_By>;
  stddev?: InputMaybe<Dedicated_Cloud_Bills_Details_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Dedicated_Cloud_Bills_Details_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Dedicated_Cloud_Bills_Details_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Dedicated_Cloud_Bills_Details_Sum_Order_By>;
  var_pop?: InputMaybe<Dedicated_Cloud_Bills_Details_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Dedicated_Cloud_Bills_Details_Var_Samp_Order_By>;
  variance?: InputMaybe<Dedicated_Cloud_Bills_Details_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Dedicated_Cloud_Bills_Details_Append_Input = {
  details?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Arr_Rel_Insert_Input = {
  data: Array<Dedicated_Cloud_Bills_Details_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Dedicated_Cloud_Bills_Details_On_Conflict>;
};

/** aggregate avg on columns */
export type Dedicated_Cloud_Bills_Details_Avg_Fields = {
  __typename?: 'dedicated_cloud_bills_details_avg_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Avg_Order_By = {
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "dedicated_cloud_bills_details". All fields are combined with a logical 'AND'. */
export type Dedicated_Cloud_Bills_Details_Bool_Exp = {
  _and?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Bool_Exp>>;
  _not?: InputMaybe<Dedicated_Cloud_Bills_Details_Bool_Exp>;
  _or?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Bool_Exp>>;
  bill_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  details?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  month?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  year?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "dedicated_cloud_bills_details" */
export enum Dedicated_Cloud_Bills_Details_Constraint {
  /** unique or primary key constraint on columns "id" */
  DedicatedCloudBillsDetailsPkey = 'dedicated_cloud_bills_details_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Dedicated_Cloud_Bills_Details_Delete_At_Path_Input = {
  details?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Dedicated_Cloud_Bills_Details_Delete_Elem_Input = {
  details?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Dedicated_Cloud_Bills_Details_Delete_Key_Input = {
  details?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Inc_Input = {
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Insert_Input = {
  bill_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  month?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Dedicated_Cloud_Bills_Details_Max_Fields = {
  __typename?: 'dedicated_cloud_bills_details_max_fields';
  bill_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  month?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Max_Order_By = {
  bill_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dedicated_Cloud_Bills_Details_Min_Fields = {
  __typename?: 'dedicated_cloud_bills_details_min_fields';
  bill_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  month?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Min_Order_By = {
  bill_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Mutation_Response = {
  __typename?: 'dedicated_cloud_bills_details_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dedicated_Cloud_Bills_Details>;
};

/** on_conflict condition type for table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_On_Conflict = {
  constraint: Dedicated_Cloud_Bills_Details_Constraint;
  update_columns?: Array<Dedicated_Cloud_Bills_Details_Update_Column>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Details_Bool_Exp>;
};

/** Ordering options when selecting data from "dedicated_cloud_bills_details". */
export type Dedicated_Cloud_Bills_Details_Order_By = {
  bill_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dedicated_cloud_bills_details */
export type Dedicated_Cloud_Bills_Details_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Dedicated_Cloud_Bills_Details_Prepend_Input = {
  details?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "dedicated_cloud_bills_details" */
export enum Dedicated_Cloud_Bills_Details_Select_Column {
  /** column name */
  BillId = 'bill_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  Month = 'month',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

/** input type for updating data in table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Set_Input = {
  bill_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  month?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Dedicated_Cloud_Bills_Details_Stddev_Fields = {
  __typename?: 'dedicated_cloud_bills_details_stddev_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Stddev_Order_By = {
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dedicated_Cloud_Bills_Details_Stddev_Pop_Fields = {
  __typename?: 'dedicated_cloud_bills_details_stddev_pop_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Stddev_Pop_Order_By = {
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dedicated_Cloud_Bills_Details_Stddev_Samp_Fields = {
  __typename?: 'dedicated_cloud_bills_details_stddev_samp_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Stddev_Samp_Order_By = {
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dedicated_Cloud_Bills_Details_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dedicated_Cloud_Bills_Details_Stream_Cursor_Value_Input = {
  bill_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  month?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Dedicated_Cloud_Bills_Details_Sum_Fields = {
  __typename?: 'dedicated_cloud_bills_details_sum_fields';
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Sum_Order_By = {
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** update columns of table "dedicated_cloud_bills_details" */
export enum Dedicated_Cloud_Bills_Details_Update_Column {
  /** column name */
  BillId = 'bill_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  Id = 'id',
  /** column name */
  Month = 'month',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

export type Dedicated_Cloud_Bills_Details_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Dedicated_Cloud_Bills_Details_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<
    Dedicated_Cloud_Bills_Details_Delete_At_Path_Input
  >;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Dedicated_Cloud_Bills_Details_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Dedicated_Cloud_Bills_Details_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dedicated_Cloud_Bills_Details_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Dedicated_Cloud_Bills_Details_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dedicated_Cloud_Bills_Details_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dedicated_Cloud_Bills_Details_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dedicated_Cloud_Bills_Details_Var_Pop_Fields = {
  __typename?: 'dedicated_cloud_bills_details_var_pop_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Var_Pop_Order_By = {
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dedicated_Cloud_Bills_Details_Var_Samp_Fields = {
  __typename?: 'dedicated_cloud_bills_details_var_samp_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Var_Samp_Order_By = {
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dedicated_Cloud_Bills_Details_Variance_Fields = {
  __typename?: 'dedicated_cloud_bills_details_variance_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dedicated_cloud_bills_details" */
export type Dedicated_Cloud_Bills_Details_Variance_Order_By = {
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Inc_Input = {
  cu_cost?: InputMaybe<Scalars['Int']>;
  data_passthrough_cost?: InputMaybe<Scalars['Int']>;
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Insert_Input = {
  compute_unit_config_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cu_cost?: InputMaybe<Scalars['Int']>;
  data_passthrough_cost?: InputMaybe<Scalars['Int']>;
  details?: InputMaybe<Dedicated_Cloud_Bills_Details_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  month?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Dedicated_Cloud_Bills_Max_Fields = {
  __typename?: 'dedicated_cloud_bills_max_fields';
  compute_unit_config_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cu_cost?: Maybe<Scalars['Int']>;
  data_passthrough_cost?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  month?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Max_Order_By = {
  compute_unit_config_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cu_cost?: InputMaybe<Order_By>;
  data_passthrough_cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Dedicated_Cloud_Bills_Min_Fields = {
  __typename?: 'dedicated_cloud_bills_min_fields';
  compute_unit_config_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  cu_cost?: Maybe<Scalars['Int']>;
  data_passthrough_cost?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  month?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Min_Order_By = {
  compute_unit_config_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cu_cost?: InputMaybe<Order_By>;
  data_passthrough_cost?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Mutation_Response = {
  __typename?: 'dedicated_cloud_bills_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dedicated_Cloud_Bills>;
};

/** on_conflict condition type for table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_On_Conflict = {
  constraint: Dedicated_Cloud_Bills_Constraint;
  update_columns?: Array<Dedicated_Cloud_Bills_Update_Column>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
};

/** Ordering options when selecting data from "dedicated_cloud_bills". */
export type Dedicated_Cloud_Bills_Order_By = {
  compute_unit_config_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  cu_cost?: InputMaybe<Order_By>;
  data_passthrough_cost?: InputMaybe<Order_By>;
  details_aggregate?: InputMaybe<
    Dedicated_Cloud_Bills_Details_Aggregate_Order_By
  >;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dedicated_cloud_bills */
export type Dedicated_Cloud_Bills_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Dedicated_Cloud_Bills_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "dedicated_cloud_bills" */
export enum Dedicated_Cloud_Bills_Select_Column {
  /** column name */
  ComputeUnitConfigId = 'compute_unit_config_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CuCost = 'cu_cost',
  /** column name */
  DataPassthroughCost = 'data_passthrough_cost',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Month = 'month',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Year = 'year',
}

/** input type for updating data in table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Set_Input = {
  compute_unit_config_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cu_cost?: InputMaybe<Scalars['Int']>;
  data_passthrough_cost?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  month?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Dedicated_Cloud_Bills_Stddev_Fields = {
  __typename?: 'dedicated_cloud_bills_stddev_fields';
  cu_cost?: Maybe<Scalars['Float']>;
  data_passthrough_cost?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Stddev_Order_By = {
  cu_cost?: InputMaybe<Order_By>;
  data_passthrough_cost?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Dedicated_Cloud_Bills_Stddev_Pop_Fields = {
  __typename?: 'dedicated_cloud_bills_stddev_pop_fields';
  cu_cost?: Maybe<Scalars['Float']>;
  data_passthrough_cost?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Stddev_Pop_Order_By = {
  cu_cost?: InputMaybe<Order_By>;
  data_passthrough_cost?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Dedicated_Cloud_Bills_Stddev_Samp_Fields = {
  __typename?: 'dedicated_cloud_bills_stddev_samp_fields';
  cu_cost?: Maybe<Scalars['Float']>;
  data_passthrough_cost?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Stddev_Samp_Order_By = {
  cu_cost?: InputMaybe<Order_By>;
  data_passthrough_cost?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dedicated_Cloud_Bills_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dedicated_Cloud_Bills_Stream_Cursor_Value_Input = {
  compute_unit_config_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  cu_cost?: InputMaybe<Scalars['Int']>;
  data_passthrough_cost?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  month?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Dedicated_Cloud_Bills_Sum_Fields = {
  __typename?: 'dedicated_cloud_bills_sum_fields';
  cu_cost?: Maybe<Scalars['Int']>;
  data_passthrough_cost?: Maybe<Scalars['Int']>;
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Sum_Order_By = {
  cu_cost?: InputMaybe<Order_By>;
  data_passthrough_cost?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** update columns of table "dedicated_cloud_bills" */
export enum Dedicated_Cloud_Bills_Update_Column {
  /** column name */
  ComputeUnitConfigId = 'compute_unit_config_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CuCost = 'cu_cost',
  /** column name */
  DataPassthroughCost = 'data_passthrough_cost',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Month = 'month',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Year = 'year',
}

export type Dedicated_Cloud_Bills_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Dedicated_Cloud_Bills_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Dedicated_Cloud_Bills_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Dedicated_Cloud_Bills_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Dedicated_Cloud_Bills_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dedicated_Cloud_Bills_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Dedicated_Cloud_Bills_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dedicated_Cloud_Bills_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dedicated_Cloud_Bills_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dedicated_Cloud_Bills_Var_Pop_Fields = {
  __typename?: 'dedicated_cloud_bills_var_pop_fields';
  cu_cost?: Maybe<Scalars['Float']>;
  data_passthrough_cost?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Var_Pop_Order_By = {
  cu_cost?: InputMaybe<Order_By>;
  data_passthrough_cost?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Dedicated_Cloud_Bills_Var_Samp_Fields = {
  __typename?: 'dedicated_cloud_bills_var_samp_fields';
  cu_cost?: Maybe<Scalars['Float']>;
  data_passthrough_cost?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Var_Samp_Order_By = {
  cu_cost?: InputMaybe<Order_By>;
  data_passthrough_cost?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Dedicated_Cloud_Bills_Variance_Fields = {
  __typename?: 'dedicated_cloud_bills_variance_fields';
  cu_cost?: Maybe<Scalars['Float']>;
  data_passthrough_cost?: Maybe<Scalars['Float']>;
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dedicated_cloud_bills" */
export type Dedicated_Cloud_Bills_Variance_Order_By = {
  cu_cost?: InputMaybe<Order_By>;
  data_passthrough_cost?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** This table records Dedicated Cloud Deals information (also available in Salesforce). */
export type Dedicated_Cloud_Commitments = {
  __typename?: 'dedicated_cloud_commitments';
  commitment_end?: Maybe<Scalars['date']>;
  commitment_start: Scalars['date'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An array relationship */
  monthly_usage: Array<Dedicated_Cloud_Bills>;
  /** An aggregate relationship */
  monthly_usage_aggregate: Dedicated_Cloud_Bills_Aggregate;
  support_plan: Support_Plan_Types_Enum;
  total_commitment: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
  yearly_commitment: Scalars['Int'];
};

/** This table records Dedicated Cloud Deals information (also available in Salesforce). */
export type Dedicated_Cloud_CommitmentsMonthly_UsageArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
};

/** This table records Dedicated Cloud Deals information (also available in Salesforce). */
export type Dedicated_Cloud_CommitmentsMonthly_Usage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
};

/** aggregated selection of "dedicated_cloud_commitments" */
export type Dedicated_Cloud_Commitments_Aggregate = {
  __typename?: 'dedicated_cloud_commitments_aggregate';
  aggregate?: Maybe<Dedicated_Cloud_Commitments_Aggregate_Fields>;
  nodes: Array<Dedicated_Cloud_Commitments>;
};

/** aggregate fields of "dedicated_cloud_commitments" */
export type Dedicated_Cloud_Commitments_Aggregate_Fields = {
  __typename?: 'dedicated_cloud_commitments_aggregate_fields';
  avg?: Maybe<Dedicated_Cloud_Commitments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dedicated_Cloud_Commitments_Max_Fields>;
  min?: Maybe<Dedicated_Cloud_Commitments_Min_Fields>;
  stddev?: Maybe<Dedicated_Cloud_Commitments_Stddev_Fields>;
  stddev_pop?: Maybe<Dedicated_Cloud_Commitments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dedicated_Cloud_Commitments_Stddev_Samp_Fields>;
  sum?: Maybe<Dedicated_Cloud_Commitments_Sum_Fields>;
  var_pop?: Maybe<Dedicated_Cloud_Commitments_Var_Pop_Fields>;
  var_samp?: Maybe<Dedicated_Cloud_Commitments_Var_Samp_Fields>;
  variance?: Maybe<Dedicated_Cloud_Commitments_Variance_Fields>;
};

/** aggregate fields of "dedicated_cloud_commitments" */
export type Dedicated_Cloud_Commitments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dedicated_Cloud_Commitments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Dedicated_Cloud_Commitments_Avg_Fields = {
  __typename?: 'dedicated_cloud_commitments_avg_fields';
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dedicated_cloud_commitments". All fields are combined with a logical 'AND'. */
export type Dedicated_Cloud_Commitments_Bool_Exp = {
  _and?: InputMaybe<Array<Dedicated_Cloud_Commitments_Bool_Exp>>;
  _not?: InputMaybe<Dedicated_Cloud_Commitments_Bool_Exp>;
  _or?: InputMaybe<Array<Dedicated_Cloud_Commitments_Bool_Exp>>;
  commitment_end?: InputMaybe<Date_Comparison_Exp>;
  commitment_start?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  monthly_usage?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
  monthly_usage_aggregate?: InputMaybe<
    Dedicated_Cloud_Bills_Aggregate_Bool_Exp
  >;
  support_plan?: InputMaybe<Support_Plan_Types_Enum_Comparison_Exp>;
  total_commitment?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  yearly_commitment?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "dedicated_cloud_commitments" */
export enum Dedicated_Cloud_Commitments_Constraint {
  /** unique or primary key constraint on columns "id" */
  DedicatedCloudCommitmentsPkey = 'dedicated_cloud_commitments_pkey',
}

/** input type for incrementing numeric columns in table "dedicated_cloud_commitments" */
export type Dedicated_Cloud_Commitments_Inc_Input = {
  total_commitment?: InputMaybe<Scalars['Int']>;
  yearly_commitment?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dedicated_cloud_commitments" */
export type Dedicated_Cloud_Commitments_Insert_Input = {
  commitment_end?: InputMaybe<Scalars['date']>;
  commitment_start?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  monthly_usage?: InputMaybe<Dedicated_Cloud_Bills_Arr_Rel_Insert_Input>;
  support_plan?: InputMaybe<Support_Plan_Types_Enum>;
  total_commitment?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  yearly_commitment?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Dedicated_Cloud_Commitments_Max_Fields = {
  __typename?: 'dedicated_cloud_commitments_max_fields';
  commitment_end?: Maybe<Scalars['date']>;
  commitment_start?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  total_commitment?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  yearly_commitment?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Dedicated_Cloud_Commitments_Min_Fields = {
  __typename?: 'dedicated_cloud_commitments_min_fields';
  commitment_end?: Maybe<Scalars['date']>;
  commitment_start?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  total_commitment?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  yearly_commitment?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "dedicated_cloud_commitments" */
export type Dedicated_Cloud_Commitments_Mutation_Response = {
  __typename?: 'dedicated_cloud_commitments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dedicated_Cloud_Commitments>;
};

/** on_conflict condition type for table "dedicated_cloud_commitments" */
export type Dedicated_Cloud_Commitments_On_Conflict = {
  constraint: Dedicated_Cloud_Commitments_Constraint;
  update_columns?: Array<Dedicated_Cloud_Commitments_Update_Column>;
  where?: InputMaybe<Dedicated_Cloud_Commitments_Bool_Exp>;
};

/** Ordering options when selecting data from "dedicated_cloud_commitments". */
export type Dedicated_Cloud_Commitments_Order_By = {
  commitment_end?: InputMaybe<Order_By>;
  commitment_start?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  monthly_usage_aggregate?: InputMaybe<
    Dedicated_Cloud_Bills_Aggregate_Order_By
  >;
  support_plan?: InputMaybe<Order_By>;
  total_commitment?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  yearly_commitment?: InputMaybe<Order_By>;
};

/** primary key columns input for table: dedicated_cloud_commitments */
export type Dedicated_Cloud_Commitments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "dedicated_cloud_commitments" */
export enum Dedicated_Cloud_Commitments_Select_Column {
  /** column name */
  CommitmentEnd = 'commitment_end',
  /** column name */
  CommitmentStart = 'commitment_start',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SupportPlan = 'support_plan',
  /** column name */
  TotalCommitment = 'total_commitment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  YearlyCommitment = 'yearly_commitment',
}

/** input type for updating data in table "dedicated_cloud_commitments" */
export type Dedicated_Cloud_Commitments_Set_Input = {
  commitment_end?: InputMaybe<Scalars['date']>;
  commitment_start?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  support_plan?: InputMaybe<Support_Plan_Types_Enum>;
  total_commitment?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  yearly_commitment?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Dedicated_Cloud_Commitments_Stddev_Fields = {
  __typename?: 'dedicated_cloud_commitments_stddev_fields';
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dedicated_Cloud_Commitments_Stddev_Pop_Fields = {
  __typename?: 'dedicated_cloud_commitments_stddev_pop_fields';
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dedicated_Cloud_Commitments_Stddev_Samp_Fields = {
  __typename?: 'dedicated_cloud_commitments_stddev_samp_fields';
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dedicated_cloud_commitments" */
export type Dedicated_Cloud_Commitments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dedicated_Cloud_Commitments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dedicated_Cloud_Commitments_Stream_Cursor_Value_Input = {
  commitment_end?: InputMaybe<Scalars['date']>;
  commitment_start?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  support_plan?: InputMaybe<Support_Plan_Types_Enum>;
  total_commitment?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  yearly_commitment?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Dedicated_Cloud_Commitments_Sum_Fields = {
  __typename?: 'dedicated_cloud_commitments_sum_fields';
  total_commitment?: Maybe<Scalars['Int']>;
  yearly_commitment?: Maybe<Scalars['Int']>;
};

/** update columns of table "dedicated_cloud_commitments" */
export enum Dedicated_Cloud_Commitments_Update_Column {
  /** column name */
  CommitmentEnd = 'commitment_end',
  /** column name */
  CommitmentStart = 'commitment_start',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SupportPlan = 'support_plan',
  /** column name */
  TotalCommitment = 'total_commitment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  YearlyCommitment = 'yearly_commitment',
}

export type Dedicated_Cloud_Commitments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dedicated_Cloud_Commitments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dedicated_Cloud_Commitments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dedicated_Cloud_Commitments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dedicated_Cloud_Commitments_Var_Pop_Fields = {
  __typename?: 'dedicated_cloud_commitments_var_pop_fields';
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dedicated_Cloud_Commitments_Var_Samp_Fields = {
  __typename?: 'dedicated_cloud_commitments_var_samp_fields';
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dedicated_Cloud_Commitments_Variance_Fields = {
  __typename?: 'dedicated_cloud_commitments_variance_fields';
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_Vpc = {
  __typename?: 'dedicated_vpc';
  cidr?: Maybe<Scalars['String']>;
  cloud: Scalars['String'];
  /** An array relationship */
  collaborator_invitations: Array<Vpc_Collaborator_Invitations>;
  /** An aggregate relationship */
  collaborator_invitations_aggregate: Vpc_Collaborator_Invitations_Aggregate;
  /** An array relationship */
  collaborators: Array<Vpc_Collaborators>;
  /** An aggregate relationship */
  collaborators_aggregate: Vpc_Collaborators_Aggregate;
  comments?: Maybe<Scalars['String']>;
  create_with_dev_cluster: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  dedicated_vpc?: Maybe<Dedicated_Vpc>;
  destroy_requested?: Maybe<Scalars['Boolean']>;
  disable_infra_auto_apply: Scalars['Boolean'];
  /** Returns the GCP network name of the VPC on Hasura Cloud */
  gcp_network_name?: Maybe<Scalars['String']>;
  /** Returns the security group to be associated with hasura workers inside dedicated VPC */
  hasura_worker_security_group_id?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  infra_status?: Maybe<Infra_Status_Enum>;
  input_variables?: Maybe<Scalars['jsonb']>;
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nat_ip?: Maybe<Scalars['String']>;
  nat_ips?: Maybe<Scalars['json']>;
  our_asn?: Maybe<Scalars['Int']>;
  output_variables?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  owner: Users;
  owner_id: Scalars['uuid'];
  parent_dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  plan_name: Scalars['String'];
  region: Scalars['String'];
  /** An object relationship */
  region_info?: Maybe<Region>;
  /** An array relationship */
  regional_data_connectors: Array<Regional_Data_Connectors>;
  /** An aggregate relationship */
  regional_data_connectors_aggregate: Regional_Data_Connectors_Aggregate;
  slug: Scalars['String'];
  status: Vpc_Status_Enum;
  /** An array relationship */
  tenant_groups: Array<Tenant_Group>;
  /** An aggregate relationship */
  tenant_groups_aggregate: Tenant_Group_Aggregate;
  /** An array relationship */
  tenants: Array<Tenant>;
  /** An aggregate relationship */
  tenants_aggregate: Tenant_Aggregate;
  their_asn?: Maybe<Scalars['Int']>;
  /** Get the VPC CIDR */
  vpc_cidr?: Maybe<Scalars['String']>;
  /** returns AWS VPC ID of the dedicated VPC */
  vpc_id?: Maybe<Scalars['String']>;
  /** An array relationship */
  vpc_peerings: Array<Vpc_Peering>;
  /** An aggregate relationship */
  vpc_peerings_aggregate: Vpc_Peering_Aggregate;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcCollaborator_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Vpc_Collaborator_Invitations_Bool_Exp>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcCollaborator_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Vpc_Collaborator_Invitations_Bool_Exp>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcCollaboratorsArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborators_Order_By>>;
  where?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcCollaborators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborators_Order_By>>;
  where?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcInput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcNat_IpsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcOutput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcRegional_Data_ConnectorsArgs = {
  distinct_on?: InputMaybe<Array<Regional_Data_Connectors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Data_Connectors_Order_By>>;
  where?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcRegional_Data_Connectors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Regional_Data_Connectors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Data_Connectors_Order_By>>;
  where?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcTenant_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Order_By>>;
  where?: InputMaybe<Tenant_Group_Bool_Exp>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcTenant_Groups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Order_By>>;
  where?: InputMaybe<Tenant_Group_Bool_Exp>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcTenantsArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Order_By>>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcTenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Order_By>>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcVpc_PeeringsArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Peering_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Peering_Order_By>>;
  where?: InputMaybe<Vpc_Peering_Bool_Exp>;
};

/** VPC to run dedicated hasura cloud  */
export type Dedicated_VpcVpc_Peerings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Peering_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Peering_Order_By>>;
  where?: InputMaybe<Vpc_Peering_Bool_Exp>;
};

/** aggregated selection of "dedicated_vpc" */
export type Dedicated_Vpc_Aggregate = {
  __typename?: 'dedicated_vpc_aggregate';
  aggregate?: Maybe<Dedicated_Vpc_Aggregate_Fields>;
  nodes: Array<Dedicated_Vpc>;
};

/** aggregate fields of "dedicated_vpc" */
export type Dedicated_Vpc_Aggregate_Fields = {
  __typename?: 'dedicated_vpc_aggregate_fields';
  avg?: Maybe<Dedicated_Vpc_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Dedicated_Vpc_Max_Fields>;
  min?: Maybe<Dedicated_Vpc_Min_Fields>;
  stddev?: Maybe<Dedicated_Vpc_Stddev_Fields>;
  stddev_pop?: Maybe<Dedicated_Vpc_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Dedicated_Vpc_Stddev_Samp_Fields>;
  sum?: Maybe<Dedicated_Vpc_Sum_Fields>;
  var_pop?: Maybe<Dedicated_Vpc_Var_Pop_Fields>;
  var_samp?: Maybe<Dedicated_Vpc_Var_Samp_Fields>;
  variance?: Maybe<Dedicated_Vpc_Variance_Fields>;
};

/** aggregate fields of "dedicated_vpc" */
export type Dedicated_Vpc_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dedicated_Vpc_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Dedicated_Vpc_Append_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Dedicated_Vpc_Avg_Fields = {
  __typename?: 'dedicated_vpc_avg_fields';
  our_asn?: Maybe<Scalars['Float']>;
  their_asn?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "dedicated_vpc". All fields are combined with a logical 'AND'. */
export type Dedicated_Vpc_Bool_Exp = {
  _and?: InputMaybe<Array<Dedicated_Vpc_Bool_Exp>>;
  _not?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
  _or?: InputMaybe<Array<Dedicated_Vpc_Bool_Exp>>;
  cidr?: InputMaybe<String_Comparison_Exp>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  collaborator_invitations?: InputMaybe<Vpc_Collaborator_Invitations_Bool_Exp>;
  collaborator_invitations_aggregate?: InputMaybe<
    Vpc_Collaborator_Invitations_Aggregate_Bool_Exp
  >;
  collaborators?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
  collaborators_aggregate?: InputMaybe<Vpc_Collaborators_Aggregate_Bool_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  create_with_dev_cluster?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
  destroy_requested?: InputMaybe<Boolean_Comparison_Exp>;
  disable_infra_auto_apply?: InputMaybe<Boolean_Comparison_Exp>;
  gcp_network_name?: InputMaybe<String_Comparison_Exp>;
  hasura_worker_security_group_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  infra_status?: InputMaybe<Infra_Status_Enum_Comparison_Exp>;
  input_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nat_ip?: InputMaybe<String_Comparison_Exp>;
  nat_ips?: InputMaybe<Json_Comparison_Exp>;
  our_asn?: InputMaybe<Int_Comparison_Exp>;
  output_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  parent_dedicated_vpc_id?: InputMaybe<Uuid_Comparison_Exp>;
  plan_name?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  region_info?: InputMaybe<Region_Bool_Exp>;
  regional_data_connectors?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
  regional_data_connectors_aggregate?: InputMaybe<
    Regional_Data_Connectors_Aggregate_Bool_Exp
  >;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Vpc_Status_Enum_Comparison_Exp>;
  tenant_groups?: InputMaybe<Tenant_Group_Bool_Exp>;
  tenant_groups_aggregate?: InputMaybe<Tenant_Group_Aggregate_Bool_Exp>;
  tenants?: InputMaybe<Tenant_Bool_Exp>;
  tenants_aggregate?: InputMaybe<Tenant_Aggregate_Bool_Exp>;
  their_asn?: InputMaybe<Int_Comparison_Exp>;
  vpc_cidr?: InputMaybe<String_Comparison_Exp>;
  vpc_id?: InputMaybe<String_Comparison_Exp>;
  vpc_peerings?: InputMaybe<Vpc_Peering_Bool_Exp>;
  vpc_peerings_aggregate?: InputMaybe<Vpc_Peering_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "dedicated_vpc" */
export enum Dedicated_Vpc_Constraint {
  /** unique or primary key constraint on columns "id" */
  DedicatedVpcPkey = 'dedicated_vpc_pkey',
  /** unique or primary key constraint on columns "slug" */
  DedicatedVpcSlugKey = 'dedicated_vpc_slug_key',
  /** unique or primary key constraint on columns "our_asn", "their_asn" */
  UniqueAsns = 'unique_asns',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Dedicated_Vpc_Delete_At_Path_Input = {
  input_variables?: InputMaybe<Array<Scalars['String']>>;
  output_variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Dedicated_Vpc_Delete_Elem_Input = {
  input_variables?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Dedicated_Vpc_Delete_Key_Input = {
  input_variables?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "dedicated_vpc" */
export type Dedicated_Vpc_Inc_Input = {
  our_asn?: InputMaybe<Scalars['Int']>;
  their_asn?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "dedicated_vpc" */
export type Dedicated_Vpc_Insert_Input = {
  cidr?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  collaborator_invitations?: InputMaybe<
    Vpc_Collaborator_Invitations_Arr_Rel_Insert_Input
  >;
  collaborators?: InputMaybe<Vpc_Collaborators_Arr_Rel_Insert_Input>;
  comments?: InputMaybe<Scalars['String']>;
  create_with_dev_cluster?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Obj_Rel_Insert_Input>;
  destroy_requested?: InputMaybe<Scalars['Boolean']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nat_ip?: InputMaybe<Scalars['String']>;
  our_asn?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  owner?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  parent_dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  plan_name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  region_info?: InputMaybe<Region_Obj_Rel_Insert_Input>;
  regional_data_connectors?: InputMaybe<
    Regional_Data_Connectors_Arr_Rel_Insert_Input
  >;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Vpc_Status_Enum>;
  tenant_groups?: InputMaybe<Tenant_Group_Arr_Rel_Insert_Input>;
  tenants?: InputMaybe<Tenant_Arr_Rel_Insert_Input>;
  their_asn?: InputMaybe<Scalars['Int']>;
  vpc_peerings?: InputMaybe<Vpc_Peering_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Dedicated_Vpc_Max_Fields = {
  __typename?: 'dedicated_vpc_max_fields';
  cidr?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Returns the GCP network name of the VPC on Hasura Cloud */
  gcp_network_name?: Maybe<Scalars['String']>;
  /** Returns the security group to be associated with hasura workers inside dedicated VPC */
  hasura_worker_security_group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nat_ip?: Maybe<Scalars['String']>;
  our_asn?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  parent_dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  plan_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  their_asn?: Maybe<Scalars['Int']>;
  /** Get the VPC CIDR */
  vpc_cidr?: Maybe<Scalars['String']>;
  /** returns AWS VPC ID of the dedicated VPC */
  vpc_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dedicated_Vpc_Min_Fields = {
  __typename?: 'dedicated_vpc_min_fields';
  cidr?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** Returns the GCP network name of the VPC on Hasura Cloud */
  gcp_network_name?: Maybe<Scalars['String']>;
  /** Returns the security group to be associated with hasura workers inside dedicated VPC */
  hasura_worker_security_group_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nat_ip?: Maybe<Scalars['String']>;
  our_asn?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  parent_dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  plan_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  their_asn?: Maybe<Scalars['Int']>;
  /** Get the VPC CIDR */
  vpc_cidr?: Maybe<Scalars['String']>;
  /** returns AWS VPC ID of the dedicated VPC */
  vpc_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "dedicated_vpc" */
export type Dedicated_Vpc_Mutation_Response = {
  __typename?: 'dedicated_vpc_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dedicated_Vpc>;
};

/** input type for inserting object relation for remote table "dedicated_vpc" */
export type Dedicated_Vpc_Obj_Rel_Insert_Input = {
  data: Dedicated_Vpc_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Dedicated_Vpc_On_Conflict>;
};

/** on_conflict condition type for table "dedicated_vpc" */
export type Dedicated_Vpc_On_Conflict = {
  constraint: Dedicated_Vpc_Constraint;
  update_columns?: Array<Dedicated_Vpc_Update_Column>;
  where?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
};

/** Ordering options when selecting data from "dedicated_vpc". */
export type Dedicated_Vpc_Order_By = {
  cidr?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  collaborator_invitations_aggregate?: InputMaybe<
    Vpc_Collaborator_Invitations_Aggregate_Order_By
  >;
  collaborators_aggregate?: InputMaybe<Vpc_Collaborators_Aggregate_Order_By>;
  comments?: InputMaybe<Order_By>;
  create_with_dev_cluster?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Order_By>;
  destroy_requested?: InputMaybe<Order_By>;
  disable_infra_auto_apply?: InputMaybe<Order_By>;
  gcp_network_name?: InputMaybe<Order_By>;
  hasura_worker_security_group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  infra_status?: InputMaybe<Order_By>;
  input_variables?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nat_ip?: InputMaybe<Order_By>;
  nat_ips?: InputMaybe<Order_By>;
  our_asn?: InputMaybe<Order_By>;
  output_variables?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  parent_dedicated_vpc_id?: InputMaybe<Order_By>;
  plan_name?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  region_info?: InputMaybe<Region_Order_By>;
  regional_data_connectors_aggregate?: InputMaybe<
    Regional_Data_Connectors_Aggregate_Order_By
  >;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tenant_groups_aggregate?: InputMaybe<Tenant_Group_Aggregate_Order_By>;
  tenants_aggregate?: InputMaybe<Tenant_Aggregate_Order_By>;
  their_asn?: InputMaybe<Order_By>;
  vpc_cidr?: InputMaybe<Order_By>;
  vpc_id?: InputMaybe<Order_By>;
  vpc_peerings_aggregate?: InputMaybe<Vpc_Peering_Aggregate_Order_By>;
};

/** View to get owner email of dedicated VPC for each unique domain */
export type Dedicated_Vpc_Owner = {
  __typename?: 'dedicated_vpc_owner';
  domain?: Maybe<Scalars['String']>;
  vpc_owner_email?: Maybe<Scalars['String']>;
};

/** aggregated selection of "dedicated_vpc_owner" */
export type Dedicated_Vpc_Owner_Aggregate = {
  __typename?: 'dedicated_vpc_owner_aggregate';
  aggregate?: Maybe<Dedicated_Vpc_Owner_Aggregate_Fields>;
  nodes: Array<Dedicated_Vpc_Owner>;
};

/** aggregate fields of "dedicated_vpc_owner" */
export type Dedicated_Vpc_Owner_Aggregate_Fields = {
  __typename?: 'dedicated_vpc_owner_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Dedicated_Vpc_Owner_Max_Fields>;
  min?: Maybe<Dedicated_Vpc_Owner_Min_Fields>;
};

/** aggregate fields of "dedicated_vpc_owner" */
export type Dedicated_Vpc_Owner_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Dedicated_Vpc_Owner_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dedicated_vpc_owner". All fields are combined with a logical 'AND'. */
export type Dedicated_Vpc_Owner_Bool_Exp = {
  _and?: InputMaybe<Array<Dedicated_Vpc_Owner_Bool_Exp>>;
  _not?: InputMaybe<Dedicated_Vpc_Owner_Bool_Exp>;
  _or?: InputMaybe<Array<Dedicated_Vpc_Owner_Bool_Exp>>;
  domain?: InputMaybe<String_Comparison_Exp>;
  vpc_owner_email?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Dedicated_Vpc_Owner_Max_Fields = {
  __typename?: 'dedicated_vpc_owner_max_fields';
  domain?: Maybe<Scalars['String']>;
  vpc_owner_email?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dedicated_Vpc_Owner_Min_Fields = {
  __typename?: 'dedicated_vpc_owner_min_fields';
  domain?: Maybe<Scalars['String']>;
  vpc_owner_email?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "dedicated_vpc_owner". */
export type Dedicated_Vpc_Owner_Order_By = {
  domain?: InputMaybe<Order_By>;
  vpc_owner_email?: InputMaybe<Order_By>;
};

/** select columns of table "dedicated_vpc_owner" */
export enum Dedicated_Vpc_Owner_Select_Column {
  /** column name */
  Domain = 'domain',
  /** column name */
  VpcOwnerEmail = 'vpc_owner_email',
}

/** Streaming cursor of the table "dedicated_vpc_owner" */
export type Dedicated_Vpc_Owner_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dedicated_Vpc_Owner_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dedicated_Vpc_Owner_Stream_Cursor_Value_Input = {
  domain?: InputMaybe<Scalars['String']>;
  vpc_owner_email?: InputMaybe<Scalars['String']>;
};

/** primary key columns input for table: dedicated_vpc */
export type Dedicated_Vpc_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Dedicated_Vpc_Prepend_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "dedicated_vpc" */
export enum Dedicated_Vpc_Select_Column {
  /** column name */
  Cidr = 'cidr',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreateWithDevCluster = 'create_with_dev_cluster',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DestroyRequested = 'destroy_requested',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  Id = 'id',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  NatIp = 'nat_ip',
  /** column name */
  OurAsn = 'our_asn',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ParentDedicatedVpcId = 'parent_dedicated_vpc_id',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  TheirAsn = 'their_asn',
}

/** input type for updating data in table "dedicated_vpc" */
export type Dedicated_Vpc_Set_Input = {
  cidr?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  create_with_dev_cluster?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  destroy_requested?: InputMaybe<Scalars['Boolean']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nat_ip?: InputMaybe<Scalars['String']>;
  our_asn?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  parent_dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  plan_name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Vpc_Status_Enum>;
  their_asn?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Dedicated_Vpc_Stddev_Fields = {
  __typename?: 'dedicated_vpc_stddev_fields';
  our_asn?: Maybe<Scalars['Float']>;
  their_asn?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Dedicated_Vpc_Stddev_Pop_Fields = {
  __typename?: 'dedicated_vpc_stddev_pop_fields';
  our_asn?: Maybe<Scalars['Float']>;
  their_asn?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Dedicated_Vpc_Stddev_Samp_Fields = {
  __typename?: 'dedicated_vpc_stddev_samp_fields';
  our_asn?: Maybe<Scalars['Float']>;
  their_asn?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "dedicated_vpc" */
export type Dedicated_Vpc_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Dedicated_Vpc_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Dedicated_Vpc_Stream_Cursor_Value_Input = {
  cidr?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  create_with_dev_cluster?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  destroy_requested?: InputMaybe<Scalars['Boolean']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nat_ip?: InputMaybe<Scalars['String']>;
  our_asn?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  parent_dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  plan_name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Vpc_Status_Enum>;
  their_asn?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Dedicated_Vpc_Sum_Fields = {
  __typename?: 'dedicated_vpc_sum_fields';
  our_asn?: Maybe<Scalars['Int']>;
  their_asn?: Maybe<Scalars['Int']>;
};

/** update columns of table "dedicated_vpc" */
export enum Dedicated_Vpc_Update_Column {
  /** column name */
  Cidr = 'cidr',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreateWithDevCluster = 'create_with_dev_cluster',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DestroyRequested = 'destroy_requested',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  Id = 'id',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  NatIp = 'nat_ip',
  /** column name */
  OurAsn = 'our_asn',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ParentDedicatedVpcId = 'parent_dedicated_vpc_id',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  TheirAsn = 'their_asn',
}

export type Dedicated_Vpc_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Dedicated_Vpc_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Dedicated_Vpc_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Dedicated_Vpc_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Dedicated_Vpc_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Dedicated_Vpc_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Dedicated_Vpc_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Dedicated_Vpc_Set_Input>;
  /** filter the rows which have to be updated */
  where: Dedicated_Vpc_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Dedicated_Vpc_Var_Pop_Fields = {
  __typename?: 'dedicated_vpc_var_pop_fields';
  our_asn?: Maybe<Scalars['Float']>;
  their_asn?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Dedicated_Vpc_Var_Samp_Fields = {
  __typename?: 'dedicated_vpc_var_samp_fields';
  our_asn?: Maybe<Scalars['Float']>;
  their_asn?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Dedicated_Vpc_Variance_Fields = {
  __typename?: 'dedicated_vpc_variance_fields';
  our_asn?: Maybe<Scalars['Float']>;
  their_asn?: Maybe<Scalars['Float']>;
};

export type DeleteUserResponse = {
  __typename?: 'deleteUserResponse';
  status: Scalars['String'];
};

/** Table to record user deletion */
export type Delete_User = {
  __typename?: 'delete_user';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  delete_tasks: Array<Delete_User_Tasks>;
  /** An aggregate relationship */
  delete_tasks_aggregate: Delete_User_Tasks_Aggregate;
  status?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  user_id: Scalars['uuid'];
};

/** Table to record user deletion */
export type Delete_UserDelete_TasksArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Tasks_Order_By>>;
  where?: InputMaybe<Delete_User_Tasks_Bool_Exp>;
};

/** Table to record user deletion */
export type Delete_UserDelete_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Tasks_Order_By>>;
  where?: InputMaybe<Delete_User_Tasks_Bool_Exp>;
};

/** aggregated selection of "delete_user" */
export type Delete_User_Aggregate = {
  __typename?: 'delete_user_aggregate';
  aggregate?: Maybe<Delete_User_Aggregate_Fields>;
  nodes: Array<Delete_User>;
};

/** aggregate fields of "delete_user" */
export type Delete_User_Aggregate_Fields = {
  __typename?: 'delete_user_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Delete_User_Max_Fields>;
  min?: Maybe<Delete_User_Min_Fields>;
};

/** aggregate fields of "delete_user" */
export type Delete_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Delete_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "delete_user". All fields are combined with a logical 'AND'. */
export type Delete_User_Bool_Exp = {
  _and?: InputMaybe<Array<Delete_User_Bool_Exp>>;
  _not?: InputMaybe<Delete_User_Bool_Exp>;
  _or?: InputMaybe<Array<Delete_User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delete_tasks?: InputMaybe<Delete_User_Tasks_Bool_Exp>;
  delete_tasks_aggregate?: InputMaybe<Delete_User_Tasks_Aggregate_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  stripe_customer_id?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "delete_user" */
export enum Delete_User_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  DeleteUserPkey = 'delete_user_pkey',
  /** unique or primary key constraint on columns "stripe_customer_id" */
  DeleteUserStripeCustomerIdKey = 'delete_user_stripe_customer_id_key',
}

/** input type for inserting data into table "delete_user" */
export type Delete_User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  delete_tasks?: InputMaybe<Delete_User_Tasks_Arr_Rel_Insert_Input>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Delete_User_Max_Fields = {
  __typename?: 'delete_user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Delete_User_Min_Fields = {
  __typename?: 'delete_user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "delete_user" */
export type Delete_User_Mutation_Response = {
  __typename?: 'delete_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Delete_User>;
};

/** on_conflict condition type for table "delete_user" */
export type Delete_User_On_Conflict = {
  constraint: Delete_User_Constraint;
  update_columns?: Array<Delete_User_Update_Column>;
  where?: InputMaybe<Delete_User_Bool_Exp>;
};

/** Ordering options when selecting data from "delete_user". */
export type Delete_User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delete_tasks_aggregate?: InputMaybe<Delete_User_Tasks_Aggregate_Order_By>;
  status?: InputMaybe<Order_By>;
  stripe_customer_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: delete_user */
export type Delete_User_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** select columns of table "delete_user" */
export enum Delete_User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "delete_user" */
export type Delete_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Hasura enum to capture the status of user deletion */
export type Delete_User_Status = {
  __typename?: 'delete_user_status';
  comment: Scalars['String'];
  status: Scalars['String'];
};

/** aggregated selection of "delete_user_status" */
export type Delete_User_Status_Aggregate = {
  __typename?: 'delete_user_status_aggregate';
  aggregate?: Maybe<Delete_User_Status_Aggregate_Fields>;
  nodes: Array<Delete_User_Status>;
};

/** aggregate fields of "delete_user_status" */
export type Delete_User_Status_Aggregate_Fields = {
  __typename?: 'delete_user_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Delete_User_Status_Max_Fields>;
  min?: Maybe<Delete_User_Status_Min_Fields>;
};

/** aggregate fields of "delete_user_status" */
export type Delete_User_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Delete_User_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "delete_user_status". All fields are combined with a logical 'AND'. */
export type Delete_User_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Delete_User_Status_Bool_Exp>>;
  _not?: InputMaybe<Delete_User_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Delete_User_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "delete_user_status" */
export enum Delete_User_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  DeleteUserStatusPkey = 'delete_user_status_pkey',
}

export enum Delete_User_Status_Enum {
  /** the task is completed */
  Completed = 'completed',
  /** the task is failed */
  Failed = 'failed',
  /** the task is in progress */
  InProgress = 'in_progress',
  /** the task is pending */
  Pending = 'pending',
}

/** Boolean expression to compare columns of type "delete_user_status_enum". All fields are combined with logical 'AND'. */
export type Delete_User_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Delete_User_Status_Enum>;
  _in?: InputMaybe<Array<Delete_User_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Delete_User_Status_Enum>;
  _nin?: InputMaybe<Array<Delete_User_Status_Enum>>;
};

/** input type for inserting data into table "delete_user_status" */
export type Delete_User_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Delete_User_Status_Max_Fields = {
  __typename?: 'delete_user_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Delete_User_Status_Min_Fields = {
  __typename?: 'delete_user_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "delete_user_status" */
export type Delete_User_Status_Mutation_Response = {
  __typename?: 'delete_user_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Delete_User_Status>;
};

/** on_conflict condition type for table "delete_user_status" */
export type Delete_User_Status_On_Conflict = {
  constraint: Delete_User_Status_Constraint;
  update_columns?: Array<Delete_User_Status_Update_Column>;
  where?: InputMaybe<Delete_User_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "delete_user_status". */
export type Delete_User_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: delete_user_status */
export type Delete_User_Status_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "delete_user_status" */
export enum Delete_User_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "delete_user_status" */
export type Delete_User_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "delete_user_status" */
export type Delete_User_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Delete_User_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Delete_User_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "delete_user_status" */
export enum Delete_User_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Status = 'status',
}

export type Delete_User_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Delete_User_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Delete_User_Status_Bool_Exp;
};

/** Streaming cursor of the table "delete_user" */
export type Delete_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Delete_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Delete_User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** User tasks to be deleted */
export type Delete_User_Tasks = {
  __typename?: 'delete_user_tasks';
  additional_info?: Maybe<Scalars['jsonb']>;
  remarks?: Maybe<Scalars['String']>;
  status: Delete_User_Status_Enum;
  task_name: Scalars['String'];
  task_order: Scalars['Int'];
  user_id: Scalars['uuid'];
  /** An object relationship */
  users?: Maybe<Users>;
};

/** User tasks to be deleted */
export type Delete_User_TasksAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "delete_user_tasks" */
export type Delete_User_Tasks_Aggregate = {
  __typename?: 'delete_user_tasks_aggregate';
  aggregate?: Maybe<Delete_User_Tasks_Aggregate_Fields>;
  nodes: Array<Delete_User_Tasks>;
};

export type Delete_User_Tasks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Delete_User_Tasks_Aggregate_Bool_Exp_Count>;
};

export type Delete_User_Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Delete_User_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Delete_User_Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "delete_user_tasks" */
export type Delete_User_Tasks_Aggregate_Fields = {
  __typename?: 'delete_user_tasks_aggregate_fields';
  avg?: Maybe<Delete_User_Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Delete_User_Tasks_Max_Fields>;
  min?: Maybe<Delete_User_Tasks_Min_Fields>;
  stddev?: Maybe<Delete_User_Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Delete_User_Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Delete_User_Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Delete_User_Tasks_Sum_Fields>;
  var_pop?: Maybe<Delete_User_Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Delete_User_Tasks_Var_Samp_Fields>;
  variance?: Maybe<Delete_User_Tasks_Variance_Fields>;
};

/** aggregate fields of "delete_user_tasks" */
export type Delete_User_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Delete_User_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "delete_user_tasks" */
export type Delete_User_Tasks_Aggregate_Order_By = {
  avg?: InputMaybe<Delete_User_Tasks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Delete_User_Tasks_Max_Order_By>;
  min?: InputMaybe<Delete_User_Tasks_Min_Order_By>;
  stddev?: InputMaybe<Delete_User_Tasks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Delete_User_Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Delete_User_Tasks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Delete_User_Tasks_Sum_Order_By>;
  var_pop?: InputMaybe<Delete_User_Tasks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Delete_User_Tasks_Var_Samp_Order_By>;
  variance?: InputMaybe<Delete_User_Tasks_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Delete_User_Tasks_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "delete_user_tasks" */
export type Delete_User_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Delete_User_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Delete_User_Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Delete_User_Tasks_Avg_Fields = {
  __typename?: 'delete_user_tasks_avg_fields';
  task_order?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "delete_user_tasks" */
export type Delete_User_Tasks_Avg_Order_By = {
  task_order?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "delete_user_tasks". All fields are combined with a logical 'AND'. */
export type Delete_User_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Delete_User_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Delete_User_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Delete_User_Tasks_Bool_Exp>>;
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Delete_User_Status_Enum_Comparison_Exp>;
  task_name?: InputMaybe<String_Comparison_Exp>;
  task_order?: InputMaybe<Int_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "delete_user_tasks" */
export enum Delete_User_Tasks_Constraint {
  /** unique or primary key constraint on columns "task_name", "user_id" */
  DeleteUserTasksPkey = 'delete_user_tasks_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Delete_User_Tasks_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Delete_User_Tasks_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Delete_User_Tasks_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "delete_user_tasks" */
export type Delete_User_Tasks_Inc_Input = {
  task_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "delete_user_tasks" */
export type Delete_User_Tasks_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Delete_User_Status_Enum>;
  task_name?: InputMaybe<Scalars['String']>;
  task_order?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  users?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Delete_User_Tasks_Max_Fields = {
  __typename?: 'delete_user_tasks_max_fields';
  remarks?: Maybe<Scalars['String']>;
  task_name?: Maybe<Scalars['String']>;
  task_order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "delete_user_tasks" */
export type Delete_User_Tasks_Max_Order_By = {
  remarks?: InputMaybe<Order_By>;
  task_name?: InputMaybe<Order_By>;
  task_order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Delete_User_Tasks_Min_Fields = {
  __typename?: 'delete_user_tasks_min_fields';
  remarks?: Maybe<Scalars['String']>;
  task_name?: Maybe<Scalars['String']>;
  task_order?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "delete_user_tasks" */
export type Delete_User_Tasks_Min_Order_By = {
  remarks?: InputMaybe<Order_By>;
  task_name?: InputMaybe<Order_By>;
  task_order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "delete_user_tasks" */
export type Delete_User_Tasks_Mutation_Response = {
  __typename?: 'delete_user_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Delete_User_Tasks>;
};

/** on_conflict condition type for table "delete_user_tasks" */
export type Delete_User_Tasks_On_Conflict = {
  constraint: Delete_User_Tasks_Constraint;
  update_columns?: Array<Delete_User_Tasks_Update_Column>;
  where?: InputMaybe<Delete_User_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "delete_user_tasks". */
export type Delete_User_Tasks_Order_By = {
  additional_info?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  task_name?: InputMaybe<Order_By>;
  task_order?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  users?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: delete_user_tasks */
export type Delete_User_Tasks_Pk_Columns_Input = {
  task_name: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Delete_User_Tasks_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "delete_user_tasks" */
export enum Delete_User_Tasks_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Status = 'status',
  /** column name */
  TaskName = 'task_name',
  /** column name */
  TaskOrder = 'task_order',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "delete_user_tasks" */
export type Delete_User_Tasks_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Delete_User_Status_Enum>;
  task_name?: InputMaybe<Scalars['String']>;
  task_order?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Delete_User_Tasks_Stddev_Fields = {
  __typename?: 'delete_user_tasks_stddev_fields';
  task_order?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "delete_user_tasks" */
export type Delete_User_Tasks_Stddev_Order_By = {
  task_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Delete_User_Tasks_Stddev_Pop_Fields = {
  __typename?: 'delete_user_tasks_stddev_pop_fields';
  task_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "delete_user_tasks" */
export type Delete_User_Tasks_Stddev_Pop_Order_By = {
  task_order?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Delete_User_Tasks_Stddev_Samp_Fields = {
  __typename?: 'delete_user_tasks_stddev_samp_fields';
  task_order?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "delete_user_tasks" */
export type Delete_User_Tasks_Stddev_Samp_Order_By = {
  task_order?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "delete_user_tasks" */
export type Delete_User_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Delete_User_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Delete_User_Tasks_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Delete_User_Status_Enum>;
  task_name?: InputMaybe<Scalars['String']>;
  task_order?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Delete_User_Tasks_Sum_Fields = {
  __typename?: 'delete_user_tasks_sum_fields';
  task_order?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "delete_user_tasks" */
export type Delete_User_Tasks_Sum_Order_By = {
  task_order?: InputMaybe<Order_By>;
};

/** update columns of table "delete_user_tasks" */
export enum Delete_User_Tasks_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Status = 'status',
  /** column name */
  TaskName = 'task_name',
  /** column name */
  TaskOrder = 'task_order',
  /** column name */
  UserId = 'user_id',
}

export type Delete_User_Tasks_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Delete_User_Tasks_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Delete_User_Tasks_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Delete_User_Tasks_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Delete_User_Tasks_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Delete_User_Tasks_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Delete_User_Tasks_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Delete_User_Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Delete_User_Tasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Delete_User_Tasks_Var_Pop_Fields = {
  __typename?: 'delete_user_tasks_var_pop_fields';
  task_order?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "delete_user_tasks" */
export type Delete_User_Tasks_Var_Pop_Order_By = {
  task_order?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Delete_User_Tasks_Var_Samp_Fields = {
  __typename?: 'delete_user_tasks_var_samp_fields';
  task_order?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "delete_user_tasks" */
export type Delete_User_Tasks_Var_Samp_Order_By = {
  task_order?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Delete_User_Tasks_Variance_Fields = {
  __typename?: 'delete_user_tasks_variance_fields';
  task_order?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "delete_user_tasks" */
export type Delete_User_Tasks_Variance_Order_By = {
  task_order?: InputMaybe<Order_By>;
};

/** update columns of table "delete_user" */
export enum Delete_User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  UserId = 'user_id',
}

export type Delete_User_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Delete_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Delete_User_Bool_Exp;
};

/** columns and relationships of "email_log" */
export type Email_Log = {
  __typename?: 'email_log';
  created_at: Scalars['timestamptz'];
  created_by_service: Scalars['String'];
  from: Scalars['String'];
  from_name: Scalars['String'];
  id: Scalars['uuid'];
  is_sent: Scalars['Boolean'];
  sent_at?: Maybe<Scalars['timestamptz']>;
  template_metadata?: Maybe<Scalars['jsonb']>;
  template_name: Scalars['String'];
  to: Scalars['String'];
};

/** columns and relationships of "email_log" */
export type Email_LogTemplate_MetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "email_log" */
export type Email_Log_Aggregate = {
  __typename?: 'email_log_aggregate';
  aggregate?: Maybe<Email_Log_Aggregate_Fields>;
  nodes: Array<Email_Log>;
};

/** aggregate fields of "email_log" */
export type Email_Log_Aggregate_Fields = {
  __typename?: 'email_log_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Email_Log_Max_Fields>;
  min?: Maybe<Email_Log_Min_Fields>;
};

/** aggregate fields of "email_log" */
export type Email_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Email_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Email_Log_Append_Input = {
  template_metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "email_log". All fields are combined with a logical 'AND'. */
export type Email_Log_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Log_Bool_Exp>>;
  _not?: InputMaybe<Email_Log_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Log_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_service?: InputMaybe<String_Comparison_Exp>;
  from?: InputMaybe<String_Comparison_Exp>;
  from_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_sent?: InputMaybe<Boolean_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  template_metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  template_name?: InputMaybe<String_Comparison_Exp>;
  to?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_log" */
export enum Email_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  EmailLogPkey = 'email_log_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Email_Log_Delete_At_Path_Input = {
  template_metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Email_Log_Delete_Elem_Input = {
  template_metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Email_Log_Delete_Key_Input = {
  template_metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "email_log" */
export type Email_Log_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_service?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  from_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_sent?: InputMaybe<Scalars['Boolean']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  template_metadata?: InputMaybe<Scalars['jsonb']>;
  template_name?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Email_Log_Max_Fields = {
  __typename?: 'email_log_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_service?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  from_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  template_name?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Email_Log_Min_Fields = {
  __typename?: 'email_log_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by_service?: Maybe<Scalars['String']>;
  from?: Maybe<Scalars['String']>;
  from_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  sent_at?: Maybe<Scalars['timestamptz']>;
  template_name?: Maybe<Scalars['String']>;
  to?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "email_log" */
export type Email_Log_Mutation_Response = {
  __typename?: 'email_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Log>;
};

/** on_conflict condition type for table "email_log" */
export type Email_Log_On_Conflict = {
  constraint: Email_Log_Constraint;
  update_columns?: Array<Email_Log_Update_Column>;
  where?: InputMaybe<Email_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "email_log". */
export type Email_Log_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by_service?: InputMaybe<Order_By>;
  from?: InputMaybe<Order_By>;
  from_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_sent?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
  template_metadata?: InputMaybe<Order_By>;
  template_name?: InputMaybe<Order_By>;
  to?: InputMaybe<Order_By>;
};

/** primary key columns input for table: email_log */
export type Email_Log_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Email_Log_Prepend_Input = {
  template_metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "email_log" */
export enum Email_Log_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByService = 'created_by_service',
  /** column name */
  From = 'from',
  /** column name */
  FromName = 'from_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsSent = 'is_sent',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  TemplateMetadata = 'template_metadata',
  /** column name */
  TemplateName = 'template_name',
  /** column name */
  To = 'to',
}

/** input type for updating data in table "email_log" */
export type Email_Log_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_service?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  from_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_sent?: InputMaybe<Scalars['Boolean']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  template_metadata?: InputMaybe<Scalars['jsonb']>;
  template_name?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "email_log" */
export type Email_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Email_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Email_Log_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by_service?: InputMaybe<Scalars['String']>;
  from?: InputMaybe<Scalars['String']>;
  from_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_sent?: InputMaybe<Scalars['Boolean']>;
  sent_at?: InputMaybe<Scalars['timestamptz']>;
  template_metadata?: InputMaybe<Scalars['jsonb']>;
  template_name?: InputMaybe<Scalars['String']>;
  to?: InputMaybe<Scalars['String']>;
};

/** update columns of table "email_log" */
export enum Email_Log_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByService = 'created_by_service',
  /** column name */
  From = 'from',
  /** column name */
  FromName = 'from_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsSent = 'is_sent',
  /** column name */
  SentAt = 'sent_at',
  /** column name */
  TemplateMetadata = 'template_metadata',
  /** column name */
  TemplateName = 'template_name',
  /** column name */
  To = 'to',
}

export type Email_Log_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Email_Log_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Email_Log_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Email_Log_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Email_Log_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Email_Log_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Email_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Log_Bool_Exp;
};

/** list of users on Hasura enterprise edition */
export type Enterprise_Users = {
  __typename?: 'enterprise_users';
  created_at: Scalars['timestamptz'];
  customer_email: Scalars['citext'];
  id: Scalars['Int'];
  is_active: Scalars['Boolean'];
  is_exempt_from_billing: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  users?: Maybe<Users>;
};

/** aggregated selection of "enterprise_users" */
export type Enterprise_Users_Aggregate = {
  __typename?: 'enterprise_users_aggregate';
  aggregate?: Maybe<Enterprise_Users_Aggregate_Fields>;
  nodes: Array<Enterprise_Users>;
};

/** aggregate fields of "enterprise_users" */
export type Enterprise_Users_Aggregate_Fields = {
  __typename?: 'enterprise_users_aggregate_fields';
  avg?: Maybe<Enterprise_Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Enterprise_Users_Max_Fields>;
  min?: Maybe<Enterprise_Users_Min_Fields>;
  stddev?: Maybe<Enterprise_Users_Stddev_Fields>;
  stddev_pop?: Maybe<Enterprise_Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Enterprise_Users_Stddev_Samp_Fields>;
  sum?: Maybe<Enterprise_Users_Sum_Fields>;
  var_pop?: Maybe<Enterprise_Users_Var_Pop_Fields>;
  var_samp?: Maybe<Enterprise_Users_Var_Samp_Fields>;
  variance?: Maybe<Enterprise_Users_Variance_Fields>;
};

/** aggregate fields of "enterprise_users" */
export type Enterprise_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enterprise_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Enterprise_Users_Avg_Fields = {
  __typename?: 'enterprise_users_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "enterprise_users". All fields are combined with a logical 'AND'. */
export type Enterprise_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Enterprise_Users_Bool_Exp>>;
  _not?: InputMaybe<Enterprise_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Enterprise_Users_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_email?: InputMaybe<Citext_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_exempt_from_billing?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "enterprise_users" */
export enum Enterprise_Users_Constraint {
  /** unique or primary key constraint on columns "customer_email" */
  EnterpriseUsersCustomerEmailKey = 'enterprise_users_customer_email_key',
  /** unique or primary key constraint on columns "customer_email" */
  EnterpriseUsersPkey = 'enterprise_users_pkey',
}

/** list of domains on Hasura enterprise edition that need to be exempted from billing */
export type Enterprise_Users_Domain = {
  __typename?: 'enterprise_users_domain';
  created_at: Scalars['timestamptz'];
  domain: Scalars['citext'];
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  is_exempt_from_billing: Scalars['Boolean'];
  owner_email: Scalars['citext'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  users: Users;
};

/** aggregated selection of "enterprise_users_domain" */
export type Enterprise_Users_Domain_Aggregate = {
  __typename?: 'enterprise_users_domain_aggregate';
  aggregate?: Maybe<Enterprise_Users_Domain_Aggregate_Fields>;
  nodes: Array<Enterprise_Users_Domain>;
};

/** aggregate fields of "enterprise_users_domain" */
export type Enterprise_Users_Domain_Aggregate_Fields = {
  __typename?: 'enterprise_users_domain_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Enterprise_Users_Domain_Max_Fields>;
  min?: Maybe<Enterprise_Users_Domain_Min_Fields>;
};

/** aggregate fields of "enterprise_users_domain" */
export type Enterprise_Users_Domain_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Enterprise_Users_Domain_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "enterprise_users_domain". All fields are combined with a logical 'AND'. */
export type Enterprise_Users_Domain_Bool_Exp = {
  _and?: InputMaybe<Array<Enterprise_Users_Domain_Bool_Exp>>;
  _not?: InputMaybe<Enterprise_Users_Domain_Bool_Exp>;
  _or?: InputMaybe<Array<Enterprise_Users_Domain_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  domain?: InputMaybe<Citext_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_exempt_from_billing?: InputMaybe<Boolean_Comparison_Exp>;
  owner_email?: InputMaybe<Citext_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  users?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "enterprise_users_domain" */
export enum Enterprise_Users_Domain_Constraint {
  /** unique or primary key constraint on columns "id" */
  EnterpriseUsersDomainPkey = 'enterprise_users_domain_pkey',
  /** unique or primary key constraint on columns "domain", "owner_email" */
  UniqueDomainOwnerEmail = 'unique_domain_owner_email',
}

/** input type for inserting data into table "enterprise_users_domain" */
export type Enterprise_Users_Domain_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_exempt_from_billing?: InputMaybe<Scalars['Boolean']>;
  owner_email?: InputMaybe<Scalars['citext']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  users?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Enterprise_Users_Domain_Max_Fields = {
  __typename?: 'enterprise_users_domain_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  owner_email?: Maybe<Scalars['citext']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Enterprise_Users_Domain_Min_Fields = {
  __typename?: 'enterprise_users_domain_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  domain?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  owner_email?: Maybe<Scalars['citext']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "enterprise_users_domain" */
export type Enterprise_Users_Domain_Mutation_Response = {
  __typename?: 'enterprise_users_domain_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enterprise_Users_Domain>;
};

/** on_conflict condition type for table "enterprise_users_domain" */
export type Enterprise_Users_Domain_On_Conflict = {
  constraint: Enterprise_Users_Domain_Constraint;
  update_columns?: Array<Enterprise_Users_Domain_Update_Column>;
  where?: InputMaybe<Enterprise_Users_Domain_Bool_Exp>;
};

/** Ordering options when selecting data from "enterprise_users_domain". */
export type Enterprise_Users_Domain_Order_By = {
  created_at?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_exempt_from_billing?: InputMaybe<Order_By>;
  owner_email?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  users?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: enterprise_users_domain */
export type Enterprise_Users_Domain_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "enterprise_users_domain" */
export enum Enterprise_Users_Domain_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsExemptFromBilling = 'is_exempt_from_billing',
  /** column name */
  OwnerEmail = 'owner_email',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "enterprise_users_domain" */
export type Enterprise_Users_Domain_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_exempt_from_billing?: InputMaybe<Scalars['Boolean']>;
  owner_email?: InputMaybe<Scalars['citext']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "enterprise_users_domain" */
export type Enterprise_Users_Domain_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enterprise_Users_Domain_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enterprise_Users_Domain_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  domain?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_exempt_from_billing?: InputMaybe<Scalars['Boolean']>;
  owner_email?: InputMaybe<Scalars['citext']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "enterprise_users_domain" */
export enum Enterprise_Users_Domain_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsExemptFromBilling = 'is_exempt_from_billing',
  /** column name */
  OwnerEmail = 'owner_email',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Enterprise_Users_Domain_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enterprise_Users_Domain_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enterprise_Users_Domain_Bool_Exp;
};

/** input type for incrementing numeric columns in table "enterprise_users" */
export type Enterprise_Users_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "enterprise_users" */
export type Enterprise_Users_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_exempt_from_billing?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  users?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Enterprise_Users_Max_Fields = {
  __typename?: 'enterprise_users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Enterprise_Users_Min_Fields = {
  __typename?: 'enterprise_users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "enterprise_users" */
export type Enterprise_Users_Mutation_Response = {
  __typename?: 'enterprise_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Enterprise_Users>;
};

/** input type for inserting object relation for remote table "enterprise_users" */
export type Enterprise_Users_Obj_Rel_Insert_Input = {
  data: Enterprise_Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Enterprise_Users_On_Conflict>;
};

/** on_conflict condition type for table "enterprise_users" */
export type Enterprise_Users_On_Conflict = {
  constraint: Enterprise_Users_Constraint;
  update_columns?: Array<Enterprise_Users_Update_Column>;
  where?: InputMaybe<Enterprise_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "enterprise_users". */
export type Enterprise_Users_Order_By = {
  created_at?: InputMaybe<Order_By>;
  customer_email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  is_exempt_from_billing?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  users?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: enterprise_users */
export type Enterprise_Users_Pk_Columns_Input = {
  customer_email: Scalars['citext'];
};

/** select columns of table "enterprise_users" */
export enum Enterprise_Users_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsExemptFromBilling = 'is_exempt_from_billing',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "enterprise_users" */
export type Enterprise_Users_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_exempt_from_billing?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Enterprise_Users_Stddev_Fields = {
  __typename?: 'enterprise_users_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Enterprise_Users_Stddev_Pop_Fields = {
  __typename?: 'enterprise_users_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Enterprise_Users_Stddev_Samp_Fields = {
  __typename?: 'enterprise_users_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "enterprise_users" */
export type Enterprise_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Enterprise_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Enterprise_Users_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  is_exempt_from_billing?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Enterprise_Users_Sum_Fields = {
  __typename?: 'enterprise_users_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "enterprise_users" */
export enum Enterprise_Users_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  IsExemptFromBilling = 'is_exempt_from_billing',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Enterprise_Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Enterprise_Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Enterprise_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Enterprise_Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Enterprise_Users_Var_Pop_Fields = {
  __typename?: 'enterprise_users_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Enterprise_Users_Var_Samp_Fields = {
  __typename?: 'enterprise_users_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Enterprise_Users_Variance_Fields = {
  __typename?: 'enterprise_users_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** This enum represents the growth experiments for Hasura. */
export type Experiments = {
  __typename?: 'experiments';
  comment: Scalars['String'];
  experiment: Scalars['String'];
};

/** aggregated selection of "experiments" */
export type Experiments_Aggregate = {
  __typename?: 'experiments_aggregate';
  aggregate?: Maybe<Experiments_Aggregate_Fields>;
  nodes: Array<Experiments>;
};

/** aggregate fields of "experiments" */
export type Experiments_Aggregate_Fields = {
  __typename?: 'experiments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Experiments_Max_Fields>;
  min?: Maybe<Experiments_Min_Fields>;
};

/** aggregate fields of "experiments" */
export type Experiments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Experiments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "experiments". All fields are combined with a logical 'AND'. */
export type Experiments_Bool_Exp = {
  _and?: InputMaybe<Array<Experiments_Bool_Exp>>;
  _not?: InputMaybe<Experiments_Bool_Exp>;
  _or?: InputMaybe<Array<Experiments_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  experiment?: InputMaybe<String_Comparison_Exp>;
};

/** Cohort of users for the specific experiment. */
export type Experiments_Cohort = {
  __typename?: 'experiments_cohort';
  activity?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  experiment: Experiments_Enum;
  /** An object relationship */
  parent_experiment: Experiments;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** Cohort of users for the specific experiment. */
export type Experiments_CohortActivityArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "experiments_cohort" */
export type Experiments_Cohort_Aggregate = {
  __typename?: 'experiments_cohort_aggregate';
  aggregate?: Maybe<Experiments_Cohort_Aggregate_Fields>;
  nodes: Array<Experiments_Cohort>;
};

export type Experiments_Cohort_Aggregate_Bool_Exp = {
  count?: InputMaybe<Experiments_Cohort_Aggregate_Bool_Exp_Count>;
};

export type Experiments_Cohort_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Experiments_Cohort_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Experiments_Cohort_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "experiments_cohort" */
export type Experiments_Cohort_Aggregate_Fields = {
  __typename?: 'experiments_cohort_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Experiments_Cohort_Max_Fields>;
  min?: Maybe<Experiments_Cohort_Min_Fields>;
};

/** aggregate fields of "experiments_cohort" */
export type Experiments_Cohort_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Experiments_Cohort_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "experiments_cohort" */
export type Experiments_Cohort_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Experiments_Cohort_Max_Order_By>;
  min?: InputMaybe<Experiments_Cohort_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Experiments_Cohort_Append_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "experiments_cohort" */
export type Experiments_Cohort_Arr_Rel_Insert_Input = {
  data: Array<Experiments_Cohort_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Experiments_Cohort_On_Conflict>;
};

/** Boolean expression to filter rows from the table "experiments_cohort". All fields are combined with a logical 'AND'. */
export type Experiments_Cohort_Bool_Exp = {
  _and?: InputMaybe<Array<Experiments_Cohort_Bool_Exp>>;
  _not?: InputMaybe<Experiments_Cohort_Bool_Exp>;
  _or?: InputMaybe<Array<Experiments_Cohort_Bool_Exp>>;
  activity?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  experiment?: InputMaybe<Experiments_Enum_Comparison_Exp>;
  parent_experiment?: InputMaybe<Experiments_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "experiments_cohort" */
export enum Experiments_Cohort_Constraint {
  /** unique or primary key constraint on columns "user_id", "experiment" */
  ExperimentsCohortPkey = 'experiments_cohort_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Experiments_Cohort_Delete_At_Path_Input = {
  activity?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Experiments_Cohort_Delete_Elem_Input = {
  activity?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Experiments_Cohort_Delete_Key_Input = {
  activity?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "experiments_cohort" */
export type Experiments_Cohort_Insert_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  experiment?: InputMaybe<Experiments_Enum>;
  parent_experiment?: InputMaybe<Experiments_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Experiments_Cohort_Max_Fields = {
  __typename?: 'experiments_cohort_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "experiments_cohort" */
export type Experiments_Cohort_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Experiments_Cohort_Min_Fields = {
  __typename?: 'experiments_cohort_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "experiments_cohort" */
export type Experiments_Cohort_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "experiments_cohort" */
export type Experiments_Cohort_Mutation_Response = {
  __typename?: 'experiments_cohort_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Experiments_Cohort>;
};

/** on_conflict condition type for table "experiments_cohort" */
export type Experiments_Cohort_On_Conflict = {
  constraint: Experiments_Cohort_Constraint;
  update_columns?: Array<Experiments_Cohort_Update_Column>;
  where?: InputMaybe<Experiments_Cohort_Bool_Exp>;
};

/** Ordering options when selecting data from "experiments_cohort". */
export type Experiments_Cohort_Order_By = {
  activity?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  experiment?: InputMaybe<Order_By>;
  parent_experiment?: InputMaybe<Experiments_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: experiments_cohort */
export type Experiments_Cohort_Pk_Columns_Input = {
  experiment: Experiments_Enum;
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Experiments_Cohort_Prepend_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "experiments_cohort" */
export enum Experiments_Cohort_Select_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Experiment = 'experiment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "experiments_cohort" */
export type Experiments_Cohort_Set_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  experiment?: InputMaybe<Experiments_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "experiments_cohort" */
export type Experiments_Cohort_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Experiments_Cohort_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Experiments_Cohort_Stream_Cursor_Value_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  experiment?: InputMaybe<Experiments_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "experiments_cohort" */
export enum Experiments_Cohort_Update_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Experiment = 'experiment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Experiments_Cohort_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Experiments_Cohort_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Experiments_Cohort_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Experiments_Cohort_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Experiments_Cohort_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Experiments_Cohort_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Experiments_Cohort_Set_Input>;
  /** filter the rows which have to be updated */
  where: Experiments_Cohort_Bool_Exp;
};

/** Configuration settings for growth experiments. */
export type Experiments_Config = {
  __typename?: 'experiments_config';
  /** An array relationship */
  config_cohort_users: Array<Experiments_Cohort>;
  /** An aggregate relationship */
  config_cohort_users_aggregate: Experiments_Cohort_Aggregate;
  created_at: Scalars['timestamptz'];
  experiment: Experiments_Enum;
  metadata?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  parent_experiment: Experiments;
  rollout_percentage: Scalars['Int'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** Configuration settings for growth experiments. */
export type Experiments_ConfigConfig_Cohort_UsersArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Cohort_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Cohort_Order_By>>;
  where?: InputMaybe<Experiments_Cohort_Bool_Exp>;
};

/** Configuration settings for growth experiments. */
export type Experiments_ConfigConfig_Cohort_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Cohort_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Cohort_Order_By>>;
  where?: InputMaybe<Experiments_Cohort_Bool_Exp>;
};

/** Configuration settings for growth experiments. */
export type Experiments_ConfigMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "experiments_config" */
export type Experiments_Config_Aggregate = {
  __typename?: 'experiments_config_aggregate';
  aggregate?: Maybe<Experiments_Config_Aggregate_Fields>;
  nodes: Array<Experiments_Config>;
};

/** aggregate fields of "experiments_config" */
export type Experiments_Config_Aggregate_Fields = {
  __typename?: 'experiments_config_aggregate_fields';
  avg?: Maybe<Experiments_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Experiments_Config_Max_Fields>;
  min?: Maybe<Experiments_Config_Min_Fields>;
  stddev?: Maybe<Experiments_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Experiments_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Experiments_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Experiments_Config_Sum_Fields>;
  var_pop?: Maybe<Experiments_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Experiments_Config_Var_Samp_Fields>;
  variance?: Maybe<Experiments_Config_Variance_Fields>;
};

/** aggregate fields of "experiments_config" */
export type Experiments_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Experiments_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Experiments_Config_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Experiments_Config_Avg_Fields = {
  __typename?: 'experiments_config_avg_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "experiments_config". All fields are combined with a logical 'AND'. */
export type Experiments_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Experiments_Config_Bool_Exp>>;
  _not?: InputMaybe<Experiments_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Experiments_Config_Bool_Exp>>;
  config_cohort_users?: InputMaybe<Experiments_Cohort_Bool_Exp>;
  config_cohort_users_aggregate?: InputMaybe<
    Experiments_Cohort_Aggregate_Bool_Exp
  >;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  experiment?: InputMaybe<Experiments_Enum_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  parent_experiment?: InputMaybe<Experiments_Bool_Exp>;
  rollout_percentage?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "experiments_config" */
export enum Experiments_Config_Constraint {
  /** unique or primary key constraint on columns "experiment" */
  ExperimentsConfigPkey = 'experiments_config_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Experiments_Config_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Experiments_Config_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Experiments_Config_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "experiments_config" */
export type Experiments_Config_Inc_Input = {
  rollout_percentage?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "experiments_config" */
export type Experiments_Config_Insert_Input = {
  config_cohort_users?: InputMaybe<Experiments_Cohort_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  experiment?: InputMaybe<Experiments_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  parent_experiment?: InputMaybe<Experiments_Obj_Rel_Insert_Input>;
  rollout_percentage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Experiments_Config_Max_Fields = {
  __typename?: 'experiments_config_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  rollout_percentage?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Experiments_Config_Min_Fields = {
  __typename?: 'experiments_config_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  rollout_percentage?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "experiments_config" */
export type Experiments_Config_Mutation_Response = {
  __typename?: 'experiments_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Experiments_Config>;
};

/** on_conflict condition type for table "experiments_config" */
export type Experiments_Config_On_Conflict = {
  constraint: Experiments_Config_Constraint;
  update_columns?: Array<Experiments_Config_Update_Column>;
  where?: InputMaybe<Experiments_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "experiments_config". */
export type Experiments_Config_Order_By = {
  config_cohort_users_aggregate?: InputMaybe<
    Experiments_Cohort_Aggregate_Order_By
  >;
  created_at?: InputMaybe<Order_By>;
  experiment?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  parent_experiment?: InputMaybe<Experiments_Order_By>;
  rollout_percentage?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: experiments_config */
export type Experiments_Config_Pk_Columns_Input = {
  experiment: Experiments_Enum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Experiments_Config_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "experiments_config" */
export enum Experiments_Config_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Experiment = 'experiment',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RolloutPercentage = 'rollout_percentage',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "experiments_config" */
export type Experiments_Config_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  experiment?: InputMaybe<Experiments_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  rollout_percentage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Experiments_Config_Stddev_Fields = {
  __typename?: 'experiments_config_stddev_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Experiments_Config_Stddev_Pop_Fields = {
  __typename?: 'experiments_config_stddev_pop_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Experiments_Config_Stddev_Samp_Fields = {
  __typename?: 'experiments_config_stddev_samp_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "experiments_config" */
export type Experiments_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Experiments_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Experiments_Config_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  experiment?: InputMaybe<Experiments_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  rollout_percentage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Experiments_Config_Sum_Fields = {
  __typename?: 'experiments_config_sum_fields';
  rollout_percentage?: Maybe<Scalars['Int']>;
};

/** update columns of table "experiments_config" */
export enum Experiments_Config_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Experiment = 'experiment',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RolloutPercentage = 'rollout_percentage',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Experiments_Config_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Experiments_Config_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Experiments_Config_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Experiments_Config_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Experiments_Config_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Experiments_Config_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Experiments_Config_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Experiments_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Experiments_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Experiments_Config_Var_Pop_Fields = {
  __typename?: 'experiments_config_var_pop_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Experiments_Config_Var_Samp_Fields = {
  __typename?: 'experiments_config_var_samp_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Experiments_Config_Variance_Fields = {
  __typename?: 'experiments_config_variance_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "experiments" */
export enum Experiments_Constraint {
  /** unique or primary key constraint on columns "experiment" */
  ExperimentsPkey = 'experiments_pkey',
}

export enum Experiments_Enum {
  /** Console Onboarding Wizard v1 for new users */
  ConsoleOnboardingWizardV1 = 'console_onboarding_wizard_v1',
}

/** Boolean expression to compare columns of type "experiments_enum". All fields are combined with logical 'AND'. */
export type Experiments_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Experiments_Enum>;
  _in?: InputMaybe<Array<Experiments_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Experiments_Enum>;
  _nin?: InputMaybe<Array<Experiments_Enum>>;
};

/** input type for inserting data into table "experiments" */
export type Experiments_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  experiment?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Experiments_Max_Fields = {
  __typename?: 'experiments_max_fields';
  comment?: Maybe<Scalars['String']>;
  experiment?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Experiments_Min_Fields = {
  __typename?: 'experiments_min_fields';
  comment?: Maybe<Scalars['String']>;
  experiment?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "experiments" */
export type Experiments_Mutation_Response = {
  __typename?: 'experiments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Experiments>;
};

/** input type for inserting object relation for remote table "experiments" */
export type Experiments_Obj_Rel_Insert_Input = {
  data: Experiments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Experiments_On_Conflict>;
};

/** on_conflict condition type for table "experiments" */
export type Experiments_On_Conflict = {
  constraint: Experiments_Constraint;
  update_columns?: Array<Experiments_Update_Column>;
  where?: InputMaybe<Experiments_Bool_Exp>;
};

/** Ordering options when selecting data from "experiments". */
export type Experiments_Order_By = {
  comment?: InputMaybe<Order_By>;
  experiment?: InputMaybe<Order_By>;
};

/** primary key columns input for table: experiments */
export type Experiments_Pk_Columns_Input = {
  experiment: Scalars['String'];
};

/** select columns of table "experiments" */
export enum Experiments_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Experiment = 'experiment',
}

/** input type for updating data in table "experiments" */
export type Experiments_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  experiment?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "experiments" */
export type Experiments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Experiments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Experiments_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  experiment?: InputMaybe<Scalars['String']>;
};

/** update columns of table "experiments" */
export enum Experiments_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Experiment = 'experiment',
}

export type Experiments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Experiments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Experiments_Bool_Exp;
};

/** Enums representing features for access control */
export type Feature = {
  __typename?: 'feature';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** Table defining what features a user has access to */
export type Feature_Access = {
  __typename?: 'feature_access';
  email: Scalars['String'];
  feature: Feature_Enum;
  granted_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "feature_access" */
export type Feature_Access_Aggregate = {
  __typename?: 'feature_access_aggregate';
  aggregate?: Maybe<Feature_Access_Aggregate_Fields>;
  nodes: Array<Feature_Access>;
};

export type Feature_Access_Aggregate_Bool_Exp = {
  count?: InputMaybe<Feature_Access_Aggregate_Bool_Exp_Count>;
};

export type Feature_Access_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Feature_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Feature_Access_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "feature_access" */
export type Feature_Access_Aggregate_Fields = {
  __typename?: 'feature_access_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Feature_Access_Max_Fields>;
  min?: Maybe<Feature_Access_Min_Fields>;
};

/** aggregate fields of "feature_access" */
export type Feature_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feature_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "feature_access" */
export type Feature_Access_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Feature_Access_Max_Order_By>;
  min?: InputMaybe<Feature_Access_Min_Order_By>;
};

/** input type for inserting array relation for remote table "feature_access" */
export type Feature_Access_Arr_Rel_Insert_Input = {
  data: Array<Feature_Access_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Feature_Access_On_Conflict>;
};

/** Boolean expression to filter rows from the table "feature_access". All fields are combined with a logical 'AND'. */
export type Feature_Access_Bool_Exp = {
  _and?: InputMaybe<Array<Feature_Access_Bool_Exp>>;
  _not?: InputMaybe<Feature_Access_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Access_Bool_Exp>>;
  email?: InputMaybe<String_Comparison_Exp>;
  feature?: InputMaybe<Feature_Enum_Comparison_Exp>;
  granted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "feature_access" */
export enum Feature_Access_Constraint {
  /** unique or primary key constraint on columns "feature", "email" */
  FeatureAccessPkey = 'feature_access_pkey',
}

/** input type for inserting data into table "feature_access" */
export type Feature_Access_Insert_Input = {
  email?: InputMaybe<Scalars['String']>;
  feature?: InputMaybe<Feature_Enum>;
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Feature_Access_Max_Fields = {
  __typename?: 'feature_access_max_fields';
  email?: Maybe<Scalars['String']>;
  granted_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "feature_access" */
export type Feature_Access_Max_Order_By = {
  email?: InputMaybe<Order_By>;
  granted_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Feature_Access_Min_Fields = {
  __typename?: 'feature_access_min_fields';
  email?: Maybe<Scalars['String']>;
  granted_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "feature_access" */
export type Feature_Access_Min_Order_By = {
  email?: InputMaybe<Order_By>;
  granted_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "feature_access" */
export type Feature_Access_Mutation_Response = {
  __typename?: 'feature_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature_Access>;
};

/** on_conflict condition type for table "feature_access" */
export type Feature_Access_On_Conflict = {
  constraint: Feature_Access_Constraint;
  update_columns?: Array<Feature_Access_Update_Column>;
  where?: InputMaybe<Feature_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "feature_access". */
export type Feature_Access_Order_By = {
  email?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  granted_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: feature_access */
export type Feature_Access_Pk_Columns_Input = {
  email: Scalars['String'];
  feature: Feature_Enum;
};

/** select columns of table "feature_access" */
export enum Feature_Access_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Feature = 'feature',
  /** column name */
  GrantedAt = 'granted_at',
}

/** input type for updating data in table "feature_access" */
export type Feature_Access_Set_Input = {
  email?: InputMaybe<Scalars['String']>;
  feature?: InputMaybe<Feature_Enum>;
  granted_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "feature_access" */
export type Feature_Access_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feature_Access_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Access_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']>;
  feature?: InputMaybe<Feature_Enum>;
  granted_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "feature_access" */
export enum Feature_Access_Update_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Feature = 'feature',
  /** column name */
  GrantedAt = 'granted_at',
}

export type Feature_Access_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Access_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Access_Bool_Exp;
};

/** aggregated selection of "feature" */
export type Feature_Aggregate = {
  __typename?: 'feature_aggregate';
  aggregate?: Maybe<Feature_Aggregate_Fields>;
  nodes: Array<Feature>;
};

/** aggregate fields of "feature" */
export type Feature_Aggregate_Fields = {
  __typename?: 'feature_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Feature_Max_Fields>;
  min?: Maybe<Feature_Min_Fields>;
};

/** aggregate fields of "feature" */
export type Feature_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feature_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "feature". All fields are combined with a logical 'AND'. */
export type Feature_Bool_Exp = {
  _and?: InputMaybe<Array<Feature_Bool_Exp>>;
  _not?: InputMaybe<Feature_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** Configuration settings for feature */
export type Feature_Config = {
  __typename?: 'feature_config';
  created_at: Scalars['timestamptz'];
  feature: Feature_Enum;
  metadata?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  parent_feature: Feature;
  rollout_percentage: Scalars['Int'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** Configuration settings for feature */
export type Feature_ConfigMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "feature_config" */
export type Feature_Config_Aggregate = {
  __typename?: 'feature_config_aggregate';
  aggregate?: Maybe<Feature_Config_Aggregate_Fields>;
  nodes: Array<Feature_Config>;
};

/** aggregate fields of "feature_config" */
export type Feature_Config_Aggregate_Fields = {
  __typename?: 'feature_config_aggregate_fields';
  avg?: Maybe<Feature_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Feature_Config_Max_Fields>;
  min?: Maybe<Feature_Config_Min_Fields>;
  stddev?: Maybe<Feature_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Feature_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Feature_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Feature_Config_Sum_Fields>;
  var_pop?: Maybe<Feature_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Feature_Config_Var_Samp_Fields>;
  variance?: Maybe<Feature_Config_Variance_Fields>;
};

/** aggregate fields of "feature_config" */
export type Feature_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Feature_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Feature_Config_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Feature_Config_Avg_Fields = {
  __typename?: 'feature_config_avg_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "feature_config". All fields are combined with a logical 'AND'. */
export type Feature_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Feature_Config_Bool_Exp>>;
  _not?: InputMaybe<Feature_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Feature_Config_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feature?: InputMaybe<Feature_Enum_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  parent_feature?: InputMaybe<Feature_Bool_Exp>;
  rollout_percentage?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "feature_config" */
export enum Feature_Config_Constraint {
  /** unique or primary key constraint on columns "feature" */
  FeatureConfigPkey = 'feature_config_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Feature_Config_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Feature_Config_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Feature_Config_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "feature_config" */
export type Feature_Config_Inc_Input = {
  rollout_percentage?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "feature_config" */
export type Feature_Config_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<Feature_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  parent_feature?: InputMaybe<Feature_Obj_Rel_Insert_Input>;
  rollout_percentage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Feature_Config_Max_Fields = {
  __typename?: 'feature_config_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  rollout_percentage?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Feature_Config_Min_Fields = {
  __typename?: 'feature_config_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  rollout_percentage?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "feature_config" */
export type Feature_Config_Mutation_Response = {
  __typename?: 'feature_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature_Config>;
};

/** on_conflict condition type for table "feature_config" */
export type Feature_Config_On_Conflict = {
  constraint: Feature_Config_Constraint;
  update_columns?: Array<Feature_Config_Update_Column>;
  where?: InputMaybe<Feature_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "feature_config". */
export type Feature_Config_Order_By = {
  created_at?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  parent_feature?: InputMaybe<Feature_Order_By>;
  rollout_percentage?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feature_config */
export type Feature_Config_Pk_Columns_Input = {
  feature: Feature_Enum;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Feature_Config_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "feature_config" */
export enum Feature_Config_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feature = 'feature',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RolloutPercentage = 'rollout_percentage',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "feature_config" */
export type Feature_Config_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<Feature_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  rollout_percentage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Feature_Config_Stddev_Fields = {
  __typename?: 'feature_config_stddev_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Feature_Config_Stddev_Pop_Fields = {
  __typename?: 'feature_config_stddev_pop_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Feature_Config_Stddev_Samp_Fields = {
  __typename?: 'feature_config_stddev_samp_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "feature_config" */
export type Feature_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feature_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Config_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  feature?: InputMaybe<Feature_Enum>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  rollout_percentage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Feature_Config_Sum_Fields = {
  __typename?: 'feature_config_sum_fields';
  rollout_percentage?: Maybe<Scalars['Int']>;
};

/** update columns of table "feature_config" */
export enum Feature_Config_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Feature = 'feature',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RolloutPercentage = 'rollout_percentage',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Feature_Config_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Feature_Config_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Feature_Config_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Feature_Config_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Feature_Config_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Feature_Config_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Feature_Config_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Feature_Config_Var_Pop_Fields = {
  __typename?: 'feature_config_var_pop_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Feature_Config_Var_Samp_Fields = {
  __typename?: 'feature_config_var_samp_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Feature_Config_Variance_Fields = {
  __typename?: 'feature_config_variance_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "feature" */
export enum Feature_Constraint {
  /** unique or primary key constraint on columns "name" */
  FeaturePkey = 'feature_pkey',
}

export enum Feature_Enum {
  /** Collect additional billing information to enable tax calculation */
  Avalara = 'Avalara',
  /** Allows user to create Cloud Dedicated VPC */
  CloudDedicatedVpc = 'CloudDedicatedVPC',
  /** Allows user to create projects with plans: cloud_free_v2, cloud_shared */
  CloudNewPlans = 'CloudNewPlans',
  /** Allows user to create projects with plans: cloud_free, cloud_payg */
  CloudUser = 'CloudUser',
  /** Console Onboarding Wizard for new users */
  ConsoleOnboardingWizard = 'ConsoleOnboardingWizard',
  /** Allows user to configure Datadog api key per project */
  DatadogIntegration = 'DatadogIntegration',
  /** Allows users to create projects on Google Cloud Platform */
  GcpSupport = 'GCPSupport',
  /** Allows user to automatically deploy metadata/migrations to the project from github repo */
  GithubIntegration = 'GithubIntegration',
  /** Allows users to have access to GraphQL Schema Registry */
  GraphQlSchemaRegistry = 'GraphQLSchemaRegistry',
  /** Neon DB Integration through Hasura Console */
  NeonDatabaseIntegration = 'NeonDatabaseIntegration',
  /** Allows user to create Hasura Pro Project */
  ProUser = 'ProUser',
  /** Allows user to configure schema/table access control */
  SchemaTableAcl = 'SchemaTableACL',
  /** Allows user to update Hasura Project to v2.0 */
  V1V2Migration = 'V1V2Migration',
}

/** Boolean expression to compare columns of type "feature_enum". All fields are combined with logical 'AND'. */
export type Feature_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Feature_Enum>;
  _in?: InputMaybe<Array<Feature_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Feature_Enum>;
  _nin?: InputMaybe<Array<Feature_Enum>>;
};

/** input type for inserting data into table "feature" */
export type Feature_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Feature_Max_Fields = {
  __typename?: 'feature_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Feature_Min_Fields = {
  __typename?: 'feature_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "feature" */
export type Feature_Mutation_Response = {
  __typename?: 'feature_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Feature>;
};

/** input type for inserting object relation for remote table "feature" */
export type Feature_Obj_Rel_Insert_Input = {
  data: Feature_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Feature_On_Conflict>;
};

/** on_conflict condition type for table "feature" */
export type Feature_On_Conflict = {
  constraint: Feature_Constraint;
  update_columns?: Array<Feature_Update_Column>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

/** Ordering options when selecting data from "feature". */
export type Feature_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feature */
export type Feature_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "feature" */
export enum Feature_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "feature" */
export type Feature_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "feature" */
export type Feature_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Feature_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feature_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "feature" */
export enum Feature_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type Feature_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Feature_Set_Input>;
  /** filter the rows which have to be updated */
  where: Feature_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** Represents ami's generated for gateway service */
export type Gateway_Ami = {
  __typename?: 'gateway_ami';
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  commit_hash: Scalars['String'];
  created_at: Scalars['timestamptz'];
  region: Scalars['String'];
  remarks: Scalars['String'];
};

/** aggregated selection of "gateway_ami" */
export type Gateway_Ami_Aggregate = {
  __typename?: 'gateway_ami_aggregate';
  aggregate?: Maybe<Gateway_Ami_Aggregate_Fields>;
  nodes: Array<Gateway_Ami>;
};

/** aggregate fields of "gateway_ami" */
export type Gateway_Ami_Aggregate_Fields = {
  __typename?: 'gateway_ami_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gateway_Ami_Max_Fields>;
  min?: Maybe<Gateway_Ami_Min_Fields>;
};

/** aggregate fields of "gateway_ami" */
export type Gateway_Ami_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gateway_Ami_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gateway_ami". All fields are combined with a logical 'AND'. */
export type Gateway_Ami_Bool_Exp = {
  _and?: InputMaybe<Array<Gateway_Ami_Bool_Exp>>;
  _not?: InputMaybe<Gateway_Ami_Bool_Exp>;
  _or?: InputMaybe<Array<Gateway_Ami_Bool_Exp>>;
  ami_id?: InputMaybe<String_Comparison_Exp>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  commit_hash?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gateway_ami" */
export enum Gateway_Ami_Constraint {
  /** unique or primary key constraint on columns "region", "cloud", "ami_id" */
  GatewayAmiPkey = 'gateway_ami_pkey',
}

/** input type for inserting data into table "gateway_ami" */
export type Gateway_Ami_Insert_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  commit_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  region?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Gateway_Ami_Max_Fields = {
  __typename?: 'gateway_ami_max_fields';
  ami_id?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  commit_hash?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  region?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gateway_Ami_Min_Fields = {
  __typename?: 'gateway_ami_min_fields';
  ami_id?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  commit_hash?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  region?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "gateway_ami" */
export type Gateway_Ami_Mutation_Response = {
  __typename?: 'gateway_ami_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gateway_Ami>;
};

/** on_conflict condition type for table "gateway_ami" */
export type Gateway_Ami_On_Conflict = {
  constraint: Gateway_Ami_Constraint;
  update_columns?: Array<Gateway_Ami_Update_Column>;
  where?: InputMaybe<Gateway_Ami_Bool_Exp>;
};

/** Ordering options when selecting data from "gateway_ami". */
export type Gateway_Ami_Order_By = {
  ami_id?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  commit_hash?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gateway_ami */
export type Gateway_Ami_Pk_Columns_Input = {
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  region: Scalars['String'];
};

/** select columns of table "gateway_ami" */
export enum Gateway_Ami_Select_Column {
  /** column name */
  AmiId = 'ami_id',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CommitHash = 'commit_hash',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Region = 'region',
  /** column name */
  Remarks = 'remarks',
}

/** input type for updating data in table "gateway_ami" */
export type Gateway_Ami_Set_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  commit_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  region?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "gateway_ami" */
export type Gateway_Ami_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gateway_Ami_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gateway_Ami_Stream_Cursor_Value_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  commit_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  region?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

/** update columns of table "gateway_ami" */
export enum Gateway_Ami_Update_Column {
  /** column name */
  AmiId = 'ami_id',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CommitHash = 'commit_hash',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Region = 'region',
  /** column name */
  Remarks = 'remarks',
}

export type Gateway_Ami_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gateway_Ami_Set_Input>;
  /** filter the rows which have to be updated */
  where: Gateway_Ami_Bool_Exp;
};

/** Set of gateway workers in a specific region */
export type Gateway_Cluster = {
  __typename?: 'gateway_cluster';
  ami_id?: Maybe<Scalars['String']>;
  cloud: Scalars['String'];
  created_at: Scalars['timestamptz'];
  disable_infra_auto_apply: Scalars['Boolean'];
  fqdn?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  infra_status?: Maybe<Infra_Status_Enum>;
  input_variables?: Maybe<Scalars['jsonb']>;
  output_variables?: Maybe<Scalars['jsonb']>;
  region: Scalars['String'];
  /** An object relationship */
  region_info?: Maybe<Region>;
  slug: Scalars['String'];
  status: Scalars['String'];
  /** An array relationship */
  workers: Array<Gateway_Worker>;
  /** An aggregate relationship */
  workers_aggregate: Gateway_Worker_Aggregate;
};

/** Set of gateway workers in a specific region */
export type Gateway_ClusterInput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Set of gateway workers in a specific region */
export type Gateway_ClusterOutput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Set of gateway workers in a specific region */
export type Gateway_ClusterWorkersArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Worker_Order_By>>;
  where?: InputMaybe<Gateway_Worker_Bool_Exp>;
};

/** Set of gateway workers in a specific region */
export type Gateway_ClusterWorkers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Worker_Order_By>>;
  where?: InputMaybe<Gateway_Worker_Bool_Exp>;
};

/** aggregated selection of "gateway_cluster" */
export type Gateway_Cluster_Aggregate = {
  __typename?: 'gateway_cluster_aggregate';
  aggregate?: Maybe<Gateway_Cluster_Aggregate_Fields>;
  nodes: Array<Gateway_Cluster>;
};

/** aggregate fields of "gateway_cluster" */
export type Gateway_Cluster_Aggregate_Fields = {
  __typename?: 'gateway_cluster_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gateway_Cluster_Max_Fields>;
  min?: Maybe<Gateway_Cluster_Min_Fields>;
};

/** aggregate fields of "gateway_cluster" */
export type Gateway_Cluster_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gateway_Cluster_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Gateway_Cluster_Append_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "gateway_cluster". All fields are combined with a logical 'AND'. */
export type Gateway_Cluster_Bool_Exp = {
  _and?: InputMaybe<Array<Gateway_Cluster_Bool_Exp>>;
  _not?: InputMaybe<Gateway_Cluster_Bool_Exp>;
  _or?: InputMaybe<Array<Gateway_Cluster_Bool_Exp>>;
  ami_id?: InputMaybe<String_Comparison_Exp>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  disable_infra_auto_apply?: InputMaybe<Boolean_Comparison_Exp>;
  fqdn?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  infra_status?: InputMaybe<Infra_Status_Enum_Comparison_Exp>;
  input_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  output_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  region_info?: InputMaybe<Region_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  workers?: InputMaybe<Gateway_Worker_Bool_Exp>;
  workers_aggregate?: InputMaybe<Gateway_Worker_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "gateway_cluster" */
export enum Gateway_Cluster_Constraint {
  /** unique or primary key constraint on columns "id" */
  GatewayClusterPkey = 'gateway_cluster_pkey',
  /** unique or primary key constraint on columns "slug" */
  GatewayClusterSlugKey = 'gateway_cluster_slug_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Gateway_Cluster_Delete_At_Path_Input = {
  input_variables?: InputMaybe<Array<Scalars['String']>>;
  output_variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Gateway_Cluster_Delete_Elem_Input = {
  input_variables?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Gateway_Cluster_Delete_Key_Input = {
  input_variables?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "gateway_cluster" */
export type Gateway_Cluster_Insert_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  region_info?: InputMaybe<Region_Obj_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  workers?: InputMaybe<Gateway_Worker_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Gateway_Cluster_Max_Fields = {
  __typename?: 'gateway_cluster_max_fields';
  ami_id?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Gateway_Cluster_Min_Fields = {
  __typename?: 'gateway_cluster_min_fields';
  ami_id?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "gateway_cluster" */
export type Gateway_Cluster_Mutation_Response = {
  __typename?: 'gateway_cluster_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gateway_Cluster>;
};

/** input type for inserting object relation for remote table "gateway_cluster" */
export type Gateway_Cluster_Obj_Rel_Insert_Input = {
  data: Gateway_Cluster_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Gateway_Cluster_On_Conflict>;
};

/** on_conflict condition type for table "gateway_cluster" */
export type Gateway_Cluster_On_Conflict = {
  constraint: Gateway_Cluster_Constraint;
  update_columns?: Array<Gateway_Cluster_Update_Column>;
  where?: InputMaybe<Gateway_Cluster_Bool_Exp>;
};

/** Ordering options when selecting data from "gateway_cluster". */
export type Gateway_Cluster_Order_By = {
  ami_id?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  disable_infra_auto_apply?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  infra_status?: InputMaybe<Order_By>;
  input_variables?: InputMaybe<Order_By>;
  output_variables?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  region_info?: InputMaybe<Region_Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  workers_aggregate?: InputMaybe<Gateway_Worker_Aggregate_Order_By>;
};

/** primary key columns input for table: gateway_cluster */
export type Gateway_Cluster_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Gateway_Cluster_Prepend_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "gateway_cluster" */
export enum Gateway_Cluster_Select_Column {
  /** column name */
  AmiId = 'ami_id',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "gateway_cluster" */
export type Gateway_Cluster_Set_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "gateway_cluster" */
export type Gateway_Cluster_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gateway_Cluster_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gateway_Cluster_Stream_Cursor_Value_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "gateway_cluster_tenants" */
export type Gateway_Cluster_Tenants = {
  __typename?: 'gateway_cluster_tenants';
  cert?: Maybe<Scalars['String']>;
  fqdn?: Maybe<Scalars['String']>;
  gateway_cluster_id?: Maybe<Scalars['uuid']>;
  plan_name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  project_name?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  workers?: Maybe<Array<Scalars['json']>>;
};

/** aggregated selection of "gateway_cluster_tenants" */
export type Gateway_Cluster_Tenants_Aggregate = {
  __typename?: 'gateway_cluster_tenants_aggregate';
  aggregate?: Maybe<Gateway_Cluster_Tenants_Aggregate_Fields>;
  nodes: Array<Gateway_Cluster_Tenants>;
};

/** aggregate fields of "gateway_cluster_tenants" */
export type Gateway_Cluster_Tenants_Aggregate_Fields = {
  __typename?: 'gateway_cluster_tenants_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gateway_Cluster_Tenants_Max_Fields>;
  min?: Maybe<Gateway_Cluster_Tenants_Min_Fields>;
};

/** aggregate fields of "gateway_cluster_tenants" */
export type Gateway_Cluster_Tenants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gateway_Cluster_Tenants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "gateway_cluster_tenants". All fields are combined with a logical 'AND'. */
export type Gateway_Cluster_Tenants_Bool_Exp = {
  _and?: InputMaybe<Array<Gateway_Cluster_Tenants_Bool_Exp>>;
  _not?: InputMaybe<Gateway_Cluster_Tenants_Bool_Exp>;
  _or?: InputMaybe<Array<Gateway_Cluster_Tenants_Bool_Exp>>;
  cert?: InputMaybe<String_Comparison_Exp>;
  fqdn?: InputMaybe<String_Comparison_Exp>;
  gateway_cluster_id?: InputMaybe<Uuid_Comparison_Exp>;
  plan_name?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_name?: InputMaybe<String_Comparison_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  workers?: InputMaybe<Json_Array_Comparison_Exp>;
};

/** aggregate max on columns */
export type Gateway_Cluster_Tenants_Max_Fields = {
  __typename?: 'gateway_cluster_tenants_max_fields';
  cert?: Maybe<Scalars['String']>;
  fqdn?: Maybe<Scalars['String']>;
  gateway_cluster_id?: Maybe<Scalars['uuid']>;
  plan_name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  project_name?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  workers?: Maybe<Array<Scalars['json']>>;
};

/** aggregate min on columns */
export type Gateway_Cluster_Tenants_Min_Fields = {
  __typename?: 'gateway_cluster_tenants_min_fields';
  cert?: Maybe<Scalars['String']>;
  fqdn?: Maybe<Scalars['String']>;
  gateway_cluster_id?: Maybe<Scalars['uuid']>;
  plan_name?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  project_name?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  workers?: Maybe<Array<Scalars['json']>>;
};

/** Ordering options when selecting data from "gateway_cluster_tenants". */
export type Gateway_Cluster_Tenants_Order_By = {
  cert?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  gateway_cluster_id?: InputMaybe<Order_By>;
  plan_name?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  project_name?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  workers?: InputMaybe<Order_By>;
};

/** select columns of table "gateway_cluster_tenants" */
export enum Gateway_Cluster_Tenants_Select_Column {
  /** column name */
  Cert = 'cert',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  GatewayClusterId = 'gateway_cluster_id',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  Workers = 'workers',
}

/** Streaming cursor of the table "gateway_cluster_tenants" */
export type Gateway_Cluster_Tenants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gateway_Cluster_Tenants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gateway_Cluster_Tenants_Stream_Cursor_Value_Input = {
  cert?: InputMaybe<Scalars['String']>;
  fqdn?: InputMaybe<Scalars['String']>;
  gateway_cluster_id?: InputMaybe<Scalars['uuid']>;
  plan_name?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  workers?: InputMaybe<Array<Scalars['json']>>;
};

/** update columns of table "gateway_cluster" */
export enum Gateway_Cluster_Update_Column {
  /** column name */
  AmiId = 'ami_id',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
}

export type Gateway_Cluster_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Gateway_Cluster_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Gateway_Cluster_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Gateway_Cluster_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Gateway_Cluster_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Gateway_Cluster_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gateway_Cluster_Set_Input>;
  /** filter the rows which have to be updated */
  where: Gateway_Cluster_Bool_Exp;
};

/** Gateway worker in a region that routes traffic to multiple Hasura Clusters */
export type Gateway_Worker = {
  __typename?: 'gateway_worker';
  cloud: Scalars['String'];
  /** An object relationship */
  cluster: Gateway_Cluster;
  cluster_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  region: Scalars['String'];
  /** An object relationship */
  region_info?: Maybe<Region>;
  slug: Scalars['String'];
  status: Scalars['String'];
  zone: Scalars['String'];
};

/** aggregated selection of "gateway_worker" */
export type Gateway_Worker_Aggregate = {
  __typename?: 'gateway_worker_aggregate';
  aggregate?: Maybe<Gateway_Worker_Aggregate_Fields>;
  nodes: Array<Gateway_Worker>;
};

export type Gateway_Worker_Aggregate_Bool_Exp = {
  count?: InputMaybe<Gateway_Worker_Aggregate_Bool_Exp_Count>;
};

export type Gateway_Worker_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Gateway_Worker_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Gateway_Worker_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "gateway_worker" */
export type Gateway_Worker_Aggregate_Fields = {
  __typename?: 'gateway_worker_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Gateway_Worker_Max_Fields>;
  min?: Maybe<Gateway_Worker_Min_Fields>;
};

/** aggregate fields of "gateway_worker" */
export type Gateway_Worker_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Gateway_Worker_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gateway_worker" */
export type Gateway_Worker_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Gateway_Worker_Max_Order_By>;
  min?: InputMaybe<Gateway_Worker_Min_Order_By>;
};

/** input type for inserting array relation for remote table "gateway_worker" */
export type Gateway_Worker_Arr_Rel_Insert_Input = {
  data: Array<Gateway_Worker_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Gateway_Worker_On_Conflict>;
};

/** Boolean expression to filter rows from the table "gateway_worker". All fields are combined with a logical 'AND'. */
export type Gateway_Worker_Bool_Exp = {
  _and?: InputMaybe<Array<Gateway_Worker_Bool_Exp>>;
  _not?: InputMaybe<Gateway_Worker_Bool_Exp>;
  _or?: InputMaybe<Array<Gateway_Worker_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  cluster?: InputMaybe<Gateway_Cluster_Bool_Exp>;
  cluster_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  region_info?: InputMaybe<Region_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  zone?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "gateway_worker" */
export enum Gateway_Worker_Constraint {
  /** unique or primary key constraint on columns "id" */
  GatewayWorkerPkey = 'gateway_worker_pkey',
  /** unique or primary key constraint on columns "slug" */
  GatewayWorkerSlugKey = 'gateway_worker_slug_key',
}

/** input type for inserting data into table "gateway_worker" */
export type Gateway_Worker_Insert_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  cluster?: InputMaybe<Gateway_Cluster_Obj_Rel_Insert_Input>;
  cluster_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  region_info?: InputMaybe<Region_Obj_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  zone?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Gateway_Worker_Max_Fields = {
  __typename?: 'gateway_worker_max_fields';
  cloud?: Maybe<Scalars['String']>;
  cluster_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "gateway_worker" */
export type Gateway_Worker_Max_Order_By = {
  cloud?: InputMaybe<Order_By>;
  cluster_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Gateway_Worker_Min_Fields = {
  __typename?: 'gateway_worker_min_fields';
  cloud?: Maybe<Scalars['String']>;
  cluster_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  zone?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "gateway_worker" */
export type Gateway_Worker_Min_Order_By = {
  cloud?: InputMaybe<Order_By>;
  cluster_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "gateway_worker" */
export type Gateway_Worker_Mutation_Response = {
  __typename?: 'gateway_worker_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Gateway_Worker>;
};

/** on_conflict condition type for table "gateway_worker" */
export type Gateway_Worker_On_Conflict = {
  constraint: Gateway_Worker_Constraint;
  update_columns?: Array<Gateway_Worker_Update_Column>;
  where?: InputMaybe<Gateway_Worker_Bool_Exp>;
};

/** Ordering options when selecting data from "gateway_worker". */
export type Gateway_Worker_Order_By = {
  cloud?: InputMaybe<Order_By>;
  cluster?: InputMaybe<Gateway_Cluster_Order_By>;
  cluster_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  region_info?: InputMaybe<Region_Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** primary key columns input for table: gateway_worker */
export type Gateway_Worker_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "gateway_worker" */
export enum Gateway_Worker_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  Zone = 'zone',
}

/** input type for updating data in table "gateway_worker" */
export type Gateway_Worker_Set_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  cluster_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  zone?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "gateway_worker" */
export type Gateway_Worker_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Gateway_Worker_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Gateway_Worker_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  cluster_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  zone?: InputMaybe<Scalars['String']>;
};

/** update columns of table "gateway_worker" */
export enum Gateway_Worker_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  Zone = 'zone',
}

export type Gateway_Worker_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Gateway_Worker_Set_Input>;
  /** filter the rows which have to be updated */
  where: Gateway_Worker_Bool_Exp;
};

export type Get_Aggregated_Cost_For_Project_On_Shared_Plan_Args = {
  fromdate?: InputMaybe<Scalars['date']>;
  projectid?: InputMaybe<Scalars['String']>;
  todate?: InputMaybe<Scalars['date']>;
};

/** Github Integration deploy commits status email types */
export type Github_Email_Type = {
  __typename?: 'github_email_type';
  description: Scalars['String'];
  email_type: Scalars['String'];
};

/** aggregated selection of "github_email_type" */
export type Github_Email_Type_Aggregate = {
  __typename?: 'github_email_type_aggregate';
  aggregate?: Maybe<Github_Email_Type_Aggregate_Fields>;
  nodes: Array<Github_Email_Type>;
};

/** aggregate fields of "github_email_type" */
export type Github_Email_Type_Aggregate_Fields = {
  __typename?: 'github_email_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Github_Email_Type_Max_Fields>;
  min?: Maybe<Github_Email_Type_Min_Fields>;
};

/** aggregate fields of "github_email_type" */
export type Github_Email_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Github_Email_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "github_email_type". All fields are combined with a logical 'AND'. */
export type Github_Email_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Github_Email_Type_Bool_Exp>>;
  _not?: InputMaybe<Github_Email_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Github_Email_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  email_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "github_email_type" */
export enum Github_Email_Type_Constraint {
  /** unique or primary key constraint on columns "email_type" */
  GithubEmailTypePkey = 'github_email_type_pkey',
}

export enum Github_Email_Type_Enum {
  /** Both email types to be sent */
  Both = 'both',
  /** Github Integration deployment failure type */
  Failure = 'failure',
  /** No email types to be sent */
  None = 'none',
  /** Github Integration deployment success type */
  Success = 'success',
}

/** Boolean expression to compare columns of type "github_email_type_enum". All fields are combined with logical 'AND'. */
export type Github_Email_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Github_Email_Type_Enum>;
  _in?: InputMaybe<Array<Github_Email_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Github_Email_Type_Enum>;
  _nin?: InputMaybe<Array<Github_Email_Type_Enum>>;
};

/** input type for inserting data into table "github_email_type" */
export type Github_Email_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  email_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Github_Email_Type_Max_Fields = {
  __typename?: 'github_email_type_max_fields';
  description?: Maybe<Scalars['String']>;
  email_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Github_Email_Type_Min_Fields = {
  __typename?: 'github_email_type_min_fields';
  description?: Maybe<Scalars['String']>;
  email_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "github_email_type" */
export type Github_Email_Type_Mutation_Response = {
  __typename?: 'github_email_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Github_Email_Type>;
};

/** on_conflict condition type for table "github_email_type" */
export type Github_Email_Type_On_Conflict = {
  constraint: Github_Email_Type_Constraint;
  update_columns?: Array<Github_Email_Type_Update_Column>;
  where?: InputMaybe<Github_Email_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "github_email_type". */
export type Github_Email_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  email_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: github_email_type */
export type Github_Email_Type_Pk_Columns_Input = {
  email_type: Scalars['String'];
};

/** select columns of table "github_email_type" */
export enum Github_Email_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  EmailType = 'email_type',
}

/** input type for updating data in table "github_email_type" */
export type Github_Email_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  email_type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "github_email_type" */
export type Github_Email_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Github_Email_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Github_Email_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  email_type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "github_email_type" */
export enum Github_Email_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  EmailType = 'email_type',
}

export type Github_Email_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Github_Email_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Github_Email_Type_Bool_Exp;
};

/** columns and relationships of "github_integration_config" */
export type Github_Integration_Config = {
  __typename?: 'github_integration_config';
  app_installation_id?: Maybe<Scalars['Int']>;
  branch: Scalars['String'];
  created_at: Scalars['timestamptz'];
  directory: Scalars['String'];
  email_status: Github_Email_Type_Enum;
  /** An array relationship */
  github_push_events: Array<Github_Push_Event>;
  /** An aggregate relationship */
  github_push_events_aggregate: Github_Push_Event_Aggregate;
  id: Scalars['uuid'];
  mode: Github_Integration_Mode_Enum;
  organisation: Scalars['String'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  repository: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "github_integration_config" */
export type Github_Integration_ConfigGithub_Push_EventsArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Bool_Exp>;
};

/** columns and relationships of "github_integration_config" */
export type Github_Integration_ConfigGithub_Push_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Bool_Exp>;
};

/** aggregated selection of "github_integration_config" */
export type Github_Integration_Config_Aggregate = {
  __typename?: 'github_integration_config_aggregate';
  aggregate?: Maybe<Github_Integration_Config_Aggregate_Fields>;
  nodes: Array<Github_Integration_Config>;
};

/** aggregate fields of "github_integration_config" */
export type Github_Integration_Config_Aggregate_Fields = {
  __typename?: 'github_integration_config_aggregate_fields';
  avg?: Maybe<Github_Integration_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Github_Integration_Config_Max_Fields>;
  min?: Maybe<Github_Integration_Config_Min_Fields>;
  stddev?: Maybe<Github_Integration_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Github_Integration_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Github_Integration_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Github_Integration_Config_Sum_Fields>;
  var_pop?: Maybe<Github_Integration_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Github_Integration_Config_Var_Samp_Fields>;
  variance?: Maybe<Github_Integration_Config_Variance_Fields>;
};

/** aggregate fields of "github_integration_config" */
export type Github_Integration_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Github_Integration_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Github_Integration_Config_Avg_Fields = {
  __typename?: 'github_integration_config_avg_fields';
  app_installation_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "github_integration_config". All fields are combined with a logical 'AND'. */
export type Github_Integration_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Github_Integration_Config_Bool_Exp>>;
  _not?: InputMaybe<Github_Integration_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Github_Integration_Config_Bool_Exp>>;
  app_installation_id?: InputMaybe<Int_Comparison_Exp>;
  branch?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  directory?: InputMaybe<String_Comparison_Exp>;
  email_status?: InputMaybe<Github_Email_Type_Enum_Comparison_Exp>;
  github_push_events?: InputMaybe<Github_Push_Event_Bool_Exp>;
  github_push_events_aggregate?: InputMaybe<
    Github_Push_Event_Aggregate_Bool_Exp
  >;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  mode?: InputMaybe<Github_Integration_Mode_Enum_Comparison_Exp>;
  organisation?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  repository?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "github_integration_config" */
export enum Github_Integration_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  GithubIntegrationConfigPkey = 'github_integration_config_pkey',
}

/** input type for incrementing numeric columns in table "github_integration_config" */
export type Github_Integration_Config_Inc_Input = {
  app_installation_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "github_integration_config" */
export type Github_Integration_Config_Insert_Input = {
  app_installation_id?: InputMaybe<Scalars['Int']>;
  branch?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  directory?: InputMaybe<Scalars['String']>;
  email_status?: InputMaybe<Github_Email_Type_Enum>;
  github_push_events?: InputMaybe<Github_Push_Event_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  mode?: InputMaybe<Github_Integration_Mode_Enum>;
  organisation?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  repository?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Github_Integration_Config_Max_Fields = {
  __typename?: 'github_integration_config_max_fields';
  app_installation_id?: Maybe<Scalars['Int']>;
  branch?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  directory?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organisation?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  repository?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Github_Integration_Config_Min_Fields = {
  __typename?: 'github_integration_config_min_fields';
  app_installation_id?: Maybe<Scalars['Int']>;
  branch?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  directory?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  organisation?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  repository?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "github_integration_config" */
export type Github_Integration_Config_Mutation_Response = {
  __typename?: 'github_integration_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Github_Integration_Config>;
};

/** input type for inserting object relation for remote table "github_integration_config" */
export type Github_Integration_Config_Obj_Rel_Insert_Input = {
  data: Github_Integration_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Github_Integration_Config_On_Conflict>;
};

/** on_conflict condition type for table "github_integration_config" */
export type Github_Integration_Config_On_Conflict = {
  constraint: Github_Integration_Config_Constraint;
  update_columns?: Array<Github_Integration_Config_Update_Column>;
  where?: InputMaybe<Github_Integration_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "github_integration_config". */
export type Github_Integration_Config_Order_By = {
  app_installation_id?: InputMaybe<Order_By>;
  branch?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  directory?: InputMaybe<Order_By>;
  email_status?: InputMaybe<Order_By>;
  github_push_events_aggregate?: InputMaybe<
    Github_Push_Event_Aggregate_Order_By
  >;
  id?: InputMaybe<Order_By>;
  mode?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  repository?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: github_integration_config */
export type Github_Integration_Config_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "github_integration_config" */
export enum Github_Integration_Config_Select_Column {
  /** column name */
  AppInstallationId = 'app_installation_id',
  /** column name */
  Branch = 'branch',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Directory = 'directory',
  /** column name */
  EmailStatus = 'email_status',
  /** column name */
  Id = 'id',
  /** column name */
  Mode = 'mode',
  /** column name */
  Organisation = 'organisation',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Repository = 'repository',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "github_integration_config" */
export type Github_Integration_Config_Set_Input = {
  app_installation_id?: InputMaybe<Scalars['Int']>;
  branch?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  directory?: InputMaybe<Scalars['String']>;
  email_status?: InputMaybe<Github_Email_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  mode?: InputMaybe<Github_Integration_Mode_Enum>;
  organisation?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  repository?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Github_Integration_Config_Stddev_Fields = {
  __typename?: 'github_integration_config_stddev_fields';
  app_installation_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Github_Integration_Config_Stddev_Pop_Fields = {
  __typename?: 'github_integration_config_stddev_pop_fields';
  app_installation_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Github_Integration_Config_Stddev_Samp_Fields = {
  __typename?: 'github_integration_config_stddev_samp_fields';
  app_installation_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "github_integration_config" */
export type Github_Integration_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Github_Integration_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Github_Integration_Config_Stream_Cursor_Value_Input = {
  app_installation_id?: InputMaybe<Scalars['Int']>;
  branch?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  directory?: InputMaybe<Scalars['String']>;
  email_status?: InputMaybe<Github_Email_Type_Enum>;
  id?: InputMaybe<Scalars['uuid']>;
  mode?: InputMaybe<Github_Integration_Mode_Enum>;
  organisation?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  repository?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Github_Integration_Config_Sum_Fields = {
  __typename?: 'github_integration_config_sum_fields';
  app_installation_id?: Maybe<Scalars['Int']>;
};

/** update columns of table "github_integration_config" */
export enum Github_Integration_Config_Update_Column {
  /** column name */
  AppInstallationId = 'app_installation_id',
  /** column name */
  Branch = 'branch',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Directory = 'directory',
  /** column name */
  EmailStatus = 'email_status',
  /** column name */
  Id = 'id',
  /** column name */
  Mode = 'mode',
  /** column name */
  Organisation = 'organisation',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Repository = 'repository',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Github_Integration_Config_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Github_Integration_Config_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Github_Integration_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Github_Integration_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Github_Integration_Config_Var_Pop_Fields = {
  __typename?: 'github_integration_config_var_pop_fields';
  app_installation_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Github_Integration_Config_Var_Samp_Fields = {
  __typename?: 'github_integration_config_var_samp_fields';
  app_installation_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Github_Integration_Config_Variance_Fields = {
  __typename?: 'github_integration_config_variance_fields';
  app_installation_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "github_integration_mode" */
export type Github_Integration_Mode = {
  __typename?: 'github_integration_mode';
  description: Scalars['String'];
  mode: Scalars['String'];
};

/** aggregated selection of "github_integration_mode" */
export type Github_Integration_Mode_Aggregate = {
  __typename?: 'github_integration_mode_aggregate';
  aggregate?: Maybe<Github_Integration_Mode_Aggregate_Fields>;
  nodes: Array<Github_Integration_Mode>;
};

/** aggregate fields of "github_integration_mode" */
export type Github_Integration_Mode_Aggregate_Fields = {
  __typename?: 'github_integration_mode_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Github_Integration_Mode_Max_Fields>;
  min?: Maybe<Github_Integration_Mode_Min_Fields>;
};

/** aggregate fields of "github_integration_mode" */
export type Github_Integration_Mode_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Github_Integration_Mode_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "github_integration_mode". All fields are combined with a logical 'AND'. */
export type Github_Integration_Mode_Bool_Exp = {
  _and?: InputMaybe<Array<Github_Integration_Mode_Bool_Exp>>;
  _not?: InputMaybe<Github_Integration_Mode_Bool_Exp>;
  _or?: InputMaybe<Array<Github_Integration_Mode_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  mode?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "github_integration_mode" */
export enum Github_Integration_Mode_Constraint {
  /** unique or primary key constraint on columns "mode" */
  GithubIntegrationModePkey = 'github_integration_mode_pkey',
}

export enum Github_Integration_Mode_Enum {
  /** deploy github automatically */
  Automatic = 'automatic',
  /** deploy github manually */
  Manual = 'manual',
  /** deploy github as preview app */
  PreviewApp = 'previewApp',
}

/** Boolean expression to compare columns of type "github_integration_mode_enum". All fields are combined with logical 'AND'. */
export type Github_Integration_Mode_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Github_Integration_Mode_Enum>;
  _in?: InputMaybe<Array<Github_Integration_Mode_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Github_Integration_Mode_Enum>;
  _nin?: InputMaybe<Array<Github_Integration_Mode_Enum>>;
};

/** input type for inserting data into table "github_integration_mode" */
export type Github_Integration_Mode_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Github_Integration_Mode_Max_Fields = {
  __typename?: 'github_integration_mode_max_fields';
  description?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Github_Integration_Mode_Min_Fields = {
  __typename?: 'github_integration_mode_min_fields';
  description?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "github_integration_mode" */
export type Github_Integration_Mode_Mutation_Response = {
  __typename?: 'github_integration_mode_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Github_Integration_Mode>;
};

/** on_conflict condition type for table "github_integration_mode" */
export type Github_Integration_Mode_On_Conflict = {
  constraint: Github_Integration_Mode_Constraint;
  update_columns?: Array<Github_Integration_Mode_Update_Column>;
  where?: InputMaybe<Github_Integration_Mode_Bool_Exp>;
};

/** Ordering options when selecting data from "github_integration_mode". */
export type Github_Integration_Mode_Order_By = {
  description?: InputMaybe<Order_By>;
  mode?: InputMaybe<Order_By>;
};

/** primary key columns input for table: github_integration_mode */
export type Github_Integration_Mode_Pk_Columns_Input = {
  mode: Scalars['String'];
};

/** select columns of table "github_integration_mode" */
export enum Github_Integration_Mode_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Mode = 'mode',
}

/** input type for updating data in table "github_integration_mode" */
export type Github_Integration_Mode_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "github_integration_mode" */
export type Github_Integration_Mode_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Github_Integration_Mode_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Github_Integration_Mode_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<Scalars['String']>;
};

/** update columns of table "github_integration_mode" */
export enum Github_Integration_Mode_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Mode = 'mode',
}

export type Github_Integration_Mode_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Github_Integration_Mode_Set_Input>;
  /** filter the rows which have to be updated */
  where: Github_Integration_Mode_Bool_Exp;
};

/** columns and relationships of "github_push_event" */
export type Github_Push_Event = {
  __typename?: 'github_push_event';
  archive_url: Scalars['String'];
  commit: Scalars['String'];
  commit_message: Scalars['String'];
  commit_timestamp: Scalars['timestamptz'];
  /** An object relationship */
  github_integration_config: Github_Integration_Config;
  github_integration_config_id: Scalars['uuid'];
  /** An array relationship */
  github_push_event_jobs: Array<Github_Push_Event_Job>;
  /** An aggregate relationship */
  github_push_event_jobs_aggregate: Github_Push_Event_Job_Aggregate;
  id: Scalars['uuid'];
  push_metadata: Scalars['jsonb'];
  received_at: Scalars['timestamptz'];
};

/** columns and relationships of "github_push_event" */
export type Github_Push_EventGithub_Push_Event_JobsArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Job_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
};

/** columns and relationships of "github_push_event" */
export type Github_Push_EventGithub_Push_Event_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Job_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
};

/** columns and relationships of "github_push_event" */
export type Github_Push_EventPush_MetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "github_push_event" */
export type Github_Push_Event_Aggregate = {
  __typename?: 'github_push_event_aggregate';
  aggregate?: Maybe<Github_Push_Event_Aggregate_Fields>;
  nodes: Array<Github_Push_Event>;
};

export type Github_Push_Event_Aggregate_Bool_Exp = {
  count?: InputMaybe<Github_Push_Event_Aggregate_Bool_Exp_Count>;
};

export type Github_Push_Event_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Github_Push_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Github_Push_Event_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "github_push_event" */
export type Github_Push_Event_Aggregate_Fields = {
  __typename?: 'github_push_event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Github_Push_Event_Max_Fields>;
  min?: Maybe<Github_Push_Event_Min_Fields>;
};

/** aggregate fields of "github_push_event" */
export type Github_Push_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Github_Push_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "github_push_event" */
export type Github_Push_Event_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Github_Push_Event_Max_Order_By>;
  min?: InputMaybe<Github_Push_Event_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Github_Push_Event_Append_Input = {
  push_metadata?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "github_push_event" */
export type Github_Push_Event_Arr_Rel_Insert_Input = {
  data: Array<Github_Push_Event_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Github_Push_Event_On_Conflict>;
};

/** Boolean expression to filter rows from the table "github_push_event". All fields are combined with a logical 'AND'. */
export type Github_Push_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Github_Push_Event_Bool_Exp>>;
  _not?: InputMaybe<Github_Push_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Github_Push_Event_Bool_Exp>>;
  archive_url?: InputMaybe<String_Comparison_Exp>;
  commit?: InputMaybe<String_Comparison_Exp>;
  commit_message?: InputMaybe<String_Comparison_Exp>;
  commit_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  github_integration_config?: InputMaybe<Github_Integration_Config_Bool_Exp>;
  github_integration_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  github_push_event_jobs?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
  github_push_event_jobs_aggregate?: InputMaybe<
    Github_Push_Event_Job_Aggregate_Bool_Exp
  >;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  push_metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  received_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "github_push_event" */
export enum Github_Push_Event_Constraint {
  /** unique or primary key constraint on columns "id" */
  GithubPushEventPkey = 'github_push_event_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Github_Push_Event_Delete_At_Path_Input = {
  push_metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Github_Push_Event_Delete_Elem_Input = {
  push_metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Github_Push_Event_Delete_Key_Input = {
  push_metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "github_push_event" */
export type Github_Push_Event_Insert_Input = {
  archive_url?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  commit_message?: InputMaybe<Scalars['String']>;
  commit_timestamp?: InputMaybe<Scalars['timestamptz']>;
  github_integration_config?: InputMaybe<
    Github_Integration_Config_Obj_Rel_Insert_Input
  >;
  github_integration_config_id?: InputMaybe<Scalars['uuid']>;
  github_push_event_jobs?: InputMaybe<
    Github_Push_Event_Job_Arr_Rel_Insert_Input
  >;
  id?: InputMaybe<Scalars['uuid']>;
  push_metadata?: InputMaybe<Scalars['jsonb']>;
  received_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "github_push_event_job" */
export type Github_Push_Event_Job = {
  __typename?: 'github_push_event_job';
  /** An object relationship */
  github_push_event: Github_Push_Event;
  github_push_event_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  job: Jobs;
  job_id: Scalars['uuid'];
};

/** aggregated selection of "github_push_event_job" */
export type Github_Push_Event_Job_Aggregate = {
  __typename?: 'github_push_event_job_aggregate';
  aggregate?: Maybe<Github_Push_Event_Job_Aggregate_Fields>;
  nodes: Array<Github_Push_Event_Job>;
};

export type Github_Push_Event_Job_Aggregate_Bool_Exp = {
  count?: InputMaybe<Github_Push_Event_Job_Aggregate_Bool_Exp_Count>;
};

export type Github_Push_Event_Job_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Github_Push_Event_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "github_push_event_job" */
export type Github_Push_Event_Job_Aggregate_Fields = {
  __typename?: 'github_push_event_job_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Github_Push_Event_Job_Max_Fields>;
  min?: Maybe<Github_Push_Event_Job_Min_Fields>;
};

/** aggregate fields of "github_push_event_job" */
export type Github_Push_Event_Job_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Github_Push_Event_Job_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "github_push_event_job" */
export type Github_Push_Event_Job_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Github_Push_Event_Job_Max_Order_By>;
  min?: InputMaybe<Github_Push_Event_Job_Min_Order_By>;
};

/** input type for inserting array relation for remote table "github_push_event_job" */
export type Github_Push_Event_Job_Arr_Rel_Insert_Input = {
  data: Array<Github_Push_Event_Job_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Github_Push_Event_Job_On_Conflict>;
};

/** Boolean expression to filter rows from the table "github_push_event_job". All fields are combined with a logical 'AND'. */
export type Github_Push_Event_Job_Bool_Exp = {
  _and?: InputMaybe<Array<Github_Push_Event_Job_Bool_Exp>>;
  _not?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
  _or?: InputMaybe<Array<Github_Push_Event_Job_Bool_Exp>>;
  github_push_event?: InputMaybe<Github_Push_Event_Bool_Exp>;
  github_push_event_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "github_push_event_job" */
export enum Github_Push_Event_Job_Constraint {
  /** unique or primary key constraint on columns "id" */
  GithubPushEventJobPkey = 'github_push_event_job_pkey',
}

/** input type for inserting data into table "github_push_event_job" */
export type Github_Push_Event_Job_Insert_Input = {
  github_push_event?: InputMaybe<Github_Push_Event_Obj_Rel_Insert_Input>;
  github_push_event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Github_Push_Event_Job_Max_Fields = {
  __typename?: 'github_push_event_job_max_fields';
  github_push_event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "github_push_event_job" */
export type Github_Push_Event_Job_Max_Order_By = {
  github_push_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Github_Push_Event_Job_Min_Fields = {
  __typename?: 'github_push_event_job_min_fields';
  github_push_event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "github_push_event_job" */
export type Github_Push_Event_Job_Min_Order_By = {
  github_push_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "github_push_event_job" */
export type Github_Push_Event_Job_Mutation_Response = {
  __typename?: 'github_push_event_job_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Github_Push_Event_Job>;
};

/** on_conflict condition type for table "github_push_event_job" */
export type Github_Push_Event_Job_On_Conflict = {
  constraint: Github_Push_Event_Job_Constraint;
  update_columns?: Array<Github_Push_Event_Job_Update_Column>;
  where?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
};

/** Ordering options when selecting data from "github_push_event_job". */
export type Github_Push_Event_Job_Order_By = {
  github_push_event?: InputMaybe<Github_Push_Event_Order_By>;
  github_push_event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Jobs_Order_By>;
  job_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: github_push_event_job */
export type Github_Push_Event_Job_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "github_push_event_job" */
export enum Github_Push_Event_Job_Select_Column {
  /** column name */
  GithubPushEventId = 'github_push_event_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
}

/** input type for updating data in table "github_push_event_job" */
export type Github_Push_Event_Job_Set_Input = {
  github_push_event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "github_push_event_job" */
export type Github_Push_Event_Job_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Github_Push_Event_Job_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Github_Push_Event_Job_Stream_Cursor_Value_Input = {
  github_push_event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "github_push_event_job" */
export enum Github_Push_Event_Job_Update_Column {
  /** column name */
  GithubPushEventId = 'github_push_event_id',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
}

export type Github_Push_Event_Job_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Github_Push_Event_Job_Set_Input>;
  /** filter the rows which have to be updated */
  where: Github_Push_Event_Job_Bool_Exp;
};

/** aggregate max on columns */
export type Github_Push_Event_Max_Fields = {
  __typename?: 'github_push_event_max_fields';
  archive_url?: Maybe<Scalars['String']>;
  commit?: Maybe<Scalars['String']>;
  commit_message?: Maybe<Scalars['String']>;
  commit_timestamp?: Maybe<Scalars['timestamptz']>;
  github_integration_config_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  received_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "github_push_event" */
export type Github_Push_Event_Max_Order_By = {
  archive_url?: InputMaybe<Order_By>;
  commit?: InputMaybe<Order_By>;
  commit_message?: InputMaybe<Order_By>;
  commit_timestamp?: InputMaybe<Order_By>;
  github_integration_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  received_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Github_Push_Event_Min_Fields = {
  __typename?: 'github_push_event_min_fields';
  archive_url?: Maybe<Scalars['String']>;
  commit?: Maybe<Scalars['String']>;
  commit_message?: Maybe<Scalars['String']>;
  commit_timestamp?: Maybe<Scalars['timestamptz']>;
  github_integration_config_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  received_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "github_push_event" */
export type Github_Push_Event_Min_Order_By = {
  archive_url?: InputMaybe<Order_By>;
  commit?: InputMaybe<Order_By>;
  commit_message?: InputMaybe<Order_By>;
  commit_timestamp?: InputMaybe<Order_By>;
  github_integration_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  received_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "github_push_event" */
export type Github_Push_Event_Mutation_Response = {
  __typename?: 'github_push_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Github_Push_Event>;
};

/** input type for inserting object relation for remote table "github_push_event" */
export type Github_Push_Event_Obj_Rel_Insert_Input = {
  data: Github_Push_Event_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Github_Push_Event_On_Conflict>;
};

/** on_conflict condition type for table "github_push_event" */
export type Github_Push_Event_On_Conflict = {
  constraint: Github_Push_Event_Constraint;
  update_columns?: Array<Github_Push_Event_Update_Column>;
  where?: InputMaybe<Github_Push_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "github_push_event". */
export type Github_Push_Event_Order_By = {
  archive_url?: InputMaybe<Order_By>;
  commit?: InputMaybe<Order_By>;
  commit_message?: InputMaybe<Order_By>;
  commit_timestamp?: InputMaybe<Order_By>;
  github_integration_config?: InputMaybe<Github_Integration_Config_Order_By>;
  github_integration_config_id?: InputMaybe<Order_By>;
  github_push_event_jobs_aggregate?: InputMaybe<
    Github_Push_Event_Job_Aggregate_Order_By
  >;
  id?: InputMaybe<Order_By>;
  push_metadata?: InputMaybe<Order_By>;
  received_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: github_push_event */
export type Github_Push_Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Github_Push_Event_Prepend_Input = {
  push_metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "github_push_event" */
export enum Github_Push_Event_Select_Column {
  /** column name */
  ArchiveUrl = 'archive_url',
  /** column name */
  Commit = 'commit',
  /** column name */
  CommitMessage = 'commit_message',
  /** column name */
  CommitTimestamp = 'commit_timestamp',
  /** column name */
  GithubIntegrationConfigId = 'github_integration_config_id',
  /** column name */
  Id = 'id',
  /** column name */
  PushMetadata = 'push_metadata',
  /** column name */
  ReceivedAt = 'received_at',
}

/** input type for updating data in table "github_push_event" */
export type Github_Push_Event_Set_Input = {
  archive_url?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  commit_message?: InputMaybe<Scalars['String']>;
  commit_timestamp?: InputMaybe<Scalars['timestamptz']>;
  github_integration_config_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  push_metadata?: InputMaybe<Scalars['jsonb']>;
  received_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "github_push_event" */
export type Github_Push_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Github_Push_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Github_Push_Event_Stream_Cursor_Value_Input = {
  archive_url?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  commit_message?: InputMaybe<Scalars['String']>;
  commit_timestamp?: InputMaybe<Scalars['timestamptz']>;
  github_integration_config_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  push_metadata?: InputMaybe<Scalars['jsonb']>;
  received_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "github_push_event" */
export enum Github_Push_Event_Update_Column {
  /** column name */
  ArchiveUrl = 'archive_url',
  /** column name */
  Commit = 'commit',
  /** column name */
  CommitMessage = 'commit_message',
  /** column name */
  CommitTimestamp = 'commit_timestamp',
  /** column name */
  GithubIntegrationConfigId = 'github_integration_config_id',
  /** column name */
  Id = 'id',
  /** column name */
  PushMetadata = 'push_metadata',
  /** column name */
  ReceivedAt = 'received_at',
}

export type Github_Push_Event_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Github_Push_Event_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Github_Push_Event_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Github_Push_Event_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Github_Push_Event_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Github_Push_Event_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Github_Push_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Github_Push_Event_Bool_Exp;
};

/** columns and relationships of "hasura_ami" */
export type Hasura_Ami = {
  __typename?: 'hasura_ami';
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  cloud_version: Scalars['String'];
  commit_hash: Scalars['String'];
  created_at: Scalars['timestamptz'];
  major_version: Scalars['smallint'];
  region: Scalars['String'];
  remarks: Scalars['String'];
  super_connector_metadata_defaults?: Maybe<Scalars['jsonb']>;
};

/** columns and relationships of "hasura_ami" */
export type Hasura_AmiSuper_Connector_Metadata_DefaultsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hasura_ami" */
export type Hasura_Ami_Aggregate = {
  __typename?: 'hasura_ami_aggregate';
  aggregate?: Maybe<Hasura_Ami_Aggregate_Fields>;
  nodes: Array<Hasura_Ami>;
};

/** aggregate fields of "hasura_ami" */
export type Hasura_Ami_Aggregate_Fields = {
  __typename?: 'hasura_ami_aggregate_fields';
  avg?: Maybe<Hasura_Ami_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hasura_Ami_Max_Fields>;
  min?: Maybe<Hasura_Ami_Min_Fields>;
  stddev?: Maybe<Hasura_Ami_Stddev_Fields>;
  stddev_pop?: Maybe<Hasura_Ami_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hasura_Ami_Stddev_Samp_Fields>;
  sum?: Maybe<Hasura_Ami_Sum_Fields>;
  var_pop?: Maybe<Hasura_Ami_Var_Pop_Fields>;
  var_samp?: Maybe<Hasura_Ami_Var_Samp_Fields>;
  variance?: Maybe<Hasura_Ami_Variance_Fields>;
};

/** aggregate fields of "hasura_ami" */
export type Hasura_Ami_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hasura_Ami_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hasura_Ami_Append_Input = {
  super_connector_metadata_defaults?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Hasura_Ami_Avg_Fields = {
  __typename?: 'hasura_ami_avg_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "hasura_ami". All fields are combined with a logical 'AND'. */
export type Hasura_Ami_Bool_Exp = {
  _and?: InputMaybe<Array<Hasura_Ami_Bool_Exp>>;
  _not?: InputMaybe<Hasura_Ami_Bool_Exp>;
  _or?: InputMaybe<Array<Hasura_Ami_Bool_Exp>>;
  ami_id?: InputMaybe<String_Comparison_Exp>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  cloud_version?: InputMaybe<String_Comparison_Exp>;
  commit_hash?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  major_version?: InputMaybe<Smallint_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  super_connector_metadata_defaults?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "hasura_ami" */
export enum Hasura_Ami_Constraint {
  /** unique or primary key constraint on columns "region", "cloud", "ami_id", "cloud_version" */
  HasuraAmiAmiIdCloudRegionCloudVersionKey = 'hasura_ami_ami_id_cloud_region_cloud_version_key',
  /** unique or primary key constraint on columns "ami_id" */
  HasuraAmiAmiIdKey = 'hasura_ami_ami_id_key',
  /** unique or primary key constraint on columns "region", "cloud", "ami_id" */
  HasuraAmiPkey = 'hasura_ami_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hasura_Ami_Delete_At_Path_Input = {
  super_connector_metadata_defaults?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hasura_Ami_Delete_Elem_Input = {
  super_connector_metadata_defaults?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hasura_Ami_Delete_Key_Input = {
  super_connector_metadata_defaults?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "hasura_ami" */
export type Hasura_Ami_Inc_Input = {
  major_version?: InputMaybe<Scalars['smallint']>;
};

/** input type for inserting data into table "hasura_ami" */
export type Hasura_Ami_Insert_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  cloud_version?: InputMaybe<Scalars['String']>;
  commit_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  major_version?: InputMaybe<Scalars['smallint']>;
  region?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  super_connector_metadata_defaults?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate max on columns */
export type Hasura_Ami_Max_Fields = {
  __typename?: 'hasura_ami_max_fields';
  ami_id?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  cloud_version?: Maybe<Scalars['String']>;
  commit_hash?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  major_version?: Maybe<Scalars['smallint']>;
  region?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hasura_Ami_Min_Fields = {
  __typename?: 'hasura_ami_min_fields';
  ami_id?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  cloud_version?: Maybe<Scalars['String']>;
  commit_hash?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  major_version?: Maybe<Scalars['smallint']>;
  region?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "hasura_ami" */
export type Hasura_Ami_Mutation_Response = {
  __typename?: 'hasura_ami_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hasura_Ami>;
};

/** on_conflict condition type for table "hasura_ami" */
export type Hasura_Ami_On_Conflict = {
  constraint: Hasura_Ami_Constraint;
  update_columns?: Array<Hasura_Ami_Update_Column>;
  where?: InputMaybe<Hasura_Ami_Bool_Exp>;
};

/** Ordering options when selecting data from "hasura_ami". */
export type Hasura_Ami_Order_By = {
  ami_id?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  cloud_version?: InputMaybe<Order_By>;
  commit_hash?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  super_connector_metadata_defaults?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hasura_ami */
export type Hasura_Ami_Pk_Columns_Input = {
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  region: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hasura_Ami_Prepend_Input = {
  super_connector_metadata_defaults?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hasura_ami" */
export enum Hasura_Ami_Select_Column {
  /** column name */
  AmiId = 'ami_id',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CloudVersion = 'cloud_version',
  /** column name */
  CommitHash = 'commit_hash',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MajorVersion = 'major_version',
  /** column name */
  Region = 'region',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  SuperConnectorMetadataDefaults = 'super_connector_metadata_defaults',
}

/** input type for updating data in table "hasura_ami" */
export type Hasura_Ami_Set_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  cloud_version?: InputMaybe<Scalars['String']>;
  commit_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  major_version?: InputMaybe<Scalars['smallint']>;
  region?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  super_connector_metadata_defaults?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate stddev on columns */
export type Hasura_Ami_Stddev_Fields = {
  __typename?: 'hasura_ami_stddev_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Hasura_Ami_Stddev_Pop_Fields = {
  __typename?: 'hasura_ami_stddev_pop_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Hasura_Ami_Stddev_Samp_Fields = {
  __typename?: 'hasura_ami_stddev_samp_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "hasura_ami" */
export type Hasura_Ami_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hasura_Ami_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hasura_Ami_Stream_Cursor_Value_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  cloud_version?: InputMaybe<Scalars['String']>;
  commit_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  major_version?: InputMaybe<Scalars['smallint']>;
  region?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  super_connector_metadata_defaults?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate sum on columns */
export type Hasura_Ami_Sum_Fields = {
  __typename?: 'hasura_ami_sum_fields';
  major_version?: Maybe<Scalars['smallint']>;
};

/** update columns of table "hasura_ami" */
export enum Hasura_Ami_Update_Column {
  /** column name */
  AmiId = 'ami_id',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CloudVersion = 'cloud_version',
  /** column name */
  CommitHash = 'commit_hash',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  MajorVersion = 'major_version',
  /** column name */
  Region = 'region',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  SuperConnectorMetadataDefaults = 'super_connector_metadata_defaults',
}

export type Hasura_Ami_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hasura_Ami_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hasura_Ami_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hasura_Ami_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hasura_Ami_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hasura_Ami_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hasura_Ami_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hasura_Ami_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hasura_Ami_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hasura_Ami_Var_Pop_Fields = {
  __typename?: 'hasura_ami_var_pop_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Hasura_Ami_Var_Samp_Fields = {
  __typename?: 'hasura_ami_var_samp_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Hasura_Ami_Variance_Fields = {
  __typename?: 'hasura_ami_variance_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** Set of Hasura workers in a specific region */
export type Hasura_Cluster = {
  __typename?: 'hasura_cluster';
  ami_id?: Maybe<Scalars['String']>;
  cloud: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  /** An object relationship */
  compute_unit_config?: Maybe<Compute_Unit_Config>;
  compute_unit_config_id?: Maybe<Scalars['uuid']>;
  configured_super_connector?: Maybe<Super_Connector_Types_Enum>;
  created_at: Scalars['timestamptz'];
  disable_infra_auto_apply: Scalars['Boolean'];
  id: Scalars['uuid'];
  infra_status?: Maybe<Infra_Status_Enum>;
  input_variables?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  node_pool?: Maybe<Node_Pools>;
  node_pool_id?: Maybe<Scalars['uuid']>;
  output_variables?: Maybe<Scalars['jsonb']>;
  pinned_version?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  /** An object relationship */
  region_info?: Maybe<Region>;
  slug: Scalars['String'];
  status: Scalars['String'];
  /** metadata defaults that will be used by the hasura cluster */
  super_connector_metadata_defaults?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  tenant_group?: Maybe<Tenant_Group>;
  tenant_group_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  workers: Array<Hasura_Worker>;
  /** An aggregate relationship */
  workers_aggregate: Hasura_Worker_Aggregate;
};

/** Set of Hasura workers in a specific region */
export type Hasura_ClusterInput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Set of Hasura workers in a specific region */
export type Hasura_ClusterOutput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Set of Hasura workers in a specific region */
export type Hasura_ClusterSuper_Connector_Metadata_DefaultsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Set of Hasura workers in a specific region */
export type Hasura_ClusterWorkersArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Worker_Order_By>>;
  where?: InputMaybe<Hasura_Worker_Bool_Exp>;
};

/** Set of Hasura workers in a specific region */
export type Hasura_ClusterWorkers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Worker_Order_By>>;
  where?: InputMaybe<Hasura_Worker_Bool_Exp>;
};

/** aggregated selection of "hasura_cluster" */
export type Hasura_Cluster_Aggregate = {
  __typename?: 'hasura_cluster_aggregate';
  aggregate?: Maybe<Hasura_Cluster_Aggregate_Fields>;
  nodes: Array<Hasura_Cluster>;
};

export type Hasura_Cluster_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Hasura_Cluster_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Hasura_Cluster_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Hasura_Cluster_Aggregate_Bool_Exp_Count>;
};

export type Hasura_Cluster_Aggregate_Bool_Exp_Bool_And = {
  arguments: Hasura_Cluster_Select_Column_Hasura_Cluster_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hasura_Cluster_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Hasura_Cluster_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Hasura_Cluster_Select_Column_Hasura_Cluster_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hasura_Cluster_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Hasura_Cluster_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hasura_Cluster_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hasura_Cluster_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "hasura_cluster" */
export type Hasura_Cluster_Aggregate_Fields = {
  __typename?: 'hasura_cluster_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Hasura_Cluster_Max_Fields>;
  min?: Maybe<Hasura_Cluster_Min_Fields>;
};

/** aggregate fields of "hasura_cluster" */
export type Hasura_Cluster_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hasura_Cluster_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hasura_cluster" */
export type Hasura_Cluster_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hasura_Cluster_Max_Order_By>;
  min?: InputMaybe<Hasura_Cluster_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hasura_Cluster_Append_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "hasura_cluster" */
export type Hasura_Cluster_Arr_Rel_Insert_Input = {
  data: Array<Hasura_Cluster_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hasura_Cluster_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hasura_cluster". All fields are combined with a logical 'AND'. */
export type Hasura_Cluster_Bool_Exp = {
  _and?: InputMaybe<Array<Hasura_Cluster_Bool_Exp>>;
  _not?: InputMaybe<Hasura_Cluster_Bool_Exp>;
  _or?: InputMaybe<Array<Hasura_Cluster_Bool_Exp>>;
  ami_id?: InputMaybe<String_Comparison_Exp>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  compute_unit_config?: InputMaybe<Compute_Unit_Config_Bool_Exp>;
  compute_unit_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  configured_super_connector?: InputMaybe<
    Super_Connector_Types_Enum_Comparison_Exp
  >;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  disable_infra_auto_apply?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  infra_status?: InputMaybe<Infra_Status_Enum_Comparison_Exp>;
  input_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  node_pool?: InputMaybe<Node_Pools_Bool_Exp>;
  node_pool_id?: InputMaybe<Uuid_Comparison_Exp>;
  output_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  pinned_version?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  region_info?: InputMaybe<Region_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  super_connector_metadata_defaults?: InputMaybe<Jsonb_Comparison_Exp>;
  tenant_group?: InputMaybe<Tenant_Group_Bool_Exp>;
  tenant_group_id?: InputMaybe<Uuid_Comparison_Exp>;
  workers?: InputMaybe<Hasura_Worker_Bool_Exp>;
  workers_aggregate?: InputMaybe<Hasura_Worker_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "hasura_cluster" */
export enum Hasura_Cluster_Constraint {
  /** unique or primary key constraint on columns "compute_unit_config_id", "node_pool_id" */
  HasuraClusterComputeUnitConfigIdUniqueKey = 'hasura_cluster_compute_unit_config_id_unique_key',
  /** unique or primary key constraint on columns "id" */
  HasuraClusterPkey = 'hasura_cluster_pkey',
  /** unique or primary key constraint on columns "slug" */
  HasuraClusterSlugKey = 'hasura_cluster_slug_key',
  /** unique or primary key constraint on columns "tenant_group_id" */
  HasuraClusterTenantGroupIdKey = 'hasura_cluster_tenant_group_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hasura_Cluster_Delete_At_Path_Input = {
  input_variables?: InputMaybe<Array<Scalars['String']>>;
  output_variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hasura_Cluster_Delete_Elem_Input = {
  input_variables?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hasura_Cluster_Delete_Key_Input = {
  input_variables?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "hasura_cluster" */
export type Hasura_Cluster_Insert_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  compute_unit_config?: InputMaybe<Compute_Unit_Config_Obj_Rel_Insert_Input>;
  compute_unit_config_id?: InputMaybe<Scalars['uuid']>;
  configured_super_connector?: InputMaybe<Super_Connector_Types_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  node_pool?: InputMaybe<Node_Pools_Obj_Rel_Insert_Input>;
  node_pool_id?: InputMaybe<Scalars['uuid']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  pinned_version?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  region_info?: InputMaybe<Region_Obj_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tenant_group?: InputMaybe<Tenant_Group_Obj_Rel_Insert_Input>;
  tenant_group_id?: InputMaybe<Scalars['uuid']>;
  workers?: InputMaybe<Hasura_Worker_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Hasura_Cluster_Max_Fields = {
  __typename?: 'hasura_cluster_max_fields';
  ami_id?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  compute_unit_config_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  node_pool_id?: Maybe<Scalars['uuid']>;
  pinned_version?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tenant_group_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "hasura_cluster" */
export type Hasura_Cluster_Max_Order_By = {
  ami_id?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  compute_unit_config_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  node_pool_id?: InputMaybe<Order_By>;
  pinned_version?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tenant_group_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hasura_Cluster_Min_Fields = {
  __typename?: 'hasura_cluster_min_fields';
  ami_id?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  compute_unit_config_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  node_pool_id?: Maybe<Scalars['uuid']>;
  pinned_version?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tenant_group_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "hasura_cluster" */
export type Hasura_Cluster_Min_Order_By = {
  ami_id?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  compute_unit_config_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  node_pool_id?: InputMaybe<Order_By>;
  pinned_version?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tenant_group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hasura_cluster" */
export type Hasura_Cluster_Mutation_Response = {
  __typename?: 'hasura_cluster_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hasura_Cluster>;
};

/** input type for inserting object relation for remote table "hasura_cluster" */
export type Hasura_Cluster_Obj_Rel_Insert_Input = {
  data: Hasura_Cluster_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Hasura_Cluster_On_Conflict>;
};

/** on_conflict condition type for table "hasura_cluster" */
export type Hasura_Cluster_On_Conflict = {
  constraint: Hasura_Cluster_Constraint;
  update_columns?: Array<Hasura_Cluster_Update_Column>;
  where?: InputMaybe<Hasura_Cluster_Bool_Exp>;
};

/** Ordering options when selecting data from "hasura_cluster". */
export type Hasura_Cluster_Order_By = {
  ami_id?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  compute_unit_config?: InputMaybe<Compute_Unit_Config_Order_By>;
  compute_unit_config_id?: InputMaybe<Order_By>;
  configured_super_connector?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  disable_infra_auto_apply?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  infra_status?: InputMaybe<Order_By>;
  input_variables?: InputMaybe<Order_By>;
  node_pool?: InputMaybe<Node_Pools_Order_By>;
  node_pool_id?: InputMaybe<Order_By>;
  output_variables?: InputMaybe<Order_By>;
  pinned_version?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  region_info?: InputMaybe<Region_Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  super_connector_metadata_defaults?: InputMaybe<Order_By>;
  tenant_group?: InputMaybe<Tenant_Group_Order_By>;
  tenant_group_id?: InputMaybe<Order_By>;
  workers_aggregate?: InputMaybe<Hasura_Worker_Aggregate_Order_By>;
};

/** primary key columns input for table: hasura_cluster */
export type Hasura_Cluster_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hasura_Cluster_Prepend_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hasura_cluster" */
export enum Hasura_Cluster_Select_Column {
  /** column name */
  AmiId = 'ami_id',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Comments = 'comments',
  /** column name */
  ComputeUnitConfigId = 'compute_unit_config_id',
  /** column name */
  ConfiguredSuperConnector = 'configured_super_connector',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  Id = 'id',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  NodePoolId = 'node_pool_id',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  PinnedVersion = 'pinned_version',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  TenantGroupId = 'tenant_group_id',
}

/** select "hasura_cluster_aggregate_bool_exp_bool_and_arguments_columns" columns of table "hasura_cluster" */
export enum Hasura_Cluster_Select_Column_Hasura_Cluster_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
}

/** select "hasura_cluster_aggregate_bool_exp_bool_or_arguments_columns" columns of table "hasura_cluster" */
export enum Hasura_Cluster_Select_Column_Hasura_Cluster_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
}

/** input type for updating data in table "hasura_cluster" */
export type Hasura_Cluster_Set_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  compute_unit_config_id?: InputMaybe<Scalars['uuid']>;
  configured_super_connector?: InputMaybe<Super_Connector_Types_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  node_pool_id?: InputMaybe<Scalars['uuid']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  pinned_version?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tenant_group_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "hasura_cluster" */
export type Hasura_Cluster_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hasura_Cluster_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hasura_Cluster_Stream_Cursor_Value_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  compute_unit_config_id?: InputMaybe<Scalars['uuid']>;
  configured_super_connector?: InputMaybe<Super_Connector_Types_Enum>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  node_pool_id?: InputMaybe<Scalars['uuid']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  pinned_version?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tenant_group_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "hasura_cluster" */
export enum Hasura_Cluster_Update_Column {
  /** column name */
  AmiId = 'ami_id',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Comments = 'comments',
  /** column name */
  ComputeUnitConfigId = 'compute_unit_config_id',
  /** column name */
  ConfiguredSuperConnector = 'configured_super_connector',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  Id = 'id',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  NodePoolId = 'node_pool_id',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  PinnedVersion = 'pinned_version',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  TenantGroupId = 'tenant_group_id',
}

export type Hasura_Cluster_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hasura_Cluster_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Hasura_Cluster_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hasura_Cluster_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hasura_Cluster_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hasura_Cluster_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hasura_Cluster_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hasura_Cluster_Bool_Exp;
};

/** Multi-tenant Hasura process capable of providing GraphQL APIs */
export type Hasura_Worker = {
  __typename?: 'hasura_worker';
  cloud: Scalars['String'];
  /** An object relationship */
  cluster: Hasura_Cluster;
  cluster_id: Scalars['uuid'];
  /** An array relationship */
  config_statuses: Array<Config_Status>;
  /** An aggregate relationship */
  config_statuses_aggregate: Config_Status_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  ip: Scalars['String'];
  region: Scalars['String'];
  slug: Scalars['String'];
  status: Scalars['String'];
  /** An array relationship */
  tenant_statuses: Array<Tenant_Status>;
  /** An aggregate relationship */
  tenant_statuses_aggregate: Tenant_Status_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zone: Scalars['String'];
};

/** Multi-tenant Hasura process capable of providing GraphQL APIs */
export type Hasura_WorkerConfig_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Config_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Config_Status_Order_By>>;
  where?: InputMaybe<Config_Status_Bool_Exp>;
};

/** Multi-tenant Hasura process capable of providing GraphQL APIs */
export type Hasura_WorkerConfig_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Config_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Config_Status_Order_By>>;
  where?: InputMaybe<Config_Status_Bool_Exp>;
};

/** Multi-tenant Hasura process capable of providing GraphQL APIs */
export type Hasura_WorkerTenant_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Status_Order_By>>;
  where?: InputMaybe<Tenant_Status_Bool_Exp>;
};

/** Multi-tenant Hasura process capable of providing GraphQL APIs */
export type Hasura_WorkerTenant_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Status_Order_By>>;
  where?: InputMaybe<Tenant_Status_Bool_Exp>;
};

/** aggregated selection of "hasura_worker" */
export type Hasura_Worker_Aggregate = {
  __typename?: 'hasura_worker_aggregate';
  aggregate?: Maybe<Hasura_Worker_Aggregate_Fields>;
  nodes: Array<Hasura_Worker>;
};

export type Hasura_Worker_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hasura_Worker_Aggregate_Bool_Exp_Count>;
};

export type Hasura_Worker_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hasura_Worker_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hasura_Worker_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "hasura_worker" */
export type Hasura_Worker_Aggregate_Fields = {
  __typename?: 'hasura_worker_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Hasura_Worker_Max_Fields>;
  min?: Maybe<Hasura_Worker_Min_Fields>;
};

/** aggregate fields of "hasura_worker" */
export type Hasura_Worker_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hasura_Worker_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hasura_worker" */
export type Hasura_Worker_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hasura_Worker_Max_Order_By>;
  min?: InputMaybe<Hasura_Worker_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hasura_worker" */
export type Hasura_Worker_Arr_Rel_Insert_Input = {
  data: Array<Hasura_Worker_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Hasura_Worker_On_Conflict>;
};

/** Boolean expression to filter rows from the table "hasura_worker". All fields are combined with a logical 'AND'. */
export type Hasura_Worker_Bool_Exp = {
  _and?: InputMaybe<Array<Hasura_Worker_Bool_Exp>>;
  _not?: InputMaybe<Hasura_Worker_Bool_Exp>;
  _or?: InputMaybe<Array<Hasura_Worker_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  cluster?: InputMaybe<Hasura_Cluster_Bool_Exp>;
  cluster_id?: InputMaybe<Uuid_Comparison_Exp>;
  config_statuses?: InputMaybe<Config_Status_Bool_Exp>;
  config_statuses_aggregate?: InputMaybe<Config_Status_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ip?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tenant_statuses?: InputMaybe<Tenant_Status_Bool_Exp>;
  tenant_statuses_aggregate?: InputMaybe<Tenant_Status_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  zone?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "hasura_worker" */
export enum Hasura_Worker_Constraint {
  /** unique or primary key constraint on columns "slug" */
  HasuraWorkerSlugKey = 'hasura_worker_slug_key',
  /** unique or primary key constraint on columns "id" */
  HasuraWorkersPkey = 'hasura_workers_pkey',
}

/** input type for inserting data into table "hasura_worker" */
export type Hasura_Worker_Insert_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  cluster?: InputMaybe<Hasura_Cluster_Obj_Rel_Insert_Input>;
  cluster_id?: InputMaybe<Scalars['uuid']>;
  config_statuses?: InputMaybe<Config_Status_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ip?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tenant_statuses?: InputMaybe<Tenant_Status_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zone?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hasura_Worker_Max_Fields = {
  __typename?: 'hasura_worker_max_fields';
  cloud?: Maybe<Scalars['String']>;
  cluster_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zone?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hasura_worker" */
export type Hasura_Worker_Max_Order_By = {
  cloud?: InputMaybe<Order_By>;
  cluster_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hasura_Worker_Min_Fields = {
  __typename?: 'hasura_worker_min_fields';
  cloud?: Maybe<Scalars['String']>;
  cluster_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zone?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hasura_worker" */
export type Hasura_Worker_Min_Order_By = {
  cloud?: InputMaybe<Order_By>;
  cluster_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "hasura_worker" */
export type Hasura_Worker_Mutation_Response = {
  __typename?: 'hasura_worker_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hasura_Worker>;
};

/** on_conflict condition type for table "hasura_worker" */
export type Hasura_Worker_On_Conflict = {
  constraint: Hasura_Worker_Constraint;
  update_columns?: Array<Hasura_Worker_Update_Column>;
  where?: InputMaybe<Hasura_Worker_Bool_Exp>;
};

/** Ordering options when selecting data from "hasura_worker". */
export type Hasura_Worker_Order_By = {
  cloud?: InputMaybe<Order_By>;
  cluster?: InputMaybe<Hasura_Cluster_Order_By>;
  cluster_id?: InputMaybe<Order_By>;
  config_statuses_aggregate?: InputMaybe<Config_Status_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tenant_statuses_aggregate?: InputMaybe<Tenant_Status_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zone?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hasura_worker */
export type Hasura_Worker_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "hasura_worker" */
export enum Hasura_Worker_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zone = 'zone',
}

/** input type for updating data in table "hasura_worker" */
export type Hasura_Worker_Set_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  cluster_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ip?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zone?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "hasura_worker" */
export type Hasura_Worker_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hasura_Worker_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hasura_Worker_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  cluster_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  ip?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zone?: InputMaybe<Scalars['String']>;
};

/** View to visualize hasura workers and corresponding tenants */
export type Hasura_Worker_Tenants = {
  __typename?: 'hasura_worker_tenants';
  group_id?: Maybe<Scalars['uuid']>;
  hash?: Maybe<Scalars['String']>;
  policies?: Maybe<Scalars['json']>;
  port?: Maybe<Scalars['Int']>;
  /** An object relationship */
  tenant?: Maybe<Tenant>;
  tenant_id?: Maybe<Scalars['uuid']>;
  vault_path?: Maybe<Scalars['String']>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** View to visualize hasura workers and corresponding tenants */
export type Hasura_Worker_TenantsPoliciesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Aggregate = {
  __typename?: 'hasura_worker_tenants_aggregate';
  aggregate?: Maybe<Hasura_Worker_Tenants_Aggregate_Fields>;
  nodes: Array<Hasura_Worker_Tenants>;
};

export type Hasura_Worker_Tenants_Aggregate_Bool_Exp = {
  count?: InputMaybe<Hasura_Worker_Tenants_Aggregate_Bool_Exp_Count>;
};

export type Hasura_Worker_Tenants_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Hasura_Worker_Tenants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Hasura_Worker_Tenants_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Aggregate_Fields = {
  __typename?: 'hasura_worker_tenants_aggregate_fields';
  avg?: Maybe<Hasura_Worker_Tenants_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hasura_Worker_Tenants_Max_Fields>;
  min?: Maybe<Hasura_Worker_Tenants_Min_Fields>;
  stddev?: Maybe<Hasura_Worker_Tenants_Stddev_Fields>;
  stddev_pop?: Maybe<Hasura_Worker_Tenants_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hasura_Worker_Tenants_Stddev_Samp_Fields>;
  sum?: Maybe<Hasura_Worker_Tenants_Sum_Fields>;
  var_pop?: Maybe<Hasura_Worker_Tenants_Var_Pop_Fields>;
  var_samp?: Maybe<Hasura_Worker_Tenants_Var_Samp_Fields>;
  variance?: Maybe<Hasura_Worker_Tenants_Variance_Fields>;
};

/** aggregate fields of "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hasura_Worker_Tenants_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Aggregate_Order_By = {
  avg?: InputMaybe<Hasura_Worker_Tenants_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Hasura_Worker_Tenants_Max_Order_By>;
  min?: InputMaybe<Hasura_Worker_Tenants_Min_Order_By>;
  stddev?: InputMaybe<Hasura_Worker_Tenants_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Hasura_Worker_Tenants_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Hasura_Worker_Tenants_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Hasura_Worker_Tenants_Sum_Order_By>;
  var_pop?: InputMaybe<Hasura_Worker_Tenants_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Hasura_Worker_Tenants_Var_Samp_Order_By>;
  variance?: InputMaybe<Hasura_Worker_Tenants_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Arr_Rel_Insert_Input = {
  data: Array<Hasura_Worker_Tenants_Insert_Input>;
};

/** aggregate avg on columns */
export type Hasura_Worker_Tenants_Avg_Fields = {
  __typename?: 'hasura_worker_tenants_avg_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Avg_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hasura_worker_tenants". All fields are combined with a logical 'AND'. */
export type Hasura_Worker_Tenants_Bool_Exp = {
  _and?: InputMaybe<Array<Hasura_Worker_Tenants_Bool_Exp>>;
  _not?: InputMaybe<Hasura_Worker_Tenants_Bool_Exp>;
  _or?: InputMaybe<Array<Hasura_Worker_Tenants_Bool_Exp>>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  hash?: InputMaybe<String_Comparison_Exp>;
  policies?: InputMaybe<Json_Comparison_Exp>;
  port?: InputMaybe<Int_Comparison_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  vault_path?: InputMaybe<String_Comparison_Exp>;
  worker_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Insert_Input = {
  group_id?: InputMaybe<Scalars['uuid']>;
  hash?: InputMaybe<Scalars['String']>;
  policies?: InputMaybe<Scalars['json']>;
  port?: InputMaybe<Scalars['Int']>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  vault_path?: InputMaybe<Scalars['String']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Hasura_Worker_Tenants_Max_Fields = {
  __typename?: 'hasura_worker_tenants_max_fields';
  group_id?: Maybe<Scalars['uuid']>;
  hash?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  vault_path?: Maybe<Scalars['String']>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Max_Order_By = {
  group_id?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  port?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  vault_path?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Hasura_Worker_Tenants_Min_Fields = {
  __typename?: 'hasura_worker_tenants_min_fields';
  group_id?: Maybe<Scalars['uuid']>;
  hash?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  vault_path?: Maybe<Scalars['String']>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Min_Order_By = {
  group_id?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  port?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  vault_path?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "hasura_worker_tenants". */
export type Hasura_Worker_Tenants_Order_By = {
  group_id?: InputMaybe<Order_By>;
  hash?: InputMaybe<Order_By>;
  policies?: InputMaybe<Order_By>;
  port?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  vault_path?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** select columns of table "hasura_worker_tenants" */
export enum Hasura_Worker_Tenants_Select_Column {
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Hash = 'hash',
  /** column name */
  Policies = 'policies',
  /** column name */
  Port = 'port',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  VaultPath = 'vault_path',
  /** column name */
  WorkerId = 'worker_id',
}

/** aggregate stddev on columns */
export type Hasura_Worker_Tenants_Stddev_Fields = {
  __typename?: 'hasura_worker_tenants_stddev_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Stddev_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hasura_Worker_Tenants_Stddev_Pop_Fields = {
  __typename?: 'hasura_worker_tenants_stddev_pop_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Stddev_Pop_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hasura_Worker_Tenants_Stddev_Samp_Fields = {
  __typename?: 'hasura_worker_tenants_stddev_samp_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Stddev_Samp_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hasura_Worker_Tenants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hasura_Worker_Tenants_Stream_Cursor_Value_Input = {
  group_id?: InputMaybe<Scalars['uuid']>;
  hash?: InputMaybe<Scalars['String']>;
  policies?: InputMaybe<Scalars['json']>;
  port?: InputMaybe<Scalars['Int']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  vault_path?: InputMaybe<Scalars['String']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Hasura_Worker_Tenants_Sum_Fields = {
  __typename?: 'hasura_worker_tenants_sum_fields';
  port?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Sum_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Hasura_Worker_Tenants_Var_Pop_Fields = {
  __typename?: 'hasura_worker_tenants_var_pop_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Var_Pop_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hasura_Worker_Tenants_Var_Samp_Fields = {
  __typename?: 'hasura_worker_tenants_var_samp_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Var_Samp_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Hasura_Worker_Tenants_Variance_Fields = {
  __typename?: 'hasura_worker_tenants_variance_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hasura_worker_tenants" */
export type Hasura_Worker_Tenants_Variance_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** update columns of table "hasura_worker" */
export enum Hasura_Worker_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  ClusterId = 'cluster_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Zone = 'zone',
}

export type Hasura_Worker_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hasura_Worker_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hasura_Worker_Bool_Exp;
};

/** Tracks activity for Hasura Year In Report */
export type Hasura_Year_In_Report_Activity = {
  __typename?: 'hasura_year_in_report_activity';
  activity?: Maybe<Scalars['jsonb']>;
  user_id: Scalars['uuid'];
};

/** Tracks activity for Hasura Year In Report */
export type Hasura_Year_In_Report_ActivityActivityArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hasura_year_in_report_activity" */
export type Hasura_Year_In_Report_Activity_Aggregate = {
  __typename?: 'hasura_year_in_report_activity_aggregate';
  aggregate?: Maybe<Hasura_Year_In_Report_Activity_Aggregate_Fields>;
  nodes: Array<Hasura_Year_In_Report_Activity>;
};

/** aggregate fields of "hasura_year_in_report_activity" */
export type Hasura_Year_In_Report_Activity_Aggregate_Fields = {
  __typename?: 'hasura_year_in_report_activity_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Hasura_Year_In_Report_Activity_Max_Fields>;
  min?: Maybe<Hasura_Year_In_Report_Activity_Min_Fields>;
};

/** aggregate fields of "hasura_year_in_report_activity" */
export type Hasura_Year_In_Report_Activity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hasura_Year_In_Report_Activity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hasura_Year_In_Report_Activity_Append_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "hasura_year_in_report_activity". All fields are combined with a logical 'AND'. */
export type Hasura_Year_In_Report_Activity_Bool_Exp = {
  _and?: InputMaybe<Array<Hasura_Year_In_Report_Activity_Bool_Exp>>;
  _not?: InputMaybe<Hasura_Year_In_Report_Activity_Bool_Exp>;
  _or?: InputMaybe<Array<Hasura_Year_In_Report_Activity_Bool_Exp>>;
  activity?: InputMaybe<Jsonb_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "hasura_year_in_report_activity" */
export enum Hasura_Year_In_Report_Activity_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  HasuraYearInReportActivityPkey = 'hasura_year_in_report_activity_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hasura_Year_In_Report_Activity_Delete_At_Path_Input = {
  activity?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hasura_Year_In_Report_Activity_Delete_Elem_Input = {
  activity?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hasura_Year_In_Report_Activity_Delete_Key_Input = {
  activity?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "hasura_year_in_report_activity" */
export type Hasura_Year_In_Report_Activity_Insert_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Hasura_Year_In_Report_Activity_Max_Fields = {
  __typename?: 'hasura_year_in_report_activity_max_fields';
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Hasura_Year_In_Report_Activity_Min_Fields = {
  __typename?: 'hasura_year_in_report_activity_min_fields';
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "hasura_year_in_report_activity" */
export type Hasura_Year_In_Report_Activity_Mutation_Response = {
  __typename?: 'hasura_year_in_report_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hasura_Year_In_Report_Activity>;
};

/** on_conflict condition type for table "hasura_year_in_report_activity" */
export type Hasura_Year_In_Report_Activity_On_Conflict = {
  constraint: Hasura_Year_In_Report_Activity_Constraint;
  update_columns?: Array<Hasura_Year_In_Report_Activity_Update_Column>;
  where?: InputMaybe<Hasura_Year_In_Report_Activity_Bool_Exp>;
};

/** Ordering options when selecting data from "hasura_year_in_report_activity". */
export type Hasura_Year_In_Report_Activity_Order_By = {
  activity?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hasura_year_in_report_activity */
export type Hasura_Year_In_Report_Activity_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hasura_Year_In_Report_Activity_Prepend_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hasura_year_in_report_activity" */
export enum Hasura_Year_In_Report_Activity_Select_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "hasura_year_in_report_activity" */
export type Hasura_Year_In_Report_Activity_Set_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "hasura_year_in_report_activity" */
export type Hasura_Year_In_Report_Activity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hasura_Year_In_Report_Activity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hasura_Year_In_Report_Activity_Stream_Cursor_Value_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "hasura_year_in_report_activity" */
export enum Hasura_Year_In_Report_Activity_Update_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  UserId = 'user_id',
}

export type Hasura_Year_In_Report_Activity_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hasura_Year_In_Report_Activity_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<
    Hasura_Year_In_Report_Activity_Delete_At_Path_Input
  >;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hasura_Year_In_Report_Activity_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hasura_Year_In_Report_Activity_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hasura_Year_In_Report_Activity_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hasura_Year_In_Report_Activity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hasura_Year_In_Report_Activity_Bool_Exp;
};

/** columns and relationships of "hasura_year_in_report_metrics" */
export type Hasura_Year_In_Report_Metrics = {
  __typename?: 'hasura_year_in_report_metrics';
  api_requests: Scalars['String'];
  card_links?: Maybe<Scalars['jsonb']>;
  data_models: Scalars['Int'];
  data_passthrough: Scalars['String'];
  data_sources: Scalars['Int'];
  id: Scalars['uuid'];
  tier: Scalars['String'];
  total_collaborators: Scalars['Int'];
  total_projects: Scalars['Int'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** columns and relationships of "hasura_year_in_report_metrics" */
export type Hasura_Year_In_Report_MetricsCard_LinksArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "hasura_year_in_report_metrics" */
export type Hasura_Year_In_Report_Metrics_Aggregate = {
  __typename?: 'hasura_year_in_report_metrics_aggregate';
  aggregate?: Maybe<Hasura_Year_In_Report_Metrics_Aggregate_Fields>;
  nodes: Array<Hasura_Year_In_Report_Metrics>;
};

/** aggregate fields of "hasura_year_in_report_metrics" */
export type Hasura_Year_In_Report_Metrics_Aggregate_Fields = {
  __typename?: 'hasura_year_in_report_metrics_aggregate_fields';
  avg?: Maybe<Hasura_Year_In_Report_Metrics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hasura_Year_In_Report_Metrics_Max_Fields>;
  min?: Maybe<Hasura_Year_In_Report_Metrics_Min_Fields>;
  stddev?: Maybe<Hasura_Year_In_Report_Metrics_Stddev_Fields>;
  stddev_pop?: Maybe<Hasura_Year_In_Report_Metrics_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hasura_Year_In_Report_Metrics_Stddev_Samp_Fields>;
  sum?: Maybe<Hasura_Year_In_Report_Metrics_Sum_Fields>;
  var_pop?: Maybe<Hasura_Year_In_Report_Metrics_Var_Pop_Fields>;
  var_samp?: Maybe<Hasura_Year_In_Report_Metrics_Var_Samp_Fields>;
  variance?: Maybe<Hasura_Year_In_Report_Metrics_Variance_Fields>;
};

/** aggregate fields of "hasura_year_in_report_metrics" */
export type Hasura_Year_In_Report_Metrics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Hasura_Year_In_Report_Metrics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Hasura_Year_In_Report_Metrics_Append_Input = {
  card_links?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Hasura_Year_In_Report_Metrics_Avg_Fields = {
  __typename?: 'hasura_year_in_report_metrics_avg_fields';
  data_models?: Maybe<Scalars['Float']>;
  data_sources?: Maybe<Scalars['Float']>;
  total_collaborators?: Maybe<Scalars['Float']>;
  total_projects?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "hasura_year_in_report_metrics". All fields are combined with a logical 'AND'. */
export type Hasura_Year_In_Report_Metrics_Bool_Exp = {
  _and?: InputMaybe<Array<Hasura_Year_In_Report_Metrics_Bool_Exp>>;
  _not?: InputMaybe<Hasura_Year_In_Report_Metrics_Bool_Exp>;
  _or?: InputMaybe<Array<Hasura_Year_In_Report_Metrics_Bool_Exp>>;
  api_requests?: InputMaybe<String_Comparison_Exp>;
  card_links?: InputMaybe<Jsonb_Comparison_Exp>;
  data_models?: InputMaybe<Int_Comparison_Exp>;
  data_passthrough?: InputMaybe<String_Comparison_Exp>;
  data_sources?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tier?: InputMaybe<String_Comparison_Exp>;
  total_collaborators?: InputMaybe<Int_Comparison_Exp>;
  total_projects?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "hasura_year_in_report_metrics" */
export enum Hasura_Year_In_Report_Metrics_Constraint {
  /** unique or primary key constraint on columns "id" */
  HasuraYearInReportMetricsPkey = 'hasura_year_in_report_metrics_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Hasura_Year_In_Report_Metrics_Delete_At_Path_Input = {
  card_links?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Hasura_Year_In_Report_Metrics_Delete_Elem_Input = {
  card_links?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Hasura_Year_In_Report_Metrics_Delete_Key_Input = {
  card_links?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "hasura_year_in_report_metrics" */
export type Hasura_Year_In_Report_Metrics_Inc_Input = {
  data_models?: InputMaybe<Scalars['Int']>;
  data_sources?: InputMaybe<Scalars['Int']>;
  total_collaborators?: InputMaybe<Scalars['Int']>;
  total_projects?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "hasura_year_in_report_metrics" */
export type Hasura_Year_In_Report_Metrics_Insert_Input = {
  api_requests?: InputMaybe<Scalars['String']>;
  card_links?: InputMaybe<Scalars['jsonb']>;
  data_models?: InputMaybe<Scalars['Int']>;
  data_passthrough?: InputMaybe<Scalars['String']>;
  data_sources?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  tier?: InputMaybe<Scalars['String']>;
  total_collaborators?: InputMaybe<Scalars['Int']>;
  total_projects?: InputMaybe<Scalars['Int']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Hasura_Year_In_Report_Metrics_Max_Fields = {
  __typename?: 'hasura_year_in_report_metrics_max_fields';
  api_requests?: Maybe<Scalars['String']>;
  data_models?: Maybe<Scalars['Int']>;
  data_passthrough?: Maybe<Scalars['String']>;
  data_sources?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  total_collaborators?: Maybe<Scalars['Int']>;
  total_projects?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Hasura_Year_In_Report_Metrics_Min_Fields = {
  __typename?: 'hasura_year_in_report_metrics_min_fields';
  api_requests?: Maybe<Scalars['String']>;
  data_models?: Maybe<Scalars['Int']>;
  data_passthrough?: Maybe<Scalars['String']>;
  data_sources?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  tier?: Maybe<Scalars['String']>;
  total_collaborators?: Maybe<Scalars['Int']>;
  total_projects?: Maybe<Scalars['Int']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "hasura_year_in_report_metrics" */
export type Hasura_Year_In_Report_Metrics_Mutation_Response = {
  __typename?: 'hasura_year_in_report_metrics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hasura_Year_In_Report_Metrics>;
};

/** on_conflict condition type for table "hasura_year_in_report_metrics" */
export type Hasura_Year_In_Report_Metrics_On_Conflict = {
  constraint: Hasura_Year_In_Report_Metrics_Constraint;
  update_columns?: Array<Hasura_Year_In_Report_Metrics_Update_Column>;
  where?: InputMaybe<Hasura_Year_In_Report_Metrics_Bool_Exp>;
};

/** Ordering options when selecting data from "hasura_year_in_report_metrics". */
export type Hasura_Year_In_Report_Metrics_Order_By = {
  api_requests?: InputMaybe<Order_By>;
  card_links?: InputMaybe<Order_By>;
  data_models?: InputMaybe<Order_By>;
  data_passthrough?: InputMaybe<Order_By>;
  data_sources?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tier?: InputMaybe<Order_By>;
  total_collaborators?: InputMaybe<Order_By>;
  total_projects?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: hasura_year_in_report_metrics */
export type Hasura_Year_In_Report_Metrics_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Hasura_Year_In_Report_Metrics_Prepend_Input = {
  card_links?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "hasura_year_in_report_metrics" */
export enum Hasura_Year_In_Report_Metrics_Select_Column {
  /** column name */
  ApiRequests = 'api_requests',
  /** column name */
  CardLinks = 'card_links',
  /** column name */
  DataModels = 'data_models',
  /** column name */
  DataPassthrough = 'data_passthrough',
  /** column name */
  DataSources = 'data_sources',
  /** column name */
  Id = 'id',
  /** column name */
  Tier = 'tier',
  /** column name */
  TotalCollaborators = 'total_collaborators',
  /** column name */
  TotalProjects = 'total_projects',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "hasura_year_in_report_metrics" */
export type Hasura_Year_In_Report_Metrics_Set_Input = {
  api_requests?: InputMaybe<Scalars['String']>;
  card_links?: InputMaybe<Scalars['jsonb']>;
  data_models?: InputMaybe<Scalars['Int']>;
  data_passthrough?: InputMaybe<Scalars['String']>;
  data_sources?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  tier?: InputMaybe<Scalars['String']>;
  total_collaborators?: InputMaybe<Scalars['Int']>;
  total_projects?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Hasura_Year_In_Report_Metrics_Stddev_Fields = {
  __typename?: 'hasura_year_in_report_metrics_stddev_fields';
  data_models?: Maybe<Scalars['Float']>;
  data_sources?: Maybe<Scalars['Float']>;
  total_collaborators?: Maybe<Scalars['Float']>;
  total_projects?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Hasura_Year_In_Report_Metrics_Stddev_Pop_Fields = {
  __typename?: 'hasura_year_in_report_metrics_stddev_pop_fields';
  data_models?: Maybe<Scalars['Float']>;
  data_sources?: Maybe<Scalars['Float']>;
  total_collaborators?: Maybe<Scalars['Float']>;
  total_projects?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Hasura_Year_In_Report_Metrics_Stddev_Samp_Fields = {
  __typename?: 'hasura_year_in_report_metrics_stddev_samp_fields';
  data_models?: Maybe<Scalars['Float']>;
  data_sources?: Maybe<Scalars['Float']>;
  total_collaborators?: Maybe<Scalars['Float']>;
  total_projects?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "hasura_year_in_report_metrics" */
export type Hasura_Year_In_Report_Metrics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Hasura_Year_In_Report_Metrics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Hasura_Year_In_Report_Metrics_Stream_Cursor_Value_Input = {
  api_requests?: InputMaybe<Scalars['String']>;
  card_links?: InputMaybe<Scalars['jsonb']>;
  data_models?: InputMaybe<Scalars['Int']>;
  data_passthrough?: InputMaybe<Scalars['String']>;
  data_sources?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  tier?: InputMaybe<Scalars['String']>;
  total_collaborators?: InputMaybe<Scalars['Int']>;
  total_projects?: InputMaybe<Scalars['Int']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Hasura_Year_In_Report_Metrics_Sum_Fields = {
  __typename?: 'hasura_year_in_report_metrics_sum_fields';
  data_models?: Maybe<Scalars['Int']>;
  data_sources?: Maybe<Scalars['Int']>;
  total_collaborators?: Maybe<Scalars['Int']>;
  total_projects?: Maybe<Scalars['Int']>;
};

/** update columns of table "hasura_year_in_report_metrics" */
export enum Hasura_Year_In_Report_Metrics_Update_Column {
  /** column name */
  ApiRequests = 'api_requests',
  /** column name */
  CardLinks = 'card_links',
  /** column name */
  DataModels = 'data_models',
  /** column name */
  DataPassthrough = 'data_passthrough',
  /** column name */
  DataSources = 'data_sources',
  /** column name */
  Id = 'id',
  /** column name */
  Tier = 'tier',
  /** column name */
  TotalCollaborators = 'total_collaborators',
  /** column name */
  TotalProjects = 'total_projects',
  /** column name */
  UserId = 'user_id',
}

export type Hasura_Year_In_Report_Metrics_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Hasura_Year_In_Report_Metrics_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<
    Hasura_Year_In_Report_Metrics_Delete_At_Path_Input
  >;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Hasura_Year_In_Report_Metrics_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Hasura_Year_In_Report_Metrics_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Hasura_Year_In_Report_Metrics_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Hasura_Year_In_Report_Metrics_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Hasura_Year_In_Report_Metrics_Set_Input>;
  /** filter the rows which have to be updated */
  where: Hasura_Year_In_Report_Metrics_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Hasura_Year_In_Report_Metrics_Var_Pop_Fields = {
  __typename?: 'hasura_year_in_report_metrics_var_pop_fields';
  data_models?: Maybe<Scalars['Float']>;
  data_sources?: Maybe<Scalars['Float']>;
  total_collaborators?: Maybe<Scalars['Float']>;
  total_projects?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Hasura_Year_In_Report_Metrics_Var_Samp_Fields = {
  __typename?: 'hasura_year_in_report_metrics_var_samp_fields';
  data_models?: Maybe<Scalars['Float']>;
  data_sources?: Maybe<Scalars['Float']>;
  total_collaborators?: Maybe<Scalars['Float']>;
  total_projects?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Hasura_Year_In_Report_Metrics_Variance_Fields = {
  __typename?: 'hasura_year_in_report_metrics_variance_fields';
  data_models?: Maybe<Scalars['Float']>;
  data_sources?: Maybe<Scalars['Float']>;
  total_collaborators?: Maybe<Scalars['Float']>;
  total_projects?: Maybe<Scalars['Float']>;
};

/** heroku integration metadata (1.4+) */
export type Heroku_Integrations = {
  __typename?: 'heroku_integrations';
  /** The agent through which the integration is created */
  agent: Scalars['String'];
  app_id: Scalars['String'];
  app_name: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  var_name: Scalars['String'];
  webhook_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "heroku_integrations" */
export type Heroku_Integrations_Aggregate = {
  __typename?: 'heroku_integrations_aggregate';
  aggregate?: Maybe<Heroku_Integrations_Aggregate_Fields>;
  nodes: Array<Heroku_Integrations>;
};

export type Heroku_Integrations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Heroku_Integrations_Aggregate_Bool_Exp_Count>;
};

export type Heroku_Integrations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Heroku_Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Heroku_Integrations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "heroku_integrations" */
export type Heroku_Integrations_Aggregate_Fields = {
  __typename?: 'heroku_integrations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Heroku_Integrations_Max_Fields>;
  min?: Maybe<Heroku_Integrations_Min_Fields>;
};

/** aggregate fields of "heroku_integrations" */
export type Heroku_Integrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Heroku_Integrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "heroku_integrations" */
export type Heroku_Integrations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Heroku_Integrations_Max_Order_By>;
  min?: InputMaybe<Heroku_Integrations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "heroku_integrations" */
export type Heroku_Integrations_Arr_Rel_Insert_Input = {
  data: Array<Heroku_Integrations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Heroku_Integrations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "heroku_integrations". All fields are combined with a logical 'AND'. */
export type Heroku_Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Heroku_Integrations_Bool_Exp>>;
  _not?: InputMaybe<Heroku_Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Heroku_Integrations_Bool_Exp>>;
  agent?: InputMaybe<String_Comparison_Exp>;
  app_id?: InputMaybe<String_Comparison_Exp>;
  app_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  var_name?: InputMaybe<String_Comparison_Exp>;
  webhook_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "heroku_integrations" */
export enum Heroku_Integrations_Constraint {
  /** unique or primary key constraint on columns "project_id", "var_name" */
  HerokuIntegrationsPkey = 'heroku_integrations_pkey',
}

/** input type for inserting data into table "heroku_integrations" */
export type Heroku_Integrations_Insert_Input = {
  /** The agent through which the integration is created */
  agent?: InputMaybe<Scalars['String']>;
  app_id?: InputMaybe<Scalars['String']>;
  app_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  var_name?: InputMaybe<Scalars['String']>;
  webhook_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Heroku_Integrations_Max_Fields = {
  __typename?: 'heroku_integrations_max_fields';
  /** The agent through which the integration is created */
  agent?: Maybe<Scalars['String']>;
  app_id?: Maybe<Scalars['String']>;
  app_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  var_name?: Maybe<Scalars['String']>;
  webhook_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "heroku_integrations" */
export type Heroku_Integrations_Max_Order_By = {
  /** The agent through which the integration is created */
  agent?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  app_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  var_name?: InputMaybe<Order_By>;
  webhook_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Heroku_Integrations_Min_Fields = {
  __typename?: 'heroku_integrations_min_fields';
  /** The agent through which the integration is created */
  agent?: Maybe<Scalars['String']>;
  app_id?: Maybe<Scalars['String']>;
  app_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  var_name?: Maybe<Scalars['String']>;
  webhook_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "heroku_integrations" */
export type Heroku_Integrations_Min_Order_By = {
  /** The agent through which the integration is created */
  agent?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  app_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  var_name?: InputMaybe<Order_By>;
  webhook_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "heroku_integrations" */
export type Heroku_Integrations_Mutation_Response = {
  __typename?: 'heroku_integrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Heroku_Integrations>;
};

/** on_conflict condition type for table "heroku_integrations" */
export type Heroku_Integrations_On_Conflict = {
  constraint: Heroku_Integrations_Constraint;
  update_columns?: Array<Heroku_Integrations_Update_Column>;
  where?: InputMaybe<Heroku_Integrations_Bool_Exp>;
};

/** Ordering options when selecting data from "heroku_integrations". */
export type Heroku_Integrations_Order_By = {
  agent?: InputMaybe<Order_By>;
  app_id?: InputMaybe<Order_By>;
  app_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  var_name?: InputMaybe<Order_By>;
  webhook_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: heroku_integrations */
export type Heroku_Integrations_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
  var_name: Scalars['String'];
};

/** select columns of table "heroku_integrations" */
export enum Heroku_Integrations_Select_Column {
  /** column name */
  Agent = 'agent',
  /** column name */
  AppId = 'app_id',
  /** column name */
  AppName = 'app_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VarName = 'var_name',
  /** column name */
  WebhookId = 'webhook_id',
}

/** input type for updating data in table "heroku_integrations" */
export type Heroku_Integrations_Set_Input = {
  /** The agent through which the integration is created */
  agent?: InputMaybe<Scalars['String']>;
  app_id?: InputMaybe<Scalars['String']>;
  app_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  var_name?: InputMaybe<Scalars['String']>;
  webhook_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "heroku_integrations" */
export type Heroku_Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Heroku_Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Heroku_Integrations_Stream_Cursor_Value_Input = {
  /** The agent through which the integration is created */
  agent?: InputMaybe<Scalars['String']>;
  app_id?: InputMaybe<Scalars['String']>;
  app_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  var_name?: InputMaybe<Scalars['String']>;
  webhook_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "heroku_integrations" */
export enum Heroku_Integrations_Update_Column {
  /** column name */
  Agent = 'agent',
  /** column name */
  AppId = 'app_id',
  /** column name */
  AppName = 'app_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VarName = 'var_name',
  /** column name */
  WebhookId = 'webhook_id',
}

export type Heroku_Integrations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Heroku_Integrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Heroku_Integrations_Bool_Exp;
};

/** columns and relationships of "hit_count_monthly" */
export type Hit_Count_Monthly = {
  __typename?: 'hit_count_monthly';
  count: Scalars['u_int64'];
  entity_name: Scalars['string'];
  entity_type: Scalars['string'];
  environment_id: Scalars['UUID'];
  month: Scalars['u_int8'];
  project_id: Scalars['UUID'];
  subgraph: Scalars['string'];
  year: Scalars['u_int16'];
};

/** aggregated selection of "hit_count_monthly" */
export type Hit_Count_Monthly_Aggregate = {
  __typename?: 'hit_count_monthly_aggregate';
  aggregate?: Maybe<Hit_Count_Monthly_Aggregate_Fields>;
  nodes: Array<Hit_Count_Monthly>;
};

/** aggregate fields of "hit_count_monthly" */
export type Hit_Count_Monthly_Aggregate_Fields = {
  __typename?: 'hit_count_monthly_aggregate_fields';
  avg?: Maybe<Hit_Count_Monthly_Avg_Fields>;
  count: Scalars['Int'];
  longest?: Maybe<Hit_Count_Monthly_Longest_Fields>;
  max?: Maybe<Hit_Count_Monthly_Max_Fields>;
  min?: Maybe<Hit_Count_Monthly_Min_Fields>;
  shortest?: Maybe<Hit_Count_Monthly_Shortest_Fields>;
  stddev_pop?: Maybe<Hit_Count_Monthly_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hit_Count_Monthly_Stddev_Samp_Fields>;
  sum?: Maybe<Hit_Count_Monthly_Sum_Fields>;
  var_pop?: Maybe<Hit_Count_Monthly_Var_Pop_Fields>;
  var_samp?: Maybe<Hit_Count_Monthly_Var_Samp_Fields>;
};

/** aggregate fields of "hit_count_monthly" */
export type Hit_Count_Monthly_Aggregate_FieldsCountArgs = {
  column?: InputMaybe<Hit_Count_Monthly_Select_Column>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Hit_Count_Monthly_Avg_Fields = {
  __typename?: 'hit_count_monthly_avg_fields';
  count?: Maybe<Scalars['float64']>;
  month?: Maybe<Scalars['float64']>;
  year?: Maybe<Scalars['float64']>;
};

/** Boolean expression to filter rows from the table "hit_count_monthly". All fields are combined with a logical 'AND'. */
export type Hit_Count_Monthly_Bool_Exp = {
  _and?: InputMaybe<Array<Hit_Count_Monthly_Bool_Exp>>;
  _not?: InputMaybe<Hit_Count_Monthly_Bool_Exp>;
  _or?: InputMaybe<Array<Hit_Count_Monthly_Bool_Exp>>;
  count?: InputMaybe<U_Int64_Clickhouse_Comparison_Exp>;
  entity_name?: InputMaybe<String_Clickhouse_Comparison_Exp>;
  entity_type?: InputMaybe<String_Clickhouse_Comparison_Exp>;
  environment_id?: InputMaybe<Uuid_Clickhouse_Comparison_Exp>;
  month?: InputMaybe<U_Int8_Clickhouse_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Clickhouse_Comparison_Exp>;
  subgraph?: InputMaybe<String_Clickhouse_Comparison_Exp>;
  year?: InputMaybe<U_Int16_Clickhouse_Comparison_Exp>;
};

/** aggregate longest on columns */
export type Hit_Count_Monthly_Longest_Fields = {
  __typename?: 'hit_count_monthly_longest_fields';
  entity_name?: Maybe<Scalars['string']>;
  entity_type?: Maybe<Scalars['string']>;
  subgraph?: Maybe<Scalars['string']>;
};

/** aggregate max on columns */
export type Hit_Count_Monthly_Max_Fields = {
  __typename?: 'hit_count_monthly_max_fields';
  count?: Maybe<Scalars['u_int64']>;
  entity_name?: Maybe<Scalars['string']>;
  entity_type?: Maybe<Scalars['string']>;
  month?: Maybe<Scalars['u_int8']>;
  subgraph?: Maybe<Scalars['string']>;
  year?: Maybe<Scalars['u_int16']>;
};

/** aggregate min on columns */
export type Hit_Count_Monthly_Min_Fields = {
  __typename?: 'hit_count_monthly_min_fields';
  count?: Maybe<Scalars['u_int64']>;
  entity_name?: Maybe<Scalars['string']>;
  entity_type?: Maybe<Scalars['string']>;
  month?: Maybe<Scalars['u_int8']>;
  subgraph?: Maybe<Scalars['string']>;
  year?: Maybe<Scalars['u_int16']>;
};

/** columns and relationships of "hit_count_monthly_mv" */
export type Hit_Count_Monthly_Mv = {
  __typename?: 'hit_count_monthly_mv';
  count: Scalars['u_int64'];
  entity_name: Scalars['string'];
  entity_type: Scalars['string'];
  environment_id: Scalars['UUID'];
  month: Scalars['u_int8'];
  project_id: Scalars['UUID'];
  subgraph: Scalars['string'];
  year: Scalars['u_int16'];
};

/** aggregated selection of "hit_count_monthly_mv" */
export type Hit_Count_Monthly_Mv_Aggregate = {
  __typename?: 'hit_count_monthly_mv_aggregate';
  aggregate?: Maybe<Hit_Count_Monthly_Mv_Aggregate_Fields>;
  nodes: Array<Hit_Count_Monthly_Mv>;
};

/** aggregate fields of "hit_count_monthly_mv" */
export type Hit_Count_Monthly_Mv_Aggregate_Fields = {
  __typename?: 'hit_count_monthly_mv_aggregate_fields';
  avg?: Maybe<Hit_Count_Monthly_Mv_Avg_Fields>;
  count: Scalars['Int'];
  longest?: Maybe<Hit_Count_Monthly_Mv_Longest_Fields>;
  max?: Maybe<Hit_Count_Monthly_Mv_Max_Fields>;
  min?: Maybe<Hit_Count_Monthly_Mv_Min_Fields>;
  shortest?: Maybe<Hit_Count_Monthly_Mv_Shortest_Fields>;
  stddev_pop?: Maybe<Hit_Count_Monthly_Mv_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hit_Count_Monthly_Mv_Stddev_Samp_Fields>;
  sum?: Maybe<Hit_Count_Monthly_Mv_Sum_Fields>;
  var_pop?: Maybe<Hit_Count_Monthly_Mv_Var_Pop_Fields>;
  var_samp?: Maybe<Hit_Count_Monthly_Mv_Var_Samp_Fields>;
};

/** aggregate fields of "hit_count_monthly_mv" */
export type Hit_Count_Monthly_Mv_Aggregate_FieldsCountArgs = {
  column?: InputMaybe<Hit_Count_Monthly_Mv_Select_Column>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Hit_Count_Monthly_Mv_Avg_Fields = {
  __typename?: 'hit_count_monthly_mv_avg_fields';
  count?: Maybe<Scalars['float64']>;
  month?: Maybe<Scalars['float64']>;
  year?: Maybe<Scalars['float64']>;
};

/** Boolean expression to filter rows from the table "hit_count_monthly_mv". All fields are combined with a logical 'AND'. */
export type Hit_Count_Monthly_Mv_Bool_Exp = {
  _and?: InputMaybe<Array<Hit_Count_Monthly_Mv_Bool_Exp>>;
  _not?: InputMaybe<Hit_Count_Monthly_Mv_Bool_Exp>;
  _or?: InputMaybe<Array<Hit_Count_Monthly_Mv_Bool_Exp>>;
  count?: InputMaybe<U_Int64_Clickhouse_Comparison_Exp>;
  entity_name?: InputMaybe<String_Clickhouse_Comparison_Exp>;
  entity_type?: InputMaybe<String_Clickhouse_Comparison_Exp>;
  environment_id?: InputMaybe<Uuid_Clickhouse_Comparison_Exp>;
  month?: InputMaybe<U_Int8_Clickhouse_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Clickhouse_Comparison_Exp>;
  subgraph?: InputMaybe<String_Clickhouse_Comparison_Exp>;
  year?: InputMaybe<U_Int16_Clickhouse_Comparison_Exp>;
};

/** aggregate longest on columns */
export type Hit_Count_Monthly_Mv_Longest_Fields = {
  __typename?: 'hit_count_monthly_mv_longest_fields';
  entity_name?: Maybe<Scalars['string']>;
  entity_type?: Maybe<Scalars['string']>;
  subgraph?: Maybe<Scalars['string']>;
};

/** aggregate max on columns */
export type Hit_Count_Monthly_Mv_Max_Fields = {
  __typename?: 'hit_count_monthly_mv_max_fields';
  count?: Maybe<Scalars['u_int64']>;
  entity_name?: Maybe<Scalars['string']>;
  entity_type?: Maybe<Scalars['string']>;
  month?: Maybe<Scalars['u_int8']>;
  subgraph?: Maybe<Scalars['string']>;
  year?: Maybe<Scalars['u_int16']>;
};

/** aggregate min on columns */
export type Hit_Count_Monthly_Mv_Min_Fields = {
  __typename?: 'hit_count_monthly_mv_min_fields';
  count?: Maybe<Scalars['u_int64']>;
  entity_name?: Maybe<Scalars['string']>;
  entity_type?: Maybe<Scalars['string']>;
  month?: Maybe<Scalars['u_int8']>;
  subgraph?: Maybe<Scalars['string']>;
  year?: Maybe<Scalars['u_int16']>;
};

/** Ordering options when selecting data from "hit_count_monthly_mv". */
export type Hit_Count_Monthly_Mv_Order_By = {
  count?: InputMaybe<Clickhouse_Order_By>;
  entity_name?: InputMaybe<Clickhouse_Order_By>;
  entity_type?: InputMaybe<Clickhouse_Order_By>;
  environment_id?: InputMaybe<Clickhouse_Order_By>;
  month?: InputMaybe<Clickhouse_Order_By>;
  project_id?: InputMaybe<Clickhouse_Order_By>;
  subgraph?: InputMaybe<Clickhouse_Order_By>;
  year?: InputMaybe<Clickhouse_Order_By>;
};

/** select columns of table "hit_count_monthly_mv" */
export enum Hit_Count_Monthly_Mv_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  Month = 'month',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Subgraph = 'subgraph',
  /** column name */
  Year = 'year',
}

/** aggregate shortest on columns */
export type Hit_Count_Monthly_Mv_Shortest_Fields = {
  __typename?: 'hit_count_monthly_mv_shortest_fields';
  entity_name?: Maybe<Scalars['string']>;
  entity_type?: Maybe<Scalars['string']>;
  subgraph?: Maybe<Scalars['string']>;
};

/** aggregate stddev_pop on columns */
export type Hit_Count_Monthly_Mv_Stddev_Pop_Fields = {
  __typename?: 'hit_count_monthly_mv_stddev_pop_fields';
  count?: Maybe<Scalars['float64']>;
  month?: Maybe<Scalars['float64']>;
  year?: Maybe<Scalars['float64']>;
};

/** aggregate stddev_samp on columns */
export type Hit_Count_Monthly_Mv_Stddev_Samp_Fields = {
  __typename?: 'hit_count_monthly_mv_stddev_samp_fields';
  count?: Maybe<Scalars['float64']>;
  month?: Maybe<Scalars['float64']>;
  year?: Maybe<Scalars['float64']>;
};

/** aggregate sum on columns */
export type Hit_Count_Monthly_Mv_Sum_Fields = {
  __typename?: 'hit_count_monthly_mv_sum_fields';
  count?: Maybe<Scalars['u_int64']>;
  month?: Maybe<Scalars['u_int64']>;
  year?: Maybe<Scalars['u_int64']>;
};

/** aggregate var_pop on columns */
export type Hit_Count_Monthly_Mv_Var_Pop_Fields = {
  __typename?: 'hit_count_monthly_mv_var_pop_fields';
  count?: Maybe<Scalars['float64']>;
  month?: Maybe<Scalars['float64']>;
  year?: Maybe<Scalars['float64']>;
};

/** aggregate var_samp on columns */
export type Hit_Count_Monthly_Mv_Var_Samp_Fields = {
  __typename?: 'hit_count_monthly_mv_var_samp_fields';
  count?: Maybe<Scalars['float64']>;
  month?: Maybe<Scalars['float64']>;
  year?: Maybe<Scalars['float64']>;
};

/** Ordering options when selecting data from "hit_count_monthly". */
export type Hit_Count_Monthly_Order_By = {
  count?: InputMaybe<Clickhouse_Order_By>;
  entity_name?: InputMaybe<Clickhouse_Order_By>;
  entity_type?: InputMaybe<Clickhouse_Order_By>;
  environment_id?: InputMaybe<Clickhouse_Order_By>;
  month?: InputMaybe<Clickhouse_Order_By>;
  project_id?: InputMaybe<Clickhouse_Order_By>;
  subgraph?: InputMaybe<Clickhouse_Order_By>;
  year?: InputMaybe<Clickhouse_Order_By>;
};

/** select columns of table "hit_count_monthly" */
export enum Hit_Count_Monthly_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  Month = 'month',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Subgraph = 'subgraph',
  /** column name */
  Year = 'year',
}

/** aggregate shortest on columns */
export type Hit_Count_Monthly_Shortest_Fields = {
  __typename?: 'hit_count_monthly_shortest_fields';
  entity_name?: Maybe<Scalars['string']>;
  entity_type?: Maybe<Scalars['string']>;
  subgraph?: Maybe<Scalars['string']>;
};

/** aggregate stddev_pop on columns */
export type Hit_Count_Monthly_Stddev_Pop_Fields = {
  __typename?: 'hit_count_monthly_stddev_pop_fields';
  count?: Maybe<Scalars['float64']>;
  month?: Maybe<Scalars['float64']>;
  year?: Maybe<Scalars['float64']>;
};

/** aggregate stddev_samp on columns */
export type Hit_Count_Monthly_Stddev_Samp_Fields = {
  __typename?: 'hit_count_monthly_stddev_samp_fields';
  count?: Maybe<Scalars['float64']>;
  month?: Maybe<Scalars['float64']>;
  year?: Maybe<Scalars['float64']>;
};

/** aggregate sum on columns */
export type Hit_Count_Monthly_Sum_Fields = {
  __typename?: 'hit_count_monthly_sum_fields';
  count?: Maybe<Scalars['u_int64']>;
  month?: Maybe<Scalars['u_int64']>;
  year?: Maybe<Scalars['u_int64']>;
};

/** aggregate var_pop on columns */
export type Hit_Count_Monthly_Var_Pop_Fields = {
  __typename?: 'hit_count_monthly_var_pop_fields';
  count?: Maybe<Scalars['float64']>;
  month?: Maybe<Scalars['float64']>;
  year?: Maybe<Scalars['float64']>;
};

/** aggregate var_samp on columns */
export type Hit_Count_Monthly_Var_Samp_Fields = {
  __typename?: 'hit_count_monthly_var_samp_fields';
  count?: Maybe<Scalars['float64']>;
  month?: Maybe<Scalars['float64']>;
  year?: Maybe<Scalars['float64']>;
};

/** columns and relationships of "hit_count_per_day" */
export type Hit_Count_Per_Day = {
  __typename?: 'hit_count_per_day';
  build_fqdn: Scalars['string'];
  count: Scalars['u_int64'];
  date: Scalars['date'];
  entity_name: Scalars['string'];
  entity_type: Scalars['string'];
  environment_id: Scalars['UUID'];
  project_id: Scalars['UUID'];
  subgraph: Scalars['string'];
};

/** aggregated selection of "hit_count_per_day" */
export type Hit_Count_Per_Day_Aggregate = {
  __typename?: 'hit_count_per_day_aggregate';
  aggregate?: Maybe<Hit_Count_Per_Day_Aggregate_Fields>;
  nodes: Array<Hit_Count_Per_Day>;
};

/** aggregate fields of "hit_count_per_day" */
export type Hit_Count_Per_Day_Aggregate_Fields = {
  __typename?: 'hit_count_per_day_aggregate_fields';
  avg?: Maybe<Hit_Count_Per_Day_Avg_Fields>;
  count: Scalars['Int'];
  longest?: Maybe<Hit_Count_Per_Day_Longest_Fields>;
  max?: Maybe<Hit_Count_Per_Day_Max_Fields>;
  min?: Maybe<Hit_Count_Per_Day_Min_Fields>;
  shortest?: Maybe<Hit_Count_Per_Day_Shortest_Fields>;
  stddev_pop?: Maybe<Hit_Count_Per_Day_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hit_Count_Per_Day_Stddev_Samp_Fields>;
  sum?: Maybe<Hit_Count_Per_Day_Sum_Fields>;
  var_pop?: Maybe<Hit_Count_Per_Day_Var_Pop_Fields>;
  var_samp?: Maybe<Hit_Count_Per_Day_Var_Samp_Fields>;
};

/** aggregate fields of "hit_count_per_day" */
export type Hit_Count_Per_Day_Aggregate_FieldsCountArgs = {
  column?: InputMaybe<Hit_Count_Per_Day_Select_Column>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Hit_Count_Per_Day_Avg_Fields = {
  __typename?: 'hit_count_per_day_avg_fields';
  count?: Maybe<Scalars['float64']>;
};

/** Boolean expression to filter rows from the table "hit_count_per_day". All fields are combined with a logical 'AND'. */
export type Hit_Count_Per_Day_Bool_Exp = {
  _and?: InputMaybe<Array<Hit_Count_Per_Day_Bool_Exp>>;
  _not?: InputMaybe<Hit_Count_Per_Day_Bool_Exp>;
  _or?: InputMaybe<Array<Hit_Count_Per_Day_Bool_Exp>>;
  build_fqdn?: InputMaybe<String_Clickhouse_Comparison_Exp>;
  count?: InputMaybe<U_Int64_Clickhouse_Comparison_Exp>;
  date?: InputMaybe<Date_Clickhouse_Comparison_Exp>;
  entity_name?: InputMaybe<String_Clickhouse_Comparison_Exp>;
  entity_type?: InputMaybe<String_Clickhouse_Comparison_Exp>;
  environment_id?: InputMaybe<Uuid_Clickhouse_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Clickhouse_Comparison_Exp>;
  subgraph?: InputMaybe<String_Clickhouse_Comparison_Exp>;
};

/** aggregate longest on columns */
export type Hit_Count_Per_Day_Longest_Fields = {
  __typename?: 'hit_count_per_day_longest_fields';
  build_fqdn?: Maybe<Scalars['string']>;
  entity_name?: Maybe<Scalars['string']>;
  entity_type?: Maybe<Scalars['string']>;
  subgraph?: Maybe<Scalars['string']>;
};

/** aggregate max on columns */
export type Hit_Count_Per_Day_Max_Fields = {
  __typename?: 'hit_count_per_day_max_fields';
  build_fqdn?: Maybe<Scalars['string']>;
  count?: Maybe<Scalars['u_int64']>;
  date?: Maybe<Scalars['date']>;
  entity_name?: Maybe<Scalars['string']>;
  entity_type?: Maybe<Scalars['string']>;
  subgraph?: Maybe<Scalars['string']>;
};

/** aggregate min on columns */
export type Hit_Count_Per_Day_Min_Fields = {
  __typename?: 'hit_count_per_day_min_fields';
  build_fqdn?: Maybe<Scalars['string']>;
  count?: Maybe<Scalars['u_int64']>;
  date?: Maybe<Scalars['date']>;
  entity_name?: Maybe<Scalars['string']>;
  entity_type?: Maybe<Scalars['string']>;
  subgraph?: Maybe<Scalars['string']>;
};

/** Ordering options when selecting data from "hit_count_per_day". */
export type Hit_Count_Per_Day_Order_By = {
  build_fqdn?: InputMaybe<Clickhouse_Order_By>;
  count?: InputMaybe<Clickhouse_Order_By>;
  date?: InputMaybe<Clickhouse_Order_By>;
  entity_name?: InputMaybe<Clickhouse_Order_By>;
  entity_type?: InputMaybe<Clickhouse_Order_By>;
  environment_id?: InputMaybe<Clickhouse_Order_By>;
  project_id?: InputMaybe<Clickhouse_Order_By>;
  subgraph?: InputMaybe<Clickhouse_Order_By>;
};

/** select columns of table "hit_count_per_day" */
export enum Hit_Count_Per_Day_Select_Column {
  /** column name */
  BuildFqdn = 'build_fqdn',
  /** column name */
  Count = 'count',
  /** column name */
  Date = 'date',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  EntityType = 'entity_type',
  /** column name */
  EnvironmentId = 'environment_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Subgraph = 'subgraph',
}

/** aggregate shortest on columns */
export type Hit_Count_Per_Day_Shortest_Fields = {
  __typename?: 'hit_count_per_day_shortest_fields';
  build_fqdn?: Maybe<Scalars['string']>;
  entity_name?: Maybe<Scalars['string']>;
  entity_type?: Maybe<Scalars['string']>;
  subgraph?: Maybe<Scalars['string']>;
};

/** aggregate stddev_pop on columns */
export type Hit_Count_Per_Day_Stddev_Pop_Fields = {
  __typename?: 'hit_count_per_day_stddev_pop_fields';
  count?: Maybe<Scalars['float64']>;
};

/** aggregate stddev_samp on columns */
export type Hit_Count_Per_Day_Stddev_Samp_Fields = {
  __typename?: 'hit_count_per_day_stddev_samp_fields';
  count?: Maybe<Scalars['float64']>;
};

/** aggregate sum on columns */
export type Hit_Count_Per_Day_Sum_Fields = {
  __typename?: 'hit_count_per_day_sum_fields';
  count?: Maybe<Scalars['u_int64']>;
};

/** aggregate var_pop on columns */
export type Hit_Count_Per_Day_Var_Pop_Fields = {
  __typename?: 'hit_count_per_day_var_pop_fields';
  count?: Maybe<Scalars['float64']>;
};

/** aggregate var_samp on columns */
export type Hit_Count_Per_Day_Var_Samp_Fields = {
  __typename?: 'hit_count_per_day_var_samp_fields';
  count?: Maybe<Scalars['float64']>;
};

/** Records projects that are excluded from suspension */
export type Inactive_Project_Exclusions = {
  __typename?: 'inactive_project_exclusions';
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "inactive_project_exclusions" */
export type Inactive_Project_Exclusions_Aggregate = {
  __typename?: 'inactive_project_exclusions_aggregate';
  aggregate?: Maybe<Inactive_Project_Exclusions_Aggregate_Fields>;
  nodes: Array<Inactive_Project_Exclusions>;
};

/** aggregate fields of "inactive_project_exclusions" */
export type Inactive_Project_Exclusions_Aggregate_Fields = {
  __typename?: 'inactive_project_exclusions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Inactive_Project_Exclusions_Max_Fields>;
  min?: Maybe<Inactive_Project_Exclusions_Min_Fields>;
};

/** aggregate fields of "inactive_project_exclusions" */
export type Inactive_Project_Exclusions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inactive_Project_Exclusions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "inactive_project_exclusions". All fields are combined with a logical 'AND'. */
export type Inactive_Project_Exclusions_Bool_Exp = {
  _and?: InputMaybe<Array<Inactive_Project_Exclusions_Bool_Exp>>;
  _not?: InputMaybe<Inactive_Project_Exclusions_Bool_Exp>;
  _or?: InputMaybe<Array<Inactive_Project_Exclusions_Bool_Exp>>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "inactive_project_exclusions" */
export enum Inactive_Project_Exclusions_Constraint {
  /** unique or primary key constraint on columns "project_id" */
  InactiveProjectExclusionsPkey = 'inactive_project_exclusions_pkey',
}

/** input type for inserting data into table "inactive_project_exclusions" */
export type Inactive_Project_Exclusions_Insert_Input = {
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Inactive_Project_Exclusions_Max_Fields = {
  __typename?: 'inactive_project_exclusions_max_fields';
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Inactive_Project_Exclusions_Min_Fields = {
  __typename?: 'inactive_project_exclusions_min_fields';
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "inactive_project_exclusions" */
export type Inactive_Project_Exclusions_Mutation_Response = {
  __typename?: 'inactive_project_exclusions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Inactive_Project_Exclusions>;
};

/** on_conflict condition type for table "inactive_project_exclusions" */
export type Inactive_Project_Exclusions_On_Conflict = {
  constraint: Inactive_Project_Exclusions_Constraint;
  update_columns?: Array<Inactive_Project_Exclusions_Update_Column>;
  where?: InputMaybe<Inactive_Project_Exclusions_Bool_Exp>;
};

/** Ordering options when selecting data from "inactive_project_exclusions". */
export type Inactive_Project_Exclusions_Order_By = {
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: inactive_project_exclusions */
export type Inactive_Project_Exclusions_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
};

/** select columns of table "inactive_project_exclusions" */
export enum Inactive_Project_Exclusions_Select_Column {
  /** column name */
  ProjectId = 'project_id',
}

/** input type for updating data in table "inactive_project_exclusions" */
export type Inactive_Project_Exclusions_Set_Input = {
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "inactive_project_exclusions" */
export type Inactive_Project_Exclusions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inactive_Project_Exclusions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inactive_Project_Exclusions_Stream_Cursor_Value_Input = {
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "inactive_project_exclusions" */
export enum Inactive_Project_Exclusions_Update_Column {
  /** column name */
  ProjectId = 'project_id',
}

export type Inactive_Project_Exclusions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inactive_Project_Exclusions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inactive_Project_Exclusions_Bool_Exp;
};

/** Records when the user has been notified about their projects being inactive */
export type Inactive_Project_Notifications = {
  __typename?: 'inactive_project_notifications';
  active: Scalars['Boolean'];
  id: Scalars['Int'];
  is_legacy_schedule: Scalars['Boolean'];
  /** An object relationship */
  owner: Users;
  owner_id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  sent_at: Scalars['timestamp'];
};

/** aggregated selection of "inactive_project_notifications" */
export type Inactive_Project_Notifications_Aggregate = {
  __typename?: 'inactive_project_notifications_aggregate';
  aggregate?: Maybe<Inactive_Project_Notifications_Aggregate_Fields>;
  nodes: Array<Inactive_Project_Notifications>;
};

/** aggregate fields of "inactive_project_notifications" */
export type Inactive_Project_Notifications_Aggregate_Fields = {
  __typename?: 'inactive_project_notifications_aggregate_fields';
  avg?: Maybe<Inactive_Project_Notifications_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inactive_Project_Notifications_Max_Fields>;
  min?: Maybe<Inactive_Project_Notifications_Min_Fields>;
  stddev?: Maybe<Inactive_Project_Notifications_Stddev_Fields>;
  stddev_pop?: Maybe<Inactive_Project_Notifications_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inactive_Project_Notifications_Stddev_Samp_Fields>;
  sum?: Maybe<Inactive_Project_Notifications_Sum_Fields>;
  var_pop?: Maybe<Inactive_Project_Notifications_Var_Pop_Fields>;
  var_samp?: Maybe<Inactive_Project_Notifications_Var_Samp_Fields>;
  variance?: Maybe<Inactive_Project_Notifications_Variance_Fields>;
};

/** aggregate fields of "inactive_project_notifications" */
export type Inactive_Project_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inactive_Project_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Inactive_Project_Notifications_Avg_Fields = {
  __typename?: 'inactive_project_notifications_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "inactive_project_notifications". All fields are combined with a logical 'AND'. */
export type Inactive_Project_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Inactive_Project_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Inactive_Project_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Inactive_Project_Notifications_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  is_legacy_schedule?: InputMaybe<Boolean_Comparison_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  sent_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "inactive_project_notifications" */
export enum Inactive_Project_Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  InactiveProjectNotificationsPkey = 'inactive_project_notifications_pkey',
}

/** input type for incrementing numeric columns in table "inactive_project_notifications" */
export type Inactive_Project_Notifications_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "inactive_project_notifications" */
export type Inactive_Project_Notifications_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  is_legacy_schedule?: InputMaybe<Scalars['Boolean']>;
  owner?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  sent_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Inactive_Project_Notifications_Max_Fields = {
  __typename?: 'inactive_project_notifications_max_fields';
  id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  sent_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Inactive_Project_Notifications_Min_Fields = {
  __typename?: 'inactive_project_notifications_min_fields';
  id?: Maybe<Scalars['Int']>;
  owner_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  sent_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "inactive_project_notifications" */
export type Inactive_Project_Notifications_Mutation_Response = {
  __typename?: 'inactive_project_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Inactive_Project_Notifications>;
};

/** on_conflict condition type for table "inactive_project_notifications" */
export type Inactive_Project_Notifications_On_Conflict = {
  constraint: Inactive_Project_Notifications_Constraint;
  update_columns?: Array<Inactive_Project_Notifications_Update_Column>;
  where?: InputMaybe<Inactive_Project_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "inactive_project_notifications". */
export type Inactive_Project_Notifications_Order_By = {
  active?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_legacy_schedule?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  sent_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: inactive_project_notifications */
export type Inactive_Project_Notifications_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "inactive_project_notifications" */
export enum Inactive_Project_Notifications_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  IsLegacySchedule = 'is_legacy_schedule',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SentAt = 'sent_at',
}

/** input type for updating data in table "inactive_project_notifications" */
export type Inactive_Project_Notifications_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  is_legacy_schedule?: InputMaybe<Scalars['Boolean']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  sent_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Inactive_Project_Notifications_Stddev_Fields = {
  __typename?: 'inactive_project_notifications_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Inactive_Project_Notifications_Stddev_Pop_Fields = {
  __typename?: 'inactive_project_notifications_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Inactive_Project_Notifications_Stddev_Samp_Fields = {
  __typename?: 'inactive_project_notifications_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "inactive_project_notifications" */
export type Inactive_Project_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inactive_Project_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inactive_Project_Notifications_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['Int']>;
  is_legacy_schedule?: InputMaybe<Scalars['Boolean']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  sent_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Inactive_Project_Notifications_Sum_Fields = {
  __typename?: 'inactive_project_notifications_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "inactive_project_notifications" */
export enum Inactive_Project_Notifications_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Id = 'id',
  /** column name */
  IsLegacySchedule = 'is_legacy_schedule',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SentAt = 'sent_at',
}

export type Inactive_Project_Notifications_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Inactive_Project_Notifications_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inactive_Project_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inactive_Project_Notifications_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inactive_Project_Notifications_Var_Pop_Fields = {
  __typename?: 'inactive_project_notifications_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Inactive_Project_Notifications_Var_Samp_Fields = {
  __typename?: 'inactive_project_notifications_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Inactive_Project_Notifications_Variance_Fields = {
  __typename?: 'inactive_project_notifications_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Allows for progressive rollout of inactive_project_suspension. App code will take latest record to base their decisions */
export type Inactive_Project_Suspension_Config = {
  __typename?: 'inactive_project_suspension_config';
  created_at: Scalars['timestamp'];
  enabled_per_thousand?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "inactive_project_suspension_config" */
export type Inactive_Project_Suspension_Config_Aggregate = {
  __typename?: 'inactive_project_suspension_config_aggregate';
  aggregate?: Maybe<Inactive_Project_Suspension_Config_Aggregate_Fields>;
  nodes: Array<Inactive_Project_Suspension_Config>;
};

/** aggregate fields of "inactive_project_suspension_config" */
export type Inactive_Project_Suspension_Config_Aggregate_Fields = {
  __typename?: 'inactive_project_suspension_config_aggregate_fields';
  avg?: Maybe<Inactive_Project_Suspension_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inactive_Project_Suspension_Config_Max_Fields>;
  min?: Maybe<Inactive_Project_Suspension_Config_Min_Fields>;
  stddev?: Maybe<Inactive_Project_Suspension_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Inactive_Project_Suspension_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inactive_Project_Suspension_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Inactive_Project_Suspension_Config_Sum_Fields>;
  var_pop?: Maybe<Inactive_Project_Suspension_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Inactive_Project_Suspension_Config_Var_Samp_Fields>;
  variance?: Maybe<Inactive_Project_Suspension_Config_Variance_Fields>;
};

/** aggregate fields of "inactive_project_suspension_config" */
export type Inactive_Project_Suspension_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inactive_Project_Suspension_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Inactive_Project_Suspension_Config_Avg_Fields = {
  __typename?: 'inactive_project_suspension_config_avg_fields';
  enabled_per_thousand?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "inactive_project_suspension_config". All fields are combined with a logical 'AND'. */
export type Inactive_Project_Suspension_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Inactive_Project_Suspension_Config_Bool_Exp>>;
  _not?: InputMaybe<Inactive_Project_Suspension_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Inactive_Project_Suspension_Config_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  enabled_per_thousand?: InputMaybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "inactive_project_suspension_config" */
export type Inactive_Project_Suspension_Config_Inc_Input = {
  enabled_per_thousand?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "inactive_project_suspension_config" */
export type Inactive_Project_Suspension_Config_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  enabled_per_thousand?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Inactive_Project_Suspension_Config_Max_Fields = {
  __typename?: 'inactive_project_suspension_config_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  enabled_per_thousand?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Inactive_Project_Suspension_Config_Min_Fields = {
  __typename?: 'inactive_project_suspension_config_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  enabled_per_thousand?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "inactive_project_suspension_config" */
export type Inactive_Project_Suspension_Config_Mutation_Response = {
  __typename?: 'inactive_project_suspension_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Inactive_Project_Suspension_Config>;
};

/** Ordering options when selecting data from "inactive_project_suspension_config". */
export type Inactive_Project_Suspension_Config_Order_By = {
  created_at?: InputMaybe<Order_By>;
  enabled_per_thousand?: InputMaybe<Order_By>;
};

/** select columns of table "inactive_project_suspension_config" */
export enum Inactive_Project_Suspension_Config_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnabledPerThousand = 'enabled_per_thousand',
}

/** input type for updating data in table "inactive_project_suspension_config" */
export type Inactive_Project_Suspension_Config_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  enabled_per_thousand?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Inactive_Project_Suspension_Config_Stddev_Fields = {
  __typename?: 'inactive_project_suspension_config_stddev_fields';
  enabled_per_thousand?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Inactive_Project_Suspension_Config_Stddev_Pop_Fields = {
  __typename?: 'inactive_project_suspension_config_stddev_pop_fields';
  enabled_per_thousand?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Inactive_Project_Suspension_Config_Stddev_Samp_Fields = {
  __typename?: 'inactive_project_suspension_config_stddev_samp_fields';
  enabled_per_thousand?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "inactive_project_suspension_config" */
export type Inactive_Project_Suspension_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inactive_Project_Suspension_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inactive_Project_Suspension_Config_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  enabled_per_thousand?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Inactive_Project_Suspension_Config_Sum_Fields = {
  __typename?: 'inactive_project_suspension_config_sum_fields';
  enabled_per_thousand?: Maybe<Scalars['Int']>;
};

export type Inactive_Project_Suspension_Config_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Inactive_Project_Suspension_Config_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Inactive_Project_Suspension_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inactive_Project_Suspension_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inactive_Project_Suspension_Config_Var_Pop_Fields = {
  __typename?: 'inactive_project_suspension_config_var_pop_fields';
  enabled_per_thousand?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Inactive_Project_Suspension_Config_Var_Samp_Fields = {
  __typename?: 'inactive_project_suspension_config_var_samp_fields';
  enabled_per_thousand?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Inactive_Project_Suspension_Config_Variance_Fields = {
  __typename?: 'inactive_project_suspension_config_variance_fields';
  enabled_per_thousand?: Maybe<Scalars['Float']>;
};

/** Free Projects with at least 25 days of continuous inactivity without a notification in at least 7 days */
export type Inactive_Projects = {
  __typename?: 'inactive_projects';
  inactive_since?: Maybe<Scalars['timestamptz']>;
  is_legacy_schedule?: Maybe<Scalars['Boolean']>;
  latest_notified?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  times_notified?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "inactive_projects" */
export type Inactive_Projects_Aggregate = {
  __typename?: 'inactive_projects_aggregate';
  aggregate?: Maybe<Inactive_Projects_Aggregate_Fields>;
  nodes: Array<Inactive_Projects>;
};

/** aggregate fields of "inactive_projects" */
export type Inactive_Projects_Aggregate_Fields = {
  __typename?: 'inactive_projects_aggregate_fields';
  avg?: Maybe<Inactive_Projects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inactive_Projects_Max_Fields>;
  min?: Maybe<Inactive_Projects_Min_Fields>;
  stddev?: Maybe<Inactive_Projects_Stddev_Fields>;
  stddev_pop?: Maybe<Inactive_Projects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inactive_Projects_Stddev_Samp_Fields>;
  sum?: Maybe<Inactive_Projects_Sum_Fields>;
  var_pop?: Maybe<Inactive_Projects_Var_Pop_Fields>;
  var_samp?: Maybe<Inactive_Projects_Var_Samp_Fields>;
  variance?: Maybe<Inactive_Projects_Variance_Fields>;
};

/** aggregate fields of "inactive_projects" */
export type Inactive_Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Inactive_Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Inactive_Projects_Avg_Fields = {
  __typename?: 'inactive_projects_avg_fields';
  times_notified?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "inactive_projects". All fields are combined with a logical 'AND'. */
export type Inactive_Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Inactive_Projects_Bool_Exp>>;
  _not?: InputMaybe<Inactive_Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Inactive_Projects_Bool_Exp>>;
  inactive_since?: InputMaybe<Timestamptz_Comparison_Exp>;
  is_legacy_schedule?: InputMaybe<Boolean_Comparison_Exp>;
  latest_notified?: InputMaybe<Timestamptz_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  times_notified?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Inactive_Projects_Max_Fields = {
  __typename?: 'inactive_projects_max_fields';
  inactive_since?: Maybe<Scalars['timestamptz']>;
  latest_notified?: Maybe<Scalars['timestamptz']>;
  project_created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  times_notified?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Inactive_Projects_Min_Fields = {
  __typename?: 'inactive_projects_min_fields';
  inactive_since?: Maybe<Scalars['timestamptz']>;
  latest_notified?: Maybe<Scalars['timestamptz']>;
  project_created_at?: Maybe<Scalars['timestamptz']>;
  project_id?: Maybe<Scalars['uuid']>;
  times_notified?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "inactive_projects". */
export type Inactive_Projects_Order_By = {
  inactive_since?: InputMaybe<Order_By>;
  is_legacy_schedule?: InputMaybe<Order_By>;
  latest_notified?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_created_at?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  times_notified?: InputMaybe<Order_By>;
};

/** select columns of table "inactive_projects" */
export enum Inactive_Projects_Select_Column {
  /** column name */
  InactiveSince = 'inactive_since',
  /** column name */
  IsLegacySchedule = 'is_legacy_schedule',
  /** column name */
  LatestNotified = 'latest_notified',
  /** column name */
  ProjectCreatedAt = 'project_created_at',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TimesNotified = 'times_notified',
}

/** aggregate stddev on columns */
export type Inactive_Projects_Stddev_Fields = {
  __typename?: 'inactive_projects_stddev_fields';
  times_notified?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Inactive_Projects_Stddev_Pop_Fields = {
  __typename?: 'inactive_projects_stddev_pop_fields';
  times_notified?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Inactive_Projects_Stddev_Samp_Fields = {
  __typename?: 'inactive_projects_stddev_samp_fields';
  times_notified?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "inactive_projects" */
export type Inactive_Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inactive_Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inactive_Projects_Stream_Cursor_Value_Input = {
  inactive_since?: InputMaybe<Scalars['timestamptz']>;
  is_legacy_schedule?: InputMaybe<Scalars['Boolean']>;
  latest_notified?: InputMaybe<Scalars['timestamptz']>;
  project_created_at?: InputMaybe<Scalars['timestamptz']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  times_notified?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Inactive_Projects_Sum_Fields = {
  __typename?: 'inactive_projects_sum_fields';
  times_notified?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Inactive_Projects_Var_Pop_Fields = {
  __typename?: 'inactive_projects_var_pop_fields';
  times_notified?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Inactive_Projects_Var_Samp_Fields = {
  __typename?: 'inactive_projects_var_samp_fields';
  times_notified?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Inactive_Projects_Variance_Fields = {
  __typename?: 'inactive_projects_variance_fields';
  times_notified?: Maybe<Scalars['Float']>;
};

/** Hasura enum capturing the Terraform status of infra */
export type Infra_Status = {
  __typename?: 'infra_status';
  comment?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

/** aggregated selection of "infra_status" */
export type Infra_Status_Aggregate = {
  __typename?: 'infra_status_aggregate';
  aggregate?: Maybe<Infra_Status_Aggregate_Fields>;
  nodes: Array<Infra_Status>;
};

/** aggregate fields of "infra_status" */
export type Infra_Status_Aggregate_Fields = {
  __typename?: 'infra_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Infra_Status_Max_Fields>;
  min?: Maybe<Infra_Status_Min_Fields>;
};

/** aggregate fields of "infra_status" */
export type Infra_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Infra_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "infra_status". All fields are combined with a logical 'AND'. */
export type Infra_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Infra_Status_Bool_Exp>>;
  _not?: InputMaybe<Infra_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Infra_Status_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "infra_status" */
export enum Infra_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  InfraStatusPkey = 'infra_status_pkey',
}

export enum Infra_Status_Enum {
  /** Changes have been applied */
  Applied = 'applied',
  /** Failed to apply changes to infra */
  ApplyFailed = 'apply_failed',
  /** Destroying infra failed */
  DestroyFailed = 'destroy_failed',
  /** Infra has been destroyed */
  Destroyed = 'destroyed',
  /** Waiting for the terraform apply */
  PendingApply = 'pending_apply',
  /** Waiting for the terraform destroy */
  PendingDestroy = 'pending_destroy',
}

/** Boolean expression to compare columns of type "infra_status_enum". All fields are combined with logical 'AND'. */
export type Infra_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Infra_Status_Enum>;
  _in?: InputMaybe<Array<Infra_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Infra_Status_Enum>;
  _nin?: InputMaybe<Array<Infra_Status_Enum>>;
};

/** input type for inserting data into table "infra_status" */
export type Infra_Status_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Infra_Status_Max_Fields = {
  __typename?: 'infra_status_max_fields';
  comment?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Infra_Status_Min_Fields = {
  __typename?: 'infra_status_min_fields';
  comment?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "infra_status" */
export type Infra_Status_Mutation_Response = {
  __typename?: 'infra_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Infra_Status>;
};

/** on_conflict condition type for table "infra_status" */
export type Infra_Status_On_Conflict = {
  constraint: Infra_Status_Constraint;
  update_columns?: Array<Infra_Status_Update_Column>;
  where?: InputMaybe<Infra_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "infra_status". */
export type Infra_Status_Order_By = {
  comment?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: infra_status */
export type Infra_Status_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "infra_status" */
export enum Infra_Status_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "infra_status" */
export type Infra_Status_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "infra_status" */
export type Infra_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Infra_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Infra_Status_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "infra_status" */
export enum Infra_Status_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Status = 'status',
}

export type Infra_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Infra_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Infra_Status_Bool_Exp;
};

export type Insert_Db_Usage_Args = {
  created_ats?: InputMaybe<Scalars['_date']>;
  no_db_usages?: InputMaybe<Scalars['_int4']>;
  non_pg_usages?: InputMaybe<Scalars['_int4']>;
  pg_usages?: InputMaybe<Scalars['_int4']>;
  prices?: InputMaybe<Scalars['_int8']>;
  project_ids?: InputMaybe<Scalars['_uuid']>;
};

export type IntercomHashResponse = {
  __typename?: 'intercomHashResponse';
  hash: Scalars['String'];
};

/** Customer invoice for Hasura Cloud services */
export type Invoice = {
  __typename?: 'invoice';
  attempt_count?: Maybe<Scalars['Int']>;
  collection_method: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** customer id that represents unique customer at stripe */
  customer_id: Scalars['String'];
  /** any additional appropriate description for this invoice. This will be used on the UI */
  description: Scalars['String'];
  /** due amount of the invoice without coupons and credit applied */
  due_amount?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  invoice_coupon_discounts: Array<Invoice_Coupon_Discount>;
  /** An aggregate relationship */
  invoice_coupon_discounts_aggregate: Invoice_Coupon_Discount_Aggregate;
  /** An array relationship */
  invoice_credit_usages: Array<Customer_Usage>;
  /** An aggregate relationship */
  invoice_credit_usages_aggregate: Customer_Usage_Aggregate;
  invoice_url?: Maybe<Scalars['String']>;
  /** An array relationship */
  items: Array<Invoice_Item>;
  /** An aggregate relationship */
  items_aggregate: Invoice_Item_Aggregate;
  /** represents month for which this invoice is for */
  month: Scalars['Int'];
  /** An object relationship */
  payment_method?: Maybe<Payment_Method>;
  payment_method_id?: Maybe<Scalars['String']>;
  receipt_url?: Maybe<Scalars['String']>;
  /** represents status of this invoice. It can be either `paid`, `failed`, `unpaid` */
  status: Scalars['String'];
  /** unique invoice id, represents invoice id at stripe */
  stripe_invoice_id: Scalars['String'];
  /** subscription for which the current invoice is generated for */
  subscription_id: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users>;
  /** represents year for which this invoice is for */
  year: Scalars['Int'];
};

/** Customer invoice for Hasura Cloud services */
export type InvoiceInvoice_Coupon_DiscountsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Coupon_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Coupon_Discount_Order_By>>;
  where?: InputMaybe<Invoice_Coupon_Discount_Bool_Exp>;
};

/** Customer invoice for Hasura Cloud services */
export type InvoiceInvoice_Coupon_Discounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Coupon_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Coupon_Discount_Order_By>>;
  where?: InputMaybe<Invoice_Coupon_Discount_Bool_Exp>;
};

/** Customer invoice for Hasura Cloud services */
export type InvoiceInvoice_Credit_UsagesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Usage_Order_By>>;
  where?: InputMaybe<Customer_Usage_Bool_Exp>;
};

/** Customer invoice for Hasura Cloud services */
export type InvoiceInvoice_Credit_Usages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Usage_Order_By>>;
  where?: InputMaybe<Customer_Usage_Bool_Exp>;
};

/** Customer invoice for Hasura Cloud services */
export type InvoiceItemsArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Item_Order_By>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};

/** Customer invoice for Hasura Cloud services */
export type InvoiceItems_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Item_Order_By>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};

export type InvoiceAndReceiptUrLsResponse = {
  __typename?: 'invoiceAndReceiptURLsResponse';
  invoice_url?: Maybe<Scalars['String']>;
  receipt_url?: Maybe<Scalars['String']>;
};

/** aggregated selection of "invoice" */
export type Invoice_Aggregate = {
  __typename?: 'invoice_aggregate';
  aggregate?: Maybe<Invoice_Aggregate_Fields>;
  nodes: Array<Invoice>;
};

export type Invoice_Aggregate_Bool_Exp = {
  count?: InputMaybe<Invoice_Aggregate_Bool_Exp_Count>;
};

export type Invoice_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoice" */
export type Invoice_Aggregate_Fields = {
  __typename?: 'invoice_aggregate_fields';
  avg?: Maybe<Invoice_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoice_Max_Fields>;
  min?: Maybe<Invoice_Min_Fields>;
  stddev?: Maybe<Invoice_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Sum_Fields>;
  var_pop?: Maybe<Invoice_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Variance_Fields>;
};

/** aggregate fields of "invoice" */
export type Invoice_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoice" */
export type Invoice_Aggregate_Order_By = {
  avg?: InputMaybe<Invoice_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoice_Max_Order_By>;
  min?: InputMaybe<Invoice_Min_Order_By>;
  stddev?: InputMaybe<Invoice_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoice_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoice_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoice_Sum_Order_By>;
  var_pop?: InputMaybe<Invoice_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoice_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoice_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice" */
export type Invoice_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoice_Avg_Fields = {
  __typename?: 'invoice_avg_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  /** due amount of the invoice without coupons and credit applied */
  due_amount?: Maybe<Scalars['numeric']>;
  /** represents month for which this invoice is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoice" */
export type Invoice_Avg_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice". All fields are combined with a logical 'AND'. */
export type Invoice_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Bool_Exp>>;
  attempt_count?: InputMaybe<Int_Comparison_Exp>;
  collection_method?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  due_amount?: InputMaybe<Numeric_Comparison_Exp>;
  invoice_coupon_discounts?: InputMaybe<Invoice_Coupon_Discount_Bool_Exp>;
  invoice_coupon_discounts_aggregate?: InputMaybe<
    Invoice_Coupon_Discount_Aggregate_Bool_Exp
  >;
  invoice_credit_usages?: InputMaybe<Customer_Usage_Bool_Exp>;
  invoice_credit_usages_aggregate?: InputMaybe<
    Customer_Usage_Aggregate_Bool_Exp
  >;
  invoice_url?: InputMaybe<String_Comparison_Exp>;
  items?: InputMaybe<Invoice_Item_Bool_Exp>;
  items_aggregate?: InputMaybe<Invoice_Item_Aggregate_Bool_Exp>;
  month?: InputMaybe<Int_Comparison_Exp>;
  payment_method?: InputMaybe<Payment_Method_Bool_Exp>;
  payment_method_id?: InputMaybe<String_Comparison_Exp>;
  receipt_url?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  stripe_invoice_id?: InputMaybe<String_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  year?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice" */
export enum Invoice_Constraint {
  /** unique or primary key constraint on columns "stripe_invoice_id" */
  InvoicePkey = 'invoice_pkey',
}

/** Discount coupon used on an invoice */
export type Invoice_Coupon_Discount = {
  __typename?: 'invoice_coupon_discount';
  amount: Scalars['numeric'];
  coupon_code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  discount_type: Scalars['String'];
  inv_id: Scalars['String'];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
};

/** aggregated selection of "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Aggregate = {
  __typename?: 'invoice_coupon_discount_aggregate';
  aggregate?: Maybe<Invoice_Coupon_Discount_Aggregate_Fields>;
  nodes: Array<Invoice_Coupon_Discount>;
};

export type Invoice_Coupon_Discount_Aggregate_Bool_Exp = {
  count?: InputMaybe<Invoice_Coupon_Discount_Aggregate_Bool_Exp_Count>;
};

export type Invoice_Coupon_Discount_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Coupon_Discount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Coupon_Discount_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Aggregate_Fields = {
  __typename?: 'invoice_coupon_discount_aggregate_fields';
  avg?: Maybe<Invoice_Coupon_Discount_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoice_Coupon_Discount_Max_Fields>;
  min?: Maybe<Invoice_Coupon_Discount_Min_Fields>;
  stddev?: Maybe<Invoice_Coupon_Discount_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Coupon_Discount_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Coupon_Discount_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Coupon_Discount_Sum_Fields>;
  var_pop?: Maybe<Invoice_Coupon_Discount_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Coupon_Discount_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Coupon_Discount_Variance_Fields>;
};

/** aggregate fields of "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Coupon_Discount_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Aggregate_Order_By = {
  avg?: InputMaybe<Invoice_Coupon_Discount_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoice_Coupon_Discount_Max_Order_By>;
  min?: InputMaybe<Invoice_Coupon_Discount_Min_Order_By>;
  stddev?: InputMaybe<Invoice_Coupon_Discount_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoice_Coupon_Discount_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoice_Coupon_Discount_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoice_Coupon_Discount_Sum_Order_By>;
  var_pop?: InputMaybe<Invoice_Coupon_Discount_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoice_Coupon_Discount_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoice_Coupon_Discount_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Coupon_Discount_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_Coupon_Discount_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoice_Coupon_Discount_Avg_Fields = {
  __typename?: 'invoice_coupon_discount_avg_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_coupon_discount". All fields are combined with a logical 'AND'. */
export type Invoice_Coupon_Discount_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Coupon_Discount_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Coupon_Discount_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Coupon_Discount_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  coupon_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  discount_type?: InputMaybe<String_Comparison_Exp>;
  inv_id?: InputMaybe<String_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
};

/** unique or primary key constraints on table "invoice_coupon_discount" */
export enum Invoice_Coupon_Discount_Constraint {
  /** unique or primary key constraint on columns "inv_id" */
  InvoiceCouponDiscountPkey = 'invoice_coupon_discount_pkey',
}

/** input type for incrementing numeric columns in table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  coupon_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  discount_type?: InputMaybe<Scalars['String']>;
  inv_id?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Invoice_Coupon_Discount_Max_Fields = {
  __typename?: 'invoice_coupon_discount_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  coupon_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount_type?: Maybe<Scalars['String']>;
  inv_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  coupon_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount_type?: InputMaybe<Order_By>;
  inv_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Coupon_Discount_Min_Fields = {
  __typename?: 'invoice_coupon_discount_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  coupon_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  discount_type?: Maybe<Scalars['String']>;
  inv_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  coupon_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount_type?: InputMaybe<Order_By>;
  inv_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Mutation_Response = {
  __typename?: 'invoice_coupon_discount_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_Coupon_Discount>;
};

/** on_conflict condition type for table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_On_Conflict = {
  constraint: Invoice_Coupon_Discount_Constraint;
  update_columns?: Array<Invoice_Coupon_Discount_Update_Column>;
  where?: InputMaybe<Invoice_Coupon_Discount_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_coupon_discount". */
export type Invoice_Coupon_Discount_Order_By = {
  amount?: InputMaybe<Order_By>;
  coupon_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  discount_type?: InputMaybe<Order_By>;
  inv_id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
};

/** primary key columns input for table: invoice_coupon_discount */
export type Invoice_Coupon_Discount_Pk_Columns_Input = {
  inv_id: Scalars['String'];
};

/** select columns of table "invoice_coupon_discount" */
export enum Invoice_Coupon_Discount_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CouponCode = 'coupon_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscountType = 'discount_type',
  /** column name */
  InvId = 'inv_id',
}

/** input type for updating data in table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  coupon_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  discount_type?: InputMaybe<Scalars['String']>;
  inv_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Invoice_Coupon_Discount_Stddev_Fields = {
  __typename?: 'invoice_coupon_discount_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Coupon_Discount_Stddev_Pop_Fields = {
  __typename?: 'invoice_coupon_discount_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Coupon_Discount_Stddev_Samp_Fields = {
  __typename?: 'invoice_coupon_discount_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Coupon_Discount_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Coupon_Discount_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  coupon_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  discount_type?: InputMaybe<Scalars['String']>;
  inv_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Invoice_Coupon_Discount_Sum_Fields = {
  __typename?: 'invoice_coupon_discount_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** update columns of table "invoice_coupon_discount" */
export enum Invoice_Coupon_Discount_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CouponCode = 'coupon_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DiscountType = 'discount_type',
  /** column name */
  InvId = 'inv_id',
}

export type Invoice_Coupon_Discount_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Coupon_Discount_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Coupon_Discount_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Coupon_Discount_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoice_Coupon_Discount_Var_Pop_Fields = {
  __typename?: 'invoice_coupon_discount_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Coupon_Discount_Var_Samp_Fields = {
  __typename?: 'invoice_coupon_discount_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Coupon_Discount_Variance_Fields = {
  __typename?: 'invoice_coupon_discount_variance_fields';
  amount?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoice_coupon_discount" */
export type Invoice_Coupon_Discount_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "invoice" */
export type Invoice_Inc_Input = {
  attempt_count?: InputMaybe<Scalars['Int']>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Scalars['Int']>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "invoice" */
export type Invoice_Insert_Input = {
  attempt_count?: InputMaybe<Scalars['Int']>;
  collection_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** customer id that represents unique customer at stripe */
  customer_id?: InputMaybe<Scalars['String']>;
  /** any additional appropriate description for this invoice. This will be used on the UI */
  description?: InputMaybe<Scalars['String']>;
  invoice_coupon_discounts?: InputMaybe<
    Invoice_Coupon_Discount_Arr_Rel_Insert_Input
  >;
  invoice_credit_usages?: InputMaybe<Customer_Usage_Arr_Rel_Insert_Input>;
  invoice_url?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Invoice_Item_Arr_Rel_Insert_Input>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Scalars['Int']>;
  payment_method?: InputMaybe<Payment_Method_Obj_Rel_Insert_Input>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  receipt_url?: InputMaybe<Scalars['String']>;
  /** represents status of this invoice. It can be either `paid`, `failed`, `unpaid` */
  status?: InputMaybe<Scalars['String']>;
  /** unique invoice id, represents invoice id at stripe */
  stripe_invoice_id?: InputMaybe<Scalars['String']>;
  /** subscription for which the current invoice is generated for */
  subscription_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Scalars['Int']>;
};

/** Item that is part of an invoice */
export type Invoice_Item = {
  __typename?: 'invoice_item';
  amount: Scalars['numeric'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** represents understandable invoice item description which will reflect in the actual invoice */
  description: Scalars['String'];
  /** contain error when adding invoice item to stripe fails */
  error?: Maybe<Scalars['String']>;
  /** if true, the stripe is updated successfully, otherwise not */
  has_updated_to_stripe: Scalars['Boolean'];
  id: Scalars['uuid'];
  /** An object relationship */
  invoice: Invoice;
  /** represents invoice_id for which this invoice_item is for */
  invoice_id: Scalars['String'];
  /** represents month for which this invoice_item is for */
  month: Scalars['Int'];
  /** represents project_id for which this invoice_item is for */
  project_id: Scalars['uuid'];
  /** represents type for which this invoice_item is for. It can take up values such as `pay_as_you_go`, `additional_data_usage`, `shared_data_passthrough`, `shared_pg_usage`, `shared_no_db_usage`, `shared_non_pg_usage` or `sales_tax` */
  type: Scalars['invoice_item_type'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** represents year for which this invoice_item is for */
  year: Scalars['Int'];
};

/** aggregated selection of "invoice_item" */
export type Invoice_Item_Aggregate = {
  __typename?: 'invoice_item_aggregate';
  aggregate?: Maybe<Invoice_Item_Aggregate_Fields>;
  nodes: Array<Invoice_Item>;
};

export type Invoice_Item_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Invoice_Item_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Invoice_Item_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Invoice_Item_Aggregate_Bool_Exp_Count>;
};

export type Invoice_Item_Aggregate_Bool_Exp_Bool_And = {
  arguments: Invoice_Item_Select_Column_Invoice_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Item_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Item_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Invoice_Item_Select_Column_Invoice_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Item_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Invoice_Item_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Invoice_Item_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "invoice_item" */
export type Invoice_Item_Aggregate_Fields = {
  __typename?: 'invoice_item_aggregate_fields';
  avg?: Maybe<Invoice_Item_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Invoice_Item_Max_Fields>;
  min?: Maybe<Invoice_Item_Min_Fields>;
  stddev?: Maybe<Invoice_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Invoice_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Invoice_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Invoice_Item_Sum_Fields>;
  var_pop?: Maybe<Invoice_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Invoice_Item_Var_Samp_Fields>;
  variance?: Maybe<Invoice_Item_Variance_Fields>;
};

/** aggregate fields of "invoice_item" */
export type Invoice_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invoice_item" */
export type Invoice_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Invoice_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invoice_Item_Max_Order_By>;
  min?: InputMaybe<Invoice_Item_Min_Order_By>;
  stddev?: InputMaybe<Invoice_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Invoice_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Invoice_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Invoice_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Invoice_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Invoice_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Invoice_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "invoice_item" */
export type Invoice_Item_Arr_Rel_Insert_Input = {
  data: Array<Invoice_Item_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Invoice_Item_Avg_Fields = {
  __typename?: 'invoice_item_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  /** represents month for which this invoice_item is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice_item is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invoice_item" */
export type Invoice_Item_Avg_Order_By = {
  amount?: InputMaybe<Order_By>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "invoice_item". All fields are combined with a logical 'AND'. */
export type Invoice_Item_Bool_Exp = {
  _and?: InputMaybe<Array<Invoice_Item_Bool_Exp>>;
  _not?: InputMaybe<Invoice_Item_Bool_Exp>;
  _or?: InputMaybe<Array<Invoice_Item_Bool_Exp>>;
  amount?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  has_updated_to_stripe?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoice_id?: InputMaybe<String_Comparison_Exp>;
  month?: InputMaybe<Int_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Invoice_Item_Type_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  year?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "invoice_item" */
export enum Invoice_Item_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvoiceItemPkey = 'invoice_item_pkey',
  /** unique or primary key constraint on columns "project_id", "year", "type", "month" */
  InvoiceItemUnique = 'invoice_item_unique',
}

/** input type for incrementing numeric columns in table "invoice_item" */
export type Invoice_Item_Inc_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Scalars['Int']>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "invoice_item" */
export type Invoice_Item_Insert_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** represents understandable invoice item description which will reflect in the actual invoice */
  description?: InputMaybe<Scalars['String']>;
  /** contain error when adding invoice item to stripe fails */
  error?: InputMaybe<Scalars['String']>;
  /** if true, the stripe is updated successfully, otherwise not */
  has_updated_to_stripe?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  /** represents invoice_id for which this invoice_item is for */
  invoice_id?: InputMaybe<Scalars['String']>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Scalars['Int']>;
  /** represents project_id for which this invoice_item is for */
  project_id?: InputMaybe<Scalars['uuid']>;
  /** represents type for which this invoice_item is for. It can take up values such as `pay_as_you_go`, `additional_data_usage`, `shared_data_passthrough`, `shared_pg_usage`, `shared_no_db_usage`, `shared_non_pg_usage` or `sales_tax` */
  type?: InputMaybe<Scalars['invoice_item_type']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Invoice_Item_Max_Fields = {
  __typename?: 'invoice_item_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** represents understandable invoice item description which will reflect in the actual invoice */
  description?: Maybe<Scalars['String']>;
  /** contain error when adding invoice item to stripe fails */
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** represents invoice_id for which this invoice_item is for */
  invoice_id?: Maybe<Scalars['String']>;
  /** represents month for which this invoice_item is for */
  month?: Maybe<Scalars['Int']>;
  /** represents project_id for which this invoice_item is for */
  project_id?: Maybe<Scalars['uuid']>;
  /** represents type for which this invoice_item is for. It can take up values such as `pay_as_you_go`, `additional_data_usage`, `shared_data_passthrough`, `shared_pg_usage`, `shared_no_db_usage`, `shared_non_pg_usage` or `sales_tax` */
  type?: Maybe<Scalars['invoice_item_type']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** represents year for which this invoice_item is for */
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "invoice_item" */
export type Invoice_Item_Max_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** represents understandable invoice item description which will reflect in the actual invoice */
  description?: InputMaybe<Order_By>;
  /** contain error when adding invoice item to stripe fails */
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** represents invoice_id for which this invoice_item is for */
  invoice_id?: InputMaybe<Order_By>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Order_By>;
  /** represents project_id for which this invoice_item is for */
  project_id?: InputMaybe<Order_By>;
  /** represents type for which this invoice_item is for. It can take up values such as `pay_as_you_go`, `additional_data_usage`, `shared_data_passthrough`, `shared_pg_usage`, `shared_no_db_usage`, `shared_non_pg_usage` or `sales_tax` */
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Item_Min_Fields = {
  __typename?: 'invoice_item_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** represents understandable invoice item description which will reflect in the actual invoice */
  description?: Maybe<Scalars['String']>;
  /** contain error when adding invoice item to stripe fails */
  error?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** represents invoice_id for which this invoice_item is for */
  invoice_id?: Maybe<Scalars['String']>;
  /** represents month for which this invoice_item is for */
  month?: Maybe<Scalars['Int']>;
  /** represents project_id for which this invoice_item is for */
  project_id?: Maybe<Scalars['uuid']>;
  /** represents type for which this invoice_item is for. It can take up values such as `pay_as_you_go`, `additional_data_usage`, `shared_data_passthrough`, `shared_pg_usage`, `shared_no_db_usage`, `shared_non_pg_usage` or `sales_tax` */
  type?: Maybe<Scalars['invoice_item_type']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** represents year for which this invoice_item is for */
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "invoice_item" */
export type Invoice_Item_Min_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** represents understandable invoice item description which will reflect in the actual invoice */
  description?: InputMaybe<Order_By>;
  /** contain error when adding invoice item to stripe fails */
  error?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** represents invoice_id for which this invoice_item is for */
  invoice_id?: InputMaybe<Order_By>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Order_By>;
  /** represents project_id for which this invoice_item is for */
  project_id?: InputMaybe<Order_By>;
  /** represents type for which this invoice_item is for. It can take up values such as `pay_as_you_go`, `additional_data_usage`, `shared_data_passthrough`, `shared_pg_usage`, `shared_no_db_usage`, `shared_non_pg_usage` or `sales_tax` */
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoice_item" */
export type Invoice_Item_Mutation_Response = {
  __typename?: 'invoice_item_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice_Item>;
};

/** on_conflict condition type for table "invoice_item" */
export type Invoice_Item_On_Conflict = {
  constraint: Invoice_Item_Constraint;
  update_columns?: Array<Invoice_Item_Update_Column>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice_item". */
export type Invoice_Item_Order_By = {
  amount?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  has_updated_to_stripe?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoice_item */
export type Invoice_Item_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "invoice_item" */
export enum Invoice_Item_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Error = 'error',
  /** column name */
  HasUpdatedToStripe = 'has_updated_to_stripe',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Month = 'month',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

/** select "invoice_item_aggregate_bool_exp_bool_and_arguments_columns" columns of table "invoice_item" */
export enum Invoice_Item_Select_Column_Invoice_Item_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasUpdatedToStripe = 'has_updated_to_stripe',
}

/** select "invoice_item_aggregate_bool_exp_bool_or_arguments_columns" columns of table "invoice_item" */
export enum Invoice_Item_Select_Column_Invoice_Item_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasUpdatedToStripe = 'has_updated_to_stripe',
}

/** input type for updating data in table "invoice_item" */
export type Invoice_Item_Set_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** represents understandable invoice item description which will reflect in the actual invoice */
  description?: InputMaybe<Scalars['String']>;
  /** contain error when adding invoice item to stripe fails */
  error?: InputMaybe<Scalars['String']>;
  /** if true, the stripe is updated successfully, otherwise not */
  has_updated_to_stripe?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** represents invoice_id for which this invoice_item is for */
  invoice_id?: InputMaybe<Scalars['String']>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Scalars['Int']>;
  /** represents project_id for which this invoice_item is for */
  project_id?: InputMaybe<Scalars['uuid']>;
  /** represents type for which this invoice_item is for. It can take up values such as `pay_as_you_go`, `additional_data_usage`, `shared_data_passthrough`, `shared_pg_usage`, `shared_no_db_usage`, `shared_non_pg_usage` or `sales_tax` */
  type?: InputMaybe<Scalars['invoice_item_type']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Invoice_Item_Stddev_Fields = {
  __typename?: 'invoice_item_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  /** represents month for which this invoice_item is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice_item is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoice_item" */
export type Invoice_Item_Stddev_Order_By = {
  amount?: InputMaybe<Order_By>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Item_Stddev_Pop_Fields = {
  __typename?: 'invoice_item_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  /** represents month for which this invoice_item is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice_item is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoice_item" */
export type Invoice_Item_Stddev_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Item_Stddev_Samp_Fields = {
  __typename?: 'invoice_item_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  /** represents month for which this invoice_item is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice_item is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoice_item" */
export type Invoice_Item_Stddev_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoice_item" */
export type Invoice_Item_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Item_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Item_Stream_Cursor_Value_Input = {
  amount?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** represents understandable invoice item description which will reflect in the actual invoice */
  description?: InputMaybe<Scalars['String']>;
  /** contain error when adding invoice item to stripe fails */
  error?: InputMaybe<Scalars['String']>;
  /** if true, the stripe is updated successfully, otherwise not */
  has_updated_to_stripe?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** represents invoice_id for which this invoice_item is for */
  invoice_id?: InputMaybe<Scalars['String']>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Scalars['Int']>;
  /** represents project_id for which this invoice_item is for */
  project_id?: InputMaybe<Scalars['uuid']>;
  /** represents type for which this invoice_item is for. It can take up values such as `pay_as_you_go`, `additional_data_usage`, `shared_data_passthrough`, `shared_pg_usage`, `shared_no_db_usage`, `shared_non_pg_usage` or `sales_tax` */
  type?: InputMaybe<Scalars['invoice_item_type']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Invoice_Item_Sum_Fields = {
  __typename?: 'invoice_item_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  /** represents month for which this invoice_item is for */
  month?: Maybe<Scalars['Int']>;
  /** represents year for which this invoice_item is for */
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "invoice_item" */
export type Invoice_Item_Sum_Order_By = {
  amount?: InputMaybe<Order_By>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "invoice_item_type". All fields are combined with logical 'AND'. */
export type Invoice_Item_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['invoice_item_type']>;
  _gt?: InputMaybe<Scalars['invoice_item_type']>;
  _gte?: InputMaybe<Scalars['invoice_item_type']>;
  _in?: InputMaybe<Array<Scalars['invoice_item_type']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['invoice_item_type']>;
  _lte?: InputMaybe<Scalars['invoice_item_type']>;
  _neq?: InputMaybe<Scalars['invoice_item_type']>;
  _nin?: InputMaybe<Array<Scalars['invoice_item_type']>>;
};

/** update columns of table "invoice_item" */
export enum Invoice_Item_Update_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Error = 'error',
  /** column name */
  HasUpdatedToStripe = 'has_updated_to_stripe',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  Month = 'month',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

export type Invoice_Item_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Item_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Item_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Item_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoice_Item_Var_Pop_Fields = {
  __typename?: 'invoice_item_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  /** represents month for which this invoice_item is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice_item is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoice_item" */
export type Invoice_Item_Var_Pop_Order_By = {
  amount?: InputMaybe<Order_By>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Item_Var_Samp_Fields = {
  __typename?: 'invoice_item_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  /** represents month for which this invoice_item is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice_item is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoice_item" */
export type Invoice_Item_Var_Samp_Order_By = {
  amount?: InputMaybe<Order_By>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Item_Variance_Fields = {
  __typename?: 'invoice_item_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  /** represents month for which this invoice_item is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice_item is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoice_item" */
export type Invoice_Item_Variance_Order_By = {
  amount?: InputMaybe<Order_By>;
  /** represents month for which this invoice_item is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice_item is for */
  year?: InputMaybe<Order_By>;
};

/** aggregate max on columns */
export type Invoice_Max_Fields = {
  __typename?: 'invoice_max_fields';
  attempt_count?: Maybe<Scalars['Int']>;
  collection_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** customer id that represents unique customer at stripe */
  customer_id?: Maybe<Scalars['String']>;
  /** any additional appropriate description for this invoice. This will be used on the UI */
  description?: Maybe<Scalars['String']>;
  /** due amount of the invoice without coupons and credit applied */
  due_amount?: Maybe<Scalars['numeric']>;
  invoice_url?: Maybe<Scalars['String']>;
  /** represents month for which this invoice is for */
  month?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['String']>;
  receipt_url?: Maybe<Scalars['String']>;
  /** represents status of this invoice. It can be either `paid`, `failed`, `unpaid` */
  status?: Maybe<Scalars['String']>;
  /** unique invoice id, represents invoice id at stripe */
  stripe_invoice_id?: Maybe<Scalars['String']>;
  /** subscription for which the current invoice is generated for */
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** represents year for which this invoice is for */
  year?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "invoice" */
export type Invoice_Max_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  collection_method?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** customer id that represents unique customer at stripe */
  customer_id?: InputMaybe<Order_By>;
  /** any additional appropriate description for this invoice. This will be used on the UI */
  description?: InputMaybe<Order_By>;
  invoice_url?: InputMaybe<Order_By>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  receipt_url?: InputMaybe<Order_By>;
  /** represents status of this invoice. It can be either `paid`, `failed`, `unpaid` */
  status?: InputMaybe<Order_By>;
  /** unique invoice id, represents invoice id at stripe */
  stripe_invoice_id?: InputMaybe<Order_By>;
  /** subscription for which the current invoice is generated for */
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Invoice_Min_Fields = {
  __typename?: 'invoice_min_fields';
  attempt_count?: Maybe<Scalars['Int']>;
  collection_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** customer id that represents unique customer at stripe */
  customer_id?: Maybe<Scalars['String']>;
  /** any additional appropriate description for this invoice. This will be used on the UI */
  description?: Maybe<Scalars['String']>;
  /** due amount of the invoice without coupons and credit applied */
  due_amount?: Maybe<Scalars['numeric']>;
  invoice_url?: Maybe<Scalars['String']>;
  /** represents month for which this invoice is for */
  month?: Maybe<Scalars['Int']>;
  payment_method_id?: Maybe<Scalars['String']>;
  receipt_url?: Maybe<Scalars['String']>;
  /** represents status of this invoice. It can be either `paid`, `failed`, `unpaid` */
  status?: Maybe<Scalars['String']>;
  /** unique invoice id, represents invoice id at stripe */
  stripe_invoice_id?: Maybe<Scalars['String']>;
  /** subscription for which the current invoice is generated for */
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** represents year for which this invoice is for */
  year?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "invoice" */
export type Invoice_Min_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  collection_method?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  /** customer id that represents unique customer at stripe */
  customer_id?: InputMaybe<Order_By>;
  /** any additional appropriate description for this invoice. This will be used on the UI */
  description?: InputMaybe<Order_By>;
  invoice_url?: InputMaybe<Order_By>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  receipt_url?: InputMaybe<Order_By>;
  /** represents status of this invoice. It can be either `paid`, `failed`, `unpaid` */
  status?: InputMaybe<Order_By>;
  /** unique invoice id, represents invoice id at stripe */
  stripe_invoice_id?: InputMaybe<Order_By>;
  /** subscription for which the current invoice is generated for */
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invoice" */
export type Invoice_Mutation_Response = {
  __typename?: 'invoice_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Invoice>;
};

/** input type for inserting object relation for remote table "invoice" */
export type Invoice_Obj_Rel_Insert_Input = {
  data: Invoice_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};

/** on_conflict condition type for table "invoice" */
export type Invoice_On_Conflict = {
  constraint: Invoice_Constraint;
  update_columns?: Array<Invoice_Update_Column>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

/** Ordering options when selecting data from "invoice". */
export type Invoice_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  collection_method?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_amount?: InputMaybe<Order_By>;
  invoice_coupon_discounts_aggregate?: InputMaybe<
    Invoice_Coupon_Discount_Aggregate_Order_By
  >;
  invoice_credit_usages_aggregate?: InputMaybe<
    Customer_Usage_Aggregate_Order_By
  >;
  invoice_url?: InputMaybe<Order_By>;
  items_aggregate?: InputMaybe<Invoice_Item_Aggregate_Order_By>;
  month?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Payment_Method_Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  receipt_url?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripe_invoice_id?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: invoice */
export type Invoice_Pk_Columns_Input = {
  /** unique invoice id, represents invoice id at stripe */
  stripe_invoice_id: Scalars['String'];
};

/** select columns of table "invoice" */
export enum Invoice_Select_Column {
  /** column name */
  AttemptCount = 'attempt_count',
  /** column name */
  CollectionMethod = 'collection_method',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Description = 'description',
  /** column name */
  InvoiceUrl = 'invoice_url',
  /** column name */
  Month = 'month',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  ReceiptUrl = 'receipt_url',
  /** column name */
  Status = 'status',
  /** column name */
  StripeInvoiceId = 'stripe_invoice_id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

/** input type for updating data in table "invoice" */
export type Invoice_Set_Input = {
  attempt_count?: InputMaybe<Scalars['Int']>;
  collection_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** customer id that represents unique customer at stripe */
  customer_id?: InputMaybe<Scalars['String']>;
  /** any additional appropriate description for this invoice. This will be used on the UI */
  description?: InputMaybe<Scalars['String']>;
  invoice_url?: InputMaybe<Scalars['String']>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  receipt_url?: InputMaybe<Scalars['String']>;
  /** represents status of this invoice. It can be either `paid`, `failed`, `unpaid` */
  status?: InputMaybe<Scalars['String']>;
  /** unique invoice id, represents invoice id at stripe */
  stripe_invoice_id?: InputMaybe<Scalars['String']>;
  /** subscription for which the current invoice is generated for */
  subscription_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Invoice_Stddev_Fields = {
  __typename?: 'invoice_stddev_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  /** due amount of the invoice without coupons and credit applied */
  due_amount?: Maybe<Scalars['numeric']>;
  /** represents month for which this invoice is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invoice" */
export type Invoice_Stddev_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Invoice_Stddev_Pop_Fields = {
  __typename?: 'invoice_stddev_pop_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  /** due amount of the invoice without coupons and credit applied */
  due_amount?: Maybe<Scalars['numeric']>;
  /** represents month for which this invoice is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invoice" */
export type Invoice_Stddev_Pop_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Invoice_Stddev_Samp_Fields = {
  __typename?: 'invoice_stddev_samp_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  /** due amount of the invoice without coupons and credit applied */
  due_amount?: Maybe<Scalars['numeric']>;
  /** represents month for which this invoice is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invoice" */
export type Invoice_Stddev_Samp_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "invoice" */
export type Invoice_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invoice_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invoice_Stream_Cursor_Value_Input = {
  attempt_count?: InputMaybe<Scalars['Int']>;
  collection_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** customer id that represents unique customer at stripe */
  customer_id?: InputMaybe<Scalars['String']>;
  /** any additional appropriate description for this invoice. This will be used on the UI */
  description?: InputMaybe<Scalars['String']>;
  invoice_url?: InputMaybe<Scalars['String']>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Scalars['Int']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  receipt_url?: InputMaybe<Scalars['String']>;
  /** represents status of this invoice. It can be either `paid`, `failed`, `unpaid` */
  status?: InputMaybe<Scalars['String']>;
  /** unique invoice id, represents invoice id at stripe */
  stripe_invoice_id?: InputMaybe<Scalars['String']>;
  /** subscription for which the current invoice is generated for */
  subscription_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Invoice_Sum_Fields = {
  __typename?: 'invoice_sum_fields';
  attempt_count?: Maybe<Scalars['Int']>;
  /** due amount of the invoice without coupons and credit applied */
  due_amount?: Maybe<Scalars['numeric']>;
  /** represents month for which this invoice is for */
  month?: Maybe<Scalars['Int']>;
  /** represents year for which this invoice is for */
  year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "invoice" */
export type Invoice_Sum_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Order_By>;
};

/** update columns of table "invoice" */
export enum Invoice_Update_Column {
  /** column name */
  AttemptCount = 'attempt_count',
  /** column name */
  CollectionMethod = 'collection_method',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Description = 'description',
  /** column name */
  InvoiceUrl = 'invoice_url',
  /** column name */
  Month = 'month',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  ReceiptUrl = 'receipt_url',
  /** column name */
  Status = 'status',
  /** column name */
  StripeInvoiceId = 'stripe_invoice_id',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

export type Invoice_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Invoice_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Invoice_Set_Input>;
  /** filter the rows which have to be updated */
  where: Invoice_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Invoice_Var_Pop_Fields = {
  __typename?: 'invoice_var_pop_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  /** due amount of the invoice without coupons and credit applied */
  due_amount?: Maybe<Scalars['numeric']>;
  /** represents month for which this invoice is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invoice" */
export type Invoice_Var_Pop_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Invoice_Var_Samp_Fields = {
  __typename?: 'invoice_var_samp_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  /** due amount of the invoice without coupons and credit applied */
  due_amount?: Maybe<Scalars['numeric']>;
  /** represents month for which this invoice is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invoice" */
export type Invoice_Var_Samp_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Invoice_Variance_Fields = {
  __typename?: 'invoice_variance_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  /** due amount of the invoice without coupons and credit applied */
  due_amount?: Maybe<Scalars['numeric']>;
  /** represents month for which this invoice is for */
  month?: Maybe<Scalars['Float']>;
  /** represents year for which this invoice is for */
  year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invoice" */
export type Invoice_Variance_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  /** represents month for which this invoice is for */
  month?: InputMaybe<Order_By>;
  /** represents year for which this invoice is for */
  year?: InputMaybe<Order_By>;
};

/** columns and relationships of "jobs" */
export type Jobs = {
  __typename?: 'jobs';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  github_push_event_jobs: Array<Github_Push_Event_Job>;
  /** An aggregate relationship */
  github_push_event_jobs_aggregate: Github_Push_Event_Job_Aggregate;
  id: Scalars['uuid'];
  input: Scalars['jsonb'];
  job_type: Scalars['String'];
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  project_name?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "jobs" */
export type JobsGithub_Push_Event_JobsArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Job_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
};

/** columns and relationships of "jobs" */
export type JobsGithub_Push_Event_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Job_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
};

/** columns and relationships of "jobs" */
export type JobsInputArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "jobs" */
export type JobsTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "jobs" */
export type JobsTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** aggregated selection of "jobs" */
export type Jobs_Aggregate = {
  __typename?: 'jobs_aggregate';
  aggregate?: Maybe<Jobs_Aggregate_Fields>;
  nodes: Array<Jobs>;
};

export type Jobs_Aggregate_Bool_Exp = {
  count?: InputMaybe<Jobs_Aggregate_Bool_Exp_Count>;
};

export type Jobs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Jobs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "jobs" */
export type Jobs_Aggregate_Fields = {
  __typename?: 'jobs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Jobs_Max_Fields>;
  min?: Maybe<Jobs_Min_Fields>;
};

/** aggregate fields of "jobs" */
export type Jobs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "jobs" */
export type Jobs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Jobs_Max_Order_By>;
  min?: InputMaybe<Jobs_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Jobs_Append_Input = {
  input?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "jobs" */
export type Jobs_Arr_Rel_Insert_Input = {
  data: Array<Jobs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "jobs". All fields are combined with a logical 'AND'. */
export type Jobs_Bool_Exp = {
  _and?: InputMaybe<Array<Jobs_Bool_Exp>>;
  _not?: InputMaybe<Jobs_Bool_Exp>;
  _or?: InputMaybe<Array<Jobs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  github_push_event_jobs?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
  github_push_event_jobs_aggregate?: InputMaybe<
    Github_Push_Event_Job_Aggregate_Bool_Exp
  >;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  input?: InputMaybe<Jsonb_Comparison_Exp>;
  job_type?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_name?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  tasks?: InputMaybe<Tasks_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "jobs" */
export enum Jobs_Constraint {
  /** unique or primary key constraint on columns "id" */
  JobsPkey = 'jobs_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Jobs_Delete_At_Path_Input = {
  input?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Jobs_Delete_Elem_Input = {
  input?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Jobs_Delete_Key_Input = {
  input?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "jobs" */
export type Jobs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  github_push_event_jobs?: InputMaybe<
    Github_Push_Event_Job_Arr_Rel_Insert_Input
  >;
  id?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['jsonb']>;
  job_type?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  tasks?: InputMaybe<Tasks_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Jobs_Max_Fields = {
  __typename?: 'jobs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  project_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "jobs" */
export type Jobs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_type?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  project_name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Jobs_Min_Fields = {
  __typename?: 'jobs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  project_name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "jobs" */
export type Jobs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_type?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  project_name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "jobs" */
export type Jobs_Mutation_Response = {
  __typename?: 'jobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Jobs>;
};

/** input type for inserting object relation for remote table "jobs" */
export type Jobs_Obj_Rel_Insert_Input = {
  data: Jobs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};

/** on_conflict condition type for table "jobs" */
export type Jobs_On_Conflict = {
  constraint: Jobs_Constraint;
  update_columns?: Array<Jobs_Update_Column>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

/** Ordering options when selecting data from "jobs". */
export type Jobs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  github_push_event_jobs_aggregate?: InputMaybe<
    Github_Push_Event_Job_Aggregate_Order_By
  >;
  id?: InputMaybe<Order_By>;
  input?: InputMaybe<Order_By>;
  job_type?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  project_name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: jobs */
export type Jobs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Jobs_Prepend_Input = {
  input?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "jobs" */
export enum Jobs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Input = 'input',
  /** column name */
  JobType = 'job_type',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "jobs" */
export type Jobs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['jsonb']>;
  job_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "jobs" */
export type Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Jobs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  input?: InputMaybe<Scalars['jsonb']>;
  job_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "jobs" */
export enum Jobs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Input = 'input',
  /** column name */
  JobType = 'job_type',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Jobs_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Jobs_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Jobs_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Jobs_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Jobs_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Jobs_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Jobs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Jobs_Bool_Exp;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: InputMaybe<Array<Scalars['json']>>;
  /** does the array contain the given value */
  _contains?: InputMaybe<Array<Scalars['json']>>;
  _eq?: InputMaybe<Array<Scalars['json']>>;
  _gt?: InputMaybe<Array<Scalars['json']>>;
  _gte?: InputMaybe<Array<Scalars['json']>>;
  _in?: InputMaybe<Array<Array<Scalars['json']>>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Array<Scalars['json']>>;
  _lte?: InputMaybe<Array<Scalars['json']>>;
  _neq?: InputMaybe<Array<Scalars['json']>>;
  _nin?: InputMaybe<Array<Array<Scalars['json']>>>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** Labels that can be created per user */
export type Label = {
  __typename?: 'label';
  color?: Maybe<Scalars['String']>;
  created_at: Scalars['timetz'];
  created_by: Scalars['uuid'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  project_labels: Array<Project_Labels>;
  /** An aggregate relationship */
  project_labels_aggregate: Project_Labels_Aggregate;
};

/** Labels that can be created per user */
export type LabelProject_LabelsArgs = {
  distinct_on?: InputMaybe<Array<Project_Labels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Labels_Order_By>>;
  where?: InputMaybe<Project_Labels_Bool_Exp>;
};

/** Labels that can be created per user */
export type LabelProject_Labels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Labels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Labels_Order_By>>;
  where?: InputMaybe<Project_Labels_Bool_Exp>;
};

/** aggregated selection of "label" */
export type Label_Aggregate = {
  __typename?: 'label_aggregate';
  aggregate?: Maybe<Label_Aggregate_Fields>;
  nodes: Array<Label>;
};

/** aggregate fields of "label" */
export type Label_Aggregate_Fields = {
  __typename?: 'label_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Label_Max_Fields>;
  min?: Maybe<Label_Min_Fields>;
};

/** aggregate fields of "label" */
export type Label_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Label_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "label". All fields are combined with a logical 'AND'. */
export type Label_Bool_Exp = {
  _and?: InputMaybe<Array<Label_Bool_Exp>>;
  _not?: InputMaybe<Label_Bool_Exp>;
  _or?: InputMaybe<Array<Label_Bool_Exp>>;
  color?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timetz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  project_labels?: InputMaybe<Project_Labels_Bool_Exp>;
  project_labels_aggregate?: InputMaybe<Project_Labels_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "label" */
export enum Label_Constraint {
  /** unique or primary key constraint on columns "created_by", "name" */
  LabelNameCreatedByKey = 'label_name_created_by_key',
  /** unique or primary key constraint on columns "id" */
  TagPkey = 'tag_pkey',
}

/** input type for inserting data into table "label" */
export type Label_Insert_Input = {
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timetz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  project_labels?: InputMaybe<Project_Labels_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Label_Max_Fields = {
  __typename?: 'label_max_fields';
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timetz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Label_Min_Fields = {
  __typename?: 'label_min_fields';
  color?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timetz']>;
  created_by?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "label" */
export type Label_Mutation_Response = {
  __typename?: 'label_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Label>;
};

/** input type for inserting object relation for remote table "label" */
export type Label_Obj_Rel_Insert_Input = {
  data: Label_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Label_On_Conflict>;
};

/** on_conflict condition type for table "label" */
export type Label_On_Conflict = {
  constraint: Label_Constraint;
  update_columns?: Array<Label_Update_Column>;
  where?: InputMaybe<Label_Bool_Exp>;
};

/** Ordering options when selecting data from "label". */
export type Label_Order_By = {
  color?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  project_labels_aggregate?: InputMaybe<Project_Labels_Aggregate_Order_By>;
};

/** primary key columns input for table: label */
export type Label_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "label" */
export enum Label_Select_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "label" */
export type Label_Set_Input = {
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timetz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "label" */
export type Label_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Label_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Label_Stream_Cursor_Value_Input = {
  color?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timetz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "label" */
export enum Label_Update_Column {
  /** column name */
  Color = 'color',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

export type Label_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Label_Set_Input>;
  /** filter the rows which have to be updated */
  where: Label_Bool_Exp;
};

/** letsencrypt status of a gateway worker */
export type Letsencrypt_Status = {
  __typename?: 'letsencrypt_status';
  cert_id: Scalars['String'];
  /** An object relationship */
  custom_domain: Custom_Domain;
  fqdn: Scalars['String'];
  is_active: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  worker_id: Scalars['uuid'];
};

/** aggregated selection of "letsencrypt_status" */
export type Letsencrypt_Status_Aggregate = {
  __typename?: 'letsencrypt_status_aggregate';
  aggregate?: Maybe<Letsencrypt_Status_Aggregate_Fields>;
  nodes: Array<Letsencrypt_Status>;
};

export type Letsencrypt_Status_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Letsencrypt_Status_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Letsencrypt_Status_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Letsencrypt_Status_Aggregate_Bool_Exp_Count>;
};

export type Letsencrypt_Status_Aggregate_Bool_Exp_Bool_And = {
  arguments: Letsencrypt_Status_Select_Column_Letsencrypt_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Letsencrypt_Status_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Letsencrypt_Status_Select_Column_Letsencrypt_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Letsencrypt_Status_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Letsencrypt_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "letsencrypt_status" */
export type Letsencrypt_Status_Aggregate_Fields = {
  __typename?: 'letsencrypt_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Letsencrypt_Status_Max_Fields>;
  min?: Maybe<Letsencrypt_Status_Min_Fields>;
};

/** aggregate fields of "letsencrypt_status" */
export type Letsencrypt_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Letsencrypt_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "letsencrypt_status" */
export type Letsencrypt_Status_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Letsencrypt_Status_Max_Order_By>;
  min?: InputMaybe<Letsencrypt_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "letsencrypt_status" */
export type Letsencrypt_Status_Arr_Rel_Insert_Input = {
  data: Array<Letsencrypt_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Letsencrypt_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "letsencrypt_status". All fields are combined with a logical 'AND'. */
export type Letsencrypt_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Letsencrypt_Status_Bool_Exp>>;
  _not?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Letsencrypt_Status_Bool_Exp>>;
  cert_id?: InputMaybe<String_Comparison_Exp>;
  custom_domain?: InputMaybe<Custom_Domain_Bool_Exp>;
  fqdn?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  worker_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "letsencrypt_status" */
export enum Letsencrypt_Status_Constraint {
  /** unique or primary key constraint on columns "fqdn" */
  LetsencryptStatusPkey = 'letsencrypt_status_pkey',
}

/** input type for inserting data into table "letsencrypt_status" */
export type Letsencrypt_Status_Insert_Input = {
  cert_id?: InputMaybe<Scalars['String']>;
  custom_domain?: InputMaybe<Custom_Domain_Obj_Rel_Insert_Input>;
  fqdn?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Letsencrypt_Status_Max_Fields = {
  __typename?: 'letsencrypt_status_max_fields';
  cert_id?: Maybe<Scalars['String']>;
  fqdn?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "letsencrypt_status" */
export type Letsencrypt_Status_Max_Order_By = {
  cert_id?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Letsencrypt_Status_Min_Fields = {
  __typename?: 'letsencrypt_status_min_fields';
  cert_id?: Maybe<Scalars['String']>;
  fqdn?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "letsencrypt_status" */
export type Letsencrypt_Status_Min_Order_By = {
  cert_id?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "letsencrypt_status" */
export type Letsencrypt_Status_Mutation_Response = {
  __typename?: 'letsencrypt_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Letsencrypt_Status>;
};

/** on_conflict condition type for table "letsencrypt_status" */
export type Letsencrypt_Status_On_Conflict = {
  constraint: Letsencrypt_Status_Constraint;
  update_columns?: Array<Letsencrypt_Status_Update_Column>;
  where?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "letsencrypt_status". */
export type Letsencrypt_Status_Order_By = {
  cert_id?: InputMaybe<Order_By>;
  custom_domain?: InputMaybe<Custom_Domain_Order_By>;
  fqdn?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: letsencrypt_status */
export type Letsencrypt_Status_Pk_Columns_Input = {
  fqdn: Scalars['String'];
};

/** select columns of table "letsencrypt_status" */
export enum Letsencrypt_Status_Select_Column {
  /** column name */
  CertId = 'cert_id',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Message = 'message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkerId = 'worker_id',
}

/** select "letsencrypt_status_aggregate_bool_exp_bool_and_arguments_columns" columns of table "letsencrypt_status" */
export enum Letsencrypt_Status_Select_Column_Letsencrypt_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** select "letsencrypt_status_aggregate_bool_exp_bool_or_arguments_columns" columns of table "letsencrypt_status" */
export enum Letsencrypt_Status_Select_Column_Letsencrypt_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** input type for updating data in table "letsencrypt_status" */
export type Letsencrypt_Status_Set_Input = {
  cert_id?: InputMaybe<Scalars['String']>;
  fqdn?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "letsencrypt_status" */
export type Letsencrypt_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Letsencrypt_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Letsencrypt_Status_Stream_Cursor_Value_Input = {
  cert_id?: InputMaybe<Scalars['String']>;
  fqdn?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "letsencrypt_status" */
export enum Letsencrypt_Status_Update_Column {
  /** column name */
  CertId = 'cert_id',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Message = 'message',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkerId = 'worker_id',
}

export type Letsencrypt_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Letsencrypt_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Letsencrypt_Status_Bool_Exp;
};

/** Record of all manual and automated actions performed on a license.  */
export type License_Activity = {
  __typename?: 'license_activity';
  action: Scalars['String'];
  activity_performed_by: Scalars['String'];
  activity_type: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  license?: Maybe<Licenses>;
  license_email: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "license_activity" */
export type License_Activity_Aggregate = {
  __typename?: 'license_activity_aggregate';
  aggregate?: Maybe<License_Activity_Aggregate_Fields>;
  nodes: Array<License_Activity>;
};

/** aggregate fields of "license_activity" */
export type License_Activity_Aggregate_Fields = {
  __typename?: 'license_activity_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<License_Activity_Max_Fields>;
  min?: Maybe<License_Activity_Min_Fields>;
};

/** aggregate fields of "license_activity" */
export type License_Activity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<License_Activity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "license_activity". All fields are combined with a logical 'AND'. */
export type License_Activity_Bool_Exp = {
  _and?: InputMaybe<Array<License_Activity_Bool_Exp>>;
  _not?: InputMaybe<License_Activity_Bool_Exp>;
  _or?: InputMaybe<Array<License_Activity_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  activity_performed_by?: InputMaybe<String_Comparison_Exp>;
  activity_type?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  license?: InputMaybe<Licenses_Bool_Exp>;
  license_email?: InputMaybe<String_Comparison_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "license_activity" */
export enum License_Activity_Constraint {
  /** unique or primary key constraint on columns "id" */
  LicenseActivityPkey = 'license_activity_pkey',
}

/** input type for inserting data into table "license_activity" */
export type License_Activity_Insert_Input = {
  action?: InputMaybe<Scalars['String']>;
  activity_performed_by?: InputMaybe<Scalars['String']>;
  activity_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  license?: InputMaybe<Licenses_Obj_Rel_Insert_Input>;
  license_email?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type License_Activity_Max_Fields = {
  __typename?: 'license_activity_max_fields';
  action?: Maybe<Scalars['String']>;
  activity_performed_by?: Maybe<Scalars['String']>;
  activity_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  license_email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type License_Activity_Min_Fields = {
  __typename?: 'license_activity_min_fields';
  action?: Maybe<Scalars['String']>;
  activity_performed_by?: Maybe<Scalars['String']>;
  activity_type?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  license_email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "license_activity" */
export type License_Activity_Mutation_Response = {
  __typename?: 'license_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<License_Activity>;
};

/** on_conflict condition type for table "license_activity" */
export type License_Activity_On_Conflict = {
  constraint: License_Activity_Constraint;
  update_columns?: Array<License_Activity_Update_Column>;
  where?: InputMaybe<License_Activity_Bool_Exp>;
};

/** Ordering options when selecting data from "license_activity". */
export type License_Activity_Order_By = {
  action?: InputMaybe<Order_By>;
  activity_performed_by?: InputMaybe<Order_By>;
  activity_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  license?: InputMaybe<Licenses_Order_By>;
  license_email?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: license_activity */
export type License_Activity_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "license_activity" */
export enum License_Activity_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActivityPerformedBy = 'activity_performed_by',
  /** column name */
  ActivityType = 'activity_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LicenseEmail = 'license_email',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "license_activity" */
export type License_Activity_Set_Input = {
  action?: InputMaybe<Scalars['String']>;
  activity_performed_by?: InputMaybe<Scalars['String']>;
  activity_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  license_email?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "license_activity" */
export type License_Activity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: License_Activity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type License_Activity_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  activity_performed_by?: InputMaybe<Scalars['String']>;
  activity_type?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  license_email?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "license_activity" */
export enum License_Activity_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActivityPerformedBy = 'activity_performed_by',
  /** column name */
  ActivityType = 'activity_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LicenseEmail = 'license_email',
  /** column name */
  Note = 'note',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type License_Activity_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<License_Activity_Set_Input>;
  /** filter the rows which have to be updated */
  where: License_Activity_Bool_Exp;
};

/** columns and relationships of "license_instance" */
export type License_Instance = {
  __typename?: 'license_instance';
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  metadata_db_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "license_instance" */
export type License_Instance_Aggregate = {
  __typename?: 'license_instance_aggregate';
  aggregate?: Maybe<License_Instance_Aggregate_Fields>;
  nodes: Array<License_Instance>;
};

/** aggregate fields of "license_instance" */
export type License_Instance_Aggregate_Fields = {
  __typename?: 'license_instance_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<License_Instance_Max_Fields>;
  min?: Maybe<License_Instance_Min_Fields>;
};

/** aggregate fields of "license_instance" */
export type License_Instance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<License_Instance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "license_instance". All fields are combined with a logical 'AND'. */
export type License_Instance_Bool_Exp = {
  _and?: InputMaybe<Array<License_Instance_Bool_Exp>>;
  _not?: InputMaybe<License_Instance_Bool_Exp>;
  _or?: InputMaybe<Array<License_Instance_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  metadata_db_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "license_instance" */
export enum License_Instance_Constraint {
  /** unique or primary key constraint on columns "metadata_db_id" */
  LicenseInstancePkey = 'license_instance_pkey',
}

/** input type for inserting data into table "license_instance" */
export type License_Instance_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  metadata_db_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type License_Instance_Max_Fields = {
  __typename?: 'license_instance_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  metadata_db_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type License_Instance_Min_Fields = {
  __typename?: 'license_instance_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  metadata_db_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "license_instance" */
export type License_Instance_Mutation_Response = {
  __typename?: 'license_instance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<License_Instance>;
};

/** on_conflict condition type for table "license_instance" */
export type License_Instance_On_Conflict = {
  constraint: License_Instance_Constraint;
  update_columns?: Array<License_Instance_Update_Column>;
  where?: InputMaybe<License_Instance_Bool_Exp>;
};

/** Ordering options when selecting data from "license_instance". */
export type License_Instance_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  metadata_db_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: license_instance */
export type License_Instance_Pk_Columns_Input = {
  metadata_db_id: Scalars['uuid'];
};

/** select columns of table "license_instance" */
export enum License_Instance_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  MetadataDbId = 'metadata_db_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "license_instance" */
export type License_Instance_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  metadata_db_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "license_instance" */
export type License_Instance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: License_Instance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type License_Instance_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  metadata_db_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "license_instance" */
export enum License_Instance_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  MetadataDbId = 'metadata_db_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type License_Instance_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<License_Instance_Set_Input>;
  /** filter the rows which have to be updated */
  where: License_Instance_Bool_Exp;
};

/** columns and relationships of "license_type" */
export type License_Type = {
  __typename?: 'license_type';
  comment?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

/** aggregated selection of "license_type" */
export type License_Type_Aggregate = {
  __typename?: 'license_type_aggregate';
  aggregate?: Maybe<License_Type_Aggregate_Fields>;
  nodes: Array<License_Type>;
};

/** aggregate fields of "license_type" */
export type License_Type_Aggregate_Fields = {
  __typename?: 'license_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<License_Type_Max_Fields>;
  min?: Maybe<License_Type_Min_Fields>;
};

/** aggregate fields of "license_type" */
export type License_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<License_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "license_type". All fields are combined with a logical 'AND'. */
export type License_Type_Bool_Exp = {
  _and?: InputMaybe<Array<License_Type_Bool_Exp>>;
  _not?: InputMaybe<License_Type_Bool_Exp>;
  _or?: InputMaybe<Array<License_Type_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "license_type" */
export enum License_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  LicenseTypePkey = 'license_type_pkey',
}

export enum License_Type_Enum {
  /** A long-lived EE license requested internally */
  Offline = 'offline',
  /** An EE license which has been paid for */
  Paid = 'paid',
  /** An EE license issued for free with a limited validity timespan */
  Trial = 'trial',
}

/** Boolean expression to compare columns of type "license_type_enum". All fields are combined with logical 'AND'. */
export type License_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<License_Type_Enum>;
  _in?: InputMaybe<Array<License_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<License_Type_Enum>;
  _nin?: InputMaybe<Array<License_Type_Enum>>;
};

/** input type for inserting data into table "license_type" */
export type License_Type_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type License_Type_Max_Fields = {
  __typename?: 'license_type_max_fields';
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type License_Type_Min_Fields = {
  __typename?: 'license_type_min_fields';
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "license_type" */
export type License_Type_Mutation_Response = {
  __typename?: 'license_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<License_Type>;
};

/** on_conflict condition type for table "license_type" */
export type License_Type_On_Conflict = {
  constraint: License_Type_Constraint;
  update_columns?: Array<License_Type_Update_Column>;
  where?: InputMaybe<License_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "license_type". */
export type License_Type_Order_By = {
  comment?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: license_type */
export type License_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "license_type" */
export enum License_Type_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "license_type" */
export type License_Type_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "license_type" */
export type License_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: License_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type License_Type_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "license_type" */
export enum License_Type_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Name = 'name',
}

export type License_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<License_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: License_Type_Bool_Exp;
};

/** columns and relationships of "licenses" */
export type Licenses = {
  __typename?: 'licenses';
  client_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  expiry_at: Scalars['timestamptz'];
  grace_at: Scalars['timestamptz'];
  license_type: License_Type_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "licenses" */
export type Licenses_Aggregate = {
  __typename?: 'licenses_aggregate';
  aggregate?: Maybe<Licenses_Aggregate_Fields>;
  nodes: Array<Licenses>;
};

/** aggregate fields of "licenses" */
export type Licenses_Aggregate_Fields = {
  __typename?: 'licenses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Licenses_Max_Fields>;
  min?: Maybe<Licenses_Min_Fields>;
};

/** aggregate fields of "licenses" */
export type Licenses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Licenses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "licenses". All fields are combined with a logical 'AND'. */
export type Licenses_Bool_Exp = {
  _and?: InputMaybe<Array<Licenses_Bool_Exp>>;
  _not?: InputMaybe<Licenses_Bool_Exp>;
  _or?: InputMaybe<Array<Licenses_Bool_Exp>>;
  client_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  expiry_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  grace_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  license_type?: InputMaybe<License_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "licenses" */
export enum Licenses_Constraint {
  /** unique or primary key constraint on columns "email" */
  LicensesPkey = 'licenses_pkey',
}

/** input type for inserting data into table "licenses" */
export type Licenses_Insert_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  expiry_at?: InputMaybe<Scalars['timestamptz']>;
  grace_at?: InputMaybe<Scalars['timestamptz']>;
  license_type?: InputMaybe<License_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Licenses_Max_Fields = {
  __typename?: 'licenses_max_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  expiry_at?: Maybe<Scalars['timestamptz']>;
  grace_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Licenses_Min_Fields = {
  __typename?: 'licenses_min_fields';
  client_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  expiry_at?: Maybe<Scalars['timestamptz']>;
  grace_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "licenses" */
export type Licenses_Mutation_Response = {
  __typename?: 'licenses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Licenses>;
};

/** input type for inserting object relation for remote table "licenses" */
export type Licenses_Obj_Rel_Insert_Input = {
  data: Licenses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Licenses_On_Conflict>;
};

/** on_conflict condition type for table "licenses" */
export type Licenses_On_Conflict = {
  constraint: Licenses_Constraint;
  update_columns?: Array<Licenses_Update_Column>;
  where?: InputMaybe<Licenses_Bool_Exp>;
};

/** Ordering options when selecting data from "licenses". */
export type Licenses_Order_By = {
  client_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  expiry_at?: InputMaybe<Order_By>;
  grace_at?: InputMaybe<Order_By>;
  license_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: licenses */
export type Licenses_Pk_Columns_Input = {
  email: Scalars['String'];
};

/** select columns of table "licenses" */
export enum Licenses_Select_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  ExpiryAt = 'expiry_at',
  /** column name */
  GraceAt = 'grace_at',
  /** column name */
  LicenseType = 'license_type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "licenses" */
export type Licenses_Set_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  expiry_at?: InputMaybe<Scalars['timestamptz']>;
  grace_at?: InputMaybe<Scalars['timestamptz']>;
  license_type?: InputMaybe<License_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "licenses" */
export type Licenses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Licenses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Licenses_Stream_Cursor_Value_Input = {
  client_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  expiry_at?: InputMaybe<Scalars['timestamptz']>;
  grace_at?: InputMaybe<Scalars['timestamptz']>;
  license_type?: InputMaybe<License_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "licenses" */
export enum Licenses_Update_Column {
  /** column name */
  ClientId = 'client_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  ExpiryAt = 'expiry_at',
  /** column name */
  GraceAt = 'grace_at',
  /** column name */
  LicenseType = 'license_type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Licenses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Licenses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Licenses_Bool_Exp;
};

/** Proxy service used by dedicated VPCs to connect to Lux and other regional resources */
export type Lux_Proxy = {
  __typename?: 'lux_proxy';
  ami_id?: Maybe<Scalars['String']>;
  cloud: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  disable_infra_auto_apply: Scalars['Boolean'];
  infra_status?: Maybe<Infra_Status_Enum>;
  input_variables?: Maybe<Scalars['jsonb']>;
  output_variables?: Maybe<Scalars['jsonb']>;
  region: Scalars['String'];
  /** An object relationship */
  region_info?: Maybe<Region>;
  updated_at: Scalars['timestamptz'];
};

/** Proxy service used by dedicated VPCs to connect to Lux and other regional resources */
export type Lux_ProxyInput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Proxy service used by dedicated VPCs to connect to Lux and other regional resources */
export type Lux_ProxyOutput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "lux_proxy" */
export type Lux_Proxy_Aggregate = {
  __typename?: 'lux_proxy_aggregate';
  aggregate?: Maybe<Lux_Proxy_Aggregate_Fields>;
  nodes: Array<Lux_Proxy>;
};

export type Lux_Proxy_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Lux_Proxy_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Lux_Proxy_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Lux_Proxy_Aggregate_Bool_Exp_Count>;
};

export type Lux_Proxy_Aggregate_Bool_Exp_Bool_And = {
  arguments: Lux_Proxy_Select_Column_Lux_Proxy_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lux_Proxy_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lux_Proxy_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Lux_Proxy_Select_Column_Lux_Proxy_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lux_Proxy_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Lux_Proxy_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Lux_Proxy_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Lux_Proxy_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "lux_proxy" */
export type Lux_Proxy_Aggregate_Fields = {
  __typename?: 'lux_proxy_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lux_Proxy_Max_Fields>;
  min?: Maybe<Lux_Proxy_Min_Fields>;
};

/** aggregate fields of "lux_proxy" */
export type Lux_Proxy_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lux_Proxy_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "lux_proxy" */
export type Lux_Proxy_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Lux_Proxy_Max_Order_By>;
  min?: InputMaybe<Lux_Proxy_Min_Order_By>;
};

/** Represents AMIs generated for lux proxy service */
export type Lux_Proxy_Ami = {
  __typename?: 'lux_proxy_ami';
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  commit_hash: Scalars['String'];
  created_at: Scalars['timestamptz'];
  region: Scalars['String'];
  remarks: Scalars['String'];
};

/** aggregated selection of "lux_proxy_ami" */
export type Lux_Proxy_Ami_Aggregate = {
  __typename?: 'lux_proxy_ami_aggregate';
  aggregate?: Maybe<Lux_Proxy_Ami_Aggregate_Fields>;
  nodes: Array<Lux_Proxy_Ami>;
};

/** aggregate fields of "lux_proxy_ami" */
export type Lux_Proxy_Ami_Aggregate_Fields = {
  __typename?: 'lux_proxy_ami_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Lux_Proxy_Ami_Max_Fields>;
  min?: Maybe<Lux_Proxy_Ami_Min_Fields>;
};

/** aggregate fields of "lux_proxy_ami" */
export type Lux_Proxy_Ami_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Lux_Proxy_Ami_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "lux_proxy_ami". All fields are combined with a logical 'AND'. */
export type Lux_Proxy_Ami_Bool_Exp = {
  _and?: InputMaybe<Array<Lux_Proxy_Ami_Bool_Exp>>;
  _not?: InputMaybe<Lux_Proxy_Ami_Bool_Exp>;
  _or?: InputMaybe<Array<Lux_Proxy_Ami_Bool_Exp>>;
  ami_id?: InputMaybe<String_Comparison_Exp>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  commit_hash?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "lux_proxy_ami" */
export enum Lux_Proxy_Ami_Constraint {
  /** unique or primary key constraint on columns "region", "cloud", "ami_id" */
  LuxProxyAmiPkey = 'lux_proxy_ami_pkey',
}

/** input type for inserting data into table "lux_proxy_ami" */
export type Lux_Proxy_Ami_Insert_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  commit_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  region?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Lux_Proxy_Ami_Max_Fields = {
  __typename?: 'lux_proxy_ami_max_fields';
  ami_id?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  commit_hash?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  region?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Lux_Proxy_Ami_Min_Fields = {
  __typename?: 'lux_proxy_ami_min_fields';
  ami_id?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  commit_hash?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  region?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "lux_proxy_ami" */
export type Lux_Proxy_Ami_Mutation_Response = {
  __typename?: 'lux_proxy_ami_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lux_Proxy_Ami>;
};

/** on_conflict condition type for table "lux_proxy_ami" */
export type Lux_Proxy_Ami_On_Conflict = {
  constraint: Lux_Proxy_Ami_Constraint;
  update_columns?: Array<Lux_Proxy_Ami_Update_Column>;
  where?: InputMaybe<Lux_Proxy_Ami_Bool_Exp>;
};

/** Ordering options when selecting data from "lux_proxy_ami". */
export type Lux_Proxy_Ami_Order_By = {
  ami_id?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  commit_hash?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lux_proxy_ami */
export type Lux_Proxy_Ami_Pk_Columns_Input = {
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  region: Scalars['String'];
};

/** select columns of table "lux_proxy_ami" */
export enum Lux_Proxy_Ami_Select_Column {
  /** column name */
  AmiId = 'ami_id',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CommitHash = 'commit_hash',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Region = 'region',
  /** column name */
  Remarks = 'remarks',
}

/** input type for updating data in table "lux_proxy_ami" */
export type Lux_Proxy_Ami_Set_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  commit_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  region?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "lux_proxy_ami" */
export type Lux_Proxy_Ami_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lux_Proxy_Ami_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lux_Proxy_Ami_Stream_Cursor_Value_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  commit_hash?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  region?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
};

/** update columns of table "lux_proxy_ami" */
export enum Lux_Proxy_Ami_Update_Column {
  /** column name */
  AmiId = 'ami_id',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CommitHash = 'commit_hash',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Region = 'region',
  /** column name */
  Remarks = 'remarks',
}

export type Lux_Proxy_Ami_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lux_Proxy_Ami_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lux_Proxy_Ami_Bool_Exp;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Lux_Proxy_Append_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "lux_proxy" */
export type Lux_Proxy_Arr_Rel_Insert_Input = {
  data: Array<Lux_Proxy_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Lux_Proxy_On_Conflict>;
};

/** Boolean expression to filter rows from the table "lux_proxy". All fields are combined with a logical 'AND'. */
export type Lux_Proxy_Bool_Exp = {
  _and?: InputMaybe<Array<Lux_Proxy_Bool_Exp>>;
  _not?: InputMaybe<Lux_Proxy_Bool_Exp>;
  _or?: InputMaybe<Array<Lux_Proxy_Bool_Exp>>;
  ami_id?: InputMaybe<String_Comparison_Exp>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  disable_infra_auto_apply?: InputMaybe<Boolean_Comparison_Exp>;
  infra_status?: InputMaybe<Infra_Status_Enum_Comparison_Exp>;
  input_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  output_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  region_info?: InputMaybe<Region_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "lux_proxy" */
export enum Lux_Proxy_Constraint {
  /** unique or primary key constraint on columns "region", "cloud" */
  LuxProxyPkey = 'lux_proxy_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Lux_Proxy_Delete_At_Path_Input = {
  input_variables?: InputMaybe<Array<Scalars['String']>>;
  output_variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Lux_Proxy_Delete_Elem_Input = {
  input_variables?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Lux_Proxy_Delete_Key_Input = {
  input_variables?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "lux_proxy" */
export type Lux_Proxy_Insert_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  region_info?: InputMaybe<Region_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Lux_Proxy_Max_Fields = {
  __typename?: 'lux_proxy_max_fields';
  ami_id?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "lux_proxy" */
export type Lux_Proxy_Max_Order_By = {
  ami_id?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Lux_Proxy_Min_Fields = {
  __typename?: 'lux_proxy_min_fields';
  ami_id?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "lux_proxy" */
export type Lux_Proxy_Min_Order_By = {
  ami_id?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "lux_proxy" */
export type Lux_Proxy_Mutation_Response = {
  __typename?: 'lux_proxy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Lux_Proxy>;
};

/** on_conflict condition type for table "lux_proxy" */
export type Lux_Proxy_On_Conflict = {
  constraint: Lux_Proxy_Constraint;
  update_columns?: Array<Lux_Proxy_Update_Column>;
  where?: InputMaybe<Lux_Proxy_Bool_Exp>;
};

/** Ordering options when selecting data from "lux_proxy". */
export type Lux_Proxy_Order_By = {
  ami_id?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  disable_infra_auto_apply?: InputMaybe<Order_By>;
  infra_status?: InputMaybe<Order_By>;
  input_variables?: InputMaybe<Order_By>;
  output_variables?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  region_info?: InputMaybe<Region_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: lux_proxy */
export type Lux_Proxy_Pk_Columns_Input = {
  cloud: Scalars['String'];
  region: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Lux_Proxy_Prepend_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "lux_proxy" */
export enum Lux_Proxy_Select_Column {
  /** column name */
  AmiId = 'ami_id',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "lux_proxy_aggregate_bool_exp_bool_and_arguments_columns" columns of table "lux_proxy" */
export enum Lux_Proxy_Select_Column_Lux_Proxy_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
}

/** select "lux_proxy_aggregate_bool_exp_bool_or_arguments_columns" columns of table "lux_proxy" */
export enum Lux_Proxy_Select_Column_Lux_Proxy_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
}

/** input type for updating data in table "lux_proxy" */
export type Lux_Proxy_Set_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "lux_proxy" */
export type Lux_Proxy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Lux_Proxy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Lux_Proxy_Stream_Cursor_Value_Input = {
  ami_id?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "lux_proxy" */
export enum Lux_Proxy_Update_Column {
  /** column name */
  AmiId = 'ami_id',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Lux_Proxy_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Lux_Proxy_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Lux_Proxy_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Lux_Proxy_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Lux_Proxy_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Lux_Proxy_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Lux_Proxy_Set_Input>;
  /** filter the rows which have to be updated */
  where: Lux_Proxy_Bool_Exp;
};

/** Contains records for cloud enterprise model based pricing customer deals */
export type Model_Based_Enterprise_Cloud_Commitments = {
  __typename?: 'model_based_enterprise_cloud_commitments';
  collection_unit_price?: Maybe<Scalars['Int']>;
  commitment_end: Scalars['date'];
  commitment_start: Scalars['date'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  logical_model_unit_price?: Maybe<Scalars['Int']>;
  support_plan: Support_Plan_Types_Enum;
  /** An object relationship */
  support_plan_type: Support_Plan_Types;
  table_unit_price?: Maybe<Scalars['Int']>;
  total_commitment: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
  yearly_commitment?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "model_based_enterprise_cloud_commitments" */
export type Model_Based_Enterprise_Cloud_Commitments_Aggregate = {
  __typename?: 'model_based_enterprise_cloud_commitments_aggregate';
  aggregate?: Maybe<Model_Based_Enterprise_Cloud_Commitments_Aggregate_Fields>;
  nodes: Array<Model_Based_Enterprise_Cloud_Commitments>;
};

/** aggregate fields of "model_based_enterprise_cloud_commitments" */
export type Model_Based_Enterprise_Cloud_Commitments_Aggregate_Fields = {
  __typename?: 'model_based_enterprise_cloud_commitments_aggregate_fields';
  avg?: Maybe<Model_Based_Enterprise_Cloud_Commitments_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Model_Based_Enterprise_Cloud_Commitments_Max_Fields>;
  min?: Maybe<Model_Based_Enterprise_Cloud_Commitments_Min_Fields>;
  stddev?: Maybe<Model_Based_Enterprise_Cloud_Commitments_Stddev_Fields>;
  stddev_pop?: Maybe<
    Model_Based_Enterprise_Cloud_Commitments_Stddev_Pop_Fields
  >;
  stddev_samp?: Maybe<
    Model_Based_Enterprise_Cloud_Commitments_Stddev_Samp_Fields
  >;
  sum?: Maybe<Model_Based_Enterprise_Cloud_Commitments_Sum_Fields>;
  var_pop?: Maybe<Model_Based_Enterprise_Cloud_Commitments_Var_Pop_Fields>;
  var_samp?: Maybe<Model_Based_Enterprise_Cloud_Commitments_Var_Samp_Fields>;
  variance?: Maybe<Model_Based_Enterprise_Cloud_Commitments_Variance_Fields>;
};

/** aggregate fields of "model_based_enterprise_cloud_commitments" */
export type Model_Based_Enterprise_Cloud_Commitments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Model_Based_Enterprise_Cloud_Commitments_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Model_Based_Enterprise_Cloud_Commitments_Avg_Fields = {
  __typename?: 'model_based_enterprise_cloud_commitments_avg_fields';
  collection_unit_price?: Maybe<Scalars['Float']>;
  logical_model_unit_price?: Maybe<Scalars['Float']>;
  table_unit_price?: Maybe<Scalars['Float']>;
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "model_based_enterprise_cloud_commitments". All fields are combined with a logical 'AND'. */
export type Model_Based_Enterprise_Cloud_Commitments_Bool_Exp = {
  _and?: InputMaybe<Array<Model_Based_Enterprise_Cloud_Commitments_Bool_Exp>>;
  _not?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Bool_Exp>;
  _or?: InputMaybe<Array<Model_Based_Enterprise_Cloud_Commitments_Bool_Exp>>;
  collection_unit_price?: InputMaybe<Int_Comparison_Exp>;
  commitment_end?: InputMaybe<Date_Comparison_Exp>;
  commitment_start?: InputMaybe<Date_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  logical_model_unit_price?: InputMaybe<Int_Comparison_Exp>;
  support_plan?: InputMaybe<Support_Plan_Types_Enum_Comparison_Exp>;
  support_plan_type?: InputMaybe<Support_Plan_Types_Bool_Exp>;
  table_unit_price?: InputMaybe<Int_Comparison_Exp>;
  total_commitment?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  yearly_commitment?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "model_based_enterprise_cloud_commitments" */
export enum Model_Based_Enterprise_Cloud_Commitments_Constraint {
  /** unique or primary key constraint on columns "id" */
  ModelBasedEnterpriseCloudCommitmentsPkey = 'model_based_enterprise_cloud_commitments_pkey',
}

/** input type for incrementing numeric columns in table "model_based_enterprise_cloud_commitments" */
export type Model_Based_Enterprise_Cloud_Commitments_Inc_Input = {
  collection_unit_price?: InputMaybe<Scalars['Int']>;
  logical_model_unit_price?: InputMaybe<Scalars['Int']>;
  table_unit_price?: InputMaybe<Scalars['Int']>;
  total_commitment?: InputMaybe<Scalars['Int']>;
  yearly_commitment?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "model_based_enterprise_cloud_commitments" */
export type Model_Based_Enterprise_Cloud_Commitments_Insert_Input = {
  collection_unit_price?: InputMaybe<Scalars['Int']>;
  commitment_end?: InputMaybe<Scalars['date']>;
  commitment_start?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  logical_model_unit_price?: InputMaybe<Scalars['Int']>;
  support_plan?: InputMaybe<Support_Plan_Types_Enum>;
  support_plan_type?: InputMaybe<Support_Plan_Types_Obj_Rel_Insert_Input>;
  table_unit_price?: InputMaybe<Scalars['Int']>;
  total_commitment?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  yearly_commitment?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Model_Based_Enterprise_Cloud_Commitments_Max_Fields = {
  __typename?: 'model_based_enterprise_cloud_commitments_max_fields';
  collection_unit_price?: Maybe<Scalars['Int']>;
  commitment_end?: Maybe<Scalars['date']>;
  commitment_start?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  logical_model_unit_price?: Maybe<Scalars['Int']>;
  table_unit_price?: Maybe<Scalars['Int']>;
  total_commitment?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  yearly_commitment?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Model_Based_Enterprise_Cloud_Commitments_Min_Fields = {
  __typename?: 'model_based_enterprise_cloud_commitments_min_fields';
  collection_unit_price?: Maybe<Scalars['Int']>;
  commitment_end?: Maybe<Scalars['date']>;
  commitment_start?: Maybe<Scalars['date']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  logical_model_unit_price?: Maybe<Scalars['Int']>;
  table_unit_price?: Maybe<Scalars['Int']>;
  total_commitment?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  yearly_commitment?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "model_based_enterprise_cloud_commitments" */
export type Model_Based_Enterprise_Cloud_Commitments_Mutation_Response = {
  __typename?: 'model_based_enterprise_cloud_commitments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Model_Based_Enterprise_Cloud_Commitments>;
};

/** on_conflict condition type for table "model_based_enterprise_cloud_commitments" */
export type Model_Based_Enterprise_Cloud_Commitments_On_Conflict = {
  constraint: Model_Based_Enterprise_Cloud_Commitments_Constraint;
  update_columns?: Array<
    Model_Based_Enterprise_Cloud_Commitments_Update_Column
  >;
  where?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Bool_Exp>;
};

/** Ordering options when selecting data from "model_based_enterprise_cloud_commitments". */
export type Model_Based_Enterprise_Cloud_Commitments_Order_By = {
  collection_unit_price?: InputMaybe<Order_By>;
  commitment_end?: InputMaybe<Order_By>;
  commitment_start?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  logical_model_unit_price?: InputMaybe<Order_By>;
  support_plan?: InputMaybe<Order_By>;
  support_plan_type?: InputMaybe<Support_Plan_Types_Order_By>;
  table_unit_price?: InputMaybe<Order_By>;
  total_commitment?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  yearly_commitment?: InputMaybe<Order_By>;
};

/** primary key columns input for table: model_based_enterprise_cloud_commitments */
export type Model_Based_Enterprise_Cloud_Commitments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "model_based_enterprise_cloud_commitments" */
export enum Model_Based_Enterprise_Cloud_Commitments_Select_Column {
  /** column name */
  CollectionUnitPrice = 'collection_unit_price',
  /** column name */
  CommitmentEnd = 'commitment_end',
  /** column name */
  CommitmentStart = 'commitment_start',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LogicalModelUnitPrice = 'logical_model_unit_price',
  /** column name */
  SupportPlan = 'support_plan',
  /** column name */
  TableUnitPrice = 'table_unit_price',
  /** column name */
  TotalCommitment = 'total_commitment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  YearlyCommitment = 'yearly_commitment',
}

/** input type for updating data in table "model_based_enterprise_cloud_commitments" */
export type Model_Based_Enterprise_Cloud_Commitments_Set_Input = {
  collection_unit_price?: InputMaybe<Scalars['Int']>;
  commitment_end?: InputMaybe<Scalars['date']>;
  commitment_start?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  logical_model_unit_price?: InputMaybe<Scalars['Int']>;
  support_plan?: InputMaybe<Support_Plan_Types_Enum>;
  table_unit_price?: InputMaybe<Scalars['Int']>;
  total_commitment?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  yearly_commitment?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Model_Based_Enterprise_Cloud_Commitments_Stddev_Fields = {
  __typename?: 'model_based_enterprise_cloud_commitments_stddev_fields';
  collection_unit_price?: Maybe<Scalars['Float']>;
  logical_model_unit_price?: Maybe<Scalars['Float']>;
  table_unit_price?: Maybe<Scalars['Float']>;
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Model_Based_Enterprise_Cloud_Commitments_Stddev_Pop_Fields = {
  __typename?: 'model_based_enterprise_cloud_commitments_stddev_pop_fields';
  collection_unit_price?: Maybe<Scalars['Float']>;
  logical_model_unit_price?: Maybe<Scalars['Float']>;
  table_unit_price?: Maybe<Scalars['Float']>;
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Model_Based_Enterprise_Cloud_Commitments_Stddev_Samp_Fields = {
  __typename?: 'model_based_enterprise_cloud_commitments_stddev_samp_fields';
  collection_unit_price?: Maybe<Scalars['Float']>;
  logical_model_unit_price?: Maybe<Scalars['Float']>;
  table_unit_price?: Maybe<Scalars['Float']>;
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "model_based_enterprise_cloud_commitments" */
export type Model_Based_Enterprise_Cloud_Commitments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Model_Based_Enterprise_Cloud_Commitments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Model_Based_Enterprise_Cloud_Commitments_Stream_Cursor_Value_Input = {
  collection_unit_price?: InputMaybe<Scalars['Int']>;
  commitment_end?: InputMaybe<Scalars['date']>;
  commitment_start?: InputMaybe<Scalars['date']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  logical_model_unit_price?: InputMaybe<Scalars['Int']>;
  support_plan?: InputMaybe<Support_Plan_Types_Enum>;
  table_unit_price?: InputMaybe<Scalars['Int']>;
  total_commitment?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  yearly_commitment?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Model_Based_Enterprise_Cloud_Commitments_Sum_Fields = {
  __typename?: 'model_based_enterprise_cloud_commitments_sum_fields';
  collection_unit_price?: Maybe<Scalars['Int']>;
  logical_model_unit_price?: Maybe<Scalars['Int']>;
  table_unit_price?: Maybe<Scalars['Int']>;
  total_commitment?: Maybe<Scalars['Int']>;
  yearly_commitment?: Maybe<Scalars['Int']>;
};

/** update columns of table "model_based_enterprise_cloud_commitments" */
export enum Model_Based_Enterprise_Cloud_Commitments_Update_Column {
  /** column name */
  CollectionUnitPrice = 'collection_unit_price',
  /** column name */
  CommitmentEnd = 'commitment_end',
  /** column name */
  CommitmentStart = 'commitment_start',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LogicalModelUnitPrice = 'logical_model_unit_price',
  /** column name */
  SupportPlan = 'support_plan',
  /** column name */
  TableUnitPrice = 'table_unit_price',
  /** column name */
  TotalCommitment = 'total_commitment',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  YearlyCommitment = 'yearly_commitment',
}

export type Model_Based_Enterprise_Cloud_Commitments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Model_Based_Enterprise_Cloud_Commitments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Model_Based_Enterprise_Cloud_Commitments_Var_Pop_Fields = {
  __typename?: 'model_based_enterprise_cloud_commitments_var_pop_fields';
  collection_unit_price?: Maybe<Scalars['Float']>;
  logical_model_unit_price?: Maybe<Scalars['Float']>;
  table_unit_price?: Maybe<Scalars['Float']>;
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Model_Based_Enterprise_Cloud_Commitments_Var_Samp_Fields = {
  __typename?: 'model_based_enterprise_cloud_commitments_var_samp_fields';
  collection_unit_price?: Maybe<Scalars['Float']>;
  logical_model_unit_price?: Maybe<Scalars['Float']>;
  table_unit_price?: Maybe<Scalars['Float']>;
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Model_Based_Enterprise_Cloud_Commitments_Variance_Fields = {
  __typename?: 'model_based_enterprise_cloud_commitments_variance_fields';
  collection_unit_price?: Maybe<Scalars['Float']>;
  logical_model_unit_price?: Maybe<Scalars['Float']>;
  table_unit_price?: Maybe<Scalars['Float']>;
  total_commitment?: Maybe<Scalars['Float']>;
  yearly_commitment?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "model_based_pricing.model_lifecycle_view" */
export type Model_Based_Pricing_Model_Lifecycle_View = {
  __typename?: 'model_based_pricing_model_lifecycle_view';
  created_at?: Maybe<Scalars['Timestamp']>;
  deleted_at?: Maybe<Scalars['Timestamp']>;
  model_name?: Maybe<Scalars['String']>;
  model_source?: Maybe<Scalars['String']>;
  model_source_type?: Maybe<Scalars['String']>;
  model_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "model_based_pricing.model_lifecycle_view" */
export type Model_Based_Pricing_Model_Lifecycle_View_Aggregate = {
  __typename?: 'model_based_pricing_model_lifecycle_view_aggregate';
  aggregate?: Maybe<Model_Based_Pricing_Model_Lifecycle_View_Aggregate_Fields>;
  nodes: Array<Model_Based_Pricing_Model_Lifecycle_View>;
};

/** aggregate fields of "model_based_pricing.model_lifecycle_view" */
export type Model_Based_Pricing_Model_Lifecycle_View_Aggregate_Fields = {
  __typename?: 'model_based_pricing_model_lifecycle_view_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Model_Based_Pricing_Model_Lifecycle_View_Max_Fields>;
  min?: Maybe<Model_Based_Pricing_Model_Lifecycle_View_Min_Fields>;
};

/** aggregate fields of "model_based_pricing.model_lifecycle_view" */
export type Model_Based_Pricing_Model_Lifecycle_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Model_Based_Pricing_Model_Lifecycle_View_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "model_based_pricing.model_lifecycle_view". All fields are combined with a logical 'AND'. */
export type Model_Based_Pricing_Model_Lifecycle_View_Bool_Exp = {
  _and?: InputMaybe<Array<Model_Based_Pricing_Model_Lifecycle_View_Bool_Exp>>;
  _not?: InputMaybe<Model_Based_Pricing_Model_Lifecycle_View_Bool_Exp>;
  _or?: InputMaybe<Array<Model_Based_Pricing_Model_Lifecycle_View_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_BigQuery_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_BigQuery_Comparison_Exp>;
  model_name?: InputMaybe<String_BigQuery_Comparison_Exp>;
  model_source?: InputMaybe<String_BigQuery_Comparison_Exp>;
  model_source_type?: InputMaybe<String_BigQuery_Comparison_Exp>;
  model_type?: InputMaybe<String_BigQuery_Comparison_Exp>;
  project_id?: InputMaybe<String_BigQuery_Comparison_Exp>;
};

/** aggregate max on columns */
export type Model_Based_Pricing_Model_Lifecycle_View_Max_Fields = {
  __typename?: 'model_based_pricing_model_lifecycle_view_max_fields';
  created_at?: Maybe<Scalars['Timestamp']>;
  deleted_at?: Maybe<Scalars['Timestamp']>;
  model_name?: Maybe<Scalars['String']>;
  model_source?: Maybe<Scalars['String']>;
  model_source_type?: Maybe<Scalars['String']>;
  model_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Model_Based_Pricing_Model_Lifecycle_View_Min_Fields = {
  __typename?: 'model_based_pricing_model_lifecycle_view_min_fields';
  created_at?: Maybe<Scalars['Timestamp']>;
  deleted_at?: Maybe<Scalars['Timestamp']>;
  model_name?: Maybe<Scalars['String']>;
  model_source?: Maybe<Scalars['String']>;
  model_source_type?: Maybe<Scalars['String']>;
  model_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "model_based_pricing.model_lifecycle_view". */
export type Model_Based_Pricing_Model_Lifecycle_View_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  model_name?: InputMaybe<Order_By>;
  model_source?: InputMaybe<Order_By>;
  model_source_type?: InputMaybe<Order_By>;
  model_type?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** select columns of table "model_based_pricing.model_lifecycle_view" */
export enum Model_Based_Pricing_Model_Lifecycle_View_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ModelName = 'model_name',
  /** column name */
  ModelSource = 'model_source',
  /** column name */
  ModelSourceType = 'model_source_type',
  /** column name */
  ModelType = 'model_type',
  /** column name */
  ProjectId = 'project_id',
}

/** columns and relationships of "model_based_pricing.model_snapshot_statistics" */
export type Model_Based_Pricing_Model_Snapshot_Statistics = {
  __typename?: 'model_based_pricing_model_snapshot_statistics';
  collections?: Maybe<Scalars['Int']>;
  logical_models?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['String']>;
  tables?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['Timestamp']>;
};

/** aggregated selection of "model_based_pricing.model_snapshot_statistics" */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Aggregate = {
  __typename?: 'model_based_pricing_model_snapshot_statistics_aggregate';
  aggregate?: Maybe<
    Model_Based_Pricing_Model_Snapshot_Statistics_Aggregate_Fields
  >;
  nodes: Array<Model_Based_Pricing_Model_Snapshot_Statistics>;
};

/** aggregate fields of "model_based_pricing.model_snapshot_statistics" */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Aggregate_Fields = {
  __typename?: 'model_based_pricing_model_snapshot_statistics_aggregate_fields';
  avg?: Maybe<Model_Based_Pricing_Model_Snapshot_Statistics_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Model_Based_Pricing_Model_Snapshot_Statistics_Max_Fields>;
  min?: Maybe<Model_Based_Pricing_Model_Snapshot_Statistics_Min_Fields>;
  stddev?: Maybe<Model_Based_Pricing_Model_Snapshot_Statistics_Stddev_Fields>;
  stddev_pop?: Maybe<
    Model_Based_Pricing_Model_Snapshot_Statistics_Stddev_Pop_Fields
  >;
  stddev_samp?: Maybe<
    Model_Based_Pricing_Model_Snapshot_Statistics_Stddev_Samp_Fields
  >;
  sum?: Maybe<Model_Based_Pricing_Model_Snapshot_Statistics_Sum_Fields>;
  var_pop?: Maybe<Model_Based_Pricing_Model_Snapshot_Statistics_Var_Pop_Fields>;
  var_samp?: Maybe<
    Model_Based_Pricing_Model_Snapshot_Statistics_Var_Samp_Fields
  >;
  variance?: Maybe<
    Model_Based_Pricing_Model_Snapshot_Statistics_Variance_Fields
  >;
};

/** aggregate fields of "model_based_pricing.model_snapshot_statistics" */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Model_Based_Pricing_Model_Snapshot_Statistics_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Avg_Fields = {
  __typename?: 'model_based_pricing_model_snapshot_statistics_avg_fields';
  collections?: Maybe<Scalars['Float']>;
  logical_models?: Maybe<Scalars['Float']>;
  tables?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "model_based_pricing.model_snapshot_statistics". All fields are combined with a logical 'AND'. */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Bool_Exp = {
  _and?: InputMaybe<
    Array<Model_Based_Pricing_Model_Snapshot_Statistics_Bool_Exp>
  >;
  _not?: InputMaybe<Model_Based_Pricing_Model_Snapshot_Statistics_Bool_Exp>;
  _or?: InputMaybe<
    Array<Model_Based_Pricing_Model_Snapshot_Statistics_Bool_Exp>
  >;
  collections?: InputMaybe<Int_BigQuery_Comparison_Exp>;
  logical_models?: InputMaybe<Int_BigQuery_Comparison_Exp>;
  project_id?: InputMaybe<String_BigQuery_Comparison_Exp>;
  tables?: InputMaybe<Int_BigQuery_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamp_BigQuery_Comparison_Exp>;
};

/** aggregate max on columns */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Max_Fields = {
  __typename?: 'model_based_pricing_model_snapshot_statistics_max_fields';
  collections?: Maybe<Scalars['Int']>;
  logical_models?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['String']>;
  tables?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['Timestamp']>;
};

/** aggregate min on columns */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Min_Fields = {
  __typename?: 'model_based_pricing_model_snapshot_statistics_min_fields';
  collections?: Maybe<Scalars['Int']>;
  logical_models?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['String']>;
  tables?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['Timestamp']>;
};

/** Ordering options when selecting data from "model_based_pricing.model_snapshot_statistics". */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Order_By = {
  collections?: InputMaybe<Order_By>;
  logical_models?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  tables?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
};

/** select columns of table "model_based_pricing.model_snapshot_statistics" */
export enum Model_Based_Pricing_Model_Snapshot_Statistics_Select_Column {
  /** column name */
  Collections = 'collections',
  /** column name */
  LogicalModels = 'logical_models',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Tables = 'tables',
  /** column name */
  Timestamp = 'timestamp',
}

/** aggregate stddev on columns */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Stddev_Fields = {
  __typename?: 'model_based_pricing_model_snapshot_statistics_stddev_fields';
  collections?: Maybe<Scalars['Float']>;
  logical_models?: Maybe<Scalars['Float']>;
  tables?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Stddev_Pop_Fields = {
  __typename?: 'model_based_pricing_model_snapshot_statistics_stddev_pop_fields';
  collections?: Maybe<Scalars['Float']>;
  logical_models?: Maybe<Scalars['Float']>;
  tables?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Stddev_Samp_Fields = {
  __typename?: 'model_based_pricing_model_snapshot_statistics_stddev_samp_fields';
  collections?: Maybe<Scalars['Float']>;
  logical_models?: Maybe<Scalars['Float']>;
  tables?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Sum_Fields = {
  __typename?: 'model_based_pricing_model_snapshot_statistics_sum_fields';
  collections?: Maybe<Scalars['Int']>;
  logical_models?: Maybe<Scalars['Int']>;
  tables?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Var_Pop_Fields = {
  __typename?: 'model_based_pricing_model_snapshot_statistics_var_pop_fields';
  collections?: Maybe<Scalars['Float']>;
  logical_models?: Maybe<Scalars['Float']>;
  tables?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Var_Samp_Fields = {
  __typename?: 'model_based_pricing_model_snapshot_statistics_var_samp_fields';
  collections?: Maybe<Scalars['Float']>;
  logical_models?: Maybe<Scalars['Float']>;
  tables?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Model_Based_Pricing_Model_Snapshot_Statistics_Variance_Fields = {
  __typename?: 'model_based_pricing_model_snapshot_statistics_variance_fields';
  collections?: Maybe<Scalars['Float']>;
  logical_models?: Maybe<Scalars['Float']>;
  tables?: Maybe<Scalars['Float']>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  acceptBillingManagerInvite?: Maybe<BillingManagerInvitation>;
  acceptInvite: ProjectCollaboratorInvitation;
  acceptTransferOwnershipInvite: ProjectOwnershipTransferInvitation;
  /** accept collaborator invitation for VPC */
  acceptVPCCollaboratorInvitation?: Maybe<
    AcceptVpcCollaboratorInvitationResponse
  >;
  addAzureMonitorConfig?: Maybe<AzureMonitorConfigResponse>;
  addCard?: Maybe<StripeCardResponse>;
  addCustomDomain?: Maybe<AddCustomDomainOutput>;
  addDatadogConfig?: Maybe<DatadogConfigResponse>;
  addFeatureRequest?: Maybe<FeatureRequestResponse>;
  addNewrelicConfig?: Maybe<NewrelicConfigResponse>;
  /** Add openTelemetry Integration to a project */
  addOpentelemetryConfig?: Maybe<OpentelemetryConfigResponse>;
  addProject: AddProjectResponse;
  /** Add Prometheus Integration to a project */
  addPrometheusConfig: PrometheusConfigResponse;
  /** (Remove after use) A temporary action to add existing vpc owners as admin owners to projects under VPC */
  addVPCOwnerAsProjectAdmin?: Maybe<AddVpcOwnerAsProjectAdminResponse>;
  applyCouponCode?: Maybe<CouponStatus>;
  changePlan?: Maybe<PlanResponse>;
  changeUserEmail: ChangeUserEmailRequest;
  checkDBLatency?: Maybe<CheckDbLatencyOutput>;
  completeChangeUserEmailRequest: ChangeUserEmailRequest;
  /** Internal utility action to convert a project's entitlements and plan to dedicated_cloud */
  convertToDedicatedCloud?: Maybe<ConvertPlanResponse>;
  createDedicatedVPC?: Maybe<CreateDedicatedVpcResponse>;
  createGitHubPreviewApp?: Maybe<PreviewAppResponse>;
  createGithubIntegration?: Maybe<GithubIntegrationStatus>;
  createInvoice: CreateInvoiceResponse;
  createJob: CreateJobResponse;
  createOneClickDeployment: CreateOneClickDeploymentOutput;
  createPersonalAccessToken: PersonalAccessToken;
  createTenant?: Maybe<CreateTenantResponse>;
  createZendeskSupportTicket: SuccessOrError;
  ddnAcceptCollaborationInvitation?: Maybe<InvitationResponse>;
  ddnAcceptProjectCollaborationRequest?: Maybe<
    AcceptProjectCollaborationRequestOutput
  >;
  /** Activate a project collaborator */
  ddnActivateProjectCollaborator?: Maybe<SuccessOrError>;
  ddnApplyMetadata?: Maybe<DdnApplyMetadataOutput>;
  /** Apply the specified subgraph build and build the supergraph for the project */
  ddnApplySubgraphBuild?: Maybe<DdnApplySubgraphBuildOutput>;
  /** ddnBackfillBuildGatewayRules */
  ddnBackfillBuildGatewayRules: DdnBackfillOutput;
  /** ddnBackfillBuildGatewayRulesByTimerange */
  ddnBackfillBuildGatewayRulesByTimerange: DdnBackfillOutput;
  /** ddnBackfillEnvironmentBuildsInBuildstore */
  ddnBackfillEnvironmentBuildsInBuildstore: DdnBackfillOutput;
  /** ddnBackfillEnvironmentGatewayRules */
  ddnBackfillEnvironmentGatewayRules: DdnBackfillOutput;
  /** ddnBackfillEnvironmentGatewayRulesByTimerange */
  ddnBackfillEnvironmentGatewayRulesByTimerange: DdnBackfillOutput;
  ddnCancelProjectCollaborationInvitation?: Maybe<SuccessOrError>;
  /** Action to change plans of a DDN project */
  ddnChangePlan?: Maybe<DdnPlanResponse>;
  /** ddnCreateBuild */
  ddnCreateBuild?: Maybe<DdnCreateBuildOutput>;
  /** ddnCreateEnvironment */
  ddnCreateEnvironment?: Maybe<DdnCreateEnvironmentResponse>;
  /** Create DDN http tunnels */
  ddnCreateHttpTunnels?: Maybe<CreateHttpTunnelsOutput>;
  ddnCreateInvoice: DdnCreateInvoiceResponse;
  ddnCreateProject?: Maybe<DdnCreateProjectResponse>;
  ddnCreateProjectV2?: Maybe<DdnCreateProjectResponse>;
  /** ddnCreateSubgraph */
  ddnCreateSubgraph: DdnCreateSubgraphResponse;
  /** Create a new build for one or more subgraphs */
  ddnCreateSubgraphBuild?: Maybe<DdnCreateSubgraphBuildOutput>;
  ddnCreateTunnel?: Maybe<CreateTunnelOutput>;
  ddnDeclineCollaborationInvitation?: Maybe<SuccessOrError>;
  ddnDeleteBuild?: Maybe<DeleteBuildResponse>;
  /** ddnDeleteEnvironment */
  ddnDeleteEnvironment: DdnDeleteEnvironmentResponse;
  ddnDeleteProject?: Maybe<DdnDeleteProjectResponse>;
  /** ddnDeleteSubgraph */
  ddnDeleteSubgraph: DdnDeleteSubgraphResponse;
  /** ddnGenerateMetadataArtifacts */
  ddnGenerateMetadataArtifacts?: Maybe<GenerateMetadataArtifactsOutput>;
  ddnHasuraSecretDelete: HasuraSecretMessage;
  ddnHasuraSecretSet: HasuraSecretMessage;
  ddnInviteProjectCollaborator?: Maybe<InvitationResponse>;
  ddnPayInvoice?: Maybe<DdnPayInvoiceStatus>;
  ddnRejectProjectCollaborationRequest?: Maybe<
    RejectProjectCollaborationRequestOutput
  >;
  ddnRemoveProjectCollaborator?: Maybe<SuccessOrError>;
  ddnRequestProjectCollaboration?: Maybe<RequestProjectCollaborationOutput>;
  ddnSetDefaultPaymentMethod?: Maybe<DdnDefaultPaymentResponse>;
  /** Suspend a project collaborator */
  ddnSuspendProjectCollaborator?: Maybe<SuccessOrError>;
  ddnToggleRequestAccessSetting?: Maybe<ToggleRequestAccessSettingOutput>;
  declineBillingManagerInvite?: Maybe<BillingManagerInvitation>;
  declineInvite: ProjectCollaboratorInvitation;
  declineTransferOwnershipInvite: ProjectOwnershipTransferInvitation;
  /** decline collaborator invitation for VPC */
  declineVPCCollaboratorInvitation?: Maybe<
    DeclineVpcCollaboratorInvitationResponse
  >;
  deleteCard?: Maybe<DeleteCardResponse>;
  deleteProject: DeleteProjectResponse;
  /** deletes the slack app integrated with the project ID. */
  deleteSlackApp?: Maybe<DeleteSlackAppOutput>;
  deleteTenant?: Maybe<TenantDeleteResponse>;
  deleteTenantEnv?: Maybe<TenantEnv>;
  deleteUser?: Maybe<DeleteUserResponse>;
  /** delete data from the table: "alert_config" */
  delete_alert_config?: Maybe<Alert_Config_Mutation_Response>;
  /** delete data from the table: "alert_config_alert_type" */
  delete_alert_config_alert_type?: Maybe<
    Alert_Config_Alert_Type_Mutation_Response
  >;
  /** delete single row from the table: "alert_config_alert_type" */
  delete_alert_config_alert_type_by_pk?: Maybe<Alert_Config_Alert_Type>;
  /** delete single row from the table: "alert_config" */
  delete_alert_config_by_pk?: Maybe<Alert_Config>;
  /** delete data from the table: "alert_config_service" */
  delete_alert_config_service?: Maybe<Alert_Config_Service_Mutation_Response>;
  /** delete single row from the table: "alert_config_service" */
  delete_alert_config_service_by_pk?: Maybe<Alert_Config_Service>;
  /** delete data from the table: "alert_service_type" */
  delete_alert_service_type?: Maybe<Alert_Service_Type_Mutation_Response>;
  /** delete single row from the table: "alert_service_type" */
  delete_alert_service_type_by_pk?: Maybe<Alert_Service_Type>;
  /** delete data from the table: "alert_type" */
  delete_alert_type?: Maybe<Alert_Type_Mutation_Response>;
  /** delete single row from the table: "alert_type" */
  delete_alert_type_by_pk?: Maybe<Alert_Type>;
  /** delete data from the table: "azuremonitor_config" */
  delete_azuremonitor_config?: Maybe<Azuremonitor_Config_Mutation_Response>;
  /** delete single row from the table: "azuremonitor_config" */
  delete_azuremonitor_config_by_pk?: Maybe<Azuremonitor_Config>;
  /** delete data from the table: "billing_address" */
  delete_billing_address?: Maybe<Billing_Address_Mutation_Response>;
  /** delete single row from the table: "billing_address" */
  delete_billing_address_by_pk?: Maybe<Billing_Address>;
  /** delete data from the table: "central_cloud" */
  delete_central_cloud?: Maybe<Central_Cloud_Mutation_Response>;
  /** delete single row from the table: "central_cloud" */
  delete_central_cloud_by_pk?: Maybe<Central_Cloud>;
  /** delete data from the table: "change_email_request" */
  delete_change_email_request?: Maybe<Change_Email_Request_Mutation_Response>;
  /** delete single row from the table: "change_email_request" */
  delete_change_email_request_by_pk?: Maybe<Change_Email_Request>;
  /** delete data from the table: "cicd.deployment_pipeline_config" */
  delete_cicd_deployment_pipeline_config?: Maybe<
    Cicd_Deployment_Pipeline_Config_Mutation_Response
  >;
  /** delete single row from the table: "cicd.deployment_pipeline_config" */
  delete_cicd_deployment_pipeline_config_by_pk?: Maybe<
    Cicd_Deployment_Pipeline_Config
  >;
  /** delete data from the table: "cicd.deployment_pool" */
  delete_cicd_deployment_pool?: Maybe<Cicd_Deployment_Pool_Mutation_Response>;
  /** delete data from the table: "cicd.deployment_pool_member" */
  delete_cicd_deployment_pool_member?: Maybe<
    Cicd_Deployment_Pool_Member_Mutation_Response
  >;
  /** delete data from the table: "cloud" */
  delete_cloud?: Maybe<Cloud_Mutation_Response>;
  /** delete single row from the table: "cloud" */
  delete_cloud_by_pk?: Maybe<Cloud>;
  /** delete data from the table: "cloud_metadata" */
  delete_cloud_metadata?: Maybe<Cloud_Metadata_Mutation_Response>;
  /** delete single row from the table: "cloud_metadata" */
  delete_cloud_metadata_by_pk?: Maybe<Cloud_Metadata>;
  /** delete data from the table: "compute_unit_config" */
  delete_compute_unit_config?: Maybe<Compute_Unit_Config_Mutation_Response>;
  /** delete single row from the table: "compute_unit_config" */
  delete_compute_unit_config_by_pk?: Maybe<Compute_Unit_Config>;
  /** delete data from the table: "config" */
  delete_config?: Maybe<Config_Mutation_Response>;
  /** delete single row from the table: "config" */
  delete_config_by_pk?: Maybe<Config>;
  /** delete data from the table: "config_status" */
  delete_config_status?: Maybe<Config_Status_Mutation_Response>;
  /** delete single row from the table: "config_status" */
  delete_config_status_by_pk?: Maybe<Config_Status>;
  /** delete data from the table: "connector_config" */
  delete_connector_config?: Maybe<Connector_Config_Mutation_Response>;
  /** delete single row from the table: "connector_config" */
  delete_connector_config_by_pk?: Maybe<Connector_Config>;
  /** delete data from the table: "connector_deployments" */
  delete_connector_deployments?: Maybe<Connector_Deployments_Mutation_Response>;
  /** delete single row from the table: "connector_deployments" */
  delete_connector_deployments_by_pk?: Maybe<Connector_Deployments>;
  /** delete data from the table: "coupon" */
  delete_coupon?: Maybe<Coupon_Mutation_Response>;
  /** delete single row from the table: "coupon" */
  delete_coupon_by_pk?: Maybe<Coupon>;
  /** delete data from the table: "coupon_duration" */
  delete_coupon_duration?: Maybe<Coupon_Duration_Mutation_Response>;
  /** delete single row from the table: "coupon_duration" */
  delete_coupon_duration_by_pk?: Maybe<Coupon_Duration>;
  /** delete data from the table: "coupon_type" */
  delete_coupon_type?: Maybe<Coupon_Type_Mutation_Response>;
  /** delete single row from the table: "coupon_type" */
  delete_coupon_type_by_pk?: Maybe<Coupon_Type>;
  /** delete data from the table: "custom_domain" */
  delete_custom_domain?: Maybe<Custom_Domain_Mutation_Response>;
  /** delete single row from the table: "custom_domain" */
  delete_custom_domain_by_pk?: Maybe<Custom_Domain>;
  /** delete data from the table: "custom_domain_cloudflare" */
  delete_custom_domain_cloudflare?: Maybe<
    Custom_Domain_Cloudflare_Mutation_Response
  >;
  /** delete single row from the table: "custom_domain_cloudflare" */
  delete_custom_domain_cloudflare_by_pk?: Maybe<Custom_Domain_Cloudflare>;
  /** delete data from the table: "custom_domain_cloudflare_dns" */
  delete_custom_domain_cloudflare_dns?: Maybe<
    Custom_Domain_Cloudflare_Dns_Mutation_Response
  >;
  /** delete single row from the table: "custom_domain_cloudflare_dns" */
  delete_custom_domain_cloudflare_dns_by_pk?: Maybe<
    Custom_Domain_Cloudflare_Dns
  >;
  /** delete data from the table: "customer_usage" */
  delete_customer_usage?: Maybe<Customer_Usage_Mutation_Response>;
  /** delete single row from the table: "customer_usage" */
  delete_customer_usage_by_pk?: Maybe<Customer_Usage>;
  /** delete data from the table: "data_connector_type" */
  delete_data_connector_type?: Maybe<Data_Connector_Type_Mutation_Response>;
  /** delete single row from the table: "data_connector_type" */
  delete_data_connector_type_by_pk?: Maybe<Data_Connector_Type>;
  /** delete data from the table: "datadog_config" */
  delete_datadog_config?: Maybe<Datadog_Config_Mutation_Response>;
  /** delete single row from the table: "datadog_config" */
  delete_datadog_config_by_pk?: Maybe<Datadog_Config>;
  /** delete data from the table: "db_latency" */
  delete_db_latency?: Maybe<Db_Latency_Mutation_Response>;
  /** delete single row from the table: "db_latency" */
  delete_db_latency_by_pk?: Maybe<Db_Latency>;
  /** delete data from the table: "ddn.applied_build_changelog" */
  delete_ddn_applied_build_changelog?: Maybe<
    Ddn_Applied_Build_Changelog_Mutation_Response
  >;
  /** delete single row from the table: "ddn.applied_build_changelog" */
  delete_ddn_applied_build_changelog_by_pk?: Maybe<Ddn_Applied_Build_Changelog>;
  /** delete data from the table: "ddn.applied_subgraph_build_changelog" */
  delete_ddn_applied_subgraph_build_changelog?: Maybe<
    Ddn_Applied_Subgraph_Build_Changelog_Mutation_Response
  >;
  /** delete single row from the table: "ddn.applied_subgraph_build_changelog" */
  delete_ddn_applied_subgraph_build_changelog_by_pk?: Maybe<
    Ddn_Applied_Subgraph_Build_Changelog
  >;
  /** delete data from the table: "ddn.auto_approve_project_request" */
  delete_ddn_auto_approve_project_request?: Maybe<
    Ddn_Auto_Approve_Project_Request_Mutation_Response
  >;
  /** delete single row from the table: "ddn.auto_approve_project_request" */
  delete_ddn_auto_approve_project_request_by_pk?: Maybe<
    Ddn_Auto_Approve_Project_Request
  >;
  /** delete data from the table: "ddn.build" */
  delete_ddn_build?: Maybe<Ddn_Build_Mutation_Response>;
  /** delete single row from the table: "ddn.build" */
  delete_ddn_build_by_pk?: Maybe<Ddn_Build>;
  /** delete data from the table: "ddn.build_details" */
  delete_ddn_build_details?: Maybe<Ddn_Build_Details_Mutation_Response>;
  /** delete single row from the table: "ddn.build_details" */
  delete_ddn_build_details_by_pk?: Maybe<Ddn_Build_Details>;
  /** delete data from the table: "ddn.build_lock" */
  delete_ddn_build_lock?: Maybe<Ddn_Build_Lock_Mutation_Response>;
  /** delete single row from the table: "ddn.build_lock" */
  delete_ddn_build_lock_by_pk?: Maybe<Ddn_Build_Lock>;
  /** delete data from the table: "ddn.build_revision" */
  delete_ddn_build_revision?: Maybe<Ddn_Build_Revision_Mutation_Response>;
  /** delete single row from the table: "ddn.build_revision" */
  delete_ddn_build_revision_by_pk?: Maybe<Ddn_Build_Revision>;
  /** delete data from the table: "ddn.build_revision_status" */
  delete_ddn_build_revision_status?: Maybe<
    Ddn_Build_Revision_Status_Mutation_Response
  >;
  /** delete single row from the table: "ddn.build_revision_status" */
  delete_ddn_build_revision_status_by_pk?: Maybe<Ddn_Build_Revision_Status>;
  /** delete data from the table: "ddn.build_sync_status" */
  delete_ddn_build_sync_status?: Maybe<Ddn_Build_Sync_Status_Mutation_Response>;
  /** delete single row from the table: "ddn.build_sync_status" */
  delete_ddn_build_sync_status_by_pk?: Maybe<Ddn_Build_Sync_Status>;
  /** delete data from the table: "ddn.build_sync_worker" */
  delete_ddn_build_sync_worker?: Maybe<Ddn_Build_Sync_Worker_Mutation_Response>;
  /** delete single row from the table: "ddn.build_sync_worker" */
  delete_ddn_build_sync_worker_by_pk?: Maybe<Ddn_Build_Sync_Worker>;
  /** delete data from the table: "ddn.collaboration_invitee_project_view" */
  delete_ddn_collaboration_invitee_project_view?: Maybe<
    Ddn_Collaboration_Invitee_Project_View_Mutation_Response
  >;
  /** delete data from the table: "ddn.connector_deployment" */
  delete_ddn_connector_deployment?: Maybe<
    Ddn_Connector_Deployment_Mutation_Response
  >;
  /** delete single row from the table: "ddn.connector_deployment" */
  delete_ddn_connector_deployment_by_pk?: Maybe<Ddn_Connector_Deployment>;
  /** delete data from the table: "ddn.ddn" */
  delete_ddn_ddn?: Maybe<Ddn_Ddn_Mutation_Response>;
  /** delete single row from the table: "ddn.ddn" */
  delete_ddn_ddn_by_pk?: Maybe<Ddn_Ddn>;
  /** delete data from the table: "ddn.environment" */
  delete_ddn_environment?: Maybe<Ddn_Environment_Mutation_Response>;
  /** delete single row from the table: "ddn.environment" */
  delete_ddn_environment_by_pk?: Maybe<Ddn_Environment>;
  /** delete data from the table: "ddn.generate_metadata_artifacts_log" */
  delete_ddn_generate_metadata_artifacts_log?: Maybe<
    Ddn_Generate_Metadata_Artifacts_Log_Mutation_Response
  >;
  /** delete single row from the table: "ddn.generate_metadata_artifacts_log" */
  delete_ddn_generate_metadata_artifacts_log_by_pk?: Maybe<
    Ddn_Generate_Metadata_Artifacts_Log
  >;
  /** delete data from the table: "ddn.invoice" */
  delete_ddn_invoice?: Maybe<Ddn_Invoice_Mutation_Response>;
  /** delete single row from the table: "ddn.invoice" */
  delete_ddn_invoice_by_pk?: Maybe<Ddn_Invoice>;
  /** delete data from the table: "ddn.invoice_item" */
  delete_ddn_invoice_item?: Maybe<Ddn_Invoice_Item_Mutation_Response>;
  /** delete single row from the table: "ddn.invoice_item" */
  delete_ddn_invoice_item_by_pk?: Maybe<Ddn_Invoice_Item>;
  /** delete data from the table: "ddn.plan_entitlement_access" */
  delete_ddn_plan_entitlement_access?: Maybe<
    Ddn_Plan_Entitlement_Access_Mutation_Response
  >;
  /** delete single row from the table: "ddn.plan_entitlement_access" */
  delete_ddn_plan_entitlement_access_by_pk?: Maybe<Ddn_Plan_Entitlement_Access>;
  /** delete data from the table: "ddn.plans" */
  delete_ddn_plans?: Maybe<Ddn_Plans_Mutation_Response>;
  /** delete single row from the table: "ddn.plans" */
  delete_ddn_plans_by_pk?: Maybe<Ddn_Plans>;
  /** delete data from the table: "ddn.private_ddn" */
  delete_ddn_private_ddn?: Maybe<Ddn_Private_Ddn_Mutation_Response>;
  /** delete single row from the table: "ddn.private_ddn" */
  delete_ddn_private_ddn_by_pk?: Maybe<Ddn_Private_Ddn>;
  /** delete data from the table: "ddn.private_ddn_region" */
  delete_ddn_private_ddn_region?: Maybe<
    Ddn_Private_Ddn_Region_Mutation_Response
  >;
  /** delete single row from the table: "ddn.private_ddn_region" */
  delete_ddn_private_ddn_region_by_pk?: Maybe<Ddn_Private_Ddn_Region>;
  /** delete data from the table: "ddn.project_access_level" */
  delete_ddn_project_access_level?: Maybe<
    Ddn_Project_Access_Level_Mutation_Response
  >;
  /** delete single row from the table: "ddn.project_access_level" */
  delete_ddn_project_access_level_by_pk?: Maybe<Ddn_Project_Access_Level>;
  /** delete data from the table: "ddn.project_active_status_changelog" */
  delete_ddn_project_active_status_changelog?: Maybe<
    Ddn_Project_Active_Status_Changelog_Mutation_Response
  >;
  /** delete single row from the table: "ddn.project_active_status_changelog" */
  delete_ddn_project_active_status_changelog_by_pk?: Maybe<
    Ddn_Project_Active_Status_Changelog
  >;
  /** delete data from the table: "ddn.project_active_status_reason" */
  delete_ddn_project_active_status_reason?: Maybe<
    Ddn_Project_Active_Status_Reason_Mutation_Response
  >;
  /** delete single row from the table: "ddn.project_active_status_reason" */
  delete_ddn_project_active_status_reason_by_pk?: Maybe<
    Ddn_Project_Active_Status_Reason
  >;
  /** delete data from the table: "ddn.project_entitlement_access" */
  delete_ddn_project_entitlement_access?: Maybe<
    Ddn_Project_Entitlement_Access_Mutation_Response
  >;
  /** delete single row from the table: "ddn.project_entitlement_access" */
  delete_ddn_project_entitlement_access_by_pk?: Maybe<
    Ddn_Project_Entitlement_Access
  >;
  /** delete data from the table: "ddn.project_entitlement_catalogue" */
  delete_ddn_project_entitlement_catalogue?: Maybe<
    Ddn_Project_Entitlement_Catalogue_Mutation_Response
  >;
  /** delete single row from the table: "ddn.project_entitlement_catalogue" */
  delete_ddn_project_entitlement_catalogue_by_pk?: Maybe<
    Ddn_Project_Entitlement_Catalogue
  >;
  /** delete data from the table: "ddn.project_entitlement_types" */
  delete_ddn_project_entitlement_types?: Maybe<
    Ddn_Project_Entitlement_Types_Mutation_Response
  >;
  /** delete single row from the table: "ddn.project_entitlement_types" */
  delete_ddn_project_entitlement_types_by_pk?: Maybe<
    Ddn_Project_Entitlement_Types
  >;
  /** delete data from the table: "ddn.project_plan_changelog" */
  delete_ddn_project_plan_changelog?: Maybe<
    Ddn_Project_Plan_Changelog_Mutation_Response
  >;
  /** delete single row from the table: "ddn.project_plan_changelog" */
  delete_ddn_project_plan_changelog_by_pk?: Maybe<Ddn_Project_Plan_Changelog>;
  /** delete data from the table: "ddn.project_request_access" */
  delete_ddn_project_request_access?: Maybe<
    Ddn_Project_Request_Access_Mutation_Response
  >;
  /** delete single row from the table: "ddn.project_request_access" */
  delete_ddn_project_request_access_by_pk?: Maybe<Ddn_Project_Request_Access>;
  /** delete data from the table: "ddn.projects" */
  delete_ddn_projects?: Maybe<Ddn_Projects_Mutation_Response>;
  /** delete single row from the table: "ddn.projects" */
  delete_ddn_projects_by_pk?: Maybe<Ddn_Projects>;
  /** delete data from the table: "ddn.secret_key" */
  delete_ddn_secret_key?: Maybe<Ddn_Secret_Key_Mutation_Response>;
  /** delete single row from the table: "ddn.secret_key" */
  delete_ddn_secret_key_by_pk?: Maybe<Ddn_Secret_Key>;
  /** delete data from the table: "ddn.stripe_subscription" */
  delete_ddn_stripe_subscription?: Maybe<
    Ddn_Stripe_Subscription_Mutation_Response
  >;
  /** delete single row from the table: "ddn.stripe_subscription" */
  delete_ddn_stripe_subscription_by_pk?: Maybe<Ddn_Stripe_Subscription>;
  /** delete data from the table: "ddn.stripe_webhook_events" */
  delete_ddn_stripe_webhook_events?: Maybe<
    Ddn_Stripe_Webhook_Events_Mutation_Response
  >;
  /** delete single row from the table: "ddn.stripe_webhook_events" */
  delete_ddn_stripe_webhook_events_by_pk?: Maybe<Ddn_Stripe_Webhook_Events>;
  /** delete data from the table: "ddn.subgraph" */
  delete_ddn_subgraph?: Maybe<Ddn_Subgraph_Mutation_Response>;
  /** delete data from the table: "ddn.subgraph_build" */
  delete_ddn_subgraph_build?: Maybe<Ddn_Subgraph_Build_Mutation_Response>;
  /** delete single row from the table: "ddn.subgraph_build" */
  delete_ddn_subgraph_build_by_pk?: Maybe<Ddn_Subgraph_Build>;
  /** delete single row from the table: "ddn.subgraph" */
  delete_ddn_subgraph_by_pk?: Maybe<Ddn_Subgraph>;
  /** delete data from the table: "ddn.team" */
  delete_ddn_team?: Maybe<Ddn_Team_Mutation_Response>;
  /** delete single row from the table: "ddn.team" */
  delete_ddn_team_by_pk?: Maybe<Ddn_Team>;
  /** delete data from the table: "ddn.team_invitation" */
  delete_ddn_team_invitation?: Maybe<Ddn_Team_Invitation_Mutation_Response>;
  /** delete single row from the table: "ddn.team_invitation" */
  delete_ddn_team_invitation_by_pk?: Maybe<Ddn_Team_Invitation>;
  /** delete data from the table: "ddn.team_invitation_status" */
  delete_ddn_team_invitation_status?: Maybe<
    Ddn_Team_Invitation_Status_Mutation_Response
  >;
  /** delete single row from the table: "ddn.team_invitation_status" */
  delete_ddn_team_invitation_status_by_pk?: Maybe<Ddn_Team_Invitation_Status>;
  /** delete data from the table: "ddn.team_kind" */
  delete_ddn_team_kind?: Maybe<Ddn_Team_Kind_Mutation_Response>;
  /** delete single row from the table: "ddn.team_kind" */
  delete_ddn_team_kind_by_pk?: Maybe<Ddn_Team_Kind>;
  /** delete data from the table: "ddn.team_membership" */
  delete_ddn_team_membership?: Maybe<Ddn_Team_Membership_Mutation_Response>;
  /** delete single row from the table: "ddn.team_membership" */
  delete_ddn_team_membership_by_pk?: Maybe<Ddn_Team_Membership>;
  /** delete data from the table: "ddn.team_project_access" */
  delete_ddn_team_project_access?: Maybe<
    Ddn_Team_Project_Access_Mutation_Response
  >;
  /** delete single row from the table: "ddn.team_project_access" */
  delete_ddn_team_project_access_by_pk?: Maybe<Ddn_Team_Project_Access>;
  /** delete data from the table: "ddn.tunnel" */
  delete_ddn_tunnel?: Maybe<Ddn_Tunnel_Mutation_Response>;
  /** delete single row from the table: "ddn.tunnel" */
  delete_ddn_tunnel_by_pk?: Maybe<Ddn_Tunnel>;
  /** delete data from the table: "ddn.tunnel_cluster" */
  delete_ddn_tunnel_cluster?: Maybe<Ddn_Tunnel_Cluster_Mutation_Response>;
  /** delete single row from the table: "ddn.tunnel_cluster" */
  delete_ddn_tunnel_cluster_by_pk?: Maybe<Ddn_Tunnel_Cluster>;
  /** delete data from the table: "ddn.tunnel_http" */
  delete_ddn_tunnel_http?: Maybe<Ddn_Tunnel_Http_Mutation_Response>;
  /** delete single row from the table: "ddn.tunnel_http" */
  delete_ddn_tunnel_http_by_pk?: Maybe<Ddn_Tunnel_Http>;
  /** delete data from the table: "ddn.tunnel_http_cluster" */
  delete_ddn_tunnel_http_cluster?: Maybe<
    Ddn_Tunnel_Http_Cluster_Mutation_Response
  >;
  /** delete single row from the table: "ddn.tunnel_http_cluster" */
  delete_ddn_tunnel_http_cluster_by_pk?: Maybe<Ddn_Tunnel_Http_Cluster>;
  /** delete data from the table: "dedicated_cloud_bills" */
  delete_dedicated_cloud_bills?: Maybe<Dedicated_Cloud_Bills_Mutation_Response>;
  /** delete single row from the table: "dedicated_cloud_bills" */
  delete_dedicated_cloud_bills_by_pk?: Maybe<Dedicated_Cloud_Bills>;
  /** delete data from the table: "dedicated_cloud_bills_details" */
  delete_dedicated_cloud_bills_details?: Maybe<
    Dedicated_Cloud_Bills_Details_Mutation_Response
  >;
  /** delete single row from the table: "dedicated_cloud_bills_details" */
  delete_dedicated_cloud_bills_details_by_pk?: Maybe<
    Dedicated_Cloud_Bills_Details
  >;
  /** delete data from the table: "dedicated_cloud_commitments" */
  delete_dedicated_cloud_commitments?: Maybe<
    Dedicated_Cloud_Commitments_Mutation_Response
  >;
  /** delete single row from the table: "dedicated_cloud_commitments" */
  delete_dedicated_cloud_commitments_by_pk?: Maybe<Dedicated_Cloud_Commitments>;
  /** delete data from the table: "dedicated_vpc" */
  delete_dedicated_vpc?: Maybe<Dedicated_Vpc_Mutation_Response>;
  /** delete single row from the table: "dedicated_vpc" */
  delete_dedicated_vpc_by_pk?: Maybe<Dedicated_Vpc>;
  /** delete data from the table: "delete_user" */
  delete_delete_user?: Maybe<Delete_User_Mutation_Response>;
  /** delete single row from the table: "delete_user" */
  delete_delete_user_by_pk?: Maybe<Delete_User>;
  /** delete data from the table: "delete_user_status" */
  delete_delete_user_status?: Maybe<Delete_User_Status_Mutation_Response>;
  /** delete single row from the table: "delete_user_status" */
  delete_delete_user_status_by_pk?: Maybe<Delete_User_Status>;
  /** delete data from the table: "delete_user_tasks" */
  delete_delete_user_tasks?: Maybe<Delete_User_Tasks_Mutation_Response>;
  /** delete single row from the table: "delete_user_tasks" */
  delete_delete_user_tasks_by_pk?: Maybe<Delete_User_Tasks>;
  /** delete data from the table: "email_log" */
  delete_email_log?: Maybe<Email_Log_Mutation_Response>;
  /** delete single row from the table: "email_log" */
  delete_email_log_by_pk?: Maybe<Email_Log>;
  /** delete data from the table: "enterprise_users" */
  delete_enterprise_users?: Maybe<Enterprise_Users_Mutation_Response>;
  /** delete single row from the table: "enterprise_users" */
  delete_enterprise_users_by_pk?: Maybe<Enterprise_Users>;
  /** delete data from the table: "enterprise_users_domain" */
  delete_enterprise_users_domain?: Maybe<
    Enterprise_Users_Domain_Mutation_Response
  >;
  /** delete single row from the table: "enterprise_users_domain" */
  delete_enterprise_users_domain_by_pk?: Maybe<Enterprise_Users_Domain>;
  /** delete data from the table: "experiments" */
  delete_experiments?: Maybe<Experiments_Mutation_Response>;
  /** delete single row from the table: "experiments" */
  delete_experiments_by_pk?: Maybe<Experiments>;
  /** delete data from the table: "experiments_cohort" */
  delete_experiments_cohort?: Maybe<Experiments_Cohort_Mutation_Response>;
  /** delete single row from the table: "experiments_cohort" */
  delete_experiments_cohort_by_pk?: Maybe<Experiments_Cohort>;
  /** delete data from the table: "experiments_config" */
  delete_experiments_config?: Maybe<Experiments_Config_Mutation_Response>;
  /** delete single row from the table: "experiments_config" */
  delete_experiments_config_by_pk?: Maybe<Experiments_Config>;
  /** delete data from the table: "feature" */
  delete_feature?: Maybe<Feature_Mutation_Response>;
  /** delete data from the table: "feature_access" */
  delete_feature_access?: Maybe<Feature_Access_Mutation_Response>;
  /** delete single row from the table: "feature_access" */
  delete_feature_access_by_pk?: Maybe<Feature_Access>;
  /** delete single row from the table: "feature" */
  delete_feature_by_pk?: Maybe<Feature>;
  /** delete data from the table: "feature_config" */
  delete_feature_config?: Maybe<Feature_Config_Mutation_Response>;
  /** delete single row from the table: "feature_config" */
  delete_feature_config_by_pk?: Maybe<Feature_Config>;
  /** delete data from the table: "gateway_ami" */
  delete_gateway_ami?: Maybe<Gateway_Ami_Mutation_Response>;
  /** delete single row from the table: "gateway_ami" */
  delete_gateway_ami_by_pk?: Maybe<Gateway_Ami>;
  /** delete data from the table: "gateway_cluster" */
  delete_gateway_cluster?: Maybe<Gateway_Cluster_Mutation_Response>;
  /** delete single row from the table: "gateway_cluster" */
  delete_gateway_cluster_by_pk?: Maybe<Gateway_Cluster>;
  /** delete data from the table: "gateway_worker" */
  delete_gateway_worker?: Maybe<Gateway_Worker_Mutation_Response>;
  /** delete single row from the table: "gateway_worker" */
  delete_gateway_worker_by_pk?: Maybe<Gateway_Worker>;
  /** delete data from the table: "github_email_type" */
  delete_github_email_type?: Maybe<Github_Email_Type_Mutation_Response>;
  /** delete single row from the table: "github_email_type" */
  delete_github_email_type_by_pk?: Maybe<Github_Email_Type>;
  /** delete data from the table: "github_integration_config" */
  delete_github_integration_config?: Maybe<
    Github_Integration_Config_Mutation_Response
  >;
  /** delete single row from the table: "github_integration_config" */
  delete_github_integration_config_by_pk?: Maybe<Github_Integration_Config>;
  /** delete data from the table: "github_integration_mode" */
  delete_github_integration_mode?: Maybe<
    Github_Integration_Mode_Mutation_Response
  >;
  /** delete single row from the table: "github_integration_mode" */
  delete_github_integration_mode_by_pk?: Maybe<Github_Integration_Mode>;
  /** delete data from the table: "github_push_event" */
  delete_github_push_event?: Maybe<Github_Push_Event_Mutation_Response>;
  /** delete single row from the table: "github_push_event" */
  delete_github_push_event_by_pk?: Maybe<Github_Push_Event>;
  /** delete data from the table: "github_push_event_job" */
  delete_github_push_event_job?: Maybe<Github_Push_Event_Job_Mutation_Response>;
  /** delete single row from the table: "github_push_event_job" */
  delete_github_push_event_job_by_pk?: Maybe<Github_Push_Event_Job>;
  /** delete data from the table: "hasura_ami" */
  delete_hasura_ami?: Maybe<Hasura_Ami_Mutation_Response>;
  /** delete single row from the table: "hasura_ami" */
  delete_hasura_ami_by_pk?: Maybe<Hasura_Ami>;
  /** delete data from the table: "hasura_cluster" */
  delete_hasura_cluster?: Maybe<Hasura_Cluster_Mutation_Response>;
  /** delete single row from the table: "hasura_cluster" */
  delete_hasura_cluster_by_pk?: Maybe<Hasura_Cluster>;
  /** delete data from the table: "hasura_worker" */
  delete_hasura_worker?: Maybe<Hasura_Worker_Mutation_Response>;
  /** delete single row from the table: "hasura_worker" */
  delete_hasura_worker_by_pk?: Maybe<Hasura_Worker>;
  /** delete data from the table: "hasura_year_in_report_activity" */
  delete_hasura_year_in_report_activity?: Maybe<
    Hasura_Year_In_Report_Activity_Mutation_Response
  >;
  /** delete single row from the table: "hasura_year_in_report_activity" */
  delete_hasura_year_in_report_activity_by_pk?: Maybe<
    Hasura_Year_In_Report_Activity
  >;
  /** delete data from the table: "hasura_year_in_report_metrics" */
  delete_hasura_year_in_report_metrics?: Maybe<
    Hasura_Year_In_Report_Metrics_Mutation_Response
  >;
  /** delete single row from the table: "hasura_year_in_report_metrics" */
  delete_hasura_year_in_report_metrics_by_pk?: Maybe<
    Hasura_Year_In_Report_Metrics
  >;
  /** delete data from the table: "heroku_integrations" */
  delete_heroku_integrations?: Maybe<Heroku_Integrations_Mutation_Response>;
  /** delete single row from the table: "heroku_integrations" */
  delete_heroku_integrations_by_pk?: Maybe<Heroku_Integrations>;
  /** delete data from the table: "inactive_project_exclusions" */
  delete_inactive_project_exclusions?: Maybe<
    Inactive_Project_Exclusions_Mutation_Response
  >;
  /** delete single row from the table: "inactive_project_exclusions" */
  delete_inactive_project_exclusions_by_pk?: Maybe<Inactive_Project_Exclusions>;
  /** delete data from the table: "inactive_project_notifications" */
  delete_inactive_project_notifications?: Maybe<
    Inactive_Project_Notifications_Mutation_Response
  >;
  /** delete single row from the table: "inactive_project_notifications" */
  delete_inactive_project_notifications_by_pk?: Maybe<
    Inactive_Project_Notifications
  >;
  /** delete data from the table: "inactive_project_suspension_config" */
  delete_inactive_project_suspension_config?: Maybe<
    Inactive_Project_Suspension_Config_Mutation_Response
  >;
  /** delete data from the table: "infra_status" */
  delete_infra_status?: Maybe<Infra_Status_Mutation_Response>;
  /** delete single row from the table: "infra_status" */
  delete_infra_status_by_pk?: Maybe<Infra_Status>;
  /** delete data from the table: "invoice" */
  delete_invoice?: Maybe<Invoice_Mutation_Response>;
  /** delete single row from the table: "invoice" */
  delete_invoice_by_pk?: Maybe<Invoice>;
  /** delete data from the table: "invoice_coupon_discount" */
  delete_invoice_coupon_discount?: Maybe<
    Invoice_Coupon_Discount_Mutation_Response
  >;
  /** delete single row from the table: "invoice_coupon_discount" */
  delete_invoice_coupon_discount_by_pk?: Maybe<Invoice_Coupon_Discount>;
  /** delete data from the table: "invoice_item" */
  delete_invoice_item?: Maybe<Invoice_Item_Mutation_Response>;
  /** delete single row from the table: "invoice_item" */
  delete_invoice_item_by_pk?: Maybe<Invoice_Item>;
  /** delete data from the table: "jobs" */
  delete_jobs?: Maybe<Jobs_Mutation_Response>;
  /** delete single row from the table: "jobs" */
  delete_jobs_by_pk?: Maybe<Jobs>;
  /** delete data from the table: "label" */
  delete_label?: Maybe<Label_Mutation_Response>;
  /** delete single row from the table: "label" */
  delete_label_by_pk?: Maybe<Label>;
  /** delete data from the table: "letsencrypt_status" */
  delete_letsencrypt_status?: Maybe<Letsencrypt_Status_Mutation_Response>;
  /** delete single row from the table: "letsencrypt_status" */
  delete_letsencrypt_status_by_pk?: Maybe<Letsencrypt_Status>;
  /** delete data from the table: "license_activity" */
  delete_license_activity?: Maybe<License_Activity_Mutation_Response>;
  /** delete single row from the table: "license_activity" */
  delete_license_activity_by_pk?: Maybe<License_Activity>;
  /** delete data from the table: "license_instance" */
  delete_license_instance?: Maybe<License_Instance_Mutation_Response>;
  /** delete single row from the table: "license_instance" */
  delete_license_instance_by_pk?: Maybe<License_Instance>;
  /** delete data from the table: "license_type" */
  delete_license_type?: Maybe<License_Type_Mutation_Response>;
  /** delete single row from the table: "license_type" */
  delete_license_type_by_pk?: Maybe<License_Type>;
  /** delete data from the table: "licenses" */
  delete_licenses?: Maybe<Licenses_Mutation_Response>;
  /** delete single row from the table: "licenses" */
  delete_licenses_by_pk?: Maybe<Licenses>;
  /** delete data from the table: "lux_proxy" */
  delete_lux_proxy?: Maybe<Lux_Proxy_Mutation_Response>;
  /** delete data from the table: "lux_proxy_ami" */
  delete_lux_proxy_ami?: Maybe<Lux_Proxy_Ami_Mutation_Response>;
  /** delete single row from the table: "lux_proxy_ami" */
  delete_lux_proxy_ami_by_pk?: Maybe<Lux_Proxy_Ami>;
  /** delete single row from the table: "lux_proxy" */
  delete_lux_proxy_by_pk?: Maybe<Lux_Proxy>;
  /** delete data from the table: "model_based_enterprise_cloud_commitments" */
  delete_model_based_enterprise_cloud_commitments?: Maybe<
    Model_Based_Enterprise_Cloud_Commitments_Mutation_Response
  >;
  /** delete single row from the table: "model_based_enterprise_cloud_commitments" */
  delete_model_based_enterprise_cloud_commitments_by_pk?: Maybe<
    Model_Based_Enterprise_Cloud_Commitments
  >;
  /** delete data from the table: "neon_db_integration" */
  delete_neon_db_integration?: Maybe<Neon_Db_Integration_Mutation_Response>;
  /** delete single row from the table: "neon_db_integration" */
  delete_neon_db_integration_by_pk?: Maybe<Neon_Db_Integration>;
  /** delete data from the table: "newrelic_config" */
  delete_newrelic_config?: Maybe<Newrelic_Config_Mutation_Response>;
  /** delete single row from the table: "newrelic_config" */
  delete_newrelic_config_by_pk?: Maybe<Newrelic_Config>;
  /** delete data from the table: "node_pool_type" */
  delete_node_pool_type?: Maybe<Node_Pool_Type_Mutation_Response>;
  /** delete single row from the table: "node_pool_type" */
  delete_node_pool_type_by_pk?: Maybe<Node_Pool_Type>;
  /** delete data from the table: "node_pools" */
  delete_node_pools?: Maybe<Node_Pools_Mutation_Response>;
  /** delete single row from the table: "node_pools" */
  delete_node_pools_by_pk?: Maybe<Node_Pools>;
  /** delete data from the table: "notification" */
  delete_notification?: Maybe<Notification_Mutation_Response>;
  /** delete single row from the table: "notification" */
  delete_notification_by_pk?: Maybe<Notification>;
  /** delete data from the table: "notification_type" */
  delete_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** delete single row from the table: "notification_type" */
  delete_notification_type_by_pk?: Maybe<Notification_Type>;
  /** delete data from the table: "onboarding_sample_db_cohort" */
  delete_onboarding_sample_db_cohort?: Maybe<
    Onboarding_Sample_Db_Cohort_Mutation_Response
  >;
  /** delete single row from the table: "onboarding_sample_db_cohort" */
  delete_onboarding_sample_db_cohort_by_pk?: Maybe<Onboarding_Sample_Db_Cohort>;
  /** delete data from the table: "onboarding_sample_db_config" */
  delete_onboarding_sample_db_config?: Maybe<
    Onboarding_Sample_Db_Config_Mutation_Response
  >;
  /** delete single row from the table: "onboarding_sample_db_config" */
  delete_onboarding_sample_db_config_by_pk?: Maybe<Onboarding_Sample_Db_Config>;
  /** delete data from the table: "one_click_deployment" */
  delete_one_click_deployment?: Maybe<One_Click_Deployment_Mutation_Response>;
  /** delete single row from the table: "one_click_deployment" */
  delete_one_click_deployment_by_pk?: Maybe<One_Click_Deployment>;
  /** delete data from the table: "one_click_deployment_sample_apps" */
  delete_one_click_deployment_sample_apps?: Maybe<
    One_Click_Deployment_Sample_Apps_Mutation_Response
  >;
  /** delete single row from the table: "one_click_deployment_sample_apps" */
  delete_one_click_deployment_sample_apps_by_pk?: Maybe<
    One_Click_Deployment_Sample_Apps
  >;
  /** delete data from the table: "one_click_deployment_state_log" */
  delete_one_click_deployment_state_log?: Maybe<
    One_Click_Deployment_State_Log_Mutation_Response
  >;
  /** delete single row from the table: "one_click_deployment_state_log" */
  delete_one_click_deployment_state_log_by_pk?: Maybe<
    One_Click_Deployment_State_Log
  >;
  /** delete data from the table: "one_click_deployment_states" */
  delete_one_click_deployment_states?: Maybe<
    One_Click_Deployment_States_Mutation_Response
  >;
  /** delete single row from the table: "one_click_deployment_states" */
  delete_one_click_deployment_states_by_pk?: Maybe<One_Click_Deployment_States>;
  /** delete data from the table: "opentelemetry_config" */
  delete_opentelemetry_config?: Maybe<Opentelemetry_Config_Mutation_Response>;
  /** delete single row from the table: "opentelemetry_config" */
  delete_opentelemetry_config_by_pk?: Maybe<Opentelemetry_Config>;
  /** delete data from the table: "operations.delete_free_plan_dns_records_nov_2021" */
  delete_operations_delete_free_plan_dns_records_nov_2021?: Maybe<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Mutation_Response
  >;
  /** delete single row from the table: "operations.delete_free_plan_dns_records_nov_2021" */
  delete_operations_delete_free_plan_dns_records_nov_2021_by_pk?: Maybe<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021
  >;
  /** delete data from the table: "operations.tenant" */
  delete_operations_tenant?: Maybe<Operations_Tenant_Mutation_Response>;
  /** delete data from the table: "payment_method" */
  delete_payment_method?: Maybe<Payment_Method_Mutation_Response>;
  /** delete single row from the table: "payment_method" */
  delete_payment_method_by_pk?: Maybe<Payment_Method>;
  /** delete data from the table: "plan_entitlements" */
  delete_plan_entitlements?: Maybe<Plan_Entitlements_Mutation_Response>;
  /** delete single row from the table: "plan_entitlements" */
  delete_plan_entitlements_by_pk?: Maybe<Plan_Entitlements>;
  /** delete data from the table: "plans" */
  delete_plans?: Maybe<Plans_Mutation_Response>;
  /** delete single row from the table: "plans" */
  delete_plans_by_pk?: Maybe<Plans>;
  /** delete data from the table: "policies" */
  delete_policies?: Maybe<Policies_Mutation_Response>;
  /** delete single row from the table: "policies" */
  delete_policies_by_pk?: Maybe<Policies>;
  /** delete data from the table: "privileges" */
  delete_privileges?: Maybe<Privileges_Mutation_Response>;
  /** delete single row from the table: "privileges" */
  delete_privileges_by_pk?: Maybe<Privileges>;
  /** delete data from the table: "pro_license_keys" */
  delete_pro_license_keys?: Maybe<Pro_License_Keys_Mutation_Response>;
  /** delete single row from the table: "pro_license_keys" */
  delete_pro_license_keys_by_pk?: Maybe<Pro_License_Keys>;
  /** delete data from the table: "project_activity" */
  delete_project_activity?: Maybe<Project_Activity_Mutation_Response>;
  /** delete single row from the table: "project_activity" */
  delete_project_activity_by_pk?: Maybe<Project_Activity>;
  /** delete data from the table: "project_billing_manager_invitations" */
  delete_project_billing_manager_invitations?: Maybe<
    Project_Billing_Manager_Invitations_Mutation_Response
  >;
  /** delete single row from the table: "project_billing_manager_invitations" */
  delete_project_billing_manager_invitations_by_pk?: Maybe<
    Project_Billing_Manager_Invitations
  >;
  /** delete data from the table: "project_collaborator_allowed_schema_tables" */
  delete_project_collaborator_allowed_schema_tables?: Maybe<
    Project_Collaborator_Allowed_Schema_Tables_Mutation_Response
  >;
  /** delete single row from the table: "project_collaborator_allowed_schema_tables" */
  delete_project_collaborator_allowed_schema_tables_by_pk?: Maybe<
    Project_Collaborator_Allowed_Schema_Tables
  >;
  /** delete data from the table: "project_collaborator_allowed_schemas" */
  delete_project_collaborator_allowed_schemas?: Maybe<
    Project_Collaborator_Allowed_Schemas_Mutation_Response
  >;
  /** delete single row from the table: "project_collaborator_allowed_schemas" */
  delete_project_collaborator_allowed_schemas_by_pk?: Maybe<
    Project_Collaborator_Allowed_Schemas
  >;
  /** delete data from the table: "project_collaborator_invitations" */
  delete_project_collaborator_invitations?: Maybe<
    Project_Collaborator_Invitations_Mutation_Response
  >;
  /** delete single row from the table: "project_collaborator_invitations" */
  delete_project_collaborator_invitations_by_pk?: Maybe<
    Project_Collaborator_Invitations
  >;
  /** delete data from the table: "project_collaborator_privileges" */
  delete_project_collaborator_privileges?: Maybe<
    Project_Collaborator_Privileges_Mutation_Response
  >;
  /** delete single row from the table: "project_collaborator_privileges" */
  delete_project_collaborator_privileges_by_pk?: Maybe<
    Project_Collaborator_Privileges
  >;
  /** delete data from the table: "project_collaborators" */
  delete_project_collaborators?: Maybe<Project_Collaborators_Mutation_Response>;
  /** delete single row from the table: "project_collaborators" */
  delete_project_collaborators_by_pk?: Maybe<Project_Collaborators>;
  /** delete data from the table: "project_data_usage" */
  delete_project_data_usage?: Maybe<Project_Data_Usage_Mutation_Response>;
  /** delete data from the table: "project_data_usage_agg" */
  delete_project_data_usage_agg?: Maybe<
    Project_Data_Usage_Agg_Mutation_Response
  >;
  /** delete data from the table: "project_data_usage_agg_user" */
  delete_project_data_usage_agg_user?: Maybe<
    Project_Data_Usage_Agg_User_Mutation_Response
  >;
  /** delete single row from the table: "project_data_usage" */
  delete_project_data_usage_by_pk?: Maybe<Project_Data_Usage>;
  /** delete data from the table: "project_data_usage_components" */
  delete_project_data_usage_components?: Maybe<
    Project_Data_Usage_Components_Mutation_Response
  >;
  /** delete data from the table: "project_data_usage_prometheus" */
  delete_project_data_usage_prometheus?: Maybe<
    Project_Data_Usage_Prometheus_Mutation_Response
  >;
  /** delete data from the table: "project_data_usage_prometheus_agg_user" */
  delete_project_data_usage_prometheus_agg_user?: Maybe<
    Project_Data_Usage_Prometheus_Agg_User_Mutation_Response
  >;
  /** delete single row from the table: "project_data_usage_prometheus" */
  delete_project_data_usage_prometheus_by_pk?: Maybe<
    Project_Data_Usage_Prometheus
  >;
  /** delete data from the table: "project_data_usage_report" */
  delete_project_data_usage_report?: Maybe<
    Project_Data_Usage_Report_Mutation_Response
  >;
  /** delete single row from the table: "project_data_usage_report" */
  delete_project_data_usage_report_by_pk?: Maybe<Project_Data_Usage_Report>;
  /** delete data from the table: "project_db_usage" */
  delete_project_db_usage?: Maybe<Project_Db_Usage_Mutation_Response>;
  /** delete data from the table: "project_db_usage_agg_user" */
  delete_project_db_usage_agg_user?: Maybe<
    Project_Db_Usage_Agg_User_Mutation_Response
  >;
  /** delete single row from the table: "project_db_usage" */
  delete_project_db_usage_by_pk?: Maybe<Project_Db_Usage>;
  /** delete data from the table: "project_entitlement_access" */
  delete_project_entitlement_access?: Maybe<
    Project_Entitlement_Access_Mutation_Response
  >;
  /** delete single row from the table: "project_entitlement_access" */
  delete_project_entitlement_access_by_pk?: Maybe<Project_Entitlement_Access>;
  /** delete data from the table: "project_entitlement_catalogue" */
  delete_project_entitlement_catalogue?: Maybe<
    Project_Entitlement_Catalogue_Mutation_Response
  >;
  /** delete single row from the table: "project_entitlement_catalogue" */
  delete_project_entitlement_catalogue_by_pk?: Maybe<
    Project_Entitlement_Catalogue
  >;
  /** delete data from the table: "project_entitlement_types" */
  delete_project_entitlement_types?: Maybe<
    Project_Entitlement_Types_Mutation_Response
  >;
  /** delete single row from the table: "project_entitlement_types" */
  delete_project_entitlement_types_by_pk?: Maybe<Project_Entitlement_Types>;
  /** delete data from the table: "project_labels" */
  delete_project_labels?: Maybe<Project_Labels_Mutation_Response>;
  /** delete single row from the table: "project_labels" */
  delete_project_labels_by_pk?: Maybe<Project_Labels>;
  /** delete data from the table: "project_metadata" */
  delete_project_metadata?: Maybe<Project_Metadata_Mutation_Response>;
  /** delete single row from the table: "project_metadata" */
  delete_project_metadata_by_pk?: Maybe<Project_Metadata>;
  /** delete data from the table: "project_notification" */
  delete_project_notification?: Maybe<Project_Notification_Mutation_Response>;
  /** delete single row from the table: "project_notification" */
  delete_project_notification_by_pk?: Maybe<Project_Notification>;
  /** delete data from the table: "project_ownership_transfer_invitations" */
  delete_project_ownership_transfer_invitations?: Maybe<
    Project_Ownership_Transfer_Invitations_Mutation_Response
  >;
  /** delete single row from the table: "project_ownership_transfer_invitations" */
  delete_project_ownership_transfer_invitations_by_pk?: Maybe<
    Project_Ownership_Transfer_Invitations
  >;
  /** delete data from the table: "project_requests_count" */
  delete_project_requests_count?: Maybe<
    Project_Requests_Count_Mutation_Response
  >;
  /** delete single row from the table: "project_requests_count" */
  delete_project_requests_count_by_pk?: Maybe<Project_Requests_Count>;
  /** delete data from the table: "project_stats" */
  delete_project_stats?: Maybe<Project_Stats_Mutation_Response>;
  /** delete single row from the table: "project_stats" */
  delete_project_stats_by_pk?: Maybe<Project_Stats>;
  /** delete data from the table: "project_total_db_usage_agg" */
  delete_project_total_db_usage_agg?: Maybe<
    Project_Total_Db_Usage_Agg_Mutation_Response
  >;
  /** delete data from the table: "projects" */
  delete_projects?: Maybe<Projects_Mutation_Response>;
  /** delete single row from the table: "projects" */
  delete_projects_by_pk?: Maybe<Projects>;
  /** delete data from the table: "projects_pool_config" */
  delete_projects_pool_config?: Maybe<Projects_Pool_Config_Mutation_Response>;
  /** delete single row from the table: "projects_pool_config" */
  delete_projects_pool_config_by_pk?: Maybe<Projects_Pool_Config>;
  /** delete data from the table: "projects_pro_key_generations" */
  delete_projects_pro_key_generations?: Maybe<
    Projects_Pro_Key_Generations_Mutation_Response
  >;
  /** delete single row from the table: "projects_pro_key_generations" */
  delete_projects_pro_key_generations_by_pk?: Maybe<
    Projects_Pro_Key_Generations
  >;
  /** delete data from the table: "prometheus_config" */
  delete_prometheus_config?: Maybe<Prometheus_Config_Mutation_Response>;
  /** delete single row from the table: "prometheus_config" */
  delete_prometheus_config_by_pk?: Maybe<Prometheus_Config>;
  /** delete data from the table: "providers" */
  delete_providers?: Maybe<Providers_Mutation_Response>;
  /** delete single row from the table: "providers" */
  delete_providers_by_pk?: Maybe<Providers>;
  /** delete data from the table: "provisioning" */
  delete_provisioning?: Maybe<Provisioning_Mutation_Response>;
  /** delete single row from the table: "provisioning" */
  delete_provisioning_by_pk?: Maybe<Provisioning>;
  /** delete data from the table: "region" */
  delete_region?: Maybe<Region_Mutation_Response>;
  /** delete single row from the table: "region" */
  delete_region_by_pk?: Maybe<Region>;
  /** delete data from the table: "region_v2" */
  delete_region_v2?: Maybe<Region_V2_Mutation_Response>;
  /** delete data from the table: "regional_data_connectors" */
  delete_regional_data_connectors?: Maybe<
    Regional_Data_Connectors_Mutation_Response
  >;
  /** delete single row from the table: "regional_data_connectors" */
  delete_regional_data_connectors_by_pk?: Maybe<Regional_Data_Connectors>;
  /** delete data from the table: "regional_metrics" */
  delete_regional_metrics?: Maybe<Regional_Metrics_Mutation_Response>;
  /** delete single row from the table: "regional_metrics" */
  delete_regional_metrics_by_pk?: Maybe<Regional_Metrics>;
  /** delete data from the table: "saml_idp" */
  delete_saml_idp?: Maybe<Saml_Idp_Mutation_Response>;
  /** delete single row from the table: "saml_idp" */
  delete_saml_idp_by_pk?: Maybe<Saml_Idp>;
  /** delete data from the table: "search_project_login_status_results" */
  delete_search_project_login_status_results?: Maybe<
    Search_Project_Login_Status_Results_Mutation_Response
  >;
  /** delete data from the table: "slack_config" */
  delete_slack_config?: Maybe<Slack_Config_Mutation_Response>;
  /** delete single row from the table: "slack_config" */
  delete_slack_config_by_pk?: Maybe<Slack_Config>;
  /** delete data from the table: "stripe_subscription" */
  delete_stripe_subscription?: Maybe<Stripe_Subscription_Mutation_Response>;
  /** delete single row from the table: "stripe_subscription" */
  delete_stripe_subscription_by_pk?: Maybe<Stripe_Subscription>;
  /** delete data from the table: "stripe_webhook_events" */
  delete_stripe_webhook_events?: Maybe<Stripe_Webhook_Events_Mutation_Response>;
  /** delete single row from the table: "stripe_webhook_events" */
  delete_stripe_webhook_events_by_pk?: Maybe<Stripe_Webhook_Events>;
  /** delete data from the table: "super_connector_types" */
  delete_super_connector_types?: Maybe<Super_Connector_Types_Mutation_Response>;
  /** delete single row from the table: "super_connector_types" */
  delete_super_connector_types_by_pk?: Maybe<Super_Connector_Types>;
  /** delete data from the table: "support_plan_types" */
  delete_support_plan_types?: Maybe<Support_Plan_Types_Mutation_Response>;
  /** delete single row from the table: "support_plan_types" */
  delete_support_plan_types_by_pk?: Maybe<Support_Plan_Types>;
  /** delete data from the table: "survey" */
  delete_survey?: Maybe<Survey_Mutation_Response>;
  /** delete single row from the table: "survey" */
  delete_survey_by_pk?: Maybe<Survey>;
  /** delete data from the table: "survey_question" */
  delete_survey_question?: Maybe<Survey_Question_Mutation_Response>;
  /** delete data from the table: "survey_question_answer_option" */
  delete_survey_question_answer_option?: Maybe<
    Survey_Question_Answer_Option_Mutation_Response
  >;
  /** delete single row from the table: "survey_question_answer_option" */
  delete_survey_question_answer_option_by_pk?: Maybe<
    Survey_Question_Answer_Option
  >;
  /** delete data from the table: "survey_question_answers" */
  delete_survey_question_answers?: Maybe<
    Survey_Question_Answers_Mutation_Response
  >;
  /** delete single row from the table: "survey_question_answers" */
  delete_survey_question_answers_by_pk?: Maybe<Survey_Question_Answers>;
  /** delete single row from the table: "survey_question" */
  delete_survey_question_by_pk?: Maybe<Survey_Question>;
  /** delete data from the table: "survey_question_kind" */
  delete_survey_question_kind?: Maybe<Survey_Question_Kind_Mutation_Response>;
  /** delete single row from the table: "survey_question_kind" */
  delete_survey_question_kind_by_pk?: Maybe<Survey_Question_Kind>;
  /** delete data from the table: "survey_question_options" */
  delete_survey_question_options?: Maybe<
    Survey_Question_Options_Mutation_Response
  >;
  /** delete single row from the table: "survey_question_options" */
  delete_survey_question_options_by_pk?: Maybe<Survey_Question_Options>;
  /** delete data from the table: "survey_v2" */
  delete_survey_v2?: Maybe<Survey_V2_Mutation_Response>;
  /** delete single row from the table: "survey_v2" */
  delete_survey_v2_by_pk?: Maybe<Survey_V2>;
  /** delete data from the table: "survey_v2_question" */
  delete_survey_v2_question?: Maybe<Survey_V2_Question_Mutation_Response>;
  /** delete single row from the table: "survey_v2_question" */
  delete_survey_v2_question_by_pk?: Maybe<Survey_V2_Question>;
  /** delete data from the table: "survey_v2_question_kind" */
  delete_survey_v2_question_kind?: Maybe<
    Survey_V2_Question_Kind_Mutation_Response
  >;
  /** delete single row from the table: "survey_v2_question_kind" */
  delete_survey_v2_question_kind_by_pk?: Maybe<Survey_V2_Question_Kind>;
  /** delete data from the table: "survey_v2_question_option" */
  delete_survey_v2_question_option?: Maybe<
    Survey_V2_Question_Option_Mutation_Response
  >;
  /** delete data from the table: "survey_v2_question_option_additional_info_config" */
  delete_survey_v2_question_option_additional_info_config?: Maybe<
    Survey_V2_Question_Option_Additional_Info_Config_Mutation_Response
  >;
  /** delete single row from the table: "survey_v2_question_option_additional_info_config" */
  delete_survey_v2_question_option_additional_info_config_by_pk?: Maybe<
    Survey_V2_Question_Option_Additional_Info_Config
  >;
  /** delete single row from the table: "survey_v2_question_option" */
  delete_survey_v2_question_option_by_pk?: Maybe<Survey_V2_Question_Option>;
  /** delete data from the table: "survey_v2_response" */
  delete_survey_v2_response?: Maybe<Survey_V2_Response_Mutation_Response>;
  /** delete data from the table: "survey_v2_response_answer" */
  delete_survey_v2_response_answer?: Maybe<
    Survey_V2_Response_Answer_Mutation_Response
  >;
  /** delete single row from the table: "survey_v2_response_answer" */
  delete_survey_v2_response_answer_by_pk?: Maybe<Survey_V2_Response_Answer>;
  /** delete data from the table: "survey_v2_response_answer_option" */
  delete_survey_v2_response_answer_option?: Maybe<
    Survey_V2_Response_Answer_Option_Mutation_Response
  >;
  /** delete single row from the table: "survey_v2_response_answer_option" */
  delete_survey_v2_response_answer_option_by_pk?: Maybe<
    Survey_V2_Response_Answer_Option
  >;
  /** delete single row from the table: "survey_v2_response" */
  delete_survey_v2_response_by_pk?: Maybe<Survey_V2_Response>;
  /** delete data from the table: "task_event" */
  delete_task_event?: Maybe<Task_Event_Mutation_Response>;
  /** delete single row from the table: "task_event" */
  delete_task_event_by_pk?: Maybe<Task_Event>;
  /** delete data from the table: "tasks" */
  delete_tasks?: Maybe<Tasks_Mutation_Response>;
  /** delete single row from the table: "tasks" */
  delete_tasks_by_pk?: Maybe<Tasks>;
  /** delete data from the table: "tenant" */
  delete_tenant?: Maybe<Tenant_Mutation_Response>;
  /** delete data from the table: "tenant_active_status_reason" */
  delete_tenant_active_status_reason?: Maybe<
    Tenant_Active_Status_Reason_Mutation_Response
  >;
  /** delete single row from the table: "tenant_active_status_reason" */
  delete_tenant_active_status_reason_by_pk?: Maybe<Tenant_Active_Status_Reason>;
  /** delete single row from the table: "tenant" */
  delete_tenant_by_pk?: Maybe<Tenant>;
  /** delete data from the table: "tenant_group" */
  delete_tenant_group?: Maybe<Tenant_Group_Mutation_Response>;
  /** delete single row from the table: "tenant_group" */
  delete_tenant_group_by_pk?: Maybe<Tenant_Group>;
  /** delete data from the table: "tenant_group_member" */
  delete_tenant_group_member?: Maybe<Tenant_Group_Member_Mutation_Response>;
  /** delete single row from the table: "tenant_group_member" */
  delete_tenant_group_member_by_pk?: Maybe<Tenant_Group_Member>;
  /** delete data from the table: "tenant_public_status" */
  delete_tenant_public_status?: Maybe<Tenant_Public_Status_Mutation_Response>;
  /** delete single row from the table: "tenant_public_status" */
  delete_tenant_public_status_by_pk?: Maybe<Tenant_Public_Status>;
  /** delete data from the table: "tenant_status" */
  delete_tenant_status?: Maybe<Tenant_Status_Mutation_Response>;
  /** delete single row from the table: "tenant_status" */
  delete_tenant_status_by_pk?: Maybe<Tenant_Status>;
  /** delete data from the table: "trial_leads" */
  delete_trial_leads?: Maybe<Trial_Leads_Mutation_Response>;
  /** delete single row from the table: "trial_leads" */
  delete_trial_leads_by_pk?: Maybe<Trial_Leads>;
  /** delete data from the table: "ua_audit_logs" */
  delete_ua_audit_logs?: Maybe<Ua_Audit_Logs_Mutation_Response>;
  /** delete single row from the table: "ua_audit_logs" */
  delete_ua_audit_logs_by_pk?: Maybe<Ua_Audit_Logs>;
  /** delete data from the table: "user_activity" */
  delete_user_activity?: Maybe<User_Activity_Mutation_Response>;
  /** delete single row from the table: "user_activity" */
  delete_user_activity_by_pk?: Maybe<User_Activity>;
  /** delete data from the table: "user_coupon" */
  delete_user_coupon?: Maybe<User_Coupon_Mutation_Response>;
  /** delete single row from the table: "user_coupon" */
  delete_user_coupon_by_pk?: Maybe<User_Coupon>;
  /** delete data from the table: "user_entitlement_access" */
  delete_user_entitlement_access?: Maybe<
    User_Entitlement_Access_Mutation_Response
  >;
  /** delete single row from the table: "user_entitlement_access" */
  delete_user_entitlement_access_by_pk?: Maybe<User_Entitlement_Access>;
  /** delete data from the table: "user_entitlement_catalogue" */
  delete_user_entitlement_catalogue?: Maybe<
    User_Entitlement_Catalogue_Mutation_Response
  >;
  /** delete single row from the table: "user_entitlement_catalogue" */
  delete_user_entitlement_catalogue_by_pk?: Maybe<User_Entitlement_Catalogue>;
  /** delete data from the table: "user_entitlement_types" */
  delete_user_entitlement_types?: Maybe<
    User_Entitlement_Types_Mutation_Response
  >;
  /** delete single row from the table: "user_entitlement_types" */
  delete_user_entitlement_types_by_pk?: Maybe<User_Entitlement_Types>;
  /** delete data from the table: "user_onboarding" */
  delete_user_onboarding?: Maybe<User_Onboarding_Mutation_Response>;
  /** delete single row from the table: "user_onboarding" */
  delete_user_onboarding_by_pk?: Maybe<User_Onboarding>;
  /** delete data from the table: "user_personal_access_tokens" */
  delete_user_personal_access_tokens?: Maybe<
    User_Personal_Access_Tokens_Mutation_Response
  >;
  /** delete single row from the table: "user_personal_access_tokens" */
  delete_user_personal_access_tokens_by_pk?: Maybe<User_Personal_Access_Tokens>;
  /** delete data from the table: "user_profile" */
  delete_user_profile?: Maybe<User_Profile_Mutation_Response>;
  /** delete single row from the table: "user_profile" */
  delete_user_profile_by_pk?: Maybe<User_Profile>;
  /** delete data from the table: "user_roles" */
  delete_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** delete single row from the table: "user_roles" */
  delete_user_roles_by_pk?: Maybe<User_Roles>;
  /** delete data from the table: "user_vpc_policy" */
  delete_user_vpc_policy?: Maybe<User_Vpc_Policy_Mutation_Response>;
  /** delete single row from the table: "user_vpc_policy" */
  delete_user_vpc_policy_by_pk?: Maybe<User_Vpc_Policy>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "users_public" */
  delete_users_public?: Maybe<Users_Public_Mutation_Response>;
  /** delete data from the table: "vercel_integration" */
  delete_vercel_integration?: Maybe<Vercel_Integration_Mutation_Response>;
  /** delete single row from the table: "vercel_integration" */
  delete_vercel_integration_by_pk?: Maybe<Vercel_Integration>;
  /** delete data from the table: "vercel_integration_connections" */
  delete_vercel_integration_connections?: Maybe<
    Vercel_Integration_Connections_Mutation_Response
  >;
  /** delete single row from the table: "vercel_integration_connections" */
  delete_vercel_integration_connections_by_pk?: Maybe<
    Vercel_Integration_Connections
  >;
  /** delete data from the table: "vpc_collaborator_invitations" */
  delete_vpc_collaborator_invitations?: Maybe<
    Vpc_Collaborator_Invitations_Mutation_Response
  >;
  /** delete single row from the table: "vpc_collaborator_invitations" */
  delete_vpc_collaborator_invitations_by_pk?: Maybe<
    Vpc_Collaborator_Invitations
  >;
  /** delete data from the table: "vpc_collaborators" */
  delete_vpc_collaborators?: Maybe<Vpc_Collaborators_Mutation_Response>;
  /** delete single row from the table: "vpc_collaborators" */
  delete_vpc_collaborators_by_pk?: Maybe<Vpc_Collaborators>;
  /** delete data from the table: "vpc_peering" */
  delete_vpc_peering?: Maybe<Vpc_Peering_Mutation_Response>;
  /** delete single row from the table: "vpc_peering" */
  delete_vpc_peering_by_pk?: Maybe<Vpc_Peering>;
  /** delete data from the table: "vpc_peering_direction" */
  delete_vpc_peering_direction?: Maybe<Vpc_Peering_Direction_Mutation_Response>;
  /** delete single row from the table: "vpc_peering_direction" */
  delete_vpc_peering_direction_by_pk?: Maybe<Vpc_Peering_Direction>;
  /** delete data from the table: "vpc_privileges" */
  delete_vpc_privileges?: Maybe<Vpc_Privileges_Mutation_Response>;
  /** delete single row from the table: "vpc_privileges" */
  delete_vpc_privileges_by_pk?: Maybe<Vpc_Privileges>;
  /** delete data from the table: "vpc_status" */
  delete_vpc_status?: Maybe<Vpc_Status_Mutation_Response>;
  /** delete single row from the table: "vpc_status" */
  delete_vpc_status_by_pk?: Maybe<Vpc_Status>;
  /** delete data from the table: "zendesk_support_category" */
  delete_zendesk_support_category?: Maybe<
    Zendesk_Support_Category_Mutation_Response
  >;
  /** delete single row from the table: "zendesk_support_category" */
  delete_zendesk_support_category_by_pk?: Maybe<Zendesk_Support_Category>;
  /** delete data from the table: "zendesk_support_tickets" */
  delete_zendesk_support_tickets?: Maybe<
    Zendesk_Support_Tickets_Mutation_Response
  >;
  /** delete single row from the table: "zendesk_support_tickets" */
  delete_zendesk_support_tickets_by_pk?: Maybe<Zendesk_Support_Tickets>;
  deployLatestGithubCommit?: Maybe<DeployLatestCommitStatus>;
  enableCloudflareProxy?: Maybe<EnableCloudflareProxyResponse>;
  /** fetchCurrentGithubLogin */
  fetchCurrentGithubLogin: GithubLoginResponse;
  /** fetchGithubAppInstallations */
  fetchGithubAppInstallations: GithubAppInstallations;
  /** fetchGithubAppIntegrationAccessibleRepos */
  fetchGithubAppIntegrationAccessibleRepos?: Maybe<
    GithubAppIntegrationAccessibleRepos
  >;
  /** fetchGithubRepoBranches */
  fetchGithubRepoBranches: GithubBranches;
  generateApiDiff: ApiDiff;
  getDDNBillingState: DdnBillingStateResponse;
  getGithubSession?: Maybe<GithubSession>;
  getHerokuSession?: Maybe<HerokuSession>;
  githubTokenExchange?: Maybe<GithubSession>;
  handleInactiveProjects?: Maybe<HandleInactiveProjectsResult>;
  herokuRegisterWebhook: WebhookRegisterStatus;
  herokuRegisterWebhookVar: WebhookRegisterStatus;
  herokuTokenExchange?: Maybe<HerokuSession>;
  herokuUnregisterWebhook: WebhookUnregisterStatus;
  herokuUnregisterWebhookVar: WebhookUnregisterStatus;
  initiateStripeAddCardProcess: ClientSecretInfo;
  /** insert data into the table: "alert_config" */
  insert_alert_config?: Maybe<Alert_Config_Mutation_Response>;
  /** insert data into the table: "alert_config_alert_type" */
  insert_alert_config_alert_type?: Maybe<
    Alert_Config_Alert_Type_Mutation_Response
  >;
  /** insert a single row into the table: "alert_config_alert_type" */
  insert_alert_config_alert_type_one?: Maybe<Alert_Config_Alert_Type>;
  /** insert a single row into the table: "alert_config" */
  insert_alert_config_one?: Maybe<Alert_Config>;
  /** insert data into the table: "alert_config_service" */
  insert_alert_config_service?: Maybe<Alert_Config_Service_Mutation_Response>;
  /** insert a single row into the table: "alert_config_service" */
  insert_alert_config_service_one?: Maybe<Alert_Config_Service>;
  /** insert data into the table: "alert_service_type" */
  insert_alert_service_type?: Maybe<Alert_Service_Type_Mutation_Response>;
  /** insert a single row into the table: "alert_service_type" */
  insert_alert_service_type_one?: Maybe<Alert_Service_Type>;
  /** insert data into the table: "alert_type" */
  insert_alert_type?: Maybe<Alert_Type_Mutation_Response>;
  /** insert a single row into the table: "alert_type" */
  insert_alert_type_one?: Maybe<Alert_Type>;
  /** insert data into the table: "azuremonitor_config" */
  insert_azuremonitor_config?: Maybe<Azuremonitor_Config_Mutation_Response>;
  /** insert a single row into the table: "azuremonitor_config" */
  insert_azuremonitor_config_one?: Maybe<Azuremonitor_Config>;
  /** insert data into the table: "billing_address" */
  insert_billing_address?: Maybe<Billing_Address_Mutation_Response>;
  /** insert a single row into the table: "billing_address" */
  insert_billing_address_one?: Maybe<Billing_Address>;
  /** insert data into the table: "central_cloud" */
  insert_central_cloud?: Maybe<Central_Cloud_Mutation_Response>;
  /** insert a single row into the table: "central_cloud" */
  insert_central_cloud_one?: Maybe<Central_Cloud>;
  /** insert data into the table: "change_email_request" */
  insert_change_email_request?: Maybe<Change_Email_Request_Mutation_Response>;
  /** insert a single row into the table: "change_email_request" */
  insert_change_email_request_one?: Maybe<Change_Email_Request>;
  /** insert data into the table: "cicd.deployment_pipeline_config" */
  insert_cicd_deployment_pipeline_config?: Maybe<
    Cicd_Deployment_Pipeline_Config_Mutation_Response
  >;
  /** insert a single row into the table: "cicd.deployment_pipeline_config" */
  insert_cicd_deployment_pipeline_config_one?: Maybe<
    Cicd_Deployment_Pipeline_Config
  >;
  /** insert data into the table: "cicd.deployment_pool" */
  insert_cicd_deployment_pool?: Maybe<Cicd_Deployment_Pool_Mutation_Response>;
  /** insert data into the table: "cicd.deployment_pool_member" */
  insert_cicd_deployment_pool_member?: Maybe<
    Cicd_Deployment_Pool_Member_Mutation_Response
  >;
  /** insert a single row into the table: "cicd.deployment_pool_member" */
  insert_cicd_deployment_pool_member_one?: Maybe<Cicd_Deployment_Pool_Member>;
  /** insert a single row into the table: "cicd.deployment_pool" */
  insert_cicd_deployment_pool_one?: Maybe<Cicd_Deployment_Pool>;
  /** insert data into the table: "cloud" */
  insert_cloud?: Maybe<Cloud_Mutation_Response>;
  /** insert data into the table: "cloud_metadata" */
  insert_cloud_metadata?: Maybe<Cloud_Metadata_Mutation_Response>;
  /** insert a single row into the table: "cloud_metadata" */
  insert_cloud_metadata_one?: Maybe<Cloud_Metadata>;
  /** insert a single row into the table: "cloud" */
  insert_cloud_one?: Maybe<Cloud>;
  /** insert data into the table: "compute_unit_config" */
  insert_compute_unit_config?: Maybe<Compute_Unit_Config_Mutation_Response>;
  /** insert a single row into the table: "compute_unit_config" */
  insert_compute_unit_config_one?: Maybe<Compute_Unit_Config>;
  /** insert data into the table: "config" */
  insert_config?: Maybe<Config_Mutation_Response>;
  /** insert a single row into the table: "config" */
  insert_config_one?: Maybe<Config>;
  /** insert data into the table: "config_status" */
  insert_config_status?: Maybe<Config_Status_Mutation_Response>;
  /** insert a single row into the table: "config_status" */
  insert_config_status_one?: Maybe<Config_Status>;
  /** insert data into the table: "connector_config" */
  insert_connector_config?: Maybe<Connector_Config_Mutation_Response>;
  /** insert a single row into the table: "connector_config" */
  insert_connector_config_one?: Maybe<Connector_Config>;
  /** insert data into the table: "connector_deployments" */
  insert_connector_deployments?: Maybe<Connector_Deployments_Mutation_Response>;
  /** insert a single row into the table: "connector_deployments" */
  insert_connector_deployments_one?: Maybe<Connector_Deployments>;
  /** insert data into the table: "coupon" */
  insert_coupon?: Maybe<Coupon_Mutation_Response>;
  /** insert data into the table: "coupon_duration" */
  insert_coupon_duration?: Maybe<Coupon_Duration_Mutation_Response>;
  /** insert a single row into the table: "coupon_duration" */
  insert_coupon_duration_one?: Maybe<Coupon_Duration>;
  /** insert a single row into the table: "coupon" */
  insert_coupon_one?: Maybe<Coupon>;
  /** insert data into the table: "coupon_type" */
  insert_coupon_type?: Maybe<Coupon_Type_Mutation_Response>;
  /** insert a single row into the table: "coupon_type" */
  insert_coupon_type_one?: Maybe<Coupon_Type>;
  /** insert data into the table: "custom_domain" */
  insert_custom_domain?: Maybe<Custom_Domain_Mutation_Response>;
  /** insert data into the table: "custom_domain_cloudflare" */
  insert_custom_domain_cloudflare?: Maybe<
    Custom_Domain_Cloudflare_Mutation_Response
  >;
  /** insert data into the table: "custom_domain_cloudflare_dns" */
  insert_custom_domain_cloudflare_dns?: Maybe<
    Custom_Domain_Cloudflare_Dns_Mutation_Response
  >;
  /** insert a single row into the table: "custom_domain_cloudflare_dns" */
  insert_custom_domain_cloudflare_dns_one?: Maybe<Custom_Domain_Cloudflare_Dns>;
  /** insert a single row into the table: "custom_domain_cloudflare" */
  insert_custom_domain_cloudflare_one?: Maybe<Custom_Domain_Cloudflare>;
  /** insert a single row into the table: "custom_domain" */
  insert_custom_domain_one?: Maybe<Custom_Domain>;
  /** insert data into the table: "customer_usage" */
  insert_customer_usage?: Maybe<Customer_Usage_Mutation_Response>;
  /** insert a single row into the table: "customer_usage" */
  insert_customer_usage_one?: Maybe<Customer_Usage>;
  /** insert data into the table: "data_connector_type" */
  insert_data_connector_type?: Maybe<Data_Connector_Type_Mutation_Response>;
  /** insert a single row into the table: "data_connector_type" */
  insert_data_connector_type_one?: Maybe<Data_Connector_Type>;
  /** insert data into the table: "datadog_config" */
  insert_datadog_config?: Maybe<Datadog_Config_Mutation_Response>;
  /** insert a single row into the table: "datadog_config" */
  insert_datadog_config_one?: Maybe<Datadog_Config>;
  /** insert data into the table: "db_latency" */
  insert_db_latency?: Maybe<Db_Latency_Mutation_Response>;
  /** insert a single row into the table: "db_latency" */
  insert_db_latency_one?: Maybe<Db_Latency>;
  /** execute VOLATILE function "insert_db_usage" which returns "project_db_usage" */
  insert_db_usage: Array<Project_Db_Usage>;
  /** insert data into the table: "ddn.applied_build_changelog" */
  insert_ddn_applied_build_changelog?: Maybe<
    Ddn_Applied_Build_Changelog_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.applied_build_changelog" */
  insert_ddn_applied_build_changelog_one?: Maybe<Ddn_Applied_Build_Changelog>;
  /** insert data into the table: "ddn.applied_subgraph_build_changelog" */
  insert_ddn_applied_subgraph_build_changelog?: Maybe<
    Ddn_Applied_Subgraph_Build_Changelog_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.applied_subgraph_build_changelog" */
  insert_ddn_applied_subgraph_build_changelog_one?: Maybe<
    Ddn_Applied_Subgraph_Build_Changelog
  >;
  /** insert data into the table: "ddn.auto_approve_project_request" */
  insert_ddn_auto_approve_project_request?: Maybe<
    Ddn_Auto_Approve_Project_Request_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.auto_approve_project_request" */
  insert_ddn_auto_approve_project_request_one?: Maybe<
    Ddn_Auto_Approve_Project_Request
  >;
  /** insert data into the table: "ddn.build" */
  insert_ddn_build?: Maybe<Ddn_Build_Mutation_Response>;
  /** insert data into the table: "ddn.build_details" */
  insert_ddn_build_details?: Maybe<Ddn_Build_Details_Mutation_Response>;
  /** insert a single row into the table: "ddn.build_details" */
  insert_ddn_build_details_one?: Maybe<Ddn_Build_Details>;
  /** insert data into the table: "ddn.build_lock" */
  insert_ddn_build_lock?: Maybe<Ddn_Build_Lock_Mutation_Response>;
  /** insert a single row into the table: "ddn.build_lock" */
  insert_ddn_build_lock_one?: Maybe<Ddn_Build_Lock>;
  /** insert a single row into the table: "ddn.build" */
  insert_ddn_build_one?: Maybe<Ddn_Build>;
  /** insert data into the table: "ddn.build_revision" */
  insert_ddn_build_revision?: Maybe<Ddn_Build_Revision_Mutation_Response>;
  /** insert a single row into the table: "ddn.build_revision" */
  insert_ddn_build_revision_one?: Maybe<Ddn_Build_Revision>;
  /** insert data into the table: "ddn.build_revision_status" */
  insert_ddn_build_revision_status?: Maybe<
    Ddn_Build_Revision_Status_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.build_revision_status" */
  insert_ddn_build_revision_status_one?: Maybe<Ddn_Build_Revision_Status>;
  /** insert data into the table: "ddn.build_sync_status" */
  insert_ddn_build_sync_status?: Maybe<Ddn_Build_Sync_Status_Mutation_Response>;
  /** insert a single row into the table: "ddn.build_sync_status" */
  insert_ddn_build_sync_status_one?: Maybe<Ddn_Build_Sync_Status>;
  /** insert data into the table: "ddn.build_sync_worker" */
  insert_ddn_build_sync_worker?: Maybe<Ddn_Build_Sync_Worker_Mutation_Response>;
  /** insert a single row into the table: "ddn.build_sync_worker" */
  insert_ddn_build_sync_worker_one?: Maybe<Ddn_Build_Sync_Worker>;
  /** insert data into the table: "ddn.collaboration_invitee_project_view" */
  insert_ddn_collaboration_invitee_project_view?: Maybe<
    Ddn_Collaboration_Invitee_Project_View_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.collaboration_invitee_project_view" */
  insert_ddn_collaboration_invitee_project_view_one?: Maybe<
    Ddn_Collaboration_Invitee_Project_View
  >;
  /** insert data into the table: "ddn.connector_deployment" */
  insert_ddn_connector_deployment?: Maybe<
    Ddn_Connector_Deployment_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.connector_deployment" */
  insert_ddn_connector_deployment_one?: Maybe<Ddn_Connector_Deployment>;
  /** insert data into the table: "ddn.ddn" */
  insert_ddn_ddn?: Maybe<Ddn_Ddn_Mutation_Response>;
  /** insert a single row into the table: "ddn.ddn" */
  insert_ddn_ddn_one?: Maybe<Ddn_Ddn>;
  /** insert data into the table: "ddn.environment" */
  insert_ddn_environment?: Maybe<Ddn_Environment_Mutation_Response>;
  /** insert a single row into the table: "ddn.environment" */
  insert_ddn_environment_one?: Maybe<Ddn_Environment>;
  /** insert data into the table: "ddn.generate_metadata_artifacts_log" */
  insert_ddn_generate_metadata_artifacts_log?: Maybe<
    Ddn_Generate_Metadata_Artifacts_Log_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.generate_metadata_artifacts_log" */
  insert_ddn_generate_metadata_artifacts_log_one?: Maybe<
    Ddn_Generate_Metadata_Artifacts_Log
  >;
  /** insert data into the table: "ddn.invoice" */
  insert_ddn_invoice?: Maybe<Ddn_Invoice_Mutation_Response>;
  /** insert data into the table: "ddn.invoice_item" */
  insert_ddn_invoice_item?: Maybe<Ddn_Invoice_Item_Mutation_Response>;
  /** insert a single row into the table: "ddn.invoice_item" */
  insert_ddn_invoice_item_one?: Maybe<Ddn_Invoice_Item>;
  /** insert a single row into the table: "ddn.invoice" */
  insert_ddn_invoice_one?: Maybe<Ddn_Invoice>;
  /** insert data into the table: "ddn.plan_entitlement_access" */
  insert_ddn_plan_entitlement_access?: Maybe<
    Ddn_Plan_Entitlement_Access_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.plan_entitlement_access" */
  insert_ddn_plan_entitlement_access_one?: Maybe<Ddn_Plan_Entitlement_Access>;
  /** insert data into the table: "ddn.plans" */
  insert_ddn_plans?: Maybe<Ddn_Plans_Mutation_Response>;
  /** insert a single row into the table: "ddn.plans" */
  insert_ddn_plans_one?: Maybe<Ddn_Plans>;
  /** insert data into the table: "ddn.private_ddn" */
  insert_ddn_private_ddn?: Maybe<Ddn_Private_Ddn_Mutation_Response>;
  /** insert a single row into the table: "ddn.private_ddn" */
  insert_ddn_private_ddn_one?: Maybe<Ddn_Private_Ddn>;
  /** insert data into the table: "ddn.private_ddn_region" */
  insert_ddn_private_ddn_region?: Maybe<
    Ddn_Private_Ddn_Region_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.private_ddn_region" */
  insert_ddn_private_ddn_region_one?: Maybe<Ddn_Private_Ddn_Region>;
  /** insert data into the table: "ddn.project_access_level" */
  insert_ddn_project_access_level?: Maybe<
    Ddn_Project_Access_Level_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.project_access_level" */
  insert_ddn_project_access_level_one?: Maybe<Ddn_Project_Access_Level>;
  /** insert data into the table: "ddn.project_active_status_changelog" */
  insert_ddn_project_active_status_changelog?: Maybe<
    Ddn_Project_Active_Status_Changelog_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.project_active_status_changelog" */
  insert_ddn_project_active_status_changelog_one?: Maybe<
    Ddn_Project_Active_Status_Changelog
  >;
  /** insert data into the table: "ddn.project_active_status_reason" */
  insert_ddn_project_active_status_reason?: Maybe<
    Ddn_Project_Active_Status_Reason_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.project_active_status_reason" */
  insert_ddn_project_active_status_reason_one?: Maybe<
    Ddn_Project_Active_Status_Reason
  >;
  /** insert data into the table: "ddn.project_entitlement_access" */
  insert_ddn_project_entitlement_access?: Maybe<
    Ddn_Project_Entitlement_Access_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.project_entitlement_access" */
  insert_ddn_project_entitlement_access_one?: Maybe<
    Ddn_Project_Entitlement_Access
  >;
  /** insert data into the table: "ddn.project_entitlement_catalogue" */
  insert_ddn_project_entitlement_catalogue?: Maybe<
    Ddn_Project_Entitlement_Catalogue_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.project_entitlement_catalogue" */
  insert_ddn_project_entitlement_catalogue_one?: Maybe<
    Ddn_Project_Entitlement_Catalogue
  >;
  /** insert data into the table: "ddn.project_entitlement_types" */
  insert_ddn_project_entitlement_types?: Maybe<
    Ddn_Project_Entitlement_Types_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.project_entitlement_types" */
  insert_ddn_project_entitlement_types_one?: Maybe<
    Ddn_Project_Entitlement_Types
  >;
  /** insert data into the table: "ddn.project_plan_changelog" */
  insert_ddn_project_plan_changelog?: Maybe<
    Ddn_Project_Plan_Changelog_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.project_plan_changelog" */
  insert_ddn_project_plan_changelog_one?: Maybe<Ddn_Project_Plan_Changelog>;
  /** insert data into the table: "ddn.project_request_access" */
  insert_ddn_project_request_access?: Maybe<
    Ddn_Project_Request_Access_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.project_request_access" */
  insert_ddn_project_request_access_one?: Maybe<Ddn_Project_Request_Access>;
  /** insert data into the table: "ddn.projects" */
  insert_ddn_projects?: Maybe<Ddn_Projects_Mutation_Response>;
  /** insert a single row into the table: "ddn.projects" */
  insert_ddn_projects_one?: Maybe<Ddn_Projects>;
  /** insert data into the table: "ddn.secret_key" */
  insert_ddn_secret_key?: Maybe<Ddn_Secret_Key_Mutation_Response>;
  /** insert a single row into the table: "ddn.secret_key" */
  insert_ddn_secret_key_one?: Maybe<Ddn_Secret_Key>;
  /** insert data into the table: "ddn.stripe_subscription" */
  insert_ddn_stripe_subscription?: Maybe<
    Ddn_Stripe_Subscription_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.stripe_subscription" */
  insert_ddn_stripe_subscription_one?: Maybe<Ddn_Stripe_Subscription>;
  /** insert data into the table: "ddn.stripe_webhook_events" */
  insert_ddn_stripe_webhook_events?: Maybe<
    Ddn_Stripe_Webhook_Events_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.stripe_webhook_events" */
  insert_ddn_stripe_webhook_events_one?: Maybe<Ddn_Stripe_Webhook_Events>;
  /** insert data into the table: "ddn.subgraph" */
  insert_ddn_subgraph?: Maybe<Ddn_Subgraph_Mutation_Response>;
  /** insert data into the table: "ddn.subgraph_build" */
  insert_ddn_subgraph_build?: Maybe<Ddn_Subgraph_Build_Mutation_Response>;
  /** insert a single row into the table: "ddn.subgraph_build" */
  insert_ddn_subgraph_build_one?: Maybe<Ddn_Subgraph_Build>;
  /** insert a single row into the table: "ddn.subgraph" */
  insert_ddn_subgraph_one?: Maybe<Ddn_Subgraph>;
  /** insert data into the table: "ddn.team" */
  insert_ddn_team?: Maybe<Ddn_Team_Mutation_Response>;
  /** insert data into the table: "ddn.team_invitation" */
  insert_ddn_team_invitation?: Maybe<Ddn_Team_Invitation_Mutation_Response>;
  /** insert a single row into the table: "ddn.team_invitation" */
  insert_ddn_team_invitation_one?: Maybe<Ddn_Team_Invitation>;
  /** insert data into the table: "ddn.team_invitation_status" */
  insert_ddn_team_invitation_status?: Maybe<
    Ddn_Team_Invitation_Status_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.team_invitation_status" */
  insert_ddn_team_invitation_status_one?: Maybe<Ddn_Team_Invitation_Status>;
  /** insert data into the table: "ddn.team_kind" */
  insert_ddn_team_kind?: Maybe<Ddn_Team_Kind_Mutation_Response>;
  /** insert a single row into the table: "ddn.team_kind" */
  insert_ddn_team_kind_one?: Maybe<Ddn_Team_Kind>;
  /** insert data into the table: "ddn.team_membership" */
  insert_ddn_team_membership?: Maybe<Ddn_Team_Membership_Mutation_Response>;
  /** insert a single row into the table: "ddn.team_membership" */
  insert_ddn_team_membership_one?: Maybe<Ddn_Team_Membership>;
  /** insert a single row into the table: "ddn.team" */
  insert_ddn_team_one?: Maybe<Ddn_Team>;
  /** insert data into the table: "ddn.team_project_access" */
  insert_ddn_team_project_access?: Maybe<
    Ddn_Team_Project_Access_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.team_project_access" */
  insert_ddn_team_project_access_one?: Maybe<Ddn_Team_Project_Access>;
  /** insert data into the table: "ddn.tunnel" */
  insert_ddn_tunnel?: Maybe<Ddn_Tunnel_Mutation_Response>;
  /** insert data into the table: "ddn.tunnel_cluster" */
  insert_ddn_tunnel_cluster?: Maybe<Ddn_Tunnel_Cluster_Mutation_Response>;
  /** insert a single row into the table: "ddn.tunnel_cluster" */
  insert_ddn_tunnel_cluster_one?: Maybe<Ddn_Tunnel_Cluster>;
  /** insert data into the table: "ddn.tunnel_http" */
  insert_ddn_tunnel_http?: Maybe<Ddn_Tunnel_Http_Mutation_Response>;
  /** insert data into the table: "ddn.tunnel_http_cluster" */
  insert_ddn_tunnel_http_cluster?: Maybe<
    Ddn_Tunnel_Http_Cluster_Mutation_Response
  >;
  /** insert a single row into the table: "ddn.tunnel_http_cluster" */
  insert_ddn_tunnel_http_cluster_one?: Maybe<Ddn_Tunnel_Http_Cluster>;
  /** insert a single row into the table: "ddn.tunnel_http" */
  insert_ddn_tunnel_http_one?: Maybe<Ddn_Tunnel_Http>;
  /** insert a single row into the table: "ddn.tunnel" */
  insert_ddn_tunnel_one?: Maybe<Ddn_Tunnel>;
  /** insert data into the table: "dedicated_cloud_bills" */
  insert_dedicated_cloud_bills?: Maybe<Dedicated_Cloud_Bills_Mutation_Response>;
  /** insert data into the table: "dedicated_cloud_bills_details" */
  insert_dedicated_cloud_bills_details?: Maybe<
    Dedicated_Cloud_Bills_Details_Mutation_Response
  >;
  /** insert a single row into the table: "dedicated_cloud_bills_details" */
  insert_dedicated_cloud_bills_details_one?: Maybe<
    Dedicated_Cloud_Bills_Details
  >;
  /** insert a single row into the table: "dedicated_cloud_bills" */
  insert_dedicated_cloud_bills_one?: Maybe<Dedicated_Cloud_Bills>;
  /** insert data into the table: "dedicated_cloud_commitments" */
  insert_dedicated_cloud_commitments?: Maybe<
    Dedicated_Cloud_Commitments_Mutation_Response
  >;
  /** insert a single row into the table: "dedicated_cloud_commitments" */
  insert_dedicated_cloud_commitments_one?: Maybe<Dedicated_Cloud_Commitments>;
  /** insert data into the table: "dedicated_vpc" */
  insert_dedicated_vpc?: Maybe<Dedicated_Vpc_Mutation_Response>;
  /** insert a single row into the table: "dedicated_vpc" */
  insert_dedicated_vpc_one?: Maybe<Dedicated_Vpc>;
  /** insert data into the table: "delete_user" */
  insert_delete_user?: Maybe<Delete_User_Mutation_Response>;
  /** insert a single row into the table: "delete_user" */
  insert_delete_user_one?: Maybe<Delete_User>;
  /** insert data into the table: "delete_user_status" */
  insert_delete_user_status?: Maybe<Delete_User_Status_Mutation_Response>;
  /** insert a single row into the table: "delete_user_status" */
  insert_delete_user_status_one?: Maybe<Delete_User_Status>;
  /** insert data into the table: "delete_user_tasks" */
  insert_delete_user_tasks?: Maybe<Delete_User_Tasks_Mutation_Response>;
  /** insert a single row into the table: "delete_user_tasks" */
  insert_delete_user_tasks_one?: Maybe<Delete_User_Tasks>;
  /** insert data into the table: "email_log" */
  insert_email_log?: Maybe<Email_Log_Mutation_Response>;
  /** insert a single row into the table: "email_log" */
  insert_email_log_one?: Maybe<Email_Log>;
  /** insert data into the table: "enterprise_users" */
  insert_enterprise_users?: Maybe<Enterprise_Users_Mutation_Response>;
  /** insert data into the table: "enterprise_users_domain" */
  insert_enterprise_users_domain?: Maybe<
    Enterprise_Users_Domain_Mutation_Response
  >;
  /** insert a single row into the table: "enterprise_users_domain" */
  insert_enterprise_users_domain_one?: Maybe<Enterprise_Users_Domain>;
  /** insert a single row into the table: "enterprise_users" */
  insert_enterprise_users_one?: Maybe<Enterprise_Users>;
  /** insert data into the table: "experiments" */
  insert_experiments?: Maybe<Experiments_Mutation_Response>;
  /** insert data into the table: "experiments_cohort" */
  insert_experiments_cohort?: Maybe<Experiments_Cohort_Mutation_Response>;
  /** insert a single row into the table: "experiments_cohort" */
  insert_experiments_cohort_one?: Maybe<Experiments_Cohort>;
  /** insert data into the table: "experiments_config" */
  insert_experiments_config?: Maybe<Experiments_Config_Mutation_Response>;
  /** insert a single row into the table: "experiments_config" */
  insert_experiments_config_one?: Maybe<Experiments_Config>;
  /** insert a single row into the table: "experiments" */
  insert_experiments_one?: Maybe<Experiments>;
  /** insert data into the table: "feature" */
  insert_feature?: Maybe<Feature_Mutation_Response>;
  /** insert data into the table: "feature_access" */
  insert_feature_access?: Maybe<Feature_Access_Mutation_Response>;
  /** insert a single row into the table: "feature_access" */
  insert_feature_access_one?: Maybe<Feature_Access>;
  /** insert data into the table: "feature_config" */
  insert_feature_config?: Maybe<Feature_Config_Mutation_Response>;
  /** insert a single row into the table: "feature_config" */
  insert_feature_config_one?: Maybe<Feature_Config>;
  /** insert a single row into the table: "feature" */
  insert_feature_one?: Maybe<Feature>;
  /** insert data into the table: "gateway_ami" */
  insert_gateway_ami?: Maybe<Gateway_Ami_Mutation_Response>;
  /** insert a single row into the table: "gateway_ami" */
  insert_gateway_ami_one?: Maybe<Gateway_Ami>;
  /** insert data into the table: "gateway_cluster" */
  insert_gateway_cluster?: Maybe<Gateway_Cluster_Mutation_Response>;
  /** insert a single row into the table: "gateway_cluster" */
  insert_gateway_cluster_one?: Maybe<Gateway_Cluster>;
  /** insert data into the table: "gateway_worker" */
  insert_gateway_worker?: Maybe<Gateway_Worker_Mutation_Response>;
  /** insert a single row into the table: "gateway_worker" */
  insert_gateway_worker_one?: Maybe<Gateway_Worker>;
  /** insert data into the table: "github_email_type" */
  insert_github_email_type?: Maybe<Github_Email_Type_Mutation_Response>;
  /** insert a single row into the table: "github_email_type" */
  insert_github_email_type_one?: Maybe<Github_Email_Type>;
  /** insert data into the table: "github_integration_config" */
  insert_github_integration_config?: Maybe<
    Github_Integration_Config_Mutation_Response
  >;
  /** insert a single row into the table: "github_integration_config" */
  insert_github_integration_config_one?: Maybe<Github_Integration_Config>;
  /** insert data into the table: "github_integration_mode" */
  insert_github_integration_mode?: Maybe<
    Github_Integration_Mode_Mutation_Response
  >;
  /** insert a single row into the table: "github_integration_mode" */
  insert_github_integration_mode_one?: Maybe<Github_Integration_Mode>;
  /** insert data into the table: "github_push_event" */
  insert_github_push_event?: Maybe<Github_Push_Event_Mutation_Response>;
  /** insert data into the table: "github_push_event_job" */
  insert_github_push_event_job?: Maybe<Github_Push_Event_Job_Mutation_Response>;
  /** insert a single row into the table: "github_push_event_job" */
  insert_github_push_event_job_one?: Maybe<Github_Push_Event_Job>;
  /** insert a single row into the table: "github_push_event" */
  insert_github_push_event_one?: Maybe<Github_Push_Event>;
  /** insert data into the table: "hasura_ami" */
  insert_hasura_ami?: Maybe<Hasura_Ami_Mutation_Response>;
  /** insert a single row into the table: "hasura_ami" */
  insert_hasura_ami_one?: Maybe<Hasura_Ami>;
  /** insert data into the table: "hasura_cluster" */
  insert_hasura_cluster?: Maybe<Hasura_Cluster_Mutation_Response>;
  /** insert a single row into the table: "hasura_cluster" */
  insert_hasura_cluster_one?: Maybe<Hasura_Cluster>;
  /** insert data into the table: "hasura_worker" */
  insert_hasura_worker?: Maybe<Hasura_Worker_Mutation_Response>;
  /** insert a single row into the table: "hasura_worker" */
  insert_hasura_worker_one?: Maybe<Hasura_Worker>;
  /** insert data into the table: "hasura_year_in_report_activity" */
  insert_hasura_year_in_report_activity?: Maybe<
    Hasura_Year_In_Report_Activity_Mutation_Response
  >;
  /** insert a single row into the table: "hasura_year_in_report_activity" */
  insert_hasura_year_in_report_activity_one?: Maybe<
    Hasura_Year_In_Report_Activity
  >;
  /** insert data into the table: "hasura_year_in_report_metrics" */
  insert_hasura_year_in_report_metrics?: Maybe<
    Hasura_Year_In_Report_Metrics_Mutation_Response
  >;
  /** insert a single row into the table: "hasura_year_in_report_metrics" */
  insert_hasura_year_in_report_metrics_one?: Maybe<
    Hasura_Year_In_Report_Metrics
  >;
  /** insert data into the table: "heroku_integrations" */
  insert_heroku_integrations?: Maybe<Heroku_Integrations_Mutation_Response>;
  /** insert a single row into the table: "heroku_integrations" */
  insert_heroku_integrations_one?: Maybe<Heroku_Integrations>;
  /** insert data into the table: "inactive_project_exclusions" */
  insert_inactive_project_exclusions?: Maybe<
    Inactive_Project_Exclusions_Mutation_Response
  >;
  /** insert a single row into the table: "inactive_project_exclusions" */
  insert_inactive_project_exclusions_one?: Maybe<Inactive_Project_Exclusions>;
  /** insert data into the table: "inactive_project_notifications" */
  insert_inactive_project_notifications?: Maybe<
    Inactive_Project_Notifications_Mutation_Response
  >;
  /** insert a single row into the table: "inactive_project_notifications" */
  insert_inactive_project_notifications_one?: Maybe<
    Inactive_Project_Notifications
  >;
  /** insert data into the table: "inactive_project_suspension_config" */
  insert_inactive_project_suspension_config?: Maybe<
    Inactive_Project_Suspension_Config_Mutation_Response
  >;
  /** insert a single row into the table: "inactive_project_suspension_config" */
  insert_inactive_project_suspension_config_one?: Maybe<
    Inactive_Project_Suspension_Config
  >;
  /** insert data into the table: "infra_status" */
  insert_infra_status?: Maybe<Infra_Status_Mutation_Response>;
  /** insert a single row into the table: "infra_status" */
  insert_infra_status_one?: Maybe<Infra_Status>;
  /** insert data into the table: "invoice" */
  insert_invoice?: Maybe<Invoice_Mutation_Response>;
  /** insert data into the table: "invoice_coupon_discount" */
  insert_invoice_coupon_discount?: Maybe<
    Invoice_Coupon_Discount_Mutation_Response
  >;
  /** insert a single row into the table: "invoice_coupon_discount" */
  insert_invoice_coupon_discount_one?: Maybe<Invoice_Coupon_Discount>;
  /** insert data into the table: "invoice_item" */
  insert_invoice_item?: Maybe<Invoice_Item_Mutation_Response>;
  /** insert a single row into the table: "invoice_item" */
  insert_invoice_item_one?: Maybe<Invoice_Item>;
  /** insert a single row into the table: "invoice" */
  insert_invoice_one?: Maybe<Invoice>;
  /** insert data into the table: "jobs" */
  insert_jobs?: Maybe<Jobs_Mutation_Response>;
  /** insert a single row into the table: "jobs" */
  insert_jobs_one?: Maybe<Jobs>;
  /** insert data into the table: "label" */
  insert_label?: Maybe<Label_Mutation_Response>;
  /** insert a single row into the table: "label" */
  insert_label_one?: Maybe<Label>;
  /** insert data into the table: "letsencrypt_status" */
  insert_letsencrypt_status?: Maybe<Letsencrypt_Status_Mutation_Response>;
  /** insert a single row into the table: "letsencrypt_status" */
  insert_letsencrypt_status_one?: Maybe<Letsencrypt_Status>;
  /** insert data into the table: "license_activity" */
  insert_license_activity?: Maybe<License_Activity_Mutation_Response>;
  /** insert a single row into the table: "license_activity" */
  insert_license_activity_one?: Maybe<License_Activity>;
  /** insert data into the table: "license_instance" */
  insert_license_instance?: Maybe<License_Instance_Mutation_Response>;
  /** insert a single row into the table: "license_instance" */
  insert_license_instance_one?: Maybe<License_Instance>;
  /** insert data into the table: "license_type" */
  insert_license_type?: Maybe<License_Type_Mutation_Response>;
  /** insert a single row into the table: "license_type" */
  insert_license_type_one?: Maybe<License_Type>;
  /** insert data into the table: "licenses" */
  insert_licenses?: Maybe<Licenses_Mutation_Response>;
  /** insert a single row into the table: "licenses" */
  insert_licenses_one?: Maybe<Licenses>;
  /** insert data into the table: "lux_proxy" */
  insert_lux_proxy?: Maybe<Lux_Proxy_Mutation_Response>;
  /** insert data into the table: "lux_proxy_ami" */
  insert_lux_proxy_ami?: Maybe<Lux_Proxy_Ami_Mutation_Response>;
  /** insert a single row into the table: "lux_proxy_ami" */
  insert_lux_proxy_ami_one?: Maybe<Lux_Proxy_Ami>;
  /** insert a single row into the table: "lux_proxy" */
  insert_lux_proxy_one?: Maybe<Lux_Proxy>;
  /** insert data into the table: "model_based_enterprise_cloud_commitments" */
  insert_model_based_enterprise_cloud_commitments?: Maybe<
    Model_Based_Enterprise_Cloud_Commitments_Mutation_Response
  >;
  /** insert a single row into the table: "model_based_enterprise_cloud_commitments" */
  insert_model_based_enterprise_cloud_commitments_one?: Maybe<
    Model_Based_Enterprise_Cloud_Commitments
  >;
  /** insert data into the table: "neon_db_integration" */
  insert_neon_db_integration?: Maybe<Neon_Db_Integration_Mutation_Response>;
  /** insert a single row into the table: "neon_db_integration" */
  insert_neon_db_integration_one?: Maybe<Neon_Db_Integration>;
  /** insert data into the table: "newrelic_config" */
  insert_newrelic_config?: Maybe<Newrelic_Config_Mutation_Response>;
  /** insert a single row into the table: "newrelic_config" */
  insert_newrelic_config_one?: Maybe<Newrelic_Config>;
  /** insert data into the table: "node_pool_type" */
  insert_node_pool_type?: Maybe<Node_Pool_Type_Mutation_Response>;
  /** insert a single row into the table: "node_pool_type" */
  insert_node_pool_type_one?: Maybe<Node_Pool_Type>;
  /** insert data into the table: "node_pools" */
  insert_node_pools?: Maybe<Node_Pools_Mutation_Response>;
  /** insert a single row into the table: "node_pools" */
  insert_node_pools_one?: Maybe<Node_Pools>;
  /** insert data into the table: "notification" */
  insert_notification?: Maybe<Notification_Mutation_Response>;
  /** insert a single row into the table: "notification" */
  insert_notification_one?: Maybe<Notification>;
  /** insert data into the table: "notification_type" */
  insert_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** insert a single row into the table: "notification_type" */
  insert_notification_type_one?: Maybe<Notification_Type>;
  /** insert data into the table: "onboarding_sample_db_cohort" */
  insert_onboarding_sample_db_cohort?: Maybe<
    Onboarding_Sample_Db_Cohort_Mutation_Response
  >;
  /** insert a single row into the table: "onboarding_sample_db_cohort" */
  insert_onboarding_sample_db_cohort_one?: Maybe<Onboarding_Sample_Db_Cohort>;
  /** insert data into the table: "onboarding_sample_db_config" */
  insert_onboarding_sample_db_config?: Maybe<
    Onboarding_Sample_Db_Config_Mutation_Response
  >;
  /** insert a single row into the table: "onboarding_sample_db_config" */
  insert_onboarding_sample_db_config_one?: Maybe<Onboarding_Sample_Db_Config>;
  /** insert data into the table: "one_click_deployment" */
  insert_one_click_deployment?: Maybe<One_Click_Deployment_Mutation_Response>;
  /** insert a single row into the table: "one_click_deployment" */
  insert_one_click_deployment_one?: Maybe<One_Click_Deployment>;
  /** insert data into the table: "one_click_deployment_sample_apps" */
  insert_one_click_deployment_sample_apps?: Maybe<
    One_Click_Deployment_Sample_Apps_Mutation_Response
  >;
  /** insert a single row into the table: "one_click_deployment_sample_apps" */
  insert_one_click_deployment_sample_apps_one?: Maybe<
    One_Click_Deployment_Sample_Apps
  >;
  /** insert data into the table: "one_click_deployment_state_log" */
  insert_one_click_deployment_state_log?: Maybe<
    One_Click_Deployment_State_Log_Mutation_Response
  >;
  /** insert a single row into the table: "one_click_deployment_state_log" */
  insert_one_click_deployment_state_log_one?: Maybe<
    One_Click_Deployment_State_Log
  >;
  /** insert data into the table: "one_click_deployment_states" */
  insert_one_click_deployment_states?: Maybe<
    One_Click_Deployment_States_Mutation_Response
  >;
  /** insert a single row into the table: "one_click_deployment_states" */
  insert_one_click_deployment_states_one?: Maybe<One_Click_Deployment_States>;
  /** insert data into the table: "opentelemetry_config" */
  insert_opentelemetry_config?: Maybe<Opentelemetry_Config_Mutation_Response>;
  /** insert a single row into the table: "opentelemetry_config" */
  insert_opentelemetry_config_one?: Maybe<Opentelemetry_Config>;
  /** insert data into the table: "operations.delete_free_plan_dns_records_nov_2021" */
  insert_operations_delete_free_plan_dns_records_nov_2021?: Maybe<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Mutation_Response
  >;
  /** insert a single row into the table: "operations.delete_free_plan_dns_records_nov_2021" */
  insert_operations_delete_free_plan_dns_records_nov_2021_one?: Maybe<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021
  >;
  /** insert data into the table: "operations.tenant" */
  insert_operations_tenant?: Maybe<Operations_Tenant_Mutation_Response>;
  /** insert a single row into the table: "operations.tenant" */
  insert_operations_tenant_one?: Maybe<Operations_Tenant>;
  /** insert data into the table: "payment_method" */
  insert_payment_method?: Maybe<Payment_Method_Mutation_Response>;
  /** insert a single row into the table: "payment_method" */
  insert_payment_method_one?: Maybe<Payment_Method>;
  /** insert data into the table: "plan_entitlements" */
  insert_plan_entitlements?: Maybe<Plan_Entitlements_Mutation_Response>;
  /** insert a single row into the table: "plan_entitlements" */
  insert_plan_entitlements_one?: Maybe<Plan_Entitlements>;
  /** insert data into the table: "plans" */
  insert_plans?: Maybe<Plans_Mutation_Response>;
  /** insert a single row into the table: "plans" */
  insert_plans_one?: Maybe<Plans>;
  /** insert data into the table: "policies" */
  insert_policies?: Maybe<Policies_Mutation_Response>;
  /** insert a single row into the table: "policies" */
  insert_policies_one?: Maybe<Policies>;
  /** insert data into the table: "privileges" */
  insert_privileges?: Maybe<Privileges_Mutation_Response>;
  /** insert a single row into the table: "privileges" */
  insert_privileges_one?: Maybe<Privileges>;
  /** insert data into the table: "pro_license_keys" */
  insert_pro_license_keys?: Maybe<Pro_License_Keys_Mutation_Response>;
  /** insert a single row into the table: "pro_license_keys" */
  insert_pro_license_keys_one?: Maybe<Pro_License_Keys>;
  /** insert data into the table: "project_activity" */
  insert_project_activity?: Maybe<Project_Activity_Mutation_Response>;
  /** insert a single row into the table: "project_activity" */
  insert_project_activity_one?: Maybe<Project_Activity>;
  /** insert data into the table: "project_billing_manager_invitations" */
  insert_project_billing_manager_invitations?: Maybe<
    Project_Billing_Manager_Invitations_Mutation_Response
  >;
  /** insert a single row into the table: "project_billing_manager_invitations" */
  insert_project_billing_manager_invitations_one?: Maybe<
    Project_Billing_Manager_Invitations
  >;
  /** insert data into the table: "project_collaborator_allowed_schema_tables" */
  insert_project_collaborator_allowed_schema_tables?: Maybe<
    Project_Collaborator_Allowed_Schema_Tables_Mutation_Response
  >;
  /** insert a single row into the table: "project_collaborator_allowed_schema_tables" */
  insert_project_collaborator_allowed_schema_tables_one?: Maybe<
    Project_Collaborator_Allowed_Schema_Tables
  >;
  /** insert data into the table: "project_collaborator_allowed_schemas" */
  insert_project_collaborator_allowed_schemas?: Maybe<
    Project_Collaborator_Allowed_Schemas_Mutation_Response
  >;
  /** insert a single row into the table: "project_collaborator_allowed_schemas" */
  insert_project_collaborator_allowed_schemas_one?: Maybe<
    Project_Collaborator_Allowed_Schemas
  >;
  /** insert data into the table: "project_collaborator_invitations" */
  insert_project_collaborator_invitations?: Maybe<
    Project_Collaborator_Invitations_Mutation_Response
  >;
  /** insert a single row into the table: "project_collaborator_invitations" */
  insert_project_collaborator_invitations_one?: Maybe<
    Project_Collaborator_Invitations
  >;
  /** insert data into the table: "project_collaborator_privileges" */
  insert_project_collaborator_privileges?: Maybe<
    Project_Collaborator_Privileges_Mutation_Response
  >;
  /** insert a single row into the table: "project_collaborator_privileges" */
  insert_project_collaborator_privileges_one?: Maybe<
    Project_Collaborator_Privileges
  >;
  /** insert data into the table: "project_collaborators" */
  insert_project_collaborators?: Maybe<Project_Collaborators_Mutation_Response>;
  /** insert a single row into the table: "project_collaborators" */
  insert_project_collaborators_one?: Maybe<Project_Collaborators>;
  /** insert data into the table: "project_data_usage" */
  insert_project_data_usage?: Maybe<Project_Data_Usage_Mutation_Response>;
  /** insert data into the table: "project_data_usage_agg" */
  insert_project_data_usage_agg?: Maybe<
    Project_Data_Usage_Agg_Mutation_Response
  >;
  /** insert a single row into the table: "project_data_usage_agg" */
  insert_project_data_usage_agg_one?: Maybe<Project_Data_Usage_Agg>;
  /** insert data into the table: "project_data_usage_agg_user" */
  insert_project_data_usage_agg_user?: Maybe<
    Project_Data_Usage_Agg_User_Mutation_Response
  >;
  /** insert a single row into the table: "project_data_usage_agg_user" */
  insert_project_data_usage_agg_user_one?: Maybe<Project_Data_Usage_Agg_User>;
  /** insert data into the table: "project_data_usage_components" */
  insert_project_data_usage_components?: Maybe<
    Project_Data_Usage_Components_Mutation_Response
  >;
  /** insert a single row into the table: "project_data_usage_components" */
  insert_project_data_usage_components_one?: Maybe<
    Project_Data_Usage_Components
  >;
  /** insert a single row into the table: "project_data_usage" */
  insert_project_data_usage_one?: Maybe<Project_Data_Usage>;
  /** insert data into the table: "project_data_usage_prometheus" */
  insert_project_data_usage_prometheus?: Maybe<
    Project_Data_Usage_Prometheus_Mutation_Response
  >;
  /** insert data into the table: "project_data_usage_prometheus_agg_user" */
  insert_project_data_usage_prometheus_agg_user?: Maybe<
    Project_Data_Usage_Prometheus_Agg_User_Mutation_Response
  >;
  /** insert a single row into the table: "project_data_usage_prometheus_agg_user" */
  insert_project_data_usage_prometheus_agg_user_one?: Maybe<
    Project_Data_Usage_Prometheus_Agg_User
  >;
  /** insert a single row into the table: "project_data_usage_prometheus" */
  insert_project_data_usage_prometheus_one?: Maybe<
    Project_Data_Usage_Prometheus
  >;
  /** insert data into the table: "project_data_usage_report" */
  insert_project_data_usage_report?: Maybe<
    Project_Data_Usage_Report_Mutation_Response
  >;
  /** insert a single row into the table: "project_data_usage_report" */
  insert_project_data_usage_report_one?: Maybe<Project_Data_Usage_Report>;
  /** insert data into the table: "project_db_usage" */
  insert_project_db_usage?: Maybe<Project_Db_Usage_Mutation_Response>;
  /** insert data into the table: "project_db_usage_agg_user" */
  insert_project_db_usage_agg_user?: Maybe<
    Project_Db_Usage_Agg_User_Mutation_Response
  >;
  /** insert a single row into the table: "project_db_usage_agg_user" */
  insert_project_db_usage_agg_user_one?: Maybe<Project_Db_Usage_Agg_User>;
  /** insert a single row into the table: "project_db_usage" */
  insert_project_db_usage_one?: Maybe<Project_Db_Usage>;
  /** insert data into the table: "project_entitlement_access" */
  insert_project_entitlement_access?: Maybe<
    Project_Entitlement_Access_Mutation_Response
  >;
  /** insert a single row into the table: "project_entitlement_access" */
  insert_project_entitlement_access_one?: Maybe<Project_Entitlement_Access>;
  /** insert data into the table: "project_entitlement_catalogue" */
  insert_project_entitlement_catalogue?: Maybe<
    Project_Entitlement_Catalogue_Mutation_Response
  >;
  /** insert a single row into the table: "project_entitlement_catalogue" */
  insert_project_entitlement_catalogue_one?: Maybe<
    Project_Entitlement_Catalogue
  >;
  /** insert data into the table: "project_entitlement_types" */
  insert_project_entitlement_types?: Maybe<
    Project_Entitlement_Types_Mutation_Response
  >;
  /** insert a single row into the table: "project_entitlement_types" */
  insert_project_entitlement_types_one?: Maybe<Project_Entitlement_Types>;
  /** insert data into the table: "project_labels" */
  insert_project_labels?: Maybe<Project_Labels_Mutation_Response>;
  /** insert a single row into the table: "project_labels" */
  insert_project_labels_one?: Maybe<Project_Labels>;
  /** insert data into the table: "project_metadata" */
  insert_project_metadata?: Maybe<Project_Metadata_Mutation_Response>;
  /** insert a single row into the table: "project_metadata" */
  insert_project_metadata_one?: Maybe<Project_Metadata>;
  /** insert data into the table: "project_notification" */
  insert_project_notification?: Maybe<Project_Notification_Mutation_Response>;
  /** insert a single row into the table: "project_notification" */
  insert_project_notification_one?: Maybe<Project_Notification>;
  /** insert data into the table: "project_ownership_transfer_invitations" */
  insert_project_ownership_transfer_invitations?: Maybe<
    Project_Ownership_Transfer_Invitations_Mutation_Response
  >;
  /** insert a single row into the table: "project_ownership_transfer_invitations" */
  insert_project_ownership_transfer_invitations_one?: Maybe<
    Project_Ownership_Transfer_Invitations
  >;
  /** insert data into the table: "project_requests_count" */
  insert_project_requests_count?: Maybe<
    Project_Requests_Count_Mutation_Response
  >;
  /** insert a single row into the table: "project_requests_count" */
  insert_project_requests_count_one?: Maybe<Project_Requests_Count>;
  /** insert data into the table: "project_stats" */
  insert_project_stats?: Maybe<Project_Stats_Mutation_Response>;
  /** insert a single row into the table: "project_stats" */
  insert_project_stats_one?: Maybe<Project_Stats>;
  /** insert data into the table: "project_total_db_usage_agg" */
  insert_project_total_db_usage_agg?: Maybe<
    Project_Total_Db_Usage_Agg_Mutation_Response
  >;
  /** insert a single row into the table: "project_total_db_usage_agg" */
  insert_project_total_db_usage_agg_one?: Maybe<Project_Total_Db_Usage_Agg>;
  /** insert data into the table: "projects" */
  insert_projects?: Maybe<Projects_Mutation_Response>;
  /** insert a single row into the table: "projects" */
  insert_projects_one?: Maybe<Projects>;
  /** insert data into the table: "projects_pool_config" */
  insert_projects_pool_config?: Maybe<Projects_Pool_Config_Mutation_Response>;
  /** insert a single row into the table: "projects_pool_config" */
  insert_projects_pool_config_one?: Maybe<Projects_Pool_Config>;
  /** insert data into the table: "projects_pro_key_generations" */
  insert_projects_pro_key_generations?: Maybe<
    Projects_Pro_Key_Generations_Mutation_Response
  >;
  /** insert a single row into the table: "projects_pro_key_generations" */
  insert_projects_pro_key_generations_one?: Maybe<Projects_Pro_Key_Generations>;
  /** insert data into the table: "prometheus_config" */
  insert_prometheus_config?: Maybe<Prometheus_Config_Mutation_Response>;
  /** insert a single row into the table: "prometheus_config" */
  insert_prometheus_config_one?: Maybe<Prometheus_Config>;
  /** insert data into the table: "providers" */
  insert_providers?: Maybe<Providers_Mutation_Response>;
  /** insert a single row into the table: "providers" */
  insert_providers_one?: Maybe<Providers>;
  /** insert data into the table: "provisioning" */
  insert_provisioning?: Maybe<Provisioning_Mutation_Response>;
  /** insert a single row into the table: "provisioning" */
  insert_provisioning_one?: Maybe<Provisioning>;
  /** insert data into the table: "region" */
  insert_region?: Maybe<Region_Mutation_Response>;
  /** insert a single row into the table: "region" */
  insert_region_one?: Maybe<Region>;
  /** insert data into the table: "region_v2" */
  insert_region_v2?: Maybe<Region_V2_Mutation_Response>;
  /** insert a single row into the table: "region_v2" */
  insert_region_v2_one?: Maybe<Region_V2>;
  /** insert data into the table: "regional_data_connectors" */
  insert_regional_data_connectors?: Maybe<
    Regional_Data_Connectors_Mutation_Response
  >;
  /** insert a single row into the table: "regional_data_connectors" */
  insert_regional_data_connectors_one?: Maybe<Regional_Data_Connectors>;
  /** insert data into the table: "regional_metrics" */
  insert_regional_metrics?: Maybe<Regional_Metrics_Mutation_Response>;
  /** insert a single row into the table: "regional_metrics" */
  insert_regional_metrics_one?: Maybe<Regional_Metrics>;
  /** insert data into the table: "saml_idp" */
  insert_saml_idp?: Maybe<Saml_Idp_Mutation_Response>;
  /** insert a single row into the table: "saml_idp" */
  insert_saml_idp_one?: Maybe<Saml_Idp>;
  /** insert data into the table: "search_project_login_status_results" */
  insert_search_project_login_status_results?: Maybe<
    Search_Project_Login_Status_Results_Mutation_Response
  >;
  /** insert a single row into the table: "search_project_login_status_results" */
  insert_search_project_login_status_results_one?: Maybe<
    Search_Project_Login_Status_Results
  >;
  /** insert data into the table: "slack_config" */
  insert_slack_config?: Maybe<Slack_Config_Mutation_Response>;
  /** insert a single row into the table: "slack_config" */
  insert_slack_config_one?: Maybe<Slack_Config>;
  /** insert data into the table: "stripe_subscription" */
  insert_stripe_subscription?: Maybe<Stripe_Subscription_Mutation_Response>;
  /** insert a single row into the table: "stripe_subscription" */
  insert_stripe_subscription_one?: Maybe<Stripe_Subscription>;
  /** insert data into the table: "stripe_webhook_events" */
  insert_stripe_webhook_events?: Maybe<Stripe_Webhook_Events_Mutation_Response>;
  /** insert a single row into the table: "stripe_webhook_events" */
  insert_stripe_webhook_events_one?: Maybe<Stripe_Webhook_Events>;
  /** insert data into the table: "super_connector_types" */
  insert_super_connector_types?: Maybe<Super_Connector_Types_Mutation_Response>;
  /** insert a single row into the table: "super_connector_types" */
  insert_super_connector_types_one?: Maybe<Super_Connector_Types>;
  /** insert data into the table: "support_plan_types" */
  insert_support_plan_types?: Maybe<Support_Plan_Types_Mutation_Response>;
  /** insert a single row into the table: "support_plan_types" */
  insert_support_plan_types_one?: Maybe<Support_Plan_Types>;
  /** insert data into the table: "survey" */
  insert_survey?: Maybe<Survey_Mutation_Response>;
  /** insert a single row into the table: "survey" */
  insert_survey_one?: Maybe<Survey>;
  /** insert data into the table: "survey_question" */
  insert_survey_question?: Maybe<Survey_Question_Mutation_Response>;
  /** insert data into the table: "survey_question_answer_option" */
  insert_survey_question_answer_option?: Maybe<
    Survey_Question_Answer_Option_Mutation_Response
  >;
  /** insert a single row into the table: "survey_question_answer_option" */
  insert_survey_question_answer_option_one?: Maybe<
    Survey_Question_Answer_Option
  >;
  /** insert data into the table: "survey_question_answers" */
  insert_survey_question_answers?: Maybe<
    Survey_Question_Answers_Mutation_Response
  >;
  /** insert a single row into the table: "survey_question_answers" */
  insert_survey_question_answers_one?: Maybe<Survey_Question_Answers>;
  /** insert data into the table: "survey_question_kind" */
  insert_survey_question_kind?: Maybe<Survey_Question_Kind_Mutation_Response>;
  /** insert a single row into the table: "survey_question_kind" */
  insert_survey_question_kind_one?: Maybe<Survey_Question_Kind>;
  /** insert a single row into the table: "survey_question" */
  insert_survey_question_one?: Maybe<Survey_Question>;
  /** insert data into the table: "survey_question_options" */
  insert_survey_question_options?: Maybe<
    Survey_Question_Options_Mutation_Response
  >;
  /** insert a single row into the table: "survey_question_options" */
  insert_survey_question_options_one?: Maybe<Survey_Question_Options>;
  /** insert data into the table: "survey_v2" */
  insert_survey_v2?: Maybe<Survey_V2_Mutation_Response>;
  /** insert a single row into the table: "survey_v2" */
  insert_survey_v2_one?: Maybe<Survey_V2>;
  /** insert data into the table: "survey_v2_question" */
  insert_survey_v2_question?: Maybe<Survey_V2_Question_Mutation_Response>;
  /** insert data into the table: "survey_v2_question_kind" */
  insert_survey_v2_question_kind?: Maybe<
    Survey_V2_Question_Kind_Mutation_Response
  >;
  /** insert a single row into the table: "survey_v2_question_kind" */
  insert_survey_v2_question_kind_one?: Maybe<Survey_V2_Question_Kind>;
  /** insert a single row into the table: "survey_v2_question" */
  insert_survey_v2_question_one?: Maybe<Survey_V2_Question>;
  /** insert data into the table: "survey_v2_question_option" */
  insert_survey_v2_question_option?: Maybe<
    Survey_V2_Question_Option_Mutation_Response
  >;
  /** insert data into the table: "survey_v2_question_option_additional_info_config" */
  insert_survey_v2_question_option_additional_info_config?: Maybe<
    Survey_V2_Question_Option_Additional_Info_Config_Mutation_Response
  >;
  /** insert a single row into the table: "survey_v2_question_option_additional_info_config" */
  insert_survey_v2_question_option_additional_info_config_one?: Maybe<
    Survey_V2_Question_Option_Additional_Info_Config
  >;
  /** insert a single row into the table: "survey_v2_question_option" */
  insert_survey_v2_question_option_one?: Maybe<Survey_V2_Question_Option>;
  /** insert data into the table: "survey_v2_response" */
  insert_survey_v2_response?: Maybe<Survey_V2_Response_Mutation_Response>;
  /** insert data into the table: "survey_v2_response_answer" */
  insert_survey_v2_response_answer?: Maybe<
    Survey_V2_Response_Answer_Mutation_Response
  >;
  /** insert a single row into the table: "survey_v2_response_answer" */
  insert_survey_v2_response_answer_one?: Maybe<Survey_V2_Response_Answer>;
  /** insert data into the table: "survey_v2_response_answer_option" */
  insert_survey_v2_response_answer_option?: Maybe<
    Survey_V2_Response_Answer_Option_Mutation_Response
  >;
  /** insert a single row into the table: "survey_v2_response_answer_option" */
  insert_survey_v2_response_answer_option_one?: Maybe<
    Survey_V2_Response_Answer_Option
  >;
  /** insert a single row into the table: "survey_v2_response" */
  insert_survey_v2_response_one?: Maybe<Survey_V2_Response>;
  /** insert data into the table: "task_event" */
  insert_task_event?: Maybe<Task_Event_Mutation_Response>;
  /** insert a single row into the table: "task_event" */
  insert_task_event_one?: Maybe<Task_Event>;
  /** insert data into the table: "tasks" */
  insert_tasks?: Maybe<Tasks_Mutation_Response>;
  /** insert a single row into the table: "tasks" */
  insert_tasks_one?: Maybe<Tasks>;
  /** insert data into the table: "tenant" */
  insert_tenant?: Maybe<Tenant_Mutation_Response>;
  /** insert data into the table: "tenant_active_status_reason" */
  insert_tenant_active_status_reason?: Maybe<
    Tenant_Active_Status_Reason_Mutation_Response
  >;
  /** insert a single row into the table: "tenant_active_status_reason" */
  insert_tenant_active_status_reason_one?: Maybe<Tenant_Active_Status_Reason>;
  /** insert data into the table: "tenant_group" */
  insert_tenant_group?: Maybe<Tenant_Group_Mutation_Response>;
  /** insert data into the table: "tenant_group_member" */
  insert_tenant_group_member?: Maybe<Tenant_Group_Member_Mutation_Response>;
  /** insert a single row into the table: "tenant_group_member" */
  insert_tenant_group_member_one?: Maybe<Tenant_Group_Member>;
  /** insert a single row into the table: "tenant_group" */
  insert_tenant_group_one?: Maybe<Tenant_Group>;
  /** insert a single row into the table: "tenant" */
  insert_tenant_one?: Maybe<Tenant>;
  /** insert data into the table: "tenant_public_status" */
  insert_tenant_public_status?: Maybe<Tenant_Public_Status_Mutation_Response>;
  /** insert a single row into the table: "tenant_public_status" */
  insert_tenant_public_status_one?: Maybe<Tenant_Public_Status>;
  /** insert data into the table: "tenant_status" */
  insert_tenant_status?: Maybe<Tenant_Status_Mutation_Response>;
  /** insert a single row into the table: "tenant_status" */
  insert_tenant_status_one?: Maybe<Tenant_Status>;
  /** insert data into the table: "trial_leads" */
  insert_trial_leads?: Maybe<Trial_Leads_Mutation_Response>;
  /** insert a single row into the table: "trial_leads" */
  insert_trial_leads_one?: Maybe<Trial_Leads>;
  /** insert data into the table: "ua_audit_logs" */
  insert_ua_audit_logs?: Maybe<Ua_Audit_Logs_Mutation_Response>;
  /** insert a single row into the table: "ua_audit_logs" */
  insert_ua_audit_logs_one?: Maybe<Ua_Audit_Logs>;
  /** insert data into the table: "user_activity" */
  insert_user_activity?: Maybe<User_Activity_Mutation_Response>;
  /** insert a single row into the table: "user_activity" */
  insert_user_activity_one?: Maybe<User_Activity>;
  /** insert data into the table: "user_coupon" */
  insert_user_coupon?: Maybe<User_Coupon_Mutation_Response>;
  /** insert a single row into the table: "user_coupon" */
  insert_user_coupon_one?: Maybe<User_Coupon>;
  /** insert data into the table: "user_entitlement_access" */
  insert_user_entitlement_access?: Maybe<
    User_Entitlement_Access_Mutation_Response
  >;
  /** insert a single row into the table: "user_entitlement_access" */
  insert_user_entitlement_access_one?: Maybe<User_Entitlement_Access>;
  /** insert data into the table: "user_entitlement_catalogue" */
  insert_user_entitlement_catalogue?: Maybe<
    User_Entitlement_Catalogue_Mutation_Response
  >;
  /** insert a single row into the table: "user_entitlement_catalogue" */
  insert_user_entitlement_catalogue_one?: Maybe<User_Entitlement_Catalogue>;
  /** insert data into the table: "user_entitlement_types" */
  insert_user_entitlement_types?: Maybe<
    User_Entitlement_Types_Mutation_Response
  >;
  /** insert a single row into the table: "user_entitlement_types" */
  insert_user_entitlement_types_one?: Maybe<User_Entitlement_Types>;
  /** insert data into the table: "user_onboarding" */
  insert_user_onboarding?: Maybe<User_Onboarding_Mutation_Response>;
  /** insert a single row into the table: "user_onboarding" */
  insert_user_onboarding_one?: Maybe<User_Onboarding>;
  /** insert data into the table: "user_personal_access_tokens" */
  insert_user_personal_access_tokens?: Maybe<
    User_Personal_Access_Tokens_Mutation_Response
  >;
  /** insert a single row into the table: "user_personal_access_tokens" */
  insert_user_personal_access_tokens_one?: Maybe<User_Personal_Access_Tokens>;
  /** insert data into the table: "user_profile" */
  insert_user_profile?: Maybe<User_Profile_Mutation_Response>;
  /** insert a single row into the table: "user_profile" */
  insert_user_profile_one?: Maybe<User_Profile>;
  /** insert data into the table: "user_roles" */
  insert_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** insert a single row into the table: "user_roles" */
  insert_user_roles_one?: Maybe<User_Roles>;
  /** insert data into the table: "user_vpc_policy" */
  insert_user_vpc_policy?: Maybe<User_Vpc_Policy_Mutation_Response>;
  /** insert a single row into the table: "user_vpc_policy" */
  insert_user_vpc_policy_one?: Maybe<User_Vpc_Policy>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "users_public" */
  insert_users_public?: Maybe<Users_Public_Mutation_Response>;
  /** insert a single row into the table: "users_public" */
  insert_users_public_one?: Maybe<Users_Public>;
  /** insert data into the table: "vercel_integration" */
  insert_vercel_integration?: Maybe<Vercel_Integration_Mutation_Response>;
  /** insert data into the table: "vercel_integration_connections" */
  insert_vercel_integration_connections?: Maybe<
    Vercel_Integration_Connections_Mutation_Response
  >;
  /** insert a single row into the table: "vercel_integration_connections" */
  insert_vercel_integration_connections_one?: Maybe<
    Vercel_Integration_Connections
  >;
  /** insert a single row into the table: "vercel_integration" */
  insert_vercel_integration_one?: Maybe<Vercel_Integration>;
  /** insert data into the table: "vpc_collaborator_invitations" */
  insert_vpc_collaborator_invitations?: Maybe<
    Vpc_Collaborator_Invitations_Mutation_Response
  >;
  /** insert a single row into the table: "vpc_collaborator_invitations" */
  insert_vpc_collaborator_invitations_one?: Maybe<Vpc_Collaborator_Invitations>;
  /** insert data into the table: "vpc_collaborators" */
  insert_vpc_collaborators?: Maybe<Vpc_Collaborators_Mutation_Response>;
  /** insert a single row into the table: "vpc_collaborators" */
  insert_vpc_collaborators_one?: Maybe<Vpc_Collaborators>;
  /** insert data into the table: "vpc_peering" */
  insert_vpc_peering?: Maybe<Vpc_Peering_Mutation_Response>;
  /** insert data into the table: "vpc_peering_direction" */
  insert_vpc_peering_direction?: Maybe<Vpc_Peering_Direction_Mutation_Response>;
  /** insert a single row into the table: "vpc_peering_direction" */
  insert_vpc_peering_direction_one?: Maybe<Vpc_Peering_Direction>;
  /** insert a single row into the table: "vpc_peering" */
  insert_vpc_peering_one?: Maybe<Vpc_Peering>;
  /** insert data into the table: "vpc_privileges" */
  insert_vpc_privileges?: Maybe<Vpc_Privileges_Mutation_Response>;
  /** insert a single row into the table: "vpc_privileges" */
  insert_vpc_privileges_one?: Maybe<Vpc_Privileges>;
  /** insert data into the table: "vpc_status" */
  insert_vpc_status?: Maybe<Vpc_Status_Mutation_Response>;
  /** insert a single row into the table: "vpc_status" */
  insert_vpc_status_one?: Maybe<Vpc_Status>;
  /** insert data into the table: "zendesk_support_category" */
  insert_zendesk_support_category?: Maybe<
    Zendesk_Support_Category_Mutation_Response
  >;
  /** insert a single row into the table: "zendesk_support_category" */
  insert_zendesk_support_category_one?: Maybe<Zendesk_Support_Category>;
  /** insert data into the table: "zendesk_support_tickets" */
  insert_zendesk_support_tickets?: Maybe<
    Zendesk_Support_Tickets_Mutation_Response
  >;
  /** insert a single row into the table: "zendesk_support_tickets" */
  insert_zendesk_support_tickets_one?: Maybe<Zendesk_Support_Tickets>;
  inviteBillingManager?: Maybe<BillingManagerInvitation>;
  inviteCollaborator?: Maybe<ProjectCollaboratorInvitation>;
  /** invite collaborators for VPC */
  inviteVPCCollaborator?: Maybe<InviteVpcCollaboratorResponse>;
  lastMonthProjectUsage: Array<ProjectUsage>;
  moveProjectRegion: MoveProjectRegionResponse;
  /** Creates a project with a database on Neon */
  neonCreateDatabase?: Maybe<NeonCreateDatabaseResponse>;
  /** Action to exchange granted code for access token */
  neonExchangeOAuthToken: NeonExchangeTokenResponse;
  /** Removes the persisted Neon session from Hasura Cloud */
  neonRemoveSession?: Maybe<SuccessOrError>;
  /** Fetches the bills accured by a project in a month for projects on the new plans */
  newPlansProjectUsage?: Maybe<NewPlansProjectOutput>;
  /** Fetches the bills accured by a user in a month for projects on the new plans, grouped by project */
  newPlansProjectUsagePerMonth: Array<NewPlansProjectUsageOutput>;
  payInvoice?: Maybe<PayInvoiceStatus>;
  /** Allows users to reactivate their suspended projects */
  reactivateSuspendedProject: ProjectReactivationResponse;
  regenerateKey: AddProjectResponse;
  removeGithubIntegration?: Maybe<DeleteGithubIntegrationStatus>;
  removeGithubSession?: Maybe<GithubIntegrationStatus>;
  removeHerokuSession: HerokuSessionStatus;
  /** remove collaborator from VPC */
  removeVPCCollaborator?: Maybe<RemoveVpcCollaboratorResponse>;
  resendBillingManagerInvite?: Maybe<BillingManagerInvitation>;
  resendChangeUserEmailVerification: ChangeUserEmailRequest;
  resendInvite: ProjectCollaboratorInvitation;
  resendTransferOwnershipInvite: ProjectOwnershipTransferInvitation;
  revokeChangeUserEmailRequest: ChangeUserEmailRequest;
  /** revoke collaborator invitation for VPC */
  revokeVPCCollaboratorInvitation?: Maybe<
    RevokeVpcCollaboratorInvitationResponse
  >;
  /** Action to save survey question's answer */
  saveSurveyAnswer?: Maybe<SuccessOrError>;
  /** Action to save survey question's answer */
  saveSurveyAnswerV2?: Maybe<SuccessOrError>;
  setDefaultPaymentMethod?: Maybe<DefaultPaymentResponse>;
  /** Action to exchange granted code for access token */
  slackExchangeOAuthToken: SlackExchangeTokenResponse;
  /** Action to track activity of experiments cohort users */
  trackExperimentsCohortActivity?: Maybe<SuccessOrError>;
  /** Action to track the onboarding activity */
  trackOnboardingActivity?: Maybe<SuccessOrError>;
  trackOnboardingSampleDbCohortActivity?: Maybe<
    TrackOnboardingSampleDbCohortActivityResponse
  >;
  /** Action to track the year in report activity */
  trackYearInReportActivity?: Maybe<SuccessOrError>;
  transferOwnershipInvite: ProjectOwnershipTransferInvitation;
  triggerAnalyticsInfraModule?: Maybe<BuildKiteBuild>;
  triggerCentralModule?: Maybe<BuildKiteBuild>;
  triggerCentralMonitoringModule?: Maybe<BuildKiteBuild>;
  triggerDataConnectorModule?: Maybe<BuildKiteBuild>;
  triggerDvpcInfraModule?: Maybe<BuildKiteBuild>;
  triggerDvpcLogsModule?: Maybe<BuildKiteBuild>;
  triggerDvpcMonitoringModule?: Maybe<BuildKiteBuild>;
  triggerDvpcOperatorModule?: Maybe<BuildKiteBuild>;
  triggerDvpcPeeringModule?: Maybe<BuildKiteBuild>;
  triggerGatewayClusterModule?: Maybe<BuildKiteBuild>;
  triggerHasuraClusterModule?: Maybe<BuildKiteBuild>;
  triggerHealthcheckModule?: Maybe<BuildKiteBuild>;
  triggerLogsModule?: Maybe<BuildKiteBuild>;
  triggerLuxProxyModule?: Maybe<BuildKiteBuild>;
  triggerMetricsModule?: Maybe<BuildKiteBuild>;
  triggerNodePoolModule?: Maybe<BuildKiteBuild>;
  /** execute one-click-deploy workflow configured for a project idempotently */
  triggerOneClickDeployment?: Maybe<OneClickDeployResponse>;
  triggerOperatorModule?: Maybe<BuildKiteBuild>;
  triggerRegionModule?: Maybe<BuildKiteBuild>;
  triggerRegionMonitoringModule?: Maybe<BuildKiteBuild>;
  triggerTaskqModule?: Maybe<BuildKiteBuild>;
  triggerTunnelClusterModule?: Maybe<BuildKiteBuild>;
  updateAzureMonitorConfig?: Maybe<AzureMonitorConfigResponse>;
  updateBillingManager?: Maybe<UpdateBillingManagerResponse>;
  updateDatadogConfig?: Maybe<DatadogConfigResponse>;
  updateGithubIntegration?: Maybe<UpdateGithubIntegrationStatus>;
  updateNewrelicConfig?: Maybe<NewrelicConfigResponse>;
  /** Update the OpenTelemetry Integration Configuration */
  updateOpentelemetryConfig?: Maybe<OpentelemetryConfigResponse>;
  updateProjectsActiveStatusReason?: Maybe<ActiveStatusReasonResponse>;
  /** Refreshes the Access token for the configured Prometheus Integration */
  updatePrometheusAccessToken: PrometheusConfigResponse;
  /** Update the Prometheus Integration Configuration */
  updatePrometheusConfig: PrometheusConfigResponse;
  updateServerEndpoint: UpdateServerEndpointResponse;
  updateTenantEnv?: Maybe<TenantEnv>;
  updateTenantName?: Maybe<UpdateTenantNameResponse>;
  /** Marks the tenant status and reason for deactivation/activation. Only available for data service admins. */
  updateTenantStatus?: Maybe<UpdateTenantStatusResponse>;
  updateVPCPlanName?: Maybe<VpcPlanNameResponse>;
  /** update data of the table: "alert_config" */
  update_alert_config?: Maybe<Alert_Config_Mutation_Response>;
  /** update data of the table: "alert_config_alert_type" */
  update_alert_config_alert_type?: Maybe<
    Alert_Config_Alert_Type_Mutation_Response
  >;
  /** update single row of the table: "alert_config_alert_type" */
  update_alert_config_alert_type_by_pk?: Maybe<Alert_Config_Alert_Type>;
  /** update multiples rows of table: "alert_config_alert_type" */
  update_alert_config_alert_type_many?: Maybe<
    Array<Maybe<Alert_Config_Alert_Type_Mutation_Response>>
  >;
  /** update single row of the table: "alert_config" */
  update_alert_config_by_pk?: Maybe<Alert_Config>;
  /** update multiples rows of table: "alert_config" */
  update_alert_config_many?: Maybe<
    Array<Maybe<Alert_Config_Mutation_Response>>
  >;
  /** update data of the table: "alert_config_service" */
  update_alert_config_service?: Maybe<Alert_Config_Service_Mutation_Response>;
  /** update single row of the table: "alert_config_service" */
  update_alert_config_service_by_pk?: Maybe<Alert_Config_Service>;
  /** update multiples rows of table: "alert_config_service" */
  update_alert_config_service_many?: Maybe<
    Array<Maybe<Alert_Config_Service_Mutation_Response>>
  >;
  /** update data of the table: "alert_service_type" */
  update_alert_service_type?: Maybe<Alert_Service_Type_Mutation_Response>;
  /** update single row of the table: "alert_service_type" */
  update_alert_service_type_by_pk?: Maybe<Alert_Service_Type>;
  /** update multiples rows of table: "alert_service_type" */
  update_alert_service_type_many?: Maybe<
    Array<Maybe<Alert_Service_Type_Mutation_Response>>
  >;
  /** update data of the table: "alert_type" */
  update_alert_type?: Maybe<Alert_Type_Mutation_Response>;
  /** update single row of the table: "alert_type" */
  update_alert_type_by_pk?: Maybe<Alert_Type>;
  /** update multiples rows of table: "alert_type" */
  update_alert_type_many?: Maybe<Array<Maybe<Alert_Type_Mutation_Response>>>;
  /** update data of the table: "azuremonitor_config" */
  update_azuremonitor_config?: Maybe<Azuremonitor_Config_Mutation_Response>;
  /** update single row of the table: "azuremonitor_config" */
  update_azuremonitor_config_by_pk?: Maybe<Azuremonitor_Config>;
  /** update multiples rows of table: "azuremonitor_config" */
  update_azuremonitor_config_many?: Maybe<
    Array<Maybe<Azuremonitor_Config_Mutation_Response>>
  >;
  /** update data of the table: "billing_address" */
  update_billing_address?: Maybe<Billing_Address_Mutation_Response>;
  /** update single row of the table: "billing_address" */
  update_billing_address_by_pk?: Maybe<Billing_Address>;
  /** update multiples rows of table: "billing_address" */
  update_billing_address_many?: Maybe<
    Array<Maybe<Billing_Address_Mutation_Response>>
  >;
  /** update data of the table: "central_cloud" */
  update_central_cloud?: Maybe<Central_Cloud_Mutation_Response>;
  /** update single row of the table: "central_cloud" */
  update_central_cloud_by_pk?: Maybe<Central_Cloud>;
  /** update multiples rows of table: "central_cloud" */
  update_central_cloud_many?: Maybe<
    Array<Maybe<Central_Cloud_Mutation_Response>>
  >;
  /** update data of the table: "change_email_request" */
  update_change_email_request?: Maybe<Change_Email_Request_Mutation_Response>;
  /** update single row of the table: "change_email_request" */
  update_change_email_request_by_pk?: Maybe<Change_Email_Request>;
  /** update multiples rows of table: "change_email_request" */
  update_change_email_request_many?: Maybe<
    Array<Maybe<Change_Email_Request_Mutation_Response>>
  >;
  /** update data of the table: "cicd.deployment_pipeline_config" */
  update_cicd_deployment_pipeline_config?: Maybe<
    Cicd_Deployment_Pipeline_Config_Mutation_Response
  >;
  /** update single row of the table: "cicd.deployment_pipeline_config" */
  update_cicd_deployment_pipeline_config_by_pk?: Maybe<
    Cicd_Deployment_Pipeline_Config
  >;
  /** update multiples rows of table: "cicd.deployment_pipeline_config" */
  update_cicd_deployment_pipeline_config_many?: Maybe<
    Array<Maybe<Cicd_Deployment_Pipeline_Config_Mutation_Response>>
  >;
  /** update data of the table: "cicd.deployment_pool" */
  update_cicd_deployment_pool?: Maybe<Cicd_Deployment_Pool_Mutation_Response>;
  /** update multiples rows of table: "cicd.deployment_pool" */
  update_cicd_deployment_pool_many?: Maybe<
    Array<Maybe<Cicd_Deployment_Pool_Mutation_Response>>
  >;
  /** update data of the table: "cicd.deployment_pool_member" */
  update_cicd_deployment_pool_member?: Maybe<
    Cicd_Deployment_Pool_Member_Mutation_Response
  >;
  /** update multiples rows of table: "cicd.deployment_pool_member" */
  update_cicd_deployment_pool_member_many?: Maybe<
    Array<Maybe<Cicd_Deployment_Pool_Member_Mutation_Response>>
  >;
  /** update data of the table: "cloud" */
  update_cloud?: Maybe<Cloud_Mutation_Response>;
  /** update single row of the table: "cloud" */
  update_cloud_by_pk?: Maybe<Cloud>;
  /** update multiples rows of table: "cloud" */
  update_cloud_many?: Maybe<Array<Maybe<Cloud_Mutation_Response>>>;
  /** update data of the table: "cloud_metadata" */
  update_cloud_metadata?: Maybe<Cloud_Metadata_Mutation_Response>;
  /** update single row of the table: "cloud_metadata" */
  update_cloud_metadata_by_pk?: Maybe<Cloud_Metadata>;
  /** update multiples rows of table: "cloud_metadata" */
  update_cloud_metadata_many?: Maybe<
    Array<Maybe<Cloud_Metadata_Mutation_Response>>
  >;
  /** update data of the table: "compute_unit_config" */
  update_compute_unit_config?: Maybe<Compute_Unit_Config_Mutation_Response>;
  /** update single row of the table: "compute_unit_config" */
  update_compute_unit_config_by_pk?: Maybe<Compute_Unit_Config>;
  /** update multiples rows of table: "compute_unit_config" */
  update_compute_unit_config_many?: Maybe<
    Array<Maybe<Compute_Unit_Config_Mutation_Response>>
  >;
  /** update data of the table: "config" */
  update_config?: Maybe<Config_Mutation_Response>;
  /** update single row of the table: "config" */
  update_config_by_pk?: Maybe<Config>;
  /** update multiples rows of table: "config" */
  update_config_many?: Maybe<Array<Maybe<Config_Mutation_Response>>>;
  /** update data of the table: "config_status" */
  update_config_status?: Maybe<Config_Status_Mutation_Response>;
  /** update single row of the table: "config_status" */
  update_config_status_by_pk?: Maybe<Config_Status>;
  /** update multiples rows of table: "config_status" */
  update_config_status_many?: Maybe<
    Array<Maybe<Config_Status_Mutation_Response>>
  >;
  /** update data of the table: "connector_config" */
  update_connector_config?: Maybe<Connector_Config_Mutation_Response>;
  /** update single row of the table: "connector_config" */
  update_connector_config_by_pk?: Maybe<Connector_Config>;
  /** update multiples rows of table: "connector_config" */
  update_connector_config_many?: Maybe<
    Array<Maybe<Connector_Config_Mutation_Response>>
  >;
  /** update data of the table: "connector_deployments" */
  update_connector_deployments?: Maybe<Connector_Deployments_Mutation_Response>;
  /** update single row of the table: "connector_deployments" */
  update_connector_deployments_by_pk?: Maybe<Connector_Deployments>;
  /** update multiples rows of table: "connector_deployments" */
  update_connector_deployments_many?: Maybe<
    Array<Maybe<Connector_Deployments_Mutation_Response>>
  >;
  /** update data of the table: "coupon" */
  update_coupon?: Maybe<Coupon_Mutation_Response>;
  /** update single row of the table: "coupon" */
  update_coupon_by_pk?: Maybe<Coupon>;
  /** update data of the table: "coupon_duration" */
  update_coupon_duration?: Maybe<Coupon_Duration_Mutation_Response>;
  /** update single row of the table: "coupon_duration" */
  update_coupon_duration_by_pk?: Maybe<Coupon_Duration>;
  /** update multiples rows of table: "coupon_duration" */
  update_coupon_duration_many?: Maybe<
    Array<Maybe<Coupon_Duration_Mutation_Response>>
  >;
  /** update multiples rows of table: "coupon" */
  update_coupon_many?: Maybe<Array<Maybe<Coupon_Mutation_Response>>>;
  /** update data of the table: "coupon_type" */
  update_coupon_type?: Maybe<Coupon_Type_Mutation_Response>;
  /** update single row of the table: "coupon_type" */
  update_coupon_type_by_pk?: Maybe<Coupon_Type>;
  /** update multiples rows of table: "coupon_type" */
  update_coupon_type_many?: Maybe<Array<Maybe<Coupon_Type_Mutation_Response>>>;
  /** update data of the table: "custom_domain" */
  update_custom_domain?: Maybe<Custom_Domain_Mutation_Response>;
  /** update single row of the table: "custom_domain" */
  update_custom_domain_by_pk?: Maybe<Custom_Domain>;
  /** update data of the table: "custom_domain_cloudflare" */
  update_custom_domain_cloudflare?: Maybe<
    Custom_Domain_Cloudflare_Mutation_Response
  >;
  /** update single row of the table: "custom_domain_cloudflare" */
  update_custom_domain_cloudflare_by_pk?: Maybe<Custom_Domain_Cloudflare>;
  /** update data of the table: "custom_domain_cloudflare_dns" */
  update_custom_domain_cloudflare_dns?: Maybe<
    Custom_Domain_Cloudflare_Dns_Mutation_Response
  >;
  /** update single row of the table: "custom_domain_cloudflare_dns" */
  update_custom_domain_cloudflare_dns_by_pk?: Maybe<
    Custom_Domain_Cloudflare_Dns
  >;
  /** update multiples rows of table: "custom_domain_cloudflare_dns" */
  update_custom_domain_cloudflare_dns_many?: Maybe<
    Array<Maybe<Custom_Domain_Cloudflare_Dns_Mutation_Response>>
  >;
  /** update multiples rows of table: "custom_domain_cloudflare" */
  update_custom_domain_cloudflare_many?: Maybe<
    Array<Maybe<Custom_Domain_Cloudflare_Mutation_Response>>
  >;
  /** update multiples rows of table: "custom_domain" */
  update_custom_domain_many?: Maybe<
    Array<Maybe<Custom_Domain_Mutation_Response>>
  >;
  /** update data of the table: "customer_usage" */
  update_customer_usage?: Maybe<Customer_Usage_Mutation_Response>;
  /** update single row of the table: "customer_usage" */
  update_customer_usage_by_pk?: Maybe<Customer_Usage>;
  /** update multiples rows of table: "customer_usage" */
  update_customer_usage_many?: Maybe<
    Array<Maybe<Customer_Usage_Mutation_Response>>
  >;
  /** update data of the table: "data_connector_type" */
  update_data_connector_type?: Maybe<Data_Connector_Type_Mutation_Response>;
  /** update single row of the table: "data_connector_type" */
  update_data_connector_type_by_pk?: Maybe<Data_Connector_Type>;
  /** update multiples rows of table: "data_connector_type" */
  update_data_connector_type_many?: Maybe<
    Array<Maybe<Data_Connector_Type_Mutation_Response>>
  >;
  /** update data of the table: "datadog_config" */
  update_datadog_config?: Maybe<Datadog_Config_Mutation_Response>;
  /** update single row of the table: "datadog_config" */
  update_datadog_config_by_pk?: Maybe<Datadog_Config>;
  /** update multiples rows of table: "datadog_config" */
  update_datadog_config_many?: Maybe<
    Array<Maybe<Datadog_Config_Mutation_Response>>
  >;
  /** update data of the table: "db_latency" */
  update_db_latency?: Maybe<Db_Latency_Mutation_Response>;
  /** update single row of the table: "db_latency" */
  update_db_latency_by_pk?: Maybe<Db_Latency>;
  /** update multiples rows of table: "db_latency" */
  update_db_latency_many?: Maybe<Array<Maybe<Db_Latency_Mutation_Response>>>;
  /** update data of the table: "ddn.applied_build_changelog" */
  update_ddn_applied_build_changelog?: Maybe<
    Ddn_Applied_Build_Changelog_Mutation_Response
  >;
  /** update single row of the table: "ddn.applied_build_changelog" */
  update_ddn_applied_build_changelog_by_pk?: Maybe<Ddn_Applied_Build_Changelog>;
  /** update multiples rows of table: "ddn.applied_build_changelog" */
  update_ddn_applied_build_changelog_many?: Maybe<
    Array<Maybe<Ddn_Applied_Build_Changelog_Mutation_Response>>
  >;
  /** update data of the table: "ddn.applied_subgraph_build_changelog" */
  update_ddn_applied_subgraph_build_changelog?: Maybe<
    Ddn_Applied_Subgraph_Build_Changelog_Mutation_Response
  >;
  /** update single row of the table: "ddn.applied_subgraph_build_changelog" */
  update_ddn_applied_subgraph_build_changelog_by_pk?: Maybe<
    Ddn_Applied_Subgraph_Build_Changelog
  >;
  /** update multiples rows of table: "ddn.applied_subgraph_build_changelog" */
  update_ddn_applied_subgraph_build_changelog_many?: Maybe<
    Array<Maybe<Ddn_Applied_Subgraph_Build_Changelog_Mutation_Response>>
  >;
  /** update data of the table: "ddn.auto_approve_project_request" */
  update_ddn_auto_approve_project_request?: Maybe<
    Ddn_Auto_Approve_Project_Request_Mutation_Response
  >;
  /** update single row of the table: "ddn.auto_approve_project_request" */
  update_ddn_auto_approve_project_request_by_pk?: Maybe<
    Ddn_Auto_Approve_Project_Request
  >;
  /** update multiples rows of table: "ddn.auto_approve_project_request" */
  update_ddn_auto_approve_project_request_many?: Maybe<
    Array<Maybe<Ddn_Auto_Approve_Project_Request_Mutation_Response>>
  >;
  /** update data of the table: "ddn.build" */
  update_ddn_build?: Maybe<Ddn_Build_Mutation_Response>;
  /** update single row of the table: "ddn.build" */
  update_ddn_build_by_pk?: Maybe<Ddn_Build>;
  /** update data of the table: "ddn.build_details" */
  update_ddn_build_details?: Maybe<Ddn_Build_Details_Mutation_Response>;
  /** update single row of the table: "ddn.build_details" */
  update_ddn_build_details_by_pk?: Maybe<Ddn_Build_Details>;
  /** update multiples rows of table: "ddn.build_details" */
  update_ddn_build_details_many?: Maybe<
    Array<Maybe<Ddn_Build_Details_Mutation_Response>>
  >;
  /** update data of the table: "ddn.build_lock" */
  update_ddn_build_lock?: Maybe<Ddn_Build_Lock_Mutation_Response>;
  /** update single row of the table: "ddn.build_lock" */
  update_ddn_build_lock_by_pk?: Maybe<Ddn_Build_Lock>;
  /** update multiples rows of table: "ddn.build_lock" */
  update_ddn_build_lock_many?: Maybe<
    Array<Maybe<Ddn_Build_Lock_Mutation_Response>>
  >;
  /** update multiples rows of table: "ddn.build" */
  update_ddn_build_many?: Maybe<Array<Maybe<Ddn_Build_Mutation_Response>>>;
  /** update data of the table: "ddn.build_revision" */
  update_ddn_build_revision?: Maybe<Ddn_Build_Revision_Mutation_Response>;
  /** update single row of the table: "ddn.build_revision" */
  update_ddn_build_revision_by_pk?: Maybe<Ddn_Build_Revision>;
  /** update multiples rows of table: "ddn.build_revision" */
  update_ddn_build_revision_many?: Maybe<
    Array<Maybe<Ddn_Build_Revision_Mutation_Response>>
  >;
  /** update data of the table: "ddn.build_revision_status" */
  update_ddn_build_revision_status?: Maybe<
    Ddn_Build_Revision_Status_Mutation_Response
  >;
  /** update single row of the table: "ddn.build_revision_status" */
  update_ddn_build_revision_status_by_pk?: Maybe<Ddn_Build_Revision_Status>;
  /** update multiples rows of table: "ddn.build_revision_status" */
  update_ddn_build_revision_status_many?: Maybe<
    Array<Maybe<Ddn_Build_Revision_Status_Mutation_Response>>
  >;
  /** update data of the table: "ddn.build_sync_status" */
  update_ddn_build_sync_status?: Maybe<Ddn_Build_Sync_Status_Mutation_Response>;
  /** update single row of the table: "ddn.build_sync_status" */
  update_ddn_build_sync_status_by_pk?: Maybe<Ddn_Build_Sync_Status>;
  /** update multiples rows of table: "ddn.build_sync_status" */
  update_ddn_build_sync_status_many?: Maybe<
    Array<Maybe<Ddn_Build_Sync_Status_Mutation_Response>>
  >;
  /** update data of the table: "ddn.build_sync_worker" */
  update_ddn_build_sync_worker?: Maybe<Ddn_Build_Sync_Worker_Mutation_Response>;
  /** update single row of the table: "ddn.build_sync_worker" */
  update_ddn_build_sync_worker_by_pk?: Maybe<Ddn_Build_Sync_Worker>;
  /** update multiples rows of table: "ddn.build_sync_worker" */
  update_ddn_build_sync_worker_many?: Maybe<
    Array<Maybe<Ddn_Build_Sync_Worker_Mutation_Response>>
  >;
  /** update data of the table: "ddn.collaboration_invitee_project_view" */
  update_ddn_collaboration_invitee_project_view?: Maybe<
    Ddn_Collaboration_Invitee_Project_View_Mutation_Response
  >;
  /** update multiples rows of table: "ddn.collaboration_invitee_project_view" */
  update_ddn_collaboration_invitee_project_view_many?: Maybe<
    Array<Maybe<Ddn_Collaboration_Invitee_Project_View_Mutation_Response>>
  >;
  /** update data of the table: "ddn.connector_deployment" */
  update_ddn_connector_deployment?: Maybe<
    Ddn_Connector_Deployment_Mutation_Response
  >;
  /** update single row of the table: "ddn.connector_deployment" */
  update_ddn_connector_deployment_by_pk?: Maybe<Ddn_Connector_Deployment>;
  /** update multiples rows of table: "ddn.connector_deployment" */
  update_ddn_connector_deployment_many?: Maybe<
    Array<Maybe<Ddn_Connector_Deployment_Mutation_Response>>
  >;
  /** update data of the table: "ddn.ddn" */
  update_ddn_ddn?: Maybe<Ddn_Ddn_Mutation_Response>;
  /** update single row of the table: "ddn.ddn" */
  update_ddn_ddn_by_pk?: Maybe<Ddn_Ddn>;
  /** update multiples rows of table: "ddn.ddn" */
  update_ddn_ddn_many?: Maybe<Array<Maybe<Ddn_Ddn_Mutation_Response>>>;
  /** update data of the table: "ddn.environment" */
  update_ddn_environment?: Maybe<Ddn_Environment_Mutation_Response>;
  /** update single row of the table: "ddn.environment" */
  update_ddn_environment_by_pk?: Maybe<Ddn_Environment>;
  /** update multiples rows of table: "ddn.environment" */
  update_ddn_environment_many?: Maybe<
    Array<Maybe<Ddn_Environment_Mutation_Response>>
  >;
  /** update data of the table: "ddn.generate_metadata_artifacts_log" */
  update_ddn_generate_metadata_artifacts_log?: Maybe<
    Ddn_Generate_Metadata_Artifacts_Log_Mutation_Response
  >;
  /** update single row of the table: "ddn.generate_metadata_artifacts_log" */
  update_ddn_generate_metadata_artifacts_log_by_pk?: Maybe<
    Ddn_Generate_Metadata_Artifacts_Log
  >;
  /** update multiples rows of table: "ddn.generate_metadata_artifacts_log" */
  update_ddn_generate_metadata_artifacts_log_many?: Maybe<
    Array<Maybe<Ddn_Generate_Metadata_Artifacts_Log_Mutation_Response>>
  >;
  /** update data of the table: "ddn.invoice" */
  update_ddn_invoice?: Maybe<Ddn_Invoice_Mutation_Response>;
  /** update single row of the table: "ddn.invoice" */
  update_ddn_invoice_by_pk?: Maybe<Ddn_Invoice>;
  /** update data of the table: "ddn.invoice_item" */
  update_ddn_invoice_item?: Maybe<Ddn_Invoice_Item_Mutation_Response>;
  /** update single row of the table: "ddn.invoice_item" */
  update_ddn_invoice_item_by_pk?: Maybe<Ddn_Invoice_Item>;
  /** update multiples rows of table: "ddn.invoice_item" */
  update_ddn_invoice_item_many?: Maybe<
    Array<Maybe<Ddn_Invoice_Item_Mutation_Response>>
  >;
  /** update multiples rows of table: "ddn.invoice" */
  update_ddn_invoice_many?: Maybe<Array<Maybe<Ddn_Invoice_Mutation_Response>>>;
  /** update data of the table: "ddn.plan_entitlement_access" */
  update_ddn_plan_entitlement_access?: Maybe<
    Ddn_Plan_Entitlement_Access_Mutation_Response
  >;
  /** update single row of the table: "ddn.plan_entitlement_access" */
  update_ddn_plan_entitlement_access_by_pk?: Maybe<Ddn_Plan_Entitlement_Access>;
  /** update multiples rows of table: "ddn.plan_entitlement_access" */
  update_ddn_plan_entitlement_access_many?: Maybe<
    Array<Maybe<Ddn_Plan_Entitlement_Access_Mutation_Response>>
  >;
  /** update data of the table: "ddn.plans" */
  update_ddn_plans?: Maybe<Ddn_Plans_Mutation_Response>;
  /** update single row of the table: "ddn.plans" */
  update_ddn_plans_by_pk?: Maybe<Ddn_Plans>;
  /** update multiples rows of table: "ddn.plans" */
  update_ddn_plans_many?: Maybe<Array<Maybe<Ddn_Plans_Mutation_Response>>>;
  /** update data of the table: "ddn.private_ddn" */
  update_ddn_private_ddn?: Maybe<Ddn_Private_Ddn_Mutation_Response>;
  /** update single row of the table: "ddn.private_ddn" */
  update_ddn_private_ddn_by_pk?: Maybe<Ddn_Private_Ddn>;
  /** update multiples rows of table: "ddn.private_ddn" */
  update_ddn_private_ddn_many?: Maybe<
    Array<Maybe<Ddn_Private_Ddn_Mutation_Response>>
  >;
  /** update data of the table: "ddn.private_ddn_region" */
  update_ddn_private_ddn_region?: Maybe<
    Ddn_Private_Ddn_Region_Mutation_Response
  >;
  /** update single row of the table: "ddn.private_ddn_region" */
  update_ddn_private_ddn_region_by_pk?: Maybe<Ddn_Private_Ddn_Region>;
  /** update multiples rows of table: "ddn.private_ddn_region" */
  update_ddn_private_ddn_region_many?: Maybe<
    Array<Maybe<Ddn_Private_Ddn_Region_Mutation_Response>>
  >;
  /** update data of the table: "ddn.project_access_level" */
  update_ddn_project_access_level?: Maybe<
    Ddn_Project_Access_Level_Mutation_Response
  >;
  /** update single row of the table: "ddn.project_access_level" */
  update_ddn_project_access_level_by_pk?: Maybe<Ddn_Project_Access_Level>;
  /** update multiples rows of table: "ddn.project_access_level" */
  update_ddn_project_access_level_many?: Maybe<
    Array<Maybe<Ddn_Project_Access_Level_Mutation_Response>>
  >;
  /** update data of the table: "ddn.project_active_status_changelog" */
  update_ddn_project_active_status_changelog?: Maybe<
    Ddn_Project_Active_Status_Changelog_Mutation_Response
  >;
  /** update single row of the table: "ddn.project_active_status_changelog" */
  update_ddn_project_active_status_changelog_by_pk?: Maybe<
    Ddn_Project_Active_Status_Changelog
  >;
  /** update multiples rows of table: "ddn.project_active_status_changelog" */
  update_ddn_project_active_status_changelog_many?: Maybe<
    Array<Maybe<Ddn_Project_Active_Status_Changelog_Mutation_Response>>
  >;
  /** update data of the table: "ddn.project_active_status_reason" */
  update_ddn_project_active_status_reason?: Maybe<
    Ddn_Project_Active_Status_Reason_Mutation_Response
  >;
  /** update single row of the table: "ddn.project_active_status_reason" */
  update_ddn_project_active_status_reason_by_pk?: Maybe<
    Ddn_Project_Active_Status_Reason
  >;
  /** update multiples rows of table: "ddn.project_active_status_reason" */
  update_ddn_project_active_status_reason_many?: Maybe<
    Array<Maybe<Ddn_Project_Active_Status_Reason_Mutation_Response>>
  >;
  /** update data of the table: "ddn.project_entitlement_access" */
  update_ddn_project_entitlement_access?: Maybe<
    Ddn_Project_Entitlement_Access_Mutation_Response
  >;
  /** update single row of the table: "ddn.project_entitlement_access" */
  update_ddn_project_entitlement_access_by_pk?: Maybe<
    Ddn_Project_Entitlement_Access
  >;
  /** update multiples rows of table: "ddn.project_entitlement_access" */
  update_ddn_project_entitlement_access_many?: Maybe<
    Array<Maybe<Ddn_Project_Entitlement_Access_Mutation_Response>>
  >;
  /** update data of the table: "ddn.project_entitlement_catalogue" */
  update_ddn_project_entitlement_catalogue?: Maybe<
    Ddn_Project_Entitlement_Catalogue_Mutation_Response
  >;
  /** update single row of the table: "ddn.project_entitlement_catalogue" */
  update_ddn_project_entitlement_catalogue_by_pk?: Maybe<
    Ddn_Project_Entitlement_Catalogue
  >;
  /** update multiples rows of table: "ddn.project_entitlement_catalogue" */
  update_ddn_project_entitlement_catalogue_many?: Maybe<
    Array<Maybe<Ddn_Project_Entitlement_Catalogue_Mutation_Response>>
  >;
  /** update data of the table: "ddn.project_entitlement_types" */
  update_ddn_project_entitlement_types?: Maybe<
    Ddn_Project_Entitlement_Types_Mutation_Response
  >;
  /** update single row of the table: "ddn.project_entitlement_types" */
  update_ddn_project_entitlement_types_by_pk?: Maybe<
    Ddn_Project_Entitlement_Types
  >;
  /** update multiples rows of table: "ddn.project_entitlement_types" */
  update_ddn_project_entitlement_types_many?: Maybe<
    Array<Maybe<Ddn_Project_Entitlement_Types_Mutation_Response>>
  >;
  /** update data of the table: "ddn.project_plan_changelog" */
  update_ddn_project_plan_changelog?: Maybe<
    Ddn_Project_Plan_Changelog_Mutation_Response
  >;
  /** update single row of the table: "ddn.project_plan_changelog" */
  update_ddn_project_plan_changelog_by_pk?: Maybe<Ddn_Project_Plan_Changelog>;
  /** update multiples rows of table: "ddn.project_plan_changelog" */
  update_ddn_project_plan_changelog_many?: Maybe<
    Array<Maybe<Ddn_Project_Plan_Changelog_Mutation_Response>>
  >;
  /** update data of the table: "ddn.project_request_access" */
  update_ddn_project_request_access?: Maybe<
    Ddn_Project_Request_Access_Mutation_Response
  >;
  /** update single row of the table: "ddn.project_request_access" */
  update_ddn_project_request_access_by_pk?: Maybe<Ddn_Project_Request_Access>;
  /** update multiples rows of table: "ddn.project_request_access" */
  update_ddn_project_request_access_many?: Maybe<
    Array<Maybe<Ddn_Project_Request_Access_Mutation_Response>>
  >;
  /** update data of the table: "ddn.projects" */
  update_ddn_projects?: Maybe<Ddn_Projects_Mutation_Response>;
  /** update single row of the table: "ddn.projects" */
  update_ddn_projects_by_pk?: Maybe<Ddn_Projects>;
  /** update multiples rows of table: "ddn.projects" */
  update_ddn_projects_many?: Maybe<
    Array<Maybe<Ddn_Projects_Mutation_Response>>
  >;
  /** update data of the table: "ddn.secret_key" */
  update_ddn_secret_key?: Maybe<Ddn_Secret_Key_Mutation_Response>;
  /** update single row of the table: "ddn.secret_key" */
  update_ddn_secret_key_by_pk?: Maybe<Ddn_Secret_Key>;
  /** update multiples rows of table: "ddn.secret_key" */
  update_ddn_secret_key_many?: Maybe<
    Array<Maybe<Ddn_Secret_Key_Mutation_Response>>
  >;
  /** update data of the table: "ddn.stripe_subscription" */
  update_ddn_stripe_subscription?: Maybe<
    Ddn_Stripe_Subscription_Mutation_Response
  >;
  /** update single row of the table: "ddn.stripe_subscription" */
  update_ddn_stripe_subscription_by_pk?: Maybe<Ddn_Stripe_Subscription>;
  /** update multiples rows of table: "ddn.stripe_subscription" */
  update_ddn_stripe_subscription_many?: Maybe<
    Array<Maybe<Ddn_Stripe_Subscription_Mutation_Response>>
  >;
  /** update data of the table: "ddn.stripe_webhook_events" */
  update_ddn_stripe_webhook_events?: Maybe<
    Ddn_Stripe_Webhook_Events_Mutation_Response
  >;
  /** update single row of the table: "ddn.stripe_webhook_events" */
  update_ddn_stripe_webhook_events_by_pk?: Maybe<Ddn_Stripe_Webhook_Events>;
  /** update multiples rows of table: "ddn.stripe_webhook_events" */
  update_ddn_stripe_webhook_events_many?: Maybe<
    Array<Maybe<Ddn_Stripe_Webhook_Events_Mutation_Response>>
  >;
  /** update data of the table: "ddn.subgraph" */
  update_ddn_subgraph?: Maybe<Ddn_Subgraph_Mutation_Response>;
  /** update data of the table: "ddn.subgraph_build" */
  update_ddn_subgraph_build?: Maybe<Ddn_Subgraph_Build_Mutation_Response>;
  /** update single row of the table: "ddn.subgraph_build" */
  update_ddn_subgraph_build_by_pk?: Maybe<Ddn_Subgraph_Build>;
  /** update multiples rows of table: "ddn.subgraph_build" */
  update_ddn_subgraph_build_many?: Maybe<
    Array<Maybe<Ddn_Subgraph_Build_Mutation_Response>>
  >;
  /** update single row of the table: "ddn.subgraph" */
  update_ddn_subgraph_by_pk?: Maybe<Ddn_Subgraph>;
  /** update multiples rows of table: "ddn.subgraph" */
  update_ddn_subgraph_many?: Maybe<
    Array<Maybe<Ddn_Subgraph_Mutation_Response>>
  >;
  /** update data of the table: "ddn.team" */
  update_ddn_team?: Maybe<Ddn_Team_Mutation_Response>;
  /** update single row of the table: "ddn.team" */
  update_ddn_team_by_pk?: Maybe<Ddn_Team>;
  /** update data of the table: "ddn.team_invitation" */
  update_ddn_team_invitation?: Maybe<Ddn_Team_Invitation_Mutation_Response>;
  /** update single row of the table: "ddn.team_invitation" */
  update_ddn_team_invitation_by_pk?: Maybe<Ddn_Team_Invitation>;
  /** update multiples rows of table: "ddn.team_invitation" */
  update_ddn_team_invitation_many?: Maybe<
    Array<Maybe<Ddn_Team_Invitation_Mutation_Response>>
  >;
  /** update data of the table: "ddn.team_invitation_status" */
  update_ddn_team_invitation_status?: Maybe<
    Ddn_Team_Invitation_Status_Mutation_Response
  >;
  /** update single row of the table: "ddn.team_invitation_status" */
  update_ddn_team_invitation_status_by_pk?: Maybe<Ddn_Team_Invitation_Status>;
  /** update multiples rows of table: "ddn.team_invitation_status" */
  update_ddn_team_invitation_status_many?: Maybe<
    Array<Maybe<Ddn_Team_Invitation_Status_Mutation_Response>>
  >;
  /** update data of the table: "ddn.team_kind" */
  update_ddn_team_kind?: Maybe<Ddn_Team_Kind_Mutation_Response>;
  /** update single row of the table: "ddn.team_kind" */
  update_ddn_team_kind_by_pk?: Maybe<Ddn_Team_Kind>;
  /** update multiples rows of table: "ddn.team_kind" */
  update_ddn_team_kind_many?: Maybe<
    Array<Maybe<Ddn_Team_Kind_Mutation_Response>>
  >;
  /** update multiples rows of table: "ddn.team" */
  update_ddn_team_many?: Maybe<Array<Maybe<Ddn_Team_Mutation_Response>>>;
  /** update data of the table: "ddn.team_membership" */
  update_ddn_team_membership?: Maybe<Ddn_Team_Membership_Mutation_Response>;
  /** update single row of the table: "ddn.team_membership" */
  update_ddn_team_membership_by_pk?: Maybe<Ddn_Team_Membership>;
  /** update multiples rows of table: "ddn.team_membership" */
  update_ddn_team_membership_many?: Maybe<
    Array<Maybe<Ddn_Team_Membership_Mutation_Response>>
  >;
  /** update data of the table: "ddn.team_project_access" */
  update_ddn_team_project_access?: Maybe<
    Ddn_Team_Project_Access_Mutation_Response
  >;
  /** update single row of the table: "ddn.team_project_access" */
  update_ddn_team_project_access_by_pk?: Maybe<Ddn_Team_Project_Access>;
  /** update multiples rows of table: "ddn.team_project_access" */
  update_ddn_team_project_access_many?: Maybe<
    Array<Maybe<Ddn_Team_Project_Access_Mutation_Response>>
  >;
  /** update data of the table: "ddn.tunnel" */
  update_ddn_tunnel?: Maybe<Ddn_Tunnel_Mutation_Response>;
  /** update single row of the table: "ddn.tunnel" */
  update_ddn_tunnel_by_pk?: Maybe<Ddn_Tunnel>;
  /** update data of the table: "ddn.tunnel_cluster" */
  update_ddn_tunnel_cluster?: Maybe<Ddn_Tunnel_Cluster_Mutation_Response>;
  /** update single row of the table: "ddn.tunnel_cluster" */
  update_ddn_tunnel_cluster_by_pk?: Maybe<Ddn_Tunnel_Cluster>;
  /** update multiples rows of table: "ddn.tunnel_cluster" */
  update_ddn_tunnel_cluster_many?: Maybe<
    Array<Maybe<Ddn_Tunnel_Cluster_Mutation_Response>>
  >;
  /** update data of the table: "ddn.tunnel_http" */
  update_ddn_tunnel_http?: Maybe<Ddn_Tunnel_Http_Mutation_Response>;
  /** update single row of the table: "ddn.tunnel_http" */
  update_ddn_tunnel_http_by_pk?: Maybe<Ddn_Tunnel_Http>;
  /** update data of the table: "ddn.tunnel_http_cluster" */
  update_ddn_tunnel_http_cluster?: Maybe<
    Ddn_Tunnel_Http_Cluster_Mutation_Response
  >;
  /** update single row of the table: "ddn.tunnel_http_cluster" */
  update_ddn_tunnel_http_cluster_by_pk?: Maybe<Ddn_Tunnel_Http_Cluster>;
  /** update multiples rows of table: "ddn.tunnel_http_cluster" */
  update_ddn_tunnel_http_cluster_many?: Maybe<
    Array<Maybe<Ddn_Tunnel_Http_Cluster_Mutation_Response>>
  >;
  /** update multiples rows of table: "ddn.tunnel_http" */
  update_ddn_tunnel_http_many?: Maybe<
    Array<Maybe<Ddn_Tunnel_Http_Mutation_Response>>
  >;
  /** update multiples rows of table: "ddn.tunnel" */
  update_ddn_tunnel_many?: Maybe<Array<Maybe<Ddn_Tunnel_Mutation_Response>>>;
  /** update data of the table: "dedicated_cloud_bills" */
  update_dedicated_cloud_bills?: Maybe<Dedicated_Cloud_Bills_Mutation_Response>;
  /** update single row of the table: "dedicated_cloud_bills" */
  update_dedicated_cloud_bills_by_pk?: Maybe<Dedicated_Cloud_Bills>;
  /** update data of the table: "dedicated_cloud_bills_details" */
  update_dedicated_cloud_bills_details?: Maybe<
    Dedicated_Cloud_Bills_Details_Mutation_Response
  >;
  /** update single row of the table: "dedicated_cloud_bills_details" */
  update_dedicated_cloud_bills_details_by_pk?: Maybe<
    Dedicated_Cloud_Bills_Details
  >;
  /** update multiples rows of table: "dedicated_cloud_bills_details" */
  update_dedicated_cloud_bills_details_many?: Maybe<
    Array<Maybe<Dedicated_Cloud_Bills_Details_Mutation_Response>>
  >;
  /** update multiples rows of table: "dedicated_cloud_bills" */
  update_dedicated_cloud_bills_many?: Maybe<
    Array<Maybe<Dedicated_Cloud_Bills_Mutation_Response>>
  >;
  /** update data of the table: "dedicated_cloud_commitments" */
  update_dedicated_cloud_commitments?: Maybe<
    Dedicated_Cloud_Commitments_Mutation_Response
  >;
  /** update single row of the table: "dedicated_cloud_commitments" */
  update_dedicated_cloud_commitments_by_pk?: Maybe<Dedicated_Cloud_Commitments>;
  /** update multiples rows of table: "dedicated_cloud_commitments" */
  update_dedicated_cloud_commitments_many?: Maybe<
    Array<Maybe<Dedicated_Cloud_Commitments_Mutation_Response>>
  >;
  /** update data of the table: "dedicated_vpc" */
  update_dedicated_vpc?: Maybe<Dedicated_Vpc_Mutation_Response>;
  /** update single row of the table: "dedicated_vpc" */
  update_dedicated_vpc_by_pk?: Maybe<Dedicated_Vpc>;
  /** update multiples rows of table: "dedicated_vpc" */
  update_dedicated_vpc_many?: Maybe<
    Array<Maybe<Dedicated_Vpc_Mutation_Response>>
  >;
  /** update data of the table: "delete_user" */
  update_delete_user?: Maybe<Delete_User_Mutation_Response>;
  /** update single row of the table: "delete_user" */
  update_delete_user_by_pk?: Maybe<Delete_User>;
  /** update multiples rows of table: "delete_user" */
  update_delete_user_many?: Maybe<Array<Maybe<Delete_User_Mutation_Response>>>;
  /** update data of the table: "delete_user_status" */
  update_delete_user_status?: Maybe<Delete_User_Status_Mutation_Response>;
  /** update single row of the table: "delete_user_status" */
  update_delete_user_status_by_pk?: Maybe<Delete_User_Status>;
  /** update multiples rows of table: "delete_user_status" */
  update_delete_user_status_many?: Maybe<
    Array<Maybe<Delete_User_Status_Mutation_Response>>
  >;
  /** update data of the table: "delete_user_tasks" */
  update_delete_user_tasks?: Maybe<Delete_User_Tasks_Mutation_Response>;
  /** update single row of the table: "delete_user_tasks" */
  update_delete_user_tasks_by_pk?: Maybe<Delete_User_Tasks>;
  /** update multiples rows of table: "delete_user_tasks" */
  update_delete_user_tasks_many?: Maybe<
    Array<Maybe<Delete_User_Tasks_Mutation_Response>>
  >;
  /** update data of the table: "email_log" */
  update_email_log?: Maybe<Email_Log_Mutation_Response>;
  /** update single row of the table: "email_log" */
  update_email_log_by_pk?: Maybe<Email_Log>;
  /** update multiples rows of table: "email_log" */
  update_email_log_many?: Maybe<Array<Maybe<Email_Log_Mutation_Response>>>;
  /** update data of the table: "enterprise_users" */
  update_enterprise_users?: Maybe<Enterprise_Users_Mutation_Response>;
  /** update single row of the table: "enterprise_users" */
  update_enterprise_users_by_pk?: Maybe<Enterprise_Users>;
  /** update data of the table: "enterprise_users_domain" */
  update_enterprise_users_domain?: Maybe<
    Enterprise_Users_Domain_Mutation_Response
  >;
  /** update single row of the table: "enterprise_users_domain" */
  update_enterprise_users_domain_by_pk?: Maybe<Enterprise_Users_Domain>;
  /** update multiples rows of table: "enterprise_users_domain" */
  update_enterprise_users_domain_many?: Maybe<
    Array<Maybe<Enterprise_Users_Domain_Mutation_Response>>
  >;
  /** update multiples rows of table: "enterprise_users" */
  update_enterprise_users_many?: Maybe<
    Array<Maybe<Enterprise_Users_Mutation_Response>>
  >;
  /** update data of the table: "experiments" */
  update_experiments?: Maybe<Experiments_Mutation_Response>;
  /** update single row of the table: "experiments" */
  update_experiments_by_pk?: Maybe<Experiments>;
  /** update data of the table: "experiments_cohort" */
  update_experiments_cohort?: Maybe<Experiments_Cohort_Mutation_Response>;
  /** update single row of the table: "experiments_cohort" */
  update_experiments_cohort_by_pk?: Maybe<Experiments_Cohort>;
  /** update multiples rows of table: "experiments_cohort" */
  update_experiments_cohort_many?: Maybe<
    Array<Maybe<Experiments_Cohort_Mutation_Response>>
  >;
  /** update data of the table: "experiments_config" */
  update_experiments_config?: Maybe<Experiments_Config_Mutation_Response>;
  /** update single row of the table: "experiments_config" */
  update_experiments_config_by_pk?: Maybe<Experiments_Config>;
  /** update multiples rows of table: "experiments_config" */
  update_experiments_config_many?: Maybe<
    Array<Maybe<Experiments_Config_Mutation_Response>>
  >;
  /** update multiples rows of table: "experiments" */
  update_experiments_many?: Maybe<Array<Maybe<Experiments_Mutation_Response>>>;
  /** update data of the table: "feature" */
  update_feature?: Maybe<Feature_Mutation_Response>;
  /** update data of the table: "feature_access" */
  update_feature_access?: Maybe<Feature_Access_Mutation_Response>;
  /** update single row of the table: "feature_access" */
  update_feature_access_by_pk?: Maybe<Feature_Access>;
  /** update multiples rows of table: "feature_access" */
  update_feature_access_many?: Maybe<
    Array<Maybe<Feature_Access_Mutation_Response>>
  >;
  /** update single row of the table: "feature" */
  update_feature_by_pk?: Maybe<Feature>;
  /** update data of the table: "feature_config" */
  update_feature_config?: Maybe<Feature_Config_Mutation_Response>;
  /** update single row of the table: "feature_config" */
  update_feature_config_by_pk?: Maybe<Feature_Config>;
  /** update multiples rows of table: "feature_config" */
  update_feature_config_many?: Maybe<
    Array<Maybe<Feature_Config_Mutation_Response>>
  >;
  /** update multiples rows of table: "feature" */
  update_feature_many?: Maybe<Array<Maybe<Feature_Mutation_Response>>>;
  /** update data of the table: "gateway_ami" */
  update_gateway_ami?: Maybe<Gateway_Ami_Mutation_Response>;
  /** update single row of the table: "gateway_ami" */
  update_gateway_ami_by_pk?: Maybe<Gateway_Ami>;
  /** update multiples rows of table: "gateway_ami" */
  update_gateway_ami_many?: Maybe<Array<Maybe<Gateway_Ami_Mutation_Response>>>;
  /** update data of the table: "gateway_cluster" */
  update_gateway_cluster?: Maybe<Gateway_Cluster_Mutation_Response>;
  /** update single row of the table: "gateway_cluster" */
  update_gateway_cluster_by_pk?: Maybe<Gateway_Cluster>;
  /** update multiples rows of table: "gateway_cluster" */
  update_gateway_cluster_many?: Maybe<
    Array<Maybe<Gateway_Cluster_Mutation_Response>>
  >;
  /** update data of the table: "gateway_worker" */
  update_gateway_worker?: Maybe<Gateway_Worker_Mutation_Response>;
  /** update single row of the table: "gateway_worker" */
  update_gateway_worker_by_pk?: Maybe<Gateway_Worker>;
  /** update multiples rows of table: "gateway_worker" */
  update_gateway_worker_many?: Maybe<
    Array<Maybe<Gateway_Worker_Mutation_Response>>
  >;
  /** update data of the table: "github_email_type" */
  update_github_email_type?: Maybe<Github_Email_Type_Mutation_Response>;
  /** update single row of the table: "github_email_type" */
  update_github_email_type_by_pk?: Maybe<Github_Email_Type>;
  /** update multiples rows of table: "github_email_type" */
  update_github_email_type_many?: Maybe<
    Array<Maybe<Github_Email_Type_Mutation_Response>>
  >;
  /** update data of the table: "github_integration_config" */
  update_github_integration_config?: Maybe<
    Github_Integration_Config_Mutation_Response
  >;
  /** update single row of the table: "github_integration_config" */
  update_github_integration_config_by_pk?: Maybe<Github_Integration_Config>;
  /** update multiples rows of table: "github_integration_config" */
  update_github_integration_config_many?: Maybe<
    Array<Maybe<Github_Integration_Config_Mutation_Response>>
  >;
  /** update data of the table: "github_integration_mode" */
  update_github_integration_mode?: Maybe<
    Github_Integration_Mode_Mutation_Response
  >;
  /** update single row of the table: "github_integration_mode" */
  update_github_integration_mode_by_pk?: Maybe<Github_Integration_Mode>;
  /** update multiples rows of table: "github_integration_mode" */
  update_github_integration_mode_many?: Maybe<
    Array<Maybe<Github_Integration_Mode_Mutation_Response>>
  >;
  /** update data of the table: "github_push_event" */
  update_github_push_event?: Maybe<Github_Push_Event_Mutation_Response>;
  /** update single row of the table: "github_push_event" */
  update_github_push_event_by_pk?: Maybe<Github_Push_Event>;
  /** update data of the table: "github_push_event_job" */
  update_github_push_event_job?: Maybe<Github_Push_Event_Job_Mutation_Response>;
  /** update single row of the table: "github_push_event_job" */
  update_github_push_event_job_by_pk?: Maybe<Github_Push_Event_Job>;
  /** update multiples rows of table: "github_push_event_job" */
  update_github_push_event_job_many?: Maybe<
    Array<Maybe<Github_Push_Event_Job_Mutation_Response>>
  >;
  /** update multiples rows of table: "github_push_event" */
  update_github_push_event_many?: Maybe<
    Array<Maybe<Github_Push_Event_Mutation_Response>>
  >;
  /** update data of the table: "hasura_ami" */
  update_hasura_ami?: Maybe<Hasura_Ami_Mutation_Response>;
  /** update single row of the table: "hasura_ami" */
  update_hasura_ami_by_pk?: Maybe<Hasura_Ami>;
  /** update multiples rows of table: "hasura_ami" */
  update_hasura_ami_many?: Maybe<Array<Maybe<Hasura_Ami_Mutation_Response>>>;
  /** update data of the table: "hasura_cluster" */
  update_hasura_cluster?: Maybe<Hasura_Cluster_Mutation_Response>;
  /** update single row of the table: "hasura_cluster" */
  update_hasura_cluster_by_pk?: Maybe<Hasura_Cluster>;
  /** update multiples rows of table: "hasura_cluster" */
  update_hasura_cluster_many?: Maybe<
    Array<Maybe<Hasura_Cluster_Mutation_Response>>
  >;
  /** update data of the table: "hasura_worker" */
  update_hasura_worker?: Maybe<Hasura_Worker_Mutation_Response>;
  /** update single row of the table: "hasura_worker" */
  update_hasura_worker_by_pk?: Maybe<Hasura_Worker>;
  /** update multiples rows of table: "hasura_worker" */
  update_hasura_worker_many?: Maybe<
    Array<Maybe<Hasura_Worker_Mutation_Response>>
  >;
  /** update data of the table: "hasura_year_in_report_activity" */
  update_hasura_year_in_report_activity?: Maybe<
    Hasura_Year_In_Report_Activity_Mutation_Response
  >;
  /** update single row of the table: "hasura_year_in_report_activity" */
  update_hasura_year_in_report_activity_by_pk?: Maybe<
    Hasura_Year_In_Report_Activity
  >;
  /** update multiples rows of table: "hasura_year_in_report_activity" */
  update_hasura_year_in_report_activity_many?: Maybe<
    Array<Maybe<Hasura_Year_In_Report_Activity_Mutation_Response>>
  >;
  /** update data of the table: "hasura_year_in_report_metrics" */
  update_hasura_year_in_report_metrics?: Maybe<
    Hasura_Year_In_Report_Metrics_Mutation_Response
  >;
  /** update single row of the table: "hasura_year_in_report_metrics" */
  update_hasura_year_in_report_metrics_by_pk?: Maybe<
    Hasura_Year_In_Report_Metrics
  >;
  /** update multiples rows of table: "hasura_year_in_report_metrics" */
  update_hasura_year_in_report_metrics_many?: Maybe<
    Array<Maybe<Hasura_Year_In_Report_Metrics_Mutation_Response>>
  >;
  /** update data of the table: "heroku_integrations" */
  update_heroku_integrations?: Maybe<Heroku_Integrations_Mutation_Response>;
  /** update single row of the table: "heroku_integrations" */
  update_heroku_integrations_by_pk?: Maybe<Heroku_Integrations>;
  /** update multiples rows of table: "heroku_integrations" */
  update_heroku_integrations_many?: Maybe<
    Array<Maybe<Heroku_Integrations_Mutation_Response>>
  >;
  /** update data of the table: "inactive_project_exclusions" */
  update_inactive_project_exclusions?: Maybe<
    Inactive_Project_Exclusions_Mutation_Response
  >;
  /** update single row of the table: "inactive_project_exclusions" */
  update_inactive_project_exclusions_by_pk?: Maybe<Inactive_Project_Exclusions>;
  /** update multiples rows of table: "inactive_project_exclusions" */
  update_inactive_project_exclusions_many?: Maybe<
    Array<Maybe<Inactive_Project_Exclusions_Mutation_Response>>
  >;
  /** update data of the table: "inactive_project_notifications" */
  update_inactive_project_notifications?: Maybe<
    Inactive_Project_Notifications_Mutation_Response
  >;
  /** update single row of the table: "inactive_project_notifications" */
  update_inactive_project_notifications_by_pk?: Maybe<
    Inactive_Project_Notifications
  >;
  /** update multiples rows of table: "inactive_project_notifications" */
  update_inactive_project_notifications_many?: Maybe<
    Array<Maybe<Inactive_Project_Notifications_Mutation_Response>>
  >;
  /** update data of the table: "inactive_project_suspension_config" */
  update_inactive_project_suspension_config?: Maybe<
    Inactive_Project_Suspension_Config_Mutation_Response
  >;
  /** update multiples rows of table: "inactive_project_suspension_config" */
  update_inactive_project_suspension_config_many?: Maybe<
    Array<Maybe<Inactive_Project_Suspension_Config_Mutation_Response>>
  >;
  /** update data of the table: "infra_status" */
  update_infra_status?: Maybe<Infra_Status_Mutation_Response>;
  /** update single row of the table: "infra_status" */
  update_infra_status_by_pk?: Maybe<Infra_Status>;
  /** update multiples rows of table: "infra_status" */
  update_infra_status_many?: Maybe<
    Array<Maybe<Infra_Status_Mutation_Response>>
  >;
  /** update data of the table: "invoice" */
  update_invoice?: Maybe<Invoice_Mutation_Response>;
  /** update single row of the table: "invoice" */
  update_invoice_by_pk?: Maybe<Invoice>;
  /** update data of the table: "invoice_coupon_discount" */
  update_invoice_coupon_discount?: Maybe<
    Invoice_Coupon_Discount_Mutation_Response
  >;
  /** update single row of the table: "invoice_coupon_discount" */
  update_invoice_coupon_discount_by_pk?: Maybe<Invoice_Coupon_Discount>;
  /** update multiples rows of table: "invoice_coupon_discount" */
  update_invoice_coupon_discount_many?: Maybe<
    Array<Maybe<Invoice_Coupon_Discount_Mutation_Response>>
  >;
  /** update data of the table: "invoice_item" */
  update_invoice_item?: Maybe<Invoice_Item_Mutation_Response>;
  /** update single row of the table: "invoice_item" */
  update_invoice_item_by_pk?: Maybe<Invoice_Item>;
  /** update multiples rows of table: "invoice_item" */
  update_invoice_item_many?: Maybe<
    Array<Maybe<Invoice_Item_Mutation_Response>>
  >;
  /** update multiples rows of table: "invoice" */
  update_invoice_many?: Maybe<Array<Maybe<Invoice_Mutation_Response>>>;
  /** update data of the table: "jobs" */
  update_jobs?: Maybe<Jobs_Mutation_Response>;
  /** update single row of the table: "jobs" */
  update_jobs_by_pk?: Maybe<Jobs>;
  /** update multiples rows of table: "jobs" */
  update_jobs_many?: Maybe<Array<Maybe<Jobs_Mutation_Response>>>;
  /** update data of the table: "label" */
  update_label?: Maybe<Label_Mutation_Response>;
  /** update single row of the table: "label" */
  update_label_by_pk?: Maybe<Label>;
  /** update multiples rows of table: "label" */
  update_label_many?: Maybe<Array<Maybe<Label_Mutation_Response>>>;
  /** update data of the table: "letsencrypt_status" */
  update_letsencrypt_status?: Maybe<Letsencrypt_Status_Mutation_Response>;
  /** update single row of the table: "letsencrypt_status" */
  update_letsencrypt_status_by_pk?: Maybe<Letsencrypt_Status>;
  /** update multiples rows of table: "letsencrypt_status" */
  update_letsencrypt_status_many?: Maybe<
    Array<Maybe<Letsencrypt_Status_Mutation_Response>>
  >;
  /** update data of the table: "license_activity" */
  update_license_activity?: Maybe<License_Activity_Mutation_Response>;
  /** update single row of the table: "license_activity" */
  update_license_activity_by_pk?: Maybe<License_Activity>;
  /** update multiples rows of table: "license_activity" */
  update_license_activity_many?: Maybe<
    Array<Maybe<License_Activity_Mutation_Response>>
  >;
  /** update data of the table: "license_instance" */
  update_license_instance?: Maybe<License_Instance_Mutation_Response>;
  /** update single row of the table: "license_instance" */
  update_license_instance_by_pk?: Maybe<License_Instance>;
  /** update multiples rows of table: "license_instance" */
  update_license_instance_many?: Maybe<
    Array<Maybe<License_Instance_Mutation_Response>>
  >;
  /** update data of the table: "license_type" */
  update_license_type?: Maybe<License_Type_Mutation_Response>;
  /** update single row of the table: "license_type" */
  update_license_type_by_pk?: Maybe<License_Type>;
  /** update multiples rows of table: "license_type" */
  update_license_type_many?: Maybe<
    Array<Maybe<License_Type_Mutation_Response>>
  >;
  /** update data of the table: "licenses" */
  update_licenses?: Maybe<Licenses_Mutation_Response>;
  /** update single row of the table: "licenses" */
  update_licenses_by_pk?: Maybe<Licenses>;
  /** update multiples rows of table: "licenses" */
  update_licenses_many?: Maybe<Array<Maybe<Licenses_Mutation_Response>>>;
  /** update data of the table: "lux_proxy" */
  update_lux_proxy?: Maybe<Lux_Proxy_Mutation_Response>;
  /** update data of the table: "lux_proxy_ami" */
  update_lux_proxy_ami?: Maybe<Lux_Proxy_Ami_Mutation_Response>;
  /** update single row of the table: "lux_proxy_ami" */
  update_lux_proxy_ami_by_pk?: Maybe<Lux_Proxy_Ami>;
  /** update multiples rows of table: "lux_proxy_ami" */
  update_lux_proxy_ami_many?: Maybe<
    Array<Maybe<Lux_Proxy_Ami_Mutation_Response>>
  >;
  /** update single row of the table: "lux_proxy" */
  update_lux_proxy_by_pk?: Maybe<Lux_Proxy>;
  /** update multiples rows of table: "lux_proxy" */
  update_lux_proxy_many?: Maybe<Array<Maybe<Lux_Proxy_Mutation_Response>>>;
  /** update data of the table: "model_based_enterprise_cloud_commitments" */
  update_model_based_enterprise_cloud_commitments?: Maybe<
    Model_Based_Enterprise_Cloud_Commitments_Mutation_Response
  >;
  /** update single row of the table: "model_based_enterprise_cloud_commitments" */
  update_model_based_enterprise_cloud_commitments_by_pk?: Maybe<
    Model_Based_Enterprise_Cloud_Commitments
  >;
  /** update multiples rows of table: "model_based_enterprise_cloud_commitments" */
  update_model_based_enterprise_cloud_commitments_many?: Maybe<
    Array<Maybe<Model_Based_Enterprise_Cloud_Commitments_Mutation_Response>>
  >;
  /** update data of the table: "neon_db_integration" */
  update_neon_db_integration?: Maybe<Neon_Db_Integration_Mutation_Response>;
  /** update single row of the table: "neon_db_integration" */
  update_neon_db_integration_by_pk?: Maybe<Neon_Db_Integration>;
  /** update multiples rows of table: "neon_db_integration" */
  update_neon_db_integration_many?: Maybe<
    Array<Maybe<Neon_Db_Integration_Mutation_Response>>
  >;
  /** update data of the table: "newrelic_config" */
  update_newrelic_config?: Maybe<Newrelic_Config_Mutation_Response>;
  /** update single row of the table: "newrelic_config" */
  update_newrelic_config_by_pk?: Maybe<Newrelic_Config>;
  /** update multiples rows of table: "newrelic_config" */
  update_newrelic_config_many?: Maybe<
    Array<Maybe<Newrelic_Config_Mutation_Response>>
  >;
  /** update data of the table: "node_pool_type" */
  update_node_pool_type?: Maybe<Node_Pool_Type_Mutation_Response>;
  /** update single row of the table: "node_pool_type" */
  update_node_pool_type_by_pk?: Maybe<Node_Pool_Type>;
  /** update multiples rows of table: "node_pool_type" */
  update_node_pool_type_many?: Maybe<
    Array<Maybe<Node_Pool_Type_Mutation_Response>>
  >;
  /** update data of the table: "node_pools" */
  update_node_pools?: Maybe<Node_Pools_Mutation_Response>;
  /** update single row of the table: "node_pools" */
  update_node_pools_by_pk?: Maybe<Node_Pools>;
  /** update multiples rows of table: "node_pools" */
  update_node_pools_many?: Maybe<Array<Maybe<Node_Pools_Mutation_Response>>>;
  /** update data of the table: "notification" */
  update_notification?: Maybe<Notification_Mutation_Response>;
  /** update single row of the table: "notification" */
  update_notification_by_pk?: Maybe<Notification>;
  /** update multiples rows of table: "notification" */
  update_notification_many?: Maybe<
    Array<Maybe<Notification_Mutation_Response>>
  >;
  /** update data of the table: "notification_type" */
  update_notification_type?: Maybe<Notification_Type_Mutation_Response>;
  /** update single row of the table: "notification_type" */
  update_notification_type_by_pk?: Maybe<Notification_Type>;
  /** update multiples rows of table: "notification_type" */
  update_notification_type_many?: Maybe<
    Array<Maybe<Notification_Type_Mutation_Response>>
  >;
  /** update data of the table: "onboarding_sample_db_cohort" */
  update_onboarding_sample_db_cohort?: Maybe<
    Onboarding_Sample_Db_Cohort_Mutation_Response
  >;
  /** update single row of the table: "onboarding_sample_db_cohort" */
  update_onboarding_sample_db_cohort_by_pk?: Maybe<Onboarding_Sample_Db_Cohort>;
  /** update multiples rows of table: "onboarding_sample_db_cohort" */
  update_onboarding_sample_db_cohort_many?: Maybe<
    Array<Maybe<Onboarding_Sample_Db_Cohort_Mutation_Response>>
  >;
  /** update data of the table: "onboarding_sample_db_config" */
  update_onboarding_sample_db_config?: Maybe<
    Onboarding_Sample_Db_Config_Mutation_Response
  >;
  /** update single row of the table: "onboarding_sample_db_config" */
  update_onboarding_sample_db_config_by_pk?: Maybe<Onboarding_Sample_Db_Config>;
  /** update multiples rows of table: "onboarding_sample_db_config" */
  update_onboarding_sample_db_config_many?: Maybe<
    Array<Maybe<Onboarding_Sample_Db_Config_Mutation_Response>>
  >;
  /** update data of the table: "one_click_deployment" */
  update_one_click_deployment?: Maybe<One_Click_Deployment_Mutation_Response>;
  /** update single row of the table: "one_click_deployment" */
  update_one_click_deployment_by_pk?: Maybe<One_Click_Deployment>;
  /** update multiples rows of table: "one_click_deployment" */
  update_one_click_deployment_many?: Maybe<
    Array<Maybe<One_Click_Deployment_Mutation_Response>>
  >;
  /** update data of the table: "one_click_deployment_sample_apps" */
  update_one_click_deployment_sample_apps?: Maybe<
    One_Click_Deployment_Sample_Apps_Mutation_Response
  >;
  /** update single row of the table: "one_click_deployment_sample_apps" */
  update_one_click_deployment_sample_apps_by_pk?: Maybe<
    One_Click_Deployment_Sample_Apps
  >;
  /** update multiples rows of table: "one_click_deployment_sample_apps" */
  update_one_click_deployment_sample_apps_many?: Maybe<
    Array<Maybe<One_Click_Deployment_Sample_Apps_Mutation_Response>>
  >;
  /** update data of the table: "one_click_deployment_state_log" */
  update_one_click_deployment_state_log?: Maybe<
    One_Click_Deployment_State_Log_Mutation_Response
  >;
  /** update single row of the table: "one_click_deployment_state_log" */
  update_one_click_deployment_state_log_by_pk?: Maybe<
    One_Click_Deployment_State_Log
  >;
  /** update multiples rows of table: "one_click_deployment_state_log" */
  update_one_click_deployment_state_log_many?: Maybe<
    Array<Maybe<One_Click_Deployment_State_Log_Mutation_Response>>
  >;
  /** update data of the table: "one_click_deployment_states" */
  update_one_click_deployment_states?: Maybe<
    One_Click_Deployment_States_Mutation_Response
  >;
  /** update single row of the table: "one_click_deployment_states" */
  update_one_click_deployment_states_by_pk?: Maybe<One_Click_Deployment_States>;
  /** update multiples rows of table: "one_click_deployment_states" */
  update_one_click_deployment_states_many?: Maybe<
    Array<Maybe<One_Click_Deployment_States_Mutation_Response>>
  >;
  /** update data of the table: "opentelemetry_config" */
  update_opentelemetry_config?: Maybe<Opentelemetry_Config_Mutation_Response>;
  /** update single row of the table: "opentelemetry_config" */
  update_opentelemetry_config_by_pk?: Maybe<Opentelemetry_Config>;
  /** update multiples rows of table: "opentelemetry_config" */
  update_opentelemetry_config_many?: Maybe<
    Array<Maybe<Opentelemetry_Config_Mutation_Response>>
  >;
  /** update data of the table: "operations.delete_free_plan_dns_records_nov_2021" */
  update_operations_delete_free_plan_dns_records_nov_2021?: Maybe<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Mutation_Response
  >;
  /** update single row of the table: "operations.delete_free_plan_dns_records_nov_2021" */
  update_operations_delete_free_plan_dns_records_nov_2021_by_pk?: Maybe<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021
  >;
  /** update multiples rows of table: "operations.delete_free_plan_dns_records_nov_2021" */
  update_operations_delete_free_plan_dns_records_nov_2021_many?: Maybe<
    Array<
      Maybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Mutation_Response>
    >
  >;
  /** update data of the table: "operations.tenant" */
  update_operations_tenant?: Maybe<Operations_Tenant_Mutation_Response>;
  /** update multiples rows of table: "operations.tenant" */
  update_operations_tenant_many?: Maybe<
    Array<Maybe<Operations_Tenant_Mutation_Response>>
  >;
  /** update data of the table: "payment_method" */
  update_payment_method?: Maybe<Payment_Method_Mutation_Response>;
  /** update single row of the table: "payment_method" */
  update_payment_method_by_pk?: Maybe<Payment_Method>;
  /** update multiples rows of table: "payment_method" */
  update_payment_method_many?: Maybe<
    Array<Maybe<Payment_Method_Mutation_Response>>
  >;
  /** update data of the table: "plan_entitlements" */
  update_plan_entitlements?: Maybe<Plan_Entitlements_Mutation_Response>;
  /** update single row of the table: "plan_entitlements" */
  update_plan_entitlements_by_pk?: Maybe<Plan_Entitlements>;
  /** update multiples rows of table: "plan_entitlements" */
  update_plan_entitlements_many?: Maybe<
    Array<Maybe<Plan_Entitlements_Mutation_Response>>
  >;
  /** update data of the table: "plans" */
  update_plans?: Maybe<Plans_Mutation_Response>;
  /** update single row of the table: "plans" */
  update_plans_by_pk?: Maybe<Plans>;
  /** update multiples rows of table: "plans" */
  update_plans_many?: Maybe<Array<Maybe<Plans_Mutation_Response>>>;
  /** update data of the table: "policies" */
  update_policies?: Maybe<Policies_Mutation_Response>;
  /** update single row of the table: "policies" */
  update_policies_by_pk?: Maybe<Policies>;
  /** update multiples rows of table: "policies" */
  update_policies_many?: Maybe<Array<Maybe<Policies_Mutation_Response>>>;
  /** update data of the table: "privileges" */
  update_privileges?: Maybe<Privileges_Mutation_Response>;
  /** update single row of the table: "privileges" */
  update_privileges_by_pk?: Maybe<Privileges>;
  /** update multiples rows of table: "privileges" */
  update_privileges_many?: Maybe<Array<Maybe<Privileges_Mutation_Response>>>;
  /** update data of the table: "pro_license_keys" */
  update_pro_license_keys?: Maybe<Pro_License_Keys_Mutation_Response>;
  /** update single row of the table: "pro_license_keys" */
  update_pro_license_keys_by_pk?: Maybe<Pro_License_Keys>;
  /** update multiples rows of table: "pro_license_keys" */
  update_pro_license_keys_many?: Maybe<
    Array<Maybe<Pro_License_Keys_Mutation_Response>>
  >;
  /** update data of the table: "project_activity" */
  update_project_activity?: Maybe<Project_Activity_Mutation_Response>;
  /** update single row of the table: "project_activity" */
  update_project_activity_by_pk?: Maybe<Project_Activity>;
  /** update multiples rows of table: "project_activity" */
  update_project_activity_many?: Maybe<
    Array<Maybe<Project_Activity_Mutation_Response>>
  >;
  /** update data of the table: "project_billing_manager_invitations" */
  update_project_billing_manager_invitations?: Maybe<
    Project_Billing_Manager_Invitations_Mutation_Response
  >;
  /** update single row of the table: "project_billing_manager_invitations" */
  update_project_billing_manager_invitations_by_pk?: Maybe<
    Project_Billing_Manager_Invitations
  >;
  /** update multiples rows of table: "project_billing_manager_invitations" */
  update_project_billing_manager_invitations_many?: Maybe<
    Array<Maybe<Project_Billing_Manager_Invitations_Mutation_Response>>
  >;
  /** update data of the table: "project_collaborator_allowed_schema_tables" */
  update_project_collaborator_allowed_schema_tables?: Maybe<
    Project_Collaborator_Allowed_Schema_Tables_Mutation_Response
  >;
  /** update single row of the table: "project_collaborator_allowed_schema_tables" */
  update_project_collaborator_allowed_schema_tables_by_pk?: Maybe<
    Project_Collaborator_Allowed_Schema_Tables
  >;
  /** update multiples rows of table: "project_collaborator_allowed_schema_tables" */
  update_project_collaborator_allowed_schema_tables_many?: Maybe<
    Array<Maybe<Project_Collaborator_Allowed_Schema_Tables_Mutation_Response>>
  >;
  /** update data of the table: "project_collaborator_allowed_schemas" */
  update_project_collaborator_allowed_schemas?: Maybe<
    Project_Collaborator_Allowed_Schemas_Mutation_Response
  >;
  /** update single row of the table: "project_collaborator_allowed_schemas" */
  update_project_collaborator_allowed_schemas_by_pk?: Maybe<
    Project_Collaborator_Allowed_Schemas
  >;
  /** update multiples rows of table: "project_collaborator_allowed_schemas" */
  update_project_collaborator_allowed_schemas_many?: Maybe<
    Array<Maybe<Project_Collaborator_Allowed_Schemas_Mutation_Response>>
  >;
  /** update data of the table: "project_collaborator_invitations" */
  update_project_collaborator_invitations?: Maybe<
    Project_Collaborator_Invitations_Mutation_Response
  >;
  /** update single row of the table: "project_collaborator_invitations" */
  update_project_collaborator_invitations_by_pk?: Maybe<
    Project_Collaborator_Invitations
  >;
  /** update multiples rows of table: "project_collaborator_invitations" */
  update_project_collaborator_invitations_many?: Maybe<
    Array<Maybe<Project_Collaborator_Invitations_Mutation_Response>>
  >;
  /** update data of the table: "project_collaborator_privileges" */
  update_project_collaborator_privileges?: Maybe<
    Project_Collaborator_Privileges_Mutation_Response
  >;
  /** update single row of the table: "project_collaborator_privileges" */
  update_project_collaborator_privileges_by_pk?: Maybe<
    Project_Collaborator_Privileges
  >;
  /** update multiples rows of table: "project_collaborator_privileges" */
  update_project_collaborator_privileges_many?: Maybe<
    Array<Maybe<Project_Collaborator_Privileges_Mutation_Response>>
  >;
  /** update data of the table: "project_collaborators" */
  update_project_collaborators?: Maybe<Project_Collaborators_Mutation_Response>;
  /** update single row of the table: "project_collaborators" */
  update_project_collaborators_by_pk?: Maybe<Project_Collaborators>;
  /** update multiples rows of table: "project_collaborators" */
  update_project_collaborators_many?: Maybe<
    Array<Maybe<Project_Collaborators_Mutation_Response>>
  >;
  /** update data of the table: "project_data_usage" */
  update_project_data_usage?: Maybe<Project_Data_Usage_Mutation_Response>;
  /** update data of the table: "project_data_usage_agg" */
  update_project_data_usage_agg?: Maybe<
    Project_Data_Usage_Agg_Mutation_Response
  >;
  /** update multiples rows of table: "project_data_usage_agg" */
  update_project_data_usage_agg_many?: Maybe<
    Array<Maybe<Project_Data_Usage_Agg_Mutation_Response>>
  >;
  /** update data of the table: "project_data_usage_agg_user" */
  update_project_data_usage_agg_user?: Maybe<
    Project_Data_Usage_Agg_User_Mutation_Response
  >;
  /** update multiples rows of table: "project_data_usage_agg_user" */
  update_project_data_usage_agg_user_many?: Maybe<
    Array<Maybe<Project_Data_Usage_Agg_User_Mutation_Response>>
  >;
  /** update single row of the table: "project_data_usage" */
  update_project_data_usage_by_pk?: Maybe<Project_Data_Usage>;
  /** update data of the table: "project_data_usage_components" */
  update_project_data_usage_components?: Maybe<
    Project_Data_Usage_Components_Mutation_Response
  >;
  /** update multiples rows of table: "project_data_usage_components" */
  update_project_data_usage_components_many?: Maybe<
    Array<Maybe<Project_Data_Usage_Components_Mutation_Response>>
  >;
  /** update multiples rows of table: "project_data_usage" */
  update_project_data_usage_many?: Maybe<
    Array<Maybe<Project_Data_Usage_Mutation_Response>>
  >;
  /** update data of the table: "project_data_usage_prometheus" */
  update_project_data_usage_prometheus?: Maybe<
    Project_Data_Usage_Prometheus_Mutation_Response
  >;
  /** update data of the table: "project_data_usage_prometheus_agg_user" */
  update_project_data_usage_prometheus_agg_user?: Maybe<
    Project_Data_Usage_Prometheus_Agg_User_Mutation_Response
  >;
  /** update multiples rows of table: "project_data_usage_prometheus_agg_user" */
  update_project_data_usage_prometheus_agg_user_many?: Maybe<
    Array<Maybe<Project_Data_Usage_Prometheus_Agg_User_Mutation_Response>>
  >;
  /** update single row of the table: "project_data_usage_prometheus" */
  update_project_data_usage_prometheus_by_pk?: Maybe<
    Project_Data_Usage_Prometheus
  >;
  /** update multiples rows of table: "project_data_usage_prometheus" */
  update_project_data_usage_prometheus_many?: Maybe<
    Array<Maybe<Project_Data_Usage_Prometheus_Mutation_Response>>
  >;
  /** update data of the table: "project_data_usage_report" */
  update_project_data_usage_report?: Maybe<
    Project_Data_Usage_Report_Mutation_Response
  >;
  /** update single row of the table: "project_data_usage_report" */
  update_project_data_usage_report_by_pk?: Maybe<Project_Data_Usage_Report>;
  /** update multiples rows of table: "project_data_usage_report" */
  update_project_data_usage_report_many?: Maybe<
    Array<Maybe<Project_Data_Usage_Report_Mutation_Response>>
  >;
  /** update data of the table: "project_db_usage" */
  update_project_db_usage?: Maybe<Project_Db_Usage_Mutation_Response>;
  /** update data of the table: "project_db_usage_agg_user" */
  update_project_db_usage_agg_user?: Maybe<
    Project_Db_Usage_Agg_User_Mutation_Response
  >;
  /** update multiples rows of table: "project_db_usage_agg_user" */
  update_project_db_usage_agg_user_many?: Maybe<
    Array<Maybe<Project_Db_Usage_Agg_User_Mutation_Response>>
  >;
  /** update single row of the table: "project_db_usage" */
  update_project_db_usage_by_pk?: Maybe<Project_Db_Usage>;
  /** update multiples rows of table: "project_db_usage" */
  update_project_db_usage_many?: Maybe<
    Array<Maybe<Project_Db_Usage_Mutation_Response>>
  >;
  /** update data of the table: "project_entitlement_access" */
  update_project_entitlement_access?: Maybe<
    Project_Entitlement_Access_Mutation_Response
  >;
  /** update single row of the table: "project_entitlement_access" */
  update_project_entitlement_access_by_pk?: Maybe<Project_Entitlement_Access>;
  /** update multiples rows of table: "project_entitlement_access" */
  update_project_entitlement_access_many?: Maybe<
    Array<Maybe<Project_Entitlement_Access_Mutation_Response>>
  >;
  /** update data of the table: "project_entitlement_catalogue" */
  update_project_entitlement_catalogue?: Maybe<
    Project_Entitlement_Catalogue_Mutation_Response
  >;
  /** update single row of the table: "project_entitlement_catalogue" */
  update_project_entitlement_catalogue_by_pk?: Maybe<
    Project_Entitlement_Catalogue
  >;
  /** update multiples rows of table: "project_entitlement_catalogue" */
  update_project_entitlement_catalogue_many?: Maybe<
    Array<Maybe<Project_Entitlement_Catalogue_Mutation_Response>>
  >;
  /** update data of the table: "project_entitlement_types" */
  update_project_entitlement_types?: Maybe<
    Project_Entitlement_Types_Mutation_Response
  >;
  /** update single row of the table: "project_entitlement_types" */
  update_project_entitlement_types_by_pk?: Maybe<Project_Entitlement_Types>;
  /** update multiples rows of table: "project_entitlement_types" */
  update_project_entitlement_types_many?: Maybe<
    Array<Maybe<Project_Entitlement_Types_Mutation_Response>>
  >;
  /** update data of the table: "project_labels" */
  update_project_labels?: Maybe<Project_Labels_Mutation_Response>;
  /** update single row of the table: "project_labels" */
  update_project_labels_by_pk?: Maybe<Project_Labels>;
  /** update multiples rows of table: "project_labels" */
  update_project_labels_many?: Maybe<
    Array<Maybe<Project_Labels_Mutation_Response>>
  >;
  /** update data of the table: "project_metadata" */
  update_project_metadata?: Maybe<Project_Metadata_Mutation_Response>;
  /** update single row of the table: "project_metadata" */
  update_project_metadata_by_pk?: Maybe<Project_Metadata>;
  /** update multiples rows of table: "project_metadata" */
  update_project_metadata_many?: Maybe<
    Array<Maybe<Project_Metadata_Mutation_Response>>
  >;
  /** update data of the table: "project_notification" */
  update_project_notification?: Maybe<Project_Notification_Mutation_Response>;
  /** update single row of the table: "project_notification" */
  update_project_notification_by_pk?: Maybe<Project_Notification>;
  /** update multiples rows of table: "project_notification" */
  update_project_notification_many?: Maybe<
    Array<Maybe<Project_Notification_Mutation_Response>>
  >;
  /** update data of the table: "project_ownership_transfer_invitations" */
  update_project_ownership_transfer_invitations?: Maybe<
    Project_Ownership_Transfer_Invitations_Mutation_Response
  >;
  /** update single row of the table: "project_ownership_transfer_invitations" */
  update_project_ownership_transfer_invitations_by_pk?: Maybe<
    Project_Ownership_Transfer_Invitations
  >;
  /** update multiples rows of table: "project_ownership_transfer_invitations" */
  update_project_ownership_transfer_invitations_many?: Maybe<
    Array<Maybe<Project_Ownership_Transfer_Invitations_Mutation_Response>>
  >;
  /** update data of the table: "project_requests_count" */
  update_project_requests_count?: Maybe<
    Project_Requests_Count_Mutation_Response
  >;
  /** update single row of the table: "project_requests_count" */
  update_project_requests_count_by_pk?: Maybe<Project_Requests_Count>;
  /** update multiples rows of table: "project_requests_count" */
  update_project_requests_count_many?: Maybe<
    Array<Maybe<Project_Requests_Count_Mutation_Response>>
  >;
  /** update data of the table: "project_stats" */
  update_project_stats?: Maybe<Project_Stats_Mutation_Response>;
  /** update single row of the table: "project_stats" */
  update_project_stats_by_pk?: Maybe<Project_Stats>;
  /** update multiples rows of table: "project_stats" */
  update_project_stats_many?: Maybe<
    Array<Maybe<Project_Stats_Mutation_Response>>
  >;
  /** update data of the table: "project_total_db_usage_agg" */
  update_project_total_db_usage_agg?: Maybe<
    Project_Total_Db_Usage_Agg_Mutation_Response
  >;
  /** update multiples rows of table: "project_total_db_usage_agg" */
  update_project_total_db_usage_agg_many?: Maybe<
    Array<Maybe<Project_Total_Db_Usage_Agg_Mutation_Response>>
  >;
  /** update data of the table: "projects" */
  update_projects?: Maybe<Projects_Mutation_Response>;
  /** update single row of the table: "projects" */
  update_projects_by_pk?: Maybe<Projects>;
  /** update multiples rows of table: "projects" */
  update_projects_many?: Maybe<Array<Maybe<Projects_Mutation_Response>>>;
  /** update data of the table: "projects_pool_config" */
  update_projects_pool_config?: Maybe<Projects_Pool_Config_Mutation_Response>;
  /** update single row of the table: "projects_pool_config" */
  update_projects_pool_config_by_pk?: Maybe<Projects_Pool_Config>;
  /** update multiples rows of table: "projects_pool_config" */
  update_projects_pool_config_many?: Maybe<
    Array<Maybe<Projects_Pool_Config_Mutation_Response>>
  >;
  /** update data of the table: "projects_pro_key_generations" */
  update_projects_pro_key_generations?: Maybe<
    Projects_Pro_Key_Generations_Mutation_Response
  >;
  /** update single row of the table: "projects_pro_key_generations" */
  update_projects_pro_key_generations_by_pk?: Maybe<
    Projects_Pro_Key_Generations
  >;
  /** update multiples rows of table: "projects_pro_key_generations" */
  update_projects_pro_key_generations_many?: Maybe<
    Array<Maybe<Projects_Pro_Key_Generations_Mutation_Response>>
  >;
  /** update data of the table: "prometheus_config" */
  update_prometheus_config?: Maybe<Prometheus_Config_Mutation_Response>;
  /** update single row of the table: "prometheus_config" */
  update_prometheus_config_by_pk?: Maybe<Prometheus_Config>;
  /** update multiples rows of table: "prometheus_config" */
  update_prometheus_config_many?: Maybe<
    Array<Maybe<Prometheus_Config_Mutation_Response>>
  >;
  /** update data of the table: "providers" */
  update_providers?: Maybe<Providers_Mutation_Response>;
  /** update single row of the table: "providers" */
  update_providers_by_pk?: Maybe<Providers>;
  /** update multiples rows of table: "providers" */
  update_providers_many?: Maybe<Array<Maybe<Providers_Mutation_Response>>>;
  /** update data of the table: "provisioning" */
  update_provisioning?: Maybe<Provisioning_Mutation_Response>;
  /** update single row of the table: "provisioning" */
  update_provisioning_by_pk?: Maybe<Provisioning>;
  /** update multiples rows of table: "provisioning" */
  update_provisioning_many?: Maybe<
    Array<Maybe<Provisioning_Mutation_Response>>
  >;
  /** update data of the table: "region" */
  update_region?: Maybe<Region_Mutation_Response>;
  /** update single row of the table: "region" */
  update_region_by_pk?: Maybe<Region>;
  /** update multiples rows of table: "region" */
  update_region_many?: Maybe<Array<Maybe<Region_Mutation_Response>>>;
  /** update data of the table: "region_v2" */
  update_region_v2?: Maybe<Region_V2_Mutation_Response>;
  /** update multiples rows of table: "region_v2" */
  update_region_v2_many?: Maybe<Array<Maybe<Region_V2_Mutation_Response>>>;
  /** update data of the table: "regional_data_connectors" */
  update_regional_data_connectors?: Maybe<
    Regional_Data_Connectors_Mutation_Response
  >;
  /** update single row of the table: "regional_data_connectors" */
  update_regional_data_connectors_by_pk?: Maybe<Regional_Data_Connectors>;
  /** update multiples rows of table: "regional_data_connectors" */
  update_regional_data_connectors_many?: Maybe<
    Array<Maybe<Regional_Data_Connectors_Mutation_Response>>
  >;
  /** update data of the table: "regional_metrics" */
  update_regional_metrics?: Maybe<Regional_Metrics_Mutation_Response>;
  /** update single row of the table: "regional_metrics" */
  update_regional_metrics_by_pk?: Maybe<Regional_Metrics>;
  /** update multiples rows of table: "regional_metrics" */
  update_regional_metrics_many?: Maybe<
    Array<Maybe<Regional_Metrics_Mutation_Response>>
  >;
  /** update data of the table: "saml_idp" */
  update_saml_idp?: Maybe<Saml_Idp_Mutation_Response>;
  /** update single row of the table: "saml_idp" */
  update_saml_idp_by_pk?: Maybe<Saml_Idp>;
  /** update multiples rows of table: "saml_idp" */
  update_saml_idp_many?: Maybe<Array<Maybe<Saml_Idp_Mutation_Response>>>;
  /** update data of the table: "search_project_login_status_results" */
  update_search_project_login_status_results?: Maybe<
    Search_Project_Login_Status_Results_Mutation_Response
  >;
  /** update multiples rows of table: "search_project_login_status_results" */
  update_search_project_login_status_results_many?: Maybe<
    Array<Maybe<Search_Project_Login_Status_Results_Mutation_Response>>
  >;
  /** update data of the table: "slack_config" */
  update_slack_config?: Maybe<Slack_Config_Mutation_Response>;
  /** update single row of the table: "slack_config" */
  update_slack_config_by_pk?: Maybe<Slack_Config>;
  /** update multiples rows of table: "slack_config" */
  update_slack_config_many?: Maybe<
    Array<Maybe<Slack_Config_Mutation_Response>>
  >;
  /** update data of the table: "stripe_subscription" */
  update_stripe_subscription?: Maybe<Stripe_Subscription_Mutation_Response>;
  /** update single row of the table: "stripe_subscription" */
  update_stripe_subscription_by_pk?: Maybe<Stripe_Subscription>;
  /** update multiples rows of table: "stripe_subscription" */
  update_stripe_subscription_many?: Maybe<
    Array<Maybe<Stripe_Subscription_Mutation_Response>>
  >;
  /** update data of the table: "stripe_webhook_events" */
  update_stripe_webhook_events?: Maybe<Stripe_Webhook_Events_Mutation_Response>;
  /** update single row of the table: "stripe_webhook_events" */
  update_stripe_webhook_events_by_pk?: Maybe<Stripe_Webhook_Events>;
  /** update multiples rows of table: "stripe_webhook_events" */
  update_stripe_webhook_events_many?: Maybe<
    Array<Maybe<Stripe_Webhook_Events_Mutation_Response>>
  >;
  /** update data of the table: "super_connector_types" */
  update_super_connector_types?: Maybe<Super_Connector_Types_Mutation_Response>;
  /** update single row of the table: "super_connector_types" */
  update_super_connector_types_by_pk?: Maybe<Super_Connector_Types>;
  /** update multiples rows of table: "super_connector_types" */
  update_super_connector_types_many?: Maybe<
    Array<Maybe<Super_Connector_Types_Mutation_Response>>
  >;
  /** update data of the table: "support_plan_types" */
  update_support_plan_types?: Maybe<Support_Plan_Types_Mutation_Response>;
  /** update single row of the table: "support_plan_types" */
  update_support_plan_types_by_pk?: Maybe<Support_Plan_Types>;
  /** update multiples rows of table: "support_plan_types" */
  update_support_plan_types_many?: Maybe<
    Array<Maybe<Support_Plan_Types_Mutation_Response>>
  >;
  /** update data of the table: "survey" */
  update_survey?: Maybe<Survey_Mutation_Response>;
  /** update single row of the table: "survey" */
  update_survey_by_pk?: Maybe<Survey>;
  /** update multiples rows of table: "survey" */
  update_survey_many?: Maybe<Array<Maybe<Survey_Mutation_Response>>>;
  /** update data of the table: "survey_question" */
  update_survey_question?: Maybe<Survey_Question_Mutation_Response>;
  /** update data of the table: "survey_question_answer_option" */
  update_survey_question_answer_option?: Maybe<
    Survey_Question_Answer_Option_Mutation_Response
  >;
  /** update single row of the table: "survey_question_answer_option" */
  update_survey_question_answer_option_by_pk?: Maybe<
    Survey_Question_Answer_Option
  >;
  /** update multiples rows of table: "survey_question_answer_option" */
  update_survey_question_answer_option_many?: Maybe<
    Array<Maybe<Survey_Question_Answer_Option_Mutation_Response>>
  >;
  /** update data of the table: "survey_question_answers" */
  update_survey_question_answers?: Maybe<
    Survey_Question_Answers_Mutation_Response
  >;
  /** update single row of the table: "survey_question_answers" */
  update_survey_question_answers_by_pk?: Maybe<Survey_Question_Answers>;
  /** update multiples rows of table: "survey_question_answers" */
  update_survey_question_answers_many?: Maybe<
    Array<Maybe<Survey_Question_Answers_Mutation_Response>>
  >;
  /** update single row of the table: "survey_question" */
  update_survey_question_by_pk?: Maybe<Survey_Question>;
  /** update data of the table: "survey_question_kind" */
  update_survey_question_kind?: Maybe<Survey_Question_Kind_Mutation_Response>;
  /** update single row of the table: "survey_question_kind" */
  update_survey_question_kind_by_pk?: Maybe<Survey_Question_Kind>;
  /** update multiples rows of table: "survey_question_kind" */
  update_survey_question_kind_many?: Maybe<
    Array<Maybe<Survey_Question_Kind_Mutation_Response>>
  >;
  /** update multiples rows of table: "survey_question" */
  update_survey_question_many?: Maybe<
    Array<Maybe<Survey_Question_Mutation_Response>>
  >;
  /** update data of the table: "survey_question_options" */
  update_survey_question_options?: Maybe<
    Survey_Question_Options_Mutation_Response
  >;
  /** update single row of the table: "survey_question_options" */
  update_survey_question_options_by_pk?: Maybe<Survey_Question_Options>;
  /** update multiples rows of table: "survey_question_options" */
  update_survey_question_options_many?: Maybe<
    Array<Maybe<Survey_Question_Options_Mutation_Response>>
  >;
  /** update data of the table: "survey_v2" */
  update_survey_v2?: Maybe<Survey_V2_Mutation_Response>;
  /** update single row of the table: "survey_v2" */
  update_survey_v2_by_pk?: Maybe<Survey_V2>;
  /** update multiples rows of table: "survey_v2" */
  update_survey_v2_many?: Maybe<Array<Maybe<Survey_V2_Mutation_Response>>>;
  /** update data of the table: "survey_v2_question" */
  update_survey_v2_question?: Maybe<Survey_V2_Question_Mutation_Response>;
  /** update single row of the table: "survey_v2_question" */
  update_survey_v2_question_by_pk?: Maybe<Survey_V2_Question>;
  /** update data of the table: "survey_v2_question_kind" */
  update_survey_v2_question_kind?: Maybe<
    Survey_V2_Question_Kind_Mutation_Response
  >;
  /** update single row of the table: "survey_v2_question_kind" */
  update_survey_v2_question_kind_by_pk?: Maybe<Survey_V2_Question_Kind>;
  /** update multiples rows of table: "survey_v2_question_kind" */
  update_survey_v2_question_kind_many?: Maybe<
    Array<Maybe<Survey_V2_Question_Kind_Mutation_Response>>
  >;
  /** update multiples rows of table: "survey_v2_question" */
  update_survey_v2_question_many?: Maybe<
    Array<Maybe<Survey_V2_Question_Mutation_Response>>
  >;
  /** update data of the table: "survey_v2_question_option" */
  update_survey_v2_question_option?: Maybe<
    Survey_V2_Question_Option_Mutation_Response
  >;
  /** update data of the table: "survey_v2_question_option_additional_info_config" */
  update_survey_v2_question_option_additional_info_config?: Maybe<
    Survey_V2_Question_Option_Additional_Info_Config_Mutation_Response
  >;
  /** update single row of the table: "survey_v2_question_option_additional_info_config" */
  update_survey_v2_question_option_additional_info_config_by_pk?: Maybe<
    Survey_V2_Question_Option_Additional_Info_Config
  >;
  /** update multiples rows of table: "survey_v2_question_option_additional_info_config" */
  update_survey_v2_question_option_additional_info_config_many?: Maybe<
    Array<
      Maybe<Survey_V2_Question_Option_Additional_Info_Config_Mutation_Response>
    >
  >;
  /** update single row of the table: "survey_v2_question_option" */
  update_survey_v2_question_option_by_pk?: Maybe<Survey_V2_Question_Option>;
  /** update multiples rows of table: "survey_v2_question_option" */
  update_survey_v2_question_option_many?: Maybe<
    Array<Maybe<Survey_V2_Question_Option_Mutation_Response>>
  >;
  /** update data of the table: "survey_v2_response" */
  update_survey_v2_response?: Maybe<Survey_V2_Response_Mutation_Response>;
  /** update data of the table: "survey_v2_response_answer" */
  update_survey_v2_response_answer?: Maybe<
    Survey_V2_Response_Answer_Mutation_Response
  >;
  /** update single row of the table: "survey_v2_response_answer" */
  update_survey_v2_response_answer_by_pk?: Maybe<Survey_V2_Response_Answer>;
  /** update multiples rows of table: "survey_v2_response_answer" */
  update_survey_v2_response_answer_many?: Maybe<
    Array<Maybe<Survey_V2_Response_Answer_Mutation_Response>>
  >;
  /** update data of the table: "survey_v2_response_answer_option" */
  update_survey_v2_response_answer_option?: Maybe<
    Survey_V2_Response_Answer_Option_Mutation_Response
  >;
  /** update single row of the table: "survey_v2_response_answer_option" */
  update_survey_v2_response_answer_option_by_pk?: Maybe<
    Survey_V2_Response_Answer_Option
  >;
  /** update multiples rows of table: "survey_v2_response_answer_option" */
  update_survey_v2_response_answer_option_many?: Maybe<
    Array<Maybe<Survey_V2_Response_Answer_Option_Mutation_Response>>
  >;
  /** update single row of the table: "survey_v2_response" */
  update_survey_v2_response_by_pk?: Maybe<Survey_V2_Response>;
  /** update multiples rows of table: "survey_v2_response" */
  update_survey_v2_response_many?: Maybe<
    Array<Maybe<Survey_V2_Response_Mutation_Response>>
  >;
  /** update data of the table: "task_event" */
  update_task_event?: Maybe<Task_Event_Mutation_Response>;
  /** update single row of the table: "task_event" */
  update_task_event_by_pk?: Maybe<Task_Event>;
  /** update multiples rows of table: "task_event" */
  update_task_event_many?: Maybe<Array<Maybe<Task_Event_Mutation_Response>>>;
  /** update data of the table: "tasks" */
  update_tasks?: Maybe<Tasks_Mutation_Response>;
  /** update single row of the table: "tasks" */
  update_tasks_by_pk?: Maybe<Tasks>;
  /** update multiples rows of table: "tasks" */
  update_tasks_many?: Maybe<Array<Maybe<Tasks_Mutation_Response>>>;
  /** update data of the table: "tenant" */
  update_tenant?: Maybe<Tenant_Mutation_Response>;
  /** update data of the table: "tenant_active_status_reason" */
  update_tenant_active_status_reason?: Maybe<
    Tenant_Active_Status_Reason_Mutation_Response
  >;
  /** update single row of the table: "tenant_active_status_reason" */
  update_tenant_active_status_reason_by_pk?: Maybe<Tenant_Active_Status_Reason>;
  /** update multiples rows of table: "tenant_active_status_reason" */
  update_tenant_active_status_reason_many?: Maybe<
    Array<Maybe<Tenant_Active_Status_Reason_Mutation_Response>>
  >;
  /** update single row of the table: "tenant" */
  update_tenant_by_pk?: Maybe<Tenant>;
  /** update data of the table: "tenant_group" */
  update_tenant_group?: Maybe<Tenant_Group_Mutation_Response>;
  /** update single row of the table: "tenant_group" */
  update_tenant_group_by_pk?: Maybe<Tenant_Group>;
  /** update multiples rows of table: "tenant_group" */
  update_tenant_group_many?: Maybe<
    Array<Maybe<Tenant_Group_Mutation_Response>>
  >;
  /** update data of the table: "tenant_group_member" */
  update_tenant_group_member?: Maybe<Tenant_Group_Member_Mutation_Response>;
  /** update single row of the table: "tenant_group_member" */
  update_tenant_group_member_by_pk?: Maybe<Tenant_Group_Member>;
  /** update multiples rows of table: "tenant_group_member" */
  update_tenant_group_member_many?: Maybe<
    Array<Maybe<Tenant_Group_Member_Mutation_Response>>
  >;
  /** update multiples rows of table: "tenant" */
  update_tenant_many?: Maybe<Array<Maybe<Tenant_Mutation_Response>>>;
  /** update data of the table: "tenant_public_status" */
  update_tenant_public_status?: Maybe<Tenant_Public_Status_Mutation_Response>;
  /** update single row of the table: "tenant_public_status" */
  update_tenant_public_status_by_pk?: Maybe<Tenant_Public_Status>;
  /** update multiples rows of table: "tenant_public_status" */
  update_tenant_public_status_many?: Maybe<
    Array<Maybe<Tenant_Public_Status_Mutation_Response>>
  >;
  /** update data of the table: "tenant_status" */
  update_tenant_status?: Maybe<Tenant_Status_Mutation_Response>;
  /** update single row of the table: "tenant_status" */
  update_tenant_status_by_pk?: Maybe<Tenant_Status>;
  /** update multiples rows of table: "tenant_status" */
  update_tenant_status_many?: Maybe<
    Array<Maybe<Tenant_Status_Mutation_Response>>
  >;
  /** update data of the table: "trial_leads" */
  update_trial_leads?: Maybe<Trial_Leads_Mutation_Response>;
  /** update single row of the table: "trial_leads" */
  update_trial_leads_by_pk?: Maybe<Trial_Leads>;
  /** update multiples rows of table: "trial_leads" */
  update_trial_leads_many?: Maybe<Array<Maybe<Trial_Leads_Mutation_Response>>>;
  /** update data of the table: "ua_audit_logs" */
  update_ua_audit_logs?: Maybe<Ua_Audit_Logs_Mutation_Response>;
  /** update single row of the table: "ua_audit_logs" */
  update_ua_audit_logs_by_pk?: Maybe<Ua_Audit_Logs>;
  /** update multiples rows of table: "ua_audit_logs" */
  update_ua_audit_logs_many?: Maybe<
    Array<Maybe<Ua_Audit_Logs_Mutation_Response>>
  >;
  /** update data of the table: "user_activity" */
  update_user_activity?: Maybe<User_Activity_Mutation_Response>;
  /** update single row of the table: "user_activity" */
  update_user_activity_by_pk?: Maybe<User_Activity>;
  /** update multiples rows of table: "user_activity" */
  update_user_activity_many?: Maybe<
    Array<Maybe<User_Activity_Mutation_Response>>
  >;
  /** update data of the table: "user_coupon" */
  update_user_coupon?: Maybe<User_Coupon_Mutation_Response>;
  /** update single row of the table: "user_coupon" */
  update_user_coupon_by_pk?: Maybe<User_Coupon>;
  /** update multiples rows of table: "user_coupon" */
  update_user_coupon_many?: Maybe<Array<Maybe<User_Coupon_Mutation_Response>>>;
  /** update data of the table: "user_entitlement_access" */
  update_user_entitlement_access?: Maybe<
    User_Entitlement_Access_Mutation_Response
  >;
  /** update single row of the table: "user_entitlement_access" */
  update_user_entitlement_access_by_pk?: Maybe<User_Entitlement_Access>;
  /** update multiples rows of table: "user_entitlement_access" */
  update_user_entitlement_access_many?: Maybe<
    Array<Maybe<User_Entitlement_Access_Mutation_Response>>
  >;
  /** update data of the table: "user_entitlement_catalogue" */
  update_user_entitlement_catalogue?: Maybe<
    User_Entitlement_Catalogue_Mutation_Response
  >;
  /** update single row of the table: "user_entitlement_catalogue" */
  update_user_entitlement_catalogue_by_pk?: Maybe<User_Entitlement_Catalogue>;
  /** update multiples rows of table: "user_entitlement_catalogue" */
  update_user_entitlement_catalogue_many?: Maybe<
    Array<Maybe<User_Entitlement_Catalogue_Mutation_Response>>
  >;
  /** update data of the table: "user_entitlement_types" */
  update_user_entitlement_types?: Maybe<
    User_Entitlement_Types_Mutation_Response
  >;
  /** update single row of the table: "user_entitlement_types" */
  update_user_entitlement_types_by_pk?: Maybe<User_Entitlement_Types>;
  /** update multiples rows of table: "user_entitlement_types" */
  update_user_entitlement_types_many?: Maybe<
    Array<Maybe<User_Entitlement_Types_Mutation_Response>>
  >;
  /** update data of the table: "user_onboarding" */
  update_user_onboarding?: Maybe<User_Onboarding_Mutation_Response>;
  /** update single row of the table: "user_onboarding" */
  update_user_onboarding_by_pk?: Maybe<User_Onboarding>;
  /** update multiples rows of table: "user_onboarding" */
  update_user_onboarding_many?: Maybe<
    Array<Maybe<User_Onboarding_Mutation_Response>>
  >;
  /** update data of the table: "user_personal_access_tokens" */
  update_user_personal_access_tokens?: Maybe<
    User_Personal_Access_Tokens_Mutation_Response
  >;
  /** update single row of the table: "user_personal_access_tokens" */
  update_user_personal_access_tokens_by_pk?: Maybe<User_Personal_Access_Tokens>;
  /** update multiples rows of table: "user_personal_access_tokens" */
  update_user_personal_access_tokens_many?: Maybe<
    Array<Maybe<User_Personal_Access_Tokens_Mutation_Response>>
  >;
  /** update data of the table: "user_profile" */
  update_user_profile?: Maybe<User_Profile_Mutation_Response>;
  /** update single row of the table: "user_profile" */
  update_user_profile_by_pk?: Maybe<User_Profile>;
  /** update multiples rows of table: "user_profile" */
  update_user_profile_many?: Maybe<
    Array<Maybe<User_Profile_Mutation_Response>>
  >;
  /** update data of the table: "user_roles" */
  update_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** update single row of the table: "user_roles" */
  update_user_roles_by_pk?: Maybe<User_Roles>;
  /** update multiples rows of table: "user_roles" */
  update_user_roles_many?: Maybe<Array<Maybe<User_Roles_Mutation_Response>>>;
  /** update data of the table: "user_vpc_policy" */
  update_user_vpc_policy?: Maybe<User_Vpc_Policy_Mutation_Response>;
  /** update single row of the table: "user_vpc_policy" */
  update_user_vpc_policy_by_pk?: Maybe<User_Vpc_Policy>;
  /** update multiples rows of table: "user_vpc_policy" */
  update_user_vpc_policy_many?: Maybe<
    Array<Maybe<User_Vpc_Policy_Mutation_Response>>
  >;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "users_public" */
  update_users_public?: Maybe<Users_Public_Mutation_Response>;
  /** update multiples rows of table: "users_public" */
  update_users_public_many?: Maybe<
    Array<Maybe<Users_Public_Mutation_Response>>
  >;
  /** update data of the table: "vercel_integration" */
  update_vercel_integration?: Maybe<Vercel_Integration_Mutation_Response>;
  /** update single row of the table: "vercel_integration" */
  update_vercel_integration_by_pk?: Maybe<Vercel_Integration>;
  /** update data of the table: "vercel_integration_connections" */
  update_vercel_integration_connections?: Maybe<
    Vercel_Integration_Connections_Mutation_Response
  >;
  /** update single row of the table: "vercel_integration_connections" */
  update_vercel_integration_connections_by_pk?: Maybe<
    Vercel_Integration_Connections
  >;
  /** update multiples rows of table: "vercel_integration_connections" */
  update_vercel_integration_connections_many?: Maybe<
    Array<Maybe<Vercel_Integration_Connections_Mutation_Response>>
  >;
  /** update multiples rows of table: "vercel_integration" */
  update_vercel_integration_many?: Maybe<
    Array<Maybe<Vercel_Integration_Mutation_Response>>
  >;
  /** update data of the table: "vpc_collaborator_invitations" */
  update_vpc_collaborator_invitations?: Maybe<
    Vpc_Collaborator_Invitations_Mutation_Response
  >;
  /** update single row of the table: "vpc_collaborator_invitations" */
  update_vpc_collaborator_invitations_by_pk?: Maybe<
    Vpc_Collaborator_Invitations
  >;
  /** update multiples rows of table: "vpc_collaborator_invitations" */
  update_vpc_collaborator_invitations_many?: Maybe<
    Array<Maybe<Vpc_Collaborator_Invitations_Mutation_Response>>
  >;
  /** update data of the table: "vpc_collaborators" */
  update_vpc_collaborators?: Maybe<Vpc_Collaborators_Mutation_Response>;
  /** update single row of the table: "vpc_collaborators" */
  update_vpc_collaborators_by_pk?: Maybe<Vpc_Collaborators>;
  /** update multiples rows of table: "vpc_collaborators" */
  update_vpc_collaborators_many?: Maybe<
    Array<Maybe<Vpc_Collaborators_Mutation_Response>>
  >;
  /** update data of the table: "vpc_peering" */
  update_vpc_peering?: Maybe<Vpc_Peering_Mutation_Response>;
  /** update single row of the table: "vpc_peering" */
  update_vpc_peering_by_pk?: Maybe<Vpc_Peering>;
  /** update data of the table: "vpc_peering_direction" */
  update_vpc_peering_direction?: Maybe<Vpc_Peering_Direction_Mutation_Response>;
  /** update single row of the table: "vpc_peering_direction" */
  update_vpc_peering_direction_by_pk?: Maybe<Vpc_Peering_Direction>;
  /** update multiples rows of table: "vpc_peering_direction" */
  update_vpc_peering_direction_many?: Maybe<
    Array<Maybe<Vpc_Peering_Direction_Mutation_Response>>
  >;
  /** update multiples rows of table: "vpc_peering" */
  update_vpc_peering_many?: Maybe<Array<Maybe<Vpc_Peering_Mutation_Response>>>;
  /** update data of the table: "vpc_privileges" */
  update_vpc_privileges?: Maybe<Vpc_Privileges_Mutation_Response>;
  /** update single row of the table: "vpc_privileges" */
  update_vpc_privileges_by_pk?: Maybe<Vpc_Privileges>;
  /** update multiples rows of table: "vpc_privileges" */
  update_vpc_privileges_many?: Maybe<
    Array<Maybe<Vpc_Privileges_Mutation_Response>>
  >;
  /** update data of the table: "vpc_status" */
  update_vpc_status?: Maybe<Vpc_Status_Mutation_Response>;
  /** update single row of the table: "vpc_status" */
  update_vpc_status_by_pk?: Maybe<Vpc_Status>;
  /** update multiples rows of table: "vpc_status" */
  update_vpc_status_many?: Maybe<Array<Maybe<Vpc_Status_Mutation_Response>>>;
  /** update data of the table: "zendesk_support_category" */
  update_zendesk_support_category?: Maybe<
    Zendesk_Support_Category_Mutation_Response
  >;
  /** update single row of the table: "zendesk_support_category" */
  update_zendesk_support_category_by_pk?: Maybe<Zendesk_Support_Category>;
  /** update multiples rows of table: "zendesk_support_category" */
  update_zendesk_support_category_many?: Maybe<
    Array<Maybe<Zendesk_Support_Category_Mutation_Response>>
  >;
  /** update data of the table: "zendesk_support_tickets" */
  update_zendesk_support_tickets?: Maybe<
    Zendesk_Support_Tickets_Mutation_Response
  >;
  /** update single row of the table: "zendesk_support_tickets" */
  update_zendesk_support_tickets_by_pk?: Maybe<Zendesk_Support_Tickets>;
  /** update multiples rows of table: "zendesk_support_tickets" */
  update_zendesk_support_tickets_many?: Maybe<
    Array<Maybe<Zendesk_Support_Tickets_Mutation_Response>>
  >;
  /** Setup/Update Vercel integration */
  vercelSetIntegration?: Maybe<SetVercelIntegrationOutput>;
  vercelTokenExchange?: Maybe<SuccessOrError>;
};

/** mutation root */
export type Mutation_RootAcceptBillingManagerInviteArgs = {
  key: Scalars['String'];
};

/** mutation root */
export type Mutation_RootAcceptInviteArgs = {
  inviteKey: Scalars['String'];
};

/** mutation root */
export type Mutation_RootAcceptTransferOwnershipInviteArgs = {
  inviteKey: Scalars['String'];
};

/** mutation root */
export type Mutation_RootAcceptVpcCollaboratorInvitationArgs = {
  invitation_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootAddAzureMonitorConfigArgs = {
  activedirectory_client_id: Scalars['String'];
  activedirectory_client_secret: Scalars['String'];
  activedirectory_tenant_id: Scalars['String'];
  attributes?: InputMaybe<Scalars['jsonb']>;
  azure_region: Scalars['String'];
  azuremonitor_workspace_id: Scalars['String'];
  instrumentation_key?: InputMaybe<Scalars['String']>;
  log_type: Scalars['String'];
  metric_namespace: Scalars['String'];
  project_id: Scalars['uuid'];
  resource_id: Scalars['String'];
  shared_key: Scalars['String'];
};

/** mutation root */
export type Mutation_RootAddCardArgs = {
  id: Scalars['String'];
  is_default: Scalars['Boolean'];
};

/** mutation root */
export type Mutation_RootAddCustomDomainArgs = {
  fqdn: Scalars['String'];
  tenant_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootAddDatadogConfigArgs = {
  datadog_region: Scalars['String'];
  host?: InputMaybe<Scalars['String']>;
  key: Scalars['String'];
  project_id: Scalars['uuid'];
  service_name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootAddFeatureRequestArgs = {
  payload: FeatureRequest;
};

/** mutation root */
export type Mutation_RootAddNewrelicConfigArgs = {
  attributes: Scalars['jsonb'];
  key: Scalars['String'];
  newrelic_region: Scalars['String'];
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootAddOpentelemetryConfigArgs = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  batch_size?: InputMaybe<Scalars['Int']>;
  connection_type: Scalars['String'];
  endpoint: Scalars['String'];
  export_traces?: InputMaybe<Scalars['Boolean']>;
  headers?: InputMaybe<Scalars['jsonb']>;
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootAddProjectArgs = {
  project: AddProjectInput;
};

/** mutation root */
export type Mutation_RootAddPrometheusConfigArgs = {
  labels?: InputMaybe<Scalars['jsonb']>;
  namespace?: InputMaybe<Scalars['String']>;
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootApplyCouponCodeArgs = {
  coupon: Scalars['String'];
};

/** mutation root */
export type Mutation_RootChangePlanArgs = {
  plan: Plan;
  projectId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootChangeUserEmailArgs = {
  input: ChangeUserEmailRequestInput;
};

/** mutation root */
export type Mutation_RootCheckDbLatencyArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootCompleteChangeUserEmailRequestArgs = {
  input: ChangeUserEmailRequestKeyInput;
};

/** mutation root */
export type Mutation_RootConvertToDedicatedCloudArgs = {
  plan?: InputMaybe<Scalars['String']>;
  projectId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootCreateDedicatedVpcArgs = {
  cidr: Scalars['String'];
  cloud: Scalars['String'];
  name: Scalars['String'];
  plan_name: Scalars['String'];
  region: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateGitHubPreviewAppArgs = {
  payload?: InputMaybe<CreateGithubPreviewAppInputPayload>;
};

/** mutation root */
export type Mutation_RootCreateGithubIntegrationArgs = {
  payload: GithubIntegrationInput;
};

/** mutation root */
export type Mutation_RootCreateInvoiceArgs = {
  args?: InputMaybe<CreateInvoiceInput>;
};

/** mutation root */
export type Mutation_RootCreateJobArgs = {
  input: CreateJobInput;
};

/** mutation root */
export type Mutation_RootCreateOneClickDeploymentArgs = {
  payload: CreateOneClickDeploymentInputPayload;
};

/** mutation root */
export type Mutation_RootCreatePersonalAccessTokenArgs = {
  token: PersonalAccessTokenInput;
};

/** mutation root */
export type Mutation_RootCreateTenantArgs = {
  cloud: Scalars['String'];
  dedicatedVPCID?: InputMaybe<Scalars['String']>;
  envs?: InputMaybe<Array<InputMaybe<UpdateEnvsObject>>>;
  name?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<Scalars['String']>;
  projectMetadata?: InputMaybe<ProjectMiscMetadata>;
  region: Scalars['String'];
};

/** mutation root */
export type Mutation_RootCreateZendeskSupportTicketArgs = {
  body: Scalars['String'];
  priority?: InputMaybe<SupportTicketPriority>;
  project_id?: InputMaybe<Scalars['uuid']>;
  subject: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDdnAcceptCollaborationInvitationArgs = {
  invitation_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDdnAcceptProjectCollaborationRequestArgs = {
  invitation_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDdnActivateProjectCollaboratorArgs = {
  collaborator_user_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDdnApplyMetadataArgs = {
  build_id?: InputMaybe<Scalars['String']>;
  build_version?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  project_name?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDdnApplySubgraphBuildArgs = {
  project_name: Scalars['String'];
  subgraph_build_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDdnBackfillBuildGatewayRulesArgs = {
  dry_run: Scalars['Boolean'];
  uuids: Array<InputMaybe<Scalars['uuid']>>;
};

/** mutation root */
export type Mutation_RootDdnBackfillBuildGatewayRulesByTimerangeArgs = {
  dry_run: Scalars['Boolean'];
  end_time: Scalars['timestamptz'];
  start_time: Scalars['timestamptz'];
};

/** mutation root */
export type Mutation_RootDdnBackfillEnvironmentBuildsInBuildstoreArgs = {
  dry_run: Scalars['Boolean'];
};

/** mutation root */
export type Mutation_RootDdnBackfillEnvironmentGatewayRulesArgs = {
  dry_run: Scalars['Boolean'];
  uuids: Array<InputMaybe<Scalars['uuid']>>;
};

/** mutation root */
export type Mutation_RootDdnBackfillEnvironmentGatewayRulesByTimerangeArgs = {
  dry_run: Scalars['Boolean'];
  end_time: Scalars['timestamptz'];
  start_time: Scalars['timestamptz'];
};

/** mutation root */
export type Mutation_RootDdnCancelProjectCollaborationInvitationArgs = {
  invitation_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDdnChangePlanArgs = {
  plan: DdnPlan;
  projectName: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDdnCreateBuildArgs = {
  description?: InputMaybe<Scalars['String']>;
  dry_run?: InputMaybe<Scalars['Boolean']>;
  environment: Scalars['String'];
  envs?: InputMaybe<EnvironmentVariablesV3>;
  metadata_json: Scalars['String'];
  patch_on_build?: InputMaybe<Scalars['String']>;
  patch_on_environment?: InputMaybe<PatchOnEnv>;
  project_id?: InputMaybe<Scalars['String']>;
  project_name?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDdnCreateEnvironmentArgs = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDdnCreateHttpTunnelsArgs = {
  ddn_id?: InputMaybe<Scalars['uuid']>;
  quantity?: InputMaybe<Scalars['Int']>;
};

/** mutation root */
export type Mutation_RootDdnCreateInvoiceArgs = {
  args?: InputMaybe<DdnCreateInvoiceInput>;
};

/** mutation root */
export type Mutation_RootDdnCreateProjectArgs = {
  cloud?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<Scalars['String']>;
  private_ddn_id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDdnCreateProjectV2Args = {
  cloud?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<Scalars['String']>;
  private_ddn_id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDdnCreateSubgraphArgs = {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDdnCreateSubgraphBuildArgs = {
  description?: InputMaybe<Scalars['String']>;
  envs?: InputMaybe<EnvironmentVariablesV3>;
  metadata_json: Scalars['String'];
  project_name?: InputMaybe<Scalars['String']>;
  subgraph_name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDdnDeclineCollaborationInvitationArgs = {
  invitation_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDdnDeleteBuildArgs = {
  build_version: Scalars['String'];
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDdnDeleteEnvironmentArgs = {
  environment: Scalars['String'];
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDdnDeleteProjectArgs = {
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDdnDeleteSubgraphArgs = {
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
  subgraph: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDdnGenerateMetadataArtifactsArgs = {
  env_vars?: InputMaybe<EnvironmentVariablesV3>;
  metadata_raw_json: Scalars['String'];
  project_id?: InputMaybe<Scalars['String']>;
  project_name?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDdnHasuraSecretDeleteArgs = {
  environment: Scalars['String'];
  key: Scalars['String'];
  project_id?: InputMaybe<Scalars['String']>;
  project_name?: InputMaybe<Scalars['String']>;
  subgraph?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDdnHasuraSecretSetArgs = {
  description?: InputMaybe<Scalars['String']>;
  environment: Scalars['String'];
  key: Scalars['String'];
  project_id?: InputMaybe<Scalars['String']>;
  project_name?: InputMaybe<Scalars['String']>;
  subgraph?: InputMaybe<Scalars['String']>;
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDdnInviteProjectCollaboratorArgs = {
  input?: InputMaybe<InviteProjectCollaboratorInput>;
};

/** mutation root */
export type Mutation_RootDdnPayInvoiceArgs = {
  invoice_id: Scalars['String'];
  payment_method_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDdnRejectProjectCollaborationRequestArgs = {
  invitation_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDdnRemoveProjectCollaboratorArgs = {
  collaborator_user_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDdnRequestProjectCollaborationArgs = {
  access_level?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDdnSetDefaultPaymentMethodArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDdnSuspendProjectCollaboratorArgs = {
  collaborator_user_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDdnToggleRequestAccessSettingArgs = {
  enable_setting: Scalars['Boolean'];
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootDeclineBillingManagerInviteArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeclineInviteArgs = {
  inviteKey: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeclineTransferOwnershipInviteArgs = {
  inviteKey: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeclineVpcCollaboratorInvitationArgs = {
  invitation_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteCardArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDeleteProjectArgs = {
  project: DeleteProjectInput;
};

/** mutation root */
export type Mutation_RootDeleteSlackAppArgs = {
  args: DelteSlackAppPayload;
};

/** mutation root */
export type Mutation_RootDeleteTenantArgs = {
  tenantId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeleteTenantEnvArgs = {
  currentHash: Scalars['String'];
  deleteEnvs: Array<Scalars['String']>;
  tenantId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Alert_ConfigArgs = {
  where: Alert_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Alert_Config_Alert_TypeArgs = {
  where: Alert_Config_Alert_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Alert_Config_Alert_Type_By_PkArgs = {
  project_id: Scalars['uuid'];
  type: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Alert_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Alert_Config_ServiceArgs = {
  where: Alert_Config_Service_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Alert_Config_Service_By_PkArgs = {
  project_id: Scalars['uuid'];
  type: Alert_Service_Type_Enum;
};

/** mutation root */
export type Mutation_RootDelete_Alert_Service_TypeArgs = {
  where: Alert_Service_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Alert_Service_Type_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Alert_TypeArgs = {
  where: Alert_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Alert_Type_By_PkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Azuremonitor_ConfigArgs = {
  where: Azuremonitor_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Azuremonitor_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Billing_AddressArgs = {
  where: Billing_Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Billing_Address_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Central_CloudArgs = {
  where: Central_Cloud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Central_Cloud_By_PkArgs = {
  cloud: Cloud_Enum;
};

/** mutation root */
export type Mutation_RootDelete_Change_Email_RequestArgs = {
  where: Change_Email_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Change_Email_Request_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Cicd_Deployment_Pipeline_ConfigArgs = {
  where: Cicd_Deployment_Pipeline_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Cicd_Deployment_Pipeline_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Cicd_Deployment_PoolArgs = {
  where: Cicd_Deployment_Pool_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Cicd_Deployment_Pool_MemberArgs = {
  where: Cicd_Deployment_Pool_Member_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_CloudArgs = {
  where: Cloud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Cloud_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Cloud_MetadataArgs = {
  where: Cloud_Metadata_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Cloud_Metadata_By_PkArgs = {
  cloud: Cloud_Enum;
};

/** mutation root */
export type Mutation_RootDelete_Compute_Unit_ConfigArgs = {
  where: Compute_Unit_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Compute_Unit_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ConfigArgs = {
  where: Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Config_By_PkArgs = {
  tenant_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Config_StatusArgs = {
  where: Config_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Config_Status_By_PkArgs = {
  hash: Scalars['String'];
  tenant_id: Scalars['uuid'];
  worker_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Connector_ConfigArgs = {
  where: Connector_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Connector_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Connector_DeploymentsArgs = {
  where: Connector_Deployments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Connector_Deployments_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_CouponArgs = {
  where: Coupon_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Coupon_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Coupon_DurationArgs = {
  where: Coupon_Duration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Coupon_Duration_By_PkArgs = {
  duration: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Coupon_TypeArgs = {
  where: Coupon_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Coupon_Type_By_PkArgs = {
  type: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Custom_DomainArgs = {
  where: Custom_Domain_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Custom_Domain_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Custom_Domain_CloudflareArgs = {
  where: Custom_Domain_Cloudflare_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Custom_Domain_Cloudflare_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Custom_Domain_Cloudflare_DnsArgs = {
  where: Custom_Domain_Cloudflare_Dns_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Custom_Domain_Cloudflare_Dns_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Customer_UsageArgs = {
  where: Customer_Usage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Customer_Usage_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Data_Connector_TypeArgs = {
  where: Data_Connector_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Data_Connector_Type_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Datadog_ConfigArgs = {
  where: Datadog_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Datadog_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Db_LatencyArgs = {
  where: Db_Latency_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Db_Latency_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Applied_Build_ChangelogArgs = {
  where: Ddn_Applied_Build_Changelog_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Applied_Build_Changelog_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Applied_Subgraph_Build_ChangelogArgs = {
  where: Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Applied_Subgraph_Build_Changelog_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Auto_Approve_Project_RequestArgs = {
  where: Ddn_Auto_Approve_Project_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Auto_Approve_Project_Request_By_PkArgs = {
  auto_approve_project_request: Scalars['Boolean'];
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_BuildArgs = {
  where: Ddn_Build_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_DetailsArgs = {
  where: Ddn_Build_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_LockArgs = {
  where: Ddn_Build_Lock_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_Lock_By_PkArgs = {
  environment_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_RevisionArgs = {
  where: Ddn_Build_Revision_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_Revision_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_Revision_StatusArgs = {
  where: Ddn_Build_Revision_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_Revision_Status_By_PkArgs = {
  status: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_Sync_StatusArgs = {
  where: Ddn_Build_Sync_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_Sync_Status_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_Sync_WorkerArgs = {
  where: Ddn_Build_Sync_Worker_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Build_Sync_Worker_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Collaboration_Invitee_Project_ViewArgs = {
  where: Ddn_Collaboration_Invitee_Project_View_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Connector_DeploymentArgs = {
  where: Ddn_Connector_Deployment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Connector_Deployment_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_DdnArgs = {
  where: Ddn_Ddn_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Ddn_By_PkArgs = {
  kind: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_EnvironmentArgs = {
  where: Ddn_Environment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Environment_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Generate_Metadata_Artifacts_LogArgs = {
  where: Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Generate_Metadata_Artifacts_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_InvoiceArgs = {
  where: Ddn_Invoice_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Invoice_By_PkArgs = {
  stripe_invoice_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Invoice_ItemArgs = {
  where: Ddn_Invoice_Item_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Invoice_Item_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Plan_Entitlement_AccessArgs = {
  where: Ddn_Plan_Entitlement_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Plan_Entitlement_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_PlansArgs = {
  where: Ddn_Plans_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Private_DdnArgs = {
  where: Ddn_Private_Ddn_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Private_Ddn_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Private_Ddn_RegionArgs = {
  where: Ddn_Private_Ddn_Region_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Private_Ddn_Region_By_PkArgs = {
  cloud: Scalars['String'];
  private_ddn_id: Scalars['uuid'];
  region: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Access_LevelArgs = {
  where: Ddn_Project_Access_Level_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Access_Level_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Active_Status_ChangelogArgs = {
  where: Ddn_Project_Active_Status_Changelog_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Active_Status_Changelog_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Active_Status_ReasonArgs = {
  where: Ddn_Project_Active_Status_Reason_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Active_Status_Reason_By_PkArgs = {
  reason: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Entitlement_AccessArgs = {
  where: Ddn_Project_Entitlement_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Entitlement_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Entitlement_CatalogueArgs = {
  where: Ddn_Project_Entitlement_Catalogue_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Entitlement_Catalogue_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Entitlement_TypesArgs = {
  where: Ddn_Project_Entitlement_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Entitlement_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Plan_ChangelogArgs = {
  where: Ddn_Project_Plan_Changelog_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Plan_Changelog_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Request_AccessArgs = {
  where: Ddn_Project_Request_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Project_Request_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_ProjectsArgs = {
  where: Ddn_Projects_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Projects_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Secret_KeyArgs = {
  where: Ddn_Secret_Key_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Secret_Key_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Stripe_SubscriptionArgs = {
  where: Ddn_Stripe_Subscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Stripe_Subscription_By_PkArgs = {
  customer_id: Scalars['String'];
  subscription_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Stripe_Webhook_EventsArgs = {
  where: Ddn_Stripe_Webhook_Events_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Stripe_Webhook_Events_By_PkArgs = {
  stripe_event_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_SubgraphArgs = {
  where: Ddn_Subgraph_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Subgraph_BuildArgs = {
  where: Ddn_Subgraph_Build_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Subgraph_Build_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Subgraph_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_TeamArgs = {
  where: Ddn_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Team_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Team_InvitationArgs = {
  where: Ddn_Team_Invitation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Team_Invitation_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Team_Invitation_StatusArgs = {
  where: Ddn_Team_Invitation_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Team_Invitation_Status_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Team_KindArgs = {
  where: Ddn_Team_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Team_Kind_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Team_MembershipArgs = {
  where: Ddn_Team_Membership_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Team_Membership_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Team_Project_AccessArgs = {
  where: Ddn_Team_Project_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Team_Project_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_TunnelArgs = {
  where: Ddn_Tunnel_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Tunnel_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Tunnel_ClusterArgs = {
  where: Ddn_Tunnel_Cluster_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Tunnel_Cluster_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Tunnel_HttpArgs = {
  where: Ddn_Tunnel_Http_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Tunnel_Http_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Tunnel_Http_ClusterArgs = {
  where: Ddn_Tunnel_Http_Cluster_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ddn_Tunnel_Http_Cluster_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Dedicated_Cloud_BillsArgs = {
  where: Dedicated_Cloud_Bills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Dedicated_Cloud_Bills_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Dedicated_Cloud_Bills_DetailsArgs = {
  where: Dedicated_Cloud_Bills_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Dedicated_Cloud_Bills_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Dedicated_Cloud_CommitmentsArgs = {
  where: Dedicated_Cloud_Commitments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Dedicated_Cloud_Commitments_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Dedicated_VpcArgs = {
  where: Dedicated_Vpc_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Dedicated_Vpc_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Delete_UserArgs = {
  where: Delete_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Delete_User_By_PkArgs = {
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Delete_User_StatusArgs = {
  where: Delete_User_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Delete_User_Status_By_PkArgs = {
  status: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Delete_User_TasksArgs = {
  where: Delete_User_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Delete_User_Tasks_By_PkArgs = {
  task_name: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Email_LogArgs = {
  where: Email_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Email_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Enterprise_UsersArgs = {
  where: Enterprise_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enterprise_Users_By_PkArgs = {
  customer_email: Scalars['citext'];
};

/** mutation root */
export type Mutation_RootDelete_Enterprise_Users_DomainArgs = {
  where: Enterprise_Users_Domain_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Enterprise_Users_Domain_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ExperimentsArgs = {
  where: Experiments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Experiments_By_PkArgs = {
  experiment: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Experiments_CohortArgs = {
  where: Experiments_Cohort_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Experiments_Cohort_By_PkArgs = {
  experiment: Experiments_Enum;
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Experiments_ConfigArgs = {
  where: Experiments_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Experiments_Config_By_PkArgs = {
  experiment: Experiments_Enum;
};

/** mutation root */
export type Mutation_RootDelete_FeatureArgs = {
  where: Feature_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Feature_AccessArgs = {
  where: Feature_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Feature_Access_By_PkArgs = {
  email: Scalars['String'];
  feature: Feature_Enum;
};

/** mutation root */
export type Mutation_RootDelete_Feature_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Feature_ConfigArgs = {
  where: Feature_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Feature_Config_By_PkArgs = {
  feature: Feature_Enum;
};

/** mutation root */
export type Mutation_RootDelete_Gateway_AmiArgs = {
  where: Gateway_Ami_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Gateway_Ami_By_PkArgs = {
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  region: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Gateway_ClusterArgs = {
  where: Gateway_Cluster_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Gateway_Cluster_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Gateway_WorkerArgs = {
  where: Gateway_Worker_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Gateway_Worker_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Github_Email_TypeArgs = {
  where: Github_Email_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Github_Email_Type_By_PkArgs = {
  email_type: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Github_Integration_ConfigArgs = {
  where: Github_Integration_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Github_Integration_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Github_Integration_ModeArgs = {
  where: Github_Integration_Mode_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Github_Integration_Mode_By_PkArgs = {
  mode: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Github_Push_EventArgs = {
  where: Github_Push_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Github_Push_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Github_Push_Event_JobArgs = {
  where: Github_Push_Event_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Github_Push_Event_Job_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Hasura_AmiArgs = {
  where: Hasura_Ami_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hasura_Ami_By_PkArgs = {
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  region: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Hasura_ClusterArgs = {
  where: Hasura_Cluster_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hasura_Cluster_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Hasura_WorkerArgs = {
  where: Hasura_Worker_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hasura_Worker_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Hasura_Year_In_Report_ActivityArgs = {
  where: Hasura_Year_In_Report_Activity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hasura_Year_In_Report_Activity_By_PkArgs = {
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Hasura_Year_In_Report_MetricsArgs = {
  where: Hasura_Year_In_Report_Metrics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Hasura_Year_In_Report_Metrics_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Heroku_IntegrationsArgs = {
  where: Heroku_Integrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Heroku_Integrations_By_PkArgs = {
  project_id: Scalars['uuid'];
  var_name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Inactive_Project_ExclusionsArgs = {
  where: Inactive_Project_Exclusions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inactive_Project_Exclusions_By_PkArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Inactive_Project_NotificationsArgs = {
  where: Inactive_Project_Notifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inactive_Project_Notifications_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Inactive_Project_Suspension_ConfigArgs = {
  where: Inactive_Project_Suspension_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Infra_StatusArgs = {
  where: Infra_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Infra_Status_By_PkArgs = {
  status: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_InvoiceArgs = {
  where: Invoice_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Invoice_By_PkArgs = {
  stripe_invoice_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Invoice_Coupon_DiscountArgs = {
  where: Invoice_Coupon_Discount_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Invoice_Coupon_Discount_By_PkArgs = {
  inv_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Invoice_ItemArgs = {
  where: Invoice_Item_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Invoice_Item_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_JobsArgs = {
  where: Jobs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Jobs_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_LabelArgs = {
  where: Label_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Label_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Letsencrypt_StatusArgs = {
  where: Letsencrypt_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Letsencrypt_Status_By_PkArgs = {
  fqdn: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_License_ActivityArgs = {
  where: License_Activity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_License_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_License_InstanceArgs = {
  where: License_Instance_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_License_Instance_By_PkArgs = {
  metadata_db_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_License_TypeArgs = {
  where: License_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_License_Type_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_LicensesArgs = {
  where: Licenses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Licenses_By_PkArgs = {
  email: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Lux_ProxyArgs = {
  where: Lux_Proxy_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Lux_Proxy_AmiArgs = {
  where: Lux_Proxy_Ami_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Lux_Proxy_Ami_By_PkArgs = {
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  region: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Lux_Proxy_By_PkArgs = {
  cloud: Scalars['String'];
  region: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Model_Based_Enterprise_Cloud_CommitmentsArgs = {
  where: Model_Based_Enterprise_Cloud_Commitments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Model_Based_Enterprise_Cloud_Commitments_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Neon_Db_IntegrationArgs = {
  where: Neon_Db_Integration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Neon_Db_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Newrelic_ConfigArgs = {
  where: Newrelic_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Newrelic_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Node_Pool_TypeArgs = {
  where: Node_Pool_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Node_Pool_Type_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Node_PoolsArgs = {
  where: Node_Pools_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Node_Pools_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_NotificationArgs = {
  where: Notification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Notification_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Notification_TypeArgs = {
  where: Notification_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Notification_Type_By_PkArgs = {
  type: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Onboarding_Sample_Db_CohortArgs = {
  where: Onboarding_Sample_Db_Cohort_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Onboarding_Sample_Db_Cohort_By_PkArgs = {
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Onboarding_Sample_Db_ConfigArgs = {
  where: Onboarding_Sample_Db_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Onboarding_Sample_Db_Config_By_PkArgs = {
  created_at: Scalars['timestamptz'];
};

/** mutation root */
export type Mutation_RootDelete_One_Click_DeploymentArgs = {
  where: One_Click_Deployment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_One_Click_Deployment_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_One_Click_Deployment_Sample_AppsArgs = {
  where: One_Click_Deployment_Sample_Apps_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_One_Click_Deployment_Sample_Apps_By_PkArgs = {
  git_repository_branch: Scalars['String'];
  git_repository_url: Scalars['String'];
  hasura_directory: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_One_Click_Deployment_State_LogArgs = {
  where: One_Click_Deployment_State_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_One_Click_Deployment_State_Log_By_PkArgs = {
  id: Scalars['bigint'];
};

/** mutation root */
export type Mutation_RootDelete_One_Click_Deployment_StatesArgs = {
  where: One_Click_Deployment_States_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_One_Click_Deployment_States_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Opentelemetry_ConfigArgs = {
  where: Opentelemetry_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Opentelemetry_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Operations_Delete_Free_Plan_Dns_Records_Nov_2021Args = {
  where: Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Operations_Delete_Free_Plan_Dns_Records_Nov_2021_By_PkArgs = {
  tenant_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Operations_TenantArgs = {
  where: Operations_Tenant_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Payment_MethodArgs = {
  where: Payment_Method_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Payment_Method_By_PkArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Plan_EntitlementsArgs = {
  where: Plan_Entitlements_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Plan_Entitlements_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_PlansArgs = {
  where: Plans_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Plans_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_PoliciesArgs = {
  where: Policies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policies_By_PkArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_PrivilegesArgs = {
  where: Privileges_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Privileges_By_PkArgs = {
  slug: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Pro_License_KeysArgs = {
  where: Pro_License_Keys_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Pro_License_Keys_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_ActivityArgs = {
  where: Project_Activity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Billing_Manager_InvitationsArgs = {
  where: Project_Billing_Manager_Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Billing_Manager_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Collaborator_Allowed_Schema_TablesArgs = {
  where: Project_Collaborator_Allowed_Schema_Tables_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Collaborator_Allowed_Schema_Tables_By_PkArgs = {
  project_collaborator_id: Scalars['uuid'];
  schema: Scalars['String'];
  table_name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Collaborator_Allowed_SchemasArgs = {
  where: Project_Collaborator_Allowed_Schemas_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Collaborator_Allowed_Schemas_By_PkArgs = {
  project_collaborator_id: Scalars['uuid'];
  schema: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Collaborator_InvitationsArgs = {
  where: Project_Collaborator_Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Collaborator_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Collaborator_PrivilegesArgs = {
  where: Project_Collaborator_Privileges_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Collaborator_Privileges_By_PkArgs = {
  privilege_slug: Scalars['String'];
  project_collaborator_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_CollaboratorsArgs = {
  where: Project_Collaborators_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Collaborators_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Data_UsageArgs = {
  where: Project_Data_Usage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Data_Usage_AggArgs = {
  where: Project_Data_Usage_Agg_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Data_Usage_Agg_UserArgs = {
  where: Project_Data_Usage_Agg_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Data_Usage_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Data_Usage_ComponentsArgs = {
  where: Project_Data_Usage_Components_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Data_Usage_PrometheusArgs = {
  where: Project_Data_Usage_Prometheus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Data_Usage_Prometheus_Agg_UserArgs = {
  where: Project_Data_Usage_Prometheus_Agg_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Data_Usage_Prometheus_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Data_Usage_ReportArgs = {
  where: Project_Data_Usage_Report_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Data_Usage_Report_By_PkArgs = {
  project_id: Scalars['uuid'];
  report_date: Scalars['date'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Db_UsageArgs = {
  where: Project_Db_Usage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Db_Usage_Agg_UserArgs = {
  where: Project_Db_Usage_Agg_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Db_Usage_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Entitlement_AccessArgs = {
  where: Project_Entitlement_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Entitlement_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Entitlement_CatalogueArgs = {
  where: Project_Entitlement_Catalogue_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Entitlement_Catalogue_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Entitlement_TypesArgs = {
  where: Project_Entitlement_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Entitlement_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Project_LabelsArgs = {
  where: Project_Labels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Labels_By_PkArgs = {
  label_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_MetadataArgs = {
  where: Project_Metadata_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Metadata_By_PkArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_NotificationArgs = {
  where: Project_Notification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Notification_By_PkArgs = {
  month: Scalars['Int'];
  project_id: Scalars['uuid'];
  type: Scalars['reminder_type_enum'];
  year: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Ownership_Transfer_InvitationsArgs = {
  where: Project_Ownership_Transfer_Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Ownership_Transfer_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Requests_CountArgs = {
  where: Project_Requests_Count_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Requests_Count_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_StatsArgs = {
  where: Project_Stats_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Project_Stats_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Project_Total_Db_Usage_AggArgs = {
  where: Project_Total_Db_Usage_Agg_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_ProjectsArgs = {
  where: Projects_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Projects_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Projects_Pool_ConfigArgs = {
  where: Projects_Pool_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Projects_Pool_Config_By_PkArgs = {
  cloud: Scalars['String'];
  plan_name: Scalars['String'];
  region: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Projects_Pro_Key_GenerationsArgs = {
  where: Projects_Pro_Key_Generations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Projects_Pro_Key_Generations_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Prometheus_ConfigArgs = {
  where: Prometheus_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Prometheus_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ProvidersArgs = {
  where: Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Providers_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_ProvisioningArgs = {
  where: Provisioning_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Provisioning_By_PkArgs = {
  provisioning: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_RegionArgs = {
  where: Region_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Region_By_PkArgs = {
  cloud: Cloud_Enum;
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Region_V2Args = {
  where: Region_V2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Regional_Data_ConnectorsArgs = {
  where: Regional_Data_Connectors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Regional_Data_Connectors_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Regional_MetricsArgs = {
  where: Regional_Metrics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Regional_Metrics_By_PkArgs = {
  cloud: Scalars['String'];
  region: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Saml_IdpArgs = {
  where: Saml_Idp_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Saml_Idp_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Search_Project_Login_Status_ResultsArgs = {
  where: Search_Project_Login_Status_Results_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Slack_ConfigArgs = {
  where: Slack_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Slack_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Stripe_SubscriptionArgs = {
  where: Stripe_Subscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stripe_Subscription_By_PkArgs = {
  customer_id: Scalars['String'];
  subscription_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Stripe_Webhook_EventsArgs = {
  where: Stripe_Webhook_Events_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stripe_Webhook_Events_By_PkArgs = {
  stripe_event_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Super_Connector_TypesArgs = {
  where: Super_Connector_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Super_Connector_Types_By_PkArgs = {
  type: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Support_Plan_TypesArgs = {
  where: Support_Plan_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Support_Plan_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_SurveyArgs = {
  where: Survey_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_By_PkArgs = {
  survey_name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_QuestionArgs = {
  where: Survey_Question_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_Question_Answer_OptionArgs = {
  where: Survey_Question_Answer_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_Question_Answer_Option_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_Question_AnswersArgs = {
  where: Survey_Question_Answers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_Question_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_Question_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_Question_KindArgs = {
  where: Survey_Question_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_Question_Kind_By_PkArgs = {
  kind: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_Question_OptionsArgs = {
  where: Survey_Question_Options_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_Question_Options_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2Args = {
  where: Survey_V2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_QuestionArgs = {
  where: Survey_V2_Question_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_Question_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_Question_KindArgs = {
  where: Survey_V2_Question_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_Question_Kind_By_PkArgs = {
  kind: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_Question_OptionArgs = {
  where: Survey_V2_Question_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_Question_Option_Additional_Info_ConfigArgs = {
  where: Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_Question_Option_Additional_Info_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_Question_Option_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_ResponseArgs = {
  where: Survey_V2_Response_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_Response_AnswerArgs = {
  where: Survey_V2_Response_Answer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_Response_Answer_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_Response_Answer_OptionArgs = {
  where: Survey_V2_Response_Answer_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_Response_Answer_Option_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Survey_V2_Response_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Task_EventArgs = {
  where: Task_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_TasksArgs = {
  where: Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_TenantArgs = {
  where: Tenant_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tenant_Active_Status_ReasonArgs = {
  where: Tenant_Active_Status_Reason_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tenant_Active_Status_Reason_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Tenant_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Tenant_GroupArgs = {
  where: Tenant_Group_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tenant_Group_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Tenant_Group_MemberArgs = {
  where: Tenant_Group_Member_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tenant_Group_Member_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Tenant_Public_StatusArgs = {
  where: Tenant_Public_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tenant_Public_Status_By_PkArgs = {
  endpoint: Scalars['String'];
  tenant_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Tenant_StatusArgs = {
  where: Tenant_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tenant_Status_By_PkArgs = {
  tenant_id: Scalars['uuid'];
  worker_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Trial_LeadsArgs = {
  where: Trial_Leads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Trial_Leads_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Ua_Audit_LogsArgs = {
  where: Ua_Audit_Logs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Ua_Audit_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_ActivityArgs = {
  where: User_Activity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_CouponArgs = {
  where: User_Coupon_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Coupon_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Entitlement_AccessArgs = {
  where: User_Entitlement_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Entitlement_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Entitlement_CatalogueArgs = {
  where: User_Entitlement_Catalogue_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Entitlement_Catalogue_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Entitlement_TypesArgs = {
  where: User_Entitlement_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Entitlement_Types_By_PkArgs = {
  value: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_User_OnboardingArgs = {
  where: User_Onboarding_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Onboarding_By_PkArgs = {
  target: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Personal_Access_TokensArgs = {
  where: User_Personal_Access_Tokens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Personal_Access_Tokens_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_ProfileArgs = {
  where: User_Profile_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_RolesArgs = {
  where: User_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_User_Vpc_PolicyArgs = {
  where: User_Vpc_Policy_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_User_Vpc_Policy_By_PkArgs = {
  id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Users_PublicArgs = {
  where: Users_Public_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vercel_IntegrationArgs = {
  where: Vercel_Integration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vercel_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Vercel_Integration_ConnectionsArgs = {
  where: Vercel_Integration_Connections_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vercel_Integration_Connections_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Vpc_Collaborator_InvitationsArgs = {
  where: Vpc_Collaborator_Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vpc_Collaborator_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Vpc_CollaboratorsArgs = {
  where: Vpc_Collaborators_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vpc_Collaborators_By_PkArgs = {
  collaborator_id: Scalars['uuid'];
  vpc_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Vpc_PeeringArgs = {
  where: Vpc_Peering_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vpc_Peering_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Vpc_Peering_DirectionArgs = {
  where: Vpc_Peering_Direction_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vpc_Peering_Direction_By_PkArgs = {
  direction: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Vpc_PrivilegesArgs = {
  where: Vpc_Privileges_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vpc_Privileges_By_PkArgs = {
  slug: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Vpc_StatusArgs = {
  where: Vpc_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vpc_Status_By_PkArgs = {
  status: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Zendesk_Support_CategoryArgs = {
  where: Zendesk_Support_Category_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Zendesk_Support_Category_By_PkArgs = {
  name: Scalars['String'];
};

/** mutation root */
export type Mutation_RootDelete_Zendesk_Support_TicketsArgs = {
  where: Zendesk_Support_Tickets_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Zendesk_Support_Tickets_By_PkArgs = {
  id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDeployLatestGithubCommitArgs = {
  payload: DeployLatestGithubCommitInput;
};

/** mutation root */
export type Mutation_RootEnableCloudflareProxyArgs = {
  tenant_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootFetchCurrentGithubLoginArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootFetchGithubAppInstallationsArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootFetchGithubAppIntegrationAccessibleReposArgs = {
  installation_id: Scalars['Int'];
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootFetchGithubRepoBranchesArgs = {
  owner: Scalars['String'];
  project_id: Scalars['uuid'];
  repo: Scalars['String'];
};

/** mutation root */
export type Mutation_RootGenerateApiDiffArgs = {
  compared_build_version: Scalars['String'];
  project_name: Scalars['String'];
  reference_build_version: Scalars['String'];
};

/** mutation root */
export type Mutation_RootGetGithubSessionArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootGithubTokenExchangeArgs = {
  code: Scalars['String'];
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootHerokuRegisterWebhookArgs = {
  appID: Scalars['String'];
  appName: Scalars['String'];
  projectID: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootHerokuRegisterWebhookVarArgs = {
  agent?: InputMaybe<ActionAgent>;
  appID: Scalars['String'];
  appName: Scalars['String'];
  projectID: Scalars['uuid'];
  varName: Scalars['String'];
};

/** mutation root */
export type Mutation_RootHerokuTokenExchangeArgs = {
  payload: HerokuTokenExchangeInput;
};

/** mutation root */
export type Mutation_RootHerokuUnregisterWebhookArgs = {
  projectID: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootHerokuUnregisterWebhookVarArgs = {
  projectID: Scalars['uuid'];
  varName: Scalars['String'];
};

/** mutation root */
export type Mutation_RootInsert_Alert_ConfigArgs = {
  objects: Array<Alert_Config_Insert_Input>;
  on_conflict?: InputMaybe<Alert_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Alert_Config_Alert_TypeArgs = {
  objects: Array<Alert_Config_Alert_Type_Insert_Input>;
  on_conflict?: InputMaybe<Alert_Config_Alert_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Alert_Config_Alert_Type_OneArgs = {
  object: Alert_Config_Alert_Type_Insert_Input;
  on_conflict?: InputMaybe<Alert_Config_Alert_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Alert_Config_OneArgs = {
  object: Alert_Config_Insert_Input;
  on_conflict?: InputMaybe<Alert_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Alert_Config_ServiceArgs = {
  objects: Array<Alert_Config_Service_Insert_Input>;
  on_conflict?: InputMaybe<Alert_Config_Service_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Alert_Config_Service_OneArgs = {
  object: Alert_Config_Service_Insert_Input;
  on_conflict?: InputMaybe<Alert_Config_Service_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Alert_Service_TypeArgs = {
  objects: Array<Alert_Service_Type_Insert_Input>;
  on_conflict?: InputMaybe<Alert_Service_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Alert_Service_Type_OneArgs = {
  object: Alert_Service_Type_Insert_Input;
  on_conflict?: InputMaybe<Alert_Service_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Alert_TypeArgs = {
  objects: Array<Alert_Type_Insert_Input>;
  on_conflict?: InputMaybe<Alert_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Alert_Type_OneArgs = {
  object: Alert_Type_Insert_Input;
  on_conflict?: InputMaybe<Alert_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Azuremonitor_ConfigArgs = {
  objects: Array<Azuremonitor_Config_Insert_Input>;
  on_conflict?: InputMaybe<Azuremonitor_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Azuremonitor_Config_OneArgs = {
  object: Azuremonitor_Config_Insert_Input;
  on_conflict?: InputMaybe<Azuremonitor_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Billing_AddressArgs = {
  objects: Array<Billing_Address_Insert_Input>;
  on_conflict?: InputMaybe<Billing_Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Billing_Address_OneArgs = {
  object: Billing_Address_Insert_Input;
  on_conflict?: InputMaybe<Billing_Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Central_CloudArgs = {
  objects: Array<Central_Cloud_Insert_Input>;
  on_conflict?: InputMaybe<Central_Cloud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Central_Cloud_OneArgs = {
  object: Central_Cloud_Insert_Input;
  on_conflict?: InputMaybe<Central_Cloud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Change_Email_RequestArgs = {
  objects: Array<Change_Email_Request_Insert_Input>;
  on_conflict?: InputMaybe<Change_Email_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Change_Email_Request_OneArgs = {
  object: Change_Email_Request_Insert_Input;
  on_conflict?: InputMaybe<Change_Email_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Cicd_Deployment_Pipeline_ConfigArgs = {
  objects: Array<Cicd_Deployment_Pipeline_Config_Insert_Input>;
  on_conflict?: InputMaybe<Cicd_Deployment_Pipeline_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Cicd_Deployment_Pipeline_Config_OneArgs = {
  object: Cicd_Deployment_Pipeline_Config_Insert_Input;
  on_conflict?: InputMaybe<Cicd_Deployment_Pipeline_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Cicd_Deployment_PoolArgs = {
  objects: Array<Cicd_Deployment_Pool_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Cicd_Deployment_Pool_MemberArgs = {
  objects: Array<Cicd_Deployment_Pool_Member_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Cicd_Deployment_Pool_Member_OneArgs = {
  object: Cicd_Deployment_Pool_Member_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Cicd_Deployment_Pool_OneArgs = {
  object: Cicd_Deployment_Pool_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_CloudArgs = {
  objects: Array<Cloud_Insert_Input>;
  on_conflict?: InputMaybe<Cloud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Cloud_MetadataArgs = {
  objects: Array<Cloud_Metadata_Insert_Input>;
  on_conflict?: InputMaybe<Cloud_Metadata_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Cloud_Metadata_OneArgs = {
  object: Cloud_Metadata_Insert_Input;
  on_conflict?: InputMaybe<Cloud_Metadata_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Cloud_OneArgs = {
  object: Cloud_Insert_Input;
  on_conflict?: InputMaybe<Cloud_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Compute_Unit_ConfigArgs = {
  objects: Array<Compute_Unit_Config_Insert_Input>;
  on_conflict?: InputMaybe<Compute_Unit_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Compute_Unit_Config_OneArgs = {
  object: Compute_Unit_Config_Insert_Input;
  on_conflict?: InputMaybe<Compute_Unit_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ConfigArgs = {
  objects: Array<Config_Insert_Input>;
  on_conflict?: InputMaybe<Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Config_OneArgs = {
  object: Config_Insert_Input;
  on_conflict?: InputMaybe<Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Config_StatusArgs = {
  objects: Array<Config_Status_Insert_Input>;
  on_conflict?: InputMaybe<Config_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Config_Status_OneArgs = {
  object: Config_Status_Insert_Input;
  on_conflict?: InputMaybe<Config_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Connector_ConfigArgs = {
  objects: Array<Connector_Config_Insert_Input>;
  on_conflict?: InputMaybe<Connector_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Connector_Config_OneArgs = {
  object: Connector_Config_Insert_Input;
  on_conflict?: InputMaybe<Connector_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Connector_DeploymentsArgs = {
  objects: Array<Connector_Deployments_Insert_Input>;
  on_conflict?: InputMaybe<Connector_Deployments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Connector_Deployments_OneArgs = {
  object: Connector_Deployments_Insert_Input;
  on_conflict?: InputMaybe<Connector_Deployments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CouponArgs = {
  objects: Array<Coupon_Insert_Input>;
  on_conflict?: InputMaybe<Coupon_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Coupon_DurationArgs = {
  objects: Array<Coupon_Duration_Insert_Input>;
  on_conflict?: InputMaybe<Coupon_Duration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Coupon_Duration_OneArgs = {
  object: Coupon_Duration_Insert_Input;
  on_conflict?: InputMaybe<Coupon_Duration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Coupon_OneArgs = {
  object: Coupon_Insert_Input;
  on_conflict?: InputMaybe<Coupon_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Coupon_TypeArgs = {
  objects: Array<Coupon_Type_Insert_Input>;
  on_conflict?: InputMaybe<Coupon_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Coupon_Type_OneArgs = {
  object: Coupon_Type_Insert_Input;
  on_conflict?: InputMaybe<Coupon_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Custom_DomainArgs = {
  objects: Array<Custom_Domain_Insert_Input>;
  on_conflict?: InputMaybe<Custom_Domain_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Custom_Domain_CloudflareArgs = {
  objects: Array<Custom_Domain_Cloudflare_Insert_Input>;
  on_conflict?: InputMaybe<Custom_Domain_Cloudflare_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Custom_Domain_Cloudflare_DnsArgs = {
  objects: Array<Custom_Domain_Cloudflare_Dns_Insert_Input>;
  on_conflict?: InputMaybe<Custom_Domain_Cloudflare_Dns_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Custom_Domain_Cloudflare_Dns_OneArgs = {
  object: Custom_Domain_Cloudflare_Dns_Insert_Input;
  on_conflict?: InputMaybe<Custom_Domain_Cloudflare_Dns_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Custom_Domain_Cloudflare_OneArgs = {
  object: Custom_Domain_Cloudflare_Insert_Input;
  on_conflict?: InputMaybe<Custom_Domain_Cloudflare_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Custom_Domain_OneArgs = {
  object: Custom_Domain_Insert_Input;
  on_conflict?: InputMaybe<Custom_Domain_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Customer_UsageArgs = {
  objects: Array<Customer_Usage_Insert_Input>;
  on_conflict?: InputMaybe<Customer_Usage_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Customer_Usage_OneArgs = {
  object: Customer_Usage_Insert_Input;
  on_conflict?: InputMaybe<Customer_Usage_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Data_Connector_TypeArgs = {
  objects: Array<Data_Connector_Type_Insert_Input>;
  on_conflict?: InputMaybe<Data_Connector_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Data_Connector_Type_OneArgs = {
  object: Data_Connector_Type_Insert_Input;
  on_conflict?: InputMaybe<Data_Connector_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Datadog_ConfigArgs = {
  objects: Array<Datadog_Config_Insert_Input>;
  on_conflict?: InputMaybe<Datadog_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Datadog_Config_OneArgs = {
  object: Datadog_Config_Insert_Input;
  on_conflict?: InputMaybe<Datadog_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Db_LatencyArgs = {
  objects: Array<Db_Latency_Insert_Input>;
  on_conflict?: InputMaybe<Db_Latency_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Db_Latency_OneArgs = {
  object: Db_Latency_Insert_Input;
  on_conflict?: InputMaybe<Db_Latency_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Db_UsageArgs = {
  args: Insert_Db_Usage_Args;
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Bool_Exp>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Applied_Build_ChangelogArgs = {
  objects: Array<Ddn_Applied_Build_Changelog_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Applied_Build_Changelog_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Applied_Build_Changelog_OneArgs = {
  object: Ddn_Applied_Build_Changelog_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Applied_Build_Changelog_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Applied_Subgraph_Build_ChangelogArgs = {
  objects: Array<Ddn_Applied_Subgraph_Build_Changelog_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Applied_Subgraph_Build_Changelog_OneArgs = {
  object: Ddn_Applied_Subgraph_Build_Changelog_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Auto_Approve_Project_RequestArgs = {
  objects: Array<Ddn_Auto_Approve_Project_Request_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Auto_Approve_Project_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Auto_Approve_Project_Request_OneArgs = {
  object: Ddn_Auto_Approve_Project_Request_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Auto_Approve_Project_Request_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_BuildArgs = {
  objects: Array<Ddn_Build_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Build_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_DetailsArgs = {
  objects: Array<Ddn_Build_Details_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Build_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_Details_OneArgs = {
  object: Ddn_Build_Details_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Build_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_LockArgs = {
  objects: Array<Ddn_Build_Lock_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Build_Lock_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_Lock_OneArgs = {
  object: Ddn_Build_Lock_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Build_Lock_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_OneArgs = {
  object: Ddn_Build_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Build_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_RevisionArgs = {
  objects: Array<Ddn_Build_Revision_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Build_Revision_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_Revision_OneArgs = {
  object: Ddn_Build_Revision_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Build_Revision_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_Revision_StatusArgs = {
  objects: Array<Ddn_Build_Revision_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Build_Revision_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_Revision_Status_OneArgs = {
  object: Ddn_Build_Revision_Status_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Build_Revision_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_Sync_StatusArgs = {
  objects: Array<Ddn_Build_Sync_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Build_Sync_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_Sync_Status_OneArgs = {
  object: Ddn_Build_Sync_Status_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Build_Sync_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_Sync_WorkerArgs = {
  objects: Array<Ddn_Build_Sync_Worker_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Build_Sync_Worker_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Build_Sync_Worker_OneArgs = {
  object: Ddn_Build_Sync_Worker_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Build_Sync_Worker_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Collaboration_Invitee_Project_ViewArgs = {
  objects: Array<Ddn_Collaboration_Invitee_Project_View_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Collaboration_Invitee_Project_View_OneArgs = {
  object: Ddn_Collaboration_Invitee_Project_View_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Connector_DeploymentArgs = {
  objects: Array<Ddn_Connector_Deployment_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Connector_Deployment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Connector_Deployment_OneArgs = {
  object: Ddn_Connector_Deployment_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Connector_Deployment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_DdnArgs = {
  objects: Array<Ddn_Ddn_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Ddn_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Ddn_OneArgs = {
  object: Ddn_Ddn_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Ddn_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_EnvironmentArgs = {
  objects: Array<Ddn_Environment_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Environment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Environment_OneArgs = {
  object: Ddn_Environment_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Environment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Generate_Metadata_Artifacts_LogArgs = {
  objects: Array<Ddn_Generate_Metadata_Artifacts_Log_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Generate_Metadata_Artifacts_Log_OneArgs = {
  object: Ddn_Generate_Metadata_Artifacts_Log_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_InvoiceArgs = {
  objects: Array<Ddn_Invoice_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Invoice_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Invoice_ItemArgs = {
  objects: Array<Ddn_Invoice_Item_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Invoice_Item_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Invoice_Item_OneArgs = {
  object: Ddn_Invoice_Item_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Invoice_Item_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Invoice_OneArgs = {
  object: Ddn_Invoice_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Invoice_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Plan_Entitlement_AccessArgs = {
  objects: Array<Ddn_Plan_Entitlement_Access_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Plan_Entitlement_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Plan_Entitlement_Access_OneArgs = {
  object: Ddn_Plan_Entitlement_Access_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Plan_Entitlement_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_PlansArgs = {
  objects: Array<Ddn_Plans_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Plans_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Plans_OneArgs = {
  object: Ddn_Plans_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Plans_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Private_DdnArgs = {
  objects: Array<Ddn_Private_Ddn_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Private_Ddn_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Private_Ddn_OneArgs = {
  object: Ddn_Private_Ddn_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Private_Ddn_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Private_Ddn_RegionArgs = {
  objects: Array<Ddn_Private_Ddn_Region_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Private_Ddn_Region_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Private_Ddn_Region_OneArgs = {
  object: Ddn_Private_Ddn_Region_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Private_Ddn_Region_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Access_LevelArgs = {
  objects: Array<Ddn_Project_Access_Level_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Project_Access_Level_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Access_Level_OneArgs = {
  object: Ddn_Project_Access_Level_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Project_Access_Level_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Active_Status_ChangelogArgs = {
  objects: Array<Ddn_Project_Active_Status_Changelog_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Project_Active_Status_Changelog_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Active_Status_Changelog_OneArgs = {
  object: Ddn_Project_Active_Status_Changelog_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Project_Active_Status_Changelog_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Active_Status_ReasonArgs = {
  objects: Array<Ddn_Project_Active_Status_Reason_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Project_Active_Status_Reason_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Active_Status_Reason_OneArgs = {
  object: Ddn_Project_Active_Status_Reason_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Project_Active_Status_Reason_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Entitlement_AccessArgs = {
  objects: Array<Ddn_Project_Entitlement_Access_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Project_Entitlement_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Entitlement_Access_OneArgs = {
  object: Ddn_Project_Entitlement_Access_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Project_Entitlement_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Entitlement_CatalogueArgs = {
  objects: Array<Ddn_Project_Entitlement_Catalogue_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Project_Entitlement_Catalogue_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Entitlement_Catalogue_OneArgs = {
  object: Ddn_Project_Entitlement_Catalogue_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Project_Entitlement_Catalogue_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Entitlement_TypesArgs = {
  objects: Array<Ddn_Project_Entitlement_Types_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Project_Entitlement_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Entitlement_Types_OneArgs = {
  object: Ddn_Project_Entitlement_Types_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Project_Entitlement_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Plan_ChangelogArgs = {
  objects: Array<Ddn_Project_Plan_Changelog_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Project_Plan_Changelog_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Plan_Changelog_OneArgs = {
  object: Ddn_Project_Plan_Changelog_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Project_Plan_Changelog_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Request_AccessArgs = {
  objects: Array<Ddn_Project_Request_Access_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Project_Request_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Project_Request_Access_OneArgs = {
  object: Ddn_Project_Request_Access_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Project_Request_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_ProjectsArgs = {
  objects: Array<Ddn_Projects_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Projects_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Projects_OneArgs = {
  object: Ddn_Projects_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Projects_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Secret_KeyArgs = {
  objects: Array<Ddn_Secret_Key_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Secret_Key_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Secret_Key_OneArgs = {
  object: Ddn_Secret_Key_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Secret_Key_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Stripe_SubscriptionArgs = {
  objects: Array<Ddn_Stripe_Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Stripe_Subscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Stripe_Subscription_OneArgs = {
  object: Ddn_Stripe_Subscription_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Stripe_Subscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Stripe_Webhook_EventsArgs = {
  objects: Array<Ddn_Stripe_Webhook_Events_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Stripe_Webhook_Events_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Stripe_Webhook_Events_OneArgs = {
  object: Ddn_Stripe_Webhook_Events_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Stripe_Webhook_Events_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_SubgraphArgs = {
  objects: Array<Ddn_Subgraph_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Subgraph_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Subgraph_BuildArgs = {
  objects: Array<Ddn_Subgraph_Build_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Subgraph_Build_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Subgraph_Build_OneArgs = {
  object: Ddn_Subgraph_Build_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Subgraph_Build_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Subgraph_OneArgs = {
  object: Ddn_Subgraph_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Subgraph_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_TeamArgs = {
  objects: Array<Ddn_Team_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Team_InvitationArgs = {
  objects: Array<Ddn_Team_Invitation_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Team_Invitation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Team_Invitation_OneArgs = {
  object: Ddn_Team_Invitation_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Team_Invitation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Team_Invitation_StatusArgs = {
  objects: Array<Ddn_Team_Invitation_Status_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Team_Invitation_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Team_Invitation_Status_OneArgs = {
  object: Ddn_Team_Invitation_Status_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Team_Invitation_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Team_KindArgs = {
  objects: Array<Ddn_Team_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Team_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Team_Kind_OneArgs = {
  object: Ddn_Team_Kind_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Team_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Team_MembershipArgs = {
  objects: Array<Ddn_Team_Membership_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Team_Membership_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Team_Membership_OneArgs = {
  object: Ddn_Team_Membership_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Team_Membership_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Team_OneArgs = {
  object: Ddn_Team_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Team_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Team_Project_AccessArgs = {
  objects: Array<Ddn_Team_Project_Access_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Team_Project_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Team_Project_Access_OneArgs = {
  object: Ddn_Team_Project_Access_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Team_Project_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_TunnelArgs = {
  objects: Array<Ddn_Tunnel_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Tunnel_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Tunnel_ClusterArgs = {
  objects: Array<Ddn_Tunnel_Cluster_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Tunnel_Cluster_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Tunnel_Cluster_OneArgs = {
  object: Ddn_Tunnel_Cluster_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Tunnel_Cluster_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Tunnel_HttpArgs = {
  objects: Array<Ddn_Tunnel_Http_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Tunnel_Http_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Tunnel_Http_ClusterArgs = {
  objects: Array<Ddn_Tunnel_Http_Cluster_Insert_Input>;
  on_conflict?: InputMaybe<Ddn_Tunnel_Http_Cluster_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Tunnel_Http_Cluster_OneArgs = {
  object: Ddn_Tunnel_Http_Cluster_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Tunnel_Http_Cluster_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Tunnel_Http_OneArgs = {
  object: Ddn_Tunnel_Http_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Tunnel_Http_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ddn_Tunnel_OneArgs = {
  object: Ddn_Tunnel_Insert_Input;
  on_conflict?: InputMaybe<Ddn_Tunnel_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dedicated_Cloud_BillsArgs = {
  objects: Array<Dedicated_Cloud_Bills_Insert_Input>;
  on_conflict?: InputMaybe<Dedicated_Cloud_Bills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dedicated_Cloud_Bills_DetailsArgs = {
  objects: Array<Dedicated_Cloud_Bills_Details_Insert_Input>;
  on_conflict?: InputMaybe<Dedicated_Cloud_Bills_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dedicated_Cloud_Bills_Details_OneArgs = {
  object: Dedicated_Cloud_Bills_Details_Insert_Input;
  on_conflict?: InputMaybe<Dedicated_Cloud_Bills_Details_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dedicated_Cloud_Bills_OneArgs = {
  object: Dedicated_Cloud_Bills_Insert_Input;
  on_conflict?: InputMaybe<Dedicated_Cloud_Bills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dedicated_Cloud_CommitmentsArgs = {
  objects: Array<Dedicated_Cloud_Commitments_Insert_Input>;
  on_conflict?: InputMaybe<Dedicated_Cloud_Commitments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dedicated_Cloud_Commitments_OneArgs = {
  object: Dedicated_Cloud_Commitments_Insert_Input;
  on_conflict?: InputMaybe<Dedicated_Cloud_Commitments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dedicated_VpcArgs = {
  objects: Array<Dedicated_Vpc_Insert_Input>;
  on_conflict?: InputMaybe<Dedicated_Vpc_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Dedicated_Vpc_OneArgs = {
  object: Dedicated_Vpc_Insert_Input;
  on_conflict?: InputMaybe<Dedicated_Vpc_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Delete_UserArgs = {
  objects: Array<Delete_User_Insert_Input>;
  on_conflict?: InputMaybe<Delete_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Delete_User_OneArgs = {
  object: Delete_User_Insert_Input;
  on_conflict?: InputMaybe<Delete_User_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Delete_User_StatusArgs = {
  objects: Array<Delete_User_Status_Insert_Input>;
  on_conflict?: InputMaybe<Delete_User_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Delete_User_Status_OneArgs = {
  object: Delete_User_Status_Insert_Input;
  on_conflict?: InputMaybe<Delete_User_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Delete_User_TasksArgs = {
  objects: Array<Delete_User_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Delete_User_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Delete_User_Tasks_OneArgs = {
  object: Delete_User_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Delete_User_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_LogArgs = {
  objects: Array<Email_Log_Insert_Input>;
  on_conflict?: InputMaybe<Email_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_Log_OneArgs = {
  object: Email_Log_Insert_Input;
  on_conflict?: InputMaybe<Email_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enterprise_UsersArgs = {
  objects: Array<Enterprise_Users_Insert_Input>;
  on_conflict?: InputMaybe<Enterprise_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enterprise_Users_DomainArgs = {
  objects: Array<Enterprise_Users_Domain_Insert_Input>;
  on_conflict?: InputMaybe<Enterprise_Users_Domain_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enterprise_Users_Domain_OneArgs = {
  object: Enterprise_Users_Domain_Insert_Input;
  on_conflict?: InputMaybe<Enterprise_Users_Domain_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Enterprise_Users_OneArgs = {
  object: Enterprise_Users_Insert_Input;
  on_conflict?: InputMaybe<Enterprise_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ExperimentsArgs = {
  objects: Array<Experiments_Insert_Input>;
  on_conflict?: InputMaybe<Experiments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Experiments_CohortArgs = {
  objects: Array<Experiments_Cohort_Insert_Input>;
  on_conflict?: InputMaybe<Experiments_Cohort_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Experiments_Cohort_OneArgs = {
  object: Experiments_Cohort_Insert_Input;
  on_conflict?: InputMaybe<Experiments_Cohort_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Experiments_ConfigArgs = {
  objects: Array<Experiments_Config_Insert_Input>;
  on_conflict?: InputMaybe<Experiments_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Experiments_Config_OneArgs = {
  object: Experiments_Config_Insert_Input;
  on_conflict?: InputMaybe<Experiments_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Experiments_OneArgs = {
  object: Experiments_Insert_Input;
  on_conflict?: InputMaybe<Experiments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FeatureArgs = {
  objects: Array<Feature_Insert_Input>;
  on_conflict?: InputMaybe<Feature_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feature_AccessArgs = {
  objects: Array<Feature_Access_Insert_Input>;
  on_conflict?: InputMaybe<Feature_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feature_Access_OneArgs = {
  object: Feature_Access_Insert_Input;
  on_conflict?: InputMaybe<Feature_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feature_ConfigArgs = {
  objects: Array<Feature_Config_Insert_Input>;
  on_conflict?: InputMaybe<Feature_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feature_Config_OneArgs = {
  object: Feature_Config_Insert_Input;
  on_conflict?: InputMaybe<Feature_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feature_OneArgs = {
  object: Feature_Insert_Input;
  on_conflict?: InputMaybe<Feature_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Gateway_AmiArgs = {
  objects: Array<Gateway_Ami_Insert_Input>;
  on_conflict?: InputMaybe<Gateway_Ami_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Gateway_Ami_OneArgs = {
  object: Gateway_Ami_Insert_Input;
  on_conflict?: InputMaybe<Gateway_Ami_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Gateway_ClusterArgs = {
  objects: Array<Gateway_Cluster_Insert_Input>;
  on_conflict?: InputMaybe<Gateway_Cluster_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Gateway_Cluster_OneArgs = {
  object: Gateway_Cluster_Insert_Input;
  on_conflict?: InputMaybe<Gateway_Cluster_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Gateway_WorkerArgs = {
  objects: Array<Gateway_Worker_Insert_Input>;
  on_conflict?: InputMaybe<Gateway_Worker_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Gateway_Worker_OneArgs = {
  object: Gateway_Worker_Insert_Input;
  on_conflict?: InputMaybe<Gateway_Worker_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Github_Email_TypeArgs = {
  objects: Array<Github_Email_Type_Insert_Input>;
  on_conflict?: InputMaybe<Github_Email_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Github_Email_Type_OneArgs = {
  object: Github_Email_Type_Insert_Input;
  on_conflict?: InputMaybe<Github_Email_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Github_Integration_ConfigArgs = {
  objects: Array<Github_Integration_Config_Insert_Input>;
  on_conflict?: InputMaybe<Github_Integration_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Github_Integration_Config_OneArgs = {
  object: Github_Integration_Config_Insert_Input;
  on_conflict?: InputMaybe<Github_Integration_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Github_Integration_ModeArgs = {
  objects: Array<Github_Integration_Mode_Insert_Input>;
  on_conflict?: InputMaybe<Github_Integration_Mode_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Github_Integration_Mode_OneArgs = {
  object: Github_Integration_Mode_Insert_Input;
  on_conflict?: InputMaybe<Github_Integration_Mode_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Github_Push_EventArgs = {
  objects: Array<Github_Push_Event_Insert_Input>;
  on_conflict?: InputMaybe<Github_Push_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Github_Push_Event_JobArgs = {
  objects: Array<Github_Push_Event_Job_Insert_Input>;
  on_conflict?: InputMaybe<Github_Push_Event_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Github_Push_Event_Job_OneArgs = {
  object: Github_Push_Event_Job_Insert_Input;
  on_conflict?: InputMaybe<Github_Push_Event_Job_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Github_Push_Event_OneArgs = {
  object: Github_Push_Event_Insert_Input;
  on_conflict?: InputMaybe<Github_Push_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hasura_AmiArgs = {
  objects: Array<Hasura_Ami_Insert_Input>;
  on_conflict?: InputMaybe<Hasura_Ami_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hasura_Ami_OneArgs = {
  object: Hasura_Ami_Insert_Input;
  on_conflict?: InputMaybe<Hasura_Ami_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hasura_ClusterArgs = {
  objects: Array<Hasura_Cluster_Insert_Input>;
  on_conflict?: InputMaybe<Hasura_Cluster_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hasura_Cluster_OneArgs = {
  object: Hasura_Cluster_Insert_Input;
  on_conflict?: InputMaybe<Hasura_Cluster_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hasura_WorkerArgs = {
  objects: Array<Hasura_Worker_Insert_Input>;
  on_conflict?: InputMaybe<Hasura_Worker_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hasura_Worker_OneArgs = {
  object: Hasura_Worker_Insert_Input;
  on_conflict?: InputMaybe<Hasura_Worker_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hasura_Year_In_Report_ActivityArgs = {
  objects: Array<Hasura_Year_In_Report_Activity_Insert_Input>;
  on_conflict?: InputMaybe<Hasura_Year_In_Report_Activity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hasura_Year_In_Report_Activity_OneArgs = {
  object: Hasura_Year_In_Report_Activity_Insert_Input;
  on_conflict?: InputMaybe<Hasura_Year_In_Report_Activity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hasura_Year_In_Report_MetricsArgs = {
  objects: Array<Hasura_Year_In_Report_Metrics_Insert_Input>;
  on_conflict?: InputMaybe<Hasura_Year_In_Report_Metrics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Hasura_Year_In_Report_Metrics_OneArgs = {
  object: Hasura_Year_In_Report_Metrics_Insert_Input;
  on_conflict?: InputMaybe<Hasura_Year_In_Report_Metrics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Heroku_IntegrationsArgs = {
  objects: Array<Heroku_Integrations_Insert_Input>;
  on_conflict?: InputMaybe<Heroku_Integrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Heroku_Integrations_OneArgs = {
  object: Heroku_Integrations_Insert_Input;
  on_conflict?: InputMaybe<Heroku_Integrations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inactive_Project_ExclusionsArgs = {
  objects: Array<Inactive_Project_Exclusions_Insert_Input>;
  on_conflict?: InputMaybe<Inactive_Project_Exclusions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inactive_Project_Exclusions_OneArgs = {
  object: Inactive_Project_Exclusions_Insert_Input;
  on_conflict?: InputMaybe<Inactive_Project_Exclusions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inactive_Project_NotificationsArgs = {
  objects: Array<Inactive_Project_Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Inactive_Project_Notifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inactive_Project_Notifications_OneArgs = {
  object: Inactive_Project_Notifications_Insert_Input;
  on_conflict?: InputMaybe<Inactive_Project_Notifications_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inactive_Project_Suspension_ConfigArgs = {
  objects: Array<Inactive_Project_Suspension_Config_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Inactive_Project_Suspension_Config_OneArgs = {
  object: Inactive_Project_Suspension_Config_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Infra_StatusArgs = {
  objects: Array<Infra_Status_Insert_Input>;
  on_conflict?: InputMaybe<Infra_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Infra_Status_OneArgs = {
  object: Infra_Status_Insert_Input;
  on_conflict?: InputMaybe<Infra_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_InvoiceArgs = {
  objects: Array<Invoice_Insert_Input>;
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invoice_Coupon_DiscountArgs = {
  objects: Array<Invoice_Coupon_Discount_Insert_Input>;
  on_conflict?: InputMaybe<Invoice_Coupon_Discount_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invoice_Coupon_Discount_OneArgs = {
  object: Invoice_Coupon_Discount_Insert_Input;
  on_conflict?: InputMaybe<Invoice_Coupon_Discount_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invoice_ItemArgs = {
  objects: Array<Invoice_Item_Insert_Input>;
  on_conflict?: InputMaybe<Invoice_Item_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invoice_Item_OneArgs = {
  object: Invoice_Item_Insert_Input;
  on_conflict?: InputMaybe<Invoice_Item_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invoice_OneArgs = {
  object: Invoice_Insert_Input;
  on_conflict?: InputMaybe<Invoice_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_JobsArgs = {
  objects: Array<Jobs_Insert_Input>;
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Jobs_OneArgs = {
  object: Jobs_Insert_Input;
  on_conflict?: InputMaybe<Jobs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LabelArgs = {
  objects: Array<Label_Insert_Input>;
  on_conflict?: InputMaybe<Label_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Label_OneArgs = {
  object: Label_Insert_Input;
  on_conflict?: InputMaybe<Label_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Letsencrypt_StatusArgs = {
  objects: Array<Letsencrypt_Status_Insert_Input>;
  on_conflict?: InputMaybe<Letsencrypt_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Letsencrypt_Status_OneArgs = {
  object: Letsencrypt_Status_Insert_Input;
  on_conflict?: InputMaybe<Letsencrypt_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_License_ActivityArgs = {
  objects: Array<License_Activity_Insert_Input>;
  on_conflict?: InputMaybe<License_Activity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_License_Activity_OneArgs = {
  object: License_Activity_Insert_Input;
  on_conflict?: InputMaybe<License_Activity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_License_InstanceArgs = {
  objects: Array<License_Instance_Insert_Input>;
  on_conflict?: InputMaybe<License_Instance_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_License_Instance_OneArgs = {
  object: License_Instance_Insert_Input;
  on_conflict?: InputMaybe<License_Instance_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_License_TypeArgs = {
  objects: Array<License_Type_Insert_Input>;
  on_conflict?: InputMaybe<License_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_License_Type_OneArgs = {
  object: License_Type_Insert_Input;
  on_conflict?: InputMaybe<License_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LicensesArgs = {
  objects: Array<Licenses_Insert_Input>;
  on_conflict?: InputMaybe<Licenses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Licenses_OneArgs = {
  object: Licenses_Insert_Input;
  on_conflict?: InputMaybe<Licenses_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lux_ProxyArgs = {
  objects: Array<Lux_Proxy_Insert_Input>;
  on_conflict?: InputMaybe<Lux_Proxy_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lux_Proxy_AmiArgs = {
  objects: Array<Lux_Proxy_Ami_Insert_Input>;
  on_conflict?: InputMaybe<Lux_Proxy_Ami_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lux_Proxy_Ami_OneArgs = {
  object: Lux_Proxy_Ami_Insert_Input;
  on_conflict?: InputMaybe<Lux_Proxy_Ami_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Lux_Proxy_OneArgs = {
  object: Lux_Proxy_Insert_Input;
  on_conflict?: InputMaybe<Lux_Proxy_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Model_Based_Enterprise_Cloud_CommitmentsArgs = {
  objects: Array<Model_Based_Enterprise_Cloud_Commitments_Insert_Input>;
  on_conflict?: InputMaybe<
    Model_Based_Enterprise_Cloud_Commitments_On_Conflict
  >;
};

/** mutation root */
export type Mutation_RootInsert_Model_Based_Enterprise_Cloud_Commitments_OneArgs = {
  object: Model_Based_Enterprise_Cloud_Commitments_Insert_Input;
  on_conflict?: InputMaybe<
    Model_Based_Enterprise_Cloud_Commitments_On_Conflict
  >;
};

/** mutation root */
export type Mutation_RootInsert_Neon_Db_IntegrationArgs = {
  objects: Array<Neon_Db_Integration_Insert_Input>;
  on_conflict?: InputMaybe<Neon_Db_Integration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Neon_Db_Integration_OneArgs = {
  object: Neon_Db_Integration_Insert_Input;
  on_conflict?: InputMaybe<Neon_Db_Integration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Newrelic_ConfigArgs = {
  objects: Array<Newrelic_Config_Insert_Input>;
  on_conflict?: InputMaybe<Newrelic_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Newrelic_Config_OneArgs = {
  object: Newrelic_Config_Insert_Input;
  on_conflict?: InputMaybe<Newrelic_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Node_Pool_TypeArgs = {
  objects: Array<Node_Pool_Type_Insert_Input>;
  on_conflict?: InputMaybe<Node_Pool_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Node_Pool_Type_OneArgs = {
  object: Node_Pool_Type_Insert_Input;
  on_conflict?: InputMaybe<Node_Pool_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Node_PoolsArgs = {
  objects: Array<Node_Pools_Insert_Input>;
  on_conflict?: InputMaybe<Node_Pools_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Node_Pools_OneArgs = {
  object: Node_Pools_Insert_Input;
  on_conflict?: InputMaybe<Node_Pools_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_NotificationArgs = {
  objects: Array<Notification_Insert_Input>;
  on_conflict?: InputMaybe<Notification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notification_OneArgs = {
  object: Notification_Insert_Input;
  on_conflict?: InputMaybe<Notification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notification_TypeArgs = {
  objects: Array<Notification_Type_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notification_Type_OneArgs = {
  object: Notification_Type_Insert_Input;
  on_conflict?: InputMaybe<Notification_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Onboarding_Sample_Db_CohortArgs = {
  objects: Array<Onboarding_Sample_Db_Cohort_Insert_Input>;
  on_conflict?: InputMaybe<Onboarding_Sample_Db_Cohort_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Onboarding_Sample_Db_Cohort_OneArgs = {
  object: Onboarding_Sample_Db_Cohort_Insert_Input;
  on_conflict?: InputMaybe<Onboarding_Sample_Db_Cohort_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Onboarding_Sample_Db_ConfigArgs = {
  objects: Array<Onboarding_Sample_Db_Config_Insert_Input>;
  on_conflict?: InputMaybe<Onboarding_Sample_Db_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Onboarding_Sample_Db_Config_OneArgs = {
  object: Onboarding_Sample_Db_Config_Insert_Input;
  on_conflict?: InputMaybe<Onboarding_Sample_Db_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_One_Click_DeploymentArgs = {
  objects: Array<One_Click_Deployment_Insert_Input>;
  on_conflict?: InputMaybe<One_Click_Deployment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_One_Click_Deployment_OneArgs = {
  object: One_Click_Deployment_Insert_Input;
  on_conflict?: InputMaybe<One_Click_Deployment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_One_Click_Deployment_Sample_AppsArgs = {
  objects: Array<One_Click_Deployment_Sample_Apps_Insert_Input>;
  on_conflict?: InputMaybe<One_Click_Deployment_Sample_Apps_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_One_Click_Deployment_Sample_Apps_OneArgs = {
  object: One_Click_Deployment_Sample_Apps_Insert_Input;
  on_conflict?: InputMaybe<One_Click_Deployment_Sample_Apps_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_One_Click_Deployment_State_LogArgs = {
  objects: Array<One_Click_Deployment_State_Log_Insert_Input>;
  on_conflict?: InputMaybe<One_Click_Deployment_State_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_One_Click_Deployment_State_Log_OneArgs = {
  object: One_Click_Deployment_State_Log_Insert_Input;
  on_conflict?: InputMaybe<One_Click_Deployment_State_Log_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_One_Click_Deployment_StatesArgs = {
  objects: Array<One_Click_Deployment_States_Insert_Input>;
  on_conflict?: InputMaybe<One_Click_Deployment_States_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_One_Click_Deployment_States_OneArgs = {
  object: One_Click_Deployment_States_Insert_Input;
  on_conflict?: InputMaybe<One_Click_Deployment_States_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Opentelemetry_ConfigArgs = {
  objects: Array<Opentelemetry_Config_Insert_Input>;
  on_conflict?: InputMaybe<Opentelemetry_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Opentelemetry_Config_OneArgs = {
  object: Opentelemetry_Config_Insert_Input;
  on_conflict?: InputMaybe<Opentelemetry_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Operations_Delete_Free_Plan_Dns_Records_Nov_2021Args = {
  objects: Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Insert_Input>;
  on_conflict?: InputMaybe<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021_On_Conflict
  >;
};

/** mutation root */
export type Mutation_RootInsert_Operations_Delete_Free_Plan_Dns_Records_Nov_2021_OneArgs = {
  object: Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Insert_Input;
  on_conflict?: InputMaybe<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021_On_Conflict
  >;
};

/** mutation root */
export type Mutation_RootInsert_Operations_TenantArgs = {
  objects: Array<Operations_Tenant_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Operations_Tenant_OneArgs = {
  object: Operations_Tenant_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Payment_MethodArgs = {
  objects: Array<Payment_Method_Insert_Input>;
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Payment_Method_OneArgs = {
  object: Payment_Method_Insert_Input;
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Plan_EntitlementsArgs = {
  objects: Array<Plan_Entitlements_Insert_Input>;
  on_conflict?: InputMaybe<Plan_Entitlements_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Plan_Entitlements_OneArgs = {
  object: Plan_Entitlements_Insert_Input;
  on_conflict?: InputMaybe<Plan_Entitlements_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PlansArgs = {
  objects: Array<Plans_Insert_Input>;
  on_conflict?: InputMaybe<Plans_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Plans_OneArgs = {
  object: Plans_Insert_Input;
  on_conflict?: InputMaybe<Plans_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PoliciesArgs = {
  objects: Array<Policies_Insert_Input>;
  on_conflict?: InputMaybe<Policies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policies_OneArgs = {
  object: Policies_Insert_Input;
  on_conflict?: InputMaybe<Policies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PrivilegesArgs = {
  objects: Array<Privileges_Insert_Input>;
  on_conflict?: InputMaybe<Privileges_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Privileges_OneArgs = {
  object: Privileges_Insert_Input;
  on_conflict?: InputMaybe<Privileges_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Pro_License_KeysArgs = {
  objects: Array<Pro_License_Keys_Insert_Input>;
  on_conflict?: InputMaybe<Pro_License_Keys_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Pro_License_Keys_OneArgs = {
  object: Pro_License_Keys_Insert_Input;
  on_conflict?: InputMaybe<Pro_License_Keys_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_ActivityArgs = {
  objects: Array<Project_Activity_Insert_Input>;
  on_conflict?: InputMaybe<Project_Activity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Activity_OneArgs = {
  object: Project_Activity_Insert_Input;
  on_conflict?: InputMaybe<Project_Activity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Billing_Manager_InvitationsArgs = {
  objects: Array<Project_Billing_Manager_Invitations_Insert_Input>;
  on_conflict?: InputMaybe<Project_Billing_Manager_Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Billing_Manager_Invitations_OneArgs = {
  object: Project_Billing_Manager_Invitations_Insert_Input;
  on_conflict?: InputMaybe<Project_Billing_Manager_Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Collaborator_Allowed_Schema_TablesArgs = {
  objects: Array<Project_Collaborator_Allowed_Schema_Tables_Insert_Input>;
  on_conflict?: InputMaybe<
    Project_Collaborator_Allowed_Schema_Tables_On_Conflict
  >;
};

/** mutation root */
export type Mutation_RootInsert_Project_Collaborator_Allowed_Schema_Tables_OneArgs = {
  object: Project_Collaborator_Allowed_Schema_Tables_Insert_Input;
  on_conflict?: InputMaybe<
    Project_Collaborator_Allowed_Schema_Tables_On_Conflict
  >;
};

/** mutation root */
export type Mutation_RootInsert_Project_Collaborator_Allowed_SchemasArgs = {
  objects: Array<Project_Collaborator_Allowed_Schemas_Insert_Input>;
  on_conflict?: InputMaybe<Project_Collaborator_Allowed_Schemas_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Collaborator_Allowed_Schemas_OneArgs = {
  object: Project_Collaborator_Allowed_Schemas_Insert_Input;
  on_conflict?: InputMaybe<Project_Collaborator_Allowed_Schemas_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Collaborator_InvitationsArgs = {
  objects: Array<Project_Collaborator_Invitations_Insert_Input>;
  on_conflict?: InputMaybe<Project_Collaborator_Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Collaborator_Invitations_OneArgs = {
  object: Project_Collaborator_Invitations_Insert_Input;
  on_conflict?: InputMaybe<Project_Collaborator_Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Collaborator_PrivilegesArgs = {
  objects: Array<Project_Collaborator_Privileges_Insert_Input>;
  on_conflict?: InputMaybe<Project_Collaborator_Privileges_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Collaborator_Privileges_OneArgs = {
  object: Project_Collaborator_Privileges_Insert_Input;
  on_conflict?: InputMaybe<Project_Collaborator_Privileges_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_CollaboratorsArgs = {
  objects: Array<Project_Collaborators_Insert_Input>;
  on_conflict?: InputMaybe<Project_Collaborators_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Collaborators_OneArgs = {
  object: Project_Collaborators_Insert_Input;
  on_conflict?: InputMaybe<Project_Collaborators_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_UsageArgs = {
  objects: Array<Project_Data_Usage_Insert_Input>;
  on_conflict?: InputMaybe<Project_Data_Usage_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_AggArgs = {
  objects: Array<Project_Data_Usage_Agg_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_Agg_OneArgs = {
  object: Project_Data_Usage_Agg_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_Agg_UserArgs = {
  objects: Array<Project_Data_Usage_Agg_User_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_Agg_User_OneArgs = {
  object: Project_Data_Usage_Agg_User_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_ComponentsArgs = {
  objects: Array<Project_Data_Usage_Components_Insert_Input>;
  on_conflict?: InputMaybe<Project_Data_Usage_Components_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_Components_OneArgs = {
  object: Project_Data_Usage_Components_Insert_Input;
  on_conflict?: InputMaybe<Project_Data_Usage_Components_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_OneArgs = {
  object: Project_Data_Usage_Insert_Input;
  on_conflict?: InputMaybe<Project_Data_Usage_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_PrometheusArgs = {
  objects: Array<Project_Data_Usage_Prometheus_Insert_Input>;
  on_conflict?: InputMaybe<Project_Data_Usage_Prometheus_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_Prometheus_Agg_UserArgs = {
  objects: Array<Project_Data_Usage_Prometheus_Agg_User_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_Prometheus_Agg_User_OneArgs = {
  object: Project_Data_Usage_Prometheus_Agg_User_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_Prometheus_OneArgs = {
  object: Project_Data_Usage_Prometheus_Insert_Input;
  on_conflict?: InputMaybe<Project_Data_Usage_Prometheus_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_ReportArgs = {
  objects: Array<Project_Data_Usage_Report_Insert_Input>;
  on_conflict?: InputMaybe<Project_Data_Usage_Report_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Data_Usage_Report_OneArgs = {
  object: Project_Data_Usage_Report_Insert_Input;
  on_conflict?: InputMaybe<Project_Data_Usage_Report_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Db_UsageArgs = {
  objects: Array<Project_Db_Usage_Insert_Input>;
  on_conflict?: InputMaybe<Project_Db_Usage_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Db_Usage_Agg_UserArgs = {
  objects: Array<Project_Db_Usage_Agg_User_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Db_Usage_Agg_User_OneArgs = {
  object: Project_Db_Usage_Agg_User_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Project_Db_Usage_OneArgs = {
  object: Project_Db_Usage_Insert_Input;
  on_conflict?: InputMaybe<Project_Db_Usage_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Entitlement_AccessArgs = {
  objects: Array<Project_Entitlement_Access_Insert_Input>;
  on_conflict?: InputMaybe<Project_Entitlement_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Entitlement_Access_OneArgs = {
  object: Project_Entitlement_Access_Insert_Input;
  on_conflict?: InputMaybe<Project_Entitlement_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Entitlement_CatalogueArgs = {
  objects: Array<Project_Entitlement_Catalogue_Insert_Input>;
  on_conflict?: InputMaybe<Project_Entitlement_Catalogue_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Entitlement_Catalogue_OneArgs = {
  object: Project_Entitlement_Catalogue_Insert_Input;
  on_conflict?: InputMaybe<Project_Entitlement_Catalogue_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Entitlement_TypesArgs = {
  objects: Array<Project_Entitlement_Types_Insert_Input>;
  on_conflict?: InputMaybe<Project_Entitlement_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Entitlement_Types_OneArgs = {
  object: Project_Entitlement_Types_Insert_Input;
  on_conflict?: InputMaybe<Project_Entitlement_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_LabelsArgs = {
  objects: Array<Project_Labels_Insert_Input>;
  on_conflict?: InputMaybe<Project_Labels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Labels_OneArgs = {
  object: Project_Labels_Insert_Input;
  on_conflict?: InputMaybe<Project_Labels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_MetadataArgs = {
  objects: Array<Project_Metadata_Insert_Input>;
  on_conflict?: InputMaybe<Project_Metadata_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Metadata_OneArgs = {
  object: Project_Metadata_Insert_Input;
  on_conflict?: InputMaybe<Project_Metadata_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_NotificationArgs = {
  objects: Array<Project_Notification_Insert_Input>;
  on_conflict?: InputMaybe<Project_Notification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Notification_OneArgs = {
  object: Project_Notification_Insert_Input;
  on_conflict?: InputMaybe<Project_Notification_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Ownership_Transfer_InvitationsArgs = {
  objects: Array<Project_Ownership_Transfer_Invitations_Insert_Input>;
  on_conflict?: InputMaybe<Project_Ownership_Transfer_Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Ownership_Transfer_Invitations_OneArgs = {
  object: Project_Ownership_Transfer_Invitations_Insert_Input;
  on_conflict?: InputMaybe<Project_Ownership_Transfer_Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Requests_CountArgs = {
  objects: Array<Project_Requests_Count_Insert_Input>;
  on_conflict?: InputMaybe<Project_Requests_Count_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Requests_Count_OneArgs = {
  object: Project_Requests_Count_Insert_Input;
  on_conflict?: InputMaybe<Project_Requests_Count_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_StatsArgs = {
  objects: Array<Project_Stats_Insert_Input>;
  on_conflict?: InputMaybe<Project_Stats_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Stats_OneArgs = {
  object: Project_Stats_Insert_Input;
  on_conflict?: InputMaybe<Project_Stats_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Total_Db_Usage_AggArgs = {
  objects: Array<Project_Total_Db_Usage_Agg_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Project_Total_Db_Usage_Agg_OneArgs = {
  object: Project_Total_Db_Usage_Agg_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_ProjectsArgs = {
  objects: Array<Projects_Insert_Input>;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Projects_OneArgs = {
  object: Projects_Insert_Input;
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Projects_Pool_ConfigArgs = {
  objects: Array<Projects_Pool_Config_Insert_Input>;
  on_conflict?: InputMaybe<Projects_Pool_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Projects_Pool_Config_OneArgs = {
  object: Projects_Pool_Config_Insert_Input;
  on_conflict?: InputMaybe<Projects_Pool_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Projects_Pro_Key_GenerationsArgs = {
  objects: Array<Projects_Pro_Key_Generations_Insert_Input>;
  on_conflict?: InputMaybe<Projects_Pro_Key_Generations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Projects_Pro_Key_Generations_OneArgs = {
  object: Projects_Pro_Key_Generations_Insert_Input;
  on_conflict?: InputMaybe<Projects_Pro_Key_Generations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Prometheus_ConfigArgs = {
  objects: Array<Prometheus_Config_Insert_Input>;
  on_conflict?: InputMaybe<Prometheus_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Prometheus_Config_OneArgs = {
  object: Prometheus_Config_Insert_Input;
  on_conflict?: InputMaybe<Prometheus_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProvidersArgs = {
  objects: Array<Providers_Insert_Input>;
  on_conflict?: InputMaybe<Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Providers_OneArgs = {
  object: Providers_Insert_Input;
  on_conflict?: InputMaybe<Providers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProvisioningArgs = {
  objects: Array<Provisioning_Insert_Input>;
  on_conflict?: InputMaybe<Provisioning_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Provisioning_OneArgs = {
  object: Provisioning_Insert_Input;
  on_conflict?: InputMaybe<Provisioning_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RegionArgs = {
  objects: Array<Region_Insert_Input>;
  on_conflict?: InputMaybe<Region_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Region_OneArgs = {
  object: Region_Insert_Input;
  on_conflict?: InputMaybe<Region_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Region_V2Args = {
  objects: Array<Region_V2_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Region_V2_OneArgs = {
  object: Region_V2_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Regional_Data_ConnectorsArgs = {
  objects: Array<Regional_Data_Connectors_Insert_Input>;
  on_conflict?: InputMaybe<Regional_Data_Connectors_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Regional_Data_Connectors_OneArgs = {
  object: Regional_Data_Connectors_Insert_Input;
  on_conflict?: InputMaybe<Regional_Data_Connectors_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Regional_MetricsArgs = {
  objects: Array<Regional_Metrics_Insert_Input>;
  on_conflict?: InputMaybe<Regional_Metrics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Regional_Metrics_OneArgs = {
  object: Regional_Metrics_Insert_Input;
  on_conflict?: InputMaybe<Regional_Metrics_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Saml_IdpArgs = {
  objects: Array<Saml_Idp_Insert_Input>;
  on_conflict?: InputMaybe<Saml_Idp_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Saml_Idp_OneArgs = {
  object: Saml_Idp_Insert_Input;
  on_conflict?: InputMaybe<Saml_Idp_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Search_Project_Login_Status_ResultsArgs = {
  objects: Array<Search_Project_Login_Status_Results_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Search_Project_Login_Status_Results_OneArgs = {
  object: Search_Project_Login_Status_Results_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Slack_ConfigArgs = {
  objects: Array<Slack_Config_Insert_Input>;
  on_conflict?: InputMaybe<Slack_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Slack_Config_OneArgs = {
  object: Slack_Config_Insert_Input;
  on_conflict?: InputMaybe<Slack_Config_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stripe_SubscriptionArgs = {
  objects: Array<Stripe_Subscription_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Subscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stripe_Subscription_OneArgs = {
  object: Stripe_Subscription_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Subscription_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stripe_Webhook_EventsArgs = {
  objects: Array<Stripe_Webhook_Events_Insert_Input>;
  on_conflict?: InputMaybe<Stripe_Webhook_Events_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stripe_Webhook_Events_OneArgs = {
  object: Stripe_Webhook_Events_Insert_Input;
  on_conflict?: InputMaybe<Stripe_Webhook_Events_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Super_Connector_TypesArgs = {
  objects: Array<Super_Connector_Types_Insert_Input>;
  on_conflict?: InputMaybe<Super_Connector_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Super_Connector_Types_OneArgs = {
  object: Super_Connector_Types_Insert_Input;
  on_conflict?: InputMaybe<Super_Connector_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Support_Plan_TypesArgs = {
  objects: Array<Support_Plan_Types_Insert_Input>;
  on_conflict?: InputMaybe<Support_Plan_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Support_Plan_Types_OneArgs = {
  object: Support_Plan_Types_Insert_Input;
  on_conflict?: InputMaybe<Support_Plan_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SurveyArgs = {
  objects: Array<Survey_Insert_Input>;
  on_conflict?: InputMaybe<Survey_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_OneArgs = {
  object: Survey_Insert_Input;
  on_conflict?: InputMaybe<Survey_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_QuestionArgs = {
  objects: Array<Survey_Question_Insert_Input>;
  on_conflict?: InputMaybe<Survey_Question_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_Question_Answer_OptionArgs = {
  objects: Array<Survey_Question_Answer_Option_Insert_Input>;
  on_conflict?: InputMaybe<Survey_Question_Answer_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_Question_Answer_Option_OneArgs = {
  object: Survey_Question_Answer_Option_Insert_Input;
  on_conflict?: InputMaybe<Survey_Question_Answer_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_Question_AnswersArgs = {
  objects: Array<Survey_Question_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Survey_Question_Answers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_Question_Answers_OneArgs = {
  object: Survey_Question_Answers_Insert_Input;
  on_conflict?: InputMaybe<Survey_Question_Answers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_Question_KindArgs = {
  objects: Array<Survey_Question_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Survey_Question_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_Question_Kind_OneArgs = {
  object: Survey_Question_Kind_Insert_Input;
  on_conflict?: InputMaybe<Survey_Question_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_Question_OneArgs = {
  object: Survey_Question_Insert_Input;
  on_conflict?: InputMaybe<Survey_Question_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_Question_OptionsArgs = {
  objects: Array<Survey_Question_Options_Insert_Input>;
  on_conflict?: InputMaybe<Survey_Question_Options_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_Question_Options_OneArgs = {
  object: Survey_Question_Options_Insert_Input;
  on_conflict?: InputMaybe<Survey_Question_Options_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2Args = {
  objects: Array<Survey_V2_Insert_Input>;
  on_conflict?: InputMaybe<Survey_V2_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_OneArgs = {
  object: Survey_V2_Insert_Input;
  on_conflict?: InputMaybe<Survey_V2_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_QuestionArgs = {
  objects: Array<Survey_V2_Question_Insert_Input>;
  on_conflict?: InputMaybe<Survey_V2_Question_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_Question_KindArgs = {
  objects: Array<Survey_V2_Question_Kind_Insert_Input>;
  on_conflict?: InputMaybe<Survey_V2_Question_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_Question_Kind_OneArgs = {
  object: Survey_V2_Question_Kind_Insert_Input;
  on_conflict?: InputMaybe<Survey_V2_Question_Kind_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_Question_OneArgs = {
  object: Survey_V2_Question_Insert_Input;
  on_conflict?: InputMaybe<Survey_V2_Question_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_Question_OptionArgs = {
  objects: Array<Survey_V2_Question_Option_Insert_Input>;
  on_conflict?: InputMaybe<Survey_V2_Question_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_Question_Option_Additional_Info_ConfigArgs = {
  objects: Array<Survey_V2_Question_Option_Additional_Info_Config_Insert_Input>;
  on_conflict?: InputMaybe<
    Survey_V2_Question_Option_Additional_Info_Config_On_Conflict
  >;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_Question_Option_Additional_Info_Config_OneArgs = {
  object: Survey_V2_Question_Option_Additional_Info_Config_Insert_Input;
  on_conflict?: InputMaybe<
    Survey_V2_Question_Option_Additional_Info_Config_On_Conflict
  >;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_Question_Option_OneArgs = {
  object: Survey_V2_Question_Option_Insert_Input;
  on_conflict?: InputMaybe<Survey_V2_Question_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_ResponseArgs = {
  objects: Array<Survey_V2_Response_Insert_Input>;
  on_conflict?: InputMaybe<Survey_V2_Response_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_Response_AnswerArgs = {
  objects: Array<Survey_V2_Response_Answer_Insert_Input>;
  on_conflict?: InputMaybe<Survey_V2_Response_Answer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_Response_Answer_OneArgs = {
  object: Survey_V2_Response_Answer_Insert_Input;
  on_conflict?: InputMaybe<Survey_V2_Response_Answer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_Response_Answer_OptionArgs = {
  objects: Array<Survey_V2_Response_Answer_Option_Insert_Input>;
  on_conflict?: InputMaybe<Survey_V2_Response_Answer_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_Response_Answer_Option_OneArgs = {
  object: Survey_V2_Response_Answer_Option_Insert_Input;
  on_conflict?: InputMaybe<Survey_V2_Response_Answer_Option_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Survey_V2_Response_OneArgs = {
  object: Survey_V2_Response_Insert_Input;
  on_conflict?: InputMaybe<Survey_V2_Response_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_EventArgs = {
  objects: Array<Task_Event_Insert_Input>;
  on_conflict?: InputMaybe<Task_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Event_OneArgs = {
  object: Task_Event_Insert_Input;
  on_conflict?: InputMaybe<Task_Event_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TasksArgs = {
  objects: Array<Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_OneArgs = {
  object: Tasks_Insert_Input;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TenantArgs = {
  objects: Array<Tenant_Insert_Input>;
  on_conflict?: InputMaybe<Tenant_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tenant_Active_Status_ReasonArgs = {
  objects: Array<Tenant_Active_Status_Reason_Insert_Input>;
  on_conflict?: InputMaybe<Tenant_Active_Status_Reason_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tenant_Active_Status_Reason_OneArgs = {
  object: Tenant_Active_Status_Reason_Insert_Input;
  on_conflict?: InputMaybe<Tenant_Active_Status_Reason_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tenant_GroupArgs = {
  objects: Array<Tenant_Group_Insert_Input>;
  on_conflict?: InputMaybe<Tenant_Group_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tenant_Group_MemberArgs = {
  objects: Array<Tenant_Group_Member_Insert_Input>;
  on_conflict?: InputMaybe<Tenant_Group_Member_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tenant_Group_Member_OneArgs = {
  object: Tenant_Group_Member_Insert_Input;
  on_conflict?: InputMaybe<Tenant_Group_Member_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tenant_Group_OneArgs = {
  object: Tenant_Group_Insert_Input;
  on_conflict?: InputMaybe<Tenant_Group_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tenant_OneArgs = {
  object: Tenant_Insert_Input;
  on_conflict?: InputMaybe<Tenant_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tenant_Public_StatusArgs = {
  objects: Array<Tenant_Public_Status_Insert_Input>;
  on_conflict?: InputMaybe<Tenant_Public_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tenant_Public_Status_OneArgs = {
  object: Tenant_Public_Status_Insert_Input;
  on_conflict?: InputMaybe<Tenant_Public_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tenant_StatusArgs = {
  objects: Array<Tenant_Status_Insert_Input>;
  on_conflict?: InputMaybe<Tenant_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tenant_Status_OneArgs = {
  object: Tenant_Status_Insert_Input;
  on_conflict?: InputMaybe<Tenant_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Trial_LeadsArgs = {
  objects: Array<Trial_Leads_Insert_Input>;
  on_conflict?: InputMaybe<Trial_Leads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Trial_Leads_OneArgs = {
  object: Trial_Leads_Insert_Input;
  on_conflict?: InputMaybe<Trial_Leads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ua_Audit_LogsArgs = {
  objects: Array<Ua_Audit_Logs_Insert_Input>;
  on_conflict?: InputMaybe<Ua_Audit_Logs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ua_Audit_Logs_OneArgs = {
  object: Ua_Audit_Logs_Insert_Input;
  on_conflict?: InputMaybe<Ua_Audit_Logs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_ActivityArgs = {
  objects: Array<User_Activity_Insert_Input>;
  on_conflict?: InputMaybe<User_Activity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Activity_OneArgs = {
  object: User_Activity_Insert_Input;
  on_conflict?: InputMaybe<User_Activity_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_CouponArgs = {
  objects: Array<User_Coupon_Insert_Input>;
  on_conflict?: InputMaybe<User_Coupon_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Coupon_OneArgs = {
  object: User_Coupon_Insert_Input;
  on_conflict?: InputMaybe<User_Coupon_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Entitlement_AccessArgs = {
  objects: Array<User_Entitlement_Access_Insert_Input>;
  on_conflict?: InputMaybe<User_Entitlement_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Entitlement_Access_OneArgs = {
  object: User_Entitlement_Access_Insert_Input;
  on_conflict?: InputMaybe<User_Entitlement_Access_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Entitlement_CatalogueArgs = {
  objects: Array<User_Entitlement_Catalogue_Insert_Input>;
  on_conflict?: InputMaybe<User_Entitlement_Catalogue_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Entitlement_Catalogue_OneArgs = {
  object: User_Entitlement_Catalogue_Insert_Input;
  on_conflict?: InputMaybe<User_Entitlement_Catalogue_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Entitlement_TypesArgs = {
  objects: Array<User_Entitlement_Types_Insert_Input>;
  on_conflict?: InputMaybe<User_Entitlement_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Entitlement_Types_OneArgs = {
  object: User_Entitlement_Types_Insert_Input;
  on_conflict?: InputMaybe<User_Entitlement_Types_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_OnboardingArgs = {
  objects: Array<User_Onboarding_Insert_Input>;
  on_conflict?: InputMaybe<User_Onboarding_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Onboarding_OneArgs = {
  object: User_Onboarding_Insert_Input;
  on_conflict?: InputMaybe<User_Onboarding_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Personal_Access_TokensArgs = {
  objects: Array<User_Personal_Access_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<User_Personal_Access_Tokens_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Personal_Access_Tokens_OneArgs = {
  object: User_Personal_Access_Tokens_Insert_Input;
  on_conflict?: InputMaybe<User_Personal_Access_Tokens_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_ProfileArgs = {
  objects: Array<User_Profile_Insert_Input>;
  on_conflict?: InputMaybe<User_Profile_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Profile_OneArgs = {
  object: User_Profile_Insert_Input;
  on_conflict?: InputMaybe<User_Profile_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_RolesArgs = {
  objects: Array<User_Roles_Insert_Input>;
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Roles_OneArgs = {
  object: User_Roles_Insert_Input;
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Vpc_PolicyArgs = {
  objects: Array<User_Vpc_Policy_Insert_Input>;
  on_conflict?: InputMaybe<User_Vpc_Policy_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_User_Vpc_Policy_OneArgs = {
  object: User_Vpc_Policy_Insert_Input;
  on_conflict?: InputMaybe<User_Vpc_Policy_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Users_PublicArgs = {
  objects: Array<Users_Public_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Users_Public_OneArgs = {
  object: Users_Public_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Vercel_IntegrationArgs = {
  objects: Array<Vercel_Integration_Insert_Input>;
  on_conflict?: InputMaybe<Vercel_Integration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vercel_Integration_ConnectionsArgs = {
  objects: Array<Vercel_Integration_Connections_Insert_Input>;
  on_conflict?: InputMaybe<Vercel_Integration_Connections_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vercel_Integration_Connections_OneArgs = {
  object: Vercel_Integration_Connections_Insert_Input;
  on_conflict?: InputMaybe<Vercel_Integration_Connections_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vercel_Integration_OneArgs = {
  object: Vercel_Integration_Insert_Input;
  on_conflict?: InputMaybe<Vercel_Integration_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vpc_Collaborator_InvitationsArgs = {
  objects: Array<Vpc_Collaborator_Invitations_Insert_Input>;
  on_conflict?: InputMaybe<Vpc_Collaborator_Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vpc_Collaborator_Invitations_OneArgs = {
  object: Vpc_Collaborator_Invitations_Insert_Input;
  on_conflict?: InputMaybe<Vpc_Collaborator_Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vpc_CollaboratorsArgs = {
  objects: Array<Vpc_Collaborators_Insert_Input>;
  on_conflict?: InputMaybe<Vpc_Collaborators_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vpc_Collaborators_OneArgs = {
  object: Vpc_Collaborators_Insert_Input;
  on_conflict?: InputMaybe<Vpc_Collaborators_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vpc_PeeringArgs = {
  objects: Array<Vpc_Peering_Insert_Input>;
  on_conflict?: InputMaybe<Vpc_Peering_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vpc_Peering_DirectionArgs = {
  objects: Array<Vpc_Peering_Direction_Insert_Input>;
  on_conflict?: InputMaybe<Vpc_Peering_Direction_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vpc_Peering_Direction_OneArgs = {
  object: Vpc_Peering_Direction_Insert_Input;
  on_conflict?: InputMaybe<Vpc_Peering_Direction_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vpc_Peering_OneArgs = {
  object: Vpc_Peering_Insert_Input;
  on_conflict?: InputMaybe<Vpc_Peering_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vpc_PrivilegesArgs = {
  objects: Array<Vpc_Privileges_Insert_Input>;
  on_conflict?: InputMaybe<Vpc_Privileges_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vpc_Privileges_OneArgs = {
  object: Vpc_Privileges_Insert_Input;
  on_conflict?: InputMaybe<Vpc_Privileges_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vpc_StatusArgs = {
  objects: Array<Vpc_Status_Insert_Input>;
  on_conflict?: InputMaybe<Vpc_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vpc_Status_OneArgs = {
  object: Vpc_Status_Insert_Input;
  on_conflict?: InputMaybe<Vpc_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Zendesk_Support_CategoryArgs = {
  objects: Array<Zendesk_Support_Category_Insert_Input>;
  on_conflict?: InputMaybe<Zendesk_Support_Category_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Zendesk_Support_Category_OneArgs = {
  object: Zendesk_Support_Category_Insert_Input;
  on_conflict?: InputMaybe<Zendesk_Support_Category_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Zendesk_Support_TicketsArgs = {
  objects: Array<Zendesk_Support_Tickets_Insert_Input>;
  on_conflict?: InputMaybe<Zendesk_Support_Tickets_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Zendesk_Support_Tickets_OneArgs = {
  object: Zendesk_Support_Tickets_Insert_Input;
  on_conflict?: InputMaybe<Zendesk_Support_Tickets_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInviteBillingManagerArgs = {
  manager_email: Scalars['String'];
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootInviteCollaboratorArgs = {
  invite: InviteColloboratorRequest;
};

/** mutation root */
export type Mutation_RootInviteVpcCollaboratorArgs = {
  access_level: VpcCollaboratorAccessLevel;
  invitee_email: Scalars['String'];
  vpc_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootLastMonthProjectUsageArgs = {
  args?: InputMaybe<ProjectUsageInput>;
};

/** mutation root */
export type Mutation_RootMoveProjectRegionArgs = {
  cloud?: InputMaybe<Scalars['String']>;
  projectId: Scalars['uuid'];
  region?: InputMaybe<Scalars['String']>;
  tenantGroupId?: InputMaybe<Scalars['uuid']>;
};

/** mutation root */
export type Mutation_RootNeonCreateDatabaseArgs = {
  projectId: Scalars['uuid'];
  projectName?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootNeonExchangeOAuthTokenArgs = {
  code: Scalars['String'];
  projectId: Scalars['uuid'];
  state: Scalars['String'];
};

/** mutation root */
export type Mutation_RootNewPlansProjectUsageArgs = {
  month: Scalars['Int'];
  project_id: Scalars['uuid'];
  year: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootNewPlansProjectUsagePerMonthArgs = {
  month: Scalars['Int'];
  user_id: Scalars['uuid'];
  year: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootPayInvoiceArgs = {
  invoice_id: Scalars['String'];
  payment_method_id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootReactivateSuspendedProjectArgs = {
  projectId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootRegenerateKeyArgs = {
  project: RegenerateKeyInput;
};

/** mutation root */
export type Mutation_RootRemoveGithubIntegrationArgs = {
  payload: DeleteGithubIntegrationInput;
};

/** mutation root */
export type Mutation_RootRemoveGithubSessionArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootRemoveVpcCollaboratorArgs = {
  collaborator_id: Scalars['uuid'];
  remove_collaborator_from_projects_list: Array<Scalars['uuid']>;
  vpc_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootResendBillingManagerInviteArgs = {
  manager_email: Scalars['String'];
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootResendChangeUserEmailVerificationArgs = {
  input: ChangeUserEmailRequestInput;
};

/** mutation root */
export type Mutation_RootResendInviteArgs = {
  args: ResendInvitationRequest;
};

/** mutation root */
export type Mutation_RootResendTransferOwnershipInviteArgs = {
  args: ResendTransferOwnershipInvitationRequest;
};

/** mutation root */
export type Mutation_RootRevokeChangeUserEmailRequestArgs = {
  input: ChangeUserEmailRequestInput;
};

/** mutation root */
export type Mutation_RootRevokeVpcCollaboratorInvitationArgs = {
  invitation_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootSaveSurveyAnswerArgs = {
  payload: SurveyAnswersPayload;
};

/** mutation root */
export type Mutation_RootSaveSurveyAnswerV2Args = {
  payload: SaveSurveyAnswerV2Payload;
};

/** mutation root */
export type Mutation_RootSetDefaultPaymentMethodArgs = {
  id: Scalars['String'];
};

/** mutation root */
export type Mutation_RootSlackExchangeOAuthTokenArgs = {
  code: Scalars['String'];
  projectId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootTrackExperimentsCohortActivityArgs = {
  experiment: Scalars['String'];
  payload: ExperimentsCohortActivityPayload;
};

/** mutation root */
export type Mutation_RootTrackOnboardingActivityArgs = {
  payload: OnboardingActivityPayload;
};

/** mutation root */
export type Mutation_RootTrackOnboardingSampleDbCohortActivityArgs = {
  error_message?: InputMaybe<Scalars['String']>;
  event: Scalars['String'];
  project_id: Scalars['uuid'];
  status?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootTrackYearInReportActivityArgs = {
  payload: YearInReportActivityPayload;
};

/** mutation root */
export type Mutation_RootTransferOwnershipInviteArgs = {
  input: TransferOwnershipRequest;
};

/** mutation root */
export type Mutation_RootTriggerAnalyticsInfraModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerCentralModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  cloud: CloudProvider;
  commit?: InputMaybe<Scalars['String']>;
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerCentralMonitoringModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  cloud: CloudProvider;
  commit?: InputMaybe<Scalars['String']>;
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerDataConnectorModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerDvpcInfraModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerDvpcLogsModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerDvpcMonitoringModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerDvpcOperatorModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerDvpcPeeringModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerGatewayClusterModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerHasuraClusterModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerHealthcheckModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  cloud: CloudProvider;
  commit?: InputMaybe<Scalars['String']>;
  mode: PulumiMode;
  region: Scalars['String'];
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerLogsModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  cloud: CloudProvider;
  commit?: InputMaybe<Scalars['String']>;
  mode: PulumiMode;
  region: Scalars['String'];
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerLuxProxyModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  cloud: CloudProvider;
  commit?: InputMaybe<Scalars['String']>;
  mode: PulumiMode;
  region: Scalars['String'];
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerMetricsModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  cloud: CloudProvider;
  commit?: InputMaybe<Scalars['String']>;
  mode: PulumiMode;
  region: Scalars['String'];
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerNodePoolModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerOneClickDeploymentArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootTriggerOperatorModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  cloud: CloudProvider;
  commit?: InputMaybe<Scalars['String']>;
  mode: PulumiMode;
  region: Scalars['String'];
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerRegionModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  cloud: CloudProvider;
  commit?: InputMaybe<Scalars['String']>;
  mode: PulumiMode;
  region: Scalars['String'];
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerRegionMonitoringModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  cloud: CloudProvider;
  commit?: InputMaybe<Scalars['String']>;
  mode: PulumiMode;
  region: Scalars['String'];
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerTaskqModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  cloud: CloudProvider;
  commit?: InputMaybe<Scalars['String']>;
  mode: PulumiMode;
  region: Scalars['String'];
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootTriggerTunnelClusterModuleArgs = {
  branch?: InputMaybe<Scalars['String']>;
  commit?: InputMaybe<Scalars['String']>;
  id: Scalars['uuid'];
  mode: PulumiMode;
  replace?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  targets?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** mutation root */
export type Mutation_RootUpdateAzureMonitorConfigArgs = {
  activedirectory_client_id?: InputMaybe<Scalars['String']>;
  activedirectory_client_secret?: InputMaybe<Scalars['String']>;
  activedirectory_tenant_id?: InputMaybe<Scalars['String']>;
  attributes?: InputMaybe<Scalars['jsonb']>;
  azure_region?: InputMaybe<Scalars['String']>;
  azuremonitor_workspace_id?: InputMaybe<Scalars['String']>;
  instrumentation_key?: InputMaybe<Scalars['String']>;
  log_type?: InputMaybe<Scalars['String']>;
  metric_namespace?: InputMaybe<Scalars['String']>;
  project_id: Scalars['uuid'];
  resource_id?: InputMaybe<Scalars['String']>;
  shared_key?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootUpdateBillingManagerArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootUpdateDatadogConfigArgs = {
  datadog_region?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  project_id: Scalars['uuid'];
  service_name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Scalars['String']>;
};

/** mutation root */
export type Mutation_RootUpdateGithubIntegrationArgs = {
  payload: UpdateGithubIntegrationInput;
};

/** mutation root */
export type Mutation_RootUpdateNewrelicConfigArgs = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  key?: InputMaybe<Scalars['String']>;
  newrelic_region?: InputMaybe<Scalars['String']>;
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootUpdateOpentelemetryConfigArgs = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  batch_size?: InputMaybe<Scalars['Int']>;
  connection_type?: InputMaybe<Scalars['String']>;
  endpoint?: InputMaybe<Scalars['String']>;
  export_traces?: InputMaybe<Scalars['Boolean']>;
  headers?: InputMaybe<Scalars['jsonb']>;
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootUpdateProjectsActiveStatusReasonArgs = {
  active_status_reason?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  project_id?: InputMaybe<Array<InputMaybe<Scalars['uuid']>>>;
};

/** mutation root */
export type Mutation_RootUpdatePrometheusAccessTokenArgs = {
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootUpdatePrometheusConfigArgs = {
  labels?: InputMaybe<Scalars['jsonb']>;
  namespace?: InputMaybe<Scalars['String']>;
  project_id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootUpdateServerEndpointArgs = {
  project: UpdateServerEndpointInput;
};

/** mutation root */
export type Mutation_RootUpdateTenantEnvArgs = {
  currentHash: Scalars['String'];
  envs: Array<UpdateEnvObject>;
  tenantId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootUpdateTenantNameArgs = {
  name: Scalars['String'];
  tenantId: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootUpdateTenantStatusArgs = {
  input: UpdateTenantStatusInputPayload;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_ConfigArgs = {
  _set?: InputMaybe<Alert_Config_Set_Input>;
  where: Alert_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Config_Alert_TypeArgs = {
  _set?: InputMaybe<Alert_Config_Alert_Type_Set_Input>;
  where: Alert_Config_Alert_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Config_Alert_Type_By_PkArgs = {
  _set?: InputMaybe<Alert_Config_Alert_Type_Set_Input>;
  pk_columns: Alert_Config_Alert_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Config_Alert_Type_ManyArgs = {
  updates: Array<Alert_Config_Alert_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Config_By_PkArgs = {
  _set?: InputMaybe<Alert_Config_Set_Input>;
  pk_columns: Alert_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Config_ManyArgs = {
  updates: Array<Alert_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Config_ServiceArgs = {
  _append?: InputMaybe<Alert_Config_Service_Append_Input>;
  _delete_at_path?: InputMaybe<Alert_Config_Service_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Alert_Config_Service_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Alert_Config_Service_Delete_Key_Input>;
  _prepend?: InputMaybe<Alert_Config_Service_Prepend_Input>;
  _set?: InputMaybe<Alert_Config_Service_Set_Input>;
  where: Alert_Config_Service_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Config_Service_By_PkArgs = {
  _append?: InputMaybe<Alert_Config_Service_Append_Input>;
  _delete_at_path?: InputMaybe<Alert_Config_Service_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Alert_Config_Service_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Alert_Config_Service_Delete_Key_Input>;
  _prepend?: InputMaybe<Alert_Config_Service_Prepend_Input>;
  _set?: InputMaybe<Alert_Config_Service_Set_Input>;
  pk_columns: Alert_Config_Service_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Config_Service_ManyArgs = {
  updates: Array<Alert_Config_Service_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Service_TypeArgs = {
  _set?: InputMaybe<Alert_Service_Type_Set_Input>;
  where: Alert_Service_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Service_Type_By_PkArgs = {
  _set?: InputMaybe<Alert_Service_Type_Set_Input>;
  pk_columns: Alert_Service_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Service_Type_ManyArgs = {
  updates: Array<Alert_Service_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_TypeArgs = {
  _set?: InputMaybe<Alert_Type_Set_Input>;
  where: Alert_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Type_By_PkArgs = {
  _set?: InputMaybe<Alert_Type_Set_Input>;
  pk_columns: Alert_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Alert_Type_ManyArgs = {
  updates: Array<Alert_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Azuremonitor_ConfigArgs = {
  _append?: InputMaybe<Azuremonitor_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Azuremonitor_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Azuremonitor_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Azuremonitor_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Azuremonitor_Config_Inc_Input>;
  _prepend?: InputMaybe<Azuremonitor_Config_Prepend_Input>;
  _set?: InputMaybe<Azuremonitor_Config_Set_Input>;
  where: Azuremonitor_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Azuremonitor_Config_By_PkArgs = {
  _append?: InputMaybe<Azuremonitor_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Azuremonitor_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Azuremonitor_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Azuremonitor_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Azuremonitor_Config_Inc_Input>;
  _prepend?: InputMaybe<Azuremonitor_Config_Prepend_Input>;
  _set?: InputMaybe<Azuremonitor_Config_Set_Input>;
  pk_columns: Azuremonitor_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Azuremonitor_Config_ManyArgs = {
  updates: Array<Azuremonitor_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Billing_AddressArgs = {
  _set?: InputMaybe<Billing_Address_Set_Input>;
  where: Billing_Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Billing_Address_By_PkArgs = {
  _set?: InputMaybe<Billing_Address_Set_Input>;
  pk_columns: Billing_Address_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Billing_Address_ManyArgs = {
  updates: Array<Billing_Address_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Central_CloudArgs = {
  _append?: InputMaybe<Central_Cloud_Append_Input>;
  _delete_at_path?: InputMaybe<Central_Cloud_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Central_Cloud_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Central_Cloud_Delete_Key_Input>;
  _prepend?: InputMaybe<Central_Cloud_Prepend_Input>;
  _set?: InputMaybe<Central_Cloud_Set_Input>;
  where: Central_Cloud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Central_Cloud_By_PkArgs = {
  _append?: InputMaybe<Central_Cloud_Append_Input>;
  _delete_at_path?: InputMaybe<Central_Cloud_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Central_Cloud_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Central_Cloud_Delete_Key_Input>;
  _prepend?: InputMaybe<Central_Cloud_Prepend_Input>;
  _set?: InputMaybe<Central_Cloud_Set_Input>;
  pk_columns: Central_Cloud_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Central_Cloud_ManyArgs = {
  updates: Array<Central_Cloud_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Change_Email_RequestArgs = {
  _inc?: InputMaybe<Change_Email_Request_Inc_Input>;
  _set?: InputMaybe<Change_Email_Request_Set_Input>;
  where: Change_Email_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Change_Email_Request_By_PkArgs = {
  _inc?: InputMaybe<Change_Email_Request_Inc_Input>;
  _set?: InputMaybe<Change_Email_Request_Set_Input>;
  pk_columns: Change_Email_Request_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Change_Email_Request_ManyArgs = {
  updates: Array<Change_Email_Request_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Cicd_Deployment_Pipeline_ConfigArgs = {
  _set?: InputMaybe<Cicd_Deployment_Pipeline_Config_Set_Input>;
  where: Cicd_Deployment_Pipeline_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Cicd_Deployment_Pipeline_Config_By_PkArgs = {
  _set?: InputMaybe<Cicd_Deployment_Pipeline_Config_Set_Input>;
  pk_columns: Cicd_Deployment_Pipeline_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Cicd_Deployment_Pipeline_Config_ManyArgs = {
  updates: Array<Cicd_Deployment_Pipeline_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Cicd_Deployment_PoolArgs = {
  _set?: InputMaybe<Cicd_Deployment_Pool_Set_Input>;
  where: Cicd_Deployment_Pool_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Cicd_Deployment_Pool_ManyArgs = {
  updates: Array<Cicd_Deployment_Pool_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Cicd_Deployment_Pool_MemberArgs = {
  _set?: InputMaybe<Cicd_Deployment_Pool_Member_Set_Input>;
  where: Cicd_Deployment_Pool_Member_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Cicd_Deployment_Pool_Member_ManyArgs = {
  updates: Array<Cicd_Deployment_Pool_Member_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CloudArgs = {
  _set?: InputMaybe<Cloud_Set_Input>;
  where: Cloud_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Cloud_By_PkArgs = {
  _set?: InputMaybe<Cloud_Set_Input>;
  pk_columns: Cloud_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Cloud_ManyArgs = {
  updates: Array<Cloud_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Cloud_MetadataArgs = {
  _append?: InputMaybe<Cloud_Metadata_Append_Input>;
  _delete_at_path?: InputMaybe<Cloud_Metadata_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cloud_Metadata_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cloud_Metadata_Delete_Key_Input>;
  _prepend?: InputMaybe<Cloud_Metadata_Prepend_Input>;
  _set?: InputMaybe<Cloud_Metadata_Set_Input>;
  where: Cloud_Metadata_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Cloud_Metadata_By_PkArgs = {
  _append?: InputMaybe<Cloud_Metadata_Append_Input>;
  _delete_at_path?: InputMaybe<Cloud_Metadata_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Cloud_Metadata_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Cloud_Metadata_Delete_Key_Input>;
  _prepend?: InputMaybe<Cloud_Metadata_Prepend_Input>;
  _set?: InputMaybe<Cloud_Metadata_Set_Input>;
  pk_columns: Cloud_Metadata_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Cloud_Metadata_ManyArgs = {
  updates: Array<Cloud_Metadata_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Compute_Unit_ConfigArgs = {
  _append?: InputMaybe<Compute_Unit_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Compute_Unit_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Compute_Unit_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Compute_Unit_Config_Delete_Key_Input>;
  _prepend?: InputMaybe<Compute_Unit_Config_Prepend_Input>;
  _set?: InputMaybe<Compute_Unit_Config_Set_Input>;
  where: Compute_Unit_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Compute_Unit_Config_By_PkArgs = {
  _append?: InputMaybe<Compute_Unit_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Compute_Unit_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Compute_Unit_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Compute_Unit_Config_Delete_Key_Input>;
  _prepend?: InputMaybe<Compute_Unit_Config_Prepend_Input>;
  _set?: InputMaybe<Compute_Unit_Config_Set_Input>;
  pk_columns: Compute_Unit_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Compute_Unit_Config_ManyArgs = {
  updates: Array<Compute_Unit_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ConfigArgs = {
  _set?: InputMaybe<Config_Set_Input>;
  where: Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Config_By_PkArgs = {
  _set?: InputMaybe<Config_Set_Input>;
  pk_columns: Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Config_ManyArgs = {
  updates: Array<Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Config_StatusArgs = {
  _set?: InputMaybe<Config_Status_Set_Input>;
  where: Config_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Config_Status_By_PkArgs = {
  _set?: InputMaybe<Config_Status_Set_Input>;
  pk_columns: Config_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Config_Status_ManyArgs = {
  updates: Array<Config_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Connector_ConfigArgs = {
  _set?: InputMaybe<Connector_Config_Set_Input>;
  where: Connector_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Connector_Config_By_PkArgs = {
  _set?: InputMaybe<Connector_Config_Set_Input>;
  pk_columns: Connector_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Connector_Config_ManyArgs = {
  updates: Array<Connector_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Connector_DeploymentsArgs = {
  _set?: InputMaybe<Connector_Deployments_Set_Input>;
  where: Connector_Deployments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Connector_Deployments_By_PkArgs = {
  _set?: InputMaybe<Connector_Deployments_Set_Input>;
  pk_columns: Connector_Deployments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Connector_Deployments_ManyArgs = {
  updates: Array<Connector_Deployments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CouponArgs = {
  _append?: InputMaybe<Coupon_Append_Input>;
  _delete_at_path?: InputMaybe<Coupon_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Coupon_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Coupon_Delete_Key_Input>;
  _inc?: InputMaybe<Coupon_Inc_Input>;
  _prepend?: InputMaybe<Coupon_Prepend_Input>;
  _set?: InputMaybe<Coupon_Set_Input>;
  where: Coupon_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Coupon_By_PkArgs = {
  _append?: InputMaybe<Coupon_Append_Input>;
  _delete_at_path?: InputMaybe<Coupon_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Coupon_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Coupon_Delete_Key_Input>;
  _inc?: InputMaybe<Coupon_Inc_Input>;
  _prepend?: InputMaybe<Coupon_Prepend_Input>;
  _set?: InputMaybe<Coupon_Set_Input>;
  pk_columns: Coupon_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Coupon_DurationArgs = {
  _set?: InputMaybe<Coupon_Duration_Set_Input>;
  where: Coupon_Duration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Coupon_Duration_By_PkArgs = {
  _set?: InputMaybe<Coupon_Duration_Set_Input>;
  pk_columns: Coupon_Duration_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Coupon_Duration_ManyArgs = {
  updates: Array<Coupon_Duration_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Coupon_ManyArgs = {
  updates: Array<Coupon_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Coupon_TypeArgs = {
  _set?: InputMaybe<Coupon_Type_Set_Input>;
  where: Coupon_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Coupon_Type_By_PkArgs = {
  _set?: InputMaybe<Coupon_Type_Set_Input>;
  pk_columns: Coupon_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Coupon_Type_ManyArgs = {
  updates: Array<Coupon_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Custom_DomainArgs = {
  _set?: InputMaybe<Custom_Domain_Set_Input>;
  where: Custom_Domain_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Custom_Domain_By_PkArgs = {
  _set?: InputMaybe<Custom_Domain_Set_Input>;
  pk_columns: Custom_Domain_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Custom_Domain_CloudflareArgs = {
  _append?: InputMaybe<Custom_Domain_Cloudflare_Append_Input>;
  _delete_at_path?: InputMaybe<Custom_Domain_Cloudflare_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Custom_Domain_Cloudflare_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Custom_Domain_Cloudflare_Delete_Key_Input>;
  _prepend?: InputMaybe<Custom_Domain_Cloudflare_Prepend_Input>;
  _set?: InputMaybe<Custom_Domain_Cloudflare_Set_Input>;
  where: Custom_Domain_Cloudflare_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Custom_Domain_Cloudflare_By_PkArgs = {
  _append?: InputMaybe<Custom_Domain_Cloudflare_Append_Input>;
  _delete_at_path?: InputMaybe<Custom_Domain_Cloudflare_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Custom_Domain_Cloudflare_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Custom_Domain_Cloudflare_Delete_Key_Input>;
  _prepend?: InputMaybe<Custom_Domain_Cloudflare_Prepend_Input>;
  _set?: InputMaybe<Custom_Domain_Cloudflare_Set_Input>;
  pk_columns: Custom_Domain_Cloudflare_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Custom_Domain_Cloudflare_DnsArgs = {
  _set?: InputMaybe<Custom_Domain_Cloudflare_Dns_Set_Input>;
  where: Custom_Domain_Cloudflare_Dns_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Custom_Domain_Cloudflare_Dns_By_PkArgs = {
  _set?: InputMaybe<Custom_Domain_Cloudflare_Dns_Set_Input>;
  pk_columns: Custom_Domain_Cloudflare_Dns_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Custom_Domain_Cloudflare_Dns_ManyArgs = {
  updates: Array<Custom_Domain_Cloudflare_Dns_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Custom_Domain_Cloudflare_ManyArgs = {
  updates: Array<Custom_Domain_Cloudflare_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Custom_Domain_ManyArgs = {
  updates: Array<Custom_Domain_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Customer_UsageArgs = {
  _inc?: InputMaybe<Customer_Usage_Inc_Input>;
  _set?: InputMaybe<Customer_Usage_Set_Input>;
  where: Customer_Usage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Customer_Usage_By_PkArgs = {
  _inc?: InputMaybe<Customer_Usage_Inc_Input>;
  _set?: InputMaybe<Customer_Usage_Set_Input>;
  pk_columns: Customer_Usage_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Customer_Usage_ManyArgs = {
  updates: Array<Customer_Usage_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Data_Connector_TypeArgs = {
  _set?: InputMaybe<Data_Connector_Type_Set_Input>;
  where: Data_Connector_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Data_Connector_Type_By_PkArgs = {
  _set?: InputMaybe<Data_Connector_Type_Set_Input>;
  pk_columns: Data_Connector_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Data_Connector_Type_ManyArgs = {
  updates: Array<Data_Connector_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Datadog_ConfigArgs = {
  _inc?: InputMaybe<Datadog_Config_Inc_Input>;
  _set?: InputMaybe<Datadog_Config_Set_Input>;
  where: Datadog_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Datadog_Config_By_PkArgs = {
  _inc?: InputMaybe<Datadog_Config_Inc_Input>;
  _set?: InputMaybe<Datadog_Config_Set_Input>;
  pk_columns: Datadog_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Datadog_Config_ManyArgs = {
  updates: Array<Datadog_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Db_LatencyArgs = {
  _inc?: InputMaybe<Db_Latency_Inc_Input>;
  _set?: InputMaybe<Db_Latency_Set_Input>;
  where: Db_Latency_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Db_Latency_By_PkArgs = {
  _inc?: InputMaybe<Db_Latency_Inc_Input>;
  _set?: InputMaybe<Db_Latency_Set_Input>;
  pk_columns: Db_Latency_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Db_Latency_ManyArgs = {
  updates: Array<Db_Latency_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Applied_Build_ChangelogArgs = {
  _set?: InputMaybe<Ddn_Applied_Build_Changelog_Set_Input>;
  where: Ddn_Applied_Build_Changelog_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Applied_Build_Changelog_By_PkArgs = {
  _set?: InputMaybe<Ddn_Applied_Build_Changelog_Set_Input>;
  pk_columns: Ddn_Applied_Build_Changelog_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Applied_Build_Changelog_ManyArgs = {
  updates: Array<Ddn_Applied_Build_Changelog_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Applied_Subgraph_Build_ChangelogArgs = {
  _set?: InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_Set_Input>;
  where: Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Applied_Subgraph_Build_Changelog_By_PkArgs = {
  _set?: InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_Set_Input>;
  pk_columns: Ddn_Applied_Subgraph_Build_Changelog_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Applied_Subgraph_Build_Changelog_ManyArgs = {
  updates: Array<Ddn_Applied_Subgraph_Build_Changelog_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Auto_Approve_Project_RequestArgs = {
  _set?: InputMaybe<Ddn_Auto_Approve_Project_Request_Set_Input>;
  where: Ddn_Auto_Approve_Project_Request_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Auto_Approve_Project_Request_By_PkArgs = {
  _set?: InputMaybe<Ddn_Auto_Approve_Project_Request_Set_Input>;
  pk_columns: Ddn_Auto_Approve_Project_Request_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Auto_Approve_Project_Request_ManyArgs = {
  updates: Array<Ddn_Auto_Approve_Project_Request_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_BuildArgs = {
  _append?: InputMaybe<Ddn_Build_Append_Input>;
  _delete_at_path?: InputMaybe<Ddn_Build_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ddn_Build_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ddn_Build_Delete_Key_Input>;
  _prepend?: InputMaybe<Ddn_Build_Prepend_Input>;
  _set?: InputMaybe<Ddn_Build_Set_Input>;
  where: Ddn_Build_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_By_PkArgs = {
  _append?: InputMaybe<Ddn_Build_Append_Input>;
  _delete_at_path?: InputMaybe<Ddn_Build_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ddn_Build_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ddn_Build_Delete_Key_Input>;
  _prepend?: InputMaybe<Ddn_Build_Prepend_Input>;
  _set?: InputMaybe<Ddn_Build_Set_Input>;
  pk_columns: Ddn_Build_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_DetailsArgs = {
  _append?: InputMaybe<Ddn_Build_Details_Append_Input>;
  _delete_at_path?: InputMaybe<Ddn_Build_Details_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ddn_Build_Details_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ddn_Build_Details_Delete_Key_Input>;
  _inc?: InputMaybe<Ddn_Build_Details_Inc_Input>;
  _prepend?: InputMaybe<Ddn_Build_Details_Prepend_Input>;
  _set?: InputMaybe<Ddn_Build_Details_Set_Input>;
  where: Ddn_Build_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Details_By_PkArgs = {
  _append?: InputMaybe<Ddn_Build_Details_Append_Input>;
  _delete_at_path?: InputMaybe<Ddn_Build_Details_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ddn_Build_Details_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ddn_Build_Details_Delete_Key_Input>;
  _inc?: InputMaybe<Ddn_Build_Details_Inc_Input>;
  _prepend?: InputMaybe<Ddn_Build_Details_Prepend_Input>;
  _set?: InputMaybe<Ddn_Build_Details_Set_Input>;
  pk_columns: Ddn_Build_Details_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Details_ManyArgs = {
  updates: Array<Ddn_Build_Details_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_LockArgs = {
  _set?: InputMaybe<Ddn_Build_Lock_Set_Input>;
  where: Ddn_Build_Lock_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Lock_By_PkArgs = {
  _set?: InputMaybe<Ddn_Build_Lock_Set_Input>;
  pk_columns: Ddn_Build_Lock_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Lock_ManyArgs = {
  updates: Array<Ddn_Build_Lock_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_ManyArgs = {
  updates: Array<Ddn_Build_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_RevisionArgs = {
  _set?: InputMaybe<Ddn_Build_Revision_Set_Input>;
  where: Ddn_Build_Revision_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Revision_By_PkArgs = {
  _set?: InputMaybe<Ddn_Build_Revision_Set_Input>;
  pk_columns: Ddn_Build_Revision_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Revision_ManyArgs = {
  updates: Array<Ddn_Build_Revision_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Revision_StatusArgs = {
  _set?: InputMaybe<Ddn_Build_Revision_Status_Set_Input>;
  where: Ddn_Build_Revision_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Revision_Status_By_PkArgs = {
  _set?: InputMaybe<Ddn_Build_Revision_Status_Set_Input>;
  pk_columns: Ddn_Build_Revision_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Revision_Status_ManyArgs = {
  updates: Array<Ddn_Build_Revision_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Sync_StatusArgs = {
  _set?: InputMaybe<Ddn_Build_Sync_Status_Set_Input>;
  where: Ddn_Build_Sync_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Sync_Status_By_PkArgs = {
  _set?: InputMaybe<Ddn_Build_Sync_Status_Set_Input>;
  pk_columns: Ddn_Build_Sync_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Sync_Status_ManyArgs = {
  updates: Array<Ddn_Build_Sync_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Sync_WorkerArgs = {
  _set?: InputMaybe<Ddn_Build_Sync_Worker_Set_Input>;
  where: Ddn_Build_Sync_Worker_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Sync_Worker_By_PkArgs = {
  _set?: InputMaybe<Ddn_Build_Sync_Worker_Set_Input>;
  pk_columns: Ddn_Build_Sync_Worker_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Build_Sync_Worker_ManyArgs = {
  updates: Array<Ddn_Build_Sync_Worker_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Collaboration_Invitee_Project_ViewArgs = {
  _set?: InputMaybe<Ddn_Collaboration_Invitee_Project_View_Set_Input>;
  where: Ddn_Collaboration_Invitee_Project_View_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Collaboration_Invitee_Project_View_ManyArgs = {
  updates: Array<Ddn_Collaboration_Invitee_Project_View_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Connector_DeploymentArgs = {
  _inc?: InputMaybe<Ddn_Connector_Deployment_Inc_Input>;
  _set?: InputMaybe<Ddn_Connector_Deployment_Set_Input>;
  where: Ddn_Connector_Deployment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Connector_Deployment_By_PkArgs = {
  _inc?: InputMaybe<Ddn_Connector_Deployment_Inc_Input>;
  _set?: InputMaybe<Ddn_Connector_Deployment_Set_Input>;
  pk_columns: Ddn_Connector_Deployment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Connector_Deployment_ManyArgs = {
  updates: Array<Ddn_Connector_Deployment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_DdnArgs = {
  _set?: InputMaybe<Ddn_Ddn_Set_Input>;
  where: Ddn_Ddn_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Ddn_By_PkArgs = {
  _set?: InputMaybe<Ddn_Ddn_Set_Input>;
  pk_columns: Ddn_Ddn_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Ddn_ManyArgs = {
  updates: Array<Ddn_Ddn_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_EnvironmentArgs = {
  _set?: InputMaybe<Ddn_Environment_Set_Input>;
  where: Ddn_Environment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Environment_By_PkArgs = {
  _set?: InputMaybe<Ddn_Environment_Set_Input>;
  pk_columns: Ddn_Environment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Environment_ManyArgs = {
  updates: Array<Ddn_Environment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Generate_Metadata_Artifacts_LogArgs = {
  _set?: InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_Set_Input>;
  where: Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Generate_Metadata_Artifacts_Log_By_PkArgs = {
  _set?: InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_Set_Input>;
  pk_columns: Ddn_Generate_Metadata_Artifacts_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Generate_Metadata_Artifacts_Log_ManyArgs = {
  updates: Array<Ddn_Generate_Metadata_Artifacts_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_InvoiceArgs = {
  _inc?: InputMaybe<Ddn_Invoice_Inc_Input>;
  _set?: InputMaybe<Ddn_Invoice_Set_Input>;
  where: Ddn_Invoice_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Invoice_By_PkArgs = {
  _inc?: InputMaybe<Ddn_Invoice_Inc_Input>;
  _set?: InputMaybe<Ddn_Invoice_Set_Input>;
  pk_columns: Ddn_Invoice_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Invoice_ItemArgs = {
  _inc?: InputMaybe<Ddn_Invoice_Item_Inc_Input>;
  _set?: InputMaybe<Ddn_Invoice_Item_Set_Input>;
  where: Ddn_Invoice_Item_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Invoice_Item_By_PkArgs = {
  _inc?: InputMaybe<Ddn_Invoice_Item_Inc_Input>;
  _set?: InputMaybe<Ddn_Invoice_Item_Set_Input>;
  pk_columns: Ddn_Invoice_Item_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Invoice_Item_ManyArgs = {
  updates: Array<Ddn_Invoice_Item_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Invoice_ManyArgs = {
  updates: Array<Ddn_Invoice_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Plan_Entitlement_AccessArgs = {
  _set?: InputMaybe<Ddn_Plan_Entitlement_Access_Set_Input>;
  where: Ddn_Plan_Entitlement_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Plan_Entitlement_Access_By_PkArgs = {
  _set?: InputMaybe<Ddn_Plan_Entitlement_Access_Set_Input>;
  pk_columns: Ddn_Plan_Entitlement_Access_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Plan_Entitlement_Access_ManyArgs = {
  updates: Array<Ddn_Plan_Entitlement_Access_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_PlansArgs = {
  _set?: InputMaybe<Ddn_Plans_Set_Input>;
  where: Ddn_Plans_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Plans_By_PkArgs = {
  _set?: InputMaybe<Ddn_Plans_Set_Input>;
  pk_columns: Ddn_Plans_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Plans_ManyArgs = {
  updates: Array<Ddn_Plans_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Private_DdnArgs = {
  _append?: InputMaybe<Ddn_Private_Ddn_Append_Input>;
  _delete_at_path?: InputMaybe<Ddn_Private_Ddn_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ddn_Private_Ddn_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ddn_Private_Ddn_Delete_Key_Input>;
  _prepend?: InputMaybe<Ddn_Private_Ddn_Prepend_Input>;
  _set?: InputMaybe<Ddn_Private_Ddn_Set_Input>;
  where: Ddn_Private_Ddn_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Private_Ddn_By_PkArgs = {
  _append?: InputMaybe<Ddn_Private_Ddn_Append_Input>;
  _delete_at_path?: InputMaybe<Ddn_Private_Ddn_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ddn_Private_Ddn_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ddn_Private_Ddn_Delete_Key_Input>;
  _prepend?: InputMaybe<Ddn_Private_Ddn_Prepend_Input>;
  _set?: InputMaybe<Ddn_Private_Ddn_Set_Input>;
  pk_columns: Ddn_Private_Ddn_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Private_Ddn_ManyArgs = {
  updates: Array<Ddn_Private_Ddn_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Private_Ddn_RegionArgs = {
  _append?: InputMaybe<Ddn_Private_Ddn_Region_Append_Input>;
  _delete_at_path?: InputMaybe<Ddn_Private_Ddn_Region_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ddn_Private_Ddn_Region_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ddn_Private_Ddn_Region_Delete_Key_Input>;
  _prepend?: InputMaybe<Ddn_Private_Ddn_Region_Prepend_Input>;
  _set?: InputMaybe<Ddn_Private_Ddn_Region_Set_Input>;
  where: Ddn_Private_Ddn_Region_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Private_Ddn_Region_By_PkArgs = {
  _append?: InputMaybe<Ddn_Private_Ddn_Region_Append_Input>;
  _delete_at_path?: InputMaybe<Ddn_Private_Ddn_Region_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ddn_Private_Ddn_Region_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ddn_Private_Ddn_Region_Delete_Key_Input>;
  _prepend?: InputMaybe<Ddn_Private_Ddn_Region_Prepend_Input>;
  _set?: InputMaybe<Ddn_Private_Ddn_Region_Set_Input>;
  pk_columns: Ddn_Private_Ddn_Region_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Private_Ddn_Region_ManyArgs = {
  updates: Array<Ddn_Private_Ddn_Region_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Access_LevelArgs = {
  _set?: InputMaybe<Ddn_Project_Access_Level_Set_Input>;
  where: Ddn_Project_Access_Level_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Access_Level_By_PkArgs = {
  _set?: InputMaybe<Ddn_Project_Access_Level_Set_Input>;
  pk_columns: Ddn_Project_Access_Level_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Access_Level_ManyArgs = {
  updates: Array<Ddn_Project_Access_Level_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Active_Status_ChangelogArgs = {
  _set?: InputMaybe<Ddn_Project_Active_Status_Changelog_Set_Input>;
  where: Ddn_Project_Active_Status_Changelog_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Active_Status_Changelog_By_PkArgs = {
  _set?: InputMaybe<Ddn_Project_Active_Status_Changelog_Set_Input>;
  pk_columns: Ddn_Project_Active_Status_Changelog_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Active_Status_Changelog_ManyArgs = {
  updates: Array<Ddn_Project_Active_Status_Changelog_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Active_Status_ReasonArgs = {
  _set?: InputMaybe<Ddn_Project_Active_Status_Reason_Set_Input>;
  where: Ddn_Project_Active_Status_Reason_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Active_Status_Reason_By_PkArgs = {
  _set?: InputMaybe<Ddn_Project_Active_Status_Reason_Set_Input>;
  pk_columns: Ddn_Project_Active_Status_Reason_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Active_Status_Reason_ManyArgs = {
  updates: Array<Ddn_Project_Active_Status_Reason_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Entitlement_AccessArgs = {
  _set?: InputMaybe<Ddn_Project_Entitlement_Access_Set_Input>;
  where: Ddn_Project_Entitlement_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Entitlement_Access_By_PkArgs = {
  _set?: InputMaybe<Ddn_Project_Entitlement_Access_Set_Input>;
  pk_columns: Ddn_Project_Entitlement_Access_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Entitlement_Access_ManyArgs = {
  updates: Array<Ddn_Project_Entitlement_Access_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Entitlement_CatalogueArgs = {
  _inc?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Inc_Input>;
  _set?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Set_Input>;
  where: Ddn_Project_Entitlement_Catalogue_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Entitlement_Catalogue_By_PkArgs = {
  _inc?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Inc_Input>;
  _set?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Set_Input>;
  pk_columns: Ddn_Project_Entitlement_Catalogue_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Entitlement_Catalogue_ManyArgs = {
  updates: Array<Ddn_Project_Entitlement_Catalogue_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Entitlement_TypesArgs = {
  _set?: InputMaybe<Ddn_Project_Entitlement_Types_Set_Input>;
  where: Ddn_Project_Entitlement_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Entitlement_Types_By_PkArgs = {
  _set?: InputMaybe<Ddn_Project_Entitlement_Types_Set_Input>;
  pk_columns: Ddn_Project_Entitlement_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Entitlement_Types_ManyArgs = {
  updates: Array<Ddn_Project_Entitlement_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Plan_ChangelogArgs = {
  _set?: InputMaybe<Ddn_Project_Plan_Changelog_Set_Input>;
  where: Ddn_Project_Plan_Changelog_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Plan_Changelog_By_PkArgs = {
  _set?: InputMaybe<Ddn_Project_Plan_Changelog_Set_Input>;
  pk_columns: Ddn_Project_Plan_Changelog_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Plan_Changelog_ManyArgs = {
  updates: Array<Ddn_Project_Plan_Changelog_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Request_AccessArgs = {
  _set?: InputMaybe<Ddn_Project_Request_Access_Set_Input>;
  where: Ddn_Project_Request_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Request_Access_By_PkArgs = {
  _set?: InputMaybe<Ddn_Project_Request_Access_Set_Input>;
  pk_columns: Ddn_Project_Request_Access_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Project_Request_Access_ManyArgs = {
  updates: Array<Ddn_Project_Request_Access_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_ProjectsArgs = {
  _set?: InputMaybe<Ddn_Projects_Set_Input>;
  where: Ddn_Projects_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Projects_By_PkArgs = {
  _set?: InputMaybe<Ddn_Projects_Set_Input>;
  pk_columns: Ddn_Projects_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Projects_ManyArgs = {
  updates: Array<Ddn_Projects_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Secret_KeyArgs = {
  _set?: InputMaybe<Ddn_Secret_Key_Set_Input>;
  where: Ddn_Secret_Key_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Secret_Key_By_PkArgs = {
  _set?: InputMaybe<Ddn_Secret_Key_Set_Input>;
  pk_columns: Ddn_Secret_Key_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Secret_Key_ManyArgs = {
  updates: Array<Ddn_Secret_Key_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Stripe_SubscriptionArgs = {
  _set?: InputMaybe<Ddn_Stripe_Subscription_Set_Input>;
  where: Ddn_Stripe_Subscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Stripe_Subscription_By_PkArgs = {
  _set?: InputMaybe<Ddn_Stripe_Subscription_Set_Input>;
  pk_columns: Ddn_Stripe_Subscription_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Stripe_Subscription_ManyArgs = {
  updates: Array<Ddn_Stripe_Subscription_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Stripe_Webhook_EventsArgs = {
  _append?: InputMaybe<Ddn_Stripe_Webhook_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Ddn_Stripe_Webhook_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ddn_Stripe_Webhook_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ddn_Stripe_Webhook_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Ddn_Stripe_Webhook_Events_Prepend_Input>;
  _set?: InputMaybe<Ddn_Stripe_Webhook_Events_Set_Input>;
  where: Ddn_Stripe_Webhook_Events_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Stripe_Webhook_Events_By_PkArgs = {
  _append?: InputMaybe<Ddn_Stripe_Webhook_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Ddn_Stripe_Webhook_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ddn_Stripe_Webhook_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ddn_Stripe_Webhook_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Ddn_Stripe_Webhook_Events_Prepend_Input>;
  _set?: InputMaybe<Ddn_Stripe_Webhook_Events_Set_Input>;
  pk_columns: Ddn_Stripe_Webhook_Events_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Stripe_Webhook_Events_ManyArgs = {
  updates: Array<Ddn_Stripe_Webhook_Events_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_SubgraphArgs = {
  _set?: InputMaybe<Ddn_Subgraph_Set_Input>;
  where: Ddn_Subgraph_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Subgraph_BuildArgs = {
  _set?: InputMaybe<Ddn_Subgraph_Build_Set_Input>;
  where: Ddn_Subgraph_Build_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Subgraph_Build_By_PkArgs = {
  _set?: InputMaybe<Ddn_Subgraph_Build_Set_Input>;
  pk_columns: Ddn_Subgraph_Build_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Subgraph_Build_ManyArgs = {
  updates: Array<Ddn_Subgraph_Build_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Subgraph_By_PkArgs = {
  _set?: InputMaybe<Ddn_Subgraph_Set_Input>;
  pk_columns: Ddn_Subgraph_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Subgraph_ManyArgs = {
  updates: Array<Ddn_Subgraph_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_TeamArgs = {
  _set?: InputMaybe<Ddn_Team_Set_Input>;
  where: Ddn_Team_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_By_PkArgs = {
  _set?: InputMaybe<Ddn_Team_Set_Input>;
  pk_columns: Ddn_Team_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_InvitationArgs = {
  _inc?: InputMaybe<Ddn_Team_Invitation_Inc_Input>;
  _set?: InputMaybe<Ddn_Team_Invitation_Set_Input>;
  where: Ddn_Team_Invitation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_Invitation_By_PkArgs = {
  _inc?: InputMaybe<Ddn_Team_Invitation_Inc_Input>;
  _set?: InputMaybe<Ddn_Team_Invitation_Set_Input>;
  pk_columns: Ddn_Team_Invitation_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_Invitation_ManyArgs = {
  updates: Array<Ddn_Team_Invitation_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_Invitation_StatusArgs = {
  _set?: InputMaybe<Ddn_Team_Invitation_Status_Set_Input>;
  where: Ddn_Team_Invitation_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_Invitation_Status_By_PkArgs = {
  _set?: InputMaybe<Ddn_Team_Invitation_Status_Set_Input>;
  pk_columns: Ddn_Team_Invitation_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_Invitation_Status_ManyArgs = {
  updates: Array<Ddn_Team_Invitation_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_KindArgs = {
  _set?: InputMaybe<Ddn_Team_Kind_Set_Input>;
  where: Ddn_Team_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_Kind_By_PkArgs = {
  _set?: InputMaybe<Ddn_Team_Kind_Set_Input>;
  pk_columns: Ddn_Team_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_Kind_ManyArgs = {
  updates: Array<Ddn_Team_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_ManyArgs = {
  updates: Array<Ddn_Team_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_MembershipArgs = {
  _set?: InputMaybe<Ddn_Team_Membership_Set_Input>;
  where: Ddn_Team_Membership_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_Membership_By_PkArgs = {
  _set?: InputMaybe<Ddn_Team_Membership_Set_Input>;
  pk_columns: Ddn_Team_Membership_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_Membership_ManyArgs = {
  updates: Array<Ddn_Team_Membership_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_Project_AccessArgs = {
  _set?: InputMaybe<Ddn_Team_Project_Access_Set_Input>;
  where: Ddn_Team_Project_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_Project_Access_By_PkArgs = {
  _set?: InputMaybe<Ddn_Team_Project_Access_Set_Input>;
  pk_columns: Ddn_Team_Project_Access_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Team_Project_Access_ManyArgs = {
  updates: Array<Ddn_Team_Project_Access_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_TunnelArgs = {
  _inc?: InputMaybe<Ddn_Tunnel_Inc_Input>;
  _set?: InputMaybe<Ddn_Tunnel_Set_Input>;
  where: Ddn_Tunnel_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Tunnel_By_PkArgs = {
  _inc?: InputMaybe<Ddn_Tunnel_Inc_Input>;
  _set?: InputMaybe<Ddn_Tunnel_Set_Input>;
  pk_columns: Ddn_Tunnel_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Tunnel_ClusterArgs = {
  _set?: InputMaybe<Ddn_Tunnel_Cluster_Set_Input>;
  where: Ddn_Tunnel_Cluster_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Tunnel_Cluster_By_PkArgs = {
  _set?: InputMaybe<Ddn_Tunnel_Cluster_Set_Input>;
  pk_columns: Ddn_Tunnel_Cluster_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Tunnel_Cluster_ManyArgs = {
  updates: Array<Ddn_Tunnel_Cluster_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Tunnel_HttpArgs = {
  _set?: InputMaybe<Ddn_Tunnel_Http_Set_Input>;
  where: Ddn_Tunnel_Http_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Tunnel_Http_By_PkArgs = {
  _set?: InputMaybe<Ddn_Tunnel_Http_Set_Input>;
  pk_columns: Ddn_Tunnel_Http_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Tunnel_Http_ClusterArgs = {
  _append?: InputMaybe<Ddn_Tunnel_Http_Cluster_Append_Input>;
  _delete_at_path?: InputMaybe<Ddn_Tunnel_Http_Cluster_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ddn_Tunnel_Http_Cluster_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ddn_Tunnel_Http_Cluster_Delete_Key_Input>;
  _prepend?: InputMaybe<Ddn_Tunnel_Http_Cluster_Prepend_Input>;
  _set?: InputMaybe<Ddn_Tunnel_Http_Cluster_Set_Input>;
  where: Ddn_Tunnel_Http_Cluster_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Tunnel_Http_Cluster_By_PkArgs = {
  _append?: InputMaybe<Ddn_Tunnel_Http_Cluster_Append_Input>;
  _delete_at_path?: InputMaybe<Ddn_Tunnel_Http_Cluster_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Ddn_Tunnel_Http_Cluster_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Ddn_Tunnel_Http_Cluster_Delete_Key_Input>;
  _prepend?: InputMaybe<Ddn_Tunnel_Http_Cluster_Prepend_Input>;
  _set?: InputMaybe<Ddn_Tunnel_Http_Cluster_Set_Input>;
  pk_columns: Ddn_Tunnel_Http_Cluster_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Tunnel_Http_Cluster_ManyArgs = {
  updates: Array<Ddn_Tunnel_Http_Cluster_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Tunnel_Http_ManyArgs = {
  updates: Array<Ddn_Tunnel_Http_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ddn_Tunnel_ManyArgs = {
  updates: Array<Ddn_Tunnel_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Dedicated_Cloud_BillsArgs = {
  _append?: InputMaybe<Dedicated_Cloud_Bills_Append_Input>;
  _delete_at_path?: InputMaybe<Dedicated_Cloud_Bills_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dedicated_Cloud_Bills_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dedicated_Cloud_Bills_Delete_Key_Input>;
  _inc?: InputMaybe<Dedicated_Cloud_Bills_Inc_Input>;
  _prepend?: InputMaybe<Dedicated_Cloud_Bills_Prepend_Input>;
  _set?: InputMaybe<Dedicated_Cloud_Bills_Set_Input>;
  where: Dedicated_Cloud_Bills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Dedicated_Cloud_Bills_By_PkArgs = {
  _append?: InputMaybe<Dedicated_Cloud_Bills_Append_Input>;
  _delete_at_path?: InputMaybe<Dedicated_Cloud_Bills_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dedicated_Cloud_Bills_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dedicated_Cloud_Bills_Delete_Key_Input>;
  _inc?: InputMaybe<Dedicated_Cloud_Bills_Inc_Input>;
  _prepend?: InputMaybe<Dedicated_Cloud_Bills_Prepend_Input>;
  _set?: InputMaybe<Dedicated_Cloud_Bills_Set_Input>;
  pk_columns: Dedicated_Cloud_Bills_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Dedicated_Cloud_Bills_DetailsArgs = {
  _append?: InputMaybe<Dedicated_Cloud_Bills_Details_Append_Input>;
  _delete_at_path?: InputMaybe<
    Dedicated_Cloud_Bills_Details_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<Dedicated_Cloud_Bills_Details_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dedicated_Cloud_Bills_Details_Delete_Key_Input>;
  _inc?: InputMaybe<Dedicated_Cloud_Bills_Details_Inc_Input>;
  _prepend?: InputMaybe<Dedicated_Cloud_Bills_Details_Prepend_Input>;
  _set?: InputMaybe<Dedicated_Cloud_Bills_Details_Set_Input>;
  where: Dedicated_Cloud_Bills_Details_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Dedicated_Cloud_Bills_Details_By_PkArgs = {
  _append?: InputMaybe<Dedicated_Cloud_Bills_Details_Append_Input>;
  _delete_at_path?: InputMaybe<
    Dedicated_Cloud_Bills_Details_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<Dedicated_Cloud_Bills_Details_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dedicated_Cloud_Bills_Details_Delete_Key_Input>;
  _inc?: InputMaybe<Dedicated_Cloud_Bills_Details_Inc_Input>;
  _prepend?: InputMaybe<Dedicated_Cloud_Bills_Details_Prepend_Input>;
  _set?: InputMaybe<Dedicated_Cloud_Bills_Details_Set_Input>;
  pk_columns: Dedicated_Cloud_Bills_Details_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Dedicated_Cloud_Bills_Details_ManyArgs = {
  updates: Array<Dedicated_Cloud_Bills_Details_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Dedicated_Cloud_Bills_ManyArgs = {
  updates: Array<Dedicated_Cloud_Bills_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Dedicated_Cloud_CommitmentsArgs = {
  _inc?: InputMaybe<Dedicated_Cloud_Commitments_Inc_Input>;
  _set?: InputMaybe<Dedicated_Cloud_Commitments_Set_Input>;
  where: Dedicated_Cloud_Commitments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Dedicated_Cloud_Commitments_By_PkArgs = {
  _inc?: InputMaybe<Dedicated_Cloud_Commitments_Inc_Input>;
  _set?: InputMaybe<Dedicated_Cloud_Commitments_Set_Input>;
  pk_columns: Dedicated_Cloud_Commitments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Dedicated_Cloud_Commitments_ManyArgs = {
  updates: Array<Dedicated_Cloud_Commitments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Dedicated_VpcArgs = {
  _append?: InputMaybe<Dedicated_Vpc_Append_Input>;
  _delete_at_path?: InputMaybe<Dedicated_Vpc_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dedicated_Vpc_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dedicated_Vpc_Delete_Key_Input>;
  _inc?: InputMaybe<Dedicated_Vpc_Inc_Input>;
  _prepend?: InputMaybe<Dedicated_Vpc_Prepend_Input>;
  _set?: InputMaybe<Dedicated_Vpc_Set_Input>;
  where: Dedicated_Vpc_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Dedicated_Vpc_By_PkArgs = {
  _append?: InputMaybe<Dedicated_Vpc_Append_Input>;
  _delete_at_path?: InputMaybe<Dedicated_Vpc_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Dedicated_Vpc_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Dedicated_Vpc_Delete_Key_Input>;
  _inc?: InputMaybe<Dedicated_Vpc_Inc_Input>;
  _prepend?: InputMaybe<Dedicated_Vpc_Prepend_Input>;
  _set?: InputMaybe<Dedicated_Vpc_Set_Input>;
  pk_columns: Dedicated_Vpc_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Dedicated_Vpc_ManyArgs = {
  updates: Array<Dedicated_Vpc_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Delete_UserArgs = {
  _set?: InputMaybe<Delete_User_Set_Input>;
  where: Delete_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Delete_User_By_PkArgs = {
  _set?: InputMaybe<Delete_User_Set_Input>;
  pk_columns: Delete_User_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Delete_User_ManyArgs = {
  updates: Array<Delete_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Delete_User_StatusArgs = {
  _set?: InputMaybe<Delete_User_Status_Set_Input>;
  where: Delete_User_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Delete_User_Status_By_PkArgs = {
  _set?: InputMaybe<Delete_User_Status_Set_Input>;
  pk_columns: Delete_User_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Delete_User_Status_ManyArgs = {
  updates: Array<Delete_User_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Delete_User_TasksArgs = {
  _append?: InputMaybe<Delete_User_Tasks_Append_Input>;
  _delete_at_path?: InputMaybe<Delete_User_Tasks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Delete_User_Tasks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Delete_User_Tasks_Delete_Key_Input>;
  _inc?: InputMaybe<Delete_User_Tasks_Inc_Input>;
  _prepend?: InputMaybe<Delete_User_Tasks_Prepend_Input>;
  _set?: InputMaybe<Delete_User_Tasks_Set_Input>;
  where: Delete_User_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Delete_User_Tasks_By_PkArgs = {
  _append?: InputMaybe<Delete_User_Tasks_Append_Input>;
  _delete_at_path?: InputMaybe<Delete_User_Tasks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Delete_User_Tasks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Delete_User_Tasks_Delete_Key_Input>;
  _inc?: InputMaybe<Delete_User_Tasks_Inc_Input>;
  _prepend?: InputMaybe<Delete_User_Tasks_Prepend_Input>;
  _set?: InputMaybe<Delete_User_Tasks_Set_Input>;
  pk_columns: Delete_User_Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Delete_User_Tasks_ManyArgs = {
  updates: Array<Delete_User_Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Email_LogArgs = {
  _append?: InputMaybe<Email_Log_Append_Input>;
  _delete_at_path?: InputMaybe<Email_Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Email_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Email_Log_Delete_Key_Input>;
  _prepend?: InputMaybe<Email_Log_Prepend_Input>;
  _set?: InputMaybe<Email_Log_Set_Input>;
  where: Email_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Log_By_PkArgs = {
  _append?: InputMaybe<Email_Log_Append_Input>;
  _delete_at_path?: InputMaybe<Email_Log_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Email_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Email_Log_Delete_Key_Input>;
  _prepend?: InputMaybe<Email_Log_Prepend_Input>;
  _set?: InputMaybe<Email_Log_Set_Input>;
  pk_columns: Email_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Log_ManyArgs = {
  updates: Array<Email_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enterprise_UsersArgs = {
  _inc?: InputMaybe<Enterprise_Users_Inc_Input>;
  _set?: InputMaybe<Enterprise_Users_Set_Input>;
  where: Enterprise_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enterprise_Users_By_PkArgs = {
  _inc?: InputMaybe<Enterprise_Users_Inc_Input>;
  _set?: InputMaybe<Enterprise_Users_Set_Input>;
  pk_columns: Enterprise_Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enterprise_Users_DomainArgs = {
  _set?: InputMaybe<Enterprise_Users_Domain_Set_Input>;
  where: Enterprise_Users_Domain_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Enterprise_Users_Domain_By_PkArgs = {
  _set?: InputMaybe<Enterprise_Users_Domain_Set_Input>;
  pk_columns: Enterprise_Users_Domain_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Enterprise_Users_Domain_ManyArgs = {
  updates: Array<Enterprise_Users_Domain_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Enterprise_Users_ManyArgs = {
  updates: Array<Enterprise_Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ExperimentsArgs = {
  _set?: InputMaybe<Experiments_Set_Input>;
  where: Experiments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Experiments_By_PkArgs = {
  _set?: InputMaybe<Experiments_Set_Input>;
  pk_columns: Experiments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Experiments_CohortArgs = {
  _append?: InputMaybe<Experiments_Cohort_Append_Input>;
  _delete_at_path?: InputMaybe<Experiments_Cohort_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Experiments_Cohort_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Experiments_Cohort_Delete_Key_Input>;
  _prepend?: InputMaybe<Experiments_Cohort_Prepend_Input>;
  _set?: InputMaybe<Experiments_Cohort_Set_Input>;
  where: Experiments_Cohort_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Experiments_Cohort_By_PkArgs = {
  _append?: InputMaybe<Experiments_Cohort_Append_Input>;
  _delete_at_path?: InputMaybe<Experiments_Cohort_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Experiments_Cohort_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Experiments_Cohort_Delete_Key_Input>;
  _prepend?: InputMaybe<Experiments_Cohort_Prepend_Input>;
  _set?: InputMaybe<Experiments_Cohort_Set_Input>;
  pk_columns: Experiments_Cohort_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Experiments_Cohort_ManyArgs = {
  updates: Array<Experiments_Cohort_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Experiments_ConfigArgs = {
  _append?: InputMaybe<Experiments_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Experiments_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Experiments_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Experiments_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Experiments_Config_Inc_Input>;
  _prepend?: InputMaybe<Experiments_Config_Prepend_Input>;
  _set?: InputMaybe<Experiments_Config_Set_Input>;
  where: Experiments_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Experiments_Config_By_PkArgs = {
  _append?: InputMaybe<Experiments_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Experiments_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Experiments_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Experiments_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Experiments_Config_Inc_Input>;
  _prepend?: InputMaybe<Experiments_Config_Prepend_Input>;
  _set?: InputMaybe<Experiments_Config_Set_Input>;
  pk_columns: Experiments_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Experiments_Config_ManyArgs = {
  updates: Array<Experiments_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Experiments_ManyArgs = {
  updates: Array<Experiments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FeatureArgs = {
  _set?: InputMaybe<Feature_Set_Input>;
  where: Feature_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Feature_AccessArgs = {
  _set?: InputMaybe<Feature_Access_Set_Input>;
  where: Feature_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Feature_Access_By_PkArgs = {
  _set?: InputMaybe<Feature_Access_Set_Input>;
  pk_columns: Feature_Access_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Feature_Access_ManyArgs = {
  updates: Array<Feature_Access_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Feature_By_PkArgs = {
  _set?: InputMaybe<Feature_Set_Input>;
  pk_columns: Feature_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Feature_ConfigArgs = {
  _append?: InputMaybe<Feature_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Feature_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Feature_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Feature_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Feature_Config_Inc_Input>;
  _prepend?: InputMaybe<Feature_Config_Prepend_Input>;
  _set?: InputMaybe<Feature_Config_Set_Input>;
  where: Feature_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Feature_Config_By_PkArgs = {
  _append?: InputMaybe<Feature_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Feature_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Feature_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Feature_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Feature_Config_Inc_Input>;
  _prepend?: InputMaybe<Feature_Config_Prepend_Input>;
  _set?: InputMaybe<Feature_Config_Set_Input>;
  pk_columns: Feature_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Feature_Config_ManyArgs = {
  updates: Array<Feature_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Feature_ManyArgs = {
  updates: Array<Feature_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Gateway_AmiArgs = {
  _set?: InputMaybe<Gateway_Ami_Set_Input>;
  where: Gateway_Ami_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Gateway_Ami_By_PkArgs = {
  _set?: InputMaybe<Gateway_Ami_Set_Input>;
  pk_columns: Gateway_Ami_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Gateway_Ami_ManyArgs = {
  updates: Array<Gateway_Ami_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Gateway_ClusterArgs = {
  _append?: InputMaybe<Gateway_Cluster_Append_Input>;
  _delete_at_path?: InputMaybe<Gateway_Cluster_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Gateway_Cluster_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Gateway_Cluster_Delete_Key_Input>;
  _prepend?: InputMaybe<Gateway_Cluster_Prepend_Input>;
  _set?: InputMaybe<Gateway_Cluster_Set_Input>;
  where: Gateway_Cluster_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Gateway_Cluster_By_PkArgs = {
  _append?: InputMaybe<Gateway_Cluster_Append_Input>;
  _delete_at_path?: InputMaybe<Gateway_Cluster_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Gateway_Cluster_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Gateway_Cluster_Delete_Key_Input>;
  _prepend?: InputMaybe<Gateway_Cluster_Prepend_Input>;
  _set?: InputMaybe<Gateway_Cluster_Set_Input>;
  pk_columns: Gateway_Cluster_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Gateway_Cluster_ManyArgs = {
  updates: Array<Gateway_Cluster_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Gateway_WorkerArgs = {
  _set?: InputMaybe<Gateway_Worker_Set_Input>;
  where: Gateway_Worker_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Gateway_Worker_By_PkArgs = {
  _set?: InputMaybe<Gateway_Worker_Set_Input>;
  pk_columns: Gateway_Worker_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Gateway_Worker_ManyArgs = {
  updates: Array<Gateway_Worker_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Email_TypeArgs = {
  _set?: InputMaybe<Github_Email_Type_Set_Input>;
  where: Github_Email_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Email_Type_By_PkArgs = {
  _set?: InputMaybe<Github_Email_Type_Set_Input>;
  pk_columns: Github_Email_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Email_Type_ManyArgs = {
  updates: Array<Github_Email_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Integration_ConfigArgs = {
  _inc?: InputMaybe<Github_Integration_Config_Inc_Input>;
  _set?: InputMaybe<Github_Integration_Config_Set_Input>;
  where: Github_Integration_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Integration_Config_By_PkArgs = {
  _inc?: InputMaybe<Github_Integration_Config_Inc_Input>;
  _set?: InputMaybe<Github_Integration_Config_Set_Input>;
  pk_columns: Github_Integration_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Integration_Config_ManyArgs = {
  updates: Array<Github_Integration_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Integration_ModeArgs = {
  _set?: InputMaybe<Github_Integration_Mode_Set_Input>;
  where: Github_Integration_Mode_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Integration_Mode_By_PkArgs = {
  _set?: InputMaybe<Github_Integration_Mode_Set_Input>;
  pk_columns: Github_Integration_Mode_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Integration_Mode_ManyArgs = {
  updates: Array<Github_Integration_Mode_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Push_EventArgs = {
  _append?: InputMaybe<Github_Push_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Github_Push_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Github_Push_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Github_Push_Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Github_Push_Event_Prepend_Input>;
  _set?: InputMaybe<Github_Push_Event_Set_Input>;
  where: Github_Push_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Push_Event_By_PkArgs = {
  _append?: InputMaybe<Github_Push_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Github_Push_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Github_Push_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Github_Push_Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Github_Push_Event_Prepend_Input>;
  _set?: InputMaybe<Github_Push_Event_Set_Input>;
  pk_columns: Github_Push_Event_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Push_Event_JobArgs = {
  _set?: InputMaybe<Github_Push_Event_Job_Set_Input>;
  where: Github_Push_Event_Job_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Push_Event_Job_By_PkArgs = {
  _set?: InputMaybe<Github_Push_Event_Job_Set_Input>;
  pk_columns: Github_Push_Event_Job_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Push_Event_Job_ManyArgs = {
  updates: Array<Github_Push_Event_Job_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Github_Push_Event_ManyArgs = {
  updates: Array<Github_Push_Event_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_AmiArgs = {
  _append?: InputMaybe<Hasura_Ami_Append_Input>;
  _delete_at_path?: InputMaybe<Hasura_Ami_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hasura_Ami_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hasura_Ami_Delete_Key_Input>;
  _inc?: InputMaybe<Hasura_Ami_Inc_Input>;
  _prepend?: InputMaybe<Hasura_Ami_Prepend_Input>;
  _set?: InputMaybe<Hasura_Ami_Set_Input>;
  where: Hasura_Ami_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_Ami_By_PkArgs = {
  _append?: InputMaybe<Hasura_Ami_Append_Input>;
  _delete_at_path?: InputMaybe<Hasura_Ami_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hasura_Ami_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hasura_Ami_Delete_Key_Input>;
  _inc?: InputMaybe<Hasura_Ami_Inc_Input>;
  _prepend?: InputMaybe<Hasura_Ami_Prepend_Input>;
  _set?: InputMaybe<Hasura_Ami_Set_Input>;
  pk_columns: Hasura_Ami_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_Ami_ManyArgs = {
  updates: Array<Hasura_Ami_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_ClusterArgs = {
  _append?: InputMaybe<Hasura_Cluster_Append_Input>;
  _delete_at_path?: InputMaybe<Hasura_Cluster_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hasura_Cluster_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hasura_Cluster_Delete_Key_Input>;
  _prepend?: InputMaybe<Hasura_Cluster_Prepend_Input>;
  _set?: InputMaybe<Hasura_Cluster_Set_Input>;
  where: Hasura_Cluster_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_Cluster_By_PkArgs = {
  _append?: InputMaybe<Hasura_Cluster_Append_Input>;
  _delete_at_path?: InputMaybe<Hasura_Cluster_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Hasura_Cluster_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hasura_Cluster_Delete_Key_Input>;
  _prepend?: InputMaybe<Hasura_Cluster_Prepend_Input>;
  _set?: InputMaybe<Hasura_Cluster_Set_Input>;
  pk_columns: Hasura_Cluster_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_Cluster_ManyArgs = {
  updates: Array<Hasura_Cluster_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_WorkerArgs = {
  _set?: InputMaybe<Hasura_Worker_Set_Input>;
  where: Hasura_Worker_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_Worker_By_PkArgs = {
  _set?: InputMaybe<Hasura_Worker_Set_Input>;
  pk_columns: Hasura_Worker_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_Worker_ManyArgs = {
  updates: Array<Hasura_Worker_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_Year_In_Report_ActivityArgs = {
  _append?: InputMaybe<Hasura_Year_In_Report_Activity_Append_Input>;
  _delete_at_path?: InputMaybe<
    Hasura_Year_In_Report_Activity_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<Hasura_Year_In_Report_Activity_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hasura_Year_In_Report_Activity_Delete_Key_Input>;
  _prepend?: InputMaybe<Hasura_Year_In_Report_Activity_Prepend_Input>;
  _set?: InputMaybe<Hasura_Year_In_Report_Activity_Set_Input>;
  where: Hasura_Year_In_Report_Activity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_Year_In_Report_Activity_By_PkArgs = {
  _append?: InputMaybe<Hasura_Year_In_Report_Activity_Append_Input>;
  _delete_at_path?: InputMaybe<
    Hasura_Year_In_Report_Activity_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<Hasura_Year_In_Report_Activity_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hasura_Year_In_Report_Activity_Delete_Key_Input>;
  _prepend?: InputMaybe<Hasura_Year_In_Report_Activity_Prepend_Input>;
  _set?: InputMaybe<Hasura_Year_In_Report_Activity_Set_Input>;
  pk_columns: Hasura_Year_In_Report_Activity_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_Year_In_Report_Activity_ManyArgs = {
  updates: Array<Hasura_Year_In_Report_Activity_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_Year_In_Report_MetricsArgs = {
  _append?: InputMaybe<Hasura_Year_In_Report_Metrics_Append_Input>;
  _delete_at_path?: InputMaybe<
    Hasura_Year_In_Report_Metrics_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<Hasura_Year_In_Report_Metrics_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hasura_Year_In_Report_Metrics_Delete_Key_Input>;
  _inc?: InputMaybe<Hasura_Year_In_Report_Metrics_Inc_Input>;
  _prepend?: InputMaybe<Hasura_Year_In_Report_Metrics_Prepend_Input>;
  _set?: InputMaybe<Hasura_Year_In_Report_Metrics_Set_Input>;
  where: Hasura_Year_In_Report_Metrics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_Year_In_Report_Metrics_By_PkArgs = {
  _append?: InputMaybe<Hasura_Year_In_Report_Metrics_Append_Input>;
  _delete_at_path?: InputMaybe<
    Hasura_Year_In_Report_Metrics_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<Hasura_Year_In_Report_Metrics_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Hasura_Year_In_Report_Metrics_Delete_Key_Input>;
  _inc?: InputMaybe<Hasura_Year_In_Report_Metrics_Inc_Input>;
  _prepend?: InputMaybe<Hasura_Year_In_Report_Metrics_Prepend_Input>;
  _set?: InputMaybe<Hasura_Year_In_Report_Metrics_Set_Input>;
  pk_columns: Hasura_Year_In_Report_Metrics_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Hasura_Year_In_Report_Metrics_ManyArgs = {
  updates: Array<Hasura_Year_In_Report_Metrics_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Heroku_IntegrationsArgs = {
  _set?: InputMaybe<Heroku_Integrations_Set_Input>;
  where: Heroku_Integrations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Heroku_Integrations_By_PkArgs = {
  _set?: InputMaybe<Heroku_Integrations_Set_Input>;
  pk_columns: Heroku_Integrations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Heroku_Integrations_ManyArgs = {
  updates: Array<Heroku_Integrations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inactive_Project_ExclusionsArgs = {
  _set?: InputMaybe<Inactive_Project_Exclusions_Set_Input>;
  where: Inactive_Project_Exclusions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inactive_Project_Exclusions_By_PkArgs = {
  _set?: InputMaybe<Inactive_Project_Exclusions_Set_Input>;
  pk_columns: Inactive_Project_Exclusions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inactive_Project_Exclusions_ManyArgs = {
  updates: Array<Inactive_Project_Exclusions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inactive_Project_NotificationsArgs = {
  _inc?: InputMaybe<Inactive_Project_Notifications_Inc_Input>;
  _set?: InputMaybe<Inactive_Project_Notifications_Set_Input>;
  where: Inactive_Project_Notifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inactive_Project_Notifications_By_PkArgs = {
  _inc?: InputMaybe<Inactive_Project_Notifications_Inc_Input>;
  _set?: InputMaybe<Inactive_Project_Notifications_Set_Input>;
  pk_columns: Inactive_Project_Notifications_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inactive_Project_Notifications_ManyArgs = {
  updates: Array<Inactive_Project_Notifications_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inactive_Project_Suspension_ConfigArgs = {
  _inc?: InputMaybe<Inactive_Project_Suspension_Config_Inc_Input>;
  _set?: InputMaybe<Inactive_Project_Suspension_Config_Set_Input>;
  where: Inactive_Project_Suspension_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inactive_Project_Suspension_Config_ManyArgs = {
  updates: Array<Inactive_Project_Suspension_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Infra_StatusArgs = {
  _set?: InputMaybe<Infra_Status_Set_Input>;
  where: Infra_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Infra_Status_By_PkArgs = {
  _set?: InputMaybe<Infra_Status_Set_Input>;
  pk_columns: Infra_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Infra_Status_ManyArgs = {
  updates: Array<Infra_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_InvoiceArgs = {
  _inc?: InputMaybe<Invoice_Inc_Input>;
  _set?: InputMaybe<Invoice_Set_Input>;
  where: Invoice_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_By_PkArgs = {
  _inc?: InputMaybe<Invoice_Inc_Input>;
  _set?: InputMaybe<Invoice_Set_Input>;
  pk_columns: Invoice_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_Coupon_DiscountArgs = {
  _inc?: InputMaybe<Invoice_Coupon_Discount_Inc_Input>;
  _set?: InputMaybe<Invoice_Coupon_Discount_Set_Input>;
  where: Invoice_Coupon_Discount_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_Coupon_Discount_By_PkArgs = {
  _inc?: InputMaybe<Invoice_Coupon_Discount_Inc_Input>;
  _set?: InputMaybe<Invoice_Coupon_Discount_Set_Input>;
  pk_columns: Invoice_Coupon_Discount_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_Coupon_Discount_ManyArgs = {
  updates: Array<Invoice_Coupon_Discount_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_ItemArgs = {
  _inc?: InputMaybe<Invoice_Item_Inc_Input>;
  _set?: InputMaybe<Invoice_Item_Set_Input>;
  where: Invoice_Item_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_Item_By_PkArgs = {
  _inc?: InputMaybe<Invoice_Item_Inc_Input>;
  _set?: InputMaybe<Invoice_Item_Set_Input>;
  pk_columns: Invoice_Item_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_Item_ManyArgs = {
  updates: Array<Invoice_Item_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Invoice_ManyArgs = {
  updates: Array<Invoice_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_JobsArgs = {
  _append?: InputMaybe<Jobs_Append_Input>;
  _delete_at_path?: InputMaybe<Jobs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Jobs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Jobs_Delete_Key_Input>;
  _prepend?: InputMaybe<Jobs_Prepend_Input>;
  _set?: InputMaybe<Jobs_Set_Input>;
  where: Jobs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Jobs_By_PkArgs = {
  _append?: InputMaybe<Jobs_Append_Input>;
  _delete_at_path?: InputMaybe<Jobs_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Jobs_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Jobs_Delete_Key_Input>;
  _prepend?: InputMaybe<Jobs_Prepend_Input>;
  _set?: InputMaybe<Jobs_Set_Input>;
  pk_columns: Jobs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Jobs_ManyArgs = {
  updates: Array<Jobs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LabelArgs = {
  _set?: InputMaybe<Label_Set_Input>;
  where: Label_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Label_By_PkArgs = {
  _set?: InputMaybe<Label_Set_Input>;
  pk_columns: Label_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Label_ManyArgs = {
  updates: Array<Label_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Letsencrypt_StatusArgs = {
  _set?: InputMaybe<Letsencrypt_Status_Set_Input>;
  where: Letsencrypt_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Letsencrypt_Status_By_PkArgs = {
  _set?: InputMaybe<Letsencrypt_Status_Set_Input>;
  pk_columns: Letsencrypt_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Letsencrypt_Status_ManyArgs = {
  updates: Array<Letsencrypt_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_License_ActivityArgs = {
  _set?: InputMaybe<License_Activity_Set_Input>;
  where: License_Activity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_License_Activity_By_PkArgs = {
  _set?: InputMaybe<License_Activity_Set_Input>;
  pk_columns: License_Activity_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_License_Activity_ManyArgs = {
  updates: Array<License_Activity_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_License_InstanceArgs = {
  _set?: InputMaybe<License_Instance_Set_Input>;
  where: License_Instance_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_License_Instance_By_PkArgs = {
  _set?: InputMaybe<License_Instance_Set_Input>;
  pk_columns: License_Instance_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_License_Instance_ManyArgs = {
  updates: Array<License_Instance_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_License_TypeArgs = {
  _set?: InputMaybe<License_Type_Set_Input>;
  where: License_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_License_Type_By_PkArgs = {
  _set?: InputMaybe<License_Type_Set_Input>;
  pk_columns: License_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_License_Type_ManyArgs = {
  updates: Array<License_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LicensesArgs = {
  _set?: InputMaybe<Licenses_Set_Input>;
  where: Licenses_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Licenses_By_PkArgs = {
  _set?: InputMaybe<Licenses_Set_Input>;
  pk_columns: Licenses_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Licenses_ManyArgs = {
  updates: Array<Licenses_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Lux_ProxyArgs = {
  _append?: InputMaybe<Lux_Proxy_Append_Input>;
  _delete_at_path?: InputMaybe<Lux_Proxy_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Lux_Proxy_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Lux_Proxy_Delete_Key_Input>;
  _prepend?: InputMaybe<Lux_Proxy_Prepend_Input>;
  _set?: InputMaybe<Lux_Proxy_Set_Input>;
  where: Lux_Proxy_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Lux_Proxy_AmiArgs = {
  _set?: InputMaybe<Lux_Proxy_Ami_Set_Input>;
  where: Lux_Proxy_Ami_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Lux_Proxy_Ami_By_PkArgs = {
  _set?: InputMaybe<Lux_Proxy_Ami_Set_Input>;
  pk_columns: Lux_Proxy_Ami_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Lux_Proxy_Ami_ManyArgs = {
  updates: Array<Lux_Proxy_Ami_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Lux_Proxy_By_PkArgs = {
  _append?: InputMaybe<Lux_Proxy_Append_Input>;
  _delete_at_path?: InputMaybe<Lux_Proxy_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Lux_Proxy_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Lux_Proxy_Delete_Key_Input>;
  _prepend?: InputMaybe<Lux_Proxy_Prepend_Input>;
  _set?: InputMaybe<Lux_Proxy_Set_Input>;
  pk_columns: Lux_Proxy_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Lux_Proxy_ManyArgs = {
  updates: Array<Lux_Proxy_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Model_Based_Enterprise_Cloud_CommitmentsArgs = {
  _inc?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Inc_Input>;
  _set?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Set_Input>;
  where: Model_Based_Enterprise_Cloud_Commitments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Model_Based_Enterprise_Cloud_Commitments_By_PkArgs = {
  _inc?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Inc_Input>;
  _set?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Set_Input>;
  pk_columns: Model_Based_Enterprise_Cloud_Commitments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Model_Based_Enterprise_Cloud_Commitments_ManyArgs = {
  updates: Array<Model_Based_Enterprise_Cloud_Commitments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Neon_Db_IntegrationArgs = {
  _set?: InputMaybe<Neon_Db_Integration_Set_Input>;
  where: Neon_Db_Integration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Neon_Db_Integration_By_PkArgs = {
  _set?: InputMaybe<Neon_Db_Integration_Set_Input>;
  pk_columns: Neon_Db_Integration_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Neon_Db_Integration_ManyArgs = {
  updates: Array<Neon_Db_Integration_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Newrelic_ConfigArgs = {
  _append?: InputMaybe<Newrelic_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Newrelic_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Newrelic_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Newrelic_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Newrelic_Config_Inc_Input>;
  _prepend?: InputMaybe<Newrelic_Config_Prepend_Input>;
  _set?: InputMaybe<Newrelic_Config_Set_Input>;
  where: Newrelic_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Newrelic_Config_By_PkArgs = {
  _append?: InputMaybe<Newrelic_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Newrelic_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Newrelic_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Newrelic_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Newrelic_Config_Inc_Input>;
  _prepend?: InputMaybe<Newrelic_Config_Prepend_Input>;
  _set?: InputMaybe<Newrelic_Config_Set_Input>;
  pk_columns: Newrelic_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Newrelic_Config_ManyArgs = {
  updates: Array<Newrelic_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Node_Pool_TypeArgs = {
  _set?: InputMaybe<Node_Pool_Type_Set_Input>;
  where: Node_Pool_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Node_Pool_Type_By_PkArgs = {
  _set?: InputMaybe<Node_Pool_Type_Set_Input>;
  pk_columns: Node_Pool_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Node_Pool_Type_ManyArgs = {
  updates: Array<Node_Pool_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Node_PoolsArgs = {
  _append?: InputMaybe<Node_Pools_Append_Input>;
  _delete_at_path?: InputMaybe<Node_Pools_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Node_Pools_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Node_Pools_Delete_Key_Input>;
  _prepend?: InputMaybe<Node_Pools_Prepend_Input>;
  _set?: InputMaybe<Node_Pools_Set_Input>;
  where: Node_Pools_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Node_Pools_By_PkArgs = {
  _append?: InputMaybe<Node_Pools_Append_Input>;
  _delete_at_path?: InputMaybe<Node_Pools_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Node_Pools_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Node_Pools_Delete_Key_Input>;
  _prepend?: InputMaybe<Node_Pools_Prepend_Input>;
  _set?: InputMaybe<Node_Pools_Set_Input>;
  pk_columns: Node_Pools_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Node_Pools_ManyArgs = {
  updates: Array<Node_Pools_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_NotificationArgs = {
  _set?: InputMaybe<Notification_Set_Input>;
  where: Notification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_By_PkArgs = {
  _set?: InputMaybe<Notification_Set_Input>;
  pk_columns: Notification_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_ManyArgs = {
  updates: Array<Notification_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_TypeArgs = {
  _set?: InputMaybe<Notification_Type_Set_Input>;
  where: Notification_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_Type_By_PkArgs = {
  _set?: InputMaybe<Notification_Type_Set_Input>;
  pk_columns: Notification_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Notification_Type_ManyArgs = {
  updates: Array<Notification_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Sample_Db_CohortArgs = {
  _append?: InputMaybe<Onboarding_Sample_Db_Cohort_Append_Input>;
  _delete_at_path?: InputMaybe<
    Onboarding_Sample_Db_Cohort_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<Onboarding_Sample_Db_Cohort_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Onboarding_Sample_Db_Cohort_Delete_Key_Input>;
  _prepend?: InputMaybe<Onboarding_Sample_Db_Cohort_Prepend_Input>;
  _set?: InputMaybe<Onboarding_Sample_Db_Cohort_Set_Input>;
  where: Onboarding_Sample_Db_Cohort_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Sample_Db_Cohort_By_PkArgs = {
  _append?: InputMaybe<Onboarding_Sample_Db_Cohort_Append_Input>;
  _delete_at_path?: InputMaybe<
    Onboarding_Sample_Db_Cohort_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<Onboarding_Sample_Db_Cohort_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Onboarding_Sample_Db_Cohort_Delete_Key_Input>;
  _prepend?: InputMaybe<Onboarding_Sample_Db_Cohort_Prepend_Input>;
  _set?: InputMaybe<Onboarding_Sample_Db_Cohort_Set_Input>;
  pk_columns: Onboarding_Sample_Db_Cohort_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Sample_Db_Cohort_ManyArgs = {
  updates: Array<Onboarding_Sample_Db_Cohort_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Sample_Db_ConfigArgs = {
  _append?: InputMaybe<Onboarding_Sample_Db_Config_Append_Input>;
  _delete_at_path?: InputMaybe<
    Onboarding_Sample_Db_Config_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<Onboarding_Sample_Db_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Onboarding_Sample_Db_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Onboarding_Sample_Db_Config_Inc_Input>;
  _prepend?: InputMaybe<Onboarding_Sample_Db_Config_Prepend_Input>;
  _set?: InputMaybe<Onboarding_Sample_Db_Config_Set_Input>;
  where: Onboarding_Sample_Db_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Sample_Db_Config_By_PkArgs = {
  _append?: InputMaybe<Onboarding_Sample_Db_Config_Append_Input>;
  _delete_at_path?: InputMaybe<
    Onboarding_Sample_Db_Config_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<Onboarding_Sample_Db_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Onboarding_Sample_Db_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Onboarding_Sample_Db_Config_Inc_Input>;
  _prepend?: InputMaybe<Onboarding_Sample_Db_Config_Prepend_Input>;
  _set?: InputMaybe<Onboarding_Sample_Db_Config_Set_Input>;
  pk_columns: Onboarding_Sample_Db_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Onboarding_Sample_Db_Config_ManyArgs = {
  updates: Array<Onboarding_Sample_Db_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_One_Click_DeploymentArgs = {
  _append?: InputMaybe<One_Click_Deployment_Append_Input>;
  _delete_at_path?: InputMaybe<One_Click_Deployment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<One_Click_Deployment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<One_Click_Deployment_Delete_Key_Input>;
  _inc?: InputMaybe<One_Click_Deployment_Inc_Input>;
  _prepend?: InputMaybe<One_Click_Deployment_Prepend_Input>;
  _set?: InputMaybe<One_Click_Deployment_Set_Input>;
  where: One_Click_Deployment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_One_Click_Deployment_By_PkArgs = {
  _append?: InputMaybe<One_Click_Deployment_Append_Input>;
  _delete_at_path?: InputMaybe<One_Click_Deployment_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<One_Click_Deployment_Delete_Elem_Input>;
  _delete_key?: InputMaybe<One_Click_Deployment_Delete_Key_Input>;
  _inc?: InputMaybe<One_Click_Deployment_Inc_Input>;
  _prepend?: InputMaybe<One_Click_Deployment_Prepend_Input>;
  _set?: InputMaybe<One_Click_Deployment_Set_Input>;
  pk_columns: One_Click_Deployment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_One_Click_Deployment_ManyArgs = {
  updates: Array<One_Click_Deployment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_One_Click_Deployment_Sample_AppsArgs = {
  _inc?: InputMaybe<One_Click_Deployment_Sample_Apps_Inc_Input>;
  _set?: InputMaybe<One_Click_Deployment_Sample_Apps_Set_Input>;
  where: One_Click_Deployment_Sample_Apps_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_One_Click_Deployment_Sample_Apps_By_PkArgs = {
  _inc?: InputMaybe<One_Click_Deployment_Sample_Apps_Inc_Input>;
  _set?: InputMaybe<One_Click_Deployment_Sample_Apps_Set_Input>;
  pk_columns: One_Click_Deployment_Sample_Apps_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_One_Click_Deployment_Sample_Apps_ManyArgs = {
  updates: Array<One_Click_Deployment_Sample_Apps_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_One_Click_Deployment_State_LogArgs = {
  _append?: InputMaybe<One_Click_Deployment_State_Log_Append_Input>;
  _delete_at_path?: InputMaybe<
    One_Click_Deployment_State_Log_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<One_Click_Deployment_State_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<One_Click_Deployment_State_Log_Delete_Key_Input>;
  _inc?: InputMaybe<One_Click_Deployment_State_Log_Inc_Input>;
  _prepend?: InputMaybe<One_Click_Deployment_State_Log_Prepend_Input>;
  _set?: InputMaybe<One_Click_Deployment_State_Log_Set_Input>;
  where: One_Click_Deployment_State_Log_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_One_Click_Deployment_State_Log_By_PkArgs = {
  _append?: InputMaybe<One_Click_Deployment_State_Log_Append_Input>;
  _delete_at_path?: InputMaybe<
    One_Click_Deployment_State_Log_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<One_Click_Deployment_State_Log_Delete_Elem_Input>;
  _delete_key?: InputMaybe<One_Click_Deployment_State_Log_Delete_Key_Input>;
  _inc?: InputMaybe<One_Click_Deployment_State_Log_Inc_Input>;
  _prepend?: InputMaybe<One_Click_Deployment_State_Log_Prepend_Input>;
  _set?: InputMaybe<One_Click_Deployment_State_Log_Set_Input>;
  pk_columns: One_Click_Deployment_State_Log_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_One_Click_Deployment_State_Log_ManyArgs = {
  updates: Array<One_Click_Deployment_State_Log_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_One_Click_Deployment_StatesArgs = {
  _set?: InputMaybe<One_Click_Deployment_States_Set_Input>;
  where: One_Click_Deployment_States_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_One_Click_Deployment_States_By_PkArgs = {
  _set?: InputMaybe<One_Click_Deployment_States_Set_Input>;
  pk_columns: One_Click_Deployment_States_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_One_Click_Deployment_States_ManyArgs = {
  updates: Array<One_Click_Deployment_States_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Opentelemetry_ConfigArgs = {
  _append?: InputMaybe<Opentelemetry_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Opentelemetry_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Opentelemetry_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Opentelemetry_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Opentelemetry_Config_Inc_Input>;
  _prepend?: InputMaybe<Opentelemetry_Config_Prepend_Input>;
  _set?: InputMaybe<Opentelemetry_Config_Set_Input>;
  where: Opentelemetry_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Opentelemetry_Config_By_PkArgs = {
  _append?: InputMaybe<Opentelemetry_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Opentelemetry_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Opentelemetry_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Opentelemetry_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Opentelemetry_Config_Inc_Input>;
  _prepend?: InputMaybe<Opentelemetry_Config_Prepend_Input>;
  _set?: InputMaybe<Opentelemetry_Config_Set_Input>;
  pk_columns: Opentelemetry_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Opentelemetry_Config_ManyArgs = {
  updates: Array<Opentelemetry_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Operations_Delete_Free_Plan_Dns_Records_Nov_2021Args = {
  _set?: InputMaybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Set_Input>;
  where: Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Operations_Delete_Free_Plan_Dns_Records_Nov_2021_By_PkArgs = {
  _set?: InputMaybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Set_Input>;
  pk_columns: Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Operations_Delete_Free_Plan_Dns_Records_Nov_2021_ManyArgs = {
  updates: Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Operations_TenantArgs = {
  _inc?: InputMaybe<Operations_Tenant_Inc_Input>;
  _set?: InputMaybe<Operations_Tenant_Set_Input>;
  where: Operations_Tenant_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Operations_Tenant_ManyArgs = {
  updates: Array<Operations_Tenant_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Payment_MethodArgs = {
  _inc?: InputMaybe<Payment_Method_Inc_Input>;
  _set?: InputMaybe<Payment_Method_Set_Input>;
  where: Payment_Method_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Payment_Method_By_PkArgs = {
  _inc?: InputMaybe<Payment_Method_Inc_Input>;
  _set?: InputMaybe<Payment_Method_Set_Input>;
  pk_columns: Payment_Method_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Payment_Method_ManyArgs = {
  updates: Array<Payment_Method_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Plan_EntitlementsArgs = {
  _set?: InputMaybe<Plan_Entitlements_Set_Input>;
  where: Plan_Entitlements_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Plan_Entitlements_By_PkArgs = {
  _set?: InputMaybe<Plan_Entitlements_Set_Input>;
  pk_columns: Plan_Entitlements_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Plan_Entitlements_ManyArgs = {
  updates: Array<Plan_Entitlements_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PlansArgs = {
  _inc?: InputMaybe<Plans_Inc_Input>;
  _set?: InputMaybe<Plans_Set_Input>;
  where: Plans_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Plans_By_PkArgs = {
  _inc?: InputMaybe<Plans_Inc_Input>;
  _set?: InputMaybe<Plans_Set_Input>;
  pk_columns: Plans_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Plans_ManyArgs = {
  updates: Array<Plans_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PoliciesArgs = {
  _inc?: InputMaybe<Policies_Inc_Input>;
  _set?: InputMaybe<Policies_Set_Input>;
  where: Policies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policies_By_PkArgs = {
  _inc?: InputMaybe<Policies_Inc_Input>;
  _set?: InputMaybe<Policies_Set_Input>;
  pk_columns: Policies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policies_ManyArgs = {
  updates: Array<Policies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PrivilegesArgs = {
  _inc?: InputMaybe<Privileges_Inc_Input>;
  _set?: InputMaybe<Privileges_Set_Input>;
  where: Privileges_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Privileges_By_PkArgs = {
  _inc?: InputMaybe<Privileges_Inc_Input>;
  _set?: InputMaybe<Privileges_Set_Input>;
  pk_columns: Privileges_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Privileges_ManyArgs = {
  updates: Array<Privileges_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Pro_License_KeysArgs = {
  _inc?: InputMaybe<Pro_License_Keys_Inc_Input>;
  _set?: InputMaybe<Pro_License_Keys_Set_Input>;
  where: Pro_License_Keys_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Pro_License_Keys_By_PkArgs = {
  _inc?: InputMaybe<Pro_License_Keys_Inc_Input>;
  _set?: InputMaybe<Pro_License_Keys_Set_Input>;
  pk_columns: Pro_License_Keys_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Pro_License_Keys_ManyArgs = {
  updates: Array<Pro_License_Keys_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_ActivityArgs = {
  _append?: InputMaybe<Project_Activity_Append_Input>;
  _delete_at_path?: InputMaybe<Project_Activity_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Project_Activity_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Project_Activity_Delete_Key_Input>;
  _inc?: InputMaybe<Project_Activity_Inc_Input>;
  _prepend?: InputMaybe<Project_Activity_Prepend_Input>;
  _set?: InputMaybe<Project_Activity_Set_Input>;
  where: Project_Activity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Activity_By_PkArgs = {
  _append?: InputMaybe<Project_Activity_Append_Input>;
  _delete_at_path?: InputMaybe<Project_Activity_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Project_Activity_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Project_Activity_Delete_Key_Input>;
  _inc?: InputMaybe<Project_Activity_Inc_Input>;
  _prepend?: InputMaybe<Project_Activity_Prepend_Input>;
  _set?: InputMaybe<Project_Activity_Set_Input>;
  pk_columns: Project_Activity_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Activity_ManyArgs = {
  updates: Array<Project_Activity_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Billing_Manager_InvitationsArgs = {
  _inc?: InputMaybe<Project_Billing_Manager_Invitations_Inc_Input>;
  _set?: InputMaybe<Project_Billing_Manager_Invitations_Set_Input>;
  where: Project_Billing_Manager_Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Billing_Manager_Invitations_By_PkArgs = {
  _inc?: InputMaybe<Project_Billing_Manager_Invitations_Inc_Input>;
  _set?: InputMaybe<Project_Billing_Manager_Invitations_Set_Input>;
  pk_columns: Project_Billing_Manager_Invitations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Billing_Manager_Invitations_ManyArgs = {
  updates: Array<Project_Billing_Manager_Invitations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_Allowed_Schema_TablesArgs = {
  _set?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Set_Input>;
  where: Project_Collaborator_Allowed_Schema_Tables_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_Allowed_Schema_Tables_By_PkArgs = {
  _set?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Set_Input>;
  pk_columns: Project_Collaborator_Allowed_Schema_Tables_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_Allowed_Schema_Tables_ManyArgs = {
  updates: Array<Project_Collaborator_Allowed_Schema_Tables_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_Allowed_SchemasArgs = {
  _set?: InputMaybe<Project_Collaborator_Allowed_Schemas_Set_Input>;
  where: Project_Collaborator_Allowed_Schemas_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_Allowed_Schemas_By_PkArgs = {
  _set?: InputMaybe<Project_Collaborator_Allowed_Schemas_Set_Input>;
  pk_columns: Project_Collaborator_Allowed_Schemas_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_Allowed_Schemas_ManyArgs = {
  updates: Array<Project_Collaborator_Allowed_Schemas_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_InvitationsArgs = {
  _append?: InputMaybe<Project_Collaborator_Invitations_Append_Input>;
  _delete_at_path?: InputMaybe<
    Project_Collaborator_Invitations_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<Project_Collaborator_Invitations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Project_Collaborator_Invitations_Delete_Key_Input>;
  _inc?: InputMaybe<Project_Collaborator_Invitations_Inc_Input>;
  _prepend?: InputMaybe<Project_Collaborator_Invitations_Prepend_Input>;
  _set?: InputMaybe<Project_Collaborator_Invitations_Set_Input>;
  where: Project_Collaborator_Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_Invitations_By_PkArgs = {
  _append?: InputMaybe<Project_Collaborator_Invitations_Append_Input>;
  _delete_at_path?: InputMaybe<
    Project_Collaborator_Invitations_Delete_At_Path_Input
  >;
  _delete_elem?: InputMaybe<Project_Collaborator_Invitations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Project_Collaborator_Invitations_Delete_Key_Input>;
  _inc?: InputMaybe<Project_Collaborator_Invitations_Inc_Input>;
  _prepend?: InputMaybe<Project_Collaborator_Invitations_Prepend_Input>;
  _set?: InputMaybe<Project_Collaborator_Invitations_Set_Input>;
  pk_columns: Project_Collaborator_Invitations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_Invitations_ManyArgs = {
  updates: Array<Project_Collaborator_Invitations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_PrivilegesArgs = {
  _set?: InputMaybe<Project_Collaborator_Privileges_Set_Input>;
  where: Project_Collaborator_Privileges_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_Privileges_By_PkArgs = {
  _set?: InputMaybe<Project_Collaborator_Privileges_Set_Input>;
  pk_columns: Project_Collaborator_Privileges_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborator_Privileges_ManyArgs = {
  updates: Array<Project_Collaborator_Privileges_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_CollaboratorsArgs = {
  _set?: InputMaybe<Project_Collaborators_Set_Input>;
  where: Project_Collaborators_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborators_By_PkArgs = {
  _set?: InputMaybe<Project_Collaborators_Set_Input>;
  pk_columns: Project_Collaborators_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Collaborators_ManyArgs = {
  updates: Array<Project_Collaborators_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_UsageArgs = {
  _inc?: InputMaybe<Project_Data_Usage_Inc_Input>;
  _set?: InputMaybe<Project_Data_Usage_Set_Input>;
  where: Project_Data_Usage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_AggArgs = {
  _inc?: InputMaybe<Project_Data_Usage_Agg_Inc_Input>;
  _set?: InputMaybe<Project_Data_Usage_Agg_Set_Input>;
  where: Project_Data_Usage_Agg_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_Agg_ManyArgs = {
  updates: Array<Project_Data_Usage_Agg_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_Agg_UserArgs = {
  _inc?: InputMaybe<Project_Data_Usage_Agg_User_Inc_Input>;
  _set?: InputMaybe<Project_Data_Usage_Agg_User_Set_Input>;
  where: Project_Data_Usage_Agg_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_Agg_User_ManyArgs = {
  updates: Array<Project_Data_Usage_Agg_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_By_PkArgs = {
  _inc?: InputMaybe<Project_Data_Usage_Inc_Input>;
  _set?: InputMaybe<Project_Data_Usage_Set_Input>;
  pk_columns: Project_Data_Usage_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_ComponentsArgs = {
  _inc?: InputMaybe<Project_Data_Usage_Components_Inc_Input>;
  _set?: InputMaybe<Project_Data_Usage_Components_Set_Input>;
  where: Project_Data_Usage_Components_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_Components_ManyArgs = {
  updates: Array<Project_Data_Usage_Components_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_ManyArgs = {
  updates: Array<Project_Data_Usage_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_PrometheusArgs = {
  _inc?: InputMaybe<Project_Data_Usage_Prometheus_Inc_Input>;
  _set?: InputMaybe<Project_Data_Usage_Prometheus_Set_Input>;
  where: Project_Data_Usage_Prometheus_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_Prometheus_Agg_UserArgs = {
  _inc?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Inc_Input>;
  _set?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Set_Input>;
  where: Project_Data_Usage_Prometheus_Agg_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_Prometheus_Agg_User_ManyArgs = {
  updates: Array<Project_Data_Usage_Prometheus_Agg_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_Prometheus_By_PkArgs = {
  _inc?: InputMaybe<Project_Data_Usage_Prometheus_Inc_Input>;
  _set?: InputMaybe<Project_Data_Usage_Prometheus_Set_Input>;
  pk_columns: Project_Data_Usage_Prometheus_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_Prometheus_ManyArgs = {
  updates: Array<Project_Data_Usage_Prometheus_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_ReportArgs = {
  _set?: InputMaybe<Project_Data_Usage_Report_Set_Input>;
  where: Project_Data_Usage_Report_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_Report_By_PkArgs = {
  _set?: InputMaybe<Project_Data_Usage_Report_Set_Input>;
  pk_columns: Project_Data_Usage_Report_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Data_Usage_Report_ManyArgs = {
  updates: Array<Project_Data_Usage_Report_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Db_UsageArgs = {
  _inc?: InputMaybe<Project_Db_Usage_Inc_Input>;
  _set?: InputMaybe<Project_Db_Usage_Set_Input>;
  where: Project_Db_Usage_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Db_Usage_Agg_UserArgs = {
  _inc?: InputMaybe<Project_Db_Usage_Agg_User_Inc_Input>;
  _set?: InputMaybe<Project_Db_Usage_Agg_User_Set_Input>;
  where: Project_Db_Usage_Agg_User_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Db_Usage_Agg_User_ManyArgs = {
  updates: Array<Project_Db_Usage_Agg_User_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Db_Usage_By_PkArgs = {
  _inc?: InputMaybe<Project_Db_Usage_Inc_Input>;
  _set?: InputMaybe<Project_Db_Usage_Set_Input>;
  pk_columns: Project_Db_Usage_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Db_Usage_ManyArgs = {
  updates: Array<Project_Db_Usage_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Entitlement_AccessArgs = {
  _set?: InputMaybe<Project_Entitlement_Access_Set_Input>;
  where: Project_Entitlement_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Entitlement_Access_By_PkArgs = {
  _set?: InputMaybe<Project_Entitlement_Access_Set_Input>;
  pk_columns: Project_Entitlement_Access_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Entitlement_Access_ManyArgs = {
  updates: Array<Project_Entitlement_Access_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Entitlement_CatalogueArgs = {
  _inc?: InputMaybe<Project_Entitlement_Catalogue_Inc_Input>;
  _set?: InputMaybe<Project_Entitlement_Catalogue_Set_Input>;
  where: Project_Entitlement_Catalogue_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Entitlement_Catalogue_By_PkArgs = {
  _inc?: InputMaybe<Project_Entitlement_Catalogue_Inc_Input>;
  _set?: InputMaybe<Project_Entitlement_Catalogue_Set_Input>;
  pk_columns: Project_Entitlement_Catalogue_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Entitlement_Catalogue_ManyArgs = {
  updates: Array<Project_Entitlement_Catalogue_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Entitlement_TypesArgs = {
  _set?: InputMaybe<Project_Entitlement_Types_Set_Input>;
  where: Project_Entitlement_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Entitlement_Types_By_PkArgs = {
  _set?: InputMaybe<Project_Entitlement_Types_Set_Input>;
  pk_columns: Project_Entitlement_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Entitlement_Types_ManyArgs = {
  updates: Array<Project_Entitlement_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_LabelsArgs = {
  _set?: InputMaybe<Project_Labels_Set_Input>;
  where: Project_Labels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Labels_By_PkArgs = {
  _set?: InputMaybe<Project_Labels_Set_Input>;
  pk_columns: Project_Labels_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Labels_ManyArgs = {
  updates: Array<Project_Labels_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_MetadataArgs = {
  _inc?: InputMaybe<Project_Metadata_Inc_Input>;
  _set?: InputMaybe<Project_Metadata_Set_Input>;
  where: Project_Metadata_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Metadata_By_PkArgs = {
  _inc?: InputMaybe<Project_Metadata_Inc_Input>;
  _set?: InputMaybe<Project_Metadata_Set_Input>;
  pk_columns: Project_Metadata_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Metadata_ManyArgs = {
  updates: Array<Project_Metadata_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_NotificationArgs = {
  _append?: InputMaybe<Project_Notification_Append_Input>;
  _delete_at_path?: InputMaybe<Project_Notification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Project_Notification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Project_Notification_Delete_Key_Input>;
  _inc?: InputMaybe<Project_Notification_Inc_Input>;
  _prepend?: InputMaybe<Project_Notification_Prepend_Input>;
  _set?: InputMaybe<Project_Notification_Set_Input>;
  where: Project_Notification_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Notification_By_PkArgs = {
  _append?: InputMaybe<Project_Notification_Append_Input>;
  _delete_at_path?: InputMaybe<Project_Notification_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Project_Notification_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Project_Notification_Delete_Key_Input>;
  _inc?: InputMaybe<Project_Notification_Inc_Input>;
  _prepend?: InputMaybe<Project_Notification_Prepend_Input>;
  _set?: InputMaybe<Project_Notification_Set_Input>;
  pk_columns: Project_Notification_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Notification_ManyArgs = {
  updates: Array<Project_Notification_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Ownership_Transfer_InvitationsArgs = {
  _inc?: InputMaybe<Project_Ownership_Transfer_Invitations_Inc_Input>;
  _set?: InputMaybe<Project_Ownership_Transfer_Invitations_Set_Input>;
  where: Project_Ownership_Transfer_Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Ownership_Transfer_Invitations_By_PkArgs = {
  _inc?: InputMaybe<Project_Ownership_Transfer_Invitations_Inc_Input>;
  _set?: InputMaybe<Project_Ownership_Transfer_Invitations_Set_Input>;
  pk_columns: Project_Ownership_Transfer_Invitations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Ownership_Transfer_Invitations_ManyArgs = {
  updates: Array<Project_Ownership_Transfer_Invitations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Requests_CountArgs = {
  _inc?: InputMaybe<Project_Requests_Count_Inc_Input>;
  _set?: InputMaybe<Project_Requests_Count_Set_Input>;
  where: Project_Requests_Count_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Requests_Count_By_PkArgs = {
  _inc?: InputMaybe<Project_Requests_Count_Inc_Input>;
  _set?: InputMaybe<Project_Requests_Count_Set_Input>;
  pk_columns: Project_Requests_Count_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Requests_Count_ManyArgs = {
  updates: Array<Project_Requests_Count_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_StatsArgs = {
  _inc?: InputMaybe<Project_Stats_Inc_Input>;
  _set?: InputMaybe<Project_Stats_Set_Input>;
  where: Project_Stats_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Stats_By_PkArgs = {
  _inc?: InputMaybe<Project_Stats_Inc_Input>;
  _set?: InputMaybe<Project_Stats_Set_Input>;
  pk_columns: Project_Stats_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Stats_ManyArgs = {
  updates: Array<Project_Stats_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Total_Db_Usage_AggArgs = {
  _inc?: InputMaybe<Project_Total_Db_Usage_Agg_Inc_Input>;
  _set?: InputMaybe<Project_Total_Db_Usage_Agg_Set_Input>;
  where: Project_Total_Db_Usage_Agg_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Project_Total_Db_Usage_Agg_ManyArgs = {
  updates: Array<Project_Total_Db_Usage_Agg_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProjectsArgs = {
  _set?: InputMaybe<Projects_Set_Input>;
  where: Projects_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Projects_By_PkArgs = {
  _set?: InputMaybe<Projects_Set_Input>;
  pk_columns: Projects_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Projects_ManyArgs = {
  updates: Array<Projects_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Projects_Pool_ConfigArgs = {
  _inc?: InputMaybe<Projects_Pool_Config_Inc_Input>;
  _set?: InputMaybe<Projects_Pool_Config_Set_Input>;
  where: Projects_Pool_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Projects_Pool_Config_By_PkArgs = {
  _inc?: InputMaybe<Projects_Pool_Config_Inc_Input>;
  _set?: InputMaybe<Projects_Pool_Config_Set_Input>;
  pk_columns: Projects_Pool_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Projects_Pool_Config_ManyArgs = {
  updates: Array<Projects_Pool_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Projects_Pro_Key_GenerationsArgs = {
  _set?: InputMaybe<Projects_Pro_Key_Generations_Set_Input>;
  where: Projects_Pro_Key_Generations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Projects_Pro_Key_Generations_By_PkArgs = {
  _set?: InputMaybe<Projects_Pro_Key_Generations_Set_Input>;
  pk_columns: Projects_Pro_Key_Generations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Projects_Pro_Key_Generations_ManyArgs = {
  updates: Array<Projects_Pro_Key_Generations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Prometheus_ConfigArgs = {
  _append?: InputMaybe<Prometheus_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Prometheus_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Prometheus_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Prometheus_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Prometheus_Config_Inc_Input>;
  _prepend?: InputMaybe<Prometheus_Config_Prepend_Input>;
  _set?: InputMaybe<Prometheus_Config_Set_Input>;
  where: Prometheus_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Prometheus_Config_By_PkArgs = {
  _append?: InputMaybe<Prometheus_Config_Append_Input>;
  _delete_at_path?: InputMaybe<Prometheus_Config_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Prometheus_Config_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Prometheus_Config_Delete_Key_Input>;
  _inc?: InputMaybe<Prometheus_Config_Inc_Input>;
  _prepend?: InputMaybe<Prometheus_Config_Prepend_Input>;
  _set?: InputMaybe<Prometheus_Config_Set_Input>;
  pk_columns: Prometheus_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Prometheus_Config_ManyArgs = {
  updates: Array<Prometheus_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProvidersArgs = {
  _inc?: InputMaybe<Providers_Inc_Input>;
  _set?: InputMaybe<Providers_Set_Input>;
  where: Providers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Providers_By_PkArgs = {
  _inc?: InputMaybe<Providers_Inc_Input>;
  _set?: InputMaybe<Providers_Set_Input>;
  pk_columns: Providers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Providers_ManyArgs = {
  updates: Array<Providers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProvisioningArgs = {
  _set?: InputMaybe<Provisioning_Set_Input>;
  where: Provisioning_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Provisioning_By_PkArgs = {
  _set?: InputMaybe<Provisioning_Set_Input>;
  pk_columns: Provisioning_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Provisioning_ManyArgs = {
  updates: Array<Provisioning_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RegionArgs = {
  _append?: InputMaybe<Region_Append_Input>;
  _delete_at_path?: InputMaybe<Region_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Region_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Region_Delete_Key_Input>;
  _prepend?: InputMaybe<Region_Prepend_Input>;
  _set?: InputMaybe<Region_Set_Input>;
  where: Region_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Region_By_PkArgs = {
  _append?: InputMaybe<Region_Append_Input>;
  _delete_at_path?: InputMaybe<Region_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Region_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Region_Delete_Key_Input>;
  _prepend?: InputMaybe<Region_Prepend_Input>;
  _set?: InputMaybe<Region_Set_Input>;
  pk_columns: Region_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Region_ManyArgs = {
  updates: Array<Region_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Region_V2Args = {
  _append?: InputMaybe<Region_V2_Append_Input>;
  _delete_at_path?: InputMaybe<Region_V2_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Region_V2_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Region_V2_Delete_Key_Input>;
  _prepend?: InputMaybe<Region_V2_Prepend_Input>;
  _set?: InputMaybe<Region_V2_Set_Input>;
  where: Region_V2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Region_V2_ManyArgs = {
  updates: Array<Region_V2_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Regional_Data_ConnectorsArgs = {
  _append?: InputMaybe<Regional_Data_Connectors_Append_Input>;
  _delete_at_path?: InputMaybe<Regional_Data_Connectors_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Regional_Data_Connectors_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Regional_Data_Connectors_Delete_Key_Input>;
  _prepend?: InputMaybe<Regional_Data_Connectors_Prepend_Input>;
  _set?: InputMaybe<Regional_Data_Connectors_Set_Input>;
  where: Regional_Data_Connectors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Regional_Data_Connectors_By_PkArgs = {
  _append?: InputMaybe<Regional_Data_Connectors_Append_Input>;
  _delete_at_path?: InputMaybe<Regional_Data_Connectors_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Regional_Data_Connectors_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Regional_Data_Connectors_Delete_Key_Input>;
  _prepend?: InputMaybe<Regional_Data_Connectors_Prepend_Input>;
  _set?: InputMaybe<Regional_Data_Connectors_Set_Input>;
  pk_columns: Regional_Data_Connectors_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Regional_Data_Connectors_ManyArgs = {
  updates: Array<Regional_Data_Connectors_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Regional_MetricsArgs = {
  _append?: InputMaybe<Regional_Metrics_Append_Input>;
  _delete_at_path?: InputMaybe<Regional_Metrics_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Regional_Metrics_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Regional_Metrics_Delete_Key_Input>;
  _prepend?: InputMaybe<Regional_Metrics_Prepend_Input>;
  _set?: InputMaybe<Regional_Metrics_Set_Input>;
  where: Regional_Metrics_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Regional_Metrics_By_PkArgs = {
  _append?: InputMaybe<Regional_Metrics_Append_Input>;
  _delete_at_path?: InputMaybe<Regional_Metrics_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Regional_Metrics_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Regional_Metrics_Delete_Key_Input>;
  _prepend?: InputMaybe<Regional_Metrics_Prepend_Input>;
  _set?: InputMaybe<Regional_Metrics_Set_Input>;
  pk_columns: Regional_Metrics_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Regional_Metrics_ManyArgs = {
  updates: Array<Regional_Metrics_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Saml_IdpArgs = {
  _set?: InputMaybe<Saml_Idp_Set_Input>;
  where: Saml_Idp_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Saml_Idp_By_PkArgs = {
  _set?: InputMaybe<Saml_Idp_Set_Input>;
  pk_columns: Saml_Idp_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Saml_Idp_ManyArgs = {
  updates: Array<Saml_Idp_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Search_Project_Login_Status_ResultsArgs = {
  _inc?: InputMaybe<Search_Project_Login_Status_Results_Inc_Input>;
  _set?: InputMaybe<Search_Project_Login_Status_Results_Set_Input>;
  where: Search_Project_Login_Status_Results_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Search_Project_Login_Status_Results_ManyArgs = {
  updates: Array<Search_Project_Login_Status_Results_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Slack_ConfigArgs = {
  _set?: InputMaybe<Slack_Config_Set_Input>;
  where: Slack_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Slack_Config_By_PkArgs = {
  _set?: InputMaybe<Slack_Config_Set_Input>;
  pk_columns: Slack_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Slack_Config_ManyArgs = {
  updates: Array<Slack_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stripe_SubscriptionArgs = {
  _set?: InputMaybe<Stripe_Subscription_Set_Input>;
  where: Stripe_Subscription_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stripe_Subscription_By_PkArgs = {
  _set?: InputMaybe<Stripe_Subscription_Set_Input>;
  pk_columns: Stripe_Subscription_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stripe_Subscription_ManyArgs = {
  updates: Array<Stripe_Subscription_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stripe_Webhook_EventsArgs = {
  _append?: InputMaybe<Stripe_Webhook_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Webhook_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Webhook_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Webhook_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Stripe_Webhook_Events_Prepend_Input>;
  _set?: InputMaybe<Stripe_Webhook_Events_Set_Input>;
  where: Stripe_Webhook_Events_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stripe_Webhook_Events_By_PkArgs = {
  _append?: InputMaybe<Stripe_Webhook_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Stripe_Webhook_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stripe_Webhook_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stripe_Webhook_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Stripe_Webhook_Events_Prepend_Input>;
  _set?: InputMaybe<Stripe_Webhook_Events_Set_Input>;
  pk_columns: Stripe_Webhook_Events_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stripe_Webhook_Events_ManyArgs = {
  updates: Array<Stripe_Webhook_Events_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Super_Connector_TypesArgs = {
  _set?: InputMaybe<Super_Connector_Types_Set_Input>;
  where: Super_Connector_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Super_Connector_Types_By_PkArgs = {
  _set?: InputMaybe<Super_Connector_Types_Set_Input>;
  pk_columns: Super_Connector_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Super_Connector_Types_ManyArgs = {
  updates: Array<Super_Connector_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Support_Plan_TypesArgs = {
  _set?: InputMaybe<Support_Plan_Types_Set_Input>;
  where: Support_Plan_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Support_Plan_Types_By_PkArgs = {
  _set?: InputMaybe<Support_Plan_Types_Set_Input>;
  pk_columns: Support_Plan_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Support_Plan_Types_ManyArgs = {
  updates: Array<Support_Plan_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SurveyArgs = {
  _set?: InputMaybe<Survey_Set_Input>;
  where: Survey_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_By_PkArgs = {
  _set?: InputMaybe<Survey_Set_Input>;
  pk_columns: Survey_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_ManyArgs = {
  updates: Array<Survey_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_QuestionArgs = {
  _set?: InputMaybe<Survey_Question_Set_Input>;
  where: Survey_Question_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_Answer_OptionArgs = {
  _set?: InputMaybe<Survey_Question_Answer_Option_Set_Input>;
  where: Survey_Question_Answer_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_Answer_Option_By_PkArgs = {
  _set?: InputMaybe<Survey_Question_Answer_Option_Set_Input>;
  pk_columns: Survey_Question_Answer_Option_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_Answer_Option_ManyArgs = {
  updates: Array<Survey_Question_Answer_Option_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_AnswersArgs = {
  _set?: InputMaybe<Survey_Question_Answers_Set_Input>;
  where: Survey_Question_Answers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_Answers_By_PkArgs = {
  _set?: InputMaybe<Survey_Question_Answers_Set_Input>;
  pk_columns: Survey_Question_Answers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_Answers_ManyArgs = {
  updates: Array<Survey_Question_Answers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_By_PkArgs = {
  _set?: InputMaybe<Survey_Question_Set_Input>;
  pk_columns: Survey_Question_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_KindArgs = {
  _set?: InputMaybe<Survey_Question_Kind_Set_Input>;
  where: Survey_Question_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_Kind_By_PkArgs = {
  _set?: InputMaybe<Survey_Question_Kind_Set_Input>;
  pk_columns: Survey_Question_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_Kind_ManyArgs = {
  updates: Array<Survey_Question_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_ManyArgs = {
  updates: Array<Survey_Question_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_OptionsArgs = {
  _set?: InputMaybe<Survey_Question_Options_Set_Input>;
  where: Survey_Question_Options_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_Options_By_PkArgs = {
  _set?: InputMaybe<Survey_Question_Options_Set_Input>;
  pk_columns: Survey_Question_Options_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_Question_Options_ManyArgs = {
  updates: Array<Survey_Question_Options_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2Args = {
  _append?: InputMaybe<Survey_V2_Append_Input>;
  _delete_at_path?: InputMaybe<Survey_V2_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Survey_V2_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Survey_V2_Delete_Key_Input>;
  _prepend?: InputMaybe<Survey_V2_Prepend_Input>;
  _set?: InputMaybe<Survey_V2_Set_Input>;
  where: Survey_V2_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_By_PkArgs = {
  _append?: InputMaybe<Survey_V2_Append_Input>;
  _delete_at_path?: InputMaybe<Survey_V2_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Survey_V2_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Survey_V2_Delete_Key_Input>;
  _prepend?: InputMaybe<Survey_V2_Prepend_Input>;
  _set?: InputMaybe<Survey_V2_Set_Input>;
  pk_columns: Survey_V2_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_ManyArgs = {
  updates: Array<Survey_V2_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_QuestionArgs = {
  _append?: InputMaybe<Survey_V2_Question_Append_Input>;
  _delete_at_path?: InputMaybe<Survey_V2_Question_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Survey_V2_Question_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Survey_V2_Question_Delete_Key_Input>;
  _inc?: InputMaybe<Survey_V2_Question_Inc_Input>;
  _prepend?: InputMaybe<Survey_V2_Question_Prepend_Input>;
  _set?: InputMaybe<Survey_V2_Question_Set_Input>;
  where: Survey_V2_Question_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Question_By_PkArgs = {
  _append?: InputMaybe<Survey_V2_Question_Append_Input>;
  _delete_at_path?: InputMaybe<Survey_V2_Question_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Survey_V2_Question_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Survey_V2_Question_Delete_Key_Input>;
  _inc?: InputMaybe<Survey_V2_Question_Inc_Input>;
  _prepend?: InputMaybe<Survey_V2_Question_Prepend_Input>;
  _set?: InputMaybe<Survey_V2_Question_Set_Input>;
  pk_columns: Survey_V2_Question_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Question_KindArgs = {
  _set?: InputMaybe<Survey_V2_Question_Kind_Set_Input>;
  where: Survey_V2_Question_Kind_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Question_Kind_By_PkArgs = {
  _set?: InputMaybe<Survey_V2_Question_Kind_Set_Input>;
  pk_columns: Survey_V2_Question_Kind_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Question_Kind_ManyArgs = {
  updates: Array<Survey_V2_Question_Kind_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Question_ManyArgs = {
  updates: Array<Survey_V2_Question_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Question_OptionArgs = {
  _append?: InputMaybe<Survey_V2_Question_Option_Append_Input>;
  _delete_at_path?: InputMaybe<Survey_V2_Question_Option_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Survey_V2_Question_Option_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Survey_V2_Question_Option_Delete_Key_Input>;
  _inc?: InputMaybe<Survey_V2_Question_Option_Inc_Input>;
  _prepend?: InputMaybe<Survey_V2_Question_Option_Prepend_Input>;
  _set?: InputMaybe<Survey_V2_Question_Option_Set_Input>;
  where: Survey_V2_Question_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Question_Option_Additional_Info_ConfigArgs = {
  _set?: InputMaybe<Survey_V2_Question_Option_Additional_Info_Config_Set_Input>;
  where: Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Question_Option_Additional_Info_Config_By_PkArgs = {
  _set?: InputMaybe<Survey_V2_Question_Option_Additional_Info_Config_Set_Input>;
  pk_columns: Survey_V2_Question_Option_Additional_Info_Config_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Question_Option_Additional_Info_Config_ManyArgs = {
  updates: Array<Survey_V2_Question_Option_Additional_Info_Config_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Question_Option_By_PkArgs = {
  _append?: InputMaybe<Survey_V2_Question_Option_Append_Input>;
  _delete_at_path?: InputMaybe<Survey_V2_Question_Option_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Survey_V2_Question_Option_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Survey_V2_Question_Option_Delete_Key_Input>;
  _inc?: InputMaybe<Survey_V2_Question_Option_Inc_Input>;
  _prepend?: InputMaybe<Survey_V2_Question_Option_Prepend_Input>;
  _set?: InputMaybe<Survey_V2_Question_Option_Set_Input>;
  pk_columns: Survey_V2_Question_Option_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Question_Option_ManyArgs = {
  updates: Array<Survey_V2_Question_Option_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_ResponseArgs = {
  _set?: InputMaybe<Survey_V2_Response_Set_Input>;
  where: Survey_V2_Response_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Response_AnswerArgs = {
  _set?: InputMaybe<Survey_V2_Response_Answer_Set_Input>;
  where: Survey_V2_Response_Answer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Response_Answer_By_PkArgs = {
  _set?: InputMaybe<Survey_V2_Response_Answer_Set_Input>;
  pk_columns: Survey_V2_Response_Answer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Response_Answer_ManyArgs = {
  updates: Array<Survey_V2_Response_Answer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Response_Answer_OptionArgs = {
  _set?: InputMaybe<Survey_V2_Response_Answer_Option_Set_Input>;
  where: Survey_V2_Response_Answer_Option_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Response_Answer_Option_By_PkArgs = {
  _set?: InputMaybe<Survey_V2_Response_Answer_Option_Set_Input>;
  pk_columns: Survey_V2_Response_Answer_Option_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Response_Answer_Option_ManyArgs = {
  updates: Array<Survey_V2_Response_Answer_Option_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Response_By_PkArgs = {
  _set?: InputMaybe<Survey_V2_Response_Set_Input>;
  pk_columns: Survey_V2_Response_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Survey_V2_Response_ManyArgs = {
  updates: Array<Survey_V2_Response_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_EventArgs = {
  _append?: InputMaybe<Task_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Task_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Task_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Task_Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Task_Event_Prepend_Input>;
  _set?: InputMaybe<Task_Event_Set_Input>;
  where: Task_Event_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Event_By_PkArgs = {
  _append?: InputMaybe<Task_Event_Append_Input>;
  _delete_at_path?: InputMaybe<Task_Event_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Task_Event_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Task_Event_Delete_Key_Input>;
  _prepend?: InputMaybe<Task_Event_Prepend_Input>;
  _set?: InputMaybe<Task_Event_Set_Input>;
  pk_columns: Task_Event_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Event_ManyArgs = {
  updates: Array<Task_Event_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TasksArgs = {
  _append?: InputMaybe<Tasks_Append_Input>;
  _delete_at_path?: InputMaybe<Tasks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tasks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tasks_Delete_Key_Input>;
  _inc?: InputMaybe<Tasks_Inc_Input>;
  _prepend?: InputMaybe<Tasks_Prepend_Input>;
  _set?: InputMaybe<Tasks_Set_Input>;
  where: Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_By_PkArgs = {
  _append?: InputMaybe<Tasks_Append_Input>;
  _delete_at_path?: InputMaybe<Tasks_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Tasks_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Tasks_Delete_Key_Input>;
  _inc?: InputMaybe<Tasks_Inc_Input>;
  _prepend?: InputMaybe<Tasks_Prepend_Input>;
  _set?: InputMaybe<Tasks_Set_Input>;
  pk_columns: Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_ManyArgs = {
  updates: Array<Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TenantArgs = {
  _inc?: InputMaybe<Tenant_Inc_Input>;
  _set?: InputMaybe<Tenant_Set_Input>;
  where: Tenant_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Active_Status_ReasonArgs = {
  _set?: InputMaybe<Tenant_Active_Status_Reason_Set_Input>;
  where: Tenant_Active_Status_Reason_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Active_Status_Reason_By_PkArgs = {
  _set?: InputMaybe<Tenant_Active_Status_Reason_Set_Input>;
  pk_columns: Tenant_Active_Status_Reason_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Active_Status_Reason_ManyArgs = {
  updates: Array<Tenant_Active_Status_Reason_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_By_PkArgs = {
  _inc?: InputMaybe<Tenant_Inc_Input>;
  _set?: InputMaybe<Tenant_Set_Input>;
  pk_columns: Tenant_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_GroupArgs = {
  _inc?: InputMaybe<Tenant_Group_Inc_Input>;
  _set?: InputMaybe<Tenant_Group_Set_Input>;
  where: Tenant_Group_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Group_By_PkArgs = {
  _inc?: InputMaybe<Tenant_Group_Inc_Input>;
  _set?: InputMaybe<Tenant_Group_Set_Input>;
  pk_columns: Tenant_Group_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Group_ManyArgs = {
  updates: Array<Tenant_Group_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Group_MemberArgs = {
  _inc?: InputMaybe<Tenant_Group_Member_Inc_Input>;
  _set?: InputMaybe<Tenant_Group_Member_Set_Input>;
  where: Tenant_Group_Member_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Group_Member_By_PkArgs = {
  _inc?: InputMaybe<Tenant_Group_Member_Inc_Input>;
  _set?: InputMaybe<Tenant_Group_Member_Set_Input>;
  pk_columns: Tenant_Group_Member_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Group_Member_ManyArgs = {
  updates: Array<Tenant_Group_Member_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_ManyArgs = {
  updates: Array<Tenant_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Public_StatusArgs = {
  _set?: InputMaybe<Tenant_Public_Status_Set_Input>;
  where: Tenant_Public_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Public_Status_By_PkArgs = {
  _set?: InputMaybe<Tenant_Public_Status_Set_Input>;
  pk_columns: Tenant_Public_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Public_Status_ManyArgs = {
  updates: Array<Tenant_Public_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_StatusArgs = {
  _set?: InputMaybe<Tenant_Status_Set_Input>;
  where: Tenant_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Status_By_PkArgs = {
  _set?: InputMaybe<Tenant_Status_Set_Input>;
  pk_columns: Tenant_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tenant_Status_ManyArgs = {
  updates: Array<Tenant_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Trial_LeadsArgs = {
  _append?: InputMaybe<Trial_Leads_Append_Input>;
  _delete_at_path?: InputMaybe<Trial_Leads_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Trial_Leads_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Trial_Leads_Delete_Key_Input>;
  _prepend?: InputMaybe<Trial_Leads_Prepend_Input>;
  _set?: InputMaybe<Trial_Leads_Set_Input>;
  where: Trial_Leads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Trial_Leads_By_PkArgs = {
  _append?: InputMaybe<Trial_Leads_Append_Input>;
  _delete_at_path?: InputMaybe<Trial_Leads_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Trial_Leads_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Trial_Leads_Delete_Key_Input>;
  _prepend?: InputMaybe<Trial_Leads_Prepend_Input>;
  _set?: InputMaybe<Trial_Leads_Set_Input>;
  pk_columns: Trial_Leads_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Trial_Leads_ManyArgs = {
  updates: Array<Trial_Leads_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Ua_Audit_LogsArgs = {
  _set?: InputMaybe<Ua_Audit_Logs_Set_Input>;
  where: Ua_Audit_Logs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Ua_Audit_Logs_By_PkArgs = {
  _set?: InputMaybe<Ua_Audit_Logs_Set_Input>;
  pk_columns: Ua_Audit_Logs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Ua_Audit_Logs_ManyArgs = {
  updates: Array<Ua_Audit_Logs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_ActivityArgs = {
  _append?: InputMaybe<User_Activity_Append_Input>;
  _delete_at_path?: InputMaybe<User_Activity_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Activity_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Activity_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Activity_Prepend_Input>;
  _set?: InputMaybe<User_Activity_Set_Input>;
  where: User_Activity_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Activity_By_PkArgs = {
  _append?: InputMaybe<User_Activity_Append_Input>;
  _delete_at_path?: InputMaybe<User_Activity_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Activity_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Activity_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Activity_Prepend_Input>;
  _set?: InputMaybe<User_Activity_Set_Input>;
  pk_columns: User_Activity_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Activity_ManyArgs = {
  updates: Array<User_Activity_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_CouponArgs = {
  _inc?: InputMaybe<User_Coupon_Inc_Input>;
  _set?: InputMaybe<User_Coupon_Set_Input>;
  where: User_Coupon_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Coupon_By_PkArgs = {
  _inc?: InputMaybe<User_Coupon_Inc_Input>;
  _set?: InputMaybe<User_Coupon_Set_Input>;
  pk_columns: User_Coupon_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Coupon_ManyArgs = {
  updates: Array<User_Coupon_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Entitlement_AccessArgs = {
  _set?: InputMaybe<User_Entitlement_Access_Set_Input>;
  where: User_Entitlement_Access_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Entitlement_Access_By_PkArgs = {
  _set?: InputMaybe<User_Entitlement_Access_Set_Input>;
  pk_columns: User_Entitlement_Access_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Entitlement_Access_ManyArgs = {
  updates: Array<User_Entitlement_Access_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Entitlement_CatalogueArgs = {
  _inc?: InputMaybe<User_Entitlement_Catalogue_Inc_Input>;
  _set?: InputMaybe<User_Entitlement_Catalogue_Set_Input>;
  where: User_Entitlement_Catalogue_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Entitlement_Catalogue_By_PkArgs = {
  _inc?: InputMaybe<User_Entitlement_Catalogue_Inc_Input>;
  _set?: InputMaybe<User_Entitlement_Catalogue_Set_Input>;
  pk_columns: User_Entitlement_Catalogue_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Entitlement_Catalogue_ManyArgs = {
  updates: Array<User_Entitlement_Catalogue_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Entitlement_TypesArgs = {
  _set?: InputMaybe<User_Entitlement_Types_Set_Input>;
  where: User_Entitlement_Types_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Entitlement_Types_By_PkArgs = {
  _set?: InputMaybe<User_Entitlement_Types_Set_Input>;
  pk_columns: User_Entitlement_Types_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Entitlement_Types_ManyArgs = {
  updates: Array<User_Entitlement_Types_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_OnboardingArgs = {
  _append?: InputMaybe<User_Onboarding_Append_Input>;
  _delete_at_path?: InputMaybe<User_Onboarding_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Onboarding_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Onboarding_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Onboarding_Prepend_Input>;
  _set?: InputMaybe<User_Onboarding_Set_Input>;
  where: User_Onboarding_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_By_PkArgs = {
  _append?: InputMaybe<User_Onboarding_Append_Input>;
  _delete_at_path?: InputMaybe<User_Onboarding_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<User_Onboarding_Delete_Elem_Input>;
  _delete_key?: InputMaybe<User_Onboarding_Delete_Key_Input>;
  _prepend?: InputMaybe<User_Onboarding_Prepend_Input>;
  _set?: InputMaybe<User_Onboarding_Set_Input>;
  pk_columns: User_Onboarding_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Onboarding_ManyArgs = {
  updates: Array<User_Onboarding_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Personal_Access_TokensArgs = {
  _set?: InputMaybe<User_Personal_Access_Tokens_Set_Input>;
  where: User_Personal_Access_Tokens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Personal_Access_Tokens_By_PkArgs = {
  _set?: InputMaybe<User_Personal_Access_Tokens_Set_Input>;
  pk_columns: User_Personal_Access_Tokens_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Personal_Access_Tokens_ManyArgs = {
  updates: Array<User_Personal_Access_Tokens_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_ProfileArgs = {
  _set?: InputMaybe<User_Profile_Set_Input>;
  where: User_Profile_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Profile_By_PkArgs = {
  _set?: InputMaybe<User_Profile_Set_Input>;
  pk_columns: User_Profile_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Profile_ManyArgs = {
  updates: Array<User_Profile_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_RolesArgs = {
  _set?: InputMaybe<User_Roles_Set_Input>;
  where: User_Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Roles_By_PkArgs = {
  _set?: InputMaybe<User_Roles_Set_Input>;
  pk_columns: User_Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Roles_ManyArgs = {
  updates: Array<User_Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_Vpc_PolicyArgs = {
  _inc?: InputMaybe<User_Vpc_Policy_Inc_Input>;
  _set?: InputMaybe<User_Vpc_Policy_Set_Input>;
  where: User_Vpc_Policy_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Vpc_Policy_By_PkArgs = {
  _inc?: InputMaybe<User_Vpc_Policy_Inc_Input>;
  _set?: InputMaybe<User_Vpc_Policy_Set_Input>;
  pk_columns: User_Vpc_Policy_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Vpc_Policy_ManyArgs = {
  updates: Array<User_Vpc_Policy_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Users_PublicArgs = {
  _set?: InputMaybe<Users_Public_Set_Input>;
  where: Users_Public_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Users_Public_ManyArgs = {
  updates: Array<Users_Public_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vercel_IntegrationArgs = {
  _append?: InputMaybe<Vercel_Integration_Append_Input>;
  _delete_at_path?: InputMaybe<Vercel_Integration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vercel_Integration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vercel_Integration_Delete_Key_Input>;
  _prepend?: InputMaybe<Vercel_Integration_Prepend_Input>;
  _set?: InputMaybe<Vercel_Integration_Set_Input>;
  where: Vercel_Integration_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vercel_Integration_By_PkArgs = {
  _append?: InputMaybe<Vercel_Integration_Append_Input>;
  _delete_at_path?: InputMaybe<Vercel_Integration_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vercel_Integration_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vercel_Integration_Delete_Key_Input>;
  _prepend?: InputMaybe<Vercel_Integration_Prepend_Input>;
  _set?: InputMaybe<Vercel_Integration_Set_Input>;
  pk_columns: Vercel_Integration_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vercel_Integration_ConnectionsArgs = {
  _set?: InputMaybe<Vercel_Integration_Connections_Set_Input>;
  where: Vercel_Integration_Connections_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vercel_Integration_Connections_By_PkArgs = {
  _set?: InputMaybe<Vercel_Integration_Connections_Set_Input>;
  pk_columns: Vercel_Integration_Connections_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vercel_Integration_Connections_ManyArgs = {
  updates: Array<Vercel_Integration_Connections_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vercel_Integration_ManyArgs = {
  updates: Array<Vercel_Integration_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Collaborator_InvitationsArgs = {
  _set?: InputMaybe<Vpc_Collaborator_Invitations_Set_Input>;
  where: Vpc_Collaborator_Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Collaborator_Invitations_By_PkArgs = {
  _set?: InputMaybe<Vpc_Collaborator_Invitations_Set_Input>;
  pk_columns: Vpc_Collaborator_Invitations_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Collaborator_Invitations_ManyArgs = {
  updates: Array<Vpc_Collaborator_Invitations_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_CollaboratorsArgs = {
  _set?: InputMaybe<Vpc_Collaborators_Set_Input>;
  where: Vpc_Collaborators_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Collaborators_By_PkArgs = {
  _set?: InputMaybe<Vpc_Collaborators_Set_Input>;
  pk_columns: Vpc_Collaborators_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Collaborators_ManyArgs = {
  updates: Array<Vpc_Collaborators_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_PeeringArgs = {
  _append?: InputMaybe<Vpc_Peering_Append_Input>;
  _delete_at_path?: InputMaybe<Vpc_Peering_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vpc_Peering_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vpc_Peering_Delete_Key_Input>;
  _prepend?: InputMaybe<Vpc_Peering_Prepend_Input>;
  _set?: InputMaybe<Vpc_Peering_Set_Input>;
  where: Vpc_Peering_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Peering_By_PkArgs = {
  _append?: InputMaybe<Vpc_Peering_Append_Input>;
  _delete_at_path?: InputMaybe<Vpc_Peering_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Vpc_Peering_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Vpc_Peering_Delete_Key_Input>;
  _prepend?: InputMaybe<Vpc_Peering_Prepend_Input>;
  _set?: InputMaybe<Vpc_Peering_Set_Input>;
  pk_columns: Vpc_Peering_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Peering_DirectionArgs = {
  _set?: InputMaybe<Vpc_Peering_Direction_Set_Input>;
  where: Vpc_Peering_Direction_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Peering_Direction_By_PkArgs = {
  _set?: InputMaybe<Vpc_Peering_Direction_Set_Input>;
  pk_columns: Vpc_Peering_Direction_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Peering_Direction_ManyArgs = {
  updates: Array<Vpc_Peering_Direction_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Peering_ManyArgs = {
  updates: Array<Vpc_Peering_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_PrivilegesArgs = {
  _set?: InputMaybe<Vpc_Privileges_Set_Input>;
  where: Vpc_Privileges_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Privileges_By_PkArgs = {
  _set?: InputMaybe<Vpc_Privileges_Set_Input>;
  pk_columns: Vpc_Privileges_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Privileges_ManyArgs = {
  updates: Array<Vpc_Privileges_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_StatusArgs = {
  _set?: InputMaybe<Vpc_Status_Set_Input>;
  where: Vpc_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Status_By_PkArgs = {
  _set?: InputMaybe<Vpc_Status_Set_Input>;
  pk_columns: Vpc_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vpc_Status_ManyArgs = {
  updates: Array<Vpc_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Zendesk_Support_CategoryArgs = {
  _inc?: InputMaybe<Zendesk_Support_Category_Inc_Input>;
  _set?: InputMaybe<Zendesk_Support_Category_Set_Input>;
  where: Zendesk_Support_Category_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Zendesk_Support_Category_By_PkArgs = {
  _inc?: InputMaybe<Zendesk_Support_Category_Inc_Input>;
  _set?: InputMaybe<Zendesk_Support_Category_Set_Input>;
  pk_columns: Zendesk_Support_Category_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Zendesk_Support_Category_ManyArgs = {
  updates: Array<Zendesk_Support_Category_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Zendesk_Support_TicketsArgs = {
  _inc?: InputMaybe<Zendesk_Support_Tickets_Inc_Input>;
  _set?: InputMaybe<Zendesk_Support_Tickets_Set_Input>;
  where: Zendesk_Support_Tickets_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Zendesk_Support_Tickets_By_PkArgs = {
  _inc?: InputMaybe<Zendesk_Support_Tickets_Inc_Input>;
  _set?: InputMaybe<Zendesk_Support_Tickets_Set_Input>;
  pk_columns: Zendesk_Support_Tickets_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Zendesk_Support_Tickets_ManyArgs = {
  updates: Array<Zendesk_Support_Tickets_Updates>;
};

/** mutation root */
export type Mutation_RootVercelSetIntegrationArgs = {
  payload: SetVercelIntegrationInput;
};

/** mutation root */
export type Mutation_RootVercelTokenExchangeArgs = {
  payload?: InputMaybe<VercelTokenInputPayload>;
};

/** Stores integration state for all neon DB integrations */
export type Neon_Db_Integration = {
  __typename?: 'neon_db_integration';
  created_at: Scalars['timestamptz'];
  env_var?: Maybe<Scalars['String']>;
  hasura_cloud_project_id: Scalars['uuid'];
  id: Scalars['uuid'];
  neon_branch_id?: Maybe<Scalars['String']>;
  neon_database_id?: Maybe<Scalars['String']>;
  neon_integration_version: Scalars['String'];
  neon_project_id: Scalars['String'];
  /** An object relationship */
  project: Projects;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "neon_db_integration" */
export type Neon_Db_Integration_Aggregate = {
  __typename?: 'neon_db_integration_aggregate';
  aggregate?: Maybe<Neon_Db_Integration_Aggregate_Fields>;
  nodes: Array<Neon_Db_Integration>;
};

/** aggregate fields of "neon_db_integration" */
export type Neon_Db_Integration_Aggregate_Fields = {
  __typename?: 'neon_db_integration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Neon_Db_Integration_Max_Fields>;
  min?: Maybe<Neon_Db_Integration_Min_Fields>;
};

/** aggregate fields of "neon_db_integration" */
export type Neon_Db_Integration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Neon_Db_Integration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "neon_db_integration". All fields are combined with a logical 'AND'. */
export type Neon_Db_Integration_Bool_Exp = {
  _and?: InputMaybe<Array<Neon_Db_Integration_Bool_Exp>>;
  _not?: InputMaybe<Neon_Db_Integration_Bool_Exp>;
  _or?: InputMaybe<Array<Neon_Db_Integration_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  env_var?: InputMaybe<String_Comparison_Exp>;
  hasura_cloud_project_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  neon_branch_id?: InputMaybe<String_Comparison_Exp>;
  neon_database_id?: InputMaybe<String_Comparison_Exp>;
  neon_integration_version?: InputMaybe<String_Comparison_Exp>;
  neon_project_id?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "neon_db_integration" */
export enum Neon_Db_Integration_Constraint {
  /** unique or primary key constraint on columns "id" */
  NeonDbIntegrationPkey = 'neon_db_integration_pkey',
}

/** input type for inserting data into table "neon_db_integration" */
export type Neon_Db_Integration_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  env_var?: InputMaybe<Scalars['String']>;
  hasura_cloud_project_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  neon_branch_id?: InputMaybe<Scalars['String']>;
  neon_database_id?: InputMaybe<Scalars['String']>;
  neon_integration_version?: InputMaybe<Scalars['String']>;
  neon_project_id?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Neon_Db_Integration_Max_Fields = {
  __typename?: 'neon_db_integration_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  env_var?: Maybe<Scalars['String']>;
  hasura_cloud_project_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  neon_branch_id?: Maybe<Scalars['String']>;
  neon_database_id?: Maybe<Scalars['String']>;
  neon_integration_version?: Maybe<Scalars['String']>;
  neon_project_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Neon_Db_Integration_Min_Fields = {
  __typename?: 'neon_db_integration_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  env_var?: Maybe<Scalars['String']>;
  hasura_cloud_project_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  neon_branch_id?: Maybe<Scalars['String']>;
  neon_database_id?: Maybe<Scalars['String']>;
  neon_integration_version?: Maybe<Scalars['String']>;
  neon_project_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "neon_db_integration" */
export type Neon_Db_Integration_Mutation_Response = {
  __typename?: 'neon_db_integration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Neon_Db_Integration>;
};

/** on_conflict condition type for table "neon_db_integration" */
export type Neon_Db_Integration_On_Conflict = {
  constraint: Neon_Db_Integration_Constraint;
  update_columns?: Array<Neon_Db_Integration_Update_Column>;
  where?: InputMaybe<Neon_Db_Integration_Bool_Exp>;
};

/** Ordering options when selecting data from "neon_db_integration". */
export type Neon_Db_Integration_Order_By = {
  created_at?: InputMaybe<Order_By>;
  env_var?: InputMaybe<Order_By>;
  hasura_cloud_project_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  neon_branch_id?: InputMaybe<Order_By>;
  neon_database_id?: InputMaybe<Order_By>;
  neon_integration_version?: InputMaybe<Order_By>;
  neon_project_id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: neon_db_integration */
export type Neon_Db_Integration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "neon_db_integration" */
export enum Neon_Db_Integration_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvVar = 'env_var',
  /** column name */
  HasuraCloudProjectId = 'hasura_cloud_project_id',
  /** column name */
  Id = 'id',
  /** column name */
  NeonBranchId = 'neon_branch_id',
  /** column name */
  NeonDatabaseId = 'neon_database_id',
  /** column name */
  NeonIntegrationVersion = 'neon_integration_version',
  /** column name */
  NeonProjectId = 'neon_project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "neon_db_integration" */
export type Neon_Db_Integration_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  env_var?: InputMaybe<Scalars['String']>;
  hasura_cloud_project_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  neon_branch_id?: InputMaybe<Scalars['String']>;
  neon_database_id?: InputMaybe<Scalars['String']>;
  neon_integration_version?: InputMaybe<Scalars['String']>;
  neon_project_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "neon_db_integration" */
export type Neon_Db_Integration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Neon_Db_Integration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Neon_Db_Integration_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  env_var?: InputMaybe<Scalars['String']>;
  hasura_cloud_project_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  neon_branch_id?: InputMaybe<Scalars['String']>;
  neon_database_id?: InputMaybe<Scalars['String']>;
  neon_integration_version?: InputMaybe<Scalars['String']>;
  neon_project_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "neon_db_integration" */
export enum Neon_Db_Integration_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvVar = 'env_var',
  /** column name */
  HasuraCloudProjectId = 'hasura_cloud_project_id',
  /** column name */
  Id = 'id',
  /** column name */
  NeonBranchId = 'neon_branch_id',
  /** column name */
  NeonDatabaseId = 'neon_database_id',
  /** column name */
  NeonIntegrationVersion = 'neon_integration_version',
  /** column name */
  NeonProjectId = 'neon_project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Neon_Db_Integration_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Neon_Db_Integration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Neon_Db_Integration_Bool_Exp;
};

/** columns and relationships of "newrelic_config" */
export type Newrelic_Config = {
  __typename?: 'newrelic_config';
  attributes: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  key: Scalars['String'];
  logs_last_logged: Scalars['timestamptz'];
  logs_response_code?: Maybe<Scalars['Int']>;
  metrics_last_logged: Scalars['timestamptz'];
  metrics_response_code?: Maybe<Scalars['Int']>;
  newrelic_region: Scalars['String'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** columns and relationships of "newrelic_config" */
export type Newrelic_ConfigAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "newrelic_config" */
export type Newrelic_Config_Aggregate = {
  __typename?: 'newrelic_config_aggregate';
  aggregate?: Maybe<Newrelic_Config_Aggregate_Fields>;
  nodes: Array<Newrelic_Config>;
};

/** aggregate fields of "newrelic_config" */
export type Newrelic_Config_Aggregate_Fields = {
  __typename?: 'newrelic_config_aggregate_fields';
  avg?: Maybe<Newrelic_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Newrelic_Config_Max_Fields>;
  min?: Maybe<Newrelic_Config_Min_Fields>;
  stddev?: Maybe<Newrelic_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Newrelic_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Newrelic_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Newrelic_Config_Sum_Fields>;
  var_pop?: Maybe<Newrelic_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Newrelic_Config_Var_Samp_Fields>;
  variance?: Maybe<Newrelic_Config_Variance_Fields>;
};

/** aggregate fields of "newrelic_config" */
export type Newrelic_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Newrelic_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Newrelic_Config_Append_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Newrelic_Config_Avg_Fields = {
  __typename?: 'newrelic_config_avg_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "newrelic_config". All fields are combined with a logical 'AND'. */
export type Newrelic_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Newrelic_Config_Bool_Exp>>;
  _not?: InputMaybe<Newrelic_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Newrelic_Config_Bool_Exp>>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  logs_last_logged?: InputMaybe<Timestamptz_Comparison_Exp>;
  logs_response_code?: InputMaybe<Int_Comparison_Exp>;
  metrics_last_logged?: InputMaybe<Timestamptz_Comparison_Exp>;
  metrics_response_code?: InputMaybe<Int_Comparison_Exp>;
  newrelic_region?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "newrelic_config" */
export enum Newrelic_Config_Constraint {
  /** unique or primary key constraint on columns "project_id" */
  NewrelicConfigPkey = 'newrelic_config_pkey',
  /** unique or primary key constraint on columns "project_id" */
  NewrelicConfigProjectIdKey = 'newrelic_config_project_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Newrelic_Config_Delete_At_Path_Input = {
  attributes?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Newrelic_Config_Delete_Elem_Input = {
  attributes?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Newrelic_Config_Delete_Key_Input = {
  attributes?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "newrelic_config" */
export type Newrelic_Config_Inc_Input = {
  logs_response_code?: InputMaybe<Scalars['Int']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "newrelic_config" */
export type Newrelic_Config_Insert_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  logs_last_logged?: InputMaybe<Scalars['timestamptz']>;
  logs_response_code?: InputMaybe<Scalars['Int']>;
  metrics_last_logged?: InputMaybe<Scalars['timestamptz']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  newrelic_region?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Newrelic_Config_Max_Fields = {
  __typename?: 'newrelic_config_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  logs_last_logged?: Maybe<Scalars['timestamptz']>;
  logs_response_code?: Maybe<Scalars['Int']>;
  metrics_last_logged?: Maybe<Scalars['timestamptz']>;
  metrics_response_code?: Maybe<Scalars['Int']>;
  newrelic_region?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Newrelic_Config_Min_Fields = {
  __typename?: 'newrelic_config_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  logs_last_logged?: Maybe<Scalars['timestamptz']>;
  logs_response_code?: Maybe<Scalars['Int']>;
  metrics_last_logged?: Maybe<Scalars['timestamptz']>;
  metrics_response_code?: Maybe<Scalars['Int']>;
  newrelic_region?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "newrelic_config" */
export type Newrelic_Config_Mutation_Response = {
  __typename?: 'newrelic_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Newrelic_Config>;
};

/** input type for inserting object relation for remote table "newrelic_config" */
export type Newrelic_Config_Obj_Rel_Insert_Input = {
  data: Newrelic_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Newrelic_Config_On_Conflict>;
};

/** on_conflict condition type for table "newrelic_config" */
export type Newrelic_Config_On_Conflict = {
  constraint: Newrelic_Config_Constraint;
  update_columns?: Array<Newrelic_Config_Update_Column>;
  where?: InputMaybe<Newrelic_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "newrelic_config". */
export type Newrelic_Config_Order_By = {
  attributes?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  logs_last_logged?: InputMaybe<Order_By>;
  logs_response_code?: InputMaybe<Order_By>;
  metrics_last_logged?: InputMaybe<Order_By>;
  metrics_response_code?: InputMaybe<Order_By>;
  newrelic_region?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: newrelic_config */
export type Newrelic_Config_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Newrelic_Config_Prepend_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "newrelic_config" */
export enum Newrelic_Config_Select_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  LogsLastLogged = 'logs_last_logged',
  /** column name */
  LogsResponseCode = 'logs_response_code',
  /** column name */
  MetricsLastLogged = 'metrics_last_logged',
  /** column name */
  MetricsResponseCode = 'metrics_response_code',
  /** column name */
  NewrelicRegion = 'newrelic_region',
  /** column name */
  ProjectId = 'project_id',
}

/** input type for updating data in table "newrelic_config" */
export type Newrelic_Config_Set_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  logs_last_logged?: InputMaybe<Scalars['timestamptz']>;
  logs_response_code?: InputMaybe<Scalars['Int']>;
  metrics_last_logged?: InputMaybe<Scalars['timestamptz']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  newrelic_region?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Newrelic_Config_Stddev_Fields = {
  __typename?: 'newrelic_config_stddev_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Newrelic_Config_Stddev_Pop_Fields = {
  __typename?: 'newrelic_config_stddev_pop_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Newrelic_Config_Stddev_Samp_Fields = {
  __typename?: 'newrelic_config_stddev_samp_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "newrelic_config" */
export type Newrelic_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Newrelic_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Newrelic_Config_Stream_Cursor_Value_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  logs_last_logged?: InputMaybe<Scalars['timestamptz']>;
  logs_response_code?: InputMaybe<Scalars['Int']>;
  metrics_last_logged?: InputMaybe<Scalars['timestamptz']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  newrelic_region?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Newrelic_Config_Sum_Fields = {
  __typename?: 'newrelic_config_sum_fields';
  logs_response_code?: Maybe<Scalars['Int']>;
  metrics_response_code?: Maybe<Scalars['Int']>;
};

/** update columns of table "newrelic_config" */
export enum Newrelic_Config_Update_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  LogsLastLogged = 'logs_last_logged',
  /** column name */
  LogsResponseCode = 'logs_response_code',
  /** column name */
  MetricsLastLogged = 'metrics_last_logged',
  /** column name */
  MetricsResponseCode = 'metrics_response_code',
  /** column name */
  NewrelicRegion = 'newrelic_region',
  /** column name */
  ProjectId = 'project_id',
}

export type Newrelic_Config_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Newrelic_Config_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Newrelic_Config_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Newrelic_Config_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Newrelic_Config_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Newrelic_Config_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Newrelic_Config_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Newrelic_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Newrelic_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Newrelic_Config_Var_Pop_Fields = {
  __typename?: 'newrelic_config_var_pop_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Newrelic_Config_Var_Samp_Fields = {
  __typename?: 'newrelic_config_var_samp_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Newrelic_Config_Variance_Fields = {
  __typename?: 'newrelic_config_variance_fields';
  logs_response_code?: Maybe<Scalars['Float']>;
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "node_pool_type" */
export type Node_Pool_Type = {
  __typename?: 'node_pool_type';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "node_pool_type" */
export type Node_Pool_Type_Aggregate = {
  __typename?: 'node_pool_type_aggregate';
  aggregate?: Maybe<Node_Pool_Type_Aggregate_Fields>;
  nodes: Array<Node_Pool_Type>;
};

/** aggregate fields of "node_pool_type" */
export type Node_Pool_Type_Aggregate_Fields = {
  __typename?: 'node_pool_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Node_Pool_Type_Max_Fields>;
  min?: Maybe<Node_Pool_Type_Min_Fields>;
};

/** aggregate fields of "node_pool_type" */
export type Node_Pool_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Node_Pool_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "node_pool_type". All fields are combined with a logical 'AND'. */
export type Node_Pool_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Node_Pool_Type_Bool_Exp>>;
  _not?: InputMaybe<Node_Pool_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Node_Pool_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "node_pool_type" */
export enum Node_Pool_Type_Constraint {
  /** unique or primary key constraint on columns "name" */
  NodePoolTypePkey = 'node_pool_type_pkey',
}

export enum Node_Pool_Type_Enum {
  /** Node pool for apps related resources */
  Apps = 'apps',
  /** Node pool for bee server */
  BeeServer = 'bee_server',
  /** Node pool for data connector related resources */
  DataConnector = 'data_connector',
  /** Node pool for gateway related resources */
  Gateway = 'gateway',
  /** Node pool for metrics related resources */
  Metrics = 'metrics',
  /** Node pool for monitoring related resources */
  Monitoring = 'monitoring',
  /** Node pool for multitenant related resources */
  Multitenant = 'multitenant',
  /** Node pool to host opentelemetry components */
  Opentelemetry = 'opentelemetry',
  /** Node pool for timescale related resources */
  Timescale = 'timescale',
  /** node pool for v3 gateway */
  V3Gateway = 'v3_gateway',
  /** Node pool for v3 server */
  V3Server = 'v3_server',
}

/** Boolean expression to compare columns of type "node_pool_type_enum". All fields are combined with logical 'AND'. */
export type Node_Pool_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Node_Pool_Type_Enum>;
  _in?: InputMaybe<Array<Node_Pool_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Node_Pool_Type_Enum>;
  _nin?: InputMaybe<Array<Node_Pool_Type_Enum>>;
};

/** input type for inserting data into table "node_pool_type" */
export type Node_Pool_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Node_Pool_Type_Max_Fields = {
  __typename?: 'node_pool_type_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Node_Pool_Type_Min_Fields = {
  __typename?: 'node_pool_type_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "node_pool_type" */
export type Node_Pool_Type_Mutation_Response = {
  __typename?: 'node_pool_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Node_Pool_Type>;
};

/** on_conflict condition type for table "node_pool_type" */
export type Node_Pool_Type_On_Conflict = {
  constraint: Node_Pool_Type_Constraint;
  update_columns?: Array<Node_Pool_Type_Update_Column>;
  where?: InputMaybe<Node_Pool_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "node_pool_type". */
export type Node_Pool_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: node_pool_type */
export type Node_Pool_Type_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "node_pool_type" */
export enum Node_Pool_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "node_pool_type" */
export type Node_Pool_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "node_pool_type" */
export type Node_Pool_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Node_Pool_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Node_Pool_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "node_pool_type" */
export enum Node_Pool_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type Node_Pool_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Node_Pool_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Node_Pool_Type_Bool_Exp;
};

/** columns and relationships of "node_pools" */
export type Node_Pools = {
  __typename?: 'node_pools';
  cloud: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  dedicated_vpc?: Maybe<Dedicated_Vpc>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  hasura_clusters: Array<Hasura_Cluster>;
  /** An aggregate relationship */
  hasura_clusters_aggregate: Hasura_Cluster_Aggregate;
  id: Scalars['uuid'];
  input_variables: Scalars['jsonb'];
  name: Scalars['String'];
  pool_type: Node_Pool_Type_Enum;
  region: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "node_pools" */
export type Node_PoolsHasura_ClustersArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Cluster_Order_By>>;
  where?: InputMaybe<Hasura_Cluster_Bool_Exp>;
};

/** columns and relationships of "node_pools" */
export type Node_PoolsHasura_Clusters_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Cluster_Order_By>>;
  where?: InputMaybe<Hasura_Cluster_Bool_Exp>;
};

/** columns and relationships of "node_pools" */
export type Node_PoolsInput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "node_pools" */
export type Node_Pools_Aggregate = {
  __typename?: 'node_pools_aggregate';
  aggregate?: Maybe<Node_Pools_Aggregate_Fields>;
  nodes: Array<Node_Pools>;
};

/** aggregate fields of "node_pools" */
export type Node_Pools_Aggregate_Fields = {
  __typename?: 'node_pools_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Node_Pools_Max_Fields>;
  min?: Maybe<Node_Pools_Min_Fields>;
};

/** aggregate fields of "node_pools" */
export type Node_Pools_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Node_Pools_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Node_Pools_Append_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "node_pools". All fields are combined with a logical 'AND'. */
export type Node_Pools_Bool_Exp = {
  _and?: InputMaybe<Array<Node_Pools_Bool_Exp>>;
  _not?: InputMaybe<Node_Pools_Bool_Exp>;
  _or?: InputMaybe<Array<Node_Pools_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
  dedicated_vpc_id?: InputMaybe<Uuid_Comparison_Exp>;
  hasura_clusters?: InputMaybe<Hasura_Cluster_Bool_Exp>;
  hasura_clusters_aggregate?: InputMaybe<Hasura_Cluster_Aggregate_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  input_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pool_type?: InputMaybe<Node_Pool_Type_Enum_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "node_pools" */
export enum Node_Pools_Constraint {
  /** unique or primary key constraint on columns "id" */
  NodePoolsPkey = 'node_pools_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Node_Pools_Delete_At_Path_Input = {
  input_variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Node_Pools_Delete_Elem_Input = {
  input_variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Node_Pools_Delete_Key_Input = {
  input_variables?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "node_pools" */
export type Node_Pools_Insert_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Obj_Rel_Insert_Input>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  hasura_clusters?: InputMaybe<Hasura_Cluster_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  pool_type?: InputMaybe<Node_Pool_Type_Enum>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Node_Pools_Max_Fields = {
  __typename?: 'node_pools_max_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Node_Pools_Min_Fields = {
  __typename?: 'node_pools_min_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "node_pools" */
export type Node_Pools_Mutation_Response = {
  __typename?: 'node_pools_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Node_Pools>;
};

/** input type for inserting object relation for remote table "node_pools" */
export type Node_Pools_Obj_Rel_Insert_Input = {
  data: Node_Pools_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Node_Pools_On_Conflict>;
};

/** on_conflict condition type for table "node_pools" */
export type Node_Pools_On_Conflict = {
  constraint: Node_Pools_Constraint;
  update_columns?: Array<Node_Pools_Update_Column>;
  where?: InputMaybe<Node_Pools_Bool_Exp>;
};

/** Ordering options when selecting data from "node_pools". */
export type Node_Pools_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  hasura_clusters_aggregate?: InputMaybe<Hasura_Cluster_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  input_variables?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pool_type?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: node_pools */
export type Node_Pools_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Node_Pools_Prepend_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "node_pools" */
export enum Node_Pools_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DedicatedVpcId = 'dedicated_vpc_id',
  /** column name */
  Id = 'id',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  Name = 'name',
  /** column name */
  PoolType = 'pool_type',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "node_pools" */
export type Node_Pools_Set_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  pool_type?: InputMaybe<Node_Pool_Type_Enum>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "node_pools" */
export type Node_Pools_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Node_Pools_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Node_Pools_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  pool_type?: InputMaybe<Node_Pool_Type_Enum>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "node_pools" */
export enum Node_Pools_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DedicatedVpcId = 'dedicated_vpc_id',
  /** column name */
  Id = 'id',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  Name = 'name',
  /** column name */
  PoolType = 'pool_type',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Node_Pools_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Node_Pools_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Node_Pools_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Node_Pools_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Node_Pools_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Node_Pools_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Node_Pools_Set_Input>;
  /** filter the rows which have to be updated */
  where: Node_Pools_Bool_Exp;
};

/** Notifications that can be triggered per user */
export type Notification = {
  __typename?: 'notification';
  content?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  end_date?: Maybe<Scalars['timestamptz']>;
  href?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  is_active: Scalars['Boolean'];
  read_at?: Maybe<Scalars['timestamptz']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  title: Scalars['String'];
  type: Scalars['String'];
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "notification" */
export type Notification_Aggregate = {
  __typename?: 'notification_aggregate';
  aggregate?: Maybe<Notification_Aggregate_Fields>;
  nodes: Array<Notification>;
};

/** aggregate fields of "notification" */
export type Notification_Aggregate_Fields = {
  __typename?: 'notification_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Max_Fields>;
  min?: Maybe<Notification_Min_Fields>;
};

/** aggregate fields of "notification" */
export type Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "notification". All fields are combined with a logical 'AND'. */
export type Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Bool_Exp>>;
  _not?: InputMaybe<Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Bool_Exp>>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  end_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  href?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  start_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification" */
export enum Notification_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationPkey = 'notification_pkey',
}

/** input type for inserting data into table "notification" */
export type Notification_Insert_Input = {
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  href?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  read_at?: InputMaybe<Scalars['timestamptz']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Notification_Max_Fields = {
  __typename?: 'notification_max_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Notification_Min_Fields = {
  __typename?: 'notification_min_fields';
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  end_date?: Maybe<Scalars['timestamptz']>;
  href?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  read_at?: Maybe<Scalars['timestamptz']>;
  start_date?: Maybe<Scalars['timestamptz']>;
  title?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "notification" */
export type Notification_Mutation_Response = {
  __typename?: 'notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification>;
};

/** on_conflict condition type for table "notification" */
export type Notification_On_Conflict = {
  constraint: Notification_Constraint;
  update_columns?: Array<Notification_Update_Column>;
  where?: InputMaybe<Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "notification". */
export type Notification_Order_By = {
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  end_date?: InputMaybe<Order_By>;
  href?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  start_date?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification */
export type Notification_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "notification" */
export enum Notification_Select_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Href = 'href',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "notification" */
export type Notification_Set_Input = {
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  href?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  read_at?: InputMaybe<Scalars['timestamptz']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "notification" */
export type Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Stream_Cursor_Value_Input = {
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  end_date?: InputMaybe<Scalars['timestamptz']>;
  href?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  read_at?: InputMaybe<Scalars['timestamptz']>;
  start_date?: InputMaybe<Scalars['timestamptz']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Hasura enum to capture type of notification */
export type Notification_Type = {
  __typename?: 'notification_type';
  description: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "notification_type" */
export type Notification_Type_Aggregate = {
  __typename?: 'notification_type_aggregate';
  aggregate?: Maybe<Notification_Type_Aggregate_Fields>;
  nodes: Array<Notification_Type>;
};

/** aggregate fields of "notification_type" */
export type Notification_Type_Aggregate_Fields = {
  __typename?: 'notification_type_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Type_Max_Fields>;
  min?: Maybe<Notification_Type_Min_Fields>;
};

/** aggregate fields of "notification_type" */
export type Notification_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "notification_type". All fields are combined with a logical 'AND'. */
export type Notification_Type_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Type_Bool_Exp>>;
  _not?: InputMaybe<Notification_Type_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Type_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_type" */
export enum Notification_Type_Constraint {
  /** unique or primary key constraint on columns "type" */
  NotificationTypePkey = 'notification_type_pkey',
}

/** input type for inserting data into table "notification_type" */
export type Notification_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Notification_Type_Max_Fields = {
  __typename?: 'notification_type_max_fields';
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notification_Type_Min_Fields = {
  __typename?: 'notification_type_min_fields';
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification_type" */
export type Notification_Type_Mutation_Response = {
  __typename?: 'notification_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Type>;
};

/** on_conflict condition type for table "notification_type" */
export type Notification_Type_On_Conflict = {
  constraint: Notification_Type_Constraint;
  update_columns?: Array<Notification_Type_Update_Column>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_type". */
export type Notification_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_type */
export type Notification_Type_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "notification_type" */
export enum Notification_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "notification_type" */
export type Notification_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "notification_type" */
export type Notification_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Type_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "notification_type" */
export enum Notification_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type',
}

export type Notification_Type_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Type_Bool_Exp;
};

/** update columns of table "notification" */
export enum Notification_Update_Column {
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Href = 'href',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Title = 'title',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
}

export type Notification_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notification_Bool_Exp;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** Sample DB cohort and its activity */
export type Onboarding_Sample_Db_Cohort = {
  __typename?: 'onboarding_sample_db_cohort';
  activity: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** Sample DB cohort and its activity */
export type Onboarding_Sample_Db_CohortActivityArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "onboarding_sample_db_cohort" */
export type Onboarding_Sample_Db_Cohort_Aggregate = {
  __typename?: 'onboarding_sample_db_cohort_aggregate';
  aggregate?: Maybe<Onboarding_Sample_Db_Cohort_Aggregate_Fields>;
  nodes: Array<Onboarding_Sample_Db_Cohort>;
};

/** aggregate fields of "onboarding_sample_db_cohort" */
export type Onboarding_Sample_Db_Cohort_Aggregate_Fields = {
  __typename?: 'onboarding_sample_db_cohort_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Onboarding_Sample_Db_Cohort_Max_Fields>;
  min?: Maybe<Onboarding_Sample_Db_Cohort_Min_Fields>;
};

/** aggregate fields of "onboarding_sample_db_cohort" */
export type Onboarding_Sample_Db_Cohort_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Onboarding_Sample_Db_Cohort_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Onboarding_Sample_Db_Cohort_Append_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "onboarding_sample_db_cohort". All fields are combined with a logical 'AND'. */
export type Onboarding_Sample_Db_Cohort_Bool_Exp = {
  _and?: InputMaybe<Array<Onboarding_Sample_Db_Cohort_Bool_Exp>>;
  _not?: InputMaybe<Onboarding_Sample_Db_Cohort_Bool_Exp>;
  _or?: InputMaybe<Array<Onboarding_Sample_Db_Cohort_Bool_Exp>>;
  activity?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "onboarding_sample_db_cohort" */
export enum Onboarding_Sample_Db_Cohort_Constraint {
  /** unique or primary key constraint on columns "user_id" */
  OnboardingSampleDbCohortPkey = 'onboarding_sample_db_cohort_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Onboarding_Sample_Db_Cohort_Delete_At_Path_Input = {
  activity?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Onboarding_Sample_Db_Cohort_Delete_Elem_Input = {
  activity?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Onboarding_Sample_Db_Cohort_Delete_Key_Input = {
  activity?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "onboarding_sample_db_cohort" */
export type Onboarding_Sample_Db_Cohort_Insert_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Onboarding_Sample_Db_Cohort_Max_Fields = {
  __typename?: 'onboarding_sample_db_cohort_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Onboarding_Sample_Db_Cohort_Min_Fields = {
  __typename?: 'onboarding_sample_db_cohort_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "onboarding_sample_db_cohort" */
export type Onboarding_Sample_Db_Cohort_Mutation_Response = {
  __typename?: 'onboarding_sample_db_cohort_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Onboarding_Sample_Db_Cohort>;
};

/** on_conflict condition type for table "onboarding_sample_db_cohort" */
export type Onboarding_Sample_Db_Cohort_On_Conflict = {
  constraint: Onboarding_Sample_Db_Cohort_Constraint;
  update_columns?: Array<Onboarding_Sample_Db_Cohort_Update_Column>;
  where?: InputMaybe<Onboarding_Sample_Db_Cohort_Bool_Exp>;
};

/** Ordering options when selecting data from "onboarding_sample_db_cohort". */
export type Onboarding_Sample_Db_Cohort_Order_By = {
  activity?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: onboarding_sample_db_cohort */
export type Onboarding_Sample_Db_Cohort_Pk_Columns_Input = {
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Onboarding_Sample_Db_Cohort_Prepend_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "onboarding_sample_db_cohort" */
export enum Onboarding_Sample_Db_Cohort_Select_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "onboarding_sample_db_cohort" */
export type Onboarding_Sample_Db_Cohort_Set_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "onboarding_sample_db_cohort" */
export type Onboarding_Sample_Db_Cohort_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Onboarding_Sample_Db_Cohort_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Onboarding_Sample_Db_Cohort_Stream_Cursor_Value_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "onboarding_sample_db_cohort" */
export enum Onboarding_Sample_Db_Cohort_Update_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type Onboarding_Sample_Db_Cohort_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Onboarding_Sample_Db_Cohort_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<
    Onboarding_Sample_Db_Cohort_Delete_At_Path_Input
  >;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Onboarding_Sample_Db_Cohort_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Onboarding_Sample_Db_Cohort_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Onboarding_Sample_Db_Cohort_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Onboarding_Sample_Db_Cohort_Set_Input>;
  /** filter the rows which have to be updated */
  where: Onboarding_Sample_Db_Cohort_Bool_Exp;
};

/** Configuration for onboarding sample DB experiment */
export type Onboarding_Sample_Db_Config = {
  __typename?: 'onboarding_sample_db_config';
  created_at: Scalars['timestamptz'];
  database_url: Scalars['String'];
  metadata: Scalars['jsonb'];
  rollout_percentage: Scalars['Int'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** Configuration for onboarding sample DB experiment */
export type Onboarding_Sample_Db_ConfigMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "onboarding_sample_db_config" */
export type Onboarding_Sample_Db_Config_Aggregate = {
  __typename?: 'onboarding_sample_db_config_aggregate';
  aggregate?: Maybe<Onboarding_Sample_Db_Config_Aggregate_Fields>;
  nodes: Array<Onboarding_Sample_Db_Config>;
};

/** aggregate fields of "onboarding_sample_db_config" */
export type Onboarding_Sample_Db_Config_Aggregate_Fields = {
  __typename?: 'onboarding_sample_db_config_aggregate_fields';
  avg?: Maybe<Onboarding_Sample_Db_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Onboarding_Sample_Db_Config_Max_Fields>;
  min?: Maybe<Onboarding_Sample_Db_Config_Min_Fields>;
  stddev?: Maybe<Onboarding_Sample_Db_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Onboarding_Sample_Db_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Onboarding_Sample_Db_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Onboarding_Sample_Db_Config_Sum_Fields>;
  var_pop?: Maybe<Onboarding_Sample_Db_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Onboarding_Sample_Db_Config_Var_Samp_Fields>;
  variance?: Maybe<Onboarding_Sample_Db_Config_Variance_Fields>;
};

/** aggregate fields of "onboarding_sample_db_config" */
export type Onboarding_Sample_Db_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Onboarding_Sample_Db_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Onboarding_Sample_Db_Config_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Onboarding_Sample_Db_Config_Avg_Fields = {
  __typename?: 'onboarding_sample_db_config_avg_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "onboarding_sample_db_config". All fields are combined with a logical 'AND'. */
export type Onboarding_Sample_Db_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Onboarding_Sample_Db_Config_Bool_Exp>>;
  _not?: InputMaybe<Onboarding_Sample_Db_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Onboarding_Sample_Db_Config_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  database_url?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  rollout_percentage?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "onboarding_sample_db_config" */
export enum Onboarding_Sample_Db_Config_Constraint {
  /** unique or primary key constraint on columns "created_at" */
  OnboardingSampleDbConfigPkey = 'onboarding_sample_db_config_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Onboarding_Sample_Db_Config_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Onboarding_Sample_Db_Config_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Onboarding_Sample_Db_Config_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "onboarding_sample_db_config" */
export type Onboarding_Sample_Db_Config_Inc_Input = {
  rollout_percentage?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "onboarding_sample_db_config" */
export type Onboarding_Sample_Db_Config_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  database_url?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  rollout_percentage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Onboarding_Sample_Db_Config_Max_Fields = {
  __typename?: 'onboarding_sample_db_config_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  database_url?: Maybe<Scalars['String']>;
  rollout_percentage?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Onboarding_Sample_Db_Config_Min_Fields = {
  __typename?: 'onboarding_sample_db_config_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  database_url?: Maybe<Scalars['String']>;
  rollout_percentage?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "onboarding_sample_db_config" */
export type Onboarding_Sample_Db_Config_Mutation_Response = {
  __typename?: 'onboarding_sample_db_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Onboarding_Sample_Db_Config>;
};

/** on_conflict condition type for table "onboarding_sample_db_config" */
export type Onboarding_Sample_Db_Config_On_Conflict = {
  constraint: Onboarding_Sample_Db_Config_Constraint;
  update_columns?: Array<Onboarding_Sample_Db_Config_Update_Column>;
  where?: InputMaybe<Onboarding_Sample_Db_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "onboarding_sample_db_config". */
export type Onboarding_Sample_Db_Config_Order_By = {
  created_at?: InputMaybe<Order_By>;
  database_url?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  rollout_percentage?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: onboarding_sample_db_config */
export type Onboarding_Sample_Db_Config_Pk_Columns_Input = {
  created_at: Scalars['timestamptz'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Onboarding_Sample_Db_Config_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "onboarding_sample_db_config" */
export enum Onboarding_Sample_Db_Config_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DatabaseUrl = 'database_url',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RolloutPercentage = 'rollout_percentage',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "onboarding_sample_db_config" */
export type Onboarding_Sample_Db_Config_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  database_url?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  rollout_percentage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Onboarding_Sample_Db_Config_Stddev_Fields = {
  __typename?: 'onboarding_sample_db_config_stddev_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Onboarding_Sample_Db_Config_Stddev_Pop_Fields = {
  __typename?: 'onboarding_sample_db_config_stddev_pop_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Onboarding_Sample_Db_Config_Stddev_Samp_Fields = {
  __typename?: 'onboarding_sample_db_config_stddev_samp_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "onboarding_sample_db_config" */
export type Onboarding_Sample_Db_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Onboarding_Sample_Db_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Onboarding_Sample_Db_Config_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  database_url?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  rollout_percentage?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Onboarding_Sample_Db_Config_Sum_Fields = {
  __typename?: 'onboarding_sample_db_config_sum_fields';
  rollout_percentage?: Maybe<Scalars['Int']>;
};

/** update columns of table "onboarding_sample_db_config" */
export enum Onboarding_Sample_Db_Config_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DatabaseUrl = 'database_url',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RolloutPercentage = 'rollout_percentage',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Onboarding_Sample_Db_Config_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Onboarding_Sample_Db_Config_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<
    Onboarding_Sample_Db_Config_Delete_At_Path_Input
  >;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Onboarding_Sample_Db_Config_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Onboarding_Sample_Db_Config_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Onboarding_Sample_Db_Config_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Onboarding_Sample_Db_Config_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Onboarding_Sample_Db_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Onboarding_Sample_Db_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Onboarding_Sample_Db_Config_Var_Pop_Fields = {
  __typename?: 'onboarding_sample_db_config_var_pop_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Onboarding_Sample_Db_Config_Var_Samp_Fields = {
  __typename?: 'onboarding_sample_db_config_var_samp_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Onboarding_Sample_Db_Config_Variance_Fields = {
  __typename?: 'onboarding_sample_db_config_variance_fields';
  rollout_percentage?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "one_click_deployment" */
export type One_Click_Deployment = {
  __typename?: 'one_click_deployment';
  additional_info: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  git_repository_branch?: Maybe<Scalars['String']>;
  git_repository_url: Scalars['String'];
  hasura_directory?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** An array relationship */
  one_click_deployment_state_logs: Array<One_Click_Deployment_State_Log>;
  /** An aggregate relationship */
  one_click_deployment_state_logs_aggregate: One_Click_Deployment_State_Log_Aggregate;
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  /** A computed field, executes function "one_click_deployment_state" */
  state?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "one_click_deployment" */
export type One_Click_DeploymentAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "one_click_deployment" */
export type One_Click_DeploymentOne_Click_Deployment_State_LogsArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_State_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_State_Log_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_State_Log_Bool_Exp>;
};

/** columns and relationships of "one_click_deployment" */
export type One_Click_DeploymentOne_Click_Deployment_State_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_State_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_State_Log_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_State_Log_Bool_Exp>;
};

/** aggregated selection of "one_click_deployment" */
export type One_Click_Deployment_Aggregate = {
  __typename?: 'one_click_deployment_aggregate';
  aggregate?: Maybe<One_Click_Deployment_Aggregate_Fields>;
  nodes: Array<One_Click_Deployment>;
};

/** aggregate fields of "one_click_deployment" */
export type One_Click_Deployment_Aggregate_Fields = {
  __typename?: 'one_click_deployment_aggregate_fields';
  avg?: Maybe<One_Click_Deployment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<One_Click_Deployment_Max_Fields>;
  min?: Maybe<One_Click_Deployment_Min_Fields>;
  stddev?: Maybe<One_Click_Deployment_Stddev_Fields>;
  stddev_pop?: Maybe<One_Click_Deployment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<One_Click_Deployment_Stddev_Samp_Fields>;
  sum?: Maybe<One_Click_Deployment_Sum_Fields>;
  var_pop?: Maybe<One_Click_Deployment_Var_Pop_Fields>;
  var_samp?: Maybe<One_Click_Deployment_Var_Samp_Fields>;
  variance?: Maybe<One_Click_Deployment_Variance_Fields>;
};

/** aggregate fields of "one_click_deployment" */
export type One_Click_Deployment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<One_Click_Deployment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type One_Click_Deployment_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type One_Click_Deployment_Avg_Fields = {
  __typename?: 'one_click_deployment_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "one_click_deployment". All fields are combined with a logical 'AND'. */
export type One_Click_Deployment_Bool_Exp = {
  _and?: InputMaybe<Array<One_Click_Deployment_Bool_Exp>>;
  _not?: InputMaybe<One_Click_Deployment_Bool_Exp>;
  _or?: InputMaybe<Array<One_Click_Deployment_Bool_Exp>>;
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  git_repository_branch?: InputMaybe<String_Comparison_Exp>;
  git_repository_url?: InputMaybe<String_Comparison_Exp>;
  hasura_directory?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  one_click_deployment_state_logs?: InputMaybe<
    One_Click_Deployment_State_Log_Bool_Exp
  >;
  one_click_deployment_state_logs_aggregate?: InputMaybe<
    One_Click_Deployment_State_Log_Aggregate_Bool_Exp
  >;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  state?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "one_click_deployment" */
export enum One_Click_Deployment_Constraint {
  /** unique or primary key constraint on columns "id" */
  OneClickDeploymentPkey = 'one_click_deployment_pkey',
  /** unique or primary key constraint on columns "project_id" */
  OneClickDeploymentProjectIdKey = 'one_click_deployment_project_id_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type One_Click_Deployment_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type One_Click_Deployment_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type One_Click_Deployment_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "one_click_deployment" */
export type One_Click_Deployment_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "one_click_deployment" */
export type One_Click_Deployment_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  git_repository_branch?: InputMaybe<Scalars['String']>;
  git_repository_url?: InputMaybe<Scalars['String']>;
  hasura_directory?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  one_click_deployment_state_logs?: InputMaybe<
    One_Click_Deployment_State_Log_Arr_Rel_Insert_Input
  >;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type One_Click_Deployment_Max_Fields = {
  __typename?: 'one_click_deployment_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  git_repository_branch?: Maybe<Scalars['String']>;
  git_repository_url?: Maybe<Scalars['String']>;
  hasura_directory?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "one_click_deployment_state" */
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type One_Click_Deployment_Min_Fields = {
  __typename?: 'one_click_deployment_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  git_repository_branch?: Maybe<Scalars['String']>;
  git_repository_url?: Maybe<Scalars['String']>;
  hasura_directory?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  /** A computed field, executes function "one_click_deployment_state" */
  state?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "one_click_deployment" */
export type One_Click_Deployment_Mutation_Response = {
  __typename?: 'one_click_deployment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<One_Click_Deployment>;
};

/** input type for inserting object relation for remote table "one_click_deployment" */
export type One_Click_Deployment_Obj_Rel_Insert_Input = {
  data: One_Click_Deployment_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<One_Click_Deployment_On_Conflict>;
};

/** on_conflict condition type for table "one_click_deployment" */
export type One_Click_Deployment_On_Conflict = {
  constraint: One_Click_Deployment_Constraint;
  update_columns?: Array<One_Click_Deployment_Update_Column>;
  where?: InputMaybe<One_Click_Deployment_Bool_Exp>;
};

/** Ordering options when selecting data from "one_click_deployment". */
export type One_Click_Deployment_Order_By = {
  additional_info?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  git_repository_branch?: InputMaybe<Order_By>;
  git_repository_url?: InputMaybe<Order_By>;
  hasura_directory?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  one_click_deployment_state_logs_aggregate?: InputMaybe<
    One_Click_Deployment_State_Log_Aggregate_Order_By
  >;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: one_click_deployment */
export type One_Click_Deployment_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type One_Click_Deployment_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
};

/** columns and relationships of "one_click_deployment_sample_apps" */
export type One_Click_Deployment_Sample_Apps = {
  __typename?: 'one_click_deployment_sample_apps';
  /** Description of the sample app */
  description?: Maybe<Scalars['String']>;
  /** Branch of the GitHub repository */
  git_repository_branch: Scalars['String'];
  /** URL to the GitHub repository along with the protocol */
  git_repository_url: Scalars['String'];
  /** Path of the Hasura project relative to the root of the repository */
  hasura_directory: Scalars['String'];
  /** Name of the sample app */
  name: Scalars['String'];
  /** Rank used for ordering these sample apps on the client. */
  rank: Scalars['Int'];
  /** Font awesome react-icons component name from https://react-icons.github.io/react-icons/icons?name=fa. Example: FaShoppingCart, FaMusic etc */
  react_icons_fa_component_name?: Maybe<Scalars['String']>;
};

/** aggregated selection of "one_click_deployment_sample_apps" */
export type One_Click_Deployment_Sample_Apps_Aggregate = {
  __typename?: 'one_click_deployment_sample_apps_aggregate';
  aggregate?: Maybe<One_Click_Deployment_Sample_Apps_Aggregate_Fields>;
  nodes: Array<One_Click_Deployment_Sample_Apps>;
};

/** aggregate fields of "one_click_deployment_sample_apps" */
export type One_Click_Deployment_Sample_Apps_Aggregate_Fields = {
  __typename?: 'one_click_deployment_sample_apps_aggregate_fields';
  avg?: Maybe<One_Click_Deployment_Sample_Apps_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<One_Click_Deployment_Sample_Apps_Max_Fields>;
  min?: Maybe<One_Click_Deployment_Sample_Apps_Min_Fields>;
  stddev?: Maybe<One_Click_Deployment_Sample_Apps_Stddev_Fields>;
  stddev_pop?: Maybe<One_Click_Deployment_Sample_Apps_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<One_Click_Deployment_Sample_Apps_Stddev_Samp_Fields>;
  sum?: Maybe<One_Click_Deployment_Sample_Apps_Sum_Fields>;
  var_pop?: Maybe<One_Click_Deployment_Sample_Apps_Var_Pop_Fields>;
  var_samp?: Maybe<One_Click_Deployment_Sample_Apps_Var_Samp_Fields>;
  variance?: Maybe<One_Click_Deployment_Sample_Apps_Variance_Fields>;
};

/** aggregate fields of "one_click_deployment_sample_apps" */
export type One_Click_Deployment_Sample_Apps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<One_Click_Deployment_Sample_Apps_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type One_Click_Deployment_Sample_Apps_Avg_Fields = {
  __typename?: 'one_click_deployment_sample_apps_avg_fields';
  /** Rank used for ordering these sample apps on the client. */
  rank?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "one_click_deployment_sample_apps". All fields are combined with a logical 'AND'. */
export type One_Click_Deployment_Sample_Apps_Bool_Exp = {
  _and?: InputMaybe<Array<One_Click_Deployment_Sample_Apps_Bool_Exp>>;
  _not?: InputMaybe<One_Click_Deployment_Sample_Apps_Bool_Exp>;
  _or?: InputMaybe<Array<One_Click_Deployment_Sample_Apps_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  git_repository_branch?: InputMaybe<String_Comparison_Exp>;
  git_repository_url?: InputMaybe<String_Comparison_Exp>;
  hasura_directory?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  rank?: InputMaybe<Int_Comparison_Exp>;
  react_icons_fa_component_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "one_click_deployment_sample_apps" */
export enum One_Click_Deployment_Sample_Apps_Constraint {
  /** unique or primary key constraint on columns "git_repository_branch", "git_repository_url", "hasura_directory" */
  OneClickDeploymentSampleAppsPkey = 'one_click_deployment_sample_apps_pkey',
}

/** input type for incrementing numeric columns in table "one_click_deployment_sample_apps" */
export type One_Click_Deployment_Sample_Apps_Inc_Input = {
  /** Rank used for ordering these sample apps on the client. */
  rank?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "one_click_deployment_sample_apps" */
export type One_Click_Deployment_Sample_Apps_Insert_Input = {
  /** Description of the sample app */
  description?: InputMaybe<Scalars['String']>;
  /** Branch of the GitHub repository */
  git_repository_branch?: InputMaybe<Scalars['String']>;
  /** URL to the GitHub repository along with the protocol */
  git_repository_url?: InputMaybe<Scalars['String']>;
  /** Path of the Hasura project relative to the root of the repository */
  hasura_directory?: InputMaybe<Scalars['String']>;
  /** Name of the sample app */
  name?: InputMaybe<Scalars['String']>;
  /** Rank used for ordering these sample apps on the client. */
  rank?: InputMaybe<Scalars['Int']>;
  /** Font awesome react-icons component name from https://react-icons.github.io/react-icons/icons?name=fa. Example: FaShoppingCart, FaMusic etc */
  react_icons_fa_component_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type One_Click_Deployment_Sample_Apps_Max_Fields = {
  __typename?: 'one_click_deployment_sample_apps_max_fields';
  /** Description of the sample app */
  description?: Maybe<Scalars['String']>;
  /** Branch of the GitHub repository */
  git_repository_branch?: Maybe<Scalars['String']>;
  /** URL to the GitHub repository along with the protocol */
  git_repository_url?: Maybe<Scalars['String']>;
  /** Path of the Hasura project relative to the root of the repository */
  hasura_directory?: Maybe<Scalars['String']>;
  /** Name of the sample app */
  name?: Maybe<Scalars['String']>;
  /** Rank used for ordering these sample apps on the client. */
  rank?: Maybe<Scalars['Int']>;
  /** Font awesome react-icons component name from https://react-icons.github.io/react-icons/icons?name=fa. Example: FaShoppingCart, FaMusic etc */
  react_icons_fa_component_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type One_Click_Deployment_Sample_Apps_Min_Fields = {
  __typename?: 'one_click_deployment_sample_apps_min_fields';
  /** Description of the sample app */
  description?: Maybe<Scalars['String']>;
  /** Branch of the GitHub repository */
  git_repository_branch?: Maybe<Scalars['String']>;
  /** URL to the GitHub repository along with the protocol */
  git_repository_url?: Maybe<Scalars['String']>;
  /** Path of the Hasura project relative to the root of the repository */
  hasura_directory?: Maybe<Scalars['String']>;
  /** Name of the sample app */
  name?: Maybe<Scalars['String']>;
  /** Rank used for ordering these sample apps on the client. */
  rank?: Maybe<Scalars['Int']>;
  /** Font awesome react-icons component name from https://react-icons.github.io/react-icons/icons?name=fa. Example: FaShoppingCart, FaMusic etc */
  react_icons_fa_component_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "one_click_deployment_sample_apps" */
export type One_Click_Deployment_Sample_Apps_Mutation_Response = {
  __typename?: 'one_click_deployment_sample_apps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<One_Click_Deployment_Sample_Apps>;
};

/** on_conflict condition type for table "one_click_deployment_sample_apps" */
export type One_Click_Deployment_Sample_Apps_On_Conflict = {
  constraint: One_Click_Deployment_Sample_Apps_Constraint;
  update_columns?: Array<One_Click_Deployment_Sample_Apps_Update_Column>;
  where?: InputMaybe<One_Click_Deployment_Sample_Apps_Bool_Exp>;
};

/** Ordering options when selecting data from "one_click_deployment_sample_apps". */
export type One_Click_Deployment_Sample_Apps_Order_By = {
  description?: InputMaybe<Order_By>;
  git_repository_branch?: InputMaybe<Order_By>;
  git_repository_url?: InputMaybe<Order_By>;
  hasura_directory?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  rank?: InputMaybe<Order_By>;
  react_icons_fa_component_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: one_click_deployment_sample_apps */
export type One_Click_Deployment_Sample_Apps_Pk_Columns_Input = {
  /** Branch of the GitHub repository */
  git_repository_branch: Scalars['String'];
  /** URL to the GitHub repository along with the protocol */
  git_repository_url: Scalars['String'];
  /** Path of the Hasura project relative to the root of the repository */
  hasura_directory: Scalars['String'];
};

/** select columns of table "one_click_deployment_sample_apps" */
export enum One_Click_Deployment_Sample_Apps_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  GitRepositoryBranch = 'git_repository_branch',
  /** column name */
  GitRepositoryUrl = 'git_repository_url',
  /** column name */
  HasuraDirectory = 'hasura_directory',
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
  /** column name */
  ReactIconsFaComponentName = 'react_icons_fa_component_name',
}

/** input type for updating data in table "one_click_deployment_sample_apps" */
export type One_Click_Deployment_Sample_Apps_Set_Input = {
  /** Description of the sample app */
  description?: InputMaybe<Scalars['String']>;
  /** Branch of the GitHub repository */
  git_repository_branch?: InputMaybe<Scalars['String']>;
  /** URL to the GitHub repository along with the protocol */
  git_repository_url?: InputMaybe<Scalars['String']>;
  /** Path of the Hasura project relative to the root of the repository */
  hasura_directory?: InputMaybe<Scalars['String']>;
  /** Name of the sample app */
  name?: InputMaybe<Scalars['String']>;
  /** Rank used for ordering these sample apps on the client. */
  rank?: InputMaybe<Scalars['Int']>;
  /** Font awesome react-icons component name from https://react-icons.github.io/react-icons/icons?name=fa. Example: FaShoppingCart, FaMusic etc */
  react_icons_fa_component_name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type One_Click_Deployment_Sample_Apps_Stddev_Fields = {
  __typename?: 'one_click_deployment_sample_apps_stddev_fields';
  /** Rank used for ordering these sample apps on the client. */
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type One_Click_Deployment_Sample_Apps_Stddev_Pop_Fields = {
  __typename?: 'one_click_deployment_sample_apps_stddev_pop_fields';
  /** Rank used for ordering these sample apps on the client. */
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type One_Click_Deployment_Sample_Apps_Stddev_Samp_Fields = {
  __typename?: 'one_click_deployment_sample_apps_stddev_samp_fields';
  /** Rank used for ordering these sample apps on the client. */
  rank?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "one_click_deployment_sample_apps" */
export type One_Click_Deployment_Sample_Apps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: One_Click_Deployment_Sample_Apps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type One_Click_Deployment_Sample_Apps_Stream_Cursor_Value_Input = {
  /** Description of the sample app */
  description?: InputMaybe<Scalars['String']>;
  /** Branch of the GitHub repository */
  git_repository_branch?: InputMaybe<Scalars['String']>;
  /** URL to the GitHub repository along with the protocol */
  git_repository_url?: InputMaybe<Scalars['String']>;
  /** Path of the Hasura project relative to the root of the repository */
  hasura_directory?: InputMaybe<Scalars['String']>;
  /** Name of the sample app */
  name?: InputMaybe<Scalars['String']>;
  /** Rank used for ordering these sample apps on the client. */
  rank?: InputMaybe<Scalars['Int']>;
  /** Font awesome react-icons component name from https://react-icons.github.io/react-icons/icons?name=fa. Example: FaShoppingCart, FaMusic etc */
  react_icons_fa_component_name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type One_Click_Deployment_Sample_Apps_Sum_Fields = {
  __typename?: 'one_click_deployment_sample_apps_sum_fields';
  /** Rank used for ordering these sample apps on the client. */
  rank?: Maybe<Scalars['Int']>;
};

/** update columns of table "one_click_deployment_sample_apps" */
export enum One_Click_Deployment_Sample_Apps_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  GitRepositoryBranch = 'git_repository_branch',
  /** column name */
  GitRepositoryUrl = 'git_repository_url',
  /** column name */
  HasuraDirectory = 'hasura_directory',
  /** column name */
  Name = 'name',
  /** column name */
  Rank = 'rank',
  /** column name */
  ReactIconsFaComponentName = 'react_icons_fa_component_name',
}

export type One_Click_Deployment_Sample_Apps_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<One_Click_Deployment_Sample_Apps_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<One_Click_Deployment_Sample_Apps_Set_Input>;
  /** filter the rows which have to be updated */
  where: One_Click_Deployment_Sample_Apps_Bool_Exp;
};

/** aggregate var_pop on columns */
export type One_Click_Deployment_Sample_Apps_Var_Pop_Fields = {
  __typename?: 'one_click_deployment_sample_apps_var_pop_fields';
  /** Rank used for ordering these sample apps on the client. */
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type One_Click_Deployment_Sample_Apps_Var_Samp_Fields = {
  __typename?: 'one_click_deployment_sample_apps_var_samp_fields';
  /** Rank used for ordering these sample apps on the client. */
  rank?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type One_Click_Deployment_Sample_Apps_Variance_Fields = {
  __typename?: 'one_click_deployment_sample_apps_variance_fields';
  /** Rank used for ordering these sample apps on the client. */
  rank?: Maybe<Scalars['Float']>;
};

/** select columns of table "one_click_deployment" */
export enum One_Click_Deployment_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GitRepositoryBranch = 'git_repository_branch',
  /** column name */
  GitRepositoryUrl = 'git_repository_url',
  /** column name */
  HasuraDirectory = 'hasura_directory',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "one_click_deployment" */
export type One_Click_Deployment_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  git_repository_branch?: InputMaybe<Scalars['String']>;
  git_repository_url?: InputMaybe<Scalars['String']>;
  hasura_directory?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log = {
  __typename?: 'one_click_deployment_state_log';
  additional_info: Scalars['jsonb'];
  created_at: Scalars['timestamptz'];
  execution_id?: Maybe<Scalars['uuid']>;
  from_state: One_Click_Deployment_States_Enum;
  id: Scalars['bigint'];
  /** An object relationship */
  one_click_deployment: One_Click_Deployment;
  one_click_deployment_id: Scalars['Int'];
  to_state: One_Click_Deployment_States_Enum;
};

/** columns and relationships of "one_click_deployment_state_log" */
export type One_Click_Deployment_State_LogAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Aggregate = {
  __typename?: 'one_click_deployment_state_log_aggregate';
  aggregate?: Maybe<One_Click_Deployment_State_Log_Aggregate_Fields>;
  nodes: Array<One_Click_Deployment_State_Log>;
};

export type One_Click_Deployment_State_Log_Aggregate_Bool_Exp = {
  count?: InputMaybe<One_Click_Deployment_State_Log_Aggregate_Bool_Exp_Count>;
};

export type One_Click_Deployment_State_Log_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<One_Click_Deployment_State_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<One_Click_Deployment_State_Log_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Aggregate_Fields = {
  __typename?: 'one_click_deployment_state_log_aggregate_fields';
  avg?: Maybe<One_Click_Deployment_State_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<One_Click_Deployment_State_Log_Max_Fields>;
  min?: Maybe<One_Click_Deployment_State_Log_Min_Fields>;
  stddev?: Maybe<One_Click_Deployment_State_Log_Stddev_Fields>;
  stddev_pop?: Maybe<One_Click_Deployment_State_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<One_Click_Deployment_State_Log_Stddev_Samp_Fields>;
  sum?: Maybe<One_Click_Deployment_State_Log_Sum_Fields>;
  var_pop?: Maybe<One_Click_Deployment_State_Log_Var_Pop_Fields>;
  var_samp?: Maybe<One_Click_Deployment_State_Log_Var_Samp_Fields>;
  variance?: Maybe<One_Click_Deployment_State_Log_Variance_Fields>;
};

/** aggregate fields of "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<One_Click_Deployment_State_Log_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Aggregate_Order_By = {
  avg?: InputMaybe<One_Click_Deployment_State_Log_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<One_Click_Deployment_State_Log_Max_Order_By>;
  min?: InputMaybe<One_Click_Deployment_State_Log_Min_Order_By>;
  stddev?: InputMaybe<One_Click_Deployment_State_Log_Stddev_Order_By>;
  stddev_pop?: InputMaybe<One_Click_Deployment_State_Log_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<One_Click_Deployment_State_Log_Stddev_Samp_Order_By>;
  sum?: InputMaybe<One_Click_Deployment_State_Log_Sum_Order_By>;
  var_pop?: InputMaybe<One_Click_Deployment_State_Log_Var_Pop_Order_By>;
  var_samp?: InputMaybe<One_Click_Deployment_State_Log_Var_Samp_Order_By>;
  variance?: InputMaybe<One_Click_Deployment_State_Log_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type One_Click_Deployment_State_Log_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Arr_Rel_Insert_Input = {
  data: Array<One_Click_Deployment_State_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<One_Click_Deployment_State_Log_On_Conflict>;
};

/** aggregate avg on columns */
export type One_Click_Deployment_State_Log_Avg_Fields = {
  __typename?: 'one_click_deployment_state_log_avg_fields';
  id?: Maybe<Scalars['Float']>;
  one_click_deployment_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  one_click_deployment_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "one_click_deployment_state_log". All fields are combined with a logical 'AND'. */
export type One_Click_Deployment_State_Log_Bool_Exp = {
  _and?: InputMaybe<Array<One_Click_Deployment_State_Log_Bool_Exp>>;
  _not?: InputMaybe<One_Click_Deployment_State_Log_Bool_Exp>;
  _or?: InputMaybe<Array<One_Click_Deployment_State_Log_Bool_Exp>>;
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  execution_id?: InputMaybe<Uuid_Comparison_Exp>;
  from_state?: InputMaybe<One_Click_Deployment_States_Enum_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  one_click_deployment?: InputMaybe<One_Click_Deployment_Bool_Exp>;
  one_click_deployment_id?: InputMaybe<Int_Comparison_Exp>;
  to_state?: InputMaybe<One_Click_Deployment_States_Enum_Comparison_Exp>;
};

/** unique or primary key constraints on table "one_click_deployment_state_log" */
export enum One_Click_Deployment_State_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  OneClickDeploymentStateLogPkey = 'one_click_deployment_state_log_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type One_Click_Deployment_State_Log_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type One_Click_Deployment_State_Log_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type One_Click_Deployment_State_Log_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  one_click_deployment_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  execution_id?: InputMaybe<Scalars['uuid']>;
  from_state?: InputMaybe<One_Click_Deployment_States_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  one_click_deployment?: InputMaybe<One_Click_Deployment_Obj_Rel_Insert_Input>;
  one_click_deployment_id?: InputMaybe<Scalars['Int']>;
  to_state?: InputMaybe<One_Click_Deployment_States_Enum>;
};

/** aggregate max on columns */
export type One_Click_Deployment_State_Log_Max_Fields = {
  __typename?: 'one_click_deployment_state_log_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  execution_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  one_click_deployment_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  execution_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  one_click_deployment_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type One_Click_Deployment_State_Log_Min_Fields = {
  __typename?: 'one_click_deployment_state_log_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  execution_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['bigint']>;
  one_click_deployment_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  execution_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  one_click_deployment_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Mutation_Response = {
  __typename?: 'one_click_deployment_state_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<One_Click_Deployment_State_Log>;
};

/** on_conflict condition type for table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_On_Conflict = {
  constraint: One_Click_Deployment_State_Log_Constraint;
  update_columns?: Array<One_Click_Deployment_State_Log_Update_Column>;
  where?: InputMaybe<One_Click_Deployment_State_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "one_click_deployment_state_log". */
export type One_Click_Deployment_State_Log_Order_By = {
  additional_info?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  execution_id?: InputMaybe<Order_By>;
  from_state?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  one_click_deployment?: InputMaybe<One_Click_Deployment_Order_By>;
  one_click_deployment_id?: InputMaybe<Order_By>;
  to_state?: InputMaybe<Order_By>;
};

/** primary key columns input for table: one_click_deployment_state_log */
export type One_Click_Deployment_State_Log_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type One_Click_Deployment_State_Log_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "one_click_deployment_state_log" */
export enum One_Click_Deployment_State_Log_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExecutionId = 'execution_id',
  /** column name */
  FromState = 'from_state',
  /** column name */
  Id = 'id',
  /** column name */
  OneClickDeploymentId = 'one_click_deployment_id',
  /** column name */
  ToState = 'to_state',
}

/** input type for updating data in table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  execution_id?: InputMaybe<Scalars['uuid']>;
  from_state?: InputMaybe<One_Click_Deployment_States_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  one_click_deployment_id?: InputMaybe<Scalars['Int']>;
  to_state?: InputMaybe<One_Click_Deployment_States_Enum>;
};

/** aggregate stddev on columns */
export type One_Click_Deployment_State_Log_Stddev_Fields = {
  __typename?: 'one_click_deployment_state_log_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  one_click_deployment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  one_click_deployment_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type One_Click_Deployment_State_Log_Stddev_Pop_Fields = {
  __typename?: 'one_click_deployment_state_log_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  one_click_deployment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  one_click_deployment_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type One_Click_Deployment_State_Log_Stddev_Samp_Fields = {
  __typename?: 'one_click_deployment_state_log_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  one_click_deployment_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  one_click_deployment_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: One_Click_Deployment_State_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type One_Click_Deployment_State_Log_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  execution_id?: InputMaybe<Scalars['uuid']>;
  from_state?: InputMaybe<One_Click_Deployment_States_Enum>;
  id?: InputMaybe<Scalars['bigint']>;
  one_click_deployment_id?: InputMaybe<Scalars['Int']>;
  to_state?: InputMaybe<One_Click_Deployment_States_Enum>;
};

/** aggregate sum on columns */
export type One_Click_Deployment_State_Log_Sum_Fields = {
  __typename?: 'one_click_deployment_state_log_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  one_click_deployment_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  one_click_deployment_id?: InputMaybe<Order_By>;
};

/** update columns of table "one_click_deployment_state_log" */
export enum One_Click_Deployment_State_Log_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExecutionId = 'execution_id',
  /** column name */
  FromState = 'from_state',
  /** column name */
  Id = 'id',
  /** column name */
  OneClickDeploymentId = 'one_click_deployment_id',
  /** column name */
  ToState = 'to_state',
}

export type One_Click_Deployment_State_Log_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<One_Click_Deployment_State_Log_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<
    One_Click_Deployment_State_Log_Delete_At_Path_Input
  >;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<One_Click_Deployment_State_Log_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<One_Click_Deployment_State_Log_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<One_Click_Deployment_State_Log_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<One_Click_Deployment_State_Log_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<One_Click_Deployment_State_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: One_Click_Deployment_State_Log_Bool_Exp;
};

/** aggregate var_pop on columns */
export type One_Click_Deployment_State_Log_Var_Pop_Fields = {
  __typename?: 'one_click_deployment_state_log_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  one_click_deployment_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  one_click_deployment_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type One_Click_Deployment_State_Log_Var_Samp_Fields = {
  __typename?: 'one_click_deployment_state_log_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  one_click_deployment_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  one_click_deployment_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type One_Click_Deployment_State_Log_Variance_Fields = {
  __typename?: 'one_click_deployment_state_log_variance_fields';
  id?: Maybe<Scalars['Float']>;
  one_click_deployment_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "one_click_deployment_state_log" */
export type One_Click_Deployment_State_Log_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  one_click_deployment_id?: InputMaybe<Order_By>;
};

/** This enum represents the different states one_click_deployment feature can be in. */
export type One_Click_Deployment_States = {
  __typename?: 'one_click_deployment_states';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "one_click_deployment_states" */
export type One_Click_Deployment_States_Aggregate = {
  __typename?: 'one_click_deployment_states_aggregate';
  aggregate?: Maybe<One_Click_Deployment_States_Aggregate_Fields>;
  nodes: Array<One_Click_Deployment_States>;
};

/** aggregate fields of "one_click_deployment_states" */
export type One_Click_Deployment_States_Aggregate_Fields = {
  __typename?: 'one_click_deployment_states_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<One_Click_Deployment_States_Max_Fields>;
  min?: Maybe<One_Click_Deployment_States_Min_Fields>;
};

/** aggregate fields of "one_click_deployment_states" */
export type One_Click_Deployment_States_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<One_Click_Deployment_States_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "one_click_deployment_states". All fields are combined with a logical 'AND'. */
export type One_Click_Deployment_States_Bool_Exp = {
  _and?: InputMaybe<Array<One_Click_Deployment_States_Bool_Exp>>;
  _not?: InputMaybe<One_Click_Deployment_States_Bool_Exp>;
  _or?: InputMaybe<Array<One_Click_Deployment_States_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "one_click_deployment_states" */
export enum One_Click_Deployment_States_Constraint {
  /** unique or primary key constraint on columns "name" */
  OneClickDeploymentStatesPkey = 'one_click_deployment_states_pkey',
}

export enum One_Click_Deployment_States_Enum {
  /** Applying metadata, migration and seed data to the project */
  ApplyingMetadataMigrationsSeeds = 'APPLYING_METADATA_MIGRATIONS_SEEDS',
  /** Waiting for environment variables from user to be available */
  AwaitingEnvironmentVariables = 'AWAITING_ENVIRONMENT_VARIABLES',
  /** Cloning git repository */
  CloningGitRepository = 'CLONING_GIT_REPOSITORY',
  /** One click deployment executed successfully */
  Completed = 'COMPLETED',
  /** Some error occurred while deploying the git repository to hasura cloud project */
  Error = 'ERROR',
  /** One click deployment initiated */
  Initialized = 'INITIALIZED',
  /** Checking if the required environment variables are present in the project */
  ReadingEnvironmentVariables = 'READING_ENVIRONMENT_VARIABLES',
  /** No environment variables required from the user */
  SufficientEnvironmentVariables = 'SUFFICIENT_ENVIRONMENT_VARIABLES',
}

/** Boolean expression to compare columns of type "one_click_deployment_states_enum". All fields are combined with logical 'AND'. */
export type One_Click_Deployment_States_Enum_Comparison_Exp = {
  _eq?: InputMaybe<One_Click_Deployment_States_Enum>;
  _in?: InputMaybe<Array<One_Click_Deployment_States_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<One_Click_Deployment_States_Enum>;
  _nin?: InputMaybe<Array<One_Click_Deployment_States_Enum>>;
};

/** input type for inserting data into table "one_click_deployment_states" */
export type One_Click_Deployment_States_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type One_Click_Deployment_States_Max_Fields = {
  __typename?: 'one_click_deployment_states_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type One_Click_Deployment_States_Min_Fields = {
  __typename?: 'one_click_deployment_states_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "one_click_deployment_states" */
export type One_Click_Deployment_States_Mutation_Response = {
  __typename?: 'one_click_deployment_states_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<One_Click_Deployment_States>;
};

/** on_conflict condition type for table "one_click_deployment_states" */
export type One_Click_Deployment_States_On_Conflict = {
  constraint: One_Click_Deployment_States_Constraint;
  update_columns?: Array<One_Click_Deployment_States_Update_Column>;
  where?: InputMaybe<One_Click_Deployment_States_Bool_Exp>;
};

/** Ordering options when selecting data from "one_click_deployment_states". */
export type One_Click_Deployment_States_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: one_click_deployment_states */
export type One_Click_Deployment_States_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "one_click_deployment_states" */
export enum One_Click_Deployment_States_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "one_click_deployment_states" */
export type One_Click_Deployment_States_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "one_click_deployment_states" */
export type One_Click_Deployment_States_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: One_Click_Deployment_States_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type One_Click_Deployment_States_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "one_click_deployment_states" */
export enum One_Click_Deployment_States_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type One_Click_Deployment_States_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<One_Click_Deployment_States_Set_Input>;
  /** filter the rows which have to be updated */
  where: One_Click_Deployment_States_Bool_Exp;
};

/** aggregate stddev on columns */
export type One_Click_Deployment_Stddev_Fields = {
  __typename?: 'one_click_deployment_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type One_Click_Deployment_Stddev_Pop_Fields = {
  __typename?: 'one_click_deployment_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type One_Click_Deployment_Stddev_Samp_Fields = {
  __typename?: 'one_click_deployment_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "one_click_deployment" */
export type One_Click_Deployment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: One_Click_Deployment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type One_Click_Deployment_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  git_repository_branch?: InputMaybe<Scalars['String']>;
  git_repository_url?: InputMaybe<Scalars['String']>;
  hasura_directory?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type One_Click_Deployment_Sum_Fields = {
  __typename?: 'one_click_deployment_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "one_click_deployment" */
export enum One_Click_Deployment_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GitRepositoryBranch = 'git_repository_branch',
  /** column name */
  GitRepositoryUrl = 'git_repository_url',
  /** column name */
  HasuraDirectory = 'hasura_directory',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type One_Click_Deployment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<One_Click_Deployment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<One_Click_Deployment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<One_Click_Deployment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<One_Click_Deployment_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<One_Click_Deployment_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<One_Click_Deployment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<One_Click_Deployment_Set_Input>;
  /** filter the rows which have to be updated */
  where: One_Click_Deployment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type One_Click_Deployment_Var_Pop_Fields = {
  __typename?: 'one_click_deployment_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type One_Click_Deployment_Var_Samp_Fields = {
  __typename?: 'one_click_deployment_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type One_Click_Deployment_Variance_Fields = {
  __typename?: 'one_click_deployment_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** OpenTelemetry Exporter Config */
export type Opentelemetry_Config = {
  __typename?: 'opentelemetry_config';
  attributes?: Maybe<Scalars['jsonb']>;
  batch_size?: Maybe<Scalars['Int']>;
  connection_type: Scalars['opentelemetry_connection_type_enum'];
  created_at: Scalars['timestamptz'];
  endpoint: Scalars['String'];
  export_traces: Scalars['Boolean'];
  headers?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  traces_last_exported?: Maybe<Scalars['timestamptz']>;
  traces_response_code?: Maybe<Scalars['Int']>;
  traces_response_message?: Maybe<Scalars['String']>;
};

/** OpenTelemetry Exporter Config */
export type Opentelemetry_ConfigAttributesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** OpenTelemetry Exporter Config */
export type Opentelemetry_ConfigHeadersArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "opentelemetry_config" */
export type Opentelemetry_Config_Aggregate = {
  __typename?: 'opentelemetry_config_aggregate';
  aggregate?: Maybe<Opentelemetry_Config_Aggregate_Fields>;
  nodes: Array<Opentelemetry_Config>;
};

/** aggregate fields of "opentelemetry_config" */
export type Opentelemetry_Config_Aggregate_Fields = {
  __typename?: 'opentelemetry_config_aggregate_fields';
  avg?: Maybe<Opentelemetry_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Opentelemetry_Config_Max_Fields>;
  min?: Maybe<Opentelemetry_Config_Min_Fields>;
  stddev?: Maybe<Opentelemetry_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Opentelemetry_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Opentelemetry_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Opentelemetry_Config_Sum_Fields>;
  var_pop?: Maybe<Opentelemetry_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Opentelemetry_Config_Var_Samp_Fields>;
  variance?: Maybe<Opentelemetry_Config_Variance_Fields>;
};

/** aggregate fields of "opentelemetry_config" */
export type Opentelemetry_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Opentelemetry_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Opentelemetry_Config_Append_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  headers?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Opentelemetry_Config_Avg_Fields = {
  __typename?: 'opentelemetry_config_avg_fields';
  batch_size?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "opentelemetry_config". All fields are combined with a logical 'AND'. */
export type Opentelemetry_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Opentelemetry_Config_Bool_Exp>>;
  _not?: InputMaybe<Opentelemetry_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Opentelemetry_Config_Bool_Exp>>;
  attributes?: InputMaybe<Jsonb_Comparison_Exp>;
  batch_size?: InputMaybe<Int_Comparison_Exp>;
  connection_type?: InputMaybe<
    Opentelemetry_Connection_Type_Enum_Comparison_Exp
  >;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  endpoint?: InputMaybe<String_Comparison_Exp>;
  export_traces?: InputMaybe<Boolean_Comparison_Exp>;
  headers?: InputMaybe<Jsonb_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  traces_last_exported?: InputMaybe<Timestamptz_Comparison_Exp>;
  traces_response_code?: InputMaybe<Int_Comparison_Exp>;
  traces_response_message?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "opentelemetry_config" */
export enum Opentelemetry_Config_Constraint {
  /** unique or primary key constraint on columns "project_id" */
  OpentelemetryConfigPkey = 'opentelemetry_config_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Opentelemetry_Config_Delete_At_Path_Input = {
  attributes?: InputMaybe<Array<Scalars['String']>>;
  headers?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Opentelemetry_Config_Delete_Elem_Input = {
  attributes?: InputMaybe<Scalars['Int']>;
  headers?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Opentelemetry_Config_Delete_Key_Input = {
  attributes?: InputMaybe<Scalars['String']>;
  headers?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "opentelemetry_config" */
export type Opentelemetry_Config_Inc_Input = {
  batch_size?: InputMaybe<Scalars['Int']>;
  traces_response_code?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "opentelemetry_config" */
export type Opentelemetry_Config_Insert_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  batch_size?: InputMaybe<Scalars['Int']>;
  connection_type?: InputMaybe<Scalars['opentelemetry_connection_type_enum']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endpoint?: InputMaybe<Scalars['String']>;
  export_traces?: InputMaybe<Scalars['Boolean']>;
  headers?: InputMaybe<Scalars['jsonb']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  traces_last_exported?: InputMaybe<Scalars['timestamptz']>;
  traces_response_code?: InputMaybe<Scalars['Int']>;
  traces_response_message?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Opentelemetry_Config_Max_Fields = {
  __typename?: 'opentelemetry_config_max_fields';
  batch_size?: Maybe<Scalars['Int']>;
  connection_type?: Maybe<Scalars['opentelemetry_connection_type_enum']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  endpoint?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  traces_last_exported?: Maybe<Scalars['timestamptz']>;
  traces_response_code?: Maybe<Scalars['Int']>;
  traces_response_message?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Opentelemetry_Config_Min_Fields = {
  __typename?: 'opentelemetry_config_min_fields';
  batch_size?: Maybe<Scalars['Int']>;
  connection_type?: Maybe<Scalars['opentelemetry_connection_type_enum']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  endpoint?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  traces_last_exported?: Maybe<Scalars['timestamptz']>;
  traces_response_code?: Maybe<Scalars['Int']>;
  traces_response_message?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "opentelemetry_config" */
export type Opentelemetry_Config_Mutation_Response = {
  __typename?: 'opentelemetry_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Opentelemetry_Config>;
};

/** input type for inserting object relation for remote table "opentelemetry_config" */
export type Opentelemetry_Config_Obj_Rel_Insert_Input = {
  data: Opentelemetry_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Opentelemetry_Config_On_Conflict>;
};

/** on_conflict condition type for table "opentelemetry_config" */
export type Opentelemetry_Config_On_Conflict = {
  constraint: Opentelemetry_Config_Constraint;
  update_columns?: Array<Opentelemetry_Config_Update_Column>;
  where?: InputMaybe<Opentelemetry_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "opentelemetry_config". */
export type Opentelemetry_Config_Order_By = {
  attributes?: InputMaybe<Order_By>;
  batch_size?: InputMaybe<Order_By>;
  connection_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  endpoint?: InputMaybe<Order_By>;
  export_traces?: InputMaybe<Order_By>;
  headers?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  traces_last_exported?: InputMaybe<Order_By>;
  traces_response_code?: InputMaybe<Order_By>;
  traces_response_message?: InputMaybe<Order_By>;
};

/** primary key columns input for table: opentelemetry_config */
export type Opentelemetry_Config_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Opentelemetry_Config_Prepend_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  headers?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "opentelemetry_config" */
export enum Opentelemetry_Config_Select_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  BatchSize = 'batch_size',
  /** column name */
  ConnectionType = 'connection_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  ExportTraces = 'export_traces',
  /** column name */
  Headers = 'headers',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TracesLastExported = 'traces_last_exported',
  /** column name */
  TracesResponseCode = 'traces_response_code',
  /** column name */
  TracesResponseMessage = 'traces_response_message',
}

/** input type for updating data in table "opentelemetry_config" */
export type Opentelemetry_Config_Set_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  batch_size?: InputMaybe<Scalars['Int']>;
  connection_type?: InputMaybe<Scalars['opentelemetry_connection_type_enum']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endpoint?: InputMaybe<Scalars['String']>;
  export_traces?: InputMaybe<Scalars['Boolean']>;
  headers?: InputMaybe<Scalars['jsonb']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  traces_last_exported?: InputMaybe<Scalars['timestamptz']>;
  traces_response_code?: InputMaybe<Scalars['Int']>;
  traces_response_message?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Opentelemetry_Config_Stddev_Fields = {
  __typename?: 'opentelemetry_config_stddev_fields';
  batch_size?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Opentelemetry_Config_Stddev_Pop_Fields = {
  __typename?: 'opentelemetry_config_stddev_pop_fields';
  batch_size?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Opentelemetry_Config_Stddev_Samp_Fields = {
  __typename?: 'opentelemetry_config_stddev_samp_fields';
  batch_size?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "opentelemetry_config" */
export type Opentelemetry_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Opentelemetry_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Opentelemetry_Config_Stream_Cursor_Value_Input = {
  attributes?: InputMaybe<Scalars['jsonb']>;
  batch_size?: InputMaybe<Scalars['Int']>;
  connection_type?: InputMaybe<Scalars['opentelemetry_connection_type_enum']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endpoint?: InputMaybe<Scalars['String']>;
  export_traces?: InputMaybe<Scalars['Boolean']>;
  headers?: InputMaybe<Scalars['jsonb']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  traces_last_exported?: InputMaybe<Scalars['timestamptz']>;
  traces_response_code?: InputMaybe<Scalars['Int']>;
  traces_response_message?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Opentelemetry_Config_Sum_Fields = {
  __typename?: 'opentelemetry_config_sum_fields';
  batch_size?: Maybe<Scalars['Int']>;
  traces_response_code?: Maybe<Scalars['Int']>;
};

/** update columns of table "opentelemetry_config" */
export enum Opentelemetry_Config_Update_Column {
  /** column name */
  Attributes = 'attributes',
  /** column name */
  BatchSize = 'batch_size',
  /** column name */
  ConnectionType = 'connection_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  ExportTraces = 'export_traces',
  /** column name */
  Headers = 'headers',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TracesLastExported = 'traces_last_exported',
  /** column name */
  TracesResponseCode = 'traces_response_code',
  /** column name */
  TracesResponseMessage = 'traces_response_message',
}

export type Opentelemetry_Config_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Opentelemetry_Config_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Opentelemetry_Config_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Opentelemetry_Config_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Opentelemetry_Config_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Opentelemetry_Config_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Opentelemetry_Config_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Opentelemetry_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Opentelemetry_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Opentelemetry_Config_Var_Pop_Fields = {
  __typename?: 'opentelemetry_config_var_pop_fields';
  batch_size?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Opentelemetry_Config_Var_Samp_Fields = {
  __typename?: 'opentelemetry_config_var_samp_fields';
  batch_size?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Opentelemetry_Config_Variance_Fields = {
  __typename?: 'opentelemetry_config_variance_fields';
  batch_size?: Maybe<Scalars['Float']>;
  traces_response_code?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "opentelemetry_connection_type_enum". All fields are combined with logical 'AND'. */
export type Opentelemetry_Connection_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['opentelemetry_connection_type_enum']>;
  _gt?: InputMaybe<Scalars['opentelemetry_connection_type_enum']>;
  _gte?: InputMaybe<Scalars['opentelemetry_connection_type_enum']>;
  _in?: InputMaybe<Array<Scalars['opentelemetry_connection_type_enum']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['opentelemetry_connection_type_enum']>;
  _lte?: InputMaybe<Scalars['opentelemetry_connection_type_enum']>;
  _neq?: InputMaybe<Scalars['opentelemetry_connection_type_enum']>;
  _nin?: InputMaybe<Array<Scalars['opentelemetry_connection_type_enum']>>;
};

/** Keeps track of tenants for which DNS records were deleted */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021 = {
  __typename?: 'operations_delete_free_plan_dns_records_nov_2021';
  dns_record_deleted: Scalars['Boolean'];
  message: Scalars['String'];
  /** An object relationship */
  tenant: Tenant;
  tenant_id: Scalars['uuid'];
};

/** aggregated selection of "operations.delete_free_plan_dns_records_nov_2021" */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Aggregate = {
  __typename?: 'operations_delete_free_plan_dns_records_nov_2021_aggregate';
  aggregate?: Maybe<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Aggregate_Fields
  >;
  nodes: Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021>;
};

/** aggregate fields of "operations.delete_free_plan_dns_records_nov_2021" */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Aggregate_Fields = {
  __typename?: 'operations_delete_free_plan_dns_records_nov_2021_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Max_Fields>;
  min?: Maybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Min_Fields>;
};

/** aggregate fields of "operations.delete_free_plan_dns_records_nov_2021" */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "operations.delete_free_plan_dns_records_nov_2021". All fields are combined with a logical 'AND'. */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp = {
  _and?: InputMaybe<
    Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp>
  >;
  _not?: InputMaybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp>;
  _or?: InputMaybe<
    Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp>
  >;
  dns_record_deleted?: InputMaybe<Boolean_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "operations.delete_free_plan_dns_records_nov_2021" */
export enum Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Constraint {
  /** unique or primary key constraint on columns "tenant_id" */
  DeleteFreePlanDnsRecordsNov_2021Pkey = 'delete_free_plan_dns_records_nov_2021_pkey',
}

/** input type for inserting data into table "operations.delete_free_plan_dns_records_nov_2021" */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Insert_Input = {
  dns_record_deleted?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Max_Fields = {
  __typename?: 'operations_delete_free_plan_dns_records_nov_2021_max_fields';
  message?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Min_Fields = {
  __typename?: 'operations_delete_free_plan_dns_records_nov_2021_min_fields';
  message?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "operations.delete_free_plan_dns_records_nov_2021" */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Mutation_Response = {
  __typename?: 'operations_delete_free_plan_dns_records_nov_2021_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021>;
};

/** on_conflict condition type for table "operations.delete_free_plan_dns_records_nov_2021" */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_On_Conflict = {
  constraint: Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Constraint;
  update_columns?: Array<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Update_Column
  >;
  where?: InputMaybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp>;
};

/** Ordering options when selecting data from "operations.delete_free_plan_dns_records_nov_2021". */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Order_By = {
  dns_record_deleted?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: operations.delete_free_plan_dns_records_nov_2021 */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Pk_Columns_Input = {
  tenant_id: Scalars['uuid'];
};

/** select columns of table "operations.delete_free_plan_dns_records_nov_2021" */
export enum Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Select_Column {
  /** column name */
  DnsRecordDeleted = 'dns_record_deleted',
  /** column name */
  Message = 'message',
  /** column name */
  TenantId = 'tenant_id',
}

/** input type for updating data in table "operations.delete_free_plan_dns_records_nov_2021" */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Set_Input = {
  dns_record_deleted?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "operations_delete_free_plan_dns_records_nov_2021" */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Stream_Cursor_Value_Input = {
  dns_record_deleted?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "operations.delete_free_plan_dns_records_nov_2021" */
export enum Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Update_Column {
  /** column name */
  DnsRecordDeleted = 'dns_record_deleted',
  /** column name */
  Message = 'message',
  /** column name */
  TenantId = 'tenant_id',
}

export type Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp;
};

/** columns and relationships of "operations.tenant" */
export type Operations_Tenant = {
  __typename?: 'operations_tenant';
  active?: Maybe<Scalars['Boolean']>;
  alias?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  cloudflare_proxy_enabled?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  maintenance_mode?: Maybe<Scalars['Boolean']>;
  major_version?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregated selection of "operations.tenant" */
export type Operations_Tenant_Aggregate = {
  __typename?: 'operations_tenant_aggregate';
  aggregate?: Maybe<Operations_Tenant_Aggregate_Fields>;
  nodes: Array<Operations_Tenant>;
};

/** aggregate fields of "operations.tenant" */
export type Operations_Tenant_Aggregate_Fields = {
  __typename?: 'operations_tenant_aggregate_fields';
  avg?: Maybe<Operations_Tenant_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Operations_Tenant_Max_Fields>;
  min?: Maybe<Operations_Tenant_Min_Fields>;
  stddev?: Maybe<Operations_Tenant_Stddev_Fields>;
  stddev_pop?: Maybe<Operations_Tenant_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Operations_Tenant_Stddev_Samp_Fields>;
  sum?: Maybe<Operations_Tenant_Sum_Fields>;
  var_pop?: Maybe<Operations_Tenant_Var_Pop_Fields>;
  var_samp?: Maybe<Operations_Tenant_Var_Samp_Fields>;
  variance?: Maybe<Operations_Tenant_Variance_Fields>;
};

/** aggregate fields of "operations.tenant" */
export type Operations_Tenant_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Operations_Tenant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Operations_Tenant_Avg_Fields = {
  __typename?: 'operations_tenant_avg_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "operations.tenant". All fields are combined with a logical 'AND'. */
export type Operations_Tenant_Bool_Exp = {
  _and?: InputMaybe<Array<Operations_Tenant_Bool_Exp>>;
  _not?: InputMaybe<Operations_Tenant_Bool_Exp>;
  _or?: InputMaybe<Array<Operations_Tenant_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  alias?: InputMaybe<String_Comparison_Exp>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  cloudflare_proxy_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dedicated_vpc_id?: InputMaybe<Uuid_Comparison_Exp>;
  fqdn?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  maintenance_mode?: InputMaybe<Boolean_Comparison_Exp>;
  major_version?: InputMaybe<Int_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "operations.tenant" */
export type Operations_Tenant_Inc_Input = {
  major_version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "operations.tenant" */
export type Operations_Tenant_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  alias?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  cloudflare_proxy_enabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  maintenance_mode?: InputMaybe<Scalars['Boolean']>;
  major_version?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Operations_Tenant_Max_Fields = {
  __typename?: 'operations_tenant_max_fields';
  alias?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  major_version?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Operations_Tenant_Min_Fields = {
  __typename?: 'operations_tenant_min_fields';
  alias?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  major_version?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "operations.tenant" */
export type Operations_Tenant_Mutation_Response = {
  __typename?: 'operations_tenant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Operations_Tenant>;
};

/** Ordering options when selecting data from "operations.tenant". */
export type Operations_Tenant_Order_By = {
  active?: InputMaybe<Order_By>;
  alias?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  cloudflare_proxy_enabled?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maintenance_mode?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** select columns of table "operations.tenant" */
export enum Operations_Tenant_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Alias = 'alias',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CloudflareProxyEnabled = 'cloudflare_proxy_enabled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DedicatedVpcId = 'dedicated_vpc_id',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  MaintenanceMode = 'maintenance_mode',
  /** column name */
  MajorVersion = 'major_version',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
}

/** input type for updating data in table "operations.tenant" */
export type Operations_Tenant_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  alias?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  cloudflare_proxy_enabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  maintenance_mode?: InputMaybe<Scalars['Boolean']>;
  major_version?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Operations_Tenant_Stddev_Fields = {
  __typename?: 'operations_tenant_stddev_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Operations_Tenant_Stddev_Pop_Fields = {
  __typename?: 'operations_tenant_stddev_pop_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Operations_Tenant_Stddev_Samp_Fields = {
  __typename?: 'operations_tenant_stddev_samp_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "operations_tenant" */
export type Operations_Tenant_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Operations_Tenant_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Operations_Tenant_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  alias?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  cloudflare_proxy_enabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  maintenance_mode?: InputMaybe<Scalars['Boolean']>;
  major_version?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Operations_Tenant_Sum_Fields = {
  __typename?: 'operations_tenant_sum_fields';
  major_version?: Maybe<Scalars['Int']>;
};

export type Operations_Tenant_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Operations_Tenant_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Operations_Tenant_Set_Input>;
  /** filter the rows which have to be updated */
  where: Operations_Tenant_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Operations_Tenant_Var_Pop_Fields = {
  __typename?: 'operations_tenant_var_pop_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Operations_Tenant_Var_Samp_Fields = {
  __typename?: 'operations_tenant_var_samp_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Operations_Tenant_Variance_Fields = {
  __typename?: 'operations_tenant_variance_fields';
  major_version?: Maybe<Scalars['Float']>;
};

export type Operations_To_Delete_Free_Plan_Dns_Records_Args = {
  free_plan_region?: InputMaybe<Scalars['String']>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

export enum PatchBuildType {
  Applied = 'applied',
  Latest = 'latest',
}

/** Payment method used by stripe customer  */
export type Payment_Method = {
  __typename?: 'payment_method';
  brand: Scalars['String'];
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exp_month: Scalars['Int'];
  exp_year: Scalars['Int'];
  id: Scalars['String'];
  is_default: Scalars['Boolean'];
  last4: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  stripe_customer_id: Scalars['String'];
  type: Scalars['String'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "payment_method" */
export type Payment_Method_Aggregate = {
  __typename?: 'payment_method_aggregate';
  aggregate?: Maybe<Payment_Method_Aggregate_Fields>;
  nodes: Array<Payment_Method>;
};

export type Payment_Method_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Payment_Method_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Payment_Method_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Payment_Method_Aggregate_Bool_Exp_Count>;
};

export type Payment_Method_Aggregate_Bool_Exp_Bool_And = {
  arguments: Payment_Method_Select_Column_Payment_Method_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Payment_Method_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Payment_Method_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Payment_Method_Select_Column_Payment_Method_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Payment_Method_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Payment_Method_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Payment_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Payment_Method_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "payment_method" */
export type Payment_Method_Aggregate_Fields = {
  __typename?: 'payment_method_aggregate_fields';
  avg?: Maybe<Payment_Method_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Payment_Method_Max_Fields>;
  min?: Maybe<Payment_Method_Min_Fields>;
  stddev?: Maybe<Payment_Method_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Method_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Method_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Method_Sum_Fields>;
  var_pop?: Maybe<Payment_Method_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Method_Var_Samp_Fields>;
  variance?: Maybe<Payment_Method_Variance_Fields>;
};

/** aggregate fields of "payment_method" */
export type Payment_Method_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Payment_Method_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "payment_method" */
export type Payment_Method_Aggregate_Order_By = {
  avg?: InputMaybe<Payment_Method_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Payment_Method_Max_Order_By>;
  min?: InputMaybe<Payment_Method_Min_Order_By>;
  stddev?: InputMaybe<Payment_Method_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Payment_Method_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Payment_Method_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Payment_Method_Sum_Order_By>;
  var_pop?: InputMaybe<Payment_Method_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Payment_Method_Var_Samp_Order_By>;
  variance?: InputMaybe<Payment_Method_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "payment_method" */
export type Payment_Method_Arr_Rel_Insert_Input = {
  data: Array<Payment_Method_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Method_Avg_Fields = {
  __typename?: 'payment_method_avg_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "payment_method" */
export type Payment_Method_Avg_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "payment_method". All fields are combined with a logical 'AND'. */
export type Payment_Method_Bool_Exp = {
  _and?: InputMaybe<Array<Payment_Method_Bool_Exp>>;
  _not?: InputMaybe<Payment_Method_Bool_Exp>;
  _or?: InputMaybe<Array<Payment_Method_Bool_Exp>>;
  brand?: InputMaybe<String_Comparison_Exp>;
  country_code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  exp_month?: InputMaybe<Int_Comparison_Exp>;
  exp_year?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  is_default?: InputMaybe<Boolean_Comparison_Exp>;
  last4?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  stripe_customer_id?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "payment_method" */
export enum Payment_Method_Constraint {
  /** unique or primary key constraint on columns "id" */
  PaymentMethodPkey = 'payment_method_pkey',
}

/** input type for incrementing numeric columns in table "payment_method" */
export type Payment_Method_Inc_Input = {
  exp_month?: InputMaybe<Scalars['Int']>;
  exp_year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "payment_method" */
export type Payment_Method_Insert_Input = {
  brand?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  exp_month?: InputMaybe<Scalars['Int']>;
  exp_year?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  last4?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Payment_Method_Max_Fields = {
  __typename?: 'payment_method_max_fields';
  brand?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "payment_method" */
export type Payment_Method_Max_Order_By = {
  brand?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last4?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripe_customer_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Method_Min_Fields = {
  __typename?: 'payment_method_min_fields';
  brand?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  stripe_customer_id?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "payment_method" */
export type Payment_Method_Min_Order_By = {
  brand?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last4?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripe_customer_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "payment_method" */
export type Payment_Method_Mutation_Response = {
  __typename?: 'payment_method_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Payment_Method>;
};

/** input type for inserting object relation for remote table "payment_method" */
export type Payment_Method_Obj_Rel_Insert_Input = {
  data: Payment_Method_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Payment_Method_On_Conflict>;
};

/** on_conflict condition type for table "payment_method" */
export type Payment_Method_On_Conflict = {
  constraint: Payment_Method_Constraint;
  update_columns?: Array<Payment_Method_Update_Column>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

/** Ordering options when selecting data from "payment_method". */
export type Payment_Method_Order_By = {
  brand?: InputMaybe<Order_By>;
  country_code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_default?: InputMaybe<Order_By>;
  last4?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stripe_customer_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: payment_method */
export type Payment_Method_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** select columns of table "payment_method" */
export enum Payment_Method_Select_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpMonth = 'exp_month',
  /** column name */
  ExpYear = 'exp_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  Last4 = 'last4',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  Type = 'type',
}

/** select "payment_method_aggregate_bool_exp_bool_and_arguments_columns" columns of table "payment_method" */
export enum Payment_Method_Select_Column_Payment_Method_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDefault = 'is_default',
}

/** select "payment_method_aggregate_bool_exp_bool_or_arguments_columns" columns of table "payment_method" */
export enum Payment_Method_Select_Column_Payment_Method_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDefault = 'is_default',
}

/** input type for updating data in table "payment_method" */
export type Payment_Method_Set_Input = {
  brand?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  exp_month?: InputMaybe<Scalars['Int']>;
  exp_year?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  last4?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Payment_Method_Stddev_Fields = {
  __typename?: 'payment_method_stddev_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "payment_method" */
export type Payment_Method_Stddev_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Method_Stddev_Pop_Fields = {
  __typename?: 'payment_method_stddev_pop_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "payment_method" */
export type Payment_Method_Stddev_Pop_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Method_Stddev_Samp_Fields = {
  __typename?: 'payment_method_stddev_samp_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "payment_method" */
export type Payment_Method_Stddev_Samp_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "payment_method" */
export type Payment_Method_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Payment_Method_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Payment_Method_Stream_Cursor_Value_Input = {
  brand?: InputMaybe<Scalars['String']>;
  country_code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  exp_month?: InputMaybe<Scalars['Int']>;
  exp_year?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  is_default?: InputMaybe<Scalars['Boolean']>;
  last4?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  stripe_customer_id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Payment_Method_Sum_Fields = {
  __typename?: 'payment_method_sum_fields';
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "payment_method" */
export type Payment_Method_Sum_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
};

/** update columns of table "payment_method" */
export enum Payment_Method_Update_Column {
  /** column name */
  Brand = 'brand',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpMonth = 'exp_month',
  /** column name */
  ExpYear = 'exp_year',
  /** column name */
  Id = 'id',
  /** column name */
  IsDefault = 'is_default',
  /** column name */
  Last4 = 'last4',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  StripeCustomerId = 'stripe_customer_id',
  /** column name */
  Type = 'type',
}

export type Payment_Method_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Payment_Method_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Payment_Method_Set_Input>;
  /** filter the rows which have to be updated */
  where: Payment_Method_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Payment_Method_Var_Pop_Fields = {
  __typename?: 'payment_method_var_pop_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "payment_method" */
export type Payment_Method_Var_Pop_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Method_Var_Samp_Fields = {
  __typename?: 'payment_method_var_samp_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "payment_method" */
export type Payment_Method_Var_Samp_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Method_Variance_Fields = {
  __typename?: 'payment_method_variance_fields';
  exp_month?: Maybe<Scalars['Float']>;
  exp_year?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "payment_method" */
export type Payment_Method_Variance_Order_By = {
  exp_month?: InputMaybe<Order_By>;
  exp_year?: InputMaybe<Order_By>;
};

/** Each row represents which entitlements a plan has access to.  */
export type Plan_Entitlements = {
  __typename?: 'plan_entitlements';
  created_at: Scalars['timestamp'];
  /** An object relationship */
  entitlement: Project_Entitlement_Catalogue;
  entitlement_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  plan: Plans;
  plan_name: Scalars['String'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "plan_entitlements" */
export type Plan_Entitlements_Aggregate = {
  __typename?: 'plan_entitlements_aggregate';
  aggregate?: Maybe<Plan_Entitlements_Aggregate_Fields>;
  nodes: Array<Plan_Entitlements>;
};

export type Plan_Entitlements_Aggregate_Bool_Exp = {
  count?: InputMaybe<Plan_Entitlements_Aggregate_Bool_Exp_Count>;
};

export type Plan_Entitlements_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Plan_Entitlements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Plan_Entitlements_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "plan_entitlements" */
export type Plan_Entitlements_Aggregate_Fields = {
  __typename?: 'plan_entitlements_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Plan_Entitlements_Max_Fields>;
  min?: Maybe<Plan_Entitlements_Min_Fields>;
};

/** aggregate fields of "plan_entitlements" */
export type Plan_Entitlements_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plan_Entitlements_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "plan_entitlements" */
export type Plan_Entitlements_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Plan_Entitlements_Max_Order_By>;
  min?: InputMaybe<Plan_Entitlements_Min_Order_By>;
};

/** input type for inserting array relation for remote table "plan_entitlements" */
export type Plan_Entitlements_Arr_Rel_Insert_Input = {
  data: Array<Plan_Entitlements_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Plan_Entitlements_On_Conflict>;
};

/** Boolean expression to filter rows from the table "plan_entitlements". All fields are combined with a logical 'AND'. */
export type Plan_Entitlements_Bool_Exp = {
  _and?: InputMaybe<Array<Plan_Entitlements_Bool_Exp>>;
  _not?: InputMaybe<Plan_Entitlements_Bool_Exp>;
  _or?: InputMaybe<Array<Plan_Entitlements_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  entitlement?: InputMaybe<Project_Entitlement_Catalogue_Bool_Exp>;
  entitlement_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  plan?: InputMaybe<Plans_Bool_Exp>;
  plan_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "plan_entitlements" */
export enum Plan_Entitlements_Constraint {
  /** unique or primary key constraint on columns "id" */
  PlanEntitlementsPkey = 'plan_entitlements_pkey',
}

/** input type for inserting data into table "plan_entitlements" */
export type Plan_Entitlements_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  entitlement?: InputMaybe<Project_Entitlement_Catalogue_Obj_Rel_Insert_Input>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan?: InputMaybe<Plans_Obj_Rel_Insert_Input>;
  plan_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Plan_Entitlements_Max_Fields = {
  __typename?: 'plan_entitlements_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  entitlement_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  plan_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "plan_entitlements" */
export type Plan_Entitlements_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Plan_Entitlements_Min_Fields = {
  __typename?: 'plan_entitlements_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  entitlement_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  plan_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "plan_entitlements" */
export type Plan_Entitlements_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "plan_entitlements" */
export type Plan_Entitlements_Mutation_Response = {
  __typename?: 'plan_entitlements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Plan_Entitlements>;
};

/** on_conflict condition type for table "plan_entitlements" */
export type Plan_Entitlements_On_Conflict = {
  constraint: Plan_Entitlements_Constraint;
  update_columns?: Array<Plan_Entitlements_Update_Column>;
  where?: InputMaybe<Plan_Entitlements_Bool_Exp>;
};

/** Ordering options when selecting data from "plan_entitlements". */
export type Plan_Entitlements_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entitlement?: InputMaybe<Project_Entitlement_Catalogue_Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan?: InputMaybe<Plans_Order_By>;
  plan_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plan_entitlements */
export type Plan_Entitlements_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "plan_entitlements" */
export enum Plan_Entitlements_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntitlementId = 'entitlement_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "plan_entitlements" */
export type Plan_Entitlements_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "plan_entitlements" */
export type Plan_Entitlements_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plan_Entitlements_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plan_Entitlements_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  plan_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "plan_entitlements" */
export enum Plan_Entitlements_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntitlementId = 'entitlement_id',
  /** column name */
  Id = 'id',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Plan_Entitlements_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plan_Entitlements_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plan_Entitlements_Bool_Exp;
};

/** Payment / usage plans available for Hasura Cloud */
export type Plans = {
  __typename?: 'plans';
  alloc_limit?: Maybe<Scalars['Int']>;
  allow_list_enabled: Scalars['Boolean'];
  api_limits_enabled: Scalars['Boolean'];
  autoscaling_enabled: Scalars['Boolean'];
  bucket_capacity?: Maybe<Scalars['bigint']>;
  bucket_rate?: Maybe<Scalars['bigint']>;
  caching_enabled: Scalars['Boolean'];
  collaborators_enabled: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  enable_granular_metrics?: Maybe<Scalars['Boolean']>;
  enable_model_info_log: Scalars['Boolean'];
  enable_otel_exporter: Scalars['Boolean'];
  /** An array relationship */
  entitlements: Array<Plan_Entitlements>;
  /** An aggregate relationship */
  entitlements_aggregate: Plan_Entitlements_Aggregate;
  high_availability_enabled: Scalars['Boolean'];
  log_retention: Scalars['Int'];
  max_api_limit_roles?: Maybe<Scalars['Int']>;
  max_cache_size?: Maybe<Scalars['bigint']>;
  max_cache_ttl?: Maybe<Scalars['bigint']>;
  max_collaborators?: Maybe<Scalars['Int']>;
  max_data_usage?: Maybe<Scalars['bigint']>;
  max_read_replicas?: Maybe<Scalars['Int']>;
  max_reqs_per_min?: Maybe<Scalars['Int']>;
  metrics_enabled: Scalars['Boolean'];
  name: Scalars['String'];
  operation_retention: Scalars['bigint'];
  read_replicas_enabled: Scalars['Boolean'];
  regression_tests_enabled: Scalars['Boolean'];
  request_time_limit?: Maybe<Scalars['Int']>;
  tracing_enabled: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  vpc_peering_enabled: Scalars['Boolean'];
};

/** Payment / usage plans available for Hasura Cloud */
export type PlansEntitlementsArgs = {
  distinct_on?: InputMaybe<Array<Plan_Entitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plan_Entitlements_Order_By>>;
  where?: InputMaybe<Plan_Entitlements_Bool_Exp>;
};

/** Payment / usage plans available for Hasura Cloud */
export type PlansEntitlements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Entitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plan_Entitlements_Order_By>>;
  where?: InputMaybe<Plan_Entitlements_Bool_Exp>;
};

/** aggregated selection of "plans" */
export type Plans_Aggregate = {
  __typename?: 'plans_aggregate';
  aggregate?: Maybe<Plans_Aggregate_Fields>;
  nodes: Array<Plans>;
};

/** aggregate fields of "plans" */
export type Plans_Aggregate_Fields = {
  __typename?: 'plans_aggregate_fields';
  avg?: Maybe<Plans_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Plans_Max_Fields>;
  min?: Maybe<Plans_Min_Fields>;
  stddev?: Maybe<Plans_Stddev_Fields>;
  stddev_pop?: Maybe<Plans_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Plans_Stddev_Samp_Fields>;
  sum?: Maybe<Plans_Sum_Fields>;
  var_pop?: Maybe<Plans_Var_Pop_Fields>;
  var_samp?: Maybe<Plans_Var_Samp_Fields>;
  variance?: Maybe<Plans_Variance_Fields>;
};

/** aggregate fields of "plans" */
export type Plans_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Plans_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Plans_Avg_Fields = {
  __typename?: 'plans_avg_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "plans". All fields are combined with a logical 'AND'. */
export type Plans_Bool_Exp = {
  _and?: InputMaybe<Array<Plans_Bool_Exp>>;
  _not?: InputMaybe<Plans_Bool_Exp>;
  _or?: InputMaybe<Array<Plans_Bool_Exp>>;
  alloc_limit?: InputMaybe<Int_Comparison_Exp>;
  allow_list_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  api_limits_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  autoscaling_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  bucket_capacity?: InputMaybe<Bigint_Comparison_Exp>;
  bucket_rate?: InputMaybe<Bigint_Comparison_Exp>;
  caching_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  collaborators_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  enable_granular_metrics?: InputMaybe<Boolean_Comparison_Exp>;
  enable_model_info_log?: InputMaybe<Boolean_Comparison_Exp>;
  enable_otel_exporter?: InputMaybe<Boolean_Comparison_Exp>;
  entitlements?: InputMaybe<Plan_Entitlements_Bool_Exp>;
  entitlements_aggregate?: InputMaybe<Plan_Entitlements_Aggregate_Bool_Exp>;
  high_availability_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  log_retention?: InputMaybe<Int_Comparison_Exp>;
  max_api_limit_roles?: InputMaybe<Int_Comparison_Exp>;
  max_cache_size?: InputMaybe<Bigint_Comparison_Exp>;
  max_cache_ttl?: InputMaybe<Bigint_Comparison_Exp>;
  max_collaborators?: InputMaybe<Int_Comparison_Exp>;
  max_data_usage?: InputMaybe<Bigint_Comparison_Exp>;
  max_read_replicas?: InputMaybe<Int_Comparison_Exp>;
  max_reqs_per_min?: InputMaybe<Int_Comparison_Exp>;
  metrics_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  operation_retention?: InputMaybe<Bigint_Comparison_Exp>;
  read_replicas_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  regression_tests_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  request_time_limit?: InputMaybe<Int_Comparison_Exp>;
  tracing_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vpc_peering_enabled?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "plans" */
export enum Plans_Constraint {
  /** unique or primary key constraint on columns "name" */
  PlansPkey = 'plans_pkey',
}

/** input type for incrementing numeric columns in table "plans" */
export type Plans_Inc_Input = {
  alloc_limit?: InputMaybe<Scalars['Int']>;
  bucket_capacity?: InputMaybe<Scalars['bigint']>;
  bucket_rate?: InputMaybe<Scalars['bigint']>;
  log_retention?: InputMaybe<Scalars['Int']>;
  max_api_limit_roles?: InputMaybe<Scalars['Int']>;
  max_cache_size?: InputMaybe<Scalars['bigint']>;
  max_cache_ttl?: InputMaybe<Scalars['bigint']>;
  max_collaborators?: InputMaybe<Scalars['Int']>;
  max_data_usage?: InputMaybe<Scalars['bigint']>;
  max_read_replicas?: InputMaybe<Scalars['Int']>;
  max_reqs_per_min?: InputMaybe<Scalars['Int']>;
  operation_retention?: InputMaybe<Scalars['bigint']>;
  request_time_limit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "plans" */
export type Plans_Insert_Input = {
  alloc_limit?: InputMaybe<Scalars['Int']>;
  allow_list_enabled?: InputMaybe<Scalars['Boolean']>;
  api_limits_enabled?: InputMaybe<Scalars['Boolean']>;
  autoscaling_enabled?: InputMaybe<Scalars['Boolean']>;
  bucket_capacity?: InputMaybe<Scalars['bigint']>;
  bucket_rate?: InputMaybe<Scalars['bigint']>;
  caching_enabled?: InputMaybe<Scalars['Boolean']>;
  collaborators_enabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  enable_granular_metrics?: InputMaybe<Scalars['Boolean']>;
  enable_model_info_log?: InputMaybe<Scalars['Boolean']>;
  enable_otel_exporter?: InputMaybe<Scalars['Boolean']>;
  entitlements?: InputMaybe<Plan_Entitlements_Arr_Rel_Insert_Input>;
  high_availability_enabled?: InputMaybe<Scalars['Boolean']>;
  log_retention?: InputMaybe<Scalars['Int']>;
  max_api_limit_roles?: InputMaybe<Scalars['Int']>;
  max_cache_size?: InputMaybe<Scalars['bigint']>;
  max_cache_ttl?: InputMaybe<Scalars['bigint']>;
  max_collaborators?: InputMaybe<Scalars['Int']>;
  max_data_usage?: InputMaybe<Scalars['bigint']>;
  max_read_replicas?: InputMaybe<Scalars['Int']>;
  max_reqs_per_min?: InputMaybe<Scalars['Int']>;
  metrics_enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  operation_retention?: InputMaybe<Scalars['bigint']>;
  read_replicas_enabled?: InputMaybe<Scalars['Boolean']>;
  regression_tests_enabled?: InputMaybe<Scalars['Boolean']>;
  request_time_limit?: InputMaybe<Scalars['Int']>;
  tracing_enabled?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vpc_peering_enabled?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Plans_Max_Fields = {
  __typename?: 'plans_max_fields';
  alloc_limit?: Maybe<Scalars['Int']>;
  bucket_capacity?: Maybe<Scalars['bigint']>;
  bucket_rate?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  log_retention?: Maybe<Scalars['Int']>;
  max_api_limit_roles?: Maybe<Scalars['Int']>;
  max_cache_size?: Maybe<Scalars['bigint']>;
  max_cache_ttl?: Maybe<Scalars['bigint']>;
  max_collaborators?: Maybe<Scalars['Int']>;
  max_data_usage?: Maybe<Scalars['bigint']>;
  max_read_replicas?: Maybe<Scalars['Int']>;
  max_reqs_per_min?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  operation_retention?: Maybe<Scalars['bigint']>;
  request_time_limit?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Plans_Min_Fields = {
  __typename?: 'plans_min_fields';
  alloc_limit?: Maybe<Scalars['Int']>;
  bucket_capacity?: Maybe<Scalars['bigint']>;
  bucket_rate?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  log_retention?: Maybe<Scalars['Int']>;
  max_api_limit_roles?: Maybe<Scalars['Int']>;
  max_cache_size?: Maybe<Scalars['bigint']>;
  max_cache_ttl?: Maybe<Scalars['bigint']>;
  max_collaborators?: Maybe<Scalars['Int']>;
  max_data_usage?: Maybe<Scalars['bigint']>;
  max_read_replicas?: Maybe<Scalars['Int']>;
  max_reqs_per_min?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  operation_retention?: Maybe<Scalars['bigint']>;
  request_time_limit?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "plans" */
export type Plans_Mutation_Response = {
  __typename?: 'plans_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Plans>;
};

/** input type for inserting object relation for remote table "plans" */
export type Plans_Obj_Rel_Insert_Input = {
  data: Plans_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Plans_On_Conflict>;
};

/** on_conflict condition type for table "plans" */
export type Plans_On_Conflict = {
  constraint: Plans_Constraint;
  update_columns?: Array<Plans_Update_Column>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "plans". */
export type Plans_Order_By = {
  alloc_limit?: InputMaybe<Order_By>;
  allow_list_enabled?: InputMaybe<Order_By>;
  api_limits_enabled?: InputMaybe<Order_By>;
  autoscaling_enabled?: InputMaybe<Order_By>;
  bucket_capacity?: InputMaybe<Order_By>;
  bucket_rate?: InputMaybe<Order_By>;
  caching_enabled?: InputMaybe<Order_By>;
  collaborators_enabled?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  enable_granular_metrics?: InputMaybe<Order_By>;
  enable_model_info_log?: InputMaybe<Order_By>;
  enable_otel_exporter?: InputMaybe<Order_By>;
  entitlements_aggregate?: InputMaybe<Plan_Entitlements_Aggregate_Order_By>;
  high_availability_enabled?: InputMaybe<Order_By>;
  log_retention?: InputMaybe<Order_By>;
  max_api_limit_roles?: InputMaybe<Order_By>;
  max_cache_size?: InputMaybe<Order_By>;
  max_cache_ttl?: InputMaybe<Order_By>;
  max_collaborators?: InputMaybe<Order_By>;
  max_data_usage?: InputMaybe<Order_By>;
  max_read_replicas?: InputMaybe<Order_By>;
  max_reqs_per_min?: InputMaybe<Order_By>;
  metrics_enabled?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  operation_retention?: InputMaybe<Order_By>;
  read_replicas_enabled?: InputMaybe<Order_By>;
  regression_tests_enabled?: InputMaybe<Order_By>;
  request_time_limit?: InputMaybe<Order_By>;
  tracing_enabled?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vpc_peering_enabled?: InputMaybe<Order_By>;
};

/** primary key columns input for table: plans */
export type Plans_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "plans" */
export enum Plans_Select_Column {
  /** column name */
  AllocLimit = 'alloc_limit',
  /** column name */
  AllowListEnabled = 'allow_list_enabled',
  /** column name */
  ApiLimitsEnabled = 'api_limits_enabled',
  /** column name */
  AutoscalingEnabled = 'autoscaling_enabled',
  /** column name */
  BucketCapacity = 'bucket_capacity',
  /** column name */
  BucketRate = 'bucket_rate',
  /** column name */
  CachingEnabled = 'caching_enabled',
  /** column name */
  CollaboratorsEnabled = 'collaborators_enabled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnableGranularMetrics = 'enable_granular_metrics',
  /** column name */
  EnableModelInfoLog = 'enable_model_info_log',
  /** column name */
  EnableOtelExporter = 'enable_otel_exporter',
  /** column name */
  HighAvailabilityEnabled = 'high_availability_enabled',
  /** column name */
  LogRetention = 'log_retention',
  /** column name */
  MaxApiLimitRoles = 'max_api_limit_roles',
  /** column name */
  MaxCacheSize = 'max_cache_size',
  /** column name */
  MaxCacheTtl = 'max_cache_ttl',
  /** column name */
  MaxCollaborators = 'max_collaborators',
  /** column name */
  MaxDataUsage = 'max_data_usage',
  /** column name */
  MaxReadReplicas = 'max_read_replicas',
  /** column name */
  MaxReqsPerMin = 'max_reqs_per_min',
  /** column name */
  MetricsEnabled = 'metrics_enabled',
  /** column name */
  Name = 'name',
  /** column name */
  OperationRetention = 'operation_retention',
  /** column name */
  ReadReplicasEnabled = 'read_replicas_enabled',
  /** column name */
  RegressionTestsEnabled = 'regression_tests_enabled',
  /** column name */
  RequestTimeLimit = 'request_time_limit',
  /** column name */
  TracingEnabled = 'tracing_enabled',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VpcPeeringEnabled = 'vpc_peering_enabled',
}

/** input type for updating data in table "plans" */
export type Plans_Set_Input = {
  alloc_limit?: InputMaybe<Scalars['Int']>;
  allow_list_enabled?: InputMaybe<Scalars['Boolean']>;
  api_limits_enabled?: InputMaybe<Scalars['Boolean']>;
  autoscaling_enabled?: InputMaybe<Scalars['Boolean']>;
  bucket_capacity?: InputMaybe<Scalars['bigint']>;
  bucket_rate?: InputMaybe<Scalars['bigint']>;
  caching_enabled?: InputMaybe<Scalars['Boolean']>;
  collaborators_enabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  enable_granular_metrics?: InputMaybe<Scalars['Boolean']>;
  enable_model_info_log?: InputMaybe<Scalars['Boolean']>;
  enable_otel_exporter?: InputMaybe<Scalars['Boolean']>;
  high_availability_enabled?: InputMaybe<Scalars['Boolean']>;
  log_retention?: InputMaybe<Scalars['Int']>;
  max_api_limit_roles?: InputMaybe<Scalars['Int']>;
  max_cache_size?: InputMaybe<Scalars['bigint']>;
  max_cache_ttl?: InputMaybe<Scalars['bigint']>;
  max_collaborators?: InputMaybe<Scalars['Int']>;
  max_data_usage?: InputMaybe<Scalars['bigint']>;
  max_read_replicas?: InputMaybe<Scalars['Int']>;
  max_reqs_per_min?: InputMaybe<Scalars['Int']>;
  metrics_enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  operation_retention?: InputMaybe<Scalars['bigint']>;
  read_replicas_enabled?: InputMaybe<Scalars['Boolean']>;
  regression_tests_enabled?: InputMaybe<Scalars['Boolean']>;
  request_time_limit?: InputMaybe<Scalars['Int']>;
  tracing_enabled?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vpc_peering_enabled?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Plans_Stddev_Fields = {
  __typename?: 'plans_stddev_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Plans_Stddev_Pop_Fields = {
  __typename?: 'plans_stddev_pop_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Plans_Stddev_Samp_Fields = {
  __typename?: 'plans_stddev_samp_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "plans" */
export type Plans_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Plans_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Plans_Stream_Cursor_Value_Input = {
  alloc_limit?: InputMaybe<Scalars['Int']>;
  allow_list_enabled?: InputMaybe<Scalars['Boolean']>;
  api_limits_enabled?: InputMaybe<Scalars['Boolean']>;
  autoscaling_enabled?: InputMaybe<Scalars['Boolean']>;
  bucket_capacity?: InputMaybe<Scalars['bigint']>;
  bucket_rate?: InputMaybe<Scalars['bigint']>;
  caching_enabled?: InputMaybe<Scalars['Boolean']>;
  collaborators_enabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  enable_granular_metrics?: InputMaybe<Scalars['Boolean']>;
  enable_model_info_log?: InputMaybe<Scalars['Boolean']>;
  enable_otel_exporter?: InputMaybe<Scalars['Boolean']>;
  high_availability_enabled?: InputMaybe<Scalars['Boolean']>;
  log_retention?: InputMaybe<Scalars['Int']>;
  max_api_limit_roles?: InputMaybe<Scalars['Int']>;
  max_cache_size?: InputMaybe<Scalars['bigint']>;
  max_cache_ttl?: InputMaybe<Scalars['bigint']>;
  max_collaborators?: InputMaybe<Scalars['Int']>;
  max_data_usage?: InputMaybe<Scalars['bigint']>;
  max_read_replicas?: InputMaybe<Scalars['Int']>;
  max_reqs_per_min?: InputMaybe<Scalars['Int']>;
  metrics_enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  operation_retention?: InputMaybe<Scalars['bigint']>;
  read_replicas_enabled?: InputMaybe<Scalars['Boolean']>;
  regression_tests_enabled?: InputMaybe<Scalars['Boolean']>;
  request_time_limit?: InputMaybe<Scalars['Int']>;
  tracing_enabled?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vpc_peering_enabled?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Plans_Sum_Fields = {
  __typename?: 'plans_sum_fields';
  alloc_limit?: Maybe<Scalars['Int']>;
  bucket_capacity?: Maybe<Scalars['bigint']>;
  bucket_rate?: Maybe<Scalars['bigint']>;
  log_retention?: Maybe<Scalars['Int']>;
  max_api_limit_roles?: Maybe<Scalars['Int']>;
  max_cache_size?: Maybe<Scalars['bigint']>;
  max_cache_ttl?: Maybe<Scalars['bigint']>;
  max_collaborators?: Maybe<Scalars['Int']>;
  max_data_usage?: Maybe<Scalars['bigint']>;
  max_read_replicas?: Maybe<Scalars['Int']>;
  max_reqs_per_min?: Maybe<Scalars['Int']>;
  operation_retention?: Maybe<Scalars['bigint']>;
  request_time_limit?: Maybe<Scalars['Int']>;
};

/** update columns of table "plans" */
export enum Plans_Update_Column {
  /** column name */
  AllocLimit = 'alloc_limit',
  /** column name */
  AllowListEnabled = 'allow_list_enabled',
  /** column name */
  ApiLimitsEnabled = 'api_limits_enabled',
  /** column name */
  AutoscalingEnabled = 'autoscaling_enabled',
  /** column name */
  BucketCapacity = 'bucket_capacity',
  /** column name */
  BucketRate = 'bucket_rate',
  /** column name */
  CachingEnabled = 'caching_enabled',
  /** column name */
  CollaboratorsEnabled = 'collaborators_enabled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  EnableGranularMetrics = 'enable_granular_metrics',
  /** column name */
  EnableModelInfoLog = 'enable_model_info_log',
  /** column name */
  EnableOtelExporter = 'enable_otel_exporter',
  /** column name */
  HighAvailabilityEnabled = 'high_availability_enabled',
  /** column name */
  LogRetention = 'log_retention',
  /** column name */
  MaxApiLimitRoles = 'max_api_limit_roles',
  /** column name */
  MaxCacheSize = 'max_cache_size',
  /** column name */
  MaxCacheTtl = 'max_cache_ttl',
  /** column name */
  MaxCollaborators = 'max_collaborators',
  /** column name */
  MaxDataUsage = 'max_data_usage',
  /** column name */
  MaxReadReplicas = 'max_read_replicas',
  /** column name */
  MaxReqsPerMin = 'max_reqs_per_min',
  /** column name */
  MetricsEnabled = 'metrics_enabled',
  /** column name */
  Name = 'name',
  /** column name */
  OperationRetention = 'operation_retention',
  /** column name */
  ReadReplicasEnabled = 'read_replicas_enabled',
  /** column name */
  RegressionTestsEnabled = 'regression_tests_enabled',
  /** column name */
  RequestTimeLimit = 'request_time_limit',
  /** column name */
  TracingEnabled = 'tracing_enabled',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VpcPeeringEnabled = 'vpc_peering_enabled',
}

export type Plans_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Plans_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Plans_Set_Input>;
  /** filter the rows which have to be updated */
  where: Plans_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Plans_Var_Pop_Fields = {
  __typename?: 'plans_var_pop_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Plans_Var_Samp_Fields = {
  __typename?: 'plans_var_samp_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Plans_Variance_Fields = {
  __typename?: 'plans_variance_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** Policies (features) that are enabled for a project */
export type Policies = {
  __typename?: 'policies';
  alloc_limit?: Maybe<Scalars['Int']>;
  allow_list_enabled: Scalars['Boolean'];
  api_limits_enabled: Scalars['Boolean'];
  autoscaling_enabled: Scalars['Boolean'];
  bucket_capacity?: Maybe<Scalars['bigint']>;
  bucket_rate?: Maybe<Scalars['bigint']>;
  caching_enabled: Scalars['Boolean'];
  collaborators_enabled: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  enable_granular_metrics?: Maybe<Scalars['Boolean']>;
  enable_model_info_log: Scalars['Boolean'];
  enable_otel_exporter: Scalars['Boolean'];
  high_availability_enabled: Scalars['Boolean'];
  log_retention: Scalars['Int'];
  max_api_limit_roles?: Maybe<Scalars['Int']>;
  max_cache_size?: Maybe<Scalars['bigint']>;
  max_cache_ttl?: Maybe<Scalars['bigint']>;
  max_collaborators?: Maybe<Scalars['Int']>;
  max_data_usage?: Maybe<Scalars['bigint']>;
  max_read_replicas?: Maybe<Scalars['Int']>;
  max_reqs_per_min?: Maybe<Scalars['Int']>;
  metrics_enabled: Scalars['Boolean'];
  operation_retention: Scalars['bigint'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  read_replicas_enabled: Scalars['Boolean'];
  regression_tests_enabled: Scalars['Boolean'];
  request_time_limit?: Maybe<Scalars['Int']>;
  tracing_enabled: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  vpc_peering_enabled: Scalars['Boolean'];
};

/** aggregated selection of "policies" */
export type Policies_Aggregate = {
  __typename?: 'policies_aggregate';
  aggregate?: Maybe<Policies_Aggregate_Fields>;
  nodes: Array<Policies>;
};

export type Policies_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Policies_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Policies_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Policies_Aggregate_Bool_Exp_Count>;
};

export type Policies_Aggregate_Bool_Exp_Bool_And = {
  arguments: Policies_Select_Column_Policies_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Policies_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Policies_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Policies_Select_Column_Policies_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Policies_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Policies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Policies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Policies_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "policies" */
export type Policies_Aggregate_Fields = {
  __typename?: 'policies_aggregate_fields';
  avg?: Maybe<Policies_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Policies_Max_Fields>;
  min?: Maybe<Policies_Min_Fields>;
  stddev?: Maybe<Policies_Stddev_Fields>;
  stddev_pop?: Maybe<Policies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Policies_Stddev_Samp_Fields>;
  sum?: Maybe<Policies_Sum_Fields>;
  var_pop?: Maybe<Policies_Var_Pop_Fields>;
  var_samp?: Maybe<Policies_Var_Samp_Fields>;
  variance?: Maybe<Policies_Variance_Fields>;
};

/** aggregate fields of "policies" */
export type Policies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "policies" */
export type Policies_Aggregate_Order_By = {
  avg?: InputMaybe<Policies_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policies_Max_Order_By>;
  min?: InputMaybe<Policies_Min_Order_By>;
  stddev?: InputMaybe<Policies_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Policies_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Policies_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Policies_Sum_Order_By>;
  var_pop?: InputMaybe<Policies_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Policies_Var_Samp_Order_By>;
  variance?: InputMaybe<Policies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "policies" */
export type Policies_Arr_Rel_Insert_Input = {
  data: Array<Policies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policies_On_Conflict>;
};

/** aggregate avg on columns */
export type Policies_Avg_Fields = {
  __typename?: 'policies_avg_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "policies" */
export type Policies_Avg_Order_By = {
  alloc_limit?: InputMaybe<Order_By>;
  bucket_capacity?: InputMaybe<Order_By>;
  bucket_rate?: InputMaybe<Order_By>;
  log_retention?: InputMaybe<Order_By>;
  max_api_limit_roles?: InputMaybe<Order_By>;
  max_cache_size?: InputMaybe<Order_By>;
  max_cache_ttl?: InputMaybe<Order_By>;
  max_collaborators?: InputMaybe<Order_By>;
  max_data_usage?: InputMaybe<Order_By>;
  max_read_replicas?: InputMaybe<Order_By>;
  max_reqs_per_min?: InputMaybe<Order_By>;
  operation_retention?: InputMaybe<Order_By>;
  request_time_limit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "policies". All fields are combined with a logical 'AND'. */
export type Policies_Bool_Exp = {
  _and?: InputMaybe<Array<Policies_Bool_Exp>>;
  _not?: InputMaybe<Policies_Bool_Exp>;
  _or?: InputMaybe<Array<Policies_Bool_Exp>>;
  alloc_limit?: InputMaybe<Int_Comparison_Exp>;
  allow_list_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  api_limits_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  autoscaling_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  bucket_capacity?: InputMaybe<Bigint_Comparison_Exp>;
  bucket_rate?: InputMaybe<Bigint_Comparison_Exp>;
  caching_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  collaborators_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  enable_granular_metrics?: InputMaybe<Boolean_Comparison_Exp>;
  enable_model_info_log?: InputMaybe<Boolean_Comparison_Exp>;
  enable_otel_exporter?: InputMaybe<Boolean_Comparison_Exp>;
  high_availability_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  log_retention?: InputMaybe<Int_Comparison_Exp>;
  max_api_limit_roles?: InputMaybe<Int_Comparison_Exp>;
  max_cache_size?: InputMaybe<Bigint_Comparison_Exp>;
  max_cache_ttl?: InputMaybe<Bigint_Comparison_Exp>;
  max_collaborators?: InputMaybe<Int_Comparison_Exp>;
  max_data_usage?: InputMaybe<Bigint_Comparison_Exp>;
  max_read_replicas?: InputMaybe<Int_Comparison_Exp>;
  max_reqs_per_min?: InputMaybe<Int_Comparison_Exp>;
  metrics_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  operation_retention?: InputMaybe<Bigint_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  read_replicas_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  regression_tests_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  request_time_limit?: InputMaybe<Int_Comparison_Exp>;
  tracing_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vpc_peering_enabled?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "policies" */
export enum Policies_Constraint {
  /** unique or primary key constraint on columns "project_id" */
  PoliciesPkey = 'policies_pkey',
}

/** input type for incrementing numeric columns in table "policies" */
export type Policies_Inc_Input = {
  alloc_limit?: InputMaybe<Scalars['Int']>;
  bucket_capacity?: InputMaybe<Scalars['bigint']>;
  bucket_rate?: InputMaybe<Scalars['bigint']>;
  log_retention?: InputMaybe<Scalars['Int']>;
  max_api_limit_roles?: InputMaybe<Scalars['Int']>;
  max_cache_size?: InputMaybe<Scalars['bigint']>;
  max_cache_ttl?: InputMaybe<Scalars['bigint']>;
  max_collaborators?: InputMaybe<Scalars['Int']>;
  max_data_usage?: InputMaybe<Scalars['bigint']>;
  max_read_replicas?: InputMaybe<Scalars['Int']>;
  max_reqs_per_min?: InputMaybe<Scalars['Int']>;
  operation_retention?: InputMaybe<Scalars['bigint']>;
  request_time_limit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "policies" */
export type Policies_Insert_Input = {
  alloc_limit?: InputMaybe<Scalars['Int']>;
  allow_list_enabled?: InputMaybe<Scalars['Boolean']>;
  api_limits_enabled?: InputMaybe<Scalars['Boolean']>;
  autoscaling_enabled?: InputMaybe<Scalars['Boolean']>;
  bucket_capacity?: InputMaybe<Scalars['bigint']>;
  bucket_rate?: InputMaybe<Scalars['bigint']>;
  caching_enabled?: InputMaybe<Scalars['Boolean']>;
  collaborators_enabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enable_granular_metrics?: InputMaybe<Scalars['Boolean']>;
  enable_model_info_log?: InputMaybe<Scalars['Boolean']>;
  enable_otel_exporter?: InputMaybe<Scalars['Boolean']>;
  high_availability_enabled?: InputMaybe<Scalars['Boolean']>;
  log_retention?: InputMaybe<Scalars['Int']>;
  max_api_limit_roles?: InputMaybe<Scalars['Int']>;
  max_cache_size?: InputMaybe<Scalars['bigint']>;
  max_cache_ttl?: InputMaybe<Scalars['bigint']>;
  max_collaborators?: InputMaybe<Scalars['Int']>;
  max_data_usage?: InputMaybe<Scalars['bigint']>;
  max_read_replicas?: InputMaybe<Scalars['Int']>;
  max_reqs_per_min?: InputMaybe<Scalars['Int']>;
  metrics_enabled?: InputMaybe<Scalars['Boolean']>;
  operation_retention?: InputMaybe<Scalars['bigint']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  read_replicas_enabled?: InputMaybe<Scalars['Boolean']>;
  regression_tests_enabled?: InputMaybe<Scalars['Boolean']>;
  request_time_limit?: InputMaybe<Scalars['Int']>;
  tracing_enabled?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vpc_peering_enabled?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Policies_Max_Fields = {
  __typename?: 'policies_max_fields';
  alloc_limit?: Maybe<Scalars['Int']>;
  bucket_capacity?: Maybe<Scalars['bigint']>;
  bucket_rate?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  log_retention?: Maybe<Scalars['Int']>;
  max_api_limit_roles?: Maybe<Scalars['Int']>;
  max_cache_size?: Maybe<Scalars['bigint']>;
  max_cache_ttl?: Maybe<Scalars['bigint']>;
  max_collaborators?: Maybe<Scalars['Int']>;
  max_data_usage?: Maybe<Scalars['bigint']>;
  max_read_replicas?: Maybe<Scalars['Int']>;
  max_reqs_per_min?: Maybe<Scalars['Int']>;
  operation_retention?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  request_time_limit?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "policies" */
export type Policies_Max_Order_By = {
  alloc_limit?: InputMaybe<Order_By>;
  bucket_capacity?: InputMaybe<Order_By>;
  bucket_rate?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  log_retention?: InputMaybe<Order_By>;
  max_api_limit_roles?: InputMaybe<Order_By>;
  max_cache_size?: InputMaybe<Order_By>;
  max_cache_ttl?: InputMaybe<Order_By>;
  max_collaborators?: InputMaybe<Order_By>;
  max_data_usage?: InputMaybe<Order_By>;
  max_read_replicas?: InputMaybe<Order_By>;
  max_reqs_per_min?: InputMaybe<Order_By>;
  operation_retention?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  request_time_limit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Policies_Min_Fields = {
  __typename?: 'policies_min_fields';
  alloc_limit?: Maybe<Scalars['Int']>;
  bucket_capacity?: Maybe<Scalars['bigint']>;
  bucket_rate?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  log_retention?: Maybe<Scalars['Int']>;
  max_api_limit_roles?: Maybe<Scalars['Int']>;
  max_cache_size?: Maybe<Scalars['bigint']>;
  max_cache_ttl?: Maybe<Scalars['bigint']>;
  max_collaborators?: Maybe<Scalars['Int']>;
  max_data_usage?: Maybe<Scalars['bigint']>;
  max_read_replicas?: Maybe<Scalars['Int']>;
  max_reqs_per_min?: Maybe<Scalars['Int']>;
  operation_retention?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  request_time_limit?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "policies" */
export type Policies_Min_Order_By = {
  alloc_limit?: InputMaybe<Order_By>;
  bucket_capacity?: InputMaybe<Order_By>;
  bucket_rate?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  log_retention?: InputMaybe<Order_By>;
  max_api_limit_roles?: InputMaybe<Order_By>;
  max_cache_size?: InputMaybe<Order_By>;
  max_cache_ttl?: InputMaybe<Order_By>;
  max_collaborators?: InputMaybe<Order_By>;
  max_data_usage?: InputMaybe<Order_By>;
  max_read_replicas?: InputMaybe<Order_By>;
  max_reqs_per_min?: InputMaybe<Order_By>;
  operation_retention?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  request_time_limit?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policies" */
export type Policies_Mutation_Response = {
  __typename?: 'policies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Policies>;
};

/** input type for inserting object relation for remote table "policies" */
export type Policies_Obj_Rel_Insert_Input = {
  data: Policies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policies_On_Conflict>;
};

/** on_conflict condition type for table "policies" */
export type Policies_On_Conflict = {
  constraint: Policies_Constraint;
  update_columns?: Array<Policies_Update_Column>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** Ordering options when selecting data from "policies". */
export type Policies_Order_By = {
  alloc_limit?: InputMaybe<Order_By>;
  allow_list_enabled?: InputMaybe<Order_By>;
  api_limits_enabled?: InputMaybe<Order_By>;
  autoscaling_enabled?: InputMaybe<Order_By>;
  bucket_capacity?: InputMaybe<Order_By>;
  bucket_rate?: InputMaybe<Order_By>;
  caching_enabled?: InputMaybe<Order_By>;
  collaborators_enabled?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  enable_granular_metrics?: InputMaybe<Order_By>;
  enable_model_info_log?: InputMaybe<Order_By>;
  enable_otel_exporter?: InputMaybe<Order_By>;
  high_availability_enabled?: InputMaybe<Order_By>;
  log_retention?: InputMaybe<Order_By>;
  max_api_limit_roles?: InputMaybe<Order_By>;
  max_cache_size?: InputMaybe<Order_By>;
  max_cache_ttl?: InputMaybe<Order_By>;
  max_collaborators?: InputMaybe<Order_By>;
  max_data_usage?: InputMaybe<Order_By>;
  max_read_replicas?: InputMaybe<Order_By>;
  max_reqs_per_min?: InputMaybe<Order_By>;
  metrics_enabled?: InputMaybe<Order_By>;
  operation_retention?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  read_replicas_enabled?: InputMaybe<Order_By>;
  regression_tests_enabled?: InputMaybe<Order_By>;
  request_time_limit?: InputMaybe<Order_By>;
  tracing_enabled?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vpc_peering_enabled?: InputMaybe<Order_By>;
};

/** primary key columns input for table: policies */
export type Policies_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
};

/** select columns of table "policies" */
export enum Policies_Select_Column {
  /** column name */
  AllocLimit = 'alloc_limit',
  /** column name */
  AllowListEnabled = 'allow_list_enabled',
  /** column name */
  ApiLimitsEnabled = 'api_limits_enabled',
  /** column name */
  AutoscalingEnabled = 'autoscaling_enabled',
  /** column name */
  BucketCapacity = 'bucket_capacity',
  /** column name */
  BucketRate = 'bucket_rate',
  /** column name */
  CachingEnabled = 'caching_enabled',
  /** column name */
  CollaboratorsEnabled = 'collaborators_enabled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnableGranularMetrics = 'enable_granular_metrics',
  /** column name */
  EnableModelInfoLog = 'enable_model_info_log',
  /** column name */
  EnableOtelExporter = 'enable_otel_exporter',
  /** column name */
  HighAvailabilityEnabled = 'high_availability_enabled',
  /** column name */
  LogRetention = 'log_retention',
  /** column name */
  MaxApiLimitRoles = 'max_api_limit_roles',
  /** column name */
  MaxCacheSize = 'max_cache_size',
  /** column name */
  MaxCacheTtl = 'max_cache_ttl',
  /** column name */
  MaxCollaborators = 'max_collaborators',
  /** column name */
  MaxDataUsage = 'max_data_usage',
  /** column name */
  MaxReadReplicas = 'max_read_replicas',
  /** column name */
  MaxReqsPerMin = 'max_reqs_per_min',
  /** column name */
  MetricsEnabled = 'metrics_enabled',
  /** column name */
  OperationRetention = 'operation_retention',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ReadReplicasEnabled = 'read_replicas_enabled',
  /** column name */
  RegressionTestsEnabled = 'regression_tests_enabled',
  /** column name */
  RequestTimeLimit = 'request_time_limit',
  /** column name */
  TracingEnabled = 'tracing_enabled',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VpcPeeringEnabled = 'vpc_peering_enabled',
}

/** select "policies_aggregate_bool_exp_bool_and_arguments_columns" columns of table "policies" */
export enum Policies_Select_Column_Policies_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AllowListEnabled = 'allow_list_enabled',
  /** column name */
  ApiLimitsEnabled = 'api_limits_enabled',
  /** column name */
  AutoscalingEnabled = 'autoscaling_enabled',
  /** column name */
  CachingEnabled = 'caching_enabled',
  /** column name */
  CollaboratorsEnabled = 'collaborators_enabled',
  /** column name */
  EnableGranularMetrics = 'enable_granular_metrics',
  /** column name */
  EnableModelInfoLog = 'enable_model_info_log',
  /** column name */
  EnableOtelExporter = 'enable_otel_exporter',
  /** column name */
  HighAvailabilityEnabled = 'high_availability_enabled',
  /** column name */
  MetricsEnabled = 'metrics_enabled',
  /** column name */
  ReadReplicasEnabled = 'read_replicas_enabled',
  /** column name */
  RegressionTestsEnabled = 'regression_tests_enabled',
  /** column name */
  TracingEnabled = 'tracing_enabled',
  /** column name */
  VpcPeeringEnabled = 'vpc_peering_enabled',
}

/** select "policies_aggregate_bool_exp_bool_or_arguments_columns" columns of table "policies" */
export enum Policies_Select_Column_Policies_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AllowListEnabled = 'allow_list_enabled',
  /** column name */
  ApiLimitsEnabled = 'api_limits_enabled',
  /** column name */
  AutoscalingEnabled = 'autoscaling_enabled',
  /** column name */
  CachingEnabled = 'caching_enabled',
  /** column name */
  CollaboratorsEnabled = 'collaborators_enabled',
  /** column name */
  EnableGranularMetrics = 'enable_granular_metrics',
  /** column name */
  EnableModelInfoLog = 'enable_model_info_log',
  /** column name */
  EnableOtelExporter = 'enable_otel_exporter',
  /** column name */
  HighAvailabilityEnabled = 'high_availability_enabled',
  /** column name */
  MetricsEnabled = 'metrics_enabled',
  /** column name */
  ReadReplicasEnabled = 'read_replicas_enabled',
  /** column name */
  RegressionTestsEnabled = 'regression_tests_enabled',
  /** column name */
  TracingEnabled = 'tracing_enabled',
  /** column name */
  VpcPeeringEnabled = 'vpc_peering_enabled',
}

/** input type for updating data in table "policies" */
export type Policies_Set_Input = {
  alloc_limit?: InputMaybe<Scalars['Int']>;
  allow_list_enabled?: InputMaybe<Scalars['Boolean']>;
  api_limits_enabled?: InputMaybe<Scalars['Boolean']>;
  autoscaling_enabled?: InputMaybe<Scalars['Boolean']>;
  bucket_capacity?: InputMaybe<Scalars['bigint']>;
  bucket_rate?: InputMaybe<Scalars['bigint']>;
  caching_enabled?: InputMaybe<Scalars['Boolean']>;
  collaborators_enabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enable_granular_metrics?: InputMaybe<Scalars['Boolean']>;
  enable_model_info_log?: InputMaybe<Scalars['Boolean']>;
  enable_otel_exporter?: InputMaybe<Scalars['Boolean']>;
  high_availability_enabled?: InputMaybe<Scalars['Boolean']>;
  log_retention?: InputMaybe<Scalars['Int']>;
  max_api_limit_roles?: InputMaybe<Scalars['Int']>;
  max_cache_size?: InputMaybe<Scalars['bigint']>;
  max_cache_ttl?: InputMaybe<Scalars['bigint']>;
  max_collaborators?: InputMaybe<Scalars['Int']>;
  max_data_usage?: InputMaybe<Scalars['bigint']>;
  max_read_replicas?: InputMaybe<Scalars['Int']>;
  max_reqs_per_min?: InputMaybe<Scalars['Int']>;
  metrics_enabled?: InputMaybe<Scalars['Boolean']>;
  operation_retention?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  read_replicas_enabled?: InputMaybe<Scalars['Boolean']>;
  regression_tests_enabled?: InputMaybe<Scalars['Boolean']>;
  request_time_limit?: InputMaybe<Scalars['Int']>;
  tracing_enabled?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vpc_peering_enabled?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Policies_Stddev_Fields = {
  __typename?: 'policies_stddev_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "policies" */
export type Policies_Stddev_Order_By = {
  alloc_limit?: InputMaybe<Order_By>;
  bucket_capacity?: InputMaybe<Order_By>;
  bucket_rate?: InputMaybe<Order_By>;
  log_retention?: InputMaybe<Order_By>;
  max_api_limit_roles?: InputMaybe<Order_By>;
  max_cache_size?: InputMaybe<Order_By>;
  max_cache_ttl?: InputMaybe<Order_By>;
  max_collaborators?: InputMaybe<Order_By>;
  max_data_usage?: InputMaybe<Order_By>;
  max_read_replicas?: InputMaybe<Order_By>;
  max_reqs_per_min?: InputMaybe<Order_By>;
  operation_retention?: InputMaybe<Order_By>;
  request_time_limit?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Policies_Stddev_Pop_Fields = {
  __typename?: 'policies_stddev_pop_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "policies" */
export type Policies_Stddev_Pop_Order_By = {
  alloc_limit?: InputMaybe<Order_By>;
  bucket_capacity?: InputMaybe<Order_By>;
  bucket_rate?: InputMaybe<Order_By>;
  log_retention?: InputMaybe<Order_By>;
  max_api_limit_roles?: InputMaybe<Order_By>;
  max_cache_size?: InputMaybe<Order_By>;
  max_cache_ttl?: InputMaybe<Order_By>;
  max_collaborators?: InputMaybe<Order_By>;
  max_data_usage?: InputMaybe<Order_By>;
  max_read_replicas?: InputMaybe<Order_By>;
  max_reqs_per_min?: InputMaybe<Order_By>;
  operation_retention?: InputMaybe<Order_By>;
  request_time_limit?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Policies_Stddev_Samp_Fields = {
  __typename?: 'policies_stddev_samp_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "policies" */
export type Policies_Stddev_Samp_Order_By = {
  alloc_limit?: InputMaybe<Order_By>;
  bucket_capacity?: InputMaybe<Order_By>;
  bucket_rate?: InputMaybe<Order_By>;
  log_retention?: InputMaybe<Order_By>;
  max_api_limit_roles?: InputMaybe<Order_By>;
  max_cache_size?: InputMaybe<Order_By>;
  max_cache_ttl?: InputMaybe<Order_By>;
  max_collaborators?: InputMaybe<Order_By>;
  max_data_usage?: InputMaybe<Order_By>;
  max_read_replicas?: InputMaybe<Order_By>;
  max_reqs_per_min?: InputMaybe<Order_By>;
  operation_retention?: InputMaybe<Order_By>;
  request_time_limit?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "policies" */
export type Policies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policies_Stream_Cursor_Value_Input = {
  alloc_limit?: InputMaybe<Scalars['Int']>;
  allow_list_enabled?: InputMaybe<Scalars['Boolean']>;
  api_limits_enabled?: InputMaybe<Scalars['Boolean']>;
  autoscaling_enabled?: InputMaybe<Scalars['Boolean']>;
  bucket_capacity?: InputMaybe<Scalars['bigint']>;
  bucket_rate?: InputMaybe<Scalars['bigint']>;
  caching_enabled?: InputMaybe<Scalars['Boolean']>;
  collaborators_enabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  enable_granular_metrics?: InputMaybe<Scalars['Boolean']>;
  enable_model_info_log?: InputMaybe<Scalars['Boolean']>;
  enable_otel_exporter?: InputMaybe<Scalars['Boolean']>;
  high_availability_enabled?: InputMaybe<Scalars['Boolean']>;
  log_retention?: InputMaybe<Scalars['Int']>;
  max_api_limit_roles?: InputMaybe<Scalars['Int']>;
  max_cache_size?: InputMaybe<Scalars['bigint']>;
  max_cache_ttl?: InputMaybe<Scalars['bigint']>;
  max_collaborators?: InputMaybe<Scalars['Int']>;
  max_data_usage?: InputMaybe<Scalars['bigint']>;
  max_read_replicas?: InputMaybe<Scalars['Int']>;
  max_reqs_per_min?: InputMaybe<Scalars['Int']>;
  metrics_enabled?: InputMaybe<Scalars['Boolean']>;
  operation_retention?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  read_replicas_enabled?: InputMaybe<Scalars['Boolean']>;
  regression_tests_enabled?: InputMaybe<Scalars['Boolean']>;
  request_time_limit?: InputMaybe<Scalars['Int']>;
  tracing_enabled?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vpc_peering_enabled?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Policies_Sum_Fields = {
  __typename?: 'policies_sum_fields';
  alloc_limit?: Maybe<Scalars['Int']>;
  bucket_capacity?: Maybe<Scalars['bigint']>;
  bucket_rate?: Maybe<Scalars['bigint']>;
  log_retention?: Maybe<Scalars['Int']>;
  max_api_limit_roles?: Maybe<Scalars['Int']>;
  max_cache_size?: Maybe<Scalars['bigint']>;
  max_cache_ttl?: Maybe<Scalars['bigint']>;
  max_collaborators?: Maybe<Scalars['Int']>;
  max_data_usage?: Maybe<Scalars['bigint']>;
  max_read_replicas?: Maybe<Scalars['Int']>;
  max_reqs_per_min?: Maybe<Scalars['Int']>;
  operation_retention?: Maybe<Scalars['bigint']>;
  request_time_limit?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "policies" */
export type Policies_Sum_Order_By = {
  alloc_limit?: InputMaybe<Order_By>;
  bucket_capacity?: InputMaybe<Order_By>;
  bucket_rate?: InputMaybe<Order_By>;
  log_retention?: InputMaybe<Order_By>;
  max_api_limit_roles?: InputMaybe<Order_By>;
  max_cache_size?: InputMaybe<Order_By>;
  max_cache_ttl?: InputMaybe<Order_By>;
  max_collaborators?: InputMaybe<Order_By>;
  max_data_usage?: InputMaybe<Order_By>;
  max_read_replicas?: InputMaybe<Order_By>;
  max_reqs_per_min?: InputMaybe<Order_By>;
  operation_retention?: InputMaybe<Order_By>;
  request_time_limit?: InputMaybe<Order_By>;
};

/** update columns of table "policies" */
export enum Policies_Update_Column {
  /** column name */
  AllocLimit = 'alloc_limit',
  /** column name */
  AllowListEnabled = 'allow_list_enabled',
  /** column name */
  ApiLimitsEnabled = 'api_limits_enabled',
  /** column name */
  AutoscalingEnabled = 'autoscaling_enabled',
  /** column name */
  BucketCapacity = 'bucket_capacity',
  /** column name */
  BucketRate = 'bucket_rate',
  /** column name */
  CachingEnabled = 'caching_enabled',
  /** column name */
  CollaboratorsEnabled = 'collaborators_enabled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnableGranularMetrics = 'enable_granular_metrics',
  /** column name */
  EnableModelInfoLog = 'enable_model_info_log',
  /** column name */
  EnableOtelExporter = 'enable_otel_exporter',
  /** column name */
  HighAvailabilityEnabled = 'high_availability_enabled',
  /** column name */
  LogRetention = 'log_retention',
  /** column name */
  MaxApiLimitRoles = 'max_api_limit_roles',
  /** column name */
  MaxCacheSize = 'max_cache_size',
  /** column name */
  MaxCacheTtl = 'max_cache_ttl',
  /** column name */
  MaxCollaborators = 'max_collaborators',
  /** column name */
  MaxDataUsage = 'max_data_usage',
  /** column name */
  MaxReadReplicas = 'max_read_replicas',
  /** column name */
  MaxReqsPerMin = 'max_reqs_per_min',
  /** column name */
  MetricsEnabled = 'metrics_enabled',
  /** column name */
  OperationRetention = 'operation_retention',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ReadReplicasEnabled = 'read_replicas_enabled',
  /** column name */
  RegressionTestsEnabled = 'regression_tests_enabled',
  /** column name */
  RequestTimeLimit = 'request_time_limit',
  /** column name */
  TracingEnabled = 'tracing_enabled',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VpcPeeringEnabled = 'vpc_peering_enabled',
}

export type Policies_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Policies_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policies_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Policies_Var_Pop_Fields = {
  __typename?: 'policies_var_pop_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "policies" */
export type Policies_Var_Pop_Order_By = {
  alloc_limit?: InputMaybe<Order_By>;
  bucket_capacity?: InputMaybe<Order_By>;
  bucket_rate?: InputMaybe<Order_By>;
  log_retention?: InputMaybe<Order_By>;
  max_api_limit_roles?: InputMaybe<Order_By>;
  max_cache_size?: InputMaybe<Order_By>;
  max_cache_ttl?: InputMaybe<Order_By>;
  max_collaborators?: InputMaybe<Order_By>;
  max_data_usage?: InputMaybe<Order_By>;
  max_read_replicas?: InputMaybe<Order_By>;
  max_reqs_per_min?: InputMaybe<Order_By>;
  operation_retention?: InputMaybe<Order_By>;
  request_time_limit?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Policies_Var_Samp_Fields = {
  __typename?: 'policies_var_samp_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "policies" */
export type Policies_Var_Samp_Order_By = {
  alloc_limit?: InputMaybe<Order_By>;
  bucket_capacity?: InputMaybe<Order_By>;
  bucket_rate?: InputMaybe<Order_By>;
  log_retention?: InputMaybe<Order_By>;
  max_api_limit_roles?: InputMaybe<Order_By>;
  max_cache_size?: InputMaybe<Order_By>;
  max_cache_ttl?: InputMaybe<Order_By>;
  max_collaborators?: InputMaybe<Order_By>;
  max_data_usage?: InputMaybe<Order_By>;
  max_read_replicas?: InputMaybe<Order_By>;
  max_reqs_per_min?: InputMaybe<Order_By>;
  operation_retention?: InputMaybe<Order_By>;
  request_time_limit?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Policies_Variance_Fields = {
  __typename?: 'policies_variance_fields';
  alloc_limit?: Maybe<Scalars['Float']>;
  bucket_capacity?: Maybe<Scalars['Float']>;
  bucket_rate?: Maybe<Scalars['Float']>;
  log_retention?: Maybe<Scalars['Float']>;
  max_api_limit_roles?: Maybe<Scalars['Float']>;
  max_cache_size?: Maybe<Scalars['Float']>;
  max_cache_ttl?: Maybe<Scalars['Float']>;
  max_collaborators?: Maybe<Scalars['Float']>;
  max_data_usage?: Maybe<Scalars['Float']>;
  max_read_replicas?: Maybe<Scalars['Float']>;
  max_reqs_per_min?: Maybe<Scalars['Float']>;
  operation_retention?: Maybe<Scalars['Float']>;
  request_time_limit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "policies" */
export type Policies_Variance_Order_By = {
  alloc_limit?: InputMaybe<Order_By>;
  bucket_capacity?: InputMaybe<Order_By>;
  bucket_rate?: InputMaybe<Order_By>;
  log_retention?: InputMaybe<Order_By>;
  max_api_limit_roles?: InputMaybe<Order_By>;
  max_cache_size?: InputMaybe<Order_By>;
  max_cache_ttl?: InputMaybe<Order_By>;
  max_collaborators?: InputMaybe<Order_By>;
  max_data_usage?: InputMaybe<Order_By>;
  max_read_replicas?: InputMaybe<Order_By>;
  max_reqs_per_min?: InputMaybe<Order_By>;
  operation_retention?: InputMaybe<Order_By>;
  request_time_limit?: InputMaybe<Order_By>;
};

/** Available privileges (e.g. admin) for Hasura Cloud */
export type Privileges = {
  __typename?: 'privileges';
  description?: Maybe<Scalars['String']>;
  display_order: Scalars['Int'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

/** aggregated selection of "privileges" */
export type Privileges_Aggregate = {
  __typename?: 'privileges_aggregate';
  aggregate?: Maybe<Privileges_Aggregate_Fields>;
  nodes: Array<Privileges>;
};

/** aggregate fields of "privileges" */
export type Privileges_Aggregate_Fields = {
  __typename?: 'privileges_aggregate_fields';
  avg?: Maybe<Privileges_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Privileges_Max_Fields>;
  min?: Maybe<Privileges_Min_Fields>;
  stddev?: Maybe<Privileges_Stddev_Fields>;
  stddev_pop?: Maybe<Privileges_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Privileges_Stddev_Samp_Fields>;
  sum?: Maybe<Privileges_Sum_Fields>;
  var_pop?: Maybe<Privileges_Var_Pop_Fields>;
  var_samp?: Maybe<Privileges_Var_Samp_Fields>;
  variance?: Maybe<Privileges_Variance_Fields>;
};

/** aggregate fields of "privileges" */
export type Privileges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Privileges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Privileges_Avg_Fields = {
  __typename?: 'privileges_avg_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "privileges". All fields are combined with a logical 'AND'. */
export type Privileges_Bool_Exp = {
  _and?: InputMaybe<Array<Privileges_Bool_Exp>>;
  _not?: InputMaybe<Privileges_Bool_Exp>;
  _or?: InputMaybe<Array<Privileges_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  display_order?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "privileges" */
export enum Privileges_Constraint {
  /** unique or primary key constraint on columns "slug" */
  PrivilegesPkey = 'privileges_pkey',
}

/** input type for incrementing numeric columns in table "privileges" */
export type Privileges_Inc_Input = {
  display_order?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "privileges" */
export type Privileges_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  display_order?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Privileges_Max_Fields = {
  __typename?: 'privileges_max_fields';
  description?: Maybe<Scalars['String']>;
  display_order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Privileges_Min_Fields = {
  __typename?: 'privileges_min_fields';
  description?: Maybe<Scalars['String']>;
  display_order?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "privileges" */
export type Privileges_Mutation_Response = {
  __typename?: 'privileges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Privileges>;
};

/** on_conflict condition type for table "privileges" */
export type Privileges_On_Conflict = {
  constraint: Privileges_Constraint;
  update_columns?: Array<Privileges_Update_Column>;
  where?: InputMaybe<Privileges_Bool_Exp>;
};

/** Ordering options when selecting data from "privileges". */
export type Privileges_Order_By = {
  description?: InputMaybe<Order_By>;
  display_order?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** primary key columns input for table: privileges */
export type Privileges_Pk_Columns_Input = {
  slug: Scalars['String'];
};

/** select columns of table "privileges" */
export enum Privileges_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
}

/** input type for updating data in table "privileges" */
export type Privileges_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  display_order?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Privileges_Stddev_Fields = {
  __typename?: 'privileges_stddev_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Privileges_Stddev_Pop_Fields = {
  __typename?: 'privileges_stddev_pop_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Privileges_Stddev_Samp_Fields = {
  __typename?: 'privileges_stddev_samp_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "privileges" */
export type Privileges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Privileges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Privileges_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  display_order?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Privileges_Sum_Fields = {
  __typename?: 'privileges_sum_fields';
  display_order?: Maybe<Scalars['Int']>;
};

/** update columns of table "privileges" */
export enum Privileges_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
}

export type Privileges_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Privileges_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Privileges_Set_Input>;
  /** filter the rows which have to be updated */
  where: Privileges_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Privileges_Var_Pop_Fields = {
  __typename?: 'privileges_var_pop_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Privileges_Var_Samp_Fields = {
  __typename?: 'privileges_var_samp_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Privileges_Variance_Fields = {
  __typename?: 'privileges_variance_fields';
  display_order?: Maybe<Scalars['Float']>;
};

/** Pro License Keys generated for EE Lite */
export type Pro_License_Keys = {
  __typename?: 'pro_license_keys';
  created_at: Scalars['timestamptz'];
  created_by: Scalars['uuid'];
  customer_email: Scalars['String'];
  customer_name: Scalars['String'];
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  license_key: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  /** Token Lifetime in days */
  validity: Scalars['Int'];
};

/** aggregated selection of "pro_license_keys" */
export type Pro_License_Keys_Aggregate = {
  __typename?: 'pro_license_keys_aggregate';
  aggregate?: Maybe<Pro_License_Keys_Aggregate_Fields>;
  nodes: Array<Pro_License_Keys>;
};

/** aggregate fields of "pro_license_keys" */
export type Pro_License_Keys_Aggregate_Fields = {
  __typename?: 'pro_license_keys_aggregate_fields';
  avg?: Maybe<Pro_License_Keys_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Pro_License_Keys_Max_Fields>;
  min?: Maybe<Pro_License_Keys_Min_Fields>;
  stddev?: Maybe<Pro_License_Keys_Stddev_Fields>;
  stddev_pop?: Maybe<Pro_License_Keys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pro_License_Keys_Stddev_Samp_Fields>;
  sum?: Maybe<Pro_License_Keys_Sum_Fields>;
  var_pop?: Maybe<Pro_License_Keys_Var_Pop_Fields>;
  var_samp?: Maybe<Pro_License_Keys_Var_Samp_Fields>;
  variance?: Maybe<Pro_License_Keys_Variance_Fields>;
};

/** aggregate fields of "pro_license_keys" */
export type Pro_License_Keys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pro_License_Keys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Pro_License_Keys_Avg_Fields = {
  __typename?: 'pro_license_keys_avg_fields';
  /** Token Lifetime in days */
  validity?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "pro_license_keys". All fields are combined with a logical 'AND'. */
export type Pro_License_Keys_Bool_Exp = {
  _and?: InputMaybe<Array<Pro_License_Keys_Bool_Exp>>;
  _not?: InputMaybe<Pro_License_Keys_Bool_Exp>;
  _or?: InputMaybe<Array<Pro_License_Keys_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  customer_email?: InputMaybe<String_Comparison_Exp>;
  customer_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  license_key?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  validity?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "pro_license_keys" */
export enum Pro_License_Keys_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProLicenseKeysPkey = 'pro_license_keys_pkey',
}

/** input type for incrementing numeric columns in table "pro_license_keys" */
export type Pro_License_Keys_Inc_Input = {
  /** Token Lifetime in days */
  validity?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "pro_license_keys" */
export type Pro_License_Keys_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  customer_email?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  license_key?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  /** Token Lifetime in days */
  validity?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Pro_License_Keys_Max_Fields = {
  __typename?: 'pro_license_keys_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  license_key?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  /** Token Lifetime in days */
  validity?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Pro_License_Keys_Min_Fields = {
  __typename?: 'pro_license_keys_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['uuid']>;
  customer_email?: Maybe<Scalars['String']>;
  customer_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  license_key?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  /** Token Lifetime in days */
  validity?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "pro_license_keys" */
export type Pro_License_Keys_Mutation_Response = {
  __typename?: 'pro_license_keys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pro_License_Keys>;
};

/** on_conflict condition type for table "pro_license_keys" */
export type Pro_License_Keys_On_Conflict = {
  constraint: Pro_License_Keys_Constraint;
  update_columns?: Array<Pro_License_Keys_Update_Column>;
  where?: InputMaybe<Pro_License_Keys_Bool_Exp>;
};

/** Ordering options when selecting data from "pro_license_keys". */
export type Pro_License_Keys_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  customer_email?: InputMaybe<Order_By>;
  customer_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  license_key?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  validity?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pro_license_keys */
export type Pro_License_Keys_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "pro_license_keys" */
export enum Pro_License_Keys_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LicenseKey = 'license_key',
  /** column name */
  Notes = 'notes',
  /** column name */
  Validity = 'validity',
}

/** input type for updating data in table "pro_license_keys" */
export type Pro_License_Keys_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  customer_email?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  license_key?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  /** Token Lifetime in days */
  validity?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Pro_License_Keys_Stddev_Fields = {
  __typename?: 'pro_license_keys_stddev_fields';
  /** Token Lifetime in days */
  validity?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Pro_License_Keys_Stddev_Pop_Fields = {
  __typename?: 'pro_license_keys_stddev_pop_fields';
  /** Token Lifetime in days */
  validity?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Pro_License_Keys_Stddev_Samp_Fields = {
  __typename?: 'pro_license_keys_stddev_samp_fields';
  /** Token Lifetime in days */
  validity?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "pro_license_keys" */
export type Pro_License_Keys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pro_License_Keys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pro_License_Keys_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['uuid']>;
  customer_email?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  license_key?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  /** Token Lifetime in days */
  validity?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Pro_License_Keys_Sum_Fields = {
  __typename?: 'pro_license_keys_sum_fields';
  /** Token Lifetime in days */
  validity?: Maybe<Scalars['Int']>;
};

/** update columns of table "pro_license_keys" */
export enum Pro_License_Keys_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  CustomerName = 'customer_name',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LicenseKey = 'license_key',
  /** column name */
  Notes = 'notes',
  /** column name */
  Validity = 'validity',
}

export type Pro_License_Keys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Pro_License_Keys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pro_License_Keys_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pro_License_Keys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Pro_License_Keys_Var_Pop_Fields = {
  __typename?: 'pro_license_keys_var_pop_fields';
  /** Token Lifetime in days */
  validity?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Pro_License_Keys_Var_Samp_Fields = {
  __typename?: 'pro_license_keys_var_samp_fields';
  /** Token Lifetime in days */
  validity?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Pro_License_Keys_Variance_Fields = {
  __typename?: 'pro_license_keys_variance_fields';
  /** Token Lifetime in days */
  validity?: Maybe<Scalars['Float']>;
};

/** Table to capture project activity (features, plan etc.) */
export type Project_Activity = {
  __typename?: 'project_activity';
  activity_info?: Maybe<Scalars['String']>;
  activity_type?: Maybe<Scalars['String']>;
  changed_by_user_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  feature?: Maybe<Scalars['String']>;
  feature_enabled?: Maybe<Scalars['Boolean']>;
  feature_limit?: Maybe<Scalars['Int']>;
  id: Scalars['uuid'];
  is_feature_update?: Maybe<Scalars['Boolean']>;
  meta: Scalars['jsonb'];
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  status: Scalars['project_activity_status_enum'];
  /** An object relationship */
  user?: Maybe<Users>;
  /** user ID of the user to be billed for the plan/feature change */
  user_id: Scalars['uuid'];
};

/** Table to capture project activity (features, plan etc.) */
export type Project_ActivityMetaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "project_activity" */
export type Project_Activity_Aggregate = {
  __typename?: 'project_activity_aggregate';
  aggregate?: Maybe<Project_Activity_Aggregate_Fields>;
  nodes: Array<Project_Activity>;
};

export type Project_Activity_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Project_Activity_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Project_Activity_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Project_Activity_Aggregate_Bool_Exp_Count>;
};

export type Project_Activity_Aggregate_Bool_Exp_Bool_And = {
  arguments: Project_Activity_Select_Column_Project_Activity_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Activity_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Activity_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Project_Activity_Select_Column_Project_Activity_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Activity_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Project_Activity_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Activity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Activity_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_activity" */
export type Project_Activity_Aggregate_Fields = {
  __typename?: 'project_activity_aggregate_fields';
  avg?: Maybe<Project_Activity_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Activity_Max_Fields>;
  min?: Maybe<Project_Activity_Min_Fields>;
  stddev?: Maybe<Project_Activity_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Activity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Activity_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Activity_Sum_Fields>;
  var_pop?: Maybe<Project_Activity_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Activity_Var_Samp_Fields>;
  variance?: Maybe<Project_Activity_Variance_Fields>;
};

/** aggregate fields of "project_activity" */
export type Project_Activity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Activity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_activity" */
export type Project_Activity_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Activity_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Activity_Max_Order_By>;
  min?: InputMaybe<Project_Activity_Min_Order_By>;
  stddev?: InputMaybe<Project_Activity_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Activity_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_Activity_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_Activity_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Activity_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Activity_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Activity_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Project_Activity_Append_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "project_activity" */
export type Project_Activity_Arr_Rel_Insert_Input = {
  data: Array<Project_Activity_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Activity_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Activity_Avg_Fields = {
  __typename?: 'project_activity_avg_fields';
  feature_limit?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_activity" */
export type Project_Activity_Avg_Order_By = {
  feature_limit?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_activity". All fields are combined with a logical 'AND'. */
export type Project_Activity_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Activity_Bool_Exp>>;
  _not?: InputMaybe<Project_Activity_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Activity_Bool_Exp>>;
  activity_info?: InputMaybe<String_Comparison_Exp>;
  activity_type?: InputMaybe<String_Comparison_Exp>;
  changed_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  feature?: InputMaybe<String_Comparison_Exp>;
  feature_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  feature_limit?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_feature_update?: InputMaybe<Boolean_Comparison_Exp>;
  meta?: InputMaybe<Jsonb_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Project_Activity_Status_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_activity" */
export enum Project_Activity_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectActivityPkey = 'project_activity_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Project_Activity_Delete_At_Path_Input = {
  meta?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Project_Activity_Delete_Elem_Input = {
  meta?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Project_Activity_Delete_Key_Input = {
  meta?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "project_activity" */
export type Project_Activity_Inc_Input = {
  feature_limit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_activity" */
export type Project_Activity_Insert_Input = {
  activity_info?: InputMaybe<Scalars['String']>;
  activity_type?: InputMaybe<Scalars['String']>;
  changed_by_user_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  feature?: InputMaybe<Scalars['String']>;
  feature_enabled?: InputMaybe<Scalars['Boolean']>;
  feature_limit?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_feature_update?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['project_activity_status_enum']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  /** user ID of the user to be billed for the plan/feature change */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Activity_Max_Fields = {
  __typename?: 'project_activity_max_fields';
  activity_info?: Maybe<Scalars['String']>;
  activity_type?: Maybe<Scalars['String']>;
  changed_by_user_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['String']>;
  feature_limit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['project_activity_status_enum']>;
  /** user ID of the user to be billed for the plan/feature change */
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_activity" */
export type Project_Activity_Max_Order_By = {
  activity_info?: InputMaybe<Order_By>;
  activity_type?: InputMaybe<Order_By>;
  changed_by_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  feature_limit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  /** user ID of the user to be billed for the plan/feature change */
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Activity_Min_Fields = {
  __typename?: 'project_activity_min_fields';
  activity_info?: Maybe<Scalars['String']>;
  activity_type?: Maybe<Scalars['String']>;
  changed_by_user_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  feature?: Maybe<Scalars['String']>;
  feature_limit?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['project_activity_status_enum']>;
  /** user ID of the user to be billed for the plan/feature change */
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project_activity" */
export type Project_Activity_Min_Order_By = {
  activity_info?: InputMaybe<Order_By>;
  activity_type?: InputMaybe<Order_By>;
  changed_by_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  feature_limit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  /** user ID of the user to be billed for the plan/feature change */
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_activity" */
export type Project_Activity_Mutation_Response = {
  __typename?: 'project_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Activity>;
};

/** on_conflict condition type for table "project_activity" */
export type Project_Activity_On_Conflict = {
  constraint: Project_Activity_Constraint;
  update_columns?: Array<Project_Activity_Update_Column>;
  where?: InputMaybe<Project_Activity_Bool_Exp>;
};

/** Ordering options when selecting data from "project_activity". */
export type Project_Activity_Order_By = {
  activity_info?: InputMaybe<Order_By>;
  activity_type?: InputMaybe<Order_By>;
  changed_by_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  feature?: InputMaybe<Order_By>;
  feature_enabled?: InputMaybe<Order_By>;
  feature_limit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_feature_update?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_activity */
export type Project_Activity_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Project_Activity_Prepend_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "project_activity" */
export enum Project_Activity_Select_Column {
  /** column name */
  ActivityInfo = 'activity_info',
  /** column name */
  ActivityType = 'activity_type',
  /** column name */
  ChangedByUserId = 'changed_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Feature = 'feature',
  /** column name */
  FeatureEnabled = 'feature_enabled',
  /** column name */
  FeatureLimit = 'feature_limit',
  /** column name */
  Id = 'id',
  /** column name */
  IsFeatureUpdate = 'is_feature_update',
  /** column name */
  Meta = 'meta',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id',
}

/** select "project_activity_aggregate_bool_exp_bool_and_arguments_columns" columns of table "project_activity" */
export enum Project_Activity_Select_Column_Project_Activity_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FeatureEnabled = 'feature_enabled',
  /** column name */
  IsFeatureUpdate = 'is_feature_update',
}

/** select "project_activity_aggregate_bool_exp_bool_or_arguments_columns" columns of table "project_activity" */
export enum Project_Activity_Select_Column_Project_Activity_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FeatureEnabled = 'feature_enabled',
  /** column name */
  IsFeatureUpdate = 'is_feature_update',
}

/** input type for updating data in table "project_activity" */
export type Project_Activity_Set_Input = {
  activity_info?: InputMaybe<Scalars['String']>;
  activity_type?: InputMaybe<Scalars['String']>;
  changed_by_user_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  feature?: InputMaybe<Scalars['String']>;
  feature_enabled?: InputMaybe<Scalars['Boolean']>;
  feature_limit?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_feature_update?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['project_activity_status_enum']>;
  /** user ID of the user to be billed for the plan/feature change */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Boolean expression to compare columns of type "project_activity_status_enum". All fields are combined with logical 'AND'. */
export type Project_Activity_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['project_activity_status_enum']>;
  _gt?: InputMaybe<Scalars['project_activity_status_enum']>;
  _gte?: InputMaybe<Scalars['project_activity_status_enum']>;
  _in?: InputMaybe<Array<Scalars['project_activity_status_enum']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['project_activity_status_enum']>;
  _lte?: InputMaybe<Scalars['project_activity_status_enum']>;
  _neq?: InputMaybe<Scalars['project_activity_status_enum']>;
  _nin?: InputMaybe<Array<Scalars['project_activity_status_enum']>>;
};

/** aggregate stddev on columns */
export type Project_Activity_Stddev_Fields = {
  __typename?: 'project_activity_stddev_fields';
  feature_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_activity" */
export type Project_Activity_Stddev_Order_By = {
  feature_limit?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Activity_Stddev_Pop_Fields = {
  __typename?: 'project_activity_stddev_pop_fields';
  feature_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_activity" */
export type Project_Activity_Stddev_Pop_Order_By = {
  feature_limit?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Activity_Stddev_Samp_Fields = {
  __typename?: 'project_activity_stddev_samp_fields';
  feature_limit?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_activity" */
export type Project_Activity_Stddev_Samp_Order_By = {
  feature_limit?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project_activity" */
export type Project_Activity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Activity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Activity_Stream_Cursor_Value_Input = {
  activity_info?: InputMaybe<Scalars['String']>;
  activity_type?: InputMaybe<Scalars['String']>;
  changed_by_user_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  feature?: InputMaybe<Scalars['String']>;
  feature_enabled?: InputMaybe<Scalars['Boolean']>;
  feature_limit?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_feature_update?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['project_activity_status_enum']>;
  /** user ID of the user to be billed for the plan/feature change */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Project_Activity_Sum_Fields = {
  __typename?: 'project_activity_sum_fields';
  feature_limit?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project_activity" */
export type Project_Activity_Sum_Order_By = {
  feature_limit?: InputMaybe<Order_By>;
};

/** update columns of table "project_activity" */
export enum Project_Activity_Update_Column {
  /** column name */
  ActivityInfo = 'activity_info',
  /** column name */
  ActivityType = 'activity_type',
  /** column name */
  ChangedByUserId = 'changed_by_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Feature = 'feature',
  /** column name */
  FeatureEnabled = 'feature_enabled',
  /** column name */
  FeatureLimit = 'feature_limit',
  /** column name */
  Id = 'id',
  /** column name */
  IsFeatureUpdate = 'is_feature_update',
  /** column name */
  Meta = 'meta',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  UserId = 'user_id',
}

export type Project_Activity_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Project_Activity_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Project_Activity_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Project_Activity_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Project_Activity_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Activity_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Project_Activity_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Activity_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Activity_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Activity_Var_Pop_Fields = {
  __typename?: 'project_activity_var_pop_fields';
  feature_limit?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_activity" */
export type Project_Activity_Var_Pop_Order_By = {
  feature_limit?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Activity_Var_Samp_Fields = {
  __typename?: 'project_activity_var_samp_fields';
  feature_limit?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_activity" */
export type Project_Activity_Var_Samp_Order_By = {
  feature_limit?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Activity_Variance_Fields = {
  __typename?: 'project_activity_variance_fields';
  feature_limit?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_activity" */
export type Project_Activity_Variance_Order_By = {
  feature_limit?: InputMaybe<Order_By>;
};

/** columns and relationships of "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations = {
  __typename?: 'project_billing_manager_invitations';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  declined_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  invited_at: Scalars['timestamptz'];
  invited_by: Scalars['uuid'];
  /** An object relationship */
  invited_by_user: Users;
  /** An object relationship */
  invited_manager?: Maybe<Users>;
  key: Scalars['uuid'];
  manager_email: Scalars['String'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  resend_invitation_count: Scalars['Int'];
};

/** aggregated selection of "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Aggregate = {
  __typename?: 'project_billing_manager_invitations_aggregate';
  aggregate?: Maybe<Project_Billing_Manager_Invitations_Aggregate_Fields>;
  nodes: Array<Project_Billing_Manager_Invitations>;
};

export type Project_Billing_Manager_Invitations_Aggregate_Bool_Exp = {
  count?: InputMaybe<
    Project_Billing_Manager_Invitations_Aggregate_Bool_Exp_Count
  >;
};

export type Project_Billing_Manager_Invitations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Project_Billing_Manager_Invitations_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Billing_Manager_Invitations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Aggregate_Fields = {
  __typename?: 'project_billing_manager_invitations_aggregate_fields';
  avg?: Maybe<Project_Billing_Manager_Invitations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Billing_Manager_Invitations_Max_Fields>;
  min?: Maybe<Project_Billing_Manager_Invitations_Min_Fields>;
  stddev?: Maybe<Project_Billing_Manager_Invitations_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Billing_Manager_Invitations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Billing_Manager_Invitations_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Billing_Manager_Invitations_Sum_Fields>;
  var_pop?: Maybe<Project_Billing_Manager_Invitations_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Billing_Manager_Invitations_Var_Samp_Fields>;
  variance?: Maybe<Project_Billing_Manager_Invitations_Variance_Fields>;
};

/** aggregate fields of "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Project_Billing_Manager_Invitations_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Billing_Manager_Invitations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Billing_Manager_Invitations_Max_Order_By>;
  min?: InputMaybe<Project_Billing_Manager_Invitations_Min_Order_By>;
  stddev?: InputMaybe<Project_Billing_Manager_Invitations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<
    Project_Billing_Manager_Invitations_Stddev_Pop_Order_By
  >;
  stddev_samp?: InputMaybe<
    Project_Billing_Manager_Invitations_Stddev_Samp_Order_By
  >;
  sum?: InputMaybe<Project_Billing_Manager_Invitations_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Billing_Manager_Invitations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Billing_Manager_Invitations_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Billing_Manager_Invitations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Arr_Rel_Insert_Input = {
  data: Array<Project_Billing_Manager_Invitations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Billing_Manager_Invitations_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Billing_Manager_Invitations_Avg_Fields = {
  __typename?: 'project_billing_manager_invitations_avg_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Avg_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_billing_manager_invitations". All fields are combined with a logical 'AND'. */
export type Project_Billing_Manager_Invitations_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Billing_Manager_Invitations_Bool_Exp>>;
  _not?: InputMaybe<Project_Billing_Manager_Invitations_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Billing_Manager_Invitations_Bool_Exp>>;
  accepted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  declined_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invited_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  invited_by?: InputMaybe<Uuid_Comparison_Exp>;
  invited_by_user?: InputMaybe<Users_Bool_Exp>;
  invited_manager?: InputMaybe<Users_Bool_Exp>;
  key?: InputMaybe<Uuid_Comparison_Exp>;
  manager_email?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  resend_invitation_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_billing_manager_invitations" */
export enum Project_Billing_Manager_Invitations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectBillingManagerInvitationsPkey = 'project_billing_manager_invitations_pkey',
}

/** input type for incrementing numeric columns in table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Inc_Input = {
  resend_invitation_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Insert_Input = {
  accepted_at?: InputMaybe<Scalars['timestamptz']>;
  declined_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  invited_by_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  invited_manager?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  key?: InputMaybe<Scalars['uuid']>;
  manager_email?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  resend_invitation_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Project_Billing_Manager_Invitations_Max_Fields = {
  __typename?: 'project_billing_manager_invitations_max_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  declined_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invited_at?: Maybe<Scalars['timestamptz']>;
  invited_by?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['uuid']>;
  manager_email?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  resend_invitation_count?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Max_Order_By = {
  accepted_at?: InputMaybe<Order_By>;
  declined_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  manager_email?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Billing_Manager_Invitations_Min_Fields = {
  __typename?: 'project_billing_manager_invitations_min_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  declined_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invited_at?: Maybe<Scalars['timestamptz']>;
  invited_by?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['uuid']>;
  manager_email?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  resend_invitation_count?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Min_Order_By = {
  accepted_at?: InputMaybe<Order_By>;
  declined_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  manager_email?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Mutation_Response = {
  __typename?: 'project_billing_manager_invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Billing_Manager_Invitations>;
};

/** on_conflict condition type for table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_On_Conflict = {
  constraint: Project_Billing_Manager_Invitations_Constraint;
  update_columns?: Array<Project_Billing_Manager_Invitations_Update_Column>;
  where?: InputMaybe<Project_Billing_Manager_Invitations_Bool_Exp>;
};

/** Ordering options when selecting data from "project_billing_manager_invitations". */
export type Project_Billing_Manager_Invitations_Order_By = {
  accepted_at?: InputMaybe<Order_By>;
  declined_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  invited_by_user?: InputMaybe<Users_Order_By>;
  invited_manager?: InputMaybe<Users_Order_By>;
  key?: InputMaybe<Order_By>;
  manager_email?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_billing_manager_invitations */
export type Project_Billing_Manager_Invitations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_billing_manager_invitations" */
export enum Project_Billing_Manager_Invitations_Select_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  DeclinedAt = 'declined_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedAt = 'invited_at',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  Key = 'key',
  /** column name */
  ManagerEmail = 'manager_email',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ResendInvitationCount = 'resend_invitation_count',
}

/** input type for updating data in table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Set_Input = {
  accepted_at?: InputMaybe<Scalars['timestamptz']>;
  declined_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['uuid']>;
  manager_email?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  resend_invitation_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Project_Billing_Manager_Invitations_Stddev_Fields = {
  __typename?: 'project_billing_manager_invitations_stddev_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Stddev_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Billing_Manager_Invitations_Stddev_Pop_Fields = {
  __typename?: 'project_billing_manager_invitations_stddev_pop_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Stddev_Pop_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Billing_Manager_Invitations_Stddev_Samp_Fields = {
  __typename?: 'project_billing_manager_invitations_stddev_samp_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Stddev_Samp_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Billing_Manager_Invitations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Billing_Manager_Invitations_Stream_Cursor_Value_Input = {
  accepted_at?: InputMaybe<Scalars['timestamptz']>;
  declined_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['uuid']>;
  manager_email?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  resend_invitation_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Project_Billing_Manager_Invitations_Sum_Fields = {
  __typename?: 'project_billing_manager_invitations_sum_fields';
  resend_invitation_count?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Sum_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** update columns of table "project_billing_manager_invitations" */
export enum Project_Billing_Manager_Invitations_Update_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  DeclinedAt = 'declined_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedAt = 'invited_at',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  Key = 'key',
  /** column name */
  ManagerEmail = 'manager_email',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ResendInvitationCount = 'resend_invitation_count',
}

export type Project_Billing_Manager_Invitations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Billing_Manager_Invitations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Billing_Manager_Invitations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Billing_Manager_Invitations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Billing_Manager_Invitations_Var_Pop_Fields = {
  __typename?: 'project_billing_manager_invitations_var_pop_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Var_Pop_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Billing_Manager_Invitations_Var_Samp_Fields = {
  __typename?: 'project_billing_manager_invitations_var_samp_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Var_Samp_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Billing_Manager_Invitations_Variance_Fields = {
  __typename?: 'project_billing_manager_invitations_variance_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_billing_manager_invitations" */
export type Project_Billing_Manager_Invitations_Variance_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** Captures table access for each collaborator */
export type Project_Collaborator_Allowed_Schema_Tables = {
  __typename?: 'project_collaborator_allowed_schema_tables';
  granted_at: Scalars['timestamptz'];
  granted_by: Scalars['uuid'];
  project_collaborator_id: Scalars['uuid'];
  /** An object relationship */
  project_schema?: Maybe<Project_Collaborator_Allowed_Schemas>;
  schema: Scalars['String'];
  table_name: Scalars['String'];
};

/** aggregated selection of "project_collaborator_allowed_schema_tables" */
export type Project_Collaborator_Allowed_Schema_Tables_Aggregate = {
  __typename?: 'project_collaborator_allowed_schema_tables_aggregate';
  aggregate?: Maybe<
    Project_Collaborator_Allowed_Schema_Tables_Aggregate_Fields
  >;
  nodes: Array<Project_Collaborator_Allowed_Schema_Tables>;
};

export type Project_Collaborator_Allowed_Schema_Tables_Aggregate_Bool_Exp = {
  count?: InputMaybe<
    Project_Collaborator_Allowed_Schema_Tables_Aggregate_Bool_Exp_Count
  >;
};

export type Project_Collaborator_Allowed_Schema_Tables_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_collaborator_allowed_schema_tables" */
export type Project_Collaborator_Allowed_Schema_Tables_Aggregate_Fields = {
  __typename?: 'project_collaborator_allowed_schema_tables_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Collaborator_Allowed_Schema_Tables_Max_Fields>;
  min?: Maybe<Project_Collaborator_Allowed_Schema_Tables_Min_Fields>;
};

/** aggregate fields of "project_collaborator_allowed_schema_tables" */
export type Project_Collaborator_Allowed_Schema_Tables_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_collaborator_allowed_schema_tables" */
export type Project_Collaborator_Allowed_Schema_Tables_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Max_Order_By>;
  min?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_collaborator_allowed_schema_tables" */
export type Project_Collaborator_Allowed_Schema_Tables_Arr_Rel_Insert_Input = {
  data: Array<Project_Collaborator_Allowed_Schema_Tables_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<
    Project_Collaborator_Allowed_Schema_Tables_On_Conflict
  >;
};

/** Boolean expression to filter rows from the table "project_collaborator_allowed_schema_tables". All fields are combined with a logical 'AND'. */
export type Project_Collaborator_Allowed_Schema_Tables_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>>;
  _not?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>>;
  granted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  granted_by?: InputMaybe<Uuid_Comparison_Exp>;
  project_collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_schema?: InputMaybe<Project_Collaborator_Allowed_Schemas_Bool_Exp>;
  schema?: InputMaybe<String_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_collaborator_allowed_schema_tables" */
export enum Project_Collaborator_Allowed_Schema_Tables_Constraint {
  /** unique or primary key constraint on columns "schema", "project_collaborator_id", "table_name" */
  ProjectCollaboratorAllowedSchemaTablesPkey = 'project_collaborator_allowed_schema_tables_pkey',
}

/** input type for inserting data into table "project_collaborator_allowed_schema_tables" */
export type Project_Collaborator_Allowed_Schema_Tables_Insert_Input = {
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  granted_by?: InputMaybe<Scalars['uuid']>;
  project_collaborator_id?: InputMaybe<Scalars['uuid']>;
  project_schema?: InputMaybe<
    Project_Collaborator_Allowed_Schemas_Obj_Rel_Insert_Input
  >;
  schema?: InputMaybe<Scalars['String']>;
  table_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Collaborator_Allowed_Schema_Tables_Max_Fields = {
  __typename?: 'project_collaborator_allowed_schema_tables_max_fields';
  granted_at?: Maybe<Scalars['timestamptz']>;
  granted_by?: Maybe<Scalars['uuid']>;
  project_collaborator_id?: Maybe<Scalars['uuid']>;
  schema?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project_collaborator_allowed_schema_tables" */
export type Project_Collaborator_Allowed_Schema_Tables_Max_Order_By = {
  granted_at?: InputMaybe<Order_By>;
  granted_by?: InputMaybe<Order_By>;
  project_collaborator_id?: InputMaybe<Order_By>;
  schema?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Collaborator_Allowed_Schema_Tables_Min_Fields = {
  __typename?: 'project_collaborator_allowed_schema_tables_min_fields';
  granted_at?: Maybe<Scalars['timestamptz']>;
  granted_by?: Maybe<Scalars['uuid']>;
  project_collaborator_id?: Maybe<Scalars['uuid']>;
  schema?: Maybe<Scalars['String']>;
  table_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project_collaborator_allowed_schema_tables" */
export type Project_Collaborator_Allowed_Schema_Tables_Min_Order_By = {
  granted_at?: InputMaybe<Order_By>;
  granted_by?: InputMaybe<Order_By>;
  project_collaborator_id?: InputMaybe<Order_By>;
  schema?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_collaborator_allowed_schema_tables" */
export type Project_Collaborator_Allowed_Schema_Tables_Mutation_Response = {
  __typename?: 'project_collaborator_allowed_schema_tables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Collaborator_Allowed_Schema_Tables>;
};

/** on_conflict condition type for table "project_collaborator_allowed_schema_tables" */
export type Project_Collaborator_Allowed_Schema_Tables_On_Conflict = {
  constraint: Project_Collaborator_Allowed_Schema_Tables_Constraint;
  update_columns?: Array<
    Project_Collaborator_Allowed_Schema_Tables_Update_Column
  >;
  where?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>;
};

/** Ordering options when selecting data from "project_collaborator_allowed_schema_tables". */
export type Project_Collaborator_Allowed_Schema_Tables_Order_By = {
  granted_at?: InputMaybe<Order_By>;
  granted_by?: InputMaybe<Order_By>;
  project_collaborator_id?: InputMaybe<Order_By>;
  project_schema?: InputMaybe<Project_Collaborator_Allowed_Schemas_Order_By>;
  schema?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_collaborator_allowed_schema_tables */
export type Project_Collaborator_Allowed_Schema_Tables_Pk_Columns_Input = {
  project_collaborator_id: Scalars['uuid'];
  schema: Scalars['String'];
  table_name: Scalars['String'];
};

/** select columns of table "project_collaborator_allowed_schema_tables" */
export enum Project_Collaborator_Allowed_Schema_Tables_Select_Column {
  /** column name */
  GrantedAt = 'granted_at',
  /** column name */
  GrantedBy = 'granted_by',
  /** column name */
  ProjectCollaboratorId = 'project_collaborator_id',
  /** column name */
  Schema = 'schema',
  /** column name */
  TableName = 'table_name',
}

/** input type for updating data in table "project_collaborator_allowed_schema_tables" */
export type Project_Collaborator_Allowed_Schema_Tables_Set_Input = {
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  granted_by?: InputMaybe<Scalars['uuid']>;
  project_collaborator_id?: InputMaybe<Scalars['uuid']>;
  schema?: InputMaybe<Scalars['String']>;
  table_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "project_collaborator_allowed_schema_tables" */
export type Project_Collaborator_Allowed_Schema_Tables_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Collaborator_Allowed_Schema_Tables_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Collaborator_Allowed_Schema_Tables_Stream_Cursor_Value_Input = {
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  granted_by?: InputMaybe<Scalars['uuid']>;
  project_collaborator_id?: InputMaybe<Scalars['uuid']>;
  schema?: InputMaybe<Scalars['String']>;
  table_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "project_collaborator_allowed_schema_tables" */
export enum Project_Collaborator_Allowed_Schema_Tables_Update_Column {
  /** column name */
  GrantedAt = 'granted_at',
  /** column name */
  GrantedBy = 'granted_by',
  /** column name */
  ProjectCollaboratorId = 'project_collaborator_id',
  /** column name */
  Schema = 'schema',
  /** column name */
  TableName = 'table_name',
}

export type Project_Collaborator_Allowed_Schema_Tables_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Collaborator_Allowed_Schema_Tables_Bool_Exp;
};

/** Captures schema access for each collaborator */
export type Project_Collaborator_Allowed_Schemas = {
  __typename?: 'project_collaborator_allowed_schemas';
  granted_at: Scalars['timestamptz'];
  granted_by: Scalars['uuid'];
  /** An object relationship */
  project_collaborator: Project_Collaborators;
  project_collaborator_id: Scalars['uuid'];
  schema: Scalars['String'];
  /** An array relationship */
  tables: Array<Project_Collaborator_Allowed_Schema_Tables>;
  /** An aggregate relationship */
  tables_aggregate: Project_Collaborator_Allowed_Schema_Tables_Aggregate;
};

/** Captures schema access for each collaborator */
export type Project_Collaborator_Allowed_SchemasTablesArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Order_By>
  >;
  where?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>;
};

/** Captures schema access for each collaborator */
export type Project_Collaborator_Allowed_SchemasTables_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Order_By>
  >;
  where?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>;
};

/** aggregated selection of "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_Aggregate = {
  __typename?: 'project_collaborator_allowed_schemas_aggregate';
  aggregate?: Maybe<Project_Collaborator_Allowed_Schemas_Aggregate_Fields>;
  nodes: Array<Project_Collaborator_Allowed_Schemas>;
};

export type Project_Collaborator_Allowed_Schemas_Aggregate_Bool_Exp = {
  count?: InputMaybe<
    Project_Collaborator_Allowed_Schemas_Aggregate_Bool_Exp_Count
  >;
};

export type Project_Collaborator_Allowed_Schemas_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schemas_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Collaborator_Allowed_Schemas_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_Aggregate_Fields = {
  __typename?: 'project_collaborator_allowed_schemas_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Collaborator_Allowed_Schemas_Max_Fields>;
  min?: Maybe<Project_Collaborator_Allowed_Schemas_Min_Fields>;
};

/** aggregate fields of "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schemas_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Collaborator_Allowed_Schemas_Max_Order_By>;
  min?: InputMaybe<Project_Collaborator_Allowed_Schemas_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_Arr_Rel_Insert_Input = {
  data: Array<Project_Collaborator_Allowed_Schemas_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Collaborator_Allowed_Schemas_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_collaborator_allowed_schemas". All fields are combined with a logical 'AND'. */
export type Project_Collaborator_Allowed_Schemas_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Collaborator_Allowed_Schemas_Bool_Exp>>;
  _not?: InputMaybe<Project_Collaborator_Allowed_Schemas_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Collaborator_Allowed_Schemas_Bool_Exp>>;
  granted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  granted_by?: InputMaybe<Uuid_Comparison_Exp>;
  project_collaborator?: InputMaybe<Project_Collaborators_Bool_Exp>;
  project_collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  schema?: InputMaybe<String_Comparison_Exp>;
  tables?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>;
  tables_aggregate?: InputMaybe<
    Project_Collaborator_Allowed_Schema_Tables_Aggregate_Bool_Exp
  >;
};

/** unique or primary key constraints on table "project_collaborator_allowed_schemas" */
export enum Project_Collaborator_Allowed_Schemas_Constraint {
  /** unique or primary key constraint on columns "schema", "project_collaborator_id" */
  ProjectCollaboratorAllowedSchemasPkey = 'project_collaborator_allowed_schemas_pkey',
}

/** input type for inserting data into table "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_Insert_Input = {
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  granted_by?: InputMaybe<Scalars['uuid']>;
  project_collaborator?: InputMaybe<Project_Collaborators_Obj_Rel_Insert_Input>;
  project_collaborator_id?: InputMaybe<Scalars['uuid']>;
  schema?: InputMaybe<Scalars['String']>;
  tables?: InputMaybe<
    Project_Collaborator_Allowed_Schema_Tables_Arr_Rel_Insert_Input
  >;
};

/** aggregate max on columns */
export type Project_Collaborator_Allowed_Schemas_Max_Fields = {
  __typename?: 'project_collaborator_allowed_schemas_max_fields';
  granted_at?: Maybe<Scalars['timestamptz']>;
  granted_by?: Maybe<Scalars['uuid']>;
  project_collaborator_id?: Maybe<Scalars['uuid']>;
  schema?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_Max_Order_By = {
  granted_at?: InputMaybe<Order_By>;
  granted_by?: InputMaybe<Order_By>;
  project_collaborator_id?: InputMaybe<Order_By>;
  schema?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Collaborator_Allowed_Schemas_Min_Fields = {
  __typename?: 'project_collaborator_allowed_schemas_min_fields';
  granted_at?: Maybe<Scalars['timestamptz']>;
  granted_by?: Maybe<Scalars['uuid']>;
  project_collaborator_id?: Maybe<Scalars['uuid']>;
  schema?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_Min_Order_By = {
  granted_at?: InputMaybe<Order_By>;
  granted_by?: InputMaybe<Order_By>;
  project_collaborator_id?: InputMaybe<Order_By>;
  schema?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_Mutation_Response = {
  __typename?: 'project_collaborator_allowed_schemas_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Collaborator_Allowed_Schemas>;
};

/** input type for inserting object relation for remote table "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_Obj_Rel_Insert_Input = {
  data: Project_Collaborator_Allowed_Schemas_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Collaborator_Allowed_Schemas_On_Conflict>;
};

/** on_conflict condition type for table "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_On_Conflict = {
  constraint: Project_Collaborator_Allowed_Schemas_Constraint;
  update_columns?: Array<Project_Collaborator_Allowed_Schemas_Update_Column>;
  where?: InputMaybe<Project_Collaborator_Allowed_Schemas_Bool_Exp>;
};

/** Ordering options when selecting data from "project_collaborator_allowed_schemas". */
export type Project_Collaborator_Allowed_Schemas_Order_By = {
  granted_at?: InputMaybe<Order_By>;
  granted_by?: InputMaybe<Order_By>;
  project_collaborator?: InputMaybe<Project_Collaborators_Order_By>;
  project_collaborator_id?: InputMaybe<Order_By>;
  schema?: InputMaybe<Order_By>;
  tables_aggregate?: InputMaybe<
    Project_Collaborator_Allowed_Schema_Tables_Aggregate_Order_By
  >;
};

/** primary key columns input for table: project_collaborator_allowed_schemas */
export type Project_Collaborator_Allowed_Schemas_Pk_Columns_Input = {
  project_collaborator_id: Scalars['uuid'];
  schema: Scalars['String'];
};

/** select columns of table "project_collaborator_allowed_schemas" */
export enum Project_Collaborator_Allowed_Schemas_Select_Column {
  /** column name */
  GrantedAt = 'granted_at',
  /** column name */
  GrantedBy = 'granted_by',
  /** column name */
  ProjectCollaboratorId = 'project_collaborator_id',
  /** column name */
  Schema = 'schema',
}

/** input type for updating data in table "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_Set_Input = {
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  granted_by?: InputMaybe<Scalars['uuid']>;
  project_collaborator_id?: InputMaybe<Scalars['uuid']>;
  schema?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "project_collaborator_allowed_schemas" */
export type Project_Collaborator_Allowed_Schemas_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Collaborator_Allowed_Schemas_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Collaborator_Allowed_Schemas_Stream_Cursor_Value_Input = {
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  granted_by?: InputMaybe<Scalars['uuid']>;
  project_collaborator_id?: InputMaybe<Scalars['uuid']>;
  schema?: InputMaybe<Scalars['String']>;
};

/** update columns of table "project_collaborator_allowed_schemas" */
export enum Project_Collaborator_Allowed_Schemas_Update_Column {
  /** column name */
  GrantedAt = 'granted_at',
  /** column name */
  GrantedBy = 'granted_by',
  /** column name */
  ProjectCollaboratorId = 'project_collaborator_id',
  /** column name */
  Schema = 'schema',
}

export type Project_Collaborator_Allowed_Schemas_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Collaborator_Allowed_Schemas_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Collaborator_Allowed_Schemas_Bool_Exp;
};

/** Captures invitations to collaborate on a project */
export type Project_Collaborator_Invitations = {
  __typename?: 'project_collaborator_invitations';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  collaborator_email: Scalars['citext'];
  declined_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  invited_at: Scalars['timestamptz'];
  invited_by: Scalars['uuid'];
  /** An object relationship */
  invited_by_user: Users;
  key: Scalars['String'];
  privileges?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  project: Projects;
  /** An object relationship */
  project_collaborator?: Maybe<Project_Collaborators>;
  project_collaborator_id?: Maybe<Scalars['uuid']>;
  project_id: Scalars['uuid'];
  resend_invitation_count: Scalars['Int'];
};

/** Captures invitations to collaborate on a project */
export type Project_Collaborator_InvitationsPrivilegesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Aggregate = {
  __typename?: 'project_collaborator_invitations_aggregate';
  aggregate?: Maybe<Project_Collaborator_Invitations_Aggregate_Fields>;
  nodes: Array<Project_Collaborator_Invitations>;
};

export type Project_Collaborator_Invitations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Collaborator_Invitations_Aggregate_Bool_Exp_Count>;
};

export type Project_Collaborator_Invitations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Collaborator_Invitations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Aggregate_Fields = {
  __typename?: 'project_collaborator_invitations_aggregate_fields';
  avg?: Maybe<Project_Collaborator_Invitations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Collaborator_Invitations_Max_Fields>;
  min?: Maybe<Project_Collaborator_Invitations_Min_Fields>;
  stddev?: Maybe<Project_Collaborator_Invitations_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Collaborator_Invitations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Collaborator_Invitations_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Collaborator_Invitations_Sum_Fields>;
  var_pop?: Maybe<Project_Collaborator_Invitations_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Collaborator_Invitations_Var_Samp_Fields>;
  variance?: Maybe<Project_Collaborator_Invitations_Variance_Fields>;
};

/** aggregate fields of "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Collaborator_Invitations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Collaborator_Invitations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Collaborator_Invitations_Max_Order_By>;
  min?: InputMaybe<Project_Collaborator_Invitations_Min_Order_By>;
  stddev?: InputMaybe<Project_Collaborator_Invitations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Collaborator_Invitations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<
    Project_Collaborator_Invitations_Stddev_Samp_Order_By
  >;
  sum?: InputMaybe<Project_Collaborator_Invitations_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Collaborator_Invitations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Collaborator_Invitations_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Collaborator_Invitations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Project_Collaborator_Invitations_Append_Input = {
  privileges?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Arr_Rel_Insert_Input = {
  data: Array<Project_Collaborator_Invitations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Collaborator_Invitations_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Collaborator_Invitations_Avg_Fields = {
  __typename?: 'project_collaborator_invitations_avg_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Avg_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_collaborator_invitations". All fields are combined with a logical 'AND'. */
export type Project_Collaborator_Invitations_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Collaborator_Invitations_Bool_Exp>>;
  _not?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Collaborator_Invitations_Bool_Exp>>;
  accepted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  collaborator_email?: InputMaybe<Citext_Comparison_Exp>;
  declined_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invited_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  invited_by?: InputMaybe<Uuid_Comparison_Exp>;
  invited_by_user?: InputMaybe<Users_Bool_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  privileges?: InputMaybe<Jsonb_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_collaborator?: InputMaybe<Project_Collaborators_Bool_Exp>;
  project_collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  resend_invitation_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_collaborator_invitations" */
export enum Project_Collaborator_Invitations_Constraint {
  /** unique or primary key constraint on columns "key" */
  ProjectCollaboratorInvitationsKeyKey = 'project_collaborator_invitations_key_key',
  /** unique or primary key constraint on columns "id" */
  ProjectCollaboratorInvitationsPkey = 'project_collaborator_invitations_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Project_Collaborator_Invitations_Delete_At_Path_Input = {
  privileges?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Project_Collaborator_Invitations_Delete_Elem_Input = {
  privileges?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Project_Collaborator_Invitations_Delete_Key_Input = {
  privileges?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Inc_Input = {
  resend_invitation_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Insert_Input = {
  accepted_at?: InputMaybe<Scalars['timestamptz']>;
  collaborator_email?: InputMaybe<Scalars['citext']>;
  declined_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  invited_by_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  key?: InputMaybe<Scalars['String']>;
  privileges?: InputMaybe<Scalars['jsonb']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_collaborator?: InputMaybe<Project_Collaborators_Obj_Rel_Insert_Input>;
  project_collaborator_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  resend_invitation_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Project_Collaborator_Invitations_Max_Fields = {
  __typename?: 'project_collaborator_invitations_max_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  collaborator_email?: Maybe<Scalars['citext']>;
  declined_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invited_at?: Maybe<Scalars['timestamptz']>;
  invited_by?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  project_collaborator_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  resend_invitation_count?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Max_Order_By = {
  accepted_at?: InputMaybe<Order_By>;
  collaborator_email?: InputMaybe<Order_By>;
  declined_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  project_collaborator_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Collaborator_Invitations_Min_Fields = {
  __typename?: 'project_collaborator_invitations_min_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  collaborator_email?: Maybe<Scalars['citext']>;
  declined_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invited_at?: Maybe<Scalars['timestamptz']>;
  invited_by?: Maybe<Scalars['uuid']>;
  key?: Maybe<Scalars['String']>;
  project_collaborator_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  resend_invitation_count?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Min_Order_By = {
  accepted_at?: InputMaybe<Order_By>;
  collaborator_email?: InputMaybe<Order_By>;
  declined_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  project_collaborator_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Mutation_Response = {
  __typename?: 'project_collaborator_invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Collaborator_Invitations>;
};

/** on_conflict condition type for table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_On_Conflict = {
  constraint: Project_Collaborator_Invitations_Constraint;
  update_columns?: Array<Project_Collaborator_Invitations_Update_Column>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};

/** Ordering options when selecting data from "project_collaborator_invitations". */
export type Project_Collaborator_Invitations_Order_By = {
  accepted_at?: InputMaybe<Order_By>;
  collaborator_email?: InputMaybe<Order_By>;
  declined_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  invited_by_user?: InputMaybe<Users_Order_By>;
  key?: InputMaybe<Order_By>;
  privileges?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_collaborator?: InputMaybe<Project_Collaborators_Order_By>;
  project_collaborator_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_collaborator_invitations */
export type Project_Collaborator_Invitations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Project_Collaborator_Invitations_Prepend_Input = {
  privileges?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "project_collaborator_invitations" */
export enum Project_Collaborator_Invitations_Select_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  CollaboratorEmail = 'collaborator_email',
  /** column name */
  DeclinedAt = 'declined_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedAt = 'invited_at',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  Key = 'key',
  /** column name */
  Privileges = 'privileges',
  /** column name */
  ProjectCollaboratorId = 'project_collaborator_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ResendInvitationCount = 'resend_invitation_count',
}

/** input type for updating data in table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Set_Input = {
  accepted_at?: InputMaybe<Scalars['timestamptz']>;
  collaborator_email?: InputMaybe<Scalars['citext']>;
  declined_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  privileges?: InputMaybe<Scalars['jsonb']>;
  project_collaborator_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  resend_invitation_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Project_Collaborator_Invitations_Stddev_Fields = {
  __typename?: 'project_collaborator_invitations_stddev_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Stddev_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Collaborator_Invitations_Stddev_Pop_Fields = {
  __typename?: 'project_collaborator_invitations_stddev_pop_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Stddev_Pop_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Collaborator_Invitations_Stddev_Samp_Fields = {
  __typename?: 'project_collaborator_invitations_stddev_samp_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Stddev_Samp_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Collaborator_Invitations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Collaborator_Invitations_Stream_Cursor_Value_Input = {
  accepted_at?: InputMaybe<Scalars['timestamptz']>;
  collaborator_email?: InputMaybe<Scalars['citext']>;
  declined_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  key?: InputMaybe<Scalars['String']>;
  privileges?: InputMaybe<Scalars['jsonb']>;
  project_collaborator_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  resend_invitation_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Project_Collaborator_Invitations_Sum_Fields = {
  __typename?: 'project_collaborator_invitations_sum_fields';
  resend_invitation_count?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Sum_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** update columns of table "project_collaborator_invitations" */
export enum Project_Collaborator_Invitations_Update_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  CollaboratorEmail = 'collaborator_email',
  /** column name */
  DeclinedAt = 'declined_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedAt = 'invited_at',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  Key = 'key',
  /** column name */
  Privileges = 'privileges',
  /** column name */
  ProjectCollaboratorId = 'project_collaborator_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ResendInvitationCount = 'resend_invitation_count',
}

export type Project_Collaborator_Invitations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Project_Collaborator_Invitations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<
    Project_Collaborator_Invitations_Delete_At_Path_Input
  >;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Project_Collaborator_Invitations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Project_Collaborator_Invitations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Collaborator_Invitations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Project_Collaborator_Invitations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Collaborator_Invitations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Collaborator_Invitations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Collaborator_Invitations_Var_Pop_Fields = {
  __typename?: 'project_collaborator_invitations_var_pop_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Var_Pop_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Collaborator_Invitations_Var_Samp_Fields = {
  __typename?: 'project_collaborator_invitations_var_samp_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Var_Samp_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Collaborator_Invitations_Variance_Fields = {
  __typename?: 'project_collaborator_invitations_variance_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_collaborator_invitations" */
export type Project_Collaborator_Invitations_Variance_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** Privileges that can be granted for collaborators of a project */
export type Project_Collaborator_Privileges = {
  __typename?: 'project_collaborator_privileges';
  granted_at: Scalars['timestamptz'];
  granted_by: Scalars['uuid'];
  privilege_slug: Scalars['String'];
  /** An object relationship */
  project_collaborator: Project_Collaborators;
  project_collaborator_id: Scalars['uuid'];
};

/** aggregated selection of "project_collaborator_privileges" */
export type Project_Collaborator_Privileges_Aggregate = {
  __typename?: 'project_collaborator_privileges_aggregate';
  aggregate?: Maybe<Project_Collaborator_Privileges_Aggregate_Fields>;
  nodes: Array<Project_Collaborator_Privileges>;
};

export type Project_Collaborator_Privileges_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Collaborator_Privileges_Aggregate_Bool_Exp_Count>;
};

export type Project_Collaborator_Privileges_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Collaborator_Privileges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Collaborator_Privileges_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_collaborator_privileges" */
export type Project_Collaborator_Privileges_Aggregate_Fields = {
  __typename?: 'project_collaborator_privileges_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Collaborator_Privileges_Max_Fields>;
  min?: Maybe<Project_Collaborator_Privileges_Min_Fields>;
};

/** aggregate fields of "project_collaborator_privileges" */
export type Project_Collaborator_Privileges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Collaborator_Privileges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_collaborator_privileges" */
export type Project_Collaborator_Privileges_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Collaborator_Privileges_Max_Order_By>;
  min?: InputMaybe<Project_Collaborator_Privileges_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_collaborator_privileges" */
export type Project_Collaborator_Privileges_Arr_Rel_Insert_Input = {
  data: Array<Project_Collaborator_Privileges_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Collaborator_Privileges_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_collaborator_privileges". All fields are combined with a logical 'AND'. */
export type Project_Collaborator_Privileges_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Collaborator_Privileges_Bool_Exp>>;
  _not?: InputMaybe<Project_Collaborator_Privileges_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Collaborator_Privileges_Bool_Exp>>;
  granted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  granted_by?: InputMaybe<Uuid_Comparison_Exp>;
  privilege_slug?: InputMaybe<String_Comparison_Exp>;
  project_collaborator?: InputMaybe<Project_Collaborators_Bool_Exp>;
  project_collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_collaborator_privileges" */
export enum Project_Collaborator_Privileges_Constraint {
  /** unique or primary key constraint on columns "privilege_slug", "project_collaborator_id" */
  ProjectCollaboratorPrivilegesPkey = 'project_collaborator_privileges_pkey',
}

/** input type for inserting data into table "project_collaborator_privileges" */
export type Project_Collaborator_Privileges_Insert_Input = {
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  granted_by?: InputMaybe<Scalars['uuid']>;
  privilege_slug?: InputMaybe<Scalars['String']>;
  project_collaborator?: InputMaybe<Project_Collaborators_Obj_Rel_Insert_Input>;
  project_collaborator_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Collaborator_Privileges_Max_Fields = {
  __typename?: 'project_collaborator_privileges_max_fields';
  granted_at?: Maybe<Scalars['timestamptz']>;
  granted_by?: Maybe<Scalars['uuid']>;
  privilege_slug?: Maybe<Scalars['String']>;
  project_collaborator_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_collaborator_privileges" */
export type Project_Collaborator_Privileges_Max_Order_By = {
  granted_at?: InputMaybe<Order_By>;
  granted_by?: InputMaybe<Order_By>;
  privilege_slug?: InputMaybe<Order_By>;
  project_collaborator_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Collaborator_Privileges_Min_Fields = {
  __typename?: 'project_collaborator_privileges_min_fields';
  granted_at?: Maybe<Scalars['timestamptz']>;
  granted_by?: Maybe<Scalars['uuid']>;
  privilege_slug?: Maybe<Scalars['String']>;
  project_collaborator_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project_collaborator_privileges" */
export type Project_Collaborator_Privileges_Min_Order_By = {
  granted_at?: InputMaybe<Order_By>;
  granted_by?: InputMaybe<Order_By>;
  privilege_slug?: InputMaybe<Order_By>;
  project_collaborator_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_collaborator_privileges" */
export type Project_Collaborator_Privileges_Mutation_Response = {
  __typename?: 'project_collaborator_privileges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Collaborator_Privileges>;
};

/** on_conflict condition type for table "project_collaborator_privileges" */
export type Project_Collaborator_Privileges_On_Conflict = {
  constraint: Project_Collaborator_Privileges_Constraint;
  update_columns?: Array<Project_Collaborator_Privileges_Update_Column>;
  where?: InputMaybe<Project_Collaborator_Privileges_Bool_Exp>;
};

/** Ordering options when selecting data from "project_collaborator_privileges". */
export type Project_Collaborator_Privileges_Order_By = {
  granted_at?: InputMaybe<Order_By>;
  granted_by?: InputMaybe<Order_By>;
  privilege_slug?: InputMaybe<Order_By>;
  project_collaborator?: InputMaybe<Project_Collaborators_Order_By>;
  project_collaborator_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_collaborator_privileges */
export type Project_Collaborator_Privileges_Pk_Columns_Input = {
  privilege_slug: Scalars['String'];
  project_collaborator_id: Scalars['uuid'];
};

/** select columns of table "project_collaborator_privileges" */
export enum Project_Collaborator_Privileges_Select_Column {
  /** column name */
  GrantedAt = 'granted_at',
  /** column name */
  GrantedBy = 'granted_by',
  /** column name */
  PrivilegeSlug = 'privilege_slug',
  /** column name */
  ProjectCollaboratorId = 'project_collaborator_id',
}

/** input type for updating data in table "project_collaborator_privileges" */
export type Project_Collaborator_Privileges_Set_Input = {
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  granted_by?: InputMaybe<Scalars['uuid']>;
  privilege_slug?: InputMaybe<Scalars['String']>;
  project_collaborator_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "project_collaborator_privileges" */
export type Project_Collaborator_Privileges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Collaborator_Privileges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Collaborator_Privileges_Stream_Cursor_Value_Input = {
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  granted_by?: InputMaybe<Scalars['uuid']>;
  privilege_slug?: InputMaybe<Scalars['String']>;
  project_collaborator_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "project_collaborator_privileges" */
export enum Project_Collaborator_Privileges_Update_Column {
  /** column name */
  GrantedAt = 'granted_at',
  /** column name */
  GrantedBy = 'granted_by',
  /** column name */
  PrivilegeSlug = 'privilege_slug',
  /** column name */
  ProjectCollaboratorId = 'project_collaborator_id',
}

export type Project_Collaborator_Privileges_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Collaborator_Privileges_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Collaborator_Privileges_Bool_Exp;
};

/** Captures collaborators of a project */
export type Project_Collaborators = {
  __typename?: 'project_collaborators';
  /** An object relationship */
  collaborator?: Maybe<Users_Public>;
  collaborator_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  /** An array relationship */
  project_collaborator_allowed_schemas: Array<
    Project_Collaborator_Allowed_Schemas
  >;
  /** An aggregate relationship */
  project_collaborator_allowed_schemas_aggregate: Project_Collaborator_Allowed_Schemas_Aggregate;
  /** An array relationship */
  project_collaborator_privileges: Array<Project_Collaborator_Privileges>;
  /** An aggregate relationship */
  project_collaborator_privileges_aggregate: Project_Collaborator_Privileges_Aggregate;
  project_id: Scalars['uuid'];
  status: Scalars['String'];
};

/** Captures collaborators of a project */
export type Project_CollaboratorsProject_Collaborator_Allowed_SchemasArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schemas_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Allowed_Schemas_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Allowed_Schemas_Bool_Exp>;
};

/** Captures collaborators of a project */
export type Project_CollaboratorsProject_Collaborator_Allowed_Schemas_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schemas_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Allowed_Schemas_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Allowed_Schemas_Bool_Exp>;
};

/** Captures collaborators of a project */
export type Project_CollaboratorsProject_Collaborator_PrivilegesArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Privileges_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Privileges_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Privileges_Bool_Exp>;
};

/** Captures collaborators of a project */
export type Project_CollaboratorsProject_Collaborator_Privileges_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Privileges_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Privileges_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Privileges_Bool_Exp>;
};

/** aggregated selection of "project_collaborators" */
export type Project_Collaborators_Aggregate = {
  __typename?: 'project_collaborators_aggregate';
  aggregate?: Maybe<Project_Collaborators_Aggregate_Fields>;
  nodes: Array<Project_Collaborators>;
};

export type Project_Collaborators_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Collaborators_Aggregate_Bool_Exp_Count>;
};

export type Project_Collaborators_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Collaborators_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Collaborators_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_collaborators" */
export type Project_Collaborators_Aggregate_Fields = {
  __typename?: 'project_collaborators_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Collaborators_Max_Fields>;
  min?: Maybe<Project_Collaborators_Min_Fields>;
};

/** aggregate fields of "project_collaborators" */
export type Project_Collaborators_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Collaborators_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_collaborators" */
export type Project_Collaborators_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Collaborators_Max_Order_By>;
  min?: InputMaybe<Project_Collaborators_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_collaborators" */
export type Project_Collaborators_Arr_Rel_Insert_Input = {
  data: Array<Project_Collaborators_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Collaborators_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_collaborators". All fields are combined with a logical 'AND'. */
export type Project_Collaborators_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Collaborators_Bool_Exp>>;
  _not?: InputMaybe<Project_Collaborators_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Collaborators_Bool_Exp>>;
  collaborator?: InputMaybe<Users_Public_Bool_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_collaborator_allowed_schemas?: InputMaybe<
    Project_Collaborator_Allowed_Schemas_Bool_Exp
  >;
  project_collaborator_allowed_schemas_aggregate?: InputMaybe<
    Project_Collaborator_Allowed_Schemas_Aggregate_Bool_Exp
  >;
  project_collaborator_privileges?: InputMaybe<
    Project_Collaborator_Privileges_Bool_Exp
  >;
  project_collaborator_privileges_aggregate?: InputMaybe<
    Project_Collaborator_Privileges_Aggregate_Bool_Exp
  >;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_collaborators" */
export enum Project_Collaborators_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectCollaboratorsPkey = 'project_collaborators_pkey',
}

/** input type for inserting data into table "project_collaborators" */
export type Project_Collaborators_Insert_Input = {
  collaborator?: InputMaybe<Users_Public_Obj_Rel_Insert_Input>;
  collaborator_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_collaborator_allowed_schemas?: InputMaybe<
    Project_Collaborator_Allowed_Schemas_Arr_Rel_Insert_Input
  >;
  project_collaborator_privileges?: InputMaybe<
    Project_Collaborator_Privileges_Arr_Rel_Insert_Input
  >;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Collaborators_Max_Fields = {
  __typename?: 'project_collaborators_max_fields';
  collaborator_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "project_collaborators" */
export type Project_Collaborators_Max_Order_By = {
  collaborator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Collaborators_Min_Fields = {
  __typename?: 'project_collaborators_min_fields';
  collaborator_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "project_collaborators" */
export type Project_Collaborators_Min_Order_By = {
  collaborator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_collaborators" */
export type Project_Collaborators_Mutation_Response = {
  __typename?: 'project_collaborators_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Collaborators>;
};

/** input type for inserting object relation for remote table "project_collaborators" */
export type Project_Collaborators_Obj_Rel_Insert_Input = {
  data: Project_Collaborators_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Collaborators_On_Conflict>;
};

/** on_conflict condition type for table "project_collaborators" */
export type Project_Collaborators_On_Conflict = {
  constraint: Project_Collaborators_Constraint;
  update_columns?: Array<Project_Collaborators_Update_Column>;
  where?: InputMaybe<Project_Collaborators_Bool_Exp>;
};

/** Ordering options when selecting data from "project_collaborators". */
export type Project_Collaborators_Order_By = {
  collaborator?: InputMaybe<Users_Public_Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_collaborator_allowed_schemas_aggregate?: InputMaybe<
    Project_Collaborator_Allowed_Schemas_Aggregate_Order_By
  >;
  project_collaborator_privileges_aggregate?: InputMaybe<
    Project_Collaborator_Privileges_Aggregate_Order_By
  >;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_collaborators */
export type Project_Collaborators_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_collaborators" */
export enum Project_Collaborators_Select_Column {
  /** column name */
  CollaboratorId = 'collaborator_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "project_collaborators" */
export type Project_Collaborators_Set_Input = {
  collaborator_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "project_collaborators" */
export type Project_Collaborators_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Collaborators_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Collaborators_Stream_Cursor_Value_Input = {
  collaborator_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "project_collaborators" */
export enum Project_Collaborators_Update_Column {
  /** column name */
  CollaboratorId = 'collaborator_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
}

export type Project_Collaborators_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Collaborators_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Collaborators_Bool_Exp;
};

/** Captures data usage of a project for billing */
export type Project_Data_Usage = {
  __typename?: 'project_data_usage';
  date: Scalars['date'];
  id: Scalars['uuid'];
  inv_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id: Scalars['uuid'];
  usage: Scalars['bigint'];
  usage_updated_at: Scalars['timestamp'];
};

/** Used as a set of table for compute_project_agg_usage function to retrieve project_ids and their aggregate usage */
export type Project_Data_Usage_Agg = {
  __typename?: 'project_data_usage_agg';
  agg_usage?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  policy?: Maybe<Policies>;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
};

export type Project_Data_Usage_Agg_Aggregate = {
  __typename?: 'project_data_usage_agg_aggregate';
  aggregate?: Maybe<Project_Data_Usage_Agg_Aggregate_Fields>;
  nodes: Array<Project_Data_Usage_Agg>;
};

/** aggregate fields of "project_data_usage_agg" */
export type Project_Data_Usage_Agg_Aggregate_Fields = {
  __typename?: 'project_data_usage_agg_aggregate_fields';
  avg?: Maybe<Project_Data_Usage_Agg_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Data_Usage_Agg_Max_Fields>;
  min?: Maybe<Project_Data_Usage_Agg_Min_Fields>;
  stddev?: Maybe<Project_Data_Usage_Agg_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Data_Usage_Agg_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Data_Usage_Agg_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Data_Usage_Agg_Sum_Fields>;
  var_pop?: Maybe<Project_Data_Usage_Agg_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Data_Usage_Agg_Var_Samp_Fields>;
  variance?: Maybe<Project_Data_Usage_Agg_Variance_Fields>;
};

/** aggregate fields of "project_data_usage_agg" */
export type Project_Data_Usage_Agg_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Data_Usage_Agg_Avg_Fields = {
  __typename?: 'project_data_usage_agg_avg_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_data_usage_agg". All fields are combined with a logical 'AND'. */
export type Project_Data_Usage_Agg_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Data_Usage_Agg_Bool_Exp>>;
  _not?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Data_Usage_Agg_Bool_Exp>>;
  agg_usage?: InputMaybe<Bigint_Comparison_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "project_data_usage_agg" */
export type Project_Data_Usage_Agg_Inc_Input = {
  agg_usage?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "project_data_usage_agg" */
export type Project_Data_Usage_Agg_Insert_Input = {
  agg_usage?: InputMaybe<Scalars['bigint']>;
  policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Data_Usage_Agg_Max_Fields = {
  __typename?: 'project_data_usage_agg_max_fields';
  agg_usage?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Project_Data_Usage_Agg_Min_Fields = {
  __typename?: 'project_data_usage_agg_min_fields';
  agg_usage?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "project_data_usage_agg" */
export type Project_Data_Usage_Agg_Mutation_Response = {
  __typename?: 'project_data_usage_agg_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Data_Usage_Agg>;
};

/** Ordering options when selecting data from "project_data_usage_agg". */
export type Project_Data_Usage_Agg_Order_By = {
  agg_usage?: InputMaybe<Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** select columns of table "project_data_usage_agg" */
export enum Project_Data_Usage_Agg_Select_Column {
  /** column name */
  AggUsage = 'agg_usage',
  /** column name */
  ProjectId = 'project_id',
}

/** input type for updating data in table "project_data_usage_agg" */
export type Project_Data_Usage_Agg_Set_Input = {
  agg_usage?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Project_Data_Usage_Agg_Stddev_Fields = {
  __typename?: 'project_data_usage_agg_stddev_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Data_Usage_Agg_Stddev_Pop_Fields = {
  __typename?: 'project_data_usage_agg_stddev_pop_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Data_Usage_Agg_Stddev_Samp_Fields = {
  __typename?: 'project_data_usage_agg_stddev_samp_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_data_usage_agg" */
export type Project_Data_Usage_Agg_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Data_Usage_Agg_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Data_Usage_Agg_Stream_Cursor_Value_Input = {
  agg_usage?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Project_Data_Usage_Agg_Sum_Fields = {
  __typename?: 'project_data_usage_agg_sum_fields';
  agg_usage?: Maybe<Scalars['bigint']>;
};

export type Project_Data_Usage_Agg_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Data_Usage_Agg_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Data_Usage_Agg_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Data_Usage_Agg_Bool_Exp;
};

/** used as a set of table for compute_project_agg_usage_user function to retrieve project_ids and their aggregate usage filtered by user_id in a particular date range */
export type Project_Data_Usage_Agg_User = {
  __typename?: 'project_data_usage_agg_user';
  agg_usage?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type Project_Data_Usage_Agg_User_Aggregate = {
  __typename?: 'project_data_usage_agg_user_aggregate';
  aggregate?: Maybe<Project_Data_Usage_Agg_User_Aggregate_Fields>;
  nodes: Array<Project_Data_Usage_Agg_User>;
};

/** aggregate fields of "project_data_usage_agg_user" */
export type Project_Data_Usage_Agg_User_Aggregate_Fields = {
  __typename?: 'project_data_usage_agg_user_aggregate_fields';
  avg?: Maybe<Project_Data_Usage_Agg_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Data_Usage_Agg_User_Max_Fields>;
  min?: Maybe<Project_Data_Usage_Agg_User_Min_Fields>;
  stddev?: Maybe<Project_Data_Usage_Agg_User_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Data_Usage_Agg_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Data_Usage_Agg_User_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Data_Usage_Agg_User_Sum_Fields>;
  var_pop?: Maybe<Project_Data_Usage_Agg_User_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Data_Usage_Agg_User_Var_Samp_Fields>;
  variance?: Maybe<Project_Data_Usage_Agg_User_Variance_Fields>;
};

/** aggregate fields of "project_data_usage_agg_user" */
export type Project_Data_Usage_Agg_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Data_Usage_Agg_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Data_Usage_Agg_User_Avg_Fields = {
  __typename?: 'project_data_usage_agg_user_avg_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_data_usage_agg_user". All fields are combined with a logical 'AND'. */
export type Project_Data_Usage_Agg_User_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Data_Usage_Agg_User_Bool_Exp>>;
  _not?: InputMaybe<Project_Data_Usage_Agg_User_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Data_Usage_Agg_User_Bool_Exp>>;
  agg_usage?: InputMaybe<Bigint_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "project_data_usage_agg_user" */
export type Project_Data_Usage_Agg_User_Inc_Input = {
  agg_usage?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "project_data_usage_agg_user" */
export type Project_Data_Usage_Agg_User_Insert_Input = {
  agg_usage?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Data_Usage_Agg_User_Max_Fields = {
  __typename?: 'project_data_usage_agg_user_max_fields';
  agg_usage?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Project_Data_Usage_Agg_User_Min_Fields = {
  __typename?: 'project_data_usage_agg_user_min_fields';
  agg_usage?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "project_data_usage_agg_user" */
export type Project_Data_Usage_Agg_User_Mutation_Response = {
  __typename?: 'project_data_usage_agg_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Data_Usage_Agg_User>;
};

/** Ordering options when selecting data from "project_data_usage_agg_user". */
export type Project_Data_Usage_Agg_User_Order_By = {
  agg_usage?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "project_data_usage_agg_user" */
export enum Project_Data_Usage_Agg_User_Select_Column {
  /** column name */
  AggUsage = 'agg_usage',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "project_data_usage_agg_user" */
export type Project_Data_Usage_Agg_User_Set_Input = {
  agg_usage?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Project_Data_Usage_Agg_User_Stddev_Fields = {
  __typename?: 'project_data_usage_agg_user_stddev_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Data_Usage_Agg_User_Stddev_Pop_Fields = {
  __typename?: 'project_data_usage_agg_user_stddev_pop_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Data_Usage_Agg_User_Stddev_Samp_Fields = {
  __typename?: 'project_data_usage_agg_user_stddev_samp_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_data_usage_agg_user" */
export type Project_Data_Usage_Agg_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Data_Usage_Agg_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Data_Usage_Agg_User_Stream_Cursor_Value_Input = {
  agg_usage?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Project_Data_Usage_Agg_User_Sum_Fields = {
  __typename?: 'project_data_usage_agg_user_sum_fields';
  agg_usage?: Maybe<Scalars['bigint']>;
};

export type Project_Data_Usage_Agg_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Data_Usage_Agg_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Data_Usage_Agg_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Data_Usage_Agg_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Data_Usage_Agg_User_Var_Pop_Fields = {
  __typename?: 'project_data_usage_agg_user_var_pop_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Data_Usage_Agg_User_Var_Samp_Fields = {
  __typename?: 'project_data_usage_agg_user_var_samp_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Data_Usage_Agg_User_Variance_Fields = {
  __typename?: 'project_data_usage_agg_user_variance_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate var_pop on columns */
export type Project_Data_Usage_Agg_Var_Pop_Fields = {
  __typename?: 'project_data_usage_agg_var_pop_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Data_Usage_Agg_Var_Samp_Fields = {
  __typename?: 'project_data_usage_agg_var_samp_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Data_Usage_Agg_Variance_Fields = {
  __typename?: 'project_data_usage_agg_variance_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "project_data_usage" */
export type Project_Data_Usage_Aggregate = {
  __typename?: 'project_data_usage_aggregate';
  aggregate?: Maybe<Project_Data_Usage_Aggregate_Fields>;
  nodes: Array<Project_Data_Usage>;
};

export type Project_Data_Usage_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Data_Usage_Aggregate_Bool_Exp_Count>;
};

export type Project_Data_Usage_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Data_Usage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Data_Usage_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_data_usage" */
export type Project_Data_Usage_Aggregate_Fields = {
  __typename?: 'project_data_usage_aggregate_fields';
  avg?: Maybe<Project_Data_Usage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Data_Usage_Max_Fields>;
  min?: Maybe<Project_Data_Usage_Min_Fields>;
  stddev?: Maybe<Project_Data_Usage_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Data_Usage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Data_Usage_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Data_Usage_Sum_Fields>;
  var_pop?: Maybe<Project_Data_Usage_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Data_Usage_Var_Samp_Fields>;
  variance?: Maybe<Project_Data_Usage_Variance_Fields>;
};

/** aggregate fields of "project_data_usage" */
export type Project_Data_Usage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Data_Usage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_data_usage" */
export type Project_Data_Usage_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Data_Usage_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Data_Usage_Max_Order_By>;
  min?: InputMaybe<Project_Data_Usage_Min_Order_By>;
  stddev?: InputMaybe<Project_Data_Usage_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Data_Usage_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_Data_Usage_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_Data_Usage_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Data_Usage_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Data_Usage_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Data_Usage_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_data_usage" */
export type Project_Data_Usage_Arr_Rel_Insert_Input = {
  data: Array<Project_Data_Usage_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Data_Usage_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Data_Usage_Avg_Fields = {
  __typename?: 'project_data_usage_avg_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_data_usage" */
export type Project_Data_Usage_Avg_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_data_usage". All fields are combined with a logical 'AND'. */
export type Project_Data_Usage_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Data_Usage_Bool_Exp>>;
  _not?: InputMaybe<Project_Data_Usage_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Data_Usage_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inv_id?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  usage?: InputMaybe<Bigint_Comparison_Exp>;
  usage_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** This table captures the different components that contribute to data passthrough for projects */
export type Project_Data_Usage_Components = {
  __typename?: 'project_data_usage_components';
  date: Scalars['date'];
  hasura_action_request_bytes_total: Scalars['bigint'];
  hasura_action_response_bytes_total: Scalars['bigint'];
  hasura_event_trigger_request_bytes_total: Scalars['bigint'];
  hasura_event_trigger_response_bytes_total: Scalars['bigint'];
  hasura_http_request_bytes_total: Scalars['bigint'];
  hasura_http_response_bytes_total: Scalars['bigint'];
  hasura_scheduled_trigger_request_bytes_total: Scalars['bigint'];
  hasura_scheduled_trigger_response_bytes_total: Scalars['bigint'];
  hasura_websocket_messages_received_bytes_total: Scalars['bigint'];
  hasura_websocket_messages_sent_bytes_total: Scalars['bigint'];
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id: Scalars['uuid'];
  usage_updated_at: Scalars['timestamp'];
};

/** aggregated selection of "project_data_usage_components" */
export type Project_Data_Usage_Components_Aggregate = {
  __typename?: 'project_data_usage_components_aggregate';
  aggregate?: Maybe<Project_Data_Usage_Components_Aggregate_Fields>;
  nodes: Array<Project_Data_Usage_Components>;
};

/** aggregate fields of "project_data_usage_components" */
export type Project_Data_Usage_Components_Aggregate_Fields = {
  __typename?: 'project_data_usage_components_aggregate_fields';
  avg?: Maybe<Project_Data_Usage_Components_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Data_Usage_Components_Max_Fields>;
  min?: Maybe<Project_Data_Usage_Components_Min_Fields>;
  stddev?: Maybe<Project_Data_Usage_Components_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Data_Usage_Components_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Data_Usage_Components_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Data_Usage_Components_Sum_Fields>;
  var_pop?: Maybe<Project_Data_Usage_Components_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Data_Usage_Components_Var_Samp_Fields>;
  variance?: Maybe<Project_Data_Usage_Components_Variance_Fields>;
};

/** aggregate fields of "project_data_usage_components" */
export type Project_Data_Usage_Components_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Data_Usage_Components_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Data_Usage_Components_Avg_Fields = {
  __typename?: 'project_data_usage_components_avg_fields';
  hasura_action_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_action_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_received_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_sent_bytes_total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_data_usage_components". All fields are combined with a logical 'AND'. */
export type Project_Data_Usage_Components_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Data_Usage_Components_Bool_Exp>>;
  _not?: InputMaybe<Project_Data_Usage_Components_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Data_Usage_Components_Bool_Exp>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  hasura_action_request_bytes_total?: InputMaybe<Bigint_Comparison_Exp>;
  hasura_action_response_bytes_total?: InputMaybe<Bigint_Comparison_Exp>;
  hasura_event_trigger_request_bytes_total?: InputMaybe<Bigint_Comparison_Exp>;
  hasura_event_trigger_response_bytes_total?: InputMaybe<Bigint_Comparison_Exp>;
  hasura_http_request_bytes_total?: InputMaybe<Bigint_Comparison_Exp>;
  hasura_http_response_bytes_total?: InputMaybe<Bigint_Comparison_Exp>;
  hasura_scheduled_trigger_request_bytes_total?: InputMaybe<
    Bigint_Comparison_Exp
  >;
  hasura_scheduled_trigger_response_bytes_total?: InputMaybe<
    Bigint_Comparison_Exp
  >;
  hasura_websocket_messages_received_bytes_total?: InputMaybe<
    Bigint_Comparison_Exp
  >;
  hasura_websocket_messages_sent_bytes_total?: InputMaybe<
    Bigint_Comparison_Exp
  >;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  usage_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_data_usage_components" */
export enum Project_Data_Usage_Components_Constraint {
  /** unique or primary key constraint on columns "project_id", "date" */
  ProjectDataUsageComponentsProjectIdDateKey = 'project_data_usage_components_project_id_date_key',
}

/** input type for incrementing numeric columns in table "project_data_usage_components" */
export type Project_Data_Usage_Components_Inc_Input = {
  hasura_action_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_action_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_event_trigger_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_event_trigger_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_http_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_http_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_scheduled_trigger_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_scheduled_trigger_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_websocket_messages_received_bytes_total?: InputMaybe<
    Scalars['bigint']
  >;
  hasura_websocket_messages_sent_bytes_total?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "project_data_usage_components" */
export type Project_Data_Usage_Components_Insert_Input = {
  date?: InputMaybe<Scalars['date']>;
  hasura_action_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_action_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_event_trigger_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_event_trigger_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_http_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_http_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_scheduled_trigger_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_scheduled_trigger_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_websocket_messages_received_bytes_total?: InputMaybe<
    Scalars['bigint']
  >;
  hasura_websocket_messages_sent_bytes_total?: InputMaybe<Scalars['bigint']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  usage_updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Project_Data_Usage_Components_Max_Fields = {
  __typename?: 'project_data_usage_components_max_fields';
  date?: Maybe<Scalars['date']>;
  hasura_action_request_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_action_response_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_event_trigger_request_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_event_trigger_response_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_http_request_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_http_response_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_scheduled_trigger_request_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_scheduled_trigger_response_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_websocket_messages_received_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_websocket_messages_sent_bytes_total?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  usage_updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Project_Data_Usage_Components_Min_Fields = {
  __typename?: 'project_data_usage_components_min_fields';
  date?: Maybe<Scalars['date']>;
  hasura_action_request_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_action_response_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_event_trigger_request_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_event_trigger_response_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_http_request_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_http_response_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_scheduled_trigger_request_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_scheduled_trigger_response_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_websocket_messages_received_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_websocket_messages_sent_bytes_total?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  usage_updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "project_data_usage_components" */
export type Project_Data_Usage_Components_Mutation_Response = {
  __typename?: 'project_data_usage_components_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Data_Usage_Components>;
};

/** input type for inserting object relation for remote table "project_data_usage_components" */
export type Project_Data_Usage_Components_Obj_Rel_Insert_Input = {
  data: Project_Data_Usage_Components_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Data_Usage_Components_On_Conflict>;
};

/** on_conflict condition type for table "project_data_usage_components" */
export type Project_Data_Usage_Components_On_Conflict = {
  constraint: Project_Data_Usage_Components_Constraint;
  update_columns?: Array<Project_Data_Usage_Components_Update_Column>;
  where?: InputMaybe<Project_Data_Usage_Components_Bool_Exp>;
};

/** Ordering options when selecting data from "project_data_usage_components". */
export type Project_Data_Usage_Components_Order_By = {
  date?: InputMaybe<Order_By>;
  hasura_action_request_bytes_total?: InputMaybe<Order_By>;
  hasura_action_response_bytes_total?: InputMaybe<Order_By>;
  hasura_event_trigger_request_bytes_total?: InputMaybe<Order_By>;
  hasura_event_trigger_response_bytes_total?: InputMaybe<Order_By>;
  hasura_http_request_bytes_total?: InputMaybe<Order_By>;
  hasura_http_response_bytes_total?: InputMaybe<Order_By>;
  hasura_scheduled_trigger_request_bytes_total?: InputMaybe<Order_By>;
  hasura_scheduled_trigger_response_bytes_total?: InputMaybe<Order_By>;
  hasura_websocket_messages_received_bytes_total?: InputMaybe<Order_By>;
  hasura_websocket_messages_sent_bytes_total?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  usage_updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "project_data_usage_components" */
export enum Project_Data_Usage_Components_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  HasuraActionRequestBytesTotal = 'hasura_action_request_bytes_total',
  /** column name */
  HasuraActionResponseBytesTotal = 'hasura_action_response_bytes_total',
  /** column name */
  HasuraEventTriggerRequestBytesTotal = 'hasura_event_trigger_request_bytes_total',
  /** column name */
  HasuraEventTriggerResponseBytesTotal = 'hasura_event_trigger_response_bytes_total',
  /** column name */
  HasuraHttpRequestBytesTotal = 'hasura_http_request_bytes_total',
  /** column name */
  HasuraHttpResponseBytesTotal = 'hasura_http_response_bytes_total',
  /** column name */
  HasuraScheduledTriggerRequestBytesTotal = 'hasura_scheduled_trigger_request_bytes_total',
  /** column name */
  HasuraScheduledTriggerResponseBytesTotal = 'hasura_scheduled_trigger_response_bytes_total',
  /** column name */
  HasuraWebsocketMessagesReceivedBytesTotal = 'hasura_websocket_messages_received_bytes_total',
  /** column name */
  HasuraWebsocketMessagesSentBytesTotal = 'hasura_websocket_messages_sent_bytes_total',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UsageUpdatedAt = 'usage_updated_at',
}

/** input type for updating data in table "project_data_usage_components" */
export type Project_Data_Usage_Components_Set_Input = {
  date?: InputMaybe<Scalars['date']>;
  hasura_action_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_action_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_event_trigger_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_event_trigger_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_http_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_http_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_scheduled_trigger_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_scheduled_trigger_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_websocket_messages_received_bytes_total?: InputMaybe<
    Scalars['bigint']
  >;
  hasura_websocket_messages_sent_bytes_total?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  usage_updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Project_Data_Usage_Components_Stddev_Fields = {
  __typename?: 'project_data_usage_components_stddev_fields';
  hasura_action_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_action_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_received_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_sent_bytes_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Data_Usage_Components_Stddev_Pop_Fields = {
  __typename?: 'project_data_usage_components_stddev_pop_fields';
  hasura_action_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_action_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_received_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_sent_bytes_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Data_Usage_Components_Stddev_Samp_Fields = {
  __typename?: 'project_data_usage_components_stddev_samp_fields';
  hasura_action_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_action_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_received_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_sent_bytes_total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_data_usage_components" */
export type Project_Data_Usage_Components_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Data_Usage_Components_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Data_Usage_Components_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  hasura_action_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_action_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_event_trigger_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_event_trigger_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_http_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_http_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_scheduled_trigger_request_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_scheduled_trigger_response_bytes_total?: InputMaybe<Scalars['bigint']>;
  hasura_websocket_messages_received_bytes_total?: InputMaybe<
    Scalars['bigint']
  >;
  hasura_websocket_messages_sent_bytes_total?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  usage_updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Project_Data_Usage_Components_Sum_Fields = {
  __typename?: 'project_data_usage_components_sum_fields';
  hasura_action_request_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_action_response_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_event_trigger_request_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_event_trigger_response_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_http_request_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_http_response_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_scheduled_trigger_request_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_scheduled_trigger_response_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_websocket_messages_received_bytes_total?: Maybe<Scalars['bigint']>;
  hasura_websocket_messages_sent_bytes_total?: Maybe<Scalars['bigint']>;
};

/** update columns of table "project_data_usage_components" */
export enum Project_Data_Usage_Components_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  HasuraActionRequestBytesTotal = 'hasura_action_request_bytes_total',
  /** column name */
  HasuraActionResponseBytesTotal = 'hasura_action_response_bytes_total',
  /** column name */
  HasuraEventTriggerRequestBytesTotal = 'hasura_event_trigger_request_bytes_total',
  /** column name */
  HasuraEventTriggerResponseBytesTotal = 'hasura_event_trigger_response_bytes_total',
  /** column name */
  HasuraHttpRequestBytesTotal = 'hasura_http_request_bytes_total',
  /** column name */
  HasuraHttpResponseBytesTotal = 'hasura_http_response_bytes_total',
  /** column name */
  HasuraScheduledTriggerRequestBytesTotal = 'hasura_scheduled_trigger_request_bytes_total',
  /** column name */
  HasuraScheduledTriggerResponseBytesTotal = 'hasura_scheduled_trigger_response_bytes_total',
  /** column name */
  HasuraWebsocketMessagesReceivedBytesTotal = 'hasura_websocket_messages_received_bytes_total',
  /** column name */
  HasuraWebsocketMessagesSentBytesTotal = 'hasura_websocket_messages_sent_bytes_total',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UsageUpdatedAt = 'usage_updated_at',
}

export type Project_Data_Usage_Components_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Data_Usage_Components_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Data_Usage_Components_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Data_Usage_Components_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Data_Usage_Components_Var_Pop_Fields = {
  __typename?: 'project_data_usage_components_var_pop_fields';
  hasura_action_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_action_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_received_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_sent_bytes_total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Data_Usage_Components_Var_Samp_Fields = {
  __typename?: 'project_data_usage_components_var_samp_fields';
  hasura_action_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_action_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_received_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_sent_bytes_total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Data_Usage_Components_Variance_Fields = {
  __typename?: 'project_data_usage_components_variance_fields';
  hasura_action_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_action_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_event_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_http_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_request_bytes_total?: Maybe<Scalars['Float']>;
  hasura_scheduled_trigger_response_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_received_bytes_total?: Maybe<Scalars['Float']>;
  hasura_websocket_messages_sent_bytes_total?: Maybe<Scalars['Float']>;
};

/** unique or primary key constraints on table "project_data_usage" */
export enum Project_Data_Usage_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectDataUsagePkey = 'project_data_usage_pkey',
  /** unique or primary key constraint on columns "project_id", "date" */
  ProjectDataUsageProjectIdDateKey = 'project_data_usage_project_id_date_key',
}

/** input type for incrementing numeric columns in table "project_data_usage" */
export type Project_Data_Usage_Inc_Input = {
  usage?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "project_data_usage" */
export type Project_Data_Usage_Insert_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  inv_id?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  usage?: InputMaybe<Scalars['bigint']>;
  usage_updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Project_Data_Usage_Max_Fields = {
  __typename?: 'project_data_usage_max_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  inv_id?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  usage?: Maybe<Scalars['bigint']>;
  usage_updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "project_data_usage" */
export type Project_Data_Usage_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inv_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  usage?: InputMaybe<Order_By>;
  usage_updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Data_Usage_Min_Fields = {
  __typename?: 'project_data_usage_min_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  inv_id?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  usage?: Maybe<Scalars['bigint']>;
  usage_updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "project_data_usage" */
export type Project_Data_Usage_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inv_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  usage?: InputMaybe<Order_By>;
  usage_updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_data_usage" */
export type Project_Data_Usage_Mutation_Response = {
  __typename?: 'project_data_usage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Data_Usage>;
};

/** on_conflict condition type for table "project_data_usage" */
export type Project_Data_Usage_On_Conflict = {
  constraint: Project_Data_Usage_Constraint;
  update_columns?: Array<Project_Data_Usage_Update_Column>;
  where?: InputMaybe<Project_Data_Usage_Bool_Exp>;
};

/** Ordering options when selecting data from "project_data_usage". */
export type Project_Data_Usage_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inv_id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  usage?: InputMaybe<Order_By>;
  usage_updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_data_usage */
export type Project_Data_Usage_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** This table captures the data usage, for each project per day for billing, queried from Prometheus  */
export type Project_Data_Usage_Prometheus = {
  __typename?: 'project_data_usage_prometheus';
  /** An object relationship */
  data_usage_components?: Maybe<Project_Data_Usage_Components>;
  date: Scalars['date'];
  id: Scalars['uuid'];
  invoice_id?: Maybe<Scalars['String']>;
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id: Scalars['uuid'];
  usage: Scalars['bigint'];
  usage_updated_at: Scalars['timestamp'];
};

/** used as a set of table for compute_project_agg_usage_prometheus_user function to retrieve project_ids and their aggregate usage filtered by user_id in a particular date range */
export type Project_Data_Usage_Prometheus_Agg_User = {
  __typename?: 'project_data_usage_prometheus_agg_user';
  agg_usage?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type Project_Data_Usage_Prometheus_Agg_User_Aggregate = {
  __typename?: 'project_data_usage_prometheus_agg_user_aggregate';
  aggregate?: Maybe<Project_Data_Usage_Prometheus_Agg_User_Aggregate_Fields>;
  nodes: Array<Project_Data_Usage_Prometheus_Agg_User>;
};

/** aggregate fields of "project_data_usage_prometheus_agg_user" */
export type Project_Data_Usage_Prometheus_Agg_User_Aggregate_Fields = {
  __typename?: 'project_data_usage_prometheus_agg_user_aggregate_fields';
  avg?: Maybe<Project_Data_Usage_Prometheus_Agg_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Data_Usage_Prometheus_Agg_User_Max_Fields>;
  min?: Maybe<Project_Data_Usage_Prometheus_Agg_User_Min_Fields>;
  stddev?: Maybe<Project_Data_Usage_Prometheus_Agg_User_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Data_Usage_Prometheus_Agg_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<
    Project_Data_Usage_Prometheus_Agg_User_Stddev_Samp_Fields
  >;
  sum?: Maybe<Project_Data_Usage_Prometheus_Agg_User_Sum_Fields>;
  var_pop?: Maybe<Project_Data_Usage_Prometheus_Agg_User_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Data_Usage_Prometheus_Agg_User_Var_Samp_Fields>;
  variance?: Maybe<Project_Data_Usage_Prometheus_Agg_User_Variance_Fields>;
};

/** aggregate fields of "project_data_usage_prometheus_agg_user" */
export type Project_Data_Usage_Prometheus_Agg_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Project_Data_Usage_Prometheus_Agg_User_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Data_Usage_Prometheus_Agg_User_Avg_Fields = {
  __typename?: 'project_data_usage_prometheus_agg_user_avg_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_data_usage_prometheus_agg_user". All fields are combined with a logical 'AND'. */
export type Project_Data_Usage_Prometheus_Agg_User_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Data_Usage_Prometheus_Agg_User_Bool_Exp>>;
  _not?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Data_Usage_Prometheus_Agg_User_Bool_Exp>>;
  agg_usage?: InputMaybe<Bigint_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "project_data_usage_prometheus_agg_user" */
export type Project_Data_Usage_Prometheus_Agg_User_Inc_Input = {
  agg_usage?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "project_data_usage_prometheus_agg_user" */
export type Project_Data_Usage_Prometheus_Agg_User_Insert_Input = {
  agg_usage?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Data_Usage_Prometheus_Agg_User_Max_Fields = {
  __typename?: 'project_data_usage_prometheus_agg_user_max_fields';
  agg_usage?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Project_Data_Usage_Prometheus_Agg_User_Min_Fields = {
  __typename?: 'project_data_usage_prometheus_agg_user_min_fields';
  agg_usage?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "project_data_usage_prometheus_agg_user" */
export type Project_Data_Usage_Prometheus_Agg_User_Mutation_Response = {
  __typename?: 'project_data_usage_prometheus_agg_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Data_Usage_Prometheus_Agg_User>;
};

/** Ordering options when selecting data from "project_data_usage_prometheus_agg_user". */
export type Project_Data_Usage_Prometheus_Agg_User_Order_By = {
  agg_usage?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "project_data_usage_prometheus_agg_user" */
export enum Project_Data_Usage_Prometheus_Agg_User_Select_Column {
  /** column name */
  AggUsage = 'agg_usage',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "project_data_usage_prometheus_agg_user" */
export type Project_Data_Usage_Prometheus_Agg_User_Set_Input = {
  agg_usage?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Project_Data_Usage_Prometheus_Agg_User_Stddev_Fields = {
  __typename?: 'project_data_usage_prometheus_agg_user_stddev_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Data_Usage_Prometheus_Agg_User_Stddev_Pop_Fields = {
  __typename?: 'project_data_usage_prometheus_agg_user_stddev_pop_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Data_Usage_Prometheus_Agg_User_Stddev_Samp_Fields = {
  __typename?: 'project_data_usage_prometheus_agg_user_stddev_samp_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_data_usage_prometheus_agg_user" */
export type Project_Data_Usage_Prometheus_Agg_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Data_Usage_Prometheus_Agg_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Data_Usage_Prometheus_Agg_User_Stream_Cursor_Value_Input = {
  agg_usage?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Project_Data_Usage_Prometheus_Agg_User_Sum_Fields = {
  __typename?: 'project_data_usage_prometheus_agg_user_sum_fields';
  agg_usage?: Maybe<Scalars['bigint']>;
};

export type Project_Data_Usage_Prometheus_Agg_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Data_Usage_Prometheus_Agg_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Data_Usage_Prometheus_Agg_User_Var_Pop_Fields = {
  __typename?: 'project_data_usage_prometheus_agg_user_var_pop_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Data_Usage_Prometheus_Agg_User_Var_Samp_Fields = {
  __typename?: 'project_data_usage_prometheus_agg_user_var_samp_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Data_Usage_Prometheus_Agg_User_Variance_Fields = {
  __typename?: 'project_data_usage_prometheus_agg_user_variance_fields';
  agg_usage?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Aggregate = {
  __typename?: 'project_data_usage_prometheus_aggregate';
  aggregate?: Maybe<Project_Data_Usage_Prometheus_Aggregate_Fields>;
  nodes: Array<Project_Data_Usage_Prometheus>;
};

export type Project_Data_Usage_Prometheus_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Data_Usage_Prometheus_Aggregate_Bool_Exp_Count>;
};

export type Project_Data_Usage_Prometheus_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Data_Usage_Prometheus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Data_Usage_Prometheus_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Aggregate_Fields = {
  __typename?: 'project_data_usage_prometheus_aggregate_fields';
  avg?: Maybe<Project_Data_Usage_Prometheus_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Data_Usage_Prometheus_Max_Fields>;
  min?: Maybe<Project_Data_Usage_Prometheus_Min_Fields>;
  stddev?: Maybe<Project_Data_Usage_Prometheus_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Data_Usage_Prometheus_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Data_Usage_Prometheus_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Data_Usage_Prometheus_Sum_Fields>;
  var_pop?: Maybe<Project_Data_Usage_Prometheus_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Data_Usage_Prometheus_Var_Samp_Fields>;
  variance?: Maybe<Project_Data_Usage_Prometheus_Variance_Fields>;
};

/** aggregate fields of "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Data_Usage_Prometheus_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Data_Usage_Prometheus_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Data_Usage_Prometheus_Max_Order_By>;
  min?: InputMaybe<Project_Data_Usage_Prometheus_Min_Order_By>;
  stddev?: InputMaybe<Project_Data_Usage_Prometheus_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Project_Data_Usage_Prometheus_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Project_Data_Usage_Prometheus_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Project_Data_Usage_Prometheus_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Data_Usage_Prometheus_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Project_Data_Usage_Prometheus_Var_Samp_Order_By>;
  variance?: InputMaybe<Project_Data_Usage_Prometheus_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Arr_Rel_Insert_Input = {
  data: Array<Project_Data_Usage_Prometheus_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Data_Usage_Prometheus_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Data_Usage_Prometheus_Avg_Fields = {
  __typename?: 'project_data_usage_prometheus_avg_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Avg_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_data_usage_prometheus". All fields are combined with a logical 'AND'. */
export type Project_Data_Usage_Prometheus_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Data_Usage_Prometheus_Bool_Exp>>;
  _not?: InputMaybe<Project_Data_Usage_Prometheus_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Data_Usage_Prometheus_Bool_Exp>>;
  data_usage_components?: InputMaybe<Project_Data_Usage_Components_Bool_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_id?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  usage?: InputMaybe<Bigint_Comparison_Exp>;
  usage_updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_data_usage_prometheus" */
export enum Project_Data_Usage_Prometheus_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectDataUsagePrometheusPkey = 'project_data_usage_prometheus_pkey',
  /** unique or primary key constraint on columns "project_id", "date" */
  ProjectDataUsagePrometheusProjectIdDateKey = 'project_data_usage_prometheus_project_id_date_key',
}

/** input type for incrementing numeric columns in table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Inc_Input = {
  usage?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Insert_Input = {
  data_usage_components?: InputMaybe<
    Project_Data_Usage_Components_Obj_Rel_Insert_Input
  >;
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  usage?: InputMaybe<Scalars['bigint']>;
  usage_updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Project_Data_Usage_Prometheus_Max_Fields = {
  __typename?: 'project_data_usage_prometheus_max_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_id?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  usage?: Maybe<Scalars['bigint']>;
  usage_updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  usage?: InputMaybe<Order_By>;
  usage_updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Data_Usage_Prometheus_Min_Fields = {
  __typename?: 'project_data_usage_prometheus_min_fields';
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_id?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  usage?: Maybe<Scalars['bigint']>;
  usage_updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  usage?: InputMaybe<Order_By>;
  usage_updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Mutation_Response = {
  __typename?: 'project_data_usage_prometheus_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Data_Usage_Prometheus>;
};

/** on_conflict condition type for table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_On_Conflict = {
  constraint: Project_Data_Usage_Prometheus_Constraint;
  update_columns?: Array<Project_Data_Usage_Prometheus_Update_Column>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Bool_Exp>;
};

/** Ordering options when selecting data from "project_data_usage_prometheus". */
export type Project_Data_Usage_Prometheus_Order_By = {
  data_usage_components?: InputMaybe<Project_Data_Usage_Components_Order_By>;
  date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  usage?: InputMaybe<Order_By>;
  usage_updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_data_usage_prometheus */
export type Project_Data_Usage_Prometheus_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_data_usage_prometheus" */
export enum Project_Data_Usage_Prometheus_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Usage = 'usage',
  /** column name */
  UsageUpdatedAt = 'usage_updated_at',
}

/** input type for updating data in table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Set_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  usage?: InputMaybe<Scalars['bigint']>;
  usage_updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Project_Data_Usage_Prometheus_Stddev_Fields = {
  __typename?: 'project_data_usage_prometheus_stddev_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Stddev_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Data_Usage_Prometheus_Stddev_Pop_Fields = {
  __typename?: 'project_data_usage_prometheus_stddev_pop_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Stddev_Pop_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Data_Usage_Prometheus_Stddev_Samp_Fields = {
  __typename?: 'project_data_usage_prometheus_stddev_samp_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Stddev_Samp_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Data_Usage_Prometheus_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Data_Usage_Prometheus_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  usage?: InputMaybe<Scalars['bigint']>;
  usage_updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Project_Data_Usage_Prometheus_Sum_Fields = {
  __typename?: 'project_data_usage_prometheus_sum_fields';
  usage?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Sum_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** update columns of table "project_data_usage_prometheus" */
export enum Project_Data_Usage_Prometheus_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Usage = 'usage',
  /** column name */
  UsageUpdatedAt = 'usage_updated_at',
}

export type Project_Data_Usage_Prometheus_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Data_Usage_Prometheus_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Data_Usage_Prometheus_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Data_Usage_Prometheus_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Data_Usage_Prometheus_Var_Pop_Fields = {
  __typename?: 'project_data_usage_prometheus_var_pop_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Var_Pop_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Data_Usage_Prometheus_Var_Samp_Fields = {
  __typename?: 'project_data_usage_prometheus_var_samp_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Var_Samp_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Data_Usage_Prometheus_Variance_Fields = {
  __typename?: 'project_data_usage_prometheus_variance_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_data_usage_prometheus" */
export type Project_Data_Usage_Prometheus_Variance_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** columns and relationships of "project_data_usage_report" */
export type Project_Data_Usage_Report = {
  __typename?: 'project_data_usage_report';
  bucketname: Scalars['String'];
  created_at: Scalars['timestamptz'];
  key: Scalars['String'];
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id: Scalars['uuid'];
  report_date: Scalars['date'];
};

/** aggregated selection of "project_data_usage_report" */
export type Project_Data_Usage_Report_Aggregate = {
  __typename?: 'project_data_usage_report_aggregate';
  aggregate?: Maybe<Project_Data_Usage_Report_Aggregate_Fields>;
  nodes: Array<Project_Data_Usage_Report>;
};

export type Project_Data_Usage_Report_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Data_Usage_Report_Aggregate_Bool_Exp_Count>;
};

export type Project_Data_Usage_Report_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Data_Usage_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Data_Usage_Report_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_data_usage_report" */
export type Project_Data_Usage_Report_Aggregate_Fields = {
  __typename?: 'project_data_usage_report_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Data_Usage_Report_Max_Fields>;
  min?: Maybe<Project_Data_Usage_Report_Min_Fields>;
};

/** aggregate fields of "project_data_usage_report" */
export type Project_Data_Usage_Report_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Data_Usage_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_data_usage_report" */
export type Project_Data_Usage_Report_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Data_Usage_Report_Max_Order_By>;
  min?: InputMaybe<Project_Data_Usage_Report_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_data_usage_report" */
export type Project_Data_Usage_Report_Arr_Rel_Insert_Input = {
  data: Array<Project_Data_Usage_Report_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Data_Usage_Report_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_data_usage_report". All fields are combined with a logical 'AND'. */
export type Project_Data_Usage_Report_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Data_Usage_Report_Bool_Exp>>;
  _not?: InputMaybe<Project_Data_Usage_Report_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Data_Usage_Report_Bool_Exp>>;
  bucketname?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  report_date?: InputMaybe<Date_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_data_usage_report" */
export enum Project_Data_Usage_Report_Constraint {
  /** unique or primary key constraint on columns "project_id", "report_date" */
  ProjectDataUsageReportPkey = 'project_data_usage_report_pkey',
}

/** input type for inserting data into table "project_data_usage_report" */
export type Project_Data_Usage_Report_Insert_Input = {
  bucketname?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  report_date?: InputMaybe<Scalars['date']>;
};

/** aggregate max on columns */
export type Project_Data_Usage_Report_Max_Fields = {
  __typename?: 'project_data_usage_report_max_fields';
  bucketname?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  report_date?: Maybe<Scalars['date']>;
};

/** order by max() on columns of table "project_data_usage_report" */
export type Project_Data_Usage_Report_Max_Order_By = {
  bucketname?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  report_date?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Data_Usage_Report_Min_Fields = {
  __typename?: 'project_data_usage_report_min_fields';
  bucketname?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  key?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  report_date?: Maybe<Scalars['date']>;
};

/** order by min() on columns of table "project_data_usage_report" */
export type Project_Data_Usage_Report_Min_Order_By = {
  bucketname?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  report_date?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_data_usage_report" */
export type Project_Data_Usage_Report_Mutation_Response = {
  __typename?: 'project_data_usage_report_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Data_Usage_Report>;
};

/** on_conflict condition type for table "project_data_usage_report" */
export type Project_Data_Usage_Report_On_Conflict = {
  constraint: Project_Data_Usage_Report_Constraint;
  update_columns?: Array<Project_Data_Usage_Report_Update_Column>;
  where?: InputMaybe<Project_Data_Usage_Report_Bool_Exp>;
};

/** Ordering options when selecting data from "project_data_usage_report". */
export type Project_Data_Usage_Report_Order_By = {
  bucketname?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  report_date?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_data_usage_report */
export type Project_Data_Usage_Report_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
  report_date: Scalars['date'];
};

/** select columns of table "project_data_usage_report" */
export enum Project_Data_Usage_Report_Select_Column {
  /** column name */
  Bucketname = 'bucketname',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ReportDate = 'report_date',
}

/** input type for updating data in table "project_data_usage_report" */
export type Project_Data_Usage_Report_Set_Input = {
  bucketname?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  report_date?: InputMaybe<Scalars['date']>;
};

/** Streaming cursor of the table "project_data_usage_report" */
export type Project_Data_Usage_Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Data_Usage_Report_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Data_Usage_Report_Stream_Cursor_Value_Input = {
  bucketname?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  key?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  report_date?: InputMaybe<Scalars['date']>;
};

/** update columns of table "project_data_usage_report" */
export enum Project_Data_Usage_Report_Update_Column {
  /** column name */
  Bucketname = 'bucketname',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Key = 'key',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ReportDate = 'report_date',
}

export type Project_Data_Usage_Report_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Data_Usage_Report_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Data_Usage_Report_Bool_Exp;
};

/** select columns of table "project_data_usage" */
export enum Project_Data_Usage_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  InvId = 'inv_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Usage = 'usage',
  /** column name */
  UsageUpdatedAt = 'usage_updated_at',
}

/** input type for updating data in table "project_data_usage" */
export type Project_Data_Usage_Set_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  inv_id?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  usage?: InputMaybe<Scalars['bigint']>;
  usage_updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Project_Data_Usage_Stddev_Fields = {
  __typename?: 'project_data_usage_stddev_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_data_usage" */
export type Project_Data_Usage_Stddev_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Data_Usage_Stddev_Pop_Fields = {
  __typename?: 'project_data_usage_stddev_pop_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_data_usage" */
export type Project_Data_Usage_Stddev_Pop_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Data_Usage_Stddev_Samp_Fields = {
  __typename?: 'project_data_usage_stddev_samp_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_data_usage" */
export type Project_Data_Usage_Stddev_Samp_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project_data_usage" */
export type Project_Data_Usage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Data_Usage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Data_Usage_Stream_Cursor_Value_Input = {
  date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  inv_id?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  usage?: InputMaybe<Scalars['bigint']>;
  usage_updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Project_Data_Usage_Sum_Fields = {
  __typename?: 'project_data_usage_sum_fields';
  usage?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "project_data_usage" */
export type Project_Data_Usage_Sum_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** update columns of table "project_data_usage" */
export enum Project_Data_Usage_Update_Column {
  /** column name */
  Date = 'date',
  /** column name */
  Id = 'id',
  /** column name */
  InvId = 'inv_id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Usage = 'usage',
  /** column name */
  UsageUpdatedAt = 'usage_updated_at',
}

export type Project_Data_Usage_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Data_Usage_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Data_Usage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Data_Usage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Data_Usage_Var_Pop_Fields = {
  __typename?: 'project_data_usage_var_pop_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_data_usage" */
export type Project_Data_Usage_Var_Pop_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Data_Usage_Var_Samp_Fields = {
  __typename?: 'project_data_usage_var_samp_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_data_usage" */
export type Project_Data_Usage_Var_Samp_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Data_Usage_Variance_Fields = {
  __typename?: 'project_data_usage_variance_fields';
  usage?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_data_usage" */
export type Project_Data_Usage_Variance_Order_By = {
  usage?: InputMaybe<Order_By>;
};

/** this table helps track the usage of data source for paid projects that are based on the new plans */
export type Project_Db_Usage = {
  __typename?: 'project_db_usage';
  created_at: Scalars['date'];
  id: Scalars['uuid'];
  invoice_id?: Maybe<Scalars['String']>;
  no_db_usage: Scalars['Int'];
  non_pg_usage: Scalars['Int'];
  pg_usage: Scalars['Int'];
  price: Scalars['bigint'];
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** used as a set of table for compute_project_agg_db_usage_user function to retrieve project_ids and their aggregate usage filtered by user_id in a particular date range */
export type Project_Db_Usage_Agg_User = {
  __typename?: 'project_db_usage_agg_user';
  agg_no_db_usage?: Maybe<Scalars['Int']>;
  agg_non_pg_usage?: Maybe<Scalars['Int']>;
  agg_pg_usage?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

export type Project_Db_Usage_Agg_User_Aggregate = {
  __typename?: 'project_db_usage_agg_user_aggregate';
  aggregate?: Maybe<Project_Db_Usage_Agg_User_Aggregate_Fields>;
  nodes: Array<Project_Db_Usage_Agg_User>;
};

/** aggregate fields of "project_db_usage_agg_user" */
export type Project_Db_Usage_Agg_User_Aggregate_Fields = {
  __typename?: 'project_db_usage_agg_user_aggregate_fields';
  avg?: Maybe<Project_Db_Usage_Agg_User_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Db_Usage_Agg_User_Max_Fields>;
  min?: Maybe<Project_Db_Usage_Agg_User_Min_Fields>;
  stddev?: Maybe<Project_Db_Usage_Agg_User_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Db_Usage_Agg_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Db_Usage_Agg_User_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Db_Usage_Agg_User_Sum_Fields>;
  var_pop?: Maybe<Project_Db_Usage_Agg_User_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Db_Usage_Agg_User_Var_Samp_Fields>;
  variance?: Maybe<Project_Db_Usage_Agg_User_Variance_Fields>;
};

/** aggregate fields of "project_db_usage_agg_user" */
export type Project_Db_Usage_Agg_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Db_Usage_Agg_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Db_Usage_Agg_User_Avg_Fields = {
  __typename?: 'project_db_usage_agg_user_avg_fields';
  agg_no_db_usage?: Maybe<Scalars['Float']>;
  agg_non_pg_usage?: Maybe<Scalars['Float']>;
  agg_pg_usage?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_db_usage_agg_user". All fields are combined with a logical 'AND'. */
export type Project_Db_Usage_Agg_User_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Db_Usage_Agg_User_Bool_Exp>>;
  _not?: InputMaybe<Project_Db_Usage_Agg_User_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Db_Usage_Agg_User_Bool_Exp>>;
  agg_no_db_usage?: InputMaybe<Int_Comparison_Exp>;
  agg_non_pg_usage?: InputMaybe<Int_Comparison_Exp>;
  agg_pg_usage?: InputMaybe<Int_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "project_db_usage_agg_user" */
export type Project_Db_Usage_Agg_User_Inc_Input = {
  agg_no_db_usage?: InputMaybe<Scalars['Int']>;
  agg_non_pg_usage?: InputMaybe<Scalars['Int']>;
  agg_pg_usage?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_db_usage_agg_user" */
export type Project_Db_Usage_Agg_User_Insert_Input = {
  agg_no_db_usage?: InputMaybe<Scalars['Int']>;
  agg_non_pg_usage?: InputMaybe<Scalars['Int']>;
  agg_pg_usage?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Db_Usage_Agg_User_Max_Fields = {
  __typename?: 'project_db_usage_agg_user_max_fields';
  agg_no_db_usage?: Maybe<Scalars['Int']>;
  agg_non_pg_usage?: Maybe<Scalars['Int']>;
  agg_pg_usage?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Project_Db_Usage_Agg_User_Min_Fields = {
  __typename?: 'project_db_usage_agg_user_min_fields';
  agg_no_db_usage?: Maybe<Scalars['Int']>;
  agg_non_pg_usage?: Maybe<Scalars['Int']>;
  agg_pg_usage?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "project_db_usage_agg_user" */
export type Project_Db_Usage_Agg_User_Mutation_Response = {
  __typename?: 'project_db_usage_agg_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Db_Usage_Agg_User>;
};

/** Ordering options when selecting data from "project_db_usage_agg_user". */
export type Project_Db_Usage_Agg_User_Order_By = {
  agg_no_db_usage?: InputMaybe<Order_By>;
  agg_non_pg_usage?: InputMaybe<Order_By>;
  agg_pg_usage?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "project_db_usage_agg_user" */
export enum Project_Db_Usage_Agg_User_Select_Column {
  /** column name */
  AggNoDbUsage = 'agg_no_db_usage',
  /** column name */
  AggNonPgUsage = 'agg_non_pg_usage',
  /** column name */
  AggPgUsage = 'agg_pg_usage',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "project_db_usage_agg_user" */
export type Project_Db_Usage_Agg_User_Set_Input = {
  agg_no_db_usage?: InputMaybe<Scalars['Int']>;
  agg_non_pg_usage?: InputMaybe<Scalars['Int']>;
  agg_pg_usage?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Project_Db_Usage_Agg_User_Stddev_Fields = {
  __typename?: 'project_db_usage_agg_user_stddev_fields';
  agg_no_db_usage?: Maybe<Scalars['Float']>;
  agg_non_pg_usage?: Maybe<Scalars['Float']>;
  agg_pg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Db_Usage_Agg_User_Stddev_Pop_Fields = {
  __typename?: 'project_db_usage_agg_user_stddev_pop_fields';
  agg_no_db_usage?: Maybe<Scalars['Float']>;
  agg_non_pg_usage?: Maybe<Scalars['Float']>;
  agg_pg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Db_Usage_Agg_User_Stddev_Samp_Fields = {
  __typename?: 'project_db_usage_agg_user_stddev_samp_fields';
  agg_no_db_usage?: Maybe<Scalars['Float']>;
  agg_non_pg_usage?: Maybe<Scalars['Float']>;
  agg_pg_usage?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_db_usage_agg_user" */
export type Project_Db_Usage_Agg_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Db_Usage_Agg_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Db_Usage_Agg_User_Stream_Cursor_Value_Input = {
  agg_no_db_usage?: InputMaybe<Scalars['Int']>;
  agg_non_pg_usage?: InputMaybe<Scalars['Int']>;
  agg_pg_usage?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Project_Db_Usage_Agg_User_Sum_Fields = {
  __typename?: 'project_db_usage_agg_user_sum_fields';
  agg_no_db_usage?: Maybe<Scalars['Int']>;
  agg_non_pg_usage?: Maybe<Scalars['Int']>;
  agg_pg_usage?: Maybe<Scalars['Int']>;
};

export type Project_Db_Usage_Agg_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Db_Usage_Agg_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Db_Usage_Agg_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Db_Usage_Agg_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Db_Usage_Agg_User_Var_Pop_Fields = {
  __typename?: 'project_db_usage_agg_user_var_pop_fields';
  agg_no_db_usage?: Maybe<Scalars['Float']>;
  agg_non_pg_usage?: Maybe<Scalars['Float']>;
  agg_pg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Db_Usage_Agg_User_Var_Samp_Fields = {
  __typename?: 'project_db_usage_agg_user_var_samp_fields';
  agg_no_db_usage?: Maybe<Scalars['Float']>;
  agg_non_pg_usage?: Maybe<Scalars['Float']>;
  agg_pg_usage?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Db_Usage_Agg_User_Variance_Fields = {
  __typename?: 'project_db_usage_agg_user_variance_fields';
  agg_no_db_usage?: Maybe<Scalars['Float']>;
  agg_non_pg_usage?: Maybe<Scalars['Float']>;
  agg_pg_usage?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "project_db_usage" */
export type Project_Db_Usage_Aggregate = {
  __typename?: 'project_db_usage_aggregate';
  aggregate?: Maybe<Project_Db_Usage_Aggregate_Fields>;
  nodes: Array<Project_Db_Usage>;
};

/** aggregate fields of "project_db_usage" */
export type Project_Db_Usage_Aggregate_Fields = {
  __typename?: 'project_db_usage_aggregate_fields';
  avg?: Maybe<Project_Db_Usage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Db_Usage_Max_Fields>;
  min?: Maybe<Project_Db_Usage_Min_Fields>;
  stddev?: Maybe<Project_Db_Usage_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Db_Usage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Db_Usage_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Db_Usage_Sum_Fields>;
  var_pop?: Maybe<Project_Db_Usage_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Db_Usage_Var_Samp_Fields>;
  variance?: Maybe<Project_Db_Usage_Variance_Fields>;
};

/** aggregate fields of "project_db_usage" */
export type Project_Db_Usage_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Db_Usage_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Db_Usage_Avg_Fields = {
  __typename?: 'project_db_usage_avg_fields';
  no_db_usage?: Maybe<Scalars['Float']>;
  non_pg_usage?: Maybe<Scalars['Float']>;
  pg_usage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_db_usage". All fields are combined with a logical 'AND'. */
export type Project_Db_Usage_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Db_Usage_Bool_Exp>>;
  _not?: InputMaybe<Project_Db_Usage_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Db_Usage_Bool_Exp>>;
  created_at?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_id?: InputMaybe<String_Comparison_Exp>;
  no_db_usage?: InputMaybe<Int_Comparison_Exp>;
  non_pg_usage?: InputMaybe<Int_Comparison_Exp>;
  pg_usage?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Bigint_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_db_usage" */
export enum Project_Db_Usage_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectDbUsagePkey = 'project_db_usage_pkey',
  /** unique or primary key constraint on columns "project_id", "created_at" */
  ProjectDbUsageProjectIdCreatedAtKey = 'project_db_usage_project_id_created_at_key',
}

/** input type for incrementing numeric columns in table "project_db_usage" */
export type Project_Db_Usage_Inc_Input = {
  no_db_usage?: InputMaybe<Scalars['Int']>;
  non_pg_usage?: InputMaybe<Scalars['Int']>;
  pg_usage?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "project_db_usage" */
export type Project_Db_Usage_Insert_Input = {
  created_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  no_db_usage?: InputMaybe<Scalars['Int']>;
  non_pg_usage?: InputMaybe<Scalars['Int']>;
  pg_usage?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['bigint']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Project_Db_Usage_Max_Fields = {
  __typename?: 'project_db_usage_max_fields';
  created_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_id?: Maybe<Scalars['String']>;
  no_db_usage?: Maybe<Scalars['Int']>;
  non_pg_usage?: Maybe<Scalars['Int']>;
  pg_usage?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Project_Db_Usage_Min_Fields = {
  __typename?: 'project_db_usage_min_fields';
  created_at?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_id?: Maybe<Scalars['String']>;
  no_db_usage?: Maybe<Scalars['Int']>;
  non_pg_usage?: Maybe<Scalars['Int']>;
  pg_usage?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "project_db_usage" */
export type Project_Db_Usage_Mutation_Response = {
  __typename?: 'project_db_usage_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Db_Usage>;
};

/** on_conflict condition type for table "project_db_usage" */
export type Project_Db_Usage_On_Conflict = {
  constraint: Project_Db_Usage_Constraint;
  update_columns?: Array<Project_Db_Usage_Update_Column>;
  where?: InputMaybe<Project_Db_Usage_Bool_Exp>;
};

/** Ordering options when selecting data from "project_db_usage". */
export type Project_Db_Usage_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  no_db_usage?: InputMaybe<Order_By>;
  non_pg_usage?: InputMaybe<Order_By>;
  pg_usage?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_db_usage */
export type Project_Db_Usage_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_db_usage" */
export enum Project_Db_Usage_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  NoDbUsage = 'no_db_usage',
  /** column name */
  NonPgUsage = 'non_pg_usage',
  /** column name */
  PgUsage = 'pg_usage',
  /** column name */
  Price = 'price',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "project_db_usage" */
export type Project_Db_Usage_Set_Input = {
  created_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  no_db_usage?: InputMaybe<Scalars['Int']>;
  non_pg_usage?: InputMaybe<Scalars['Int']>;
  pg_usage?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Project_Db_Usage_Stddev_Fields = {
  __typename?: 'project_db_usage_stddev_fields';
  no_db_usage?: Maybe<Scalars['Float']>;
  non_pg_usage?: Maybe<Scalars['Float']>;
  pg_usage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Db_Usage_Stddev_Pop_Fields = {
  __typename?: 'project_db_usage_stddev_pop_fields';
  no_db_usage?: Maybe<Scalars['Float']>;
  non_pg_usage?: Maybe<Scalars['Float']>;
  pg_usage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Db_Usage_Stddev_Samp_Fields = {
  __typename?: 'project_db_usage_stddev_samp_fields';
  no_db_usage?: Maybe<Scalars['Float']>;
  non_pg_usage?: Maybe<Scalars['Float']>;
  pg_usage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_db_usage" */
export type Project_Db_Usage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Db_Usage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Db_Usage_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  no_db_usage?: InputMaybe<Scalars['Int']>;
  non_pg_usage?: InputMaybe<Scalars['Int']>;
  pg_usage?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['bigint']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Project_Db_Usage_Sum_Fields = {
  __typename?: 'project_db_usage_sum_fields';
  no_db_usage?: Maybe<Scalars['Int']>;
  non_pg_usage?: Maybe<Scalars['Int']>;
  pg_usage?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
};

/** update columns of table "project_db_usage" */
export enum Project_Db_Usage_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  NoDbUsage = 'no_db_usage',
  /** column name */
  NonPgUsage = 'non_pg_usage',
  /** column name */
  PgUsage = 'pg_usage',
  /** column name */
  Price = 'price',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Project_Db_Usage_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Db_Usage_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Db_Usage_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Db_Usage_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Db_Usage_Var_Pop_Fields = {
  __typename?: 'project_db_usage_var_pop_fields';
  no_db_usage?: Maybe<Scalars['Float']>;
  non_pg_usage?: Maybe<Scalars['Float']>;
  pg_usage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Db_Usage_Var_Samp_Fields = {
  __typename?: 'project_db_usage_var_samp_fields';
  no_db_usage?: Maybe<Scalars['Float']>;
  non_pg_usage?: Maybe<Scalars['Float']>;
  pg_usage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Db_Usage_Variance_Fields = {
  __typename?: 'project_db_usage_variance_fields';
  no_db_usage?: Maybe<Scalars['Float']>;
  non_pg_usage?: Maybe<Scalars['Float']>;
  pg_usage?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
};

/** Each entry in this table represents a specific project entitlement from project_entitlement_catalogue that a project has access to. A project can only have one version of a given type of entitlement at a time. */
export type Project_Entitlement_Access = {
  __typename?: 'project_entitlement_access';
  created_at: Scalars['timestamp'];
  /** An object relationship */
  entitlement: Project_Entitlement_Catalogue;
  entitlement_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "project_entitlement_access" */
export type Project_Entitlement_Access_Aggregate = {
  __typename?: 'project_entitlement_access_aggregate';
  aggregate?: Maybe<Project_Entitlement_Access_Aggregate_Fields>;
  nodes: Array<Project_Entitlement_Access>;
};

export type Project_Entitlement_Access_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Entitlement_Access_Aggregate_Bool_Exp_Count>;
};

export type Project_Entitlement_Access_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Entitlement_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Entitlement_Access_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_entitlement_access" */
export type Project_Entitlement_Access_Aggregate_Fields = {
  __typename?: 'project_entitlement_access_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Entitlement_Access_Max_Fields>;
  min?: Maybe<Project_Entitlement_Access_Min_Fields>;
};

/** aggregate fields of "project_entitlement_access" */
export type Project_Entitlement_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Entitlement_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_entitlement_access" */
export type Project_Entitlement_Access_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Entitlement_Access_Max_Order_By>;
  min?: InputMaybe<Project_Entitlement_Access_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_entitlement_access" */
export type Project_Entitlement_Access_Arr_Rel_Insert_Input = {
  data: Array<Project_Entitlement_Access_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Entitlement_Access_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_entitlement_access". All fields are combined with a logical 'AND'. */
export type Project_Entitlement_Access_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Entitlement_Access_Bool_Exp>>;
  _not?: InputMaybe<Project_Entitlement_Access_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Entitlement_Access_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  entitlement?: InputMaybe<Project_Entitlement_Catalogue_Bool_Exp>;
  entitlement_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_entitlement_access" */
export enum Project_Entitlement_Access_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectEntitlementAccessPkey = 'project_entitlement_access_pkey',
}

/** input type for inserting data into table "project_entitlement_access" */
export type Project_Entitlement_Access_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  entitlement?: InputMaybe<Project_Entitlement_Catalogue_Obj_Rel_Insert_Input>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Project_Entitlement_Access_Max_Fields = {
  __typename?: 'project_entitlement_access_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  entitlement_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by max() on columns of table "project_entitlement_access" */
export type Project_Entitlement_Access_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Entitlement_Access_Min_Fields = {
  __typename?: 'project_entitlement_access_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  entitlement_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** order by min() on columns of table "project_entitlement_access" */
export type Project_Entitlement_Access_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_entitlement_access" */
export type Project_Entitlement_Access_Mutation_Response = {
  __typename?: 'project_entitlement_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Entitlement_Access>;
};

/** on_conflict condition type for table "project_entitlement_access" */
export type Project_Entitlement_Access_On_Conflict = {
  constraint: Project_Entitlement_Access_Constraint;
  update_columns?: Array<Project_Entitlement_Access_Update_Column>;
  where?: InputMaybe<Project_Entitlement_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "project_entitlement_access". */
export type Project_Entitlement_Access_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entitlement?: InputMaybe<Project_Entitlement_Catalogue_Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_entitlement_access */
export type Project_Entitlement_Access_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_entitlement_access" */
export enum Project_Entitlement_Access_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntitlementId = 'entitlement_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "project_entitlement_access" */
export type Project_Entitlement_Access_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** Streaming cursor of the table "project_entitlement_access" */
export type Project_Entitlement_Access_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Entitlement_Access_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Entitlement_Access_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** update columns of table "project_entitlement_access" */
export enum Project_Entitlement_Access_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntitlementId = 'entitlement_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Project_Entitlement_Access_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Entitlement_Access_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Entitlement_Access_Bool_Exp;
};

/** Stores all versions of all types of entitlements. */
export type Project_Entitlement_Catalogue = {
  __typename?: 'project_entitlement_catalogue';
  config_is_enabled: Scalars['Boolean'];
  config_limit?: Maybe<Scalars['bigint']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  type: Project_Entitlement_Types_Enum;
  updated_at: Scalars['timestamp'];
};

/** aggregated selection of "project_entitlement_catalogue" */
export type Project_Entitlement_Catalogue_Aggregate = {
  __typename?: 'project_entitlement_catalogue_aggregate';
  aggregate?: Maybe<Project_Entitlement_Catalogue_Aggregate_Fields>;
  nodes: Array<Project_Entitlement_Catalogue>;
};

/** aggregate fields of "project_entitlement_catalogue" */
export type Project_Entitlement_Catalogue_Aggregate_Fields = {
  __typename?: 'project_entitlement_catalogue_aggregate_fields';
  avg?: Maybe<Project_Entitlement_Catalogue_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Entitlement_Catalogue_Max_Fields>;
  min?: Maybe<Project_Entitlement_Catalogue_Min_Fields>;
  stddev?: Maybe<Project_Entitlement_Catalogue_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Entitlement_Catalogue_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Entitlement_Catalogue_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Entitlement_Catalogue_Sum_Fields>;
  var_pop?: Maybe<Project_Entitlement_Catalogue_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Entitlement_Catalogue_Var_Samp_Fields>;
  variance?: Maybe<Project_Entitlement_Catalogue_Variance_Fields>;
};

/** aggregate fields of "project_entitlement_catalogue" */
export type Project_Entitlement_Catalogue_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Entitlement_Catalogue_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Entitlement_Catalogue_Avg_Fields = {
  __typename?: 'project_entitlement_catalogue_avg_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_entitlement_catalogue". All fields are combined with a logical 'AND'. */
export type Project_Entitlement_Catalogue_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Entitlement_Catalogue_Bool_Exp>>;
  _not?: InputMaybe<Project_Entitlement_Catalogue_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Entitlement_Catalogue_Bool_Exp>>;
  config_is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  config_limit?: InputMaybe<Bigint_Comparison_Exp>;
  cost?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Project_Entitlement_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_entitlement_catalogue" */
export enum Project_Entitlement_Catalogue_Constraint {
  /** unique or primary key constraint on columns "name" */
  ProjectEntitlementCatalogueNameKey = 'project_entitlement_catalogue_name_key',
  /** unique or primary key constraint on columns "id" */
  ProjectEntitlementCataloguePkey = 'project_entitlement_catalogue_pkey',
}

/** input type for incrementing numeric columns in table "project_entitlement_catalogue" */
export type Project_Entitlement_Catalogue_Inc_Input = {
  config_limit?: InputMaybe<Scalars['bigint']>;
  /** Cost of entitlement in microdollars */
  cost?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "project_entitlement_catalogue" */
export type Project_Entitlement_Catalogue_Insert_Input = {
  config_is_enabled?: InputMaybe<Scalars['Boolean']>;
  config_limit?: InputMaybe<Scalars['bigint']>;
  /** Cost of entitlement in microdollars */
  cost?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Project_Entitlement_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Project_Entitlement_Catalogue_Max_Fields = {
  __typename?: 'project_entitlement_catalogue_max_fields';
  config_limit?: Maybe<Scalars['bigint']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Project_Entitlement_Catalogue_Min_Fields = {
  __typename?: 'project_entitlement_catalogue_min_fields';
  config_limit?: Maybe<Scalars['bigint']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "project_entitlement_catalogue" */
export type Project_Entitlement_Catalogue_Mutation_Response = {
  __typename?: 'project_entitlement_catalogue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Entitlement_Catalogue>;
};

/** input type for inserting object relation for remote table "project_entitlement_catalogue" */
export type Project_Entitlement_Catalogue_Obj_Rel_Insert_Input = {
  data: Project_Entitlement_Catalogue_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Entitlement_Catalogue_On_Conflict>;
};

/** on_conflict condition type for table "project_entitlement_catalogue" */
export type Project_Entitlement_Catalogue_On_Conflict = {
  constraint: Project_Entitlement_Catalogue_Constraint;
  update_columns?: Array<Project_Entitlement_Catalogue_Update_Column>;
  where?: InputMaybe<Project_Entitlement_Catalogue_Bool_Exp>;
};

/** Ordering options when selecting data from "project_entitlement_catalogue". */
export type Project_Entitlement_Catalogue_Order_By = {
  config_is_enabled?: InputMaybe<Order_By>;
  config_limit?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_entitlement_catalogue */
export type Project_Entitlement_Catalogue_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_entitlement_catalogue" */
export enum Project_Entitlement_Catalogue_Select_Column {
  /** column name */
  ConfigIsEnabled = 'config_is_enabled',
  /** column name */
  ConfigLimit = 'config_limit',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "project_entitlement_catalogue" */
export type Project_Entitlement_Catalogue_Set_Input = {
  config_is_enabled?: InputMaybe<Scalars['Boolean']>;
  config_limit?: InputMaybe<Scalars['bigint']>;
  /** Cost of entitlement in microdollars */
  cost?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Project_Entitlement_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Project_Entitlement_Catalogue_Stddev_Fields = {
  __typename?: 'project_entitlement_catalogue_stddev_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Entitlement_Catalogue_Stddev_Pop_Fields = {
  __typename?: 'project_entitlement_catalogue_stddev_pop_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Entitlement_Catalogue_Stddev_Samp_Fields = {
  __typename?: 'project_entitlement_catalogue_stddev_samp_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_entitlement_catalogue" */
export type Project_Entitlement_Catalogue_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Entitlement_Catalogue_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Entitlement_Catalogue_Stream_Cursor_Value_Input = {
  config_is_enabled?: InputMaybe<Scalars['Boolean']>;
  config_limit?: InputMaybe<Scalars['bigint']>;
  /** Cost of entitlement in microdollars */
  cost?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Project_Entitlement_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Project_Entitlement_Catalogue_Sum_Fields = {
  __typename?: 'project_entitlement_catalogue_sum_fields';
  config_limit?: Maybe<Scalars['bigint']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['bigint']>;
};

/** update columns of table "project_entitlement_catalogue" */
export enum Project_Entitlement_Catalogue_Update_Column {
  /** column name */
  ConfigIsEnabled = 'config_is_enabled',
  /** column name */
  ConfigLimit = 'config_limit',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Project_Entitlement_Catalogue_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Entitlement_Catalogue_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Entitlement_Catalogue_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Entitlement_Catalogue_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Entitlement_Catalogue_Var_Pop_Fields = {
  __typename?: 'project_entitlement_catalogue_var_pop_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Entitlement_Catalogue_Var_Samp_Fields = {
  __typename?: 'project_entitlement_catalogue_var_samp_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Entitlement_Catalogue_Variance_Fields = {
  __typename?: 'project_entitlement_catalogue_variance_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** Enums to represent all types of entitlements a project can have. */
export type Project_Entitlement_Types = {
  __typename?: 'project_entitlement_types';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "project_entitlement_types" */
export type Project_Entitlement_Types_Aggregate = {
  __typename?: 'project_entitlement_types_aggregate';
  aggregate?: Maybe<Project_Entitlement_Types_Aggregate_Fields>;
  nodes: Array<Project_Entitlement_Types>;
};

/** aggregate fields of "project_entitlement_types" */
export type Project_Entitlement_Types_Aggregate_Fields = {
  __typename?: 'project_entitlement_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Entitlement_Types_Max_Fields>;
  min?: Maybe<Project_Entitlement_Types_Min_Fields>;
};

/** aggregate fields of "project_entitlement_types" */
export type Project_Entitlement_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Entitlement_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "project_entitlement_types". All fields are combined with a logical 'AND'. */
export type Project_Entitlement_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Entitlement_Types_Bool_Exp>>;
  _not?: InputMaybe<Project_Entitlement_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Entitlement_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_entitlement_types" */
export enum Project_Entitlement_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  ProjectEntitlementTypesPkey = 'project_entitlement_types_pkey',
}

export enum Project_Entitlement_Types_Enum {
  /** Azure Monitor APM integration: export a project's logs and metrics to Azure Monitor. */
  ApmIntegrationAzuremonitor = 'apm_integration_azuremonitor',
  /** Datadog APM integration: export a project's logs and metrics to Datadog. */
  ApmIntegrationDatadog = 'apm_integration_datadog',
  /** New Relic APM integration: export a project's logs and metrics to New Relic. */
  ApmIntegrationNewrelic = 'apm_integration_newrelic',
  /** Opentelemetry APM integration: export a project's logs and metrics to Opentelemetry. */
  ApmIntegrationOpentelemetry = 'apm_integration_opentelemetry',
  /** Prometheus APM integration: provides an endpoint to fetch project's metrics for ingestion into Prometheus server. */
  ApmIntegrationPrometheus = 'apm_integration_prometheus',
  /** Maximum number of collaborators that can be added to a project */
  CollaboratorLimit = 'collaborator_limit',
  /** Allow to add a collaborator to the project with admin privilege. */
  CollaboratorPrivilegeAdmin = 'collaborator_privilege_admin',
  /** Allow to add a collaborator to the project with graphql_admin privilege. */
  CollaboratorPrivilegeGraphqlAdmin = 'collaborator_privilege_graphql_admin',
  /** Allow to add a collaborator to the project with view_metrics privilege. */
  CollaboratorPrivilegeViewMetrics = 'collaborator_privilege_view_metrics',
  /** Configure access to the metrics tab on the project's HGE console. */
  ConsoleMetricsTab = 'console_metrics_tab',
  /** Configure custom domains for projects. */
  CustomDomainLimit = 'custom_domain_limit',
  /** Maximum amount of data passthrough allowed for a project per month. Unit is bytes. */
  DataPassthroughLimit = 'data_passthrough_limit',
  /** Maximum number of databases that can be connected to a project */
  DbLimit = 'db_limit',
  /** Configure Graphql allow lists for a project */
  GqlAllowLists = 'gql_allow_lists',
  /** Configure multiple admin secrets for a project */
  MultipleAdminSecrets = 'multiple_admin_secrets',
  /** Configure multiple JWT secrets for a project */
  MultipleJwt = 'multiple_jwt',
  /** Cost per hour if the project is not connected to any database. */
  NoDb = 'no_db',
  /** Cost and access to connecting a non Postgres databases to a project. */
  NonPgDb = 'non_pg_db',
  /** Configure access and limit for read replicas */
  ReadReplicas = 'read_replicas',
  /** Move a project between cloud host regions */
  RegionMigration = 'region_migration',
  /** Configure access to the metrics endpoint on tenant */
  ServerMetricsEndpoint = 'server_metrics_endpoint',
  /** Cost and access to connecting a Vanilla Postgres databases to a project. */
  VanillaPgDb = 'vanilla_pg_db',
}

/** Boolean expression to compare columns of type "project_entitlement_types_enum". All fields are combined with logical 'AND'. */
export type Project_Entitlement_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Project_Entitlement_Types_Enum>;
  _in?: InputMaybe<Array<Project_Entitlement_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Project_Entitlement_Types_Enum>;
  _nin?: InputMaybe<Array<Project_Entitlement_Types_Enum>>;
};

/** input type for inserting data into table "project_entitlement_types" */
export type Project_Entitlement_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Entitlement_Types_Max_Fields = {
  __typename?: 'project_entitlement_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Entitlement_Types_Min_Fields = {
  __typename?: 'project_entitlement_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project_entitlement_types" */
export type Project_Entitlement_Types_Mutation_Response = {
  __typename?: 'project_entitlement_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Entitlement_Types>;
};

/** on_conflict condition type for table "project_entitlement_types" */
export type Project_Entitlement_Types_On_Conflict = {
  constraint: Project_Entitlement_Types_Constraint;
  update_columns?: Array<Project_Entitlement_Types_Update_Column>;
  where?: InputMaybe<Project_Entitlement_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "project_entitlement_types". */
export type Project_Entitlement_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_entitlement_types */
export type Project_Entitlement_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "project_entitlement_types" */
export enum Project_Entitlement_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "project_entitlement_types" */
export type Project_Entitlement_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "project_entitlement_types" */
export type Project_Entitlement_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Entitlement_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Entitlement_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "project_entitlement_types" */
export enum Project_Entitlement_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Project_Entitlement_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Entitlement_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Entitlement_Types_Bool_Exp;
};

/** Labels that can be created per project */
export type Project_Labels = {
  __typename?: 'project_labels';
  added_at: Scalars['timetz'];
  /** An object relationship */
  label: Label;
  label_id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "project_labels" */
export type Project_Labels_Aggregate = {
  __typename?: 'project_labels_aggregate';
  aggregate?: Maybe<Project_Labels_Aggregate_Fields>;
  nodes: Array<Project_Labels>;
};

export type Project_Labels_Aggregate_Bool_Exp = {
  count?: InputMaybe<Project_Labels_Aggregate_Bool_Exp_Count>;
};

export type Project_Labels_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Project_Labels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Labels_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_labels" */
export type Project_Labels_Aggregate_Fields = {
  __typename?: 'project_labels_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Project_Labels_Max_Fields>;
  min?: Maybe<Project_Labels_Min_Fields>;
};

/** aggregate fields of "project_labels" */
export type Project_Labels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Labels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_labels" */
export type Project_Labels_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Labels_Max_Order_By>;
  min?: InputMaybe<Project_Labels_Min_Order_By>;
};

/** input type for inserting array relation for remote table "project_labels" */
export type Project_Labels_Arr_Rel_Insert_Input = {
  data: Array<Project_Labels_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Labels_On_Conflict>;
};

/** Boolean expression to filter rows from the table "project_labels". All fields are combined with a logical 'AND'. */
export type Project_Labels_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Labels_Bool_Exp>>;
  _not?: InputMaybe<Project_Labels_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Labels_Bool_Exp>>;
  added_at?: InputMaybe<Timetz_Comparison_Exp>;
  label?: InputMaybe<Label_Bool_Exp>;
  label_id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_labels" */
export enum Project_Labels_Constraint {
  /** unique or primary key constraint on columns "project_id", "label_id" */
  ProjectTagsPkey = 'project_tags_pkey',
}

/** input type for inserting data into table "project_labels" */
export type Project_Labels_Insert_Input = {
  added_at?: InputMaybe<Scalars['timetz']>;
  label?: InputMaybe<Label_Obj_Rel_Insert_Input>;
  label_id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Project_Labels_Max_Fields = {
  __typename?: 'project_labels_max_fields';
  added_at?: Maybe<Scalars['timetz']>;
  label_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "project_labels" */
export type Project_Labels_Max_Order_By = {
  added_at?: InputMaybe<Order_By>;
  label_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Labels_Min_Fields = {
  __typename?: 'project_labels_min_fields';
  added_at?: Maybe<Scalars['timetz']>;
  label_id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "project_labels" */
export type Project_Labels_Min_Order_By = {
  added_at?: InputMaybe<Order_By>;
  label_id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_labels" */
export type Project_Labels_Mutation_Response = {
  __typename?: 'project_labels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Labels>;
};

/** on_conflict condition type for table "project_labels" */
export type Project_Labels_On_Conflict = {
  constraint: Project_Labels_Constraint;
  update_columns?: Array<Project_Labels_Update_Column>;
  where?: InputMaybe<Project_Labels_Bool_Exp>;
};

/** Ordering options when selecting data from "project_labels". */
export type Project_Labels_Order_By = {
  added_at?: InputMaybe<Order_By>;
  label?: InputMaybe<Label_Order_By>;
  label_id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_labels */
export type Project_Labels_Pk_Columns_Input = {
  label_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

/** select columns of table "project_labels" */
export enum Project_Labels_Select_Column {
  /** column name */
  AddedAt = 'added_at',
  /** column name */
  LabelId = 'label_id',
  /** column name */
  ProjectId = 'project_id',
}

/** input type for updating data in table "project_labels" */
export type Project_Labels_Set_Input = {
  added_at?: InputMaybe<Scalars['timetz']>;
  label_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "project_labels" */
export type Project_Labels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Labels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Labels_Stream_Cursor_Value_Input = {
  added_at?: InputMaybe<Scalars['timetz']>;
  label_id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "project_labels" */
export enum Project_Labels_Update_Column {
  /** column name */
  AddedAt = 'added_at',
  /** column name */
  LabelId = 'label_id',
  /** column name */
  ProjectId = 'project_id',
}

export type Project_Labels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Labels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Labels_Bool_Exp;
};

/** Metadata of a project (e.g. Heroku app connection) */
export type Project_Metadata = {
  __typename?: 'project_metadata';
  connected_heroku_app_name?: Maybe<Scalars['String']>;
  environment_color?: Maybe<Scalars['String']>;
  environment_name?: Maybe<Scalars['String']>;
  heroku_managed_mode: Scalars['Boolean'];
  metrics_flags?: Maybe<Array<Scalars['String']>>;
  num_read_replicas: Scalars['Int'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  webhook_id?: Maybe<Scalars['String']>;
};

/** aggregated selection of "project_metadata" */
export type Project_Metadata_Aggregate = {
  __typename?: 'project_metadata_aggregate';
  aggregate?: Maybe<Project_Metadata_Aggregate_Fields>;
  nodes: Array<Project_Metadata>;
};

/** aggregate fields of "project_metadata" */
export type Project_Metadata_Aggregate_Fields = {
  __typename?: 'project_metadata_aggregate_fields';
  avg?: Maybe<Project_Metadata_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Metadata_Max_Fields>;
  min?: Maybe<Project_Metadata_Min_Fields>;
  stddev?: Maybe<Project_Metadata_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Metadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Metadata_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Metadata_Sum_Fields>;
  var_pop?: Maybe<Project_Metadata_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Metadata_Var_Samp_Fields>;
  variance?: Maybe<Project_Metadata_Variance_Fields>;
};

/** aggregate fields of "project_metadata" */
export type Project_Metadata_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Metadata_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Metadata_Avg_Fields = {
  __typename?: 'project_metadata_avg_fields';
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_metadata". All fields are combined with a logical 'AND'. */
export type Project_Metadata_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Metadata_Bool_Exp>>;
  _not?: InputMaybe<Project_Metadata_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Metadata_Bool_Exp>>;
  connected_heroku_app_name?: InputMaybe<String_Comparison_Exp>;
  environment_color?: InputMaybe<String_Comparison_Exp>;
  environment_name?: InputMaybe<String_Comparison_Exp>;
  heroku_managed_mode?: InputMaybe<Boolean_Comparison_Exp>;
  metrics_flags?: InputMaybe<String_Array_Comparison_Exp>;
  num_read_replicas?: InputMaybe<Int_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  webhook_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_metadata" */
export enum Project_Metadata_Constraint {
  /** unique or primary key constraint on columns "project_id" */
  ProjectMetadataPkey = 'project_metadata_pkey',
}

/** input type for incrementing numeric columns in table "project_metadata" */
export type Project_Metadata_Inc_Input = {
  num_read_replicas?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_metadata" */
export type Project_Metadata_Insert_Input = {
  connected_heroku_app_name?: InputMaybe<Scalars['String']>;
  environment_color?: InputMaybe<Scalars['String']>;
  environment_name?: InputMaybe<Scalars['String']>;
  heroku_managed_mode?: InputMaybe<Scalars['Boolean']>;
  metrics_flags?: InputMaybe<Array<Scalars['String']>>;
  num_read_replicas?: InputMaybe<Scalars['Int']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  webhook_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Metadata_Max_Fields = {
  __typename?: 'project_metadata_max_fields';
  connected_heroku_app_name?: Maybe<Scalars['String']>;
  environment_color?: Maybe<Scalars['String']>;
  environment_name?: Maybe<Scalars['String']>;
  metrics_flags?: Maybe<Array<Scalars['String']>>;
  num_read_replicas?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  webhook_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Metadata_Min_Fields = {
  __typename?: 'project_metadata_min_fields';
  connected_heroku_app_name?: Maybe<Scalars['String']>;
  environment_color?: Maybe<Scalars['String']>;
  environment_name?: Maybe<Scalars['String']>;
  metrics_flags?: Maybe<Array<Scalars['String']>>;
  num_read_replicas?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  webhook_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "project_metadata" */
export type Project_Metadata_Mutation_Response = {
  __typename?: 'project_metadata_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Metadata>;
};

/** input type for inserting object relation for remote table "project_metadata" */
export type Project_Metadata_Obj_Rel_Insert_Input = {
  data: Project_Metadata_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Metadata_On_Conflict>;
};

/** on_conflict condition type for table "project_metadata" */
export type Project_Metadata_On_Conflict = {
  constraint: Project_Metadata_Constraint;
  update_columns?: Array<Project_Metadata_Update_Column>;
  where?: InputMaybe<Project_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "project_metadata". */
export type Project_Metadata_Order_By = {
  connected_heroku_app_name?: InputMaybe<Order_By>;
  environment_color?: InputMaybe<Order_By>;
  environment_name?: InputMaybe<Order_By>;
  heroku_managed_mode?: InputMaybe<Order_By>;
  metrics_flags?: InputMaybe<Order_By>;
  num_read_replicas?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  webhook_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_metadata */
export type Project_Metadata_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
};

/** select columns of table "project_metadata" */
export enum Project_Metadata_Select_Column {
  /** column name */
  ConnectedHerokuAppName = 'connected_heroku_app_name',
  /** column name */
  EnvironmentColor = 'environment_color',
  /** column name */
  EnvironmentName = 'environment_name',
  /** column name */
  HerokuManagedMode = 'heroku_managed_mode',
  /** column name */
  MetricsFlags = 'metrics_flags',
  /** column name */
  NumReadReplicas = 'num_read_replicas',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  WebhookId = 'webhook_id',
}

/** input type for updating data in table "project_metadata" */
export type Project_Metadata_Set_Input = {
  connected_heroku_app_name?: InputMaybe<Scalars['String']>;
  environment_color?: InputMaybe<Scalars['String']>;
  environment_name?: InputMaybe<Scalars['String']>;
  heroku_managed_mode?: InputMaybe<Scalars['Boolean']>;
  metrics_flags?: InputMaybe<Array<Scalars['String']>>;
  num_read_replicas?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  webhook_id?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Project_Metadata_Stddev_Fields = {
  __typename?: 'project_metadata_stddev_fields';
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Metadata_Stddev_Pop_Fields = {
  __typename?: 'project_metadata_stddev_pop_fields';
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Metadata_Stddev_Samp_Fields = {
  __typename?: 'project_metadata_stddev_samp_fields';
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_metadata" */
export type Project_Metadata_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Metadata_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Metadata_Stream_Cursor_Value_Input = {
  connected_heroku_app_name?: InputMaybe<Scalars['String']>;
  environment_color?: InputMaybe<Scalars['String']>;
  environment_name?: InputMaybe<Scalars['String']>;
  heroku_managed_mode?: InputMaybe<Scalars['Boolean']>;
  metrics_flags?: InputMaybe<Array<Scalars['String']>>;
  num_read_replicas?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  webhook_id?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Project_Metadata_Sum_Fields = {
  __typename?: 'project_metadata_sum_fields';
  num_read_replicas?: Maybe<Scalars['Int']>;
};

/** update columns of table "project_metadata" */
export enum Project_Metadata_Update_Column {
  /** column name */
  ConnectedHerokuAppName = 'connected_heroku_app_name',
  /** column name */
  EnvironmentColor = 'environment_color',
  /** column name */
  EnvironmentName = 'environment_name',
  /** column name */
  HerokuManagedMode = 'heroku_managed_mode',
  /** column name */
  MetricsFlags = 'metrics_flags',
  /** column name */
  NumReadReplicas = 'num_read_replicas',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  WebhookId = 'webhook_id',
}

export type Project_Metadata_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Metadata_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Metadata_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Metadata_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Metadata_Var_Pop_Fields = {
  __typename?: 'project_metadata_var_pop_fields';
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Metadata_Var_Samp_Fields = {
  __typename?: 'project_metadata_var_samp_fields';
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Metadata_Variance_Fields = {
  __typename?: 'project_metadata_variance_fields';
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** Notifications that can be triggered per project */
export type Project_Notification = {
  __typename?: 'project_notification';
  created_at: Scalars['timestamptz'];
  has_notified: Scalars['Boolean'];
  meta: Scalars['jsonb'];
  month: Scalars['Int'];
  project_id: Scalars['uuid'];
  type: Scalars['reminder_type_enum'];
  updated_at: Scalars['timestamptz'];
  year: Scalars['Int'];
};

/** Notifications that can be triggered per project */
export type Project_NotificationMetaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "project_notification" */
export type Project_Notification_Aggregate = {
  __typename?: 'project_notification_aggregate';
  aggregate?: Maybe<Project_Notification_Aggregate_Fields>;
  nodes: Array<Project_Notification>;
};

/** aggregate fields of "project_notification" */
export type Project_Notification_Aggregate_Fields = {
  __typename?: 'project_notification_aggregate_fields';
  avg?: Maybe<Project_Notification_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Notification_Max_Fields>;
  min?: Maybe<Project_Notification_Min_Fields>;
  stddev?: Maybe<Project_Notification_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Notification_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Notification_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Notification_Sum_Fields>;
  var_pop?: Maybe<Project_Notification_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Notification_Var_Samp_Fields>;
  variance?: Maybe<Project_Notification_Variance_Fields>;
};

/** aggregate fields of "project_notification" */
export type Project_Notification_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Notification_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Project_Notification_Append_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Project_Notification_Avg_Fields = {
  __typename?: 'project_notification_avg_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_notification". All fields are combined with a logical 'AND'. */
export type Project_Notification_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Notification_Bool_Exp>>;
  _not?: InputMaybe<Project_Notification_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Notification_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  has_notified?: InputMaybe<Boolean_Comparison_Exp>;
  meta?: InputMaybe<Jsonb_Comparison_Exp>;
  month?: InputMaybe<Int_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Reminder_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  year?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_notification" */
export enum Project_Notification_Constraint {
  /** unique or primary key constraint on columns "project_id", "year", "type", "month" */
  ProjectNotificationPkey = 'project_notification_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Project_Notification_Delete_At_Path_Input = {
  meta?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Project_Notification_Delete_Elem_Input = {
  meta?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Project_Notification_Delete_Key_Input = {
  meta?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "project_notification" */
export type Project_Notification_Inc_Input = {
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_notification" */
export type Project_Notification_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  has_notified?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  month?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['reminder_type_enum']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Project_Notification_Max_Fields = {
  __typename?: 'project_notification_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  month?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['reminder_type_enum']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type Project_Notification_Min_Fields = {
  __typename?: 'project_notification_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  month?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['reminder_type_enum']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  year?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "project_notification" */
export type Project_Notification_Mutation_Response = {
  __typename?: 'project_notification_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Notification>;
};

/** on_conflict condition type for table "project_notification" */
export type Project_Notification_On_Conflict = {
  constraint: Project_Notification_Constraint;
  update_columns?: Array<Project_Notification_Update_Column>;
  where?: InputMaybe<Project_Notification_Bool_Exp>;
};

/** Ordering options when selecting data from "project_notification". */
export type Project_Notification_Order_By = {
  created_at?: InputMaybe<Order_By>;
  has_notified?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
  month?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  year?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_notification */
export type Project_Notification_Pk_Columns_Input = {
  month: Scalars['Int'];
  project_id: Scalars['uuid'];
  type: Scalars['reminder_type_enum'];
  year: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Project_Notification_Prepend_Input = {
  meta?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "project_notification" */
export enum Project_Notification_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasNotified = 'has_notified',
  /** column name */
  Meta = 'meta',
  /** column name */
  Month = 'month',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

/** input type for updating data in table "project_notification" */
export type Project_Notification_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  has_notified?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  month?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['reminder_type_enum']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Project_Notification_Stddev_Fields = {
  __typename?: 'project_notification_stddev_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Notification_Stddev_Pop_Fields = {
  __typename?: 'project_notification_stddev_pop_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Notification_Stddev_Samp_Fields = {
  __typename?: 'project_notification_stddev_samp_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_notification" */
export type Project_Notification_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Notification_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Notification_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  has_notified?: InputMaybe<Scalars['Boolean']>;
  meta?: InputMaybe<Scalars['jsonb']>;
  month?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['reminder_type_enum']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  year?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Project_Notification_Sum_Fields = {
  __typename?: 'project_notification_sum_fields';
  month?: Maybe<Scalars['Int']>;
  year?: Maybe<Scalars['Int']>;
};

/** update columns of table "project_notification" */
export enum Project_Notification_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasNotified = 'has_notified',
  /** column name */
  Meta = 'meta',
  /** column name */
  Month = 'month',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Year = 'year',
}

export type Project_Notification_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Project_Notification_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Project_Notification_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Project_Notification_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Project_Notification_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Notification_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Project_Notification_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Notification_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Notification_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Notification_Var_Pop_Fields = {
  __typename?: 'project_notification_var_pop_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Notification_Var_Samp_Fields = {
  __typename?: 'project_notification_var_samp_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Notification_Variance_Fields = {
  __typename?: 'project_notification_variance_fields';
  month?: Maybe<Scalars['Float']>;
  year?: Maybe<Scalars['Float']>;
};

/** Information about ownership transfer invitations for all projects */
export type Project_Ownership_Transfer_Invitations = {
  __typename?: 'project_ownership_transfer_invitations';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  declined_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  invited_at: Scalars['timestamptz'];
  invited_by: Scalars['uuid'];
  /** An object relationship */
  invited_by_user?: Maybe<Users>;
  /** An object relationship */
  invitee?: Maybe<Users>;
  invitee_email: Scalars['String'];
  key: Scalars['String'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  resend_invitation_count: Scalars['Int'];
};

/** aggregated selection of "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Aggregate = {
  __typename?: 'project_ownership_transfer_invitations_aggregate';
  aggregate?: Maybe<Project_Ownership_Transfer_Invitations_Aggregate_Fields>;
  nodes: Array<Project_Ownership_Transfer_Invitations>;
};

export type Project_Ownership_Transfer_Invitations_Aggregate_Bool_Exp = {
  count?: InputMaybe<
    Project_Ownership_Transfer_Invitations_Aggregate_Bool_Exp_Count
  >;
};

export type Project_Ownership_Transfer_Invitations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<
    Array<Project_Ownership_Transfer_Invitations_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Project_Ownership_Transfer_Invitations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Aggregate_Fields = {
  __typename?: 'project_ownership_transfer_invitations_aggregate_fields';
  avg?: Maybe<Project_Ownership_Transfer_Invitations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Ownership_Transfer_Invitations_Max_Fields>;
  min?: Maybe<Project_Ownership_Transfer_Invitations_Min_Fields>;
  stddev?: Maybe<Project_Ownership_Transfer_Invitations_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Ownership_Transfer_Invitations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<
    Project_Ownership_Transfer_Invitations_Stddev_Samp_Fields
  >;
  sum?: Maybe<Project_Ownership_Transfer_Invitations_Sum_Fields>;
  var_pop?: Maybe<Project_Ownership_Transfer_Invitations_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Ownership_Transfer_Invitations_Var_Samp_Fields>;
  variance?: Maybe<Project_Ownership_Transfer_Invitations_Variance_Fields>;
};

/** aggregate fields of "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Project_Ownership_Transfer_Invitations_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Aggregate_Order_By = {
  avg?: InputMaybe<Project_Ownership_Transfer_Invitations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Project_Ownership_Transfer_Invitations_Max_Order_By>;
  min?: InputMaybe<Project_Ownership_Transfer_Invitations_Min_Order_By>;
  stddev?: InputMaybe<Project_Ownership_Transfer_Invitations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<
    Project_Ownership_Transfer_Invitations_Stddev_Pop_Order_By
  >;
  stddev_samp?: InputMaybe<
    Project_Ownership_Transfer_Invitations_Stddev_Samp_Order_By
  >;
  sum?: InputMaybe<Project_Ownership_Transfer_Invitations_Sum_Order_By>;
  var_pop?: InputMaybe<Project_Ownership_Transfer_Invitations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<
    Project_Ownership_Transfer_Invitations_Var_Samp_Order_By
  >;
  variance?: InputMaybe<
    Project_Ownership_Transfer_Invitations_Variance_Order_By
  >;
};

/** input type for inserting array relation for remote table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Arr_Rel_Insert_Input = {
  data: Array<Project_Ownership_Transfer_Invitations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Project_Ownership_Transfer_Invitations_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Ownership_Transfer_Invitations_Avg_Fields = {
  __typename?: 'project_ownership_transfer_invitations_avg_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Avg_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "project_ownership_transfer_invitations". All fields are combined with a logical 'AND'. */
export type Project_Ownership_Transfer_Invitations_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Ownership_Transfer_Invitations_Bool_Exp>>;
  _not?: InputMaybe<Project_Ownership_Transfer_Invitations_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Ownership_Transfer_Invitations_Bool_Exp>>;
  accepted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  declined_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invited_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  invited_by?: InputMaybe<Uuid_Comparison_Exp>;
  invited_by_user?: InputMaybe<Users_Bool_Exp>;
  invitee?: InputMaybe<Users_Bool_Exp>;
  invitee_email?: InputMaybe<String_Comparison_Exp>;
  key?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  resend_invitation_count?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_ownership_transfer_invitations" */
export enum Project_Ownership_Transfer_Invitations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectOwnershipChangeInvitationPkey = 'project_ownership_change_invitation_pkey',
  /** unique or primary key constraint on columns "key" */
  ProjectOwnershipTransferInvitationsKeyKey = 'project_ownership_transfer_invitations_key_key',
}

/** input type for incrementing numeric columns in table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Inc_Input = {
  resend_invitation_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Insert_Input = {
  accepted_at?: InputMaybe<Scalars['timestamptz']>;
  declined_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  invited_by_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  invitee?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  invitee_email?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  resend_invitation_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Project_Ownership_Transfer_Invitations_Max_Fields = {
  __typename?: 'project_ownership_transfer_invitations_max_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  declined_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invited_at?: Maybe<Scalars['timestamptz']>;
  invited_by?: Maybe<Scalars['uuid']>;
  invitee_email?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  resend_invitation_count?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Max_Order_By = {
  accepted_at?: InputMaybe<Order_By>;
  declined_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  invitee_email?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Ownership_Transfer_Invitations_Min_Fields = {
  __typename?: 'project_ownership_transfer_invitations_min_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  declined_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invited_at?: Maybe<Scalars['timestamptz']>;
  invited_by?: Maybe<Scalars['uuid']>;
  invitee_email?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  resend_invitation_count?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Min_Order_By = {
  accepted_at?: InputMaybe<Order_By>;
  declined_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  invitee_email?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Mutation_Response = {
  __typename?: 'project_ownership_transfer_invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Ownership_Transfer_Invitations>;
};

/** on_conflict condition type for table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_On_Conflict = {
  constraint: Project_Ownership_Transfer_Invitations_Constraint;
  update_columns?: Array<Project_Ownership_Transfer_Invitations_Update_Column>;
  where?: InputMaybe<Project_Ownership_Transfer_Invitations_Bool_Exp>;
};

/** Ordering options when selecting data from "project_ownership_transfer_invitations". */
export type Project_Ownership_Transfer_Invitations_Order_By = {
  accepted_at?: InputMaybe<Order_By>;
  declined_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  invited_by_user?: InputMaybe<Users_Order_By>;
  invitee?: InputMaybe<Users_Order_By>;
  invitee_email?: InputMaybe<Order_By>;
  key?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_ownership_transfer_invitations */
export type Project_Ownership_Transfer_Invitations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_ownership_transfer_invitations" */
export enum Project_Ownership_Transfer_Invitations_Select_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  DeclinedAt = 'declined_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedAt = 'invited_at',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  InviteeEmail = 'invitee_email',
  /** column name */
  Key = 'key',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ResendInvitationCount = 'resend_invitation_count',
}

/** input type for updating data in table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Set_Input = {
  accepted_at?: InputMaybe<Scalars['timestamptz']>;
  declined_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  invitee_email?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  resend_invitation_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Project_Ownership_Transfer_Invitations_Stddev_Fields = {
  __typename?: 'project_ownership_transfer_invitations_stddev_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Stddev_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Ownership_Transfer_Invitations_Stddev_Pop_Fields = {
  __typename?: 'project_ownership_transfer_invitations_stddev_pop_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Stddev_Pop_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Ownership_Transfer_Invitations_Stddev_Samp_Fields = {
  __typename?: 'project_ownership_transfer_invitations_stddev_samp_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Stddev_Samp_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Ownership_Transfer_Invitations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Ownership_Transfer_Invitations_Stream_Cursor_Value_Input = {
  accepted_at?: InputMaybe<Scalars['timestamptz']>;
  declined_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  invitee_email?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  resend_invitation_count?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Project_Ownership_Transfer_Invitations_Sum_Fields = {
  __typename?: 'project_ownership_transfer_invitations_sum_fields';
  resend_invitation_count?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Sum_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** update columns of table "project_ownership_transfer_invitations" */
export enum Project_Ownership_Transfer_Invitations_Update_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  DeclinedAt = 'declined_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedAt = 'invited_at',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  InviteeEmail = 'invitee_email',
  /** column name */
  Key = 'key',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  ResendInvitationCount = 'resend_invitation_count',
}

export type Project_Ownership_Transfer_Invitations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Ownership_Transfer_Invitations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Ownership_Transfer_Invitations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Ownership_Transfer_Invitations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Ownership_Transfer_Invitations_Var_Pop_Fields = {
  __typename?: 'project_ownership_transfer_invitations_var_pop_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Var_Pop_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Ownership_Transfer_Invitations_Var_Samp_Fields = {
  __typename?: 'project_ownership_transfer_invitations_var_samp_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Var_Samp_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Ownership_Transfer_Invitations_Variance_Fields = {
  __typename?: 'project_ownership_transfer_invitations_variance_fields';
  resend_invitation_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "project_ownership_transfer_invitations" */
export type Project_Ownership_Transfer_Invitations_Variance_Order_By = {
  resend_invitation_count?: InputMaybe<Order_By>;
};

/** columns and relationships of "project_pool_current_status" */
export type Project_Pool_Current_Status = {
  __typename?: 'project_pool_current_status';
  cloud?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  plan_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

/** aggregated selection of "project_pool_current_status" */
export type Project_Pool_Current_Status_Aggregate = {
  __typename?: 'project_pool_current_status_aggregate';
  aggregate?: Maybe<Project_Pool_Current_Status_Aggregate_Fields>;
  nodes: Array<Project_Pool_Current_Status>;
};

/** aggregate fields of "project_pool_current_status" */
export type Project_Pool_Current_Status_Aggregate_Fields = {
  __typename?: 'project_pool_current_status_aggregate_fields';
  avg?: Maybe<Project_Pool_Current_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Pool_Current_Status_Max_Fields>;
  min?: Maybe<Project_Pool_Current_Status_Min_Fields>;
  stddev?: Maybe<Project_Pool_Current_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Pool_Current_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Pool_Current_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Pool_Current_Status_Sum_Fields>;
  var_pop?: Maybe<Project_Pool_Current_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Pool_Current_Status_Var_Samp_Fields>;
  variance?: Maybe<Project_Pool_Current_Status_Variance_Fields>;
};

/** aggregate fields of "project_pool_current_status" */
export type Project_Pool_Current_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Pool_Current_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Pool_Current_Status_Avg_Fields = {
  __typename?: 'project_pool_current_status_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_pool_current_status". All fields are combined with a logical 'AND'. */
export type Project_Pool_Current_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Pool_Current_Status_Bool_Exp>>;
  _not?: InputMaybe<Project_Pool_Current_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Pool_Current_Status_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  plan_name?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "project_pool_current_status" */
export type Project_Pool_Current_Status_Insert_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['bigint']>;
  plan_name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Project_Pool_Current_Status_Max_Fields = {
  __typename?: 'project_pool_current_status_max_fields';
  cloud?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  plan_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Project_Pool_Current_Status_Min_Fields = {
  __typename?: 'project_pool_current_status_min_fields';
  cloud?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['bigint']>;
  plan_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

/** input type for inserting object relation for remote table "project_pool_current_status" */
export type Project_Pool_Current_Status_Obj_Rel_Insert_Input = {
  data: Project_Pool_Current_Status_Insert_Input;
};

/** Ordering options when selecting data from "project_pool_current_status". */
export type Project_Pool_Current_Status_Order_By = {
  cloud?: InputMaybe<Order_By>;
  count?: InputMaybe<Order_By>;
  plan_name?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
};

/** select columns of table "project_pool_current_status" */
export enum Project_Pool_Current_Status_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  Count = 'count',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  Region = 'region',
}

/** aggregate stddev on columns */
export type Project_Pool_Current_Status_Stddev_Fields = {
  __typename?: 'project_pool_current_status_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Pool_Current_Status_Stddev_Pop_Fields = {
  __typename?: 'project_pool_current_status_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Pool_Current_Status_Stddev_Samp_Fields = {
  __typename?: 'project_pool_current_status_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_pool_current_status" */
export type Project_Pool_Current_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Pool_Current_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Pool_Current_Status_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  count?: InputMaybe<Scalars['bigint']>;
  plan_name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Project_Pool_Current_Status_Sum_Fields = {
  __typename?: 'project_pool_current_status_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Project_Pool_Current_Status_Var_Pop_Fields = {
  __typename?: 'project_pool_current_status_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Pool_Current_Status_Var_Samp_Fields = {
  __typename?: 'project_pool_current_status_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Pool_Current_Status_Variance_Fields = {
  __typename?: 'project_pool_current_status_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** table to help store all relevant request count metric data from prometheus for all projects */
export type Project_Requests_Count = {
  __typename?: 'project_requests_count';
  created_at: Scalars['date'];
  hasura_graphql_requests_total: Scalars['bigint'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "project_requests_count" */
export type Project_Requests_Count_Aggregate = {
  __typename?: 'project_requests_count_aggregate';
  aggregate?: Maybe<Project_Requests_Count_Aggregate_Fields>;
  nodes: Array<Project_Requests_Count>;
};

/** aggregate fields of "project_requests_count" */
export type Project_Requests_Count_Aggregate_Fields = {
  __typename?: 'project_requests_count_aggregate_fields';
  avg?: Maybe<Project_Requests_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Requests_Count_Max_Fields>;
  min?: Maybe<Project_Requests_Count_Min_Fields>;
  stddev?: Maybe<Project_Requests_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Requests_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Requests_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Requests_Count_Sum_Fields>;
  var_pop?: Maybe<Project_Requests_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Requests_Count_Var_Samp_Fields>;
  variance?: Maybe<Project_Requests_Count_Variance_Fields>;
};

/** aggregate fields of "project_requests_count" */
export type Project_Requests_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Requests_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Requests_Count_Avg_Fields = {
  __typename?: 'project_requests_count_avg_fields';
  hasura_graphql_requests_total?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_requests_count". All fields are combined with a logical 'AND'. */
export type Project_Requests_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Requests_Count_Bool_Exp>>;
  _not?: InputMaybe<Project_Requests_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Requests_Count_Bool_Exp>>;
  created_at?: InputMaybe<Date_Comparison_Exp>;
  hasura_graphql_requests_total?: InputMaybe<Bigint_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_requests_count" */
export enum Project_Requests_Count_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectRequestsCountPkey = 'project_requests_count_pkey',
  /** unique or primary key constraint on columns "project_id", "created_at" */
  ProjectRequestsCountProjectIdCreatedAtKey = 'project_requests_count_project_id_created_at_key',
}

/** input type for incrementing numeric columns in table "project_requests_count" */
export type Project_Requests_Count_Inc_Input = {
  hasura_graphql_requests_total?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "project_requests_count" */
export type Project_Requests_Count_Insert_Input = {
  created_at?: InputMaybe<Scalars['date']>;
  hasura_graphql_requests_total?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Project_Requests_Count_Max_Fields = {
  __typename?: 'project_requests_count_max_fields';
  created_at?: Maybe<Scalars['date']>;
  hasura_graphql_requests_total?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Project_Requests_Count_Min_Fields = {
  __typename?: 'project_requests_count_min_fields';
  created_at?: Maybe<Scalars['date']>;
  hasura_graphql_requests_total?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "project_requests_count" */
export type Project_Requests_Count_Mutation_Response = {
  __typename?: 'project_requests_count_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Requests_Count>;
};

/** on_conflict condition type for table "project_requests_count" */
export type Project_Requests_Count_On_Conflict = {
  constraint: Project_Requests_Count_Constraint;
  update_columns?: Array<Project_Requests_Count_Update_Column>;
  where?: InputMaybe<Project_Requests_Count_Bool_Exp>;
};

/** Ordering options when selecting data from "project_requests_count". */
export type Project_Requests_Count_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hasura_graphql_requests_total?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_requests_count */
export type Project_Requests_Count_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_requests_count" */
export enum Project_Requests_Count_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasuraGraphqlRequestsTotal = 'hasura_graphql_requests_total',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "project_requests_count" */
export type Project_Requests_Count_Set_Input = {
  created_at?: InputMaybe<Scalars['date']>;
  hasura_graphql_requests_total?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Project_Requests_Count_Stddev_Fields = {
  __typename?: 'project_requests_count_stddev_fields';
  hasura_graphql_requests_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Requests_Count_Stddev_Pop_Fields = {
  __typename?: 'project_requests_count_stddev_pop_fields';
  hasura_graphql_requests_total?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Requests_Count_Stddev_Samp_Fields = {
  __typename?: 'project_requests_count_stddev_samp_fields';
  hasura_graphql_requests_total?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_requests_count" */
export type Project_Requests_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Requests_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Requests_Count_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['date']>;
  hasura_graphql_requests_total?: InputMaybe<Scalars['bigint']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Project_Requests_Count_Sum_Fields = {
  __typename?: 'project_requests_count_sum_fields';
  hasura_graphql_requests_total?: Maybe<Scalars['bigint']>;
};

/** update columns of table "project_requests_count" */
export enum Project_Requests_Count_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasuraGraphqlRequestsTotal = 'hasura_graphql_requests_total',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Project_Requests_Count_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Requests_Count_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Requests_Count_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Requests_Count_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Requests_Count_Var_Pop_Fields = {
  __typename?: 'project_requests_count_var_pop_fields';
  hasura_graphql_requests_total?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Requests_Count_Var_Samp_Fields = {
  __typename?: 'project_requests_count_var_samp_fields';
  hasura_graphql_requests_total?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Requests_Count_Variance_Fields = {
  __typename?: 'project_requests_count_variance_fields';
  hasura_graphql_requests_total?: Maybe<Scalars['Float']>;
};

/** Project statistics used to send to metabase */
export type Project_Stats = {
  __typename?: 'project_stats';
  cache_size_usage: Scalars['bigint'];
  date: Scalars['date'];
  has_cached_query: Scalars['Boolean'];
  has_mutation: Scalars['Boolean'];
  has_query: Scalars['Boolean'];
  has_subscription: Scalars['Boolean'];
  id: Scalars['uuid'];
  last_console_access?: Maybe<Scalars['timestamptz']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  num_allow_list_operations: Scalars['Int'];
  num_collaborators: Scalars['Int'];
  num_login_sessions: Scalars['Int'];
  num_rate_limits: Scalars['Int'];
  num_read_replicas: Scalars['Int'];
  num_test_runs: Scalars['Int'];
  num_test_suite_operations: Scalars['Int'];
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id: Scalars['uuid'];
  rate_limit_depths: Scalars['Int'];
  total_metadata_requests: Scalars['bigint'];
  total_requests: Scalars['bigint'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "project_stats" */
export type Project_Stats_Aggregate = {
  __typename?: 'project_stats_aggregate';
  aggregate?: Maybe<Project_Stats_Aggregate_Fields>;
  nodes: Array<Project_Stats>;
};

/** aggregate fields of "project_stats" */
export type Project_Stats_Aggregate_Fields = {
  __typename?: 'project_stats_aggregate_fields';
  avg?: Maybe<Project_Stats_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Stats_Max_Fields>;
  min?: Maybe<Project_Stats_Min_Fields>;
  stddev?: Maybe<Project_Stats_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Stats_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Stats_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Stats_Sum_Fields>;
  var_pop?: Maybe<Project_Stats_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Stats_Var_Samp_Fields>;
  variance?: Maybe<Project_Stats_Variance_Fields>;
};

/** aggregate fields of "project_stats" */
export type Project_Stats_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Stats_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Stats_Avg_Fields = {
  __typename?: 'project_stats_avg_fields';
  cache_size_usage?: Maybe<Scalars['Float']>;
  num_allow_list_operations?: Maybe<Scalars['Float']>;
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_rate_limits?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
  num_test_runs?: Maybe<Scalars['Float']>;
  num_test_suite_operations?: Maybe<Scalars['Float']>;
  rate_limit_depths?: Maybe<Scalars['Float']>;
  total_metadata_requests?: Maybe<Scalars['Float']>;
  total_requests?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_stats". All fields are combined with a logical 'AND'. */
export type Project_Stats_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Stats_Bool_Exp>>;
  _not?: InputMaybe<Project_Stats_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Stats_Bool_Exp>>;
  cache_size_usage?: InputMaybe<Bigint_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  has_cached_query?: InputMaybe<Boolean_Comparison_Exp>;
  has_mutation?: InputMaybe<Boolean_Comparison_Exp>;
  has_query?: InputMaybe<Boolean_Comparison_Exp>;
  has_subscription?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  last_console_access?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_login?: InputMaybe<Timestamptz_Comparison_Exp>;
  num_allow_list_operations?: InputMaybe<Int_Comparison_Exp>;
  num_collaborators?: InputMaybe<Int_Comparison_Exp>;
  num_login_sessions?: InputMaybe<Int_Comparison_Exp>;
  num_rate_limits?: InputMaybe<Int_Comparison_Exp>;
  num_read_replicas?: InputMaybe<Int_Comparison_Exp>;
  num_test_runs?: InputMaybe<Int_Comparison_Exp>;
  num_test_suite_operations?: InputMaybe<Int_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  rate_limit_depths?: InputMaybe<Int_Comparison_Exp>;
  total_metadata_requests?: InputMaybe<Bigint_Comparison_Exp>;
  total_requests?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "project_stats" */
export enum Project_Stats_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectStatsPkey = 'project_stats_pkey',
  /** unique or primary key constraint on columns "project_id", "date" */
  ProjectStatsProjectIdDateKey = 'project_stats_project_id_date_key',
}

/** input type for incrementing numeric columns in table "project_stats" */
export type Project_Stats_Inc_Input = {
  cache_size_usage?: InputMaybe<Scalars['bigint']>;
  num_allow_list_operations?: InputMaybe<Scalars['Int']>;
  num_collaborators?: InputMaybe<Scalars['Int']>;
  num_login_sessions?: InputMaybe<Scalars['Int']>;
  num_rate_limits?: InputMaybe<Scalars['Int']>;
  num_read_replicas?: InputMaybe<Scalars['Int']>;
  num_test_runs?: InputMaybe<Scalars['Int']>;
  num_test_suite_operations?: InputMaybe<Scalars['Int']>;
  rate_limit_depths?: InputMaybe<Scalars['Int']>;
  total_metadata_requests?: InputMaybe<Scalars['bigint']>;
  total_requests?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "project_stats" */
export type Project_Stats_Insert_Input = {
  cache_size_usage?: InputMaybe<Scalars['bigint']>;
  date?: InputMaybe<Scalars['date']>;
  has_cached_query?: InputMaybe<Scalars['Boolean']>;
  has_mutation?: InputMaybe<Scalars['Boolean']>;
  has_query?: InputMaybe<Scalars['Boolean']>;
  has_subscription?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_console_access?: InputMaybe<Scalars['timestamptz']>;
  last_login?: InputMaybe<Scalars['timestamptz']>;
  num_allow_list_operations?: InputMaybe<Scalars['Int']>;
  num_collaborators?: InputMaybe<Scalars['Int']>;
  num_login_sessions?: InputMaybe<Scalars['Int']>;
  num_rate_limits?: InputMaybe<Scalars['Int']>;
  num_read_replicas?: InputMaybe<Scalars['Int']>;
  num_test_runs?: InputMaybe<Scalars['Int']>;
  num_test_suite_operations?: InputMaybe<Scalars['Int']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rate_limit_depths?: InputMaybe<Scalars['Int']>;
  total_metadata_requests?: InputMaybe<Scalars['bigint']>;
  total_requests?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Project_Stats_Max_Fields = {
  __typename?: 'project_stats_max_fields';
  cache_size_usage?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  last_console_access?: Maybe<Scalars['timestamptz']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  num_allow_list_operations?: Maybe<Scalars['Int']>;
  num_collaborators?: Maybe<Scalars['Int']>;
  num_login_sessions?: Maybe<Scalars['Int']>;
  num_rate_limits?: Maybe<Scalars['Int']>;
  num_read_replicas?: Maybe<Scalars['Int']>;
  num_test_runs?: Maybe<Scalars['Int']>;
  num_test_suite_operations?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  rate_limit_depths?: Maybe<Scalars['Int']>;
  total_metadata_requests?: Maybe<Scalars['bigint']>;
  total_requests?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Project_Stats_Min_Fields = {
  __typename?: 'project_stats_min_fields';
  cache_size_usage?: Maybe<Scalars['bigint']>;
  date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  last_console_access?: Maybe<Scalars['timestamptz']>;
  last_login?: Maybe<Scalars['timestamptz']>;
  num_allow_list_operations?: Maybe<Scalars['Int']>;
  num_collaborators?: Maybe<Scalars['Int']>;
  num_login_sessions?: Maybe<Scalars['Int']>;
  num_rate_limits?: Maybe<Scalars['Int']>;
  num_read_replicas?: Maybe<Scalars['Int']>;
  num_test_runs?: Maybe<Scalars['Int']>;
  num_test_suite_operations?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  rate_limit_depths?: Maybe<Scalars['Int']>;
  total_metadata_requests?: Maybe<Scalars['bigint']>;
  total_requests?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "project_stats" */
export type Project_Stats_Mutation_Response = {
  __typename?: 'project_stats_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Stats>;
};

/** on_conflict condition type for table "project_stats" */
export type Project_Stats_On_Conflict = {
  constraint: Project_Stats_Constraint;
  update_columns?: Array<Project_Stats_Update_Column>;
  where?: InputMaybe<Project_Stats_Bool_Exp>;
};

/** Ordering options when selecting data from "project_stats". */
export type Project_Stats_Order_By = {
  cache_size_usage?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  has_cached_query?: InputMaybe<Order_By>;
  has_mutation?: InputMaybe<Order_By>;
  has_query?: InputMaybe<Order_By>;
  has_subscription?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_console_access?: InputMaybe<Order_By>;
  last_login?: InputMaybe<Order_By>;
  num_allow_list_operations?: InputMaybe<Order_By>;
  num_collaborators?: InputMaybe<Order_By>;
  num_login_sessions?: InputMaybe<Order_By>;
  num_rate_limits?: InputMaybe<Order_By>;
  num_read_replicas?: InputMaybe<Order_By>;
  num_test_runs?: InputMaybe<Order_By>;
  num_test_suite_operations?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  rate_limit_depths?: InputMaybe<Order_By>;
  total_metadata_requests?: InputMaybe<Order_By>;
  total_requests?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: project_stats */
export type Project_Stats_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "project_stats" */
export enum Project_Stats_Select_Column {
  /** column name */
  CacheSizeUsage = 'cache_size_usage',
  /** column name */
  Date = 'date',
  /** column name */
  HasCachedQuery = 'has_cached_query',
  /** column name */
  HasMutation = 'has_mutation',
  /** column name */
  HasQuery = 'has_query',
  /** column name */
  HasSubscription = 'has_subscription',
  /** column name */
  Id = 'id',
  /** column name */
  LastConsoleAccess = 'last_console_access',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  NumAllowListOperations = 'num_allow_list_operations',
  /** column name */
  NumCollaborators = 'num_collaborators',
  /** column name */
  NumLoginSessions = 'num_login_sessions',
  /** column name */
  NumRateLimits = 'num_rate_limits',
  /** column name */
  NumReadReplicas = 'num_read_replicas',
  /** column name */
  NumTestRuns = 'num_test_runs',
  /** column name */
  NumTestSuiteOperations = 'num_test_suite_operations',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RateLimitDepths = 'rate_limit_depths',
  /** column name */
  TotalMetadataRequests = 'total_metadata_requests',
  /** column name */
  TotalRequests = 'total_requests',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "project_stats" */
export type Project_Stats_Set_Input = {
  cache_size_usage?: InputMaybe<Scalars['bigint']>;
  date?: InputMaybe<Scalars['date']>;
  has_cached_query?: InputMaybe<Scalars['Boolean']>;
  has_mutation?: InputMaybe<Scalars['Boolean']>;
  has_query?: InputMaybe<Scalars['Boolean']>;
  has_subscription?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_console_access?: InputMaybe<Scalars['timestamptz']>;
  last_login?: InputMaybe<Scalars['timestamptz']>;
  num_allow_list_operations?: InputMaybe<Scalars['Int']>;
  num_collaborators?: InputMaybe<Scalars['Int']>;
  num_login_sessions?: InputMaybe<Scalars['Int']>;
  num_rate_limits?: InputMaybe<Scalars['Int']>;
  num_read_replicas?: InputMaybe<Scalars['Int']>;
  num_test_runs?: InputMaybe<Scalars['Int']>;
  num_test_suite_operations?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rate_limit_depths?: InputMaybe<Scalars['Int']>;
  total_metadata_requests?: InputMaybe<Scalars['bigint']>;
  total_requests?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Project_Stats_Stddev_Fields = {
  __typename?: 'project_stats_stddev_fields';
  cache_size_usage?: Maybe<Scalars['Float']>;
  num_allow_list_operations?: Maybe<Scalars['Float']>;
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_rate_limits?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
  num_test_runs?: Maybe<Scalars['Float']>;
  num_test_suite_operations?: Maybe<Scalars['Float']>;
  rate_limit_depths?: Maybe<Scalars['Float']>;
  total_metadata_requests?: Maybe<Scalars['Float']>;
  total_requests?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Stats_Stddev_Pop_Fields = {
  __typename?: 'project_stats_stddev_pop_fields';
  cache_size_usage?: Maybe<Scalars['Float']>;
  num_allow_list_operations?: Maybe<Scalars['Float']>;
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_rate_limits?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
  num_test_runs?: Maybe<Scalars['Float']>;
  num_test_suite_operations?: Maybe<Scalars['Float']>;
  rate_limit_depths?: Maybe<Scalars['Float']>;
  total_metadata_requests?: Maybe<Scalars['Float']>;
  total_requests?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Stats_Stddev_Samp_Fields = {
  __typename?: 'project_stats_stddev_samp_fields';
  cache_size_usage?: Maybe<Scalars['Float']>;
  num_allow_list_operations?: Maybe<Scalars['Float']>;
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_rate_limits?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
  num_test_runs?: Maybe<Scalars['Float']>;
  num_test_suite_operations?: Maybe<Scalars['Float']>;
  rate_limit_depths?: Maybe<Scalars['Float']>;
  total_metadata_requests?: Maybe<Scalars['Float']>;
  total_requests?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_stats" */
export type Project_Stats_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Stats_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Stats_Stream_Cursor_Value_Input = {
  cache_size_usage?: InputMaybe<Scalars['bigint']>;
  date?: InputMaybe<Scalars['date']>;
  has_cached_query?: InputMaybe<Scalars['Boolean']>;
  has_mutation?: InputMaybe<Scalars['Boolean']>;
  has_query?: InputMaybe<Scalars['Boolean']>;
  has_subscription?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['uuid']>;
  last_console_access?: InputMaybe<Scalars['timestamptz']>;
  last_login?: InputMaybe<Scalars['timestamptz']>;
  num_allow_list_operations?: InputMaybe<Scalars['Int']>;
  num_collaborators?: InputMaybe<Scalars['Int']>;
  num_login_sessions?: InputMaybe<Scalars['Int']>;
  num_rate_limits?: InputMaybe<Scalars['Int']>;
  num_read_replicas?: InputMaybe<Scalars['Int']>;
  num_test_runs?: InputMaybe<Scalars['Int']>;
  num_test_suite_operations?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  rate_limit_depths?: InputMaybe<Scalars['Int']>;
  total_metadata_requests?: InputMaybe<Scalars['bigint']>;
  total_requests?: InputMaybe<Scalars['bigint']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Project_Stats_Sum_Fields = {
  __typename?: 'project_stats_sum_fields';
  cache_size_usage?: Maybe<Scalars['bigint']>;
  num_allow_list_operations?: Maybe<Scalars['Int']>;
  num_collaborators?: Maybe<Scalars['Int']>;
  num_login_sessions?: Maybe<Scalars['Int']>;
  num_rate_limits?: Maybe<Scalars['Int']>;
  num_read_replicas?: Maybe<Scalars['Int']>;
  num_test_runs?: Maybe<Scalars['Int']>;
  num_test_suite_operations?: Maybe<Scalars['Int']>;
  rate_limit_depths?: Maybe<Scalars['Int']>;
  total_metadata_requests?: Maybe<Scalars['bigint']>;
  total_requests?: Maybe<Scalars['bigint']>;
};

/** update columns of table "project_stats" */
export enum Project_Stats_Update_Column {
  /** column name */
  CacheSizeUsage = 'cache_size_usage',
  /** column name */
  Date = 'date',
  /** column name */
  HasCachedQuery = 'has_cached_query',
  /** column name */
  HasMutation = 'has_mutation',
  /** column name */
  HasQuery = 'has_query',
  /** column name */
  HasSubscription = 'has_subscription',
  /** column name */
  Id = 'id',
  /** column name */
  LastConsoleAccess = 'last_console_access',
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  NumAllowListOperations = 'num_allow_list_operations',
  /** column name */
  NumCollaborators = 'num_collaborators',
  /** column name */
  NumLoginSessions = 'num_login_sessions',
  /** column name */
  NumRateLimits = 'num_rate_limits',
  /** column name */
  NumReadReplicas = 'num_read_replicas',
  /** column name */
  NumTestRuns = 'num_test_runs',
  /** column name */
  NumTestSuiteOperations = 'num_test_suite_operations',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RateLimitDepths = 'rate_limit_depths',
  /** column name */
  TotalMetadataRequests = 'total_metadata_requests',
  /** column name */
  TotalRequests = 'total_requests',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Project_Stats_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Stats_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Stats_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Stats_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Stats_Var_Pop_Fields = {
  __typename?: 'project_stats_var_pop_fields';
  cache_size_usage?: Maybe<Scalars['Float']>;
  num_allow_list_operations?: Maybe<Scalars['Float']>;
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_rate_limits?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
  num_test_runs?: Maybe<Scalars['Float']>;
  num_test_suite_operations?: Maybe<Scalars['Float']>;
  rate_limit_depths?: Maybe<Scalars['Float']>;
  total_metadata_requests?: Maybe<Scalars['Float']>;
  total_requests?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Stats_Var_Samp_Fields = {
  __typename?: 'project_stats_var_samp_fields';
  cache_size_usage?: Maybe<Scalars['Float']>;
  num_allow_list_operations?: Maybe<Scalars['Float']>;
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_rate_limits?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
  num_test_runs?: Maybe<Scalars['Float']>;
  num_test_suite_operations?: Maybe<Scalars['Float']>;
  rate_limit_depths?: Maybe<Scalars['Float']>;
  total_metadata_requests?: Maybe<Scalars['Float']>;
  total_requests?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Stats_Variance_Fields = {
  __typename?: 'project_stats_variance_fields';
  cache_size_usage?: Maybe<Scalars['Float']>;
  num_allow_list_operations?: Maybe<Scalars['Float']>;
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_rate_limits?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
  num_test_runs?: Maybe<Scalars['Float']>;
  num_test_suite_operations?: Maybe<Scalars['Float']>;
  rate_limit_depths?: Maybe<Scalars['Float']>;
  total_metadata_requests?: Maybe<Scalars['Float']>;
  total_requests?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "project_total_db_usage_agg" */
export type Project_Total_Db_Usage_Agg = {
  __typename?: 'project_total_db_usage_agg';
  project_id?: Maybe<Scalars['uuid']>;
  total_no_db_usage?: Maybe<Scalars['Int']>;
  total_non_pg_usage?: Maybe<Scalars['Int']>;
  total_pg_usage?: Maybe<Scalars['Int']>;
  total_price?: Maybe<Scalars['bigint']>;
};

export type Project_Total_Db_Usage_Agg_Aggregate = {
  __typename?: 'project_total_db_usage_agg_aggregate';
  aggregate?: Maybe<Project_Total_Db_Usage_Agg_Aggregate_Fields>;
  nodes: Array<Project_Total_Db_Usage_Agg>;
};

/** aggregate fields of "project_total_db_usage_agg" */
export type Project_Total_Db_Usage_Agg_Aggregate_Fields = {
  __typename?: 'project_total_db_usage_agg_aggregate_fields';
  avg?: Maybe<Project_Total_Db_Usage_Agg_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Project_Total_Db_Usage_Agg_Max_Fields>;
  min?: Maybe<Project_Total_Db_Usage_Agg_Min_Fields>;
  stddev?: Maybe<Project_Total_Db_Usage_Agg_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Total_Db_Usage_Agg_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Total_Db_Usage_Agg_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Total_Db_Usage_Agg_Sum_Fields>;
  var_pop?: Maybe<Project_Total_Db_Usage_Agg_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Total_Db_Usage_Agg_Var_Samp_Fields>;
  variance?: Maybe<Project_Total_Db_Usage_Agg_Variance_Fields>;
};

/** aggregate fields of "project_total_db_usage_agg" */
export type Project_Total_Db_Usage_Agg_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Project_Total_Db_Usage_Agg_Avg_Fields = {
  __typename?: 'project_total_db_usage_agg_avg_fields';
  total_no_db_usage?: Maybe<Scalars['Float']>;
  total_non_pg_usage?: Maybe<Scalars['Float']>;
  total_pg_usage?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "project_total_db_usage_agg". All fields are combined with a logical 'AND'. */
export type Project_Total_Db_Usage_Agg_Bool_Exp = {
  _and?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Bool_Exp>>;
  _not?: InputMaybe<Project_Total_Db_Usage_Agg_Bool_Exp>;
  _or?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Bool_Exp>>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  total_no_db_usage?: InputMaybe<Int_Comparison_Exp>;
  total_non_pg_usage?: InputMaybe<Int_Comparison_Exp>;
  total_pg_usage?: InputMaybe<Int_Comparison_Exp>;
  total_price?: InputMaybe<Bigint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "project_total_db_usage_agg" */
export type Project_Total_Db_Usage_Agg_Inc_Input = {
  total_no_db_usage?: InputMaybe<Scalars['Int']>;
  total_non_pg_usage?: InputMaybe<Scalars['Int']>;
  total_pg_usage?: InputMaybe<Scalars['Int']>;
  total_price?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "project_total_db_usage_agg" */
export type Project_Total_Db_Usage_Agg_Insert_Input = {
  project_id?: InputMaybe<Scalars['uuid']>;
  total_no_db_usage?: InputMaybe<Scalars['Int']>;
  total_non_pg_usage?: InputMaybe<Scalars['Int']>;
  total_pg_usage?: InputMaybe<Scalars['Int']>;
  total_price?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Project_Total_Db_Usage_Agg_Max_Fields = {
  __typename?: 'project_total_db_usage_agg_max_fields';
  project_id?: Maybe<Scalars['uuid']>;
  total_no_db_usage?: Maybe<Scalars['Int']>;
  total_non_pg_usage?: Maybe<Scalars['Int']>;
  total_pg_usage?: Maybe<Scalars['Int']>;
  total_price?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Project_Total_Db_Usage_Agg_Min_Fields = {
  __typename?: 'project_total_db_usage_agg_min_fields';
  project_id?: Maybe<Scalars['uuid']>;
  total_no_db_usage?: Maybe<Scalars['Int']>;
  total_non_pg_usage?: Maybe<Scalars['Int']>;
  total_pg_usage?: Maybe<Scalars['Int']>;
  total_price?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "project_total_db_usage_agg" */
export type Project_Total_Db_Usage_Agg_Mutation_Response = {
  __typename?: 'project_total_db_usage_agg_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Project_Total_Db_Usage_Agg>;
};

/** Ordering options when selecting data from "project_total_db_usage_agg". */
export type Project_Total_Db_Usage_Agg_Order_By = {
  project_id?: InputMaybe<Order_By>;
  total_no_db_usage?: InputMaybe<Order_By>;
  total_non_pg_usage?: InputMaybe<Order_By>;
  total_pg_usage?: InputMaybe<Order_By>;
  total_price?: InputMaybe<Order_By>;
};

/** select columns of table "project_total_db_usage_agg" */
export enum Project_Total_Db_Usage_Agg_Select_Column {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TotalNoDbUsage = 'total_no_db_usage',
  /** column name */
  TotalNonPgUsage = 'total_non_pg_usage',
  /** column name */
  TotalPgUsage = 'total_pg_usage',
  /** column name */
  TotalPrice = 'total_price',
}

/** input type for updating data in table "project_total_db_usage_agg" */
export type Project_Total_Db_Usage_Agg_Set_Input = {
  project_id?: InputMaybe<Scalars['uuid']>;
  total_no_db_usage?: InputMaybe<Scalars['Int']>;
  total_non_pg_usage?: InputMaybe<Scalars['Int']>;
  total_pg_usage?: InputMaybe<Scalars['Int']>;
  total_price?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Project_Total_Db_Usage_Agg_Stddev_Fields = {
  __typename?: 'project_total_db_usage_agg_stddev_fields';
  total_no_db_usage?: Maybe<Scalars['Float']>;
  total_non_pg_usage?: Maybe<Scalars['Float']>;
  total_pg_usage?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Project_Total_Db_Usage_Agg_Stddev_Pop_Fields = {
  __typename?: 'project_total_db_usage_agg_stddev_pop_fields';
  total_no_db_usage?: Maybe<Scalars['Float']>;
  total_non_pg_usage?: Maybe<Scalars['Float']>;
  total_pg_usage?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Project_Total_Db_Usage_Agg_Stddev_Samp_Fields = {
  __typename?: 'project_total_db_usage_agg_stddev_samp_fields';
  total_no_db_usage?: Maybe<Scalars['Float']>;
  total_non_pg_usage?: Maybe<Scalars['Float']>;
  total_pg_usage?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "project_total_db_usage_agg" */
export type Project_Total_Db_Usage_Agg_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Project_Total_Db_Usage_Agg_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Project_Total_Db_Usage_Agg_Stream_Cursor_Value_Input = {
  project_id?: InputMaybe<Scalars['uuid']>;
  total_no_db_usage?: InputMaybe<Scalars['Int']>;
  total_non_pg_usage?: InputMaybe<Scalars['Int']>;
  total_pg_usage?: InputMaybe<Scalars['Int']>;
  total_price?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Project_Total_Db_Usage_Agg_Sum_Fields = {
  __typename?: 'project_total_db_usage_agg_sum_fields';
  total_no_db_usage?: Maybe<Scalars['Int']>;
  total_non_pg_usage?: Maybe<Scalars['Int']>;
  total_pg_usage?: Maybe<Scalars['Int']>;
  total_price?: Maybe<Scalars['bigint']>;
};

export type Project_Total_Db_Usage_Agg_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Project_Total_Db_Usage_Agg_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Project_Total_Db_Usage_Agg_Set_Input>;
  /** filter the rows which have to be updated */
  where: Project_Total_Db_Usage_Agg_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Project_Total_Db_Usage_Agg_Var_Pop_Fields = {
  __typename?: 'project_total_db_usage_agg_var_pop_fields';
  total_no_db_usage?: Maybe<Scalars['Float']>;
  total_non_pg_usage?: Maybe<Scalars['Float']>;
  total_pg_usage?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Project_Total_Db_Usage_Agg_Var_Samp_Fields = {
  __typename?: 'project_total_db_usage_agg_var_samp_fields';
  total_no_db_usage?: Maybe<Scalars['Float']>;
  total_non_pg_usage?: Maybe<Scalars['Float']>;
  total_pg_usage?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Project_Total_Db_Usage_Agg_Variance_Fields = {
  __typename?: 'project_total_db_usage_agg_variance_fields';
  total_no_db_usage?: Maybe<Scalars['Float']>;
  total_non_pg_usage?: Maybe<Scalars['Float']>;
  total_pg_usage?: Maybe<Scalars['Float']>;
  total_price?: Maybe<Scalars['Float']>;
};

/** Hasura projects */
export type Projects = {
  __typename?: 'projects';
  /** An array relationship */
  activities: Array<Project_Activity>;
  /** An aggregate relationship */
  activities_aggregate: Project_Activity_Aggregate;
  /** An object relationship */
  alert_config?: Maybe<Alert_Config>;
  assigned_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  azuremonitor_config?: Maybe<Azuremonitor_Config>;
  /** An object relationship */
  billing_manager?: Maybe<Users_Public>;
  billing_manager_id: Scalars['uuid'];
  /** billing_plan_name reflects whether this project is payable or not. This is a derived columm from the last plan_change activity in the project_activity table corresponding to this project. This is different from the plan_name column because it also takes into consideration the active/deactive state of the project. */
  billing_plan_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  collaborators: Array<Project_Collaborators>;
  /** An aggregate relationship */
  collaborators_aggregate: Project_Collaborators_Aggregate;
  console_oauth_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** This computed field returns the data usage for the project in the current month, computed from Prometheus. */
  current_month_data_usage?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  datadog_config?: Maybe<Datadog_Config>;
  endpoint: Scalars['String'];
  /** An object relationship */
  enterprise_users?: Maybe<Enterprise_Users>;
  /** An array relationship */
  entitlements: Array<Project_Entitlement_Access>;
  /** An aggregate relationship */
  entitlements_aggregate: Project_Entitlement_Access_Aggregate;
  /** An array relationship */
  heroku_integrations: Array<Heroku_Integrations>;
  /** An aggregate relationship */
  heroku_integrations_aggregate: Heroku_Integrations_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  invitations: Array<Project_Collaborator_Invitations>;
  /** An aggregate relationship */
  invitations_aggregate: Project_Collaborator_Invitations_Aggregate;
  /** invoice attempt count if exists */
  invoice_attempt_count?: Maybe<Scalars['Int']>;
  /** latest invoice payement status if exists */
  invoice_status?: Maybe<Scalars['String']>;
  /** An array relationship */
  jobs: Array<Jobs>;
  /** An aggregate relationship */
  jobs_aggregate: Jobs_Aggregate;
  /** An array relationship */
  labels: Array<Project_Labels>;
  /** An aggregate relationship */
  labels_aggregate: Project_Labels_Aggregate;
  /** An object relationship */
  metadata?: Maybe<Project_Metadata>;
  name: Scalars['String'];
  /** An object relationship */
  newrelic_config?: Maybe<Newrelic_Config>;
  /** A computed field, executes function "get_requests_per_month_for_project" */
  number_of_requests_this_month?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  opentelemetry_config?: Maybe<Opentelemetry_Config>;
  /** An object relationship */
  owner?: Maybe<Users_Public>;
  /** An object relationship */
  owner_entitlements?: Maybe<User_Entitlement_Access>;
  owner_id: Scalars['uuid'];
  /** An array relationship */
  ownership_transfer_invites: Array<Project_Ownership_Transfer_Invitations>;
  /** An aggregate relationship */
  ownership_transfer_invites_aggregate: Project_Ownership_Transfer_Invitations_Aggregate;
  plan_name?: Maybe<Scalars['String']>;
  /** An array relationship */
  policies: Array<Policies>;
  /** An aggregate relationship */
  policies_aggregate: Policies_Aggregate;
  /** An array relationship */
  pro_key_generations: Array<Projects_Pro_Key_Generations>;
  /** An aggregate relationship */
  pro_key_generations_aggregate: Projects_Pro_Key_Generations_Aggregate;
  /** An array relationship */
  project_billing_manager_invitations: Array<
    Project_Billing_Manager_Invitations
  >;
  /** An aggregate relationship */
  project_billing_manager_invitations_aggregate: Project_Billing_Manager_Invitations_Aggregate;
  /** An array relationship */
  project_usage_prometheus: Array<Project_Data_Usage_Prometheus>;
  /** An aggregate relationship */
  project_usage_prometheus_aggregate: Project_Data_Usage_Prometheus_Aggregate;
  /** An array relationship */
  project_usages: Array<Project_Data_Usage>;
  /** An aggregate relationship */
  project_usages_aggregate: Project_Data_Usage_Aggregate;
  /** An object relationship */
  prometheus_config?: Maybe<Prometheus_Config>;
  server_oauth_id: Scalars['String'];
  /** An object relationship */
  tenant?: Maybe<Tenant>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  usage_reports: Array<Project_Data_Usage_Report>;
  /** An aggregate relationship */
  usage_reports_aggregate: Project_Data_Usage_Report_Aggregate;
  /** An array relationship */
  vercel_integration_connections: Array<Vercel_Integration_Connections>;
  /** An aggregate relationship */
  vercel_integration_connections_aggregate: Vercel_Integration_Connections_Aggregate;
};

/** Hasura projects */
export type ProjectsActivitiesArgs = {
  distinct_on?: InputMaybe<Array<Project_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Activity_Order_By>>;
  where?: InputMaybe<Project_Activity_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsActivities_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Activity_Order_By>>;
  where?: InputMaybe<Project_Activity_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsCollaboratorsArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborators_Order_By>>;
  where?: InputMaybe<Project_Collaborators_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsCollaborators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborators_Order_By>>;
  where?: InputMaybe<Project_Collaborators_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsEntitlementsArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Access_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsEntitlements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Access_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsHeroku_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Heroku_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Heroku_Integrations_Order_By>>;
  where?: InputMaybe<Heroku_Integrations_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsHeroku_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Heroku_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Heroku_Integrations_Order_By>>;
  where?: InputMaybe<Heroku_Integrations_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsInvitationsArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsInvitations_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsLabelsArgs = {
  distinct_on?: InputMaybe<Array<Project_Labels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Labels_Order_By>>;
  where?: InputMaybe<Project_Labels_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsLabels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Labels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Labels_Order_By>>;
  where?: InputMaybe<Project_Labels_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsOwnership_Transfer_InvitesArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Ownership_Transfer_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Ownership_Transfer_Invitations_Order_By>>;
  where?: InputMaybe<Project_Ownership_Transfer_Invitations_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsOwnership_Transfer_Invites_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Ownership_Transfer_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Ownership_Transfer_Invitations_Order_By>>;
  where?: InputMaybe<Project_Ownership_Transfer_Invitations_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsPolicies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsPro_Key_GenerationsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Pro_Key_Generations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Pro_Key_Generations_Order_By>>;
  where?: InputMaybe<Projects_Pro_Key_Generations_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsPro_Key_Generations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Pro_Key_Generations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Pro_Key_Generations_Order_By>>;
  where?: InputMaybe<Projects_Pro_Key_Generations_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsProject_Billing_Manager_InvitationsArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Billing_Manager_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Billing_Manager_Invitations_Order_By>>;
  where?: InputMaybe<Project_Billing_Manager_Invitations_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsProject_Billing_Manager_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Billing_Manager_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Billing_Manager_Invitations_Order_By>>;
  where?: InputMaybe<Project_Billing_Manager_Invitations_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsProject_Usage_PrometheusArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Prometheus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsProject_Usage_Prometheus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Prometheus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsProject_UsagesArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsProject_Usages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsUsage_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Report_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Report_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsUsage_Reports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Report_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Report_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsVercel_Integration_ConnectionsArgs = {
  distinct_on?: InputMaybe<Array<Vercel_Integration_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vercel_Integration_Connections_Order_By>>;
  where?: InputMaybe<Vercel_Integration_Connections_Bool_Exp>;
};

/** Hasura projects */
export type ProjectsVercel_Integration_Connections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vercel_Integration_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vercel_Integration_Connections_Order_By>>;
  where?: InputMaybe<Vercel_Integration_Connections_Bool_Exp>;
};

/** aggregated selection of "projects" */
export type Projects_Aggregate = {
  __typename?: 'projects_aggregate';
  aggregate?: Maybe<Projects_Aggregate_Fields>;
  nodes: Array<Projects>;
};

export type Projects_Aggregate_Bool_Exp = {
  count?: InputMaybe<Projects_Aggregate_Bool_Exp_Count>;
};

export type Projects_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Projects_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_Fields = {
  __typename?: 'projects_aggregate_fields';
  avg?: Maybe<Projects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Projects_Max_Fields>;
  min?: Maybe<Projects_Min_Fields>;
  stddev?: Maybe<Projects_Stddev_Fields>;
  stddev_pop?: Maybe<Projects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Projects_Stddev_Samp_Fields>;
  sum?: Maybe<Projects_Sum_Fields>;
  var_pop?: Maybe<Projects_Var_Pop_Fields>;
  var_samp?: Maybe<Projects_Var_Samp_Fields>;
  variance?: Maybe<Projects_Variance_Fields>;
};

/** aggregate fields of "projects" */
export type Projects_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Projects_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "projects" */
export type Projects_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Projects_Max_Order_By>;
  min?: InputMaybe<Projects_Min_Order_By>;
};

/** input type for inserting array relation for remote table "projects" */
export type Projects_Arr_Rel_Insert_Input = {
  data: Array<Projects_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};

/** aggregate avg on columns */
export type Projects_Avg_Fields = {
  __typename?: 'projects_avg_fields';
  /** This computed field returns the data usage for the project in the current month, computed from Prometheus. */
  current_month_data_usage?: Maybe<Scalars['bigint']>;
  /** invoice attempt count if exists */
  invoice_attempt_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_requests_per_month_for_project" */
  number_of_requests_this_month?: Maybe<Scalars['bigint']>;
};

/** Boolean expression to filter rows from the table "projects". All fields are combined with a logical 'AND'. */
export type Projects_Bool_Exp = {
  _and?: InputMaybe<Array<Projects_Bool_Exp>>;
  _not?: InputMaybe<Projects_Bool_Exp>;
  _or?: InputMaybe<Array<Projects_Bool_Exp>>;
  activities?: InputMaybe<Project_Activity_Bool_Exp>;
  activities_aggregate?: InputMaybe<Project_Activity_Aggregate_Bool_Exp>;
  alert_config?: InputMaybe<Alert_Config_Bool_Exp>;
  assigned_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  azuremonitor_config?: InputMaybe<Azuremonitor_Config_Bool_Exp>;
  billing_manager?: InputMaybe<Users_Public_Bool_Exp>;
  billing_manager_id?: InputMaybe<Uuid_Comparison_Exp>;
  billing_plan_name?: InputMaybe<String_Comparison_Exp>;
  collaborators?: InputMaybe<Project_Collaborators_Bool_Exp>;
  collaborators_aggregate?: InputMaybe<
    Project_Collaborators_Aggregate_Bool_Exp
  >;
  console_oauth_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_month_data_usage?: InputMaybe<Bigint_Comparison_Exp>;
  datadog_config?: InputMaybe<Datadog_Config_Bool_Exp>;
  endpoint?: InputMaybe<String_Comparison_Exp>;
  enterprise_users?: InputMaybe<Enterprise_Users_Bool_Exp>;
  entitlements?: InputMaybe<Project_Entitlement_Access_Bool_Exp>;
  entitlements_aggregate?: InputMaybe<
    Project_Entitlement_Access_Aggregate_Bool_Exp
  >;
  heroku_integrations?: InputMaybe<Heroku_Integrations_Bool_Exp>;
  heroku_integrations_aggregate?: InputMaybe<
    Heroku_Integrations_Aggregate_Bool_Exp
  >;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitations?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
  invitations_aggregate?: InputMaybe<
    Project_Collaborator_Invitations_Aggregate_Bool_Exp
  >;
  invoice_attempt_count?: InputMaybe<Int_Comparison_Exp>;
  invoice_status?: InputMaybe<String_Comparison_Exp>;
  jobs?: InputMaybe<Jobs_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Jobs_Aggregate_Bool_Exp>;
  labels?: InputMaybe<Project_Labels_Bool_Exp>;
  labels_aggregate?: InputMaybe<Project_Labels_Aggregate_Bool_Exp>;
  metadata?: InputMaybe<Project_Metadata_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  newrelic_config?: InputMaybe<Newrelic_Config_Bool_Exp>;
  number_of_requests_this_month?: InputMaybe<Bigint_Comparison_Exp>;
  opentelemetry_config?: InputMaybe<Opentelemetry_Config_Bool_Exp>;
  owner?: InputMaybe<Users_Public_Bool_Exp>;
  owner_entitlements?: InputMaybe<User_Entitlement_Access_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  ownership_transfer_invites?: InputMaybe<
    Project_Ownership_Transfer_Invitations_Bool_Exp
  >;
  ownership_transfer_invites_aggregate?: InputMaybe<
    Project_Ownership_Transfer_Invitations_Aggregate_Bool_Exp
  >;
  plan_name?: InputMaybe<String_Comparison_Exp>;
  policies?: InputMaybe<Policies_Bool_Exp>;
  policies_aggregate?: InputMaybe<Policies_Aggregate_Bool_Exp>;
  pro_key_generations?: InputMaybe<Projects_Pro_Key_Generations_Bool_Exp>;
  pro_key_generations_aggregate?: InputMaybe<
    Projects_Pro_Key_Generations_Aggregate_Bool_Exp
  >;
  project_billing_manager_invitations?: InputMaybe<
    Project_Billing_Manager_Invitations_Bool_Exp
  >;
  project_billing_manager_invitations_aggregate?: InputMaybe<
    Project_Billing_Manager_Invitations_Aggregate_Bool_Exp
  >;
  project_usage_prometheus?: InputMaybe<Project_Data_Usage_Prometheus_Bool_Exp>;
  project_usage_prometheus_aggregate?: InputMaybe<
    Project_Data_Usage_Prometheus_Aggregate_Bool_Exp
  >;
  project_usages?: InputMaybe<Project_Data_Usage_Bool_Exp>;
  project_usages_aggregate?: InputMaybe<Project_Data_Usage_Aggregate_Bool_Exp>;
  prometheus_config?: InputMaybe<Prometheus_Config_Bool_Exp>;
  server_oauth_id?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  usage_reports?: InputMaybe<Project_Data_Usage_Report_Bool_Exp>;
  usage_reports_aggregate?: InputMaybe<
    Project_Data_Usage_Report_Aggregate_Bool_Exp
  >;
  vercel_integration_connections?: InputMaybe<
    Vercel_Integration_Connections_Bool_Exp
  >;
  vercel_integration_connections_aggregate?: InputMaybe<
    Vercel_Integration_Connections_Aggregate_Bool_Exp
  >;
};

/** unique or primary key constraints on table "projects" */
export enum Projects_Constraint {
  /** unique or primary key constraint on columns "console_oauth_id" */
  ProjectsConsoleOauthIdKey = 'projects_console_oauth_id_key',
  /** unique or primary key constraint on columns "id" */
  ProjectsPkey = 'projects_pkey',
  /** unique or primary key constraint on columns "server_oauth_id" */
  ProjectsServerOauthIdKey = 'projects_server_oauth_id_key',
}

/** input type for inserting data into table "projects" */
export type Projects_Insert_Input = {
  activities?: InputMaybe<Project_Activity_Arr_Rel_Insert_Input>;
  alert_config?: InputMaybe<Alert_Config_Obj_Rel_Insert_Input>;
  assigned_at?: InputMaybe<Scalars['timestamptz']>;
  azuremonitor_config?: InputMaybe<Azuremonitor_Config_Obj_Rel_Insert_Input>;
  billing_manager?: InputMaybe<Users_Public_Obj_Rel_Insert_Input>;
  billing_manager_id?: InputMaybe<Scalars['uuid']>;
  collaborators?: InputMaybe<Project_Collaborators_Arr_Rel_Insert_Input>;
  console_oauth_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  datadog_config?: InputMaybe<Datadog_Config_Obj_Rel_Insert_Input>;
  endpoint?: InputMaybe<Scalars['String']>;
  enterprise_users?: InputMaybe<Enterprise_Users_Obj_Rel_Insert_Input>;
  entitlements?: InputMaybe<Project_Entitlement_Access_Arr_Rel_Insert_Input>;
  heroku_integrations?: InputMaybe<Heroku_Integrations_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  invitations?: InputMaybe<
    Project_Collaborator_Invitations_Arr_Rel_Insert_Input
  >;
  jobs?: InputMaybe<Jobs_Arr_Rel_Insert_Input>;
  labels?: InputMaybe<Project_Labels_Arr_Rel_Insert_Input>;
  metadata?: InputMaybe<Project_Metadata_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']>;
  newrelic_config?: InputMaybe<Newrelic_Config_Obj_Rel_Insert_Input>;
  opentelemetry_config?: InputMaybe<Opentelemetry_Config_Obj_Rel_Insert_Input>;
  owner?: InputMaybe<Users_Public_Obj_Rel_Insert_Input>;
  owner_entitlements?: InputMaybe<User_Entitlement_Access_Obj_Rel_Insert_Input>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  ownership_transfer_invites?: InputMaybe<
    Project_Ownership_Transfer_Invitations_Arr_Rel_Insert_Input
  >;
  plan_name?: InputMaybe<Scalars['String']>;
  policies?: InputMaybe<Policies_Arr_Rel_Insert_Input>;
  pro_key_generations?: InputMaybe<
    Projects_Pro_Key_Generations_Arr_Rel_Insert_Input
  >;
  project_billing_manager_invitations?: InputMaybe<
    Project_Billing_Manager_Invitations_Arr_Rel_Insert_Input
  >;
  project_usage_prometheus?: InputMaybe<
    Project_Data_Usage_Prometheus_Arr_Rel_Insert_Input
  >;
  project_usages?: InputMaybe<Project_Data_Usage_Arr_Rel_Insert_Input>;
  prometheus_config?: InputMaybe<Prometheus_Config_Obj_Rel_Insert_Input>;
  server_oauth_id?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  usage_reports?: InputMaybe<Project_Data_Usage_Report_Arr_Rel_Insert_Input>;
  vercel_integration_connections?: InputMaybe<
    Vercel_Integration_Connections_Arr_Rel_Insert_Input
  >;
};

/** aggregate max on columns */
export type Projects_Max_Fields = {
  __typename?: 'projects_max_fields';
  assigned_at?: Maybe<Scalars['timestamptz']>;
  billing_manager_id?: Maybe<Scalars['uuid']>;
  /** billing_plan_name reflects whether this project is payable or not. This is a derived columm from the last plan_change activity in the project_activity table corresponding to this project. This is different from the plan_name column because it also takes into consideration the active/deactive state of the project. */
  billing_plan_name?: Maybe<Scalars['String']>;
  console_oauth_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** This computed field returns the data usage for the project in the current month, computed from Prometheus. */
  current_month_data_usage?: Maybe<Scalars['bigint']>;
  endpoint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** invoice attempt count if exists */
  invoice_attempt_count?: Maybe<Scalars['Int']>;
  /** latest invoice payement status if exists */
  invoice_status?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_requests_per_month_for_project" */
  number_of_requests_this_month?: Maybe<Scalars['bigint']>;
  owner_id?: Maybe<Scalars['uuid']>;
  plan_name?: Maybe<Scalars['String']>;
  server_oauth_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "projects" */
export type Projects_Max_Order_By = {
  assigned_at?: InputMaybe<Order_By>;
  billing_manager_id?: InputMaybe<Order_By>;
  console_oauth_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  endpoint?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  plan_name?: InputMaybe<Order_By>;
  server_oauth_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Projects_Min_Fields = {
  __typename?: 'projects_min_fields';
  assigned_at?: Maybe<Scalars['timestamptz']>;
  billing_manager_id?: Maybe<Scalars['uuid']>;
  /** billing_plan_name reflects whether this project is payable or not. This is a derived columm from the last plan_change activity in the project_activity table corresponding to this project. This is different from the plan_name column because it also takes into consideration the active/deactive state of the project. */
  billing_plan_name?: Maybe<Scalars['String']>;
  console_oauth_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** This computed field returns the data usage for the project in the current month, computed from Prometheus. */
  current_month_data_usage?: Maybe<Scalars['bigint']>;
  endpoint?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** invoice attempt count if exists */
  invoice_attempt_count?: Maybe<Scalars['Int']>;
  /** latest invoice payement status if exists */
  invoice_status?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** A computed field, executes function "get_requests_per_month_for_project" */
  number_of_requests_this_month?: Maybe<Scalars['bigint']>;
  owner_id?: Maybe<Scalars['uuid']>;
  plan_name?: Maybe<Scalars['String']>;
  server_oauth_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "projects" */
export type Projects_Min_Order_By = {
  assigned_at?: InputMaybe<Order_By>;
  billing_manager_id?: InputMaybe<Order_By>;
  console_oauth_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  endpoint?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  plan_name?: InputMaybe<Order_By>;
  server_oauth_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "projects" */
export type Projects_Mutation_Response = {
  __typename?: 'projects_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Projects>;
};

/** input type for inserting object relation for remote table "projects" */
export type Projects_Obj_Rel_Insert_Input = {
  data: Projects_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Projects_On_Conflict>;
};

/** on_conflict condition type for table "projects" */
export type Projects_On_Conflict = {
  constraint: Projects_Constraint;
  update_columns?: Array<Projects_Update_Column>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** Ordering options when selecting data from "projects". */
export type Projects_Order_By = {
  activities_aggregate?: InputMaybe<Project_Activity_Aggregate_Order_By>;
  alert_config?: InputMaybe<Alert_Config_Order_By>;
  assigned_at?: InputMaybe<Order_By>;
  azuremonitor_config?: InputMaybe<Azuremonitor_Config_Order_By>;
  billing_manager?: InputMaybe<Users_Public_Order_By>;
  billing_manager_id?: InputMaybe<Order_By>;
  billing_plan_name?: InputMaybe<Order_By>;
  collaborators_aggregate?: InputMaybe<
    Project_Collaborators_Aggregate_Order_By
  >;
  console_oauth_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_month_data_usage?: InputMaybe<Order_By>;
  datadog_config?: InputMaybe<Datadog_Config_Order_By>;
  endpoint?: InputMaybe<Order_By>;
  enterprise_users?: InputMaybe<Enterprise_Users_Order_By>;
  entitlements_aggregate?: InputMaybe<
    Project_Entitlement_Access_Aggregate_Order_By
  >;
  heroku_integrations_aggregate?: InputMaybe<
    Heroku_Integrations_Aggregate_Order_By
  >;
  id?: InputMaybe<Order_By>;
  invitations_aggregate?: InputMaybe<
    Project_Collaborator_Invitations_Aggregate_Order_By
  >;
  invoice_attempt_count?: InputMaybe<Order_By>;
  invoice_status?: InputMaybe<Order_By>;
  jobs_aggregate?: InputMaybe<Jobs_Aggregate_Order_By>;
  labels_aggregate?: InputMaybe<Project_Labels_Aggregate_Order_By>;
  metadata?: InputMaybe<Project_Metadata_Order_By>;
  name?: InputMaybe<Order_By>;
  newrelic_config?: InputMaybe<Newrelic_Config_Order_By>;
  number_of_requests_this_month?: InputMaybe<Order_By>;
  opentelemetry_config?: InputMaybe<Opentelemetry_Config_Order_By>;
  owner?: InputMaybe<Users_Public_Order_By>;
  owner_entitlements?: InputMaybe<User_Entitlement_Access_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  ownership_transfer_invites_aggregate?: InputMaybe<
    Project_Ownership_Transfer_Invitations_Aggregate_Order_By
  >;
  plan_name?: InputMaybe<Order_By>;
  policies_aggregate?: InputMaybe<Policies_Aggregate_Order_By>;
  pro_key_generations_aggregate?: InputMaybe<
    Projects_Pro_Key_Generations_Aggregate_Order_By
  >;
  project_billing_manager_invitations_aggregate?: InputMaybe<
    Project_Billing_Manager_Invitations_Aggregate_Order_By
  >;
  project_usage_prometheus_aggregate?: InputMaybe<
    Project_Data_Usage_Prometheus_Aggregate_Order_By
  >;
  project_usages_aggregate?: InputMaybe<Project_Data_Usage_Aggregate_Order_By>;
  prometheus_config?: InputMaybe<Prometheus_Config_Order_By>;
  server_oauth_id?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  usage_reports_aggregate?: InputMaybe<
    Project_Data_Usage_Report_Aggregate_Order_By
  >;
  vercel_integration_connections_aggregate?: InputMaybe<
    Vercel_Integration_Connections_Aggregate_Order_By
  >;
};

/** primary key columns input for table: projects */
export type Projects_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** columns and relationships of "projects_pool_config" */
export type Projects_Pool_Config = {
  __typename?: 'projects_pool_config';
  cloud: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  current_status?: Maybe<Project_Pool_Current_Status>;
  plan_name: Scalars['String'];
  region: Scalars['String'];
  size: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "projects_pool_config" */
export type Projects_Pool_Config_Aggregate = {
  __typename?: 'projects_pool_config_aggregate';
  aggregate?: Maybe<Projects_Pool_Config_Aggregate_Fields>;
  nodes: Array<Projects_Pool_Config>;
};

/** aggregate fields of "projects_pool_config" */
export type Projects_Pool_Config_Aggregate_Fields = {
  __typename?: 'projects_pool_config_aggregate_fields';
  avg?: Maybe<Projects_Pool_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Projects_Pool_Config_Max_Fields>;
  min?: Maybe<Projects_Pool_Config_Min_Fields>;
  stddev?: Maybe<Projects_Pool_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Projects_Pool_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Projects_Pool_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Projects_Pool_Config_Sum_Fields>;
  var_pop?: Maybe<Projects_Pool_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Projects_Pool_Config_Var_Samp_Fields>;
  variance?: Maybe<Projects_Pool_Config_Variance_Fields>;
};

/** aggregate fields of "projects_pool_config" */
export type Projects_Pool_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Projects_Pool_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Projects_Pool_Config_Avg_Fields = {
  __typename?: 'projects_pool_config_avg_fields';
  size?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "projects_pool_config". All fields are combined with a logical 'AND'. */
export type Projects_Pool_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Projects_Pool_Config_Bool_Exp>>;
  _not?: InputMaybe<Projects_Pool_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Projects_Pool_Config_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  current_status?: InputMaybe<Project_Pool_Current_Status_Bool_Exp>;
  plan_name?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects_pool_config" */
export enum Projects_Pool_Config_Constraint {
  /** unique or primary key constraint on columns "region", "cloud", "plan_name" */
  ProjectsPoolConfigPkey = 'projects_pool_config_pkey',
}

/** input type for incrementing numeric columns in table "projects_pool_config" */
export type Projects_Pool_Config_Inc_Input = {
  size?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "projects_pool_config" */
export type Projects_Pool_Config_Insert_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  current_status?: InputMaybe<Project_Pool_Current_Status_Obj_Rel_Insert_Input>;
  plan_name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Projects_Pool_Config_Max_Fields = {
  __typename?: 'projects_pool_config_max_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  plan_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Projects_Pool_Config_Min_Fields = {
  __typename?: 'projects_pool_config_min_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  plan_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "projects_pool_config" */
export type Projects_Pool_Config_Mutation_Response = {
  __typename?: 'projects_pool_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Projects_Pool_Config>;
};

/** on_conflict condition type for table "projects_pool_config" */
export type Projects_Pool_Config_On_Conflict = {
  constraint: Projects_Pool_Config_Constraint;
  update_columns?: Array<Projects_Pool_Config_Update_Column>;
  where?: InputMaybe<Projects_Pool_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "projects_pool_config". */
export type Projects_Pool_Config_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  current_status?: InputMaybe<Project_Pool_Current_Status_Order_By>;
  plan_name?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  size?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects_pool_config */
export type Projects_Pool_Config_Pk_Columns_Input = {
  cloud: Scalars['String'];
  plan_name: Scalars['String'];
  region: Scalars['String'];
};

/** select columns of table "projects_pool_config" */
export enum Projects_Pool_Config_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  Region = 'region',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "projects_pool_config" */
export type Projects_Pool_Config_Set_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  plan_name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Projects_Pool_Config_Stddev_Fields = {
  __typename?: 'projects_pool_config_stddev_fields';
  size?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Projects_Pool_Config_Stddev_Pop_Fields = {
  __typename?: 'projects_pool_config_stddev_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Projects_Pool_Config_Stddev_Samp_Fields = {
  __typename?: 'projects_pool_config_stddev_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "projects_pool_config" */
export type Projects_Pool_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Projects_Pool_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Pool_Config_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  plan_name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Projects_Pool_Config_Sum_Fields = {
  __typename?: 'projects_pool_config_sum_fields';
  size?: Maybe<Scalars['Int']>;
};

/** update columns of table "projects_pool_config" */
export enum Projects_Pool_Config_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  Region = 'region',
  /** column name */
  Size = 'size',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Projects_Pool_Config_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Projects_Pool_Config_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Projects_Pool_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Projects_Pool_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Projects_Pool_Config_Var_Pop_Fields = {
  __typename?: 'projects_pool_config_var_pop_fields';
  size?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Projects_Pool_Config_Var_Samp_Fields = {
  __typename?: 'projects_pool_config_var_samp_fields';
  size?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Projects_Pool_Config_Variance_Fields = {
  __typename?: 'projects_pool_config_variance_fields';
  size?: Maybe<Scalars['Float']>;
};

/** Captures pro keys for a project */
export type Projects_Pro_Key_Generations = {
  __typename?: 'projects_pro_key_generations';
  generated_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** aggregated selection of "projects_pro_key_generations" */
export type Projects_Pro_Key_Generations_Aggregate = {
  __typename?: 'projects_pro_key_generations_aggregate';
  aggregate?: Maybe<Projects_Pro_Key_Generations_Aggregate_Fields>;
  nodes: Array<Projects_Pro_Key_Generations>;
};

export type Projects_Pro_Key_Generations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Projects_Pro_Key_Generations_Aggregate_Bool_Exp_Count>;
};

export type Projects_Pro_Key_Generations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Projects_Pro_Key_Generations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Projects_Pro_Key_Generations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "projects_pro_key_generations" */
export type Projects_Pro_Key_Generations_Aggregate_Fields = {
  __typename?: 'projects_pro_key_generations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Projects_Pro_Key_Generations_Max_Fields>;
  min?: Maybe<Projects_Pro_Key_Generations_Min_Fields>;
};

/** aggregate fields of "projects_pro_key_generations" */
export type Projects_Pro_Key_Generations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Projects_Pro_Key_Generations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "projects_pro_key_generations" */
export type Projects_Pro_Key_Generations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Projects_Pro_Key_Generations_Max_Order_By>;
  min?: InputMaybe<Projects_Pro_Key_Generations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "projects_pro_key_generations" */
export type Projects_Pro_Key_Generations_Arr_Rel_Insert_Input = {
  data: Array<Projects_Pro_Key_Generations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Projects_Pro_Key_Generations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "projects_pro_key_generations". All fields are combined with a logical 'AND'. */
export type Projects_Pro_Key_Generations_Bool_Exp = {
  _and?: InputMaybe<Array<Projects_Pro_Key_Generations_Bool_Exp>>;
  _not?: InputMaybe<Projects_Pro_Key_Generations_Bool_Exp>;
  _or?: InputMaybe<Array<Projects_Pro_Key_Generations_Bool_Exp>>;
  generated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "projects_pro_key_generations" */
export enum Projects_Pro_Key_Generations_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProjectsProKeyGenerationsPkey = 'projects_pro_key_generations_pkey',
}

/** input type for inserting data into table "projects_pro_key_generations" */
export type Projects_Pro_Key_Generations_Insert_Input = {
  generated_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Projects_Pro_Key_Generations_Max_Fields = {
  __typename?: 'projects_pro_key_generations_max_fields';
  generated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "projects_pro_key_generations" */
export type Projects_Pro_Key_Generations_Max_Order_By = {
  generated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Projects_Pro_Key_Generations_Min_Fields = {
  __typename?: 'projects_pro_key_generations_min_fields';
  generated_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "projects_pro_key_generations" */
export type Projects_Pro_Key_Generations_Min_Order_By = {
  generated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "projects_pro_key_generations" */
export type Projects_Pro_Key_Generations_Mutation_Response = {
  __typename?: 'projects_pro_key_generations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Projects_Pro_Key_Generations>;
};

/** on_conflict condition type for table "projects_pro_key_generations" */
export type Projects_Pro_Key_Generations_On_Conflict = {
  constraint: Projects_Pro_Key_Generations_Constraint;
  update_columns?: Array<Projects_Pro_Key_Generations_Update_Column>;
  where?: InputMaybe<Projects_Pro_Key_Generations_Bool_Exp>;
};

/** Ordering options when selecting data from "projects_pro_key_generations". */
export type Projects_Pro_Key_Generations_Order_By = {
  generated_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: projects_pro_key_generations */
export type Projects_Pro_Key_Generations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "projects_pro_key_generations" */
export enum Projects_Pro_Key_Generations_Select_Column {
  /** column name */
  GeneratedAt = 'generated_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
}

/** input type for updating data in table "projects_pro_key_generations" */
export type Projects_Pro_Key_Generations_Set_Input = {
  generated_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "projects_pro_key_generations" */
export type Projects_Pro_Key_Generations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Projects_Pro_Key_Generations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Pro_Key_Generations_Stream_Cursor_Value_Input = {
  generated_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "projects_pro_key_generations" */
export enum Projects_Pro_Key_Generations_Update_Column {
  /** column name */
  GeneratedAt = 'generated_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
}

export type Projects_Pro_Key_Generations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Projects_Pro_Key_Generations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Projects_Pro_Key_Generations_Bool_Exp;
};

/** select columns of table "projects" */
export enum Projects_Select_Column {
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  BillingManagerId = 'billing_manager_id',
  /** column name */
  ConsoleOauthId = 'console_oauth_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  ServerOauthId = 'server_oauth_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "projects" */
export type Projects_Set_Input = {
  assigned_at?: InputMaybe<Scalars['timestamptz']>;
  billing_manager_id?: InputMaybe<Scalars['uuid']>;
  console_oauth_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endpoint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  plan_name?: InputMaybe<Scalars['String']>;
  server_oauth_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Projects_Stddev_Fields = {
  __typename?: 'projects_stddev_fields';
  /** This computed field returns the data usage for the project in the current month, computed from Prometheus. */
  current_month_data_usage?: Maybe<Scalars['bigint']>;
  /** invoice attempt count if exists */
  invoice_attempt_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_requests_per_month_for_project" */
  number_of_requests_this_month?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev_pop on columns */
export type Projects_Stddev_Pop_Fields = {
  __typename?: 'projects_stddev_pop_fields';
  /** This computed field returns the data usage for the project in the current month, computed from Prometheus. */
  current_month_data_usage?: Maybe<Scalars['bigint']>;
  /** invoice attempt count if exists */
  invoice_attempt_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_requests_per_month_for_project" */
  number_of_requests_this_month?: Maybe<Scalars['bigint']>;
};

/** aggregate stddev_samp on columns */
export type Projects_Stddev_Samp_Fields = {
  __typename?: 'projects_stddev_samp_fields';
  /** This computed field returns the data usage for the project in the current month, computed from Prometheus. */
  current_month_data_usage?: Maybe<Scalars['bigint']>;
  /** invoice attempt count if exists */
  invoice_attempt_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_requests_per_month_for_project" */
  number_of_requests_this_month?: Maybe<Scalars['bigint']>;
};

/** Streaming cursor of the table "projects" */
export type Projects_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Projects_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_Stream_Cursor_Value_Input = {
  assigned_at?: InputMaybe<Scalars['timestamptz']>;
  billing_manager_id?: InputMaybe<Scalars['uuid']>;
  console_oauth_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  endpoint?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  plan_name?: InputMaybe<Scalars['String']>;
  server_oauth_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Projects_Sum_Fields = {
  __typename?: 'projects_sum_fields';
  /** This computed field returns the data usage for the project in the current month, computed from Prometheus. */
  current_month_data_usage?: Maybe<Scalars['bigint']>;
  /** invoice attempt count if exists */
  invoice_attempt_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_requests_per_month_for_project" */
  number_of_requests_this_month?: Maybe<Scalars['bigint']>;
};

/** update columns of table "projects" */
export enum Projects_Update_Column {
  /** column name */
  AssignedAt = 'assigned_at',
  /** column name */
  BillingManagerId = 'billing_manager_id',
  /** column name */
  ConsoleOauthId = 'console_oauth_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  ServerOauthId = 'server_oauth_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Projects_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Projects_Set_Input>;
  /** filter the rows which have to be updated */
  where: Projects_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Projects_Var_Pop_Fields = {
  __typename?: 'projects_var_pop_fields';
  /** This computed field returns the data usage for the project in the current month, computed from Prometheus. */
  current_month_data_usage?: Maybe<Scalars['bigint']>;
  /** invoice attempt count if exists */
  invoice_attempt_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_requests_per_month_for_project" */
  number_of_requests_this_month?: Maybe<Scalars['bigint']>;
};

/** aggregate var_samp on columns */
export type Projects_Var_Samp_Fields = {
  __typename?: 'projects_var_samp_fields';
  /** This computed field returns the data usage for the project in the current month, computed from Prometheus. */
  current_month_data_usage?: Maybe<Scalars['bigint']>;
  /** invoice attempt count if exists */
  invoice_attempt_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_requests_per_month_for_project" */
  number_of_requests_this_month?: Maybe<Scalars['bigint']>;
};

/** aggregate variance on columns */
export type Projects_Variance_Fields = {
  __typename?: 'projects_variance_fields';
  /** This computed field returns the data usage for the project in the current month, computed from Prometheus. */
  current_month_data_usage?: Maybe<Scalars['bigint']>;
  /** invoice attempt count if exists */
  invoice_attempt_count?: Maybe<Scalars['Int']>;
  /** A computed field, executes function "get_requests_per_month_for_project" */
  number_of_requests_this_month?: Maybe<Scalars['bigint']>;
};

/** columns and relationships of "projects_with_no_running_deployments" */
export type Projects_With_No_Running_Deployments = {
  __typename?: 'projects_with_no_running_deployments';
  /** An array relationship */
  jobs: Array<Jobs>;
  /** An aggregate relationship */
  jobs_aggregate: Jobs_Aggregate;
  project_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "projects_with_no_running_deployments" */
export type Projects_With_No_Running_DeploymentsJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

/** columns and relationships of "projects_with_no_running_deployments" */
export type Projects_With_No_Running_DeploymentsJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

/** aggregated selection of "projects_with_no_running_deployments" */
export type Projects_With_No_Running_Deployments_Aggregate = {
  __typename?: 'projects_with_no_running_deployments_aggregate';
  aggregate?: Maybe<Projects_With_No_Running_Deployments_Aggregate_Fields>;
  nodes: Array<Projects_With_No_Running_Deployments>;
};

/** aggregate fields of "projects_with_no_running_deployments" */
export type Projects_With_No_Running_Deployments_Aggregate_Fields = {
  __typename?: 'projects_with_no_running_deployments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Projects_With_No_Running_Deployments_Max_Fields>;
  min?: Maybe<Projects_With_No_Running_Deployments_Min_Fields>;
};

/** aggregate fields of "projects_with_no_running_deployments" */
export type Projects_With_No_Running_Deployments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Projects_With_No_Running_Deployments_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "projects_with_no_running_deployments". All fields are combined with a logical 'AND'. */
export type Projects_With_No_Running_Deployments_Bool_Exp = {
  _and?: InputMaybe<Array<Projects_With_No_Running_Deployments_Bool_Exp>>;
  _not?: InputMaybe<Projects_With_No_Running_Deployments_Bool_Exp>;
  _or?: InputMaybe<Array<Projects_With_No_Running_Deployments_Bool_Exp>>;
  jobs?: InputMaybe<Jobs_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Jobs_Aggregate_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type Projects_With_No_Running_Deployments_Max_Fields = {
  __typename?: 'projects_with_no_running_deployments_max_fields';
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Projects_With_No_Running_Deployments_Min_Fields = {
  __typename?: 'projects_with_no_running_deployments_min_fields';
  project_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "projects_with_no_running_deployments". */
export type Projects_With_No_Running_Deployments_Order_By = {
  jobs_aggregate?: InputMaybe<Jobs_Aggregate_Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** select columns of table "projects_with_no_running_deployments" */
export enum Projects_With_No_Running_Deployments_Select_Column {
  /** column name */
  ProjectId = 'project_id',
}

/** Streaming cursor of the table "projects_with_no_running_deployments" */
export type Projects_With_No_Running_Deployments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Projects_With_No_Running_Deployments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_With_No_Running_Deployments_Stream_Cursor_Value_Input = {
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** columns and relationships of "projects_with_no_running_preview_app" */
export type Projects_With_No_Running_Preview_App = {
  __typename?: 'projects_with_no_running_preview_app';
  /** An array relationship */
  jobs: Array<Jobs>;
  /** An aggregate relationship */
  jobs_aggregate: Jobs_Aggregate;
  project_name?: Maybe<Scalars['String']>;
};

/** columns and relationships of "projects_with_no_running_preview_app" */
export type Projects_With_No_Running_Preview_AppJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

/** columns and relationships of "projects_with_no_running_preview_app" */
export type Projects_With_No_Running_Preview_AppJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

/** aggregated selection of "projects_with_no_running_preview_app" */
export type Projects_With_No_Running_Preview_App_Aggregate = {
  __typename?: 'projects_with_no_running_preview_app_aggregate';
  aggregate?: Maybe<Projects_With_No_Running_Preview_App_Aggregate_Fields>;
  nodes: Array<Projects_With_No_Running_Preview_App>;
};

/** aggregate fields of "projects_with_no_running_preview_app" */
export type Projects_With_No_Running_Preview_App_Aggregate_Fields = {
  __typename?: 'projects_with_no_running_preview_app_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Projects_With_No_Running_Preview_App_Max_Fields>;
  min?: Maybe<Projects_With_No_Running_Preview_App_Min_Fields>;
};

/** aggregate fields of "projects_with_no_running_preview_app" */
export type Projects_With_No_Running_Preview_App_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Projects_With_No_Running_Preview_App_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "projects_with_no_running_preview_app". All fields are combined with a logical 'AND'. */
export type Projects_With_No_Running_Preview_App_Bool_Exp = {
  _and?: InputMaybe<Array<Projects_With_No_Running_Preview_App_Bool_Exp>>;
  _not?: InputMaybe<Projects_With_No_Running_Preview_App_Bool_Exp>;
  _or?: InputMaybe<Array<Projects_With_No_Running_Preview_App_Bool_Exp>>;
  jobs?: InputMaybe<Jobs_Bool_Exp>;
  jobs_aggregate?: InputMaybe<Jobs_Aggregate_Bool_Exp>;
  project_name?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Projects_With_No_Running_Preview_App_Max_Fields = {
  __typename?: 'projects_with_no_running_preview_app_max_fields';
  project_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Projects_With_No_Running_Preview_App_Min_Fields = {
  __typename?: 'projects_with_no_running_preview_app_min_fields';
  project_name?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "projects_with_no_running_preview_app". */
export type Projects_With_No_Running_Preview_App_Order_By = {
  jobs_aggregate?: InputMaybe<Jobs_Aggregate_Order_By>;
  project_name?: InputMaybe<Order_By>;
};

/** select columns of table "projects_with_no_running_preview_app" */
export enum Projects_With_No_Running_Preview_App_Select_Column {
  /** column name */
  ProjectName = 'project_name',
}

/** Streaming cursor of the table "projects_with_no_running_preview_app" */
export type Projects_With_No_Running_Preview_App_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Projects_With_No_Running_Preview_App_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Projects_With_No_Running_Preview_App_Stream_Cursor_Value_Input = {
  project_name?: InputMaybe<Scalars['String']>;
};

/** Prometheus Exporter Config */
export type Prometheus_Config = {
  __typename?: 'prometheus_config';
  access_token: Scalars['String'];
  created_at: Scalars['timestamptz'];
  labels: Scalars['jsonb'];
  metrics_last_logged?: Maybe<Scalars['timestamptz']>;
  metrics_response_code?: Maybe<Scalars['Int']>;
  namespace?: Maybe<Scalars['String']>;
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
};

/** Prometheus Exporter Config */
export type Prometheus_ConfigLabelsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "prometheus_config" */
export type Prometheus_Config_Aggregate = {
  __typename?: 'prometheus_config_aggregate';
  aggregate?: Maybe<Prometheus_Config_Aggregate_Fields>;
  nodes: Array<Prometheus_Config>;
};

/** aggregate fields of "prometheus_config" */
export type Prometheus_Config_Aggregate_Fields = {
  __typename?: 'prometheus_config_aggregate_fields';
  avg?: Maybe<Prometheus_Config_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Prometheus_Config_Max_Fields>;
  min?: Maybe<Prometheus_Config_Min_Fields>;
  stddev?: Maybe<Prometheus_Config_Stddev_Fields>;
  stddev_pop?: Maybe<Prometheus_Config_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Prometheus_Config_Stddev_Samp_Fields>;
  sum?: Maybe<Prometheus_Config_Sum_Fields>;
  var_pop?: Maybe<Prometheus_Config_Var_Pop_Fields>;
  var_samp?: Maybe<Prometheus_Config_Var_Samp_Fields>;
  variance?: Maybe<Prometheus_Config_Variance_Fields>;
};

/** aggregate fields of "prometheus_config" */
export type Prometheus_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Prometheus_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Prometheus_Config_Append_Input = {
  labels?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Prometheus_Config_Avg_Fields = {
  __typename?: 'prometheus_config_avg_fields';
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "prometheus_config". All fields are combined with a logical 'AND'. */
export type Prometheus_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Prometheus_Config_Bool_Exp>>;
  _not?: InputMaybe<Prometheus_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Prometheus_Config_Bool_Exp>>;
  access_token?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  labels?: InputMaybe<Jsonb_Comparison_Exp>;
  metrics_last_logged?: InputMaybe<Timestamptz_Comparison_Exp>;
  metrics_response_code?: InputMaybe<Int_Comparison_Exp>;
  namespace?: InputMaybe<String_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "prometheus_config" */
export enum Prometheus_Config_Constraint {
  /** unique or primary key constraint on columns "project_id" */
  PrometheusConfigPkey = 'prometheus_config_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Prometheus_Config_Delete_At_Path_Input = {
  labels?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Prometheus_Config_Delete_Elem_Input = {
  labels?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Prometheus_Config_Delete_Key_Input = {
  labels?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "prometheus_config" */
export type Prometheus_Config_Inc_Input = {
  metrics_response_code?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "prometheus_config" */
export type Prometheus_Config_Insert_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  metrics_last_logged?: InputMaybe<Scalars['timestamptz']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  namespace?: InputMaybe<Scalars['String']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Prometheus_Config_Max_Fields = {
  __typename?: 'prometheus_config_max_fields';
  access_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  metrics_last_logged?: Maybe<Scalars['timestamptz']>;
  metrics_response_code?: Maybe<Scalars['Int']>;
  namespace?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Prometheus_Config_Min_Fields = {
  __typename?: 'prometheus_config_min_fields';
  access_token?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  metrics_last_logged?: Maybe<Scalars['timestamptz']>;
  metrics_response_code?: Maybe<Scalars['Int']>;
  namespace?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "prometheus_config" */
export type Prometheus_Config_Mutation_Response = {
  __typename?: 'prometheus_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Prometheus_Config>;
};

/** input type for inserting object relation for remote table "prometheus_config" */
export type Prometheus_Config_Obj_Rel_Insert_Input = {
  data: Prometheus_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Prometheus_Config_On_Conflict>;
};

/** on_conflict condition type for table "prometheus_config" */
export type Prometheus_Config_On_Conflict = {
  constraint: Prometheus_Config_Constraint;
  update_columns?: Array<Prometheus_Config_Update_Column>;
  where?: InputMaybe<Prometheus_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "prometheus_config". */
export type Prometheus_Config_Order_By = {
  access_token?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  labels?: InputMaybe<Order_By>;
  metrics_last_logged?: InputMaybe<Order_By>;
  metrics_response_code?: InputMaybe<Order_By>;
  namespace?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: prometheus_config */
export type Prometheus_Config_Pk_Columns_Input = {
  project_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Prometheus_Config_Prepend_Input = {
  labels?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "prometheus_config" */
export enum Prometheus_Config_Select_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Labels = 'labels',
  /** column name */
  MetricsLastLogged = 'metrics_last_logged',
  /** column name */
  MetricsResponseCode = 'metrics_response_code',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  ProjectId = 'project_id',
}

/** input type for updating data in table "prometheus_config" */
export type Prometheus_Config_Set_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  metrics_last_logged?: InputMaybe<Scalars['timestamptz']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  namespace?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Prometheus_Config_Stddev_Fields = {
  __typename?: 'prometheus_config_stddev_fields';
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Prometheus_Config_Stddev_Pop_Fields = {
  __typename?: 'prometheus_config_stddev_pop_fields';
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Prometheus_Config_Stddev_Samp_Fields = {
  __typename?: 'prometheus_config_stddev_samp_fields';
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "prometheus_config" */
export type Prometheus_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Prometheus_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Prometheus_Config_Stream_Cursor_Value_Input = {
  access_token?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  labels?: InputMaybe<Scalars['jsonb']>;
  metrics_last_logged?: InputMaybe<Scalars['timestamptz']>;
  metrics_response_code?: InputMaybe<Scalars['Int']>;
  namespace?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Prometheus_Config_Sum_Fields = {
  __typename?: 'prometheus_config_sum_fields';
  metrics_response_code?: Maybe<Scalars['Int']>;
};

/** update columns of table "prometheus_config" */
export enum Prometheus_Config_Update_Column {
  /** column name */
  AccessToken = 'access_token',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Labels = 'labels',
  /** column name */
  MetricsLastLogged = 'metrics_last_logged',
  /** column name */
  MetricsResponseCode = 'metrics_response_code',
  /** column name */
  Namespace = 'namespace',
  /** column name */
  ProjectId = 'project_id',
}

export type Prometheus_Config_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Prometheus_Config_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Prometheus_Config_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Prometheus_Config_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Prometheus_Config_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Prometheus_Config_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Prometheus_Config_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Prometheus_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Prometheus_Config_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Prometheus_Config_Var_Pop_Fields = {
  __typename?: 'prometheus_config_var_pop_fields';
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Prometheus_Config_Var_Samp_Fields = {
  __typename?: 'prometheus_config_var_samp_fields';
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Prometheus_Config_Variance_Fields = {
  __typename?: 'prometheus_config_variance_fields';
  metrics_response_code?: Maybe<Scalars['Float']>;
};

/** Captures oauth2 integration */
export type Providers = {
  __typename?: 'providers';
  id: Scalars['Int'];
  oauth2_access_token: Scalars['String'];
  oauth2_expiry: Scalars['timestamptz'];
  oauth2_provider: Scalars['String'];
  oauth2_refresh_token: Scalars['String'];
  oauth2_uid: Scalars['String'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "providers" */
export type Providers_Aggregate = {
  __typename?: 'providers_aggregate';
  aggregate?: Maybe<Providers_Aggregate_Fields>;
  nodes: Array<Providers>;
};

export type Providers_Aggregate_Bool_Exp = {
  count?: InputMaybe<Providers_Aggregate_Bool_Exp_Count>;
};

export type Providers_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Providers_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "providers" */
export type Providers_Aggregate_Fields = {
  __typename?: 'providers_aggregate_fields';
  avg?: Maybe<Providers_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Providers_Max_Fields>;
  min?: Maybe<Providers_Min_Fields>;
  stddev?: Maybe<Providers_Stddev_Fields>;
  stddev_pop?: Maybe<Providers_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Providers_Stddev_Samp_Fields>;
  sum?: Maybe<Providers_Sum_Fields>;
  var_pop?: Maybe<Providers_Var_Pop_Fields>;
  var_samp?: Maybe<Providers_Var_Samp_Fields>;
  variance?: Maybe<Providers_Variance_Fields>;
};

/** aggregate fields of "providers" */
export type Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "providers" */
export type Providers_Aggregate_Order_By = {
  avg?: InputMaybe<Providers_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Providers_Max_Order_By>;
  min?: InputMaybe<Providers_Min_Order_By>;
  stddev?: InputMaybe<Providers_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Providers_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Providers_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Providers_Sum_Order_By>;
  var_pop?: InputMaybe<Providers_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Providers_Var_Samp_Order_By>;
  variance?: InputMaybe<Providers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "providers" */
export type Providers_Arr_Rel_Insert_Input = {
  data: Array<Providers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Providers_On_Conflict>;
};

/** aggregate avg on columns */
export type Providers_Avg_Fields = {
  __typename?: 'providers_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "providers" */
export type Providers_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "providers". All fields are combined with a logical 'AND'. */
export type Providers_Bool_Exp = {
  _and?: InputMaybe<Array<Providers_Bool_Exp>>;
  _not?: InputMaybe<Providers_Bool_Exp>;
  _or?: InputMaybe<Array<Providers_Bool_Exp>>;
  id?: InputMaybe<Int_Comparison_Exp>;
  oauth2_access_token?: InputMaybe<String_Comparison_Exp>;
  oauth2_expiry?: InputMaybe<Timestamptz_Comparison_Exp>;
  oauth2_provider?: InputMaybe<String_Comparison_Exp>;
  oauth2_refresh_token?: InputMaybe<String_Comparison_Exp>;
  oauth2_uid?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "providers" */
export enum Providers_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProvidersPkey = 'providers_pkey',
  /** unique or primary key constraint on columns "user_id", "oauth2_provider" */
  ProvidersUserIdOauth2ProviderKey = 'providers_user_id_oauth2_provider_key',
}

/** input type for incrementing numeric columns in table "providers" */
export type Providers_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "providers" */
export type Providers_Insert_Input = {
  id?: InputMaybe<Scalars['Int']>;
  oauth2_access_token?: InputMaybe<Scalars['String']>;
  oauth2_expiry?: InputMaybe<Scalars['timestamptz']>;
  oauth2_provider?: InputMaybe<Scalars['String']>;
  oauth2_refresh_token?: InputMaybe<Scalars['String']>;
  oauth2_uid?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Providers_Max_Fields = {
  __typename?: 'providers_max_fields';
  id?: Maybe<Scalars['Int']>;
  oauth2_access_token?: Maybe<Scalars['String']>;
  oauth2_expiry?: Maybe<Scalars['timestamptz']>;
  oauth2_provider?: Maybe<Scalars['String']>;
  oauth2_refresh_token?: Maybe<Scalars['String']>;
  oauth2_uid?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "providers" */
export type Providers_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  oauth2_access_token?: InputMaybe<Order_By>;
  oauth2_expiry?: InputMaybe<Order_By>;
  oauth2_provider?: InputMaybe<Order_By>;
  oauth2_refresh_token?: InputMaybe<Order_By>;
  oauth2_uid?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Providers_Min_Fields = {
  __typename?: 'providers_min_fields';
  id?: Maybe<Scalars['Int']>;
  oauth2_access_token?: Maybe<Scalars['String']>;
  oauth2_expiry?: Maybe<Scalars['timestamptz']>;
  oauth2_provider?: Maybe<Scalars['String']>;
  oauth2_refresh_token?: Maybe<Scalars['String']>;
  oauth2_uid?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "providers" */
export type Providers_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  oauth2_access_token?: InputMaybe<Order_By>;
  oauth2_expiry?: InputMaybe<Order_By>;
  oauth2_provider?: InputMaybe<Order_By>;
  oauth2_refresh_token?: InputMaybe<Order_By>;
  oauth2_uid?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "providers" */
export type Providers_Mutation_Response = {
  __typename?: 'providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Providers>;
};

/** on_conflict condition type for table "providers" */
export type Providers_On_Conflict = {
  constraint: Providers_Constraint;
  update_columns?: Array<Providers_Update_Column>;
  where?: InputMaybe<Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "providers". */
export type Providers_Order_By = {
  id?: InputMaybe<Order_By>;
  oauth2_access_token?: InputMaybe<Order_By>;
  oauth2_expiry?: InputMaybe<Order_By>;
  oauth2_provider?: InputMaybe<Order_By>;
  oauth2_refresh_token?: InputMaybe<Order_By>;
  oauth2_uid?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: providers */
export type Providers_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "providers" */
export enum Providers_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Oauth2AccessToken = 'oauth2_access_token',
  /** column name */
  Oauth2Expiry = 'oauth2_expiry',
  /** column name */
  Oauth2Provider = 'oauth2_provider',
  /** column name */
  Oauth2RefreshToken = 'oauth2_refresh_token',
  /** column name */
  Oauth2Uid = 'oauth2_uid',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "providers" */
export type Providers_Set_Input = {
  id?: InputMaybe<Scalars['Int']>;
  oauth2_access_token?: InputMaybe<Scalars['String']>;
  oauth2_expiry?: InputMaybe<Scalars['timestamptz']>;
  oauth2_provider?: InputMaybe<Scalars['String']>;
  oauth2_refresh_token?: InputMaybe<Scalars['String']>;
  oauth2_uid?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Providers_Stddev_Fields = {
  __typename?: 'providers_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "providers" */
export type Providers_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Providers_Stddev_Pop_Fields = {
  __typename?: 'providers_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "providers" */
export type Providers_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Providers_Stddev_Samp_Fields = {
  __typename?: 'providers_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "providers" */
export type Providers_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "providers" */
export type Providers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Providers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Providers_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['Int']>;
  oauth2_access_token?: InputMaybe<Scalars['String']>;
  oauth2_expiry?: InputMaybe<Scalars['timestamptz']>;
  oauth2_provider?: InputMaybe<Scalars['String']>;
  oauth2_refresh_token?: InputMaybe<Scalars['String']>;
  oauth2_uid?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Providers_Sum_Fields = {
  __typename?: 'providers_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "providers" */
export type Providers_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "providers" */
export enum Providers_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Oauth2AccessToken = 'oauth2_access_token',
  /** column name */
  Oauth2Expiry = 'oauth2_expiry',
  /** column name */
  Oauth2Provider = 'oauth2_provider',
  /** column name */
  Oauth2RefreshToken = 'oauth2_refresh_token',
  /** column name */
  Oauth2Uid = 'oauth2_uid',
  /** column name */
  UserId = 'user_id',
}

export type Providers_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Providers_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Providers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Providers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Providers_Var_Pop_Fields = {
  __typename?: 'providers_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "providers" */
export type Providers_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Providers_Var_Samp_Fields = {
  __typename?: 'providers_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "providers" */
export type Providers_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Providers_Variance_Fields = {
  __typename?: 'providers_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "providers" */
export type Providers_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Different provisioning options for cloud resources */
export type Provisioning = {
  __typename?: 'provisioning';
  description: Scalars['String'];
  provisioning: Scalars['String'];
};

/** aggregated selection of "provisioning" */
export type Provisioning_Aggregate = {
  __typename?: 'provisioning_aggregate';
  aggregate?: Maybe<Provisioning_Aggregate_Fields>;
  nodes: Array<Provisioning>;
};

/** aggregate fields of "provisioning" */
export type Provisioning_Aggregate_Fields = {
  __typename?: 'provisioning_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Provisioning_Max_Fields>;
  min?: Maybe<Provisioning_Min_Fields>;
};

/** aggregate fields of "provisioning" */
export type Provisioning_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Provisioning_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "provisioning". All fields are combined with a logical 'AND'. */
export type Provisioning_Bool_Exp = {
  _and?: InputMaybe<Array<Provisioning_Bool_Exp>>;
  _not?: InputMaybe<Provisioning_Bool_Exp>;
  _or?: InputMaybe<Array<Provisioning_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  provisioning?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "provisioning" */
export enum Provisioning_Constraint {
  /** unique or primary key constraint on columns "provisioning" */
  ProvisioningPkey = 'provisioning_pkey',
}

export enum Provisioning_Enum {
  /** Manual provisioning of resources. The event-trigger/taskq should skip execution if the provisioning is marked as manual */
  Manual = 'manual',
  /** Provisioning is done by event-triggers/taskq */
  SelfServe = 'self_serve',
}

/** Boolean expression to compare columns of type "provisioning_enum". All fields are combined with logical 'AND'. */
export type Provisioning_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Provisioning_Enum>;
  _in?: InputMaybe<Array<Provisioning_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Provisioning_Enum>;
  _nin?: InputMaybe<Array<Provisioning_Enum>>;
};

/** input type for inserting data into table "provisioning" */
export type Provisioning_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  provisioning?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Provisioning_Max_Fields = {
  __typename?: 'provisioning_max_fields';
  description?: Maybe<Scalars['String']>;
  provisioning?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Provisioning_Min_Fields = {
  __typename?: 'provisioning_min_fields';
  description?: Maybe<Scalars['String']>;
  provisioning?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "provisioning" */
export type Provisioning_Mutation_Response = {
  __typename?: 'provisioning_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Provisioning>;
};

/** on_conflict condition type for table "provisioning" */
export type Provisioning_On_Conflict = {
  constraint: Provisioning_Constraint;
  update_columns?: Array<Provisioning_Update_Column>;
  where?: InputMaybe<Provisioning_Bool_Exp>;
};

/** Ordering options when selecting data from "provisioning". */
export type Provisioning_Order_By = {
  description?: InputMaybe<Order_By>;
  provisioning?: InputMaybe<Order_By>;
};

/** primary key columns input for table: provisioning */
export type Provisioning_Pk_Columns_Input = {
  provisioning: Scalars['String'];
};

/** select columns of table "provisioning" */
export enum Provisioning_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Provisioning = 'provisioning',
}

/** input type for updating data in table "provisioning" */
export type Provisioning_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  provisioning?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "provisioning" */
export type Provisioning_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Provisioning_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Provisioning_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  provisioning?: InputMaybe<Scalars['String']>;
};

/** update columns of table "provisioning" */
export enum Provisioning_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Provisioning = 'provisioning',
}

export type Provisioning_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Provisioning_Set_Input>;
  /** filter the rows which have to be updated */
  where: Provisioning_Bool_Exp;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "alert_config" */
  alert_config: Array<Alert_Config>;
  /** fetch aggregated fields from the table: "alert_config" */
  alert_config_aggregate: Alert_Config_Aggregate;
  /** fetch data from the table: "alert_config_alert_type" */
  alert_config_alert_type: Array<Alert_Config_Alert_Type>;
  /** fetch aggregated fields from the table: "alert_config_alert_type" */
  alert_config_alert_type_aggregate: Alert_Config_Alert_Type_Aggregate;
  /** fetch data from the table: "alert_config_alert_type" using primary key columns */
  alert_config_alert_type_by_pk?: Maybe<Alert_Config_Alert_Type>;
  /** fetch data from the table: "alert_config" using primary key columns */
  alert_config_by_pk?: Maybe<Alert_Config>;
  /** fetch data from the table: "alert_config_service" */
  alert_config_service: Array<Alert_Config_Service>;
  /** fetch aggregated fields from the table: "alert_config_service" */
  alert_config_service_aggregate: Alert_Config_Service_Aggregate;
  /** fetch data from the table: "alert_config_service" using primary key columns */
  alert_config_service_by_pk?: Maybe<Alert_Config_Service>;
  /** fetch data from the table: "alert_service_type" */
  alert_service_type: Array<Alert_Service_Type>;
  /** fetch aggregated fields from the table: "alert_service_type" */
  alert_service_type_aggregate: Alert_Service_Type_Aggregate;
  /** fetch data from the table: "alert_service_type" using primary key columns */
  alert_service_type_by_pk?: Maybe<Alert_Service_Type>;
  /** fetch data from the table: "alert_type" */
  alert_type: Array<Alert_Type>;
  /** fetch aggregated fields from the table: "alert_type" */
  alert_type_aggregate: Alert_Type_Aggregate;
  /** fetch data from the table: "alert_type" using primary key columns */
  alert_type_by_pk?: Maybe<Alert_Type>;
  /** fetch data from the table: "azuremonitor_config" */
  azuremonitor_config: Array<Azuremonitor_Config>;
  /** fetch aggregated fields from the table: "azuremonitor_config" */
  azuremonitor_config_aggregate: Azuremonitor_Config_Aggregate;
  /** fetch data from the table: "azuremonitor_config" using primary key columns */
  azuremonitor_config_by_pk?: Maybe<Azuremonitor_Config>;
  /** fetch data from the table: "billing_address" */
  billing_address: Array<Billing_Address>;
  /** fetch aggregated fields from the table: "billing_address" */
  billing_address_aggregate: Billing_Address_Aggregate;
  /** fetch data from the table: "billing_address" using primary key columns */
  billing_address_by_pk?: Maybe<Billing_Address>;
  /** fetch data from the table: "central_cloud" */
  central_cloud: Array<Central_Cloud>;
  /** fetch aggregated fields from the table: "central_cloud" */
  central_cloud_aggregate: Central_Cloud_Aggregate;
  /** fetch data from the table: "central_cloud" using primary key columns */
  central_cloud_by_pk?: Maybe<Central_Cloud>;
  /** fetch data from the table: "change_email_request" */
  change_email_request: Array<Change_Email_Request>;
  /** fetch aggregated fields from the table: "change_email_request" */
  change_email_request_aggregate: Change_Email_Request_Aggregate;
  /** fetch data from the table: "change_email_request" using primary key columns */
  change_email_request_by_pk?: Maybe<Change_Email_Request>;
  /** fetch data from the table: "cicd.deployment_pipeline_config" */
  cicd_deployment_pipeline_config: Array<Cicd_Deployment_Pipeline_Config>;
  /** fetch aggregated fields from the table: "cicd.deployment_pipeline_config" */
  cicd_deployment_pipeline_config_aggregate: Cicd_Deployment_Pipeline_Config_Aggregate;
  /** fetch data from the table: "cicd.deployment_pipeline_config" using primary key columns */
  cicd_deployment_pipeline_config_by_pk?: Maybe<
    Cicd_Deployment_Pipeline_Config
  >;
  /** fetch data from the table: "cicd.deployment_pool" */
  cicd_deployment_pool: Array<Cicd_Deployment_Pool>;
  /** fetch aggregated fields from the table: "cicd.deployment_pool" */
  cicd_deployment_pool_aggregate: Cicd_Deployment_Pool_Aggregate;
  /** fetch data from the table: "cicd.deployment_pool_member" */
  cicd_deployment_pool_member: Array<Cicd_Deployment_Pool_Member>;
  /** fetch aggregated fields from the table: "cicd.deployment_pool_member" */
  cicd_deployment_pool_member_aggregate: Cicd_Deployment_Pool_Member_Aggregate;
  /** execute function "cicd.get_matching_pipeline_config" which returns "cicd.deployment_pipeline_config" */
  cicd_get_matching_pipeline_config: Array<Cicd_Deployment_Pipeline_Config>;
  /** execute function "cicd.get_matching_pipeline_config" and query aggregates on result of table type "cicd.deployment_pipeline_config" */
  cicd_get_matching_pipeline_config_aggregate: Cicd_Deployment_Pipeline_Config_Aggregate;
  /** fetch data from the table: "cloud" */
  cloud: Array<Cloud>;
  /** fetch aggregated fields from the table: "cloud" */
  cloud_aggregate: Cloud_Aggregate;
  /** fetch data from the table: "cloud" using primary key columns */
  cloud_by_pk?: Maybe<Cloud>;
  /** fetch data from the table: "cloud_metadata" */
  cloud_metadata: Array<Cloud_Metadata>;
  /** fetch aggregated fields from the table: "cloud_metadata" */
  cloud_metadata_aggregate: Cloud_Metadata_Aggregate;
  /** fetch data from the table: "cloud_metadata" using primary key columns */
  cloud_metadata_by_pk?: Maybe<Cloud_Metadata>;
  /** fetch data from the table: "cloud_regions_by_plan" */
  cloud_regions_by_plan: Array<Cloud_Regions_By_Plan>;
  /** fetch aggregated fields from the table: "cloud_regions_by_plan" */
  cloud_regions_by_plan_aggregate: Cloud_Regions_By_Plan_Aggregate;
  /** execute function "compute_project_agg_db_usage_user" which returns "project_db_usage_agg_user" */
  compute_project_agg_db_usage_user: Array<Project_Db_Usage_Agg_User>;
  /** execute function "compute_project_agg_db_usage_user" and query aggregates on result of table type "project_db_usage_agg_user" */
  compute_project_agg_db_usage_user_aggregate: Project_Db_Usage_Agg_User_Aggregate;
  /** execute function "compute_project_agg_usage" which returns "project_data_usage_agg" */
  compute_project_agg_usage: Array<Project_Data_Usage_Agg>;
  /** execute function "compute_project_agg_usage" and query aggregates on result of table type "project_data_usage_agg" */
  compute_project_agg_usage_aggregate: Project_Data_Usage_Agg_Aggregate;
  /** execute function "compute_project_agg_usage_prometheus" which returns "project_data_usage_agg" */
  compute_project_agg_usage_prometheus: Array<Project_Data_Usage_Agg>;
  /** execute function "compute_project_agg_usage_prometheus" and query aggregates on result of table type "project_data_usage_agg" */
  compute_project_agg_usage_prometheus_aggregate: Project_Data_Usage_Agg_Aggregate;
  /** execute function "compute_project_agg_usage_prometheus_user" which returns "project_data_usage_prometheus_agg_user" */
  compute_project_agg_usage_prometheus_user: Array<
    Project_Data_Usage_Prometheus_Agg_User
  >;
  /** execute function "compute_project_agg_usage_prometheus_user" and query aggregates on result of table type "project_data_usage_prometheus_agg_user" */
  compute_project_agg_usage_prometheus_user_aggregate: Project_Data_Usage_Prometheus_Agg_User_Aggregate;
  /** execute function "compute_project_agg_usage_user" which returns "project_data_usage_agg_user" */
  compute_project_agg_usage_user: Array<Project_Data_Usage_Agg_User>;
  /** execute function "compute_project_agg_usage_user" and query aggregates on result of table type "project_data_usage_agg_user" */
  compute_project_agg_usage_user_aggregate: Project_Data_Usage_Agg_User_Aggregate;
  /** fetch data from the table: "compute_unit_config" */
  compute_unit_config: Array<Compute_Unit_Config>;
  /** fetch aggregated fields from the table: "compute_unit_config" */
  compute_unit_config_aggregate: Compute_Unit_Config_Aggregate;
  /** fetch data from the table: "compute_unit_config" using primary key columns */
  compute_unit_config_by_pk?: Maybe<Compute_Unit_Config>;
  /** fetch data from the table: "config" */
  config: Array<Config>;
  /** fetch aggregated fields from the table: "config" */
  config_aggregate: Config_Aggregate;
  /** fetch data from the table: "config" using primary key columns */
  config_by_pk?: Maybe<Config>;
  /** fetch data from the table: "config_status" */
  config_status: Array<Config_Status>;
  /** fetch aggregated fields from the table: "config_status" */
  config_status_aggregate: Config_Status_Aggregate;
  /** fetch data from the table: "config_status" using primary key columns */
  config_status_by_pk?: Maybe<Config_Status>;
  /** fetch data from the table: "connector_config" */
  connector_config: Array<Connector_Config>;
  /** fetch aggregated fields from the table: "connector_config" */
  connector_config_aggregate: Connector_Config_Aggregate;
  /** fetch data from the table: "connector_config" using primary key columns */
  connector_config_by_pk?: Maybe<Connector_Config>;
  /** fetch data from the table: "connector_deployments" */
  connector_deployments: Array<Connector_Deployments>;
  /** fetch aggregated fields from the table: "connector_deployments" */
  connector_deployments_aggregate: Connector_Deployments_Aggregate;
  /** fetch data from the table: "connector_deployments" using primary key columns */
  connector_deployments_by_pk?: Maybe<Connector_Deployments>;
  continent?: Maybe<Continent>;
  continents: Array<Continent>;
  countries: Array<Country>;
  country?: Maybe<Country>;
  /** An array relationship */
  coupon: Array<Coupon>;
  /** An aggregate relationship */
  coupon_aggregate: Coupon_Aggregate;
  /** fetch data from the table: "coupon" using primary key columns */
  coupon_by_pk?: Maybe<Coupon>;
  /** fetch data from the table: "coupon_duration" */
  coupon_duration: Array<Coupon_Duration>;
  /** fetch aggregated fields from the table: "coupon_duration" */
  coupon_duration_aggregate: Coupon_Duration_Aggregate;
  /** fetch data from the table: "coupon_duration" using primary key columns */
  coupon_duration_by_pk?: Maybe<Coupon_Duration>;
  /** fetch data from the table: "coupon_type" */
  coupon_type: Array<Coupon_Type>;
  /** fetch aggregated fields from the table: "coupon_type" */
  coupon_type_aggregate: Coupon_Type_Aggregate;
  /** fetch data from the table: "coupon_type" using primary key columns */
  coupon_type_by_pk?: Maybe<Coupon_Type>;
  /** fetch data from the table: "custom_domain" */
  custom_domain: Array<Custom_Domain>;
  /** fetch aggregated fields from the table: "custom_domain" */
  custom_domain_aggregate: Custom_Domain_Aggregate;
  /** fetch data from the table: "custom_domain" using primary key columns */
  custom_domain_by_pk?: Maybe<Custom_Domain>;
  /** fetch data from the table: "custom_domain_cloudflare" */
  custom_domain_cloudflare: Array<Custom_Domain_Cloudflare>;
  /** fetch aggregated fields from the table: "custom_domain_cloudflare" */
  custom_domain_cloudflare_aggregate: Custom_Domain_Cloudflare_Aggregate;
  /** fetch data from the table: "custom_domain_cloudflare" using primary key columns */
  custom_domain_cloudflare_by_pk?: Maybe<Custom_Domain_Cloudflare>;
  /** fetch data from the table: "custom_domain_cloudflare_dns" */
  custom_domain_cloudflare_dns: Array<Custom_Domain_Cloudflare_Dns>;
  /** fetch aggregated fields from the table: "custom_domain_cloudflare_dns" */
  custom_domain_cloudflare_dns_aggregate: Custom_Domain_Cloudflare_Dns_Aggregate;
  /** fetch data from the table: "custom_domain_cloudflare_dns" using primary key columns */
  custom_domain_cloudflare_dns_by_pk?: Maybe<Custom_Domain_Cloudflare_Dns>;
  /** fetch data from the table: "customer_usage" */
  customer_usage: Array<Customer_Usage>;
  /** fetch aggregated fields from the table: "customer_usage" */
  customer_usage_aggregate: Customer_Usage_Aggregate;
  /** fetch data from the table: "customer_usage" using primary key columns */
  customer_usage_by_pk?: Maybe<Customer_Usage>;
  /** fetch data from the table: "data_connector_type" */
  data_connector_type: Array<Data_Connector_Type>;
  /** fetch aggregated fields from the table: "data_connector_type" */
  data_connector_type_aggregate: Data_Connector_Type_Aggregate;
  /** fetch data from the table: "data_connector_type" using primary key columns */
  data_connector_type_by_pk?: Maybe<Data_Connector_Type>;
  /** fetch data from the table: "datadog_config" */
  datadog_config: Array<Datadog_Config>;
  /** fetch aggregated fields from the table: "datadog_config" */
  datadog_config_aggregate: Datadog_Config_Aggregate;
  /** fetch data from the table: "datadog_config" using primary key columns */
  datadog_config_by_pk?: Maybe<Datadog_Config>;
  /** fetch data from the table: "db_latency" */
  db_latency: Array<Db_Latency>;
  /** fetch aggregated fields from the table: "db_latency" */
  db_latency_aggregate: Db_Latency_Aggregate;
  /** fetch data from the table: "db_latency" using primary key columns */
  db_latency_by_pk?: Maybe<Db_Latency>;
  ddnGetInvoiceAndReceiptURLs?: Maybe<DdnInvoiceAndReceiptUrLsResponse>;
  ddnHasuraSecretList: Array<HasuraSecretList>;
  ddnInitiateStripeAddCardProcess: DdnClientSecretInfo;
  ddnIsCollaborationRequestPresent?: Maybe<IsCollaborationRequestPresentOutput>;
  ddnIsProjectCreationLimitReached?: Maybe<
    DdnIsProjectCreationLimitReachedOutput
  >;
  /** Fetch the total billable cost of the project for a month */
  ddnProjectEntitiesCostPerMonth: ProjectEntitiesCostPerMonthOutput;
  /** fetch data from the table: "ddn.applied_build_changelog" */
  ddn_applied_build_changelog: Array<Ddn_Applied_Build_Changelog>;
  /** fetch aggregated fields from the table: "ddn.applied_build_changelog" */
  ddn_applied_build_changelog_aggregate: Ddn_Applied_Build_Changelog_Aggregate;
  /** fetch data from the table: "ddn.applied_build_changelog" using primary key columns */
  ddn_applied_build_changelog_by_pk?: Maybe<Ddn_Applied_Build_Changelog>;
  /** fetch data from the table: "ddn.applied_subgraph_build_changelog" */
  ddn_applied_subgraph_build_changelog: Array<
    Ddn_Applied_Subgraph_Build_Changelog
  >;
  /** fetch aggregated fields from the table: "ddn.applied_subgraph_build_changelog" */
  ddn_applied_subgraph_build_changelog_aggregate: Ddn_Applied_Subgraph_Build_Changelog_Aggregate;
  /** fetch data from the table: "ddn.applied_subgraph_build_changelog" using primary key columns */
  ddn_applied_subgraph_build_changelog_by_pk?: Maybe<
    Ddn_Applied_Subgraph_Build_Changelog
  >;
  /** fetch data from the table: "ddn.auto_approve_project_request" */
  ddn_auto_approve_project_request: Array<Ddn_Auto_Approve_Project_Request>;
  /** fetch aggregated fields from the table: "ddn.auto_approve_project_request" */
  ddn_auto_approve_project_request_aggregate: Ddn_Auto_Approve_Project_Request_Aggregate;
  /** fetch data from the table: "ddn.auto_approve_project_request" using primary key columns */
  ddn_auto_approve_project_request_by_pk?: Maybe<
    Ddn_Auto_Approve_Project_Request
  >;
  /** fetch data from the table: "ddn.build" */
  ddn_build: Array<Ddn_Build>;
  /** fetch aggregated fields from the table: "ddn.build" */
  ddn_build_aggregate: Ddn_Build_Aggregate;
  /** fetch data from the table: "ddn.build" using primary key columns */
  ddn_build_by_pk?: Maybe<Ddn_Build>;
  /** fetch data from the table: "ddn.build_details" */
  ddn_build_details: Array<Ddn_Build_Details>;
  /** fetch aggregated fields from the table: "ddn.build_details" */
  ddn_build_details_aggregate: Ddn_Build_Details_Aggregate;
  /** fetch data from the table: "ddn.build_details" using primary key columns */
  ddn_build_details_by_pk?: Maybe<Ddn_Build_Details>;
  /** fetch data from the table: "ddn.build_lock" */
  ddn_build_lock: Array<Ddn_Build_Lock>;
  /** fetch aggregated fields from the table: "ddn.build_lock" */
  ddn_build_lock_aggregate: Ddn_Build_Lock_Aggregate;
  /** fetch data from the table: "ddn.build_lock" using primary key columns */
  ddn_build_lock_by_pk?: Maybe<Ddn_Build_Lock>;
  /** fetch data from the table: "ddn.build_model_count" */
  ddn_build_model_count: Array<Ddn_Build_Model_Count>;
  /** fetch aggregated fields from the table: "ddn.build_model_count" */
  ddn_build_model_count_aggregate: Ddn_Build_Model_Count_Aggregate;
  /** fetch data from the table: "ddn.build_revision" */
  ddn_build_revision: Array<Ddn_Build_Revision>;
  /** fetch aggregated fields from the table: "ddn.build_revision" */
  ddn_build_revision_aggregate: Ddn_Build_Revision_Aggregate;
  /** fetch data from the table: "ddn.build_revision" using primary key columns */
  ddn_build_revision_by_pk?: Maybe<Ddn_Build_Revision>;
  /** fetch data from the table: "ddn.build_revision_status" */
  ddn_build_revision_status: Array<Ddn_Build_Revision_Status>;
  /** fetch aggregated fields from the table: "ddn.build_revision_status" */
  ddn_build_revision_status_aggregate: Ddn_Build_Revision_Status_Aggregate;
  /** fetch data from the table: "ddn.build_revision_status" using primary key columns */
  ddn_build_revision_status_by_pk?: Maybe<Ddn_Build_Revision_Status>;
  /** fetch data from the table: "ddn.build_sync_status" */
  ddn_build_sync_status: Array<Ddn_Build_Sync_Status>;
  /** fetch aggregated fields from the table: "ddn.build_sync_status" */
  ddn_build_sync_status_aggregate: Ddn_Build_Sync_Status_Aggregate;
  /** fetch data from the table: "ddn.build_sync_status" using primary key columns */
  ddn_build_sync_status_by_pk?: Maybe<Ddn_Build_Sync_Status>;
  /** fetch data from the table: "ddn.build_sync_worker" */
  ddn_build_sync_worker: Array<Ddn_Build_Sync_Worker>;
  /** fetch aggregated fields from the table: "ddn.build_sync_worker" */
  ddn_build_sync_worker_aggregate: Ddn_Build_Sync_Worker_Aggregate;
  /** fetch data from the table: "ddn.build_sync_worker" using primary key columns */
  ddn_build_sync_worker_by_pk?: Maybe<Ddn_Build_Sync_Worker>;
  /** fetch data from the table: "ddn.collaboration_invitee_project_view" */
  ddn_collaboration_invitee_project_view: Array<
    Ddn_Collaboration_Invitee_Project_View
  >;
  /** fetch aggregated fields from the table: "ddn.collaboration_invitee_project_view" */
  ddn_collaboration_invitee_project_view_aggregate: Ddn_Collaboration_Invitee_Project_View_Aggregate;
  /** fetch data from the table: "ddn.connector_deployment" */
  ddn_connector_deployment: Array<Ddn_Connector_Deployment>;
  /** fetch aggregated fields from the table: "ddn.connector_deployment" */
  ddn_connector_deployment_aggregate: Ddn_Connector_Deployment_Aggregate;
  /** fetch data from the table: "ddn.connector_deployment" using primary key columns */
  ddn_connector_deployment_by_pk?: Maybe<Ddn_Connector_Deployment>;
  /** fetch data from the table: "ddn.ddn" */
  ddn_ddn: Array<Ddn_Ddn>;
  /** fetch aggregated fields from the table: "ddn.ddn" */
  ddn_ddn_aggregate: Ddn_Ddn_Aggregate;
  /** fetch data from the table: "ddn.ddn" using primary key columns */
  ddn_ddn_by_pk?: Maybe<Ddn_Ddn>;
  /** fetch data from the table: "ddn.environment" */
  ddn_environment: Array<Ddn_Environment>;
  /** fetch aggregated fields from the table: "ddn.environment" */
  ddn_environment_aggregate: Ddn_Environment_Aggregate;
  /** fetch data from the table: "ddn.environment" using primary key columns */
  ddn_environment_by_pk?: Maybe<Ddn_Environment>;
  /** fetch data from the table: "ddn.generate_metadata_artifacts_log" */
  ddn_generate_metadata_artifacts_log: Array<
    Ddn_Generate_Metadata_Artifacts_Log
  >;
  /** fetch aggregated fields from the table: "ddn.generate_metadata_artifacts_log" */
  ddn_generate_metadata_artifacts_log_aggregate: Ddn_Generate_Metadata_Artifacts_Log_Aggregate;
  /** fetch data from the table: "ddn.generate_metadata_artifacts_log" using primary key columns */
  ddn_generate_metadata_artifacts_log_by_pk?: Maybe<
    Ddn_Generate_Metadata_Artifacts_Log
  >;
  /** execute function "ddn.get_token_access_level" which returns "ddn.token_access_level_output" */
  ddn_get_token_access_level?: Maybe<Ddn_Token_Access_Level_Output>;
  /** execute function "ddn.get_token_access_level" and query aggregates on result of table type "ddn.token_access_level_output" */
  ddn_get_token_access_level_aggregate: Ddn_Token_Access_Level_Output_Aggregate;
  /** fetch data from the table: "ddn.invoice" */
  ddn_invoice: Array<Ddn_Invoice>;
  /** fetch aggregated fields from the table: "ddn.invoice" */
  ddn_invoice_aggregate: Ddn_Invoice_Aggregate;
  /** fetch data from the table: "ddn.invoice" using primary key columns */
  ddn_invoice_by_pk?: Maybe<Ddn_Invoice>;
  /** fetch data from the table: "ddn.invoice_item" */
  ddn_invoice_item: Array<Ddn_Invoice_Item>;
  /** fetch aggregated fields from the table: "ddn.invoice_item" */
  ddn_invoice_item_aggregate: Ddn_Invoice_Item_Aggregate;
  /** fetch data from the table: "ddn.invoice_item" using primary key columns */
  ddn_invoice_item_by_pk?: Maybe<Ddn_Invoice_Item>;
  /** fetch data from the table: "ddn.plan_entitlement_access" */
  ddn_plan_entitlement_access: Array<Ddn_Plan_Entitlement_Access>;
  /** fetch aggregated fields from the table: "ddn.plan_entitlement_access" */
  ddn_plan_entitlement_access_aggregate: Ddn_Plan_Entitlement_Access_Aggregate;
  /** fetch data from the table: "ddn.plan_entitlement_access" using primary key columns */
  ddn_plan_entitlement_access_by_pk?: Maybe<Ddn_Plan_Entitlement_Access>;
  /** fetch data from the table: "ddn.plans" */
  ddn_plans: Array<Ddn_Plans>;
  /** fetch aggregated fields from the table: "ddn.plans" */
  ddn_plans_aggregate: Ddn_Plans_Aggregate;
  /** fetch data from the table: "ddn.plans" using primary key columns */
  ddn_plans_by_pk?: Maybe<Ddn_Plans>;
  /** fetch data from the table: "ddn.private_ddn" */
  ddn_private_ddn: Array<Ddn_Private_Ddn>;
  /** fetch aggregated fields from the table: "ddn.private_ddn" */
  ddn_private_ddn_aggregate: Ddn_Private_Ddn_Aggregate;
  /** fetch data from the table: "ddn.private_ddn" using primary key columns */
  ddn_private_ddn_by_pk?: Maybe<Ddn_Private_Ddn>;
  /** fetch data from the table: "ddn.private_ddn_region" */
  ddn_private_ddn_region: Array<Ddn_Private_Ddn_Region>;
  /** fetch aggregated fields from the table: "ddn.private_ddn_region" */
  ddn_private_ddn_region_aggregate: Ddn_Private_Ddn_Region_Aggregate;
  /** fetch data from the table: "ddn.private_ddn_region" using primary key columns */
  ddn_private_ddn_region_by_pk?: Maybe<Ddn_Private_Ddn_Region>;
  /** fetch data from the table: "ddn.project_access_level" */
  ddn_project_access_level: Array<Ddn_Project_Access_Level>;
  /** fetch aggregated fields from the table: "ddn.project_access_level" */
  ddn_project_access_level_aggregate: Ddn_Project_Access_Level_Aggregate;
  /** fetch data from the table: "ddn.project_access_level" using primary key columns */
  ddn_project_access_level_by_pk?: Maybe<Ddn_Project_Access_Level>;
  /** fetch data from the table: "ddn.project_active_status_changelog" */
  ddn_project_active_status_changelog: Array<
    Ddn_Project_Active_Status_Changelog
  >;
  /** fetch aggregated fields from the table: "ddn.project_active_status_changelog" */
  ddn_project_active_status_changelog_aggregate: Ddn_Project_Active_Status_Changelog_Aggregate;
  /** fetch data from the table: "ddn.project_active_status_changelog" using primary key columns */
  ddn_project_active_status_changelog_by_pk?: Maybe<
    Ddn_Project_Active_Status_Changelog
  >;
  /** fetch data from the table: "ddn.project_active_status_reason" */
  ddn_project_active_status_reason: Array<Ddn_Project_Active_Status_Reason>;
  /** fetch aggregated fields from the table: "ddn.project_active_status_reason" */
  ddn_project_active_status_reason_aggregate: Ddn_Project_Active_Status_Reason_Aggregate;
  /** fetch data from the table: "ddn.project_active_status_reason" using primary key columns */
  ddn_project_active_status_reason_by_pk?: Maybe<
    Ddn_Project_Active_Status_Reason
  >;
  /** fetch data from the table: "ddn.project_entitlement_access" */
  ddn_project_entitlement_access: Array<Ddn_Project_Entitlement_Access>;
  /** fetch aggregated fields from the table: "ddn.project_entitlement_access" */
  ddn_project_entitlement_access_aggregate: Ddn_Project_Entitlement_Access_Aggregate;
  /** fetch data from the table: "ddn.project_entitlement_access" using primary key columns */
  ddn_project_entitlement_access_by_pk?: Maybe<Ddn_Project_Entitlement_Access>;
  /** fetch data from the table: "ddn.project_entitlement_catalogue" */
  ddn_project_entitlement_catalogue: Array<Ddn_Project_Entitlement_Catalogue>;
  /** fetch aggregated fields from the table: "ddn.project_entitlement_catalogue" */
  ddn_project_entitlement_catalogue_aggregate: Ddn_Project_Entitlement_Catalogue_Aggregate;
  /** fetch data from the table: "ddn.project_entitlement_catalogue" using primary key columns */
  ddn_project_entitlement_catalogue_by_pk?: Maybe<
    Ddn_Project_Entitlement_Catalogue
  >;
  /** fetch data from the table: "ddn.project_entitlement_types" */
  ddn_project_entitlement_types: Array<Ddn_Project_Entitlement_Types>;
  /** fetch aggregated fields from the table: "ddn.project_entitlement_types" */
  ddn_project_entitlement_types_aggregate: Ddn_Project_Entitlement_Types_Aggregate;
  /** fetch data from the table: "ddn.project_entitlement_types" using primary key columns */
  ddn_project_entitlement_types_by_pk?: Maybe<Ddn_Project_Entitlement_Types>;
  /** fetch data from the table: "ddn.project_plan_changelog" */
  ddn_project_plan_changelog: Array<Ddn_Project_Plan_Changelog>;
  /** fetch aggregated fields from the table: "ddn.project_plan_changelog" */
  ddn_project_plan_changelog_aggregate: Ddn_Project_Plan_Changelog_Aggregate;
  /** fetch data from the table: "ddn.project_plan_changelog" using primary key columns */
  ddn_project_plan_changelog_by_pk?: Maybe<Ddn_Project_Plan_Changelog>;
  /** fetch data from the table: "ddn.project_request_access" */
  ddn_project_request_access: Array<Ddn_Project_Request_Access>;
  /** fetch aggregated fields from the table: "ddn.project_request_access" */
  ddn_project_request_access_aggregate: Ddn_Project_Request_Access_Aggregate;
  /** fetch data from the table: "ddn.project_request_access" using primary key columns */
  ddn_project_request_access_by_pk?: Maybe<Ddn_Project_Request_Access>;
  /** An array relationship */
  ddn_projects: Array<Ddn_Projects>;
  /** An aggregate relationship */
  ddn_projects_aggregate: Ddn_Projects_Aggregate;
  /** fetch data from the table: "ddn.projects" using primary key columns */
  ddn_projects_by_pk?: Maybe<Ddn_Projects>;
  /** execute function "ddn.search_tunnel_cluster" which returns "ddn.tunnel_cluster" */
  ddn_search_tunnel_cluster: Array<Ddn_Tunnel_Cluster>;
  /** execute function "ddn.search_tunnel_cluster" and query aggregates on result of table type "ddn.tunnel_cluster" */
  ddn_search_tunnel_cluster_aggregate: Ddn_Tunnel_Cluster_Aggregate;
  /** fetch data from the table: "ddn.secret_key" */
  ddn_secret_key: Array<Ddn_Secret_Key>;
  /** fetch aggregated fields from the table: "ddn.secret_key" */
  ddn_secret_key_aggregate: Ddn_Secret_Key_Aggregate;
  /** fetch data from the table: "ddn.secret_key" using primary key columns */
  ddn_secret_key_by_pk?: Maybe<Ddn_Secret_Key>;
  /** fetch data from the table: "ddn.stripe_subscription" */
  ddn_stripe_subscription: Array<Ddn_Stripe_Subscription>;
  /** fetch aggregated fields from the table: "ddn.stripe_subscription" */
  ddn_stripe_subscription_aggregate: Ddn_Stripe_Subscription_Aggregate;
  /** fetch data from the table: "ddn.stripe_subscription" using primary key columns */
  ddn_stripe_subscription_by_pk?: Maybe<Ddn_Stripe_Subscription>;
  /** fetch data from the table: "ddn.stripe_webhook_events" */
  ddn_stripe_webhook_events: Array<Ddn_Stripe_Webhook_Events>;
  /** fetch aggregated fields from the table: "ddn.stripe_webhook_events" */
  ddn_stripe_webhook_events_aggregate: Ddn_Stripe_Webhook_Events_Aggregate;
  /** fetch data from the table: "ddn.stripe_webhook_events" using primary key columns */
  ddn_stripe_webhook_events_by_pk?: Maybe<Ddn_Stripe_Webhook_Events>;
  /** fetch data from the table: "ddn.subgraph" */
  ddn_subgraph: Array<Ddn_Subgraph>;
  /** fetch aggregated fields from the table: "ddn.subgraph" */
  ddn_subgraph_aggregate: Ddn_Subgraph_Aggregate;
  /** fetch data from the table: "ddn.subgraph_build" */
  ddn_subgraph_build: Array<Ddn_Subgraph_Build>;
  /** fetch aggregated fields from the table: "ddn.subgraph_build" */
  ddn_subgraph_build_aggregate: Ddn_Subgraph_Build_Aggregate;
  /** fetch data from the table: "ddn.subgraph_build" using primary key columns */
  ddn_subgraph_build_by_pk?: Maybe<Ddn_Subgraph_Build>;
  /** fetch data from the table: "ddn.subgraph" using primary key columns */
  ddn_subgraph_by_pk?: Maybe<Ddn_Subgraph>;
  /** fetch data from the table: "ddn.team" */
  ddn_team: Array<Ddn_Team>;
  /** fetch aggregated fields from the table: "ddn.team" */
  ddn_team_aggregate: Ddn_Team_Aggregate;
  /** fetch data from the table: "ddn.team" using primary key columns */
  ddn_team_by_pk?: Maybe<Ddn_Team>;
  /** fetch data from the table: "ddn.team_invitation" */
  ddn_team_invitation: Array<Ddn_Team_Invitation>;
  /** fetch aggregated fields from the table: "ddn.team_invitation" */
  ddn_team_invitation_aggregate: Ddn_Team_Invitation_Aggregate;
  /** fetch data from the table: "ddn.team_invitation" using primary key columns */
  ddn_team_invitation_by_pk?: Maybe<Ddn_Team_Invitation>;
  /** fetch data from the table: "ddn.team_invitation_status" */
  ddn_team_invitation_status: Array<Ddn_Team_Invitation_Status>;
  /** fetch aggregated fields from the table: "ddn.team_invitation_status" */
  ddn_team_invitation_status_aggregate: Ddn_Team_Invitation_Status_Aggregate;
  /** fetch data from the table: "ddn.team_invitation_status" using primary key columns */
  ddn_team_invitation_status_by_pk?: Maybe<Ddn_Team_Invitation_Status>;
  /** fetch data from the table: "ddn.team_kind" */
  ddn_team_kind: Array<Ddn_Team_Kind>;
  /** fetch aggregated fields from the table: "ddn.team_kind" */
  ddn_team_kind_aggregate: Ddn_Team_Kind_Aggregate;
  /** fetch data from the table: "ddn.team_kind" using primary key columns */
  ddn_team_kind_by_pk?: Maybe<Ddn_Team_Kind>;
  /** fetch data from the table: "ddn.team_membership" */
  ddn_team_membership: Array<Ddn_Team_Membership>;
  /** fetch aggregated fields from the table: "ddn.team_membership" */
  ddn_team_membership_aggregate: Ddn_Team_Membership_Aggregate;
  /** fetch data from the table: "ddn.team_membership" using primary key columns */
  ddn_team_membership_by_pk?: Maybe<Ddn_Team_Membership>;
  /** fetch data from the table: "ddn.team_project_access" */
  ddn_team_project_access: Array<Ddn_Team_Project_Access>;
  /** fetch aggregated fields from the table: "ddn.team_project_access" */
  ddn_team_project_access_aggregate: Ddn_Team_Project_Access_Aggregate;
  /** fetch data from the table: "ddn.team_project_access" using primary key columns */
  ddn_team_project_access_by_pk?: Maybe<Ddn_Team_Project_Access>;
  /** fetch data from the table: "ddn.token_access_level_output" */
  ddn_token_access_level_output: Array<Ddn_Token_Access_Level_Output>;
  /** fetch aggregated fields from the table: "ddn.token_access_level_output" */
  ddn_token_access_level_output_aggregate: Ddn_Token_Access_Level_Output_Aggregate;
  /** fetch data from the table: "ddn.tunnel" */
  ddn_tunnel: Array<Ddn_Tunnel>;
  /** fetch aggregated fields from the table: "ddn.tunnel" */
  ddn_tunnel_aggregate: Ddn_Tunnel_Aggregate;
  /** fetch data from the table: "ddn.tunnel" using primary key columns */
  ddn_tunnel_by_pk?: Maybe<Ddn_Tunnel>;
  /** fetch data from the table: "ddn.tunnel_cluster" */
  ddn_tunnel_cluster: Array<Ddn_Tunnel_Cluster>;
  /** fetch aggregated fields from the table: "ddn.tunnel_cluster" */
  ddn_tunnel_cluster_aggregate: Ddn_Tunnel_Cluster_Aggregate;
  /** fetch data from the table: "ddn.tunnel_cluster" using primary key columns */
  ddn_tunnel_cluster_by_pk?: Maybe<Ddn_Tunnel_Cluster>;
  /** fetch data from the table: "ddn.tunnel_http" */
  ddn_tunnel_http: Array<Ddn_Tunnel_Http>;
  /** fetch aggregated fields from the table: "ddn.tunnel_http" */
  ddn_tunnel_http_aggregate: Ddn_Tunnel_Http_Aggregate;
  /** fetch data from the table: "ddn.tunnel_http" using primary key columns */
  ddn_tunnel_http_by_pk?: Maybe<Ddn_Tunnel_Http>;
  /** fetch data from the table: "ddn.tunnel_http_cluster" */
  ddn_tunnel_http_cluster: Array<Ddn_Tunnel_Http_Cluster>;
  /** fetch aggregated fields from the table: "ddn.tunnel_http_cluster" */
  ddn_tunnel_http_cluster_aggregate: Ddn_Tunnel_Http_Cluster_Aggregate;
  /** fetch data from the table: "ddn.tunnel_http_cluster" using primary key columns */
  ddn_tunnel_http_cluster_by_pk?: Maybe<Ddn_Tunnel_Http_Cluster>;
  /** fetch data from the table: "ddn.user_project_access" */
  ddn_user_project_access: Array<Ddn_User_Project_Access>;
  /** fetch aggregated fields from the table: "ddn.user_project_access" */
  ddn_user_project_access_aggregate: Ddn_User_Project_Access_Aggregate;
  /** fetch data from the table: "ddn.v3_customer" */
  ddn_v3_customer: Array<Ddn_V3_Customer>;
  /** fetch aggregated fields from the table: "ddn.v3_customer" */
  ddn_v3_customer_aggregate: Ddn_V3_Customer_Aggregate;
  /** fetch data from the table: "dedicated_cloud_bills" */
  dedicated_cloud_bills: Array<Dedicated_Cloud_Bills>;
  /** fetch aggregated fields from the table: "dedicated_cloud_bills" */
  dedicated_cloud_bills_aggregate: Dedicated_Cloud_Bills_Aggregate;
  /** fetch data from the table: "dedicated_cloud_bills" using primary key columns */
  dedicated_cloud_bills_by_pk?: Maybe<Dedicated_Cloud_Bills>;
  /** fetch data from the table: "dedicated_cloud_bills_details" */
  dedicated_cloud_bills_details: Array<Dedicated_Cloud_Bills_Details>;
  /** fetch aggregated fields from the table: "dedicated_cloud_bills_details" */
  dedicated_cloud_bills_details_aggregate: Dedicated_Cloud_Bills_Details_Aggregate;
  /** fetch data from the table: "dedicated_cloud_bills_details" using primary key columns */
  dedicated_cloud_bills_details_by_pk?: Maybe<Dedicated_Cloud_Bills_Details>;
  /** fetch data from the table: "dedicated_cloud_commitments" */
  dedicated_cloud_commitments: Array<Dedicated_Cloud_Commitments>;
  /** fetch aggregated fields from the table: "dedicated_cloud_commitments" */
  dedicated_cloud_commitments_aggregate: Dedicated_Cloud_Commitments_Aggregate;
  /** fetch data from the table: "dedicated_cloud_commitments" using primary key columns */
  dedicated_cloud_commitments_by_pk?: Maybe<Dedicated_Cloud_Commitments>;
  /** fetch data from the table: "dedicated_vpc" */
  dedicated_vpc: Array<Dedicated_Vpc>;
  /** fetch aggregated fields from the table: "dedicated_vpc" */
  dedicated_vpc_aggregate: Dedicated_Vpc_Aggregate;
  /** fetch data from the table: "dedicated_vpc" using primary key columns */
  dedicated_vpc_by_pk?: Maybe<Dedicated_Vpc>;
  /** fetch data from the table: "dedicated_vpc_owner" */
  dedicated_vpc_owner: Array<Dedicated_Vpc_Owner>;
  /** fetch aggregated fields from the table: "dedicated_vpc_owner" */
  dedicated_vpc_owner_aggregate: Dedicated_Vpc_Owner_Aggregate;
  /** fetch data from the table: "delete_user" */
  delete_user: Array<Delete_User>;
  /** fetch aggregated fields from the table: "delete_user" */
  delete_user_aggregate: Delete_User_Aggregate;
  /** fetch data from the table: "delete_user" using primary key columns */
  delete_user_by_pk?: Maybe<Delete_User>;
  /** fetch data from the table: "delete_user_status" */
  delete_user_status: Array<Delete_User_Status>;
  /** fetch aggregated fields from the table: "delete_user_status" */
  delete_user_status_aggregate: Delete_User_Status_Aggregate;
  /** fetch data from the table: "delete_user_status" using primary key columns */
  delete_user_status_by_pk?: Maybe<Delete_User_Status>;
  /** fetch data from the table: "delete_user_tasks" */
  delete_user_tasks: Array<Delete_User_Tasks>;
  /** fetch aggregated fields from the table: "delete_user_tasks" */
  delete_user_tasks_aggregate: Delete_User_Tasks_Aggregate;
  /** fetch data from the table: "delete_user_tasks" using primary key columns */
  delete_user_tasks_by_pk?: Maybe<Delete_User_Tasks>;
  /** fetch data from the table: "email_log" */
  email_log: Array<Email_Log>;
  /** fetch aggregated fields from the table: "email_log" */
  email_log_aggregate: Email_Log_Aggregate;
  /** fetch data from the table: "email_log" using primary key columns */
  email_log_by_pk?: Maybe<Email_Log>;
  /** fetch data from the table: "enterprise_users" */
  enterprise_users: Array<Enterprise_Users>;
  /** fetch aggregated fields from the table: "enterprise_users" */
  enterprise_users_aggregate: Enterprise_Users_Aggregate;
  /** fetch data from the table: "enterprise_users" using primary key columns */
  enterprise_users_by_pk?: Maybe<Enterprise_Users>;
  /** fetch data from the table: "enterprise_users_domain" */
  enterprise_users_domain: Array<Enterprise_Users_Domain>;
  /** fetch aggregated fields from the table: "enterprise_users_domain" */
  enterprise_users_domain_aggregate: Enterprise_Users_Domain_Aggregate;
  /** fetch data from the table: "enterprise_users_domain" using primary key columns */
  enterprise_users_domain_by_pk?: Maybe<Enterprise_Users_Domain>;
  /** fetch data from the table: "experiments" */
  experiments: Array<Experiments>;
  /** fetch aggregated fields from the table: "experiments" */
  experiments_aggregate: Experiments_Aggregate;
  /** fetch data from the table: "experiments" using primary key columns */
  experiments_by_pk?: Maybe<Experiments>;
  /** fetch data from the table: "experiments_cohort" */
  experiments_cohort: Array<Experiments_Cohort>;
  /** fetch aggregated fields from the table: "experiments_cohort" */
  experiments_cohort_aggregate: Experiments_Cohort_Aggregate;
  /** fetch data from the table: "experiments_cohort" using primary key columns */
  experiments_cohort_by_pk?: Maybe<Experiments_Cohort>;
  /** fetch data from the table: "experiments_config" */
  experiments_config: Array<Experiments_Config>;
  /** fetch aggregated fields from the table: "experiments_config" */
  experiments_config_aggregate: Experiments_Config_Aggregate;
  /** fetch data from the table: "experiments_config" using primary key columns */
  experiments_config_by_pk?: Maybe<Experiments_Config>;
  /** fetch data from the table: "feature" */
  feature: Array<Feature>;
  /** fetch data from the table: "feature_access" */
  feature_access: Array<Feature_Access>;
  /** fetch aggregated fields from the table: "feature_access" */
  feature_access_aggregate: Feature_Access_Aggregate;
  /** fetch data from the table: "feature_access" using primary key columns */
  feature_access_by_pk?: Maybe<Feature_Access>;
  /** fetch aggregated fields from the table: "feature" */
  feature_aggregate: Feature_Aggregate;
  /** fetch data from the table: "feature" using primary key columns */
  feature_by_pk?: Maybe<Feature>;
  /** fetch data from the table: "feature_config" */
  feature_config: Array<Feature_Config>;
  /** fetch aggregated fields from the table: "feature_config" */
  feature_config_aggregate: Feature_Config_Aggregate;
  /** fetch data from the table: "feature_config" using primary key columns */
  feature_config_by_pk?: Maybe<Feature_Config>;
  fetchDedicatedCloudClusterBills?: Maybe<
    Array<DedicatedCloudClusterBillsOutput>
  >;
  fetchPendingInvitations: Array<UserInvitation>;
  fetchPendingOwnershipInvitations: Array<ProjectOwnershipInvitation>;
  fetchUserFacingMetadata?: Maybe<FetchUserFacingMetadataResponse>;
  fetchUserSupportTicketDetails?: Maybe<Array<SupportTicketDetails>>;
  /** fetch data from the table: "gateway_ami" */
  gateway_ami: Array<Gateway_Ami>;
  /** fetch aggregated fields from the table: "gateway_ami" */
  gateway_ami_aggregate: Gateway_Ami_Aggregate;
  /** fetch data from the table: "gateway_ami" using primary key columns */
  gateway_ami_by_pk?: Maybe<Gateway_Ami>;
  /** fetch data from the table: "gateway_cluster" */
  gateway_cluster: Array<Gateway_Cluster>;
  /** fetch aggregated fields from the table: "gateway_cluster" */
  gateway_cluster_aggregate: Gateway_Cluster_Aggregate;
  /** fetch data from the table: "gateway_cluster" using primary key columns */
  gateway_cluster_by_pk?: Maybe<Gateway_Cluster>;
  /** fetch data from the table: "gateway_cluster_tenants" */
  gateway_cluster_tenants: Array<Gateway_Cluster_Tenants>;
  /** fetch aggregated fields from the table: "gateway_cluster_tenants" */
  gateway_cluster_tenants_aggregate: Gateway_Cluster_Tenants_Aggregate;
  /** fetch data from the table: "gateway_worker" */
  gateway_worker: Array<Gateway_Worker>;
  /** fetch aggregated fields from the table: "gateway_worker" */
  gateway_worker_aggregate: Gateway_Worker_Aggregate;
  /** fetch data from the table: "gateway_worker" using primary key columns */
  gateway_worker_by_pk?: Maybe<Gateway_Worker>;
  getBuildVersion: BuildVersion;
  getComputeUnitAllocation?: Maybe<ComputeUnitCountResponse>;
  getComputeUnitUsage?: Maybe<ComputeUnitUsageResponse>;
  getIntercomHash?: Maybe<IntercomHashResponse>;
  /** gets invoice and receipt URLs from Stripe */
  getInvoiceAndReceiptURLs?: Maybe<InvoiceAndReceiptUrLsResponse>;
  getLetsEncryptStatus?: Maybe<LetsEncryptStatus>;
  /** This action helps fetch the number of data sources connected to a project that is using the new pricing plans (cloud_free_v2 & cloud_shared) */
  getNumberOfConnectedSources?: Maybe<NumberOfConnectedSourcesOutput>;
  getProjectMigrationStatus?: Maybe<Array<Maybe<ProjectMigrationStatus>>>;
  getReportURL?: Maybe<GetReportUrlResponse>;
  getTenantEnv?: Maybe<TenantEnv>;
  getTenantEnvConfigInfo?: Maybe<TenantEnvConfigInfo>;
  /** execute function "get_aggregated_cost_for_project_on_shared_plan" which returns "project_total_db_usage_agg" */
  get_aggregated_cost_for_project_on_shared_plan?: Maybe<
    Project_Total_Db_Usage_Agg
  >;
  /** execute function "get_aggregated_cost_for_project_on_shared_plan" and query aggregates on result of table type "project_total_db_usage_agg" */
  get_aggregated_cost_for_project_on_shared_plan_aggregate: Project_Total_Db_Usage_Agg_Aggregate;
  /** fetch data from the table: "github_email_type" */
  github_email_type: Array<Github_Email_Type>;
  /** fetch aggregated fields from the table: "github_email_type" */
  github_email_type_aggregate: Github_Email_Type_Aggregate;
  /** fetch data from the table: "github_email_type" using primary key columns */
  github_email_type_by_pk?: Maybe<Github_Email_Type>;
  /** fetch data from the table: "github_integration_config" */
  github_integration_config: Array<Github_Integration_Config>;
  /** fetch aggregated fields from the table: "github_integration_config" */
  github_integration_config_aggregate: Github_Integration_Config_Aggregate;
  /** fetch data from the table: "github_integration_config" using primary key columns */
  github_integration_config_by_pk?: Maybe<Github_Integration_Config>;
  /** fetch data from the table: "github_integration_mode" */
  github_integration_mode: Array<Github_Integration_Mode>;
  /** fetch aggregated fields from the table: "github_integration_mode" */
  github_integration_mode_aggregate: Github_Integration_Mode_Aggregate;
  /** fetch data from the table: "github_integration_mode" using primary key columns */
  github_integration_mode_by_pk?: Maybe<Github_Integration_Mode>;
  /** fetch data from the table: "github_push_event" */
  github_push_event: Array<Github_Push_Event>;
  /** fetch aggregated fields from the table: "github_push_event" */
  github_push_event_aggregate: Github_Push_Event_Aggregate;
  /** fetch data from the table: "github_push_event" using primary key columns */
  github_push_event_by_pk?: Maybe<Github_Push_Event>;
  /** fetch data from the table: "github_push_event_job" */
  github_push_event_job: Array<Github_Push_Event_Job>;
  /** fetch aggregated fields from the table: "github_push_event_job" */
  github_push_event_job_aggregate: Github_Push_Event_Job_Aggregate;
  /** fetch data from the table: "github_push_event_job" using primary key columns */
  github_push_event_job_by_pk?: Maybe<Github_Push_Event_Job>;
  /** fetch data from the table: "hasura_ami" */
  hasura_ami: Array<Hasura_Ami>;
  /** fetch aggregated fields from the table: "hasura_ami" */
  hasura_ami_aggregate: Hasura_Ami_Aggregate;
  /** fetch data from the table: "hasura_ami" using primary key columns */
  hasura_ami_by_pk?: Maybe<Hasura_Ami>;
  /** fetch data from the table: "hasura_cluster" */
  hasura_cluster: Array<Hasura_Cluster>;
  /** fetch aggregated fields from the table: "hasura_cluster" */
  hasura_cluster_aggregate: Hasura_Cluster_Aggregate;
  /** fetch data from the table: "hasura_cluster" using primary key columns */
  hasura_cluster_by_pk?: Maybe<Hasura_Cluster>;
  /** fetch data from the table: "hasura_worker" */
  hasura_worker: Array<Hasura_Worker>;
  /** fetch aggregated fields from the table: "hasura_worker" */
  hasura_worker_aggregate: Hasura_Worker_Aggregate;
  /** fetch data from the table: "hasura_worker" using primary key columns */
  hasura_worker_by_pk?: Maybe<Hasura_Worker>;
  /** fetch data from the table: "hasura_worker_tenants" */
  hasura_worker_tenants: Array<Hasura_Worker_Tenants>;
  /** fetch aggregated fields from the table: "hasura_worker_tenants" */
  hasura_worker_tenants_aggregate: Hasura_Worker_Tenants_Aggregate;
  /** fetch data from the table: "hasura_year_in_report_activity" */
  hasura_year_in_report_activity: Array<Hasura_Year_In_Report_Activity>;
  /** fetch aggregated fields from the table: "hasura_year_in_report_activity" */
  hasura_year_in_report_activity_aggregate: Hasura_Year_In_Report_Activity_Aggregate;
  /** fetch data from the table: "hasura_year_in_report_activity" using primary key columns */
  hasura_year_in_report_activity_by_pk?: Maybe<Hasura_Year_In_Report_Activity>;
  /** fetch data from the table: "hasura_year_in_report_metrics" */
  hasura_year_in_report_metrics: Array<Hasura_Year_In_Report_Metrics>;
  /** fetch aggregated fields from the table: "hasura_year_in_report_metrics" */
  hasura_year_in_report_metrics_aggregate: Hasura_Year_In_Report_Metrics_Aggregate;
  /** fetch data from the table: "hasura_year_in_report_metrics" using primary key columns */
  hasura_year_in_report_metrics_by_pk?: Maybe<Hasura_Year_In_Report_Metrics>;
  /** An array relationship */
  heroku_integrations: Array<Heroku_Integrations>;
  /** An aggregate relationship */
  heroku_integrations_aggregate: Heroku_Integrations_Aggregate;
  /** fetch data from the table: "heroku_integrations" using primary key columns */
  heroku_integrations_by_pk?: Maybe<Heroku_Integrations>;
  /** fetch data from the table: "hit_count_monthly" */
  hit_count_monthly: Array<Hit_Count_Monthly>;
  /** fetch aggregated fields from the table: "hit_count_monthly" */
  hit_count_monthly_aggregate: Hit_Count_Monthly_Aggregate;
  /** fetch data from the table: "hit_count_monthly" using primary key columns */
  hit_count_monthly_by_pk?: Maybe<Hit_Count_Monthly>;
  /** fetch data from the table: "hit_count_monthly_mv" */
  hit_count_monthly_mv: Array<Hit_Count_Monthly_Mv>;
  /** fetch aggregated fields from the table: "hit_count_monthly_mv" */
  hit_count_monthly_mv_aggregate: Hit_Count_Monthly_Mv_Aggregate;
  /** fetch data from the table: "hit_count_per_day" */
  hit_count_per_day: Array<Hit_Count_Per_Day>;
  /** fetch aggregated fields from the table: "hit_count_per_day" */
  hit_count_per_day_aggregate: Hit_Count_Per_Day_Aggregate;
  /** fetch data from the table: "hit_count_per_day" using primary key columns */
  hit_count_per_day_by_pk?: Maybe<Hit_Count_Per_Day>;
  /** fetch data from the table: "inactive_project_exclusions" */
  inactive_project_exclusions: Array<Inactive_Project_Exclusions>;
  /** fetch aggregated fields from the table: "inactive_project_exclusions" */
  inactive_project_exclusions_aggregate: Inactive_Project_Exclusions_Aggregate;
  /** fetch data from the table: "inactive_project_exclusions" using primary key columns */
  inactive_project_exclusions_by_pk?: Maybe<Inactive_Project_Exclusions>;
  /** fetch data from the table: "inactive_project_notifications" */
  inactive_project_notifications: Array<Inactive_Project_Notifications>;
  /** fetch aggregated fields from the table: "inactive_project_notifications" */
  inactive_project_notifications_aggregate: Inactive_Project_Notifications_Aggregate;
  /** fetch data from the table: "inactive_project_notifications" using primary key columns */
  inactive_project_notifications_by_pk?: Maybe<Inactive_Project_Notifications>;
  /** fetch data from the table: "inactive_project_suspension_config" */
  inactive_project_suspension_config: Array<Inactive_Project_Suspension_Config>;
  /** fetch aggregated fields from the table: "inactive_project_suspension_config" */
  inactive_project_suspension_config_aggregate: Inactive_Project_Suspension_Config_Aggregate;
  /** fetch data from the table: "inactive_projects" */
  inactive_projects: Array<Inactive_Projects>;
  /** fetch aggregated fields from the table: "inactive_projects" */
  inactive_projects_aggregate: Inactive_Projects_Aggregate;
  /** fetch data from the table: "infra_status" */
  infra_status: Array<Infra_Status>;
  /** fetch aggregated fields from the table: "infra_status" */
  infra_status_aggregate: Infra_Status_Aggregate;
  /** fetch data from the table: "infra_status" using primary key columns */
  infra_status_by_pk?: Maybe<Infra_Status>;
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>;
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate;
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>;
  /** fetch data from the table: "invoice_coupon_discount" */
  invoice_coupon_discount: Array<Invoice_Coupon_Discount>;
  /** fetch aggregated fields from the table: "invoice_coupon_discount" */
  invoice_coupon_discount_aggregate: Invoice_Coupon_Discount_Aggregate;
  /** fetch data from the table: "invoice_coupon_discount" using primary key columns */
  invoice_coupon_discount_by_pk?: Maybe<Invoice_Coupon_Discount>;
  /** fetch data from the table: "invoice_item" */
  invoice_item: Array<Invoice_Item>;
  /** fetch aggregated fields from the table: "invoice_item" */
  invoice_item_aggregate: Invoice_Item_Aggregate;
  /** fetch data from the table: "invoice_item" using primary key columns */
  invoice_item_by_pk?: Maybe<Invoice_Item>;
  /** An array relationship */
  jobs: Array<Jobs>;
  /** An aggregate relationship */
  jobs_aggregate: Jobs_Aggregate;
  /** fetch data from the table: "jobs" using primary key columns */
  jobs_by_pk?: Maybe<Jobs>;
  /** fetch data from the table: "label" */
  label: Array<Label>;
  /** fetch aggregated fields from the table: "label" */
  label_aggregate: Label_Aggregate;
  /** fetch data from the table: "label" using primary key columns */
  label_by_pk?: Maybe<Label>;
  language?: Maybe<Language>;
  languages: Array<Language>;
  /** fetch data from the table: "letsencrypt_status" */
  letsencrypt_status: Array<Letsencrypt_Status>;
  /** fetch aggregated fields from the table: "letsencrypt_status" */
  letsencrypt_status_aggregate: Letsencrypt_Status_Aggregate;
  /** fetch data from the table: "letsencrypt_status" using primary key columns */
  letsencrypt_status_by_pk?: Maybe<Letsencrypt_Status>;
  /** fetch data from the table: "license_activity" */
  license_activity: Array<License_Activity>;
  /** fetch aggregated fields from the table: "license_activity" */
  license_activity_aggregate: License_Activity_Aggregate;
  /** fetch data from the table: "license_activity" using primary key columns */
  license_activity_by_pk?: Maybe<License_Activity>;
  /** fetch data from the table: "license_instance" */
  license_instance: Array<License_Instance>;
  /** fetch aggregated fields from the table: "license_instance" */
  license_instance_aggregate: License_Instance_Aggregate;
  /** fetch data from the table: "license_instance" using primary key columns */
  license_instance_by_pk?: Maybe<License_Instance>;
  /** fetch data from the table: "license_type" */
  license_type: Array<License_Type>;
  /** fetch aggregated fields from the table: "license_type" */
  license_type_aggregate: License_Type_Aggregate;
  /** fetch data from the table: "license_type" using primary key columns */
  license_type_by_pk?: Maybe<License_Type>;
  /** fetch data from the table: "licenses" */
  licenses: Array<Licenses>;
  /** fetch aggregated fields from the table: "licenses" */
  licenses_aggregate: Licenses_Aggregate;
  /** fetch data from the table: "licenses" using primary key columns */
  licenses_by_pk?: Maybe<Licenses>;
  /** An array relationship */
  lux_proxy: Array<Lux_Proxy>;
  /** An aggregate relationship */
  lux_proxy_aggregate: Lux_Proxy_Aggregate;
  /** fetch data from the table: "lux_proxy_ami" */
  lux_proxy_ami: Array<Lux_Proxy_Ami>;
  /** fetch aggregated fields from the table: "lux_proxy_ami" */
  lux_proxy_ami_aggregate: Lux_Proxy_Ami_Aggregate;
  /** fetch data from the table: "lux_proxy_ami" using primary key columns */
  lux_proxy_ami_by_pk?: Maybe<Lux_Proxy_Ami>;
  /** fetch data from the table: "lux_proxy" using primary key columns */
  lux_proxy_by_pk?: Maybe<Lux_Proxy>;
  /** fetch data from the table: "model_based_enterprise_cloud_commitments" */
  model_based_enterprise_cloud_commitments: Array<
    Model_Based_Enterprise_Cloud_Commitments
  >;
  /** fetch aggregated fields from the table: "model_based_enterprise_cloud_commitments" */
  model_based_enterprise_cloud_commitments_aggregate: Model_Based_Enterprise_Cloud_Commitments_Aggregate;
  /** fetch data from the table: "model_based_enterprise_cloud_commitments" using primary key columns */
  model_based_enterprise_cloud_commitments_by_pk?: Maybe<
    Model_Based_Enterprise_Cloud_Commitments
  >;
  /** fetch data from the table: "model_based_pricing.model_lifecycle_view" */
  model_based_pricing_model_lifecycle_view: Array<
    Model_Based_Pricing_Model_Lifecycle_View
  >;
  /** fetch aggregated fields from the table: "model_based_pricing.model_lifecycle_view" */
  model_based_pricing_model_lifecycle_view_aggregate: Model_Based_Pricing_Model_Lifecycle_View_Aggregate;
  /** fetch data from the table: "model_based_pricing.model_snapshot_statistics" */
  model_based_pricing_model_snapshot_statistics: Array<
    Model_Based_Pricing_Model_Snapshot_Statistics
  >;
  /** fetch aggregated fields from the table: "model_based_pricing.model_snapshot_statistics" */
  model_based_pricing_model_snapshot_statistics_aggregate: Model_Based_Pricing_Model_Snapshot_Statistics_Aggregate;
  /** Gets Neon user info of the logged-in with Neon */
  neonGetUserInfo?: Maybe<NeonGetUserInfoResponse>;
  /** fetch data from the table: "neon_db_integration" */
  neon_db_integration: Array<Neon_Db_Integration>;
  /** fetch aggregated fields from the table: "neon_db_integration" */
  neon_db_integration_aggregate: Neon_Db_Integration_Aggregate;
  /** fetch data from the table: "neon_db_integration" using primary key columns */
  neon_db_integration_by_pk?: Maybe<Neon_Db_Integration>;
  /** fetch data from the table: "newrelic_config" */
  newrelic_config: Array<Newrelic_Config>;
  /** fetch aggregated fields from the table: "newrelic_config" */
  newrelic_config_aggregate: Newrelic_Config_Aggregate;
  /** fetch data from the table: "newrelic_config" using primary key columns */
  newrelic_config_by_pk?: Maybe<Newrelic_Config>;
  /** fetch data from the table: "node_pool_type" */
  node_pool_type: Array<Node_Pool_Type>;
  /** fetch aggregated fields from the table: "node_pool_type" */
  node_pool_type_aggregate: Node_Pool_Type_Aggregate;
  /** fetch data from the table: "node_pool_type" using primary key columns */
  node_pool_type_by_pk?: Maybe<Node_Pool_Type>;
  /** fetch data from the table: "node_pools" */
  node_pools: Array<Node_Pools>;
  /** fetch aggregated fields from the table: "node_pools" */
  node_pools_aggregate: Node_Pools_Aggregate;
  /** fetch data from the table: "node_pools" using primary key columns */
  node_pools_by_pk?: Maybe<Node_Pools>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<Notification_Type>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: Notification_Type_Aggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<Notification_Type>;
  /** fetch data from the table: "onboarding_sample_db_cohort" */
  onboarding_sample_db_cohort: Array<Onboarding_Sample_Db_Cohort>;
  /** fetch aggregated fields from the table: "onboarding_sample_db_cohort" */
  onboarding_sample_db_cohort_aggregate: Onboarding_Sample_Db_Cohort_Aggregate;
  /** fetch data from the table: "onboarding_sample_db_cohort" using primary key columns */
  onboarding_sample_db_cohort_by_pk?: Maybe<Onboarding_Sample_Db_Cohort>;
  /** fetch data from the table: "onboarding_sample_db_config" */
  onboarding_sample_db_config: Array<Onboarding_Sample_Db_Config>;
  /** fetch aggregated fields from the table: "onboarding_sample_db_config" */
  onboarding_sample_db_config_aggregate: Onboarding_Sample_Db_Config_Aggregate;
  /** fetch data from the table: "onboarding_sample_db_config" using primary key columns */
  onboarding_sample_db_config_by_pk?: Maybe<Onboarding_Sample_Db_Config>;
  /** fetch data from the table: "one_click_deployment" */
  one_click_deployment: Array<One_Click_Deployment>;
  /** fetch aggregated fields from the table: "one_click_deployment" */
  one_click_deployment_aggregate: One_Click_Deployment_Aggregate;
  /** fetch data from the table: "one_click_deployment" using primary key columns */
  one_click_deployment_by_pk?: Maybe<One_Click_Deployment>;
  /** fetch data from the table: "one_click_deployment_sample_apps" */
  one_click_deployment_sample_apps: Array<One_Click_Deployment_Sample_Apps>;
  /** fetch aggregated fields from the table: "one_click_deployment_sample_apps" */
  one_click_deployment_sample_apps_aggregate: One_Click_Deployment_Sample_Apps_Aggregate;
  /** fetch data from the table: "one_click_deployment_sample_apps" using primary key columns */
  one_click_deployment_sample_apps_by_pk?: Maybe<
    One_Click_Deployment_Sample_Apps
  >;
  /** fetch data from the table: "one_click_deployment_state_log" */
  one_click_deployment_state_log: Array<One_Click_Deployment_State_Log>;
  /** fetch aggregated fields from the table: "one_click_deployment_state_log" */
  one_click_deployment_state_log_aggregate: One_Click_Deployment_State_Log_Aggregate;
  /** fetch data from the table: "one_click_deployment_state_log" using primary key columns */
  one_click_deployment_state_log_by_pk?: Maybe<One_Click_Deployment_State_Log>;
  /** fetch data from the table: "one_click_deployment_states" */
  one_click_deployment_states: Array<One_Click_Deployment_States>;
  /** fetch aggregated fields from the table: "one_click_deployment_states" */
  one_click_deployment_states_aggregate: One_Click_Deployment_States_Aggregate;
  /** fetch data from the table: "one_click_deployment_states" using primary key columns */
  one_click_deployment_states_by_pk?: Maybe<One_Click_Deployment_States>;
  /** fetch data from the table: "opentelemetry_config" */
  opentelemetry_config: Array<Opentelemetry_Config>;
  /** fetch aggregated fields from the table: "opentelemetry_config" */
  opentelemetry_config_aggregate: Opentelemetry_Config_Aggregate;
  /** fetch data from the table: "opentelemetry_config" using primary key columns */
  opentelemetry_config_by_pk?: Maybe<Opentelemetry_Config>;
  /** fetch data from the table: "operations.delete_free_plan_dns_records_nov_2021" */
  operations_delete_free_plan_dns_records_nov_2021: Array<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021
  >;
  /** fetch aggregated fields from the table: "operations.delete_free_plan_dns_records_nov_2021" */
  operations_delete_free_plan_dns_records_nov_2021_aggregate: Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Aggregate;
  /** fetch data from the table: "operations.delete_free_plan_dns_records_nov_2021" using primary key columns */
  operations_delete_free_plan_dns_records_nov_2021_by_pk?: Maybe<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021
  >;
  /** fetch data from the table: "operations.tenant" */
  operations_tenant: Array<Operations_Tenant>;
  /** fetch aggregated fields from the table: "operations.tenant" */
  operations_tenant_aggregate: Operations_Tenant_Aggregate;
  /** execute function "operations.to_delete_free_plan_dns_records" which returns "operations.tenant" */
  operations_to_delete_free_plan_dns_records: Array<Operations_Tenant>;
  /** execute function "operations.to_delete_free_plan_dns_records" and query aggregates on result of table type "operations.tenant" */
  operations_to_delete_free_plan_dns_records_aggregate: Operations_Tenant_Aggregate;
  /** fetch data from the table: "payment_method" */
  payment_method: Array<Payment_Method>;
  /** fetch aggregated fields from the table: "payment_method" */
  payment_method_aggregate: Payment_Method_Aggregate;
  /** fetch data from the table: "payment_method" using primary key columns */
  payment_method_by_pk?: Maybe<Payment_Method>;
  /** fetch data from the table: "plan_entitlements" */
  plan_entitlements: Array<Plan_Entitlements>;
  /** fetch aggregated fields from the table: "plan_entitlements" */
  plan_entitlements_aggregate: Plan_Entitlements_Aggregate;
  /** fetch data from the table: "plan_entitlements" using primary key columns */
  plan_entitlements_by_pk?: Maybe<Plan_Entitlements>;
  /** fetch data from the table: "plans" */
  plans: Array<Plans>;
  /** fetch aggregated fields from the table: "plans" */
  plans_aggregate: Plans_Aggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plans_by_pk?: Maybe<Plans>;
  /** An array relationship */
  policies: Array<Policies>;
  /** An aggregate relationship */
  policies_aggregate: Policies_Aggregate;
  /** fetch data from the table: "policies" using primary key columns */
  policies_by_pk?: Maybe<Policies>;
  privilegedProjects?: Maybe<Array<AllowedProjectResponse>>;
  /** fetch data from the table: "privileges" */
  privileges: Array<Privileges>;
  /** fetch aggregated fields from the table: "privileges" */
  privileges_aggregate: Privileges_Aggregate;
  /** fetch data from the table: "privileges" using primary key columns */
  privileges_by_pk?: Maybe<Privileges>;
  /** fetch data from the table: "pro_license_keys" */
  pro_license_keys: Array<Pro_License_Keys>;
  /** fetch aggregated fields from the table: "pro_license_keys" */
  pro_license_keys_aggregate: Pro_License_Keys_Aggregate;
  /** fetch data from the table: "pro_license_keys" using primary key columns */
  pro_license_keys_by_pk?: Maybe<Pro_License_Keys>;
  /** fetch data from the table: "project_activity" */
  project_activity: Array<Project_Activity>;
  /** fetch aggregated fields from the table: "project_activity" */
  project_activity_aggregate: Project_Activity_Aggregate;
  /** fetch data from the table: "project_activity" using primary key columns */
  project_activity_by_pk?: Maybe<Project_Activity>;
  /** An array relationship */
  project_billing_manager_invitations: Array<
    Project_Billing_Manager_Invitations
  >;
  /** An aggregate relationship */
  project_billing_manager_invitations_aggregate: Project_Billing_Manager_Invitations_Aggregate;
  /** fetch data from the table: "project_billing_manager_invitations" using primary key columns */
  project_billing_manager_invitations_by_pk?: Maybe<
    Project_Billing_Manager_Invitations
  >;
  /** fetch data from the table: "project_collaborator_allowed_schema_tables" */
  project_collaborator_allowed_schema_tables: Array<
    Project_Collaborator_Allowed_Schema_Tables
  >;
  /** fetch aggregated fields from the table: "project_collaborator_allowed_schema_tables" */
  project_collaborator_allowed_schema_tables_aggregate: Project_Collaborator_Allowed_Schema_Tables_Aggregate;
  /** fetch data from the table: "project_collaborator_allowed_schema_tables" using primary key columns */
  project_collaborator_allowed_schema_tables_by_pk?: Maybe<
    Project_Collaborator_Allowed_Schema_Tables
  >;
  /** An array relationship */
  project_collaborator_allowed_schemas: Array<
    Project_Collaborator_Allowed_Schemas
  >;
  /** An aggregate relationship */
  project_collaborator_allowed_schemas_aggregate: Project_Collaborator_Allowed_Schemas_Aggregate;
  /** fetch data from the table: "project_collaborator_allowed_schemas" using primary key columns */
  project_collaborator_allowed_schemas_by_pk?: Maybe<
    Project_Collaborator_Allowed_Schemas
  >;
  /** fetch data from the table: "project_collaborator_invitations" */
  project_collaborator_invitations: Array<Project_Collaborator_Invitations>;
  /** fetch aggregated fields from the table: "project_collaborator_invitations" */
  project_collaborator_invitations_aggregate: Project_Collaborator_Invitations_Aggregate;
  /** fetch data from the table: "project_collaborator_invitations" using primary key columns */
  project_collaborator_invitations_by_pk?: Maybe<
    Project_Collaborator_Invitations
  >;
  /** An array relationship */
  project_collaborator_privileges: Array<Project_Collaborator_Privileges>;
  /** An aggregate relationship */
  project_collaborator_privileges_aggregate: Project_Collaborator_Privileges_Aggregate;
  /** fetch data from the table: "project_collaborator_privileges" using primary key columns */
  project_collaborator_privileges_by_pk?: Maybe<
    Project_Collaborator_Privileges
  >;
  /** fetch data from the table: "project_collaborators" */
  project_collaborators: Array<Project_Collaborators>;
  /** fetch aggregated fields from the table: "project_collaborators" */
  project_collaborators_aggregate: Project_Collaborators_Aggregate;
  /** fetch data from the table: "project_collaborators" using primary key columns */
  project_collaborators_by_pk?: Maybe<Project_Collaborators>;
  /** fetch data from the table: "project_data_usage" */
  project_data_usage: Array<Project_Data_Usage>;
  /** fetch data from the table: "project_data_usage_agg" */
  project_data_usage_agg: Array<Project_Data_Usage_Agg>;
  /** fetch aggregated fields from the table: "project_data_usage_agg" */
  project_data_usage_agg_aggregate: Project_Data_Usage_Agg_Aggregate;
  /** fetch data from the table: "project_data_usage_agg_user" */
  project_data_usage_agg_user: Array<Project_Data_Usage_Agg_User>;
  /** fetch aggregated fields from the table: "project_data_usage_agg_user" */
  project_data_usage_agg_user_aggregate: Project_Data_Usage_Agg_User_Aggregate;
  /** fetch aggregated fields from the table: "project_data_usage" */
  project_data_usage_aggregate: Project_Data_Usage_Aggregate;
  /** fetch data from the table: "project_data_usage" using primary key columns */
  project_data_usage_by_pk?: Maybe<Project_Data_Usage>;
  /** fetch data from the table: "project_data_usage_components" */
  project_data_usage_components: Array<Project_Data_Usage_Components>;
  /** fetch aggregated fields from the table: "project_data_usage_components" */
  project_data_usage_components_aggregate: Project_Data_Usage_Components_Aggregate;
  /** fetch data from the table: "project_data_usage_prometheus" */
  project_data_usage_prometheus: Array<Project_Data_Usage_Prometheus>;
  /** fetch data from the table: "project_data_usage_prometheus_agg_user" */
  project_data_usage_prometheus_agg_user: Array<
    Project_Data_Usage_Prometheus_Agg_User
  >;
  /** fetch aggregated fields from the table: "project_data_usage_prometheus_agg_user" */
  project_data_usage_prometheus_agg_user_aggregate: Project_Data_Usage_Prometheus_Agg_User_Aggregate;
  /** fetch aggregated fields from the table: "project_data_usage_prometheus" */
  project_data_usage_prometheus_aggregate: Project_Data_Usage_Prometheus_Aggregate;
  /** fetch data from the table: "project_data_usage_prometheus" using primary key columns */
  project_data_usage_prometheus_by_pk?: Maybe<Project_Data_Usage_Prometheus>;
  /** fetch data from the table: "project_data_usage_report" */
  project_data_usage_report: Array<Project_Data_Usage_Report>;
  /** fetch aggregated fields from the table: "project_data_usage_report" */
  project_data_usage_report_aggregate: Project_Data_Usage_Report_Aggregate;
  /** fetch data from the table: "project_data_usage_report" using primary key columns */
  project_data_usage_report_by_pk?: Maybe<Project_Data_Usage_Report>;
  /** fetch data from the table: "project_db_usage" */
  project_db_usage: Array<Project_Db_Usage>;
  /** fetch data from the table: "project_db_usage_agg_user" */
  project_db_usage_agg_user: Array<Project_Db_Usage_Agg_User>;
  /** fetch aggregated fields from the table: "project_db_usage_agg_user" */
  project_db_usage_agg_user_aggregate: Project_Db_Usage_Agg_User_Aggregate;
  /** fetch aggregated fields from the table: "project_db_usage" */
  project_db_usage_aggregate: Project_Db_Usage_Aggregate;
  /** fetch data from the table: "project_db_usage" using primary key columns */
  project_db_usage_by_pk?: Maybe<Project_Db_Usage>;
  /** fetch data from the table: "project_entitlement_access" */
  project_entitlement_access: Array<Project_Entitlement_Access>;
  /** fetch aggregated fields from the table: "project_entitlement_access" */
  project_entitlement_access_aggregate: Project_Entitlement_Access_Aggregate;
  /** fetch data from the table: "project_entitlement_access" using primary key columns */
  project_entitlement_access_by_pk?: Maybe<Project_Entitlement_Access>;
  /** fetch data from the table: "project_entitlement_catalogue" */
  project_entitlement_catalogue: Array<Project_Entitlement_Catalogue>;
  /** fetch aggregated fields from the table: "project_entitlement_catalogue" */
  project_entitlement_catalogue_aggregate: Project_Entitlement_Catalogue_Aggregate;
  /** fetch data from the table: "project_entitlement_catalogue" using primary key columns */
  project_entitlement_catalogue_by_pk?: Maybe<Project_Entitlement_Catalogue>;
  /** fetch data from the table: "project_entitlement_types" */
  project_entitlement_types: Array<Project_Entitlement_Types>;
  /** fetch aggregated fields from the table: "project_entitlement_types" */
  project_entitlement_types_aggregate: Project_Entitlement_Types_Aggregate;
  /** fetch data from the table: "project_entitlement_types" using primary key columns */
  project_entitlement_types_by_pk?: Maybe<Project_Entitlement_Types>;
  /** An array relationship */
  project_labels: Array<Project_Labels>;
  /** An aggregate relationship */
  project_labels_aggregate: Project_Labels_Aggregate;
  /** fetch data from the table: "project_labels" using primary key columns */
  project_labels_by_pk?: Maybe<Project_Labels>;
  /** fetch data from the table: "project_metadata" */
  project_metadata: Array<Project_Metadata>;
  /** fetch aggregated fields from the table: "project_metadata" */
  project_metadata_aggregate: Project_Metadata_Aggregate;
  /** fetch data from the table: "project_metadata" using primary key columns */
  project_metadata_by_pk?: Maybe<Project_Metadata>;
  /** fetch data from the table: "project_notification" */
  project_notification: Array<Project_Notification>;
  /** fetch aggregated fields from the table: "project_notification" */
  project_notification_aggregate: Project_Notification_Aggregate;
  /** fetch data from the table: "project_notification" using primary key columns */
  project_notification_by_pk?: Maybe<Project_Notification>;
  /** fetch data from the table: "project_ownership_transfer_invitations" */
  project_ownership_transfer_invitations: Array<
    Project_Ownership_Transfer_Invitations
  >;
  /** fetch aggregated fields from the table: "project_ownership_transfer_invitations" */
  project_ownership_transfer_invitations_aggregate: Project_Ownership_Transfer_Invitations_Aggregate;
  /** fetch data from the table: "project_ownership_transfer_invitations" using primary key columns */
  project_ownership_transfer_invitations_by_pk?: Maybe<
    Project_Ownership_Transfer_Invitations
  >;
  /** fetch data from the table: "project_pool_current_status" */
  project_pool_current_status: Array<Project_Pool_Current_Status>;
  /** fetch aggregated fields from the table: "project_pool_current_status" */
  project_pool_current_status_aggregate: Project_Pool_Current_Status_Aggregate;
  /** fetch data from the table: "project_requests_count" */
  project_requests_count: Array<Project_Requests_Count>;
  /** fetch aggregated fields from the table: "project_requests_count" */
  project_requests_count_aggregate: Project_Requests_Count_Aggregate;
  /** fetch data from the table: "project_requests_count" using primary key columns */
  project_requests_count_by_pk?: Maybe<Project_Requests_Count>;
  /** fetch data from the table: "project_stats" */
  project_stats: Array<Project_Stats>;
  /** fetch aggregated fields from the table: "project_stats" */
  project_stats_aggregate: Project_Stats_Aggregate;
  /** fetch data from the table: "project_stats" using primary key columns */
  project_stats_by_pk?: Maybe<Project_Stats>;
  /** fetch data from the table: "project_total_db_usage_agg" */
  project_total_db_usage_agg: Array<Project_Total_Db_Usage_Agg>;
  /** fetch aggregated fields from the table: "project_total_db_usage_agg" */
  project_total_db_usage_agg_aggregate: Project_Total_Db_Usage_Agg_Aggregate;
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "projects_pool_config" */
  projects_pool_config: Array<Projects_Pool_Config>;
  /** fetch aggregated fields from the table: "projects_pool_config" */
  projects_pool_config_aggregate: Projects_Pool_Config_Aggregate;
  /** fetch data from the table: "projects_pool_config" using primary key columns */
  projects_pool_config_by_pk?: Maybe<Projects_Pool_Config>;
  /** fetch data from the table: "projects_pro_key_generations" */
  projects_pro_key_generations: Array<Projects_Pro_Key_Generations>;
  /** fetch aggregated fields from the table: "projects_pro_key_generations" */
  projects_pro_key_generations_aggregate: Projects_Pro_Key_Generations_Aggregate;
  /** fetch data from the table: "projects_pro_key_generations" using primary key columns */
  projects_pro_key_generations_by_pk?: Maybe<Projects_Pro_Key_Generations>;
  /** fetch data from the table: "projects_with_no_running_deployments" */
  projects_with_no_running_deployments: Array<
    Projects_With_No_Running_Deployments
  >;
  /** fetch aggregated fields from the table: "projects_with_no_running_deployments" */
  projects_with_no_running_deployments_aggregate: Projects_With_No_Running_Deployments_Aggregate;
  /** fetch data from the table: "projects_with_no_running_preview_app" */
  projects_with_no_running_preview_app: Array<
    Projects_With_No_Running_Preview_App
  >;
  /** fetch aggregated fields from the table: "projects_with_no_running_preview_app" */
  projects_with_no_running_preview_app_aggregate: Projects_With_No_Running_Preview_App_Aggregate;
  /** fetch data from the table: "prometheus_config" */
  prometheus_config: Array<Prometheus_Config>;
  /** fetch aggregated fields from the table: "prometheus_config" */
  prometheus_config_aggregate: Prometheus_Config_Aggregate;
  /** fetch data from the table: "prometheus_config" using primary key columns */
  prometheus_config_by_pk?: Maybe<Prometheus_Config>;
  /** An array relationship */
  providers: Array<Providers>;
  /** An aggregate relationship */
  providers_aggregate: Providers_Aggregate;
  /** fetch data from the table: "providers" using primary key columns */
  providers_by_pk?: Maybe<Providers>;
  /** fetch data from the table: "provisioning" */
  provisioning: Array<Provisioning>;
  /** fetch aggregated fields from the table: "provisioning" */
  provisioning_aggregate: Provisioning_Aggregate;
  /** fetch data from the table: "provisioning" using primary key columns */
  provisioning_by_pk?: Maybe<Provisioning>;
  /** fetch data from the table: "region" */
  region: Array<Region>;
  /** fetch aggregated fields from the table: "region" */
  region_aggregate: Region_Aggregate;
  /** fetch data from the table: "region" using primary key columns */
  region_by_pk?: Maybe<Region>;
  /** fetch data from the table: "region_v2" */
  region_v2: Array<Region_V2>;
  /** fetch aggregated fields from the table: "region_v2" */
  region_v2_aggregate: Region_V2_Aggregate;
  /** An array relationship */
  regional_data_connectors: Array<Regional_Data_Connectors>;
  /** An aggregate relationship */
  regional_data_connectors_aggregate: Regional_Data_Connectors_Aggregate;
  /** fetch data from the table: "regional_data_connectors" using primary key columns */
  regional_data_connectors_by_pk?: Maybe<Regional_Data_Connectors>;
  /** An array relationship */
  regional_metrics: Array<Regional_Metrics>;
  /** An aggregate relationship */
  regional_metrics_aggregate: Regional_Metrics_Aggregate;
  /** fetch data from the table: "regional_metrics" using primary key columns */
  regional_metrics_by_pk?: Maybe<Regional_Metrics>;
  /** fetch data from the table: "saml_idp" */
  saml_idp: Array<Saml_Idp>;
  /** fetch aggregated fields from the table: "saml_idp" */
  saml_idp_aggregate: Saml_Idp_Aggregate;
  /** fetch data from the table: "saml_idp" using primary key columns */
  saml_idp_by_pk?: Maybe<Saml_Idp>;
  /** execute function "search_project_login_status" which returns "search_project_login_status_results" */
  search_project_login_status: Array<Search_Project_Login_Status_Results>;
  /** execute function "search_project_login_status" and query aggregates on result of table type "search_project_login_status_results" */
  search_project_login_status_aggregate: Search_Project_Login_Status_Results_Aggregate;
  /** fetch data from the table: "search_project_login_status_results" */
  search_project_login_status_results: Array<
    Search_Project_Login_Status_Results
  >;
  /** fetch aggregated fields from the table: "search_project_login_status_results" */
  search_project_login_status_results_aggregate: Search_Project_Login_Status_Results_Aggregate;
  /** execute function "search_tenant_group_has_least_members" which returns "tenant_group" */
  search_tenant_group_has_least_members: Array<Tenant_Group>;
  /** execute function "search_tenant_group_has_least_members" and query aggregates on result of table type "tenant_group" */
  search_tenant_group_has_least_members_aggregate: Tenant_Group_Aggregate;
  /** fetch data from the table: "slack_config" */
  slack_config: Array<Slack_Config>;
  /** fetch aggregated fields from the table: "slack_config" */
  slack_config_aggregate: Slack_Config_Aggregate;
  /** fetch data from the table: "slack_config" using primary key columns */
  slack_config_by_pk?: Maybe<Slack_Config>;
  status: Scalars['String'];
  /** fetch data from the table: "stripe_subscription" */
  stripe_subscription: Array<Stripe_Subscription>;
  /** fetch aggregated fields from the table: "stripe_subscription" */
  stripe_subscription_aggregate: Stripe_Subscription_Aggregate;
  /** fetch data from the table: "stripe_subscription" using primary key columns */
  stripe_subscription_by_pk?: Maybe<Stripe_Subscription>;
  /** fetch data from the table: "stripe_webhook_events" */
  stripe_webhook_events: Array<Stripe_Webhook_Events>;
  /** fetch aggregated fields from the table: "stripe_webhook_events" */
  stripe_webhook_events_aggregate: Stripe_Webhook_Events_Aggregate;
  /** fetch data from the table: "stripe_webhook_events" using primary key columns */
  stripe_webhook_events_by_pk?: Maybe<Stripe_Webhook_Events>;
  /** fetch data from the table: "super_connector_types" */
  super_connector_types: Array<Super_Connector_Types>;
  /** fetch aggregated fields from the table: "super_connector_types" */
  super_connector_types_aggregate: Super_Connector_Types_Aggregate;
  /** fetch data from the table: "super_connector_types" using primary key columns */
  super_connector_types_by_pk?: Maybe<Super_Connector_Types>;
  /** fetch data from the table: "support_plan_types" */
  support_plan_types: Array<Support_Plan_Types>;
  /** fetch aggregated fields from the table: "support_plan_types" */
  support_plan_types_aggregate: Support_Plan_Types_Aggregate;
  /** fetch data from the table: "support_plan_types" using primary key columns */
  support_plan_types_by_pk?: Maybe<Support_Plan_Types>;
  /** fetch data from the table: "survey" */
  survey: Array<Survey>;
  /** fetch aggregated fields from the table: "survey" */
  survey_aggregate: Survey_Aggregate;
  /** fetch data from the table: "survey" using primary key columns */
  survey_by_pk?: Maybe<Survey>;
  /** fetch data from the table: "survey_question" */
  survey_question: Array<Survey_Question>;
  /** fetch aggregated fields from the table: "survey_question" */
  survey_question_aggregate: Survey_Question_Aggregate;
  /** fetch data from the table: "survey_question_answer_option" */
  survey_question_answer_option: Array<Survey_Question_Answer_Option>;
  /** fetch aggregated fields from the table: "survey_question_answer_option" */
  survey_question_answer_option_aggregate: Survey_Question_Answer_Option_Aggregate;
  /** fetch data from the table: "survey_question_answer_option" using primary key columns */
  survey_question_answer_option_by_pk?: Maybe<Survey_Question_Answer_Option>;
  /** fetch data from the table: "survey_question_answers" */
  survey_question_answers: Array<Survey_Question_Answers>;
  /** fetch aggregated fields from the table: "survey_question_answers" */
  survey_question_answers_aggregate: Survey_Question_Answers_Aggregate;
  /** fetch data from the table: "survey_question_answers" using primary key columns */
  survey_question_answers_by_pk?: Maybe<Survey_Question_Answers>;
  /** fetch data from the table: "survey_question" using primary key columns */
  survey_question_by_pk?: Maybe<Survey_Question>;
  /** fetch data from the table: "survey_question_kind" */
  survey_question_kind: Array<Survey_Question_Kind>;
  /** fetch aggregated fields from the table: "survey_question_kind" */
  survey_question_kind_aggregate: Survey_Question_Kind_Aggregate;
  /** fetch data from the table: "survey_question_kind" using primary key columns */
  survey_question_kind_by_pk?: Maybe<Survey_Question_Kind>;
  /** An array relationship */
  survey_question_options: Array<Survey_Question_Options>;
  /** An aggregate relationship */
  survey_question_options_aggregate: Survey_Question_Options_Aggregate;
  /** fetch data from the table: "survey_question_options" using primary key columns */
  survey_question_options_by_pk?: Maybe<Survey_Question_Options>;
  /** fetch data from the table: "survey_v2" */
  survey_v2: Array<Survey_V2>;
  /** fetch aggregated fields from the table: "survey_v2" */
  survey_v2_aggregate: Survey_V2_Aggregate;
  /** fetch data from the table: "survey_v2" using primary key columns */
  survey_v2_by_pk?: Maybe<Survey_V2>;
  /** fetch data from the table: "survey_v2_question" */
  survey_v2_question: Array<Survey_V2_Question>;
  /** fetch aggregated fields from the table: "survey_v2_question" */
  survey_v2_question_aggregate: Survey_V2_Question_Aggregate;
  /** fetch data from the table: "survey_v2_question" using primary key columns */
  survey_v2_question_by_pk?: Maybe<Survey_V2_Question>;
  /** fetch data from the table: "survey_v2_question_kind" */
  survey_v2_question_kind: Array<Survey_V2_Question_Kind>;
  /** fetch aggregated fields from the table: "survey_v2_question_kind" */
  survey_v2_question_kind_aggregate: Survey_V2_Question_Kind_Aggregate;
  /** fetch data from the table: "survey_v2_question_kind" using primary key columns */
  survey_v2_question_kind_by_pk?: Maybe<Survey_V2_Question_Kind>;
  /** fetch data from the table: "survey_v2_question_option" */
  survey_v2_question_option: Array<Survey_V2_Question_Option>;
  /** fetch data from the table: "survey_v2_question_option_additional_info_config" */
  survey_v2_question_option_additional_info_config: Array<
    Survey_V2_Question_Option_Additional_Info_Config
  >;
  /** fetch aggregated fields from the table: "survey_v2_question_option_additional_info_config" */
  survey_v2_question_option_additional_info_config_aggregate: Survey_V2_Question_Option_Additional_Info_Config_Aggregate;
  /** fetch data from the table: "survey_v2_question_option_additional_info_config" using primary key columns */
  survey_v2_question_option_additional_info_config_by_pk?: Maybe<
    Survey_V2_Question_Option_Additional_Info_Config
  >;
  /** fetch aggregated fields from the table: "survey_v2_question_option" */
  survey_v2_question_option_aggregate: Survey_V2_Question_Option_Aggregate;
  /** fetch data from the table: "survey_v2_question_option" using primary key columns */
  survey_v2_question_option_by_pk?: Maybe<Survey_V2_Question_Option>;
  /** fetch data from the table: "survey_v2_response" */
  survey_v2_response: Array<Survey_V2_Response>;
  /** fetch aggregated fields from the table: "survey_v2_response" */
  survey_v2_response_aggregate: Survey_V2_Response_Aggregate;
  /** fetch data from the table: "survey_v2_response_answer" */
  survey_v2_response_answer: Array<Survey_V2_Response_Answer>;
  /** fetch aggregated fields from the table: "survey_v2_response_answer" */
  survey_v2_response_answer_aggregate: Survey_V2_Response_Answer_Aggregate;
  /** fetch data from the table: "survey_v2_response_answer" using primary key columns */
  survey_v2_response_answer_by_pk?: Maybe<Survey_V2_Response_Answer>;
  /** fetch data from the table: "survey_v2_response_answer_option" */
  survey_v2_response_answer_option: Array<Survey_V2_Response_Answer_Option>;
  /** fetch aggregated fields from the table: "survey_v2_response_answer_option" */
  survey_v2_response_answer_option_aggregate: Survey_V2_Response_Answer_Option_Aggregate;
  /** fetch data from the table: "survey_v2_response_answer_option" using primary key columns */
  survey_v2_response_answer_option_by_pk?: Maybe<
    Survey_V2_Response_Answer_Option
  >;
  /** fetch data from the table: "survey_v2_response" using primary key columns */
  survey_v2_response_by_pk?: Maybe<Survey_V2_Response>;
  /** fetch data from the table: "task_event" */
  task_event: Array<Task_Event>;
  /** fetch aggregated fields from the table: "task_event" */
  task_event_aggregate: Task_Event_Aggregate;
  /** fetch data from the table: "task_event" using primary key columns */
  task_event_by_pk?: Maybe<Task_Event>;
  /** fetch data from the table: "taskq_worker_event_latest" */
  taskq_worker_event_latest: Array<Taskq_Worker_Event_Latest>;
  /** fetch aggregated fields from the table: "taskq_worker_event_latest" */
  taskq_worker_event_latest_aggregate: Taskq_Worker_Event_Latest_Aggregate;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** fetch data from the table: "tenant" */
  tenant: Array<Tenant>;
  /** fetch data from the table: "tenant_active_status_reason" */
  tenant_active_status_reason: Array<Tenant_Active_Status_Reason>;
  /** fetch aggregated fields from the table: "tenant_active_status_reason" */
  tenant_active_status_reason_aggregate: Tenant_Active_Status_Reason_Aggregate;
  /** fetch data from the table: "tenant_active_status_reason" using primary key columns */
  tenant_active_status_reason_by_pk?: Maybe<Tenant_Active_Status_Reason>;
  /** fetch aggregated fields from the table: "tenant" */
  tenant_aggregate: Tenant_Aggregate;
  /** fetch data from the table: "tenant" using primary key columns */
  tenant_by_pk?: Maybe<Tenant>;
  /** fetch data from the table: "tenant_group" */
  tenant_group: Array<Tenant_Group>;
  /** fetch aggregated fields from the table: "tenant_group" */
  tenant_group_aggregate: Tenant_Group_Aggregate;
  /** fetch data from the table: "tenant_group" using primary key columns */
  tenant_group_by_pk?: Maybe<Tenant_Group>;
  /** fetch data from the table: "tenant_group_member" */
  tenant_group_member: Array<Tenant_Group_Member>;
  /** fetch aggregated fields from the table: "tenant_group_member" */
  tenant_group_member_aggregate: Tenant_Group_Member_Aggregate;
  /** fetch data from the table: "tenant_group_member" using primary key columns */
  tenant_group_member_by_pk?: Maybe<Tenant_Group_Member>;
  /** An array relationship */
  tenant_public_status: Array<Tenant_Public_Status>;
  /** An aggregate relationship */
  tenant_public_status_aggregate: Tenant_Public_Status_Aggregate;
  /** fetch data from the table: "tenant_public_status" using primary key columns */
  tenant_public_status_by_pk?: Maybe<Tenant_Public_Status>;
  /** fetch data from the table: "tenant_status" */
  tenant_status: Array<Tenant_Status>;
  /** fetch aggregated fields from the table: "tenant_status" */
  tenant_status_aggregate: Tenant_Status_Aggregate;
  /** fetch data from the table: "tenant_status" using primary key columns */
  tenant_status_by_pk?: Maybe<Tenant_Status>;
  /** fetch data from the table: "trial_leads" */
  trial_leads: Array<Trial_Leads>;
  /** fetch aggregated fields from the table: "trial_leads" */
  trial_leads_aggregate: Trial_Leads_Aggregate;
  /** fetch data from the table: "trial_leads" using primary key columns */
  trial_leads_by_pk?: Maybe<Trial_Leads>;
  /** fetch data from the table: "ua_audit_logs" */
  ua_audit_logs: Array<Ua_Audit_Logs>;
  /** fetch aggregated fields from the table: "ua_audit_logs" */
  ua_audit_logs_aggregate: Ua_Audit_Logs_Aggregate;
  /** fetch data from the table: "ua_audit_logs" using primary key columns */
  ua_audit_logs_by_pk?: Maybe<Ua_Audit_Logs>;
  /** fetch data from the table: "user_activity" */
  user_activity: Array<User_Activity>;
  /** fetch aggregated fields from the table: "user_activity" */
  user_activity_aggregate: User_Activity_Aggregate;
  /** fetch data from the table: "user_activity" using primary key columns */
  user_activity_by_pk?: Maybe<User_Activity>;
  /** fetch data from the table: "user_coupon" */
  user_coupon: Array<User_Coupon>;
  /** fetch aggregated fields from the table: "user_coupon" */
  user_coupon_aggregate: User_Coupon_Aggregate;
  /** fetch data from the table: "user_coupon" using primary key columns */
  user_coupon_by_pk?: Maybe<User_Coupon>;
  /** fetch data from the table: "user_entitlement_access" */
  user_entitlement_access: Array<User_Entitlement_Access>;
  /** fetch aggregated fields from the table: "user_entitlement_access" */
  user_entitlement_access_aggregate: User_Entitlement_Access_Aggregate;
  /** fetch data from the table: "user_entitlement_access" using primary key columns */
  user_entitlement_access_by_pk?: Maybe<User_Entitlement_Access>;
  /** fetch data from the table: "user_entitlement_catalogue" */
  user_entitlement_catalogue: Array<User_Entitlement_Catalogue>;
  /** fetch aggregated fields from the table: "user_entitlement_catalogue" */
  user_entitlement_catalogue_aggregate: User_Entitlement_Catalogue_Aggregate;
  /** fetch data from the table: "user_entitlement_catalogue" using primary key columns */
  user_entitlement_catalogue_by_pk?: Maybe<User_Entitlement_Catalogue>;
  /** fetch data from the table: "user_entitlement_types" */
  user_entitlement_types: Array<User_Entitlement_Types>;
  /** fetch aggregated fields from the table: "user_entitlement_types" */
  user_entitlement_types_aggregate: User_Entitlement_Types_Aggregate;
  /** fetch data from the table: "user_entitlement_types" using primary key columns */
  user_entitlement_types_by_pk?: Maybe<User_Entitlement_Types>;
  /** fetch data from the table: "user_onboarding" */
  user_onboarding: Array<User_Onboarding>;
  /** fetch aggregated fields from the table: "user_onboarding" */
  user_onboarding_aggregate: User_Onboarding_Aggregate;
  /** fetch data from the table: "user_onboarding" using primary key columns */
  user_onboarding_by_pk?: Maybe<User_Onboarding>;
  /** fetch data from the table: "user_personal_access_tokens" */
  user_personal_access_tokens: Array<User_Personal_Access_Tokens>;
  /** fetch aggregated fields from the table: "user_personal_access_tokens" */
  user_personal_access_tokens_aggregate: User_Personal_Access_Tokens_Aggregate;
  /** fetch data from the table: "user_personal_access_tokens" using primary key columns */
  user_personal_access_tokens_by_pk?: Maybe<User_Personal_Access_Tokens>;
  /** fetch data from the table: "user_profile" */
  user_profile: Array<User_Profile>;
  /** fetch aggregated fields from the table: "user_profile" */
  user_profile_aggregate: User_Profile_Aggregate;
  /** fetch data from the table: "user_profile" using primary key columns */
  user_profile_by_pk?: Maybe<User_Profile>;
  /** fetch data from the table: "user_project_map" */
  user_project_map: Array<User_Project_Map>;
  /** fetch aggregated fields from the table: "user_project_map" */
  user_project_map_aggregate: User_Project_Map_Aggregate;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table: "user_vpc_policy" */
  user_vpc_policy: Array<User_Vpc_Policy>;
  /** fetch aggregated fields from the table: "user_vpc_policy" */
  user_vpc_policy_aggregate: User_Vpc_Policy_Aggregate;
  /** fetch data from the table: "user_vpc_policy" using primary key columns */
  user_vpc_policy_by_pk?: Maybe<User_Vpc_Policy>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_public" */
  users_public: Array<Users_Public>;
  /** fetch aggregated fields from the table: "users_public" */
  users_public_aggregate: Users_Public_Aggregate;
  validTenantName?: Maybe<ValidTenantNameResponse>;
  vercelGetProjects?: Maybe<VercelGetProjectsOutput>;
  /** fetch data from the table: "vercel_integration" */
  vercel_integration: Array<Vercel_Integration>;
  /** fetch aggregated fields from the table: "vercel_integration" */
  vercel_integration_aggregate: Vercel_Integration_Aggregate;
  /** fetch data from the table: "vercel_integration" using primary key columns */
  vercel_integration_by_pk?: Maybe<Vercel_Integration>;
  /** An array relationship */
  vercel_integration_connections: Array<Vercel_Integration_Connections>;
  /** An aggregate relationship */
  vercel_integration_connections_aggregate: Vercel_Integration_Connections_Aggregate;
  /** fetch data from the table: "vercel_integration_connections" using primary key columns */
  vercel_integration_connections_by_pk?: Maybe<Vercel_Integration_Connections>;
  /** fetch data from the table: "vpc_collaborator_invitations" */
  vpc_collaborator_invitations: Array<Vpc_Collaborator_Invitations>;
  /** fetch aggregated fields from the table: "vpc_collaborator_invitations" */
  vpc_collaborator_invitations_aggregate: Vpc_Collaborator_Invitations_Aggregate;
  /** fetch data from the table: "vpc_collaborator_invitations" using primary key columns */
  vpc_collaborator_invitations_by_pk?: Maybe<Vpc_Collaborator_Invitations>;
  /** fetch data from the table: "vpc_collaborators" */
  vpc_collaborators: Array<Vpc_Collaborators>;
  /** fetch aggregated fields from the table: "vpc_collaborators" */
  vpc_collaborators_aggregate: Vpc_Collaborators_Aggregate;
  /** fetch data from the table: "vpc_collaborators" using primary key columns */
  vpc_collaborators_by_pk?: Maybe<Vpc_Collaborators>;
  /** fetch data from the table: "vpc_peering" */
  vpc_peering: Array<Vpc_Peering>;
  /** fetch aggregated fields from the table: "vpc_peering" */
  vpc_peering_aggregate: Vpc_Peering_Aggregate;
  /** fetch data from the table: "vpc_peering" using primary key columns */
  vpc_peering_by_pk?: Maybe<Vpc_Peering>;
  /** fetch data from the table: "vpc_peering_direction" */
  vpc_peering_direction: Array<Vpc_Peering_Direction>;
  /** fetch aggregated fields from the table: "vpc_peering_direction" */
  vpc_peering_direction_aggregate: Vpc_Peering_Direction_Aggregate;
  /** fetch data from the table: "vpc_peering_direction" using primary key columns */
  vpc_peering_direction_by_pk?: Maybe<Vpc_Peering_Direction>;
  /** fetch data from the table: "vpc_privileges" */
  vpc_privileges: Array<Vpc_Privileges>;
  /** fetch aggregated fields from the table: "vpc_privileges" */
  vpc_privileges_aggregate: Vpc_Privileges_Aggregate;
  /** fetch data from the table: "vpc_privileges" using primary key columns */
  vpc_privileges_by_pk?: Maybe<Vpc_Privileges>;
  /** fetch data from the table: "vpc_status" */
  vpc_status: Array<Vpc_Status>;
  /** fetch aggregated fields from the table: "vpc_status" */
  vpc_status_aggregate: Vpc_Status_Aggregate;
  /** fetch data from the table: "vpc_status" using primary key columns */
  vpc_status_by_pk?: Maybe<Vpc_Status>;
  /** fetch data from the table: "zendesk_support_category" */
  zendesk_support_category: Array<Zendesk_Support_Category>;
  /** fetch aggregated fields from the table: "zendesk_support_category" */
  zendesk_support_category_aggregate: Zendesk_Support_Category_Aggregate;
  /** fetch data from the table: "zendesk_support_category" using primary key columns */
  zendesk_support_category_by_pk?: Maybe<Zendesk_Support_Category>;
  /** fetch data from the table: "zendesk_support_tickets" */
  zendesk_support_tickets: Array<Zendesk_Support_Tickets>;
  /** fetch aggregated fields from the table: "zendesk_support_tickets" */
  zendesk_support_tickets_aggregate: Zendesk_Support_Tickets_Aggregate;
  /** fetch data from the table: "zendesk_support_tickets" using primary key columns */
  zendesk_support_tickets_by_pk?: Maybe<Zendesk_Support_Tickets>;
};

export type Query_RootAlert_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Order_By>>;
  where?: InputMaybe<Alert_Config_Bool_Exp>;
};

export type Query_RootAlert_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Order_By>>;
  where?: InputMaybe<Alert_Config_Bool_Exp>;
};

export type Query_RootAlert_Config_Alert_TypeArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Alert_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Alert_Type_Order_By>>;
  where?: InputMaybe<Alert_Config_Alert_Type_Bool_Exp>;
};

export type Query_RootAlert_Config_Alert_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Alert_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Alert_Type_Order_By>>;
  where?: InputMaybe<Alert_Config_Alert_Type_Bool_Exp>;
};

export type Query_RootAlert_Config_Alert_Type_By_PkArgs = {
  project_id: Scalars['uuid'];
  type: Scalars['String'];
};

export type Query_RootAlert_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Query_RootAlert_Config_ServiceArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Service_Order_By>>;
  where?: InputMaybe<Alert_Config_Service_Bool_Exp>;
};

export type Query_RootAlert_Config_Service_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Service_Order_By>>;
  where?: InputMaybe<Alert_Config_Service_Bool_Exp>;
};

export type Query_RootAlert_Config_Service_By_PkArgs = {
  project_id: Scalars['uuid'];
  type: Alert_Service_Type_Enum;
};

export type Query_RootAlert_Service_TypeArgs = {
  distinct_on?: InputMaybe<Array<Alert_Service_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Service_Type_Order_By>>;
  where?: InputMaybe<Alert_Service_Type_Bool_Exp>;
};

export type Query_RootAlert_Service_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Service_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Service_Type_Order_By>>;
  where?: InputMaybe<Alert_Service_Type_Bool_Exp>;
};

export type Query_RootAlert_Service_Type_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootAlert_TypeArgs = {
  distinct_on?: InputMaybe<Array<Alert_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Type_Order_By>>;
  where?: InputMaybe<Alert_Type_Bool_Exp>;
};

export type Query_RootAlert_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Type_Order_By>>;
  where?: InputMaybe<Alert_Type_Bool_Exp>;
};

export type Query_RootAlert_Type_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootAzuremonitor_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Azuremonitor_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Azuremonitor_Config_Order_By>>;
  where?: InputMaybe<Azuremonitor_Config_Bool_Exp>;
};

export type Query_RootAzuremonitor_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Azuremonitor_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Azuremonitor_Config_Order_By>>;
  where?: InputMaybe<Azuremonitor_Config_Bool_Exp>;
};

export type Query_RootAzuremonitor_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Query_RootBilling_AddressArgs = {
  distinct_on?: InputMaybe<Array<Billing_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billing_Address_Order_By>>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};

export type Query_RootBilling_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billing_Address_Order_By>>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};

export type Query_RootBilling_Address_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCentral_CloudArgs = {
  distinct_on?: InputMaybe<Array<Central_Cloud_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Central_Cloud_Order_By>>;
  where?: InputMaybe<Central_Cloud_Bool_Exp>;
};

export type Query_RootCentral_Cloud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Central_Cloud_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Central_Cloud_Order_By>>;
  where?: InputMaybe<Central_Cloud_Bool_Exp>;
};

export type Query_RootCentral_Cloud_By_PkArgs = {
  cloud: Cloud_Enum;
};

export type Query_RootChange_Email_RequestArgs = {
  distinct_on?: InputMaybe<Array<Change_Email_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Change_Email_Request_Order_By>>;
  where?: InputMaybe<Change_Email_Request_Bool_Exp>;
};

export type Query_RootChange_Email_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Change_Email_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Change_Email_Request_Order_By>>;
  where?: InputMaybe<Change_Email_Request_Bool_Exp>;
};

export type Query_RootChange_Email_Request_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCicd_Deployment_Pipeline_ConfigArgs = {
  distinct_on?: InputMaybe<
    Array<Cicd_Deployment_Pipeline_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pipeline_Config_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pipeline_Config_Bool_Exp>;
};

export type Query_RootCicd_Deployment_Pipeline_Config_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Cicd_Deployment_Pipeline_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pipeline_Config_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pipeline_Config_Bool_Exp>;
};

export type Query_RootCicd_Deployment_Pipeline_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCicd_Deployment_PoolArgs = {
  distinct_on?: InputMaybe<Array<Cicd_Deployment_Pool_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pool_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Bool_Exp>;
};

export type Query_RootCicd_Deployment_Pool_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cicd_Deployment_Pool_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pool_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Bool_Exp>;
};

export type Query_RootCicd_Deployment_Pool_MemberArgs = {
  distinct_on?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
};

export type Query_RootCicd_Deployment_Pool_Member_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
};

export type Query_RootCicd_Get_Matching_Pipeline_ConfigArgs = {
  args?: InputMaybe<Cicd_Get_Matching_Pipeline_Config_Args>;
  distinct_on?: InputMaybe<
    Array<Cicd_Deployment_Pipeline_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pipeline_Config_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pipeline_Config_Bool_Exp>;
};

export type Query_RootCicd_Get_Matching_Pipeline_Config_AggregateArgs = {
  args?: InputMaybe<Cicd_Get_Matching_Pipeline_Config_Args>;
  distinct_on?: InputMaybe<
    Array<Cicd_Deployment_Pipeline_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pipeline_Config_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pipeline_Config_Bool_Exp>;
};

export type Query_RootCloudArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cloud_Order_By>>;
  where?: InputMaybe<Cloud_Bool_Exp>;
};

export type Query_RootCloud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cloud_Order_By>>;
  where?: InputMaybe<Cloud_Bool_Exp>;
};

export type Query_RootCloud_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootCloud_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cloud_Metadata_Order_By>>;
  where?: InputMaybe<Cloud_Metadata_Bool_Exp>;
};

export type Query_RootCloud_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cloud_Metadata_Order_By>>;
  where?: InputMaybe<Cloud_Metadata_Bool_Exp>;
};

export type Query_RootCloud_Metadata_By_PkArgs = {
  cloud: Cloud_Enum;
};

export type Query_RootCloud_Regions_By_PlanArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Regions_By_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cloud_Regions_By_Plan_Order_By>>;
  where?: InputMaybe<Cloud_Regions_By_Plan_Bool_Exp>;
};

export type Query_RootCloud_Regions_By_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Regions_By_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cloud_Regions_By_Plan_Order_By>>;
  where?: InputMaybe<Cloud_Regions_By_Plan_Bool_Exp>;
};

export type Query_RootCompute_Project_Agg_Db_Usage_UserArgs = {
  args?: InputMaybe<Compute_Project_Agg_Db_Usage_User_Args>;
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Agg_User_Bool_Exp>;
};

export type Query_RootCompute_Project_Agg_Db_Usage_User_AggregateArgs = {
  args?: InputMaybe<Compute_Project_Agg_Db_Usage_User_Args>;
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Agg_User_Bool_Exp>;
};

export type Query_RootCompute_Project_Agg_UsageArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_Args>;
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Query_RootCompute_Project_Agg_Usage_AggregateArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_Args>;
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Query_RootCompute_Project_Agg_Usage_PrometheusArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_Prometheus_Args>;
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Query_RootCompute_Project_Agg_Usage_Prometheus_AggregateArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_Prometheus_Args>;
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Query_RootCompute_Project_Agg_Usage_Prometheus_UserArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_Prometheus_User_Args>;
  distinct_on?: InputMaybe<
    Array<Project_Data_Usage_Prometheus_Agg_User_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Bool_Exp>;
};

export type Query_RootCompute_Project_Agg_Usage_Prometheus_User_AggregateArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_Prometheus_User_Args>;
  distinct_on?: InputMaybe<
    Array<Project_Data_Usage_Prometheus_Agg_User_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Bool_Exp>;
};

export type Query_RootCompute_Project_Agg_Usage_UserArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_User_Args>;
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_User_Bool_Exp>;
};

export type Query_RootCompute_Project_Agg_Usage_User_AggregateArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_User_Args>;
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_User_Bool_Exp>;
};

export type Query_RootCompute_Unit_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Compute_Unit_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compute_Unit_Config_Order_By>>;
  where?: InputMaybe<Compute_Unit_Config_Bool_Exp>;
};

export type Query_RootCompute_Unit_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compute_Unit_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compute_Unit_Config_Order_By>>;
  where?: InputMaybe<Compute_Unit_Config_Bool_Exp>;
};

export type Query_RootCompute_Unit_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootConfigArgs = {
  distinct_on?: InputMaybe<Array<Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Config_Order_By>>;
  where?: InputMaybe<Config_Bool_Exp>;
};

export type Query_RootConfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Config_Order_By>>;
  where?: InputMaybe<Config_Bool_Exp>;
};

export type Query_RootConfig_By_PkArgs = {
  tenant_id: Scalars['uuid'];
};

export type Query_RootConfig_StatusArgs = {
  distinct_on?: InputMaybe<Array<Config_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Config_Status_Order_By>>;
  where?: InputMaybe<Config_Status_Bool_Exp>;
};

export type Query_RootConfig_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Config_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Config_Status_Order_By>>;
  where?: InputMaybe<Config_Status_Bool_Exp>;
};

export type Query_RootConfig_Status_By_PkArgs = {
  hash: Scalars['String'];
  tenant_id: Scalars['uuid'];
  worker_id: Scalars['uuid'];
};

export type Query_RootConnector_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Connector_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connector_Config_Order_By>>;
  where?: InputMaybe<Connector_Config_Bool_Exp>;
};

export type Query_RootConnector_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Connector_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connector_Config_Order_By>>;
  where?: InputMaybe<Connector_Config_Bool_Exp>;
};

export type Query_RootConnector_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootConnector_DeploymentsArgs = {
  distinct_on?: InputMaybe<Array<Connector_Deployments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connector_Deployments_Order_By>>;
  where?: InputMaybe<Connector_Deployments_Bool_Exp>;
};

export type Query_RootConnector_Deployments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Connector_Deployments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connector_Deployments_Order_By>>;
  where?: InputMaybe<Connector_Deployments_Bool_Exp>;
};

export type Query_RootConnector_Deployments_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootContinentArgs = {
  code: Scalars['ID'];
};

export type Query_RootContinentsArgs = {
  filter?: InputMaybe<ContinentFilterInput>;
};

export type Query_RootCountriesArgs = {
  filter?: InputMaybe<CountryFilterInput>;
};

export type Query_RootCountryArgs = {
  code: Scalars['ID'];
};

export type Query_RootCouponArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type Query_RootCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type Query_RootCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCoupon_DurationArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Duration_Order_By>>;
  where?: InputMaybe<Coupon_Duration_Bool_Exp>;
};

export type Query_RootCoupon_Duration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Duration_Order_By>>;
  where?: InputMaybe<Coupon_Duration_Bool_Exp>;
};

export type Query_RootCoupon_Duration_By_PkArgs = {
  duration: Scalars['String'];
};

export type Query_RootCoupon_TypeArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Type_Order_By>>;
  where?: InputMaybe<Coupon_Type_Bool_Exp>;
};

export type Query_RootCoupon_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Type_Order_By>>;
  where?: InputMaybe<Coupon_Type_Bool_Exp>;
};

export type Query_RootCoupon_Type_By_PkArgs = {
  type: Scalars['String'];
};

export type Query_RootCustom_DomainArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Order_By>>;
  where?: InputMaybe<Custom_Domain_Bool_Exp>;
};

export type Query_RootCustom_Domain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Order_By>>;
  where?: InputMaybe<Custom_Domain_Bool_Exp>;
};

export type Query_RootCustom_Domain_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCustom_Domain_CloudflareArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Cloudflare_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Cloudflare_Order_By>>;
  where?: InputMaybe<Custom_Domain_Cloudflare_Bool_Exp>;
};

export type Query_RootCustom_Domain_Cloudflare_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Cloudflare_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Cloudflare_Order_By>>;
  where?: InputMaybe<Custom_Domain_Cloudflare_Bool_Exp>;
};

export type Query_RootCustom_Domain_Cloudflare_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCustom_Domain_Cloudflare_DnsArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Cloudflare_Dns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Cloudflare_Dns_Order_By>>;
  where?: InputMaybe<Custom_Domain_Cloudflare_Dns_Bool_Exp>;
};

export type Query_RootCustom_Domain_Cloudflare_Dns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Cloudflare_Dns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Cloudflare_Dns_Order_By>>;
  where?: InputMaybe<Custom_Domain_Cloudflare_Dns_Bool_Exp>;
};

export type Query_RootCustom_Domain_Cloudflare_Dns_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootCustomer_UsageArgs = {
  distinct_on?: InputMaybe<Array<Customer_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Usage_Order_By>>;
  where?: InputMaybe<Customer_Usage_Bool_Exp>;
};

export type Query_RootCustomer_Usage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Usage_Order_By>>;
  where?: InputMaybe<Customer_Usage_Bool_Exp>;
};

export type Query_RootCustomer_Usage_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootData_Connector_TypeArgs = {
  distinct_on?: InputMaybe<Array<Data_Connector_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Data_Connector_Type_Order_By>>;
  where?: InputMaybe<Data_Connector_Type_Bool_Exp>;
};

export type Query_RootData_Connector_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Data_Connector_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Data_Connector_Type_Order_By>>;
  where?: InputMaybe<Data_Connector_Type_Bool_Exp>;
};

export type Query_RootData_Connector_Type_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootDatadog_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Datadog_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Datadog_Config_Order_By>>;
  where?: InputMaybe<Datadog_Config_Bool_Exp>;
};

export type Query_RootDatadog_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Datadog_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Datadog_Config_Order_By>>;
  where?: InputMaybe<Datadog_Config_Bool_Exp>;
};

export type Query_RootDatadog_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Query_RootDb_LatencyArgs = {
  distinct_on?: InputMaybe<Array<Db_Latency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Db_Latency_Order_By>>;
  where?: InputMaybe<Db_Latency_Bool_Exp>;
};

export type Query_RootDb_Latency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Db_Latency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Db_Latency_Order_By>>;
  where?: InputMaybe<Db_Latency_Bool_Exp>;
};

export type Query_RootDb_Latency_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdnGetInvoiceAndReceiptUrLsArgs = {
  invoice_id: Scalars['String'];
};

export type Query_RootDdnHasuraSecretListArgs = {
  environment?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['String']>;
  project_name?: InputMaybe<Scalars['String']>;
  subgraph?: InputMaybe<Scalars['String']>;
};

export type Query_RootDdnIsCollaborationRequestPresentArgs = {
  project_id?: InputMaybe<Scalars['uuid']>;
  project_name?: InputMaybe<Scalars['String']>;
};

export type Query_RootDdnIsProjectCreationLimitReachedArgs = {
  plan_name: DdnPlan;
  user_id: Scalars['uuid'];
};

export type Query_RootDdnProjectEntitiesCostPerMonthArgs = {
  month: Scalars['Int'];
  project_name: Scalars['String'];
  year: Scalars['Int'];
};

export type Query_RootDdn_Applied_Build_ChangelogArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Applied_Build_Changelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Applied_Build_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Applied_Build_Changelog_Bool_Exp>;
};

export type Query_RootDdn_Applied_Build_Changelog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Applied_Build_Changelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Applied_Build_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Applied_Build_Changelog_Bool_Exp>;
};

export type Query_RootDdn_Applied_Build_Changelog_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Applied_Subgraph_Build_ChangelogArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Applied_Subgraph_Build_Changelog_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Applied_Subgraph_Build_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp>;
};

export type Query_RootDdn_Applied_Subgraph_Build_Changelog_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Applied_Subgraph_Build_Changelog_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Applied_Subgraph_Build_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp>;
};

export type Query_RootDdn_Applied_Subgraph_Build_Changelog_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Auto_Approve_Project_RequestArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Auto_Approve_Project_Request_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Auto_Approve_Project_Request_Order_By>>;
  where?: InputMaybe<Ddn_Auto_Approve_Project_Request_Bool_Exp>;
};

export type Query_RootDdn_Auto_Approve_Project_Request_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Auto_Approve_Project_Request_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Auto_Approve_Project_Request_Order_By>>;
  where?: InputMaybe<Ddn_Auto_Approve_Project_Request_Bool_Exp>;
};

export type Query_RootDdn_Auto_Approve_Project_Request_By_PkArgs = {
  auto_approve_project_request: Scalars['Boolean'];
  project_id: Scalars['uuid'];
};

export type Query_RootDdn_BuildArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Order_By>>;
  where?: InputMaybe<Ddn_Build_Bool_Exp>;
};

export type Query_RootDdn_Build_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Order_By>>;
  where?: InputMaybe<Ddn_Build_Bool_Exp>;
};

export type Query_RootDdn_Build_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Build_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Details_Order_By>>;
  where?: InputMaybe<Ddn_Build_Details_Bool_Exp>;
};

export type Query_RootDdn_Build_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Details_Order_By>>;
  where?: InputMaybe<Ddn_Build_Details_Bool_Exp>;
};

export type Query_RootDdn_Build_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Build_LockArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Lock_Order_By>>;
  where?: InputMaybe<Ddn_Build_Lock_Bool_Exp>;
};

export type Query_RootDdn_Build_Lock_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Lock_Order_By>>;
  where?: InputMaybe<Ddn_Build_Lock_Bool_Exp>;
};

export type Query_RootDdn_Build_Lock_By_PkArgs = {
  environment_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

export type Query_RootDdn_Build_Model_CountArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Model_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Model_Count_Order_By>>;
  where?: InputMaybe<Ddn_Build_Model_Count_Bool_Exp>;
};

export type Query_RootDdn_Build_Model_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Model_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Model_Count_Order_By>>;
  where?: InputMaybe<Ddn_Build_Model_Count_Bool_Exp>;
};

export type Query_RootDdn_Build_RevisionArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Revision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Revision_Order_By>>;
  where?: InputMaybe<Ddn_Build_Revision_Bool_Exp>;
};

export type Query_RootDdn_Build_Revision_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Revision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Revision_Order_By>>;
  where?: InputMaybe<Ddn_Build_Revision_Bool_Exp>;
};

export type Query_RootDdn_Build_Revision_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Build_Revision_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Revision_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Revision_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Revision_Status_Bool_Exp>;
};

export type Query_RootDdn_Build_Revision_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Revision_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Revision_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Revision_Status_Bool_Exp>;
};

export type Query_RootDdn_Build_Revision_Status_By_PkArgs = {
  status: Scalars['String'];
};

export type Query_RootDdn_Build_Sync_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
};

export type Query_RootDdn_Build_Sync_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
};

export type Query_RootDdn_Build_Sync_Status_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Build_Sync_WorkerArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Worker_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Worker_Bool_Exp>;
};

export type Query_RootDdn_Build_Sync_Worker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Worker_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Worker_Bool_Exp>;
};

export type Query_RootDdn_Build_Sync_Worker_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Collaboration_Invitee_Project_ViewArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Collaboration_Invitee_Project_View_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Collaboration_Invitee_Project_View_Order_By>>;
  where?: InputMaybe<Ddn_Collaboration_Invitee_Project_View_Bool_Exp>;
};

export type Query_RootDdn_Collaboration_Invitee_Project_View_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Collaboration_Invitee_Project_View_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Collaboration_Invitee_Project_View_Order_By>>;
  where?: InputMaybe<Ddn_Collaboration_Invitee_Project_View_Bool_Exp>;
};

export type Query_RootDdn_Connector_DeploymentArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Connector_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Connector_Deployment_Order_By>>;
  where?: InputMaybe<Ddn_Connector_Deployment_Bool_Exp>;
};

export type Query_RootDdn_Connector_Deployment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Connector_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Connector_Deployment_Order_By>>;
  where?: InputMaybe<Ddn_Connector_Deployment_Bool_Exp>;
};

export type Query_RootDdn_Connector_Deployment_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootDdn_DdnArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Ddn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Ddn_Order_By>>;
  where?: InputMaybe<Ddn_Ddn_Bool_Exp>;
};

export type Query_RootDdn_Ddn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Ddn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Ddn_Order_By>>;
  where?: InputMaybe<Ddn_Ddn_Bool_Exp>;
};

export type Query_RootDdn_Ddn_By_PkArgs = {
  kind: Scalars['String'];
};

export type Query_RootDdn_EnvironmentArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Environment_Order_By>>;
  where?: InputMaybe<Ddn_Environment_Bool_Exp>;
};

export type Query_RootDdn_Environment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Environment_Order_By>>;
  where?: InputMaybe<Ddn_Environment_Bool_Exp>;
};

export type Query_RootDdn_Environment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Generate_Metadata_Artifacts_LogArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Generate_Metadata_Artifacts_Log_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Generate_Metadata_Artifacts_Log_Order_By>>;
  where?: InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp>;
};

export type Query_RootDdn_Generate_Metadata_Artifacts_Log_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Generate_Metadata_Artifacts_Log_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Generate_Metadata_Artifacts_Log_Order_By>>;
  where?: InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp>;
};

export type Query_RootDdn_Generate_Metadata_Artifacts_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Get_Token_Access_LevelArgs = {
  args: Ddn_Get_Token_Access_Level_Args;
  distinct_on?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Order_By>>;
  where?: InputMaybe<Ddn_Token_Access_Level_Output_Bool_Exp>;
};

export type Query_RootDdn_Get_Token_Access_Level_AggregateArgs = {
  args: Ddn_Get_Token_Access_Level_Args;
  distinct_on?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Order_By>>;
  where?: InputMaybe<Ddn_Token_Access_Level_Output_Bool_Exp>;
};

export type Query_RootDdn_InvoiceArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Bool_Exp>;
};

export type Query_RootDdn_Invoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Bool_Exp>;
};

export type Query_RootDdn_Invoice_By_PkArgs = {
  stripe_invoice_id: Scalars['String'];
};

export type Query_RootDdn_Invoice_ItemArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Item_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
};

export type Query_RootDdn_Invoice_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Item_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
};

export type Query_RootDdn_Invoice_Item_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Plan_Entitlement_AccessArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Ddn_Plan_Entitlement_Access_Bool_Exp>;
};

export type Query_RootDdn_Plan_Entitlement_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Ddn_Plan_Entitlement_Access_Bool_Exp>;
};

export type Query_RootDdn_Plan_Entitlement_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_PlansArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Plans_Order_By>>;
  where?: InputMaybe<Ddn_Plans_Bool_Exp>;
};

export type Query_RootDdn_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Plans_Order_By>>;
  where?: InputMaybe<Ddn_Plans_Bool_Exp>;
};

export type Query_RootDdn_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Private_DdnArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Private_Ddn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Private_Ddn_Order_By>>;
  where?: InputMaybe<Ddn_Private_Ddn_Bool_Exp>;
};

export type Query_RootDdn_Private_Ddn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Private_Ddn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Private_Ddn_Order_By>>;
  where?: InputMaybe<Ddn_Private_Ddn_Bool_Exp>;
};

export type Query_RootDdn_Private_Ddn_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Private_Ddn_RegionArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Private_Ddn_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Private_Ddn_Region_Order_By>>;
  where?: InputMaybe<Ddn_Private_Ddn_Region_Bool_Exp>;
};

export type Query_RootDdn_Private_Ddn_Region_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Private_Ddn_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Private_Ddn_Region_Order_By>>;
  where?: InputMaybe<Ddn_Private_Ddn_Region_Bool_Exp>;
};

export type Query_RootDdn_Private_Ddn_Region_By_PkArgs = {
  cloud: Scalars['String'];
  private_ddn_id: Scalars['uuid'];
  region: Scalars['String'];
};

export type Query_RootDdn_Project_Access_LevelArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Access_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Access_Level_Order_By>>;
  where?: InputMaybe<Ddn_Project_Access_Level_Bool_Exp>;
};

export type Query_RootDdn_Project_Access_Level_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Access_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Access_Level_Order_By>>;
  where?: InputMaybe<Ddn_Project_Access_Level_Bool_Exp>;
};

export type Query_RootDdn_Project_Access_Level_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootDdn_Project_Active_Status_ChangelogArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Active_Status_Changelog_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Active_Status_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Project_Active_Status_Changelog_Bool_Exp>;
};

export type Query_RootDdn_Project_Active_Status_Changelog_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Active_Status_Changelog_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Active_Status_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Project_Active_Status_Changelog_Bool_Exp>;
};

export type Query_RootDdn_Project_Active_Status_Changelog_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Project_Active_Status_ReasonArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Active_Status_Reason_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Active_Status_Reason_Order_By>>;
  where?: InputMaybe<Ddn_Project_Active_Status_Reason_Bool_Exp>;
};

export type Query_RootDdn_Project_Active_Status_Reason_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Active_Status_Reason_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Active_Status_Reason_Order_By>>;
  where?: InputMaybe<Ddn_Project_Active_Status_Reason_Bool_Exp>;
};

export type Query_RootDdn_Project_Active_Status_Reason_By_PkArgs = {
  reason: Scalars['String'];
};

export type Query_RootDdn_Project_Entitlement_AccessArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Access_Bool_Exp>;
};

export type Query_RootDdn_Project_Entitlement_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Access_Bool_Exp>;
};

export type Query_RootDdn_Project_Entitlement_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Project_Entitlement_CatalogueArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Entitlement_Catalogue_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Catalogue_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Bool_Exp>;
};

export type Query_RootDdn_Project_Entitlement_Catalogue_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Entitlement_Catalogue_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Catalogue_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Bool_Exp>;
};

export type Query_RootDdn_Project_Entitlement_Catalogue_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Project_Entitlement_TypesArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Types_Bool_Exp>;
};

export type Query_RootDdn_Project_Entitlement_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Types_Bool_Exp>;
};

export type Query_RootDdn_Project_Entitlement_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootDdn_Project_Plan_ChangelogArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Project_Plan_Changelog_Bool_Exp>;
};

export type Query_RootDdn_Project_Plan_Changelog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Project_Plan_Changelog_Bool_Exp>;
};

export type Query_RootDdn_Project_Plan_Changelog_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Project_Request_AccessArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Request_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Request_Access_Order_By>>;
  where?: InputMaybe<Ddn_Project_Request_Access_Bool_Exp>;
};

export type Query_RootDdn_Project_Request_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Request_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Request_Access_Order_By>>;
  where?: InputMaybe<Ddn_Project_Request_Access_Bool_Exp>;
};

export type Query_RootDdn_Project_Request_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Projects_Order_By>>;
  where?: InputMaybe<Ddn_Projects_Bool_Exp>;
};

export type Query_RootDdn_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Projects_Order_By>>;
  where?: InputMaybe<Ddn_Projects_Bool_Exp>;
};

export type Query_RootDdn_Projects_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Search_Tunnel_ClusterArgs = {
  args?: InputMaybe<Ddn_Search_Tunnel_Cluster_Args>;
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Cluster_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Cluster_Bool_Exp>;
};

export type Query_RootDdn_Search_Tunnel_Cluster_AggregateArgs = {
  args?: InputMaybe<Ddn_Search_Tunnel_Cluster_Args>;
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Cluster_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Cluster_Bool_Exp>;
};

export type Query_RootDdn_Secret_KeyArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Secret_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Secret_Key_Order_By>>;
  where?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
};

export type Query_RootDdn_Secret_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Secret_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Secret_Key_Order_By>>;
  where?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
};

export type Query_RootDdn_Secret_Key_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Stripe_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Stripe_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Stripe_Subscription_Order_By>>;
  where?: InputMaybe<Ddn_Stripe_Subscription_Bool_Exp>;
};

export type Query_RootDdn_Stripe_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Stripe_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Stripe_Subscription_Order_By>>;
  where?: InputMaybe<Ddn_Stripe_Subscription_Bool_Exp>;
};

export type Query_RootDdn_Stripe_Subscription_By_PkArgs = {
  customer_id: Scalars['String'];
  subscription_id: Scalars['String'];
};

export type Query_RootDdn_Stripe_Webhook_EventsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Stripe_Webhook_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Stripe_Webhook_Events_Order_By>>;
  where?: InputMaybe<Ddn_Stripe_Webhook_Events_Bool_Exp>;
};

export type Query_RootDdn_Stripe_Webhook_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Stripe_Webhook_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Stripe_Webhook_Events_Order_By>>;
  where?: InputMaybe<Ddn_Stripe_Webhook_Events_Bool_Exp>;
};

export type Query_RootDdn_Stripe_Webhook_Events_By_PkArgs = {
  stripe_event_id: Scalars['String'];
};

export type Query_RootDdn_SubgraphArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Subgraph_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Subgraph_Order_By>>;
  where?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
};

export type Query_RootDdn_Subgraph_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Subgraph_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Subgraph_Order_By>>;
  where?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
};

export type Query_RootDdn_Subgraph_BuildArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Subgraph_Build_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Subgraph_Build_Order_By>>;
  where?: InputMaybe<Ddn_Subgraph_Build_Bool_Exp>;
};

export type Query_RootDdn_Subgraph_Build_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Subgraph_Build_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Subgraph_Build_Order_By>>;
  where?: InputMaybe<Ddn_Subgraph_Build_Bool_Exp>;
};

export type Query_RootDdn_Subgraph_Build_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Subgraph_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_TeamArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Order_By>>;
  where?: InputMaybe<Ddn_Team_Bool_Exp>;
};

export type Query_RootDdn_Team_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Order_By>>;
  where?: InputMaybe<Ddn_Team_Bool_Exp>;
};

export type Query_RootDdn_Team_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Team_InvitationArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Invitation_Order_By>>;
  where?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
};

export type Query_RootDdn_Team_Invitation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Invitation_Order_By>>;
  where?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
};

export type Query_RootDdn_Team_Invitation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Team_Invitation_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Invitation_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Invitation_Status_Order_By>>;
  where?: InputMaybe<Ddn_Team_Invitation_Status_Bool_Exp>;
};

export type Query_RootDdn_Team_Invitation_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Invitation_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Invitation_Status_Order_By>>;
  where?: InputMaybe<Ddn_Team_Invitation_Status_Bool_Exp>;
};

export type Query_RootDdn_Team_Invitation_Status_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootDdn_Team_KindArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Kind_Order_By>>;
  where?: InputMaybe<Ddn_Team_Kind_Bool_Exp>;
};

export type Query_RootDdn_Team_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Kind_Order_By>>;
  where?: InputMaybe<Ddn_Team_Kind_Bool_Exp>;
};

export type Query_RootDdn_Team_Kind_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootDdn_Team_MembershipArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Membership_Order_By>>;
  where?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
};

export type Query_RootDdn_Team_Membership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Membership_Order_By>>;
  where?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
};

export type Query_RootDdn_Team_Membership_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Team_Project_AccessArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
};

export type Query_RootDdn_Team_Project_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
};

export type Query_RootDdn_Team_Project_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Token_Access_Level_OutputArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Order_By>>;
  where?: InputMaybe<Ddn_Token_Access_Level_Output_Bool_Exp>;
};

export type Query_RootDdn_Token_Access_Level_Output_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Order_By>>;
  where?: InputMaybe<Ddn_Token_Access_Level_Output_Bool_Exp>;
};

export type Query_RootDdn_TunnelArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Bool_Exp>;
};

export type Query_RootDdn_Tunnel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Bool_Exp>;
};

export type Query_RootDdn_Tunnel_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Tunnel_ClusterArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Cluster_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Cluster_Bool_Exp>;
};

export type Query_RootDdn_Tunnel_Cluster_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Cluster_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Cluster_Bool_Exp>;
};

export type Query_RootDdn_Tunnel_Cluster_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Tunnel_HttpArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Http_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Http_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Http_Bool_Exp>;
};

export type Query_RootDdn_Tunnel_Http_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Http_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Http_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Http_Bool_Exp>;
};

export type Query_RootDdn_Tunnel_Http_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_Tunnel_Http_ClusterArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Http_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Http_Cluster_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Http_Cluster_Bool_Exp>;
};

export type Query_RootDdn_Tunnel_Http_Cluster_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Http_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Http_Cluster_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Http_Cluster_Bool_Exp>;
};

export type Query_RootDdn_Tunnel_Http_Cluster_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDdn_User_Project_AccessArgs = {
  distinct_on?: InputMaybe<Array<Ddn_User_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_User_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
};

export type Query_RootDdn_User_Project_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_User_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_User_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
};

export type Query_RootDdn_V3_CustomerArgs = {
  distinct_on?: InputMaybe<Array<Ddn_V3_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_V3_Customer_Order_By>>;
  where?: InputMaybe<Ddn_V3_Customer_Bool_Exp>;
};

export type Query_RootDdn_V3_Customer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_V3_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_V3_Customer_Order_By>>;
  where?: InputMaybe<Ddn_V3_Customer_Bool_Exp>;
};

export type Query_RootDedicated_Cloud_BillsArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
};

export type Query_RootDedicated_Cloud_Bills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
};

export type Query_RootDedicated_Cloud_Bills_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDedicated_Cloud_Bills_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Details_Bool_Exp>;
};

export type Query_RootDedicated_Cloud_Bills_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Details_Bool_Exp>;
};

export type Query_RootDedicated_Cloud_Bills_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDedicated_Cloud_CommitmentsArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Commitments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Commitments_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Commitments_Bool_Exp>;
};

export type Query_RootDedicated_Cloud_Commitments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Commitments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Commitments_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Commitments_Bool_Exp>;
};

export type Query_RootDedicated_Cloud_Commitments_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDedicated_VpcArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Vpc_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Vpc_Order_By>>;
  where?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
};

export type Query_RootDedicated_Vpc_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Vpc_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Vpc_Order_By>>;
  where?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
};

export type Query_RootDedicated_Vpc_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootDedicated_Vpc_OwnerArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Vpc_Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Vpc_Owner_Order_By>>;
  where?: InputMaybe<Dedicated_Vpc_Owner_Bool_Exp>;
};

export type Query_RootDedicated_Vpc_Owner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Vpc_Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Vpc_Owner_Order_By>>;
  where?: InputMaybe<Dedicated_Vpc_Owner_Bool_Exp>;
};

export type Query_RootDelete_UserArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Order_By>>;
  where?: InputMaybe<Delete_User_Bool_Exp>;
};

export type Query_RootDelete_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Order_By>>;
  where?: InputMaybe<Delete_User_Bool_Exp>;
};

export type Query_RootDelete_User_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Query_RootDelete_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Status_Order_By>>;
  where?: InputMaybe<Delete_User_Status_Bool_Exp>;
};

export type Query_RootDelete_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Status_Order_By>>;
  where?: InputMaybe<Delete_User_Status_Bool_Exp>;
};

export type Query_RootDelete_User_Status_By_PkArgs = {
  status: Scalars['String'];
};

export type Query_RootDelete_User_TasksArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Tasks_Order_By>>;
  where?: InputMaybe<Delete_User_Tasks_Bool_Exp>;
};

export type Query_RootDelete_User_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Tasks_Order_By>>;
  where?: InputMaybe<Delete_User_Tasks_Bool_Exp>;
};

export type Query_RootDelete_User_Tasks_By_PkArgs = {
  task_name: Scalars['String'];
  user_id: Scalars['uuid'];
};

export type Query_RootEmail_LogArgs = {
  distinct_on?: InputMaybe<Array<Email_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Log_Order_By>>;
  where?: InputMaybe<Email_Log_Bool_Exp>;
};

export type Query_RootEmail_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Log_Order_By>>;
  where?: InputMaybe<Email_Log_Bool_Exp>;
};

export type Query_RootEmail_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootEnterprise_UsersArgs = {
  distinct_on?: InputMaybe<Array<Enterprise_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enterprise_Users_Order_By>>;
  where?: InputMaybe<Enterprise_Users_Bool_Exp>;
};

export type Query_RootEnterprise_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enterprise_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enterprise_Users_Order_By>>;
  where?: InputMaybe<Enterprise_Users_Bool_Exp>;
};

export type Query_RootEnterprise_Users_By_PkArgs = {
  customer_email: Scalars['citext'];
};

export type Query_RootEnterprise_Users_DomainArgs = {
  distinct_on?: InputMaybe<Array<Enterprise_Users_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enterprise_Users_Domain_Order_By>>;
  where?: InputMaybe<Enterprise_Users_Domain_Bool_Exp>;
};

export type Query_RootEnterprise_Users_Domain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enterprise_Users_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enterprise_Users_Domain_Order_By>>;
  where?: InputMaybe<Enterprise_Users_Domain_Bool_Exp>;
};

export type Query_RootEnterprise_Users_Domain_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootExperimentsArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Order_By>>;
  where?: InputMaybe<Experiments_Bool_Exp>;
};

export type Query_RootExperiments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Order_By>>;
  where?: InputMaybe<Experiments_Bool_Exp>;
};

export type Query_RootExperiments_By_PkArgs = {
  experiment: Scalars['String'];
};

export type Query_RootExperiments_CohortArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Cohort_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Cohort_Order_By>>;
  where?: InputMaybe<Experiments_Cohort_Bool_Exp>;
};

export type Query_RootExperiments_Cohort_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Cohort_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Cohort_Order_By>>;
  where?: InputMaybe<Experiments_Cohort_Bool_Exp>;
};

export type Query_RootExperiments_Cohort_By_PkArgs = {
  experiment: Experiments_Enum;
  user_id: Scalars['uuid'];
};

export type Query_RootExperiments_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Config_Order_By>>;
  where?: InputMaybe<Experiments_Config_Bool_Exp>;
};

export type Query_RootExperiments_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Config_Order_By>>;
  where?: InputMaybe<Experiments_Config_Bool_Exp>;
};

export type Query_RootExperiments_Config_By_PkArgs = {
  experiment: Experiments_Enum;
};

export type Query_RootFeatureArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Query_RootFeature_AccessArgs = {
  distinct_on?: InputMaybe<Array<Feature_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Access_Order_By>>;
  where?: InputMaybe<Feature_Access_Bool_Exp>;
};

export type Query_RootFeature_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Access_Order_By>>;
  where?: InputMaybe<Feature_Access_Bool_Exp>;
};

export type Query_RootFeature_Access_By_PkArgs = {
  email: Scalars['String'];
  feature: Feature_Enum;
};

export type Query_RootFeature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Query_RootFeature_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootFeature_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Feature_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Config_Order_By>>;
  where?: InputMaybe<Feature_Config_Bool_Exp>;
};

export type Query_RootFeature_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Config_Order_By>>;
  where?: InputMaybe<Feature_Config_Bool_Exp>;
};

export type Query_RootFeature_Config_By_PkArgs = {
  feature: Feature_Enum;
};

export type Query_RootFetchDedicatedCloudClusterBillsArgs = {
  month: Scalars['Int'];
  user_id: Scalars['uuid'];
  year: Scalars['Int'];
};

export type Query_RootFetchUserFacingMetadataArgs = {
  build_id: Scalars['uuid'];
};

export type Query_RootFetchUserSupportTicketDetailsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type Query_RootGateway_AmiArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Ami_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Ami_Order_By>>;
  where?: InputMaybe<Gateway_Ami_Bool_Exp>;
};

export type Query_RootGateway_Ami_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Ami_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Ami_Order_By>>;
  where?: InputMaybe<Gateway_Ami_Bool_Exp>;
};

export type Query_RootGateway_Ami_By_PkArgs = {
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  region: Scalars['String'];
};

export type Query_RootGateway_ClusterArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Cluster_Order_By>>;
  where?: InputMaybe<Gateway_Cluster_Bool_Exp>;
};

export type Query_RootGateway_Cluster_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Cluster_Order_By>>;
  where?: InputMaybe<Gateway_Cluster_Bool_Exp>;
};

export type Query_RootGateway_Cluster_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGateway_Cluster_TenantsArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Cluster_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Cluster_Tenants_Order_By>>;
  where?: InputMaybe<Gateway_Cluster_Tenants_Bool_Exp>;
};

export type Query_RootGateway_Cluster_Tenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Cluster_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Cluster_Tenants_Order_By>>;
  where?: InputMaybe<Gateway_Cluster_Tenants_Bool_Exp>;
};

export type Query_RootGateway_WorkerArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Worker_Order_By>>;
  where?: InputMaybe<Gateway_Worker_Bool_Exp>;
};

export type Query_RootGateway_Worker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Worker_Order_By>>;
  where?: InputMaybe<Gateway_Worker_Bool_Exp>;
};

export type Query_RootGateway_Worker_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGetComputeUnitAllocationArgs = {
  cloud: CloudProvider;
  cluster_id: Scalars['String'];
  query_time: PrometheusQueryTime;
  roll_up_duration: PrometheusTimeDuration;
};

export type Query_RootGetComputeUnitUsageArgs = {
  cloud: CloudProvider;
  cluster_id: Scalars['String'];
  query_time: PrometheusQueryTime;
  roll_up_duration: PrometheusTimeDuration;
};

export type Query_RootGetInvoiceAndReceiptUrLsArgs = {
  invoice_id: Scalars['String'];
};

export type Query_RootGetLetsEncryptStatusArgs = {
  fqdn: Scalars['String'];
};

export type Query_RootGetNumberOfConnectedSourcesArgs = {
  projectId: Scalars['uuid'];
};

export type Query_RootGetProjectMigrationStatusArgs = {
  payload: ProjectMigrationStatusInput;
};

export type Query_RootGetReportUrlArgs = {
  key: Scalars['String'];
};

export type Query_RootGetTenantEnvArgs = {
  tenantId: Scalars['uuid'];
};

export type Query_RootGetTenantEnvConfigInfoArgs = {
  tenantId: Scalars['uuid'];
};

export type Query_RootGet_Aggregated_Cost_For_Project_On_Shared_PlanArgs = {
  args: Get_Aggregated_Cost_For_Project_On_Shared_Plan_Args;
  distinct_on?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Total_Db_Usage_Agg_Bool_Exp>;
};

export type Query_RootGet_Aggregated_Cost_For_Project_On_Shared_Plan_AggregateArgs = {
  args: Get_Aggregated_Cost_For_Project_On_Shared_Plan_Args;
  distinct_on?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Total_Db_Usage_Agg_Bool_Exp>;
};

export type Query_RootGithub_Email_TypeArgs = {
  distinct_on?: InputMaybe<Array<Github_Email_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Email_Type_Order_By>>;
  where?: InputMaybe<Github_Email_Type_Bool_Exp>;
};

export type Query_RootGithub_Email_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Email_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Email_Type_Order_By>>;
  where?: InputMaybe<Github_Email_Type_Bool_Exp>;
};

export type Query_RootGithub_Email_Type_By_PkArgs = {
  email_type: Scalars['String'];
};

export type Query_RootGithub_Integration_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Github_Integration_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Integration_Config_Order_By>>;
  where?: InputMaybe<Github_Integration_Config_Bool_Exp>;
};

export type Query_RootGithub_Integration_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Integration_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Integration_Config_Order_By>>;
  where?: InputMaybe<Github_Integration_Config_Bool_Exp>;
};

export type Query_RootGithub_Integration_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGithub_Integration_ModeArgs = {
  distinct_on?: InputMaybe<Array<Github_Integration_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Integration_Mode_Order_By>>;
  where?: InputMaybe<Github_Integration_Mode_Bool_Exp>;
};

export type Query_RootGithub_Integration_Mode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Integration_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Integration_Mode_Order_By>>;
  where?: InputMaybe<Github_Integration_Mode_Bool_Exp>;
};

export type Query_RootGithub_Integration_Mode_By_PkArgs = {
  mode: Scalars['String'];
};

export type Query_RootGithub_Push_EventArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Bool_Exp>;
};

export type Query_RootGithub_Push_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Bool_Exp>;
};

export type Query_RootGithub_Push_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootGithub_Push_Event_JobArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Job_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
};

export type Query_RootGithub_Push_Event_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Job_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
};

export type Query_RootGithub_Push_Event_Job_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHasura_AmiArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Ami_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Ami_Order_By>>;
  where?: InputMaybe<Hasura_Ami_Bool_Exp>;
};

export type Query_RootHasura_Ami_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Ami_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Ami_Order_By>>;
  where?: InputMaybe<Hasura_Ami_Bool_Exp>;
};

export type Query_RootHasura_Ami_By_PkArgs = {
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  region: Scalars['String'];
};

export type Query_RootHasura_ClusterArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Cluster_Order_By>>;
  where?: InputMaybe<Hasura_Cluster_Bool_Exp>;
};

export type Query_RootHasura_Cluster_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Cluster_Order_By>>;
  where?: InputMaybe<Hasura_Cluster_Bool_Exp>;
};

export type Query_RootHasura_Cluster_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHasura_WorkerArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Worker_Order_By>>;
  where?: InputMaybe<Hasura_Worker_Bool_Exp>;
};

export type Query_RootHasura_Worker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Worker_Order_By>>;
  where?: InputMaybe<Hasura_Worker_Bool_Exp>;
};

export type Query_RootHasura_Worker_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHasura_Worker_TenantsArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Worker_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Worker_Tenants_Order_By>>;
  where?: InputMaybe<Hasura_Worker_Tenants_Bool_Exp>;
};

export type Query_RootHasura_Worker_Tenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Worker_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Worker_Tenants_Order_By>>;
  where?: InputMaybe<Hasura_Worker_Tenants_Bool_Exp>;
};

export type Query_RootHasura_Year_In_Report_ActivityArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Year_In_Report_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Year_In_Report_Activity_Order_By>>;
  where?: InputMaybe<Hasura_Year_In_Report_Activity_Bool_Exp>;
};

export type Query_RootHasura_Year_In_Report_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Year_In_Report_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Year_In_Report_Activity_Order_By>>;
  where?: InputMaybe<Hasura_Year_In_Report_Activity_Bool_Exp>;
};

export type Query_RootHasura_Year_In_Report_Activity_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Query_RootHasura_Year_In_Report_MetricsArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Year_In_Report_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Year_In_Report_Metrics_Order_By>>;
  where?: InputMaybe<Hasura_Year_In_Report_Metrics_Bool_Exp>;
};

export type Query_RootHasura_Year_In_Report_Metrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Year_In_Report_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Year_In_Report_Metrics_Order_By>>;
  where?: InputMaybe<Hasura_Year_In_Report_Metrics_Bool_Exp>;
};

export type Query_RootHasura_Year_In_Report_Metrics_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootHeroku_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Heroku_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Heroku_Integrations_Order_By>>;
  where?: InputMaybe<Heroku_Integrations_Bool_Exp>;
};

export type Query_RootHeroku_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Heroku_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Heroku_Integrations_Order_By>>;
  where?: InputMaybe<Heroku_Integrations_Bool_Exp>;
};

export type Query_RootHeroku_Integrations_By_PkArgs = {
  project_id: Scalars['uuid'];
  var_name: Scalars['String'];
};

export type Query_RootHit_Count_MonthlyArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hit_Count_Monthly_Order_By>>;
  where?: InputMaybe<Hit_Count_Monthly_Bool_Exp>;
};

export type Query_RootHit_Count_Monthly_AggregateArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hit_Count_Monthly_Order_By>>;
  where?: InputMaybe<Hit_Count_Monthly_Bool_Exp>;
};

export type Query_RootHit_Count_Monthly_By_PkArgs = {
  entity_name: Scalars['string'];
  entity_type: Scalars['string'];
  environment_id: Scalars['UUID'];
  month: Scalars['u_int8'];
  project_id: Scalars['UUID'];
  subgraph: Scalars['string'];
  year: Scalars['u_int16'];
};

export type Query_RootHit_Count_Monthly_MvArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hit_Count_Monthly_Mv_Order_By>>;
  where?: InputMaybe<Hit_Count_Monthly_Mv_Bool_Exp>;
};

export type Query_RootHit_Count_Monthly_Mv_AggregateArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hit_Count_Monthly_Mv_Order_By>>;
  where?: InputMaybe<Hit_Count_Monthly_Mv_Bool_Exp>;
};

export type Query_RootHit_Count_Per_DayArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hit_Count_Per_Day_Order_By>>;
  where?: InputMaybe<Hit_Count_Per_Day_Bool_Exp>;
};

export type Query_RootHit_Count_Per_Day_AggregateArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hit_Count_Per_Day_Order_By>>;
  where?: InputMaybe<Hit_Count_Per_Day_Bool_Exp>;
};

export type Query_RootHit_Count_Per_Day_By_PkArgs = {
  date: Scalars['date'];
  entity_name: Scalars['string'];
  entity_type: Scalars['string'];
  environment_id: Scalars['UUID'];
  project_id: Scalars['UUID'];
  subgraph: Scalars['string'];
};

export type Query_RootInactive_Project_ExclusionsArgs = {
  distinct_on?: InputMaybe<Array<Inactive_Project_Exclusions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Project_Exclusions_Order_By>>;
  where?: InputMaybe<Inactive_Project_Exclusions_Bool_Exp>;
};

export type Query_RootInactive_Project_Exclusions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inactive_Project_Exclusions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Project_Exclusions_Order_By>>;
  where?: InputMaybe<Inactive_Project_Exclusions_Bool_Exp>;
};

export type Query_RootInactive_Project_Exclusions_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Query_RootInactive_Project_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Inactive_Project_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Project_Notifications_Order_By>>;
  where?: InputMaybe<Inactive_Project_Notifications_Bool_Exp>;
};

export type Query_RootInactive_Project_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inactive_Project_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Project_Notifications_Order_By>>;
  where?: InputMaybe<Inactive_Project_Notifications_Bool_Exp>;
};

export type Query_RootInactive_Project_Notifications_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootInactive_Project_Suspension_ConfigArgs = {
  distinct_on?: InputMaybe<
    Array<Inactive_Project_Suspension_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Project_Suspension_Config_Order_By>>;
  where?: InputMaybe<Inactive_Project_Suspension_Config_Bool_Exp>;
};

export type Query_RootInactive_Project_Suspension_Config_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Inactive_Project_Suspension_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Project_Suspension_Config_Order_By>>;
  where?: InputMaybe<Inactive_Project_Suspension_Config_Bool_Exp>;
};

export type Query_RootInactive_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Inactive_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Projects_Order_By>>;
  where?: InputMaybe<Inactive_Projects_Bool_Exp>;
};

export type Query_RootInactive_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inactive_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Projects_Order_By>>;
  where?: InputMaybe<Inactive_Projects_Bool_Exp>;
};

export type Query_RootInfra_StatusArgs = {
  distinct_on?: InputMaybe<Array<Infra_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Infra_Status_Order_By>>;
  where?: InputMaybe<Infra_Status_Bool_Exp>;
};

export type Query_RootInfra_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Infra_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Infra_Status_Order_By>>;
  where?: InputMaybe<Infra_Status_Bool_Exp>;
};

export type Query_RootInfra_Status_By_PkArgs = {
  status: Scalars['String'];
};

export type Query_RootInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

export type Query_RootInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

export type Query_RootInvoice_By_PkArgs = {
  stripe_invoice_id: Scalars['String'];
};

export type Query_RootInvoice_Coupon_DiscountArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Coupon_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Coupon_Discount_Order_By>>;
  where?: InputMaybe<Invoice_Coupon_Discount_Bool_Exp>;
};

export type Query_RootInvoice_Coupon_Discount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Coupon_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Coupon_Discount_Order_By>>;
  where?: InputMaybe<Invoice_Coupon_Discount_Bool_Exp>;
};

export type Query_RootInvoice_Coupon_Discount_By_PkArgs = {
  inv_id: Scalars['String'];
};

export type Query_RootInvoice_ItemArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Item_Order_By>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};

export type Query_RootInvoice_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Item_Order_By>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};

export type Query_RootInvoice_Item_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

export type Query_RootJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

export type Query_RootJobs_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLabelArgs = {
  distinct_on?: InputMaybe<Array<Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Label_Order_By>>;
  where?: InputMaybe<Label_Bool_Exp>;
};

export type Query_RootLabel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Label_Order_By>>;
  where?: InputMaybe<Label_Bool_Exp>;
};

export type Query_RootLabel_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLanguageArgs = {
  code: Scalars['ID'];
};

export type Query_RootLanguagesArgs = {
  filter?: InputMaybe<LanguageFilterInput>;
};

export type Query_RootLetsencrypt_StatusArgs = {
  distinct_on?: InputMaybe<Array<Letsencrypt_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Letsencrypt_Status_Order_By>>;
  where?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
};

export type Query_RootLetsencrypt_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Letsencrypt_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Letsencrypt_Status_Order_By>>;
  where?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
};

export type Query_RootLetsencrypt_Status_By_PkArgs = {
  fqdn: Scalars['String'];
};

export type Query_RootLicense_ActivityArgs = {
  distinct_on?: InputMaybe<Array<License_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<License_Activity_Order_By>>;
  where?: InputMaybe<License_Activity_Bool_Exp>;
};

export type Query_RootLicense_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<License_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<License_Activity_Order_By>>;
  where?: InputMaybe<License_Activity_Bool_Exp>;
};

export type Query_RootLicense_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootLicense_InstanceArgs = {
  distinct_on?: InputMaybe<Array<License_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<License_Instance_Order_By>>;
  where?: InputMaybe<License_Instance_Bool_Exp>;
};

export type Query_RootLicense_Instance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<License_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<License_Instance_Order_By>>;
  where?: InputMaybe<License_Instance_Bool_Exp>;
};

export type Query_RootLicense_Instance_By_PkArgs = {
  metadata_db_id: Scalars['uuid'];
};

export type Query_RootLicense_TypeArgs = {
  distinct_on?: InputMaybe<Array<License_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<License_Type_Order_By>>;
  where?: InputMaybe<License_Type_Bool_Exp>;
};

export type Query_RootLicense_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<License_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<License_Type_Order_By>>;
  where?: InputMaybe<License_Type_Bool_Exp>;
};

export type Query_RootLicense_Type_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootLicensesArgs = {
  distinct_on?: InputMaybe<Array<Licenses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Licenses_Order_By>>;
  where?: InputMaybe<Licenses_Bool_Exp>;
};

export type Query_RootLicenses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Licenses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Licenses_Order_By>>;
  where?: InputMaybe<Licenses_Bool_Exp>;
};

export type Query_RootLicenses_By_PkArgs = {
  email: Scalars['String'];
};

export type Query_RootLux_ProxyArgs = {
  distinct_on?: InputMaybe<Array<Lux_Proxy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lux_Proxy_Order_By>>;
  where?: InputMaybe<Lux_Proxy_Bool_Exp>;
};

export type Query_RootLux_Proxy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lux_Proxy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lux_Proxy_Order_By>>;
  where?: InputMaybe<Lux_Proxy_Bool_Exp>;
};

export type Query_RootLux_Proxy_AmiArgs = {
  distinct_on?: InputMaybe<Array<Lux_Proxy_Ami_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lux_Proxy_Ami_Order_By>>;
  where?: InputMaybe<Lux_Proxy_Ami_Bool_Exp>;
};

export type Query_RootLux_Proxy_Ami_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lux_Proxy_Ami_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lux_Proxy_Ami_Order_By>>;
  where?: InputMaybe<Lux_Proxy_Ami_Bool_Exp>;
};

export type Query_RootLux_Proxy_Ami_By_PkArgs = {
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  region: Scalars['String'];
};

export type Query_RootLux_Proxy_By_PkArgs = {
  cloud: Scalars['String'];
  region: Scalars['String'];
};

export type Query_RootModel_Based_Enterprise_Cloud_CommitmentsArgs = {
  distinct_on?: InputMaybe<
    Array<Model_Based_Enterprise_Cloud_Commitments_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Model_Based_Enterprise_Cloud_Commitments_Order_By>
  >;
  where?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Bool_Exp>;
};

export type Query_RootModel_Based_Enterprise_Cloud_Commitments_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Model_Based_Enterprise_Cloud_Commitments_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Model_Based_Enterprise_Cloud_Commitments_Order_By>
  >;
  where?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Bool_Exp>;
};

export type Query_RootModel_Based_Enterprise_Cloud_Commitments_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootModel_Based_Pricing_Model_Lifecycle_ViewArgs = {
  distinct_on?: InputMaybe<
    Array<Model_Based_Pricing_Model_Lifecycle_View_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Model_Based_Pricing_Model_Lifecycle_View_Order_By>
  >;
  where?: InputMaybe<Model_Based_Pricing_Model_Lifecycle_View_Bool_Exp>;
};

export type Query_RootModel_Based_Pricing_Model_Lifecycle_View_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Model_Based_Pricing_Model_Lifecycle_View_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Model_Based_Pricing_Model_Lifecycle_View_Order_By>
  >;
  where?: InputMaybe<Model_Based_Pricing_Model_Lifecycle_View_Bool_Exp>;
};

export type Query_RootModel_Based_Pricing_Model_Snapshot_StatisticsArgs = {
  distinct_on?: InputMaybe<
    Array<Model_Based_Pricing_Model_Snapshot_Statistics_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Model_Based_Pricing_Model_Snapshot_Statistics_Order_By>
  >;
  where?: InputMaybe<Model_Based_Pricing_Model_Snapshot_Statistics_Bool_Exp>;
};

export type Query_RootModel_Based_Pricing_Model_Snapshot_Statistics_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Model_Based_Pricing_Model_Snapshot_Statistics_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Model_Based_Pricing_Model_Snapshot_Statistics_Order_By>
  >;
  where?: InputMaybe<Model_Based_Pricing_Model_Snapshot_Statistics_Bool_Exp>;
};

export type Query_RootNeon_Db_IntegrationArgs = {
  distinct_on?: InputMaybe<Array<Neon_Db_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Neon_Db_Integration_Order_By>>;
  where?: InputMaybe<Neon_Db_Integration_Bool_Exp>;
};

export type Query_RootNeon_Db_Integration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Neon_Db_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Neon_Db_Integration_Order_By>>;
  where?: InputMaybe<Neon_Db_Integration_Bool_Exp>;
};

export type Query_RootNeon_Db_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootNewrelic_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Newrelic_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Newrelic_Config_Order_By>>;
  where?: InputMaybe<Newrelic_Config_Bool_Exp>;
};

export type Query_RootNewrelic_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Newrelic_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Newrelic_Config_Order_By>>;
  where?: InputMaybe<Newrelic_Config_Bool_Exp>;
};

export type Query_RootNewrelic_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Query_RootNode_Pool_TypeArgs = {
  distinct_on?: InputMaybe<Array<Node_Pool_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Node_Pool_Type_Order_By>>;
  where?: InputMaybe<Node_Pool_Type_Bool_Exp>;
};

export type Query_RootNode_Pool_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Node_Pool_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Node_Pool_Type_Order_By>>;
  where?: InputMaybe<Node_Pool_Type_Bool_Exp>;
};

export type Query_RootNode_Pool_Type_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootNode_PoolsArgs = {
  distinct_on?: InputMaybe<Array<Node_Pools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Node_Pools_Order_By>>;
  where?: InputMaybe<Node_Pools_Bool_Exp>;
};

export type Query_RootNode_Pools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Node_Pools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Node_Pools_Order_By>>;
  where?: InputMaybe<Node_Pools_Bool_Exp>;
};

export type Query_RootNode_Pools_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootNotificationArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};

export type Query_RootNotification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};

export type Query_RootNotification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootNotification_TypeArgs = {
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

export type Query_RootNotification_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

export type Query_RootNotification_Type_By_PkArgs = {
  type: Scalars['String'];
};

export type Query_RootOnboarding_Sample_Db_CohortArgs = {
  distinct_on?: InputMaybe<Array<Onboarding_Sample_Db_Cohort_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Onboarding_Sample_Db_Cohort_Order_By>>;
  where?: InputMaybe<Onboarding_Sample_Db_Cohort_Bool_Exp>;
};

export type Query_RootOnboarding_Sample_Db_Cohort_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Onboarding_Sample_Db_Cohort_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Onboarding_Sample_Db_Cohort_Order_By>>;
  where?: InputMaybe<Onboarding_Sample_Db_Cohort_Bool_Exp>;
};

export type Query_RootOnboarding_Sample_Db_Cohort_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Query_RootOnboarding_Sample_Db_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Onboarding_Sample_Db_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Onboarding_Sample_Db_Config_Order_By>>;
  where?: InputMaybe<Onboarding_Sample_Db_Config_Bool_Exp>;
};

export type Query_RootOnboarding_Sample_Db_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Onboarding_Sample_Db_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Onboarding_Sample_Db_Config_Order_By>>;
  where?: InputMaybe<Onboarding_Sample_Db_Config_Bool_Exp>;
};

export type Query_RootOnboarding_Sample_Db_Config_By_PkArgs = {
  created_at: Scalars['timestamptz'];
};

export type Query_RootOne_Click_DeploymentArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_Bool_Exp>;
};

export type Query_RootOne_Click_Deployment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_Bool_Exp>;
};

export type Query_RootOne_Click_Deployment_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootOne_Click_Deployment_Sample_AppsArgs = {
  distinct_on?: InputMaybe<
    Array<One_Click_Deployment_Sample_Apps_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_Sample_Apps_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_Sample_Apps_Bool_Exp>;
};

export type Query_RootOne_Click_Deployment_Sample_Apps_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<One_Click_Deployment_Sample_Apps_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_Sample_Apps_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_Sample_Apps_Bool_Exp>;
};

export type Query_RootOne_Click_Deployment_Sample_Apps_By_PkArgs = {
  git_repository_branch: Scalars['String'];
  git_repository_url: Scalars['String'];
  hasura_directory: Scalars['String'];
};

export type Query_RootOne_Click_Deployment_State_LogArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_State_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_State_Log_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_State_Log_Bool_Exp>;
};

export type Query_RootOne_Click_Deployment_State_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_State_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_State_Log_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_State_Log_Bool_Exp>;
};

export type Query_RootOne_Click_Deployment_State_Log_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Query_RootOne_Click_Deployment_StatesArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_States_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_States_Bool_Exp>;
};

export type Query_RootOne_Click_Deployment_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_States_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_States_Bool_Exp>;
};

export type Query_RootOne_Click_Deployment_States_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootOpentelemetry_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Opentelemetry_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opentelemetry_Config_Order_By>>;
  where?: InputMaybe<Opentelemetry_Config_Bool_Exp>;
};

export type Query_RootOpentelemetry_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opentelemetry_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opentelemetry_Config_Order_By>>;
  where?: InputMaybe<Opentelemetry_Config_Bool_Exp>;
};

export type Query_RootOpentelemetry_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Query_RootOperations_Delete_Free_Plan_Dns_Records_Nov_2021Args = {
  distinct_on?: InputMaybe<
    Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Order_By>
  >;
  where?: InputMaybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp>;
};

export type Query_RootOperations_Delete_Free_Plan_Dns_Records_Nov_2021_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Order_By>
  >;
  where?: InputMaybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp>;
};

export type Query_RootOperations_Delete_Free_Plan_Dns_Records_Nov_2021_By_PkArgs = {
  tenant_id: Scalars['uuid'];
};

export type Query_RootOperations_TenantArgs = {
  distinct_on?: InputMaybe<Array<Operations_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Tenant_Order_By>>;
  where?: InputMaybe<Operations_Tenant_Bool_Exp>;
};

export type Query_RootOperations_Tenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operations_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Tenant_Order_By>>;
  where?: InputMaybe<Operations_Tenant_Bool_Exp>;
};

export type Query_RootOperations_To_Delete_Free_Plan_Dns_RecordsArgs = {
  args?: InputMaybe<Operations_To_Delete_Free_Plan_Dns_Records_Args>;
  distinct_on?: InputMaybe<Array<Operations_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Tenant_Order_By>>;
  where?: InputMaybe<Operations_Tenant_Bool_Exp>;
};

export type Query_RootOperations_To_Delete_Free_Plan_Dns_Records_AggregateArgs = {
  args?: InputMaybe<Operations_To_Delete_Free_Plan_Dns_Records_Args>;
  distinct_on?: InputMaybe<Array<Operations_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Tenant_Order_By>>;
  where?: InputMaybe<Operations_Tenant_Bool_Exp>;
};

export type Query_RootPayment_MethodArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

export type Query_RootPayment_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

export type Query_RootPayment_Method_By_PkArgs = {
  id: Scalars['String'];
};

export type Query_RootPlan_EntitlementsArgs = {
  distinct_on?: InputMaybe<Array<Plan_Entitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plan_Entitlements_Order_By>>;
  where?: InputMaybe<Plan_Entitlements_Bool_Exp>;
};

export type Query_RootPlan_Entitlements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Entitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plan_Entitlements_Order_By>>;
  where?: InputMaybe<Plan_Entitlements_Bool_Exp>;
};

export type Query_RootPlan_Entitlements_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootPlansArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

export type Query_RootPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

export type Query_RootPlans_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Query_RootPolicies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Query_RootPolicies_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Query_RootPrivilegedProjectsArgs = {
  where?: InputMaybe<PrivilegedProjectInput>;
};

export type Query_RootPrivilegesArgs = {
  distinct_on?: InputMaybe<Array<Privileges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Privileges_Order_By>>;
  where?: InputMaybe<Privileges_Bool_Exp>;
};

export type Query_RootPrivileges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Privileges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Privileges_Order_By>>;
  where?: InputMaybe<Privileges_Bool_Exp>;
};

export type Query_RootPrivileges_By_PkArgs = {
  slug: Scalars['String'];
};

export type Query_RootPro_License_KeysArgs = {
  distinct_on?: InputMaybe<Array<Pro_License_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pro_License_Keys_Order_By>>;
  where?: InputMaybe<Pro_License_Keys_Bool_Exp>;
};

export type Query_RootPro_License_Keys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pro_License_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pro_License_Keys_Order_By>>;
  where?: InputMaybe<Pro_License_Keys_Bool_Exp>;
};

export type Query_RootPro_License_Keys_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_ActivityArgs = {
  distinct_on?: InputMaybe<Array<Project_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Activity_Order_By>>;
  where?: InputMaybe<Project_Activity_Bool_Exp>;
};

export type Query_RootProject_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Activity_Order_By>>;
  where?: InputMaybe<Project_Activity_Bool_Exp>;
};

export type Query_RootProject_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Billing_Manager_InvitationsArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Billing_Manager_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Billing_Manager_Invitations_Order_By>>;
  where?: InputMaybe<Project_Billing_Manager_Invitations_Bool_Exp>;
};

export type Query_RootProject_Billing_Manager_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Billing_Manager_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Billing_Manager_Invitations_Order_By>>;
  where?: InputMaybe<Project_Billing_Manager_Invitations_Bool_Exp>;
};

export type Query_RootProject_Billing_Manager_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Collaborator_Allowed_Schema_TablesArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Order_By>
  >;
  where?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>;
};

export type Query_RootProject_Collaborator_Allowed_Schema_Tables_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Order_By>
  >;
  where?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>;
};

export type Query_RootProject_Collaborator_Allowed_Schema_Tables_By_PkArgs = {
  project_collaborator_id: Scalars['uuid'];
  schema: Scalars['String'];
  table_name: Scalars['String'];
};

export type Query_RootProject_Collaborator_Allowed_SchemasArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schemas_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Allowed_Schemas_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Allowed_Schemas_Bool_Exp>;
};

export type Query_RootProject_Collaborator_Allowed_Schemas_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schemas_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Allowed_Schemas_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Allowed_Schemas_Bool_Exp>;
};

export type Query_RootProject_Collaborator_Allowed_Schemas_By_PkArgs = {
  project_collaborator_id: Scalars['uuid'];
  schema: Scalars['String'];
};

export type Query_RootProject_Collaborator_InvitationsArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};

export type Query_RootProject_Collaborator_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};

export type Query_RootProject_Collaborator_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Collaborator_PrivilegesArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Privileges_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Privileges_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Privileges_Bool_Exp>;
};

export type Query_RootProject_Collaborator_Privileges_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Privileges_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Privileges_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Privileges_Bool_Exp>;
};

export type Query_RootProject_Collaborator_Privileges_By_PkArgs = {
  privilege_slug: Scalars['String'];
  project_collaborator_id: Scalars['uuid'];
};

export type Query_RootProject_CollaboratorsArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborators_Order_By>>;
  where?: InputMaybe<Project_Collaborators_Bool_Exp>;
};

export type Query_RootProject_Collaborators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborators_Order_By>>;
  where?: InputMaybe<Project_Collaborators_Bool_Exp>;
};

export type Query_RootProject_Collaborators_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Data_UsageArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_AggArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_Agg_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_Agg_UserArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_User_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_Agg_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_User_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Data_Usage_ComponentsArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Components_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Components_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Components_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_Components_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Components_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Components_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Components_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_PrometheusArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Prometheus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_Prometheus_Agg_UserArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Data_Usage_Prometheus_Agg_User_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_Prometheus_Agg_User_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Data_Usage_Prometheus_Agg_User_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_Prometheus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Prometheus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_Prometheus_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Data_Usage_ReportArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Report_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Report_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Report_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Report_Bool_Exp>;
};

export type Query_RootProject_Data_Usage_Report_By_PkArgs = {
  project_id: Scalars['uuid'];
  report_date: Scalars['date'];
};

export type Query_RootProject_Db_UsageArgs = {
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Bool_Exp>;
};

export type Query_RootProject_Db_Usage_Agg_UserArgs = {
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Agg_User_Bool_Exp>;
};

export type Query_RootProject_Db_Usage_Agg_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Agg_User_Bool_Exp>;
};

export type Query_RootProject_Db_Usage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Bool_Exp>;
};

export type Query_RootProject_Db_Usage_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Entitlement_AccessArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Access_Bool_Exp>;
};

export type Query_RootProject_Entitlement_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Access_Bool_Exp>;
};

export type Query_RootProject_Entitlement_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Entitlement_CatalogueArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Catalogue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Catalogue_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Catalogue_Bool_Exp>;
};

export type Query_RootProject_Entitlement_Catalogue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Catalogue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Catalogue_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Catalogue_Bool_Exp>;
};

export type Query_RootProject_Entitlement_Catalogue_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Entitlement_TypesArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Types_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Types_Bool_Exp>;
};

export type Query_RootProject_Entitlement_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Types_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Types_Bool_Exp>;
};

export type Query_RootProject_Entitlement_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootProject_LabelsArgs = {
  distinct_on?: InputMaybe<Array<Project_Labels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Labels_Order_By>>;
  where?: InputMaybe<Project_Labels_Bool_Exp>;
};

export type Query_RootProject_Labels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Labels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Labels_Order_By>>;
  where?: InputMaybe<Project_Labels_Bool_Exp>;
};

export type Query_RootProject_Labels_By_PkArgs = {
  label_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

export type Query_RootProject_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Project_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Metadata_Order_By>>;
  where?: InputMaybe<Project_Metadata_Bool_Exp>;
};

export type Query_RootProject_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Metadata_Order_By>>;
  where?: InputMaybe<Project_Metadata_Bool_Exp>;
};

export type Query_RootProject_Metadata_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Query_RootProject_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Project_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Notification_Order_By>>;
  where?: InputMaybe<Project_Notification_Bool_Exp>;
};

export type Query_RootProject_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Notification_Order_By>>;
  where?: InputMaybe<Project_Notification_Bool_Exp>;
};

export type Query_RootProject_Notification_By_PkArgs = {
  month: Scalars['Int'];
  project_id: Scalars['uuid'];
  type: Scalars['reminder_type_enum'];
  year: Scalars['Int'];
};

export type Query_RootProject_Ownership_Transfer_InvitationsArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Ownership_Transfer_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Ownership_Transfer_Invitations_Order_By>>;
  where?: InputMaybe<Project_Ownership_Transfer_Invitations_Bool_Exp>;
};

export type Query_RootProject_Ownership_Transfer_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Ownership_Transfer_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Ownership_Transfer_Invitations_Order_By>>;
  where?: InputMaybe<Project_Ownership_Transfer_Invitations_Bool_Exp>;
};

export type Query_RootProject_Ownership_Transfer_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Pool_Current_StatusArgs = {
  distinct_on?: InputMaybe<Array<Project_Pool_Current_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Pool_Current_Status_Order_By>>;
  where?: InputMaybe<Project_Pool_Current_Status_Bool_Exp>;
};

export type Query_RootProject_Pool_Current_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Pool_Current_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Pool_Current_Status_Order_By>>;
  where?: InputMaybe<Project_Pool_Current_Status_Bool_Exp>;
};

export type Query_RootProject_Requests_CountArgs = {
  distinct_on?: InputMaybe<Array<Project_Requests_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Requests_Count_Order_By>>;
  where?: InputMaybe<Project_Requests_Count_Bool_Exp>;
};

export type Query_RootProject_Requests_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Requests_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Requests_Count_Order_By>>;
  where?: InputMaybe<Project_Requests_Count_Bool_Exp>;
};

export type Query_RootProject_Requests_Count_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_StatsArgs = {
  distinct_on?: InputMaybe<Array<Project_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Stats_Order_By>>;
  where?: InputMaybe<Project_Stats_Bool_Exp>;
};

export type Query_RootProject_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Stats_Order_By>>;
  where?: InputMaybe<Project_Stats_Bool_Exp>;
};

export type Query_RootProject_Stats_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProject_Total_Db_Usage_AggArgs = {
  distinct_on?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Total_Db_Usage_Agg_Bool_Exp>;
};

export type Query_RootProject_Total_Db_Usage_Agg_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Total_Db_Usage_Agg_Bool_Exp>;
};

export type Query_RootProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

export type Query_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

export type Query_RootProjects_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProjects_Pool_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Projects_Pool_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Pool_Config_Order_By>>;
  where?: InputMaybe<Projects_Pool_Config_Bool_Exp>;
};

export type Query_RootProjects_Pool_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Pool_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Pool_Config_Order_By>>;
  where?: InputMaybe<Projects_Pool_Config_Bool_Exp>;
};

export type Query_RootProjects_Pool_Config_By_PkArgs = {
  cloud: Scalars['String'];
  plan_name: Scalars['String'];
  region: Scalars['String'];
};

export type Query_RootProjects_Pro_Key_GenerationsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Pro_Key_Generations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Pro_Key_Generations_Order_By>>;
  where?: InputMaybe<Projects_Pro_Key_Generations_Bool_Exp>;
};

export type Query_RootProjects_Pro_Key_Generations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Pro_Key_Generations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Pro_Key_Generations_Order_By>>;
  where?: InputMaybe<Projects_Pro_Key_Generations_Bool_Exp>;
};

export type Query_RootProjects_Pro_Key_Generations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootProjects_With_No_Running_DeploymentsArgs = {
  distinct_on?: InputMaybe<
    Array<Projects_With_No_Running_Deployments_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_With_No_Running_Deployments_Order_By>>;
  where?: InputMaybe<Projects_With_No_Running_Deployments_Bool_Exp>;
};

export type Query_RootProjects_With_No_Running_Deployments_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Projects_With_No_Running_Deployments_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_With_No_Running_Deployments_Order_By>>;
  where?: InputMaybe<Projects_With_No_Running_Deployments_Bool_Exp>;
};

export type Query_RootProjects_With_No_Running_Preview_AppArgs = {
  distinct_on?: InputMaybe<
    Array<Projects_With_No_Running_Preview_App_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_With_No_Running_Preview_App_Order_By>>;
  where?: InputMaybe<Projects_With_No_Running_Preview_App_Bool_Exp>;
};

export type Query_RootProjects_With_No_Running_Preview_App_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Projects_With_No_Running_Preview_App_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_With_No_Running_Preview_App_Order_By>>;
  where?: InputMaybe<Projects_With_No_Running_Preview_App_Bool_Exp>;
};

export type Query_RootPrometheus_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Prometheus_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prometheus_Config_Order_By>>;
  where?: InputMaybe<Prometheus_Config_Bool_Exp>;
};

export type Query_RootPrometheus_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prometheus_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prometheus_Config_Order_By>>;
  where?: InputMaybe<Prometheus_Config_Bool_Exp>;
};

export type Query_RootPrometheus_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Query_RootProvidersArgs = {
  distinct_on?: InputMaybe<Array<Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Providers_Order_By>>;
  where?: InputMaybe<Providers_Bool_Exp>;
};

export type Query_RootProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Providers_Order_By>>;
  where?: InputMaybe<Providers_Bool_Exp>;
};

export type Query_RootProviders_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootProvisioningArgs = {
  distinct_on?: InputMaybe<Array<Provisioning_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provisioning_Order_By>>;
  where?: InputMaybe<Provisioning_Bool_Exp>;
};

export type Query_RootProvisioning_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provisioning_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provisioning_Order_By>>;
  where?: InputMaybe<Provisioning_Bool_Exp>;
};

export type Query_RootProvisioning_By_PkArgs = {
  provisioning: Scalars['String'];
};

export type Query_RootRegionArgs = {
  distinct_on?: InputMaybe<Array<Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Region_Order_By>>;
  where?: InputMaybe<Region_Bool_Exp>;
};

export type Query_RootRegion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Region_Order_By>>;
  where?: InputMaybe<Region_Bool_Exp>;
};

export type Query_RootRegion_By_PkArgs = {
  cloud: Cloud_Enum;
  name: Scalars['String'];
};

export type Query_RootRegion_V2Args = {
  distinct_on?: InputMaybe<Array<Region_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Region_V2_Order_By>>;
  where?: InputMaybe<Region_V2_Bool_Exp>;
};

export type Query_RootRegion_V2_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Region_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Region_V2_Order_By>>;
  where?: InputMaybe<Region_V2_Bool_Exp>;
};

export type Query_RootRegional_Data_ConnectorsArgs = {
  distinct_on?: InputMaybe<Array<Regional_Data_Connectors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Data_Connectors_Order_By>>;
  where?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
};

export type Query_RootRegional_Data_Connectors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Regional_Data_Connectors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Data_Connectors_Order_By>>;
  where?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
};

export type Query_RootRegional_Data_Connectors_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootRegional_MetricsArgs = {
  distinct_on?: InputMaybe<Array<Regional_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Metrics_Order_By>>;
  where?: InputMaybe<Regional_Metrics_Bool_Exp>;
};

export type Query_RootRegional_Metrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Regional_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Metrics_Order_By>>;
  where?: InputMaybe<Regional_Metrics_Bool_Exp>;
};

export type Query_RootRegional_Metrics_By_PkArgs = {
  cloud: Scalars['String'];
  region: Scalars['String'];
};

export type Query_RootSaml_IdpArgs = {
  distinct_on?: InputMaybe<Array<Saml_Idp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Saml_Idp_Order_By>>;
  where?: InputMaybe<Saml_Idp_Bool_Exp>;
};

export type Query_RootSaml_Idp_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Saml_Idp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Saml_Idp_Order_By>>;
  where?: InputMaybe<Saml_Idp_Bool_Exp>;
};

export type Query_RootSaml_Idp_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSearch_Project_Login_StatusArgs = {
  args: Search_Project_Login_Status_Args;
  distinct_on?: InputMaybe<
    Array<Search_Project_Login_Status_Results_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Project_Login_Status_Results_Order_By>>;
  where?: InputMaybe<Search_Project_Login_Status_Results_Bool_Exp>;
};

export type Query_RootSearch_Project_Login_Status_AggregateArgs = {
  args: Search_Project_Login_Status_Args;
  distinct_on?: InputMaybe<
    Array<Search_Project_Login_Status_Results_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Project_Login_Status_Results_Order_By>>;
  where?: InputMaybe<Search_Project_Login_Status_Results_Bool_Exp>;
};

export type Query_RootSearch_Project_Login_Status_ResultsArgs = {
  distinct_on?: InputMaybe<
    Array<Search_Project_Login_Status_Results_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Project_Login_Status_Results_Order_By>>;
  where?: InputMaybe<Search_Project_Login_Status_Results_Bool_Exp>;
};

export type Query_RootSearch_Project_Login_Status_Results_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Search_Project_Login_Status_Results_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Project_Login_Status_Results_Order_By>>;
  where?: InputMaybe<Search_Project_Login_Status_Results_Bool_Exp>;
};

export type Query_RootSearch_Tenant_Group_Has_Least_MembersArgs = {
  args?: InputMaybe<Search_Tenant_Group_Has_Least_Members_Args>;
  distinct_on?: InputMaybe<Array<Tenant_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Order_By>>;
  where?: InputMaybe<Tenant_Group_Bool_Exp>;
};

export type Query_RootSearch_Tenant_Group_Has_Least_Members_AggregateArgs = {
  args?: InputMaybe<Search_Tenant_Group_Has_Least_Members_Args>;
  distinct_on?: InputMaybe<Array<Tenant_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Order_By>>;
  where?: InputMaybe<Tenant_Group_Bool_Exp>;
};

export type Query_RootSlack_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Slack_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Slack_Config_Order_By>>;
  where?: InputMaybe<Slack_Config_Bool_Exp>;
};

export type Query_RootSlack_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Slack_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Slack_Config_Order_By>>;
  where?: InputMaybe<Slack_Config_Bool_Exp>;
};

export type Query_RootSlack_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootStripe_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stripe_Subscription_Order_By>>;
  where?: InputMaybe<Stripe_Subscription_Bool_Exp>;
};

export type Query_RootStripe_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stripe_Subscription_Order_By>>;
  where?: InputMaybe<Stripe_Subscription_Bool_Exp>;
};

export type Query_RootStripe_Subscription_By_PkArgs = {
  customer_id: Scalars['String'];
  subscription_id: Scalars['String'];
};

export type Query_RootStripe_Webhook_EventsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Webhook_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stripe_Webhook_Events_Order_By>>;
  where?: InputMaybe<Stripe_Webhook_Events_Bool_Exp>;
};

export type Query_RootStripe_Webhook_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Webhook_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stripe_Webhook_Events_Order_By>>;
  where?: InputMaybe<Stripe_Webhook_Events_Bool_Exp>;
};

export type Query_RootStripe_Webhook_Events_By_PkArgs = {
  stripe_event_id: Scalars['String'];
};

export type Query_RootSuper_Connector_TypesArgs = {
  distinct_on?: InputMaybe<Array<Super_Connector_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Super_Connector_Types_Order_By>>;
  where?: InputMaybe<Super_Connector_Types_Bool_Exp>;
};

export type Query_RootSuper_Connector_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Super_Connector_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Super_Connector_Types_Order_By>>;
  where?: InputMaybe<Super_Connector_Types_Bool_Exp>;
};

export type Query_RootSuper_Connector_Types_By_PkArgs = {
  type: Scalars['String'];
};

export type Query_RootSupport_Plan_TypesArgs = {
  distinct_on?: InputMaybe<Array<Support_Plan_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Plan_Types_Order_By>>;
  where?: InputMaybe<Support_Plan_Types_Bool_Exp>;
};

export type Query_RootSupport_Plan_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Plan_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Plan_Types_Order_By>>;
  where?: InputMaybe<Support_Plan_Types_Bool_Exp>;
};

export type Query_RootSupport_Plan_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootSurveyArgs = {
  distinct_on?: InputMaybe<Array<Survey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Order_By>>;
  where?: InputMaybe<Survey_Bool_Exp>;
};

export type Query_RootSurvey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Order_By>>;
  where?: InputMaybe<Survey_Bool_Exp>;
};

export type Query_RootSurvey_By_PkArgs = {
  survey_name: Scalars['String'];
};

export type Query_RootSurvey_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Order_By>>;
  where?: InputMaybe<Survey_Question_Bool_Exp>;
};

export type Query_RootSurvey_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Order_By>>;
  where?: InputMaybe<Survey_Question_Bool_Exp>;
};

export type Query_RootSurvey_Question_Answer_OptionArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Answer_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Answer_Option_Order_By>>;
  where?: InputMaybe<Survey_Question_Answer_Option_Bool_Exp>;
};

export type Query_RootSurvey_Question_Answer_Option_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Answer_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Answer_Option_Order_By>>;
  where?: InputMaybe<Survey_Question_Answer_Option_Bool_Exp>;
};

export type Query_RootSurvey_Question_Answer_Option_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSurvey_Question_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Answers_Order_By>>;
  where?: InputMaybe<Survey_Question_Answers_Bool_Exp>;
};

export type Query_RootSurvey_Question_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Answers_Order_By>>;
  where?: InputMaybe<Survey_Question_Answers_Bool_Exp>;
};

export type Query_RootSurvey_Question_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSurvey_Question_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSurvey_Question_KindArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Kind_Order_By>>;
  where?: InputMaybe<Survey_Question_Kind_Bool_Exp>;
};

export type Query_RootSurvey_Question_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Kind_Order_By>>;
  where?: InputMaybe<Survey_Question_Kind_Bool_Exp>;
};

export type Query_RootSurvey_Question_Kind_By_PkArgs = {
  kind: Scalars['String'];
};

export type Query_RootSurvey_Question_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Options_Order_By>>;
  where?: InputMaybe<Survey_Question_Options_Bool_Exp>;
};

export type Query_RootSurvey_Question_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Options_Order_By>>;
  where?: InputMaybe<Survey_Question_Options_Bool_Exp>;
};

export type Query_RootSurvey_Question_Options_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSurvey_V2Args = {
  distinct_on?: InputMaybe<Array<Survey_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Order_By>>;
  where?: InputMaybe<Survey_V2_Bool_Exp>;
};

export type Query_RootSurvey_V2_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Order_By>>;
  where?: InputMaybe<Survey_V2_Bool_Exp>;
};

export type Query_RootSurvey_V2_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSurvey_V2_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Bool_Exp>;
};

export type Query_RootSurvey_V2_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Bool_Exp>;
};

export type Query_RootSurvey_V2_Question_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSurvey_V2_Question_KindArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Kind_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Kind_Bool_Exp>;
};

export type Query_RootSurvey_V2_Question_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Kind_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Kind_Bool_Exp>;
};

export type Query_RootSurvey_V2_Question_Kind_By_PkArgs = {
  kind: Scalars['String'];
};

export type Query_RootSurvey_V2_Question_OptionArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Option_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Option_Bool_Exp>;
};

export type Query_RootSurvey_V2_Question_Option_Additional_Info_ConfigArgs = {
  distinct_on?: InputMaybe<
    Array<Survey_V2_Question_Option_Additional_Info_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Survey_V2_Question_Option_Additional_Info_Config_Order_By>
  >;
  where?: InputMaybe<Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp>;
};

export type Query_RootSurvey_V2_Question_Option_Additional_Info_Config_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Survey_V2_Question_Option_Additional_Info_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Survey_V2_Question_Option_Additional_Info_Config_Order_By>
  >;
  where?: InputMaybe<Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp>;
};

export type Query_RootSurvey_V2_Question_Option_Additional_Info_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSurvey_V2_Question_Option_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Option_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Option_Bool_Exp>;
};

export type Query_RootSurvey_V2_Question_Option_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSurvey_V2_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Bool_Exp>;
};

export type Query_RootSurvey_V2_Response_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Bool_Exp>;
};

export type Query_RootSurvey_V2_Response_AnswerArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
};

export type Query_RootSurvey_V2_Response_Answer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
};

export type Query_RootSurvey_V2_Response_Answer_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSurvey_V2_Response_Answer_OptionArgs = {
  distinct_on?: InputMaybe<
    Array<Survey_V2_Response_Answer_Option_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Option_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Option_Bool_Exp>;
};

export type Query_RootSurvey_V2_Response_Answer_Option_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Survey_V2_Response_Answer_Option_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Option_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Option_Bool_Exp>;
};

export type Query_RootSurvey_V2_Response_Answer_Option_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootSurvey_V2_Response_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTask_EventArgs = {
  distinct_on?: InputMaybe<Array<Task_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Event_Order_By>>;
  where?: InputMaybe<Task_Event_Bool_Exp>;
};

export type Query_RootTask_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Event_Order_By>>;
  where?: InputMaybe<Task_Event_Bool_Exp>;
};

export type Query_RootTask_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTaskq_Worker_Event_LatestArgs = {
  distinct_on?: InputMaybe<Array<Taskq_Worker_Event_Latest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Taskq_Worker_Event_Latest_Order_By>>;
  where?: InputMaybe<Taskq_Worker_Event_Latest_Bool_Exp>;
};

export type Query_RootTaskq_Worker_Event_Latest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taskq_Worker_Event_Latest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Taskq_Worker_Event_Latest_Order_By>>;
  where?: InputMaybe<Taskq_Worker_Event_Latest_Bool_Exp>;
};

export type Query_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootTasks_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTenantArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Order_By>>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

export type Query_RootTenant_Active_Status_ReasonArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Active_Status_Reason_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Active_Status_Reason_Order_By>>;
  where?: InputMaybe<Tenant_Active_Status_Reason_Bool_Exp>;
};

export type Query_RootTenant_Active_Status_Reason_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Active_Status_Reason_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Active_Status_Reason_Order_By>>;
  where?: InputMaybe<Tenant_Active_Status_Reason_Bool_Exp>;
};

export type Query_RootTenant_Active_Status_Reason_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootTenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Order_By>>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

export type Query_RootTenant_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTenant_GroupArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Order_By>>;
  where?: InputMaybe<Tenant_Group_Bool_Exp>;
};

export type Query_RootTenant_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Order_By>>;
  where?: InputMaybe<Tenant_Group_Bool_Exp>;
};

export type Query_RootTenant_Group_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTenant_Group_MemberArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Member_Order_By>>;
  where?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
};

export type Query_RootTenant_Group_Member_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Member_Order_By>>;
  where?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
};

export type Query_RootTenant_Group_Member_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootTenant_Public_StatusArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Public_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Public_Status_Order_By>>;
  where?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
};

export type Query_RootTenant_Public_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Public_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Public_Status_Order_By>>;
  where?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
};

export type Query_RootTenant_Public_Status_By_PkArgs = {
  endpoint: Scalars['String'];
  tenant_id: Scalars['uuid'];
};

export type Query_RootTenant_StatusArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Status_Order_By>>;
  where?: InputMaybe<Tenant_Status_Bool_Exp>;
};

export type Query_RootTenant_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Status_Order_By>>;
  where?: InputMaybe<Tenant_Status_Bool_Exp>;
};

export type Query_RootTenant_Status_By_PkArgs = {
  tenant_id: Scalars['uuid'];
  worker_id: Scalars['uuid'];
};

export type Query_RootTrial_LeadsArgs = {
  distinct_on?: InputMaybe<Array<Trial_Leads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trial_Leads_Order_By>>;
  where?: InputMaybe<Trial_Leads_Bool_Exp>;
};

export type Query_RootTrial_Leads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trial_Leads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trial_Leads_Order_By>>;
  where?: InputMaybe<Trial_Leads_Bool_Exp>;
};

export type Query_RootTrial_Leads_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUa_Audit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Ua_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ua_Audit_Logs_Order_By>>;
  where?: InputMaybe<Ua_Audit_Logs_Bool_Exp>;
};

export type Query_RootUa_Audit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ua_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ua_Audit_Logs_Order_By>>;
  where?: InputMaybe<Ua_Audit_Logs_Bool_Exp>;
};

export type Query_RootUa_Audit_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_ActivityArgs = {
  distinct_on?: InputMaybe<Array<User_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Activity_Order_By>>;
  where?: InputMaybe<User_Activity_Bool_Exp>;
};

export type Query_RootUser_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Activity_Order_By>>;
  where?: InputMaybe<User_Activity_Bool_Exp>;
};

export type Query_RootUser_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_CouponArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type Query_RootUser_Coupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type Query_RootUser_Coupon_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_Entitlement_AccessArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Access_Order_By>>;
  where?: InputMaybe<User_Entitlement_Access_Bool_Exp>;
};

export type Query_RootUser_Entitlement_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Access_Order_By>>;
  where?: InputMaybe<User_Entitlement_Access_Bool_Exp>;
};

export type Query_RootUser_Entitlement_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_Entitlement_CatalogueArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Catalogue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Catalogue_Order_By>>;
  where?: InputMaybe<User_Entitlement_Catalogue_Bool_Exp>;
};

export type Query_RootUser_Entitlement_Catalogue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Catalogue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Catalogue_Order_By>>;
  where?: InputMaybe<User_Entitlement_Catalogue_Bool_Exp>;
};

export type Query_RootUser_Entitlement_Catalogue_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_Entitlement_TypesArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Types_Order_By>>;
  where?: InputMaybe<User_Entitlement_Types_Bool_Exp>;
};

export type Query_RootUser_Entitlement_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Types_Order_By>>;
  where?: InputMaybe<User_Entitlement_Types_Bool_Exp>;
};

export type Query_RootUser_Entitlement_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Query_RootUser_OnboardingArgs = {
  distinct_on?: InputMaybe<Array<User_Onboarding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Onboarding_Order_By>>;
  where?: InputMaybe<User_Onboarding_Bool_Exp>;
};

export type Query_RootUser_Onboarding_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Onboarding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Onboarding_Order_By>>;
  where?: InputMaybe<User_Onboarding_Bool_Exp>;
};

export type Query_RootUser_Onboarding_By_PkArgs = {
  target: Scalars['String'];
  user_id: Scalars['uuid'];
};

export type Query_RootUser_Personal_Access_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Personal_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Personal_Access_Tokens_Order_By>>;
  where?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
};

export type Query_RootUser_Personal_Access_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Personal_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Personal_Access_Tokens_Order_By>>;
  where?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
};

export type Query_RootUser_Personal_Access_Tokens_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_ProfileArgs = {
  distinct_on?: InputMaybe<Array<User_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Profile_Order_By>>;
  where?: InputMaybe<User_Profile_Bool_Exp>;
};

export type Query_RootUser_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Profile_Order_By>>;
  where?: InputMaybe<User_Profile_Bool_Exp>;
};

export type Query_RootUser_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_Project_MapArgs = {
  distinct_on?: InputMaybe<Array<User_Project_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Project_Map_Order_By>>;
  where?: InputMaybe<User_Project_Map_Bool_Exp>;
};

export type Query_RootUser_Project_Map_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Project_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Project_Map_Order_By>>;
  where?: InputMaybe<User_Project_Map_Bool_Exp>;
};

export type Query_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Query_RootUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Query_RootUser_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUser_Vpc_PolicyArgs = {
  distinct_on?: InputMaybe<Array<User_Vpc_Policy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Vpc_Policy_Order_By>>;
  where?: InputMaybe<User_Vpc_Policy_Bool_Exp>;
};

export type Query_RootUser_Vpc_Policy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Vpc_Policy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Vpc_Policy_Order_By>>;
  where?: InputMaybe<User_Vpc_Policy_Bool_Exp>;
};

export type Query_RootUser_Vpc_Policy_By_PkArgs = {
  id: Scalars['Int'];
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootUsers_PublicArgs = {
  distinct_on?: InputMaybe<Array<Users_Public_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Public_Order_By>>;
  where?: InputMaybe<Users_Public_Bool_Exp>;
};

export type Query_RootUsers_Public_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Public_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Public_Order_By>>;
  where?: InputMaybe<Users_Public_Bool_Exp>;
};

export type Query_RootValidTenantNameArgs = {
  name: Scalars['String'];
  tenantId?: InputMaybe<Scalars['uuid']>;
};

export type Query_RootVercelGetProjectsArgs = {
  configuration_id: Scalars['String'];
  team_id?: InputMaybe<Scalars['String']>;
};

export type Query_RootVercel_IntegrationArgs = {
  distinct_on?: InputMaybe<Array<Vercel_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vercel_Integration_Order_By>>;
  where?: InputMaybe<Vercel_Integration_Bool_Exp>;
};

export type Query_RootVercel_Integration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vercel_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vercel_Integration_Order_By>>;
  where?: InputMaybe<Vercel_Integration_Bool_Exp>;
};

export type Query_RootVercel_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootVercel_Integration_ConnectionsArgs = {
  distinct_on?: InputMaybe<Array<Vercel_Integration_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vercel_Integration_Connections_Order_By>>;
  where?: InputMaybe<Vercel_Integration_Connections_Bool_Exp>;
};

export type Query_RootVercel_Integration_Connections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vercel_Integration_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vercel_Integration_Connections_Order_By>>;
  where?: InputMaybe<Vercel_Integration_Connections_Bool_Exp>;
};

export type Query_RootVercel_Integration_Connections_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootVpc_Collaborator_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Vpc_Collaborator_Invitations_Bool_Exp>;
};

export type Query_RootVpc_Collaborator_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Vpc_Collaborator_Invitations_Bool_Exp>;
};

export type Query_RootVpc_Collaborator_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootVpc_CollaboratorsArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborators_Order_By>>;
  where?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
};

export type Query_RootVpc_Collaborators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborators_Order_By>>;
  where?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
};

export type Query_RootVpc_Collaborators_By_PkArgs = {
  collaborator_id: Scalars['uuid'];
  vpc_id: Scalars['uuid'];
};

export type Query_RootVpc_PeeringArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Peering_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Peering_Order_By>>;
  where?: InputMaybe<Vpc_Peering_Bool_Exp>;
};

export type Query_RootVpc_Peering_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Peering_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Peering_Order_By>>;
  where?: InputMaybe<Vpc_Peering_Bool_Exp>;
};

export type Query_RootVpc_Peering_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Query_RootVpc_Peering_DirectionArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Peering_Direction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Peering_Direction_Order_By>>;
  where?: InputMaybe<Vpc_Peering_Direction_Bool_Exp>;
};

export type Query_RootVpc_Peering_Direction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Peering_Direction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Peering_Direction_Order_By>>;
  where?: InputMaybe<Vpc_Peering_Direction_Bool_Exp>;
};

export type Query_RootVpc_Peering_Direction_By_PkArgs = {
  direction: Scalars['String'];
};

export type Query_RootVpc_PrivilegesArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Privileges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Privileges_Order_By>>;
  where?: InputMaybe<Vpc_Privileges_Bool_Exp>;
};

export type Query_RootVpc_Privileges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Privileges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Privileges_Order_By>>;
  where?: InputMaybe<Vpc_Privileges_Bool_Exp>;
};

export type Query_RootVpc_Privileges_By_PkArgs = {
  slug: Scalars['String'];
};

export type Query_RootVpc_StatusArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Status_Order_By>>;
  where?: InputMaybe<Vpc_Status_Bool_Exp>;
};

export type Query_RootVpc_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Status_Order_By>>;
  where?: InputMaybe<Vpc_Status_Bool_Exp>;
};

export type Query_RootVpc_Status_By_PkArgs = {
  status: Scalars['String'];
};

export type Query_RootZendesk_Support_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Zendesk_Support_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zendesk_Support_Category_Order_By>>;
  where?: InputMaybe<Zendesk_Support_Category_Bool_Exp>;
};

export type Query_RootZendesk_Support_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zendesk_Support_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zendesk_Support_Category_Order_By>>;
  where?: InputMaybe<Zendesk_Support_Category_Bool_Exp>;
};

export type Query_RootZendesk_Support_Category_By_PkArgs = {
  name: Scalars['String'];
};

export type Query_RootZendesk_Support_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Zendesk_Support_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zendesk_Support_Tickets_Order_By>>;
  where?: InputMaybe<Zendesk_Support_Tickets_Bool_Exp>;
};

export type Query_RootZendesk_Support_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zendesk_Support_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zendesk_Support_Tickets_Order_By>>;
  where?: InputMaybe<Zendesk_Support_Tickets_Bool_Exp>;
};

export type Query_RootZendesk_Support_Tickets_By_PkArgs = {
  id: Scalars['uuid'];
};

/** Region that can be assigned to a cloud provider */
export type Region = {
  __typename?: 'region';
  cloud: Cloud_Enum;
  disable_infra_auto_apply: Scalars['Boolean'];
  hasura_worker_security_group_id?: Maybe<Scalars['String']>;
  healthcheck_variables?: Maybe<Scalars['jsonb']>;
  infra_status?: Maybe<Infra_Status_Enum>;
  input_variables: Scalars['jsonb'];
  is_active: Scalars['Boolean'];
  legacy_metrics_fqdn?: Maybe<Scalars['String']>;
  /** An array relationship */
  lux_proxy: Array<Lux_Proxy>;
  /** An aggregate relationship */
  lux_proxy_aggregate: Lux_Proxy_Aggregate;
  /** Get security group of id for the Lux proxy endpoint interface */
  lux_proxy_security_group_id?: Maybe<Scalars['jsonb']>;
  /** Get the service name of the VPC endpoint service of Lux proxy */
  lux_proxy_service_name?: Maybe<Scalars['jsonb']>;
  metrics_fqdn?: Maybe<Scalars['String']>;
  metrics_fqdn_v2?: Maybe<Scalars['String']>;
  metrics_ips?: Maybe<Scalars['jsonb']>;
  metrics_security_group_id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nat_ip?: Maybe<Scalars['String']>;
  nat_ips?: Maybe<Scalars['json']>;
  operator_variables?: Maybe<Scalars['jsonb']>;
  output_variables?: Maybe<Scalars['jsonb']>;
  /** An array relationship */
  regional_data_connectors: Array<Regional_Data_Connectors>;
  /** An aggregate relationship */
  regional_data_connectors_aggregate: Regional_Data_Connectors_Aggregate;
  /** An array relationship */
  regional_metrics: Array<Regional_Metrics>;
  /** An aggregate relationship */
  regional_metrics_aggregate: Regional_Metrics_Aggregate;
  /** VPC id for the region */
  vpc_id?: Maybe<Scalars['String']>;
};

/** Region that can be assigned to a cloud provider */
export type RegionHealthcheck_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Region that can be assigned to a cloud provider */
export type RegionInput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Region that can be assigned to a cloud provider */
export type RegionLux_ProxyArgs = {
  distinct_on?: InputMaybe<Array<Lux_Proxy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lux_Proxy_Order_By>>;
  where?: InputMaybe<Lux_Proxy_Bool_Exp>;
};

/** Region that can be assigned to a cloud provider */
export type RegionLux_Proxy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lux_Proxy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lux_Proxy_Order_By>>;
  where?: InputMaybe<Lux_Proxy_Bool_Exp>;
};

/** Region that can be assigned to a cloud provider */
export type RegionLux_Proxy_Security_Group_IdArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Region that can be assigned to a cloud provider */
export type RegionLux_Proxy_Service_NameArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Region that can be assigned to a cloud provider */
export type RegionMetrics_IpsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Region that can be assigned to a cloud provider */
export type RegionNat_IpsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Region that can be assigned to a cloud provider */
export type RegionOperator_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Region that can be assigned to a cloud provider */
export type RegionOutput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Region that can be assigned to a cloud provider */
export type RegionRegional_Data_ConnectorsArgs = {
  distinct_on?: InputMaybe<Array<Regional_Data_Connectors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Data_Connectors_Order_By>>;
  where?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
};

/** Region that can be assigned to a cloud provider */
export type RegionRegional_Data_Connectors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Regional_Data_Connectors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Data_Connectors_Order_By>>;
  where?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
};

/** Region that can be assigned to a cloud provider */
export type RegionRegional_MetricsArgs = {
  distinct_on?: InputMaybe<Array<Regional_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Metrics_Order_By>>;
  where?: InputMaybe<Regional_Metrics_Bool_Exp>;
};

/** Region that can be assigned to a cloud provider */
export type RegionRegional_Metrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Regional_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Metrics_Order_By>>;
  where?: InputMaybe<Regional_Metrics_Bool_Exp>;
};

/** aggregated selection of "region" */
export type Region_Aggregate = {
  __typename?: 'region_aggregate';
  aggregate?: Maybe<Region_Aggregate_Fields>;
  nodes: Array<Region>;
};

/** aggregate fields of "region" */
export type Region_Aggregate_Fields = {
  __typename?: 'region_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Region_Max_Fields>;
  min?: Maybe<Region_Min_Fields>;
};

/** aggregate fields of "region" */
export type Region_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Region_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Region_Append_Input = {
  healthcheck_variables?: InputMaybe<Scalars['jsonb']>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  operator_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "region". All fields are combined with a logical 'AND'. */
export type Region_Bool_Exp = {
  _and?: InputMaybe<Array<Region_Bool_Exp>>;
  _not?: InputMaybe<Region_Bool_Exp>;
  _or?: InputMaybe<Array<Region_Bool_Exp>>;
  cloud?: InputMaybe<Cloud_Enum_Comparison_Exp>;
  disable_infra_auto_apply?: InputMaybe<Boolean_Comparison_Exp>;
  hasura_worker_security_group_id?: InputMaybe<String_Comparison_Exp>;
  healthcheck_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  infra_status?: InputMaybe<Infra_Status_Enum_Comparison_Exp>;
  input_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  legacy_metrics_fqdn?: InputMaybe<String_Comparison_Exp>;
  lux_proxy?: InputMaybe<Lux_Proxy_Bool_Exp>;
  lux_proxy_aggregate?: InputMaybe<Lux_Proxy_Aggregate_Bool_Exp>;
  lux_proxy_security_group_id?: InputMaybe<Jsonb_Comparison_Exp>;
  lux_proxy_service_name?: InputMaybe<Jsonb_Comparison_Exp>;
  metrics_fqdn?: InputMaybe<String_Comparison_Exp>;
  metrics_fqdn_v2?: InputMaybe<String_Comparison_Exp>;
  metrics_ips?: InputMaybe<Jsonb_Comparison_Exp>;
  metrics_security_group_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nat_ip?: InputMaybe<String_Comparison_Exp>;
  nat_ips?: InputMaybe<Json_Comparison_Exp>;
  operator_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  output_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  regional_data_connectors?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
  regional_data_connectors_aggregate?: InputMaybe<
    Regional_Data_Connectors_Aggregate_Bool_Exp
  >;
  regional_metrics?: InputMaybe<Regional_Metrics_Bool_Exp>;
  regional_metrics_aggregate?: InputMaybe<Regional_Metrics_Aggregate_Bool_Exp>;
  vpc_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "region" */
export enum Region_Constraint {
  /** unique or primary key constraint on columns "cloud", "name" */
  RegionPkey = 'region_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Region_Delete_At_Path_Input = {
  healthcheck_variables?: InputMaybe<Array<Scalars['String']>>;
  input_variables?: InputMaybe<Array<Scalars['String']>>;
  operator_variables?: InputMaybe<Array<Scalars['String']>>;
  output_variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Region_Delete_Elem_Input = {
  healthcheck_variables?: InputMaybe<Scalars['Int']>;
  input_variables?: InputMaybe<Scalars['Int']>;
  operator_variables?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Region_Delete_Key_Input = {
  healthcheck_variables?: InputMaybe<Scalars['String']>;
  input_variables?: InputMaybe<Scalars['String']>;
  operator_variables?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "region" */
export type Region_Insert_Input = {
  cloud?: InputMaybe<Cloud_Enum>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  healthcheck_variables?: InputMaybe<Scalars['jsonb']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  legacy_metrics_fqdn?: InputMaybe<Scalars['String']>;
  lux_proxy?: InputMaybe<Lux_Proxy_Arr_Rel_Insert_Input>;
  metrics_fqdn?: InputMaybe<Scalars['String']>;
  metrics_fqdn_v2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nat_ip?: InputMaybe<Scalars['String']>;
  operator_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  regional_data_connectors?: InputMaybe<
    Regional_Data_Connectors_Arr_Rel_Insert_Input
  >;
  regional_metrics?: InputMaybe<Regional_Metrics_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Region_Max_Fields = {
  __typename?: 'region_max_fields';
  hasura_worker_security_group_id?: Maybe<Scalars['String']>;
  legacy_metrics_fqdn?: Maybe<Scalars['String']>;
  metrics_fqdn?: Maybe<Scalars['String']>;
  metrics_fqdn_v2?: Maybe<Scalars['String']>;
  metrics_security_group_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nat_ip?: Maybe<Scalars['String']>;
  /** VPC id for the region */
  vpc_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Region_Min_Fields = {
  __typename?: 'region_min_fields';
  hasura_worker_security_group_id?: Maybe<Scalars['String']>;
  legacy_metrics_fqdn?: Maybe<Scalars['String']>;
  metrics_fqdn?: Maybe<Scalars['String']>;
  metrics_fqdn_v2?: Maybe<Scalars['String']>;
  metrics_security_group_id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nat_ip?: Maybe<Scalars['String']>;
  /** VPC id for the region */
  vpc_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "region" */
export type Region_Mutation_Response = {
  __typename?: 'region_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Region>;
};

/** input type for inserting object relation for remote table "region" */
export type Region_Obj_Rel_Insert_Input = {
  data: Region_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Region_On_Conflict>;
};

/** on_conflict condition type for table "region" */
export type Region_On_Conflict = {
  constraint: Region_Constraint;
  update_columns?: Array<Region_Update_Column>;
  where?: InputMaybe<Region_Bool_Exp>;
};

/** Ordering options when selecting data from "region". */
export type Region_Order_By = {
  cloud?: InputMaybe<Order_By>;
  disable_infra_auto_apply?: InputMaybe<Order_By>;
  hasura_worker_security_group_id?: InputMaybe<Order_By>;
  healthcheck_variables?: InputMaybe<Order_By>;
  infra_status?: InputMaybe<Order_By>;
  input_variables?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  legacy_metrics_fqdn?: InputMaybe<Order_By>;
  lux_proxy_aggregate?: InputMaybe<Lux_Proxy_Aggregate_Order_By>;
  lux_proxy_security_group_id?: InputMaybe<Order_By>;
  lux_proxy_service_name?: InputMaybe<Order_By>;
  metrics_fqdn?: InputMaybe<Order_By>;
  metrics_fqdn_v2?: InputMaybe<Order_By>;
  metrics_ips?: InputMaybe<Order_By>;
  metrics_security_group_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nat_ip?: InputMaybe<Order_By>;
  nat_ips?: InputMaybe<Order_By>;
  operator_variables?: InputMaybe<Order_By>;
  output_variables?: InputMaybe<Order_By>;
  regional_data_connectors_aggregate?: InputMaybe<
    Regional_Data_Connectors_Aggregate_Order_By
  >;
  regional_metrics_aggregate?: InputMaybe<Regional_Metrics_Aggregate_Order_By>;
  vpc_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: region */
export type Region_Pk_Columns_Input = {
  cloud: Cloud_Enum;
  name: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Region_Prepend_Input = {
  healthcheck_variables?: InputMaybe<Scalars['jsonb']>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  operator_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "region" */
export enum Region_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  HealthcheckVariables = 'healthcheck_variables',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LegacyMetricsFqdn = 'legacy_metrics_fqdn',
  /** column name */
  MetricsFqdn = 'metrics_fqdn',
  /** column name */
  MetricsFqdnV2 = 'metrics_fqdn_v2',
  /** column name */
  Name = 'name',
  /** column name */
  NatIp = 'nat_ip',
  /** column name */
  OperatorVariables = 'operator_variables',
  /** column name */
  OutputVariables = 'output_variables',
}

/** input type for updating data in table "region" */
export type Region_Set_Input = {
  cloud?: InputMaybe<Cloud_Enum>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  healthcheck_variables?: InputMaybe<Scalars['jsonb']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  legacy_metrics_fqdn?: InputMaybe<Scalars['String']>;
  metrics_fqdn?: InputMaybe<Scalars['String']>;
  metrics_fqdn_v2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nat_ip?: InputMaybe<Scalars['String']>;
  operator_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "region" */
export type Region_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Region_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Region_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Cloud_Enum>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  healthcheck_variables?: InputMaybe<Scalars['jsonb']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  legacy_metrics_fqdn?: InputMaybe<Scalars['String']>;
  metrics_fqdn?: InputMaybe<Scalars['String']>;
  metrics_fqdn_v2?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nat_ip?: InputMaybe<Scalars['String']>;
  operator_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** update columns of table "region" */
export enum Region_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  HealthcheckVariables = 'healthcheck_variables',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LegacyMetricsFqdn = 'legacy_metrics_fqdn',
  /** column name */
  MetricsFqdn = 'metrics_fqdn',
  /** column name */
  MetricsFqdnV2 = 'metrics_fqdn_v2',
  /** column name */
  Name = 'name',
  /** column name */
  NatIp = 'nat_ip',
  /** column name */
  OperatorVariables = 'operator_variables',
  /** column name */
  OutputVariables = 'output_variables',
}

export type Region_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Region_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Region_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Region_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Region_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Region_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Region_Set_Input>;
  /** filter the rows which have to be updated */
  where: Region_Bool_Exp;
};

/** View to capture version 2 of regions */
export type Region_V2 = {
  __typename?: 'region_v2';
  cloud?: Maybe<Scalars['String']>;
  infra_status?: Maybe<Scalars['String']>;
  input_variables?: Maybe<Scalars['jsonb']>;
  is_active?: Maybe<Scalars['Boolean']>;
  metrics_fqdn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nat_ip?: Maybe<Scalars['String']>;
  output_variables?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  region?: Maybe<Region>;
};

/** View to capture version 2 of regions */
export type Region_V2Input_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** View to capture version 2 of regions */
export type Region_V2Output_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "region_v2" */
export type Region_V2_Aggregate = {
  __typename?: 'region_v2_aggregate';
  aggregate?: Maybe<Region_V2_Aggregate_Fields>;
  nodes: Array<Region_V2>;
};

/** aggregate fields of "region_v2" */
export type Region_V2_Aggregate_Fields = {
  __typename?: 'region_v2_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Region_V2_Max_Fields>;
  min?: Maybe<Region_V2_Min_Fields>;
};

/** aggregate fields of "region_v2" */
export type Region_V2_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Region_V2_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Region_V2_Append_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "region_v2". All fields are combined with a logical 'AND'. */
export type Region_V2_Bool_Exp = {
  _and?: InputMaybe<Array<Region_V2_Bool_Exp>>;
  _not?: InputMaybe<Region_V2_Bool_Exp>;
  _or?: InputMaybe<Array<Region_V2_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  infra_status?: InputMaybe<String_Comparison_Exp>;
  input_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  metrics_fqdn?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nat_ip?: InputMaybe<String_Comparison_Exp>;
  output_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  region?: InputMaybe<Region_Bool_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Region_V2_Delete_At_Path_Input = {
  input_variables?: InputMaybe<Array<Scalars['String']>>;
  output_variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Region_V2_Delete_Elem_Input = {
  input_variables?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Region_V2_Delete_Key_Input = {
  input_variables?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "region_v2" */
export type Region_V2_Insert_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  infra_status?: InputMaybe<Scalars['String']>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  metrics_fqdn?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nat_ip?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Region_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Region_V2_Max_Fields = {
  __typename?: 'region_v2_max_fields';
  cloud?: Maybe<Scalars['String']>;
  infra_status?: Maybe<Scalars['String']>;
  metrics_fqdn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nat_ip?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Region_V2_Min_Fields = {
  __typename?: 'region_v2_min_fields';
  cloud?: Maybe<Scalars['String']>;
  infra_status?: Maybe<Scalars['String']>;
  metrics_fqdn?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nat_ip?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "region_v2" */
export type Region_V2_Mutation_Response = {
  __typename?: 'region_v2_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Region_V2>;
};

/** Ordering options when selecting data from "region_v2". */
export type Region_V2_Order_By = {
  cloud?: InputMaybe<Order_By>;
  infra_status?: InputMaybe<Order_By>;
  input_variables?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  metrics_fqdn?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nat_ip?: InputMaybe<Order_By>;
  output_variables?: InputMaybe<Order_By>;
  region?: InputMaybe<Region_Order_By>;
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Region_V2_Prepend_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "region_v2" */
export enum Region_V2_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  MetricsFqdn = 'metrics_fqdn',
  /** column name */
  Name = 'name',
  /** column name */
  NatIp = 'nat_ip',
  /** column name */
  OutputVariables = 'output_variables',
}

/** input type for updating data in table "region_v2" */
export type Region_V2_Set_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  infra_status?: InputMaybe<Scalars['String']>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  metrics_fqdn?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nat_ip?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** Streaming cursor of the table "region_v2" */
export type Region_V2_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Region_V2_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Region_V2_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  infra_status?: InputMaybe<Scalars['String']>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  metrics_fqdn?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  nat_ip?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

export type Region_V2_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Region_V2_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Region_V2_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Region_V2_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Region_V2_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Region_V2_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Region_V2_Set_Input>;
  /** filter the rows which have to be updated */
  where: Region_V2_Bool_Exp;
};

/** set of data connectors running in a region */
export type Regional_Data_Connectors = {
  __typename?: 'regional_data_connectors';
  cloud: Scalars['String'];
  created_at: Scalars['timestamptz'];
  data_connector_type: Data_Connector_Type_Enum;
  /** An object relationship */
  dedicated_vpc?: Maybe<Dedicated_Vpc>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  input_variables?: Maybe<Scalars['jsonb']>;
  output_variables?: Maybe<Scalars['jsonb']>;
  region: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** set of data connectors running in a region */
export type Regional_Data_ConnectorsInput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** set of data connectors running in a region */
export type Regional_Data_ConnectorsOutput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "regional_data_connectors" */
export type Regional_Data_Connectors_Aggregate = {
  __typename?: 'regional_data_connectors_aggregate';
  aggregate?: Maybe<Regional_Data_Connectors_Aggregate_Fields>;
  nodes: Array<Regional_Data_Connectors>;
};

export type Regional_Data_Connectors_Aggregate_Bool_Exp = {
  count?: InputMaybe<Regional_Data_Connectors_Aggregate_Bool_Exp_Count>;
};

export type Regional_Data_Connectors_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Regional_Data_Connectors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "regional_data_connectors" */
export type Regional_Data_Connectors_Aggregate_Fields = {
  __typename?: 'regional_data_connectors_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Regional_Data_Connectors_Max_Fields>;
  min?: Maybe<Regional_Data_Connectors_Min_Fields>;
};

/** aggregate fields of "regional_data_connectors" */
export type Regional_Data_Connectors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Regional_Data_Connectors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "regional_data_connectors" */
export type Regional_Data_Connectors_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Regional_Data_Connectors_Max_Order_By>;
  min?: InputMaybe<Regional_Data_Connectors_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Regional_Data_Connectors_Append_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "regional_data_connectors" */
export type Regional_Data_Connectors_Arr_Rel_Insert_Input = {
  data: Array<Regional_Data_Connectors_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Regional_Data_Connectors_On_Conflict>;
};

/** Boolean expression to filter rows from the table "regional_data_connectors". All fields are combined with a logical 'AND'. */
export type Regional_Data_Connectors_Bool_Exp = {
  _and?: InputMaybe<Array<Regional_Data_Connectors_Bool_Exp>>;
  _not?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
  _or?: InputMaybe<Array<Regional_Data_Connectors_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  data_connector_type?: InputMaybe<Data_Connector_Type_Enum_Comparison_Exp>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
  dedicated_vpc_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  input_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  output_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "regional_data_connectors" */
export enum Regional_Data_Connectors_Constraint {
  /** unique or primary key constraint on columns "id" */
  RegionalDataConnectorsPkey = 'regional_data_connectors_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Regional_Data_Connectors_Delete_At_Path_Input = {
  input_variables?: InputMaybe<Array<Scalars['String']>>;
  output_variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Regional_Data_Connectors_Delete_Elem_Input = {
  input_variables?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Regional_Data_Connectors_Delete_Key_Input = {
  input_variables?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "regional_data_connectors" */
export type Regional_Data_Connectors_Insert_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data_connector_type?: InputMaybe<Data_Connector_Type_Enum>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Obj_Rel_Insert_Input>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Regional_Data_Connectors_Max_Fields = {
  __typename?: 'regional_data_connectors_max_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "regional_data_connectors" */
export type Regional_Data_Connectors_Max_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Regional_Data_Connectors_Min_Fields = {
  __typename?: 'regional_data_connectors_min_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "regional_data_connectors" */
export type Regional_Data_Connectors_Min_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "regional_data_connectors" */
export type Regional_Data_Connectors_Mutation_Response = {
  __typename?: 'regional_data_connectors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Regional_Data_Connectors>;
};

/** on_conflict condition type for table "regional_data_connectors" */
export type Regional_Data_Connectors_On_Conflict = {
  constraint: Regional_Data_Connectors_Constraint;
  update_columns?: Array<Regional_Data_Connectors_Update_Column>;
  where?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
};

/** Ordering options when selecting data from "regional_data_connectors". */
export type Regional_Data_Connectors_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  data_connector_type?: InputMaybe<Order_By>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  input_variables?: InputMaybe<Order_By>;
  output_variables?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: regional_data_connectors */
export type Regional_Data_Connectors_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Regional_Data_Connectors_Prepend_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "regional_data_connectors" */
export enum Regional_Data_Connectors_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataConnectorType = 'data_connector_type',
  /** column name */
  DedicatedVpcId = 'dedicated_vpc_id',
  /** column name */
  Id = 'id',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "regional_data_connectors" */
export type Regional_Data_Connectors_Set_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data_connector_type?: InputMaybe<Data_Connector_Type_Enum>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "regional_data_connectors" */
export type Regional_Data_Connectors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Regional_Data_Connectors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Regional_Data_Connectors_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  data_connector_type?: InputMaybe<Data_Connector_Type_Enum>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "regional_data_connectors" */
export enum Regional_Data_Connectors_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DataConnectorType = 'data_connector_type',
  /** column name */
  DedicatedVpcId = 'dedicated_vpc_id',
  /** column name */
  Id = 'id',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Regional_Data_Connectors_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Regional_Data_Connectors_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Regional_Data_Connectors_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Regional_Data_Connectors_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Regional_Data_Connectors_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Regional_Data_Connectors_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Regional_Data_Connectors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Regional_Data_Connectors_Bool_Exp;
};

/** Metrics captures on a regional level for cloud and region */
export type Regional_Metrics = {
  __typename?: 'regional_metrics';
  cloud: Scalars['String'];
  created_at: Scalars['timestamptz'];
  disable_infra_auto_apply: Scalars['Boolean'];
  infra_status?: Maybe<Infra_Status_Enum>;
  input_variables?: Maybe<Scalars['jsonb']>;
  metrics_ips?: Maybe<Scalars['jsonb']>;
  output_variables?: Maybe<Scalars['jsonb']>;
  region: Scalars['String'];
  /** An object relationship */
  region_info?: Maybe<Region>;
  updated_at: Scalars['timestamptz'];
};

/** Metrics captures on a regional level for cloud and region */
export type Regional_MetricsInput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Metrics captures on a regional level for cloud and region */
export type Regional_MetricsMetrics_IpsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Metrics captures on a regional level for cloud and region */
export type Regional_MetricsOutput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "regional_metrics" */
export type Regional_Metrics_Aggregate = {
  __typename?: 'regional_metrics_aggregate';
  aggregate?: Maybe<Regional_Metrics_Aggregate_Fields>;
  nodes: Array<Regional_Metrics>;
};

export type Regional_Metrics_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Regional_Metrics_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Regional_Metrics_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Regional_Metrics_Aggregate_Bool_Exp_Count>;
};

export type Regional_Metrics_Aggregate_Bool_Exp_Bool_And = {
  arguments: Regional_Metrics_Select_Column_Regional_Metrics_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Regional_Metrics_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Regional_Metrics_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Regional_Metrics_Select_Column_Regional_Metrics_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Regional_Metrics_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Regional_Metrics_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Regional_Metrics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Regional_Metrics_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "regional_metrics" */
export type Regional_Metrics_Aggregate_Fields = {
  __typename?: 'regional_metrics_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Regional_Metrics_Max_Fields>;
  min?: Maybe<Regional_Metrics_Min_Fields>;
};

/** aggregate fields of "regional_metrics" */
export type Regional_Metrics_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Regional_Metrics_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "regional_metrics" */
export type Regional_Metrics_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Regional_Metrics_Max_Order_By>;
  min?: InputMaybe<Regional_Metrics_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Regional_Metrics_Append_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "regional_metrics" */
export type Regional_Metrics_Arr_Rel_Insert_Input = {
  data: Array<Regional_Metrics_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Regional_Metrics_On_Conflict>;
};

/** Boolean expression to filter rows from the table "regional_metrics". All fields are combined with a logical 'AND'. */
export type Regional_Metrics_Bool_Exp = {
  _and?: InputMaybe<Array<Regional_Metrics_Bool_Exp>>;
  _not?: InputMaybe<Regional_Metrics_Bool_Exp>;
  _or?: InputMaybe<Array<Regional_Metrics_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  disable_infra_auto_apply?: InputMaybe<Boolean_Comparison_Exp>;
  infra_status?: InputMaybe<Infra_Status_Enum_Comparison_Exp>;
  input_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  metrics_ips?: InputMaybe<Jsonb_Comparison_Exp>;
  output_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  region_info?: InputMaybe<Region_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "regional_metrics" */
export enum Regional_Metrics_Constraint {
  /** unique or primary key constraint on columns "region", "cloud" */
  RegionalMetricsPkey = 'regional_metrics_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Regional_Metrics_Delete_At_Path_Input = {
  input_variables?: InputMaybe<Array<Scalars['String']>>;
  output_variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Regional_Metrics_Delete_Elem_Input = {
  input_variables?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Regional_Metrics_Delete_Key_Input = {
  input_variables?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "regional_metrics" */
export type Regional_Metrics_Insert_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  region_info?: InputMaybe<Region_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Regional_Metrics_Max_Fields = {
  __typename?: 'regional_metrics_max_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "regional_metrics" */
export type Regional_Metrics_Max_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Regional_Metrics_Min_Fields = {
  __typename?: 'regional_metrics_min_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "regional_metrics" */
export type Regional_Metrics_Min_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "regional_metrics" */
export type Regional_Metrics_Mutation_Response = {
  __typename?: 'regional_metrics_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Regional_Metrics>;
};

/** on_conflict condition type for table "regional_metrics" */
export type Regional_Metrics_On_Conflict = {
  constraint: Regional_Metrics_Constraint;
  update_columns?: Array<Regional_Metrics_Update_Column>;
  where?: InputMaybe<Regional_Metrics_Bool_Exp>;
};

/** Ordering options when selecting data from "regional_metrics". */
export type Regional_Metrics_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  disable_infra_auto_apply?: InputMaybe<Order_By>;
  infra_status?: InputMaybe<Order_By>;
  input_variables?: InputMaybe<Order_By>;
  metrics_ips?: InputMaybe<Order_By>;
  output_variables?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  region_info?: InputMaybe<Region_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: regional_metrics */
export type Regional_Metrics_Pk_Columns_Input = {
  cloud: Scalars['String'];
  region: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Regional_Metrics_Prepend_Input = {
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "regional_metrics" */
export enum Regional_Metrics_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "regional_metrics_aggregate_bool_exp_bool_and_arguments_columns" columns of table "regional_metrics" */
export enum Regional_Metrics_Select_Column_Regional_Metrics_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
}

/** select "regional_metrics_aggregate_bool_exp_bool_or_arguments_columns" columns of table "regional_metrics" */
export enum Regional_Metrics_Select_Column_Regional_Metrics_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
}

/** input type for updating data in table "regional_metrics" */
export type Regional_Metrics_Set_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "regional_metrics" */
export type Regional_Metrics_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Regional_Metrics_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Regional_Metrics_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  input_variables?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "regional_metrics" */
export enum Regional_Metrics_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  InputVariables = 'input_variables',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Regional_Metrics_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Regional_Metrics_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Regional_Metrics_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Regional_Metrics_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Regional_Metrics_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Regional_Metrics_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Regional_Metrics_Set_Input>;
  /** filter the rows which have to be updated */
  where: Regional_Metrics_Bool_Exp;
};

/** Boolean expression to compare columns of type "reminder_type_enum". All fields are combined with logical 'AND'. */
export type Reminder_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['reminder_type_enum']>;
  _gt?: InputMaybe<Scalars['reminder_type_enum']>;
  _gte?: InputMaybe<Scalars['reminder_type_enum']>;
  _in?: InputMaybe<Array<Scalars['reminder_type_enum']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['reminder_type_enum']>;
  _lte?: InputMaybe<Scalars['reminder_type_enum']>;
  _neq?: InputMaybe<Scalars['reminder_type_enum']>;
  _nin?: InputMaybe<Array<Scalars['reminder_type_enum']>>;
};

/** columns and relationships of "saml_idp" */
export type Saml_Idp = {
  __typename?: 'saml_idp';
  /** Boolean field to enable or disable SP Assertion Request Signing */
  disable_request_signing: Scalars['Boolean'];
  domain: Scalars['String'];
  id: Scalars['uuid'];
  idp_metadata_url?: Maybe<Scalars['String']>;
  idp_metadata_xml?: Maybe<Scalars['String']>;
  provider: Scalars['String'];
};

/** aggregated selection of "saml_idp" */
export type Saml_Idp_Aggregate = {
  __typename?: 'saml_idp_aggregate';
  aggregate?: Maybe<Saml_Idp_Aggregate_Fields>;
  nodes: Array<Saml_Idp>;
};

/** aggregate fields of "saml_idp" */
export type Saml_Idp_Aggregate_Fields = {
  __typename?: 'saml_idp_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Saml_Idp_Max_Fields>;
  min?: Maybe<Saml_Idp_Min_Fields>;
};

/** aggregate fields of "saml_idp" */
export type Saml_Idp_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Saml_Idp_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "saml_idp". All fields are combined with a logical 'AND'. */
export type Saml_Idp_Bool_Exp = {
  _and?: InputMaybe<Array<Saml_Idp_Bool_Exp>>;
  _not?: InputMaybe<Saml_Idp_Bool_Exp>;
  _or?: InputMaybe<Array<Saml_Idp_Bool_Exp>>;
  disable_request_signing?: InputMaybe<Boolean_Comparison_Exp>;
  domain?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  idp_metadata_url?: InputMaybe<String_Comparison_Exp>;
  idp_metadata_xml?: InputMaybe<String_Comparison_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "saml_idp" */
export enum Saml_Idp_Constraint {
  /** unique or primary key constraint on columns "domain" */
  SamlIdpDomainKey = 'saml_idp_domain_key',
  /** unique or primary key constraint on columns "id" */
  SamlIdpPkey = 'saml_idp_pkey',
}

/** input type for inserting data into table "saml_idp" */
export type Saml_Idp_Insert_Input = {
  /** Boolean field to enable or disable SP Assertion Request Signing */
  disable_request_signing?: InputMaybe<Scalars['Boolean']>;
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  idp_metadata_url?: InputMaybe<Scalars['String']>;
  idp_metadata_xml?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Saml_Idp_Max_Fields = {
  __typename?: 'saml_idp_max_fields';
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  idp_metadata_url?: Maybe<Scalars['String']>;
  idp_metadata_xml?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Saml_Idp_Min_Fields = {
  __typename?: 'saml_idp_min_fields';
  domain?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  idp_metadata_url?: Maybe<Scalars['String']>;
  idp_metadata_xml?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "saml_idp" */
export type Saml_Idp_Mutation_Response = {
  __typename?: 'saml_idp_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Saml_Idp>;
};

/** on_conflict condition type for table "saml_idp" */
export type Saml_Idp_On_Conflict = {
  constraint: Saml_Idp_Constraint;
  update_columns?: Array<Saml_Idp_Update_Column>;
  where?: InputMaybe<Saml_Idp_Bool_Exp>;
};

/** Ordering options when selecting data from "saml_idp". */
export type Saml_Idp_Order_By = {
  disable_request_signing?: InputMaybe<Order_By>;
  domain?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  idp_metadata_url?: InputMaybe<Order_By>;
  idp_metadata_xml?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
};

/** primary key columns input for table: saml_idp */
export type Saml_Idp_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "saml_idp" */
export enum Saml_Idp_Select_Column {
  /** column name */
  DisableRequestSigning = 'disable_request_signing',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  IdpMetadataUrl = 'idp_metadata_url',
  /** column name */
  IdpMetadataXml = 'idp_metadata_xml',
  /** column name */
  Provider = 'provider',
}

/** input type for updating data in table "saml_idp" */
export type Saml_Idp_Set_Input = {
  /** Boolean field to enable or disable SP Assertion Request Signing */
  disable_request_signing?: InputMaybe<Scalars['Boolean']>;
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  idp_metadata_url?: InputMaybe<Scalars['String']>;
  idp_metadata_xml?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "saml_idp" */
export type Saml_Idp_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Saml_Idp_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Saml_Idp_Stream_Cursor_Value_Input = {
  /** Boolean field to enable or disable SP Assertion Request Signing */
  disable_request_signing?: InputMaybe<Scalars['Boolean']>;
  domain?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  idp_metadata_url?: InputMaybe<Scalars['String']>;
  idp_metadata_xml?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
};

/** update columns of table "saml_idp" */
export enum Saml_Idp_Update_Column {
  /** column name */
  DisableRequestSigning = 'disable_request_signing',
  /** column name */
  Domain = 'domain',
  /** column name */
  Id = 'id',
  /** column name */
  IdpMetadataUrl = 'idp_metadata_url',
  /** column name */
  IdpMetadataXml = 'idp_metadata_xml',
  /** column name */
  Provider = 'provider',
}

export type Saml_Idp_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Saml_Idp_Set_Input>;
  /** filter the rows which have to be updated */
  where: Saml_Idp_Bool_Exp;
};

export type Search_Project_Login_Status_Args = {
  from_time?: InputMaybe<Scalars['timestamptz']>;
  project_ids?: InputMaybe<Scalars['_uuid']>;
  to_time?: InputMaybe<Scalars['timestamptz']>;
};

/** columns and relationships of "search_project_login_status_results" */
export type Search_Project_Login_Status_Results = {
  __typename?: 'search_project_login_status_results';
  last_login?: Maybe<Scalars['timestamptz']>;
  num_collaborators?: Maybe<Scalars['Int']>;
  num_login_sessions?: Maybe<Scalars['Int']>;
  num_read_replicas?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
};

export type Search_Project_Login_Status_Results_Aggregate = {
  __typename?: 'search_project_login_status_results_aggregate';
  aggregate?: Maybe<Search_Project_Login_Status_Results_Aggregate_Fields>;
  nodes: Array<Search_Project_Login_Status_Results>;
};

/** aggregate fields of "search_project_login_status_results" */
export type Search_Project_Login_Status_Results_Aggregate_Fields = {
  __typename?: 'search_project_login_status_results_aggregate_fields';
  avg?: Maybe<Search_Project_Login_Status_Results_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Search_Project_Login_Status_Results_Max_Fields>;
  min?: Maybe<Search_Project_Login_Status_Results_Min_Fields>;
  stddev?: Maybe<Search_Project_Login_Status_Results_Stddev_Fields>;
  stddev_pop?: Maybe<Search_Project_Login_Status_Results_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Search_Project_Login_Status_Results_Stddev_Samp_Fields>;
  sum?: Maybe<Search_Project_Login_Status_Results_Sum_Fields>;
  var_pop?: Maybe<Search_Project_Login_Status_Results_Var_Pop_Fields>;
  var_samp?: Maybe<Search_Project_Login_Status_Results_Var_Samp_Fields>;
  variance?: Maybe<Search_Project_Login_Status_Results_Variance_Fields>;
};

/** aggregate fields of "search_project_login_status_results" */
export type Search_Project_Login_Status_Results_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Search_Project_Login_Status_Results_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Search_Project_Login_Status_Results_Avg_Fields = {
  __typename?: 'search_project_login_status_results_avg_fields';
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "search_project_login_status_results". All fields are combined with a logical 'AND'. */
export type Search_Project_Login_Status_Results_Bool_Exp = {
  _and?: InputMaybe<Array<Search_Project_Login_Status_Results_Bool_Exp>>;
  _not?: InputMaybe<Search_Project_Login_Status_Results_Bool_Exp>;
  _or?: InputMaybe<Array<Search_Project_Login_Status_Results_Bool_Exp>>;
  last_login?: InputMaybe<Timestamptz_Comparison_Exp>;
  num_collaborators?: InputMaybe<Int_Comparison_Exp>;
  num_login_sessions?: InputMaybe<Int_Comparison_Exp>;
  num_read_replicas?: InputMaybe<Int_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "search_project_login_status_results" */
export type Search_Project_Login_Status_Results_Inc_Input = {
  num_collaborators?: InputMaybe<Scalars['Int']>;
  num_login_sessions?: InputMaybe<Scalars['Int']>;
  num_read_replicas?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "search_project_login_status_results" */
export type Search_Project_Login_Status_Results_Insert_Input = {
  last_login?: InputMaybe<Scalars['timestamptz']>;
  num_collaborators?: InputMaybe<Scalars['Int']>;
  num_login_sessions?: InputMaybe<Scalars['Int']>;
  num_read_replicas?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Search_Project_Login_Status_Results_Max_Fields = {
  __typename?: 'search_project_login_status_results_max_fields';
  last_login?: Maybe<Scalars['timestamptz']>;
  num_collaborators?: Maybe<Scalars['Int']>;
  num_login_sessions?: Maybe<Scalars['Int']>;
  num_read_replicas?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Search_Project_Login_Status_Results_Min_Fields = {
  __typename?: 'search_project_login_status_results_min_fields';
  last_login?: Maybe<Scalars['timestamptz']>;
  num_collaborators?: Maybe<Scalars['Int']>;
  num_login_sessions?: Maybe<Scalars['Int']>;
  num_read_replicas?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "search_project_login_status_results" */
export type Search_Project_Login_Status_Results_Mutation_Response = {
  __typename?: 'search_project_login_status_results_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Search_Project_Login_Status_Results>;
};

/** Ordering options when selecting data from "search_project_login_status_results". */
export type Search_Project_Login_Status_Results_Order_By = {
  last_login?: InputMaybe<Order_By>;
  num_collaborators?: InputMaybe<Order_By>;
  num_login_sessions?: InputMaybe<Order_By>;
  num_read_replicas?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
};

/** select columns of table "search_project_login_status_results" */
export enum Search_Project_Login_Status_Results_Select_Column {
  /** column name */
  LastLogin = 'last_login',
  /** column name */
  NumCollaborators = 'num_collaborators',
  /** column name */
  NumLoginSessions = 'num_login_sessions',
  /** column name */
  NumReadReplicas = 'num_read_replicas',
  /** column name */
  ProjectId = 'project_id',
}

/** input type for updating data in table "search_project_login_status_results" */
export type Search_Project_Login_Status_Results_Set_Input = {
  last_login?: InputMaybe<Scalars['timestamptz']>;
  num_collaborators?: InputMaybe<Scalars['Int']>;
  num_login_sessions?: InputMaybe<Scalars['Int']>;
  num_read_replicas?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Search_Project_Login_Status_Results_Stddev_Fields = {
  __typename?: 'search_project_login_status_results_stddev_fields';
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Search_Project_Login_Status_Results_Stddev_Pop_Fields = {
  __typename?: 'search_project_login_status_results_stddev_pop_fields';
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Search_Project_Login_Status_Results_Stddev_Samp_Fields = {
  __typename?: 'search_project_login_status_results_stddev_samp_fields';
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "search_project_login_status_results" */
export type Search_Project_Login_Status_Results_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Search_Project_Login_Status_Results_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Search_Project_Login_Status_Results_Stream_Cursor_Value_Input = {
  last_login?: InputMaybe<Scalars['timestamptz']>;
  num_collaborators?: InputMaybe<Scalars['Int']>;
  num_login_sessions?: InputMaybe<Scalars['Int']>;
  num_read_replicas?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Search_Project_Login_Status_Results_Sum_Fields = {
  __typename?: 'search_project_login_status_results_sum_fields';
  num_collaborators?: Maybe<Scalars['Int']>;
  num_login_sessions?: Maybe<Scalars['Int']>;
  num_read_replicas?: Maybe<Scalars['Int']>;
};

export type Search_Project_Login_Status_Results_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Search_Project_Login_Status_Results_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Search_Project_Login_Status_Results_Set_Input>;
  /** filter the rows which have to be updated */
  where: Search_Project_Login_Status_Results_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Search_Project_Login_Status_Results_Var_Pop_Fields = {
  __typename?: 'search_project_login_status_results_var_pop_fields';
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Search_Project_Login_Status_Results_Var_Samp_Fields = {
  __typename?: 'search_project_login_status_results_var_samp_fields';
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Search_Project_Login_Status_Results_Variance_Fields = {
  __typename?: 'search_project_login_status_results_variance_fields';
  num_collaborators?: Maybe<Scalars['Float']>;
  num_login_sessions?: Maybe<Scalars['Float']>;
  num_read_replicas?: Maybe<Scalars['Float']>;
};

export type Search_Tenant_Group_Has_Least_Members_Args = {
  allocation_policies?: InputMaybe<Scalars['_text']>;
  clouds?: InputMaybe<Scalars['_text']>;
  dedicated_vpc_ids?: InputMaybe<Scalars['_uuid']>;
  ids?: InputMaybe<Scalars['_uuid']>;
  major_versions?: InputMaybe<Scalars['_int4']>;
  plan_names?: InputMaybe<Scalars['_text']>;
  regions?: InputMaybe<Scalars['_text']>;
};

/** stores the configuration for slack which user has configured */
export type Slack_Config = {
  __typename?: 'slack_config';
  /** An object relationship */
  alert_config?: Maybe<Alert_Config>;
  channel_id?: Maybe<Scalars['String']>;
  channel_name?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  project_id: Scalars['uuid'];
  slack_team_id?: Maybe<Scalars['String']>;
  team_name?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  webhook_url: Scalars['String'];
};

/** aggregated selection of "slack_config" */
export type Slack_Config_Aggregate = {
  __typename?: 'slack_config_aggregate';
  aggregate?: Maybe<Slack_Config_Aggregate_Fields>;
  nodes: Array<Slack_Config>;
};

export type Slack_Config_Aggregate_Bool_Exp = {
  count?: InputMaybe<Slack_Config_Aggregate_Bool_Exp_Count>;
};

export type Slack_Config_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Slack_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Slack_Config_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "slack_config" */
export type Slack_Config_Aggregate_Fields = {
  __typename?: 'slack_config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Slack_Config_Max_Fields>;
  min?: Maybe<Slack_Config_Min_Fields>;
};

/** aggregate fields of "slack_config" */
export type Slack_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Slack_Config_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "slack_config" */
export type Slack_Config_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Slack_Config_Max_Order_By>;
  min?: InputMaybe<Slack_Config_Min_Order_By>;
};

/** input type for inserting array relation for remote table "slack_config" */
export type Slack_Config_Arr_Rel_Insert_Input = {
  data: Array<Slack_Config_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Slack_Config_On_Conflict>;
};

/** Boolean expression to filter rows from the table "slack_config". All fields are combined with a logical 'AND'. */
export type Slack_Config_Bool_Exp = {
  _and?: InputMaybe<Array<Slack_Config_Bool_Exp>>;
  _not?: InputMaybe<Slack_Config_Bool_Exp>;
  _or?: InputMaybe<Array<Slack_Config_Bool_Exp>>;
  alert_config?: InputMaybe<Alert_Config_Bool_Exp>;
  channel_id?: InputMaybe<String_Comparison_Exp>;
  channel_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  slack_team_id?: InputMaybe<String_Comparison_Exp>;
  team_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  webhook_url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "slack_config" */
export enum Slack_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  SlackConfigPkey = 'slack_config_pkey',
  /** unique or primary key constraint on columns "project_id" */
  SlackConfigProjectIdKey = 'slack_config_project_id_key',
}

/** input type for inserting data into table "slack_config" */
export type Slack_Config_Insert_Input = {
  alert_config?: InputMaybe<Alert_Config_Obj_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars['String']>;
  channel_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  slack_team_id?: InputMaybe<Scalars['String']>;
  team_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  webhook_url?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Slack_Config_Max_Fields = {
  __typename?: 'slack_config_max_fields';
  channel_id?: Maybe<Scalars['String']>;
  channel_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  slack_team_id?: Maybe<Scalars['String']>;
  team_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webhook_url?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "slack_config" */
export type Slack_Config_Max_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  channel_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  slack_team_id?: InputMaybe<Order_By>;
  team_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  webhook_url?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Slack_Config_Min_Fields = {
  __typename?: 'slack_config_min_fields';
  channel_id?: Maybe<Scalars['String']>;
  channel_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  slack_team_id?: Maybe<Scalars['String']>;
  team_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  webhook_url?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "slack_config" */
export type Slack_Config_Min_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  channel_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  slack_team_id?: InputMaybe<Order_By>;
  team_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  webhook_url?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "slack_config" */
export type Slack_Config_Mutation_Response = {
  __typename?: 'slack_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Slack_Config>;
};

/** input type for inserting object relation for remote table "slack_config" */
export type Slack_Config_Obj_Rel_Insert_Input = {
  data: Slack_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Slack_Config_On_Conflict>;
};

/** on_conflict condition type for table "slack_config" */
export type Slack_Config_On_Conflict = {
  constraint: Slack_Config_Constraint;
  update_columns?: Array<Slack_Config_Update_Column>;
  where?: InputMaybe<Slack_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "slack_config". */
export type Slack_Config_Order_By = {
  alert_config?: InputMaybe<Alert_Config_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  channel_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  slack_team_id?: InputMaybe<Order_By>;
  team_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  webhook_url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: slack_config */
export type Slack_Config_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "slack_config" */
export enum Slack_Config_Select_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ChannelName = 'channel_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SlackTeamId = 'slack_team_id',
  /** column name */
  TeamName = 'team_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebhookUrl = 'webhook_url',
}

/** input type for updating data in table "slack_config" */
export type Slack_Config_Set_Input = {
  channel_id?: InputMaybe<Scalars['String']>;
  channel_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  slack_team_id?: InputMaybe<Scalars['String']>;
  team_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  webhook_url?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "slack_config" */
export type Slack_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Slack_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Slack_Config_Stream_Cursor_Value_Input = {
  channel_id?: InputMaybe<Scalars['String']>;
  channel_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  slack_team_id?: InputMaybe<Scalars['String']>;
  team_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  webhook_url?: InputMaybe<Scalars['String']>;
};

/** update columns of table "slack_config" */
export enum Slack_Config_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  ChannelName = 'channel_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SlackTeamId = 'slack_team_id',
  /** column name */
  TeamName = 'team_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WebhookUrl = 'webhook_url',
}

export type Slack_Config_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Slack_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Slack_Config_Bool_Exp;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']>;
  _gt?: InputMaybe<Scalars['smallint']>;
  _gte?: InputMaybe<Scalars['smallint']>;
  _in?: InputMaybe<Array<Scalars['smallint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['smallint']>;
  _lte?: InputMaybe<Scalars['smallint']>;
  _neq?: InputMaybe<Scalars['smallint']>;
  _nin?: InputMaybe<Array<Scalars['smallint']>>;
};

/** Boolean expression to compare columns of type "string". All fields are combined with logical 'AND'. */
export type String_Clickhouse_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['string']>;
  _gt?: InputMaybe<Scalars['string']>;
  _gte?: InputMaybe<Scalars['string']>;
  _in?: InputMaybe<Array<Scalars['string']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['string']>;
  _lte?: InputMaybe<Scalars['string']>;
  _neq?: InputMaybe<Scalars['string']>;
  _nin?: InputMaybe<Array<Scalars['string']>>;
};

/** Stripe subscription per customer */
export type Stripe_Subscription = {
  __typename?: 'stripe_subscription';
  collection_method: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  customer?: Maybe<Users>;
  customer_id: Scalars['String'];
  /** An object relationship */
  payment_method: Payment_Method;
  payment_method_id: Scalars['String'];
  remarks: Scalars['String'];
  status: Scalars['String'];
  subscription_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "stripe_subscription" */
export type Stripe_Subscription_Aggregate = {
  __typename?: 'stripe_subscription_aggregate';
  aggregate?: Maybe<Stripe_Subscription_Aggregate_Fields>;
  nodes: Array<Stripe_Subscription>;
};

/** aggregate fields of "stripe_subscription" */
export type Stripe_Subscription_Aggregate_Fields = {
  __typename?: 'stripe_subscription_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Stripe_Subscription_Max_Fields>;
  min?: Maybe<Stripe_Subscription_Min_Fields>;
};

/** aggregate fields of "stripe_subscription" */
export type Stripe_Subscription_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Subscription_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "stripe_subscription". All fields are combined with a logical 'AND'. */
export type Stripe_Subscription_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Subscription_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Subscription_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Subscription_Bool_Exp>>;
  collection_method?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer?: InputMaybe<Users_Bool_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  payment_method?: InputMaybe<Payment_Method_Bool_Exp>;
  payment_method_id?: InputMaybe<String_Comparison_Exp>;
  remarks?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  subscription_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_subscription" */
export enum Stripe_Subscription_Constraint {
  /** unique or primary key constraint on columns "customer_id", "subscription_id" */
  StripeSubscriptionPkey = 'stripe_subscription_pkey',
}

/** input type for inserting data into table "stripe_subscription" */
export type Stripe_Subscription_Insert_Input = {
  collection_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  customer_id?: InputMaybe<Scalars['String']>;
  payment_method?: InputMaybe<Payment_Method_Obj_Rel_Insert_Input>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Stripe_Subscription_Max_Fields = {
  __typename?: 'stripe_subscription_max_fields';
  collection_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Stripe_Subscription_Min_Fields = {
  __typename?: 'stripe_subscription_min_fields';
  collection_method?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  payment_method_id?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  subscription_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "stripe_subscription" */
export type Stripe_Subscription_Mutation_Response = {
  __typename?: 'stripe_subscription_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Subscription>;
};

/** on_conflict condition type for table "stripe_subscription" */
export type Stripe_Subscription_On_Conflict = {
  constraint: Stripe_Subscription_Constraint;
  update_columns?: Array<Stripe_Subscription_Update_Column>;
  where?: InputMaybe<Stripe_Subscription_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_subscription". */
export type Stripe_Subscription_Order_By = {
  collection_method?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer?: InputMaybe<Users_Order_By>;
  customer_id?: InputMaybe<Order_By>;
  payment_method?: InputMaybe<Payment_Method_Order_By>;
  payment_method_id?: InputMaybe<Order_By>;
  remarks?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subscription_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_subscription */
export type Stripe_Subscription_Pk_Columns_Input = {
  customer_id: Scalars['String'];
  subscription_id: Scalars['String'];
};

/** select columns of table "stripe_subscription" */
export enum Stripe_Subscription_Select_Column {
  /** column name */
  CollectionMethod = 'collection_method',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "stripe_subscription" */
export type Stripe_Subscription_Set_Input = {
  collection_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "stripe_subscription" */
export type Stripe_Subscription_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Subscription_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Subscription_Stream_Cursor_Value_Input = {
  collection_method?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  payment_method_id?: InputMaybe<Scalars['String']>;
  remarks?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  subscription_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "stripe_subscription" */
export enum Stripe_Subscription_Update_Column {
  /** column name */
  CollectionMethod = 'collection_method',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  PaymentMethodId = 'payment_method_id',
  /** column name */
  Remarks = 'remarks',
  /** column name */
  Status = 'status',
  /** column name */
  SubscriptionId = 'subscription_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Stripe_Subscription_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Subscription_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Subscription_Bool_Exp;
};

/** This table stores only those events received from Stripe that need to be acted on. (eg - for sending mails, delete user tasks) */
export type Stripe_Webhook_Events = {
  __typename?: 'stripe_webhook_events';
  created_at: Scalars['timestamptz'];
  error?: Maybe<Scalars['String']>;
  event_type: Scalars['String'];
  /** An object relationship */
  invoice?: Maybe<Invoice>;
  invoice_id: Scalars['String'];
  is_handled: Scalars['Boolean'];
  request_body_invoice_object: Scalars['jsonb'];
  stripe_event_id: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** This table stores only those events received from Stripe that need to be acted on. (eg - for sending mails, delete user tasks) */
export type Stripe_Webhook_EventsRequest_Body_Invoice_ObjectArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "stripe_webhook_events" */
export type Stripe_Webhook_Events_Aggregate = {
  __typename?: 'stripe_webhook_events_aggregate';
  aggregate?: Maybe<Stripe_Webhook_Events_Aggregate_Fields>;
  nodes: Array<Stripe_Webhook_Events>;
};

/** aggregate fields of "stripe_webhook_events" */
export type Stripe_Webhook_Events_Aggregate_Fields = {
  __typename?: 'stripe_webhook_events_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Stripe_Webhook_Events_Max_Fields>;
  min?: Maybe<Stripe_Webhook_Events_Min_Fields>;
};

/** aggregate fields of "stripe_webhook_events" */
export type Stripe_Webhook_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stripe_Webhook_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Webhook_Events_Append_Input = {
  request_body_invoice_object?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "stripe_webhook_events". All fields are combined with a logical 'AND'. */
export type Stripe_Webhook_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Stripe_Webhook_Events_Bool_Exp>>;
  _not?: InputMaybe<Stripe_Webhook_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Stripe_Webhook_Events_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  event_type?: InputMaybe<String_Comparison_Exp>;
  invoice?: InputMaybe<Invoice_Bool_Exp>;
  invoice_id?: InputMaybe<String_Comparison_Exp>;
  is_handled?: InputMaybe<Boolean_Comparison_Exp>;
  request_body_invoice_object?: InputMaybe<Jsonb_Comparison_Exp>;
  stripe_event_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "stripe_webhook_events" */
export enum Stripe_Webhook_Events_Constraint {
  /** unique or primary key constraint on columns "stripe_event_id" */
  StripeWebhookEventsPkey = 'stripe_webhook_events_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stripe_Webhook_Events_Delete_At_Path_Input = {
  request_body_invoice_object?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stripe_Webhook_Events_Delete_Elem_Input = {
  request_body_invoice_object?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stripe_Webhook_Events_Delete_Key_Input = {
  request_body_invoice_object?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "stripe_webhook_events" */
export type Stripe_Webhook_Events_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  invoice?: InputMaybe<Invoice_Obj_Rel_Insert_Input>;
  invoice_id?: InputMaybe<Scalars['String']>;
  is_handled?: InputMaybe<Scalars['Boolean']>;
  request_body_invoice_object?: InputMaybe<Scalars['jsonb']>;
  stripe_event_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Stripe_Webhook_Events_Max_Fields = {
  __typename?: 'stripe_webhook_events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['String']>;
  stripe_event_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Stripe_Webhook_Events_Min_Fields = {
  __typename?: 'stripe_webhook_events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  invoice_id?: Maybe<Scalars['String']>;
  stripe_event_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "stripe_webhook_events" */
export type Stripe_Webhook_Events_Mutation_Response = {
  __typename?: 'stripe_webhook_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stripe_Webhook_Events>;
};

/** on_conflict condition type for table "stripe_webhook_events" */
export type Stripe_Webhook_Events_On_Conflict = {
  constraint: Stripe_Webhook_Events_Constraint;
  update_columns?: Array<Stripe_Webhook_Events_Update_Column>;
  where?: InputMaybe<Stripe_Webhook_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "stripe_webhook_events". */
export type Stripe_Webhook_Events_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  invoice?: InputMaybe<Invoice_Order_By>;
  invoice_id?: InputMaybe<Order_By>;
  is_handled?: InputMaybe<Order_By>;
  request_body_invoice_object?: InputMaybe<Order_By>;
  stripe_event_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stripe_webhook_events */
export type Stripe_Webhook_Events_Pk_Columns_Input = {
  stripe_event_id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stripe_Webhook_Events_Prepend_Input = {
  request_body_invoice_object?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "stripe_webhook_events" */
export enum Stripe_Webhook_Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Error = 'error',
  /** column name */
  EventType = 'event_type',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  IsHandled = 'is_handled',
  /** column name */
  RequestBodyInvoiceObject = 'request_body_invoice_object',
  /** column name */
  StripeEventId = 'stripe_event_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "stripe_webhook_events" */
export type Stripe_Webhook_Events_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  is_handled?: InputMaybe<Scalars['Boolean']>;
  request_body_invoice_object?: InputMaybe<Scalars['jsonb']>;
  stripe_event_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "stripe_webhook_events" */
export type Stripe_Webhook_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stripe_Webhook_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stripe_Webhook_Events_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  error?: InputMaybe<Scalars['String']>;
  event_type?: InputMaybe<Scalars['String']>;
  invoice_id?: InputMaybe<Scalars['String']>;
  is_handled?: InputMaybe<Scalars['Boolean']>;
  request_body_invoice_object?: InputMaybe<Scalars['jsonb']>;
  stripe_event_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "stripe_webhook_events" */
export enum Stripe_Webhook_Events_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Error = 'error',
  /** column name */
  EventType = 'event_type',
  /** column name */
  InvoiceId = 'invoice_id',
  /** column name */
  IsHandled = 'is_handled',
  /** column name */
  RequestBodyInvoiceObject = 'request_body_invoice_object',
  /** column name */
  StripeEventId = 'stripe_event_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Stripe_Webhook_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stripe_Webhook_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stripe_Webhook_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stripe_Webhook_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stripe_Webhook_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stripe_Webhook_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stripe_Webhook_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stripe_Webhook_Events_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "alert_config" */
  alert_config: Array<Alert_Config>;
  /** fetch aggregated fields from the table: "alert_config" */
  alert_config_aggregate: Alert_Config_Aggregate;
  /** fetch data from the table: "alert_config_alert_type" */
  alert_config_alert_type: Array<Alert_Config_Alert_Type>;
  /** fetch aggregated fields from the table: "alert_config_alert_type" */
  alert_config_alert_type_aggregate: Alert_Config_Alert_Type_Aggregate;
  /** fetch data from the table: "alert_config_alert_type" using primary key columns */
  alert_config_alert_type_by_pk?: Maybe<Alert_Config_Alert_Type>;
  /** fetch data from the table in a streaming manner: "alert_config_alert_type" */
  alert_config_alert_type_stream: Array<Alert_Config_Alert_Type>;
  /** fetch data from the table: "alert_config" using primary key columns */
  alert_config_by_pk?: Maybe<Alert_Config>;
  /** fetch data from the table: "alert_config_service" */
  alert_config_service: Array<Alert_Config_Service>;
  /** fetch aggregated fields from the table: "alert_config_service" */
  alert_config_service_aggregate: Alert_Config_Service_Aggregate;
  /** fetch data from the table: "alert_config_service" using primary key columns */
  alert_config_service_by_pk?: Maybe<Alert_Config_Service>;
  /** fetch data from the table in a streaming manner: "alert_config_service" */
  alert_config_service_stream: Array<Alert_Config_Service>;
  /** fetch data from the table in a streaming manner: "alert_config" */
  alert_config_stream: Array<Alert_Config>;
  /** fetch data from the table: "alert_service_type" */
  alert_service_type: Array<Alert_Service_Type>;
  /** fetch aggregated fields from the table: "alert_service_type" */
  alert_service_type_aggregate: Alert_Service_Type_Aggregate;
  /** fetch data from the table: "alert_service_type" using primary key columns */
  alert_service_type_by_pk?: Maybe<Alert_Service_Type>;
  /** fetch data from the table in a streaming manner: "alert_service_type" */
  alert_service_type_stream: Array<Alert_Service_Type>;
  /** fetch data from the table: "alert_type" */
  alert_type: Array<Alert_Type>;
  /** fetch aggregated fields from the table: "alert_type" */
  alert_type_aggregate: Alert_Type_Aggregate;
  /** fetch data from the table: "alert_type" using primary key columns */
  alert_type_by_pk?: Maybe<Alert_Type>;
  /** fetch data from the table in a streaming manner: "alert_type" */
  alert_type_stream: Array<Alert_Type>;
  /** fetch data from the table: "azuremonitor_config" */
  azuremonitor_config: Array<Azuremonitor_Config>;
  /** fetch aggregated fields from the table: "azuremonitor_config" */
  azuremonitor_config_aggregate: Azuremonitor_Config_Aggregate;
  /** fetch data from the table: "azuremonitor_config" using primary key columns */
  azuremonitor_config_by_pk?: Maybe<Azuremonitor_Config>;
  /** fetch data from the table in a streaming manner: "azuremonitor_config" */
  azuremonitor_config_stream: Array<Azuremonitor_Config>;
  /** fetch data from the table: "billing_address" */
  billing_address: Array<Billing_Address>;
  /** fetch aggregated fields from the table: "billing_address" */
  billing_address_aggregate: Billing_Address_Aggregate;
  /** fetch data from the table: "billing_address" using primary key columns */
  billing_address_by_pk?: Maybe<Billing_Address>;
  /** fetch data from the table in a streaming manner: "billing_address" */
  billing_address_stream: Array<Billing_Address>;
  /** fetch data from the table: "central_cloud" */
  central_cloud: Array<Central_Cloud>;
  /** fetch aggregated fields from the table: "central_cloud" */
  central_cloud_aggregate: Central_Cloud_Aggregate;
  /** fetch data from the table: "central_cloud" using primary key columns */
  central_cloud_by_pk?: Maybe<Central_Cloud>;
  /** fetch data from the table in a streaming manner: "central_cloud" */
  central_cloud_stream: Array<Central_Cloud>;
  /** fetch data from the table: "change_email_request" */
  change_email_request: Array<Change_Email_Request>;
  /** fetch aggregated fields from the table: "change_email_request" */
  change_email_request_aggregate: Change_Email_Request_Aggregate;
  /** fetch data from the table: "change_email_request" using primary key columns */
  change_email_request_by_pk?: Maybe<Change_Email_Request>;
  /** fetch data from the table in a streaming manner: "change_email_request" */
  change_email_request_stream: Array<Change_Email_Request>;
  /** fetch data from the table: "cicd.deployment_pipeline_config" */
  cicd_deployment_pipeline_config: Array<Cicd_Deployment_Pipeline_Config>;
  /** fetch aggregated fields from the table: "cicd.deployment_pipeline_config" */
  cicd_deployment_pipeline_config_aggregate: Cicd_Deployment_Pipeline_Config_Aggregate;
  /** fetch data from the table: "cicd.deployment_pipeline_config" using primary key columns */
  cicd_deployment_pipeline_config_by_pk?: Maybe<
    Cicd_Deployment_Pipeline_Config
  >;
  /** fetch data from the table in a streaming manner: "cicd.deployment_pipeline_config" */
  cicd_deployment_pipeline_config_stream: Array<
    Cicd_Deployment_Pipeline_Config
  >;
  /** fetch data from the table: "cicd.deployment_pool" */
  cicd_deployment_pool: Array<Cicd_Deployment_Pool>;
  /** fetch aggregated fields from the table: "cicd.deployment_pool" */
  cicd_deployment_pool_aggregate: Cicd_Deployment_Pool_Aggregate;
  /** fetch data from the table: "cicd.deployment_pool_member" */
  cicd_deployment_pool_member: Array<Cicd_Deployment_Pool_Member>;
  /** fetch aggregated fields from the table: "cicd.deployment_pool_member" */
  cicd_deployment_pool_member_aggregate: Cicd_Deployment_Pool_Member_Aggregate;
  /** fetch data from the table in a streaming manner: "cicd.deployment_pool_member" */
  cicd_deployment_pool_member_stream: Array<Cicd_Deployment_Pool_Member>;
  /** fetch data from the table in a streaming manner: "cicd.deployment_pool" */
  cicd_deployment_pool_stream: Array<Cicd_Deployment_Pool>;
  /** execute function "cicd.get_matching_pipeline_config" which returns "cicd.deployment_pipeline_config" */
  cicd_get_matching_pipeline_config: Array<Cicd_Deployment_Pipeline_Config>;
  /** execute function "cicd.get_matching_pipeline_config" and query aggregates on result of table type "cicd.deployment_pipeline_config" */
  cicd_get_matching_pipeline_config_aggregate: Cicd_Deployment_Pipeline_Config_Aggregate;
  /** fetch data from the table: "cloud" */
  cloud: Array<Cloud>;
  /** fetch aggregated fields from the table: "cloud" */
  cloud_aggregate: Cloud_Aggregate;
  /** fetch data from the table: "cloud" using primary key columns */
  cloud_by_pk?: Maybe<Cloud>;
  /** fetch data from the table: "cloud_metadata" */
  cloud_metadata: Array<Cloud_Metadata>;
  /** fetch aggregated fields from the table: "cloud_metadata" */
  cloud_metadata_aggregate: Cloud_Metadata_Aggregate;
  /** fetch data from the table: "cloud_metadata" using primary key columns */
  cloud_metadata_by_pk?: Maybe<Cloud_Metadata>;
  /** fetch data from the table in a streaming manner: "cloud_metadata" */
  cloud_metadata_stream: Array<Cloud_Metadata>;
  /** fetch data from the table: "cloud_regions_by_plan" */
  cloud_regions_by_plan: Array<Cloud_Regions_By_Plan>;
  /** fetch aggregated fields from the table: "cloud_regions_by_plan" */
  cloud_regions_by_plan_aggregate: Cloud_Regions_By_Plan_Aggregate;
  /** fetch data from the table in a streaming manner: "cloud_regions_by_plan" */
  cloud_regions_by_plan_stream: Array<Cloud_Regions_By_Plan>;
  /** fetch data from the table in a streaming manner: "cloud" */
  cloud_stream: Array<Cloud>;
  /** execute function "compute_project_agg_db_usage_user" which returns "project_db_usage_agg_user" */
  compute_project_agg_db_usage_user: Array<Project_Db_Usage_Agg_User>;
  /** execute function "compute_project_agg_db_usage_user" and query aggregates on result of table type "project_db_usage_agg_user" */
  compute_project_agg_db_usage_user_aggregate: Project_Db_Usage_Agg_User_Aggregate;
  /** execute function "compute_project_agg_usage" which returns "project_data_usage_agg" */
  compute_project_agg_usage: Array<Project_Data_Usage_Agg>;
  /** execute function "compute_project_agg_usage" and query aggregates on result of table type "project_data_usage_agg" */
  compute_project_agg_usage_aggregate: Project_Data_Usage_Agg_Aggregate;
  /** execute function "compute_project_agg_usage_prometheus" which returns "project_data_usage_agg" */
  compute_project_agg_usage_prometheus: Array<Project_Data_Usage_Agg>;
  /** execute function "compute_project_agg_usage_prometheus" and query aggregates on result of table type "project_data_usage_agg" */
  compute_project_agg_usage_prometheus_aggregate: Project_Data_Usage_Agg_Aggregate;
  /** execute function "compute_project_agg_usage_prometheus_user" which returns "project_data_usage_prometheus_agg_user" */
  compute_project_agg_usage_prometheus_user: Array<
    Project_Data_Usage_Prometheus_Agg_User
  >;
  /** execute function "compute_project_agg_usage_prometheus_user" and query aggregates on result of table type "project_data_usage_prometheus_agg_user" */
  compute_project_agg_usage_prometheus_user_aggregate: Project_Data_Usage_Prometheus_Agg_User_Aggregate;
  /** execute function "compute_project_agg_usage_user" which returns "project_data_usage_agg_user" */
  compute_project_agg_usage_user: Array<Project_Data_Usage_Agg_User>;
  /** execute function "compute_project_agg_usage_user" and query aggregates on result of table type "project_data_usage_agg_user" */
  compute_project_agg_usage_user_aggregate: Project_Data_Usage_Agg_User_Aggregate;
  /** fetch data from the table: "compute_unit_config" */
  compute_unit_config: Array<Compute_Unit_Config>;
  /** fetch aggregated fields from the table: "compute_unit_config" */
  compute_unit_config_aggregate: Compute_Unit_Config_Aggregate;
  /** fetch data from the table: "compute_unit_config" using primary key columns */
  compute_unit_config_by_pk?: Maybe<Compute_Unit_Config>;
  /** fetch data from the table in a streaming manner: "compute_unit_config" */
  compute_unit_config_stream: Array<Compute_Unit_Config>;
  /** fetch data from the table: "config" */
  config: Array<Config>;
  /** fetch aggregated fields from the table: "config" */
  config_aggregate: Config_Aggregate;
  /** fetch data from the table: "config" using primary key columns */
  config_by_pk?: Maybe<Config>;
  /** fetch data from the table: "config_status" */
  config_status: Array<Config_Status>;
  /** fetch aggregated fields from the table: "config_status" */
  config_status_aggregate: Config_Status_Aggregate;
  /** fetch data from the table: "config_status" using primary key columns */
  config_status_by_pk?: Maybe<Config_Status>;
  /** fetch data from the table in a streaming manner: "config_status" */
  config_status_stream: Array<Config_Status>;
  /** fetch data from the table in a streaming manner: "config" */
  config_stream: Array<Config>;
  /** fetch data from the table: "connector_config" */
  connector_config: Array<Connector_Config>;
  /** fetch aggregated fields from the table: "connector_config" */
  connector_config_aggregate: Connector_Config_Aggregate;
  /** fetch data from the table: "connector_config" using primary key columns */
  connector_config_by_pk?: Maybe<Connector_Config>;
  /** fetch data from the table in a streaming manner: "connector_config" */
  connector_config_stream: Array<Connector_Config>;
  /** fetch data from the table: "connector_deployments" */
  connector_deployments: Array<Connector_Deployments>;
  /** fetch aggregated fields from the table: "connector_deployments" */
  connector_deployments_aggregate: Connector_Deployments_Aggregate;
  /** fetch data from the table: "connector_deployments" using primary key columns */
  connector_deployments_by_pk?: Maybe<Connector_Deployments>;
  /** fetch data from the table in a streaming manner: "connector_deployments" */
  connector_deployments_stream: Array<Connector_Deployments>;
  /** An array relationship */
  coupon: Array<Coupon>;
  /** An aggregate relationship */
  coupon_aggregate: Coupon_Aggregate;
  /** fetch data from the table: "coupon" using primary key columns */
  coupon_by_pk?: Maybe<Coupon>;
  /** fetch data from the table: "coupon_duration" */
  coupon_duration: Array<Coupon_Duration>;
  /** fetch aggregated fields from the table: "coupon_duration" */
  coupon_duration_aggregate: Coupon_Duration_Aggregate;
  /** fetch data from the table: "coupon_duration" using primary key columns */
  coupon_duration_by_pk?: Maybe<Coupon_Duration>;
  /** fetch data from the table in a streaming manner: "coupon_duration" */
  coupon_duration_stream: Array<Coupon_Duration>;
  /** fetch data from the table in a streaming manner: "coupon" */
  coupon_stream: Array<Coupon>;
  /** fetch data from the table: "coupon_type" */
  coupon_type: Array<Coupon_Type>;
  /** fetch aggregated fields from the table: "coupon_type" */
  coupon_type_aggregate: Coupon_Type_Aggregate;
  /** fetch data from the table: "coupon_type" using primary key columns */
  coupon_type_by_pk?: Maybe<Coupon_Type>;
  /** fetch data from the table in a streaming manner: "coupon_type" */
  coupon_type_stream: Array<Coupon_Type>;
  /** fetch data from the table: "custom_domain" */
  custom_domain: Array<Custom_Domain>;
  /** fetch aggregated fields from the table: "custom_domain" */
  custom_domain_aggregate: Custom_Domain_Aggregate;
  /** fetch data from the table: "custom_domain" using primary key columns */
  custom_domain_by_pk?: Maybe<Custom_Domain>;
  /** fetch data from the table: "custom_domain_cloudflare" */
  custom_domain_cloudflare: Array<Custom_Domain_Cloudflare>;
  /** fetch aggregated fields from the table: "custom_domain_cloudflare" */
  custom_domain_cloudflare_aggregate: Custom_Domain_Cloudflare_Aggregate;
  /** fetch data from the table: "custom_domain_cloudflare" using primary key columns */
  custom_domain_cloudflare_by_pk?: Maybe<Custom_Domain_Cloudflare>;
  /** fetch data from the table: "custom_domain_cloudflare_dns" */
  custom_domain_cloudflare_dns: Array<Custom_Domain_Cloudflare_Dns>;
  /** fetch aggregated fields from the table: "custom_domain_cloudflare_dns" */
  custom_domain_cloudflare_dns_aggregate: Custom_Domain_Cloudflare_Dns_Aggregate;
  /** fetch data from the table: "custom_domain_cloudflare_dns" using primary key columns */
  custom_domain_cloudflare_dns_by_pk?: Maybe<Custom_Domain_Cloudflare_Dns>;
  /** fetch data from the table in a streaming manner: "custom_domain_cloudflare_dns" */
  custom_domain_cloudflare_dns_stream: Array<Custom_Domain_Cloudflare_Dns>;
  /** fetch data from the table in a streaming manner: "custom_domain_cloudflare" */
  custom_domain_cloudflare_stream: Array<Custom_Domain_Cloudflare>;
  /** fetch data from the table in a streaming manner: "custom_domain" */
  custom_domain_stream: Array<Custom_Domain>;
  /** fetch data from the table: "customer_usage" */
  customer_usage: Array<Customer_Usage>;
  /** fetch aggregated fields from the table: "customer_usage" */
  customer_usage_aggregate: Customer_Usage_Aggregate;
  /** fetch data from the table: "customer_usage" using primary key columns */
  customer_usage_by_pk?: Maybe<Customer_Usage>;
  /** fetch data from the table in a streaming manner: "customer_usage" */
  customer_usage_stream: Array<Customer_Usage>;
  /** fetch data from the table: "data_connector_type" */
  data_connector_type: Array<Data_Connector_Type>;
  /** fetch aggregated fields from the table: "data_connector_type" */
  data_connector_type_aggregate: Data_Connector_Type_Aggregate;
  /** fetch data from the table: "data_connector_type" using primary key columns */
  data_connector_type_by_pk?: Maybe<Data_Connector_Type>;
  /** fetch data from the table in a streaming manner: "data_connector_type" */
  data_connector_type_stream: Array<Data_Connector_Type>;
  /** fetch data from the table: "datadog_config" */
  datadog_config: Array<Datadog_Config>;
  /** fetch aggregated fields from the table: "datadog_config" */
  datadog_config_aggregate: Datadog_Config_Aggregate;
  /** fetch data from the table: "datadog_config" using primary key columns */
  datadog_config_by_pk?: Maybe<Datadog_Config>;
  /** fetch data from the table in a streaming manner: "datadog_config" */
  datadog_config_stream: Array<Datadog_Config>;
  /** fetch data from the table: "db_latency" */
  db_latency: Array<Db_Latency>;
  /** fetch aggregated fields from the table: "db_latency" */
  db_latency_aggregate: Db_Latency_Aggregate;
  /** fetch data from the table: "db_latency" using primary key columns */
  db_latency_by_pk?: Maybe<Db_Latency>;
  /** fetch data from the table in a streaming manner: "db_latency" */
  db_latency_stream: Array<Db_Latency>;
  /** fetch data from the table: "ddn.applied_build_changelog" */
  ddn_applied_build_changelog: Array<Ddn_Applied_Build_Changelog>;
  /** fetch aggregated fields from the table: "ddn.applied_build_changelog" */
  ddn_applied_build_changelog_aggregate: Ddn_Applied_Build_Changelog_Aggregate;
  /** fetch data from the table: "ddn.applied_build_changelog" using primary key columns */
  ddn_applied_build_changelog_by_pk?: Maybe<Ddn_Applied_Build_Changelog>;
  /** fetch data from the table in a streaming manner: "ddn.applied_build_changelog" */
  ddn_applied_build_changelog_stream: Array<Ddn_Applied_Build_Changelog>;
  /** fetch data from the table: "ddn.applied_subgraph_build_changelog" */
  ddn_applied_subgraph_build_changelog: Array<
    Ddn_Applied_Subgraph_Build_Changelog
  >;
  /** fetch aggregated fields from the table: "ddn.applied_subgraph_build_changelog" */
  ddn_applied_subgraph_build_changelog_aggregate: Ddn_Applied_Subgraph_Build_Changelog_Aggregate;
  /** fetch data from the table: "ddn.applied_subgraph_build_changelog" using primary key columns */
  ddn_applied_subgraph_build_changelog_by_pk?: Maybe<
    Ddn_Applied_Subgraph_Build_Changelog
  >;
  /** fetch data from the table in a streaming manner: "ddn.applied_subgraph_build_changelog" */
  ddn_applied_subgraph_build_changelog_stream: Array<
    Ddn_Applied_Subgraph_Build_Changelog
  >;
  /** fetch data from the table: "ddn.auto_approve_project_request" */
  ddn_auto_approve_project_request: Array<Ddn_Auto_Approve_Project_Request>;
  /** fetch aggregated fields from the table: "ddn.auto_approve_project_request" */
  ddn_auto_approve_project_request_aggregate: Ddn_Auto_Approve_Project_Request_Aggregate;
  /** fetch data from the table: "ddn.auto_approve_project_request" using primary key columns */
  ddn_auto_approve_project_request_by_pk?: Maybe<
    Ddn_Auto_Approve_Project_Request
  >;
  /** fetch data from the table in a streaming manner: "ddn.auto_approve_project_request" */
  ddn_auto_approve_project_request_stream: Array<
    Ddn_Auto_Approve_Project_Request
  >;
  /** fetch data from the table: "ddn.build" */
  ddn_build: Array<Ddn_Build>;
  /** fetch aggregated fields from the table: "ddn.build" */
  ddn_build_aggregate: Ddn_Build_Aggregate;
  /** fetch data from the table: "ddn.build" using primary key columns */
  ddn_build_by_pk?: Maybe<Ddn_Build>;
  /** fetch data from the table: "ddn.build_details" */
  ddn_build_details: Array<Ddn_Build_Details>;
  /** fetch aggregated fields from the table: "ddn.build_details" */
  ddn_build_details_aggregate: Ddn_Build_Details_Aggregate;
  /** fetch data from the table: "ddn.build_details" using primary key columns */
  ddn_build_details_by_pk?: Maybe<Ddn_Build_Details>;
  /** fetch data from the table in a streaming manner: "ddn.build_details" */
  ddn_build_details_stream: Array<Ddn_Build_Details>;
  /** fetch data from the table: "ddn.build_lock" */
  ddn_build_lock: Array<Ddn_Build_Lock>;
  /** fetch aggregated fields from the table: "ddn.build_lock" */
  ddn_build_lock_aggregate: Ddn_Build_Lock_Aggregate;
  /** fetch data from the table: "ddn.build_lock" using primary key columns */
  ddn_build_lock_by_pk?: Maybe<Ddn_Build_Lock>;
  /** fetch data from the table in a streaming manner: "ddn.build_lock" */
  ddn_build_lock_stream: Array<Ddn_Build_Lock>;
  /** fetch data from the table: "ddn.build_model_count" */
  ddn_build_model_count: Array<Ddn_Build_Model_Count>;
  /** fetch aggregated fields from the table: "ddn.build_model_count" */
  ddn_build_model_count_aggregate: Ddn_Build_Model_Count_Aggregate;
  /** fetch data from the table in a streaming manner: "ddn.build_model_count" */
  ddn_build_model_count_stream: Array<Ddn_Build_Model_Count>;
  /** fetch data from the table: "ddn.build_revision" */
  ddn_build_revision: Array<Ddn_Build_Revision>;
  /** fetch aggregated fields from the table: "ddn.build_revision" */
  ddn_build_revision_aggregate: Ddn_Build_Revision_Aggregate;
  /** fetch data from the table: "ddn.build_revision" using primary key columns */
  ddn_build_revision_by_pk?: Maybe<Ddn_Build_Revision>;
  /** fetch data from the table: "ddn.build_revision_status" */
  ddn_build_revision_status: Array<Ddn_Build_Revision_Status>;
  /** fetch aggregated fields from the table: "ddn.build_revision_status" */
  ddn_build_revision_status_aggregate: Ddn_Build_Revision_Status_Aggregate;
  /** fetch data from the table: "ddn.build_revision_status" using primary key columns */
  ddn_build_revision_status_by_pk?: Maybe<Ddn_Build_Revision_Status>;
  /** fetch data from the table in a streaming manner: "ddn.build_revision_status" */
  ddn_build_revision_status_stream: Array<Ddn_Build_Revision_Status>;
  /** fetch data from the table in a streaming manner: "ddn.build_revision" */
  ddn_build_revision_stream: Array<Ddn_Build_Revision>;
  /** fetch data from the table in a streaming manner: "ddn.build" */
  ddn_build_stream: Array<Ddn_Build>;
  /** fetch data from the table: "ddn.build_sync_status" */
  ddn_build_sync_status: Array<Ddn_Build_Sync_Status>;
  /** fetch aggregated fields from the table: "ddn.build_sync_status" */
  ddn_build_sync_status_aggregate: Ddn_Build_Sync_Status_Aggregate;
  /** fetch data from the table: "ddn.build_sync_status" using primary key columns */
  ddn_build_sync_status_by_pk?: Maybe<Ddn_Build_Sync_Status>;
  /** fetch data from the table in a streaming manner: "ddn.build_sync_status" */
  ddn_build_sync_status_stream: Array<Ddn_Build_Sync_Status>;
  /** fetch data from the table: "ddn.build_sync_worker" */
  ddn_build_sync_worker: Array<Ddn_Build_Sync_Worker>;
  /** fetch aggregated fields from the table: "ddn.build_sync_worker" */
  ddn_build_sync_worker_aggregate: Ddn_Build_Sync_Worker_Aggregate;
  /** fetch data from the table: "ddn.build_sync_worker" using primary key columns */
  ddn_build_sync_worker_by_pk?: Maybe<Ddn_Build_Sync_Worker>;
  /** fetch data from the table in a streaming manner: "ddn.build_sync_worker" */
  ddn_build_sync_worker_stream: Array<Ddn_Build_Sync_Worker>;
  /** fetch data from the table: "ddn.collaboration_invitee_project_view" */
  ddn_collaboration_invitee_project_view: Array<
    Ddn_Collaboration_Invitee_Project_View
  >;
  /** fetch aggregated fields from the table: "ddn.collaboration_invitee_project_view" */
  ddn_collaboration_invitee_project_view_aggregate: Ddn_Collaboration_Invitee_Project_View_Aggregate;
  /** fetch data from the table in a streaming manner: "ddn.collaboration_invitee_project_view" */
  ddn_collaboration_invitee_project_view_stream: Array<
    Ddn_Collaboration_Invitee_Project_View
  >;
  /** fetch data from the table: "ddn.connector_deployment" */
  ddn_connector_deployment: Array<Ddn_Connector_Deployment>;
  /** fetch aggregated fields from the table: "ddn.connector_deployment" */
  ddn_connector_deployment_aggregate: Ddn_Connector_Deployment_Aggregate;
  /** fetch data from the table: "ddn.connector_deployment" using primary key columns */
  ddn_connector_deployment_by_pk?: Maybe<Ddn_Connector_Deployment>;
  /** fetch data from the table in a streaming manner: "ddn.connector_deployment" */
  ddn_connector_deployment_stream: Array<Ddn_Connector_Deployment>;
  /** fetch data from the table: "ddn.ddn" */
  ddn_ddn: Array<Ddn_Ddn>;
  /** fetch aggregated fields from the table: "ddn.ddn" */
  ddn_ddn_aggregate: Ddn_Ddn_Aggregate;
  /** fetch data from the table: "ddn.ddn" using primary key columns */
  ddn_ddn_by_pk?: Maybe<Ddn_Ddn>;
  /** fetch data from the table in a streaming manner: "ddn.ddn" */
  ddn_ddn_stream: Array<Ddn_Ddn>;
  /** fetch data from the table: "ddn.environment" */
  ddn_environment: Array<Ddn_Environment>;
  /** fetch aggregated fields from the table: "ddn.environment" */
  ddn_environment_aggregate: Ddn_Environment_Aggregate;
  /** fetch data from the table: "ddn.environment" using primary key columns */
  ddn_environment_by_pk?: Maybe<Ddn_Environment>;
  /** fetch data from the table in a streaming manner: "ddn.environment" */
  ddn_environment_stream: Array<Ddn_Environment>;
  /** fetch data from the table: "ddn.generate_metadata_artifacts_log" */
  ddn_generate_metadata_artifacts_log: Array<
    Ddn_Generate_Metadata_Artifacts_Log
  >;
  /** fetch aggregated fields from the table: "ddn.generate_metadata_artifacts_log" */
  ddn_generate_metadata_artifacts_log_aggregate: Ddn_Generate_Metadata_Artifacts_Log_Aggregate;
  /** fetch data from the table: "ddn.generate_metadata_artifacts_log" using primary key columns */
  ddn_generate_metadata_artifacts_log_by_pk?: Maybe<
    Ddn_Generate_Metadata_Artifacts_Log
  >;
  /** fetch data from the table in a streaming manner: "ddn.generate_metadata_artifacts_log" */
  ddn_generate_metadata_artifacts_log_stream: Array<
    Ddn_Generate_Metadata_Artifacts_Log
  >;
  /** execute function "ddn.get_token_access_level" which returns "ddn.token_access_level_output" */
  ddn_get_token_access_level?: Maybe<Ddn_Token_Access_Level_Output>;
  /** execute function "ddn.get_token_access_level" and query aggregates on result of table type "ddn.token_access_level_output" */
  ddn_get_token_access_level_aggregate: Ddn_Token_Access_Level_Output_Aggregate;
  /** fetch data from the table: "ddn.invoice" */
  ddn_invoice: Array<Ddn_Invoice>;
  /** fetch aggregated fields from the table: "ddn.invoice" */
  ddn_invoice_aggregate: Ddn_Invoice_Aggregate;
  /** fetch data from the table: "ddn.invoice" using primary key columns */
  ddn_invoice_by_pk?: Maybe<Ddn_Invoice>;
  /** fetch data from the table: "ddn.invoice_item" */
  ddn_invoice_item: Array<Ddn_Invoice_Item>;
  /** fetch aggregated fields from the table: "ddn.invoice_item" */
  ddn_invoice_item_aggregate: Ddn_Invoice_Item_Aggregate;
  /** fetch data from the table: "ddn.invoice_item" using primary key columns */
  ddn_invoice_item_by_pk?: Maybe<Ddn_Invoice_Item>;
  /** fetch data from the table in a streaming manner: "ddn.invoice_item" */
  ddn_invoice_item_stream: Array<Ddn_Invoice_Item>;
  /** fetch data from the table in a streaming manner: "ddn.invoice" */
  ddn_invoice_stream: Array<Ddn_Invoice>;
  /** fetch data from the table: "ddn.plan_entitlement_access" */
  ddn_plan_entitlement_access: Array<Ddn_Plan_Entitlement_Access>;
  /** fetch aggregated fields from the table: "ddn.plan_entitlement_access" */
  ddn_plan_entitlement_access_aggregate: Ddn_Plan_Entitlement_Access_Aggregate;
  /** fetch data from the table: "ddn.plan_entitlement_access" using primary key columns */
  ddn_plan_entitlement_access_by_pk?: Maybe<Ddn_Plan_Entitlement_Access>;
  /** fetch data from the table in a streaming manner: "ddn.plan_entitlement_access" */
  ddn_plan_entitlement_access_stream: Array<Ddn_Plan_Entitlement_Access>;
  /** fetch data from the table: "ddn.plans" */
  ddn_plans: Array<Ddn_Plans>;
  /** fetch aggregated fields from the table: "ddn.plans" */
  ddn_plans_aggregate: Ddn_Plans_Aggregate;
  /** fetch data from the table: "ddn.plans" using primary key columns */
  ddn_plans_by_pk?: Maybe<Ddn_Plans>;
  /** fetch data from the table in a streaming manner: "ddn.plans" */
  ddn_plans_stream: Array<Ddn_Plans>;
  /** fetch data from the table: "ddn.private_ddn" */
  ddn_private_ddn: Array<Ddn_Private_Ddn>;
  /** fetch aggregated fields from the table: "ddn.private_ddn" */
  ddn_private_ddn_aggregate: Ddn_Private_Ddn_Aggregate;
  /** fetch data from the table: "ddn.private_ddn" using primary key columns */
  ddn_private_ddn_by_pk?: Maybe<Ddn_Private_Ddn>;
  /** fetch data from the table: "ddn.private_ddn_region" */
  ddn_private_ddn_region: Array<Ddn_Private_Ddn_Region>;
  /** fetch aggregated fields from the table: "ddn.private_ddn_region" */
  ddn_private_ddn_region_aggregate: Ddn_Private_Ddn_Region_Aggregate;
  /** fetch data from the table: "ddn.private_ddn_region" using primary key columns */
  ddn_private_ddn_region_by_pk?: Maybe<Ddn_Private_Ddn_Region>;
  /** fetch data from the table in a streaming manner: "ddn.private_ddn_region" */
  ddn_private_ddn_region_stream: Array<Ddn_Private_Ddn_Region>;
  /** fetch data from the table in a streaming manner: "ddn.private_ddn" */
  ddn_private_ddn_stream: Array<Ddn_Private_Ddn>;
  /** fetch data from the table: "ddn.project_access_level" */
  ddn_project_access_level: Array<Ddn_Project_Access_Level>;
  /** fetch aggregated fields from the table: "ddn.project_access_level" */
  ddn_project_access_level_aggregate: Ddn_Project_Access_Level_Aggregate;
  /** fetch data from the table: "ddn.project_access_level" using primary key columns */
  ddn_project_access_level_by_pk?: Maybe<Ddn_Project_Access_Level>;
  /** fetch data from the table in a streaming manner: "ddn.project_access_level" */
  ddn_project_access_level_stream: Array<Ddn_Project_Access_Level>;
  /** fetch data from the table: "ddn.project_active_status_changelog" */
  ddn_project_active_status_changelog: Array<
    Ddn_Project_Active_Status_Changelog
  >;
  /** fetch aggregated fields from the table: "ddn.project_active_status_changelog" */
  ddn_project_active_status_changelog_aggregate: Ddn_Project_Active_Status_Changelog_Aggregate;
  /** fetch data from the table: "ddn.project_active_status_changelog" using primary key columns */
  ddn_project_active_status_changelog_by_pk?: Maybe<
    Ddn_Project_Active_Status_Changelog
  >;
  /** fetch data from the table in a streaming manner: "ddn.project_active_status_changelog" */
  ddn_project_active_status_changelog_stream: Array<
    Ddn_Project_Active_Status_Changelog
  >;
  /** fetch data from the table: "ddn.project_active_status_reason" */
  ddn_project_active_status_reason: Array<Ddn_Project_Active_Status_Reason>;
  /** fetch aggregated fields from the table: "ddn.project_active_status_reason" */
  ddn_project_active_status_reason_aggregate: Ddn_Project_Active_Status_Reason_Aggregate;
  /** fetch data from the table: "ddn.project_active_status_reason" using primary key columns */
  ddn_project_active_status_reason_by_pk?: Maybe<
    Ddn_Project_Active_Status_Reason
  >;
  /** fetch data from the table in a streaming manner: "ddn.project_active_status_reason" */
  ddn_project_active_status_reason_stream: Array<
    Ddn_Project_Active_Status_Reason
  >;
  /** fetch data from the table: "ddn.project_entitlement_access" */
  ddn_project_entitlement_access: Array<Ddn_Project_Entitlement_Access>;
  /** fetch aggregated fields from the table: "ddn.project_entitlement_access" */
  ddn_project_entitlement_access_aggregate: Ddn_Project_Entitlement_Access_Aggregate;
  /** fetch data from the table: "ddn.project_entitlement_access" using primary key columns */
  ddn_project_entitlement_access_by_pk?: Maybe<Ddn_Project_Entitlement_Access>;
  /** fetch data from the table in a streaming manner: "ddn.project_entitlement_access" */
  ddn_project_entitlement_access_stream: Array<Ddn_Project_Entitlement_Access>;
  /** fetch data from the table: "ddn.project_entitlement_catalogue" */
  ddn_project_entitlement_catalogue: Array<Ddn_Project_Entitlement_Catalogue>;
  /** fetch aggregated fields from the table: "ddn.project_entitlement_catalogue" */
  ddn_project_entitlement_catalogue_aggregate: Ddn_Project_Entitlement_Catalogue_Aggregate;
  /** fetch data from the table: "ddn.project_entitlement_catalogue" using primary key columns */
  ddn_project_entitlement_catalogue_by_pk?: Maybe<
    Ddn_Project_Entitlement_Catalogue
  >;
  /** fetch data from the table in a streaming manner: "ddn.project_entitlement_catalogue" */
  ddn_project_entitlement_catalogue_stream: Array<
    Ddn_Project_Entitlement_Catalogue
  >;
  /** fetch data from the table: "ddn.project_entitlement_types" */
  ddn_project_entitlement_types: Array<Ddn_Project_Entitlement_Types>;
  /** fetch aggregated fields from the table: "ddn.project_entitlement_types" */
  ddn_project_entitlement_types_aggregate: Ddn_Project_Entitlement_Types_Aggregate;
  /** fetch data from the table: "ddn.project_entitlement_types" using primary key columns */
  ddn_project_entitlement_types_by_pk?: Maybe<Ddn_Project_Entitlement_Types>;
  /** fetch data from the table in a streaming manner: "ddn.project_entitlement_types" */
  ddn_project_entitlement_types_stream: Array<Ddn_Project_Entitlement_Types>;
  /** fetch data from the table: "ddn.project_plan_changelog" */
  ddn_project_plan_changelog: Array<Ddn_Project_Plan_Changelog>;
  /** fetch aggregated fields from the table: "ddn.project_plan_changelog" */
  ddn_project_plan_changelog_aggregate: Ddn_Project_Plan_Changelog_Aggregate;
  /** fetch data from the table: "ddn.project_plan_changelog" using primary key columns */
  ddn_project_plan_changelog_by_pk?: Maybe<Ddn_Project_Plan_Changelog>;
  /** fetch data from the table in a streaming manner: "ddn.project_plan_changelog" */
  ddn_project_plan_changelog_stream: Array<Ddn_Project_Plan_Changelog>;
  /** fetch data from the table: "ddn.project_request_access" */
  ddn_project_request_access: Array<Ddn_Project_Request_Access>;
  /** fetch aggregated fields from the table: "ddn.project_request_access" */
  ddn_project_request_access_aggregate: Ddn_Project_Request_Access_Aggregate;
  /** fetch data from the table: "ddn.project_request_access" using primary key columns */
  ddn_project_request_access_by_pk?: Maybe<Ddn_Project_Request_Access>;
  /** fetch data from the table in a streaming manner: "ddn.project_request_access" */
  ddn_project_request_access_stream: Array<Ddn_Project_Request_Access>;
  /** An array relationship */
  ddn_projects: Array<Ddn_Projects>;
  /** An aggregate relationship */
  ddn_projects_aggregate: Ddn_Projects_Aggregate;
  /** fetch data from the table: "ddn.projects" using primary key columns */
  ddn_projects_by_pk?: Maybe<Ddn_Projects>;
  /** fetch data from the table in a streaming manner: "ddn.projects" */
  ddn_projects_stream: Array<Ddn_Projects>;
  /** execute function "ddn.search_tunnel_cluster" which returns "ddn.tunnel_cluster" */
  ddn_search_tunnel_cluster: Array<Ddn_Tunnel_Cluster>;
  /** execute function "ddn.search_tunnel_cluster" and query aggregates on result of table type "ddn.tunnel_cluster" */
  ddn_search_tunnel_cluster_aggregate: Ddn_Tunnel_Cluster_Aggregate;
  /** fetch data from the table: "ddn.secret_key" */
  ddn_secret_key: Array<Ddn_Secret_Key>;
  /** fetch aggregated fields from the table: "ddn.secret_key" */
  ddn_secret_key_aggregate: Ddn_Secret_Key_Aggregate;
  /** fetch data from the table: "ddn.secret_key" using primary key columns */
  ddn_secret_key_by_pk?: Maybe<Ddn_Secret_Key>;
  /** fetch data from the table in a streaming manner: "ddn.secret_key" */
  ddn_secret_key_stream: Array<Ddn_Secret_Key>;
  /** fetch data from the table: "ddn.stripe_subscription" */
  ddn_stripe_subscription: Array<Ddn_Stripe_Subscription>;
  /** fetch aggregated fields from the table: "ddn.stripe_subscription" */
  ddn_stripe_subscription_aggregate: Ddn_Stripe_Subscription_Aggregate;
  /** fetch data from the table: "ddn.stripe_subscription" using primary key columns */
  ddn_stripe_subscription_by_pk?: Maybe<Ddn_Stripe_Subscription>;
  /** fetch data from the table in a streaming manner: "ddn.stripe_subscription" */
  ddn_stripe_subscription_stream: Array<Ddn_Stripe_Subscription>;
  /** fetch data from the table: "ddn.stripe_webhook_events" */
  ddn_stripe_webhook_events: Array<Ddn_Stripe_Webhook_Events>;
  /** fetch aggregated fields from the table: "ddn.stripe_webhook_events" */
  ddn_stripe_webhook_events_aggregate: Ddn_Stripe_Webhook_Events_Aggregate;
  /** fetch data from the table: "ddn.stripe_webhook_events" using primary key columns */
  ddn_stripe_webhook_events_by_pk?: Maybe<Ddn_Stripe_Webhook_Events>;
  /** fetch data from the table in a streaming manner: "ddn.stripe_webhook_events" */
  ddn_stripe_webhook_events_stream: Array<Ddn_Stripe_Webhook_Events>;
  /** fetch data from the table: "ddn.subgraph" */
  ddn_subgraph: Array<Ddn_Subgraph>;
  /** fetch aggregated fields from the table: "ddn.subgraph" */
  ddn_subgraph_aggregate: Ddn_Subgraph_Aggregate;
  /** fetch data from the table: "ddn.subgraph_build" */
  ddn_subgraph_build: Array<Ddn_Subgraph_Build>;
  /** fetch aggregated fields from the table: "ddn.subgraph_build" */
  ddn_subgraph_build_aggregate: Ddn_Subgraph_Build_Aggregate;
  /** fetch data from the table: "ddn.subgraph_build" using primary key columns */
  ddn_subgraph_build_by_pk?: Maybe<Ddn_Subgraph_Build>;
  /** fetch data from the table in a streaming manner: "ddn.subgraph_build" */
  ddn_subgraph_build_stream: Array<Ddn_Subgraph_Build>;
  /** fetch data from the table: "ddn.subgraph" using primary key columns */
  ddn_subgraph_by_pk?: Maybe<Ddn_Subgraph>;
  /** fetch data from the table in a streaming manner: "ddn.subgraph" */
  ddn_subgraph_stream: Array<Ddn_Subgraph>;
  /** fetch data from the table: "ddn.team" */
  ddn_team: Array<Ddn_Team>;
  /** fetch aggregated fields from the table: "ddn.team" */
  ddn_team_aggregate: Ddn_Team_Aggregate;
  /** fetch data from the table: "ddn.team" using primary key columns */
  ddn_team_by_pk?: Maybe<Ddn_Team>;
  /** fetch data from the table: "ddn.team_invitation" */
  ddn_team_invitation: Array<Ddn_Team_Invitation>;
  /** fetch aggregated fields from the table: "ddn.team_invitation" */
  ddn_team_invitation_aggregate: Ddn_Team_Invitation_Aggregate;
  /** fetch data from the table: "ddn.team_invitation" using primary key columns */
  ddn_team_invitation_by_pk?: Maybe<Ddn_Team_Invitation>;
  /** fetch data from the table: "ddn.team_invitation_status" */
  ddn_team_invitation_status: Array<Ddn_Team_Invitation_Status>;
  /** fetch aggregated fields from the table: "ddn.team_invitation_status" */
  ddn_team_invitation_status_aggregate: Ddn_Team_Invitation_Status_Aggregate;
  /** fetch data from the table: "ddn.team_invitation_status" using primary key columns */
  ddn_team_invitation_status_by_pk?: Maybe<Ddn_Team_Invitation_Status>;
  /** fetch data from the table in a streaming manner: "ddn.team_invitation_status" */
  ddn_team_invitation_status_stream: Array<Ddn_Team_Invitation_Status>;
  /** fetch data from the table in a streaming manner: "ddn.team_invitation" */
  ddn_team_invitation_stream: Array<Ddn_Team_Invitation>;
  /** fetch data from the table: "ddn.team_kind" */
  ddn_team_kind: Array<Ddn_Team_Kind>;
  /** fetch aggregated fields from the table: "ddn.team_kind" */
  ddn_team_kind_aggregate: Ddn_Team_Kind_Aggregate;
  /** fetch data from the table: "ddn.team_kind" using primary key columns */
  ddn_team_kind_by_pk?: Maybe<Ddn_Team_Kind>;
  /** fetch data from the table in a streaming manner: "ddn.team_kind" */
  ddn_team_kind_stream: Array<Ddn_Team_Kind>;
  /** fetch data from the table: "ddn.team_membership" */
  ddn_team_membership: Array<Ddn_Team_Membership>;
  /** fetch aggregated fields from the table: "ddn.team_membership" */
  ddn_team_membership_aggregate: Ddn_Team_Membership_Aggregate;
  /** fetch data from the table: "ddn.team_membership" using primary key columns */
  ddn_team_membership_by_pk?: Maybe<Ddn_Team_Membership>;
  /** fetch data from the table in a streaming manner: "ddn.team_membership" */
  ddn_team_membership_stream: Array<Ddn_Team_Membership>;
  /** fetch data from the table: "ddn.team_project_access" */
  ddn_team_project_access: Array<Ddn_Team_Project_Access>;
  /** fetch aggregated fields from the table: "ddn.team_project_access" */
  ddn_team_project_access_aggregate: Ddn_Team_Project_Access_Aggregate;
  /** fetch data from the table: "ddn.team_project_access" using primary key columns */
  ddn_team_project_access_by_pk?: Maybe<Ddn_Team_Project_Access>;
  /** fetch data from the table in a streaming manner: "ddn.team_project_access" */
  ddn_team_project_access_stream: Array<Ddn_Team_Project_Access>;
  /** fetch data from the table in a streaming manner: "ddn.team" */
  ddn_team_stream: Array<Ddn_Team>;
  /** fetch data from the table: "ddn.token_access_level_output" */
  ddn_token_access_level_output: Array<Ddn_Token_Access_Level_Output>;
  /** fetch aggregated fields from the table: "ddn.token_access_level_output" */
  ddn_token_access_level_output_aggregate: Ddn_Token_Access_Level_Output_Aggregate;
  /** fetch data from the table in a streaming manner: "ddn.token_access_level_output" */
  ddn_token_access_level_output_stream: Array<Ddn_Token_Access_Level_Output>;
  /** fetch data from the table: "ddn.tunnel" */
  ddn_tunnel: Array<Ddn_Tunnel>;
  /** fetch aggregated fields from the table: "ddn.tunnel" */
  ddn_tunnel_aggregate: Ddn_Tunnel_Aggregate;
  /** fetch data from the table: "ddn.tunnel" using primary key columns */
  ddn_tunnel_by_pk?: Maybe<Ddn_Tunnel>;
  /** fetch data from the table: "ddn.tunnel_cluster" */
  ddn_tunnel_cluster: Array<Ddn_Tunnel_Cluster>;
  /** fetch aggregated fields from the table: "ddn.tunnel_cluster" */
  ddn_tunnel_cluster_aggregate: Ddn_Tunnel_Cluster_Aggregate;
  /** fetch data from the table: "ddn.tunnel_cluster" using primary key columns */
  ddn_tunnel_cluster_by_pk?: Maybe<Ddn_Tunnel_Cluster>;
  /** fetch data from the table in a streaming manner: "ddn.tunnel_cluster" */
  ddn_tunnel_cluster_stream: Array<Ddn_Tunnel_Cluster>;
  /** fetch data from the table: "ddn.tunnel_http" */
  ddn_tunnel_http: Array<Ddn_Tunnel_Http>;
  /** fetch aggregated fields from the table: "ddn.tunnel_http" */
  ddn_tunnel_http_aggregate: Ddn_Tunnel_Http_Aggregate;
  /** fetch data from the table: "ddn.tunnel_http" using primary key columns */
  ddn_tunnel_http_by_pk?: Maybe<Ddn_Tunnel_Http>;
  /** fetch data from the table: "ddn.tunnel_http_cluster" */
  ddn_tunnel_http_cluster: Array<Ddn_Tunnel_Http_Cluster>;
  /** fetch aggregated fields from the table: "ddn.tunnel_http_cluster" */
  ddn_tunnel_http_cluster_aggregate: Ddn_Tunnel_Http_Cluster_Aggregate;
  /** fetch data from the table: "ddn.tunnel_http_cluster" using primary key columns */
  ddn_tunnel_http_cluster_by_pk?: Maybe<Ddn_Tunnel_Http_Cluster>;
  /** fetch data from the table in a streaming manner: "ddn.tunnel_http_cluster" */
  ddn_tunnel_http_cluster_stream: Array<Ddn_Tunnel_Http_Cluster>;
  /** fetch data from the table in a streaming manner: "ddn.tunnel_http" */
  ddn_tunnel_http_stream: Array<Ddn_Tunnel_Http>;
  /** fetch data from the table in a streaming manner: "ddn.tunnel" */
  ddn_tunnel_stream: Array<Ddn_Tunnel>;
  /** fetch data from the table: "ddn.user_project_access" */
  ddn_user_project_access: Array<Ddn_User_Project_Access>;
  /** fetch aggregated fields from the table: "ddn.user_project_access" */
  ddn_user_project_access_aggregate: Ddn_User_Project_Access_Aggregate;
  /** fetch data from the table in a streaming manner: "ddn.user_project_access" */
  ddn_user_project_access_stream: Array<Ddn_User_Project_Access>;
  /** fetch data from the table: "ddn.v3_customer" */
  ddn_v3_customer: Array<Ddn_V3_Customer>;
  /** fetch aggregated fields from the table: "ddn.v3_customer" */
  ddn_v3_customer_aggregate: Ddn_V3_Customer_Aggregate;
  /** fetch data from the table in a streaming manner: "ddn.v3_customer" */
  ddn_v3_customer_stream: Array<Ddn_V3_Customer>;
  /** fetch data from the table: "dedicated_cloud_bills" */
  dedicated_cloud_bills: Array<Dedicated_Cloud_Bills>;
  /** fetch aggregated fields from the table: "dedicated_cloud_bills" */
  dedicated_cloud_bills_aggregate: Dedicated_Cloud_Bills_Aggregate;
  /** fetch data from the table: "dedicated_cloud_bills" using primary key columns */
  dedicated_cloud_bills_by_pk?: Maybe<Dedicated_Cloud_Bills>;
  /** fetch data from the table: "dedicated_cloud_bills_details" */
  dedicated_cloud_bills_details: Array<Dedicated_Cloud_Bills_Details>;
  /** fetch aggregated fields from the table: "dedicated_cloud_bills_details" */
  dedicated_cloud_bills_details_aggregate: Dedicated_Cloud_Bills_Details_Aggregate;
  /** fetch data from the table: "dedicated_cloud_bills_details" using primary key columns */
  dedicated_cloud_bills_details_by_pk?: Maybe<Dedicated_Cloud_Bills_Details>;
  /** fetch data from the table in a streaming manner: "dedicated_cloud_bills_details" */
  dedicated_cloud_bills_details_stream: Array<Dedicated_Cloud_Bills_Details>;
  /** fetch data from the table in a streaming manner: "dedicated_cloud_bills" */
  dedicated_cloud_bills_stream: Array<Dedicated_Cloud_Bills>;
  /** fetch data from the table: "dedicated_cloud_commitments" */
  dedicated_cloud_commitments: Array<Dedicated_Cloud_Commitments>;
  /** fetch aggregated fields from the table: "dedicated_cloud_commitments" */
  dedicated_cloud_commitments_aggregate: Dedicated_Cloud_Commitments_Aggregate;
  /** fetch data from the table: "dedicated_cloud_commitments" using primary key columns */
  dedicated_cloud_commitments_by_pk?: Maybe<Dedicated_Cloud_Commitments>;
  /** fetch data from the table in a streaming manner: "dedicated_cloud_commitments" */
  dedicated_cloud_commitments_stream: Array<Dedicated_Cloud_Commitments>;
  /** fetch data from the table: "dedicated_vpc" */
  dedicated_vpc: Array<Dedicated_Vpc>;
  /** fetch aggregated fields from the table: "dedicated_vpc" */
  dedicated_vpc_aggregate: Dedicated_Vpc_Aggregate;
  /** fetch data from the table: "dedicated_vpc" using primary key columns */
  dedicated_vpc_by_pk?: Maybe<Dedicated_Vpc>;
  /** fetch data from the table: "dedicated_vpc_owner" */
  dedicated_vpc_owner: Array<Dedicated_Vpc_Owner>;
  /** fetch aggregated fields from the table: "dedicated_vpc_owner" */
  dedicated_vpc_owner_aggregate: Dedicated_Vpc_Owner_Aggregate;
  /** fetch data from the table in a streaming manner: "dedicated_vpc_owner" */
  dedicated_vpc_owner_stream: Array<Dedicated_Vpc_Owner>;
  /** fetch data from the table in a streaming manner: "dedicated_vpc" */
  dedicated_vpc_stream: Array<Dedicated_Vpc>;
  /** fetch data from the table: "delete_user" */
  delete_user: Array<Delete_User>;
  /** fetch aggregated fields from the table: "delete_user" */
  delete_user_aggregate: Delete_User_Aggregate;
  /** fetch data from the table: "delete_user" using primary key columns */
  delete_user_by_pk?: Maybe<Delete_User>;
  /** fetch data from the table: "delete_user_status" */
  delete_user_status: Array<Delete_User_Status>;
  /** fetch aggregated fields from the table: "delete_user_status" */
  delete_user_status_aggregate: Delete_User_Status_Aggregate;
  /** fetch data from the table: "delete_user_status" using primary key columns */
  delete_user_status_by_pk?: Maybe<Delete_User_Status>;
  /** fetch data from the table in a streaming manner: "delete_user_status" */
  delete_user_status_stream: Array<Delete_User_Status>;
  /** fetch data from the table in a streaming manner: "delete_user" */
  delete_user_stream: Array<Delete_User>;
  /** fetch data from the table: "delete_user_tasks" */
  delete_user_tasks: Array<Delete_User_Tasks>;
  /** fetch aggregated fields from the table: "delete_user_tasks" */
  delete_user_tasks_aggregate: Delete_User_Tasks_Aggregate;
  /** fetch data from the table: "delete_user_tasks" using primary key columns */
  delete_user_tasks_by_pk?: Maybe<Delete_User_Tasks>;
  /** fetch data from the table in a streaming manner: "delete_user_tasks" */
  delete_user_tasks_stream: Array<Delete_User_Tasks>;
  /** fetch data from the table: "email_log" */
  email_log: Array<Email_Log>;
  /** fetch aggregated fields from the table: "email_log" */
  email_log_aggregate: Email_Log_Aggregate;
  /** fetch data from the table: "email_log" using primary key columns */
  email_log_by_pk?: Maybe<Email_Log>;
  /** fetch data from the table in a streaming manner: "email_log" */
  email_log_stream: Array<Email_Log>;
  /** fetch data from the table: "enterprise_users" */
  enterprise_users: Array<Enterprise_Users>;
  /** fetch aggregated fields from the table: "enterprise_users" */
  enterprise_users_aggregate: Enterprise_Users_Aggregate;
  /** fetch data from the table: "enterprise_users" using primary key columns */
  enterprise_users_by_pk?: Maybe<Enterprise_Users>;
  /** fetch data from the table: "enterprise_users_domain" */
  enterprise_users_domain: Array<Enterprise_Users_Domain>;
  /** fetch aggregated fields from the table: "enterprise_users_domain" */
  enterprise_users_domain_aggregate: Enterprise_Users_Domain_Aggregate;
  /** fetch data from the table: "enterprise_users_domain" using primary key columns */
  enterprise_users_domain_by_pk?: Maybe<Enterprise_Users_Domain>;
  /** fetch data from the table in a streaming manner: "enterprise_users_domain" */
  enterprise_users_domain_stream: Array<Enterprise_Users_Domain>;
  /** fetch data from the table in a streaming manner: "enterprise_users" */
  enterprise_users_stream: Array<Enterprise_Users>;
  /** fetch data from the table: "experiments" */
  experiments: Array<Experiments>;
  /** fetch aggregated fields from the table: "experiments" */
  experiments_aggregate: Experiments_Aggregate;
  /** fetch data from the table: "experiments" using primary key columns */
  experiments_by_pk?: Maybe<Experiments>;
  /** fetch data from the table: "experiments_cohort" */
  experiments_cohort: Array<Experiments_Cohort>;
  /** fetch aggregated fields from the table: "experiments_cohort" */
  experiments_cohort_aggregate: Experiments_Cohort_Aggregate;
  /** fetch data from the table: "experiments_cohort" using primary key columns */
  experiments_cohort_by_pk?: Maybe<Experiments_Cohort>;
  /** fetch data from the table in a streaming manner: "experiments_cohort" */
  experiments_cohort_stream: Array<Experiments_Cohort>;
  /** fetch data from the table: "experiments_config" */
  experiments_config: Array<Experiments_Config>;
  /** fetch aggregated fields from the table: "experiments_config" */
  experiments_config_aggregate: Experiments_Config_Aggregate;
  /** fetch data from the table: "experiments_config" using primary key columns */
  experiments_config_by_pk?: Maybe<Experiments_Config>;
  /** fetch data from the table in a streaming manner: "experiments_config" */
  experiments_config_stream: Array<Experiments_Config>;
  /** fetch data from the table in a streaming manner: "experiments" */
  experiments_stream: Array<Experiments>;
  /** fetch data from the table: "feature" */
  feature: Array<Feature>;
  /** fetch data from the table: "feature_access" */
  feature_access: Array<Feature_Access>;
  /** fetch aggregated fields from the table: "feature_access" */
  feature_access_aggregate: Feature_Access_Aggregate;
  /** fetch data from the table: "feature_access" using primary key columns */
  feature_access_by_pk?: Maybe<Feature_Access>;
  /** fetch data from the table in a streaming manner: "feature_access" */
  feature_access_stream: Array<Feature_Access>;
  /** fetch aggregated fields from the table: "feature" */
  feature_aggregate: Feature_Aggregate;
  /** fetch data from the table: "feature" using primary key columns */
  feature_by_pk?: Maybe<Feature>;
  /** fetch data from the table: "feature_config" */
  feature_config: Array<Feature_Config>;
  /** fetch aggregated fields from the table: "feature_config" */
  feature_config_aggregate: Feature_Config_Aggregate;
  /** fetch data from the table: "feature_config" using primary key columns */
  feature_config_by_pk?: Maybe<Feature_Config>;
  /** fetch data from the table in a streaming manner: "feature_config" */
  feature_config_stream: Array<Feature_Config>;
  /** fetch data from the table in a streaming manner: "feature" */
  feature_stream: Array<Feature>;
  /** fetch data from the table: "gateway_ami" */
  gateway_ami: Array<Gateway_Ami>;
  /** fetch aggregated fields from the table: "gateway_ami" */
  gateway_ami_aggregate: Gateway_Ami_Aggregate;
  /** fetch data from the table: "gateway_ami" using primary key columns */
  gateway_ami_by_pk?: Maybe<Gateway_Ami>;
  /** fetch data from the table in a streaming manner: "gateway_ami" */
  gateway_ami_stream: Array<Gateway_Ami>;
  /** fetch data from the table: "gateway_cluster" */
  gateway_cluster: Array<Gateway_Cluster>;
  /** fetch aggregated fields from the table: "gateway_cluster" */
  gateway_cluster_aggregate: Gateway_Cluster_Aggregate;
  /** fetch data from the table: "gateway_cluster" using primary key columns */
  gateway_cluster_by_pk?: Maybe<Gateway_Cluster>;
  /** fetch data from the table in a streaming manner: "gateway_cluster" */
  gateway_cluster_stream: Array<Gateway_Cluster>;
  /** fetch data from the table: "gateway_cluster_tenants" */
  gateway_cluster_tenants: Array<Gateway_Cluster_Tenants>;
  /** fetch aggregated fields from the table: "gateway_cluster_tenants" */
  gateway_cluster_tenants_aggregate: Gateway_Cluster_Tenants_Aggregate;
  /** fetch data from the table in a streaming manner: "gateway_cluster_tenants" */
  gateway_cluster_tenants_stream: Array<Gateway_Cluster_Tenants>;
  /** fetch data from the table: "gateway_worker" */
  gateway_worker: Array<Gateway_Worker>;
  /** fetch aggregated fields from the table: "gateway_worker" */
  gateway_worker_aggregate: Gateway_Worker_Aggregate;
  /** fetch data from the table: "gateway_worker" using primary key columns */
  gateway_worker_by_pk?: Maybe<Gateway_Worker>;
  /** fetch data from the table in a streaming manner: "gateway_worker" */
  gateway_worker_stream: Array<Gateway_Worker>;
  /** execute function "get_aggregated_cost_for_project_on_shared_plan" which returns "project_total_db_usage_agg" */
  get_aggregated_cost_for_project_on_shared_plan?: Maybe<
    Project_Total_Db_Usage_Agg
  >;
  /** execute function "get_aggregated_cost_for_project_on_shared_plan" and query aggregates on result of table type "project_total_db_usage_agg" */
  get_aggregated_cost_for_project_on_shared_plan_aggregate: Project_Total_Db_Usage_Agg_Aggregate;
  /** fetch data from the table: "github_email_type" */
  github_email_type: Array<Github_Email_Type>;
  /** fetch aggregated fields from the table: "github_email_type" */
  github_email_type_aggregate: Github_Email_Type_Aggregate;
  /** fetch data from the table: "github_email_type" using primary key columns */
  github_email_type_by_pk?: Maybe<Github_Email_Type>;
  /** fetch data from the table in a streaming manner: "github_email_type" */
  github_email_type_stream: Array<Github_Email_Type>;
  /** fetch data from the table: "github_integration_config" */
  github_integration_config: Array<Github_Integration_Config>;
  /** fetch aggregated fields from the table: "github_integration_config" */
  github_integration_config_aggregate: Github_Integration_Config_Aggregate;
  /** fetch data from the table: "github_integration_config" using primary key columns */
  github_integration_config_by_pk?: Maybe<Github_Integration_Config>;
  /** fetch data from the table in a streaming manner: "github_integration_config" */
  github_integration_config_stream: Array<Github_Integration_Config>;
  /** fetch data from the table: "github_integration_mode" */
  github_integration_mode: Array<Github_Integration_Mode>;
  /** fetch aggregated fields from the table: "github_integration_mode" */
  github_integration_mode_aggregate: Github_Integration_Mode_Aggregate;
  /** fetch data from the table: "github_integration_mode" using primary key columns */
  github_integration_mode_by_pk?: Maybe<Github_Integration_Mode>;
  /** fetch data from the table in a streaming manner: "github_integration_mode" */
  github_integration_mode_stream: Array<Github_Integration_Mode>;
  /** fetch data from the table: "github_push_event" */
  github_push_event: Array<Github_Push_Event>;
  /** fetch aggregated fields from the table: "github_push_event" */
  github_push_event_aggregate: Github_Push_Event_Aggregate;
  /** fetch data from the table: "github_push_event" using primary key columns */
  github_push_event_by_pk?: Maybe<Github_Push_Event>;
  /** fetch data from the table: "github_push_event_job" */
  github_push_event_job: Array<Github_Push_Event_Job>;
  /** fetch aggregated fields from the table: "github_push_event_job" */
  github_push_event_job_aggregate: Github_Push_Event_Job_Aggregate;
  /** fetch data from the table: "github_push_event_job" using primary key columns */
  github_push_event_job_by_pk?: Maybe<Github_Push_Event_Job>;
  /** fetch data from the table in a streaming manner: "github_push_event_job" */
  github_push_event_job_stream: Array<Github_Push_Event_Job>;
  /** fetch data from the table in a streaming manner: "github_push_event" */
  github_push_event_stream: Array<Github_Push_Event>;
  /** fetch data from the table: "hasura_ami" */
  hasura_ami: Array<Hasura_Ami>;
  /** fetch aggregated fields from the table: "hasura_ami" */
  hasura_ami_aggregate: Hasura_Ami_Aggregate;
  /** fetch data from the table: "hasura_ami" using primary key columns */
  hasura_ami_by_pk?: Maybe<Hasura_Ami>;
  /** fetch data from the table in a streaming manner: "hasura_ami" */
  hasura_ami_stream: Array<Hasura_Ami>;
  /** fetch data from the table: "hasura_cluster" */
  hasura_cluster: Array<Hasura_Cluster>;
  /** fetch aggregated fields from the table: "hasura_cluster" */
  hasura_cluster_aggregate: Hasura_Cluster_Aggregate;
  /** fetch data from the table: "hasura_cluster" using primary key columns */
  hasura_cluster_by_pk?: Maybe<Hasura_Cluster>;
  /** fetch data from the table in a streaming manner: "hasura_cluster" */
  hasura_cluster_stream: Array<Hasura_Cluster>;
  /** fetch data from the table: "hasura_worker" */
  hasura_worker: Array<Hasura_Worker>;
  /** fetch aggregated fields from the table: "hasura_worker" */
  hasura_worker_aggregate: Hasura_Worker_Aggregate;
  /** fetch data from the table: "hasura_worker" using primary key columns */
  hasura_worker_by_pk?: Maybe<Hasura_Worker>;
  /** fetch data from the table in a streaming manner: "hasura_worker" */
  hasura_worker_stream: Array<Hasura_Worker>;
  /** fetch data from the table: "hasura_worker_tenants" */
  hasura_worker_tenants: Array<Hasura_Worker_Tenants>;
  /** fetch aggregated fields from the table: "hasura_worker_tenants" */
  hasura_worker_tenants_aggregate: Hasura_Worker_Tenants_Aggregate;
  /** fetch data from the table in a streaming manner: "hasura_worker_tenants" */
  hasura_worker_tenants_stream: Array<Hasura_Worker_Tenants>;
  /** fetch data from the table: "hasura_year_in_report_activity" */
  hasura_year_in_report_activity: Array<Hasura_Year_In_Report_Activity>;
  /** fetch aggregated fields from the table: "hasura_year_in_report_activity" */
  hasura_year_in_report_activity_aggregate: Hasura_Year_In_Report_Activity_Aggregate;
  /** fetch data from the table: "hasura_year_in_report_activity" using primary key columns */
  hasura_year_in_report_activity_by_pk?: Maybe<Hasura_Year_In_Report_Activity>;
  /** fetch data from the table in a streaming manner: "hasura_year_in_report_activity" */
  hasura_year_in_report_activity_stream: Array<Hasura_Year_In_Report_Activity>;
  /** fetch data from the table: "hasura_year_in_report_metrics" */
  hasura_year_in_report_metrics: Array<Hasura_Year_In_Report_Metrics>;
  /** fetch aggregated fields from the table: "hasura_year_in_report_metrics" */
  hasura_year_in_report_metrics_aggregate: Hasura_Year_In_Report_Metrics_Aggregate;
  /** fetch data from the table: "hasura_year_in_report_metrics" using primary key columns */
  hasura_year_in_report_metrics_by_pk?: Maybe<Hasura_Year_In_Report_Metrics>;
  /** fetch data from the table in a streaming manner: "hasura_year_in_report_metrics" */
  hasura_year_in_report_metrics_stream: Array<Hasura_Year_In_Report_Metrics>;
  /** An array relationship */
  heroku_integrations: Array<Heroku_Integrations>;
  /** An aggregate relationship */
  heroku_integrations_aggregate: Heroku_Integrations_Aggregate;
  /** fetch data from the table: "heroku_integrations" using primary key columns */
  heroku_integrations_by_pk?: Maybe<Heroku_Integrations>;
  /** fetch data from the table in a streaming manner: "heroku_integrations" */
  heroku_integrations_stream: Array<Heroku_Integrations>;
  /** fetch data from the table: "hit_count_monthly" */
  hit_count_monthly: Array<Hit_Count_Monthly>;
  /** fetch aggregated fields from the table: "hit_count_monthly" */
  hit_count_monthly_aggregate: Hit_Count_Monthly_Aggregate;
  /** fetch data from the table: "hit_count_monthly" using primary key columns */
  hit_count_monthly_by_pk?: Maybe<Hit_Count_Monthly>;
  /** fetch data from the table: "hit_count_monthly_mv" */
  hit_count_monthly_mv: Array<Hit_Count_Monthly_Mv>;
  /** fetch aggregated fields from the table: "hit_count_monthly_mv" */
  hit_count_monthly_mv_aggregate: Hit_Count_Monthly_Mv_Aggregate;
  /** fetch data from the table: "hit_count_per_day" */
  hit_count_per_day: Array<Hit_Count_Per_Day>;
  /** fetch aggregated fields from the table: "hit_count_per_day" */
  hit_count_per_day_aggregate: Hit_Count_Per_Day_Aggregate;
  /** fetch data from the table: "hit_count_per_day" using primary key columns */
  hit_count_per_day_by_pk?: Maybe<Hit_Count_Per_Day>;
  /** fetch data from the table: "inactive_project_exclusions" */
  inactive_project_exclusions: Array<Inactive_Project_Exclusions>;
  /** fetch aggregated fields from the table: "inactive_project_exclusions" */
  inactive_project_exclusions_aggregate: Inactive_Project_Exclusions_Aggregate;
  /** fetch data from the table: "inactive_project_exclusions" using primary key columns */
  inactive_project_exclusions_by_pk?: Maybe<Inactive_Project_Exclusions>;
  /** fetch data from the table in a streaming manner: "inactive_project_exclusions" */
  inactive_project_exclusions_stream: Array<Inactive_Project_Exclusions>;
  /** fetch data from the table: "inactive_project_notifications" */
  inactive_project_notifications: Array<Inactive_Project_Notifications>;
  /** fetch aggregated fields from the table: "inactive_project_notifications" */
  inactive_project_notifications_aggregate: Inactive_Project_Notifications_Aggregate;
  /** fetch data from the table: "inactive_project_notifications" using primary key columns */
  inactive_project_notifications_by_pk?: Maybe<Inactive_Project_Notifications>;
  /** fetch data from the table in a streaming manner: "inactive_project_notifications" */
  inactive_project_notifications_stream: Array<Inactive_Project_Notifications>;
  /** fetch data from the table: "inactive_project_suspension_config" */
  inactive_project_suspension_config: Array<Inactive_Project_Suspension_Config>;
  /** fetch aggregated fields from the table: "inactive_project_suspension_config" */
  inactive_project_suspension_config_aggregate: Inactive_Project_Suspension_Config_Aggregate;
  /** fetch data from the table in a streaming manner: "inactive_project_suspension_config" */
  inactive_project_suspension_config_stream: Array<
    Inactive_Project_Suspension_Config
  >;
  /** fetch data from the table: "inactive_projects" */
  inactive_projects: Array<Inactive_Projects>;
  /** fetch aggregated fields from the table: "inactive_projects" */
  inactive_projects_aggregate: Inactive_Projects_Aggregate;
  /** fetch data from the table in a streaming manner: "inactive_projects" */
  inactive_projects_stream: Array<Inactive_Projects>;
  /** fetch data from the table: "infra_status" */
  infra_status: Array<Infra_Status>;
  /** fetch aggregated fields from the table: "infra_status" */
  infra_status_aggregate: Infra_Status_Aggregate;
  /** fetch data from the table: "infra_status" using primary key columns */
  infra_status_by_pk?: Maybe<Infra_Status>;
  /** fetch data from the table in a streaming manner: "infra_status" */
  infra_status_stream: Array<Infra_Status>;
  /** fetch data from the table: "invoice" */
  invoice: Array<Invoice>;
  /** fetch aggregated fields from the table: "invoice" */
  invoice_aggregate: Invoice_Aggregate;
  /** fetch data from the table: "invoice" using primary key columns */
  invoice_by_pk?: Maybe<Invoice>;
  /** fetch data from the table: "invoice_coupon_discount" */
  invoice_coupon_discount: Array<Invoice_Coupon_Discount>;
  /** fetch aggregated fields from the table: "invoice_coupon_discount" */
  invoice_coupon_discount_aggregate: Invoice_Coupon_Discount_Aggregate;
  /** fetch data from the table: "invoice_coupon_discount" using primary key columns */
  invoice_coupon_discount_by_pk?: Maybe<Invoice_Coupon_Discount>;
  /** fetch data from the table in a streaming manner: "invoice_coupon_discount" */
  invoice_coupon_discount_stream: Array<Invoice_Coupon_Discount>;
  /** fetch data from the table: "invoice_item" */
  invoice_item: Array<Invoice_Item>;
  /** fetch aggregated fields from the table: "invoice_item" */
  invoice_item_aggregate: Invoice_Item_Aggregate;
  /** fetch data from the table: "invoice_item" using primary key columns */
  invoice_item_by_pk?: Maybe<Invoice_Item>;
  /** fetch data from the table in a streaming manner: "invoice_item" */
  invoice_item_stream: Array<Invoice_Item>;
  /** fetch data from the table in a streaming manner: "invoice" */
  invoice_stream: Array<Invoice>;
  /** An array relationship */
  jobs: Array<Jobs>;
  /** An aggregate relationship */
  jobs_aggregate: Jobs_Aggregate;
  /** fetch data from the table: "jobs" using primary key columns */
  jobs_by_pk?: Maybe<Jobs>;
  /** fetch data from the table in a streaming manner: "jobs" */
  jobs_stream: Array<Jobs>;
  /** fetch data from the table: "label" */
  label: Array<Label>;
  /** fetch aggregated fields from the table: "label" */
  label_aggregate: Label_Aggregate;
  /** fetch data from the table: "label" using primary key columns */
  label_by_pk?: Maybe<Label>;
  /** fetch data from the table in a streaming manner: "label" */
  label_stream: Array<Label>;
  /** fetch data from the table: "letsencrypt_status" */
  letsencrypt_status: Array<Letsencrypt_Status>;
  /** fetch aggregated fields from the table: "letsencrypt_status" */
  letsencrypt_status_aggregate: Letsencrypt_Status_Aggregate;
  /** fetch data from the table: "letsencrypt_status" using primary key columns */
  letsencrypt_status_by_pk?: Maybe<Letsencrypt_Status>;
  /** fetch data from the table in a streaming manner: "letsencrypt_status" */
  letsencrypt_status_stream: Array<Letsencrypt_Status>;
  /** fetch data from the table: "license_activity" */
  license_activity: Array<License_Activity>;
  /** fetch aggregated fields from the table: "license_activity" */
  license_activity_aggregate: License_Activity_Aggregate;
  /** fetch data from the table: "license_activity" using primary key columns */
  license_activity_by_pk?: Maybe<License_Activity>;
  /** fetch data from the table in a streaming manner: "license_activity" */
  license_activity_stream: Array<License_Activity>;
  /** fetch data from the table: "license_instance" */
  license_instance: Array<License_Instance>;
  /** fetch aggregated fields from the table: "license_instance" */
  license_instance_aggregate: License_Instance_Aggregate;
  /** fetch data from the table: "license_instance" using primary key columns */
  license_instance_by_pk?: Maybe<License_Instance>;
  /** fetch data from the table in a streaming manner: "license_instance" */
  license_instance_stream: Array<License_Instance>;
  /** fetch data from the table: "license_type" */
  license_type: Array<License_Type>;
  /** fetch aggregated fields from the table: "license_type" */
  license_type_aggregate: License_Type_Aggregate;
  /** fetch data from the table: "license_type" using primary key columns */
  license_type_by_pk?: Maybe<License_Type>;
  /** fetch data from the table in a streaming manner: "license_type" */
  license_type_stream: Array<License_Type>;
  /** fetch data from the table: "licenses" */
  licenses: Array<Licenses>;
  /** fetch aggregated fields from the table: "licenses" */
  licenses_aggregate: Licenses_Aggregate;
  /** fetch data from the table: "licenses" using primary key columns */
  licenses_by_pk?: Maybe<Licenses>;
  /** fetch data from the table in a streaming manner: "licenses" */
  licenses_stream: Array<Licenses>;
  /** An array relationship */
  lux_proxy: Array<Lux_Proxy>;
  /** An aggregate relationship */
  lux_proxy_aggregate: Lux_Proxy_Aggregate;
  /** fetch data from the table: "lux_proxy_ami" */
  lux_proxy_ami: Array<Lux_Proxy_Ami>;
  /** fetch aggregated fields from the table: "lux_proxy_ami" */
  lux_proxy_ami_aggregate: Lux_Proxy_Ami_Aggregate;
  /** fetch data from the table: "lux_proxy_ami" using primary key columns */
  lux_proxy_ami_by_pk?: Maybe<Lux_Proxy_Ami>;
  /** fetch data from the table in a streaming manner: "lux_proxy_ami" */
  lux_proxy_ami_stream: Array<Lux_Proxy_Ami>;
  /** fetch data from the table: "lux_proxy" using primary key columns */
  lux_proxy_by_pk?: Maybe<Lux_Proxy>;
  /** fetch data from the table in a streaming manner: "lux_proxy" */
  lux_proxy_stream: Array<Lux_Proxy>;
  /** fetch data from the table: "model_based_enterprise_cloud_commitments" */
  model_based_enterprise_cloud_commitments: Array<
    Model_Based_Enterprise_Cloud_Commitments
  >;
  /** fetch aggregated fields from the table: "model_based_enterprise_cloud_commitments" */
  model_based_enterprise_cloud_commitments_aggregate: Model_Based_Enterprise_Cloud_Commitments_Aggregate;
  /** fetch data from the table: "model_based_enterprise_cloud_commitments" using primary key columns */
  model_based_enterprise_cloud_commitments_by_pk?: Maybe<
    Model_Based_Enterprise_Cloud_Commitments
  >;
  /** fetch data from the table in a streaming manner: "model_based_enterprise_cloud_commitments" */
  model_based_enterprise_cloud_commitments_stream: Array<
    Model_Based_Enterprise_Cloud_Commitments
  >;
  /** fetch data from the table: "model_based_pricing.model_lifecycle_view" */
  model_based_pricing_model_lifecycle_view: Array<
    Model_Based_Pricing_Model_Lifecycle_View
  >;
  /** fetch aggregated fields from the table: "model_based_pricing.model_lifecycle_view" */
  model_based_pricing_model_lifecycle_view_aggregate: Model_Based_Pricing_Model_Lifecycle_View_Aggregate;
  /** fetch data from the table: "model_based_pricing.model_snapshot_statistics" */
  model_based_pricing_model_snapshot_statistics: Array<
    Model_Based_Pricing_Model_Snapshot_Statistics
  >;
  /** fetch aggregated fields from the table: "model_based_pricing.model_snapshot_statistics" */
  model_based_pricing_model_snapshot_statistics_aggregate: Model_Based_Pricing_Model_Snapshot_Statistics_Aggregate;
  /** fetch data from the table: "neon_db_integration" */
  neon_db_integration: Array<Neon_Db_Integration>;
  /** fetch aggregated fields from the table: "neon_db_integration" */
  neon_db_integration_aggregate: Neon_Db_Integration_Aggregate;
  /** fetch data from the table: "neon_db_integration" using primary key columns */
  neon_db_integration_by_pk?: Maybe<Neon_Db_Integration>;
  /** fetch data from the table in a streaming manner: "neon_db_integration" */
  neon_db_integration_stream: Array<Neon_Db_Integration>;
  /** fetch data from the table: "newrelic_config" */
  newrelic_config: Array<Newrelic_Config>;
  /** fetch aggregated fields from the table: "newrelic_config" */
  newrelic_config_aggregate: Newrelic_Config_Aggregate;
  /** fetch data from the table: "newrelic_config" using primary key columns */
  newrelic_config_by_pk?: Maybe<Newrelic_Config>;
  /** fetch data from the table in a streaming manner: "newrelic_config" */
  newrelic_config_stream: Array<Newrelic_Config>;
  /** fetch data from the table: "node_pool_type" */
  node_pool_type: Array<Node_Pool_Type>;
  /** fetch aggregated fields from the table: "node_pool_type" */
  node_pool_type_aggregate: Node_Pool_Type_Aggregate;
  /** fetch data from the table: "node_pool_type" using primary key columns */
  node_pool_type_by_pk?: Maybe<Node_Pool_Type>;
  /** fetch data from the table in a streaming manner: "node_pool_type" */
  node_pool_type_stream: Array<Node_Pool_Type>;
  /** fetch data from the table: "node_pools" */
  node_pools: Array<Node_Pools>;
  /** fetch aggregated fields from the table: "node_pools" */
  node_pools_aggregate: Node_Pools_Aggregate;
  /** fetch data from the table: "node_pools" using primary key columns */
  node_pools_by_pk?: Maybe<Node_Pools>;
  /** fetch data from the table in a streaming manner: "node_pools" */
  node_pools_stream: Array<Node_Pools>;
  /** fetch data from the table: "notification" */
  notification: Array<Notification>;
  /** fetch aggregated fields from the table: "notification" */
  notification_aggregate: Notification_Aggregate;
  /** fetch data from the table: "notification" using primary key columns */
  notification_by_pk?: Maybe<Notification>;
  /** fetch data from the table in a streaming manner: "notification" */
  notification_stream: Array<Notification>;
  /** fetch data from the table: "notification_type" */
  notification_type: Array<Notification_Type>;
  /** fetch aggregated fields from the table: "notification_type" */
  notification_type_aggregate: Notification_Type_Aggregate;
  /** fetch data from the table: "notification_type" using primary key columns */
  notification_type_by_pk?: Maybe<Notification_Type>;
  /** fetch data from the table in a streaming manner: "notification_type" */
  notification_type_stream: Array<Notification_Type>;
  /** fetch data from the table: "onboarding_sample_db_cohort" */
  onboarding_sample_db_cohort: Array<Onboarding_Sample_Db_Cohort>;
  /** fetch aggregated fields from the table: "onboarding_sample_db_cohort" */
  onboarding_sample_db_cohort_aggregate: Onboarding_Sample_Db_Cohort_Aggregate;
  /** fetch data from the table: "onboarding_sample_db_cohort" using primary key columns */
  onboarding_sample_db_cohort_by_pk?: Maybe<Onboarding_Sample_Db_Cohort>;
  /** fetch data from the table in a streaming manner: "onboarding_sample_db_cohort" */
  onboarding_sample_db_cohort_stream: Array<Onboarding_Sample_Db_Cohort>;
  /** fetch data from the table: "onboarding_sample_db_config" */
  onboarding_sample_db_config: Array<Onboarding_Sample_Db_Config>;
  /** fetch aggregated fields from the table: "onboarding_sample_db_config" */
  onboarding_sample_db_config_aggregate: Onboarding_Sample_Db_Config_Aggregate;
  /** fetch data from the table: "onboarding_sample_db_config" using primary key columns */
  onboarding_sample_db_config_by_pk?: Maybe<Onboarding_Sample_Db_Config>;
  /** fetch data from the table in a streaming manner: "onboarding_sample_db_config" */
  onboarding_sample_db_config_stream: Array<Onboarding_Sample_Db_Config>;
  /** fetch data from the table: "one_click_deployment" */
  one_click_deployment: Array<One_Click_Deployment>;
  /** fetch aggregated fields from the table: "one_click_deployment" */
  one_click_deployment_aggregate: One_Click_Deployment_Aggregate;
  /** fetch data from the table: "one_click_deployment" using primary key columns */
  one_click_deployment_by_pk?: Maybe<One_Click_Deployment>;
  /** fetch data from the table: "one_click_deployment_sample_apps" */
  one_click_deployment_sample_apps: Array<One_Click_Deployment_Sample_Apps>;
  /** fetch aggregated fields from the table: "one_click_deployment_sample_apps" */
  one_click_deployment_sample_apps_aggregate: One_Click_Deployment_Sample_Apps_Aggregate;
  /** fetch data from the table: "one_click_deployment_sample_apps" using primary key columns */
  one_click_deployment_sample_apps_by_pk?: Maybe<
    One_Click_Deployment_Sample_Apps
  >;
  /** fetch data from the table in a streaming manner: "one_click_deployment_sample_apps" */
  one_click_deployment_sample_apps_stream: Array<
    One_Click_Deployment_Sample_Apps
  >;
  /** fetch data from the table: "one_click_deployment_state_log" */
  one_click_deployment_state_log: Array<One_Click_Deployment_State_Log>;
  /** fetch aggregated fields from the table: "one_click_deployment_state_log" */
  one_click_deployment_state_log_aggregate: One_Click_Deployment_State_Log_Aggregate;
  /** fetch data from the table: "one_click_deployment_state_log" using primary key columns */
  one_click_deployment_state_log_by_pk?: Maybe<One_Click_Deployment_State_Log>;
  /** fetch data from the table in a streaming manner: "one_click_deployment_state_log" */
  one_click_deployment_state_log_stream: Array<One_Click_Deployment_State_Log>;
  /** fetch data from the table: "one_click_deployment_states" */
  one_click_deployment_states: Array<One_Click_Deployment_States>;
  /** fetch aggregated fields from the table: "one_click_deployment_states" */
  one_click_deployment_states_aggregate: One_Click_Deployment_States_Aggregate;
  /** fetch data from the table: "one_click_deployment_states" using primary key columns */
  one_click_deployment_states_by_pk?: Maybe<One_Click_Deployment_States>;
  /** fetch data from the table in a streaming manner: "one_click_deployment_states" */
  one_click_deployment_states_stream: Array<One_Click_Deployment_States>;
  /** fetch data from the table in a streaming manner: "one_click_deployment" */
  one_click_deployment_stream: Array<One_Click_Deployment>;
  /** fetch data from the table: "opentelemetry_config" */
  opentelemetry_config: Array<Opentelemetry_Config>;
  /** fetch aggregated fields from the table: "opentelemetry_config" */
  opentelemetry_config_aggregate: Opentelemetry_Config_Aggregate;
  /** fetch data from the table: "opentelemetry_config" using primary key columns */
  opentelemetry_config_by_pk?: Maybe<Opentelemetry_Config>;
  /** fetch data from the table in a streaming manner: "opentelemetry_config" */
  opentelemetry_config_stream: Array<Opentelemetry_Config>;
  /** fetch data from the table: "operations.delete_free_plan_dns_records_nov_2021" */
  operations_delete_free_plan_dns_records_nov_2021: Array<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021
  >;
  /** fetch aggregated fields from the table: "operations.delete_free_plan_dns_records_nov_2021" */
  operations_delete_free_plan_dns_records_nov_2021_aggregate: Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Aggregate;
  /** fetch data from the table: "operations.delete_free_plan_dns_records_nov_2021" using primary key columns */
  operations_delete_free_plan_dns_records_nov_2021_by_pk?: Maybe<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021
  >;
  /** fetch data from the table in a streaming manner: "operations.delete_free_plan_dns_records_nov_2021" */
  operations_delete_free_plan_dns_records_nov_2021_stream: Array<
    Operations_Delete_Free_Plan_Dns_Records_Nov_2021
  >;
  /** fetch data from the table: "operations.tenant" */
  operations_tenant: Array<Operations_Tenant>;
  /** fetch aggregated fields from the table: "operations.tenant" */
  operations_tenant_aggregate: Operations_Tenant_Aggregate;
  /** fetch data from the table in a streaming manner: "operations.tenant" */
  operations_tenant_stream: Array<Operations_Tenant>;
  /** execute function "operations.to_delete_free_plan_dns_records" which returns "operations.tenant" */
  operations_to_delete_free_plan_dns_records: Array<Operations_Tenant>;
  /** execute function "operations.to_delete_free_plan_dns_records" and query aggregates on result of table type "operations.tenant" */
  operations_to_delete_free_plan_dns_records_aggregate: Operations_Tenant_Aggregate;
  /** fetch data from the table: "payment_method" */
  payment_method: Array<Payment_Method>;
  /** fetch aggregated fields from the table: "payment_method" */
  payment_method_aggregate: Payment_Method_Aggregate;
  /** fetch data from the table: "payment_method" using primary key columns */
  payment_method_by_pk?: Maybe<Payment_Method>;
  /** fetch data from the table in a streaming manner: "payment_method" */
  payment_method_stream: Array<Payment_Method>;
  /** fetch data from the table: "plan_entitlements" */
  plan_entitlements: Array<Plan_Entitlements>;
  /** fetch aggregated fields from the table: "plan_entitlements" */
  plan_entitlements_aggregate: Plan_Entitlements_Aggregate;
  /** fetch data from the table: "plan_entitlements" using primary key columns */
  plan_entitlements_by_pk?: Maybe<Plan_Entitlements>;
  /** fetch data from the table in a streaming manner: "plan_entitlements" */
  plan_entitlements_stream: Array<Plan_Entitlements>;
  /** fetch data from the table: "plans" */
  plans: Array<Plans>;
  /** fetch aggregated fields from the table: "plans" */
  plans_aggregate: Plans_Aggregate;
  /** fetch data from the table: "plans" using primary key columns */
  plans_by_pk?: Maybe<Plans>;
  /** fetch data from the table in a streaming manner: "plans" */
  plans_stream: Array<Plans>;
  /** An array relationship */
  policies: Array<Policies>;
  /** An aggregate relationship */
  policies_aggregate: Policies_Aggregate;
  /** fetch data from the table: "policies" using primary key columns */
  policies_by_pk?: Maybe<Policies>;
  /** fetch data from the table in a streaming manner: "policies" */
  policies_stream: Array<Policies>;
  /** fetch data from the table: "privileges" */
  privileges: Array<Privileges>;
  /** fetch aggregated fields from the table: "privileges" */
  privileges_aggregate: Privileges_Aggregate;
  /** fetch data from the table: "privileges" using primary key columns */
  privileges_by_pk?: Maybe<Privileges>;
  /** fetch data from the table in a streaming manner: "privileges" */
  privileges_stream: Array<Privileges>;
  /** fetch data from the table: "pro_license_keys" */
  pro_license_keys: Array<Pro_License_Keys>;
  /** fetch aggregated fields from the table: "pro_license_keys" */
  pro_license_keys_aggregate: Pro_License_Keys_Aggregate;
  /** fetch data from the table: "pro_license_keys" using primary key columns */
  pro_license_keys_by_pk?: Maybe<Pro_License_Keys>;
  /** fetch data from the table in a streaming manner: "pro_license_keys" */
  pro_license_keys_stream: Array<Pro_License_Keys>;
  /** fetch data from the table: "project_activity" */
  project_activity: Array<Project_Activity>;
  /** fetch aggregated fields from the table: "project_activity" */
  project_activity_aggregate: Project_Activity_Aggregate;
  /** fetch data from the table: "project_activity" using primary key columns */
  project_activity_by_pk?: Maybe<Project_Activity>;
  /** fetch data from the table in a streaming manner: "project_activity" */
  project_activity_stream: Array<Project_Activity>;
  /** An array relationship */
  project_billing_manager_invitations: Array<
    Project_Billing_Manager_Invitations
  >;
  /** An aggregate relationship */
  project_billing_manager_invitations_aggregate: Project_Billing_Manager_Invitations_Aggregate;
  /** fetch data from the table: "project_billing_manager_invitations" using primary key columns */
  project_billing_manager_invitations_by_pk?: Maybe<
    Project_Billing_Manager_Invitations
  >;
  /** fetch data from the table in a streaming manner: "project_billing_manager_invitations" */
  project_billing_manager_invitations_stream: Array<
    Project_Billing_Manager_Invitations
  >;
  /** fetch data from the table: "project_collaborator_allowed_schema_tables" */
  project_collaborator_allowed_schema_tables: Array<
    Project_Collaborator_Allowed_Schema_Tables
  >;
  /** fetch aggregated fields from the table: "project_collaborator_allowed_schema_tables" */
  project_collaborator_allowed_schema_tables_aggregate: Project_Collaborator_Allowed_Schema_Tables_Aggregate;
  /** fetch data from the table: "project_collaborator_allowed_schema_tables" using primary key columns */
  project_collaborator_allowed_schema_tables_by_pk?: Maybe<
    Project_Collaborator_Allowed_Schema_Tables
  >;
  /** fetch data from the table in a streaming manner: "project_collaborator_allowed_schema_tables" */
  project_collaborator_allowed_schema_tables_stream: Array<
    Project_Collaborator_Allowed_Schema_Tables
  >;
  /** An array relationship */
  project_collaborator_allowed_schemas: Array<
    Project_Collaborator_Allowed_Schemas
  >;
  /** An aggregate relationship */
  project_collaborator_allowed_schemas_aggregate: Project_Collaborator_Allowed_Schemas_Aggregate;
  /** fetch data from the table: "project_collaborator_allowed_schemas" using primary key columns */
  project_collaborator_allowed_schemas_by_pk?: Maybe<
    Project_Collaborator_Allowed_Schemas
  >;
  /** fetch data from the table in a streaming manner: "project_collaborator_allowed_schemas" */
  project_collaborator_allowed_schemas_stream: Array<
    Project_Collaborator_Allowed_Schemas
  >;
  /** fetch data from the table: "project_collaborator_invitations" */
  project_collaborator_invitations: Array<Project_Collaborator_Invitations>;
  /** fetch aggregated fields from the table: "project_collaborator_invitations" */
  project_collaborator_invitations_aggregate: Project_Collaborator_Invitations_Aggregate;
  /** fetch data from the table: "project_collaborator_invitations" using primary key columns */
  project_collaborator_invitations_by_pk?: Maybe<
    Project_Collaborator_Invitations
  >;
  /** fetch data from the table in a streaming manner: "project_collaborator_invitations" */
  project_collaborator_invitations_stream: Array<
    Project_Collaborator_Invitations
  >;
  /** An array relationship */
  project_collaborator_privileges: Array<Project_Collaborator_Privileges>;
  /** An aggregate relationship */
  project_collaborator_privileges_aggregate: Project_Collaborator_Privileges_Aggregate;
  /** fetch data from the table: "project_collaborator_privileges" using primary key columns */
  project_collaborator_privileges_by_pk?: Maybe<
    Project_Collaborator_Privileges
  >;
  /** fetch data from the table in a streaming manner: "project_collaborator_privileges" */
  project_collaborator_privileges_stream: Array<
    Project_Collaborator_Privileges
  >;
  /** fetch data from the table: "project_collaborators" */
  project_collaborators: Array<Project_Collaborators>;
  /** fetch aggregated fields from the table: "project_collaborators" */
  project_collaborators_aggregate: Project_Collaborators_Aggregate;
  /** fetch data from the table: "project_collaborators" using primary key columns */
  project_collaborators_by_pk?: Maybe<Project_Collaborators>;
  /** fetch data from the table in a streaming manner: "project_collaborators" */
  project_collaborators_stream: Array<Project_Collaborators>;
  /** fetch data from the table: "project_data_usage" */
  project_data_usage: Array<Project_Data_Usage>;
  /** fetch data from the table: "project_data_usage_agg" */
  project_data_usage_agg: Array<Project_Data_Usage_Agg>;
  /** fetch aggregated fields from the table: "project_data_usage_agg" */
  project_data_usage_agg_aggregate: Project_Data_Usage_Agg_Aggregate;
  /** fetch data from the table in a streaming manner: "project_data_usage_agg" */
  project_data_usage_agg_stream: Array<Project_Data_Usage_Agg>;
  /** fetch data from the table: "project_data_usage_agg_user" */
  project_data_usage_agg_user: Array<Project_Data_Usage_Agg_User>;
  /** fetch aggregated fields from the table: "project_data_usage_agg_user" */
  project_data_usage_agg_user_aggregate: Project_Data_Usage_Agg_User_Aggregate;
  /** fetch data from the table in a streaming manner: "project_data_usage_agg_user" */
  project_data_usage_agg_user_stream: Array<Project_Data_Usage_Agg_User>;
  /** fetch aggregated fields from the table: "project_data_usage" */
  project_data_usage_aggregate: Project_Data_Usage_Aggregate;
  /** fetch data from the table: "project_data_usage" using primary key columns */
  project_data_usage_by_pk?: Maybe<Project_Data_Usage>;
  /** fetch data from the table: "project_data_usage_components" */
  project_data_usage_components: Array<Project_Data_Usage_Components>;
  /** fetch aggregated fields from the table: "project_data_usage_components" */
  project_data_usage_components_aggregate: Project_Data_Usage_Components_Aggregate;
  /** fetch data from the table in a streaming manner: "project_data_usage_components" */
  project_data_usage_components_stream: Array<Project_Data_Usage_Components>;
  /** fetch data from the table: "project_data_usage_prometheus" */
  project_data_usage_prometheus: Array<Project_Data_Usage_Prometheus>;
  /** fetch data from the table: "project_data_usage_prometheus_agg_user" */
  project_data_usage_prometheus_agg_user: Array<
    Project_Data_Usage_Prometheus_Agg_User
  >;
  /** fetch aggregated fields from the table: "project_data_usage_prometheus_agg_user" */
  project_data_usage_prometheus_agg_user_aggregate: Project_Data_Usage_Prometheus_Agg_User_Aggregate;
  /** fetch data from the table in a streaming manner: "project_data_usage_prometheus_agg_user" */
  project_data_usage_prometheus_agg_user_stream: Array<
    Project_Data_Usage_Prometheus_Agg_User
  >;
  /** fetch aggregated fields from the table: "project_data_usage_prometheus" */
  project_data_usage_prometheus_aggregate: Project_Data_Usage_Prometheus_Aggregate;
  /** fetch data from the table: "project_data_usage_prometheus" using primary key columns */
  project_data_usage_prometheus_by_pk?: Maybe<Project_Data_Usage_Prometheus>;
  /** fetch data from the table in a streaming manner: "project_data_usage_prometheus" */
  project_data_usage_prometheus_stream: Array<Project_Data_Usage_Prometheus>;
  /** fetch data from the table: "project_data_usage_report" */
  project_data_usage_report: Array<Project_Data_Usage_Report>;
  /** fetch aggregated fields from the table: "project_data_usage_report" */
  project_data_usage_report_aggregate: Project_Data_Usage_Report_Aggregate;
  /** fetch data from the table: "project_data_usage_report" using primary key columns */
  project_data_usage_report_by_pk?: Maybe<Project_Data_Usage_Report>;
  /** fetch data from the table in a streaming manner: "project_data_usage_report" */
  project_data_usage_report_stream: Array<Project_Data_Usage_Report>;
  /** fetch data from the table in a streaming manner: "project_data_usage" */
  project_data_usage_stream: Array<Project_Data_Usage>;
  /** fetch data from the table: "project_db_usage" */
  project_db_usage: Array<Project_Db_Usage>;
  /** fetch data from the table: "project_db_usage_agg_user" */
  project_db_usage_agg_user: Array<Project_Db_Usage_Agg_User>;
  /** fetch aggregated fields from the table: "project_db_usage_agg_user" */
  project_db_usage_agg_user_aggregate: Project_Db_Usage_Agg_User_Aggregate;
  /** fetch data from the table in a streaming manner: "project_db_usage_agg_user" */
  project_db_usage_agg_user_stream: Array<Project_Db_Usage_Agg_User>;
  /** fetch aggregated fields from the table: "project_db_usage" */
  project_db_usage_aggregate: Project_Db_Usage_Aggregate;
  /** fetch data from the table: "project_db_usage" using primary key columns */
  project_db_usage_by_pk?: Maybe<Project_Db_Usage>;
  /** fetch data from the table in a streaming manner: "project_db_usage" */
  project_db_usage_stream: Array<Project_Db_Usage>;
  /** fetch data from the table: "project_entitlement_access" */
  project_entitlement_access: Array<Project_Entitlement_Access>;
  /** fetch aggregated fields from the table: "project_entitlement_access" */
  project_entitlement_access_aggregate: Project_Entitlement_Access_Aggregate;
  /** fetch data from the table: "project_entitlement_access" using primary key columns */
  project_entitlement_access_by_pk?: Maybe<Project_Entitlement_Access>;
  /** fetch data from the table in a streaming manner: "project_entitlement_access" */
  project_entitlement_access_stream: Array<Project_Entitlement_Access>;
  /** fetch data from the table: "project_entitlement_catalogue" */
  project_entitlement_catalogue: Array<Project_Entitlement_Catalogue>;
  /** fetch aggregated fields from the table: "project_entitlement_catalogue" */
  project_entitlement_catalogue_aggregate: Project_Entitlement_Catalogue_Aggregate;
  /** fetch data from the table: "project_entitlement_catalogue" using primary key columns */
  project_entitlement_catalogue_by_pk?: Maybe<Project_Entitlement_Catalogue>;
  /** fetch data from the table in a streaming manner: "project_entitlement_catalogue" */
  project_entitlement_catalogue_stream: Array<Project_Entitlement_Catalogue>;
  /** fetch data from the table: "project_entitlement_types" */
  project_entitlement_types: Array<Project_Entitlement_Types>;
  /** fetch aggregated fields from the table: "project_entitlement_types" */
  project_entitlement_types_aggregate: Project_Entitlement_Types_Aggregate;
  /** fetch data from the table: "project_entitlement_types" using primary key columns */
  project_entitlement_types_by_pk?: Maybe<Project_Entitlement_Types>;
  /** fetch data from the table in a streaming manner: "project_entitlement_types" */
  project_entitlement_types_stream: Array<Project_Entitlement_Types>;
  /** An array relationship */
  project_labels: Array<Project_Labels>;
  /** An aggregate relationship */
  project_labels_aggregate: Project_Labels_Aggregate;
  /** fetch data from the table: "project_labels" using primary key columns */
  project_labels_by_pk?: Maybe<Project_Labels>;
  /** fetch data from the table in a streaming manner: "project_labels" */
  project_labels_stream: Array<Project_Labels>;
  /** fetch data from the table: "project_metadata" */
  project_metadata: Array<Project_Metadata>;
  /** fetch aggregated fields from the table: "project_metadata" */
  project_metadata_aggregate: Project_Metadata_Aggregate;
  /** fetch data from the table: "project_metadata" using primary key columns */
  project_metadata_by_pk?: Maybe<Project_Metadata>;
  /** fetch data from the table in a streaming manner: "project_metadata" */
  project_metadata_stream: Array<Project_Metadata>;
  /** fetch data from the table: "project_notification" */
  project_notification: Array<Project_Notification>;
  /** fetch aggregated fields from the table: "project_notification" */
  project_notification_aggregate: Project_Notification_Aggregate;
  /** fetch data from the table: "project_notification" using primary key columns */
  project_notification_by_pk?: Maybe<Project_Notification>;
  /** fetch data from the table in a streaming manner: "project_notification" */
  project_notification_stream: Array<Project_Notification>;
  /** fetch data from the table: "project_ownership_transfer_invitations" */
  project_ownership_transfer_invitations: Array<
    Project_Ownership_Transfer_Invitations
  >;
  /** fetch aggregated fields from the table: "project_ownership_transfer_invitations" */
  project_ownership_transfer_invitations_aggregate: Project_Ownership_Transfer_Invitations_Aggregate;
  /** fetch data from the table: "project_ownership_transfer_invitations" using primary key columns */
  project_ownership_transfer_invitations_by_pk?: Maybe<
    Project_Ownership_Transfer_Invitations
  >;
  /** fetch data from the table in a streaming manner: "project_ownership_transfer_invitations" */
  project_ownership_transfer_invitations_stream: Array<
    Project_Ownership_Transfer_Invitations
  >;
  /** fetch data from the table: "project_pool_current_status" */
  project_pool_current_status: Array<Project_Pool_Current_Status>;
  /** fetch aggregated fields from the table: "project_pool_current_status" */
  project_pool_current_status_aggregate: Project_Pool_Current_Status_Aggregate;
  /** fetch data from the table in a streaming manner: "project_pool_current_status" */
  project_pool_current_status_stream: Array<Project_Pool_Current_Status>;
  /** fetch data from the table: "project_requests_count" */
  project_requests_count: Array<Project_Requests_Count>;
  /** fetch aggregated fields from the table: "project_requests_count" */
  project_requests_count_aggregate: Project_Requests_Count_Aggregate;
  /** fetch data from the table: "project_requests_count" using primary key columns */
  project_requests_count_by_pk?: Maybe<Project_Requests_Count>;
  /** fetch data from the table in a streaming manner: "project_requests_count" */
  project_requests_count_stream: Array<Project_Requests_Count>;
  /** fetch data from the table: "project_stats" */
  project_stats: Array<Project_Stats>;
  /** fetch aggregated fields from the table: "project_stats" */
  project_stats_aggregate: Project_Stats_Aggregate;
  /** fetch data from the table: "project_stats" using primary key columns */
  project_stats_by_pk?: Maybe<Project_Stats>;
  /** fetch data from the table in a streaming manner: "project_stats" */
  project_stats_stream: Array<Project_Stats>;
  /** fetch data from the table: "project_total_db_usage_agg" */
  project_total_db_usage_agg: Array<Project_Total_Db_Usage_Agg>;
  /** fetch aggregated fields from the table: "project_total_db_usage_agg" */
  project_total_db_usage_agg_aggregate: Project_Total_Db_Usage_Agg_Aggregate;
  /** fetch data from the table in a streaming manner: "project_total_db_usage_agg" */
  project_total_db_usage_agg_stream: Array<Project_Total_Db_Usage_Agg>;
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  /** fetch data from the table: "projects" using primary key columns */
  projects_by_pk?: Maybe<Projects>;
  /** fetch data from the table: "projects_pool_config" */
  projects_pool_config: Array<Projects_Pool_Config>;
  /** fetch aggregated fields from the table: "projects_pool_config" */
  projects_pool_config_aggregate: Projects_Pool_Config_Aggregate;
  /** fetch data from the table: "projects_pool_config" using primary key columns */
  projects_pool_config_by_pk?: Maybe<Projects_Pool_Config>;
  /** fetch data from the table in a streaming manner: "projects_pool_config" */
  projects_pool_config_stream: Array<Projects_Pool_Config>;
  /** fetch data from the table: "projects_pro_key_generations" */
  projects_pro_key_generations: Array<Projects_Pro_Key_Generations>;
  /** fetch aggregated fields from the table: "projects_pro_key_generations" */
  projects_pro_key_generations_aggregate: Projects_Pro_Key_Generations_Aggregate;
  /** fetch data from the table: "projects_pro_key_generations" using primary key columns */
  projects_pro_key_generations_by_pk?: Maybe<Projects_Pro_Key_Generations>;
  /** fetch data from the table in a streaming manner: "projects_pro_key_generations" */
  projects_pro_key_generations_stream: Array<Projects_Pro_Key_Generations>;
  /** fetch data from the table in a streaming manner: "projects" */
  projects_stream: Array<Projects>;
  /** fetch data from the table: "projects_with_no_running_deployments" */
  projects_with_no_running_deployments: Array<
    Projects_With_No_Running_Deployments
  >;
  /** fetch aggregated fields from the table: "projects_with_no_running_deployments" */
  projects_with_no_running_deployments_aggregate: Projects_With_No_Running_Deployments_Aggregate;
  /** fetch data from the table in a streaming manner: "projects_with_no_running_deployments" */
  projects_with_no_running_deployments_stream: Array<
    Projects_With_No_Running_Deployments
  >;
  /** fetch data from the table: "projects_with_no_running_preview_app" */
  projects_with_no_running_preview_app: Array<
    Projects_With_No_Running_Preview_App
  >;
  /** fetch aggregated fields from the table: "projects_with_no_running_preview_app" */
  projects_with_no_running_preview_app_aggregate: Projects_With_No_Running_Preview_App_Aggregate;
  /** fetch data from the table in a streaming manner: "projects_with_no_running_preview_app" */
  projects_with_no_running_preview_app_stream: Array<
    Projects_With_No_Running_Preview_App
  >;
  /** fetch data from the table: "prometheus_config" */
  prometheus_config: Array<Prometheus_Config>;
  /** fetch aggregated fields from the table: "prometheus_config" */
  prometheus_config_aggregate: Prometheus_Config_Aggregate;
  /** fetch data from the table: "prometheus_config" using primary key columns */
  prometheus_config_by_pk?: Maybe<Prometheus_Config>;
  /** fetch data from the table in a streaming manner: "prometheus_config" */
  prometheus_config_stream: Array<Prometheus_Config>;
  /** An array relationship */
  providers: Array<Providers>;
  /** An aggregate relationship */
  providers_aggregate: Providers_Aggregate;
  /** fetch data from the table: "providers" using primary key columns */
  providers_by_pk?: Maybe<Providers>;
  /** fetch data from the table in a streaming manner: "providers" */
  providers_stream: Array<Providers>;
  /** fetch data from the table: "provisioning" */
  provisioning: Array<Provisioning>;
  /** fetch aggregated fields from the table: "provisioning" */
  provisioning_aggregate: Provisioning_Aggregate;
  /** fetch data from the table: "provisioning" using primary key columns */
  provisioning_by_pk?: Maybe<Provisioning>;
  /** fetch data from the table in a streaming manner: "provisioning" */
  provisioning_stream: Array<Provisioning>;
  /** fetch data from the table: "region" */
  region: Array<Region>;
  /** fetch aggregated fields from the table: "region" */
  region_aggregate: Region_Aggregate;
  /** fetch data from the table: "region" using primary key columns */
  region_by_pk?: Maybe<Region>;
  /** fetch data from the table in a streaming manner: "region" */
  region_stream: Array<Region>;
  /** fetch data from the table: "region_v2" */
  region_v2: Array<Region_V2>;
  /** fetch aggregated fields from the table: "region_v2" */
  region_v2_aggregate: Region_V2_Aggregate;
  /** fetch data from the table in a streaming manner: "region_v2" */
  region_v2_stream: Array<Region_V2>;
  /** An array relationship */
  regional_data_connectors: Array<Regional_Data_Connectors>;
  /** An aggregate relationship */
  regional_data_connectors_aggregate: Regional_Data_Connectors_Aggregate;
  /** fetch data from the table: "regional_data_connectors" using primary key columns */
  regional_data_connectors_by_pk?: Maybe<Regional_Data_Connectors>;
  /** fetch data from the table in a streaming manner: "regional_data_connectors" */
  regional_data_connectors_stream: Array<Regional_Data_Connectors>;
  /** An array relationship */
  regional_metrics: Array<Regional_Metrics>;
  /** An aggregate relationship */
  regional_metrics_aggregate: Regional_Metrics_Aggregate;
  /** fetch data from the table: "regional_metrics" using primary key columns */
  regional_metrics_by_pk?: Maybe<Regional_Metrics>;
  /** fetch data from the table in a streaming manner: "regional_metrics" */
  regional_metrics_stream: Array<Regional_Metrics>;
  /** fetch data from the table: "saml_idp" */
  saml_idp: Array<Saml_Idp>;
  /** fetch aggregated fields from the table: "saml_idp" */
  saml_idp_aggregate: Saml_Idp_Aggregate;
  /** fetch data from the table: "saml_idp" using primary key columns */
  saml_idp_by_pk?: Maybe<Saml_Idp>;
  /** fetch data from the table in a streaming manner: "saml_idp" */
  saml_idp_stream: Array<Saml_Idp>;
  /** execute function "search_project_login_status" which returns "search_project_login_status_results" */
  search_project_login_status: Array<Search_Project_Login_Status_Results>;
  /** execute function "search_project_login_status" and query aggregates on result of table type "search_project_login_status_results" */
  search_project_login_status_aggregate: Search_Project_Login_Status_Results_Aggregate;
  /** fetch data from the table: "search_project_login_status_results" */
  search_project_login_status_results: Array<
    Search_Project_Login_Status_Results
  >;
  /** fetch aggregated fields from the table: "search_project_login_status_results" */
  search_project_login_status_results_aggregate: Search_Project_Login_Status_Results_Aggregate;
  /** fetch data from the table in a streaming manner: "search_project_login_status_results" */
  search_project_login_status_results_stream: Array<
    Search_Project_Login_Status_Results
  >;
  /** execute function "search_tenant_group_has_least_members" which returns "tenant_group" */
  search_tenant_group_has_least_members: Array<Tenant_Group>;
  /** execute function "search_tenant_group_has_least_members" and query aggregates on result of table type "tenant_group" */
  search_tenant_group_has_least_members_aggregate: Tenant_Group_Aggregate;
  /** fetch data from the table: "slack_config" */
  slack_config: Array<Slack_Config>;
  /** fetch aggregated fields from the table: "slack_config" */
  slack_config_aggregate: Slack_Config_Aggregate;
  /** fetch data from the table: "slack_config" using primary key columns */
  slack_config_by_pk?: Maybe<Slack_Config>;
  /** fetch data from the table in a streaming manner: "slack_config" */
  slack_config_stream: Array<Slack_Config>;
  /** fetch data from the table: "stripe_subscription" */
  stripe_subscription: Array<Stripe_Subscription>;
  /** fetch aggregated fields from the table: "stripe_subscription" */
  stripe_subscription_aggregate: Stripe_Subscription_Aggregate;
  /** fetch data from the table: "stripe_subscription" using primary key columns */
  stripe_subscription_by_pk?: Maybe<Stripe_Subscription>;
  /** fetch data from the table in a streaming manner: "stripe_subscription" */
  stripe_subscription_stream: Array<Stripe_Subscription>;
  /** fetch data from the table: "stripe_webhook_events" */
  stripe_webhook_events: Array<Stripe_Webhook_Events>;
  /** fetch aggregated fields from the table: "stripe_webhook_events" */
  stripe_webhook_events_aggregate: Stripe_Webhook_Events_Aggregate;
  /** fetch data from the table: "stripe_webhook_events" using primary key columns */
  stripe_webhook_events_by_pk?: Maybe<Stripe_Webhook_Events>;
  /** fetch data from the table in a streaming manner: "stripe_webhook_events" */
  stripe_webhook_events_stream: Array<Stripe_Webhook_Events>;
  /** fetch data from the table: "super_connector_types" */
  super_connector_types: Array<Super_Connector_Types>;
  /** fetch aggregated fields from the table: "super_connector_types" */
  super_connector_types_aggregate: Super_Connector_Types_Aggregate;
  /** fetch data from the table: "super_connector_types" using primary key columns */
  super_connector_types_by_pk?: Maybe<Super_Connector_Types>;
  /** fetch data from the table in a streaming manner: "super_connector_types" */
  super_connector_types_stream: Array<Super_Connector_Types>;
  /** fetch data from the table: "support_plan_types" */
  support_plan_types: Array<Support_Plan_Types>;
  /** fetch aggregated fields from the table: "support_plan_types" */
  support_plan_types_aggregate: Support_Plan_Types_Aggregate;
  /** fetch data from the table: "support_plan_types" using primary key columns */
  support_plan_types_by_pk?: Maybe<Support_Plan_Types>;
  /** fetch data from the table in a streaming manner: "support_plan_types" */
  support_plan_types_stream: Array<Support_Plan_Types>;
  /** fetch data from the table: "survey" */
  survey: Array<Survey>;
  /** fetch aggregated fields from the table: "survey" */
  survey_aggregate: Survey_Aggregate;
  /** fetch data from the table: "survey" using primary key columns */
  survey_by_pk?: Maybe<Survey>;
  /** fetch data from the table: "survey_question" */
  survey_question: Array<Survey_Question>;
  /** fetch aggregated fields from the table: "survey_question" */
  survey_question_aggregate: Survey_Question_Aggregate;
  /** fetch data from the table: "survey_question_answer_option" */
  survey_question_answer_option: Array<Survey_Question_Answer_Option>;
  /** fetch aggregated fields from the table: "survey_question_answer_option" */
  survey_question_answer_option_aggregate: Survey_Question_Answer_Option_Aggregate;
  /** fetch data from the table: "survey_question_answer_option" using primary key columns */
  survey_question_answer_option_by_pk?: Maybe<Survey_Question_Answer_Option>;
  /** fetch data from the table in a streaming manner: "survey_question_answer_option" */
  survey_question_answer_option_stream: Array<Survey_Question_Answer_Option>;
  /** fetch data from the table: "survey_question_answers" */
  survey_question_answers: Array<Survey_Question_Answers>;
  /** fetch aggregated fields from the table: "survey_question_answers" */
  survey_question_answers_aggregate: Survey_Question_Answers_Aggregate;
  /** fetch data from the table: "survey_question_answers" using primary key columns */
  survey_question_answers_by_pk?: Maybe<Survey_Question_Answers>;
  /** fetch data from the table in a streaming manner: "survey_question_answers" */
  survey_question_answers_stream: Array<Survey_Question_Answers>;
  /** fetch data from the table: "survey_question" using primary key columns */
  survey_question_by_pk?: Maybe<Survey_Question>;
  /** fetch data from the table: "survey_question_kind" */
  survey_question_kind: Array<Survey_Question_Kind>;
  /** fetch aggregated fields from the table: "survey_question_kind" */
  survey_question_kind_aggregate: Survey_Question_Kind_Aggregate;
  /** fetch data from the table: "survey_question_kind" using primary key columns */
  survey_question_kind_by_pk?: Maybe<Survey_Question_Kind>;
  /** fetch data from the table in a streaming manner: "survey_question_kind" */
  survey_question_kind_stream: Array<Survey_Question_Kind>;
  /** An array relationship */
  survey_question_options: Array<Survey_Question_Options>;
  /** An aggregate relationship */
  survey_question_options_aggregate: Survey_Question_Options_Aggregate;
  /** fetch data from the table: "survey_question_options" using primary key columns */
  survey_question_options_by_pk?: Maybe<Survey_Question_Options>;
  /** fetch data from the table in a streaming manner: "survey_question_options" */
  survey_question_options_stream: Array<Survey_Question_Options>;
  /** fetch data from the table in a streaming manner: "survey_question" */
  survey_question_stream: Array<Survey_Question>;
  /** fetch data from the table in a streaming manner: "survey" */
  survey_stream: Array<Survey>;
  /** fetch data from the table: "survey_v2" */
  survey_v2: Array<Survey_V2>;
  /** fetch aggregated fields from the table: "survey_v2" */
  survey_v2_aggregate: Survey_V2_Aggregate;
  /** fetch data from the table: "survey_v2" using primary key columns */
  survey_v2_by_pk?: Maybe<Survey_V2>;
  /** fetch data from the table: "survey_v2_question" */
  survey_v2_question: Array<Survey_V2_Question>;
  /** fetch aggregated fields from the table: "survey_v2_question" */
  survey_v2_question_aggregate: Survey_V2_Question_Aggregate;
  /** fetch data from the table: "survey_v2_question" using primary key columns */
  survey_v2_question_by_pk?: Maybe<Survey_V2_Question>;
  /** fetch data from the table: "survey_v2_question_kind" */
  survey_v2_question_kind: Array<Survey_V2_Question_Kind>;
  /** fetch aggregated fields from the table: "survey_v2_question_kind" */
  survey_v2_question_kind_aggregate: Survey_V2_Question_Kind_Aggregate;
  /** fetch data from the table: "survey_v2_question_kind" using primary key columns */
  survey_v2_question_kind_by_pk?: Maybe<Survey_V2_Question_Kind>;
  /** fetch data from the table in a streaming manner: "survey_v2_question_kind" */
  survey_v2_question_kind_stream: Array<Survey_V2_Question_Kind>;
  /** fetch data from the table: "survey_v2_question_option" */
  survey_v2_question_option: Array<Survey_V2_Question_Option>;
  /** fetch data from the table: "survey_v2_question_option_additional_info_config" */
  survey_v2_question_option_additional_info_config: Array<
    Survey_V2_Question_Option_Additional_Info_Config
  >;
  /** fetch aggregated fields from the table: "survey_v2_question_option_additional_info_config" */
  survey_v2_question_option_additional_info_config_aggregate: Survey_V2_Question_Option_Additional_Info_Config_Aggregate;
  /** fetch data from the table: "survey_v2_question_option_additional_info_config" using primary key columns */
  survey_v2_question_option_additional_info_config_by_pk?: Maybe<
    Survey_V2_Question_Option_Additional_Info_Config
  >;
  /** fetch data from the table in a streaming manner: "survey_v2_question_option_additional_info_config" */
  survey_v2_question_option_additional_info_config_stream: Array<
    Survey_V2_Question_Option_Additional_Info_Config
  >;
  /** fetch aggregated fields from the table: "survey_v2_question_option" */
  survey_v2_question_option_aggregate: Survey_V2_Question_Option_Aggregate;
  /** fetch data from the table: "survey_v2_question_option" using primary key columns */
  survey_v2_question_option_by_pk?: Maybe<Survey_V2_Question_Option>;
  /** fetch data from the table in a streaming manner: "survey_v2_question_option" */
  survey_v2_question_option_stream: Array<Survey_V2_Question_Option>;
  /** fetch data from the table in a streaming manner: "survey_v2_question" */
  survey_v2_question_stream: Array<Survey_V2_Question>;
  /** fetch data from the table: "survey_v2_response" */
  survey_v2_response: Array<Survey_V2_Response>;
  /** fetch aggregated fields from the table: "survey_v2_response" */
  survey_v2_response_aggregate: Survey_V2_Response_Aggregate;
  /** fetch data from the table: "survey_v2_response_answer" */
  survey_v2_response_answer: Array<Survey_V2_Response_Answer>;
  /** fetch aggregated fields from the table: "survey_v2_response_answer" */
  survey_v2_response_answer_aggregate: Survey_V2_Response_Answer_Aggregate;
  /** fetch data from the table: "survey_v2_response_answer" using primary key columns */
  survey_v2_response_answer_by_pk?: Maybe<Survey_V2_Response_Answer>;
  /** fetch data from the table: "survey_v2_response_answer_option" */
  survey_v2_response_answer_option: Array<Survey_V2_Response_Answer_Option>;
  /** fetch aggregated fields from the table: "survey_v2_response_answer_option" */
  survey_v2_response_answer_option_aggregate: Survey_V2_Response_Answer_Option_Aggregate;
  /** fetch data from the table: "survey_v2_response_answer_option" using primary key columns */
  survey_v2_response_answer_option_by_pk?: Maybe<
    Survey_V2_Response_Answer_Option
  >;
  /** fetch data from the table in a streaming manner: "survey_v2_response_answer_option" */
  survey_v2_response_answer_option_stream: Array<
    Survey_V2_Response_Answer_Option
  >;
  /** fetch data from the table in a streaming manner: "survey_v2_response_answer" */
  survey_v2_response_answer_stream: Array<Survey_V2_Response_Answer>;
  /** fetch data from the table: "survey_v2_response" using primary key columns */
  survey_v2_response_by_pk?: Maybe<Survey_V2_Response>;
  /** fetch data from the table in a streaming manner: "survey_v2_response" */
  survey_v2_response_stream: Array<Survey_V2_Response>;
  /** fetch data from the table in a streaming manner: "survey_v2" */
  survey_v2_stream: Array<Survey_V2>;
  /** fetch data from the table: "task_event" */
  task_event: Array<Task_Event>;
  /** fetch aggregated fields from the table: "task_event" */
  task_event_aggregate: Task_Event_Aggregate;
  /** fetch data from the table: "task_event" using primary key columns */
  task_event_by_pk?: Maybe<Task_Event>;
  /** fetch data from the table in a streaming manner: "task_event" */
  task_event_stream: Array<Task_Event>;
  /** fetch data from the table: "taskq_worker_event_latest" */
  taskq_worker_event_latest: Array<Taskq_Worker_Event_Latest>;
  /** fetch aggregated fields from the table: "taskq_worker_event_latest" */
  taskq_worker_event_latest_aggregate: Taskq_Worker_Event_Latest_Aggregate;
  /** fetch data from the table in a streaming manner: "taskq_worker_event_latest" */
  taskq_worker_event_latest_stream: Array<Taskq_Worker_Event_Latest>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** fetch data from the table in a streaming manner: "tasks" */
  tasks_stream: Array<Tasks>;
  /** fetch data from the table: "tenant" */
  tenant: Array<Tenant>;
  /** fetch data from the table: "tenant_active_status_reason" */
  tenant_active_status_reason: Array<Tenant_Active_Status_Reason>;
  /** fetch aggregated fields from the table: "tenant_active_status_reason" */
  tenant_active_status_reason_aggregate: Tenant_Active_Status_Reason_Aggregate;
  /** fetch data from the table: "tenant_active_status_reason" using primary key columns */
  tenant_active_status_reason_by_pk?: Maybe<Tenant_Active_Status_Reason>;
  /** fetch data from the table in a streaming manner: "tenant_active_status_reason" */
  tenant_active_status_reason_stream: Array<Tenant_Active_Status_Reason>;
  /** fetch aggregated fields from the table: "tenant" */
  tenant_aggregate: Tenant_Aggregate;
  /** fetch data from the table: "tenant" using primary key columns */
  tenant_by_pk?: Maybe<Tenant>;
  /** fetch data from the table: "tenant_group" */
  tenant_group: Array<Tenant_Group>;
  /** fetch aggregated fields from the table: "tenant_group" */
  tenant_group_aggregate: Tenant_Group_Aggregate;
  /** fetch data from the table: "tenant_group" using primary key columns */
  tenant_group_by_pk?: Maybe<Tenant_Group>;
  /** fetch data from the table: "tenant_group_member" */
  tenant_group_member: Array<Tenant_Group_Member>;
  /** fetch aggregated fields from the table: "tenant_group_member" */
  tenant_group_member_aggregate: Tenant_Group_Member_Aggregate;
  /** fetch data from the table: "tenant_group_member" using primary key columns */
  tenant_group_member_by_pk?: Maybe<Tenant_Group_Member>;
  /** fetch data from the table in a streaming manner: "tenant_group_member" */
  tenant_group_member_stream: Array<Tenant_Group_Member>;
  /** fetch data from the table in a streaming manner: "tenant_group" */
  tenant_group_stream: Array<Tenant_Group>;
  /** An array relationship */
  tenant_public_status: Array<Tenant_Public_Status>;
  /** An aggregate relationship */
  tenant_public_status_aggregate: Tenant_Public_Status_Aggregate;
  /** fetch data from the table: "tenant_public_status" using primary key columns */
  tenant_public_status_by_pk?: Maybe<Tenant_Public_Status>;
  /** fetch data from the table in a streaming manner: "tenant_public_status" */
  tenant_public_status_stream: Array<Tenant_Public_Status>;
  /** fetch data from the table: "tenant_status" */
  tenant_status: Array<Tenant_Status>;
  /** fetch aggregated fields from the table: "tenant_status" */
  tenant_status_aggregate: Tenant_Status_Aggregate;
  /** fetch data from the table: "tenant_status" using primary key columns */
  tenant_status_by_pk?: Maybe<Tenant_Status>;
  /** fetch data from the table in a streaming manner: "tenant_status" */
  tenant_status_stream: Array<Tenant_Status>;
  /** fetch data from the table in a streaming manner: "tenant" */
  tenant_stream: Array<Tenant>;
  /** fetch data from the table: "trial_leads" */
  trial_leads: Array<Trial_Leads>;
  /** fetch aggregated fields from the table: "trial_leads" */
  trial_leads_aggregate: Trial_Leads_Aggregate;
  /** fetch data from the table: "trial_leads" using primary key columns */
  trial_leads_by_pk?: Maybe<Trial_Leads>;
  /** fetch data from the table in a streaming manner: "trial_leads" */
  trial_leads_stream: Array<Trial_Leads>;
  /** fetch data from the table: "ua_audit_logs" */
  ua_audit_logs: Array<Ua_Audit_Logs>;
  /** fetch aggregated fields from the table: "ua_audit_logs" */
  ua_audit_logs_aggregate: Ua_Audit_Logs_Aggregate;
  /** fetch data from the table: "ua_audit_logs" using primary key columns */
  ua_audit_logs_by_pk?: Maybe<Ua_Audit_Logs>;
  /** fetch data from the table in a streaming manner: "ua_audit_logs" */
  ua_audit_logs_stream: Array<Ua_Audit_Logs>;
  /** fetch data from the table: "user_activity" */
  user_activity: Array<User_Activity>;
  /** fetch aggregated fields from the table: "user_activity" */
  user_activity_aggregate: User_Activity_Aggregate;
  /** fetch data from the table: "user_activity" using primary key columns */
  user_activity_by_pk?: Maybe<User_Activity>;
  /** fetch data from the table in a streaming manner: "user_activity" */
  user_activity_stream: Array<User_Activity>;
  /** fetch data from the table: "user_coupon" */
  user_coupon: Array<User_Coupon>;
  /** fetch aggregated fields from the table: "user_coupon" */
  user_coupon_aggregate: User_Coupon_Aggregate;
  /** fetch data from the table: "user_coupon" using primary key columns */
  user_coupon_by_pk?: Maybe<User_Coupon>;
  /** fetch data from the table in a streaming manner: "user_coupon" */
  user_coupon_stream: Array<User_Coupon>;
  /** fetch data from the table: "user_entitlement_access" */
  user_entitlement_access: Array<User_Entitlement_Access>;
  /** fetch aggregated fields from the table: "user_entitlement_access" */
  user_entitlement_access_aggregate: User_Entitlement_Access_Aggregate;
  /** fetch data from the table: "user_entitlement_access" using primary key columns */
  user_entitlement_access_by_pk?: Maybe<User_Entitlement_Access>;
  /** fetch data from the table in a streaming manner: "user_entitlement_access" */
  user_entitlement_access_stream: Array<User_Entitlement_Access>;
  /** fetch data from the table: "user_entitlement_catalogue" */
  user_entitlement_catalogue: Array<User_Entitlement_Catalogue>;
  /** fetch aggregated fields from the table: "user_entitlement_catalogue" */
  user_entitlement_catalogue_aggregate: User_Entitlement_Catalogue_Aggregate;
  /** fetch data from the table: "user_entitlement_catalogue" using primary key columns */
  user_entitlement_catalogue_by_pk?: Maybe<User_Entitlement_Catalogue>;
  /** fetch data from the table in a streaming manner: "user_entitlement_catalogue" */
  user_entitlement_catalogue_stream: Array<User_Entitlement_Catalogue>;
  /** fetch data from the table: "user_entitlement_types" */
  user_entitlement_types: Array<User_Entitlement_Types>;
  /** fetch aggregated fields from the table: "user_entitlement_types" */
  user_entitlement_types_aggregate: User_Entitlement_Types_Aggregate;
  /** fetch data from the table: "user_entitlement_types" using primary key columns */
  user_entitlement_types_by_pk?: Maybe<User_Entitlement_Types>;
  /** fetch data from the table in a streaming manner: "user_entitlement_types" */
  user_entitlement_types_stream: Array<User_Entitlement_Types>;
  /** fetch data from the table: "user_onboarding" */
  user_onboarding: Array<User_Onboarding>;
  /** fetch aggregated fields from the table: "user_onboarding" */
  user_onboarding_aggregate: User_Onboarding_Aggregate;
  /** fetch data from the table: "user_onboarding" using primary key columns */
  user_onboarding_by_pk?: Maybe<User_Onboarding>;
  /** fetch data from the table in a streaming manner: "user_onboarding" */
  user_onboarding_stream: Array<User_Onboarding>;
  /** fetch data from the table: "user_personal_access_tokens" */
  user_personal_access_tokens: Array<User_Personal_Access_Tokens>;
  /** fetch aggregated fields from the table: "user_personal_access_tokens" */
  user_personal_access_tokens_aggregate: User_Personal_Access_Tokens_Aggregate;
  /** fetch data from the table: "user_personal_access_tokens" using primary key columns */
  user_personal_access_tokens_by_pk?: Maybe<User_Personal_Access_Tokens>;
  /** fetch data from the table in a streaming manner: "user_personal_access_tokens" */
  user_personal_access_tokens_stream: Array<User_Personal_Access_Tokens>;
  /** fetch data from the table: "user_profile" */
  user_profile: Array<User_Profile>;
  /** fetch aggregated fields from the table: "user_profile" */
  user_profile_aggregate: User_Profile_Aggregate;
  /** fetch data from the table: "user_profile" using primary key columns */
  user_profile_by_pk?: Maybe<User_Profile>;
  /** fetch data from the table in a streaming manner: "user_profile" */
  user_profile_stream: Array<User_Profile>;
  /** fetch data from the table: "user_project_map" */
  user_project_map: Array<User_Project_Map>;
  /** fetch aggregated fields from the table: "user_project_map" */
  user_project_map_aggregate: User_Project_Map_Aggregate;
  /** fetch data from the table in a streaming manner: "user_project_map" */
  user_project_map_stream: Array<User_Project_Map>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table in a streaming manner: "user_roles" */
  user_roles_stream: Array<User_Roles>;
  /** fetch data from the table: "user_vpc_policy" */
  user_vpc_policy: Array<User_Vpc_Policy>;
  /** fetch aggregated fields from the table: "user_vpc_policy" */
  user_vpc_policy_aggregate: User_Vpc_Policy_Aggregate;
  /** fetch data from the table: "user_vpc_policy" using primary key columns */
  user_vpc_policy_by_pk?: Maybe<User_Vpc_Policy>;
  /** fetch data from the table in a streaming manner: "user_vpc_policy" */
  user_vpc_policy_stream: Array<User_Vpc_Policy>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "users_public" */
  users_public: Array<Users_Public>;
  /** fetch aggregated fields from the table: "users_public" */
  users_public_aggregate: Users_Public_Aggregate;
  /** fetch data from the table in a streaming manner: "users_public" */
  users_public_stream: Array<Users_Public>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "vercel_integration" */
  vercel_integration: Array<Vercel_Integration>;
  /** fetch aggregated fields from the table: "vercel_integration" */
  vercel_integration_aggregate: Vercel_Integration_Aggregate;
  /** fetch data from the table: "vercel_integration" using primary key columns */
  vercel_integration_by_pk?: Maybe<Vercel_Integration>;
  /** An array relationship */
  vercel_integration_connections: Array<Vercel_Integration_Connections>;
  /** An aggregate relationship */
  vercel_integration_connections_aggregate: Vercel_Integration_Connections_Aggregate;
  /** fetch data from the table: "vercel_integration_connections" using primary key columns */
  vercel_integration_connections_by_pk?: Maybe<Vercel_Integration_Connections>;
  /** fetch data from the table in a streaming manner: "vercel_integration_connections" */
  vercel_integration_connections_stream: Array<Vercel_Integration_Connections>;
  /** fetch data from the table in a streaming manner: "vercel_integration" */
  vercel_integration_stream: Array<Vercel_Integration>;
  /** fetch data from the table: "vpc_collaborator_invitations" */
  vpc_collaborator_invitations: Array<Vpc_Collaborator_Invitations>;
  /** fetch aggregated fields from the table: "vpc_collaborator_invitations" */
  vpc_collaborator_invitations_aggregate: Vpc_Collaborator_Invitations_Aggregate;
  /** fetch data from the table: "vpc_collaborator_invitations" using primary key columns */
  vpc_collaborator_invitations_by_pk?: Maybe<Vpc_Collaborator_Invitations>;
  /** fetch data from the table in a streaming manner: "vpc_collaborator_invitations" */
  vpc_collaborator_invitations_stream: Array<Vpc_Collaborator_Invitations>;
  /** fetch data from the table: "vpc_collaborators" */
  vpc_collaborators: Array<Vpc_Collaborators>;
  /** fetch aggregated fields from the table: "vpc_collaborators" */
  vpc_collaborators_aggregate: Vpc_Collaborators_Aggregate;
  /** fetch data from the table: "vpc_collaborators" using primary key columns */
  vpc_collaborators_by_pk?: Maybe<Vpc_Collaborators>;
  /** fetch data from the table in a streaming manner: "vpc_collaborators" */
  vpc_collaborators_stream: Array<Vpc_Collaborators>;
  /** fetch data from the table: "vpc_peering" */
  vpc_peering: Array<Vpc_Peering>;
  /** fetch aggregated fields from the table: "vpc_peering" */
  vpc_peering_aggregate: Vpc_Peering_Aggregate;
  /** fetch data from the table: "vpc_peering" using primary key columns */
  vpc_peering_by_pk?: Maybe<Vpc_Peering>;
  /** fetch data from the table: "vpc_peering_direction" */
  vpc_peering_direction: Array<Vpc_Peering_Direction>;
  /** fetch aggregated fields from the table: "vpc_peering_direction" */
  vpc_peering_direction_aggregate: Vpc_Peering_Direction_Aggregate;
  /** fetch data from the table: "vpc_peering_direction" using primary key columns */
  vpc_peering_direction_by_pk?: Maybe<Vpc_Peering_Direction>;
  /** fetch data from the table in a streaming manner: "vpc_peering_direction" */
  vpc_peering_direction_stream: Array<Vpc_Peering_Direction>;
  /** fetch data from the table in a streaming manner: "vpc_peering" */
  vpc_peering_stream: Array<Vpc_Peering>;
  /** fetch data from the table: "vpc_privileges" */
  vpc_privileges: Array<Vpc_Privileges>;
  /** fetch aggregated fields from the table: "vpc_privileges" */
  vpc_privileges_aggregate: Vpc_Privileges_Aggregate;
  /** fetch data from the table: "vpc_privileges" using primary key columns */
  vpc_privileges_by_pk?: Maybe<Vpc_Privileges>;
  /** fetch data from the table in a streaming manner: "vpc_privileges" */
  vpc_privileges_stream: Array<Vpc_Privileges>;
  /** fetch data from the table: "vpc_status" */
  vpc_status: Array<Vpc_Status>;
  /** fetch aggregated fields from the table: "vpc_status" */
  vpc_status_aggregate: Vpc_Status_Aggregate;
  /** fetch data from the table: "vpc_status" using primary key columns */
  vpc_status_by_pk?: Maybe<Vpc_Status>;
  /** fetch data from the table in a streaming manner: "vpc_status" */
  vpc_status_stream: Array<Vpc_Status>;
  /** fetch data from the table: "zendesk_support_category" */
  zendesk_support_category: Array<Zendesk_Support_Category>;
  /** fetch aggregated fields from the table: "zendesk_support_category" */
  zendesk_support_category_aggregate: Zendesk_Support_Category_Aggregate;
  /** fetch data from the table: "zendesk_support_category" using primary key columns */
  zendesk_support_category_by_pk?: Maybe<Zendesk_Support_Category>;
  /** fetch data from the table in a streaming manner: "zendesk_support_category" */
  zendesk_support_category_stream: Array<Zendesk_Support_Category>;
  /** fetch data from the table: "zendesk_support_tickets" */
  zendesk_support_tickets: Array<Zendesk_Support_Tickets>;
  /** fetch aggregated fields from the table: "zendesk_support_tickets" */
  zendesk_support_tickets_aggregate: Zendesk_Support_Tickets_Aggregate;
  /** fetch data from the table: "zendesk_support_tickets" using primary key columns */
  zendesk_support_tickets_by_pk?: Maybe<Zendesk_Support_Tickets>;
  /** fetch data from the table in a streaming manner: "zendesk_support_tickets" */
  zendesk_support_tickets_stream: Array<Zendesk_Support_Tickets>;
};

export type Subscription_RootAlert_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Order_By>>;
  where?: InputMaybe<Alert_Config_Bool_Exp>;
};

export type Subscription_RootAlert_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Order_By>>;
  where?: InputMaybe<Alert_Config_Bool_Exp>;
};

export type Subscription_RootAlert_Config_Alert_TypeArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Alert_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Alert_Type_Order_By>>;
  where?: InputMaybe<Alert_Config_Alert_Type_Bool_Exp>;
};

export type Subscription_RootAlert_Config_Alert_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Alert_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Alert_Type_Order_By>>;
  where?: InputMaybe<Alert_Config_Alert_Type_Bool_Exp>;
};

export type Subscription_RootAlert_Config_Alert_Type_By_PkArgs = {
  project_id: Scalars['uuid'];
  type: Scalars['String'];
};

export type Subscription_RootAlert_Config_Alert_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Alert_Config_Alert_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Alert_Config_Alert_Type_Bool_Exp>;
};

export type Subscription_RootAlert_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Subscription_RootAlert_Config_ServiceArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Service_Order_By>>;
  where?: InputMaybe<Alert_Config_Service_Bool_Exp>;
};

export type Subscription_RootAlert_Config_Service_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Config_Service_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Config_Service_Order_By>>;
  where?: InputMaybe<Alert_Config_Service_Bool_Exp>;
};

export type Subscription_RootAlert_Config_Service_By_PkArgs = {
  project_id: Scalars['uuid'];
  type: Alert_Service_Type_Enum;
};

export type Subscription_RootAlert_Config_Service_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Alert_Config_Service_Stream_Cursor_Input>>;
  where?: InputMaybe<Alert_Config_Service_Bool_Exp>;
};

export type Subscription_RootAlert_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Alert_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Alert_Config_Bool_Exp>;
};

export type Subscription_RootAlert_Service_TypeArgs = {
  distinct_on?: InputMaybe<Array<Alert_Service_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Service_Type_Order_By>>;
  where?: InputMaybe<Alert_Service_Type_Bool_Exp>;
};

export type Subscription_RootAlert_Service_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Service_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Service_Type_Order_By>>;
  where?: InputMaybe<Alert_Service_Type_Bool_Exp>;
};

export type Subscription_RootAlert_Service_Type_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootAlert_Service_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Alert_Service_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Alert_Service_Type_Bool_Exp>;
};

export type Subscription_RootAlert_TypeArgs = {
  distinct_on?: InputMaybe<Array<Alert_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Type_Order_By>>;
  where?: InputMaybe<Alert_Type_Bool_Exp>;
};

export type Subscription_RootAlert_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Alert_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Alert_Type_Order_By>>;
  where?: InputMaybe<Alert_Type_Bool_Exp>;
};

export type Subscription_RootAlert_Type_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootAlert_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Alert_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Alert_Type_Bool_Exp>;
};

export type Subscription_RootAzuremonitor_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Azuremonitor_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Azuremonitor_Config_Order_By>>;
  where?: InputMaybe<Azuremonitor_Config_Bool_Exp>;
};

export type Subscription_RootAzuremonitor_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Azuremonitor_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Azuremonitor_Config_Order_By>>;
  where?: InputMaybe<Azuremonitor_Config_Bool_Exp>;
};

export type Subscription_RootAzuremonitor_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Subscription_RootAzuremonitor_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Azuremonitor_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Azuremonitor_Config_Bool_Exp>;
};

export type Subscription_RootBilling_AddressArgs = {
  distinct_on?: InputMaybe<Array<Billing_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billing_Address_Order_By>>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};

export type Subscription_RootBilling_Address_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Billing_Address_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Billing_Address_Order_By>>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};

export type Subscription_RootBilling_Address_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootBilling_Address_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Billing_Address_Stream_Cursor_Input>>;
  where?: InputMaybe<Billing_Address_Bool_Exp>;
};

export type Subscription_RootCentral_CloudArgs = {
  distinct_on?: InputMaybe<Array<Central_Cloud_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Central_Cloud_Order_By>>;
  where?: InputMaybe<Central_Cloud_Bool_Exp>;
};

export type Subscription_RootCentral_Cloud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Central_Cloud_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Central_Cloud_Order_By>>;
  where?: InputMaybe<Central_Cloud_Bool_Exp>;
};

export type Subscription_RootCentral_Cloud_By_PkArgs = {
  cloud: Cloud_Enum;
};

export type Subscription_RootCentral_Cloud_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Central_Cloud_Stream_Cursor_Input>>;
  where?: InputMaybe<Central_Cloud_Bool_Exp>;
};

export type Subscription_RootChange_Email_RequestArgs = {
  distinct_on?: InputMaybe<Array<Change_Email_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Change_Email_Request_Order_By>>;
  where?: InputMaybe<Change_Email_Request_Bool_Exp>;
};

export type Subscription_RootChange_Email_Request_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Change_Email_Request_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Change_Email_Request_Order_By>>;
  where?: InputMaybe<Change_Email_Request_Bool_Exp>;
};

export type Subscription_RootChange_Email_Request_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootChange_Email_Request_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Change_Email_Request_Stream_Cursor_Input>>;
  where?: InputMaybe<Change_Email_Request_Bool_Exp>;
};

export type Subscription_RootCicd_Deployment_Pipeline_ConfigArgs = {
  distinct_on?: InputMaybe<
    Array<Cicd_Deployment_Pipeline_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pipeline_Config_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pipeline_Config_Bool_Exp>;
};

export type Subscription_RootCicd_Deployment_Pipeline_Config_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Cicd_Deployment_Pipeline_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pipeline_Config_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pipeline_Config_Bool_Exp>;
};

export type Subscription_RootCicd_Deployment_Pipeline_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCicd_Deployment_Pipeline_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Cicd_Deployment_Pipeline_Config_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Cicd_Deployment_Pipeline_Config_Bool_Exp>;
};

export type Subscription_RootCicd_Deployment_PoolArgs = {
  distinct_on?: InputMaybe<Array<Cicd_Deployment_Pool_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pool_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Bool_Exp>;
};

export type Subscription_RootCicd_Deployment_Pool_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cicd_Deployment_Pool_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pool_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Bool_Exp>;
};

export type Subscription_RootCicd_Deployment_Pool_MemberArgs = {
  distinct_on?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
};

export type Subscription_RootCicd_Deployment_Pool_Member_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
};

export type Subscription_RootCicd_Deployment_Pool_Member_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cicd_Deployment_Pool_Member_Stream_Cursor_Input>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
};

export type Subscription_RootCicd_Deployment_Pool_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cicd_Deployment_Pool_Stream_Cursor_Input>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Bool_Exp>;
};

export type Subscription_RootCicd_Get_Matching_Pipeline_ConfigArgs = {
  args?: InputMaybe<Cicd_Get_Matching_Pipeline_Config_Args>;
  distinct_on?: InputMaybe<
    Array<Cicd_Deployment_Pipeline_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pipeline_Config_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pipeline_Config_Bool_Exp>;
};

export type Subscription_RootCicd_Get_Matching_Pipeline_Config_AggregateArgs = {
  args?: InputMaybe<Cicd_Get_Matching_Pipeline_Config_Args>;
  distinct_on?: InputMaybe<
    Array<Cicd_Deployment_Pipeline_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pipeline_Config_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pipeline_Config_Bool_Exp>;
};

export type Subscription_RootCloudArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cloud_Order_By>>;
  where?: InputMaybe<Cloud_Bool_Exp>;
};

export type Subscription_RootCloud_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cloud_Order_By>>;
  where?: InputMaybe<Cloud_Bool_Exp>;
};

export type Subscription_RootCloud_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootCloud_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cloud_Metadata_Order_By>>;
  where?: InputMaybe<Cloud_Metadata_Bool_Exp>;
};

export type Subscription_RootCloud_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cloud_Metadata_Order_By>>;
  where?: InputMaybe<Cloud_Metadata_Bool_Exp>;
};

export type Subscription_RootCloud_Metadata_By_PkArgs = {
  cloud: Cloud_Enum;
};

export type Subscription_RootCloud_Metadata_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cloud_Metadata_Stream_Cursor_Input>>;
  where?: InputMaybe<Cloud_Metadata_Bool_Exp>;
};

export type Subscription_RootCloud_Regions_By_PlanArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Regions_By_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cloud_Regions_By_Plan_Order_By>>;
  where?: InputMaybe<Cloud_Regions_By_Plan_Bool_Exp>;
};

export type Subscription_RootCloud_Regions_By_Plan_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cloud_Regions_By_Plan_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cloud_Regions_By_Plan_Order_By>>;
  where?: InputMaybe<Cloud_Regions_By_Plan_Bool_Exp>;
};

export type Subscription_RootCloud_Regions_By_Plan_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cloud_Regions_By_Plan_Stream_Cursor_Input>>;
  where?: InputMaybe<Cloud_Regions_By_Plan_Bool_Exp>;
};

export type Subscription_RootCloud_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Cloud_Stream_Cursor_Input>>;
  where?: InputMaybe<Cloud_Bool_Exp>;
};

export type Subscription_RootCompute_Project_Agg_Db_Usage_UserArgs = {
  args?: InputMaybe<Compute_Project_Agg_Db_Usage_User_Args>;
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Agg_User_Bool_Exp>;
};

export type Subscription_RootCompute_Project_Agg_Db_Usage_User_AggregateArgs = {
  args?: InputMaybe<Compute_Project_Agg_Db_Usage_User_Args>;
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Agg_User_Bool_Exp>;
};

export type Subscription_RootCompute_Project_Agg_UsageArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_Args>;
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Subscription_RootCompute_Project_Agg_Usage_AggregateArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_Args>;
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Subscription_RootCompute_Project_Agg_Usage_PrometheusArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_Prometheus_Args>;
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Subscription_RootCompute_Project_Agg_Usage_Prometheus_AggregateArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_Prometheus_Args>;
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Subscription_RootCompute_Project_Agg_Usage_Prometheus_UserArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_Prometheus_User_Args>;
  distinct_on?: InputMaybe<
    Array<Project_Data_Usage_Prometheus_Agg_User_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Bool_Exp>;
};

export type Subscription_RootCompute_Project_Agg_Usage_Prometheus_User_AggregateArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_Prometheus_User_Args>;
  distinct_on?: InputMaybe<
    Array<Project_Data_Usage_Prometheus_Agg_User_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Bool_Exp>;
};

export type Subscription_RootCompute_Project_Agg_Usage_UserArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_User_Args>;
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_User_Bool_Exp>;
};

export type Subscription_RootCompute_Project_Agg_Usage_User_AggregateArgs = {
  args?: InputMaybe<Compute_Project_Agg_Usage_User_Args>;
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_User_Bool_Exp>;
};

export type Subscription_RootCompute_Unit_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Compute_Unit_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compute_Unit_Config_Order_By>>;
  where?: InputMaybe<Compute_Unit_Config_Bool_Exp>;
};

export type Subscription_RootCompute_Unit_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Compute_Unit_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Compute_Unit_Config_Order_By>>;
  where?: InputMaybe<Compute_Unit_Config_Bool_Exp>;
};

export type Subscription_RootCompute_Unit_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCompute_Unit_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Compute_Unit_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Compute_Unit_Config_Bool_Exp>;
};

export type Subscription_RootConfigArgs = {
  distinct_on?: InputMaybe<Array<Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Config_Order_By>>;
  where?: InputMaybe<Config_Bool_Exp>;
};

export type Subscription_RootConfig_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Config_Order_By>>;
  where?: InputMaybe<Config_Bool_Exp>;
};

export type Subscription_RootConfig_By_PkArgs = {
  tenant_id: Scalars['uuid'];
};

export type Subscription_RootConfig_StatusArgs = {
  distinct_on?: InputMaybe<Array<Config_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Config_Status_Order_By>>;
  where?: InputMaybe<Config_Status_Bool_Exp>;
};

export type Subscription_RootConfig_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Config_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Config_Status_Order_By>>;
  where?: InputMaybe<Config_Status_Bool_Exp>;
};

export type Subscription_RootConfig_Status_By_PkArgs = {
  hash: Scalars['String'];
  tenant_id: Scalars['uuid'];
  worker_id: Scalars['uuid'];
};

export type Subscription_RootConfig_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Config_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Config_Status_Bool_Exp>;
};

export type Subscription_RootConfig_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Config_Bool_Exp>;
};

export type Subscription_RootConnector_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Connector_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connector_Config_Order_By>>;
  where?: InputMaybe<Connector_Config_Bool_Exp>;
};

export type Subscription_RootConnector_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Connector_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connector_Config_Order_By>>;
  where?: InputMaybe<Connector_Config_Bool_Exp>;
};

export type Subscription_RootConnector_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootConnector_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Connector_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Connector_Config_Bool_Exp>;
};

export type Subscription_RootConnector_DeploymentsArgs = {
  distinct_on?: InputMaybe<Array<Connector_Deployments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connector_Deployments_Order_By>>;
  where?: InputMaybe<Connector_Deployments_Bool_Exp>;
};

export type Subscription_RootConnector_Deployments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Connector_Deployments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Connector_Deployments_Order_By>>;
  where?: InputMaybe<Connector_Deployments_Bool_Exp>;
};

export type Subscription_RootConnector_Deployments_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootConnector_Deployments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Connector_Deployments_Stream_Cursor_Input>>;
  where?: InputMaybe<Connector_Deployments_Bool_Exp>;
};

export type Subscription_RootCouponArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type Subscription_RootCoupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Order_By>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type Subscription_RootCoupon_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCoupon_DurationArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Duration_Order_By>>;
  where?: InputMaybe<Coupon_Duration_Bool_Exp>;
};

export type Subscription_RootCoupon_Duration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Duration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Duration_Order_By>>;
  where?: InputMaybe<Coupon_Duration_Bool_Exp>;
};

export type Subscription_RootCoupon_Duration_By_PkArgs = {
  duration: Scalars['String'];
};

export type Subscription_RootCoupon_Duration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Coupon_Duration_Stream_Cursor_Input>>;
  where?: InputMaybe<Coupon_Duration_Bool_Exp>;
};

export type Subscription_RootCoupon_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Coupon_Stream_Cursor_Input>>;
  where?: InputMaybe<Coupon_Bool_Exp>;
};

export type Subscription_RootCoupon_TypeArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Type_Order_By>>;
  where?: InputMaybe<Coupon_Type_Bool_Exp>;
};

export type Subscription_RootCoupon_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Coupon_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Coupon_Type_Order_By>>;
  where?: InputMaybe<Coupon_Type_Bool_Exp>;
};

export type Subscription_RootCoupon_Type_By_PkArgs = {
  type: Scalars['String'];
};

export type Subscription_RootCoupon_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Coupon_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Coupon_Type_Bool_Exp>;
};

export type Subscription_RootCustom_DomainArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Order_By>>;
  where?: InputMaybe<Custom_Domain_Bool_Exp>;
};

export type Subscription_RootCustom_Domain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Order_By>>;
  where?: InputMaybe<Custom_Domain_Bool_Exp>;
};

export type Subscription_RootCustom_Domain_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCustom_Domain_CloudflareArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Cloudflare_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Cloudflare_Order_By>>;
  where?: InputMaybe<Custom_Domain_Cloudflare_Bool_Exp>;
};

export type Subscription_RootCustom_Domain_Cloudflare_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Cloudflare_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Cloudflare_Order_By>>;
  where?: InputMaybe<Custom_Domain_Cloudflare_Bool_Exp>;
};

export type Subscription_RootCustom_Domain_Cloudflare_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCustom_Domain_Cloudflare_DnsArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Cloudflare_Dns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Cloudflare_Dns_Order_By>>;
  where?: InputMaybe<Custom_Domain_Cloudflare_Dns_Bool_Exp>;
};

export type Subscription_RootCustom_Domain_Cloudflare_Dns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Cloudflare_Dns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Cloudflare_Dns_Order_By>>;
  where?: InputMaybe<Custom_Domain_Cloudflare_Dns_Bool_Exp>;
};

export type Subscription_RootCustom_Domain_Cloudflare_Dns_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCustom_Domain_Cloudflare_Dns_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Custom_Domain_Cloudflare_Dns_Stream_Cursor_Input>>;
  where?: InputMaybe<Custom_Domain_Cloudflare_Dns_Bool_Exp>;
};

export type Subscription_RootCustom_Domain_Cloudflare_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Custom_Domain_Cloudflare_Stream_Cursor_Input>>;
  where?: InputMaybe<Custom_Domain_Cloudflare_Bool_Exp>;
};

export type Subscription_RootCustom_Domain_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Custom_Domain_Stream_Cursor_Input>>;
  where?: InputMaybe<Custom_Domain_Bool_Exp>;
};

export type Subscription_RootCustomer_UsageArgs = {
  distinct_on?: InputMaybe<Array<Customer_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Usage_Order_By>>;
  where?: InputMaybe<Customer_Usage_Bool_Exp>;
};

export type Subscription_RootCustomer_Usage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Usage_Order_By>>;
  where?: InputMaybe<Customer_Usage_Bool_Exp>;
};

export type Subscription_RootCustomer_Usage_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootCustomer_Usage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Customer_Usage_Stream_Cursor_Input>>;
  where?: InputMaybe<Customer_Usage_Bool_Exp>;
};

export type Subscription_RootData_Connector_TypeArgs = {
  distinct_on?: InputMaybe<Array<Data_Connector_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Data_Connector_Type_Order_By>>;
  where?: InputMaybe<Data_Connector_Type_Bool_Exp>;
};

export type Subscription_RootData_Connector_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Data_Connector_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Data_Connector_Type_Order_By>>;
  where?: InputMaybe<Data_Connector_Type_Bool_Exp>;
};

export type Subscription_RootData_Connector_Type_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootData_Connector_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Data_Connector_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Data_Connector_Type_Bool_Exp>;
};

export type Subscription_RootDatadog_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Datadog_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Datadog_Config_Order_By>>;
  where?: InputMaybe<Datadog_Config_Bool_Exp>;
};

export type Subscription_RootDatadog_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Datadog_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Datadog_Config_Order_By>>;
  where?: InputMaybe<Datadog_Config_Bool_Exp>;
};

export type Subscription_RootDatadog_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Subscription_RootDatadog_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Datadog_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Datadog_Config_Bool_Exp>;
};

export type Subscription_RootDb_LatencyArgs = {
  distinct_on?: InputMaybe<Array<Db_Latency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Db_Latency_Order_By>>;
  where?: InputMaybe<Db_Latency_Bool_Exp>;
};

export type Subscription_RootDb_Latency_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Db_Latency_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Db_Latency_Order_By>>;
  where?: InputMaybe<Db_Latency_Bool_Exp>;
};

export type Subscription_RootDb_Latency_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDb_Latency_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Db_Latency_Stream_Cursor_Input>>;
  where?: InputMaybe<Db_Latency_Bool_Exp>;
};

export type Subscription_RootDdn_Applied_Build_ChangelogArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Applied_Build_Changelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Applied_Build_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Applied_Build_Changelog_Bool_Exp>;
};

export type Subscription_RootDdn_Applied_Build_Changelog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Applied_Build_Changelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Applied_Build_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Applied_Build_Changelog_Bool_Exp>;
};

export type Subscription_RootDdn_Applied_Build_Changelog_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Applied_Build_Changelog_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Applied_Build_Changelog_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Applied_Build_Changelog_Bool_Exp>;
};

export type Subscription_RootDdn_Applied_Subgraph_Build_ChangelogArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Applied_Subgraph_Build_Changelog_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Applied_Subgraph_Build_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp>;
};

export type Subscription_RootDdn_Applied_Subgraph_Build_Changelog_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Applied_Subgraph_Build_Changelog_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Applied_Subgraph_Build_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp>;
};

export type Subscription_RootDdn_Applied_Subgraph_Build_Changelog_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Applied_Subgraph_Build_Changelog_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Ddn_Applied_Subgraph_Build_Changelog_Bool_Exp>;
};

export type Subscription_RootDdn_Auto_Approve_Project_RequestArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Auto_Approve_Project_Request_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Auto_Approve_Project_Request_Order_By>>;
  where?: InputMaybe<Ddn_Auto_Approve_Project_Request_Bool_Exp>;
};

export type Subscription_RootDdn_Auto_Approve_Project_Request_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Auto_Approve_Project_Request_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Auto_Approve_Project_Request_Order_By>>;
  where?: InputMaybe<Ddn_Auto_Approve_Project_Request_Bool_Exp>;
};

export type Subscription_RootDdn_Auto_Approve_Project_Request_By_PkArgs = {
  auto_approve_project_request: Scalars['Boolean'];
  project_id: Scalars['uuid'];
};

export type Subscription_RootDdn_Auto_Approve_Project_Request_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Ddn_Auto_Approve_Project_Request_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Ddn_Auto_Approve_Project_Request_Bool_Exp>;
};

export type Subscription_RootDdn_BuildArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Order_By>>;
  where?: InputMaybe<Ddn_Build_Bool_Exp>;
};

export type Subscription_RootDdn_Build_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Order_By>>;
  where?: InputMaybe<Ddn_Build_Bool_Exp>;
};

export type Subscription_RootDdn_Build_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Build_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Details_Order_By>>;
  where?: InputMaybe<Ddn_Build_Details_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Details_Order_By>>;
  where?: InputMaybe<Ddn_Build_Details_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Build_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Build_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Build_Details_Bool_Exp>;
};

export type Subscription_RootDdn_Build_LockArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Lock_Order_By>>;
  where?: InputMaybe<Ddn_Build_Lock_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Lock_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Lock_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Lock_Order_By>>;
  where?: InputMaybe<Ddn_Build_Lock_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Lock_By_PkArgs = {
  environment_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

export type Subscription_RootDdn_Build_Lock_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Build_Lock_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Build_Lock_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Model_CountArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Model_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Model_Count_Order_By>>;
  where?: InputMaybe<Ddn_Build_Model_Count_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Model_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Model_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Model_Count_Order_By>>;
  where?: InputMaybe<Ddn_Build_Model_Count_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Model_Count_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Build_Model_Count_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Build_Model_Count_Bool_Exp>;
};

export type Subscription_RootDdn_Build_RevisionArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Revision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Revision_Order_By>>;
  where?: InputMaybe<Ddn_Build_Revision_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Revision_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Revision_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Revision_Order_By>>;
  where?: InputMaybe<Ddn_Build_Revision_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Revision_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Build_Revision_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Revision_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Revision_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Revision_Status_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Revision_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Revision_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Revision_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Revision_Status_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Revision_Status_By_PkArgs = {
  status: Scalars['String'];
};

export type Subscription_RootDdn_Build_Revision_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Build_Revision_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Build_Revision_Status_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Revision_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Build_Revision_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Build_Revision_Bool_Exp>;
};

export type Subscription_RootDdn_Build_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Build_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Build_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Sync_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Sync_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Status_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Sync_Status_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Build_Sync_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Build_Sync_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Build_Sync_Status_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Sync_WorkerArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Worker_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Worker_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Sync_Worker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Build_Sync_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Build_Sync_Worker_Order_By>>;
  where?: InputMaybe<Ddn_Build_Sync_Worker_Bool_Exp>;
};

export type Subscription_RootDdn_Build_Sync_Worker_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Build_Sync_Worker_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Build_Sync_Worker_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Build_Sync_Worker_Bool_Exp>;
};

export type Subscription_RootDdn_Collaboration_Invitee_Project_ViewArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Collaboration_Invitee_Project_View_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Collaboration_Invitee_Project_View_Order_By>>;
  where?: InputMaybe<Ddn_Collaboration_Invitee_Project_View_Bool_Exp>;
};

export type Subscription_RootDdn_Collaboration_Invitee_Project_View_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Collaboration_Invitee_Project_View_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Collaboration_Invitee_Project_View_Order_By>>;
  where?: InputMaybe<Ddn_Collaboration_Invitee_Project_View_Bool_Exp>;
};

export type Subscription_RootDdn_Collaboration_Invitee_Project_View_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Ddn_Collaboration_Invitee_Project_View_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Ddn_Collaboration_Invitee_Project_View_Bool_Exp>;
};

export type Subscription_RootDdn_Connector_DeploymentArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Connector_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Connector_Deployment_Order_By>>;
  where?: InputMaybe<Ddn_Connector_Deployment_Bool_Exp>;
};

export type Subscription_RootDdn_Connector_Deployment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Connector_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Connector_Deployment_Order_By>>;
  where?: InputMaybe<Ddn_Connector_Deployment_Bool_Exp>;
};

export type Subscription_RootDdn_Connector_Deployment_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootDdn_Connector_Deployment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Connector_Deployment_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Connector_Deployment_Bool_Exp>;
};

export type Subscription_RootDdn_DdnArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Ddn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Ddn_Order_By>>;
  where?: InputMaybe<Ddn_Ddn_Bool_Exp>;
};

export type Subscription_RootDdn_Ddn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Ddn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Ddn_Order_By>>;
  where?: InputMaybe<Ddn_Ddn_Bool_Exp>;
};

export type Subscription_RootDdn_Ddn_By_PkArgs = {
  kind: Scalars['String'];
};

export type Subscription_RootDdn_Ddn_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Ddn_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Ddn_Bool_Exp>;
};

export type Subscription_RootDdn_EnvironmentArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Environment_Order_By>>;
  where?: InputMaybe<Ddn_Environment_Bool_Exp>;
};

export type Subscription_RootDdn_Environment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Environment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Environment_Order_By>>;
  where?: InputMaybe<Ddn_Environment_Bool_Exp>;
};

export type Subscription_RootDdn_Environment_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Environment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Environment_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Environment_Bool_Exp>;
};

export type Subscription_RootDdn_Generate_Metadata_Artifacts_LogArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Generate_Metadata_Artifacts_Log_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Generate_Metadata_Artifacts_Log_Order_By>>;
  where?: InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp>;
};

export type Subscription_RootDdn_Generate_Metadata_Artifacts_Log_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Generate_Metadata_Artifacts_Log_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Generate_Metadata_Artifacts_Log_Order_By>>;
  where?: InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp>;
};

export type Subscription_RootDdn_Generate_Metadata_Artifacts_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Generate_Metadata_Artifacts_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Ddn_Generate_Metadata_Artifacts_Log_Bool_Exp>;
};

export type Subscription_RootDdn_Get_Token_Access_LevelArgs = {
  args: Ddn_Get_Token_Access_Level_Args;
  distinct_on?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Order_By>>;
  where?: InputMaybe<Ddn_Token_Access_Level_Output_Bool_Exp>;
};

export type Subscription_RootDdn_Get_Token_Access_Level_AggregateArgs = {
  args: Ddn_Get_Token_Access_Level_Args;
  distinct_on?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Order_By>>;
  where?: InputMaybe<Ddn_Token_Access_Level_Output_Bool_Exp>;
};

export type Subscription_RootDdn_InvoiceArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Bool_Exp>;
};

export type Subscription_RootDdn_Invoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Bool_Exp>;
};

export type Subscription_RootDdn_Invoice_By_PkArgs = {
  stripe_invoice_id: Scalars['String'];
};

export type Subscription_RootDdn_Invoice_ItemArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Item_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
};

export type Subscription_RootDdn_Invoice_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Invoice_Item_Order_By>>;
  where?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
};

export type Subscription_RootDdn_Invoice_Item_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Invoice_Item_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Invoice_Item_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Invoice_Item_Bool_Exp>;
};

export type Subscription_RootDdn_Invoice_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Invoice_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Invoice_Bool_Exp>;
};

export type Subscription_RootDdn_Plan_Entitlement_AccessArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Ddn_Plan_Entitlement_Access_Bool_Exp>;
};

export type Subscription_RootDdn_Plan_Entitlement_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Plan_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Ddn_Plan_Entitlement_Access_Bool_Exp>;
};

export type Subscription_RootDdn_Plan_Entitlement_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Plan_Entitlement_Access_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Plan_Entitlement_Access_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Plan_Entitlement_Access_Bool_Exp>;
};

export type Subscription_RootDdn_PlansArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Plans_Order_By>>;
  where?: InputMaybe<Ddn_Plans_Bool_Exp>;
};

export type Subscription_RootDdn_Plans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Plans_Order_By>>;
  where?: InputMaybe<Ddn_Plans_Bool_Exp>;
};

export type Subscription_RootDdn_Plans_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Plans_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Plans_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Plans_Bool_Exp>;
};

export type Subscription_RootDdn_Private_DdnArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Private_Ddn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Private_Ddn_Order_By>>;
  where?: InputMaybe<Ddn_Private_Ddn_Bool_Exp>;
};

export type Subscription_RootDdn_Private_Ddn_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Private_Ddn_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Private_Ddn_Order_By>>;
  where?: InputMaybe<Ddn_Private_Ddn_Bool_Exp>;
};

export type Subscription_RootDdn_Private_Ddn_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Private_Ddn_RegionArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Private_Ddn_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Private_Ddn_Region_Order_By>>;
  where?: InputMaybe<Ddn_Private_Ddn_Region_Bool_Exp>;
};

export type Subscription_RootDdn_Private_Ddn_Region_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Private_Ddn_Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Private_Ddn_Region_Order_By>>;
  where?: InputMaybe<Ddn_Private_Ddn_Region_Bool_Exp>;
};

export type Subscription_RootDdn_Private_Ddn_Region_By_PkArgs = {
  cloud: Scalars['String'];
  private_ddn_id: Scalars['uuid'];
  region: Scalars['String'];
};

export type Subscription_RootDdn_Private_Ddn_Region_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Private_Ddn_Region_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Private_Ddn_Region_Bool_Exp>;
};

export type Subscription_RootDdn_Private_Ddn_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Private_Ddn_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Private_Ddn_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Access_LevelArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Access_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Access_Level_Order_By>>;
  where?: InputMaybe<Ddn_Project_Access_Level_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Access_Level_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Access_Level_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Access_Level_Order_By>>;
  where?: InputMaybe<Ddn_Project_Access_Level_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Access_Level_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootDdn_Project_Access_Level_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Project_Access_Level_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Project_Access_Level_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Active_Status_ChangelogArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Active_Status_Changelog_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Active_Status_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Project_Active_Status_Changelog_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Active_Status_Changelog_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Active_Status_Changelog_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Active_Status_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Project_Active_Status_Changelog_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Active_Status_Changelog_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Project_Active_Status_Changelog_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Ddn_Project_Active_Status_Changelog_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Ddn_Project_Active_Status_Changelog_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Active_Status_ReasonArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Active_Status_Reason_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Active_Status_Reason_Order_By>>;
  where?: InputMaybe<Ddn_Project_Active_Status_Reason_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Active_Status_Reason_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Active_Status_Reason_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Active_Status_Reason_Order_By>>;
  where?: InputMaybe<Ddn_Project_Active_Status_Reason_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Active_Status_Reason_By_PkArgs = {
  reason: Scalars['String'];
};

export type Subscription_RootDdn_Project_Active_Status_Reason_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Ddn_Project_Active_Status_Reason_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Ddn_Project_Active_Status_Reason_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Entitlement_AccessArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Access_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Entitlement_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Access_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Entitlement_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Project_Entitlement_Access_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Project_Entitlement_Access_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Access_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Entitlement_CatalogueArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Entitlement_Catalogue_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Catalogue_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Entitlement_Catalogue_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Ddn_Project_Entitlement_Catalogue_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Catalogue_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Entitlement_Catalogue_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Project_Entitlement_Catalogue_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Ddn_Project_Entitlement_Catalogue_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Ddn_Project_Entitlement_Catalogue_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Entitlement_TypesArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Types_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Entitlement_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Entitlement_Types_Order_By>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Types_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Entitlement_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootDdn_Project_Entitlement_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Project_Entitlement_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Project_Entitlement_Types_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Plan_ChangelogArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Project_Plan_Changelog_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Plan_Changelog_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Plan_Changelog_Order_By>>;
  where?: InputMaybe<Ddn_Project_Plan_Changelog_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Plan_Changelog_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Project_Plan_Changelog_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Project_Plan_Changelog_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Project_Plan_Changelog_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Request_AccessArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Request_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Request_Access_Order_By>>;
  where?: InputMaybe<Ddn_Project_Request_Access_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Request_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Project_Request_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Project_Request_Access_Order_By>>;
  where?: InputMaybe<Ddn_Project_Request_Access_Bool_Exp>;
};

export type Subscription_RootDdn_Project_Request_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Project_Request_Access_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Project_Request_Access_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Project_Request_Access_Bool_Exp>;
};

export type Subscription_RootDdn_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Projects_Order_By>>;
  where?: InputMaybe<Ddn_Projects_Bool_Exp>;
};

export type Subscription_RootDdn_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Projects_Order_By>>;
  where?: InputMaybe<Ddn_Projects_Bool_Exp>;
};

export type Subscription_RootDdn_Projects_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Projects_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Projects_Bool_Exp>;
};

export type Subscription_RootDdn_Search_Tunnel_ClusterArgs = {
  args?: InputMaybe<Ddn_Search_Tunnel_Cluster_Args>;
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Cluster_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Cluster_Bool_Exp>;
};

export type Subscription_RootDdn_Search_Tunnel_Cluster_AggregateArgs = {
  args?: InputMaybe<Ddn_Search_Tunnel_Cluster_Args>;
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Cluster_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Cluster_Bool_Exp>;
};

export type Subscription_RootDdn_Secret_KeyArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Secret_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Secret_Key_Order_By>>;
  where?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
};

export type Subscription_RootDdn_Secret_Key_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Secret_Key_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Secret_Key_Order_By>>;
  where?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
};

export type Subscription_RootDdn_Secret_Key_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Secret_Key_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Secret_Key_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Secret_Key_Bool_Exp>;
};

export type Subscription_RootDdn_Stripe_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Stripe_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Stripe_Subscription_Order_By>>;
  where?: InputMaybe<Ddn_Stripe_Subscription_Bool_Exp>;
};

export type Subscription_RootDdn_Stripe_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Stripe_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Stripe_Subscription_Order_By>>;
  where?: InputMaybe<Ddn_Stripe_Subscription_Bool_Exp>;
};

export type Subscription_RootDdn_Stripe_Subscription_By_PkArgs = {
  customer_id: Scalars['String'];
  subscription_id: Scalars['String'];
};

export type Subscription_RootDdn_Stripe_Subscription_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Stripe_Subscription_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Stripe_Subscription_Bool_Exp>;
};

export type Subscription_RootDdn_Stripe_Webhook_EventsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Stripe_Webhook_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Stripe_Webhook_Events_Order_By>>;
  where?: InputMaybe<Ddn_Stripe_Webhook_Events_Bool_Exp>;
};

export type Subscription_RootDdn_Stripe_Webhook_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Stripe_Webhook_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Stripe_Webhook_Events_Order_By>>;
  where?: InputMaybe<Ddn_Stripe_Webhook_Events_Bool_Exp>;
};

export type Subscription_RootDdn_Stripe_Webhook_Events_By_PkArgs = {
  stripe_event_id: Scalars['String'];
};

export type Subscription_RootDdn_Stripe_Webhook_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Stripe_Webhook_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Stripe_Webhook_Events_Bool_Exp>;
};

export type Subscription_RootDdn_SubgraphArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Subgraph_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Subgraph_Order_By>>;
  where?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
};

export type Subscription_RootDdn_Subgraph_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Subgraph_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Subgraph_Order_By>>;
  where?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
};

export type Subscription_RootDdn_Subgraph_BuildArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Subgraph_Build_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Subgraph_Build_Order_By>>;
  where?: InputMaybe<Ddn_Subgraph_Build_Bool_Exp>;
};

export type Subscription_RootDdn_Subgraph_Build_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Subgraph_Build_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Subgraph_Build_Order_By>>;
  where?: InputMaybe<Ddn_Subgraph_Build_Bool_Exp>;
};

export type Subscription_RootDdn_Subgraph_Build_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Subgraph_Build_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Subgraph_Build_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Subgraph_Build_Bool_Exp>;
};

export type Subscription_RootDdn_Subgraph_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Subgraph_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Subgraph_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Subgraph_Bool_Exp>;
};

export type Subscription_RootDdn_TeamArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Order_By>>;
  where?: InputMaybe<Ddn_Team_Bool_Exp>;
};

export type Subscription_RootDdn_Team_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Order_By>>;
  where?: InputMaybe<Ddn_Team_Bool_Exp>;
};

export type Subscription_RootDdn_Team_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Team_InvitationArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Invitation_Order_By>>;
  where?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
};

export type Subscription_RootDdn_Team_Invitation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Invitation_Order_By>>;
  where?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
};

export type Subscription_RootDdn_Team_Invitation_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Team_Invitation_StatusArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Invitation_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Invitation_Status_Order_By>>;
  where?: InputMaybe<Ddn_Team_Invitation_Status_Bool_Exp>;
};

export type Subscription_RootDdn_Team_Invitation_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Invitation_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Invitation_Status_Order_By>>;
  where?: InputMaybe<Ddn_Team_Invitation_Status_Bool_Exp>;
};

export type Subscription_RootDdn_Team_Invitation_Status_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootDdn_Team_Invitation_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Team_Invitation_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Team_Invitation_Status_Bool_Exp>;
};

export type Subscription_RootDdn_Team_Invitation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Team_Invitation_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
};

export type Subscription_RootDdn_Team_KindArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Kind_Order_By>>;
  where?: InputMaybe<Ddn_Team_Kind_Bool_Exp>;
};

export type Subscription_RootDdn_Team_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Kind_Order_By>>;
  where?: InputMaybe<Ddn_Team_Kind_Bool_Exp>;
};

export type Subscription_RootDdn_Team_Kind_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootDdn_Team_Kind_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Team_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Team_Kind_Bool_Exp>;
};

export type Subscription_RootDdn_Team_MembershipArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Membership_Order_By>>;
  where?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
};

export type Subscription_RootDdn_Team_Membership_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Membership_Order_By>>;
  where?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
};

export type Subscription_RootDdn_Team_Membership_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Team_Membership_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Team_Membership_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
};

export type Subscription_RootDdn_Team_Project_AccessArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
};

export type Subscription_RootDdn_Team_Project_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
};

export type Subscription_RootDdn_Team_Project_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Team_Project_Access_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Team_Project_Access_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Team_Project_Access_Bool_Exp>;
};

export type Subscription_RootDdn_Team_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Team_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Team_Bool_Exp>;
};

export type Subscription_RootDdn_Token_Access_Level_OutputArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Order_By>>;
  where?: InputMaybe<Ddn_Token_Access_Level_Output_Bool_Exp>;
};

export type Subscription_RootDdn_Token_Access_Level_Output_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Token_Access_Level_Output_Order_By>>;
  where?: InputMaybe<Ddn_Token_Access_Level_Output_Bool_Exp>;
};

export type Subscription_RootDdn_Token_Access_Level_Output_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Token_Access_Level_Output_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Token_Access_Level_Output_Bool_Exp>;
};

export type Subscription_RootDdn_TunnelArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Bool_Exp>;
};

export type Subscription_RootDdn_Tunnel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Bool_Exp>;
};

export type Subscription_RootDdn_Tunnel_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Tunnel_ClusterArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Cluster_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Cluster_Bool_Exp>;
};

export type Subscription_RootDdn_Tunnel_Cluster_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Cluster_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Cluster_Bool_Exp>;
};

export type Subscription_RootDdn_Tunnel_Cluster_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Tunnel_Cluster_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Tunnel_Cluster_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Tunnel_Cluster_Bool_Exp>;
};

export type Subscription_RootDdn_Tunnel_HttpArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Http_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Http_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Http_Bool_Exp>;
};

export type Subscription_RootDdn_Tunnel_Http_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Http_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Http_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Http_Bool_Exp>;
};

export type Subscription_RootDdn_Tunnel_Http_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Tunnel_Http_ClusterArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Http_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Http_Cluster_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Http_Cluster_Bool_Exp>;
};

export type Subscription_RootDdn_Tunnel_Http_Cluster_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Tunnel_Http_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Tunnel_Http_Cluster_Order_By>>;
  where?: InputMaybe<Ddn_Tunnel_Http_Cluster_Bool_Exp>;
};

export type Subscription_RootDdn_Tunnel_Http_Cluster_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDdn_Tunnel_Http_Cluster_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Tunnel_Http_Cluster_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Tunnel_Http_Cluster_Bool_Exp>;
};

export type Subscription_RootDdn_Tunnel_Http_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Tunnel_Http_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Tunnel_Http_Bool_Exp>;
};

export type Subscription_RootDdn_Tunnel_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_Tunnel_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_Tunnel_Bool_Exp>;
};

export type Subscription_RootDdn_User_Project_AccessArgs = {
  distinct_on?: InputMaybe<Array<Ddn_User_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_User_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
};

export type Subscription_RootDdn_User_Project_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_User_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_User_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
};

export type Subscription_RootDdn_User_Project_Access_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_User_Project_Access_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
};

export type Subscription_RootDdn_V3_CustomerArgs = {
  distinct_on?: InputMaybe<Array<Ddn_V3_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_V3_Customer_Order_By>>;
  where?: InputMaybe<Ddn_V3_Customer_Bool_Exp>;
};

export type Subscription_RootDdn_V3_Customer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_V3_Customer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_V3_Customer_Order_By>>;
  where?: InputMaybe<Ddn_V3_Customer_Bool_Exp>;
};

export type Subscription_RootDdn_V3_Customer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ddn_V3_Customer_Stream_Cursor_Input>>;
  where?: InputMaybe<Ddn_V3_Customer_Bool_Exp>;
};

export type Subscription_RootDedicated_Cloud_BillsArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
};

export type Subscription_RootDedicated_Cloud_Bills_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
};

export type Subscription_RootDedicated_Cloud_Bills_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDedicated_Cloud_Bills_DetailsArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Details_Bool_Exp>;
};

export type Subscription_RootDedicated_Cloud_Bills_Details_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Bills_Details_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Details_Bool_Exp>;
};

export type Subscription_RootDedicated_Cloud_Bills_Details_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDedicated_Cloud_Bills_Details_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dedicated_Cloud_Bills_Details_Stream_Cursor_Input>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Details_Bool_Exp>;
};

export type Subscription_RootDedicated_Cloud_Bills_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dedicated_Cloud_Bills_Stream_Cursor_Input>>;
  where?: InputMaybe<Dedicated_Cloud_Bills_Bool_Exp>;
};

export type Subscription_RootDedicated_Cloud_CommitmentsArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Commitments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Commitments_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Commitments_Bool_Exp>;
};

export type Subscription_RootDedicated_Cloud_Commitments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Cloud_Commitments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Cloud_Commitments_Order_By>>;
  where?: InputMaybe<Dedicated_Cloud_Commitments_Bool_Exp>;
};

export type Subscription_RootDedicated_Cloud_Commitments_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDedicated_Cloud_Commitments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dedicated_Cloud_Commitments_Stream_Cursor_Input>>;
  where?: InputMaybe<Dedicated_Cloud_Commitments_Bool_Exp>;
};

export type Subscription_RootDedicated_VpcArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Vpc_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Vpc_Order_By>>;
  where?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
};

export type Subscription_RootDedicated_Vpc_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Vpc_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Vpc_Order_By>>;
  where?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
};

export type Subscription_RootDedicated_Vpc_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootDedicated_Vpc_OwnerArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Vpc_Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Vpc_Owner_Order_By>>;
  where?: InputMaybe<Dedicated_Vpc_Owner_Bool_Exp>;
};

export type Subscription_RootDedicated_Vpc_Owner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Dedicated_Vpc_Owner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Dedicated_Vpc_Owner_Order_By>>;
  where?: InputMaybe<Dedicated_Vpc_Owner_Bool_Exp>;
};

export type Subscription_RootDedicated_Vpc_Owner_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dedicated_Vpc_Owner_Stream_Cursor_Input>>;
  where?: InputMaybe<Dedicated_Vpc_Owner_Bool_Exp>;
};

export type Subscription_RootDedicated_Vpc_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Dedicated_Vpc_Stream_Cursor_Input>>;
  where?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
};

export type Subscription_RootDelete_UserArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Order_By>>;
  where?: InputMaybe<Delete_User_Bool_Exp>;
};

export type Subscription_RootDelete_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Order_By>>;
  where?: InputMaybe<Delete_User_Bool_Exp>;
};

export type Subscription_RootDelete_User_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Subscription_RootDelete_User_StatusArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Status_Order_By>>;
  where?: InputMaybe<Delete_User_Status_Bool_Exp>;
};

export type Subscription_RootDelete_User_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Status_Order_By>>;
  where?: InputMaybe<Delete_User_Status_Bool_Exp>;
};

export type Subscription_RootDelete_User_Status_By_PkArgs = {
  status: Scalars['String'];
};

export type Subscription_RootDelete_User_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Delete_User_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Delete_User_Status_Bool_Exp>;
};

export type Subscription_RootDelete_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Delete_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Delete_User_Bool_Exp>;
};

export type Subscription_RootDelete_User_TasksArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Tasks_Order_By>>;
  where?: InputMaybe<Delete_User_Tasks_Bool_Exp>;
};

export type Subscription_RootDelete_User_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Delete_User_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Delete_User_Tasks_Order_By>>;
  where?: InputMaybe<Delete_User_Tasks_Bool_Exp>;
};

export type Subscription_RootDelete_User_Tasks_By_PkArgs = {
  task_name: Scalars['String'];
  user_id: Scalars['uuid'];
};

export type Subscription_RootDelete_User_Tasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Delete_User_Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Delete_User_Tasks_Bool_Exp>;
};

export type Subscription_RootEmail_LogArgs = {
  distinct_on?: InputMaybe<Array<Email_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Log_Order_By>>;
  where?: InputMaybe<Email_Log_Bool_Exp>;
};

export type Subscription_RootEmail_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Email_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Email_Log_Order_By>>;
  where?: InputMaybe<Email_Log_Bool_Exp>;
};

export type Subscription_RootEmail_Log_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootEmail_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Email_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<Email_Log_Bool_Exp>;
};

export type Subscription_RootEnterprise_UsersArgs = {
  distinct_on?: InputMaybe<Array<Enterprise_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enterprise_Users_Order_By>>;
  where?: InputMaybe<Enterprise_Users_Bool_Exp>;
};

export type Subscription_RootEnterprise_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enterprise_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enterprise_Users_Order_By>>;
  where?: InputMaybe<Enterprise_Users_Bool_Exp>;
};

export type Subscription_RootEnterprise_Users_By_PkArgs = {
  customer_email: Scalars['citext'];
};

export type Subscription_RootEnterprise_Users_DomainArgs = {
  distinct_on?: InputMaybe<Array<Enterprise_Users_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enterprise_Users_Domain_Order_By>>;
  where?: InputMaybe<Enterprise_Users_Domain_Bool_Exp>;
};

export type Subscription_RootEnterprise_Users_Domain_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Enterprise_Users_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Enterprise_Users_Domain_Order_By>>;
  where?: InputMaybe<Enterprise_Users_Domain_Bool_Exp>;
};

export type Subscription_RootEnterprise_Users_Domain_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootEnterprise_Users_Domain_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enterprise_Users_Domain_Stream_Cursor_Input>>;
  where?: InputMaybe<Enterprise_Users_Domain_Bool_Exp>;
};

export type Subscription_RootEnterprise_Users_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Enterprise_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Enterprise_Users_Bool_Exp>;
};

export type Subscription_RootExperimentsArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Order_By>>;
  where?: InputMaybe<Experiments_Bool_Exp>;
};

export type Subscription_RootExperiments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Order_By>>;
  where?: InputMaybe<Experiments_Bool_Exp>;
};

export type Subscription_RootExperiments_By_PkArgs = {
  experiment: Scalars['String'];
};

export type Subscription_RootExperiments_CohortArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Cohort_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Cohort_Order_By>>;
  where?: InputMaybe<Experiments_Cohort_Bool_Exp>;
};

export type Subscription_RootExperiments_Cohort_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Cohort_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Cohort_Order_By>>;
  where?: InputMaybe<Experiments_Cohort_Bool_Exp>;
};

export type Subscription_RootExperiments_Cohort_By_PkArgs = {
  experiment: Experiments_Enum;
  user_id: Scalars['uuid'];
};

export type Subscription_RootExperiments_Cohort_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Experiments_Cohort_Stream_Cursor_Input>>;
  where?: InputMaybe<Experiments_Cohort_Bool_Exp>;
};

export type Subscription_RootExperiments_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Config_Order_By>>;
  where?: InputMaybe<Experiments_Config_Bool_Exp>;
};

export type Subscription_RootExperiments_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Experiments_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Experiments_Config_Order_By>>;
  where?: InputMaybe<Experiments_Config_Bool_Exp>;
};

export type Subscription_RootExperiments_Config_By_PkArgs = {
  experiment: Experiments_Enum;
};

export type Subscription_RootExperiments_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Experiments_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Experiments_Config_Bool_Exp>;
};

export type Subscription_RootExperiments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Experiments_Stream_Cursor_Input>>;
  where?: InputMaybe<Experiments_Bool_Exp>;
};

export type Subscription_RootFeatureArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Subscription_RootFeature_AccessArgs = {
  distinct_on?: InputMaybe<Array<Feature_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Access_Order_By>>;
  where?: InputMaybe<Feature_Access_Bool_Exp>;
};

export type Subscription_RootFeature_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Access_Order_By>>;
  where?: InputMaybe<Feature_Access_Bool_Exp>;
};

export type Subscription_RootFeature_Access_By_PkArgs = {
  email: Scalars['String'];
  feature: Feature_Enum;
};

export type Subscription_RootFeature_Access_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Feature_Access_Stream_Cursor_Input>>;
  where?: InputMaybe<Feature_Access_Bool_Exp>;
};

export type Subscription_RootFeature_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Order_By>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Subscription_RootFeature_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootFeature_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Feature_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Config_Order_By>>;
  where?: InputMaybe<Feature_Config_Bool_Exp>;
};

export type Subscription_RootFeature_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Config_Order_By>>;
  where?: InputMaybe<Feature_Config_Bool_Exp>;
};

export type Subscription_RootFeature_Config_By_PkArgs = {
  feature: Feature_Enum;
};

export type Subscription_RootFeature_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Feature_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Feature_Config_Bool_Exp>;
};

export type Subscription_RootFeature_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Feature_Stream_Cursor_Input>>;
  where?: InputMaybe<Feature_Bool_Exp>;
};

export type Subscription_RootGateway_AmiArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Ami_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Ami_Order_By>>;
  where?: InputMaybe<Gateway_Ami_Bool_Exp>;
};

export type Subscription_RootGateway_Ami_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Ami_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Ami_Order_By>>;
  where?: InputMaybe<Gateway_Ami_Bool_Exp>;
};

export type Subscription_RootGateway_Ami_By_PkArgs = {
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  region: Scalars['String'];
};

export type Subscription_RootGateway_Ami_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gateway_Ami_Stream_Cursor_Input>>;
  where?: InputMaybe<Gateway_Ami_Bool_Exp>;
};

export type Subscription_RootGateway_ClusterArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Cluster_Order_By>>;
  where?: InputMaybe<Gateway_Cluster_Bool_Exp>;
};

export type Subscription_RootGateway_Cluster_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Cluster_Order_By>>;
  where?: InputMaybe<Gateway_Cluster_Bool_Exp>;
};

export type Subscription_RootGateway_Cluster_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGateway_Cluster_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gateway_Cluster_Stream_Cursor_Input>>;
  where?: InputMaybe<Gateway_Cluster_Bool_Exp>;
};

export type Subscription_RootGateway_Cluster_TenantsArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Cluster_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Cluster_Tenants_Order_By>>;
  where?: InputMaybe<Gateway_Cluster_Tenants_Bool_Exp>;
};

export type Subscription_RootGateway_Cluster_Tenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Cluster_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Cluster_Tenants_Order_By>>;
  where?: InputMaybe<Gateway_Cluster_Tenants_Bool_Exp>;
};

export type Subscription_RootGateway_Cluster_Tenants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gateway_Cluster_Tenants_Stream_Cursor_Input>>;
  where?: InputMaybe<Gateway_Cluster_Tenants_Bool_Exp>;
};

export type Subscription_RootGateway_WorkerArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Worker_Order_By>>;
  where?: InputMaybe<Gateway_Worker_Bool_Exp>;
};

export type Subscription_RootGateway_Worker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Gateway_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Gateway_Worker_Order_By>>;
  where?: InputMaybe<Gateway_Worker_Bool_Exp>;
};

export type Subscription_RootGateway_Worker_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGateway_Worker_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Gateway_Worker_Stream_Cursor_Input>>;
  where?: InputMaybe<Gateway_Worker_Bool_Exp>;
};

export type Subscription_RootGet_Aggregated_Cost_For_Project_On_Shared_PlanArgs = {
  args: Get_Aggregated_Cost_For_Project_On_Shared_Plan_Args;
  distinct_on?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Total_Db_Usage_Agg_Bool_Exp>;
};

export type Subscription_RootGet_Aggregated_Cost_For_Project_On_Shared_Plan_AggregateArgs = {
  args: Get_Aggregated_Cost_For_Project_On_Shared_Plan_Args;
  distinct_on?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Total_Db_Usage_Agg_Bool_Exp>;
};

export type Subscription_RootGithub_Email_TypeArgs = {
  distinct_on?: InputMaybe<Array<Github_Email_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Email_Type_Order_By>>;
  where?: InputMaybe<Github_Email_Type_Bool_Exp>;
};

export type Subscription_RootGithub_Email_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Email_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Email_Type_Order_By>>;
  where?: InputMaybe<Github_Email_Type_Bool_Exp>;
};

export type Subscription_RootGithub_Email_Type_By_PkArgs = {
  email_type: Scalars['String'];
};

export type Subscription_RootGithub_Email_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Github_Email_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Github_Email_Type_Bool_Exp>;
};

export type Subscription_RootGithub_Integration_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Github_Integration_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Integration_Config_Order_By>>;
  where?: InputMaybe<Github_Integration_Config_Bool_Exp>;
};

export type Subscription_RootGithub_Integration_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Integration_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Integration_Config_Order_By>>;
  where?: InputMaybe<Github_Integration_Config_Bool_Exp>;
};

export type Subscription_RootGithub_Integration_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGithub_Integration_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Github_Integration_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Github_Integration_Config_Bool_Exp>;
};

export type Subscription_RootGithub_Integration_ModeArgs = {
  distinct_on?: InputMaybe<Array<Github_Integration_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Integration_Mode_Order_By>>;
  where?: InputMaybe<Github_Integration_Mode_Bool_Exp>;
};

export type Subscription_RootGithub_Integration_Mode_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Integration_Mode_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Integration_Mode_Order_By>>;
  where?: InputMaybe<Github_Integration_Mode_Bool_Exp>;
};

export type Subscription_RootGithub_Integration_Mode_By_PkArgs = {
  mode: Scalars['String'];
};

export type Subscription_RootGithub_Integration_Mode_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Github_Integration_Mode_Stream_Cursor_Input>>;
  where?: InputMaybe<Github_Integration_Mode_Bool_Exp>;
};

export type Subscription_RootGithub_Push_EventArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Bool_Exp>;
};

export type Subscription_RootGithub_Push_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Bool_Exp>;
};

export type Subscription_RootGithub_Push_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGithub_Push_Event_JobArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Job_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
};

export type Subscription_RootGithub_Push_Event_Job_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Github_Push_Event_Job_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Github_Push_Event_Job_Order_By>>;
  where?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
};

export type Subscription_RootGithub_Push_Event_Job_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootGithub_Push_Event_Job_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Github_Push_Event_Job_Stream_Cursor_Input>>;
  where?: InputMaybe<Github_Push_Event_Job_Bool_Exp>;
};

export type Subscription_RootGithub_Push_Event_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Github_Push_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Github_Push_Event_Bool_Exp>;
};

export type Subscription_RootHasura_AmiArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Ami_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Ami_Order_By>>;
  where?: InputMaybe<Hasura_Ami_Bool_Exp>;
};

export type Subscription_RootHasura_Ami_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Ami_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Ami_Order_By>>;
  where?: InputMaybe<Hasura_Ami_Bool_Exp>;
};

export type Subscription_RootHasura_Ami_By_PkArgs = {
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  region: Scalars['String'];
};

export type Subscription_RootHasura_Ami_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hasura_Ami_Stream_Cursor_Input>>;
  where?: InputMaybe<Hasura_Ami_Bool_Exp>;
};

export type Subscription_RootHasura_ClusterArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Cluster_Order_By>>;
  where?: InputMaybe<Hasura_Cluster_Bool_Exp>;
};

export type Subscription_RootHasura_Cluster_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Cluster_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Cluster_Order_By>>;
  where?: InputMaybe<Hasura_Cluster_Bool_Exp>;
};

export type Subscription_RootHasura_Cluster_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHasura_Cluster_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hasura_Cluster_Stream_Cursor_Input>>;
  where?: InputMaybe<Hasura_Cluster_Bool_Exp>;
};

export type Subscription_RootHasura_WorkerArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Worker_Order_By>>;
  where?: InputMaybe<Hasura_Worker_Bool_Exp>;
};

export type Subscription_RootHasura_Worker_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Worker_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Worker_Order_By>>;
  where?: InputMaybe<Hasura_Worker_Bool_Exp>;
};

export type Subscription_RootHasura_Worker_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHasura_Worker_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hasura_Worker_Stream_Cursor_Input>>;
  where?: InputMaybe<Hasura_Worker_Bool_Exp>;
};

export type Subscription_RootHasura_Worker_TenantsArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Worker_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Worker_Tenants_Order_By>>;
  where?: InputMaybe<Hasura_Worker_Tenants_Bool_Exp>;
};

export type Subscription_RootHasura_Worker_Tenants_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Worker_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Worker_Tenants_Order_By>>;
  where?: InputMaybe<Hasura_Worker_Tenants_Bool_Exp>;
};

export type Subscription_RootHasura_Worker_Tenants_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hasura_Worker_Tenants_Stream_Cursor_Input>>;
  where?: InputMaybe<Hasura_Worker_Tenants_Bool_Exp>;
};

export type Subscription_RootHasura_Year_In_Report_ActivityArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Year_In_Report_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Year_In_Report_Activity_Order_By>>;
  where?: InputMaybe<Hasura_Year_In_Report_Activity_Bool_Exp>;
};

export type Subscription_RootHasura_Year_In_Report_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Year_In_Report_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Year_In_Report_Activity_Order_By>>;
  where?: InputMaybe<Hasura_Year_In_Report_Activity_Bool_Exp>;
};

export type Subscription_RootHasura_Year_In_Report_Activity_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Subscription_RootHasura_Year_In_Report_Activity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hasura_Year_In_Report_Activity_Stream_Cursor_Input>>;
  where?: InputMaybe<Hasura_Year_In_Report_Activity_Bool_Exp>;
};

export type Subscription_RootHasura_Year_In_Report_MetricsArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Year_In_Report_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Year_In_Report_Metrics_Order_By>>;
  where?: InputMaybe<Hasura_Year_In_Report_Metrics_Bool_Exp>;
};

export type Subscription_RootHasura_Year_In_Report_Metrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Year_In_Report_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Year_In_Report_Metrics_Order_By>>;
  where?: InputMaybe<Hasura_Year_In_Report_Metrics_Bool_Exp>;
};

export type Subscription_RootHasura_Year_In_Report_Metrics_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootHasura_Year_In_Report_Metrics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Hasura_Year_In_Report_Metrics_Stream_Cursor_Input>>;
  where?: InputMaybe<Hasura_Year_In_Report_Metrics_Bool_Exp>;
};

export type Subscription_RootHeroku_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Heroku_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Heroku_Integrations_Order_By>>;
  where?: InputMaybe<Heroku_Integrations_Bool_Exp>;
};

export type Subscription_RootHeroku_Integrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Heroku_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Heroku_Integrations_Order_By>>;
  where?: InputMaybe<Heroku_Integrations_Bool_Exp>;
};

export type Subscription_RootHeroku_Integrations_By_PkArgs = {
  project_id: Scalars['uuid'];
  var_name: Scalars['String'];
};

export type Subscription_RootHeroku_Integrations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Heroku_Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Heroku_Integrations_Bool_Exp>;
};

export type Subscription_RootHit_Count_MonthlyArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hit_Count_Monthly_Order_By>>;
  where?: InputMaybe<Hit_Count_Monthly_Bool_Exp>;
};

export type Subscription_RootHit_Count_Monthly_AggregateArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hit_Count_Monthly_Order_By>>;
  where?: InputMaybe<Hit_Count_Monthly_Bool_Exp>;
};

export type Subscription_RootHit_Count_Monthly_By_PkArgs = {
  entity_name: Scalars['string'];
  entity_type: Scalars['string'];
  environment_id: Scalars['UUID'];
  month: Scalars['u_int8'];
  project_id: Scalars['UUID'];
  subgraph: Scalars['string'];
  year: Scalars['u_int16'];
};

export type Subscription_RootHit_Count_Monthly_MvArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hit_Count_Monthly_Mv_Order_By>>;
  where?: InputMaybe<Hit_Count_Monthly_Mv_Bool_Exp>;
};

export type Subscription_RootHit_Count_Monthly_Mv_AggregateArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hit_Count_Monthly_Mv_Order_By>>;
  where?: InputMaybe<Hit_Count_Monthly_Mv_Bool_Exp>;
};

export type Subscription_RootHit_Count_Per_DayArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hit_Count_Per_Day_Order_By>>;
  where?: InputMaybe<Hit_Count_Per_Day_Bool_Exp>;
};

export type Subscription_RootHit_Count_Per_Day_AggregateArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hit_Count_Per_Day_Order_By>>;
  where?: InputMaybe<Hit_Count_Per_Day_Bool_Exp>;
};

export type Subscription_RootHit_Count_Per_Day_By_PkArgs = {
  date: Scalars['date'];
  entity_name: Scalars['string'];
  entity_type: Scalars['string'];
  environment_id: Scalars['UUID'];
  project_id: Scalars['UUID'];
  subgraph: Scalars['string'];
};

export type Subscription_RootInactive_Project_ExclusionsArgs = {
  distinct_on?: InputMaybe<Array<Inactive_Project_Exclusions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Project_Exclusions_Order_By>>;
  where?: InputMaybe<Inactive_Project_Exclusions_Bool_Exp>;
};

export type Subscription_RootInactive_Project_Exclusions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inactive_Project_Exclusions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Project_Exclusions_Order_By>>;
  where?: InputMaybe<Inactive_Project_Exclusions_Bool_Exp>;
};

export type Subscription_RootInactive_Project_Exclusions_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Subscription_RootInactive_Project_Exclusions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Inactive_Project_Exclusions_Stream_Cursor_Input>>;
  where?: InputMaybe<Inactive_Project_Exclusions_Bool_Exp>;
};

export type Subscription_RootInactive_Project_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Inactive_Project_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Project_Notifications_Order_By>>;
  where?: InputMaybe<Inactive_Project_Notifications_Bool_Exp>;
};

export type Subscription_RootInactive_Project_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inactive_Project_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Project_Notifications_Order_By>>;
  where?: InputMaybe<Inactive_Project_Notifications_Bool_Exp>;
};

export type Subscription_RootInactive_Project_Notifications_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootInactive_Project_Notifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Inactive_Project_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Inactive_Project_Notifications_Bool_Exp>;
};

export type Subscription_RootInactive_Project_Suspension_ConfigArgs = {
  distinct_on?: InputMaybe<
    Array<Inactive_Project_Suspension_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Project_Suspension_Config_Order_By>>;
  where?: InputMaybe<Inactive_Project_Suspension_Config_Bool_Exp>;
};

export type Subscription_RootInactive_Project_Suspension_Config_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Inactive_Project_Suspension_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Project_Suspension_Config_Order_By>>;
  where?: InputMaybe<Inactive_Project_Suspension_Config_Bool_Exp>;
};

export type Subscription_RootInactive_Project_Suspension_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Inactive_Project_Suspension_Config_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Inactive_Project_Suspension_Config_Bool_Exp>;
};

export type Subscription_RootInactive_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Inactive_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Projects_Order_By>>;
  where?: InputMaybe<Inactive_Projects_Bool_Exp>;
};

export type Subscription_RootInactive_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Inactive_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Inactive_Projects_Order_By>>;
  where?: InputMaybe<Inactive_Projects_Bool_Exp>;
};

export type Subscription_RootInactive_Projects_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Inactive_Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Inactive_Projects_Bool_Exp>;
};

export type Subscription_RootInfra_StatusArgs = {
  distinct_on?: InputMaybe<Array<Infra_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Infra_Status_Order_By>>;
  where?: InputMaybe<Infra_Status_Bool_Exp>;
};

export type Subscription_RootInfra_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Infra_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Infra_Status_Order_By>>;
  where?: InputMaybe<Infra_Status_Bool_Exp>;
};

export type Subscription_RootInfra_Status_By_PkArgs = {
  status: Scalars['String'];
};

export type Subscription_RootInfra_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Infra_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Infra_Status_Bool_Exp>;
};

export type Subscription_RootInvoiceArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

export type Subscription_RootInvoice_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

export type Subscription_RootInvoice_By_PkArgs = {
  stripe_invoice_id: Scalars['String'];
};

export type Subscription_RootInvoice_Coupon_DiscountArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Coupon_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Coupon_Discount_Order_By>>;
  where?: InputMaybe<Invoice_Coupon_Discount_Bool_Exp>;
};

export type Subscription_RootInvoice_Coupon_Discount_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Coupon_Discount_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Coupon_Discount_Order_By>>;
  where?: InputMaybe<Invoice_Coupon_Discount_Bool_Exp>;
};

export type Subscription_RootInvoice_Coupon_Discount_By_PkArgs = {
  inv_id: Scalars['String'];
};

export type Subscription_RootInvoice_Coupon_Discount_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Invoice_Coupon_Discount_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoice_Coupon_Discount_Bool_Exp>;
};

export type Subscription_RootInvoice_ItemArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Item_Order_By>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};

export type Subscription_RootInvoice_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Item_Order_By>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};

export type Subscription_RootInvoice_Item_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootInvoice_Item_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Invoice_Item_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoice_Item_Bool_Exp>;
};

export type Subscription_RootInvoice_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Invoice_Stream_Cursor_Input>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

export type Subscription_RootJobsArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

export type Subscription_RootJobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Jobs_Order_By>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

export type Subscription_RootJobs_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootJobs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Jobs_Stream_Cursor_Input>>;
  where?: InputMaybe<Jobs_Bool_Exp>;
};

export type Subscription_RootLabelArgs = {
  distinct_on?: InputMaybe<Array<Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Label_Order_By>>;
  where?: InputMaybe<Label_Bool_Exp>;
};

export type Subscription_RootLabel_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Label_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Label_Order_By>>;
  where?: InputMaybe<Label_Bool_Exp>;
};

export type Subscription_RootLabel_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLabel_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Label_Stream_Cursor_Input>>;
  where?: InputMaybe<Label_Bool_Exp>;
};

export type Subscription_RootLetsencrypt_StatusArgs = {
  distinct_on?: InputMaybe<Array<Letsencrypt_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Letsencrypt_Status_Order_By>>;
  where?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
};

export type Subscription_RootLetsencrypt_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Letsencrypt_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Letsencrypt_Status_Order_By>>;
  where?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
};

export type Subscription_RootLetsencrypt_Status_By_PkArgs = {
  fqdn: Scalars['String'];
};

export type Subscription_RootLetsencrypt_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Letsencrypt_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Letsencrypt_Status_Bool_Exp>;
};

export type Subscription_RootLicense_ActivityArgs = {
  distinct_on?: InputMaybe<Array<License_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<License_Activity_Order_By>>;
  where?: InputMaybe<License_Activity_Bool_Exp>;
};

export type Subscription_RootLicense_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<License_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<License_Activity_Order_By>>;
  where?: InputMaybe<License_Activity_Bool_Exp>;
};

export type Subscription_RootLicense_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootLicense_Activity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<License_Activity_Stream_Cursor_Input>>;
  where?: InputMaybe<License_Activity_Bool_Exp>;
};

export type Subscription_RootLicense_InstanceArgs = {
  distinct_on?: InputMaybe<Array<License_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<License_Instance_Order_By>>;
  where?: InputMaybe<License_Instance_Bool_Exp>;
};

export type Subscription_RootLicense_Instance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<License_Instance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<License_Instance_Order_By>>;
  where?: InputMaybe<License_Instance_Bool_Exp>;
};

export type Subscription_RootLicense_Instance_By_PkArgs = {
  metadata_db_id: Scalars['uuid'];
};

export type Subscription_RootLicense_Instance_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<License_Instance_Stream_Cursor_Input>>;
  where?: InputMaybe<License_Instance_Bool_Exp>;
};

export type Subscription_RootLicense_TypeArgs = {
  distinct_on?: InputMaybe<Array<License_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<License_Type_Order_By>>;
  where?: InputMaybe<License_Type_Bool_Exp>;
};

export type Subscription_RootLicense_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<License_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<License_Type_Order_By>>;
  where?: InputMaybe<License_Type_Bool_Exp>;
};

export type Subscription_RootLicense_Type_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootLicense_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<License_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<License_Type_Bool_Exp>;
};

export type Subscription_RootLicensesArgs = {
  distinct_on?: InputMaybe<Array<Licenses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Licenses_Order_By>>;
  where?: InputMaybe<Licenses_Bool_Exp>;
};

export type Subscription_RootLicenses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Licenses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Licenses_Order_By>>;
  where?: InputMaybe<Licenses_Bool_Exp>;
};

export type Subscription_RootLicenses_By_PkArgs = {
  email: Scalars['String'];
};

export type Subscription_RootLicenses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Licenses_Stream_Cursor_Input>>;
  where?: InputMaybe<Licenses_Bool_Exp>;
};

export type Subscription_RootLux_ProxyArgs = {
  distinct_on?: InputMaybe<Array<Lux_Proxy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lux_Proxy_Order_By>>;
  where?: InputMaybe<Lux_Proxy_Bool_Exp>;
};

export type Subscription_RootLux_Proxy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lux_Proxy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lux_Proxy_Order_By>>;
  where?: InputMaybe<Lux_Proxy_Bool_Exp>;
};

export type Subscription_RootLux_Proxy_AmiArgs = {
  distinct_on?: InputMaybe<Array<Lux_Proxy_Ami_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lux_Proxy_Ami_Order_By>>;
  where?: InputMaybe<Lux_Proxy_Ami_Bool_Exp>;
};

export type Subscription_RootLux_Proxy_Ami_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Lux_Proxy_Ami_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Lux_Proxy_Ami_Order_By>>;
  where?: InputMaybe<Lux_Proxy_Ami_Bool_Exp>;
};

export type Subscription_RootLux_Proxy_Ami_By_PkArgs = {
  ami_id: Scalars['String'];
  cloud: Scalars['String'];
  region: Scalars['String'];
};

export type Subscription_RootLux_Proxy_Ami_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lux_Proxy_Ami_Stream_Cursor_Input>>;
  where?: InputMaybe<Lux_Proxy_Ami_Bool_Exp>;
};

export type Subscription_RootLux_Proxy_By_PkArgs = {
  cloud: Scalars['String'];
  region: Scalars['String'];
};

export type Subscription_RootLux_Proxy_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Lux_Proxy_Stream_Cursor_Input>>;
  where?: InputMaybe<Lux_Proxy_Bool_Exp>;
};

export type Subscription_RootModel_Based_Enterprise_Cloud_CommitmentsArgs = {
  distinct_on?: InputMaybe<
    Array<Model_Based_Enterprise_Cloud_Commitments_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Model_Based_Enterprise_Cloud_Commitments_Order_By>
  >;
  where?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Bool_Exp>;
};

export type Subscription_RootModel_Based_Enterprise_Cloud_Commitments_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Model_Based_Enterprise_Cloud_Commitments_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Model_Based_Enterprise_Cloud_Commitments_Order_By>
  >;
  where?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Bool_Exp>;
};

export type Subscription_RootModel_Based_Enterprise_Cloud_Commitments_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootModel_Based_Enterprise_Cloud_Commitments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Model_Based_Enterprise_Cloud_Commitments_Bool_Exp>;
};

export type Subscription_RootModel_Based_Pricing_Model_Lifecycle_ViewArgs = {
  distinct_on?: InputMaybe<
    Array<Model_Based_Pricing_Model_Lifecycle_View_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Model_Based_Pricing_Model_Lifecycle_View_Order_By>
  >;
  where?: InputMaybe<Model_Based_Pricing_Model_Lifecycle_View_Bool_Exp>;
};

export type Subscription_RootModel_Based_Pricing_Model_Lifecycle_View_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Model_Based_Pricing_Model_Lifecycle_View_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Model_Based_Pricing_Model_Lifecycle_View_Order_By>
  >;
  where?: InputMaybe<Model_Based_Pricing_Model_Lifecycle_View_Bool_Exp>;
};

export type Subscription_RootModel_Based_Pricing_Model_Snapshot_StatisticsArgs = {
  distinct_on?: InputMaybe<
    Array<Model_Based_Pricing_Model_Snapshot_Statistics_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Model_Based_Pricing_Model_Snapshot_Statistics_Order_By>
  >;
  where?: InputMaybe<Model_Based_Pricing_Model_Snapshot_Statistics_Bool_Exp>;
};

export type Subscription_RootModel_Based_Pricing_Model_Snapshot_Statistics_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Model_Based_Pricing_Model_Snapshot_Statistics_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Model_Based_Pricing_Model_Snapshot_Statistics_Order_By>
  >;
  where?: InputMaybe<Model_Based_Pricing_Model_Snapshot_Statistics_Bool_Exp>;
};

export type Subscription_RootNeon_Db_IntegrationArgs = {
  distinct_on?: InputMaybe<Array<Neon_Db_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Neon_Db_Integration_Order_By>>;
  where?: InputMaybe<Neon_Db_Integration_Bool_Exp>;
};

export type Subscription_RootNeon_Db_Integration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Neon_Db_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Neon_Db_Integration_Order_By>>;
  where?: InputMaybe<Neon_Db_Integration_Bool_Exp>;
};

export type Subscription_RootNeon_Db_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootNeon_Db_Integration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Neon_Db_Integration_Stream_Cursor_Input>>;
  where?: InputMaybe<Neon_Db_Integration_Bool_Exp>;
};

export type Subscription_RootNewrelic_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Newrelic_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Newrelic_Config_Order_By>>;
  where?: InputMaybe<Newrelic_Config_Bool_Exp>;
};

export type Subscription_RootNewrelic_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Newrelic_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Newrelic_Config_Order_By>>;
  where?: InputMaybe<Newrelic_Config_Bool_Exp>;
};

export type Subscription_RootNewrelic_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Subscription_RootNewrelic_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Newrelic_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Newrelic_Config_Bool_Exp>;
};

export type Subscription_RootNode_Pool_TypeArgs = {
  distinct_on?: InputMaybe<Array<Node_Pool_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Node_Pool_Type_Order_By>>;
  where?: InputMaybe<Node_Pool_Type_Bool_Exp>;
};

export type Subscription_RootNode_Pool_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Node_Pool_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Node_Pool_Type_Order_By>>;
  where?: InputMaybe<Node_Pool_Type_Bool_Exp>;
};

export type Subscription_RootNode_Pool_Type_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootNode_Pool_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Node_Pool_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Node_Pool_Type_Bool_Exp>;
};

export type Subscription_RootNode_PoolsArgs = {
  distinct_on?: InputMaybe<Array<Node_Pools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Node_Pools_Order_By>>;
  where?: InputMaybe<Node_Pools_Bool_Exp>;
};

export type Subscription_RootNode_Pools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Node_Pools_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Node_Pools_Order_By>>;
  where?: InputMaybe<Node_Pools_Bool_Exp>;
};

export type Subscription_RootNode_Pools_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootNode_Pools_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Node_Pools_Stream_Cursor_Input>>;
  where?: InputMaybe<Node_Pools_Bool_Exp>;
};

export type Subscription_RootNotificationArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};

export type Subscription_RootNotification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Order_By>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};

export type Subscription_RootNotification_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootNotification_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notification_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Bool_Exp>;
};

export type Subscription_RootNotification_TypeArgs = {
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

export type Subscription_RootNotification_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Type_Order_By>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

export type Subscription_RootNotification_Type_By_PkArgs = {
  type: Scalars['String'];
};

export type Subscription_RootNotification_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notification_Type_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Type_Bool_Exp>;
};

export type Subscription_RootOnboarding_Sample_Db_CohortArgs = {
  distinct_on?: InputMaybe<Array<Onboarding_Sample_Db_Cohort_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Onboarding_Sample_Db_Cohort_Order_By>>;
  where?: InputMaybe<Onboarding_Sample_Db_Cohort_Bool_Exp>;
};

export type Subscription_RootOnboarding_Sample_Db_Cohort_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Onboarding_Sample_Db_Cohort_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Onboarding_Sample_Db_Cohort_Order_By>>;
  where?: InputMaybe<Onboarding_Sample_Db_Cohort_Bool_Exp>;
};

export type Subscription_RootOnboarding_Sample_Db_Cohort_By_PkArgs = {
  user_id: Scalars['uuid'];
};

export type Subscription_RootOnboarding_Sample_Db_Cohort_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Onboarding_Sample_Db_Cohort_Stream_Cursor_Input>>;
  where?: InputMaybe<Onboarding_Sample_Db_Cohort_Bool_Exp>;
};

export type Subscription_RootOnboarding_Sample_Db_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Onboarding_Sample_Db_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Onboarding_Sample_Db_Config_Order_By>>;
  where?: InputMaybe<Onboarding_Sample_Db_Config_Bool_Exp>;
};

export type Subscription_RootOnboarding_Sample_Db_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Onboarding_Sample_Db_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Onboarding_Sample_Db_Config_Order_By>>;
  where?: InputMaybe<Onboarding_Sample_Db_Config_Bool_Exp>;
};

export type Subscription_RootOnboarding_Sample_Db_Config_By_PkArgs = {
  created_at: Scalars['timestamptz'];
};

export type Subscription_RootOnboarding_Sample_Db_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Onboarding_Sample_Db_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Onboarding_Sample_Db_Config_Bool_Exp>;
};

export type Subscription_RootOne_Click_DeploymentArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_Bool_Exp>;
};

export type Subscription_RootOne_Click_Deployment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_Bool_Exp>;
};

export type Subscription_RootOne_Click_Deployment_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootOne_Click_Deployment_Sample_AppsArgs = {
  distinct_on?: InputMaybe<
    Array<One_Click_Deployment_Sample_Apps_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_Sample_Apps_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_Sample_Apps_Bool_Exp>;
};

export type Subscription_RootOne_Click_Deployment_Sample_Apps_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<One_Click_Deployment_Sample_Apps_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_Sample_Apps_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_Sample_Apps_Bool_Exp>;
};

export type Subscription_RootOne_Click_Deployment_Sample_Apps_By_PkArgs = {
  git_repository_branch: Scalars['String'];
  git_repository_url: Scalars['String'];
  hasura_directory: Scalars['String'];
};

export type Subscription_RootOne_Click_Deployment_Sample_Apps_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<One_Click_Deployment_Sample_Apps_Stream_Cursor_Input>
  >;
  where?: InputMaybe<One_Click_Deployment_Sample_Apps_Bool_Exp>;
};

export type Subscription_RootOne_Click_Deployment_State_LogArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_State_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_State_Log_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_State_Log_Bool_Exp>;
};

export type Subscription_RootOne_Click_Deployment_State_Log_AggregateArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_State_Log_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_State_Log_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_State_Log_Bool_Exp>;
};

export type Subscription_RootOne_Click_Deployment_State_Log_By_PkArgs = {
  id: Scalars['bigint'];
};

export type Subscription_RootOne_Click_Deployment_State_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<One_Click_Deployment_State_Log_Stream_Cursor_Input>>;
  where?: InputMaybe<One_Click_Deployment_State_Log_Bool_Exp>;
};

export type Subscription_RootOne_Click_Deployment_StatesArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_States_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_States_Bool_Exp>;
};

export type Subscription_RootOne_Click_Deployment_States_AggregateArgs = {
  distinct_on?: InputMaybe<Array<One_Click_Deployment_States_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<One_Click_Deployment_States_Order_By>>;
  where?: InputMaybe<One_Click_Deployment_States_Bool_Exp>;
};

export type Subscription_RootOne_Click_Deployment_States_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootOne_Click_Deployment_States_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<One_Click_Deployment_States_Stream_Cursor_Input>>;
  where?: InputMaybe<One_Click_Deployment_States_Bool_Exp>;
};

export type Subscription_RootOne_Click_Deployment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<One_Click_Deployment_Stream_Cursor_Input>>;
  where?: InputMaybe<One_Click_Deployment_Bool_Exp>;
};

export type Subscription_RootOpentelemetry_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Opentelemetry_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opentelemetry_Config_Order_By>>;
  where?: InputMaybe<Opentelemetry_Config_Bool_Exp>;
};

export type Subscription_RootOpentelemetry_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opentelemetry_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opentelemetry_Config_Order_By>>;
  where?: InputMaybe<Opentelemetry_Config_Bool_Exp>;
};

export type Subscription_RootOpentelemetry_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Subscription_RootOpentelemetry_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Opentelemetry_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Opentelemetry_Config_Bool_Exp>;
};

export type Subscription_RootOperations_Delete_Free_Plan_Dns_Records_Nov_2021Args = {
  distinct_on?: InputMaybe<
    Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Order_By>
  >;
  where?: InputMaybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp>;
};

export type Subscription_RootOperations_Delete_Free_Plan_Dns_Records_Nov_2021_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Order_By>
  >;
  where?: InputMaybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp>;
};

export type Subscription_RootOperations_Delete_Free_Plan_Dns_Records_Nov_2021_By_PkArgs = {
  tenant_id: Scalars['uuid'];
};

export type Subscription_RootOperations_Delete_Free_Plan_Dns_Records_Nov_2021_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<
      Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Stream_Cursor_Input
    >
  >;
  where?: InputMaybe<Operations_Delete_Free_Plan_Dns_Records_Nov_2021_Bool_Exp>;
};

export type Subscription_RootOperations_TenantArgs = {
  distinct_on?: InputMaybe<Array<Operations_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Tenant_Order_By>>;
  where?: InputMaybe<Operations_Tenant_Bool_Exp>;
};

export type Subscription_RootOperations_Tenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Operations_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Tenant_Order_By>>;
  where?: InputMaybe<Operations_Tenant_Bool_Exp>;
};

export type Subscription_RootOperations_Tenant_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Operations_Tenant_Stream_Cursor_Input>>;
  where?: InputMaybe<Operations_Tenant_Bool_Exp>;
};

export type Subscription_RootOperations_To_Delete_Free_Plan_Dns_RecordsArgs = {
  args?: InputMaybe<Operations_To_Delete_Free_Plan_Dns_Records_Args>;
  distinct_on?: InputMaybe<Array<Operations_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Tenant_Order_By>>;
  where?: InputMaybe<Operations_Tenant_Bool_Exp>;
};

export type Subscription_RootOperations_To_Delete_Free_Plan_Dns_Records_AggregateArgs = {
  args?: InputMaybe<Operations_To_Delete_Free_Plan_Dns_Records_Args>;
  distinct_on?: InputMaybe<Array<Operations_Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Operations_Tenant_Order_By>>;
  where?: InputMaybe<Operations_Tenant_Bool_Exp>;
};

export type Subscription_RootPayment_MethodArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

export type Subscription_RootPayment_Method_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

export type Subscription_RootPayment_Method_By_PkArgs = {
  id: Scalars['String'];
};

export type Subscription_RootPayment_Method_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Payment_Method_Stream_Cursor_Input>>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

export type Subscription_RootPlan_EntitlementsArgs = {
  distinct_on?: InputMaybe<Array<Plan_Entitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plan_Entitlements_Order_By>>;
  where?: InputMaybe<Plan_Entitlements_Bool_Exp>;
};

export type Subscription_RootPlan_Entitlements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plan_Entitlements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plan_Entitlements_Order_By>>;
  where?: InputMaybe<Plan_Entitlements_Bool_Exp>;
};

export type Subscription_RootPlan_Entitlements_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPlan_Entitlements_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Plan_Entitlements_Stream_Cursor_Input>>;
  where?: InputMaybe<Plan_Entitlements_Bool_Exp>;
};

export type Subscription_RootPlansArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

export type Subscription_RootPlans_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Plans_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Plans_Order_By>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

export type Subscription_RootPlans_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootPlans_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Plans_Stream_Cursor_Input>>;
  where?: InputMaybe<Plans_Bool_Exp>;
};

export type Subscription_RootPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootPolicies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootPolicies_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Subscription_RootPolicies_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Policies_Stream_Cursor_Input>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootPrivilegesArgs = {
  distinct_on?: InputMaybe<Array<Privileges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Privileges_Order_By>>;
  where?: InputMaybe<Privileges_Bool_Exp>;
};

export type Subscription_RootPrivileges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Privileges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Privileges_Order_By>>;
  where?: InputMaybe<Privileges_Bool_Exp>;
};

export type Subscription_RootPrivileges_By_PkArgs = {
  slug: Scalars['String'];
};

export type Subscription_RootPrivileges_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Privileges_Stream_Cursor_Input>>;
  where?: InputMaybe<Privileges_Bool_Exp>;
};

export type Subscription_RootPro_License_KeysArgs = {
  distinct_on?: InputMaybe<Array<Pro_License_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pro_License_Keys_Order_By>>;
  where?: InputMaybe<Pro_License_Keys_Bool_Exp>;
};

export type Subscription_RootPro_License_Keys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pro_License_Keys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pro_License_Keys_Order_By>>;
  where?: InputMaybe<Pro_License_Keys_Bool_Exp>;
};

export type Subscription_RootPro_License_Keys_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootPro_License_Keys_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Pro_License_Keys_Stream_Cursor_Input>>;
  where?: InputMaybe<Pro_License_Keys_Bool_Exp>;
};

export type Subscription_RootProject_ActivityArgs = {
  distinct_on?: InputMaybe<Array<Project_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Activity_Order_By>>;
  where?: InputMaybe<Project_Activity_Bool_Exp>;
};

export type Subscription_RootProject_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Activity_Order_By>>;
  where?: InputMaybe<Project_Activity_Bool_Exp>;
};

export type Subscription_RootProject_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Activity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Activity_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Activity_Bool_Exp>;
};

export type Subscription_RootProject_Billing_Manager_InvitationsArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Billing_Manager_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Billing_Manager_Invitations_Order_By>>;
  where?: InputMaybe<Project_Billing_Manager_Invitations_Bool_Exp>;
};

export type Subscription_RootProject_Billing_Manager_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Billing_Manager_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Billing_Manager_Invitations_Order_By>>;
  where?: InputMaybe<Project_Billing_Manager_Invitations_Bool_Exp>;
};

export type Subscription_RootProject_Billing_Manager_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Billing_Manager_Invitations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Project_Billing_Manager_Invitations_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Project_Billing_Manager_Invitations_Bool_Exp>;
};

export type Subscription_RootProject_Collaborator_Allowed_Schema_TablesArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Order_By>
  >;
  where?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>;
};

export type Subscription_RootProject_Collaborator_Allowed_Schema_Tables_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schema_Tables_Order_By>
  >;
  where?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>;
};

export type Subscription_RootProject_Collaborator_Allowed_Schema_Tables_By_PkArgs = {
  project_collaborator_id: Scalars['uuid'];
  schema: Scalars['String'];
  table_name: Scalars['String'];
};

export type Subscription_RootProject_Collaborator_Allowed_Schema_Tables_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Project_Collaborator_Allowed_Schema_Tables_Bool_Exp>;
};

export type Subscription_RootProject_Collaborator_Allowed_SchemasArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schemas_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Allowed_Schemas_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Allowed_Schemas_Bool_Exp>;
};

export type Subscription_RootProject_Collaborator_Allowed_Schemas_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Allowed_Schemas_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Allowed_Schemas_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Allowed_Schemas_Bool_Exp>;
};

export type Subscription_RootProject_Collaborator_Allowed_Schemas_By_PkArgs = {
  project_collaborator_id: Scalars['uuid'];
  schema: Scalars['String'];
};

export type Subscription_RootProject_Collaborator_Allowed_Schemas_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Project_Collaborator_Allowed_Schemas_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Project_Collaborator_Allowed_Schemas_Bool_Exp>;
};

export type Subscription_RootProject_Collaborator_InvitationsArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};

export type Subscription_RootProject_Collaborator_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};

export type Subscription_RootProject_Collaborator_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Collaborator_Invitations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Project_Collaborator_Invitations_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Project_Collaborator_Invitations_Bool_Exp>;
};

export type Subscription_RootProject_Collaborator_PrivilegesArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Privileges_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Privileges_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Privileges_Bool_Exp>;
};

export type Subscription_RootProject_Collaborator_Privileges_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Collaborator_Privileges_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborator_Privileges_Order_By>>;
  where?: InputMaybe<Project_Collaborator_Privileges_Bool_Exp>;
};

export type Subscription_RootProject_Collaborator_Privileges_By_PkArgs = {
  privilege_slug: Scalars['String'];
  project_collaborator_id: Scalars['uuid'];
};

export type Subscription_RootProject_Collaborator_Privileges_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Project_Collaborator_Privileges_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Project_Collaborator_Privileges_Bool_Exp>;
};

export type Subscription_RootProject_CollaboratorsArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborators_Order_By>>;
  where?: InputMaybe<Project_Collaborators_Bool_Exp>;
};

export type Subscription_RootProject_Collaborators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborators_Order_By>>;
  where?: InputMaybe<Project_Collaborators_Bool_Exp>;
};

export type Subscription_RootProject_Collaborators_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Collaborators_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Collaborators_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Collaborators_Bool_Exp>;
};

export type Subscription_RootProject_Data_UsageArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_AggArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Agg_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Agg_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Data_Usage_Agg_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Data_Usage_Agg_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Agg_UserArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_User_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Agg_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Agg_User_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Agg_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Data_Usage_Agg_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Data_Usage_Agg_User_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Data_Usage_ComponentsArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Components_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Components_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Components_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Components_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Components_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Components_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Components_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Components_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Data_Usage_Components_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Data_Usage_Components_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_PrometheusArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Prometheus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Prometheus_Agg_UserArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Data_Usage_Prometheus_Agg_User_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Prometheus_Agg_User_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Data_Usage_Prometheus_Agg_User_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Prometheus_Agg_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Agg_User_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Prometheus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Prometheus_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Prometheus_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Prometheus_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Data_Usage_Prometheus_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Data_Usage_Prometheus_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Data_Usage_Prometheus_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_ReportArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Report_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Report_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Data_Usage_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Data_Usage_Report_Order_By>>;
  where?: InputMaybe<Project_Data_Usage_Report_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_Report_By_PkArgs = {
  project_id: Scalars['uuid'];
  report_date: Scalars['date'];
};

export type Subscription_RootProject_Data_Usage_Report_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Data_Usage_Report_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Data_Usage_Report_Bool_Exp>;
};

export type Subscription_RootProject_Data_Usage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Data_Usage_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Data_Usage_Bool_Exp>;
};

export type Subscription_RootProject_Db_UsageArgs = {
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Bool_Exp>;
};

export type Subscription_RootProject_Db_Usage_Agg_UserArgs = {
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Agg_User_Bool_Exp>;
};

export type Subscription_RootProject_Db_Usage_Agg_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Agg_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Agg_User_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Agg_User_Bool_Exp>;
};

export type Subscription_RootProject_Db_Usage_Agg_User_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Db_Usage_Agg_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Db_Usage_Agg_User_Bool_Exp>;
};

export type Subscription_RootProject_Db_Usage_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Db_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Db_Usage_Order_By>>;
  where?: InputMaybe<Project_Db_Usage_Bool_Exp>;
};

export type Subscription_RootProject_Db_Usage_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Db_Usage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Db_Usage_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Db_Usage_Bool_Exp>;
};

export type Subscription_RootProject_Entitlement_AccessArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Access_Bool_Exp>;
};

export type Subscription_RootProject_Entitlement_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Access_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Access_Bool_Exp>;
};

export type Subscription_RootProject_Entitlement_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Entitlement_Access_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Entitlement_Access_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Entitlement_Access_Bool_Exp>;
};

export type Subscription_RootProject_Entitlement_CatalogueArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Catalogue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Catalogue_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Catalogue_Bool_Exp>;
};

export type Subscription_RootProject_Entitlement_Catalogue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Catalogue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Catalogue_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Catalogue_Bool_Exp>;
};

export type Subscription_RootProject_Entitlement_Catalogue_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Entitlement_Catalogue_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Entitlement_Catalogue_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Entitlement_Catalogue_Bool_Exp>;
};

export type Subscription_RootProject_Entitlement_TypesArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Types_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Types_Bool_Exp>;
};

export type Subscription_RootProject_Entitlement_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Entitlement_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Entitlement_Types_Order_By>>;
  where?: InputMaybe<Project_Entitlement_Types_Bool_Exp>;
};

export type Subscription_RootProject_Entitlement_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootProject_Entitlement_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Entitlement_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Entitlement_Types_Bool_Exp>;
};

export type Subscription_RootProject_LabelsArgs = {
  distinct_on?: InputMaybe<Array<Project_Labels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Labels_Order_By>>;
  where?: InputMaybe<Project_Labels_Bool_Exp>;
};

export type Subscription_RootProject_Labels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Labels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Labels_Order_By>>;
  where?: InputMaybe<Project_Labels_Bool_Exp>;
};

export type Subscription_RootProject_Labels_By_PkArgs = {
  label_id: Scalars['uuid'];
  project_id: Scalars['uuid'];
};

export type Subscription_RootProject_Labels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Labels_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Labels_Bool_Exp>;
};

export type Subscription_RootProject_MetadataArgs = {
  distinct_on?: InputMaybe<Array<Project_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Metadata_Order_By>>;
  where?: InputMaybe<Project_Metadata_Bool_Exp>;
};

export type Subscription_RootProject_Metadata_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Metadata_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Metadata_Order_By>>;
  where?: InputMaybe<Project_Metadata_Bool_Exp>;
};

export type Subscription_RootProject_Metadata_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Subscription_RootProject_Metadata_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Metadata_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Metadata_Bool_Exp>;
};

export type Subscription_RootProject_NotificationArgs = {
  distinct_on?: InputMaybe<Array<Project_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Notification_Order_By>>;
  where?: InputMaybe<Project_Notification_Bool_Exp>;
};

export type Subscription_RootProject_Notification_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Notification_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Notification_Order_By>>;
  where?: InputMaybe<Project_Notification_Bool_Exp>;
};

export type Subscription_RootProject_Notification_By_PkArgs = {
  month: Scalars['Int'];
  project_id: Scalars['uuid'];
  type: Scalars['reminder_type_enum'];
  year: Scalars['Int'];
};

export type Subscription_RootProject_Notification_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Notification_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Notification_Bool_Exp>;
};

export type Subscription_RootProject_Ownership_Transfer_InvitationsArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Ownership_Transfer_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Ownership_Transfer_Invitations_Order_By>>;
  where?: InputMaybe<Project_Ownership_Transfer_Invitations_Bool_Exp>;
};

export type Subscription_RootProject_Ownership_Transfer_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Project_Ownership_Transfer_Invitations_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Ownership_Transfer_Invitations_Order_By>>;
  where?: InputMaybe<Project_Ownership_Transfer_Invitations_Bool_Exp>;
};

export type Subscription_RootProject_Ownership_Transfer_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Ownership_Transfer_Invitations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Project_Ownership_Transfer_Invitations_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Project_Ownership_Transfer_Invitations_Bool_Exp>;
};

export type Subscription_RootProject_Pool_Current_StatusArgs = {
  distinct_on?: InputMaybe<Array<Project_Pool_Current_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Pool_Current_Status_Order_By>>;
  where?: InputMaybe<Project_Pool_Current_Status_Bool_Exp>;
};

export type Subscription_RootProject_Pool_Current_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Pool_Current_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Pool_Current_Status_Order_By>>;
  where?: InputMaybe<Project_Pool_Current_Status_Bool_Exp>;
};

export type Subscription_RootProject_Pool_Current_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Pool_Current_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Pool_Current_Status_Bool_Exp>;
};

export type Subscription_RootProject_Requests_CountArgs = {
  distinct_on?: InputMaybe<Array<Project_Requests_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Requests_Count_Order_By>>;
  where?: InputMaybe<Project_Requests_Count_Bool_Exp>;
};

export type Subscription_RootProject_Requests_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Requests_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Requests_Count_Order_By>>;
  where?: InputMaybe<Project_Requests_Count_Bool_Exp>;
};

export type Subscription_RootProject_Requests_Count_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Requests_Count_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Requests_Count_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Requests_Count_Bool_Exp>;
};

export type Subscription_RootProject_StatsArgs = {
  distinct_on?: InputMaybe<Array<Project_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Stats_Order_By>>;
  where?: InputMaybe<Project_Stats_Bool_Exp>;
};

export type Subscription_RootProject_Stats_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Stats_Order_By>>;
  where?: InputMaybe<Project_Stats_Bool_Exp>;
};

export type Subscription_RootProject_Stats_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProject_Stats_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Stats_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Stats_Bool_Exp>;
};

export type Subscription_RootProject_Total_Db_Usage_AggArgs = {
  distinct_on?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Total_Db_Usage_Agg_Bool_Exp>;
};

export type Subscription_RootProject_Total_Db_Usage_Agg_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Total_Db_Usage_Agg_Order_By>>;
  where?: InputMaybe<Project_Total_Db_Usage_Agg_Bool_Exp>;
};

export type Subscription_RootProject_Total_Db_Usage_Agg_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Project_Total_Db_Usage_Agg_Stream_Cursor_Input>>;
  where?: InputMaybe<Project_Total_Db_Usage_Agg_Bool_Exp>;
};

export type Subscription_RootProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

export type Subscription_RootProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

export type Subscription_RootProjects_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProjects_Pool_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Projects_Pool_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Pool_Config_Order_By>>;
  where?: InputMaybe<Projects_Pool_Config_Bool_Exp>;
};

export type Subscription_RootProjects_Pool_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Pool_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Pool_Config_Order_By>>;
  where?: InputMaybe<Projects_Pool_Config_Bool_Exp>;
};

export type Subscription_RootProjects_Pool_Config_By_PkArgs = {
  cloud: Scalars['String'];
  plan_name: Scalars['String'];
  region: Scalars['String'];
};

export type Subscription_RootProjects_Pool_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Projects_Pool_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Pool_Config_Bool_Exp>;
};

export type Subscription_RootProjects_Pro_Key_GenerationsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Pro_Key_Generations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Pro_Key_Generations_Order_By>>;
  where?: InputMaybe<Projects_Pro_Key_Generations_Bool_Exp>;
};

export type Subscription_RootProjects_Pro_Key_Generations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Pro_Key_Generations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Pro_Key_Generations_Order_By>>;
  where?: InputMaybe<Projects_Pro_Key_Generations_Bool_Exp>;
};

export type Subscription_RootProjects_Pro_Key_Generations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootProjects_Pro_Key_Generations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Projects_Pro_Key_Generations_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Pro_Key_Generations_Bool_Exp>;
};

export type Subscription_RootProjects_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Projects_Stream_Cursor_Input>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

export type Subscription_RootProjects_With_No_Running_DeploymentsArgs = {
  distinct_on?: InputMaybe<
    Array<Projects_With_No_Running_Deployments_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_With_No_Running_Deployments_Order_By>>;
  where?: InputMaybe<Projects_With_No_Running_Deployments_Bool_Exp>;
};

export type Subscription_RootProjects_With_No_Running_Deployments_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Projects_With_No_Running_Deployments_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_With_No_Running_Deployments_Order_By>>;
  where?: InputMaybe<Projects_With_No_Running_Deployments_Bool_Exp>;
};

export type Subscription_RootProjects_With_No_Running_Deployments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Projects_With_No_Running_Deployments_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Projects_With_No_Running_Deployments_Bool_Exp>;
};

export type Subscription_RootProjects_With_No_Running_Preview_AppArgs = {
  distinct_on?: InputMaybe<
    Array<Projects_With_No_Running_Preview_App_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_With_No_Running_Preview_App_Order_By>>;
  where?: InputMaybe<Projects_With_No_Running_Preview_App_Bool_Exp>;
};

export type Subscription_RootProjects_With_No_Running_Preview_App_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Projects_With_No_Running_Preview_App_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_With_No_Running_Preview_App_Order_By>>;
  where?: InputMaybe<Projects_With_No_Running_Preview_App_Bool_Exp>;
};

export type Subscription_RootProjects_With_No_Running_Preview_App_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Projects_With_No_Running_Preview_App_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Projects_With_No_Running_Preview_App_Bool_Exp>;
};

export type Subscription_RootPrometheus_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Prometheus_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prometheus_Config_Order_By>>;
  where?: InputMaybe<Prometheus_Config_Bool_Exp>;
};

export type Subscription_RootPrometheus_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Prometheus_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Prometheus_Config_Order_By>>;
  where?: InputMaybe<Prometheus_Config_Bool_Exp>;
};

export type Subscription_RootPrometheus_Config_By_PkArgs = {
  project_id: Scalars['uuid'];
};

export type Subscription_RootPrometheus_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Prometheus_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Prometheus_Config_Bool_Exp>;
};

export type Subscription_RootProvidersArgs = {
  distinct_on?: InputMaybe<Array<Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Providers_Order_By>>;
  where?: InputMaybe<Providers_Bool_Exp>;
};

export type Subscription_RootProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Providers_Order_By>>;
  where?: InputMaybe<Providers_Bool_Exp>;
};

export type Subscription_RootProviders_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootProviders_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Providers_Stream_Cursor_Input>>;
  where?: InputMaybe<Providers_Bool_Exp>;
};

export type Subscription_RootProvisioningArgs = {
  distinct_on?: InputMaybe<Array<Provisioning_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provisioning_Order_By>>;
  where?: InputMaybe<Provisioning_Bool_Exp>;
};

export type Subscription_RootProvisioning_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Provisioning_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Provisioning_Order_By>>;
  where?: InputMaybe<Provisioning_Bool_Exp>;
};

export type Subscription_RootProvisioning_By_PkArgs = {
  provisioning: Scalars['String'];
};

export type Subscription_RootProvisioning_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Provisioning_Stream_Cursor_Input>>;
  where?: InputMaybe<Provisioning_Bool_Exp>;
};

export type Subscription_RootRegionArgs = {
  distinct_on?: InputMaybe<Array<Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Region_Order_By>>;
  where?: InputMaybe<Region_Bool_Exp>;
};

export type Subscription_RootRegion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Region_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Region_Order_By>>;
  where?: InputMaybe<Region_Bool_Exp>;
};

export type Subscription_RootRegion_By_PkArgs = {
  cloud: Cloud_Enum;
  name: Scalars['String'];
};

export type Subscription_RootRegion_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Region_Stream_Cursor_Input>>;
  where?: InputMaybe<Region_Bool_Exp>;
};

export type Subscription_RootRegion_V2Args = {
  distinct_on?: InputMaybe<Array<Region_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Region_V2_Order_By>>;
  where?: InputMaybe<Region_V2_Bool_Exp>;
};

export type Subscription_RootRegion_V2_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Region_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Region_V2_Order_By>>;
  where?: InputMaybe<Region_V2_Bool_Exp>;
};

export type Subscription_RootRegion_V2_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Region_V2_Stream_Cursor_Input>>;
  where?: InputMaybe<Region_V2_Bool_Exp>;
};

export type Subscription_RootRegional_Data_ConnectorsArgs = {
  distinct_on?: InputMaybe<Array<Regional_Data_Connectors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Data_Connectors_Order_By>>;
  where?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
};

export type Subscription_RootRegional_Data_Connectors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Regional_Data_Connectors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Data_Connectors_Order_By>>;
  where?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
};

export type Subscription_RootRegional_Data_Connectors_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootRegional_Data_Connectors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Regional_Data_Connectors_Stream_Cursor_Input>>;
  where?: InputMaybe<Regional_Data_Connectors_Bool_Exp>;
};

export type Subscription_RootRegional_MetricsArgs = {
  distinct_on?: InputMaybe<Array<Regional_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Metrics_Order_By>>;
  where?: InputMaybe<Regional_Metrics_Bool_Exp>;
};

export type Subscription_RootRegional_Metrics_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Regional_Metrics_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Regional_Metrics_Order_By>>;
  where?: InputMaybe<Regional_Metrics_Bool_Exp>;
};

export type Subscription_RootRegional_Metrics_By_PkArgs = {
  cloud: Scalars['String'];
  region: Scalars['String'];
};

export type Subscription_RootRegional_Metrics_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Regional_Metrics_Stream_Cursor_Input>>;
  where?: InputMaybe<Regional_Metrics_Bool_Exp>;
};

export type Subscription_RootSaml_IdpArgs = {
  distinct_on?: InputMaybe<Array<Saml_Idp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Saml_Idp_Order_By>>;
  where?: InputMaybe<Saml_Idp_Bool_Exp>;
};

export type Subscription_RootSaml_Idp_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Saml_Idp_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Saml_Idp_Order_By>>;
  where?: InputMaybe<Saml_Idp_Bool_Exp>;
};

export type Subscription_RootSaml_Idp_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSaml_Idp_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Saml_Idp_Stream_Cursor_Input>>;
  where?: InputMaybe<Saml_Idp_Bool_Exp>;
};

export type Subscription_RootSearch_Project_Login_StatusArgs = {
  args: Search_Project_Login_Status_Args;
  distinct_on?: InputMaybe<
    Array<Search_Project_Login_Status_Results_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Project_Login_Status_Results_Order_By>>;
  where?: InputMaybe<Search_Project_Login_Status_Results_Bool_Exp>;
};

export type Subscription_RootSearch_Project_Login_Status_AggregateArgs = {
  args: Search_Project_Login_Status_Args;
  distinct_on?: InputMaybe<
    Array<Search_Project_Login_Status_Results_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Project_Login_Status_Results_Order_By>>;
  where?: InputMaybe<Search_Project_Login_Status_Results_Bool_Exp>;
};

export type Subscription_RootSearch_Project_Login_Status_ResultsArgs = {
  distinct_on?: InputMaybe<
    Array<Search_Project_Login_Status_Results_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Project_Login_Status_Results_Order_By>>;
  where?: InputMaybe<Search_Project_Login_Status_Results_Bool_Exp>;
};

export type Subscription_RootSearch_Project_Login_Status_Results_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Search_Project_Login_Status_Results_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Search_Project_Login_Status_Results_Order_By>>;
  where?: InputMaybe<Search_Project_Login_Status_Results_Bool_Exp>;
};

export type Subscription_RootSearch_Project_Login_Status_Results_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Search_Project_Login_Status_Results_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Search_Project_Login_Status_Results_Bool_Exp>;
};

export type Subscription_RootSearch_Tenant_Group_Has_Least_MembersArgs = {
  args?: InputMaybe<Search_Tenant_Group_Has_Least_Members_Args>;
  distinct_on?: InputMaybe<Array<Tenant_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Order_By>>;
  where?: InputMaybe<Tenant_Group_Bool_Exp>;
};

export type Subscription_RootSearch_Tenant_Group_Has_Least_Members_AggregateArgs = {
  args?: InputMaybe<Search_Tenant_Group_Has_Least_Members_Args>;
  distinct_on?: InputMaybe<Array<Tenant_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Order_By>>;
  where?: InputMaybe<Tenant_Group_Bool_Exp>;
};

export type Subscription_RootSlack_ConfigArgs = {
  distinct_on?: InputMaybe<Array<Slack_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Slack_Config_Order_By>>;
  where?: InputMaybe<Slack_Config_Bool_Exp>;
};

export type Subscription_RootSlack_Config_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Slack_Config_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Slack_Config_Order_By>>;
  where?: InputMaybe<Slack_Config_Bool_Exp>;
};

export type Subscription_RootSlack_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSlack_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Slack_Config_Stream_Cursor_Input>>;
  where?: InputMaybe<Slack_Config_Bool_Exp>;
};

export type Subscription_RootStripe_SubscriptionArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stripe_Subscription_Order_By>>;
  where?: InputMaybe<Stripe_Subscription_Bool_Exp>;
};

export type Subscription_RootStripe_Subscription_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Subscription_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stripe_Subscription_Order_By>>;
  where?: InputMaybe<Stripe_Subscription_Bool_Exp>;
};

export type Subscription_RootStripe_Subscription_By_PkArgs = {
  customer_id: Scalars['String'];
  subscription_id: Scalars['String'];
};

export type Subscription_RootStripe_Subscription_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stripe_Subscription_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Subscription_Bool_Exp>;
};

export type Subscription_RootStripe_Webhook_EventsArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Webhook_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stripe_Webhook_Events_Order_By>>;
  where?: InputMaybe<Stripe_Webhook_Events_Bool_Exp>;
};

export type Subscription_RootStripe_Webhook_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stripe_Webhook_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stripe_Webhook_Events_Order_By>>;
  where?: InputMaybe<Stripe_Webhook_Events_Bool_Exp>;
};

export type Subscription_RootStripe_Webhook_Events_By_PkArgs = {
  stripe_event_id: Scalars['String'];
};

export type Subscription_RootStripe_Webhook_Events_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Stripe_Webhook_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Stripe_Webhook_Events_Bool_Exp>;
};

export type Subscription_RootSuper_Connector_TypesArgs = {
  distinct_on?: InputMaybe<Array<Super_Connector_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Super_Connector_Types_Order_By>>;
  where?: InputMaybe<Super_Connector_Types_Bool_Exp>;
};

export type Subscription_RootSuper_Connector_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Super_Connector_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Super_Connector_Types_Order_By>>;
  where?: InputMaybe<Super_Connector_Types_Bool_Exp>;
};

export type Subscription_RootSuper_Connector_Types_By_PkArgs = {
  type: Scalars['String'];
};

export type Subscription_RootSuper_Connector_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Super_Connector_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Super_Connector_Types_Bool_Exp>;
};

export type Subscription_RootSupport_Plan_TypesArgs = {
  distinct_on?: InputMaybe<Array<Support_Plan_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Plan_Types_Order_By>>;
  where?: InputMaybe<Support_Plan_Types_Bool_Exp>;
};

export type Subscription_RootSupport_Plan_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Support_Plan_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Support_Plan_Types_Order_By>>;
  where?: InputMaybe<Support_Plan_Types_Bool_Exp>;
};

export type Subscription_RootSupport_Plan_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootSupport_Plan_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Support_Plan_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<Support_Plan_Types_Bool_Exp>;
};

export type Subscription_RootSurveyArgs = {
  distinct_on?: InputMaybe<Array<Survey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Order_By>>;
  where?: InputMaybe<Survey_Bool_Exp>;
};

export type Subscription_RootSurvey_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Order_By>>;
  where?: InputMaybe<Survey_Bool_Exp>;
};

export type Subscription_RootSurvey_By_PkArgs = {
  survey_name: Scalars['String'];
};

export type Subscription_RootSurvey_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Order_By>>;
  where?: InputMaybe<Survey_Question_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Order_By>>;
  where?: InputMaybe<Survey_Question_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_Answer_OptionArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Answer_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Answer_Option_Order_By>>;
  where?: InputMaybe<Survey_Question_Answer_Option_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_Answer_Option_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Answer_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Answer_Option_Order_By>>;
  where?: InputMaybe<Survey_Question_Answer_Option_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_Answer_Option_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSurvey_Question_Answer_Option_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_Question_Answer_Option_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_Question_Answer_Option_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Answers_Order_By>>;
  where?: InputMaybe<Survey_Question_Answers_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Answers_Order_By>>;
  where?: InputMaybe<Survey_Question_Answers_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_Answers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSurvey_Question_Answers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_Question_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_Question_Answers_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSurvey_Question_KindArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Kind_Order_By>>;
  where?: InputMaybe<Survey_Question_Kind_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Kind_Order_By>>;
  where?: InputMaybe<Survey_Question_Kind_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_Kind_By_PkArgs = {
  kind: Scalars['String'];
};

export type Subscription_RootSurvey_Question_Kind_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_Question_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_Question_Kind_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Options_Order_By>>;
  where?: InputMaybe<Survey_Question_Options_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Options_Order_By>>;
  where?: InputMaybe<Survey_Question_Options_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_Options_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSurvey_Question_Options_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_Question_Options_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_Question_Options_Bool_Exp>;
};

export type Subscription_RootSurvey_Question_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_Question_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_Question_Bool_Exp>;
};

export type Subscription_RootSurvey_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_Bool_Exp>;
};

export type Subscription_RootSurvey_V2Args = {
  distinct_on?: InputMaybe<Array<Survey_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Order_By>>;
  where?: InputMaybe<Survey_V2_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Order_By>>;
  where?: InputMaybe<Survey_V2_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSurvey_V2_QuestionArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Question_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Question_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSurvey_V2_Question_KindArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Kind_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Kind_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Question_Kind_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Kind_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Kind_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Kind_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Question_Kind_By_PkArgs = {
  kind: Scalars['String'];
};

export type Subscription_RootSurvey_V2_Question_Kind_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_V2_Question_Kind_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_V2_Question_Kind_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Question_OptionArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Option_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Option_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Question_Option_Additional_Info_ConfigArgs = {
  distinct_on?: InputMaybe<
    Array<Survey_V2_Question_Option_Additional_Info_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Survey_V2_Question_Option_Additional_Info_Config_Order_By>
  >;
  where?: InputMaybe<Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Question_Option_Additional_Info_Config_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Survey_V2_Question_Option_Additional_Info_Config_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<
    Array<Survey_V2_Question_Option_Additional_Info_Config_Order_By>
  >;
  where?: InputMaybe<Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Question_Option_Additional_Info_Config_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSurvey_V2_Question_Option_Additional_Info_Config_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<
      Survey_V2_Question_Option_Additional_Info_Config_Stream_Cursor_Input
    >
  >;
  where?: InputMaybe<Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Question_Option_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Option_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Option_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Question_Option_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSurvey_V2_Question_Option_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_V2_Question_Option_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_V2_Question_Option_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Question_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_V2_Question_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_V2_Question_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_ResponseArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Response_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Response_AnswerArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Response_Answer_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Response_Answer_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSurvey_V2_Response_Answer_OptionArgs = {
  distinct_on?: InputMaybe<
    Array<Survey_V2_Response_Answer_Option_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Option_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Option_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Response_Answer_Option_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Survey_V2_Response_Answer_Option_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Option_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Option_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Response_Answer_Option_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSurvey_V2_Response_Answer_Option_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<
    InputMaybe<Survey_V2_Response_Answer_Option_Stream_Cursor_Input>
  >;
  where?: InputMaybe<Survey_V2_Response_Answer_Option_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Response_Answer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_V2_Response_Answer_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_Response_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootSurvey_V2_Response_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_V2_Response_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_V2_Response_Bool_Exp>;
};

export type Subscription_RootSurvey_V2_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Survey_V2_Stream_Cursor_Input>>;
  where?: InputMaybe<Survey_V2_Bool_Exp>;
};

export type Subscription_RootTask_EventArgs = {
  distinct_on?: InputMaybe<Array<Task_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Event_Order_By>>;
  where?: InputMaybe<Task_Event_Bool_Exp>;
};

export type Subscription_RootTask_Event_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Event_Order_By>>;
  where?: InputMaybe<Task_Event_Bool_Exp>;
};

export type Subscription_RootTask_Event_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTask_Event_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Task_Event_Stream_Cursor_Input>>;
  where?: InputMaybe<Task_Event_Bool_Exp>;
};

export type Subscription_RootTaskq_Worker_Event_LatestArgs = {
  distinct_on?: InputMaybe<Array<Taskq_Worker_Event_Latest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Taskq_Worker_Event_Latest_Order_By>>;
  where?: InputMaybe<Taskq_Worker_Event_Latest_Bool_Exp>;
};

export type Subscription_RootTaskq_Worker_Event_Latest_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taskq_Worker_Event_Latest_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Taskq_Worker_Event_Latest_Order_By>>;
  where?: InputMaybe<Taskq_Worker_Event_Latest_Bool_Exp>;
};

export type Subscription_RootTaskq_Worker_Event_Latest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Taskq_Worker_Event_Latest_Stream_Cursor_Input>>;
  where?: InputMaybe<Taskq_Worker_Event_Latest_Bool_Exp>;
};

export type Subscription_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTasks_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTasks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTenantArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Order_By>>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

export type Subscription_RootTenant_Active_Status_ReasonArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Active_Status_Reason_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Active_Status_Reason_Order_By>>;
  where?: InputMaybe<Tenant_Active_Status_Reason_Bool_Exp>;
};

export type Subscription_RootTenant_Active_Status_Reason_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Active_Status_Reason_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Active_Status_Reason_Order_By>>;
  where?: InputMaybe<Tenant_Active_Status_Reason_Bool_Exp>;
};

export type Subscription_RootTenant_Active_Status_Reason_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootTenant_Active_Status_Reason_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Active_Status_Reason_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Active_Status_Reason_Bool_Exp>;
};

export type Subscription_RootTenant_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Order_By>>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

export type Subscription_RootTenant_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTenant_GroupArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Order_By>>;
  where?: InputMaybe<Tenant_Group_Bool_Exp>;
};

export type Subscription_RootTenant_Group_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Order_By>>;
  where?: InputMaybe<Tenant_Group_Bool_Exp>;
};

export type Subscription_RootTenant_Group_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTenant_Group_MemberArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Member_Order_By>>;
  where?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
};

export type Subscription_RootTenant_Group_Member_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Member_Order_By>>;
  where?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
};

export type Subscription_RootTenant_Group_Member_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTenant_Group_Member_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Group_Member_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
};

export type Subscription_RootTenant_Group_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Group_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Group_Bool_Exp>;
};

export type Subscription_RootTenant_Public_StatusArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Public_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Public_Status_Order_By>>;
  where?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
};

export type Subscription_RootTenant_Public_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Public_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Public_Status_Order_By>>;
  where?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
};

export type Subscription_RootTenant_Public_Status_By_PkArgs = {
  endpoint: Scalars['String'];
  tenant_id: Scalars['uuid'];
};

export type Subscription_RootTenant_Public_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Public_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
};

export type Subscription_RootTenant_StatusArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Status_Order_By>>;
  where?: InputMaybe<Tenant_Status_Bool_Exp>;
};

export type Subscription_RootTenant_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Status_Order_By>>;
  where?: InputMaybe<Tenant_Status_Bool_Exp>;
};

export type Subscription_RootTenant_Status_By_PkArgs = {
  tenant_id: Scalars['uuid'];
  worker_id: Scalars['uuid'];
};

export type Subscription_RootTenant_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Status_Bool_Exp>;
};

export type Subscription_RootTenant_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tenant_Stream_Cursor_Input>>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

export type Subscription_RootTrial_LeadsArgs = {
  distinct_on?: InputMaybe<Array<Trial_Leads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trial_Leads_Order_By>>;
  where?: InputMaybe<Trial_Leads_Bool_Exp>;
};

export type Subscription_RootTrial_Leads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Trial_Leads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Trial_Leads_Order_By>>;
  where?: InputMaybe<Trial_Leads_Bool_Exp>;
};

export type Subscription_RootTrial_Leads_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootTrial_Leads_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Trial_Leads_Stream_Cursor_Input>>;
  where?: InputMaybe<Trial_Leads_Bool_Exp>;
};

export type Subscription_RootUa_Audit_LogsArgs = {
  distinct_on?: InputMaybe<Array<Ua_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ua_Audit_Logs_Order_By>>;
  where?: InputMaybe<Ua_Audit_Logs_Bool_Exp>;
};

export type Subscription_RootUa_Audit_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ua_Audit_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ua_Audit_Logs_Order_By>>;
  where?: InputMaybe<Ua_Audit_Logs_Bool_Exp>;
};

export type Subscription_RootUa_Audit_Logs_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUa_Audit_Logs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Ua_Audit_Logs_Stream_Cursor_Input>>;
  where?: InputMaybe<Ua_Audit_Logs_Bool_Exp>;
};

export type Subscription_RootUser_ActivityArgs = {
  distinct_on?: InputMaybe<Array<User_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Activity_Order_By>>;
  where?: InputMaybe<User_Activity_Bool_Exp>;
};

export type Subscription_RootUser_Activity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Activity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Activity_Order_By>>;
  where?: InputMaybe<User_Activity_Bool_Exp>;
};

export type Subscription_RootUser_Activity_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Activity_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Activity_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Activity_Bool_Exp>;
};

export type Subscription_RootUser_CouponArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type Subscription_RootUser_Coupon_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type Subscription_RootUser_Coupon_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Coupon_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Coupon_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

export type Subscription_RootUser_Entitlement_AccessArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Access_Order_By>>;
  where?: InputMaybe<User_Entitlement_Access_Bool_Exp>;
};

export type Subscription_RootUser_Entitlement_Access_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Access_Order_By>>;
  where?: InputMaybe<User_Entitlement_Access_Bool_Exp>;
};

export type Subscription_RootUser_Entitlement_Access_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Entitlement_Access_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Entitlement_Access_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Entitlement_Access_Bool_Exp>;
};

export type Subscription_RootUser_Entitlement_CatalogueArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Catalogue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Catalogue_Order_By>>;
  where?: InputMaybe<User_Entitlement_Catalogue_Bool_Exp>;
};

export type Subscription_RootUser_Entitlement_Catalogue_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Catalogue_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Catalogue_Order_By>>;
  where?: InputMaybe<User_Entitlement_Catalogue_Bool_Exp>;
};

export type Subscription_RootUser_Entitlement_Catalogue_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Entitlement_Catalogue_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Entitlement_Catalogue_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Entitlement_Catalogue_Bool_Exp>;
};

export type Subscription_RootUser_Entitlement_TypesArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Types_Order_By>>;
  where?: InputMaybe<User_Entitlement_Types_Bool_Exp>;
};

export type Subscription_RootUser_Entitlement_Types_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Types_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Types_Order_By>>;
  where?: InputMaybe<User_Entitlement_Types_Bool_Exp>;
};

export type Subscription_RootUser_Entitlement_Types_By_PkArgs = {
  value: Scalars['String'];
};

export type Subscription_RootUser_Entitlement_Types_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Entitlement_Types_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Entitlement_Types_Bool_Exp>;
};

export type Subscription_RootUser_OnboardingArgs = {
  distinct_on?: InputMaybe<Array<User_Onboarding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Onboarding_Order_By>>;
  where?: InputMaybe<User_Onboarding_Bool_Exp>;
};

export type Subscription_RootUser_Onboarding_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Onboarding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Onboarding_Order_By>>;
  where?: InputMaybe<User_Onboarding_Bool_Exp>;
};

export type Subscription_RootUser_Onboarding_By_PkArgs = {
  target: Scalars['String'];
  user_id: Scalars['uuid'];
};

export type Subscription_RootUser_Onboarding_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Onboarding_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Onboarding_Bool_Exp>;
};

export type Subscription_RootUser_Personal_Access_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Personal_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Personal_Access_Tokens_Order_By>>;
  where?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
};

export type Subscription_RootUser_Personal_Access_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Personal_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Personal_Access_Tokens_Order_By>>;
  where?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
};

export type Subscription_RootUser_Personal_Access_Tokens_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Personal_Access_Tokens_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Personal_Access_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
};

export type Subscription_RootUser_ProfileArgs = {
  distinct_on?: InputMaybe<Array<User_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Profile_Order_By>>;
  where?: InputMaybe<User_Profile_Bool_Exp>;
};

export type Subscription_RootUser_Profile_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Profile_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Profile_Order_By>>;
  where?: InputMaybe<User_Profile_Bool_Exp>;
};

export type Subscription_RootUser_Profile_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Profile_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Profile_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Profile_Bool_Exp>;
};

export type Subscription_RootUser_Project_MapArgs = {
  distinct_on?: InputMaybe<Array<User_Project_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Project_Map_Order_By>>;
  where?: InputMaybe<User_Project_Map_Bool_Exp>;
};

export type Subscription_RootUser_Project_Map_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Project_Map_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Project_Map_Order_By>>;
  where?: InputMaybe<User_Project_Map_Bool_Exp>;
};

export type Subscription_RootUser_Project_Map_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Project_Map_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Project_Map_Bool_Exp>;
};

export type Subscription_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUser_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUser_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

export type Subscription_RootUser_Vpc_PolicyArgs = {
  distinct_on?: InputMaybe<Array<User_Vpc_Policy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Vpc_Policy_Order_By>>;
  where?: InputMaybe<User_Vpc_Policy_Bool_Exp>;
};

export type Subscription_RootUser_Vpc_Policy_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Vpc_Policy_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Vpc_Policy_Order_By>>;
  where?: InputMaybe<User_Vpc_Policy_Bool_Exp>;
};

export type Subscription_RootUser_Vpc_Policy_By_PkArgs = {
  id: Scalars['Int'];
};

export type Subscription_RootUser_Vpc_Policy_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Vpc_Policy_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Vpc_Policy_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootUsers_PublicArgs = {
  distinct_on?: InputMaybe<Array<Users_Public_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Public_Order_By>>;
  where?: InputMaybe<Users_Public_Bool_Exp>;
};

export type Subscription_RootUsers_Public_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Public_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Public_Order_By>>;
  where?: InputMaybe<Users_Public_Bool_Exp>;
};

export type Subscription_RootUsers_Public_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Public_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Public_Bool_Exp>;
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootVercel_IntegrationArgs = {
  distinct_on?: InputMaybe<Array<Vercel_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vercel_Integration_Order_By>>;
  where?: InputMaybe<Vercel_Integration_Bool_Exp>;
};

export type Subscription_RootVercel_Integration_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vercel_Integration_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vercel_Integration_Order_By>>;
  where?: InputMaybe<Vercel_Integration_Bool_Exp>;
};

export type Subscription_RootVercel_Integration_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootVercel_Integration_ConnectionsArgs = {
  distinct_on?: InputMaybe<Array<Vercel_Integration_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vercel_Integration_Connections_Order_By>>;
  where?: InputMaybe<Vercel_Integration_Connections_Bool_Exp>;
};

export type Subscription_RootVercel_Integration_Connections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vercel_Integration_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vercel_Integration_Connections_Order_By>>;
  where?: InputMaybe<Vercel_Integration_Connections_Bool_Exp>;
};

export type Subscription_RootVercel_Integration_Connections_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootVercel_Integration_Connections_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Vercel_Integration_Connections_Stream_Cursor_Input>>;
  where?: InputMaybe<Vercel_Integration_Connections_Bool_Exp>;
};

export type Subscription_RootVercel_Integration_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Vercel_Integration_Stream_Cursor_Input>>;
  where?: InputMaybe<Vercel_Integration_Bool_Exp>;
};

export type Subscription_RootVpc_Collaborator_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Vpc_Collaborator_Invitations_Bool_Exp>;
};

export type Subscription_RootVpc_Collaborator_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborator_Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborator_Invitations_Order_By>>;
  where?: InputMaybe<Vpc_Collaborator_Invitations_Bool_Exp>;
};

export type Subscription_RootVpc_Collaborator_Invitations_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootVpc_Collaborator_Invitations_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Vpc_Collaborator_Invitations_Stream_Cursor_Input>>;
  where?: InputMaybe<Vpc_Collaborator_Invitations_Bool_Exp>;
};

export type Subscription_RootVpc_CollaboratorsArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborators_Order_By>>;
  where?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
};

export type Subscription_RootVpc_Collaborators_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborators_Order_By>>;
  where?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
};

export type Subscription_RootVpc_Collaborators_By_PkArgs = {
  collaborator_id: Scalars['uuid'];
  vpc_id: Scalars['uuid'];
};

export type Subscription_RootVpc_Collaborators_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Vpc_Collaborators_Stream_Cursor_Input>>;
  where?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
};

export type Subscription_RootVpc_PeeringArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Peering_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Peering_Order_By>>;
  where?: InputMaybe<Vpc_Peering_Bool_Exp>;
};

export type Subscription_RootVpc_Peering_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Peering_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Peering_Order_By>>;
  where?: InputMaybe<Vpc_Peering_Bool_Exp>;
};

export type Subscription_RootVpc_Peering_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootVpc_Peering_DirectionArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Peering_Direction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Peering_Direction_Order_By>>;
  where?: InputMaybe<Vpc_Peering_Direction_Bool_Exp>;
};

export type Subscription_RootVpc_Peering_Direction_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Peering_Direction_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Peering_Direction_Order_By>>;
  where?: InputMaybe<Vpc_Peering_Direction_Bool_Exp>;
};

export type Subscription_RootVpc_Peering_Direction_By_PkArgs = {
  direction: Scalars['String'];
};

export type Subscription_RootVpc_Peering_Direction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Vpc_Peering_Direction_Stream_Cursor_Input>>;
  where?: InputMaybe<Vpc_Peering_Direction_Bool_Exp>;
};

export type Subscription_RootVpc_Peering_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Vpc_Peering_Stream_Cursor_Input>>;
  where?: InputMaybe<Vpc_Peering_Bool_Exp>;
};

export type Subscription_RootVpc_PrivilegesArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Privileges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Privileges_Order_By>>;
  where?: InputMaybe<Vpc_Privileges_Bool_Exp>;
};

export type Subscription_RootVpc_Privileges_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Privileges_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Privileges_Order_By>>;
  where?: InputMaybe<Vpc_Privileges_Bool_Exp>;
};

export type Subscription_RootVpc_Privileges_By_PkArgs = {
  slug: Scalars['String'];
};

export type Subscription_RootVpc_Privileges_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Vpc_Privileges_Stream_Cursor_Input>>;
  where?: InputMaybe<Vpc_Privileges_Bool_Exp>;
};

export type Subscription_RootVpc_StatusArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Status_Order_By>>;
  where?: InputMaybe<Vpc_Status_Bool_Exp>;
};

export type Subscription_RootVpc_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Status_Order_By>>;
  where?: InputMaybe<Vpc_Status_Bool_Exp>;
};

export type Subscription_RootVpc_Status_By_PkArgs = {
  status: Scalars['String'];
};

export type Subscription_RootVpc_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Vpc_Status_Stream_Cursor_Input>>;
  where?: InputMaybe<Vpc_Status_Bool_Exp>;
};

export type Subscription_RootZendesk_Support_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Zendesk_Support_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zendesk_Support_Category_Order_By>>;
  where?: InputMaybe<Zendesk_Support_Category_Bool_Exp>;
};

export type Subscription_RootZendesk_Support_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zendesk_Support_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zendesk_Support_Category_Order_By>>;
  where?: InputMaybe<Zendesk_Support_Category_Bool_Exp>;
};

export type Subscription_RootZendesk_Support_Category_By_PkArgs = {
  name: Scalars['String'];
};

export type Subscription_RootZendesk_Support_Category_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zendesk_Support_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Zendesk_Support_Category_Bool_Exp>;
};

export type Subscription_RootZendesk_Support_TicketsArgs = {
  distinct_on?: InputMaybe<Array<Zendesk_Support_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zendesk_Support_Tickets_Order_By>>;
  where?: InputMaybe<Zendesk_Support_Tickets_Bool_Exp>;
};

export type Subscription_RootZendesk_Support_Tickets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Zendesk_Support_Tickets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Zendesk_Support_Tickets_Order_By>>;
  where?: InputMaybe<Zendesk_Support_Tickets_Bool_Exp>;
};

export type Subscription_RootZendesk_Support_Tickets_By_PkArgs = {
  id: Scalars['uuid'];
};

export type Subscription_RootZendesk_Support_Tickets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Zendesk_Support_Tickets_Stream_Cursor_Input>>;
  where?: InputMaybe<Zendesk_Support_Tickets_Bool_Exp>;
};

/** Hasua enum to capture different super connector types  in infra */
export type Super_Connector_Types = {
  __typename?: 'super_connector_types';
  description: Scalars['String'];
  type: Scalars['String'];
};

/** aggregated selection of "super_connector_types" */
export type Super_Connector_Types_Aggregate = {
  __typename?: 'super_connector_types_aggregate';
  aggregate?: Maybe<Super_Connector_Types_Aggregate_Fields>;
  nodes: Array<Super_Connector_Types>;
};

/** aggregate fields of "super_connector_types" */
export type Super_Connector_Types_Aggregate_Fields = {
  __typename?: 'super_connector_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Super_Connector_Types_Max_Fields>;
  min?: Maybe<Super_Connector_Types_Min_Fields>;
};

/** aggregate fields of "super_connector_types" */
export type Super_Connector_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Super_Connector_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "super_connector_types". All fields are combined with a logical 'AND'. */
export type Super_Connector_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Super_Connector_Types_Bool_Exp>>;
  _not?: InputMaybe<Super_Connector_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Super_Connector_Types_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "super_connector_types" */
export enum Super_Connector_Types_Constraint {
  /** unique or primary key constraint on columns "type" */
  SuperConnectorTypesPkey = 'super_connector_types_pkey',
}

export enum Super_Connector_Types_Enum {
  /** Connect to super connector which is run along side graphql-engine-multitenant */
  Local = 'local',
}

/** Boolean expression to compare columns of type "super_connector_types_enum". All fields are combined with logical 'AND'. */
export type Super_Connector_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Super_Connector_Types_Enum>;
  _in?: InputMaybe<Array<Super_Connector_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Super_Connector_Types_Enum>;
  _nin?: InputMaybe<Array<Super_Connector_Types_Enum>>;
};

/** input type for inserting data into table "super_connector_types" */
export type Super_Connector_Types_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Super_Connector_Types_Max_Fields = {
  __typename?: 'super_connector_types_max_fields';
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Super_Connector_Types_Min_Fields = {
  __typename?: 'super_connector_types_min_fields';
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "super_connector_types" */
export type Super_Connector_Types_Mutation_Response = {
  __typename?: 'super_connector_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Super_Connector_Types>;
};

/** on_conflict condition type for table "super_connector_types" */
export type Super_Connector_Types_On_Conflict = {
  constraint: Super_Connector_Types_Constraint;
  update_columns?: Array<Super_Connector_Types_Update_Column>;
  where?: InputMaybe<Super_Connector_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "super_connector_types". */
export type Super_Connector_Types_Order_By = {
  description?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: super_connector_types */
export type Super_Connector_Types_Pk_Columns_Input = {
  type: Scalars['String'];
};

/** select columns of table "super_connector_types" */
export enum Super_Connector_Types_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type',
}

/** input type for updating data in table "super_connector_types" */
export type Super_Connector_Types_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "super_connector_types" */
export type Super_Connector_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Super_Connector_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Super_Connector_Types_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "super_connector_types" */
export enum Super_Connector_Types_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Type = 'type',
}

export type Super_Connector_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Super_Connector_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Super_Connector_Types_Bool_Exp;
};

/** Enum to represent all support plan Hasura offers.  */
export type Support_Plan_Types = {
  __typename?: 'support_plan_types';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "support_plan_types" */
export type Support_Plan_Types_Aggregate = {
  __typename?: 'support_plan_types_aggregate';
  aggregate?: Maybe<Support_Plan_Types_Aggregate_Fields>;
  nodes: Array<Support_Plan_Types>;
};

/** aggregate fields of "support_plan_types" */
export type Support_Plan_Types_Aggregate_Fields = {
  __typename?: 'support_plan_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Support_Plan_Types_Max_Fields>;
  min?: Maybe<Support_Plan_Types_Min_Fields>;
};

/** aggregate fields of "support_plan_types" */
export type Support_Plan_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Support_Plan_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "support_plan_types". All fields are combined with a logical 'AND'. */
export type Support_Plan_Types_Bool_Exp = {
  _and?: InputMaybe<Array<Support_Plan_Types_Bool_Exp>>;
  _not?: InputMaybe<Support_Plan_Types_Bool_Exp>;
  _or?: InputMaybe<Array<Support_Plan_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "support_plan_types" */
export enum Support_Plan_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  SupportPlanTypesPkey = 'support_plan_types_pkey',
}

export enum Support_Plan_Types_Enum {
  /** Bronze Support Plan for Cloud Dedicated users. */
  BronzeSupportTier = 'bronze_support_tier',
  /** Gold Support Plan for Cloud Dedicated users. */
  GoldSupportTier = 'gold_support_tier',
  /** Platinum Support Plan for Cloud Dedicated users. */
  PlatinumSupportTier = 'platinum_support_tier',
  /** Silver Support Plan for Cloud Dedicated users. */
  SilverSupportTier = 'silver_support_tier',
}

/** Boolean expression to compare columns of type "support_plan_types_enum". All fields are combined with logical 'AND'. */
export type Support_Plan_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Support_Plan_Types_Enum>;
  _in?: InputMaybe<Array<Support_Plan_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Support_Plan_Types_Enum>;
  _nin?: InputMaybe<Array<Support_Plan_Types_Enum>>;
};

/** input type for inserting data into table "support_plan_types" */
export type Support_Plan_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Support_Plan_Types_Max_Fields = {
  __typename?: 'support_plan_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Support_Plan_Types_Min_Fields = {
  __typename?: 'support_plan_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "support_plan_types" */
export type Support_Plan_Types_Mutation_Response = {
  __typename?: 'support_plan_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Support_Plan_Types>;
};

/** input type for inserting object relation for remote table "support_plan_types" */
export type Support_Plan_Types_Obj_Rel_Insert_Input = {
  data: Support_Plan_Types_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Support_Plan_Types_On_Conflict>;
};

/** on_conflict condition type for table "support_plan_types" */
export type Support_Plan_Types_On_Conflict = {
  constraint: Support_Plan_Types_Constraint;
  update_columns?: Array<Support_Plan_Types_Update_Column>;
  where?: InputMaybe<Support_Plan_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "support_plan_types". */
export type Support_Plan_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: support_plan_types */
export type Support_Plan_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "support_plan_types" */
export enum Support_Plan_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "support_plan_types" */
export type Support_Plan_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "support_plan_types" */
export type Support_Plan_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Support_Plan_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Support_Plan_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "support_plan_types" */
export enum Support_Plan_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type Support_Plan_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Support_Plan_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: Support_Plan_Types_Bool_Exp;
};

/** information on a survey being conducted  */
export type Survey = {
  __typename?: 'survey';
  created_at: Scalars['timestamptz'];
  ended_at?: Maybe<Scalars['timestamptz']>;
  requested_by: Scalars['String'];
  started_at: Scalars['timestamptz'];
  survey_description?: Maybe<Scalars['String']>;
  survey_header?: Maybe<Scalars['String']>;
  survey_name: Scalars['String'];
  /** An array relationship */
  survey_questions: Array<Survey_Question>;
  /** An aggregate relationship */
  survey_questions_aggregate: Survey_Question_Aggregate;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
};

/** information on a survey being conducted  */
export type SurveySurvey_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Order_By>>;
  where?: InputMaybe<Survey_Question_Bool_Exp>;
};

/** information on a survey being conducted  */
export type SurveySurvey_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Order_By>>;
  where?: InputMaybe<Survey_Question_Bool_Exp>;
};

/** aggregated selection of "survey" */
export type Survey_Aggregate = {
  __typename?: 'survey_aggregate';
  aggregate?: Maybe<Survey_Aggregate_Fields>;
  nodes: Array<Survey>;
};

/** aggregate fields of "survey" */
export type Survey_Aggregate_Fields = {
  __typename?: 'survey_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_Max_Fields>;
  min?: Maybe<Survey_Min_Fields>;
};

/** aggregate fields of "survey" */
export type Survey_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "survey". All fields are combined with a logical 'AND'. */
export type Survey_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_Bool_Exp>>;
  _not?: InputMaybe<Survey_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ended_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  requested_by?: InputMaybe<String_Comparison_Exp>;
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  survey_description?: InputMaybe<String_Comparison_Exp>;
  survey_header?: InputMaybe<String_Comparison_Exp>;
  survey_name?: InputMaybe<String_Comparison_Exp>;
  survey_questions?: InputMaybe<Survey_Question_Bool_Exp>;
  survey_questions_aggregate?: InputMaybe<Survey_Question_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey" */
export enum Survey_Constraint {
  /** unique or primary key constraint on columns "survey_name" */
  SurveyPkey = 'survey_pkey',
}

/** input type for inserting data into table "survey" */
export type Survey_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  requested_by?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  survey_description?: InputMaybe<Scalars['String']>;
  survey_header?: InputMaybe<Scalars['String']>;
  survey_name?: InputMaybe<Scalars['String']>;
  survey_questions?: InputMaybe<Survey_Question_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Survey_Max_Fields = {
  __typename?: 'survey_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  requested_by?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  survey_description?: Maybe<Scalars['String']>;
  survey_header?: Maybe<Scalars['String']>;
  survey_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Survey_Min_Fields = {
  __typename?: 'survey_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  requested_by?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  survey_description?: Maybe<Scalars['String']>;
  survey_header?: Maybe<Scalars['String']>;
  survey_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "survey" */
export type Survey_Mutation_Response = {
  __typename?: 'survey_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey>;
};

/** input type for inserting object relation for remote table "survey" */
export type Survey_Obj_Rel_Insert_Input = {
  data: Survey_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_On_Conflict>;
};

/** on_conflict condition type for table "survey" */
export type Survey_On_Conflict = {
  constraint: Survey_Constraint;
  update_columns?: Array<Survey_Update_Column>;
  where?: InputMaybe<Survey_Bool_Exp>;
};

/** Ordering options when selecting data from "survey". */
export type Survey_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  requested_by?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  survey_description?: InputMaybe<Order_By>;
  survey_header?: InputMaybe<Order_By>;
  survey_name?: InputMaybe<Order_By>;
  survey_questions_aggregate?: InputMaybe<Survey_Question_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey */
export type Survey_Pk_Columns_Input = {
  survey_name: Scalars['String'];
};

/** contains all questions for a survey  */
export type Survey_Question = {
  __typename?: 'survey_question';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  kind: Survey_Question_Kind_Enum;
  question: Scalars['String'];
  /** An object relationship */
  survey: Survey;
  survey_name: Scalars['String'];
  /** An array relationship */
  survey_question_options: Array<Survey_Question_Options>;
  /** An aggregate relationship */
  survey_question_options_aggregate: Survey_Question_Options_Aggregate;
  updated_at: Scalars['timestamptz'];
};

/** contains all questions for a survey  */
export type Survey_QuestionSurvey_Question_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Options_Order_By>>;
  where?: InputMaybe<Survey_Question_Options_Bool_Exp>;
};

/** contains all questions for a survey  */
export type Survey_QuestionSurvey_Question_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Options_Order_By>>;
  where?: InputMaybe<Survey_Question_Options_Bool_Exp>;
};

/** aggregated selection of "survey_question" */
export type Survey_Question_Aggregate = {
  __typename?: 'survey_question_aggregate';
  aggregate?: Maybe<Survey_Question_Aggregate_Fields>;
  nodes: Array<Survey_Question>;
};

export type Survey_Question_Aggregate_Bool_Exp = {
  count?: InputMaybe<Survey_Question_Aggregate_Bool_Exp_Count>;
};

export type Survey_Question_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Survey_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Survey_Question_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "survey_question" */
export type Survey_Question_Aggregate_Fields = {
  __typename?: 'survey_question_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_Question_Max_Fields>;
  min?: Maybe<Survey_Question_Min_Fields>;
};

/** aggregate fields of "survey_question" */
export type Survey_Question_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "survey_question" */
export type Survey_Question_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Survey_Question_Max_Order_By>;
  min?: InputMaybe<Survey_Question_Min_Order_By>;
};

/** contains all the answers given by a user for a given question */
export type Survey_Question_Answer_Option = {
  __typename?: 'survey_question_answer_option';
  answer?: Maybe<Scalars['String']>;
  answer_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  option_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  survey_question_answer: Survey_Question_Answers;
  /** An object relationship */
  survey_question_option?: Maybe<Survey_Question_Options>;
};

/** aggregated selection of "survey_question_answer_option" */
export type Survey_Question_Answer_Option_Aggregate = {
  __typename?: 'survey_question_answer_option_aggregate';
  aggregate?: Maybe<Survey_Question_Answer_Option_Aggregate_Fields>;
  nodes: Array<Survey_Question_Answer_Option>;
};

export type Survey_Question_Answer_Option_Aggregate_Bool_Exp = {
  count?: InputMaybe<Survey_Question_Answer_Option_Aggregate_Bool_Exp_Count>;
};

export type Survey_Question_Answer_Option_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Survey_Question_Answer_Option_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Survey_Question_Answer_Option_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "survey_question_answer_option" */
export type Survey_Question_Answer_Option_Aggregate_Fields = {
  __typename?: 'survey_question_answer_option_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_Question_Answer_Option_Max_Fields>;
  min?: Maybe<Survey_Question_Answer_Option_Min_Fields>;
};

/** aggregate fields of "survey_question_answer_option" */
export type Survey_Question_Answer_Option_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_Question_Answer_Option_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "survey_question_answer_option" */
export type Survey_Question_Answer_Option_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Survey_Question_Answer_Option_Max_Order_By>;
  min?: InputMaybe<Survey_Question_Answer_Option_Min_Order_By>;
};

/** input type for inserting array relation for remote table "survey_question_answer_option" */
export type Survey_Question_Answer_Option_Arr_Rel_Insert_Input = {
  data: Array<Survey_Question_Answer_Option_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_Question_Answer_Option_On_Conflict>;
};

/** Boolean expression to filter rows from the table "survey_question_answer_option". All fields are combined with a logical 'AND'. */
export type Survey_Question_Answer_Option_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_Question_Answer_Option_Bool_Exp>>;
  _not?: InputMaybe<Survey_Question_Answer_Option_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_Question_Answer_Option_Bool_Exp>>;
  answer?: InputMaybe<String_Comparison_Exp>;
  answer_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  option_id?: InputMaybe<Uuid_Comparison_Exp>;
  survey_question_answer?: InputMaybe<Survey_Question_Answers_Bool_Exp>;
  survey_question_option?: InputMaybe<Survey_Question_Options_Bool_Exp>;
};

/** unique or primary key constraints on table "survey_question_answer_option" */
export enum Survey_Question_Answer_Option_Constraint {
  /** unique or primary key constraint on columns "id" */
  SurveyQuestionAnswerOptionPkey = 'survey_question_answer_option_pkey',
}

/** input type for inserting data into table "survey_question_answer_option" */
export type Survey_Question_Answer_Option_Insert_Input = {
  answer?: InputMaybe<Scalars['String']>;
  answer_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  option_id?: InputMaybe<Scalars['uuid']>;
  survey_question_answer?: InputMaybe<
    Survey_Question_Answers_Obj_Rel_Insert_Input
  >;
  survey_question_option?: InputMaybe<
    Survey_Question_Options_Obj_Rel_Insert_Input
  >;
};

/** aggregate max on columns */
export type Survey_Question_Answer_Option_Max_Fields = {
  __typename?: 'survey_question_answer_option_max_fields';
  answer?: Maybe<Scalars['String']>;
  answer_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "survey_question_answer_option" */
export type Survey_Question_Answer_Option_Max_Order_By = {
  answer?: InputMaybe<Order_By>;
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  option_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Survey_Question_Answer_Option_Min_Fields = {
  __typename?: 'survey_question_answer_option_min_fields';
  answer?: Maybe<Scalars['String']>;
  answer_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "survey_question_answer_option" */
export type Survey_Question_Answer_Option_Min_Order_By = {
  answer?: InputMaybe<Order_By>;
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  option_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "survey_question_answer_option" */
export type Survey_Question_Answer_Option_Mutation_Response = {
  __typename?: 'survey_question_answer_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_Question_Answer_Option>;
};

/** on_conflict condition type for table "survey_question_answer_option" */
export type Survey_Question_Answer_Option_On_Conflict = {
  constraint: Survey_Question_Answer_Option_Constraint;
  update_columns?: Array<Survey_Question_Answer_Option_Update_Column>;
  where?: InputMaybe<Survey_Question_Answer_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_question_answer_option". */
export type Survey_Question_Answer_Option_Order_By = {
  answer?: InputMaybe<Order_By>;
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  option_id?: InputMaybe<Order_By>;
  survey_question_answer?: InputMaybe<Survey_Question_Answers_Order_By>;
  survey_question_option?: InputMaybe<Survey_Question_Options_Order_By>;
};

/** primary key columns input for table: survey_question_answer_option */
export type Survey_Question_Answer_Option_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "survey_question_answer_option" */
export enum Survey_Question_Answer_Option_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OptionId = 'option_id',
}

/** input type for updating data in table "survey_question_answer_option" */
export type Survey_Question_Answer_Option_Set_Input = {
  answer?: InputMaybe<Scalars['String']>;
  answer_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  option_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "survey_question_answer_option" */
export type Survey_Question_Answer_Option_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_Question_Answer_Option_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_Question_Answer_Option_Stream_Cursor_Value_Input = {
  answer?: InputMaybe<Scalars['String']>;
  answer_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  option_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "survey_question_answer_option" */
export enum Survey_Question_Answer_Option_Update_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OptionId = 'option_id',
}

export type Survey_Question_Answer_Option_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_Question_Answer_Option_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_Question_Answer_Option_Bool_Exp;
};

/** contains information on user\`s answer */
export type Survey_Question_Answers = {
  __typename?: 'survey_question_answers';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  project_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  survey_question: Survey_Question;
  /** An array relationship */
  survey_question_answer_options: Array<Survey_Question_Answer_Option>;
  /** An aggregate relationship */
  survey_question_answer_options_aggregate: Survey_Question_Answer_Option_Aggregate;
  survey_question_id: Scalars['uuid'];
  user_id: Scalars['uuid'];
};

/** contains information on user\`s answer */
export type Survey_Question_AnswersSurvey_Question_Answer_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Answer_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Answer_Option_Order_By>>;
  where?: InputMaybe<Survey_Question_Answer_Option_Bool_Exp>;
};

/** contains information on user\`s answer */
export type Survey_Question_AnswersSurvey_Question_Answer_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_Question_Answer_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_Question_Answer_Option_Order_By>>;
  where?: InputMaybe<Survey_Question_Answer_Option_Bool_Exp>;
};

/** aggregated selection of "survey_question_answers" */
export type Survey_Question_Answers_Aggregate = {
  __typename?: 'survey_question_answers_aggregate';
  aggregate?: Maybe<Survey_Question_Answers_Aggregate_Fields>;
  nodes: Array<Survey_Question_Answers>;
};

/** aggregate fields of "survey_question_answers" */
export type Survey_Question_Answers_Aggregate_Fields = {
  __typename?: 'survey_question_answers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_Question_Answers_Max_Fields>;
  min?: Maybe<Survey_Question_Answers_Min_Fields>;
};

/** aggregate fields of "survey_question_answers" */
export type Survey_Question_Answers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_Question_Answers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "survey_question_answers". All fields are combined with a logical 'AND'. */
export type Survey_Question_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_Question_Answers_Bool_Exp>>;
  _not?: InputMaybe<Survey_Question_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_Question_Answers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  survey_question?: InputMaybe<Survey_Question_Bool_Exp>;
  survey_question_answer_options?: InputMaybe<
    Survey_Question_Answer_Option_Bool_Exp
  >;
  survey_question_answer_options_aggregate?: InputMaybe<
    Survey_Question_Answer_Option_Aggregate_Bool_Exp
  >;
  survey_question_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_question_answers" */
export enum Survey_Question_Answers_Constraint {
  /** unique or primary key constraint on columns "id" */
  SurveyQuestionAnswersPkey = 'survey_question_answers_pkey',
}

/** input type for inserting data into table "survey_question_answers" */
export type Survey_Question_Answers_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  survey_question?: InputMaybe<Survey_Question_Obj_Rel_Insert_Input>;
  survey_question_answer_options?: InputMaybe<
    Survey_Question_Answer_Option_Arr_Rel_Insert_Input
  >;
  survey_question_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Survey_Question_Answers_Max_Fields = {
  __typename?: 'survey_question_answers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  survey_question_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Survey_Question_Answers_Min_Fields = {
  __typename?: 'survey_question_answers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  survey_question_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "survey_question_answers" */
export type Survey_Question_Answers_Mutation_Response = {
  __typename?: 'survey_question_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_Question_Answers>;
};

/** input type for inserting object relation for remote table "survey_question_answers" */
export type Survey_Question_Answers_Obj_Rel_Insert_Input = {
  data: Survey_Question_Answers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_Question_Answers_On_Conflict>;
};

/** on_conflict condition type for table "survey_question_answers" */
export type Survey_Question_Answers_On_Conflict = {
  constraint: Survey_Question_Answers_Constraint;
  update_columns?: Array<Survey_Question_Answers_Update_Column>;
  where?: InputMaybe<Survey_Question_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_question_answers". */
export type Survey_Question_Answers_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  survey_question?: InputMaybe<Survey_Question_Order_By>;
  survey_question_answer_options_aggregate?: InputMaybe<
    Survey_Question_Answer_Option_Aggregate_Order_By
  >;
  survey_question_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_question_answers */
export type Survey_Question_Answers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "survey_question_answers" */
export enum Survey_Question_Answers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SurveyQuestionId = 'survey_question_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "survey_question_answers" */
export type Survey_Question_Answers_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  survey_question_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "survey_question_answers" */
export type Survey_Question_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_Question_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_Question_Answers_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  survey_question_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "survey_question_answers" */
export enum Survey_Question_Answers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SurveyQuestionId = 'survey_question_id',
  /** column name */
  UserId = 'user_id',
}

export type Survey_Question_Answers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_Question_Answers_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_Question_Answers_Bool_Exp;
};

/** input type for inserting array relation for remote table "survey_question" */
export type Survey_Question_Arr_Rel_Insert_Input = {
  data: Array<Survey_Question_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_Question_On_Conflict>;
};

/** Boolean expression to filter rows from the table "survey_question". All fields are combined with a logical 'AND'. */
export type Survey_Question_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_Question_Bool_Exp>>;
  _not?: InputMaybe<Survey_Question_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_Question_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  kind?: InputMaybe<Survey_Question_Kind_Enum_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  survey?: InputMaybe<Survey_Bool_Exp>;
  survey_name?: InputMaybe<String_Comparison_Exp>;
  survey_question_options?: InputMaybe<Survey_Question_Options_Bool_Exp>;
  survey_question_options_aggregate?: InputMaybe<
    Survey_Question_Options_Aggregate_Bool_Exp
  >;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_question" */
export enum Survey_Question_Constraint {
  /** unique or primary key constraint on columns "id" */
  SurveyQuestionPkey = 'survey_question_pkey',
}

/** input type for inserting data into table "survey_question" */
export type Survey_Question_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Survey_Question_Kind_Enum>;
  question?: InputMaybe<Scalars['String']>;
  survey?: InputMaybe<Survey_Obj_Rel_Insert_Input>;
  survey_name?: InputMaybe<Scalars['String']>;
  survey_question_options?: InputMaybe<
    Survey_Question_Options_Arr_Rel_Insert_Input
  >;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** stores the different kind of question options possible for a survey */
export type Survey_Question_Kind = {
  __typename?: 'survey_question_kind';
  kind: Scalars['String'];
  score_range?: Maybe<Scalars['String']>;
};

/** aggregated selection of "survey_question_kind" */
export type Survey_Question_Kind_Aggregate = {
  __typename?: 'survey_question_kind_aggregate';
  aggregate?: Maybe<Survey_Question_Kind_Aggregate_Fields>;
  nodes: Array<Survey_Question_Kind>;
};

/** aggregate fields of "survey_question_kind" */
export type Survey_Question_Kind_Aggregate_Fields = {
  __typename?: 'survey_question_kind_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_Question_Kind_Max_Fields>;
  min?: Maybe<Survey_Question_Kind_Min_Fields>;
};

/** aggregate fields of "survey_question_kind" */
export type Survey_Question_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_Question_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "survey_question_kind". All fields are combined with a logical 'AND'. */
export type Survey_Question_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_Question_Kind_Bool_Exp>>;
  _not?: InputMaybe<Survey_Question_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_Question_Kind_Bool_Exp>>;
  kind?: InputMaybe<String_Comparison_Exp>;
  score_range?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_question_kind" */
export enum Survey_Question_Kind_Constraint {
  /** unique or primary key constraint on columns "kind" */
  SurveyQuestionKindPkey = 'survey_question_kind_pkey',
}

export enum Survey_Question_Kind_Enum {
  Checkbox = 'checkbox',
  Dropdown = 'dropdown',
  Radio = 'radio',
  /** 10 */
  Rating = 'rating',
  Text = 'text',
}

/** Boolean expression to compare columns of type "survey_question_kind_enum". All fields are combined with logical 'AND'. */
export type Survey_Question_Kind_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Survey_Question_Kind_Enum>;
  _in?: InputMaybe<Array<Survey_Question_Kind_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Survey_Question_Kind_Enum>;
  _nin?: InputMaybe<Array<Survey_Question_Kind_Enum>>;
};

/** input type for inserting data into table "survey_question_kind" */
export type Survey_Question_Kind_Insert_Input = {
  kind?: InputMaybe<Scalars['String']>;
  score_range?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Survey_Question_Kind_Max_Fields = {
  __typename?: 'survey_question_kind_max_fields';
  kind?: Maybe<Scalars['String']>;
  score_range?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Survey_Question_Kind_Min_Fields = {
  __typename?: 'survey_question_kind_min_fields';
  kind?: Maybe<Scalars['String']>;
  score_range?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "survey_question_kind" */
export type Survey_Question_Kind_Mutation_Response = {
  __typename?: 'survey_question_kind_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_Question_Kind>;
};

/** on_conflict condition type for table "survey_question_kind" */
export type Survey_Question_Kind_On_Conflict = {
  constraint: Survey_Question_Kind_Constraint;
  update_columns?: Array<Survey_Question_Kind_Update_Column>;
  where?: InputMaybe<Survey_Question_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_question_kind". */
export type Survey_Question_Kind_Order_By = {
  kind?: InputMaybe<Order_By>;
  score_range?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_question_kind */
export type Survey_Question_Kind_Pk_Columns_Input = {
  kind: Scalars['String'];
};

/** select columns of table "survey_question_kind" */
export enum Survey_Question_Kind_Select_Column {
  /** column name */
  Kind = 'kind',
  /** column name */
  ScoreRange = 'score_range',
}

/** input type for updating data in table "survey_question_kind" */
export type Survey_Question_Kind_Set_Input = {
  kind?: InputMaybe<Scalars['String']>;
  score_range?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "survey_question_kind" */
export type Survey_Question_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_Question_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_Question_Kind_Stream_Cursor_Value_Input = {
  kind?: InputMaybe<Scalars['String']>;
  score_range?: InputMaybe<Scalars['String']>;
};

/** update columns of table "survey_question_kind" */
export enum Survey_Question_Kind_Update_Column {
  /** column name */
  Kind = 'kind',
  /** column name */
  ScoreRange = 'score_range',
}

export type Survey_Question_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_Question_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_Question_Kind_Bool_Exp;
};

/** aggregate max on columns */
export type Survey_Question_Max_Fields = {
  __typename?: 'survey_question_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  survey_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "survey_question" */
export type Survey_Question_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  survey_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Survey_Question_Min_Fields = {
  __typename?: 'survey_question_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  question?: Maybe<Scalars['String']>;
  survey_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "survey_question" */
export type Survey_Question_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  survey_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "survey_question" */
export type Survey_Question_Mutation_Response = {
  __typename?: 'survey_question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_Question>;
};

/** input type for inserting object relation for remote table "survey_question" */
export type Survey_Question_Obj_Rel_Insert_Input = {
  data: Survey_Question_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_Question_On_Conflict>;
};

/** on_conflict condition type for table "survey_question" */
export type Survey_Question_On_Conflict = {
  constraint: Survey_Question_Constraint;
  update_columns?: Array<Survey_Question_Update_Column>;
  where?: InputMaybe<Survey_Question_Bool_Exp>;
};

/** holds all the options possible for a question in a survey */
export type Survey_Question_Options = {
  __typename?: 'survey_question_options';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  option: Scalars['String'];
  /** An object relationship */
  survey_question: Survey_Question;
  survey_question_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "survey_question_options" */
export type Survey_Question_Options_Aggregate = {
  __typename?: 'survey_question_options_aggregate';
  aggregate?: Maybe<Survey_Question_Options_Aggregate_Fields>;
  nodes: Array<Survey_Question_Options>;
};

export type Survey_Question_Options_Aggregate_Bool_Exp = {
  count?: InputMaybe<Survey_Question_Options_Aggregate_Bool_Exp_Count>;
};

export type Survey_Question_Options_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Survey_Question_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Survey_Question_Options_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "survey_question_options" */
export type Survey_Question_Options_Aggregate_Fields = {
  __typename?: 'survey_question_options_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_Question_Options_Max_Fields>;
  min?: Maybe<Survey_Question_Options_Min_Fields>;
};

/** aggregate fields of "survey_question_options" */
export type Survey_Question_Options_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_Question_Options_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "survey_question_options" */
export type Survey_Question_Options_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Survey_Question_Options_Max_Order_By>;
  min?: InputMaybe<Survey_Question_Options_Min_Order_By>;
};

/** input type for inserting array relation for remote table "survey_question_options" */
export type Survey_Question_Options_Arr_Rel_Insert_Input = {
  data: Array<Survey_Question_Options_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_Question_Options_On_Conflict>;
};

/** Boolean expression to filter rows from the table "survey_question_options". All fields are combined with a logical 'AND'. */
export type Survey_Question_Options_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_Question_Options_Bool_Exp>>;
  _not?: InputMaybe<Survey_Question_Options_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_Question_Options_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  option?: InputMaybe<String_Comparison_Exp>;
  survey_question?: InputMaybe<Survey_Question_Bool_Exp>;
  survey_question_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_question_options" */
export enum Survey_Question_Options_Constraint {
  /** unique or primary key constraint on columns "id" */
  SurveyQuestionOptionsPkey = 'survey_question_options_pkey',
}

/** input type for inserting data into table "survey_question_options" */
export type Survey_Question_Options_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  option?: InputMaybe<Scalars['String']>;
  survey_question?: InputMaybe<Survey_Question_Obj_Rel_Insert_Input>;
  survey_question_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Survey_Question_Options_Max_Fields = {
  __typename?: 'survey_question_options_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  option?: Maybe<Scalars['String']>;
  survey_question_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "survey_question_options" */
export type Survey_Question_Options_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  option?: InputMaybe<Order_By>;
  survey_question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Survey_Question_Options_Min_Fields = {
  __typename?: 'survey_question_options_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  option?: Maybe<Scalars['String']>;
  survey_question_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "survey_question_options" */
export type Survey_Question_Options_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  option?: InputMaybe<Order_By>;
  survey_question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "survey_question_options" */
export type Survey_Question_Options_Mutation_Response = {
  __typename?: 'survey_question_options_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_Question_Options>;
};

/** input type for inserting object relation for remote table "survey_question_options" */
export type Survey_Question_Options_Obj_Rel_Insert_Input = {
  data: Survey_Question_Options_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_Question_Options_On_Conflict>;
};

/** on_conflict condition type for table "survey_question_options" */
export type Survey_Question_Options_On_Conflict = {
  constraint: Survey_Question_Options_Constraint;
  update_columns?: Array<Survey_Question_Options_Update_Column>;
  where?: InputMaybe<Survey_Question_Options_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_question_options". */
export type Survey_Question_Options_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  option?: InputMaybe<Order_By>;
  survey_question?: InputMaybe<Survey_Question_Order_By>;
  survey_question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_question_options */
export type Survey_Question_Options_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "survey_question_options" */
export enum Survey_Question_Options_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Option = 'option',
  /** column name */
  SurveyQuestionId = 'survey_question_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "survey_question_options" */
export type Survey_Question_Options_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  option?: InputMaybe<Scalars['String']>;
  survey_question_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "survey_question_options" */
export type Survey_Question_Options_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_Question_Options_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_Question_Options_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  option?: InputMaybe<Scalars['String']>;
  survey_question_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "survey_question_options" */
export enum Survey_Question_Options_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Option = 'option',
  /** column name */
  SurveyQuestionId = 'survey_question_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Survey_Question_Options_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_Question_Options_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_Question_Options_Bool_Exp;
};

/** Ordering options when selecting data from "survey_question". */
export type Survey_Question_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  survey?: InputMaybe<Survey_Order_By>;
  survey_name?: InputMaybe<Order_By>;
  survey_question_options_aggregate?: InputMaybe<
    Survey_Question_Options_Aggregate_Order_By
  >;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_question */
export type Survey_Question_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "survey_question" */
export enum Survey_Question_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Question = 'question',
  /** column name */
  SurveyName = 'survey_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "survey_question" */
export type Survey_Question_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Survey_Question_Kind_Enum>;
  question?: InputMaybe<Scalars['String']>;
  survey_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "survey_question" */
export type Survey_Question_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_Question_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  kind?: InputMaybe<Survey_Question_Kind_Enum>;
  question?: InputMaybe<Scalars['String']>;
  survey_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "survey_question" */
export enum Survey_Question_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Kind = 'kind',
  /** column name */
  Question = 'question',
  /** column name */
  SurveyName = 'survey_name',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Survey_Question_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_Question_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_Question_Bool_Exp;
};

/** select columns of table "survey" */
export enum Survey_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  RequestedBy = 'requested_by',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  SurveyDescription = 'survey_description',
  /** column name */
  SurveyHeader = 'survey_header',
  /** column name */
  SurveyName = 'survey_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** input type for updating data in table "survey" */
export type Survey_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  requested_by?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  survey_description?: InputMaybe<Scalars['String']>;
  survey_header?: InputMaybe<Scalars['String']>;
  survey_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "survey" */
export type Survey_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  requested_by?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  survey_description?: InputMaybe<Scalars['String']>;
  survey_header?: InputMaybe<Scalars['String']>;
  survey_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** update columns of table "survey" */
export enum Survey_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  RequestedBy = 'requested_by',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  SurveyDescription = 'survey_description',
  /** column name */
  SurveyHeader = 'survey_header',
  /** column name */
  SurveyName = 'survey_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
}

export type Survey_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_Bool_Exp;
};

/** information on a survey being conducted  */
export type Survey_V2 = {
  __typename?: 'survey_v2';
  additional_info?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  ended_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  requested_by: Scalars['String'];
  started_at: Scalars['timestamptz'];
  survey_description?: Maybe<Scalars['String']>;
  survey_name: Scalars['String'];
  /** An array relationship */
  survey_questions: Array<Survey_V2_Question>;
  /** An aggregate relationship */
  survey_questions_aggregate: Survey_V2_Question_Aggregate;
  /** An array relationship */
  survey_responses: Array<Survey_V2_Response>;
  /** An aggregate relationship */
  survey_responses_aggregate: Survey_V2_Response_Aggregate;
  survey_title?: Maybe<Scalars['String']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
};

/** information on a survey being conducted  */
export type Survey_V2Additional_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** information on a survey being conducted  */
export type Survey_V2Survey_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Bool_Exp>;
};

/** information on a survey being conducted  */
export type Survey_V2Survey_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Bool_Exp>;
};

/** information on a survey being conducted  */
export type Survey_V2Survey_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Bool_Exp>;
};

/** information on a survey being conducted  */
export type Survey_V2Survey_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Bool_Exp>;
};

/** information on a survey being conducted  */
export type Survey_V2Template_ConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "survey_v2" */
export type Survey_V2_Aggregate = {
  __typename?: 'survey_v2_aggregate';
  aggregate?: Maybe<Survey_V2_Aggregate_Fields>;
  nodes: Array<Survey_V2>;
};

/** aggregate fields of "survey_v2" */
export type Survey_V2_Aggregate_Fields = {
  __typename?: 'survey_v2_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_V2_Max_Fields>;
  min?: Maybe<Survey_V2_Min_Fields>;
};

/** aggregate fields of "survey_v2" */
export type Survey_V2_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_V2_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Survey_V2_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "survey_v2". All fields are combined with a logical 'AND'. */
export type Survey_V2_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_V2_Bool_Exp>>;
  _not?: InputMaybe<Survey_V2_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_V2_Bool_Exp>>;
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ended_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  requested_by?: InputMaybe<String_Comparison_Exp>;
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  survey_description?: InputMaybe<String_Comparison_Exp>;
  survey_name?: InputMaybe<String_Comparison_Exp>;
  survey_questions?: InputMaybe<Survey_V2_Question_Bool_Exp>;
  survey_questions_aggregate?: InputMaybe<
    Survey_V2_Question_Aggregate_Bool_Exp
  >;
  survey_responses?: InputMaybe<Survey_V2_Response_Bool_Exp>;
  survey_responses_aggregate?: InputMaybe<
    Survey_V2_Response_Aggregate_Bool_Exp
  >;
  survey_title?: InputMaybe<String_Comparison_Exp>;
  template_config?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_v2" */
export enum Survey_V2_Constraint {
  /** unique or primary key constraint on columns "id" */
  SurveyV2Pkey = 'survey_v2_pkey',
  /** unique or primary key constraint on columns "survey_name" */
  SurveyV2SurveyNameKey = 'survey_v2_survey_name_key',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Survey_V2_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']>>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Survey_V2_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Survey_V2_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "survey_v2" */
export type Survey_V2_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  requested_by?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  survey_description?: InputMaybe<Scalars['String']>;
  survey_name?: InputMaybe<Scalars['String']>;
  survey_questions?: InputMaybe<Survey_V2_Question_Arr_Rel_Insert_Input>;
  survey_responses?: InputMaybe<Survey_V2_Response_Arr_Rel_Insert_Input>;
  survey_title?: InputMaybe<Scalars['String']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Survey_V2_Max_Fields = {
  __typename?: 'survey_v2_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  requested_by?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  survey_description?: Maybe<Scalars['String']>;
  survey_name?: Maybe<Scalars['String']>;
  survey_title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Survey_V2_Min_Fields = {
  __typename?: 'survey_v2_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  requested_by?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  survey_description?: Maybe<Scalars['String']>;
  survey_name?: Maybe<Scalars['String']>;
  survey_title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "survey_v2" */
export type Survey_V2_Mutation_Response = {
  __typename?: 'survey_v2_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_V2>;
};

/** input type for inserting object relation for remote table "survey_v2" */
export type Survey_V2_Obj_Rel_Insert_Input = {
  data: Survey_V2_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_V2_On_Conflict>;
};

/** on_conflict condition type for table "survey_v2" */
export type Survey_V2_On_Conflict = {
  constraint: Survey_V2_Constraint;
  update_columns?: Array<Survey_V2_Update_Column>;
  where?: InputMaybe<Survey_V2_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_v2". */
export type Survey_V2_Order_By = {
  additional_info?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  requested_by?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  survey_description?: InputMaybe<Order_By>;
  survey_name?: InputMaybe<Order_By>;
  survey_questions_aggregate?: InputMaybe<
    Survey_V2_Question_Aggregate_Order_By
  >;
  survey_responses_aggregate?: InputMaybe<
    Survey_V2_Response_Aggregate_Order_By
  >;
  survey_title?: InputMaybe<Order_By>;
  template_config?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_v2 */
export type Survey_V2_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Survey_V2_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
};

/** contains all questions for a survey  */
export type Survey_V2_Question = {
  __typename?: 'survey_v2_question';
  created_at: Scalars['timestamptz'];
  ended_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  is_mandatory: Scalars['Boolean'];
  kind: Survey_V2_Question_Kind_Enum;
  /** overrides default config provided in survey_question_kind */
  kind_config_override?: Maybe<Scalars['String']>;
  /** this decides in which order to show the questions to user */
  position: Scalars['Int'];
  question: Scalars['String'];
  started_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  survey?: Maybe<Survey_V2>;
  survey_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  survey_question_kind: Survey_V2_Question_Kind;
  /** An array relationship */
  survey_question_options: Array<Survey_V2_Question_Option>;
  /** An aggregate relationship */
  survey_question_options_aggregate: Survey_V2_Question_Option_Aggregate;
  /** An array relationship */
  survey_response_answers: Array<Survey_V2_Response_Answer>;
  /** An aggregate relationship */
  survey_response_answers_aggregate: Survey_V2_Response_Answer_Aggregate;
  /** jsonb column to store any UI template related configuration */
  template_config?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
};

/** contains all questions for a survey  */
export type Survey_V2_QuestionSurvey_Question_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Option_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Option_Bool_Exp>;
};

/** contains all questions for a survey  */
export type Survey_V2_QuestionSurvey_Question_Options_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Question_Option_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Question_Option_Order_By>>;
  where?: InputMaybe<Survey_V2_Question_Option_Bool_Exp>;
};

/** contains all questions for a survey  */
export type Survey_V2_QuestionSurvey_Response_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
};

/** contains all questions for a survey  */
export type Survey_V2_QuestionSurvey_Response_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
};

/** contains all questions for a survey  */
export type Survey_V2_QuestionTemplate_ConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "survey_v2_question" */
export type Survey_V2_Question_Aggregate = {
  __typename?: 'survey_v2_question_aggregate';
  aggregate?: Maybe<Survey_V2_Question_Aggregate_Fields>;
  nodes: Array<Survey_V2_Question>;
};

export type Survey_V2_Question_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Survey_V2_Question_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Survey_V2_Question_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Survey_V2_Question_Aggregate_Bool_Exp_Count>;
};

export type Survey_V2_Question_Aggregate_Bool_Exp_Bool_And = {
  arguments: Survey_V2_Question_Select_Column_Survey_V2_Question_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Survey_V2_Question_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Survey_V2_Question_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Survey_V2_Question_Select_Column_Survey_V2_Question_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Survey_V2_Question_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Survey_V2_Question_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Survey_V2_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Survey_V2_Question_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "survey_v2_question" */
export type Survey_V2_Question_Aggregate_Fields = {
  __typename?: 'survey_v2_question_aggregate_fields';
  avg?: Maybe<Survey_V2_Question_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Survey_V2_Question_Max_Fields>;
  min?: Maybe<Survey_V2_Question_Min_Fields>;
  stddev?: Maybe<Survey_V2_Question_Stddev_Fields>;
  stddev_pop?: Maybe<Survey_V2_Question_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Survey_V2_Question_Stddev_Samp_Fields>;
  sum?: Maybe<Survey_V2_Question_Sum_Fields>;
  var_pop?: Maybe<Survey_V2_Question_Var_Pop_Fields>;
  var_samp?: Maybe<Survey_V2_Question_Var_Samp_Fields>;
  variance?: Maybe<Survey_V2_Question_Variance_Fields>;
};

/** aggregate fields of "survey_v2_question" */
export type Survey_V2_Question_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_V2_Question_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "survey_v2_question" */
export type Survey_V2_Question_Aggregate_Order_By = {
  avg?: InputMaybe<Survey_V2_Question_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Survey_V2_Question_Max_Order_By>;
  min?: InputMaybe<Survey_V2_Question_Min_Order_By>;
  stddev?: InputMaybe<Survey_V2_Question_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Survey_V2_Question_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Survey_V2_Question_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Survey_V2_Question_Sum_Order_By>;
  var_pop?: InputMaybe<Survey_V2_Question_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Survey_V2_Question_Var_Samp_Order_By>;
  variance?: InputMaybe<Survey_V2_Question_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Survey_V2_Question_Append_Input = {
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "survey_v2_question" */
export type Survey_V2_Question_Arr_Rel_Insert_Input = {
  data: Array<Survey_V2_Question_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_V2_Question_On_Conflict>;
};

/** aggregate avg on columns */
export type Survey_V2_Question_Avg_Fields = {
  __typename?: 'survey_v2_question_avg_fields';
  /** this decides in which order to show the questions to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "survey_v2_question" */
export type Survey_V2_Question_Avg_Order_By = {
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "survey_v2_question". All fields are combined with a logical 'AND'. */
export type Survey_V2_Question_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_V2_Question_Bool_Exp>>;
  _not?: InputMaybe<Survey_V2_Question_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_V2_Question_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ended_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_mandatory?: InputMaybe<Boolean_Comparison_Exp>;
  kind?: InputMaybe<Survey_V2_Question_Kind_Enum_Comparison_Exp>;
  kind_config_override?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  survey?: InputMaybe<Survey_V2_Bool_Exp>;
  survey_id?: InputMaybe<Uuid_Comparison_Exp>;
  survey_question_kind?: InputMaybe<Survey_V2_Question_Kind_Bool_Exp>;
  survey_question_options?: InputMaybe<Survey_V2_Question_Option_Bool_Exp>;
  survey_question_options_aggregate?: InputMaybe<
    Survey_V2_Question_Option_Aggregate_Bool_Exp
  >;
  survey_response_answers?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
  survey_response_answers_aggregate?: InputMaybe<
    Survey_V2_Response_Answer_Aggregate_Bool_Exp
  >;
  template_config?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_v2_question" */
export enum Survey_V2_Question_Constraint {
  /** unique or primary key constraint on columns "id" */
  SurveyV2QuestionPkey = 'survey_v2_question_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Survey_V2_Question_Delete_At_Path_Input = {
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Survey_V2_Question_Delete_Elem_Input = {
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Survey_V2_Question_Delete_Key_Input = {
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "survey_v2_question" */
export type Survey_V2_Question_Inc_Input = {
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "survey_v2_question" */
export type Survey_V2_Question_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_mandatory?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Survey_V2_Question_Kind_Enum>;
  /** overrides default config provided in survey_question_kind */
  kind_config_override?: InputMaybe<Scalars['String']>;
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  survey?: InputMaybe<Survey_V2_Obj_Rel_Insert_Input>;
  survey_id?: InputMaybe<Scalars['uuid']>;
  survey_question_kind?: InputMaybe<
    Survey_V2_Question_Kind_Obj_Rel_Insert_Input
  >;
  survey_question_options?: InputMaybe<
    Survey_V2_Question_Option_Arr_Rel_Insert_Input
  >;
  survey_response_answers?: InputMaybe<
    Survey_V2_Response_Answer_Arr_Rel_Insert_Input
  >;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** stores the different kind of question options possible for a survey */
export type Survey_V2_Question_Kind = {
  __typename?: 'survey_v2_question_kind';
  default_config?: Maybe<Scalars['String']>;
  kind: Scalars['String'];
};

/** aggregated selection of "survey_v2_question_kind" */
export type Survey_V2_Question_Kind_Aggregate = {
  __typename?: 'survey_v2_question_kind_aggregate';
  aggregate?: Maybe<Survey_V2_Question_Kind_Aggregate_Fields>;
  nodes: Array<Survey_V2_Question_Kind>;
};

/** aggregate fields of "survey_v2_question_kind" */
export type Survey_V2_Question_Kind_Aggregate_Fields = {
  __typename?: 'survey_v2_question_kind_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_V2_Question_Kind_Max_Fields>;
  min?: Maybe<Survey_V2_Question_Kind_Min_Fields>;
};

/** aggregate fields of "survey_v2_question_kind" */
export type Survey_V2_Question_Kind_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_V2_Question_Kind_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "survey_v2_question_kind". All fields are combined with a logical 'AND'. */
export type Survey_V2_Question_Kind_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_V2_Question_Kind_Bool_Exp>>;
  _not?: InputMaybe<Survey_V2_Question_Kind_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_V2_Question_Kind_Bool_Exp>>;
  default_config?: InputMaybe<String_Comparison_Exp>;
  kind?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_v2_question_kind" */
export enum Survey_V2_Question_Kind_Constraint {
  /** unique or primary key constraint on columns "kind" */
  SurveyV2QuestionKindPkey = 'survey_v2_question_kind_pkey',
}

export enum Survey_V2_Question_Kind_Enum {
  Checkbox = 'checkbox',
  Dropdown = 'dropdown',
  Radio = 'radio',
  /** 10 */
  Rating = 'rating',
  Text = 'text',
}

/** Boolean expression to compare columns of type "survey_v2_question_kind_enum". All fields are combined with logical 'AND'. */
export type Survey_V2_Question_Kind_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Survey_V2_Question_Kind_Enum>;
  _in?: InputMaybe<Array<Survey_V2_Question_Kind_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Survey_V2_Question_Kind_Enum>;
  _nin?: InputMaybe<Array<Survey_V2_Question_Kind_Enum>>;
};

/** input type for inserting data into table "survey_v2_question_kind" */
export type Survey_V2_Question_Kind_Insert_Input = {
  default_config?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Survey_V2_Question_Kind_Max_Fields = {
  __typename?: 'survey_v2_question_kind_max_fields';
  default_config?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Survey_V2_Question_Kind_Min_Fields = {
  __typename?: 'survey_v2_question_kind_min_fields';
  default_config?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "survey_v2_question_kind" */
export type Survey_V2_Question_Kind_Mutation_Response = {
  __typename?: 'survey_v2_question_kind_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_V2_Question_Kind>;
};

/** input type for inserting object relation for remote table "survey_v2_question_kind" */
export type Survey_V2_Question_Kind_Obj_Rel_Insert_Input = {
  data: Survey_V2_Question_Kind_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_V2_Question_Kind_On_Conflict>;
};

/** on_conflict condition type for table "survey_v2_question_kind" */
export type Survey_V2_Question_Kind_On_Conflict = {
  constraint: Survey_V2_Question_Kind_Constraint;
  update_columns?: Array<Survey_V2_Question_Kind_Update_Column>;
  where?: InputMaybe<Survey_V2_Question_Kind_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_v2_question_kind". */
export type Survey_V2_Question_Kind_Order_By = {
  default_config?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_v2_question_kind */
export type Survey_V2_Question_Kind_Pk_Columns_Input = {
  kind: Scalars['String'];
};

/** select columns of table "survey_v2_question_kind" */
export enum Survey_V2_Question_Kind_Select_Column {
  /** column name */
  DefaultConfig = 'default_config',
  /** column name */
  Kind = 'kind',
}

/** input type for updating data in table "survey_v2_question_kind" */
export type Survey_V2_Question_Kind_Set_Input = {
  default_config?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "survey_v2_question_kind" */
export type Survey_V2_Question_Kind_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_V2_Question_Kind_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_V2_Question_Kind_Stream_Cursor_Value_Input = {
  default_config?: InputMaybe<Scalars['String']>;
  kind?: InputMaybe<Scalars['String']>;
};

/** update columns of table "survey_v2_question_kind" */
export enum Survey_V2_Question_Kind_Update_Column {
  /** column name */
  DefaultConfig = 'default_config',
  /** column name */
  Kind = 'kind',
}

export type Survey_V2_Question_Kind_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_V2_Question_Kind_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_V2_Question_Kind_Bool_Exp;
};

/** aggregate max on columns */
export type Survey_V2_Question_Max_Fields = {
  __typename?: 'survey_v2_question_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** overrides default config provided in survey_question_kind */
  kind_config_override?: Maybe<Scalars['String']>;
  /** this decides in which order to show the questions to user */
  position?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  survey_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "survey_v2_question" */
export type Survey_V2_Question_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** overrides default config provided in survey_question_kind */
  kind_config_override?: InputMaybe<Order_By>;
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  survey_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Survey_V2_Question_Min_Fields = {
  __typename?: 'survey_v2_question_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  /** overrides default config provided in survey_question_kind */
  kind_config_override?: Maybe<Scalars['String']>;
  /** this decides in which order to show the questions to user */
  position?: Maybe<Scalars['Int']>;
  question?: Maybe<Scalars['String']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  survey_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "survey_v2_question" */
export type Survey_V2_Question_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  /** overrides default config provided in survey_question_kind */
  kind_config_override?: InputMaybe<Order_By>;
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  survey_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "survey_v2_question" */
export type Survey_V2_Question_Mutation_Response = {
  __typename?: 'survey_v2_question_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_V2_Question>;
};

/** input type for inserting object relation for remote table "survey_v2_question" */
export type Survey_V2_Question_Obj_Rel_Insert_Input = {
  data: Survey_V2_Question_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_V2_Question_On_Conflict>;
};

/** on_conflict condition type for table "survey_v2_question" */
export type Survey_V2_Question_On_Conflict = {
  constraint: Survey_V2_Question_Constraint;
  update_columns?: Array<Survey_V2_Question_Update_Column>;
  where?: InputMaybe<Survey_V2_Question_Bool_Exp>;
};

/** holds all the options possible for a question in a survey */
export type Survey_V2_Question_Option = {
  __typename?: 'survey_v2_question_option';
  /** An object relationship */
  additional_info_config?: Maybe<
    Survey_V2_Question_Option_Additional_Info_Config
  >;
  additional_info_config_id?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  ended_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  option: Scalars['String'];
  /** this decides in which order to show the options to user */
  position: Scalars['Int'];
  started_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  survey_question: Survey_V2_Question;
  survey_question_id: Scalars['uuid'];
  /** jsonb column to store any UI template related configuration */
  template_config?: Maybe<Scalars['jsonb']>;
  updated_at: Scalars['timestamptz'];
};

/** holds all the options possible for a question in a survey */
export type Survey_V2_Question_OptionTemplate_ConfigArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "survey_v2_question_option_additional_info_config" */
export type Survey_V2_Question_Option_Additional_Info_Config = {
  __typename?: 'survey_v2_question_option_additional_info_config';
  id: Scalars['uuid'];
  info_description?: Maybe<Scalars['String']>;
  is_mandatory: Scalars['Boolean'];
};

/** aggregated selection of "survey_v2_question_option_additional_info_config" */
export type Survey_V2_Question_Option_Additional_Info_Config_Aggregate = {
  __typename?: 'survey_v2_question_option_additional_info_config_aggregate';
  aggregate?: Maybe<
    Survey_V2_Question_Option_Additional_Info_Config_Aggregate_Fields
  >;
  nodes: Array<Survey_V2_Question_Option_Additional_Info_Config>;
};

/** aggregate fields of "survey_v2_question_option_additional_info_config" */
export type Survey_V2_Question_Option_Additional_Info_Config_Aggregate_Fields = {
  __typename?: 'survey_v2_question_option_additional_info_config_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_V2_Question_Option_Additional_Info_Config_Max_Fields>;
  min?: Maybe<Survey_V2_Question_Option_Additional_Info_Config_Min_Fields>;
};

/** aggregate fields of "survey_v2_question_option_additional_info_config" */
export type Survey_V2_Question_Option_Additional_Info_Config_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<
    Array<Survey_V2_Question_Option_Additional_Info_Config_Select_Column>
  >;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "survey_v2_question_option_additional_info_config". All fields are combined with a logical 'AND'. */
export type Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp = {
  _and?: InputMaybe<
    Array<Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp>
  >;
  _not?: InputMaybe<Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp>;
  _or?: InputMaybe<
    Array<Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp>
  >;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  info_description?: InputMaybe<String_Comparison_Exp>;
  is_mandatory?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_v2_question_option_additional_info_config" */
export enum Survey_V2_Question_Option_Additional_Info_Config_Constraint {
  /** unique or primary key constraint on columns "id" */
  SurveyV2QuestionOptionAdditionalInfoConfigPkey = 'survey_v2_question_option_additional_info_config_pkey',
}

/** input type for inserting data into table "survey_v2_question_option_additional_info_config" */
export type Survey_V2_Question_Option_Additional_Info_Config_Insert_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  info_description?: InputMaybe<Scalars['String']>;
  is_mandatory?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Survey_V2_Question_Option_Additional_Info_Config_Max_Fields = {
  __typename?: 'survey_v2_question_option_additional_info_config_max_fields';
  id?: Maybe<Scalars['uuid']>;
  info_description?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Survey_V2_Question_Option_Additional_Info_Config_Min_Fields = {
  __typename?: 'survey_v2_question_option_additional_info_config_min_fields';
  id?: Maybe<Scalars['uuid']>;
  info_description?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "survey_v2_question_option_additional_info_config" */
export type Survey_V2_Question_Option_Additional_Info_Config_Mutation_Response = {
  __typename?: 'survey_v2_question_option_additional_info_config_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_V2_Question_Option_Additional_Info_Config>;
};

/** input type for inserting object relation for remote table "survey_v2_question_option_additional_info_config" */
export type Survey_V2_Question_Option_Additional_Info_Config_Obj_Rel_Insert_Input = {
  data: Survey_V2_Question_Option_Additional_Info_Config_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<
    Survey_V2_Question_Option_Additional_Info_Config_On_Conflict
  >;
};

/** on_conflict condition type for table "survey_v2_question_option_additional_info_config" */
export type Survey_V2_Question_Option_Additional_Info_Config_On_Conflict = {
  constraint: Survey_V2_Question_Option_Additional_Info_Config_Constraint;
  update_columns?: Array<
    Survey_V2_Question_Option_Additional_Info_Config_Update_Column
  >;
  where?: InputMaybe<Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_v2_question_option_additional_info_config". */
export type Survey_V2_Question_Option_Additional_Info_Config_Order_By = {
  id?: InputMaybe<Order_By>;
  info_description?: InputMaybe<Order_By>;
  is_mandatory?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_v2_question_option_additional_info_config */
export type Survey_V2_Question_Option_Additional_Info_Config_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "survey_v2_question_option_additional_info_config" */
export enum Survey_V2_Question_Option_Additional_Info_Config_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InfoDescription = 'info_description',
  /** column name */
  IsMandatory = 'is_mandatory',
}

/** input type for updating data in table "survey_v2_question_option_additional_info_config" */
export type Survey_V2_Question_Option_Additional_Info_Config_Set_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  info_description?: InputMaybe<Scalars['String']>;
  is_mandatory?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "survey_v2_question_option_additional_info_config" */
export type Survey_V2_Question_Option_Additional_Info_Config_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_V2_Question_Option_Additional_Info_Config_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_V2_Question_Option_Additional_Info_Config_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']>;
  info_description?: InputMaybe<Scalars['String']>;
  is_mandatory?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "survey_v2_question_option_additional_info_config" */
export enum Survey_V2_Question_Option_Additional_Info_Config_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InfoDescription = 'info_description',
  /** column name */
  IsMandatory = 'is_mandatory',
}

export type Survey_V2_Question_Option_Additional_Info_Config_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_V2_Question_Option_Additional_Info_Config_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp;
};

/** aggregated selection of "survey_v2_question_option" */
export type Survey_V2_Question_Option_Aggregate = {
  __typename?: 'survey_v2_question_option_aggregate';
  aggregate?: Maybe<Survey_V2_Question_Option_Aggregate_Fields>;
  nodes: Array<Survey_V2_Question_Option>;
};

export type Survey_V2_Question_Option_Aggregate_Bool_Exp = {
  count?: InputMaybe<Survey_V2_Question_Option_Aggregate_Bool_Exp_Count>;
};

export type Survey_V2_Question_Option_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Survey_V2_Question_Option_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Survey_V2_Question_Option_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "survey_v2_question_option" */
export type Survey_V2_Question_Option_Aggregate_Fields = {
  __typename?: 'survey_v2_question_option_aggregate_fields';
  avg?: Maybe<Survey_V2_Question_Option_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Survey_V2_Question_Option_Max_Fields>;
  min?: Maybe<Survey_V2_Question_Option_Min_Fields>;
  stddev?: Maybe<Survey_V2_Question_Option_Stddev_Fields>;
  stddev_pop?: Maybe<Survey_V2_Question_Option_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Survey_V2_Question_Option_Stddev_Samp_Fields>;
  sum?: Maybe<Survey_V2_Question_Option_Sum_Fields>;
  var_pop?: Maybe<Survey_V2_Question_Option_Var_Pop_Fields>;
  var_samp?: Maybe<Survey_V2_Question_Option_Var_Samp_Fields>;
  variance?: Maybe<Survey_V2_Question_Option_Variance_Fields>;
};

/** aggregate fields of "survey_v2_question_option" */
export type Survey_V2_Question_Option_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_V2_Question_Option_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Aggregate_Order_By = {
  avg?: InputMaybe<Survey_V2_Question_Option_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Survey_V2_Question_Option_Max_Order_By>;
  min?: InputMaybe<Survey_V2_Question_Option_Min_Order_By>;
  stddev?: InputMaybe<Survey_V2_Question_Option_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Survey_V2_Question_Option_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Survey_V2_Question_Option_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Survey_V2_Question_Option_Sum_Order_By>;
  var_pop?: InputMaybe<Survey_V2_Question_Option_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Survey_V2_Question_Option_Var_Samp_Order_By>;
  variance?: InputMaybe<Survey_V2_Question_Option_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Survey_V2_Question_Option_Append_Input = {
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Arr_Rel_Insert_Input = {
  data: Array<Survey_V2_Question_Option_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_V2_Question_Option_On_Conflict>;
};

/** aggregate avg on columns */
export type Survey_V2_Question_Option_Avg_Fields = {
  __typename?: 'survey_v2_question_option_avg_fields';
  /** this decides in which order to show the options to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Avg_Order_By = {
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "survey_v2_question_option". All fields are combined with a logical 'AND'. */
export type Survey_V2_Question_Option_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_V2_Question_Option_Bool_Exp>>;
  _not?: InputMaybe<Survey_V2_Question_Option_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_V2_Question_Option_Bool_Exp>>;
  additional_info_config?: InputMaybe<
    Survey_V2_Question_Option_Additional_Info_Config_Bool_Exp
  >;
  additional_info_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  ended_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  option?: InputMaybe<String_Comparison_Exp>;
  position?: InputMaybe<Int_Comparison_Exp>;
  started_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  survey_question?: InputMaybe<Survey_V2_Question_Bool_Exp>;
  survey_question_id?: InputMaybe<Uuid_Comparison_Exp>;
  template_config?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_v2_question_option" */
export enum Survey_V2_Question_Option_Constraint {
  /** unique or primary key constraint on columns "id" */
  SurveyV2QuestionOptionPkey = 'survey_v2_question_option_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Survey_V2_Question_Option_Delete_At_Path_Input = {
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Survey_V2_Question_Option_Delete_Elem_Input = {
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Survey_V2_Question_Option_Delete_Key_Input = {
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Inc_Input = {
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Insert_Input = {
  additional_info_config?: InputMaybe<
    Survey_V2_Question_Option_Additional_Info_Config_Obj_Rel_Insert_Input
  >;
  additional_info_config_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  option?: InputMaybe<Scalars['String']>;
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Scalars['Int']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  survey_question?: InputMaybe<Survey_V2_Question_Obj_Rel_Insert_Input>;
  survey_question_id?: InputMaybe<Scalars['uuid']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Survey_V2_Question_Option_Max_Fields = {
  __typename?: 'survey_v2_question_option_max_fields';
  additional_info_config_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  option?: Maybe<Scalars['String']>;
  /** this decides in which order to show the options to user */
  position?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  survey_question_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Max_Order_By = {
  additional_info_config_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  option?: InputMaybe<Order_By>;
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  survey_question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Survey_V2_Question_Option_Min_Fields = {
  __typename?: 'survey_v2_question_option_min_fields';
  additional_info_config_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  ended_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  option?: Maybe<Scalars['String']>;
  /** this decides in which order to show the options to user */
  position?: Maybe<Scalars['Int']>;
  started_at?: Maybe<Scalars['timestamptz']>;
  survey_question_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Min_Order_By = {
  additional_info_config_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  option?: InputMaybe<Order_By>;
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  survey_question_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Mutation_Response = {
  __typename?: 'survey_v2_question_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_V2_Question_Option>;
};

/** input type for inserting object relation for remote table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Obj_Rel_Insert_Input = {
  data: Survey_V2_Question_Option_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_V2_Question_Option_On_Conflict>;
};

/** on_conflict condition type for table "survey_v2_question_option" */
export type Survey_V2_Question_Option_On_Conflict = {
  constraint: Survey_V2_Question_Option_Constraint;
  update_columns?: Array<Survey_V2_Question_Option_Update_Column>;
  where?: InputMaybe<Survey_V2_Question_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_v2_question_option". */
export type Survey_V2_Question_Option_Order_By = {
  additional_info_config?: InputMaybe<
    Survey_V2_Question_Option_Additional_Info_Config_Order_By
  >;
  additional_info_config_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  option?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  survey_question?: InputMaybe<Survey_V2_Question_Order_By>;
  survey_question_id?: InputMaybe<Order_By>;
  template_config?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_v2_question_option */
export type Survey_V2_Question_Option_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Survey_V2_Question_Option_Prepend_Input = {
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "survey_v2_question_option" */
export enum Survey_V2_Question_Option_Select_Column {
  /** column name */
  AdditionalInfoConfigId = 'additional_info_config_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  Option = 'option',
  /** column name */
  Position = 'position',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  SurveyQuestionId = 'survey_question_id',
  /** column name */
  TemplateConfig = 'template_config',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Set_Input = {
  additional_info_config_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  option?: InputMaybe<Scalars['String']>;
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Scalars['Int']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  survey_question_id?: InputMaybe<Scalars['uuid']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Survey_V2_Question_Option_Stddev_Fields = {
  __typename?: 'survey_v2_question_option_stddev_fields';
  /** this decides in which order to show the options to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Stddev_Order_By = {
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Survey_V2_Question_Option_Stddev_Pop_Fields = {
  __typename?: 'survey_v2_question_option_stddev_pop_fields';
  /** this decides in which order to show the options to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Stddev_Pop_Order_By = {
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Survey_V2_Question_Option_Stddev_Samp_Fields = {
  __typename?: 'survey_v2_question_option_stddev_samp_fields';
  /** this decides in which order to show the options to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Stddev_Samp_Order_By = {
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_V2_Question_Option_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_V2_Question_Option_Stream_Cursor_Value_Input = {
  additional_info_config_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  option?: InputMaybe<Scalars['String']>;
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Scalars['Int']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  survey_question_id?: InputMaybe<Scalars['uuid']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Survey_V2_Question_Option_Sum_Fields = {
  __typename?: 'survey_v2_question_option_sum_fields';
  /** this decides in which order to show the options to user */
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Sum_Order_By = {
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Order_By>;
};

/** update columns of table "survey_v2_question_option" */
export enum Survey_V2_Question_Option_Update_Column {
  /** column name */
  AdditionalInfoConfigId = 'additional_info_config_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  Option = 'option',
  /** column name */
  Position = 'position',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  SurveyQuestionId = 'survey_question_id',
  /** column name */
  TemplateConfig = 'template_config',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Survey_V2_Question_Option_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Survey_V2_Question_Option_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Survey_V2_Question_Option_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Survey_V2_Question_Option_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Survey_V2_Question_Option_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Survey_V2_Question_Option_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Survey_V2_Question_Option_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_V2_Question_Option_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_V2_Question_Option_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Survey_V2_Question_Option_Var_Pop_Fields = {
  __typename?: 'survey_v2_question_option_var_pop_fields';
  /** this decides in which order to show the options to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Var_Pop_Order_By = {
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Survey_V2_Question_Option_Var_Samp_Fields = {
  __typename?: 'survey_v2_question_option_var_samp_fields';
  /** this decides in which order to show the options to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Var_Samp_Order_By = {
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Survey_V2_Question_Option_Variance_Fields = {
  __typename?: 'survey_v2_question_option_variance_fields';
  /** this decides in which order to show the options to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "survey_v2_question_option" */
export type Survey_V2_Question_Option_Variance_Order_By = {
  /** this decides in which order to show the options to user */
  position?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "survey_v2_question". */
export type Survey_V2_Question_Order_By = {
  created_at?: InputMaybe<Order_By>;
  ended_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_mandatory?: InputMaybe<Order_By>;
  kind?: InputMaybe<Order_By>;
  kind_config_override?: InputMaybe<Order_By>;
  position?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  started_at?: InputMaybe<Order_By>;
  survey?: InputMaybe<Survey_V2_Order_By>;
  survey_id?: InputMaybe<Order_By>;
  survey_question_kind?: InputMaybe<Survey_V2_Question_Kind_Order_By>;
  survey_question_options_aggregate?: InputMaybe<
    Survey_V2_Question_Option_Aggregate_Order_By
  >;
  survey_response_answers_aggregate?: InputMaybe<
    Survey_V2_Response_Answer_Aggregate_Order_By
  >;
  template_config?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_v2_question */
export type Survey_V2_Question_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Survey_V2_Question_Prepend_Input = {
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "survey_v2_question" */
export enum Survey_V2_Question_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsMandatory = 'is_mandatory',
  /** column name */
  Kind = 'kind',
  /** column name */
  KindConfigOverride = 'kind_config_override',
  /** column name */
  Position = 'position',
  /** column name */
  Question = 'question',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  SurveyId = 'survey_id',
  /** column name */
  TemplateConfig = 'template_config',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "survey_v2_question_aggregate_bool_exp_bool_and_arguments_columns" columns of table "survey_v2_question" */
export enum Survey_V2_Question_Select_Column_Survey_V2_Question_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsMandatory = 'is_mandatory',
}

/** select "survey_v2_question_aggregate_bool_exp_bool_or_arguments_columns" columns of table "survey_v2_question" */
export enum Survey_V2_Question_Select_Column_Survey_V2_Question_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsMandatory = 'is_mandatory',
}

/** input type for updating data in table "survey_v2_question" */
export type Survey_V2_Question_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_mandatory?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Survey_V2_Question_Kind_Enum>;
  /** overrides default config provided in survey_question_kind */
  kind_config_override?: InputMaybe<Scalars['String']>;
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  survey_id?: InputMaybe<Scalars['uuid']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Survey_V2_Question_Stddev_Fields = {
  __typename?: 'survey_v2_question_stddev_fields';
  /** this decides in which order to show the questions to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "survey_v2_question" */
export type Survey_V2_Question_Stddev_Order_By = {
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Survey_V2_Question_Stddev_Pop_Fields = {
  __typename?: 'survey_v2_question_stddev_pop_fields';
  /** this decides in which order to show the questions to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "survey_v2_question" */
export type Survey_V2_Question_Stddev_Pop_Order_By = {
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Survey_V2_Question_Stddev_Samp_Fields = {
  __typename?: 'survey_v2_question_stddev_samp_fields';
  /** this decides in which order to show the questions to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "survey_v2_question" */
export type Survey_V2_Question_Stddev_Samp_Order_By = {
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "survey_v2_question" */
export type Survey_V2_Question_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_V2_Question_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_V2_Question_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_mandatory?: InputMaybe<Scalars['Boolean']>;
  kind?: InputMaybe<Survey_V2_Question_Kind_Enum>;
  /** overrides default config provided in survey_question_kind */
  kind_config_override?: InputMaybe<Scalars['String']>;
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Scalars['Int']>;
  question?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  survey_id?: InputMaybe<Scalars['uuid']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Survey_V2_Question_Sum_Fields = {
  __typename?: 'survey_v2_question_sum_fields';
  /** this decides in which order to show the questions to user */
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "survey_v2_question" */
export type Survey_V2_Question_Sum_Order_By = {
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Order_By>;
};

/** update columns of table "survey_v2_question" */
export enum Survey_V2_Question_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsMandatory = 'is_mandatory',
  /** column name */
  Kind = 'kind',
  /** column name */
  KindConfigOverride = 'kind_config_override',
  /** column name */
  Position = 'position',
  /** column name */
  Question = 'question',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  SurveyId = 'survey_id',
  /** column name */
  TemplateConfig = 'template_config',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Survey_V2_Question_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Survey_V2_Question_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Survey_V2_Question_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Survey_V2_Question_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Survey_V2_Question_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Survey_V2_Question_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Survey_V2_Question_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_V2_Question_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_V2_Question_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Survey_V2_Question_Var_Pop_Fields = {
  __typename?: 'survey_v2_question_var_pop_fields';
  /** this decides in which order to show the questions to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "survey_v2_question" */
export type Survey_V2_Question_Var_Pop_Order_By = {
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Survey_V2_Question_Var_Samp_Fields = {
  __typename?: 'survey_v2_question_var_samp_fields';
  /** this decides in which order to show the questions to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "survey_v2_question" */
export type Survey_V2_Question_Var_Samp_Order_By = {
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Survey_V2_Question_Variance_Fields = {
  __typename?: 'survey_v2_question_variance_fields';
  /** this decides in which order to show the questions to user */
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "survey_v2_question" */
export type Survey_V2_Question_Variance_Order_By = {
  /** this decides in which order to show the questions to user */
  position?: InputMaybe<Order_By>;
};

/** survey response store the survey filled by the user */
export type Survey_V2_Response = {
  __typename?: 'survey_v2_response';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  survey: Survey_V2;
  survey_id: Scalars['uuid'];
  /** An array relationship */
  survey_response_answers: Array<Survey_V2_Response_Answer>;
  /** An aggregate relationship */
  survey_response_answers_aggregate: Survey_V2_Response_Answer_Aggregate;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** survey response store the survey filled by the user */
export type Survey_V2_ResponseSurvey_Response_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
};

/** survey response store the survey filled by the user */
export type Survey_V2_ResponseSurvey_Response_Answers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Survey_V2_Response_Answer_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
};

/** aggregated selection of "survey_v2_response" */
export type Survey_V2_Response_Aggregate = {
  __typename?: 'survey_v2_response_aggregate';
  aggregate?: Maybe<Survey_V2_Response_Aggregate_Fields>;
  nodes: Array<Survey_V2_Response>;
};

export type Survey_V2_Response_Aggregate_Bool_Exp = {
  count?: InputMaybe<Survey_V2_Response_Aggregate_Bool_Exp_Count>;
};

export type Survey_V2_Response_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Survey_V2_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Survey_V2_Response_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "survey_v2_response" */
export type Survey_V2_Response_Aggregate_Fields = {
  __typename?: 'survey_v2_response_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_V2_Response_Max_Fields>;
  min?: Maybe<Survey_V2_Response_Min_Fields>;
};

/** aggregate fields of "survey_v2_response" */
export type Survey_V2_Response_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_V2_Response_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "survey_v2_response" */
export type Survey_V2_Response_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Survey_V2_Response_Max_Order_By>;
  min?: InputMaybe<Survey_V2_Response_Min_Order_By>;
};

/** contains information on user\`s answer */
export type Survey_V2_Response_Answer = {
  __typename?: 'survey_v2_response_answer';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  survey_question: Survey_V2_Question;
  survey_question_id: Scalars['uuid'];
  /** An object relationship */
  survey_response: Survey_V2_Response;
  /** An array relationship */
  survey_response_answer_options: Array<Survey_V2_Response_Answer_Option>;
  /** An aggregate relationship */
  survey_response_answer_options_aggregate: Survey_V2_Response_Answer_Option_Aggregate;
  survey_response_id: Scalars['uuid'];
};

/** contains information on user\`s answer */
export type Survey_V2_Response_AnswerSurvey_Response_Answer_OptionsArgs = {
  distinct_on?: InputMaybe<
    Array<Survey_V2_Response_Answer_Option_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Option_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Option_Bool_Exp>;
};

/** contains information on user\`s answer */
export type Survey_V2_Response_AnswerSurvey_Response_Answer_Options_AggregateArgs = {
  distinct_on?: InputMaybe<
    Array<Survey_V2_Response_Answer_Option_Select_Column>
  >;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Survey_V2_Response_Answer_Option_Order_By>>;
  where?: InputMaybe<Survey_V2_Response_Answer_Option_Bool_Exp>;
};

/** aggregated selection of "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_Aggregate = {
  __typename?: 'survey_v2_response_answer_aggregate';
  aggregate?: Maybe<Survey_V2_Response_Answer_Aggregate_Fields>;
  nodes: Array<Survey_V2_Response_Answer>;
};

export type Survey_V2_Response_Answer_Aggregate_Bool_Exp = {
  count?: InputMaybe<Survey_V2_Response_Answer_Aggregate_Bool_Exp_Count>;
};

export type Survey_V2_Response_Answer_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Survey_V2_Response_Answer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_Aggregate_Fields = {
  __typename?: 'survey_v2_response_answer_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_V2_Response_Answer_Max_Fields>;
  min?: Maybe<Survey_V2_Response_Answer_Min_Fields>;
};

/** aggregate fields of "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_V2_Response_Answer_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Survey_V2_Response_Answer_Max_Order_By>;
  min?: InputMaybe<Survey_V2_Response_Answer_Min_Order_By>;
};

/** input type for inserting array relation for remote table "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_Arr_Rel_Insert_Input = {
  data: Array<Survey_V2_Response_Answer_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_V2_Response_Answer_On_Conflict>;
};

/** Boolean expression to filter rows from the table "survey_v2_response_answer". All fields are combined with a logical 'AND'. */
export type Survey_V2_Response_Answer_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_V2_Response_Answer_Bool_Exp>>;
  _not?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_V2_Response_Answer_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  survey_question?: InputMaybe<Survey_V2_Question_Bool_Exp>;
  survey_question_id?: InputMaybe<Uuid_Comparison_Exp>;
  survey_response?: InputMaybe<Survey_V2_Response_Bool_Exp>;
  survey_response_answer_options?: InputMaybe<
    Survey_V2_Response_Answer_Option_Bool_Exp
  >;
  survey_response_answer_options_aggregate?: InputMaybe<
    Survey_V2_Response_Answer_Option_Aggregate_Bool_Exp
  >;
  survey_response_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_v2_response_answer" */
export enum Survey_V2_Response_Answer_Constraint {
  /** unique or primary key constraint on columns "id" */
  SurveyV2ResponseAnswerPkey = 'survey_v2_response_answer_pkey',
}

/** input type for inserting data into table "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  survey_question?: InputMaybe<Survey_V2_Question_Obj_Rel_Insert_Input>;
  survey_question_id?: InputMaybe<Scalars['uuid']>;
  survey_response?: InputMaybe<Survey_V2_Response_Obj_Rel_Insert_Input>;
  survey_response_answer_options?: InputMaybe<
    Survey_V2_Response_Answer_Option_Arr_Rel_Insert_Input
  >;
  survey_response_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Survey_V2_Response_Answer_Max_Fields = {
  __typename?: 'survey_v2_response_answer_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  survey_question_id?: Maybe<Scalars['uuid']>;
  survey_response_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_question_id?: InputMaybe<Order_By>;
  survey_response_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Survey_V2_Response_Answer_Min_Fields = {
  __typename?: 'survey_v2_response_answer_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  survey_question_id?: Maybe<Scalars['uuid']>;
  survey_response_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_question_id?: InputMaybe<Order_By>;
  survey_response_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_Mutation_Response = {
  __typename?: 'survey_v2_response_answer_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_V2_Response_Answer>;
};

/** input type for inserting object relation for remote table "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_Obj_Rel_Insert_Input = {
  data: Survey_V2_Response_Answer_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_V2_Response_Answer_On_Conflict>;
};

/** on_conflict condition type for table "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_On_Conflict = {
  constraint: Survey_V2_Response_Answer_Constraint;
  update_columns?: Array<Survey_V2_Response_Answer_Update_Column>;
  where?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
};

/** contains all the answers given by a user for a given question */
export type Survey_V2_Response_Answer_Option = {
  __typename?: 'survey_v2_response_answer_option';
  /** additional info for the answer if question has `is_additonal_info_required` as true */
  additional_info?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
  answer_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  option_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  survey_question_answer: Survey_V2_Response_Answer;
  /** An object relationship */
  survey_question_option?: Maybe<Survey_V2_Question_Option>;
};

/** aggregated selection of "survey_v2_response_answer_option" */
export type Survey_V2_Response_Answer_Option_Aggregate = {
  __typename?: 'survey_v2_response_answer_option_aggregate';
  aggregate?: Maybe<Survey_V2_Response_Answer_Option_Aggregate_Fields>;
  nodes: Array<Survey_V2_Response_Answer_Option>;
};

export type Survey_V2_Response_Answer_Option_Aggregate_Bool_Exp = {
  count?: InputMaybe<Survey_V2_Response_Answer_Option_Aggregate_Bool_Exp_Count>;
};

export type Survey_V2_Response_Answer_Option_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Survey_V2_Response_Answer_Option_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Survey_V2_Response_Answer_Option_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "survey_v2_response_answer_option" */
export type Survey_V2_Response_Answer_Option_Aggregate_Fields = {
  __typename?: 'survey_v2_response_answer_option_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Survey_V2_Response_Answer_Option_Max_Fields>;
  min?: Maybe<Survey_V2_Response_Answer_Option_Min_Fields>;
};

/** aggregate fields of "survey_v2_response_answer_option" */
export type Survey_V2_Response_Answer_Option_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Survey_V2_Response_Answer_Option_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "survey_v2_response_answer_option" */
export type Survey_V2_Response_Answer_Option_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Survey_V2_Response_Answer_Option_Max_Order_By>;
  min?: InputMaybe<Survey_V2_Response_Answer_Option_Min_Order_By>;
};

/** input type for inserting array relation for remote table "survey_v2_response_answer_option" */
export type Survey_V2_Response_Answer_Option_Arr_Rel_Insert_Input = {
  data: Array<Survey_V2_Response_Answer_Option_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_V2_Response_Answer_Option_On_Conflict>;
};

/** Boolean expression to filter rows from the table "survey_v2_response_answer_option". All fields are combined with a logical 'AND'. */
export type Survey_V2_Response_Answer_Option_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_V2_Response_Answer_Option_Bool_Exp>>;
  _not?: InputMaybe<Survey_V2_Response_Answer_Option_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_V2_Response_Answer_Option_Bool_Exp>>;
  additional_info?: InputMaybe<String_Comparison_Exp>;
  answer?: InputMaybe<String_Comparison_Exp>;
  answer_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  option_id?: InputMaybe<Uuid_Comparison_Exp>;
  survey_question_answer?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
  survey_question_option?: InputMaybe<Survey_V2_Question_Option_Bool_Exp>;
};

/** unique or primary key constraints on table "survey_v2_response_answer_option" */
export enum Survey_V2_Response_Answer_Option_Constraint {
  /** unique or primary key constraint on columns "id" */
  SurveyV2ResponseAnswerOptionPkey = 'survey_v2_response_answer_option_pkey',
}

/** input type for inserting data into table "survey_v2_response_answer_option" */
export type Survey_V2_Response_Answer_Option_Insert_Input = {
  /** additional info for the answer if question has `is_additonal_info_required` as true */
  additional_info?: InputMaybe<Scalars['String']>;
  answer?: InputMaybe<Scalars['String']>;
  answer_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  option_id?: InputMaybe<Scalars['uuid']>;
  survey_question_answer?: InputMaybe<
    Survey_V2_Response_Answer_Obj_Rel_Insert_Input
  >;
  survey_question_option?: InputMaybe<
    Survey_V2_Question_Option_Obj_Rel_Insert_Input
  >;
};

/** aggregate max on columns */
export type Survey_V2_Response_Answer_Option_Max_Fields = {
  __typename?: 'survey_v2_response_answer_option_max_fields';
  /** additional info for the answer if question has `is_additonal_info_required` as true */
  additional_info?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
  answer_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "survey_v2_response_answer_option" */
export type Survey_V2_Response_Answer_Option_Max_Order_By = {
  /** additional info for the answer if question has `is_additonal_info_required` as true */
  additional_info?: InputMaybe<Order_By>;
  answer?: InputMaybe<Order_By>;
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  option_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Survey_V2_Response_Answer_Option_Min_Fields = {
  __typename?: 'survey_v2_response_answer_option_min_fields';
  /** additional info for the answer if question has `is_additonal_info_required` as true */
  additional_info?: Maybe<Scalars['String']>;
  answer?: Maybe<Scalars['String']>;
  answer_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  option_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "survey_v2_response_answer_option" */
export type Survey_V2_Response_Answer_Option_Min_Order_By = {
  /** additional info for the answer if question has `is_additonal_info_required` as true */
  additional_info?: InputMaybe<Order_By>;
  answer?: InputMaybe<Order_By>;
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  option_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "survey_v2_response_answer_option" */
export type Survey_V2_Response_Answer_Option_Mutation_Response = {
  __typename?: 'survey_v2_response_answer_option_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_V2_Response_Answer_Option>;
};

/** on_conflict condition type for table "survey_v2_response_answer_option" */
export type Survey_V2_Response_Answer_Option_On_Conflict = {
  constraint: Survey_V2_Response_Answer_Option_Constraint;
  update_columns?: Array<Survey_V2_Response_Answer_Option_Update_Column>;
  where?: InputMaybe<Survey_V2_Response_Answer_Option_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_v2_response_answer_option". */
export type Survey_V2_Response_Answer_Option_Order_By = {
  additional_info?: InputMaybe<Order_By>;
  answer?: InputMaybe<Order_By>;
  answer_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  option_id?: InputMaybe<Order_By>;
  survey_question_answer?: InputMaybe<Survey_V2_Response_Answer_Order_By>;
  survey_question_option?: InputMaybe<Survey_V2_Question_Option_Order_By>;
};

/** primary key columns input for table: survey_v2_response_answer_option */
export type Survey_V2_Response_Answer_Option_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "survey_v2_response_answer_option" */
export enum Survey_V2_Response_Answer_Option_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  Answer = 'answer',
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OptionId = 'option_id',
}

/** input type for updating data in table "survey_v2_response_answer_option" */
export type Survey_V2_Response_Answer_Option_Set_Input = {
  /** additional info for the answer if question has `is_additonal_info_required` as true */
  additional_info?: InputMaybe<Scalars['String']>;
  answer?: InputMaybe<Scalars['String']>;
  answer_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  option_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "survey_v2_response_answer_option" */
export type Survey_V2_Response_Answer_Option_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_V2_Response_Answer_Option_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_V2_Response_Answer_Option_Stream_Cursor_Value_Input = {
  /** additional info for the answer if question has `is_additonal_info_required` as true */
  additional_info?: InputMaybe<Scalars['String']>;
  answer?: InputMaybe<Scalars['String']>;
  answer_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  option_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "survey_v2_response_answer_option" */
export enum Survey_V2_Response_Answer_Option_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  Answer = 'answer',
  /** column name */
  AnswerId = 'answer_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OptionId = 'option_id',
}

export type Survey_V2_Response_Answer_Option_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_V2_Response_Answer_Option_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_V2_Response_Answer_Option_Bool_Exp;
};

/** Ordering options when selecting data from "survey_v2_response_answer". */
export type Survey_V2_Response_Answer_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  survey_question?: InputMaybe<Survey_V2_Question_Order_By>;
  survey_question_id?: InputMaybe<Order_By>;
  survey_response?: InputMaybe<Survey_V2_Response_Order_By>;
  survey_response_answer_options_aggregate?: InputMaybe<
    Survey_V2_Response_Answer_Option_Aggregate_Order_By
  >;
  survey_response_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_v2_response_answer */
export type Survey_V2_Response_Answer_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "survey_v2_response_answer" */
export enum Survey_V2_Response_Answer_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SurveyQuestionId = 'survey_question_id',
  /** column name */
  SurveyResponseId = 'survey_response_id',
}

/** input type for updating data in table "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  survey_question_id?: InputMaybe<Scalars['uuid']>;
  survey_response_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "survey_v2_response_answer" */
export type Survey_V2_Response_Answer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_V2_Response_Answer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_V2_Response_Answer_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  survey_question_id?: InputMaybe<Scalars['uuid']>;
  survey_response_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "survey_v2_response_answer" */
export enum Survey_V2_Response_Answer_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  SurveyQuestionId = 'survey_question_id',
  /** column name */
  SurveyResponseId = 'survey_response_id',
}

export type Survey_V2_Response_Answer_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_V2_Response_Answer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_V2_Response_Answer_Bool_Exp;
};

/** input type for inserting array relation for remote table "survey_v2_response" */
export type Survey_V2_Response_Arr_Rel_Insert_Input = {
  data: Array<Survey_V2_Response_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_V2_Response_On_Conflict>;
};

/** Boolean expression to filter rows from the table "survey_v2_response". All fields are combined with a logical 'AND'. */
export type Survey_V2_Response_Bool_Exp = {
  _and?: InputMaybe<Array<Survey_V2_Response_Bool_Exp>>;
  _not?: InputMaybe<Survey_V2_Response_Bool_Exp>;
  _or?: InputMaybe<Array<Survey_V2_Response_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  survey?: InputMaybe<Survey_V2_Bool_Exp>;
  survey_id?: InputMaybe<Uuid_Comparison_Exp>;
  survey_response_answers?: InputMaybe<Survey_V2_Response_Answer_Bool_Exp>;
  survey_response_answers_aggregate?: InputMaybe<
    Survey_V2_Response_Answer_Aggregate_Bool_Exp
  >;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "survey_v2_response" */
export enum Survey_V2_Response_Constraint {
  /** unique or primary key constraint on columns "id" */
  SurveyV2ResponsePkey = 'survey_v2_response_pkey',
}

/** input type for inserting data into table "survey_v2_response" */
export type Survey_V2_Response_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  survey?: InputMaybe<Survey_V2_Obj_Rel_Insert_Input>;
  survey_id?: InputMaybe<Scalars['uuid']>;
  survey_response_answers?: InputMaybe<
    Survey_V2_Response_Answer_Arr_Rel_Insert_Input
  >;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Survey_V2_Response_Max_Fields = {
  __typename?: 'survey_v2_response_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  survey_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "survey_v2_response" */
export type Survey_V2_Response_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  survey_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Survey_V2_Response_Min_Fields = {
  __typename?: 'survey_v2_response_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  survey_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "survey_v2_response" */
export type Survey_V2_Response_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  survey_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "survey_v2_response" */
export type Survey_V2_Response_Mutation_Response = {
  __typename?: 'survey_v2_response_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Survey_V2_Response>;
};

/** input type for inserting object relation for remote table "survey_v2_response" */
export type Survey_V2_Response_Obj_Rel_Insert_Input = {
  data: Survey_V2_Response_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Survey_V2_Response_On_Conflict>;
};

/** on_conflict condition type for table "survey_v2_response" */
export type Survey_V2_Response_On_Conflict = {
  constraint: Survey_V2_Response_Constraint;
  update_columns?: Array<Survey_V2_Response_Update_Column>;
  where?: InputMaybe<Survey_V2_Response_Bool_Exp>;
};

/** Ordering options when selecting data from "survey_v2_response". */
export type Survey_V2_Response_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  survey?: InputMaybe<Survey_V2_Order_By>;
  survey_id?: InputMaybe<Order_By>;
  survey_response_answers_aggregate?: InputMaybe<
    Survey_V2_Response_Answer_Aggregate_Order_By
  >;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: survey_v2_response */
export type Survey_V2_Response_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "survey_v2_response" */
export enum Survey_V2_Response_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SurveyId = 'survey_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "survey_v2_response" */
export type Survey_V2_Response_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  survey_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "survey_v2_response" */
export type Survey_V2_Response_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_V2_Response_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_V2_Response_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  survey_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "survey_v2_response" */
export enum Survey_V2_Response_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  SurveyId = 'survey_id',
  /** column name */
  UserId = 'user_id',
}

export type Survey_V2_Response_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_V2_Response_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_V2_Response_Bool_Exp;
};

/** select columns of table "survey_v2" */
export enum Survey_V2_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  RequestedBy = 'requested_by',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  SurveyDescription = 'survey_description',
  /** column name */
  SurveyName = 'survey_name',
  /** column name */
  SurveyTitle = 'survey_title',
  /** column name */
  TemplateConfig = 'template_config',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "survey_v2" */
export type Survey_V2_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  requested_by?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  survey_description?: InputMaybe<Scalars['String']>;
  survey_name?: InputMaybe<Scalars['String']>;
  survey_title?: InputMaybe<Scalars['String']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "survey_v2" */
export type Survey_V2_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Survey_V2_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Survey_V2_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  ended_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  requested_by?: InputMaybe<Scalars['String']>;
  started_at?: InputMaybe<Scalars['timestamptz']>;
  survey_description?: InputMaybe<Scalars['String']>;
  survey_name?: InputMaybe<Scalars['String']>;
  survey_title?: InputMaybe<Scalars['String']>;
  /** jsonb column to store any UI template related configuration */
  template_config?: InputMaybe<Scalars['jsonb']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "survey_v2" */
export enum Survey_V2_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EndedAt = 'ended_at',
  /** column name */
  Id = 'id',
  /** column name */
  RequestedBy = 'requested_by',
  /** column name */
  StartedAt = 'started_at',
  /** column name */
  SurveyDescription = 'survey_description',
  /** column name */
  SurveyName = 'survey_name',
  /** column name */
  SurveyTitle = 'survey_title',
  /** column name */
  TemplateConfig = 'template_config',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Survey_V2_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Survey_V2_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Survey_V2_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Survey_V2_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Survey_V2_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Survey_V2_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Survey_V2_Set_Input>;
  /** filter the rows which have to be updated */
  where: Survey_V2_Bool_Exp;
};

/** columns and relationships of "task_event" */
export type Task_Event = {
  __typename?: 'task_event';
  created_at: Scalars['timestamptz'];
  error?: Maybe<Scalars['String']>;
  event_data?: Maybe<Scalars['jsonb']>;
  event_type: Scalars['String'];
  /** gets event detail for events of github_description job type */
  github_detail?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  public_event_data?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "task_event" */
export type Task_EventEvent_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "task_event" */
export type Task_EventPublic_Event_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "task_event" */
export type Task_Event_Aggregate = {
  __typename?: 'task_event_aggregate';
  aggregate?: Maybe<Task_Event_Aggregate_Fields>;
  nodes: Array<Task_Event>;
};

export type Task_Event_Aggregate_Bool_Exp = {
  count?: InputMaybe<Task_Event_Aggregate_Bool_Exp_Count>;
};

export type Task_Event_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Task_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Task_Event_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "task_event" */
export type Task_Event_Aggregate_Fields = {
  __typename?: 'task_event_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Task_Event_Max_Fields>;
  min?: Maybe<Task_Event_Min_Fields>;
};

/** aggregate fields of "task_event" */
export type Task_Event_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Task_Event_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "task_event" */
export type Task_Event_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Event_Max_Order_By>;
  min?: InputMaybe<Task_Event_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Task_Event_Append_Input = {
  event_data?: InputMaybe<Scalars['jsonb']>;
  public_event_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "task_event" */
export type Task_Event_Arr_Rel_Insert_Input = {
  data: Array<Task_Event_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Event_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_event". All fields are combined with a logical 'AND'. */
export type Task_Event_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Event_Bool_Exp>>;
  _not?: InputMaybe<Task_Event_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Event_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error?: InputMaybe<String_Comparison_Exp>;
  event_data?: InputMaybe<Jsonb_Comparison_Exp>;
  event_type?: InputMaybe<String_Comparison_Exp>;
  github_detail?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  public_event_data?: InputMaybe<Jsonb_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "task_event" */
export enum Task_Event_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskEventPkey = 'task_event_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Task_Event_Delete_At_Path_Input = {
  event_data?: InputMaybe<Array<Scalars['String']>>;
  public_event_data?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Task_Event_Delete_Elem_Input = {
  event_data?: InputMaybe<Scalars['Int']>;
  public_event_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Task_Event_Delete_Key_Input = {
  event_data?: InputMaybe<Scalars['String']>;
  public_event_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "task_event" */
export type Task_Event_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_data?: InputMaybe<Scalars['jsonb']>;
  event_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  public_event_data?: InputMaybe<Scalars['jsonb']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Task_Event_Max_Fields = {
  __typename?: 'task_event_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  /** gets event detail for events of github_description job type */
  github_detail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "task_event" */
export type Task_Event_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Task_Event_Min_Fields = {
  __typename?: 'task_event_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  error?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  /** gets event detail for events of github_description job type */
  github_detail?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  task_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "task_event" */
export type Task_Event_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_event" */
export type Task_Event_Mutation_Response = {
  __typename?: 'task_event_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Event>;
};

/** on_conflict condition type for table "task_event" */
export type Task_Event_On_Conflict = {
  constraint: Task_Event_Constraint;
  update_columns?: Array<Task_Event_Update_Column>;
  where?: InputMaybe<Task_Event_Bool_Exp>;
};

/** Ordering options when selecting data from "task_event". */
export type Task_Event_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error?: InputMaybe<Order_By>;
  event_data?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  github_detail?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  public_event_data?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: task_event */
export type Task_Event_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Task_Event_Prepend_Input = {
  event_data?: InputMaybe<Scalars['jsonb']>;
  public_event_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "task_event" */
export enum Task_Event_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventData = 'event_data',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  PublicEventData = 'public_event_data',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "task_event" */
export type Task_Event_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_data?: InputMaybe<Scalars['jsonb']>;
  event_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  public_event_data?: InputMaybe<Scalars['jsonb']>;
  task_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "task_event" */
export type Task_Event_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Task_Event_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Task_Event_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_data?: InputMaybe<Scalars['jsonb']>;
  event_type?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  public_event_data?: InputMaybe<Scalars['jsonb']>;
  task_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "task_event" */
export enum Task_Event_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventData = 'event_data',
  /** column name */
  EventType = 'event_type',
  /** column name */
  Id = 'id',
  /** column name */
  PublicEventData = 'public_event_data',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Task_Event_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Task_Event_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Task_Event_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Task_Event_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Task_Event_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Task_Event_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Event_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Event_Bool_Exp;
};

/** columns and relationships of "taskq_worker_event_latest" */
export type Taskq_Worker_Event_Latest = {
  __typename?: 'taskq_worker_event_latest';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  event_type?: Maybe<Scalars['String']>;
  job_created_at?: Maybe<Scalars['timestamptz']>;
  job_id?: Maybe<Scalars['uuid']>;
  job_input?: Maybe<Scalars['jsonb']>;
  job_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_event_data?: Maybe<Scalars['jsonb']>;
  task_id?: Maybe<Scalars['uuid']>;
  task_payload?: Maybe<Scalars['jsonb']>;
  task_type?: Maybe<Scalars['String']>;
};

/** columns and relationships of "taskq_worker_event_latest" */
export type Taskq_Worker_Event_LatestJob_InputArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "taskq_worker_event_latest" */
export type Taskq_Worker_Event_LatestTask_Event_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "taskq_worker_event_latest" */
export type Taskq_Worker_Event_LatestTask_PayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "taskq_worker_event_latest" */
export type Taskq_Worker_Event_Latest_Aggregate = {
  __typename?: 'taskq_worker_event_latest_aggregate';
  aggregate?: Maybe<Taskq_Worker_Event_Latest_Aggregate_Fields>;
  nodes: Array<Taskq_Worker_Event_Latest>;
};

/** aggregate fields of "taskq_worker_event_latest" */
export type Taskq_Worker_Event_Latest_Aggregate_Fields = {
  __typename?: 'taskq_worker_event_latest_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Taskq_Worker_Event_Latest_Max_Fields>;
  min?: Maybe<Taskq_Worker_Event_Latest_Min_Fields>;
};

/** aggregate fields of "taskq_worker_event_latest" */
export type Taskq_Worker_Event_Latest_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Taskq_Worker_Event_Latest_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "taskq_worker_event_latest". All fields are combined with a logical 'AND'. */
export type Taskq_Worker_Event_Latest_Bool_Exp = {
  _and?: InputMaybe<Array<Taskq_Worker_Event_Latest_Bool_Exp>>;
  _not?: InputMaybe<Taskq_Worker_Event_Latest_Bool_Exp>;
  _or?: InputMaybe<Array<Taskq_Worker_Event_Latest_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  event_type?: InputMaybe<String_Comparison_Exp>;
  job_created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  job_input?: InputMaybe<Jsonb_Comparison_Exp>;
  job_type?: InputMaybe<String_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_event_data?: InputMaybe<Jsonb_Comparison_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  task_payload?: InputMaybe<Jsonb_Comparison_Exp>;
  task_type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Taskq_Worker_Event_Latest_Max_Fields = {
  __typename?: 'taskq_worker_event_latest_max_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  event_type?: Maybe<Scalars['String']>;
  job_created_at?: Maybe<Scalars['timestamptz']>;
  job_id?: Maybe<Scalars['uuid']>;
  job_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
  task_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Taskq_Worker_Event_Latest_Min_Fields = {
  __typename?: 'taskq_worker_event_latest_min_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  event_type?: Maybe<Scalars['String']>;
  job_created_at?: Maybe<Scalars['timestamptz']>;
  job_id?: Maybe<Scalars['uuid']>;
  job_type?: Maybe<Scalars['String']>;
  project_id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  task_id?: Maybe<Scalars['uuid']>;
  task_type?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "taskq_worker_event_latest". */
export type Taskq_Worker_Event_Latest_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  job_created_at?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  job_input?: InputMaybe<Order_By>;
  job_type?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_event_data?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  task_payload?: InputMaybe<Order_By>;
  task_type?: InputMaybe<Order_By>;
};

/** select columns of table "taskq_worker_event_latest" */
export enum Taskq_Worker_Event_Latest_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventId = 'event_id',
  /** column name */
  EventType = 'event_type',
  /** column name */
  JobCreatedAt = 'job_created_at',
  /** column name */
  JobId = 'job_id',
  /** column name */
  JobInput = 'job_input',
  /** column name */
  JobType = 'job_type',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Region = 'region',
  /** column name */
  TaskEventData = 'task_event_data',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  TaskPayload = 'task_payload',
  /** column name */
  TaskType = 'task_type',
}

/** Streaming cursor of the table "taskq_worker_event_latest" */
export type Taskq_Worker_Event_Latest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Taskq_Worker_Event_Latest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Taskq_Worker_Event_Latest_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  event_type?: InputMaybe<Scalars['String']>;
  job_created_at?: InputMaybe<Scalars['timestamptz']>;
  job_id?: InputMaybe<Scalars['uuid']>;
  job_input?: InputMaybe<Scalars['jsonb']>;
  job_type?: InputMaybe<Scalars['String']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  task_event_data?: InputMaybe<Scalars['jsonb']>;
  task_id?: InputMaybe<Scalars['uuid']>;
  task_payload?: InputMaybe<Scalars['jsonb']>;
  task_type?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "tasks" */
export type Tasks = {
  __typename?: 'tasks';
  cloud: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  job: Jobs;
  job_id: Scalars['uuid'];
  max_retry: Scalars['Int'];
  name: Scalars['String'];
  payload?: Maybe<Scalars['jsonb']>;
  region: Scalars['String'];
  /** An array relationship */
  task_events: Array<Task_Event>;
  /** An aggregate relationship */
  task_events_aggregate: Task_Event_Aggregate;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "tasks" */
export type TasksPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "tasks" */
export type TasksTask_EventsArgs = {
  distinct_on?: InputMaybe<Array<Task_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Event_Order_By>>;
  where?: InputMaybe<Task_Event_Bool_Exp>;
};

/** columns and relationships of "tasks" */
export type TasksTask_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Task_Event_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Task_Event_Order_By>>;
  where?: InputMaybe<Task_Event_Bool_Exp>;
};

/** aggregated selection of "tasks" */
export type Tasks_Aggregate = {
  __typename?: 'tasks_aggregate';
  aggregate?: Maybe<Tasks_Aggregate_Fields>;
  nodes: Array<Tasks>;
};

export type Tasks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tasks_Aggregate_Bool_Exp_Count>;
};

export type Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_Fields = {
  __typename?: 'tasks_aggregate_fields';
  avg?: Maybe<Tasks_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tasks_Max_Fields>;
  min?: Maybe<Tasks_Min_Fields>;
  stddev?: Maybe<Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Sum_Fields>;
  var_pop?: Maybe<Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Variance_Fields>;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tasks" */
export type Tasks_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Max_Order_By>;
  min?: InputMaybe<Tasks_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Tasks_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "tasks" */
export type Tasks_Arr_Rel_Insert_Input = {
  data: Array<Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** aggregate avg on columns */
export type Tasks_Avg_Fields = {
  __typename?: 'tasks_avg_fields';
  max_retry?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tasks" */
export type Tasks_Avg_Order_By = {
  max_retry?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'. */
export type Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Bool_Exp>>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job?: InputMaybe<Jobs_Bool_Exp>;
  job_id?: InputMaybe<Uuid_Comparison_Exp>;
  max_retry?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  task_events?: InputMaybe<Task_Event_Bool_Exp>;
  task_events_aggregate?: InputMaybe<Task_Event_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tasks" */
export enum Tasks_Constraint {
  /** unique or primary key constraint on columns "name", "job_id" */
  TasksJobIdNameKey = 'tasks_job_id_name_key',
  /** unique or primary key constraint on columns "id" */
  TasksPkey = 'tasks_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Tasks_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Tasks_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Tasks_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "tasks" */
export type Tasks_Inc_Input = {
  max_retry?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tasks" */
export type Tasks_Insert_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  job?: InputMaybe<Jobs_Obj_Rel_Insert_Input>;
  job_id?: InputMaybe<Scalars['uuid']>;
  max_retry?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  task_events?: InputMaybe<Task_Event_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tasks_Max_Fields = {
  __typename?: 'tasks_max_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  max_retry?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tasks" */
export type Tasks_Max_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  max_retry?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Min_Fields = {
  __typename?: 'tasks_min_fields';
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  job_id?: Maybe<Scalars['uuid']>;
  max_retry?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tasks" */
export type Tasks_Min_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_id?: InputMaybe<Order_By>;
  max_retry?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks" */
export type Tasks_Mutation_Response = {
  __typename?: 'tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks>;
};

/** input type for inserting object relation for remote table "tasks" */
export type Tasks_Obj_Rel_Insert_Input = {
  data: Tasks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** on_conflict condition type for table "tasks" */
export type Tasks_On_Conflict = {
  constraint: Tasks_Constraint;
  update_columns?: Array<Tasks_Update_Column>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks". */
export type Tasks_Order_By = {
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job?: InputMaybe<Jobs_Order_By>;
  job_id?: InputMaybe<Order_By>;
  max_retry?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  task_events_aggregate?: InputMaybe<Task_Event_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tasks */
export type Tasks_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Tasks_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "tasks" */
export enum Tasks_Select_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  MaxRetry = 'max_retry',
  /** column name */
  Name = 'name',
  /** column name */
  Payload = 'payload',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "tasks" */
export type Tasks_Set_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['uuid']>;
  max_retry?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tasks_Stddev_Fields = {
  __typename?: 'tasks_stddev_fields';
  max_retry?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tasks" */
export type Tasks_Stddev_Order_By = {
  max_retry?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Stddev_Pop_Fields = {
  __typename?: 'tasks_stddev_pop_fields';
  max_retry?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tasks" */
export type Tasks_Stddev_Pop_Order_By = {
  max_retry?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Stddev_Samp_Fields = {
  __typename?: 'tasks_stddev_samp_fields';
  max_retry?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tasks" */
export type Tasks_Stddev_Samp_Order_By = {
  max_retry?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tasks" */
export type Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tasks_Stream_Cursor_Value_Input = {
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  job_id?: InputMaybe<Scalars['uuid']>;
  max_retry?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  region?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tasks_Sum_Fields = {
  __typename?: 'tasks_sum_fields';
  max_retry?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tasks" */
export type Tasks_Sum_Order_By = {
  max_retry?: InputMaybe<Order_By>;
};

/** update columns of table "tasks" */
export enum Tasks_Update_Column {
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  JobId = 'job_id',
  /** column name */
  MaxRetry = 'max_retry',
  /** column name */
  Name = 'name',
  /** column name */
  Payload = 'payload',
  /** column name */
  Region = 'region',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Tasks_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Tasks_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Tasks_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Tasks_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Tasks_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tasks_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Tasks_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Var_Pop_Fields = {
  __typename?: 'tasks_var_pop_fields';
  max_retry?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tasks" */
export type Tasks_Var_Pop_Order_By = {
  max_retry?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Var_Samp_Fields = {
  __typename?: 'tasks_var_samp_fields';
  max_retry?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tasks" */
export type Tasks_Var_Samp_Order_By = {
  max_retry?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Variance_Fields = {
  __typename?: 'tasks_variance_fields';
  max_retry?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tasks" */
export type Tasks_Variance_Order_By = {
  max_retry?: InputMaybe<Order_By>;
};

/** GraphQL API provisioned for a project in a cloud and region */
export type Tenant = {
  __typename?: 'tenant';
  active: Scalars['Boolean'];
  active_status_reason?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  /** An object relationship */
  central_cloud?: Maybe<Central_Cloud>;
  cloud: Scalars['String'];
  cloudflare_proxy_enabled: Scalars['Boolean'];
  /** An object relationship */
  config?: Maybe<Config>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  custom_domains: Array<Custom_Domain>;
  /** An aggregate relationship */
  custom_domains_aggregate: Custom_Domain_Aggregate;
  /** An object relationship */
  dedicated_vpc?: Maybe<Dedicated_Vpc>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  fqdn: Scalars['String'];
  /** Tenant health status */
  health_status?: Maybe<Scalars['json']>;
  id: Scalars['uuid'];
  maintenance_mode: Scalars['Boolean'];
  major_version: Scalars['Int'];
  /** An object relationship */
  project: Projects;
  project_id: Scalars['uuid'];
  region: Scalars['String'];
  /** An object relationship */
  region_info?: Maybe<Region>;
  slug: Scalars['String'];
  /** An array relationship */
  status: Array<Tenant_Status>;
  /** An aggregate relationship */
  status_aggregate: Tenant_Status_Aggregate;
  /** An array relationship */
  tenant_group_members: Array<Tenant_Group_Member>;
  /** An aggregate relationship */
  tenant_group_members_aggregate: Tenant_Group_Member_Aggregate;
  /** An array relationship */
  tenant_public_status: Array<Tenant_Public_Status>;
  /** An aggregate relationship */
  tenant_public_status_aggregate: Tenant_Public_Status_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  workers: Array<Hasura_Worker_Tenants>;
  /** An aggregate relationship */
  workers_aggregate: Hasura_Worker_Tenants_Aggregate;
};

/** GraphQL API provisioned for a project in a cloud and region */
export type TenantCustom_DomainsArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Order_By>>;
  where?: InputMaybe<Custom_Domain_Bool_Exp>;
};

/** GraphQL API provisioned for a project in a cloud and region */
export type TenantCustom_Domains_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Custom_Domain_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Custom_Domain_Order_By>>;
  where?: InputMaybe<Custom_Domain_Bool_Exp>;
};

/** GraphQL API provisioned for a project in a cloud and region */
export type TenantHealth_StatusArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** GraphQL API provisioned for a project in a cloud and region */
export type TenantStatusArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Status_Order_By>>;
  where?: InputMaybe<Tenant_Status_Bool_Exp>;
};

/** GraphQL API provisioned for a project in a cloud and region */
export type TenantStatus_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Status_Order_By>>;
  where?: InputMaybe<Tenant_Status_Bool_Exp>;
};

/** GraphQL API provisioned for a project in a cloud and region */
export type TenantTenant_Group_MembersArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Member_Order_By>>;
  where?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
};

/** GraphQL API provisioned for a project in a cloud and region */
export type TenantTenant_Group_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Member_Order_By>>;
  where?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
};

/** GraphQL API provisioned for a project in a cloud and region */
export type TenantTenant_Public_StatusArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Public_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Public_Status_Order_By>>;
  where?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
};

/** GraphQL API provisioned for a project in a cloud and region */
export type TenantTenant_Public_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Public_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Public_Status_Order_By>>;
  where?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
};

/** GraphQL API provisioned for a project in a cloud and region */
export type TenantWorkersArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Worker_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Worker_Tenants_Order_By>>;
  where?: InputMaybe<Hasura_Worker_Tenants_Bool_Exp>;
};

/** GraphQL API provisioned for a project in a cloud and region */
export type TenantWorkers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Hasura_Worker_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Hasura_Worker_Tenants_Order_By>>;
  where?: InputMaybe<Hasura_Worker_Tenants_Bool_Exp>;
};

/** Enum for tenant activation/deactivation reason */
export type Tenant_Active_Status_Reason = {
  __typename?: 'tenant_active_status_reason';
  description: Scalars['String'];
  name: Scalars['String'];
};

/** aggregated selection of "tenant_active_status_reason" */
export type Tenant_Active_Status_Reason_Aggregate = {
  __typename?: 'tenant_active_status_reason_aggregate';
  aggregate?: Maybe<Tenant_Active_Status_Reason_Aggregate_Fields>;
  nodes: Array<Tenant_Active_Status_Reason>;
};

/** aggregate fields of "tenant_active_status_reason" */
export type Tenant_Active_Status_Reason_Aggregate_Fields = {
  __typename?: 'tenant_active_status_reason_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tenant_Active_Status_Reason_Max_Fields>;
  min?: Maybe<Tenant_Active_Status_Reason_Min_Fields>;
};

/** aggregate fields of "tenant_active_status_reason" */
export type Tenant_Active_Status_Reason_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Active_Status_Reason_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tenant_active_status_reason". All fields are combined with a logical 'AND'. */
export type Tenant_Active_Status_Reason_Bool_Exp = {
  _and?: InputMaybe<Array<Tenant_Active_Status_Reason_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Active_Status_Reason_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Active_Status_Reason_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenant_active_status_reason" */
export enum Tenant_Active_Status_Reason_Constraint {
  /** unique or primary key constraint on columns "name" */
  TenantActiveStatusReasonPkey = 'tenant_active_status_reason_pkey',
}

/** input type for inserting data into table "tenant_active_status_reason" */
export type Tenant_Active_Status_Reason_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tenant_Active_Status_Reason_Max_Fields = {
  __typename?: 'tenant_active_status_reason_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tenant_Active_Status_Reason_Min_Fields = {
  __typename?: 'tenant_active_status_reason_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tenant_active_status_reason" */
export type Tenant_Active_Status_Reason_Mutation_Response = {
  __typename?: 'tenant_active_status_reason_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant_Active_Status_Reason>;
};

/** on_conflict condition type for table "tenant_active_status_reason" */
export type Tenant_Active_Status_Reason_On_Conflict = {
  constraint: Tenant_Active_Status_Reason_Constraint;
  update_columns?: Array<Tenant_Active_Status_Reason_Update_Column>;
  where?: InputMaybe<Tenant_Active_Status_Reason_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant_active_status_reason". */
export type Tenant_Active_Status_Reason_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenant_active_status_reason */
export type Tenant_Active_Status_Reason_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "tenant_active_status_reason" */
export enum Tenant_Active_Status_Reason_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

/** input type for updating data in table "tenant_active_status_reason" */
export type Tenant_Active_Status_Reason_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "tenant_active_status_reason" */
export type Tenant_Active_Status_Reason_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Active_Status_Reason_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Active_Status_Reason_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "tenant_active_status_reason" */
export enum Tenant_Active_Status_Reason_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type Tenant_Active_Status_Reason_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenant_Active_Status_Reason_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Active_Status_Reason_Bool_Exp;
};

/** aggregated selection of "tenant" */
export type Tenant_Aggregate = {
  __typename?: 'tenant_aggregate';
  aggregate?: Maybe<Tenant_Aggregate_Fields>;
  nodes: Array<Tenant>;
};

export type Tenant_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tenant_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tenant_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tenant_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenant_Select_Column_Tenant_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenant_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenant_Select_Column_Tenant_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenant_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant" */
export type Tenant_Aggregate_Fields = {
  __typename?: 'tenant_aggregate_fields';
  avg?: Maybe<Tenant_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tenant_Max_Fields>;
  min?: Maybe<Tenant_Min_Fields>;
  stddev?: Maybe<Tenant_Stddev_Fields>;
  stddev_pop?: Maybe<Tenant_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenant_Stddev_Samp_Fields>;
  sum?: Maybe<Tenant_Sum_Fields>;
  var_pop?: Maybe<Tenant_Var_Pop_Fields>;
  var_samp?: Maybe<Tenant_Var_Samp_Fields>;
  variance?: Maybe<Tenant_Variance_Fields>;
};

/** aggregate fields of "tenant" */
export type Tenant_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant" */
export type Tenant_Aggregate_Order_By = {
  avg?: InputMaybe<Tenant_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenant_Max_Order_By>;
  min?: InputMaybe<Tenant_Min_Order_By>;
  stddev?: InputMaybe<Tenant_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tenant_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tenant_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tenant_Sum_Order_By>;
  var_pop?: InputMaybe<Tenant_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tenant_Var_Samp_Order_By>;
  variance?: InputMaybe<Tenant_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tenant" */
export type Tenant_Arr_Rel_Insert_Input = {
  data: Array<Tenant_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenant_On_Conflict>;
};

/** aggregate avg on columns */
export type Tenant_Avg_Fields = {
  __typename?: 'tenant_avg_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tenant" */
export type Tenant_Avg_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tenant". All fields are combined with a logical 'AND'. */
export type Tenant_Bool_Exp = {
  _and?: InputMaybe<Array<Tenant_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  active_status_reason?: InputMaybe<String_Comparison_Exp>;
  alias?: InputMaybe<String_Comparison_Exp>;
  central_cloud?: InputMaybe<Central_Cloud_Bool_Exp>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  cloudflare_proxy_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  config?: InputMaybe<Config_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_domains?: InputMaybe<Custom_Domain_Bool_Exp>;
  custom_domains_aggregate?: InputMaybe<Custom_Domain_Aggregate_Bool_Exp>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
  dedicated_vpc_id?: InputMaybe<Uuid_Comparison_Exp>;
  fqdn?: InputMaybe<String_Comparison_Exp>;
  health_status?: InputMaybe<Json_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  maintenance_mode?: InputMaybe<Boolean_Comparison_Exp>;
  major_version?: InputMaybe<Int_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  region_info?: InputMaybe<Region_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Tenant_Status_Bool_Exp>;
  status_aggregate?: InputMaybe<Tenant_Status_Aggregate_Bool_Exp>;
  tenant_group_members?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
  tenant_group_members_aggregate?: InputMaybe<
    Tenant_Group_Member_Aggregate_Bool_Exp
  >;
  tenant_public_status?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
  tenant_public_status_aggregate?: InputMaybe<
    Tenant_Public_Status_Aggregate_Bool_Exp
  >;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  workers?: InputMaybe<Hasura_Worker_Tenants_Bool_Exp>;
  workers_aggregate?: InputMaybe<Hasura_Worker_Tenants_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "tenant" */
export enum Tenant_Constraint {
  /** unique or primary key constraint on columns "fqdn" */
  TenantFqdnKey = 'tenant_fqdn_key',
  /** unique or primary key constraint on columns "id" */
  TenantPkey = 'tenant_pkey',
  /** unique or primary key constraint on columns "project_id" */
  TenantProjectIdKey = 'tenant_project_id_key',
  /** unique or primary key constraint on columns "slug" */
  TenantSlugKey = 'tenant_slug_key',
}

/** Group of tenants, meant for their co-location in a cluster */
export type Tenant_Group = {
  __typename?: 'tenant_group';
  allocation_policy: Scalars['String'];
  cloud: Scalars['String'];
  /** An object relationship */
  cluster?: Maybe<Hasura_Cluster>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  dedicated_vpc?: Maybe<Dedicated_Vpc>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  deployment_pools: Array<Cicd_Deployment_Pool_Member>;
  /** An aggregate relationship */
  deployment_pools_aggregate: Cicd_Deployment_Pool_Member_Aggregate;
  id: Scalars['uuid'];
  major_version: Scalars['Int'];
  /** An array relationship */
  members: Array<Tenant_Group_Member>;
  /** An aggregate relationship */
  members_aggregate: Tenant_Group_Member_Aggregate;
  plan_name?: Maybe<Scalars['String']>;
  region: Scalars['String'];
  /** An object relationship */
  region_info?: Maybe<Region>;
  slug: Scalars['String'];
};

/** Group of tenants, meant for their co-location in a cluster */
export type Tenant_GroupDeployment_PoolsArgs = {
  distinct_on?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
};

/** Group of tenants, meant for their co-location in a cluster */
export type Tenant_GroupDeployment_Pools_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Cicd_Deployment_Pool_Member_Order_By>>;
  where?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
};

/** Group of tenants, meant for their co-location in a cluster */
export type Tenant_GroupMembersArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Member_Order_By>>;
  where?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
};

/** Group of tenants, meant for their co-location in a cluster */
export type Tenant_GroupMembers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tenant_Group_Member_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tenant_Group_Member_Order_By>>;
  where?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
};

/** aggregated selection of "tenant_group" */
export type Tenant_Group_Aggregate = {
  __typename?: 'tenant_group_aggregate';
  aggregate?: Maybe<Tenant_Group_Aggregate_Fields>;
  nodes: Array<Tenant_Group>;
};

export type Tenant_Group_Aggregate_Bool_Exp = {
  count?: InputMaybe<Tenant_Group_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Group_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenant_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Group_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_group" */
export type Tenant_Group_Aggregate_Fields = {
  __typename?: 'tenant_group_aggregate_fields';
  avg?: Maybe<Tenant_Group_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tenant_Group_Max_Fields>;
  min?: Maybe<Tenant_Group_Min_Fields>;
  stddev?: Maybe<Tenant_Group_Stddev_Fields>;
  stddev_pop?: Maybe<Tenant_Group_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenant_Group_Stddev_Samp_Fields>;
  sum?: Maybe<Tenant_Group_Sum_Fields>;
  var_pop?: Maybe<Tenant_Group_Var_Pop_Fields>;
  var_samp?: Maybe<Tenant_Group_Var_Samp_Fields>;
  variance?: Maybe<Tenant_Group_Variance_Fields>;
};

/** aggregate fields of "tenant_group" */
export type Tenant_Group_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Group_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_group" */
export type Tenant_Group_Aggregate_Order_By = {
  avg?: InputMaybe<Tenant_Group_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenant_Group_Max_Order_By>;
  min?: InputMaybe<Tenant_Group_Min_Order_By>;
  stddev?: InputMaybe<Tenant_Group_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tenant_Group_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tenant_Group_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tenant_Group_Sum_Order_By>;
  var_pop?: InputMaybe<Tenant_Group_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tenant_Group_Var_Samp_Order_By>;
  variance?: InputMaybe<Tenant_Group_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tenant_group" */
export type Tenant_Group_Arr_Rel_Insert_Input = {
  data: Array<Tenant_Group_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenant_Group_On_Conflict>;
};

/** aggregate avg on columns */
export type Tenant_Group_Avg_Fields = {
  __typename?: 'tenant_group_avg_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tenant_group" */
export type Tenant_Group_Avg_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tenant_group". All fields are combined with a logical 'AND'. */
export type Tenant_Group_Bool_Exp = {
  _and?: InputMaybe<Array<Tenant_Group_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Group_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Group_Bool_Exp>>;
  allocation_policy?: InputMaybe<String_Comparison_Exp>;
  cloud?: InputMaybe<String_Comparison_Exp>;
  cluster?: InputMaybe<Hasura_Cluster_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
  dedicated_vpc_id?: InputMaybe<Uuid_Comparison_Exp>;
  deployment_pools?: InputMaybe<Cicd_Deployment_Pool_Member_Bool_Exp>;
  deployment_pools_aggregate?: InputMaybe<
    Cicd_Deployment_Pool_Member_Aggregate_Bool_Exp
  >;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  major_version?: InputMaybe<Int_Comparison_Exp>;
  members?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
  members_aggregate?: InputMaybe<Tenant_Group_Member_Aggregate_Bool_Exp>;
  plan_name?: InputMaybe<String_Comparison_Exp>;
  region?: InputMaybe<String_Comparison_Exp>;
  region_info?: InputMaybe<Region_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenant_group" */
export enum Tenant_Group_Constraint {
  /** unique or primary key constraint on columns "id" */
  TenantGroupPkey = 'tenant_group_pkey',
  /** unique or primary key constraint on columns "slug" */
  TenantGroupSlugKey = 'tenant_group_slug_key',
}

/** input type for incrementing numeric columns in table "tenant_group" */
export type Tenant_Group_Inc_Input = {
  major_version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tenant_group" */
export type Tenant_Group_Insert_Input = {
  allocation_policy?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  cluster?: InputMaybe<Hasura_Cluster_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Obj_Rel_Insert_Input>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  deployment_pools?: InputMaybe<
    Cicd_Deployment_Pool_Member_Arr_Rel_Insert_Input
  >;
  id?: InputMaybe<Scalars['uuid']>;
  major_version?: InputMaybe<Scalars['Int']>;
  members?: InputMaybe<Tenant_Group_Member_Arr_Rel_Insert_Input>;
  plan_name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  region_info?: InputMaybe<Region_Obj_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tenant_Group_Max_Fields = {
  __typename?: 'tenant_group_max_fields';
  allocation_policy?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  major_version?: Maybe<Scalars['Int']>;
  plan_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tenant_group" */
export type Tenant_Group_Max_Order_By = {
  allocation_policy?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  plan_name?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** Table connecting tenants and tenant groups */
export type Tenant_Group_Member = {
  __typename?: 'tenant_group_member';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  group: Tenant_Group;
  group_id: Scalars['uuid'];
  id: Scalars['uuid'];
  port?: Maybe<Scalars['Int']>;
  send_traffic: Scalars['Boolean'];
  /** An object relationship */
  tenant: Tenant;
  tenant_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "tenant_group_member" */
export type Tenant_Group_Member_Aggregate = {
  __typename?: 'tenant_group_member_aggregate';
  aggregate?: Maybe<Tenant_Group_Member_Aggregate_Fields>;
  nodes: Array<Tenant_Group_Member>;
};

export type Tenant_Group_Member_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tenant_Group_Member_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tenant_Group_Member_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tenant_Group_Member_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Group_Member_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenant_Group_Member_Select_Column_Tenant_Group_Member_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenant_Group_Member_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenant_Group_Member_Select_Column_Tenant_Group_Member_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenant_Group_Member_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenant_Group_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_group_member" */
export type Tenant_Group_Member_Aggregate_Fields = {
  __typename?: 'tenant_group_member_aggregate_fields';
  avg?: Maybe<Tenant_Group_Member_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Tenant_Group_Member_Max_Fields>;
  min?: Maybe<Tenant_Group_Member_Min_Fields>;
  stddev?: Maybe<Tenant_Group_Member_Stddev_Fields>;
  stddev_pop?: Maybe<Tenant_Group_Member_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tenant_Group_Member_Stddev_Samp_Fields>;
  sum?: Maybe<Tenant_Group_Member_Sum_Fields>;
  var_pop?: Maybe<Tenant_Group_Member_Var_Pop_Fields>;
  var_samp?: Maybe<Tenant_Group_Member_Var_Samp_Fields>;
  variance?: Maybe<Tenant_Group_Member_Variance_Fields>;
};

/** aggregate fields of "tenant_group_member" */
export type Tenant_Group_Member_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Group_Member_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_group_member" */
export type Tenant_Group_Member_Aggregate_Order_By = {
  avg?: InputMaybe<Tenant_Group_Member_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenant_Group_Member_Max_Order_By>;
  min?: InputMaybe<Tenant_Group_Member_Min_Order_By>;
  stddev?: InputMaybe<Tenant_Group_Member_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tenant_Group_Member_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tenant_Group_Member_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tenant_Group_Member_Sum_Order_By>;
  var_pop?: InputMaybe<Tenant_Group_Member_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tenant_Group_Member_Var_Samp_Order_By>;
  variance?: InputMaybe<Tenant_Group_Member_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "tenant_group_member" */
export type Tenant_Group_Member_Arr_Rel_Insert_Input = {
  data: Array<Tenant_Group_Member_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenant_Group_Member_On_Conflict>;
};

/** aggregate avg on columns */
export type Tenant_Group_Member_Avg_Fields = {
  __typename?: 'tenant_group_member_avg_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tenant_group_member" */
export type Tenant_Group_Member_Avg_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tenant_group_member". All fields are combined with a logical 'AND'. */
export type Tenant_Group_Member_Bool_Exp = {
  _and?: InputMaybe<Array<Tenant_Group_Member_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Group_Member_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  group?: InputMaybe<Tenant_Group_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  port?: InputMaybe<Int_Comparison_Exp>;
  send_traffic?: InputMaybe<Boolean_Comparison_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenant_group_member" */
export enum Tenant_Group_Member_Constraint {
  /** unique or primary key constraint on columns "group_id", "port" */
  TenantGroupMemberGroupIdPortKey = 'tenant_group_member_group_id_port_key',
  /** unique or primary key constraint on columns "id" */
  TenantGroupMemberPkey = 'tenant_group_member_pkey',
  /** unique or primary key constraint on columns "group_id", "tenant_id" */
  TenantGroupMemberTenantIdGroupIdKey = 'tenant_group_member_tenant_id_group_id_key',
  /** unique or primary key constraint on columns "group_id", "port" */
  TenantGroupPortUnique = 'tenant_group_port_unique',
}

/** input type for incrementing numeric columns in table "tenant_group_member" */
export type Tenant_Group_Member_Inc_Input = {
  port?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tenant_group_member" */
export type Tenant_Group_Member_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group?: InputMaybe<Tenant_Group_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  port?: InputMaybe<Scalars['Int']>;
  send_traffic?: InputMaybe<Scalars['Boolean']>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tenant_Group_Member_Max_Fields = {
  __typename?: 'tenant_group_member_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  port?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tenant_group_member" */
export type Tenant_Group_Member_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  port?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Group_Member_Min_Fields = {
  __typename?: 'tenant_group_member_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  port?: Maybe<Scalars['Int']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tenant_group_member" */
export type Tenant_Group_Member_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  port?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenant_group_member" */
export type Tenant_Group_Member_Mutation_Response = {
  __typename?: 'tenant_group_member_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant_Group_Member>;
};

/** on_conflict condition type for table "tenant_group_member" */
export type Tenant_Group_Member_On_Conflict = {
  constraint: Tenant_Group_Member_Constraint;
  update_columns?: Array<Tenant_Group_Member_Update_Column>;
  where?: InputMaybe<Tenant_Group_Member_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant_group_member". */
export type Tenant_Group_Member_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group?: InputMaybe<Tenant_Group_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  port?: InputMaybe<Order_By>;
  send_traffic?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenant_group_member */
export type Tenant_Group_Member_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tenant_group_member" */
export enum Tenant_Group_Member_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Port = 'port',
  /** column name */
  SendTraffic = 'send_traffic',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "tenant_group_member_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenant_group_member" */
export enum Tenant_Group_Member_Select_Column_Tenant_Group_Member_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  SendTraffic = 'send_traffic',
}

/** select "tenant_group_member_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenant_group_member" */
export enum Tenant_Group_Member_Select_Column_Tenant_Group_Member_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  SendTraffic = 'send_traffic',
}

/** input type for updating data in table "tenant_group_member" */
export type Tenant_Group_Member_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  port?: InputMaybe<Scalars['Int']>;
  send_traffic?: InputMaybe<Scalars['Boolean']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Tenant_Group_Member_Stddev_Fields = {
  __typename?: 'tenant_group_member_stddev_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tenant_group_member" */
export type Tenant_Group_Member_Stddev_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tenant_Group_Member_Stddev_Pop_Fields = {
  __typename?: 'tenant_group_member_stddev_pop_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tenant_group_member" */
export type Tenant_Group_Member_Stddev_Pop_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tenant_Group_Member_Stddev_Samp_Fields = {
  __typename?: 'tenant_group_member_stddev_samp_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tenant_group_member" */
export type Tenant_Group_Member_Stddev_Samp_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tenant_group_member" */
export type Tenant_Group_Member_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Group_Member_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Group_Member_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  port?: InputMaybe<Scalars['Int']>;
  send_traffic?: InputMaybe<Scalars['Boolean']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tenant_Group_Member_Sum_Fields = {
  __typename?: 'tenant_group_member_sum_fields';
  port?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tenant_group_member" */
export type Tenant_Group_Member_Sum_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** update columns of table "tenant_group_member" */
export enum Tenant_Group_Member_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  Port = 'port',
  /** column name */
  SendTraffic = 'send_traffic',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Tenant_Group_Member_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tenant_Group_Member_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenant_Group_Member_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Group_Member_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tenant_Group_Member_Var_Pop_Fields = {
  __typename?: 'tenant_group_member_var_pop_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tenant_group_member" */
export type Tenant_Group_Member_Var_Pop_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tenant_Group_Member_Var_Samp_Fields = {
  __typename?: 'tenant_group_member_var_samp_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tenant_group_member" */
export type Tenant_Group_Member_Var_Samp_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tenant_Group_Member_Variance_Fields = {
  __typename?: 'tenant_group_member_variance_fields';
  port?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tenant_group_member" */
export type Tenant_Group_Member_Variance_Order_By = {
  port?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Group_Min_Fields = {
  __typename?: 'tenant_group_min_fields';
  allocation_policy?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  major_version?: Maybe<Scalars['Int']>;
  plan_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tenant_group" */
export type Tenant_Group_Min_Order_By = {
  allocation_policy?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  plan_name?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenant_group" */
export type Tenant_Group_Mutation_Response = {
  __typename?: 'tenant_group_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant_Group>;
};

/** input type for inserting object relation for remote table "tenant_group" */
export type Tenant_Group_Obj_Rel_Insert_Input = {
  data: Tenant_Group_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenant_Group_On_Conflict>;
};

/** on_conflict condition type for table "tenant_group" */
export type Tenant_Group_On_Conflict = {
  constraint: Tenant_Group_Constraint;
  update_columns?: Array<Tenant_Group_Update_Column>;
  where?: InputMaybe<Tenant_Group_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant_group". */
export type Tenant_Group_Order_By = {
  allocation_policy?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  cluster?: InputMaybe<Hasura_Cluster_Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  deployment_pools_aggregate?: InputMaybe<
    Cicd_Deployment_Pool_Member_Aggregate_Order_By
  >;
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  members_aggregate?: InputMaybe<Tenant_Group_Member_Aggregate_Order_By>;
  plan_name?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  region_info?: InputMaybe<Region_Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenant_group */
export type Tenant_Group_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tenant_group" */
export enum Tenant_Group_Select_Column {
  /** column name */
  AllocationPolicy = 'allocation_policy',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DedicatedVpcId = 'dedicated_vpc_id',
  /** column name */
  Id = 'id',
  /** column name */
  MajorVersion = 'major_version',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
}

/** input type for updating data in table "tenant_group" */
export type Tenant_Group_Set_Input = {
  allocation_policy?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  major_version?: InputMaybe<Scalars['Int']>;
  plan_name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Tenant_Group_Stddev_Fields = {
  __typename?: 'tenant_group_stddev_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tenant_group" */
export type Tenant_Group_Stddev_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tenant_Group_Stddev_Pop_Fields = {
  __typename?: 'tenant_group_stddev_pop_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tenant_group" */
export type Tenant_Group_Stddev_Pop_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tenant_Group_Stddev_Samp_Fields = {
  __typename?: 'tenant_group_stddev_samp_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tenant_group" */
export type Tenant_Group_Stddev_Samp_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tenant_group" */
export type Tenant_Group_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Group_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Group_Stream_Cursor_Value_Input = {
  allocation_policy?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  major_version?: InputMaybe<Scalars['Int']>;
  plan_name?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Tenant_Group_Sum_Fields = {
  __typename?: 'tenant_group_sum_fields';
  major_version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tenant_group" */
export type Tenant_Group_Sum_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** update columns of table "tenant_group" */
export enum Tenant_Group_Update_Column {
  /** column name */
  AllocationPolicy = 'allocation_policy',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DedicatedVpcId = 'dedicated_vpc_id',
  /** column name */
  Id = 'id',
  /** column name */
  MajorVersion = 'major_version',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
}

export type Tenant_Group_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tenant_Group_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenant_Group_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Group_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tenant_Group_Var_Pop_Fields = {
  __typename?: 'tenant_group_var_pop_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tenant_group" */
export type Tenant_Group_Var_Pop_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tenant_Group_Var_Samp_Fields = {
  __typename?: 'tenant_group_var_samp_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tenant_group" */
export type Tenant_Group_Var_Samp_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tenant_Group_Variance_Fields = {
  __typename?: 'tenant_group_variance_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tenant_group" */
export type Tenant_Group_Variance_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** input type for incrementing numeric columns in table "tenant" */
export type Tenant_Inc_Input = {
  major_version?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "tenant" */
export type Tenant_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  active_status_reason?: InputMaybe<Scalars['String']>;
  alias?: InputMaybe<Scalars['String']>;
  central_cloud?: InputMaybe<Central_Cloud_Obj_Rel_Insert_Input>;
  cloud?: InputMaybe<Scalars['String']>;
  cloudflare_proxy_enabled?: InputMaybe<Scalars['Boolean']>;
  config?: InputMaybe<Config_Obj_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_domains?: InputMaybe<Custom_Domain_Arr_Rel_Insert_Input>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Obj_Rel_Insert_Input>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  maintenance_mode?: InputMaybe<Scalars['Boolean']>;
  major_version?: InputMaybe<Scalars['Int']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  region_info?: InputMaybe<Region_Obj_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Tenant_Status_Arr_Rel_Insert_Input>;
  tenant_group_members?: InputMaybe<Tenant_Group_Member_Arr_Rel_Insert_Input>;
  tenant_public_status?: InputMaybe<Tenant_Public_Status_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  workers?: InputMaybe<Hasura_Worker_Tenants_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tenant_Max_Fields = {
  __typename?: 'tenant_max_fields';
  active_status_reason?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  major_version?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tenant" */
export type Tenant_Max_Order_By = {
  active_status_reason?: InputMaybe<Order_By>;
  alias?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Min_Fields = {
  __typename?: 'tenant_min_fields';
  active_status_reason?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  cloud?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  fqdn?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  major_version?: Maybe<Scalars['Int']>;
  project_id?: Maybe<Scalars['uuid']>;
  region?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tenant" */
export type Tenant_Min_Order_By = {
  active_status_reason?: InputMaybe<Order_By>;
  alias?: InputMaybe<Order_By>;
  cloud?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenant" */
export type Tenant_Mutation_Response = {
  __typename?: 'tenant_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant>;
};

/** input type for inserting object relation for remote table "tenant" */
export type Tenant_Obj_Rel_Insert_Input = {
  data: Tenant_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenant_On_Conflict>;
};

/** on_conflict condition type for table "tenant" */
export type Tenant_On_Conflict = {
  constraint: Tenant_Constraint;
  update_columns?: Array<Tenant_Update_Column>;
  where?: InputMaybe<Tenant_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant". */
export type Tenant_Order_By = {
  active?: InputMaybe<Order_By>;
  active_status_reason?: InputMaybe<Order_By>;
  alias?: InputMaybe<Order_By>;
  central_cloud?: InputMaybe<Central_Cloud_Order_By>;
  cloud?: InputMaybe<Order_By>;
  cloudflare_proxy_enabled?: InputMaybe<Order_By>;
  config?: InputMaybe<Config_Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_domains_aggregate?: InputMaybe<Custom_Domain_Aggregate_Order_By>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  fqdn?: InputMaybe<Order_By>;
  health_status?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  maintenance_mode?: InputMaybe<Order_By>;
  major_version?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  region?: InputMaybe<Order_By>;
  region_info?: InputMaybe<Region_Order_By>;
  slug?: InputMaybe<Order_By>;
  status_aggregate?: InputMaybe<Tenant_Status_Aggregate_Order_By>;
  tenant_group_members_aggregate?: InputMaybe<
    Tenant_Group_Member_Aggregate_Order_By
  >;
  tenant_public_status_aggregate?: InputMaybe<
    Tenant_Public_Status_Aggregate_Order_By
  >;
  updated_at?: InputMaybe<Order_By>;
  workers_aggregate?: InputMaybe<Hasura_Worker_Tenants_Aggregate_Order_By>;
};

/** primary key columns input for table: tenant */
export type Tenant_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** Tenant status used for public health check */
export type Tenant_Public_Status = {
  __typename?: 'tenant_public_status';
  endpoint: Scalars['String'];
  is_active: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  /** An object relationship */
  tenant: Tenant;
  tenant_id: Scalars['uuid'];
  type: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "tenant_public_status" */
export type Tenant_Public_Status_Aggregate = {
  __typename?: 'tenant_public_status_aggregate';
  aggregate?: Maybe<Tenant_Public_Status_Aggregate_Fields>;
  nodes: Array<Tenant_Public_Status>;
};

export type Tenant_Public_Status_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tenant_Public_Status_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tenant_Public_Status_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tenant_Public_Status_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Public_Status_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenant_Public_Status_Select_Column_Tenant_Public_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenant_Public_Status_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenant_Public_Status_Select_Column_Tenant_Public_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenant_Public_Status_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenant_Public_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_public_status" */
export type Tenant_Public_Status_Aggregate_Fields = {
  __typename?: 'tenant_public_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tenant_Public_Status_Max_Fields>;
  min?: Maybe<Tenant_Public_Status_Min_Fields>;
};

/** aggregate fields of "tenant_public_status" */
export type Tenant_Public_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Public_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_public_status" */
export type Tenant_Public_Status_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenant_Public_Status_Max_Order_By>;
  min?: InputMaybe<Tenant_Public_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenant_public_status" */
export type Tenant_Public_Status_Arr_Rel_Insert_Input = {
  data: Array<Tenant_Public_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenant_Public_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenant_public_status". All fields are combined with a logical 'AND'. */
export type Tenant_Public_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Tenant_Public_Status_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Public_Status_Bool_Exp>>;
  endpoint?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenant_public_status" */
export enum Tenant_Public_Status_Constraint {
  /** unique or primary key constraint on columns "endpoint", "tenant_id" */
  TenantPublicStatusPkey = 'tenant_public_status_pkey',
}

/** input type for inserting data into table "tenant_public_status" */
export type Tenant_Public_Status_Insert_Input = {
  endpoint?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Tenant_Public_Status_Max_Fields = {
  __typename?: 'tenant_public_status_max_fields';
  endpoint?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "tenant_public_status" */
export type Tenant_Public_Status_Max_Order_By = {
  endpoint?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Public_Status_Min_Fields = {
  __typename?: 'tenant_public_status_min_fields';
  endpoint?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "tenant_public_status" */
export type Tenant_Public_Status_Min_Order_By = {
  endpoint?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenant_public_status" */
export type Tenant_Public_Status_Mutation_Response = {
  __typename?: 'tenant_public_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant_Public_Status>;
};

/** on_conflict condition type for table "tenant_public_status" */
export type Tenant_Public_Status_On_Conflict = {
  constraint: Tenant_Public_Status_Constraint;
  update_columns?: Array<Tenant_Public_Status_Update_Column>;
  where?: InputMaybe<Tenant_Public_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant_public_status". */
export type Tenant_Public_Status_Order_By = {
  endpoint?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenant_public_status */
export type Tenant_Public_Status_Pk_Columns_Input = {
  endpoint: Scalars['String'];
  tenant_id: Scalars['uuid'];
};

/** select columns of table "tenant_public_status" */
export enum Tenant_Public_Status_Select_Column {
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Message = 'message',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "tenant_public_status_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenant_public_status" */
export enum Tenant_Public_Status_Select_Column_Tenant_Public_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** select "tenant_public_status_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenant_public_status" */
export enum Tenant_Public_Status_Select_Column_Tenant_Public_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** input type for updating data in table "tenant_public_status" */
export type Tenant_Public_Status_Set_Input = {
  endpoint?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "tenant_public_status" */
export type Tenant_Public_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Public_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Public_Status_Stream_Cursor_Value_Input = {
  endpoint?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "tenant_public_status" */
export enum Tenant_Public_Status_Update_Column {
  /** column name */
  Endpoint = 'endpoint',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Message = 'message',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Tenant_Public_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenant_Public_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Public_Status_Bool_Exp;
};

/** select columns of table "tenant" */
export enum Tenant_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ActiveStatusReason = 'active_status_reason',
  /** column name */
  Alias = 'alias',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CloudflareProxyEnabled = 'cloudflare_proxy_enabled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DedicatedVpcId = 'dedicated_vpc_id',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  MaintenanceMode = 'maintenance_mode',
  /** column name */
  MajorVersion = 'major_version',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "tenant_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenant" */
export enum Tenant_Select_Column_Tenant_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  CloudflareProxyEnabled = 'cloudflare_proxy_enabled',
  /** column name */
  MaintenanceMode = 'maintenance_mode',
}

/** select "tenant_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenant" */
export enum Tenant_Select_Column_Tenant_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Active = 'active',
  /** column name */
  CloudflareProxyEnabled = 'cloudflare_proxy_enabled',
  /** column name */
  MaintenanceMode = 'maintenance_mode',
}

/** input type for updating data in table "tenant" */
export type Tenant_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  active_status_reason?: InputMaybe<Scalars['String']>;
  alias?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  cloudflare_proxy_enabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  maintenance_mode?: InputMaybe<Scalars['Boolean']>;
  major_version?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Tenant status used for internal health check */
export type Tenant_Status = {
  __typename?: 'tenant_status';
  has_inconsistent_metadata?: Maybe<Scalars['Boolean']>;
  is_active: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  /** An object relationship */
  tenant: Tenant;
  tenant_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  worker_id: Scalars['uuid'];
};

/** aggregated selection of "tenant_status" */
export type Tenant_Status_Aggregate = {
  __typename?: 'tenant_status_aggregate';
  aggregate?: Maybe<Tenant_Status_Aggregate_Fields>;
  nodes: Array<Tenant_Status>;
};

export type Tenant_Status_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Tenant_Status_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Tenant_Status_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Tenant_Status_Aggregate_Bool_Exp_Count>;
};

export type Tenant_Status_Aggregate_Bool_Exp_Bool_And = {
  arguments: Tenant_Status_Select_Column_Tenant_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Status_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenant_Status_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Tenant_Status_Select_Column_Tenant_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Status_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Tenant_Status_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tenant_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Tenant_Status_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "tenant_status" */
export type Tenant_Status_Aggregate_Fields = {
  __typename?: 'tenant_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tenant_Status_Max_Fields>;
  min?: Maybe<Tenant_Status_Min_Fields>;
};

/** aggregate fields of "tenant_status" */
export type Tenant_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tenant_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tenant_status" */
export type Tenant_Status_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tenant_Status_Max_Order_By>;
  min?: InputMaybe<Tenant_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "tenant_status" */
export type Tenant_Status_Arr_Rel_Insert_Input = {
  data: Array<Tenant_Status_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Tenant_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "tenant_status". All fields are combined with a logical 'AND'. */
export type Tenant_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Tenant_Status_Bool_Exp>>;
  _not?: InputMaybe<Tenant_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Tenant_Status_Bool_Exp>>;
  has_inconsistent_metadata?: InputMaybe<Boolean_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  tenant?: InputMaybe<Tenant_Bool_Exp>;
  tenant_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  worker_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "tenant_status" */
export enum Tenant_Status_Constraint {
  /** unique or primary key constraint on columns "worker_id", "tenant_id" */
  TenantStatusPkey = 'tenant_status_pkey',
}

/** input type for inserting data into table "tenant_status" */
export type Tenant_Status_Insert_Input = {
  has_inconsistent_metadata?: InputMaybe<Scalars['Boolean']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  tenant?: InputMaybe<Tenant_Obj_Rel_Insert_Input>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Tenant_Status_Max_Fields = {
  __typename?: 'tenant_status_max_fields';
  message?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "tenant_status" */
export type Tenant_Status_Max_Order_By = {
  message?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tenant_Status_Min_Fields = {
  __typename?: 'tenant_status_min_fields';
  message?: Maybe<Scalars['String']>;
  tenant_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "tenant_status" */
export type Tenant_Status_Min_Order_By = {
  message?: InputMaybe<Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tenant_status" */
export type Tenant_Status_Mutation_Response = {
  __typename?: 'tenant_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tenant_Status>;
};

/** on_conflict condition type for table "tenant_status" */
export type Tenant_Status_On_Conflict = {
  constraint: Tenant_Status_Constraint;
  update_columns?: Array<Tenant_Status_Update_Column>;
  where?: InputMaybe<Tenant_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "tenant_status". */
export type Tenant_Status_Order_By = {
  has_inconsistent_metadata?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  tenant?: InputMaybe<Tenant_Order_By>;
  tenant_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  worker_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tenant_status */
export type Tenant_Status_Pk_Columns_Input = {
  tenant_id: Scalars['uuid'];
  worker_id: Scalars['uuid'];
};

/** select columns of table "tenant_status" */
export enum Tenant_Status_Select_Column {
  /** column name */
  HasInconsistentMetadata = 'has_inconsistent_metadata',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Message = 'message',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkerId = 'worker_id',
}

/** select "tenant_status_aggregate_bool_exp_bool_and_arguments_columns" columns of table "tenant_status" */
export enum Tenant_Status_Select_Column_Tenant_Status_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  HasInconsistentMetadata = 'has_inconsistent_metadata',
  /** column name */
  IsActive = 'is_active',
}

/** select "tenant_status_aggregate_bool_exp_bool_or_arguments_columns" columns of table "tenant_status" */
export enum Tenant_Status_Select_Column_Tenant_Status_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  HasInconsistentMetadata = 'has_inconsistent_metadata',
  /** column name */
  IsActive = 'is_active',
}

/** input type for updating data in table "tenant_status" */
export type Tenant_Status_Set_Input = {
  has_inconsistent_metadata?: InputMaybe<Scalars['Boolean']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "tenant_status" */
export type Tenant_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Status_Stream_Cursor_Value_Input = {
  has_inconsistent_metadata?: InputMaybe<Scalars['Boolean']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  message?: InputMaybe<Scalars['String']>;
  tenant_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  worker_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "tenant_status" */
export enum Tenant_Status_Update_Column {
  /** column name */
  HasInconsistentMetadata = 'has_inconsistent_metadata',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Message = 'message',
  /** column name */
  TenantId = 'tenant_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WorkerId = 'worker_id',
}

export type Tenant_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenant_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Status_Bool_Exp;
};

/** aggregate stddev on columns */
export type Tenant_Stddev_Fields = {
  __typename?: 'tenant_stddev_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tenant" */
export type Tenant_Stddev_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tenant_Stddev_Pop_Fields = {
  __typename?: 'tenant_stddev_pop_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tenant" */
export type Tenant_Stddev_Pop_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tenant_Stddev_Samp_Fields = {
  __typename?: 'tenant_stddev_samp_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tenant" */
export type Tenant_Stddev_Samp_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "tenant" */
export type Tenant_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tenant_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tenant_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  active_status_reason?: InputMaybe<Scalars['String']>;
  alias?: InputMaybe<Scalars['String']>;
  cloud?: InputMaybe<Scalars['String']>;
  cloudflare_proxy_enabled?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  fqdn?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  maintenance_mode?: InputMaybe<Scalars['Boolean']>;
  major_version?: InputMaybe<Scalars['Int']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  region?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Tenant_Sum_Fields = {
  __typename?: 'tenant_sum_fields';
  major_version?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "tenant" */
export type Tenant_Sum_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** update columns of table "tenant" */
export enum Tenant_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  ActiveStatusReason = 'active_status_reason',
  /** column name */
  Alias = 'alias',
  /** column name */
  Cloud = 'cloud',
  /** column name */
  CloudflareProxyEnabled = 'cloudflare_proxy_enabled',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DedicatedVpcId = 'dedicated_vpc_id',
  /** column name */
  Fqdn = 'fqdn',
  /** column name */
  Id = 'id',
  /** column name */
  MaintenanceMode = 'maintenance_mode',
  /** column name */
  MajorVersion = 'major_version',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Region = 'region',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Tenant_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tenant_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tenant_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tenant_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tenant_Var_Pop_Fields = {
  __typename?: 'tenant_var_pop_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tenant" */
export type Tenant_Var_Pop_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tenant_Var_Samp_Fields = {
  __typename?: 'tenant_var_samp_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tenant" */
export type Tenant_Var_Samp_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tenant_Variance_Fields = {
  __typename?: 'tenant_variance_fields';
  major_version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tenant" */
export type Tenant_Variance_Order_By = {
  major_version?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "timetz". All fields are combined with logical 'AND'. */
export type Timetz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timetz']>;
  _gt?: InputMaybe<Scalars['timetz']>;
  _gte?: InputMaybe<Scalars['timetz']>;
  _in?: InputMaybe<Array<Scalars['timetz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timetz']>;
  _lte?: InputMaybe<Scalars['timetz']>;
  _neq?: InputMaybe<Scalars['timetz']>;
  _nin?: InputMaybe<Array<Scalars['timetz']>>;
};

/** columns and relationships of "trial_leads" */
export type Trial_Leads = {
  __typename?: 'trial_leads';
  additional_info?: Maybe<Scalars['jsonb']>;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  email_verified?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  job_function?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "trial_leads" */
export type Trial_LeadsAdditional_InfoArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "trial_leads" */
export type Trial_Leads_Aggregate = {
  __typename?: 'trial_leads_aggregate';
  aggregate?: Maybe<Trial_Leads_Aggregate_Fields>;
  nodes: Array<Trial_Leads>;
};

/** aggregate fields of "trial_leads" */
export type Trial_Leads_Aggregate_Fields = {
  __typename?: 'trial_leads_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Trial_Leads_Max_Fields>;
  min?: Maybe<Trial_Leads_Min_Fields>;
};

/** aggregate fields of "trial_leads" */
export type Trial_Leads_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Trial_Leads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Trial_Leads_Append_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "trial_leads". All fields are combined with a logical 'AND'. */
export type Trial_Leads_Bool_Exp = {
  _and?: InputMaybe<Array<Trial_Leads_Bool_Exp>>;
  _not?: InputMaybe<Trial_Leads_Bool_Exp>;
  _or?: InputMaybe<Array<Trial_Leads_Bool_Exp>>;
  additional_info?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_verified?: InputMaybe<Boolean_Comparison_Exp>;
  first?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  job_function?: InputMaybe<String_Comparison_Exp>;
  last?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<String_Comparison_Exp>;
  phone?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "trial_leads" */
export enum Trial_Leads_Constraint {
  /** unique or primary key constraint on columns "id" */
  TrialLeadsPkey = 'trial_leads_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Trial_Leads_Delete_At_Path_Input = {
  additional_info?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Trial_Leads_Delete_Elem_Input = {
  additional_info?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Trial_Leads_Delete_Key_Input = {
  additional_info?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "trial_leads" */
export type Trial_Leads_Insert_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  job_function?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Trial_Leads_Max_Fields = {
  __typename?: 'trial_leads_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  job_function?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Trial_Leads_Min_Fields = {
  __typename?: 'trial_leads_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  job_function?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "trial_leads" */
export type Trial_Leads_Mutation_Response = {
  __typename?: 'trial_leads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Trial_Leads>;
};

/** on_conflict condition type for table "trial_leads" */
export type Trial_Leads_On_Conflict = {
  constraint: Trial_Leads_Constraint;
  update_columns?: Array<Trial_Leads_Update_Column>;
  where?: InputMaybe<Trial_Leads_Bool_Exp>;
};

/** Ordering options when selecting data from "trial_leads". */
export type Trial_Leads_Order_By = {
  additional_info?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_verified?: InputMaybe<Order_By>;
  first?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  job_function?: InputMaybe<Order_By>;
  last?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  phone?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: trial_leads */
export type Trial_Leads_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Trial_Leads_Prepend_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "trial_leads" */
export enum Trial_Leads_Select_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  First = 'first',
  /** column name */
  Id = 'id',
  /** column name */
  JobFunction = 'job_function',
  /** column name */
  Last = 'last',
  /** column name */
  Organization = 'organization',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "trial_leads" */
export type Trial_Leads_Set_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  job_function?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "trial_leads" */
export type Trial_Leads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Trial_Leads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Trial_Leads_Stream_Cursor_Value_Input = {
  additional_info?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified?: InputMaybe<Scalars['Boolean']>;
  first?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  job_function?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['String']>;
  organization?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "trial_leads" */
export enum Trial_Leads_Update_Column {
  /** column name */
  AdditionalInfo = 'additional_info',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerified = 'email_verified',
  /** column name */
  First = 'first',
  /** column name */
  Id = 'id',
  /** column name */
  JobFunction = 'job_function',
  /** column name */
  Last = 'last',
  /** column name */
  Organization = 'organization',
  /** column name */
  Phone = 'phone',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Trial_Leads_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Trial_Leads_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Trial_Leads_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Trial_Leads_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Trial_Leads_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Trial_Leads_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Trial_Leads_Set_Input>;
  /** filter the rows which have to be updated */
  where: Trial_Leads_Bool_Exp;
};

/** Boolean expression to compare columns of type "u_int8". All fields are combined with logical 'AND'. */
export type U_Int8_Clickhouse_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['u_int8']>;
  _gt?: InputMaybe<Scalars['u_int8']>;
  _gte?: InputMaybe<Scalars['u_int8']>;
  _in?: InputMaybe<Array<Scalars['u_int8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['u_int8']>;
  _lte?: InputMaybe<Scalars['u_int8']>;
  _neq?: InputMaybe<Scalars['u_int8']>;
  _nin?: InputMaybe<Array<Scalars['u_int8']>>;
};

/** Boolean expression to compare columns of type "u_int16". All fields are combined with logical 'AND'. */
export type U_Int16_Clickhouse_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['u_int16']>;
  _gt?: InputMaybe<Scalars['u_int16']>;
  _gte?: InputMaybe<Scalars['u_int16']>;
  _in?: InputMaybe<Array<Scalars['u_int16']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['u_int16']>;
  _lte?: InputMaybe<Scalars['u_int16']>;
  _neq?: InputMaybe<Scalars['u_int16']>;
  _nin?: InputMaybe<Array<Scalars['u_int16']>>;
};

/** Boolean expression to compare columns of type "u_int64". All fields are combined with logical 'AND'. */
export type U_Int64_Clickhouse_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['u_int64']>;
  _gt?: InputMaybe<Scalars['u_int64']>;
  _gte?: InputMaybe<Scalars['u_int64']>;
  _in?: InputMaybe<Array<Scalars['u_int64']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['u_int64']>;
  _lte?: InputMaybe<Scalars['u_int64']>;
  _neq?: InputMaybe<Scalars['u_int64']>;
  _nin?: InputMaybe<Array<Scalars['u_int64']>>;
};

/** Stores all user assumption session audit logs. */
export type Ua_Audit_Logs = {
  __typename?: 'ua_audit_logs';
  action: Scalars['String'];
  actor_email: Scalars['String'];
  created_at: Scalars['timestamptz'];
  customer_email: Scalars['String'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "ua_audit_logs" */
export type Ua_Audit_Logs_Aggregate = {
  __typename?: 'ua_audit_logs_aggregate';
  aggregate?: Maybe<Ua_Audit_Logs_Aggregate_Fields>;
  nodes: Array<Ua_Audit_Logs>;
};

/** aggregate fields of "ua_audit_logs" */
export type Ua_Audit_Logs_Aggregate_Fields = {
  __typename?: 'ua_audit_logs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Ua_Audit_Logs_Max_Fields>;
  min?: Maybe<Ua_Audit_Logs_Min_Fields>;
};

/** aggregate fields of "ua_audit_logs" */
export type Ua_Audit_Logs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Ua_Audit_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "ua_audit_logs". All fields are combined with a logical 'AND'. */
export type Ua_Audit_Logs_Bool_Exp = {
  _and?: InputMaybe<Array<Ua_Audit_Logs_Bool_Exp>>;
  _not?: InputMaybe<Ua_Audit_Logs_Bool_Exp>;
  _or?: InputMaybe<Array<Ua_Audit_Logs_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  actor_email?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "ua_audit_logs" */
export enum Ua_Audit_Logs_Constraint {
  /** unique or primary key constraint on columns "id" */
  UaAuditLogsPkey = 'ua_audit_logs_pkey',
}

/** input type for inserting data into table "ua_audit_logs" */
export type Ua_Audit_Logs_Insert_Input = {
  action?: InputMaybe<Scalars['String']>;
  actor_email?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Ua_Audit_Logs_Max_Fields = {
  __typename?: 'ua_audit_logs_max_fields';
  action?: Maybe<Scalars['String']>;
  actor_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Ua_Audit_Logs_Min_Fields = {
  __typename?: 'ua_audit_logs_min_fields';
  action?: Maybe<Scalars['String']>;
  actor_email?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "ua_audit_logs" */
export type Ua_Audit_Logs_Mutation_Response = {
  __typename?: 'ua_audit_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Ua_Audit_Logs>;
};

/** on_conflict condition type for table "ua_audit_logs" */
export type Ua_Audit_Logs_On_Conflict = {
  constraint: Ua_Audit_Logs_Constraint;
  update_columns?: Array<Ua_Audit_Logs_Update_Column>;
  where?: InputMaybe<Ua_Audit_Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "ua_audit_logs". */
export type Ua_Audit_Logs_Order_By = {
  action?: InputMaybe<Order_By>;
  actor_email?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: ua_audit_logs */
export type Ua_Audit_Logs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "ua_audit_logs" */
export enum Ua_Audit_Logs_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActorEmail = 'actor_email',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "ua_audit_logs" */
export type Ua_Audit_Logs_Set_Input = {
  action?: InputMaybe<Scalars['String']>;
  actor_email?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "ua_audit_logs" */
export type Ua_Audit_Logs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ua_Audit_Logs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ua_Audit_Logs_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']>;
  actor_email?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "ua_audit_logs" */
export enum Ua_Audit_Logs_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActorEmail = 'actor_email',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerEmail = 'customer_email',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Ua_Audit_Logs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Ua_Audit_Logs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Ua_Audit_Logs_Bool_Exp;
};

/** Boolean expression to compare columns of type "user_action". All fields are combined with logical 'AND'. */
export type User_Action_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['user_action']>;
  _gt?: InputMaybe<Scalars['user_action']>;
  _gte?: InputMaybe<Scalars['user_action']>;
  _in?: InputMaybe<Array<Scalars['user_action']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['user_action']>;
  _lte?: InputMaybe<Scalars['user_action']>;
  _neq?: InputMaybe<Scalars['user_action']>;
  _nin?: InputMaybe<Array<Scalars['user_action']>>;
};

/** Captures user activity */
export type User_Activity = {
  __typename?: 'user_activity';
  action: Scalars['user_action'];
  id: Scalars['uuid'];
  ip: Scalars['String'];
  metadata?: Maybe<Scalars['jsonb']>;
  timestamp: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};

/** Captures user activity */
export type User_ActivityMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_activity" */
export type User_Activity_Aggregate = {
  __typename?: 'user_activity_aggregate';
  aggregate?: Maybe<User_Activity_Aggregate_Fields>;
  nodes: Array<User_Activity>;
};

/** aggregate fields of "user_activity" */
export type User_Activity_Aggregate_Fields = {
  __typename?: 'user_activity_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Activity_Max_Fields>;
  min?: Maybe<User_Activity_Min_Fields>;
};

/** aggregate fields of "user_activity" */
export type User_Activity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Activity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Activity_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "user_activity". All fields are combined with a logical 'AND'. */
export type User_Activity_Bool_Exp = {
  _and?: InputMaybe<Array<User_Activity_Bool_Exp>>;
  _not?: InputMaybe<User_Activity_Bool_Exp>;
  _or?: InputMaybe<Array<User_Activity_Bool_Exp>>;
  action?: InputMaybe<User_Action_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ip?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_activity" */
export enum User_Activity_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserActivityPkey = 'user_activity_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Activity_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Activity_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Activity_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user_activity" */
export type User_Activity_Insert_Input = {
  action?: InputMaybe<Scalars['user_action']>;
  id?: InputMaybe<Scalars['uuid']>;
  ip?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Activity_Max_Fields = {
  __typename?: 'user_activity_max_fields';
  action?: Maybe<Scalars['user_action']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Activity_Min_Fields = {
  __typename?: 'user_activity_min_fields';
  action?: Maybe<Scalars['user_action']>;
  id?: Maybe<Scalars['uuid']>;
  ip?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_activity" */
export type User_Activity_Mutation_Response = {
  __typename?: 'user_activity_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Activity>;
};

/** on_conflict condition type for table "user_activity" */
export type User_Activity_On_Conflict = {
  constraint: User_Activity_Constraint;
  update_columns?: Array<User_Activity_Update_Column>;
  where?: InputMaybe<User_Activity_Bool_Exp>;
};

/** Ordering options when selecting data from "user_activity". */
export type User_Activity_Order_By = {
  action?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ip?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  timestamp?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_activity */
export type User_Activity_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Activity_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_activity" */
export enum User_Activity_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_activity" */
export type User_Activity_Set_Input = {
  action?: InputMaybe<Scalars['user_action']>;
  id?: InputMaybe<Scalars['uuid']>;
  ip?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_activity" */
export type User_Activity_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Activity_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Activity_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['user_action']>;
  id?: InputMaybe<Scalars['uuid']>;
  ip?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  timestamp?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_activity" */
export enum User_Activity_Update_Column {
  /** column name */
  Action = 'action',
  /** column name */
  Id = 'id',
  /** column name */
  Ip = 'ip',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UserId = 'user_id',
}

export type User_Activity_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Activity_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Activity_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Activity_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Activity_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Activity_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Activity_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Activity_Bool_Exp;
};

/** Coupon to get discount for a specific customer */
export type User_Coupon = {
  __typename?: 'user_coupon';
  amount_off: Scalars['Int'];
  /** An object relationship */
  coupon: Coupon;
  coupon_name: Scalars['String'];
  created_at: Scalars['timestamptz'];
  customer_id: Scalars['String'];
  id: Scalars['uuid'];
  is_applied: Scalars['Boolean'];
  percentage_off?: Maybe<Scalars['numeric']>;
  promotion_code: Scalars['citext'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "user_coupon" */
export type User_Coupon_Aggregate = {
  __typename?: 'user_coupon_aggregate';
  aggregate?: Maybe<User_Coupon_Aggregate_Fields>;
  nodes: Array<User_Coupon>;
};

export type User_Coupon_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Coupon_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Coupon_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Coupon_Aggregate_Bool_Exp_Count>;
};

export type User_Coupon_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Coupon_Select_Column_User_Coupon_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Coupon_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Coupon_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Coupon_Select_Column_User_Coupon_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Coupon_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Coupon_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Coupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Coupon_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_coupon" */
export type User_Coupon_Aggregate_Fields = {
  __typename?: 'user_coupon_aggregate_fields';
  avg?: Maybe<User_Coupon_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Coupon_Max_Fields>;
  min?: Maybe<User_Coupon_Min_Fields>;
  stddev?: Maybe<User_Coupon_Stddev_Fields>;
  stddev_pop?: Maybe<User_Coupon_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Coupon_Stddev_Samp_Fields>;
  sum?: Maybe<User_Coupon_Sum_Fields>;
  var_pop?: Maybe<User_Coupon_Var_Pop_Fields>;
  var_samp?: Maybe<User_Coupon_Var_Samp_Fields>;
  variance?: Maybe<User_Coupon_Variance_Fields>;
};

/** aggregate fields of "user_coupon" */
export type User_Coupon_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Coupon_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_coupon" */
export type User_Coupon_Aggregate_Order_By = {
  avg?: InputMaybe<User_Coupon_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Coupon_Max_Order_By>;
  min?: InputMaybe<User_Coupon_Min_Order_By>;
  stddev?: InputMaybe<User_Coupon_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Coupon_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Coupon_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Coupon_Sum_Order_By>;
  var_pop?: InputMaybe<User_Coupon_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Coupon_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Coupon_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_coupon" */
export type User_Coupon_Arr_Rel_Insert_Input = {
  data: Array<User_Coupon_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Coupon_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Coupon_Avg_Fields = {
  __typename?: 'user_coupon_avg_fields';
  amount_off?: Maybe<Scalars['Float']>;
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "user_coupon" */
export type User_Coupon_Avg_Order_By = {
  amount_off?: InputMaybe<Order_By>;
  percentage_off?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_coupon". All fields are combined with a logical 'AND'. */
export type User_Coupon_Bool_Exp = {
  _and?: InputMaybe<Array<User_Coupon_Bool_Exp>>;
  _not?: InputMaybe<User_Coupon_Bool_Exp>;
  _or?: InputMaybe<Array<User_Coupon_Bool_Exp>>;
  amount_off?: InputMaybe<Int_Comparison_Exp>;
  coupon?: InputMaybe<Coupon_Bool_Exp>;
  coupon_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_applied?: InputMaybe<Boolean_Comparison_Exp>;
  percentage_off?: InputMaybe<Numeric_Comparison_Exp>;
  promotion_code?: InputMaybe<Citext_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "user_coupon" */
export enum User_Coupon_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserCouponPkey = 'user_coupon_pkey',
}

/** input type for incrementing numeric columns in table "user_coupon" */
export type User_Coupon_Inc_Input = {
  amount_off?: InputMaybe<Scalars['Int']>;
  percentage_off?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "user_coupon" */
export type User_Coupon_Insert_Input = {
  amount_off?: InputMaybe<Scalars['Int']>;
  coupon?: InputMaybe<Coupon_Obj_Rel_Insert_Input>;
  coupon_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_applied?: InputMaybe<Scalars['Boolean']>;
  percentage_off?: InputMaybe<Scalars['numeric']>;
  promotion_code?: InputMaybe<Scalars['citext']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type User_Coupon_Max_Fields = {
  __typename?: 'user_coupon_max_fields';
  amount_off?: Maybe<Scalars['Int']>;
  coupon_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  percentage_off?: Maybe<Scalars['numeric']>;
  promotion_code?: Maybe<Scalars['citext']>;
};

/** order by max() on columns of table "user_coupon" */
export type User_Coupon_Max_Order_By = {
  amount_off?: InputMaybe<Order_By>;
  coupon_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  percentage_off?: InputMaybe<Order_By>;
  promotion_code?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Coupon_Min_Fields = {
  __typename?: 'user_coupon_min_fields';
  amount_off?: Maybe<Scalars['Int']>;
  coupon_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  percentage_off?: Maybe<Scalars['numeric']>;
  promotion_code?: Maybe<Scalars['citext']>;
};

/** order by min() on columns of table "user_coupon" */
export type User_Coupon_Min_Order_By = {
  amount_off?: InputMaybe<Order_By>;
  coupon_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  percentage_off?: InputMaybe<Order_By>;
  promotion_code?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_coupon" */
export type User_Coupon_Mutation_Response = {
  __typename?: 'user_coupon_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Coupon>;
};

/** on_conflict condition type for table "user_coupon" */
export type User_Coupon_On_Conflict = {
  constraint: User_Coupon_Constraint;
  update_columns?: Array<User_Coupon_Update_Column>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

/** Ordering options when selecting data from "user_coupon". */
export type User_Coupon_Order_By = {
  amount_off?: InputMaybe<Order_By>;
  coupon?: InputMaybe<Coupon_Order_By>;
  coupon_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_applied?: InputMaybe<Order_By>;
  percentage_off?: InputMaybe<Order_By>;
  promotion_code?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: user_coupon */
export type User_Coupon_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_coupon" */
export enum User_Coupon_Select_Column {
  /** column name */
  AmountOff = 'amount_off',
  /** column name */
  CouponName = 'coupon_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsApplied = 'is_applied',
  /** column name */
  PercentageOff = 'percentage_off',
  /** column name */
  PromotionCode = 'promotion_code',
}

/** select "user_coupon_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_coupon" */
export enum User_Coupon_Select_Column_User_Coupon_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsApplied = 'is_applied',
}

/** select "user_coupon_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_coupon" */
export enum User_Coupon_Select_Column_User_Coupon_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsApplied = 'is_applied',
}

/** input type for updating data in table "user_coupon" */
export type User_Coupon_Set_Input = {
  amount_off?: InputMaybe<Scalars['Int']>;
  coupon_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_applied?: InputMaybe<Scalars['Boolean']>;
  percentage_off?: InputMaybe<Scalars['numeric']>;
  promotion_code?: InputMaybe<Scalars['citext']>;
};

/** aggregate stddev on columns */
export type User_Coupon_Stddev_Fields = {
  __typename?: 'user_coupon_stddev_fields';
  amount_off?: Maybe<Scalars['Float']>;
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "user_coupon" */
export type User_Coupon_Stddev_Order_By = {
  amount_off?: InputMaybe<Order_By>;
  percentage_off?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Coupon_Stddev_Pop_Fields = {
  __typename?: 'user_coupon_stddev_pop_fields';
  amount_off?: Maybe<Scalars['Float']>;
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "user_coupon" */
export type User_Coupon_Stddev_Pop_Order_By = {
  amount_off?: InputMaybe<Order_By>;
  percentage_off?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Coupon_Stddev_Samp_Fields = {
  __typename?: 'user_coupon_stddev_samp_fields';
  amount_off?: Maybe<Scalars['Float']>;
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "user_coupon" */
export type User_Coupon_Stddev_Samp_Order_By = {
  amount_off?: InputMaybe<Order_By>;
  percentage_off?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_coupon" */
export type User_Coupon_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Coupon_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Coupon_Stream_Cursor_Value_Input = {
  amount_off?: InputMaybe<Scalars['Int']>;
  coupon_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_applied?: InputMaybe<Scalars['Boolean']>;
  percentage_off?: InputMaybe<Scalars['numeric']>;
  promotion_code?: InputMaybe<Scalars['citext']>;
};

/** aggregate sum on columns */
export type User_Coupon_Sum_Fields = {
  __typename?: 'user_coupon_sum_fields';
  amount_off?: Maybe<Scalars['Int']>;
  percentage_off?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "user_coupon" */
export type User_Coupon_Sum_Order_By = {
  amount_off?: InputMaybe<Order_By>;
  percentage_off?: InputMaybe<Order_By>;
};

/** update columns of table "user_coupon" */
export enum User_Coupon_Update_Column {
  /** column name */
  AmountOff = 'amount_off',
  /** column name */
  CouponName = 'coupon_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsApplied = 'is_applied',
  /** column name */
  PercentageOff = 'percentage_off',
  /** column name */
  PromotionCode = 'promotion_code',
}

export type User_Coupon_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Coupon_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Coupon_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Coupon_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Coupon_Var_Pop_Fields = {
  __typename?: 'user_coupon_var_pop_fields';
  amount_off?: Maybe<Scalars['Float']>;
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "user_coupon" */
export type User_Coupon_Var_Pop_Order_By = {
  amount_off?: InputMaybe<Order_By>;
  percentage_off?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Coupon_Var_Samp_Fields = {
  __typename?: 'user_coupon_var_samp_fields';
  amount_off?: Maybe<Scalars['Float']>;
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "user_coupon" */
export type User_Coupon_Var_Samp_Order_By = {
  amount_off?: InputMaybe<Order_By>;
  percentage_off?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Coupon_Variance_Fields = {
  __typename?: 'user_coupon_variance_fields';
  amount_off?: Maybe<Scalars['Float']>;
  percentage_off?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "user_coupon" */
export type User_Coupon_Variance_Order_By = {
  amount_off?: InputMaybe<Order_By>;
  percentage_off?: InputMaybe<Order_By>;
};

/** Each entry in this table represents entitlements a user has access to. */
export type User_Entitlement_Access = {
  __typename?: 'user_entitlement_access';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  entitlement: User_Entitlement_Catalogue;
  entitlement_id: Scalars['uuid'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_entitlement_access" */
export type User_Entitlement_Access_Aggregate = {
  __typename?: 'user_entitlement_access_aggregate';
  aggregate?: Maybe<User_Entitlement_Access_Aggregate_Fields>;
  nodes: Array<User_Entitlement_Access>;
};

export type User_Entitlement_Access_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Entitlement_Access_Aggregate_Bool_Exp_Count>;
};

export type User_Entitlement_Access_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Entitlement_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Entitlement_Access_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_entitlement_access" */
export type User_Entitlement_Access_Aggregate_Fields = {
  __typename?: 'user_entitlement_access_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Entitlement_Access_Max_Fields>;
  min?: Maybe<User_Entitlement_Access_Min_Fields>;
};

/** aggregate fields of "user_entitlement_access" */
export type User_Entitlement_Access_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Entitlement_Access_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_entitlement_access" */
export type User_Entitlement_Access_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Entitlement_Access_Max_Order_By>;
  min?: InputMaybe<User_Entitlement_Access_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_entitlement_access" */
export type User_Entitlement_Access_Arr_Rel_Insert_Input = {
  data: Array<User_Entitlement_Access_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Entitlement_Access_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_entitlement_access". All fields are combined with a logical 'AND'. */
export type User_Entitlement_Access_Bool_Exp = {
  _and?: InputMaybe<Array<User_Entitlement_Access_Bool_Exp>>;
  _not?: InputMaybe<User_Entitlement_Access_Bool_Exp>;
  _or?: InputMaybe<Array<User_Entitlement_Access_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  entitlement?: InputMaybe<User_Entitlement_Catalogue_Bool_Exp>;
  entitlement_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_entitlement_access" */
export enum User_Entitlement_Access_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserEntitlementAccessPkey = 'user_entitlement_access_pkey',
}

/** input type for inserting data into table "user_entitlement_access" */
export type User_Entitlement_Access_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entitlement?: InputMaybe<User_Entitlement_Catalogue_Obj_Rel_Insert_Input>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Entitlement_Access_Max_Fields = {
  __typename?: 'user_entitlement_access_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entitlement_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_entitlement_access" */
export type User_Entitlement_Access_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Entitlement_Access_Min_Fields = {
  __typename?: 'user_entitlement_access_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  entitlement_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_entitlement_access" */
export type User_Entitlement_Access_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_entitlement_access" */
export type User_Entitlement_Access_Mutation_Response = {
  __typename?: 'user_entitlement_access_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Entitlement_Access>;
};

/** input type for inserting object relation for remote table "user_entitlement_access" */
export type User_Entitlement_Access_Obj_Rel_Insert_Input = {
  data: User_Entitlement_Access_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Entitlement_Access_On_Conflict>;
};

/** on_conflict condition type for table "user_entitlement_access" */
export type User_Entitlement_Access_On_Conflict = {
  constraint: User_Entitlement_Access_Constraint;
  update_columns?: Array<User_Entitlement_Access_Update_Column>;
  where?: InputMaybe<User_Entitlement_Access_Bool_Exp>;
};

/** Ordering options when selecting data from "user_entitlement_access". */
export type User_Entitlement_Access_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entitlement?: InputMaybe<User_Entitlement_Catalogue_Order_By>;
  entitlement_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_entitlement_access */
export type User_Entitlement_Access_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_entitlement_access" */
export enum User_Entitlement_Access_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntitlementId = 'entitlement_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_entitlement_access" */
export type User_Entitlement_Access_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_entitlement_access" */
export type User_Entitlement_Access_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Entitlement_Access_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Entitlement_Access_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  entitlement_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_entitlement_access" */
export enum User_Entitlement_Access_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntitlementId = 'entitlement_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Entitlement_Access_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Entitlement_Access_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Entitlement_Access_Bool_Exp;
};

/** Stores all versions of all types of user entitlements. */
export type User_Entitlement_Catalogue = {
  __typename?: 'user_entitlement_catalogue';
  config_is_enabled: Scalars['Boolean'];
  config_limit?: Maybe<Scalars['Int']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['bigint']>;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  type: User_Entitlement_Types_Enum;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "user_entitlement_catalogue" */
export type User_Entitlement_Catalogue_Aggregate = {
  __typename?: 'user_entitlement_catalogue_aggregate';
  aggregate?: Maybe<User_Entitlement_Catalogue_Aggregate_Fields>;
  nodes: Array<User_Entitlement_Catalogue>;
};

/** aggregate fields of "user_entitlement_catalogue" */
export type User_Entitlement_Catalogue_Aggregate_Fields = {
  __typename?: 'user_entitlement_catalogue_aggregate_fields';
  avg?: Maybe<User_Entitlement_Catalogue_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Entitlement_Catalogue_Max_Fields>;
  min?: Maybe<User_Entitlement_Catalogue_Min_Fields>;
  stddev?: Maybe<User_Entitlement_Catalogue_Stddev_Fields>;
  stddev_pop?: Maybe<User_Entitlement_Catalogue_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Entitlement_Catalogue_Stddev_Samp_Fields>;
  sum?: Maybe<User_Entitlement_Catalogue_Sum_Fields>;
  var_pop?: Maybe<User_Entitlement_Catalogue_Var_Pop_Fields>;
  var_samp?: Maybe<User_Entitlement_Catalogue_Var_Samp_Fields>;
  variance?: Maybe<User_Entitlement_Catalogue_Variance_Fields>;
};

/** aggregate fields of "user_entitlement_catalogue" */
export type User_Entitlement_Catalogue_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Entitlement_Catalogue_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Entitlement_Catalogue_Avg_Fields = {
  __typename?: 'user_entitlement_catalogue_avg_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_entitlement_catalogue". All fields are combined with a logical 'AND'. */
export type User_Entitlement_Catalogue_Bool_Exp = {
  _and?: InputMaybe<Array<User_Entitlement_Catalogue_Bool_Exp>>;
  _not?: InputMaybe<User_Entitlement_Catalogue_Bool_Exp>;
  _or?: InputMaybe<Array<User_Entitlement_Catalogue_Bool_Exp>>;
  config_is_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  config_limit?: InputMaybe<Int_Comparison_Exp>;
  cost?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<User_Entitlement_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_entitlement_catalogue" */
export enum User_Entitlement_Catalogue_Constraint {
  /** unique or primary key constraint on columns "name" */
  UserEntitlementCatalogueNameKey = 'user_entitlement_catalogue_name_key',
  /** unique or primary key constraint on columns "id" */
  UserEntitlementCataloguePkey = 'user_entitlement_catalogue_pkey',
}

/** input type for incrementing numeric columns in table "user_entitlement_catalogue" */
export type User_Entitlement_Catalogue_Inc_Input = {
  config_limit?: InputMaybe<Scalars['Int']>;
  /** Cost of entitlement in microdollars */
  cost?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "user_entitlement_catalogue" */
export type User_Entitlement_Catalogue_Insert_Input = {
  config_is_enabled?: InputMaybe<Scalars['Boolean']>;
  config_limit?: InputMaybe<Scalars['Int']>;
  /** Cost of entitlement in microdollars */
  cost?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<User_Entitlement_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type User_Entitlement_Catalogue_Max_Fields = {
  __typename?: 'user_entitlement_catalogue_max_fields';
  config_limit?: Maybe<Scalars['Int']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type User_Entitlement_Catalogue_Min_Fields = {
  __typename?: 'user_entitlement_catalogue_min_fields';
  config_limit?: Maybe<Scalars['Int']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['bigint']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "user_entitlement_catalogue" */
export type User_Entitlement_Catalogue_Mutation_Response = {
  __typename?: 'user_entitlement_catalogue_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Entitlement_Catalogue>;
};

/** input type for inserting object relation for remote table "user_entitlement_catalogue" */
export type User_Entitlement_Catalogue_Obj_Rel_Insert_Input = {
  data: User_Entitlement_Catalogue_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Entitlement_Catalogue_On_Conflict>;
};

/** on_conflict condition type for table "user_entitlement_catalogue" */
export type User_Entitlement_Catalogue_On_Conflict = {
  constraint: User_Entitlement_Catalogue_Constraint;
  update_columns?: Array<User_Entitlement_Catalogue_Update_Column>;
  where?: InputMaybe<User_Entitlement_Catalogue_Bool_Exp>;
};

/** Ordering options when selecting data from "user_entitlement_catalogue". */
export type User_Entitlement_Catalogue_Order_By = {
  config_is_enabled?: InputMaybe<Order_By>;
  config_limit?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_entitlement_catalogue */
export type User_Entitlement_Catalogue_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_entitlement_catalogue" */
export enum User_Entitlement_Catalogue_Select_Column {
  /** column name */
  ConfigIsEnabled = 'config_is_enabled',
  /** column name */
  ConfigLimit = 'config_limit',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "user_entitlement_catalogue" */
export type User_Entitlement_Catalogue_Set_Input = {
  config_is_enabled?: InputMaybe<Scalars['Boolean']>;
  config_limit?: InputMaybe<Scalars['Int']>;
  /** Cost of entitlement in microdollars */
  cost?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<User_Entitlement_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type User_Entitlement_Catalogue_Stddev_Fields = {
  __typename?: 'user_entitlement_catalogue_stddev_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Entitlement_Catalogue_Stddev_Pop_Fields = {
  __typename?: 'user_entitlement_catalogue_stddev_pop_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Entitlement_Catalogue_Stddev_Samp_Fields = {
  __typename?: 'user_entitlement_catalogue_stddev_samp_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_entitlement_catalogue" */
export type User_Entitlement_Catalogue_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Entitlement_Catalogue_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Entitlement_Catalogue_Stream_Cursor_Value_Input = {
  config_is_enabled?: InputMaybe<Scalars['Boolean']>;
  config_limit?: InputMaybe<Scalars['Int']>;
  /** Cost of entitlement in microdollars */
  cost?: InputMaybe<Scalars['bigint']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<User_Entitlement_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type User_Entitlement_Catalogue_Sum_Fields = {
  __typename?: 'user_entitlement_catalogue_sum_fields';
  config_limit?: Maybe<Scalars['Int']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['bigint']>;
};

/** update columns of table "user_entitlement_catalogue" */
export enum User_Entitlement_Catalogue_Update_Column {
  /** column name */
  ConfigIsEnabled = 'config_is_enabled',
  /** column name */
  ConfigLimit = 'config_limit',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type User_Entitlement_Catalogue_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Entitlement_Catalogue_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Entitlement_Catalogue_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Entitlement_Catalogue_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Entitlement_Catalogue_Var_Pop_Fields = {
  __typename?: 'user_entitlement_catalogue_var_pop_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Entitlement_Catalogue_Var_Samp_Fields = {
  __typename?: 'user_entitlement_catalogue_var_samp_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Entitlement_Catalogue_Variance_Fields = {
  __typename?: 'user_entitlement_catalogue_variance_fields';
  config_limit?: Maybe<Scalars['Float']>;
  /** Cost of entitlement in microdollars */
  cost?: Maybe<Scalars['Float']>;
};

/** Enums to represent all types of entitlements a user can have. */
export type User_Entitlement_Types = {
  __typename?: 'user_entitlement_types';
  comment: Scalars['String'];
  value: Scalars['String'];
};

/** aggregated selection of "user_entitlement_types" */
export type User_Entitlement_Types_Aggregate = {
  __typename?: 'user_entitlement_types_aggregate';
  aggregate?: Maybe<User_Entitlement_Types_Aggregate_Fields>;
  nodes: Array<User_Entitlement_Types>;
};

/** aggregate fields of "user_entitlement_types" */
export type User_Entitlement_Types_Aggregate_Fields = {
  __typename?: 'user_entitlement_types_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Entitlement_Types_Max_Fields>;
  min?: Maybe<User_Entitlement_Types_Min_Fields>;
};

/** aggregate fields of "user_entitlement_types" */
export type User_Entitlement_Types_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Entitlement_Types_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_entitlement_types". All fields are combined with a logical 'AND'. */
export type User_Entitlement_Types_Bool_Exp = {
  _and?: InputMaybe<Array<User_Entitlement_Types_Bool_Exp>>;
  _not?: InputMaybe<User_Entitlement_Types_Bool_Exp>;
  _or?: InputMaybe<Array<User_Entitlement_Types_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_entitlement_types" */
export enum User_Entitlement_Types_Constraint {
  /** unique or primary key constraint on columns "value" */
  UserEntitlementTypesPkey = 'user_entitlement_types_pkey',
}

export enum User_Entitlement_Types_Enum {
  /** Can user create project on DDN Advanced plan */
  DdnAdvancedAccess = 'ddn_advanced_access',
  /** Can user create project on DDN Advanced Trial plan */
  DdnAdvancedTrialAccess = 'ddn_advanced_trial_access',
  /** Can user create project on DDN Base plan */
  DdnBaseAccess = 'ddn_base_access',
  /** Can user create project on DDN Base Trial plan */
  DdnBaseTrialAccess = 'ddn_base_trial_access',
  /** Can user create project on DDN Free plan */
  DdnFreeAccess = 'ddn_free_access',
  /** Configure access to plan-wide dedicated_cloud features */
  DedicatedCloudAccess = 'dedicated_cloud_access',
  /** Access and unit cost correpsonding to data passthrough on the Dedicated Cloud Plan. */
  DedicatedCloudDataPassthrough = 'dedicated_cloud_data_passthrough',
  /** Access and unit cost correpsonding no database on the Dedicated Cloud Plan. */
  DedicatedCloudNoDb = 'dedicated_cloud_no_db',
  /** Access and unit cost correpsonding Non Postgres database on the Dedicated Cloud Plan. */
  DedicatedCloudNonPgDb = 'dedicated_cloud_non_pg_db',
  /** Access and unit cost correpsonding Postgres database on the Dedicated Cloud Plan. */
  DedicatedCloudPgDb = 'dedicated_cloud_pg_db',
  /** Is user ignored from DDN billing */
  IgnoreDdnBilling = 'ignore_ddn_billing',
  /** Configure access to cloud enterprise model based pricing plans */
  ModelBasedEnterpriseCloudAccess = 'model_based_enterprise_cloud_access',
  /** Configure access to user assumption related features */
  UserAssumptionAccess = 'user_assumption_access',
  /** Hard limit for the number of v3 projects a user can own */
  V3MaxProjectLimit = 'v3_max_project_limit',
  /** Hard limit for the number of v3 tunnels a user can own */
  V3MaxTunnelLimit = 'v3_max_tunnel_limit',
}

/** Boolean expression to compare columns of type "user_entitlement_types_enum". All fields are combined with logical 'AND'. */
export type User_Entitlement_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<User_Entitlement_Types_Enum>;
  _in?: InputMaybe<Array<User_Entitlement_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<User_Entitlement_Types_Enum>;
  _nin?: InputMaybe<Array<User_Entitlement_Types_Enum>>;
};

/** input type for inserting data into table "user_entitlement_types" */
export type User_Entitlement_Types_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Entitlement_Types_Max_Fields = {
  __typename?: 'user_entitlement_types_max_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type User_Entitlement_Types_Min_Fields = {
  __typename?: 'user_entitlement_types_min_fields';
  comment?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "user_entitlement_types" */
export type User_Entitlement_Types_Mutation_Response = {
  __typename?: 'user_entitlement_types_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Entitlement_Types>;
};

/** on_conflict condition type for table "user_entitlement_types" */
export type User_Entitlement_Types_On_Conflict = {
  constraint: User_Entitlement_Types_Constraint;
  update_columns?: Array<User_Entitlement_Types_Update_Column>;
  where?: InputMaybe<User_Entitlement_Types_Bool_Exp>;
};

/** Ordering options when selecting data from "user_entitlement_types". */
export type User_Entitlement_Types_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_entitlement_types */
export type User_Entitlement_Types_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "user_entitlement_types" */
export enum User_Entitlement_Types_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "user_entitlement_types" */
export type User_Entitlement_Types_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "user_entitlement_types" */
export type User_Entitlement_Types_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Entitlement_Types_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Entitlement_Types_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "user_entitlement_types" */
export enum User_Entitlement_Types_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

export type User_Entitlement_Types_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Entitlement_Types_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Entitlement_Types_Bool_Exp;
};

/** Captures user onboarding details and progress */
export type User_Onboarding = {
  __typename?: 'user_onboarding';
  activity?: Maybe<Scalars['jsonb']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  is_onboarded: Scalars['Boolean'];
  target: Scalars['String'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** Captures user onboarding details and progress */
export type User_OnboardingActivityArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_onboarding" */
export type User_Onboarding_Aggregate = {
  __typename?: 'user_onboarding_aggregate';
  aggregate?: Maybe<User_Onboarding_Aggregate_Fields>;
  nodes: Array<User_Onboarding>;
};

export type User_Onboarding_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<User_Onboarding_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<User_Onboarding_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Onboarding_Aggregate_Bool_Exp_Count>;
};

export type User_Onboarding_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Onboarding_Select_Column_User_Onboarding_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Onboarding_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Onboarding_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Onboarding_Select_Column_User_Onboarding_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Onboarding_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Onboarding_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Onboarding_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Onboarding_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_onboarding" */
export type User_Onboarding_Aggregate_Fields = {
  __typename?: 'user_onboarding_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Onboarding_Max_Fields>;
  min?: Maybe<User_Onboarding_Min_Fields>;
};

/** aggregate fields of "user_onboarding" */
export type User_Onboarding_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Onboarding_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_onboarding" */
export type User_Onboarding_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Onboarding_Max_Order_By>;
  min?: InputMaybe<User_Onboarding_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type User_Onboarding_Append_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "user_onboarding" */
export type User_Onboarding_Arr_Rel_Insert_Input = {
  data: Array<User_Onboarding_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Onboarding_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_onboarding". All fields are combined with a logical 'AND'. */
export type User_Onboarding_Bool_Exp = {
  _and?: InputMaybe<Array<User_Onboarding_Bool_Exp>>;
  _not?: InputMaybe<User_Onboarding_Bool_Exp>;
  _or?: InputMaybe<Array<User_Onboarding_Bool_Exp>>;
  activity?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  details?: InputMaybe<String_Comparison_Exp>;
  is_onboarded?: InputMaybe<Boolean_Comparison_Exp>;
  target?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_onboarding" */
export enum User_Onboarding_Constraint {
  /** unique or primary key constraint on columns "target", "user_id" */
  UserOnboardingPkey = 'user_onboarding_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type User_Onboarding_Delete_At_Path_Input = {
  activity?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type User_Onboarding_Delete_Elem_Input = {
  activity?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type User_Onboarding_Delete_Key_Input = {
  activity?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "user_onboarding" */
export type User_Onboarding_Insert_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['String']>;
  is_onboarded?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Onboarding_Max_Fields = {
  __typename?: 'user_onboarding_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_onboarding" */
export type User_Onboarding_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  target?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Onboarding_Min_Fields = {
  __typename?: 'user_onboarding_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  details?: Maybe<Scalars['String']>;
  target?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_onboarding" */
export type User_Onboarding_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  target?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_onboarding" */
export type User_Onboarding_Mutation_Response = {
  __typename?: 'user_onboarding_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Onboarding>;
};

/** on_conflict condition type for table "user_onboarding" */
export type User_Onboarding_On_Conflict = {
  constraint: User_Onboarding_Constraint;
  update_columns?: Array<User_Onboarding_Update_Column>;
  where?: InputMaybe<User_Onboarding_Bool_Exp>;
};

/** Ordering options when selecting data from "user_onboarding". */
export type User_Onboarding_Order_By = {
  activity?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  details?: InputMaybe<Order_By>;
  is_onboarded?: InputMaybe<Order_By>;
  target?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_onboarding */
export type User_Onboarding_Pk_Columns_Input = {
  target: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type User_Onboarding_Prepend_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "user_onboarding" */
export enum User_Onboarding_Select_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  IsOnboarded = 'is_onboarded',
  /** column name */
  Target = 'target',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "user_onboarding_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_onboarding" */
export enum User_Onboarding_Select_Column_User_Onboarding_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsOnboarded = 'is_onboarded',
}

/** select "user_onboarding_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_onboarding" */
export enum User_Onboarding_Select_Column_User_Onboarding_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsOnboarded = 'is_onboarded',
}

/** input type for updating data in table "user_onboarding" */
export type User_Onboarding_Set_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['String']>;
  is_onboarded?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_onboarding" */
export type User_Onboarding_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Onboarding_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Onboarding_Stream_Cursor_Value_Input = {
  activity?: InputMaybe<Scalars['jsonb']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  details?: InputMaybe<Scalars['String']>;
  is_onboarded?: InputMaybe<Scalars['Boolean']>;
  target?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_onboarding" */
export enum User_Onboarding_Update_Column {
  /** column name */
  Activity = 'activity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Details = 'details',
  /** column name */
  IsOnboarded = 'is_onboarded',
  /** column name */
  Target = 'target',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Onboarding_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<User_Onboarding_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<User_Onboarding_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<User_Onboarding_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<User_Onboarding_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<User_Onboarding_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Onboarding_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Onboarding_Bool_Exp;
};

/** Captures personal access tokens of user to get admin access to a project */
export type User_Personal_Access_Tokens = {
  __typename?: 'user_personal_access_tokens';
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  hashed_token: Scalars['String'];
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  last_used?: Maybe<Scalars['timestamptz']>;
  last_used_ip_address?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_personal_access_tokens" */
export type User_Personal_Access_Tokens_Aggregate = {
  __typename?: 'user_personal_access_tokens_aggregate';
  aggregate?: Maybe<User_Personal_Access_Tokens_Aggregate_Fields>;
  nodes: Array<User_Personal_Access_Tokens>;
};

export type User_Personal_Access_Tokens_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<
    User_Personal_Access_Tokens_Aggregate_Bool_Exp_Bool_And
  >;
  bool_or?: InputMaybe<User_Personal_Access_Tokens_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<User_Personal_Access_Tokens_Aggregate_Bool_Exp_Count>;
};

export type User_Personal_Access_Tokens_Aggregate_Bool_Exp_Bool_And = {
  arguments: User_Personal_Access_Tokens_Select_Column_User_Personal_Access_Tokens_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Personal_Access_Tokens_Aggregate_Bool_Exp_Bool_Or = {
  arguments: User_Personal_Access_Tokens_Select_Column_User_Personal_Access_Tokens_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type User_Personal_Access_Tokens_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Personal_Access_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_personal_access_tokens" */
export type User_Personal_Access_Tokens_Aggregate_Fields = {
  __typename?: 'user_personal_access_tokens_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Personal_Access_Tokens_Max_Fields>;
  min?: Maybe<User_Personal_Access_Tokens_Min_Fields>;
};

/** aggregate fields of "user_personal_access_tokens" */
export type User_Personal_Access_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Personal_Access_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_personal_access_tokens" */
export type User_Personal_Access_Tokens_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Personal_Access_Tokens_Max_Order_By>;
  min?: InputMaybe<User_Personal_Access_Tokens_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_personal_access_tokens" */
export type User_Personal_Access_Tokens_Arr_Rel_Insert_Input = {
  data: Array<User_Personal_Access_Tokens_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Personal_Access_Tokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_personal_access_tokens". All fields are combined with a logical 'AND'. */
export type User_Personal_Access_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<User_Personal_Access_Tokens_Bool_Exp>>;
  _not?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<User_Personal_Access_Tokens_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  hashed_token?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  last_used?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_used_ip_address?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_personal_access_tokens" */
export enum User_Personal_Access_Tokens_Constraint {
  /** unique or primary key constraint on columns "user_id", "hashed_token" */
  UserPersonalAccessTokenHashedTokenUserIdKey = 'user_personal_access_token_hashed_token_user_id_key',
  /** unique or primary key constraint on columns "id" */
  UserPersonalAccessTokensPkey = 'user_personal_access_tokens_pkey',
}

/** input type for inserting data into table "user_personal_access_tokens" */
export type User_Personal_Access_Tokens_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  hashed_token?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_used?: InputMaybe<Scalars['timestamptz']>;
  last_used_ip_address?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Personal_Access_Tokens_Max_Fields = {
  __typename?: 'user_personal_access_tokens_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  hashed_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_used?: Maybe<Scalars['timestamptz']>;
  last_used_ip_address?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_personal_access_tokens" */
export type User_Personal_Access_Tokens_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  hashed_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_used?: InputMaybe<Order_By>;
  last_used_ip_address?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Personal_Access_Tokens_Min_Fields = {
  __typename?: 'user_personal_access_tokens_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  hashed_token?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_used?: Maybe<Scalars['timestamptz']>;
  last_used_ip_address?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_personal_access_tokens" */
export type User_Personal_Access_Tokens_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  hashed_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_used?: InputMaybe<Order_By>;
  last_used_ip_address?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_personal_access_tokens" */
export type User_Personal_Access_Tokens_Mutation_Response = {
  __typename?: 'user_personal_access_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Personal_Access_Tokens>;
};

/** on_conflict condition type for table "user_personal_access_tokens" */
export type User_Personal_Access_Tokens_On_Conflict = {
  constraint: User_Personal_Access_Tokens_Constraint;
  update_columns?: Array<User_Personal_Access_Tokens_Update_Column>;
  where?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "user_personal_access_tokens". */
export type User_Personal_Access_Tokens_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  hashed_token?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  last_used?: InputMaybe<Order_By>;
  last_used_ip_address?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_personal_access_tokens */
export type User_Personal_Access_Tokens_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_personal_access_tokens" */
export enum User_Personal_Access_Tokens_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  HashedToken = 'hashed_token',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastUsed = 'last_used',
  /** column name */
  LastUsedIpAddress = 'last_used_ip_address',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** select "user_personal_access_tokens_aggregate_bool_exp_bool_and_arguments_columns" columns of table "user_personal_access_tokens" */
export enum User_Personal_Access_Tokens_Select_Column_User_Personal_Access_Tokens_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** select "user_personal_access_tokens_aggregate_bool_exp_bool_or_arguments_columns" columns of table "user_personal_access_tokens" */
export enum User_Personal_Access_Tokens_Select_Column_User_Personal_Access_Tokens_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active',
}

/** input type for updating data in table "user_personal_access_tokens" */
export type User_Personal_Access_Tokens_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  hashed_token?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_used?: InputMaybe<Scalars['timestamptz']>;
  last_used_ip_address?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_personal_access_tokens" */
export type User_Personal_Access_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Personal_Access_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Personal_Access_Tokens_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  hashed_token?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  last_used?: InputMaybe<Scalars['timestamptz']>;
  last_used_ip_address?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_personal_access_tokens" */
export enum User_Personal_Access_Tokens_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  HashedToken = 'hashed_token',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  LastUsed = 'last_used',
  /** column name */
  LastUsedIpAddress = 'last_used_ip_address',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Personal_Access_Tokens_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Personal_Access_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Personal_Access_Tokens_Bool_Exp;
};

/** Contains optional info a user can add when they add a payment method. This info is for use by sales and support and not for any technical purpose. */
export type User_Profile = {
  __typename?: 'user_profile';
  /** Self-reported company the user represents. */
  company?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** User email. Intended to be a company email. */
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** Preferred name to address user */
  name?: Maybe<Scalars['String']>;
  /** Phone number on file */
  phone_number?: Maybe<Scalars['String']>;
  /** User job title / position at their company. Self reported. */
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  /** The user to whom this profile info belongs */
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_profile" */
export type User_Profile_Aggregate = {
  __typename?: 'user_profile_aggregate';
  aggregate?: Maybe<User_Profile_Aggregate_Fields>;
  nodes: Array<User_Profile>;
};

/** aggregate fields of "user_profile" */
export type User_Profile_Aggregate_Fields = {
  __typename?: 'user_profile_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Profile_Max_Fields>;
  min?: Maybe<User_Profile_Min_Fields>;
};

/** aggregate fields of "user_profile" */
export type User_Profile_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Profile_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_profile". All fields are combined with a logical 'AND'. */
export type User_Profile_Bool_Exp = {
  _and?: InputMaybe<Array<User_Profile_Bool_Exp>>;
  _not?: InputMaybe<User_Profile_Bool_Exp>;
  _or?: InputMaybe<Array<User_Profile_Bool_Exp>>;
  company?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_profile" */
export enum User_Profile_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserProfilePkey = 'user_profile_pkey',
  /** unique or primary key constraint on columns "user_id" */
  UserProfileUserIdKey = 'user_profile_user_id_key',
}

/** input type for inserting data into table "user_profile" */
export type User_Profile_Insert_Input = {
  /** Self-reported company the user represents. */
  company?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** User email. Intended to be a company email. */
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** Preferred name to address user */
  name?: InputMaybe<Scalars['String']>;
  /** Phone number on file */
  phone_number?: InputMaybe<Scalars['String']>;
  /** User job title / position at their company. Self reported. */
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  /** The user to whom this profile info belongs */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Profile_Max_Fields = {
  __typename?: 'user_profile_max_fields';
  /** Self-reported company the user represents. */
  company?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** User email. Intended to be a company email. */
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** Preferred name to address user */
  name?: Maybe<Scalars['String']>;
  /** Phone number on file */
  phone_number?: Maybe<Scalars['String']>;
  /** User job title / position at their company. Self reported. */
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** The user to whom this profile info belongs */
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Profile_Min_Fields = {
  __typename?: 'user_profile_min_fields';
  /** Self-reported company the user represents. */
  company?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** User email. Intended to be a company email. */
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  /** Preferred name to address user */
  name?: Maybe<Scalars['String']>;
  /** Phone number on file */
  phone_number?: Maybe<Scalars['String']>;
  /** User job title / position at their company. Self reported. */
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** The user to whom this profile info belongs */
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_profile" */
export type User_Profile_Mutation_Response = {
  __typename?: 'user_profile_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Profile>;
};

/** input type for inserting object relation for remote table "user_profile" */
export type User_Profile_Obj_Rel_Insert_Input = {
  data: User_Profile_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Profile_On_Conflict>;
};

/** on_conflict condition type for table "user_profile" */
export type User_Profile_On_Conflict = {
  constraint: User_Profile_Constraint;
  update_columns?: Array<User_Profile_Update_Column>;
  where?: InputMaybe<User_Profile_Bool_Exp>;
};

/** Ordering options when selecting data from "user_profile". */
export type User_Profile_Order_By = {
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_profile */
export type User_Profile_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_profile" */
export enum User_Profile_Select_Column {
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_profile" */
export type User_Profile_Set_Input = {
  /** Self-reported company the user represents. */
  company?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** User email. Intended to be a company email. */
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** Preferred name to address user */
  name?: InputMaybe<Scalars['String']>;
  /** Phone number on file */
  phone_number?: InputMaybe<Scalars['String']>;
  /** User job title / position at their company. Self reported. */
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** The user to whom this profile info belongs */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_profile" */
export type User_Profile_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Profile_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Profile_Stream_Cursor_Value_Input = {
  /** Self-reported company the user represents. */
  company?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  /** User email. Intended to be a company email. */
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  /** Preferred name to address user */
  name?: InputMaybe<Scalars['String']>;
  /** Phone number on file */
  phone_number?: InputMaybe<Scalars['String']>;
  /** User job title / position at their company. Self reported. */
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  /** The user to whom this profile info belongs */
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_profile" */
export enum User_Profile_Update_Column {
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

export type User_Profile_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Profile_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Profile_Bool_Exp;
};

/** This view is created to query all the projects a user is associated to. */
export type User_Project_Map = {
  __typename?: 'user_project_map';
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "user_project_map" */
export type User_Project_Map_Aggregate = {
  __typename?: 'user_project_map_aggregate';
  aggregate?: Maybe<User_Project_Map_Aggregate_Fields>;
  nodes: Array<User_Project_Map>;
};

/** aggregate fields of "user_project_map" */
export type User_Project_Map_Aggregate_Fields = {
  __typename?: 'user_project_map_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Project_Map_Max_Fields>;
  min?: Maybe<User_Project_Map_Min_Fields>;
};

/** aggregate fields of "user_project_map" */
export type User_Project_Map_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Project_Map_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_project_map". All fields are combined with a logical 'AND'. */
export type User_Project_Map_Bool_Exp = {
  _and?: InputMaybe<Array<User_Project_Map_Bool_Exp>>;
  _not?: InputMaybe<User_Project_Map_Bool_Exp>;
  _or?: InputMaybe<Array<User_Project_Map_Bool_Exp>>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type User_Project_Map_Max_Fields = {
  __typename?: 'user_project_map_max_fields';
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Project_Map_Min_Fields = {
  __typename?: 'user_project_map_min_fields';
  project_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** Ordering options when selecting data from "user_project_map". */
export type User_Project_Map_Order_By = {
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "user_project_map" */
export enum User_Project_Map_Select_Column {
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "user_project_map" */
export type User_Project_Map_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Project_Map_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Project_Map_Stream_Cursor_Value_Input = {
  project_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Users roles */
export type User_Roles = {
  __typename?: 'user_roles';
  comment?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  created_by: Scalars['String'];
  id: Scalars['uuid'];
  role: Scalars['String'];
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_roles" */
export type User_Roles_Aggregate = {
  __typename?: 'user_roles_aggregate';
  aggregate?: Maybe<User_Roles_Aggregate_Fields>;
  nodes: Array<User_Roles>;
};

export type User_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Roles_Aggregate_Bool_Exp_Count>;
};

export type User_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<User_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_Fields = {
  __typename?: 'user_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Roles_Max_Fields>;
  min?: Maybe<User_Roles_Min_Fields>;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_roles" */
export type User_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Roles_Max_Order_By>;
  min?: InputMaybe<User_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_roles" */
export type User_Roles_Arr_Rel_Insert_Input = {
  data: Array<User_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  _not?: InputMaybe<User_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_roles" */
export enum User_Roles_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRolesPkey = 'user_roles_pkey',
  /** unique or primary key constraint on columns "user_id", "role" */
  UserRolesUserIdRoleKey = 'user_roles_user_id_role_key',
}

/** input type for inserting data into table "user_roles" */
export type User_Roles_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Roles_Max_Fields = {
  __typename?: 'user_roles_max_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_roles" */
export type User_Roles_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Roles_Min_Fields = {
  __typename?: 'user_roles_min_fields';
  comment?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_roles" */
export type User_Roles_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_roles" */
export type User_Roles_Mutation_Response = {
  __typename?: 'user_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Roles>;
};

/** on_conflict condition type for table "user_roles" */
export type User_Roles_On_Conflict = {
  constraint: User_Roles_Constraint;
  update_columns?: Array<User_Roles_Update_Column>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "user_roles". */
export type User_Roles_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_roles */
export type User_Roles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_roles" */
export type User_Roles_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_roles" */
export type User_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Roles_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_roles" */
export enum User_Roles_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role',
  /** column name */
  UserId = 'user_id',
}

export type User_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Roles_Bool_Exp;
};

/** Special VPC policies for specific users */
export type User_Vpc_Policy = {
  __typename?: 'user_vpc_policy';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  plan_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user: Scalars['uuid'];
  /** An object relationship */
  user_info: Users;
  vpc_limit: Scalars['Int'];
};

/** aggregated selection of "user_vpc_policy" */
export type User_Vpc_Policy_Aggregate = {
  __typename?: 'user_vpc_policy_aggregate';
  aggregate?: Maybe<User_Vpc_Policy_Aggregate_Fields>;
  nodes: Array<User_Vpc_Policy>;
};

/** aggregate fields of "user_vpc_policy" */
export type User_Vpc_Policy_Aggregate_Fields = {
  __typename?: 'user_vpc_policy_aggregate_fields';
  avg?: Maybe<User_Vpc_Policy_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<User_Vpc_Policy_Max_Fields>;
  min?: Maybe<User_Vpc_Policy_Min_Fields>;
  stddev?: Maybe<User_Vpc_Policy_Stddev_Fields>;
  stddev_pop?: Maybe<User_Vpc_Policy_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Vpc_Policy_Stddev_Samp_Fields>;
  sum?: Maybe<User_Vpc_Policy_Sum_Fields>;
  var_pop?: Maybe<User_Vpc_Policy_Var_Pop_Fields>;
  var_samp?: Maybe<User_Vpc_Policy_Var_Samp_Fields>;
  variance?: Maybe<User_Vpc_Policy_Variance_Fields>;
};

/** aggregate fields of "user_vpc_policy" */
export type User_Vpc_Policy_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Vpc_Policy_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type User_Vpc_Policy_Avg_Fields = {
  __typename?: 'user_vpc_policy_avg_fields';
  id?: Maybe<Scalars['Float']>;
  vpc_limit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "user_vpc_policy". All fields are combined with a logical 'AND'. */
export type User_Vpc_Policy_Bool_Exp = {
  _and?: InputMaybe<Array<User_Vpc_Policy_Bool_Exp>>;
  _not?: InputMaybe<User_Vpc_Policy_Bool_Exp>;
  _or?: InputMaybe<Array<User_Vpc_Policy_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  plan_name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Uuid_Comparison_Exp>;
  user_info?: InputMaybe<Users_Bool_Exp>;
  vpc_limit?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_vpc_policy" */
export enum User_Vpc_Policy_Constraint {
  /** unique or primary key constraint on columns "plan_name", "user" */
  UserVpcPlanLimitUnique = 'user_vpc_plan_limit_unique',
  /** unique or primary key constraint on columns "id" */
  UserVpcPolicyPkey = 'user_vpc_policy_pkey',
}

/** input type for incrementing numeric columns in table "user_vpc_policy" */
export type User_Vpc_Policy_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  vpc_limit?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "user_vpc_policy" */
export type User_Vpc_Policy_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  plan_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Scalars['uuid']>;
  user_info?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  vpc_limit?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type User_Vpc_Policy_Max_Fields = {
  __typename?: 'user_vpc_policy_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  plan_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Scalars['uuid']>;
  vpc_limit?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type User_Vpc_Policy_Min_Fields = {
  __typename?: 'user_vpc_policy_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  plan_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Scalars['uuid']>;
  vpc_limit?: Maybe<Scalars['Int']>;
};

/** response of any mutation on the table "user_vpc_policy" */
export type User_Vpc_Policy_Mutation_Response = {
  __typename?: 'user_vpc_policy_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Vpc_Policy>;
};

/** on_conflict condition type for table "user_vpc_policy" */
export type User_Vpc_Policy_On_Conflict = {
  constraint: User_Vpc_Policy_Constraint;
  update_columns?: Array<User_Vpc_Policy_Update_Column>;
  where?: InputMaybe<User_Vpc_Policy_Bool_Exp>;
};

/** Ordering options when selecting data from "user_vpc_policy". */
export type User_Vpc_Policy_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  plan_name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Order_By>;
  user_info?: InputMaybe<Users_Order_By>;
  vpc_limit?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_vpc_policy */
export type User_Vpc_Policy_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "user_vpc_policy" */
export enum User_Vpc_Policy_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  User = 'user',
  /** column name */
  VpcLimit = 'vpc_limit',
}

/** input type for updating data in table "user_vpc_policy" */
export type User_Vpc_Policy_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  plan_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Scalars['uuid']>;
  vpc_limit?: InputMaybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type User_Vpc_Policy_Stddev_Fields = {
  __typename?: 'user_vpc_policy_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  vpc_limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type User_Vpc_Policy_Stddev_Pop_Fields = {
  __typename?: 'user_vpc_policy_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  vpc_limit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type User_Vpc_Policy_Stddev_Samp_Fields = {
  __typename?: 'user_vpc_policy_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  vpc_limit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "user_vpc_policy" */
export type User_Vpc_Policy_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Vpc_Policy_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Vpc_Policy_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  plan_name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Scalars['uuid']>;
  vpc_limit?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type User_Vpc_Policy_Sum_Fields = {
  __typename?: 'user_vpc_policy_sum_fields';
  id?: Maybe<Scalars['Int']>;
  vpc_limit?: Maybe<Scalars['Int']>;
};

/** update columns of table "user_vpc_policy" */
export enum User_Vpc_Policy_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PlanName = 'plan_name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  User = 'user',
  /** column name */
  VpcLimit = 'vpc_limit',
}

export type User_Vpc_Policy_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Vpc_Policy_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Vpc_Policy_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Vpc_Policy_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Vpc_Policy_Var_Pop_Fields = {
  __typename?: 'user_vpc_policy_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  vpc_limit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type User_Vpc_Policy_Var_Samp_Fields = {
  __typename?: 'user_vpc_policy_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  vpc_limit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type User_Vpc_Policy_Variance_Fields = {
  __typename?: 'user_vpc_policy_variance_fields';
  id?: Maybe<Scalars['Float']>;
  vpc_limit?: Maybe<Scalars['Float']>;
};

/** Captures users on Hasura Cloud */
export type Users = {
  __typename?: 'users';
  attempt_count?: Maybe<Scalars['Int']>;
  /** An object relationship */
  billing_address?: Maybe<Billing_Address>;
  /** An array relationship */
  billing_projects: Array<Projects>;
  /** An aggregate relationship */
  billing_projects_aggregate: Projects_Aggregate;
  /** An array relationship */
  collaborated_projects: Array<Project_Collaborators>;
  /** An aggregate relationship */
  collaborated_projects_aggregate: Project_Collaborators_Aggregate;
  confim_verifier?: Maybe<Scalars['String']>;
  confirm_mail_count?: Maybe<Scalars['Int']>;
  confirm_selector?: Maybe<Scalars['String']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  created_at: Scalars['timestamptz'];
  customer_id?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  /** An object relationship */
  enterprise_users?: Maybe<Enterprise_Users>;
  /** An array relationship */
  entitlements: Array<User_Entitlement_Access>;
  /** An aggregate relationship */
  entitlements_aggregate: User_Entitlement_Access_Aggregate;
  /** An array relationship */
  feature_accesses: Array<Feature_Access>;
  /** An aggregate relationship */
  feature_accesses_aggregate: Feature_Access_Aggregate;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  invoice_receipt_email?: Maybe<Scalars['String']>;
  /** An array relationship */
  invoices: Array<Invoice>;
  /** An aggregate relationship */
  invoices_aggregate: Invoice_Aggregate;
  is_saml_user?: Maybe<Scalars['Boolean']>;
  last_attempt?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['timestamptz']>;
  max_project_limit: Scalars['Int'];
  /** An array relationship */
  onboarding: Array<User_Onboarding>;
  /** An aggregate relationship */
  onboarding_aggregate: User_Onboarding_Aggregate;
  organization?: Maybe<Scalars['String']>;
  password: Scalars['String'];
  /** An array relationship */
  payment_methods: Array<Payment_Method>;
  /** An aggregate relationship */
  payment_methods_aggregate: Payment_Method_Aggregate;
  /** An array relationship */
  personal_access_tokens: Array<User_Personal_Access_Tokens>;
  /** An aggregate relationship */
  personal_access_tokens_aggregate: User_Personal_Access_Tokens_Aggregate;
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  /** An array relationship */
  providers: Array<Providers>;
  /** An aggregate relationship */
  providers_aggregate: Providers_Aggregate;
  recover_selector?: Maybe<Scalars['String']>;
  recover_token_expiry?: Maybe<Scalars['timestamptz']>;
  recover_verifier?: Maybe<Scalars['String']>;
  recovery_codes?: Maybe<Scalars['String']>;
  /** An array relationship */
  roles: Array<User_Roles>;
  /** An aggregate relationship */
  roles_aggregate: User_Roles_Aggregate;
  totp_secret_key?: Maybe<Scalars['String']>;
  /** A computed field, executes function "is_trial_user" */
  trial_user?: Maybe<Scalars['Boolean']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_coupon_usages: Array<User_Coupon>;
  /** An aggregate relationship */
  user_coupon_usages_aggregate: User_Coupon_Aggregate;
  /** An array relationship */
  user_credit_coupon_usages: Array<Customer_Usage>;
  /** An aggregate relationship */
  user_credit_coupon_usages_aggregate: Customer_Usage_Aggregate;
  /** An object relationship */
  user_profile?: Maybe<User_Profile>;
  zendesk_user_id?: Maybe<Scalars['bigint']>;
};

/** Captures users on Hasura Cloud */
export type UsersBilling_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersBilling_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersCollaborated_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborators_Order_By>>;
  where?: InputMaybe<Project_Collaborators_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersCollaborated_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborators_Order_By>>;
  where?: InputMaybe<Project_Collaborators_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersEntitlementsArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Access_Order_By>>;
  where?: InputMaybe<User_Entitlement_Access_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersEntitlements_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Entitlement_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Entitlement_Access_Order_By>>;
  where?: InputMaybe<User_Entitlement_Access_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersFeature_AccessesArgs = {
  distinct_on?: InputMaybe<Array<Feature_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Access_Order_By>>;
  where?: InputMaybe<Feature_Access_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersFeature_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Feature_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Feature_Access_Order_By>>;
  where?: InputMaybe<Feature_Access_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersInvoicesArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersInvoices_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Invoice_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Invoice_Order_By>>;
  where?: InputMaybe<Invoice_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersOnboardingArgs = {
  distinct_on?: InputMaybe<Array<User_Onboarding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Onboarding_Order_By>>;
  where?: InputMaybe<User_Onboarding_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersOnboarding_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Onboarding_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Onboarding_Order_By>>;
  where?: InputMaybe<User_Onboarding_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersPayment_MethodsArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersPayment_Methods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Payment_Method_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Payment_Method_Order_By>>;
  where?: InputMaybe<Payment_Method_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersPersonal_Access_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Personal_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Personal_Access_Tokens_Order_By>>;
  where?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersPersonal_Access_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Personal_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Personal_Access_Tokens_Order_By>>;
  where?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersProvidersArgs = {
  distinct_on?: InputMaybe<Array<Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Providers_Order_By>>;
  where?: InputMaybe<Providers_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersProviders_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Providers_Order_By>>;
  where?: InputMaybe<Providers_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersRolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersUser_Coupon_UsagesArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersUser_Coupon_Usages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Coupon_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Coupon_Order_By>>;
  where?: InputMaybe<User_Coupon_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersUser_Credit_Coupon_UsagesArgs = {
  distinct_on?: InputMaybe<Array<Customer_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Usage_Order_By>>;
  where?: InputMaybe<Customer_Usage_Bool_Exp>;
};

/** Captures users on Hasura Cloud */
export type UsersUser_Credit_Coupon_Usages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Customer_Usage_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Customer_Usage_Order_By>>;
  where?: InputMaybe<Customer_Usage_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

export type Users_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Users_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Users_Aggregate_Bool_Exp_Count>;
};

export type Users_Aggregate_Bool_Exp_Bool_And = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  avg?: InputMaybe<Users_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
  stddev?: InputMaybe<Users_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Users_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Users_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Users_Sum_Order_By>;
  var_pop?: InputMaybe<Users_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Users_Var_Samp_Order_By>;
  variance?: InputMaybe<Users_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  confirm_mail_count?: Maybe<Scalars['Float']>;
  max_project_limit?: Maybe<Scalars['Float']>;
  zendesk_user_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "users" */
export type Users_Avg_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  confirm_mail_count?: InputMaybe<Order_By>;
  max_project_limit?: InputMaybe<Order_By>;
  zendesk_user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  attempt_count?: InputMaybe<Int_Comparison_Exp>;
  billing_address?: InputMaybe<Billing_Address_Bool_Exp>;
  billing_projects?: InputMaybe<Projects_Bool_Exp>;
  billing_projects_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  collaborated_projects?: InputMaybe<Project_Collaborators_Bool_Exp>;
  collaborated_projects_aggregate?: InputMaybe<
    Project_Collaborators_Aggregate_Bool_Exp
  >;
  confim_verifier?: InputMaybe<String_Comparison_Exp>;
  confirm_mail_count?: InputMaybe<Int_Comparison_Exp>;
  confirm_selector?: InputMaybe<String_Comparison_Exp>;
  confirmed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  customer_id?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  enterprise_users?: InputMaybe<Enterprise_Users_Bool_Exp>;
  entitlements?: InputMaybe<User_Entitlement_Access_Bool_Exp>;
  entitlements_aggregate?: InputMaybe<
    User_Entitlement_Access_Aggregate_Bool_Exp
  >;
  feature_accesses?: InputMaybe<Feature_Access_Bool_Exp>;
  feature_accesses_aggregate?: InputMaybe<Feature_Access_Aggregate_Bool_Exp>;
  first_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invoice_receipt_email?: InputMaybe<String_Comparison_Exp>;
  invoices?: InputMaybe<Invoice_Bool_Exp>;
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Bool_Exp>;
  is_saml_user?: InputMaybe<Boolean_Comparison_Exp>;
  last_attempt?: InputMaybe<Timestamptz_Comparison_Exp>;
  last_name?: InputMaybe<String_Comparison_Exp>;
  locked?: InputMaybe<Timestamptz_Comparison_Exp>;
  max_project_limit?: InputMaybe<Int_Comparison_Exp>;
  onboarding?: InputMaybe<User_Onboarding_Bool_Exp>;
  onboarding_aggregate?: InputMaybe<User_Onboarding_Aggregate_Bool_Exp>;
  organization?: InputMaybe<String_Comparison_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  payment_methods?: InputMaybe<Payment_Method_Bool_Exp>;
  payment_methods_aggregate?: InputMaybe<Payment_Method_Aggregate_Bool_Exp>;
  personal_access_tokens?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
  personal_access_tokens_aggregate?: InputMaybe<
    User_Personal_Access_Tokens_Aggregate_Bool_Exp
  >;
  projects?: InputMaybe<Projects_Bool_Exp>;
  projects_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  providers?: InputMaybe<Providers_Bool_Exp>;
  providers_aggregate?: InputMaybe<Providers_Aggregate_Bool_Exp>;
  recover_selector?: InputMaybe<String_Comparison_Exp>;
  recover_token_expiry?: InputMaybe<Timestamptz_Comparison_Exp>;
  recover_verifier?: InputMaybe<String_Comparison_Exp>;
  recovery_codes?: InputMaybe<String_Comparison_Exp>;
  roles?: InputMaybe<User_Roles_Bool_Exp>;
  roles_aggregate?: InputMaybe<User_Roles_Aggregate_Bool_Exp>;
  totp_secret_key?: InputMaybe<String_Comparison_Exp>;
  trial_user?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_coupon_usages?: InputMaybe<User_Coupon_Bool_Exp>;
  user_coupon_usages_aggregate?: InputMaybe<User_Coupon_Aggregate_Bool_Exp>;
  user_credit_coupon_usages?: InputMaybe<Customer_Usage_Bool_Exp>;
  user_credit_coupon_usages_aggregate?: InputMaybe<
    Customer_Usage_Aggregate_Bool_Exp
  >;
  user_profile?: InputMaybe<User_Profile_Bool_Exp>;
  zendesk_user_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "customer_id" */
  UsersCustomerIdKey = 'users_customer_id_key',
  /** unique or primary key constraint on columns "email" */
  UsersEmailKey = 'users_email_key',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  attempt_count?: InputMaybe<Scalars['Int']>;
  confirm_mail_count?: InputMaybe<Scalars['Int']>;
  max_project_limit?: InputMaybe<Scalars['Int']>;
  zendesk_user_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  attempt_count?: InputMaybe<Scalars['Int']>;
  billing_address?: InputMaybe<Billing_Address_Obj_Rel_Insert_Input>;
  billing_projects?: InputMaybe<Projects_Arr_Rel_Insert_Input>;
  collaborated_projects?: InputMaybe<
    Project_Collaborators_Arr_Rel_Insert_Input
  >;
  confim_verifier?: InputMaybe<Scalars['String']>;
  confirm_mail_count?: InputMaybe<Scalars['Int']>;
  confirm_selector?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  enterprise_users?: InputMaybe<Enterprise_Users_Obj_Rel_Insert_Input>;
  entitlements?: InputMaybe<User_Entitlement_Access_Arr_Rel_Insert_Input>;
  feature_accesses?: InputMaybe<Feature_Access_Arr_Rel_Insert_Input>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_receipt_email?: InputMaybe<Scalars['String']>;
  invoices?: InputMaybe<Invoice_Arr_Rel_Insert_Input>;
  is_saml_user?: InputMaybe<Scalars['Boolean']>;
  last_attempt?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['timestamptz']>;
  max_project_limit?: InputMaybe<Scalars['Int']>;
  onboarding?: InputMaybe<User_Onboarding_Arr_Rel_Insert_Input>;
  organization?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  payment_methods?: InputMaybe<Payment_Method_Arr_Rel_Insert_Input>;
  personal_access_tokens?: InputMaybe<
    User_Personal_Access_Tokens_Arr_Rel_Insert_Input
  >;
  projects?: InputMaybe<Projects_Arr_Rel_Insert_Input>;
  providers?: InputMaybe<Providers_Arr_Rel_Insert_Input>;
  recover_selector?: InputMaybe<Scalars['String']>;
  recover_token_expiry?: InputMaybe<Scalars['timestamptz']>;
  recover_verifier?: InputMaybe<Scalars['String']>;
  recovery_codes?: InputMaybe<Scalars['String']>;
  roles?: InputMaybe<User_Roles_Arr_Rel_Insert_Input>;
  totp_secret_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_coupon_usages?: InputMaybe<User_Coupon_Arr_Rel_Insert_Input>;
  user_credit_coupon_usages?: InputMaybe<Customer_Usage_Arr_Rel_Insert_Input>;
  user_profile?: InputMaybe<User_Profile_Obj_Rel_Insert_Input>;
  zendesk_user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  attempt_count?: Maybe<Scalars['Int']>;
  confim_verifier?: Maybe<Scalars['String']>;
  confirm_mail_count?: Maybe<Scalars['Int']>;
  confirm_selector?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_receipt_email?: Maybe<Scalars['String']>;
  last_attempt?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['timestamptz']>;
  max_project_limit?: Maybe<Scalars['Int']>;
  organization?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  recover_selector?: Maybe<Scalars['String']>;
  recover_token_expiry?: Maybe<Scalars['timestamptz']>;
  recover_verifier?: Maybe<Scalars['String']>;
  recovery_codes?: Maybe<Scalars['String']>;
  totp_secret_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zendesk_user_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  confim_verifier?: InputMaybe<Order_By>;
  confirm_mail_count?: InputMaybe<Order_By>;
  confirm_selector?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_receipt_email?: InputMaybe<Order_By>;
  last_attempt?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  locked?: InputMaybe<Order_By>;
  max_project_limit?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  recover_selector?: InputMaybe<Order_By>;
  recover_token_expiry?: InputMaybe<Order_By>;
  recover_verifier?: InputMaybe<Order_By>;
  recovery_codes?: InputMaybe<Order_By>;
  totp_secret_key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zendesk_user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  attempt_count?: Maybe<Scalars['Int']>;
  confim_verifier?: Maybe<Scalars['String']>;
  confirm_mail_count?: Maybe<Scalars['Int']>;
  confirm_selector?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  customer_id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  invoice_receipt_email?: Maybe<Scalars['String']>;
  last_attempt?: Maybe<Scalars['timestamptz']>;
  last_name?: Maybe<Scalars['String']>;
  locked?: Maybe<Scalars['timestamptz']>;
  max_project_limit?: Maybe<Scalars['Int']>;
  organization?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  recover_selector?: Maybe<Scalars['String']>;
  recover_token_expiry?: Maybe<Scalars['timestamptz']>;
  recover_verifier?: Maybe<Scalars['String']>;
  recovery_codes?: Maybe<Scalars['String']>;
  totp_secret_key?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  zendesk_user_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  confim_verifier?: InputMaybe<Order_By>;
  confirm_mail_count?: InputMaybe<Order_By>;
  confirm_selector?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_receipt_email?: InputMaybe<Order_By>;
  last_attempt?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  locked?: InputMaybe<Order_By>;
  max_project_limit?: InputMaybe<Order_By>;
  organization?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  recover_selector?: InputMaybe<Order_By>;
  recover_token_expiry?: InputMaybe<Order_By>;
  recover_verifier?: InputMaybe<Order_By>;
  recovery_codes?: InputMaybe<Order_By>;
  totp_secret_key?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  zendesk_user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  billing_address?: InputMaybe<Billing_Address_Order_By>;
  billing_projects_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  collaborated_projects_aggregate?: InputMaybe<
    Project_Collaborators_Aggregate_Order_By
  >;
  confim_verifier?: InputMaybe<Order_By>;
  confirm_mail_count?: InputMaybe<Order_By>;
  confirm_selector?: InputMaybe<Order_By>;
  confirmed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  customer_id?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  enterprise_users?: InputMaybe<Enterprise_Users_Order_By>;
  entitlements_aggregate?: InputMaybe<
    User_Entitlement_Access_Aggregate_Order_By
  >;
  feature_accesses_aggregate?: InputMaybe<Feature_Access_Aggregate_Order_By>;
  first_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invoice_receipt_email?: InputMaybe<Order_By>;
  invoices_aggregate?: InputMaybe<Invoice_Aggregate_Order_By>;
  is_saml_user?: InputMaybe<Order_By>;
  last_attempt?: InputMaybe<Order_By>;
  last_name?: InputMaybe<Order_By>;
  locked?: InputMaybe<Order_By>;
  max_project_limit?: InputMaybe<Order_By>;
  onboarding_aggregate?: InputMaybe<User_Onboarding_Aggregate_Order_By>;
  organization?: InputMaybe<Order_By>;
  password?: InputMaybe<Order_By>;
  payment_methods_aggregate?: InputMaybe<Payment_Method_Aggregate_Order_By>;
  personal_access_tokens_aggregate?: InputMaybe<
    User_Personal_Access_Tokens_Aggregate_Order_By
  >;
  projects_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  providers_aggregate?: InputMaybe<Providers_Aggregate_Order_By>;
  recover_selector?: InputMaybe<Order_By>;
  recover_token_expiry?: InputMaybe<Order_By>;
  recover_verifier?: InputMaybe<Order_By>;
  recovery_codes?: InputMaybe<Order_By>;
  roles_aggregate?: InputMaybe<User_Roles_Aggregate_Order_By>;
  totp_secret_key?: InputMaybe<Order_By>;
  trial_user?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_coupon_usages_aggregate?: InputMaybe<User_Coupon_Aggregate_Order_By>;
  user_credit_coupon_usages_aggregate?: InputMaybe<
    Customer_Usage_Aggregate_Order_By
  >;
  user_profile?: InputMaybe<User_Profile_Order_By>;
  zendesk_user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** View to capture public user fields */
export type Users_Public = {
  __typename?: 'users_public';
  /** An array relationship */
  billing_projects: Array<Projects>;
  /** An aggregate relationship */
  billing_projects_aggregate: Projects_Aggregate;
  /** An array relationship */
  collaborated_projects: Array<Project_Collaborators>;
  /** An aggregate relationship */
  collaborated_projects_aggregate: Project_Collaborators_Aggregate;
  /** An array relationship */
  collaborated_vpcs: Array<Vpc_Collaborators>;
  /** An aggregate relationship */
  collaborated_vpcs_aggregate: Vpc_Collaborators_Aggregate;
  /** An array relationship */
  ddn_projects: Array<Ddn_Projects>;
  /** An aggregate relationship */
  ddn_projects_aggregate: Ddn_Projects_Aggregate;
  /** An array relationship */
  ddn_team_memberships: Array<Ddn_Team_Membership>;
  /** An aggregate relationship */
  ddn_team_memberships_aggregate: Ddn_Team_Membership_Aggregate;
  /** An array relationship */
  ddn_user_project_accesses: Array<Ddn_User_Project_Access>;
  /** An aggregate relationship */
  ddn_user_project_accesses_aggregate: Ddn_User_Project_Access_Aggregate;
  email?: Maybe<Scalars['String']>;
  /** An object relationship */
  enterprise_user?: Maybe<Enterprise_Users>;
  id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  personal_access_tokens: Array<User_Personal_Access_Tokens>;
  /** An aggregate relationship */
  personal_access_tokens_aggregate: User_Personal_Access_Tokens_Aggregate;
  /** An object relationship */
  project_request_access?: Maybe<Ddn_Project_Request_Access>;
  /** An array relationship */
  projects: Array<Projects>;
  /** An aggregate relationship */
  projects_aggregate: Projects_Aggregate;
  /** An array relationship */
  sent_ddn_team_invitations: Array<Ddn_Team_Invitation>;
  /** An aggregate relationship */
  sent_ddn_team_invitations_aggregate: Ddn_Team_Invitation_Aggregate;
};

/** View to capture public user fields */
export type Users_PublicBilling_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicBilling_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicCollaborated_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborators_Order_By>>;
  where?: InputMaybe<Project_Collaborators_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicCollaborated_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Project_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Project_Collaborators_Order_By>>;
  where?: InputMaybe<Project_Collaborators_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicCollaborated_VpcsArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborators_Order_By>>;
  where?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicCollaborated_Vpcs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vpc_Collaborators_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vpc_Collaborators_Order_By>>;
  where?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicDdn_ProjectsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Projects_Order_By>>;
  where?: InputMaybe<Ddn_Projects_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicDdn_Projects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Projects_Order_By>>;
  where?: InputMaybe<Ddn_Projects_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicDdn_Team_MembershipsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Membership_Order_By>>;
  where?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicDdn_Team_Memberships_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Membership_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Membership_Order_By>>;
  where?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicDdn_User_Project_AccessesArgs = {
  distinct_on?: InputMaybe<Array<Ddn_User_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_User_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicDdn_User_Project_Accesses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_User_Project_Access_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_User_Project_Access_Order_By>>;
  where?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicPersonal_Access_TokensArgs = {
  distinct_on?: InputMaybe<Array<User_Personal_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Personal_Access_Tokens_Order_By>>;
  where?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicPersonal_Access_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Personal_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Personal_Access_Tokens_Order_By>>;
  where?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicProjectsArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicProjects_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Projects_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Projects_Order_By>>;
  where?: InputMaybe<Projects_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicSent_Ddn_Team_InvitationsArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Invitation_Order_By>>;
  where?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
};

/** View to capture public user fields */
export type Users_PublicSent_Ddn_Team_Invitations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Ddn_Team_Invitation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Ddn_Team_Invitation_Order_By>>;
  where?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
};

/** aggregated selection of "users_public" */
export type Users_Public_Aggregate = {
  __typename?: 'users_public_aggregate';
  aggregate?: Maybe<Users_Public_Aggregate_Fields>;
  nodes: Array<Users_Public>;
};

/** aggregate fields of "users_public" */
export type Users_Public_Aggregate_Fields = {
  __typename?: 'users_public_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Public_Max_Fields>;
  min?: Maybe<Users_Public_Min_Fields>;
};

/** aggregate fields of "users_public" */
export type Users_Public_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Public_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users_public". All fields are combined with a logical 'AND'. */
export type Users_Public_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Public_Bool_Exp>>;
  _not?: InputMaybe<Users_Public_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Public_Bool_Exp>>;
  billing_projects?: InputMaybe<Projects_Bool_Exp>;
  billing_projects_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  collaborated_projects?: InputMaybe<Project_Collaborators_Bool_Exp>;
  collaborated_projects_aggregate?: InputMaybe<
    Project_Collaborators_Aggregate_Bool_Exp
  >;
  collaborated_vpcs?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
  collaborated_vpcs_aggregate?: InputMaybe<
    Vpc_Collaborators_Aggregate_Bool_Exp
  >;
  ddn_projects?: InputMaybe<Ddn_Projects_Bool_Exp>;
  ddn_projects_aggregate?: InputMaybe<Ddn_Projects_Aggregate_Bool_Exp>;
  ddn_team_memberships?: InputMaybe<Ddn_Team_Membership_Bool_Exp>;
  ddn_team_memberships_aggregate?: InputMaybe<
    Ddn_Team_Membership_Aggregate_Bool_Exp
  >;
  ddn_user_project_accesses?: InputMaybe<Ddn_User_Project_Access_Bool_Exp>;
  ddn_user_project_accesses_aggregate?: InputMaybe<
    Ddn_User_Project_Access_Aggregate_Bool_Exp
  >;
  email?: InputMaybe<String_Comparison_Exp>;
  enterprise_user?: InputMaybe<Enterprise_Users_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  personal_access_tokens?: InputMaybe<User_Personal_Access_Tokens_Bool_Exp>;
  personal_access_tokens_aggregate?: InputMaybe<
    User_Personal_Access_Tokens_Aggregate_Bool_Exp
  >;
  project_request_access?: InputMaybe<Ddn_Project_Request_Access_Bool_Exp>;
  projects?: InputMaybe<Projects_Bool_Exp>;
  projects_aggregate?: InputMaybe<Projects_Aggregate_Bool_Exp>;
  sent_ddn_team_invitations?: InputMaybe<Ddn_Team_Invitation_Bool_Exp>;
  sent_ddn_team_invitations_aggregate?: InputMaybe<
    Ddn_Team_Invitation_Aggregate_Bool_Exp
  >;
};

/** input type for inserting data into table "users_public" */
export type Users_Public_Insert_Input = {
  billing_projects?: InputMaybe<Projects_Arr_Rel_Insert_Input>;
  collaborated_projects?: InputMaybe<
    Project_Collaborators_Arr_Rel_Insert_Input
  >;
  collaborated_vpcs?: InputMaybe<Vpc_Collaborators_Arr_Rel_Insert_Input>;
  ddn_projects?: InputMaybe<Ddn_Projects_Arr_Rel_Insert_Input>;
  ddn_team_memberships?: InputMaybe<Ddn_Team_Membership_Arr_Rel_Insert_Input>;
  ddn_user_project_accesses?: InputMaybe<
    Ddn_User_Project_Access_Arr_Rel_Insert_Input
  >;
  email?: InputMaybe<Scalars['String']>;
  enterprise_user?: InputMaybe<Enterprise_Users_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  personal_access_tokens?: InputMaybe<
    User_Personal_Access_Tokens_Arr_Rel_Insert_Input
  >;
  project_request_access?: InputMaybe<
    Ddn_Project_Request_Access_Obj_Rel_Insert_Input
  >;
  projects?: InputMaybe<Projects_Arr_Rel_Insert_Input>;
  sent_ddn_team_invitations?: InputMaybe<
    Ddn_Team_Invitation_Arr_Rel_Insert_Input
  >;
};

/** aggregate max on columns */
export type Users_Public_Max_Fields = {
  __typename?: 'users_public_max_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Users_Public_Min_Fields = {
  __typename?: 'users_public_min_fields';
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "users_public" */
export type Users_Public_Mutation_Response = {
  __typename?: 'users_public_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users_Public>;
};

/** input type for inserting object relation for remote table "users_public" */
export type Users_Public_Obj_Rel_Insert_Input = {
  data: Users_Public_Insert_Input;
};

/** Ordering options when selecting data from "users_public". */
export type Users_Public_Order_By = {
  billing_projects_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  collaborated_projects_aggregate?: InputMaybe<
    Project_Collaborators_Aggregate_Order_By
  >;
  collaborated_vpcs_aggregate?: InputMaybe<
    Vpc_Collaborators_Aggregate_Order_By
  >;
  ddn_projects_aggregate?: InputMaybe<Ddn_Projects_Aggregate_Order_By>;
  ddn_team_memberships_aggregate?: InputMaybe<
    Ddn_Team_Membership_Aggregate_Order_By
  >;
  ddn_user_project_accesses_aggregate?: InputMaybe<
    Ddn_User_Project_Access_Aggregate_Order_By
  >;
  email?: InputMaybe<Order_By>;
  enterprise_user?: InputMaybe<Enterprise_Users_Order_By>;
  id?: InputMaybe<Order_By>;
  personal_access_tokens_aggregate?: InputMaybe<
    User_Personal_Access_Tokens_Aggregate_Order_By
  >;
  project_request_access?: InputMaybe<Ddn_Project_Request_Access_Order_By>;
  projects_aggregate?: InputMaybe<Projects_Aggregate_Order_By>;
  sent_ddn_team_invitations_aggregate?: InputMaybe<
    Ddn_Team_Invitation_Aggregate_Order_By
  >;
};

/** select columns of table "users_public" */
export enum Users_Public_Select_Column {
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
}

/** input type for updating data in table "users_public" */
export type Users_Public_Set_Input = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "users_public" */
export type Users_Public_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Public_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Public_Stream_Cursor_Value_Input = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
};

export type Users_Public_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Public_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Public_Bool_Exp;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AttemptCount = 'attempt_count',
  /** column name */
  ConfimVerifier = 'confim_verifier',
  /** column name */
  ConfirmMailCount = 'confirm_mail_count',
  /** column name */
  ConfirmSelector = 'confirm_selector',
  /** column name */
  Confirmed = 'confirmed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceReceiptEmail = 'invoice_receipt_email',
  /** column name */
  IsSamlUser = 'is_saml_user',
  /** column name */
  LastAttempt = 'last_attempt',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Locked = 'locked',
  /** column name */
  MaxProjectLimit = 'max_project_limit',
  /** column name */
  Organization = 'organization',
  /** column name */
  Password = 'password',
  /** column name */
  RecoverSelector = 'recover_selector',
  /** column name */
  RecoverTokenExpiry = 'recover_token_expiry',
  /** column name */
  RecoverVerifier = 'recover_verifier',
  /** column name */
  RecoveryCodes = 'recovery_codes',
  /** column name */
  TotpSecretKey = 'totp_secret_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZendeskUserId = 'zendesk_user_id',
}

/** select "users_aggregate_bool_exp_bool_and_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Confirmed = 'confirmed',
  /** column name */
  IsSamlUser = 'is_saml_user',
}

/** select "users_aggregate_bool_exp_bool_or_arguments_columns" columns of table "users" */
export enum Users_Select_Column_Users_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Confirmed = 'confirmed',
  /** column name */
  IsSamlUser = 'is_saml_user',
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  attempt_count?: InputMaybe<Scalars['Int']>;
  confim_verifier?: InputMaybe<Scalars['String']>;
  confirm_mail_count?: InputMaybe<Scalars['Int']>;
  confirm_selector?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_receipt_email?: InputMaybe<Scalars['String']>;
  is_saml_user?: InputMaybe<Scalars['Boolean']>;
  last_attempt?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['timestamptz']>;
  max_project_limit?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  recover_selector?: InputMaybe<Scalars['String']>;
  recover_token_expiry?: InputMaybe<Scalars['timestamptz']>;
  recover_verifier?: InputMaybe<Scalars['String']>;
  recovery_codes?: InputMaybe<Scalars['String']>;
  totp_secret_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zendesk_user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  confirm_mail_count?: Maybe<Scalars['Float']>;
  max_project_limit?: Maybe<Scalars['Float']>;
  zendesk_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "users" */
export type Users_Stddev_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  confirm_mail_count?: InputMaybe<Order_By>;
  max_project_limit?: InputMaybe<Order_By>;
  zendesk_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  confirm_mail_count?: Maybe<Scalars['Float']>;
  max_project_limit?: Maybe<Scalars['Float']>;
  zendesk_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "users" */
export type Users_Stddev_Pop_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  confirm_mail_count?: InputMaybe<Order_By>;
  max_project_limit?: InputMaybe<Order_By>;
  zendesk_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  confirm_mail_count?: Maybe<Scalars['Float']>;
  max_project_limit?: Maybe<Scalars['Float']>;
  zendesk_user_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "users" */
export type Users_Stddev_Samp_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  confirm_mail_count?: InputMaybe<Order_By>;
  max_project_limit?: InputMaybe<Order_By>;
  zendesk_user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  attempt_count?: InputMaybe<Scalars['Int']>;
  confim_verifier?: InputMaybe<Scalars['String']>;
  confirm_mail_count?: InputMaybe<Scalars['Int']>;
  confirm_selector?: InputMaybe<Scalars['String']>;
  confirmed?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  customer_id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  first_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  invoice_receipt_email?: InputMaybe<Scalars['String']>;
  is_saml_user?: InputMaybe<Scalars['Boolean']>;
  last_attempt?: InputMaybe<Scalars['timestamptz']>;
  last_name?: InputMaybe<Scalars['String']>;
  locked?: InputMaybe<Scalars['timestamptz']>;
  max_project_limit?: InputMaybe<Scalars['Int']>;
  organization?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  recover_selector?: InputMaybe<Scalars['String']>;
  recover_token_expiry?: InputMaybe<Scalars['timestamptz']>;
  recover_verifier?: InputMaybe<Scalars['String']>;
  recovery_codes?: InputMaybe<Scalars['String']>;
  totp_secret_key?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  zendesk_user_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  attempt_count?: Maybe<Scalars['Int']>;
  confirm_mail_count?: Maybe<Scalars['Int']>;
  max_project_limit?: Maybe<Scalars['Int']>;
  zendesk_user_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "users" */
export type Users_Sum_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  confirm_mail_count?: InputMaybe<Order_By>;
  max_project_limit?: InputMaybe<Order_By>;
  zendesk_user_id?: InputMaybe<Order_By>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AttemptCount = 'attempt_count',
  /** column name */
  ConfimVerifier = 'confim_verifier',
  /** column name */
  ConfirmMailCount = 'confirm_mail_count',
  /** column name */
  ConfirmSelector = 'confirm_selector',
  /** column name */
  Confirmed = 'confirmed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomerId = 'customer_id',
  /** column name */
  Email = 'email',
  /** column name */
  FirstName = 'first_name',
  /** column name */
  Id = 'id',
  /** column name */
  InvoiceReceiptEmail = 'invoice_receipt_email',
  /** column name */
  IsSamlUser = 'is_saml_user',
  /** column name */
  LastAttempt = 'last_attempt',
  /** column name */
  LastName = 'last_name',
  /** column name */
  Locked = 'locked',
  /** column name */
  MaxProjectLimit = 'max_project_limit',
  /** column name */
  Organization = 'organization',
  /** column name */
  Password = 'password',
  /** column name */
  RecoverSelector = 'recover_selector',
  /** column name */
  RecoverTokenExpiry = 'recover_token_expiry',
  /** column name */
  RecoverVerifier = 'recover_verifier',
  /** column name */
  RecoveryCodes = 'recovery_codes',
  /** column name */
  TotpSecretKey = 'totp_secret_key',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ZendeskUserId = 'zendesk_user_id',
}

export type Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  confirm_mail_count?: Maybe<Scalars['Float']>;
  max_project_limit?: Maybe<Scalars['Float']>;
  zendesk_user_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "users" */
export type Users_Var_Pop_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  confirm_mail_count?: InputMaybe<Order_By>;
  max_project_limit?: InputMaybe<Order_By>;
  zendesk_user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  confirm_mail_count?: Maybe<Scalars['Float']>;
  max_project_limit?: Maybe<Scalars['Float']>;
  zendesk_user_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "users" */
export type Users_Var_Samp_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  confirm_mail_count?: InputMaybe<Order_By>;
  max_project_limit?: InputMaybe<Order_By>;
  zendesk_user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  attempt_count?: Maybe<Scalars['Float']>;
  confirm_mail_count?: Maybe<Scalars['Float']>;
  max_project_limit?: Maybe<Scalars['Float']>;
  zendesk_user_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "users" */
export type Users_Variance_Order_By = {
  attempt_count?: InputMaybe<Order_By>;
  confirm_mail_count?: InputMaybe<Order_By>;
  max_project_limit?: InputMaybe<Order_By>;
  zendesk_user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** Stores information about Vercel Integrations. Each Vercel user/team creating an integration to Hasura will have a row in this table. */
export type Vercel_Integration = {
  __typename?: 'vercel_integration';
  configuration_id: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  environment_variables?: Maybe<Scalars['jsonb']>;
  id: Scalars['uuid'];
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  vercel_integration_connections: Array<Vercel_Integration_Connections>;
  /** An aggregate relationship */
  vercel_integration_connections_aggregate: Vercel_Integration_Connections_Aggregate;
  vercel_user_id?: Maybe<Scalars['String']>;
};

/** Stores information about Vercel Integrations. Each Vercel user/team creating an integration to Hasura will have a row in this table. */
export type Vercel_IntegrationEnvironment_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Stores information about Vercel Integrations. Each Vercel user/team creating an integration to Hasura will have a row in this table. */
export type Vercel_IntegrationVercel_Integration_ConnectionsArgs = {
  distinct_on?: InputMaybe<Array<Vercel_Integration_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vercel_Integration_Connections_Order_By>>;
  where?: InputMaybe<Vercel_Integration_Connections_Bool_Exp>;
};

/** Stores information about Vercel Integrations. Each Vercel user/team creating an integration to Hasura will have a row in this table. */
export type Vercel_IntegrationVercel_Integration_Connections_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Vercel_Integration_Connections_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Vercel_Integration_Connections_Order_By>>;
  where?: InputMaybe<Vercel_Integration_Connections_Bool_Exp>;
};

/** aggregated selection of "vercel_integration" */
export type Vercel_Integration_Aggregate = {
  __typename?: 'vercel_integration_aggregate';
  aggregate?: Maybe<Vercel_Integration_Aggregate_Fields>;
  nodes: Array<Vercel_Integration>;
};

/** aggregate fields of "vercel_integration" */
export type Vercel_Integration_Aggregate_Fields = {
  __typename?: 'vercel_integration_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vercel_Integration_Max_Fields>;
  min?: Maybe<Vercel_Integration_Min_Fields>;
};

/** aggregate fields of "vercel_integration" */
export type Vercel_Integration_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vercel_Integration_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Vercel_Integration_Append_Input = {
  environment_variables?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "vercel_integration". All fields are combined with a logical 'AND'. */
export type Vercel_Integration_Bool_Exp = {
  _and?: InputMaybe<Array<Vercel_Integration_Bool_Exp>>;
  _not?: InputMaybe<Vercel_Integration_Bool_Exp>;
  _or?: InputMaybe<Array<Vercel_Integration_Bool_Exp>>;
  configuration_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  environment_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  team_id?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vercel_integration_connections?: InputMaybe<
    Vercel_Integration_Connections_Bool_Exp
  >;
  vercel_integration_connections_aggregate?: InputMaybe<
    Vercel_Integration_Connections_Aggregate_Bool_Exp
  >;
  vercel_user_id?: InputMaybe<String_Comparison_Exp>;
};

/** Stores the mapping information between Vercel Projects and Hasura Cloud projects. */
export type Vercel_Integration_Connections = {
  __typename?: 'vercel_integration_connections';
  created_at: Scalars['timestamp'];
  hasura_cloud_project_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  project?: Maybe<Projects>;
  status: Scalars['String'];
  updated_at: Scalars['timestamp'];
  /** An object relationship */
  vercel_integration: Vercel_Integration;
  vercel_integration_id: Scalars['uuid'];
  vercel_project_id: Scalars['String'];
};

/** aggregated selection of "vercel_integration_connections" */
export type Vercel_Integration_Connections_Aggregate = {
  __typename?: 'vercel_integration_connections_aggregate';
  aggregate?: Maybe<Vercel_Integration_Connections_Aggregate_Fields>;
  nodes: Array<Vercel_Integration_Connections>;
};

export type Vercel_Integration_Connections_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vercel_Integration_Connections_Aggregate_Bool_Exp_Count>;
};

export type Vercel_Integration_Connections_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vercel_Integration_Connections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vercel_Integration_Connections_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vercel_integration_connections" */
export type Vercel_Integration_Connections_Aggregate_Fields = {
  __typename?: 'vercel_integration_connections_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vercel_Integration_Connections_Max_Fields>;
  min?: Maybe<Vercel_Integration_Connections_Min_Fields>;
};

/** aggregate fields of "vercel_integration_connections" */
export type Vercel_Integration_Connections_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vercel_Integration_Connections_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vercel_integration_connections" */
export type Vercel_Integration_Connections_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vercel_Integration_Connections_Max_Order_By>;
  min?: InputMaybe<Vercel_Integration_Connections_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vercel_integration_connections" */
export type Vercel_Integration_Connections_Arr_Rel_Insert_Input = {
  data: Array<Vercel_Integration_Connections_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vercel_Integration_Connections_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vercel_integration_connections". All fields are combined with a logical 'AND'. */
export type Vercel_Integration_Connections_Bool_Exp = {
  _and?: InputMaybe<Array<Vercel_Integration_Connections_Bool_Exp>>;
  _not?: InputMaybe<Vercel_Integration_Connections_Bool_Exp>;
  _or?: InputMaybe<Array<Vercel_Integration_Connections_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  hasura_cloud_project_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  vercel_integration?: InputMaybe<Vercel_Integration_Bool_Exp>;
  vercel_integration_id?: InputMaybe<Uuid_Comparison_Exp>;
  vercel_project_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "vercel_integration_connections" */
export enum Vercel_Integration_Connections_Constraint {
  /** unique or primary key constraint on columns "id" */
  VercelIntegrationConnectionsPkey = 'vercel_integration_connections_pkey',
}

/** input type for inserting data into table "vercel_integration_connections" */
export type Vercel_Integration_Connections_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  hasura_cloud_project_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  vercel_integration?: InputMaybe<Vercel_Integration_Obj_Rel_Insert_Input>;
  vercel_integration_id?: InputMaybe<Scalars['uuid']>;
  vercel_project_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Vercel_Integration_Connections_Max_Fields = {
  __typename?: 'vercel_integration_connections_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  hasura_cloud_project_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  vercel_integration_id?: Maybe<Scalars['uuid']>;
  vercel_project_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "vercel_integration_connections" */
export type Vercel_Integration_Connections_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hasura_cloud_project_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vercel_integration_id?: InputMaybe<Order_By>;
  vercel_project_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vercel_Integration_Connections_Min_Fields = {
  __typename?: 'vercel_integration_connections_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  hasura_cloud_project_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  vercel_integration_id?: Maybe<Scalars['uuid']>;
  vercel_project_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "vercel_integration_connections" */
export type Vercel_Integration_Connections_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hasura_cloud_project_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vercel_integration_id?: InputMaybe<Order_By>;
  vercel_project_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vercel_integration_connections" */
export type Vercel_Integration_Connections_Mutation_Response = {
  __typename?: 'vercel_integration_connections_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vercel_Integration_Connections>;
};

/** on_conflict condition type for table "vercel_integration_connections" */
export type Vercel_Integration_Connections_On_Conflict = {
  constraint: Vercel_Integration_Connections_Constraint;
  update_columns?: Array<Vercel_Integration_Connections_Update_Column>;
  where?: InputMaybe<Vercel_Integration_Connections_Bool_Exp>;
};

/** Ordering options when selecting data from "vercel_integration_connections". */
export type Vercel_Integration_Connections_Order_By = {
  created_at?: InputMaybe<Order_By>;
  hasura_cloud_project_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vercel_integration?: InputMaybe<Vercel_Integration_Order_By>;
  vercel_integration_id?: InputMaybe<Order_By>;
  vercel_project_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vercel_integration_connections */
export type Vercel_Integration_Connections_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "vercel_integration_connections" */
export enum Vercel_Integration_Connections_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasuraCloudProjectId = 'hasura_cloud_project_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VercelIntegrationId = 'vercel_integration_id',
  /** column name */
  VercelProjectId = 'vercel_project_id',
}

/** input type for updating data in table "vercel_integration_connections" */
export type Vercel_Integration_Connections_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  hasura_cloud_project_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  vercel_integration_id?: InputMaybe<Scalars['uuid']>;
  vercel_project_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "vercel_integration_connections" */
export type Vercel_Integration_Connections_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vercel_Integration_Connections_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vercel_Integration_Connections_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  hasura_cloud_project_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  vercel_integration_id?: InputMaybe<Scalars['uuid']>;
  vercel_project_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "vercel_integration_connections" */
export enum Vercel_Integration_Connections_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  HasuraCloudProjectId = 'hasura_cloud_project_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VercelIntegrationId = 'vercel_integration_id',
  /** column name */
  VercelProjectId = 'vercel_project_id',
}

export type Vercel_Integration_Connections_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vercel_Integration_Connections_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vercel_Integration_Connections_Bool_Exp;
};

/** unique or primary key constraints on table "vercel_integration" */
export enum Vercel_Integration_Constraint {
  /** unique or primary key constraint on columns "configuration_id" */
  VercelIntegrationConfigurationIdKey = 'vercel_integration_configuration_id_key',
  /** unique or primary key constraint on columns "id" */
  VercelIntegrationPkey = 'vercel_integration_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Vercel_Integration_Delete_At_Path_Input = {
  environment_variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Vercel_Integration_Delete_Elem_Input = {
  environment_variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Vercel_Integration_Delete_Key_Input = {
  environment_variables?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "vercel_integration" */
export type Vercel_Integration_Insert_Input = {
  configuration_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_variables?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vercel_integration_connections?: InputMaybe<
    Vercel_Integration_Connections_Arr_Rel_Insert_Input
  >;
  vercel_user_id?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Vercel_Integration_Max_Fields = {
  __typename?: 'vercel_integration_max_fields';
  configuration_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vercel_user_id?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Vercel_Integration_Min_Fields = {
  __typename?: 'vercel_integration_min_fields';
  configuration_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  status?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
  vercel_user_id?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "vercel_integration" */
export type Vercel_Integration_Mutation_Response = {
  __typename?: 'vercel_integration_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vercel_Integration>;
};

/** input type for inserting object relation for remote table "vercel_integration" */
export type Vercel_Integration_Obj_Rel_Insert_Input = {
  data: Vercel_Integration_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vercel_Integration_On_Conflict>;
};

/** on_conflict condition type for table "vercel_integration" */
export type Vercel_Integration_On_Conflict = {
  constraint: Vercel_Integration_Constraint;
  update_columns?: Array<Vercel_Integration_Update_Column>;
  where?: InputMaybe<Vercel_Integration_Bool_Exp>;
};

/** Ordering options when selecting data from "vercel_integration". */
export type Vercel_Integration_Order_By = {
  configuration_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  environment_variables?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  team_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vercel_integration_connections_aggregate?: InputMaybe<
    Vercel_Integration_Connections_Aggregate_Order_By
  >;
  vercel_user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vercel_integration */
export type Vercel_Integration_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Vercel_Integration_Prepend_Input = {
  environment_variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "vercel_integration" */
export enum Vercel_Integration_Select_Column {
  /** column name */
  ConfigurationId = 'configuration_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentVariables = 'environment_variables',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VercelUserId = 'vercel_user_id',
}

/** input type for updating data in table "vercel_integration" */
export type Vercel_Integration_Set_Input = {
  configuration_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_variables?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vercel_user_id?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "vercel_integration" */
export type Vercel_Integration_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vercel_Integration_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vercel_Integration_Stream_Cursor_Value_Input = {
  configuration_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  environment_variables?: InputMaybe<Scalars['jsonb']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  status?: InputMaybe<Scalars['String']>;
  team_id?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
  vercel_user_id?: InputMaybe<Scalars['String']>;
};

/** update columns of table "vercel_integration" */
export enum Vercel_Integration_Update_Column {
  /** column name */
  ConfigurationId = 'configuration_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EnvironmentVariables = 'environment_variables',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  Status = 'status',
  /** column name */
  TeamId = 'team_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VercelUserId = 'vercel_user_id',
}

export type Vercel_Integration_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Vercel_Integration_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Vercel_Integration_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Vercel_Integration_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Vercel_Integration_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Vercel_Integration_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vercel_Integration_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vercel_Integration_Bool_Exp;
};

/** Captures invitations to collaborate on a VPC */
export type Vpc_Collaborator_Invitations = {
  __typename?: 'vpc_collaborator_invitations';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  collaborator_email: Scalars['citext'];
  declined_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  dedicated_vpc: Dedicated_Vpc;
  id: Scalars['uuid'];
  invited_at: Scalars['timestamptz'];
  invited_by: Scalars['uuid'];
  invited_by_email: Scalars['citext'];
  /** An object relationship */
  invited_by_user: Users;
  privilege_slug: Scalars['String'];
  status: Scalars['String'];
  vpc_id: Scalars['uuid'];
  vpc_name: Scalars['String'];
};

/** aggregated selection of "vpc_collaborator_invitations" */
export type Vpc_Collaborator_Invitations_Aggregate = {
  __typename?: 'vpc_collaborator_invitations_aggregate';
  aggregate?: Maybe<Vpc_Collaborator_Invitations_Aggregate_Fields>;
  nodes: Array<Vpc_Collaborator_Invitations>;
};

export type Vpc_Collaborator_Invitations_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vpc_Collaborator_Invitations_Aggregate_Bool_Exp_Count>;
};

export type Vpc_Collaborator_Invitations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vpc_Collaborator_Invitations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vpc_Collaborator_Invitations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vpc_collaborator_invitations" */
export type Vpc_Collaborator_Invitations_Aggregate_Fields = {
  __typename?: 'vpc_collaborator_invitations_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vpc_Collaborator_Invitations_Max_Fields>;
  min?: Maybe<Vpc_Collaborator_Invitations_Min_Fields>;
};

/** aggregate fields of "vpc_collaborator_invitations" */
export type Vpc_Collaborator_Invitations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vpc_Collaborator_Invitations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vpc_collaborator_invitations" */
export type Vpc_Collaborator_Invitations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vpc_Collaborator_Invitations_Max_Order_By>;
  min?: InputMaybe<Vpc_Collaborator_Invitations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vpc_collaborator_invitations" */
export type Vpc_Collaborator_Invitations_Arr_Rel_Insert_Input = {
  data: Array<Vpc_Collaborator_Invitations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vpc_Collaborator_Invitations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vpc_collaborator_invitations". All fields are combined with a logical 'AND'. */
export type Vpc_Collaborator_Invitations_Bool_Exp = {
  _and?: InputMaybe<Array<Vpc_Collaborator_Invitations_Bool_Exp>>;
  _not?: InputMaybe<Vpc_Collaborator_Invitations_Bool_Exp>;
  _or?: InputMaybe<Array<Vpc_Collaborator_Invitations_Bool_Exp>>;
  accepted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  collaborator_email?: InputMaybe<Citext_Comparison_Exp>;
  declined_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invited_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  invited_by?: InputMaybe<Uuid_Comparison_Exp>;
  invited_by_email?: InputMaybe<Citext_Comparison_Exp>;
  invited_by_user?: InputMaybe<Users_Bool_Exp>;
  privilege_slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  vpc_id?: InputMaybe<Uuid_Comparison_Exp>;
  vpc_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "vpc_collaborator_invitations" */
export enum Vpc_Collaborator_Invitations_Constraint {
  /** unique or primary key constraint on columns "id" */
  VpcCollaboratorInvitationsPkey = 'vpc_collaborator_invitations_pkey',
}

/** input type for inserting data into table "vpc_collaborator_invitations" */
export type Vpc_Collaborator_Invitations_Insert_Input = {
  accepted_at?: InputMaybe<Scalars['timestamptz']>;
  collaborator_email?: InputMaybe<Scalars['citext']>;
  declined_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  invited_by_email?: InputMaybe<Scalars['citext']>;
  invited_by_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  privilege_slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  vpc_id?: InputMaybe<Scalars['uuid']>;
  vpc_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Vpc_Collaborator_Invitations_Max_Fields = {
  __typename?: 'vpc_collaborator_invitations_max_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  collaborator_email?: Maybe<Scalars['citext']>;
  declined_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invited_at?: Maybe<Scalars['timestamptz']>;
  invited_by?: Maybe<Scalars['uuid']>;
  invited_by_email?: Maybe<Scalars['citext']>;
  privilege_slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  vpc_id?: Maybe<Scalars['uuid']>;
  vpc_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "vpc_collaborator_invitations" */
export type Vpc_Collaborator_Invitations_Max_Order_By = {
  accepted_at?: InputMaybe<Order_By>;
  collaborator_email?: InputMaybe<Order_By>;
  declined_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  invited_by_email?: InputMaybe<Order_By>;
  privilege_slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  vpc_id?: InputMaybe<Order_By>;
  vpc_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vpc_Collaborator_Invitations_Min_Fields = {
  __typename?: 'vpc_collaborator_invitations_min_fields';
  accepted_at?: Maybe<Scalars['timestamptz']>;
  collaborator_email?: Maybe<Scalars['citext']>;
  declined_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  invited_at?: Maybe<Scalars['timestamptz']>;
  invited_by?: Maybe<Scalars['uuid']>;
  invited_by_email?: Maybe<Scalars['citext']>;
  privilege_slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  vpc_id?: Maybe<Scalars['uuid']>;
  vpc_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "vpc_collaborator_invitations" */
export type Vpc_Collaborator_Invitations_Min_Order_By = {
  accepted_at?: InputMaybe<Order_By>;
  collaborator_email?: InputMaybe<Order_By>;
  declined_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  invited_by_email?: InputMaybe<Order_By>;
  privilege_slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  vpc_id?: InputMaybe<Order_By>;
  vpc_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vpc_collaborator_invitations" */
export type Vpc_Collaborator_Invitations_Mutation_Response = {
  __typename?: 'vpc_collaborator_invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vpc_Collaborator_Invitations>;
};

/** on_conflict condition type for table "vpc_collaborator_invitations" */
export type Vpc_Collaborator_Invitations_On_Conflict = {
  constraint: Vpc_Collaborator_Invitations_Constraint;
  update_columns?: Array<Vpc_Collaborator_Invitations_Update_Column>;
  where?: InputMaybe<Vpc_Collaborator_Invitations_Bool_Exp>;
};

/** Ordering options when selecting data from "vpc_collaborator_invitations". */
export type Vpc_Collaborator_Invitations_Order_By = {
  accepted_at?: InputMaybe<Order_By>;
  collaborator_email?: InputMaybe<Order_By>;
  declined_at?: InputMaybe<Order_By>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Order_By>;
  id?: InputMaybe<Order_By>;
  invited_at?: InputMaybe<Order_By>;
  invited_by?: InputMaybe<Order_By>;
  invited_by_email?: InputMaybe<Order_By>;
  invited_by_user?: InputMaybe<Users_Order_By>;
  privilege_slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  vpc_id?: InputMaybe<Order_By>;
  vpc_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vpc_collaborator_invitations */
export type Vpc_Collaborator_Invitations_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "vpc_collaborator_invitations" */
export enum Vpc_Collaborator_Invitations_Select_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  CollaboratorEmail = 'collaborator_email',
  /** column name */
  DeclinedAt = 'declined_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedAt = 'invited_at',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  InvitedByEmail = 'invited_by_email',
  /** column name */
  PrivilegeSlug = 'privilege_slug',
  /** column name */
  Status = 'status',
  /** column name */
  VpcId = 'vpc_id',
  /** column name */
  VpcName = 'vpc_name',
}

/** input type for updating data in table "vpc_collaborator_invitations" */
export type Vpc_Collaborator_Invitations_Set_Input = {
  accepted_at?: InputMaybe<Scalars['timestamptz']>;
  collaborator_email?: InputMaybe<Scalars['citext']>;
  declined_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  invited_by_email?: InputMaybe<Scalars['citext']>;
  privilege_slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  vpc_id?: InputMaybe<Scalars['uuid']>;
  vpc_name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "vpc_collaborator_invitations" */
export type Vpc_Collaborator_Invitations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vpc_Collaborator_Invitations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vpc_Collaborator_Invitations_Stream_Cursor_Value_Input = {
  accepted_at?: InputMaybe<Scalars['timestamptz']>;
  collaborator_email?: InputMaybe<Scalars['citext']>;
  declined_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  invited_at?: InputMaybe<Scalars['timestamptz']>;
  invited_by?: InputMaybe<Scalars['uuid']>;
  invited_by_email?: InputMaybe<Scalars['citext']>;
  privilege_slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  vpc_id?: InputMaybe<Scalars['uuid']>;
  vpc_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "vpc_collaborator_invitations" */
export enum Vpc_Collaborator_Invitations_Update_Column {
  /** column name */
  AcceptedAt = 'accepted_at',
  /** column name */
  CollaboratorEmail = 'collaborator_email',
  /** column name */
  DeclinedAt = 'declined_at',
  /** column name */
  Id = 'id',
  /** column name */
  InvitedAt = 'invited_at',
  /** column name */
  InvitedBy = 'invited_by',
  /** column name */
  InvitedByEmail = 'invited_by_email',
  /** column name */
  PrivilegeSlug = 'privilege_slug',
  /** column name */
  Status = 'status',
  /** column name */
  VpcId = 'vpc_id',
  /** column name */
  VpcName = 'vpc_name',
}

export type Vpc_Collaborator_Invitations_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vpc_Collaborator_Invitations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vpc_Collaborator_Invitations_Bool_Exp;
};

/** Collaborators for a VPC and their corresponding privilege_slug */
export type Vpc_Collaborators = {
  __typename?: 'vpc_collaborators';
  /** An object relationship */
  collaborator?: Maybe<Users_Public>;
  collaborator_id: Scalars['uuid'];
  /** An object relationship */
  dedicated_vpc: Dedicated_Vpc;
  granted_at: Scalars['timestamptz'];
  granted_by: Scalars['uuid'];
  privilege_slug: Scalars['String'];
  status: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  vpc_id: Scalars['uuid'];
};

/** aggregated selection of "vpc_collaborators" */
export type Vpc_Collaborators_Aggregate = {
  __typename?: 'vpc_collaborators_aggregate';
  aggregate?: Maybe<Vpc_Collaborators_Aggregate_Fields>;
  nodes: Array<Vpc_Collaborators>;
};

export type Vpc_Collaborators_Aggregate_Bool_Exp = {
  count?: InputMaybe<Vpc_Collaborators_Aggregate_Bool_Exp_Count>;
};

export type Vpc_Collaborators_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vpc_Collaborators_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vpc_collaborators" */
export type Vpc_Collaborators_Aggregate_Fields = {
  __typename?: 'vpc_collaborators_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vpc_Collaborators_Max_Fields>;
  min?: Maybe<Vpc_Collaborators_Min_Fields>;
};

/** aggregate fields of "vpc_collaborators" */
export type Vpc_Collaborators_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vpc_Collaborators_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vpc_collaborators" */
export type Vpc_Collaborators_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vpc_Collaborators_Max_Order_By>;
  min?: InputMaybe<Vpc_Collaborators_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vpc_collaborators" */
export type Vpc_Collaborators_Arr_Rel_Insert_Input = {
  data: Array<Vpc_Collaborators_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vpc_Collaborators_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vpc_collaborators". All fields are combined with a logical 'AND'. */
export type Vpc_Collaborators_Bool_Exp = {
  _and?: InputMaybe<Array<Vpc_Collaborators_Bool_Exp>>;
  _not?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
  _or?: InputMaybe<Array<Vpc_Collaborators_Bool_Exp>>;
  collaborator?: InputMaybe<Users_Public_Bool_Exp>;
  collaborator_id?: InputMaybe<Uuid_Comparison_Exp>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
  granted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  granted_by?: InputMaybe<Uuid_Comparison_Exp>;
  privilege_slug?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vpc_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vpc_collaborators" */
export enum Vpc_Collaborators_Constraint {
  /** unique or primary key constraint on columns "collaborator_id", "vpc_id" */
  VpcCollaboratorsPkey = 'vpc_collaborators_pkey',
}

/** input type for inserting data into table "vpc_collaborators" */
export type Vpc_Collaborators_Insert_Input = {
  collaborator?: InputMaybe<Users_Public_Obj_Rel_Insert_Input>;
  collaborator_id?: InputMaybe<Scalars['uuid']>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Obj_Rel_Insert_Input>;
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  granted_by?: InputMaybe<Scalars['uuid']>;
  privilege_slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vpc_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Vpc_Collaborators_Max_Fields = {
  __typename?: 'vpc_collaborators_max_fields';
  collaborator_id?: Maybe<Scalars['uuid']>;
  granted_at?: Maybe<Scalars['timestamptz']>;
  granted_by?: Maybe<Scalars['uuid']>;
  privilege_slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vpc_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "vpc_collaborators" */
export type Vpc_Collaborators_Max_Order_By = {
  collaborator_id?: InputMaybe<Order_By>;
  granted_at?: InputMaybe<Order_By>;
  granted_by?: InputMaybe<Order_By>;
  privilege_slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vpc_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vpc_Collaborators_Min_Fields = {
  __typename?: 'vpc_collaborators_min_fields';
  collaborator_id?: Maybe<Scalars['uuid']>;
  granted_at?: Maybe<Scalars['timestamptz']>;
  granted_by?: Maybe<Scalars['uuid']>;
  privilege_slug?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  vpc_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "vpc_collaborators" */
export type Vpc_Collaborators_Min_Order_By = {
  collaborator_id?: InputMaybe<Order_By>;
  granted_at?: InputMaybe<Order_By>;
  granted_by?: InputMaybe<Order_By>;
  privilege_slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vpc_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vpc_collaborators" */
export type Vpc_Collaborators_Mutation_Response = {
  __typename?: 'vpc_collaborators_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vpc_Collaborators>;
};

/** on_conflict condition type for table "vpc_collaborators" */
export type Vpc_Collaborators_On_Conflict = {
  constraint: Vpc_Collaborators_Constraint;
  update_columns?: Array<Vpc_Collaborators_Update_Column>;
  where?: InputMaybe<Vpc_Collaborators_Bool_Exp>;
};

/** Ordering options when selecting data from "vpc_collaborators". */
export type Vpc_Collaborators_Order_By = {
  collaborator?: InputMaybe<Users_Public_Order_By>;
  collaborator_id?: InputMaybe<Order_By>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Order_By>;
  granted_at?: InputMaybe<Order_By>;
  granted_by?: InputMaybe<Order_By>;
  privilege_slug?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vpc_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vpc_collaborators */
export type Vpc_Collaborators_Pk_Columns_Input = {
  collaborator_id: Scalars['uuid'];
  vpc_id: Scalars['uuid'];
};

/** select columns of table "vpc_collaborators" */
export enum Vpc_Collaborators_Select_Column {
  /** column name */
  CollaboratorId = 'collaborator_id',
  /** column name */
  GrantedAt = 'granted_at',
  /** column name */
  GrantedBy = 'granted_by',
  /** column name */
  PrivilegeSlug = 'privilege_slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VpcId = 'vpc_id',
}

/** input type for updating data in table "vpc_collaborators" */
export type Vpc_Collaborators_Set_Input = {
  collaborator_id?: InputMaybe<Scalars['uuid']>;
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  granted_by?: InputMaybe<Scalars['uuid']>;
  privilege_slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vpc_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "vpc_collaborators" */
export type Vpc_Collaborators_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vpc_Collaborators_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vpc_Collaborators_Stream_Cursor_Value_Input = {
  collaborator_id?: InputMaybe<Scalars['uuid']>;
  granted_at?: InputMaybe<Scalars['timestamptz']>;
  granted_by?: InputMaybe<Scalars['uuid']>;
  privilege_slug?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  vpc_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "vpc_collaborators" */
export enum Vpc_Collaborators_Update_Column {
  /** column name */
  CollaboratorId = 'collaborator_id',
  /** column name */
  GrantedAt = 'granted_at',
  /** column name */
  GrantedBy = 'granted_by',
  /** column name */
  PrivilegeSlug = 'privilege_slug',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VpcId = 'vpc_id',
}

export type Vpc_Collaborators_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vpc_Collaborators_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vpc_Collaborators_Bool_Exp;
};

/** Peering connections for a dedicated VPC */
export type Vpc_Peering = {
  __typename?: 'vpc_peering';
  aws_customer_account_id?: Maybe<Scalars['String']>;
  aws_customer_vpc_additional_cidrs: Array<Scalars['String']>;
  aws_customer_vpc_cidr?: Maybe<Scalars['String']>;
  aws_customer_vpc_id?: Maybe<Scalars['String']>;
  aws_customer_vpc_region?: Maybe<Scalars['String']>;
  azure_input?: Maybe<Scalars['jsonb']>;
  comments?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  dedicated_vpc: Dedicated_Vpc;
  dedicated_vpc_id: Scalars['uuid'];
  destroy_requested: Scalars['Boolean'];
  direction: Vpc_Peering_Direction_Enum;
  disable_infra_auto_apply: Scalars['Boolean'];
  gcp_project_id?: Maybe<Scalars['String']>;
  gcp_vpc_network?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  infra_status?: Maybe<Infra_Status_Enum>;
  message?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  output_variables?: Maybe<Scalars['jsonb']>;
  provisioning: Provisioning_Enum;
  /** update the value manually by referring to the infra status field */
  status: Vpc_Status_Enum;
  updated_at: Scalars['timestamptz'];
};

/** Peering connections for a dedicated VPC */
export type Vpc_PeeringAzure_InputArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** Peering connections for a dedicated VPC */
export type Vpc_PeeringOutput_VariablesArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "vpc_peering" */
export type Vpc_Peering_Aggregate = {
  __typename?: 'vpc_peering_aggregate';
  aggregate?: Maybe<Vpc_Peering_Aggregate_Fields>;
  nodes: Array<Vpc_Peering>;
};

export type Vpc_Peering_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Vpc_Peering_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Vpc_Peering_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Vpc_Peering_Aggregate_Bool_Exp_Count>;
};

export type Vpc_Peering_Aggregate_Bool_Exp_Bool_And = {
  arguments: Vpc_Peering_Select_Column_Vpc_Peering_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vpc_Peering_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vpc_Peering_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Vpc_Peering_Select_Column_Vpc_Peering_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vpc_Peering_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Vpc_Peering_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Vpc_Peering_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<Vpc_Peering_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "vpc_peering" */
export type Vpc_Peering_Aggregate_Fields = {
  __typename?: 'vpc_peering_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vpc_Peering_Max_Fields>;
  min?: Maybe<Vpc_Peering_Min_Fields>;
};

/** aggregate fields of "vpc_peering" */
export type Vpc_Peering_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vpc_Peering_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "vpc_peering" */
export type Vpc_Peering_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vpc_Peering_Max_Order_By>;
  min?: InputMaybe<Vpc_Peering_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Vpc_Peering_Append_Input = {
  azure_input?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "vpc_peering" */
export type Vpc_Peering_Arr_Rel_Insert_Input = {
  data: Array<Vpc_Peering_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vpc_Peering_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vpc_peering". All fields are combined with a logical 'AND'. */
export type Vpc_Peering_Bool_Exp = {
  _and?: InputMaybe<Array<Vpc_Peering_Bool_Exp>>;
  _not?: InputMaybe<Vpc_Peering_Bool_Exp>;
  _or?: InputMaybe<Array<Vpc_Peering_Bool_Exp>>;
  aws_customer_account_id?: InputMaybe<String_Comparison_Exp>;
  aws_customer_vpc_additional_cidrs?: InputMaybe<String_Array_Comparison_Exp>;
  aws_customer_vpc_cidr?: InputMaybe<String_Comparison_Exp>;
  aws_customer_vpc_id?: InputMaybe<String_Comparison_Exp>;
  aws_customer_vpc_region?: InputMaybe<String_Comparison_Exp>;
  azure_input?: InputMaybe<Jsonb_Comparison_Exp>;
  comments?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Bool_Exp>;
  dedicated_vpc_id?: InputMaybe<Uuid_Comparison_Exp>;
  destroy_requested?: InputMaybe<Boolean_Comparison_Exp>;
  direction?: InputMaybe<Vpc_Peering_Direction_Enum_Comparison_Exp>;
  disable_infra_auto_apply?: InputMaybe<Boolean_Comparison_Exp>;
  gcp_project_id?: InputMaybe<String_Comparison_Exp>;
  gcp_vpc_network?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  infra_status?: InputMaybe<Infra_Status_Enum_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  output_variables?: InputMaybe<Jsonb_Comparison_Exp>;
  provisioning?: InputMaybe<Provisioning_Enum_Comparison_Exp>;
  status?: InputMaybe<Vpc_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "vpc_peering" */
export enum Vpc_Peering_Constraint {
  /** unique or primary key constraint on columns "id" */
  VpcPeeringPkey = 'vpc_peering_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Vpc_Peering_Delete_At_Path_Input = {
  azure_input?: InputMaybe<Array<Scalars['String']>>;
  output_variables?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Vpc_Peering_Delete_Elem_Input = {
  azure_input?: InputMaybe<Scalars['Int']>;
  output_variables?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Vpc_Peering_Delete_Key_Input = {
  azure_input?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['String']>;
};

/** Hasura enum capturing the direction of peering connection */
export type Vpc_Peering_Direction = {
  __typename?: 'vpc_peering_direction';
  comment?: Maybe<Scalars['String']>;
  direction: Scalars['String'];
};

/** aggregated selection of "vpc_peering_direction" */
export type Vpc_Peering_Direction_Aggregate = {
  __typename?: 'vpc_peering_direction_aggregate';
  aggregate?: Maybe<Vpc_Peering_Direction_Aggregate_Fields>;
  nodes: Array<Vpc_Peering_Direction>;
};

/** aggregate fields of "vpc_peering_direction" */
export type Vpc_Peering_Direction_Aggregate_Fields = {
  __typename?: 'vpc_peering_direction_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vpc_Peering_Direction_Max_Fields>;
  min?: Maybe<Vpc_Peering_Direction_Min_Fields>;
};

/** aggregate fields of "vpc_peering_direction" */
export type Vpc_Peering_Direction_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vpc_Peering_Direction_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "vpc_peering_direction". All fields are combined with a logical 'AND'. */
export type Vpc_Peering_Direction_Bool_Exp = {
  _and?: InputMaybe<Array<Vpc_Peering_Direction_Bool_Exp>>;
  _not?: InputMaybe<Vpc_Peering_Direction_Bool_Exp>;
  _or?: InputMaybe<Array<Vpc_Peering_Direction_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  direction?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "vpc_peering_direction" */
export enum Vpc_Peering_Direction_Constraint {
  /** unique or primary key constraint on columns "direction" */
  VpcPeeringDirectionPkey = 'vpc_peering_direction_pkey',
}

export enum Vpc_Peering_Direction_Enum {
  /** Accept the peering request initiated by the customer */
  CustomerToHasura = 'customer_to_hasura',
  /** Peering request will be created from Hasura account */
  HasuraToCustomer = 'hasura_to_customer',
}

/** Boolean expression to compare columns of type "vpc_peering_direction_enum". All fields are combined with logical 'AND'. */
export type Vpc_Peering_Direction_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vpc_Peering_Direction_Enum>;
  _in?: InputMaybe<Array<Vpc_Peering_Direction_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Vpc_Peering_Direction_Enum>;
  _nin?: InputMaybe<Array<Vpc_Peering_Direction_Enum>>;
};

/** input type for inserting data into table "vpc_peering_direction" */
export type Vpc_Peering_Direction_Insert_Input = {
  comment?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Vpc_Peering_Direction_Max_Fields = {
  __typename?: 'vpc_peering_direction_max_fields';
  comment?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Vpc_Peering_Direction_Min_Fields = {
  __typename?: 'vpc_peering_direction_min_fields';
  comment?: Maybe<Scalars['String']>;
  direction?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "vpc_peering_direction" */
export type Vpc_Peering_Direction_Mutation_Response = {
  __typename?: 'vpc_peering_direction_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vpc_Peering_Direction>;
};

/** on_conflict condition type for table "vpc_peering_direction" */
export type Vpc_Peering_Direction_On_Conflict = {
  constraint: Vpc_Peering_Direction_Constraint;
  update_columns?: Array<Vpc_Peering_Direction_Update_Column>;
  where?: InputMaybe<Vpc_Peering_Direction_Bool_Exp>;
};

/** Ordering options when selecting data from "vpc_peering_direction". */
export type Vpc_Peering_Direction_Order_By = {
  comment?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vpc_peering_direction */
export type Vpc_Peering_Direction_Pk_Columns_Input = {
  direction: Scalars['String'];
};

/** select columns of table "vpc_peering_direction" */
export enum Vpc_Peering_Direction_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Direction = 'direction',
}

/** input type for updating data in table "vpc_peering_direction" */
export type Vpc_Peering_Direction_Set_Input = {
  comment?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "vpc_peering_direction" */
export type Vpc_Peering_Direction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vpc_Peering_Direction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vpc_Peering_Direction_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']>;
  direction?: InputMaybe<Scalars['String']>;
};

/** update columns of table "vpc_peering_direction" */
export enum Vpc_Peering_Direction_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Direction = 'direction',
}

export type Vpc_Peering_Direction_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vpc_Peering_Direction_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vpc_Peering_Direction_Bool_Exp;
};

/** input type for inserting data into table "vpc_peering" */
export type Vpc_Peering_Insert_Input = {
  aws_customer_account_id?: InputMaybe<Scalars['String']>;
  aws_customer_vpc_additional_cidrs?: InputMaybe<Array<Scalars['String']>>;
  aws_customer_vpc_cidr?: InputMaybe<Scalars['String']>;
  aws_customer_vpc_id?: InputMaybe<Scalars['String']>;
  aws_customer_vpc_region?: InputMaybe<Scalars['String']>;
  azure_input?: InputMaybe<Scalars['jsonb']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Obj_Rel_Insert_Input>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  destroy_requested?: InputMaybe<Scalars['Boolean']>;
  direction?: InputMaybe<Vpc_Peering_Direction_Enum>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  gcp_project_id?: InputMaybe<Scalars['String']>;
  gcp_vpc_network?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  provisioning?: InputMaybe<Provisioning_Enum>;
  /** update the value manually by referring to the infra status field */
  status?: InputMaybe<Vpc_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Vpc_Peering_Max_Fields = {
  __typename?: 'vpc_peering_max_fields';
  aws_customer_account_id?: Maybe<Scalars['String']>;
  aws_customer_vpc_additional_cidrs?: Maybe<Array<Scalars['String']>>;
  aws_customer_vpc_cidr?: Maybe<Scalars['String']>;
  aws_customer_vpc_id?: Maybe<Scalars['String']>;
  aws_customer_vpc_region?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  gcp_project_id?: Maybe<Scalars['String']>;
  gcp_vpc_network?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "vpc_peering" */
export type Vpc_Peering_Max_Order_By = {
  aws_customer_account_id?: InputMaybe<Order_By>;
  aws_customer_vpc_additional_cidrs?: InputMaybe<Order_By>;
  aws_customer_vpc_cidr?: InputMaybe<Order_By>;
  aws_customer_vpc_id?: InputMaybe<Order_By>;
  aws_customer_vpc_region?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  gcp_project_id?: InputMaybe<Order_By>;
  gcp_vpc_network?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Vpc_Peering_Min_Fields = {
  __typename?: 'vpc_peering_min_fields';
  aws_customer_account_id?: Maybe<Scalars['String']>;
  aws_customer_vpc_additional_cidrs?: Maybe<Array<Scalars['String']>>;
  aws_customer_vpc_cidr?: Maybe<Scalars['String']>;
  aws_customer_vpc_id?: Maybe<Scalars['String']>;
  aws_customer_vpc_region?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: Maybe<Scalars['uuid']>;
  gcp_project_id?: Maybe<Scalars['String']>;
  gcp_vpc_network?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "vpc_peering" */
export type Vpc_Peering_Min_Order_By = {
  aws_customer_account_id?: InputMaybe<Order_By>;
  aws_customer_vpc_additional_cidrs?: InputMaybe<Order_By>;
  aws_customer_vpc_cidr?: InputMaybe<Order_By>;
  aws_customer_vpc_id?: InputMaybe<Order_By>;
  aws_customer_vpc_region?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  gcp_project_id?: InputMaybe<Order_By>;
  gcp_vpc_network?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vpc_peering" */
export type Vpc_Peering_Mutation_Response = {
  __typename?: 'vpc_peering_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vpc_Peering>;
};

/** on_conflict condition type for table "vpc_peering" */
export type Vpc_Peering_On_Conflict = {
  constraint: Vpc_Peering_Constraint;
  update_columns?: Array<Vpc_Peering_Update_Column>;
  where?: InputMaybe<Vpc_Peering_Bool_Exp>;
};

/** Ordering options when selecting data from "vpc_peering". */
export type Vpc_Peering_Order_By = {
  aws_customer_account_id?: InputMaybe<Order_By>;
  aws_customer_vpc_additional_cidrs?: InputMaybe<Order_By>;
  aws_customer_vpc_cidr?: InputMaybe<Order_By>;
  aws_customer_vpc_id?: InputMaybe<Order_By>;
  aws_customer_vpc_region?: InputMaybe<Order_By>;
  azure_input?: InputMaybe<Order_By>;
  comments?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  dedicated_vpc?: InputMaybe<Dedicated_Vpc_Order_By>;
  dedicated_vpc_id?: InputMaybe<Order_By>;
  destroy_requested?: InputMaybe<Order_By>;
  direction?: InputMaybe<Order_By>;
  disable_infra_auto_apply?: InputMaybe<Order_By>;
  gcp_project_id?: InputMaybe<Order_By>;
  gcp_vpc_network?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  infra_status?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  output_variables?: InputMaybe<Order_By>;
  provisioning?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vpc_peering */
export type Vpc_Peering_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Vpc_Peering_Prepend_Input = {
  azure_input?: InputMaybe<Scalars['jsonb']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "vpc_peering" */
export enum Vpc_Peering_Select_Column {
  /** column name */
  AwsCustomerAccountId = 'aws_customer_account_id',
  /** column name */
  AwsCustomerVpcAdditionalCidrs = 'aws_customer_vpc_additional_cidrs',
  /** column name */
  AwsCustomerVpcCidr = 'aws_customer_vpc_cidr',
  /** column name */
  AwsCustomerVpcId = 'aws_customer_vpc_id',
  /** column name */
  AwsCustomerVpcRegion = 'aws_customer_vpc_region',
  /** column name */
  AzureInput = 'azure_input',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DedicatedVpcId = 'dedicated_vpc_id',
  /** column name */
  DestroyRequested = 'destroy_requested',
  /** column name */
  Direction = 'direction',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  GcpProjectId = 'gcp_project_id',
  /** column name */
  GcpVpcNetwork = 'gcp_vpc_network',
  /** column name */
  Id = 'id',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  Provisioning = 'provisioning',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "vpc_peering_aggregate_bool_exp_bool_and_arguments_columns" columns of table "vpc_peering" */
export enum Vpc_Peering_Select_Column_Vpc_Peering_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  DestroyRequested = 'destroy_requested',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
}

/** select "vpc_peering_aggregate_bool_exp_bool_or_arguments_columns" columns of table "vpc_peering" */
export enum Vpc_Peering_Select_Column_Vpc_Peering_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  DestroyRequested = 'destroy_requested',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
}

/** input type for updating data in table "vpc_peering" */
export type Vpc_Peering_Set_Input = {
  aws_customer_account_id?: InputMaybe<Scalars['String']>;
  aws_customer_vpc_additional_cidrs?: InputMaybe<Array<Scalars['String']>>;
  aws_customer_vpc_cidr?: InputMaybe<Scalars['String']>;
  aws_customer_vpc_id?: InputMaybe<Scalars['String']>;
  aws_customer_vpc_region?: InputMaybe<Scalars['String']>;
  azure_input?: InputMaybe<Scalars['jsonb']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  destroy_requested?: InputMaybe<Scalars['Boolean']>;
  direction?: InputMaybe<Vpc_Peering_Direction_Enum>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  gcp_project_id?: InputMaybe<Scalars['String']>;
  gcp_vpc_network?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  provisioning?: InputMaybe<Provisioning_Enum>;
  /** update the value manually by referring to the infra status field */
  status?: InputMaybe<Vpc_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "vpc_peering" */
export type Vpc_Peering_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vpc_Peering_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vpc_Peering_Stream_Cursor_Value_Input = {
  aws_customer_account_id?: InputMaybe<Scalars['String']>;
  aws_customer_vpc_additional_cidrs?: InputMaybe<Array<Scalars['String']>>;
  aws_customer_vpc_cidr?: InputMaybe<Scalars['String']>;
  aws_customer_vpc_id?: InputMaybe<Scalars['String']>;
  aws_customer_vpc_region?: InputMaybe<Scalars['String']>;
  azure_input?: InputMaybe<Scalars['jsonb']>;
  comments?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  dedicated_vpc_id?: InputMaybe<Scalars['uuid']>;
  destroy_requested?: InputMaybe<Scalars['Boolean']>;
  direction?: InputMaybe<Vpc_Peering_Direction_Enum>;
  disable_infra_auto_apply?: InputMaybe<Scalars['Boolean']>;
  gcp_project_id?: InputMaybe<Scalars['String']>;
  gcp_vpc_network?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  infra_status?: InputMaybe<Infra_Status_Enum>;
  message?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  output_variables?: InputMaybe<Scalars['jsonb']>;
  provisioning?: InputMaybe<Provisioning_Enum>;
  /** update the value manually by referring to the infra status field */
  status?: InputMaybe<Vpc_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "vpc_peering" */
export enum Vpc_Peering_Update_Column {
  /** column name */
  AwsCustomerAccountId = 'aws_customer_account_id',
  /** column name */
  AwsCustomerVpcAdditionalCidrs = 'aws_customer_vpc_additional_cidrs',
  /** column name */
  AwsCustomerVpcCidr = 'aws_customer_vpc_cidr',
  /** column name */
  AwsCustomerVpcId = 'aws_customer_vpc_id',
  /** column name */
  AwsCustomerVpcRegion = 'aws_customer_vpc_region',
  /** column name */
  AzureInput = 'azure_input',
  /** column name */
  Comments = 'comments',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DedicatedVpcId = 'dedicated_vpc_id',
  /** column name */
  DestroyRequested = 'destroy_requested',
  /** column name */
  Direction = 'direction',
  /** column name */
  DisableInfraAutoApply = 'disable_infra_auto_apply',
  /** column name */
  GcpProjectId = 'gcp_project_id',
  /** column name */
  GcpVpcNetwork = 'gcp_vpc_network',
  /** column name */
  Id = 'id',
  /** column name */
  InfraStatus = 'infra_status',
  /** column name */
  Message = 'message',
  /** column name */
  Name = 'name',
  /** column name */
  OutputVariables = 'output_variables',
  /** column name */
  Provisioning = 'provisioning',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

export type Vpc_Peering_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Vpc_Peering_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Vpc_Peering_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Vpc_Peering_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Vpc_Peering_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Vpc_Peering_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vpc_Peering_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vpc_Peering_Bool_Exp;
};

/** Available privileges (e.g: viewer/manager) for VPC collaborators */
export type Vpc_Privileges = {
  __typename?: 'vpc_privileges';
  description?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

/** aggregated selection of "vpc_privileges" */
export type Vpc_Privileges_Aggregate = {
  __typename?: 'vpc_privileges_aggregate';
  aggregate?: Maybe<Vpc_Privileges_Aggregate_Fields>;
  nodes: Array<Vpc_Privileges>;
};

/** aggregate fields of "vpc_privileges" */
export type Vpc_Privileges_Aggregate_Fields = {
  __typename?: 'vpc_privileges_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vpc_Privileges_Max_Fields>;
  min?: Maybe<Vpc_Privileges_Min_Fields>;
};

/** aggregate fields of "vpc_privileges" */
export type Vpc_Privileges_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vpc_Privileges_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "vpc_privileges". All fields are combined with a logical 'AND'. */
export type Vpc_Privileges_Bool_Exp = {
  _and?: InputMaybe<Array<Vpc_Privileges_Bool_Exp>>;
  _not?: InputMaybe<Vpc_Privileges_Bool_Exp>;
  _or?: InputMaybe<Array<Vpc_Privileges_Bool_Exp>>;
  description?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "vpc_privileges" */
export enum Vpc_Privileges_Constraint {
  /** unique or primary key constraint on columns "slug" */
  VpcPrivilegesPkey = 'vpc_privileges_pkey',
}

/** input type for inserting data into table "vpc_privileges" */
export type Vpc_Privileges_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Vpc_Privileges_Max_Fields = {
  __typename?: 'vpc_privileges_max_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Vpc_Privileges_Min_Fields = {
  __typename?: 'vpc_privileges_min_fields';
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "vpc_privileges" */
export type Vpc_Privileges_Mutation_Response = {
  __typename?: 'vpc_privileges_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vpc_Privileges>;
};

/** on_conflict condition type for table "vpc_privileges" */
export type Vpc_Privileges_On_Conflict = {
  constraint: Vpc_Privileges_Constraint;
  update_columns?: Array<Vpc_Privileges_Update_Column>;
  where?: InputMaybe<Vpc_Privileges_Bool_Exp>;
};

/** Ordering options when selecting data from "vpc_privileges". */
export type Vpc_Privileges_Order_By = {
  description?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vpc_privileges */
export type Vpc_Privileges_Pk_Columns_Input = {
  slug: Scalars['String'];
};

/** select columns of table "vpc_privileges" */
export enum Vpc_Privileges_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
}

/** input type for updating data in table "vpc_privileges" */
export type Vpc_Privileges_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "vpc_privileges" */
export type Vpc_Privileges_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vpc_Privileges_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vpc_Privileges_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
};

/** update columns of table "vpc_privileges" */
export enum Vpc_Privileges_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
}

export type Vpc_Privileges_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vpc_Privileges_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vpc_Privileges_Bool_Exp;
};

/** Hasura enum to capture supported status for vpc. This enum is used in dedicated_vpc */
export type Vpc_Status = {
  __typename?: 'vpc_status';
  status: Scalars['String'];
};

/** aggregated selection of "vpc_status" */
export type Vpc_Status_Aggregate = {
  __typename?: 'vpc_status_aggregate';
  aggregate?: Maybe<Vpc_Status_Aggregate_Fields>;
  nodes: Array<Vpc_Status>;
};

/** aggregate fields of "vpc_status" */
export type Vpc_Status_Aggregate_Fields = {
  __typename?: 'vpc_status_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Vpc_Status_Max_Fields>;
  min?: Maybe<Vpc_Status_Min_Fields>;
};

/** aggregate fields of "vpc_status" */
export type Vpc_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Vpc_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "vpc_status". All fields are combined with a logical 'AND'. */
export type Vpc_Status_Bool_Exp = {
  _and?: InputMaybe<Array<Vpc_Status_Bool_Exp>>;
  _not?: InputMaybe<Vpc_Status_Bool_Exp>;
  _or?: InputMaybe<Array<Vpc_Status_Bool_Exp>>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "vpc_status" */
export enum Vpc_Status_Constraint {
  /** unique or primary key constraint on columns "status" */
  VpcStatusPkey = 'vpc_status_pkey',
}

export enum Vpc_Status_Enum {
  Active = 'active',
  Destroyed = 'destroyed',
  Failed = 'failed',
  Pending = 'pending',
  PendingAcceptance = 'pending_acceptance',
  PendingDestroy = 'pending_destroy',
  Provisioning = 'provisioning',
}

/** Boolean expression to compare columns of type "vpc_status_enum". All fields are combined with logical 'AND'. */
export type Vpc_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vpc_Status_Enum>;
  _in?: InputMaybe<Array<Vpc_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Vpc_Status_Enum>;
  _nin?: InputMaybe<Array<Vpc_Status_Enum>>;
};

/** input type for inserting data into table "vpc_status" */
export type Vpc_Status_Insert_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Vpc_Status_Max_Fields = {
  __typename?: 'vpc_status_max_fields';
  status?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Vpc_Status_Min_Fields = {
  __typename?: 'vpc_status_min_fields';
  status?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "vpc_status" */
export type Vpc_Status_Mutation_Response = {
  __typename?: 'vpc_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Vpc_Status>;
};

/** on_conflict condition type for table "vpc_status" */
export type Vpc_Status_On_Conflict = {
  constraint: Vpc_Status_Constraint;
  update_columns?: Array<Vpc_Status_Update_Column>;
  where?: InputMaybe<Vpc_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "vpc_status". */
export type Vpc_Status_Order_By = {
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vpc_status */
export type Vpc_Status_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "vpc_status" */
export enum Vpc_Status_Select_Column {
  /** column name */
  Status = 'status',
}

/** input type for updating data in table "vpc_status" */
export type Vpc_Status_Set_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "vpc_status" */
export type Vpc_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vpc_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vpc_Status_Stream_Cursor_Value_Input = {
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "vpc_status" */
export enum Vpc_Status_Update_Column {
  /** column name */
  Status = 'status',
}

export type Vpc_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vpc_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vpc_Status_Bool_Exp;
};

/** columns and relationships of "zendesk_support_category" */
export type Zendesk_Support_Category = {
  __typename?: 'zendesk_support_category';
  name: Scalars['String'];
  zendesk_group_id: Scalars['bigint'];
};

/** aggregated selection of "zendesk_support_category" */
export type Zendesk_Support_Category_Aggregate = {
  __typename?: 'zendesk_support_category_aggregate';
  aggregate?: Maybe<Zendesk_Support_Category_Aggregate_Fields>;
  nodes: Array<Zendesk_Support_Category>;
};

/** aggregate fields of "zendesk_support_category" */
export type Zendesk_Support_Category_Aggregate_Fields = {
  __typename?: 'zendesk_support_category_aggregate_fields';
  avg?: Maybe<Zendesk_Support_Category_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zendesk_Support_Category_Max_Fields>;
  min?: Maybe<Zendesk_Support_Category_Min_Fields>;
  stddev?: Maybe<Zendesk_Support_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Zendesk_Support_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zendesk_Support_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Zendesk_Support_Category_Sum_Fields>;
  var_pop?: Maybe<Zendesk_Support_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Zendesk_Support_Category_Var_Samp_Fields>;
  variance?: Maybe<Zendesk_Support_Category_Variance_Fields>;
};

/** aggregate fields of "zendesk_support_category" */
export type Zendesk_Support_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zendesk_Support_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zendesk_Support_Category_Avg_Fields = {
  __typename?: 'zendesk_support_category_avg_fields';
  zendesk_group_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zendesk_support_category". All fields are combined with a logical 'AND'. */
export type Zendesk_Support_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Zendesk_Support_Category_Bool_Exp>>;
  _not?: InputMaybe<Zendesk_Support_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Zendesk_Support_Category_Bool_Exp>>;
  name?: InputMaybe<String_Comparison_Exp>;
  zendesk_group_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "zendesk_support_category" */
export enum Zendesk_Support_Category_Constraint {
  /** unique or primary key constraint on columns "name" */
  ZendeskSupportCategoryPkey = 'zendesk_support_category_pkey',
}

/** input type for incrementing numeric columns in table "zendesk_support_category" */
export type Zendesk_Support_Category_Inc_Input = {
  zendesk_group_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "zendesk_support_category" */
export type Zendesk_Support_Category_Insert_Input = {
  name?: InputMaybe<Scalars['String']>;
  zendesk_group_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Zendesk_Support_Category_Max_Fields = {
  __typename?: 'zendesk_support_category_max_fields';
  name?: Maybe<Scalars['String']>;
  zendesk_group_id?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type Zendesk_Support_Category_Min_Fields = {
  __typename?: 'zendesk_support_category_min_fields';
  name?: Maybe<Scalars['String']>;
  zendesk_group_id?: Maybe<Scalars['bigint']>;
};

/** response of any mutation on the table "zendesk_support_category" */
export type Zendesk_Support_Category_Mutation_Response = {
  __typename?: 'zendesk_support_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zendesk_Support_Category>;
};

/** on_conflict condition type for table "zendesk_support_category" */
export type Zendesk_Support_Category_On_Conflict = {
  constraint: Zendesk_Support_Category_Constraint;
  update_columns?: Array<Zendesk_Support_Category_Update_Column>;
  where?: InputMaybe<Zendesk_Support_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "zendesk_support_category". */
export type Zendesk_Support_Category_Order_By = {
  name?: InputMaybe<Order_By>;
  zendesk_group_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zendesk_support_category */
export type Zendesk_Support_Category_Pk_Columns_Input = {
  name: Scalars['String'];
};

/** select columns of table "zendesk_support_category" */
export enum Zendesk_Support_Category_Select_Column {
  /** column name */
  Name = 'name',
  /** column name */
  ZendeskGroupId = 'zendesk_group_id',
}

/** input type for updating data in table "zendesk_support_category" */
export type Zendesk_Support_Category_Set_Input = {
  name?: InputMaybe<Scalars['String']>;
  zendesk_group_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Zendesk_Support_Category_Stddev_Fields = {
  __typename?: 'zendesk_support_category_stddev_fields';
  zendesk_group_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zendesk_Support_Category_Stddev_Pop_Fields = {
  __typename?: 'zendesk_support_category_stddev_pop_fields';
  zendesk_group_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zendesk_Support_Category_Stddev_Samp_Fields = {
  __typename?: 'zendesk_support_category_stddev_samp_fields';
  zendesk_group_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zendesk_support_category" */
export type Zendesk_Support_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zendesk_Support_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zendesk_Support_Category_Stream_Cursor_Value_Input = {
  name?: InputMaybe<Scalars['String']>;
  zendesk_group_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type Zendesk_Support_Category_Sum_Fields = {
  __typename?: 'zendesk_support_category_sum_fields';
  zendesk_group_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "zendesk_support_category" */
export enum Zendesk_Support_Category_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  ZendeskGroupId = 'zendesk_group_id',
}

export type Zendesk_Support_Category_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zendesk_Support_Category_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zendesk_Support_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zendesk_Support_Category_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zendesk_Support_Category_Var_Pop_Fields = {
  __typename?: 'zendesk_support_category_var_pop_fields';
  zendesk_group_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zendesk_Support_Category_Var_Samp_Fields = {
  __typename?: 'zendesk_support_category_var_samp_fields';
  zendesk_group_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zendesk_Support_Category_Variance_Fields = {
  __typename?: 'zendesk_support_category_variance_fields';
  zendesk_group_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "zendesk_support_tickets" */
export type Zendesk_Support_Tickets = {
  __typename?: 'zendesk_support_tickets';
  category_name: Scalars['String'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  project?: Maybe<Projects>;
  project_id?: Maybe<Scalars['uuid']>;
  ticket_id: Scalars['bigint'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "zendesk_support_tickets" */
export type Zendesk_Support_Tickets_Aggregate = {
  __typename?: 'zendesk_support_tickets_aggregate';
  aggregate?: Maybe<Zendesk_Support_Tickets_Aggregate_Fields>;
  nodes: Array<Zendesk_Support_Tickets>;
};

/** aggregate fields of "zendesk_support_tickets" */
export type Zendesk_Support_Tickets_Aggregate_Fields = {
  __typename?: 'zendesk_support_tickets_aggregate_fields';
  avg?: Maybe<Zendesk_Support_Tickets_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Zendesk_Support_Tickets_Max_Fields>;
  min?: Maybe<Zendesk_Support_Tickets_Min_Fields>;
  stddev?: Maybe<Zendesk_Support_Tickets_Stddev_Fields>;
  stddev_pop?: Maybe<Zendesk_Support_Tickets_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Zendesk_Support_Tickets_Stddev_Samp_Fields>;
  sum?: Maybe<Zendesk_Support_Tickets_Sum_Fields>;
  var_pop?: Maybe<Zendesk_Support_Tickets_Var_Pop_Fields>;
  var_samp?: Maybe<Zendesk_Support_Tickets_Var_Samp_Fields>;
  variance?: Maybe<Zendesk_Support_Tickets_Variance_Fields>;
};

/** aggregate fields of "zendesk_support_tickets" */
export type Zendesk_Support_Tickets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Zendesk_Support_Tickets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Zendesk_Support_Tickets_Avg_Fields = {
  __typename?: 'zendesk_support_tickets_avg_fields';
  ticket_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "zendesk_support_tickets". All fields are combined with a logical 'AND'. */
export type Zendesk_Support_Tickets_Bool_Exp = {
  _and?: InputMaybe<Array<Zendesk_Support_Tickets_Bool_Exp>>;
  _not?: InputMaybe<Zendesk_Support_Tickets_Bool_Exp>;
  _or?: InputMaybe<Array<Zendesk_Support_Tickets_Bool_Exp>>;
  category_name?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  project?: InputMaybe<Projects_Bool_Exp>;
  project_id?: InputMaybe<Uuid_Comparison_Exp>;
  ticket_id?: InputMaybe<Bigint_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "zendesk_support_tickets" */
export enum Zendesk_Support_Tickets_Constraint {
  /** unique or primary key constraint on columns "id" */
  ZendeskSupportTicketsPkey = 'zendesk_support_tickets_pkey',
}

/** input type for incrementing numeric columns in table "zendesk_support_tickets" */
export type Zendesk_Support_Tickets_Inc_Input = {
  ticket_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "zendesk_support_tickets" */
export type Zendesk_Support_Tickets_Insert_Input = {
  category_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project?: InputMaybe<Projects_Obj_Rel_Insert_Input>;
  project_id?: InputMaybe<Scalars['uuid']>;
  ticket_id?: InputMaybe<Scalars['bigint']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Zendesk_Support_Tickets_Max_Fields = {
  __typename?: 'zendesk_support_tickets_max_fields';
  category_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  ticket_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Zendesk_Support_Tickets_Min_Fields = {
  __typename?: 'zendesk_support_tickets_min_fields';
  category_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  project_id?: Maybe<Scalars['uuid']>;
  ticket_id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "zendesk_support_tickets" */
export type Zendesk_Support_Tickets_Mutation_Response = {
  __typename?: 'zendesk_support_tickets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Zendesk_Support_Tickets>;
};

/** on_conflict condition type for table "zendesk_support_tickets" */
export type Zendesk_Support_Tickets_On_Conflict = {
  constraint: Zendesk_Support_Tickets_Constraint;
  update_columns?: Array<Zendesk_Support_Tickets_Update_Column>;
  where?: InputMaybe<Zendesk_Support_Tickets_Bool_Exp>;
};

/** Ordering options when selecting data from "zendesk_support_tickets". */
export type Zendesk_Support_Tickets_Order_By = {
  category_name?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  project?: InputMaybe<Projects_Order_By>;
  project_id?: InputMaybe<Order_By>;
  ticket_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: zendesk_support_tickets */
export type Zendesk_Support_Tickets_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "zendesk_support_tickets" */
export enum Zendesk_Support_Tickets_Select_Column {
  /** column name */
  CategoryName = 'category_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "zendesk_support_tickets" */
export type Zendesk_Support_Tickets_Set_Input = {
  category_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  ticket_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Zendesk_Support_Tickets_Stddev_Fields = {
  __typename?: 'zendesk_support_tickets_stddev_fields';
  ticket_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Zendesk_Support_Tickets_Stddev_Pop_Fields = {
  __typename?: 'zendesk_support_tickets_stddev_pop_fields';
  ticket_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Zendesk_Support_Tickets_Stddev_Samp_Fields = {
  __typename?: 'zendesk_support_tickets_stddev_samp_fields';
  ticket_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "zendesk_support_tickets" */
export type Zendesk_Support_Tickets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Zendesk_Support_Tickets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Zendesk_Support_Tickets_Stream_Cursor_Value_Input = {
  category_name?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  project_id?: InputMaybe<Scalars['uuid']>;
  ticket_id?: InputMaybe<Scalars['bigint']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Zendesk_Support_Tickets_Sum_Fields = {
  __typename?: 'zendesk_support_tickets_sum_fields';
  ticket_id?: Maybe<Scalars['bigint']>;
};

/** update columns of table "zendesk_support_tickets" */
export enum Zendesk_Support_Tickets_Update_Column {
  /** column name */
  CategoryName = 'category_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  TicketId = 'ticket_id',
  /** column name */
  UserId = 'user_id',
}

export type Zendesk_Support_Tickets_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Zendesk_Support_Tickets_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Zendesk_Support_Tickets_Set_Input>;
  /** filter the rows which have to be updated */
  where: Zendesk_Support_Tickets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Zendesk_Support_Tickets_Var_Pop_Fields = {
  __typename?: 'zendesk_support_tickets_var_pop_fields';
  ticket_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Zendesk_Support_Tickets_Var_Samp_Fields = {
  __typename?: 'zendesk_support_tickets_var_samp_fields';
  ticket_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Zendesk_Support_Tickets_Variance_Fields = {
  __typename?: 'zendesk_support_tickets_variance_fields';
  ticket_id?: Maybe<Scalars['Float']>;
};

export type VpcPeeringFieldsFragment = {
  __typename?: 'vpc_peering';
  name: string;
  status: Vpc_Status_Enum;
  id: any;
  direction: Vpc_Peering_Direction_Enum;
  aws_customer_vpc_id?: string | null | undefined;
  gcp_project_id?: string | null | undefined;
  gcp_vpc_network?: string | null | undefined;
  output_variables?: any | null | undefined;
  azure_input?: any | null | undefined;
};

export type RegionFieldsFragment = {
  __typename?: 'region';
  name: string;
  nat_ip?: string | null | undefined;
  nat_ips?: any | null | undefined;
  cloud: Cloud_Enum;
};

export type DedicateVpcFieldsFragment = {
  __typename?: 'dedicated_vpc';
  id: any;
  plan_name: string;
  cloud: string;
  created_at: any;
  owner_id: any;
  region: string;
  name: string;
  status: Vpc_Status_Enum;
  gcp_network_name?: string | null | undefined;
  nat_ip?: string | null | undefined;
  cidr?: string | null | undefined;
  nat_ips?: any | null | undefined;
  vpc_id?: string | null | undefined;
  vpc_cidr?: string | null | undefined;
  owner: { __typename?: 'users'; id: any; email: string };
  collaborators: Array<{
    __typename?: 'vpc_collaborators';
    collaborator_id: any;
    privilege_slug: string;
    status: string;
    collaborator?:
      | { __typename?: 'users_public'; email?: string | null | undefined }
      | null
      | undefined;
  }>;
  collaborator_invitations: Array<{
    __typename?: 'vpc_collaborator_invitations';
    status: string;
    privilege_slug: string;
    collaborator_email: any;
    id: any;
  }>;
  region_info?:
    | {
        __typename?: 'region';
        name: string;
        nat_ip?: string | null | undefined;
        nat_ips?: any | null | undefined;
        cloud: Cloud_Enum;
      }
    | null
    | undefined;
};

export type TenantFieldsFragment = {
  __typename?: 'tenant';
  id: any;
  created_at: any;
  updated_at: any;
  health_status?: any | null | undefined;
  major_version: number;
  active: boolean;
  active_status_reason?: string | null | undefined;
  dedicated_vpc_id?: any | null | undefined;
  maintenance_mode: boolean;
  region_info?:
    | {
        __typename?: 'region';
        name: string;
        nat_ip?: string | null | undefined;
        nat_ips?: any | null | undefined;
        cloud: Cloud_Enum;
      }
    | null
    | undefined;
  config?:
    | {
        __typename?: 'config';
        updated_at: any;
        status: Array<{
          __typename?: 'config_status';
          is_active: boolean;
          message?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
  custom_domains: Array<{
    __typename?: 'custom_domain';
    id: any;
    fqdn: string;
  }>;
  tenant_public_status: Array<{
    __typename?: 'tenant_public_status';
    endpoint: string;
    type: string;
    is_active: boolean;
    message?: string | null | undefined;
  }>;
  dedicated_vpc?:
    | {
        __typename?: 'dedicated_vpc';
        id: any;
        plan_name: string;
        cloud: string;
        created_at: any;
        owner_id: any;
        region: string;
        name: string;
        status: Vpc_Status_Enum;
        gcp_network_name?: string | null | undefined;
        nat_ip?: string | null | undefined;
        cidr?: string | null | undefined;
        nat_ips?: any | null | undefined;
        vpc_id?: string | null | undefined;
        vpc_cidr?: string | null | undefined;
        owner: { __typename?: 'users'; id: any; email: string };
        collaborators: Array<{
          __typename?: 'vpc_collaborators';
          collaborator_id: any;
          privilege_slug: string;
          status: string;
          collaborator?:
            | { __typename?: 'users_public'; email?: string | null | undefined }
            | null
            | undefined;
        }>;
        collaborator_invitations: Array<{
          __typename?: 'vpc_collaborator_invitations';
          status: string;
          privilege_slug: string;
          collaborator_email: any;
          id: any;
        }>;
        region_info?:
          | {
              __typename?: 'region';
              name: string;
              nat_ip?: string | null | undefined;
              nat_ips?: any | null | undefined;
              cloud: Cloud_Enum;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type LabelFieldsFragment = {
  __typename?: 'label';
  id: any;
  name: string;
  color?: string | null | undefined;
  created_by: any;
};

export type ProjectFieldsFragment = {
  __typename?: 'projects';
  id: any;
  name: string;
  updated_at: any;
  plan_name?: string | null | undefined;
  invoice_status?: string | null | undefined;
  invoice_attempt_count?: number | null | undefined;
  endpoint: string;
  created_at: any;
  assigned_at?: any | null | undefined;
  current_month_data_usage?: any | null | undefined;
  number_of_requests_this_month?: any | null | undefined;
  owner?:
    | {
        __typename?: 'users_public';
        id?: any | null | undefined;
        email?: string | null | undefined;
        enterprise_user?:
          | {
              __typename?: 'enterprise_users';
              is_active: boolean;
              is_exempt_from_billing: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  tenant?:
    | {
        __typename?: 'tenant';
        id: any;
        created_at: any;
        updated_at: any;
        health_status?: any | null | undefined;
        major_version: number;
        active: boolean;
        active_status_reason?: string | null | undefined;
        dedicated_vpc_id?: any | null | undefined;
        maintenance_mode: boolean;
        region_info?:
          | {
              __typename?: 'region';
              name: string;
              nat_ip?: string | null | undefined;
              nat_ips?: any | null | undefined;
              cloud: Cloud_Enum;
            }
          | null
          | undefined;
        config?:
          | {
              __typename?: 'config';
              updated_at: any;
              status: Array<{
                __typename?: 'config_status';
                is_active: boolean;
                message?: string | null | undefined;
              }>;
            }
          | null
          | undefined;
        custom_domains: Array<{
          __typename?: 'custom_domain';
          id: any;
          fqdn: string;
        }>;
        tenant_public_status: Array<{
          __typename?: 'tenant_public_status';
          endpoint: string;
          type: string;
          is_active: boolean;
          message?: string | null | undefined;
        }>;
        dedicated_vpc?:
          | {
              __typename?: 'dedicated_vpc';
              id: any;
              plan_name: string;
              cloud: string;
              created_at: any;
              owner_id: any;
              region: string;
              name: string;
              status: Vpc_Status_Enum;
              gcp_network_name?: string | null | undefined;
              nat_ip?: string | null | undefined;
              cidr?: string | null | undefined;
              nat_ips?: any | null | undefined;
              vpc_id?: string | null | undefined;
              vpc_cidr?: string | null | undefined;
              owner: { __typename?: 'users'; id: any; email: string };
              collaborators: Array<{
                __typename?: 'vpc_collaborators';
                collaborator_id: any;
                privilege_slug: string;
                status: string;
                collaborator?:
                  | {
                      __typename?: 'users_public';
                      email?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }>;
              collaborator_invitations: Array<{
                __typename?: 'vpc_collaborator_invitations';
                status: string;
                privilege_slug: string;
                collaborator_email: any;
                id: any;
              }>;
              region_info?:
                | {
                    __typename?: 'region';
                    name: string;
                    nat_ip?: string | null | undefined;
                    nat_ips?: any | null | undefined;
                    cloud: Cloud_Enum;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  metadata?:
    | {
        __typename?: 'project_metadata';
        connected_heroku_app_name?: string | null | undefined;
        heroku_managed_mode: boolean;
        environment_name?: string | null | undefined;
        environment_color?: string | null | undefined;
      }
    | null
    | undefined;
  collaborators: Array<{
    __typename?: 'project_collaborators';
    id: any;
    status: string;
    collaborator_id: any;
    project_collaborator_privileges: Array<{
      __typename?: 'project_collaborator_privileges';
      privilege_slug: string;
    }>;
  }>;
  ownership_transfer_invites: Array<{
    __typename?: 'project_ownership_transfer_invitations';
    invitee_email: string;
    key: string;
  }>;
  datadog_config?:
    | { __typename?: 'datadog_config'; project_id: any }
    | null
    | undefined;
  newrelic_config?:
    | { __typename?: 'newrelic_config'; project_id: any }
    | null
    | undefined;
  azuremonitor_config?:
    | { __typename?: 'azuremonitor_config'; project_id: any }
    | null
    | undefined;
  labels: Array<{
    __typename?: 'project_labels';
    label: {
      __typename?: 'label';
      id: any;
      name: string;
      color?: string | null | undefined;
      created_by: any;
    };
  }>;
  heroku_integrations: Array<{
    __typename?: 'heroku_integrations';
    app_name: string;
    var_name: string;
  }>;
  billing_manager?:
    | {
        __typename?: 'users_public';
        email?: string | null | undefined;
        id?: any | null | undefined;
      }
    | null
    | undefined;
  project_usages: Array<{ __typename?: 'project_data_usage'; usage: any }>;
  jobs: Array<{
    __typename?: 'jobs';
    id: any;
    status: string;
    job_type: string;
  }>;
  vercel_integration_connections: Array<{
    __typename?: 'vercel_integration_connections';
    id: any;
    status: string;
  }>;
  entitlements: Array<{
    __typename?: 'project_entitlement_access';
    entitlement_id: any;
    id: any;
    entitlement: {
      __typename?: 'project_entitlement_catalogue';
      config_is_enabled: boolean;
      config_limit?: any | null | undefined;
      name: string;
      type: Project_Entitlement_Types_Enum;
      id: any;
    };
  }>;
};

export type UserFieldsFragment = {
  __typename?: 'users';
  id: any;
  email: string;
  customer_id?: string | null | undefined;
  invoice_receipt_email?: string | null | undefined;
  onboarding: Array<{
    __typename?: 'user_onboarding';
    details?: string | null | undefined;
    is_onboarded: boolean;
    target: string;
  }>;
  billing_address?:
    | {
        __typename?: 'billing_address';
        id: any;
        name: string;
        customer_id: string;
        address_line_1: string;
        address_line_2?: string | null | undefined;
        pin_code?: string | null | undefined;
        city: string;
        state: string;
        country: string;
        last_updated_to_stripe: any;
        updated_at?: any | null | undefined;
      }
    | null
    | undefined;
  feature_accesses: Array<{
    __typename?: 'feature_access';
    email: string;
    feature: Feature_Enum;
  }>;
  payment_methods: Array<{
    __typename?: 'payment_method';
    id: string;
    status?: string | null | undefined;
  }>;
  providers: Array<{ __typename?: 'providers'; user_id: any }>;
  invoices: Array<{
    __typename?: 'invoice';
    attempt_count?: number | null | undefined;
    stripe_invoice_id: string;
    invoice_url?: string | null | undefined;
    collection_method: string;
  }>;
  user_profile?:
    | {
        __typename?: 'user_profile';
        name?: string | null | undefined;
        company?: string | null | undefined;
        title?: string | null | undefined;
        email?: string | null | undefined;
        phone_number?: string | null | undefined;
      }
    | null
    | undefined;
  enterprise_users?:
    | { __typename?: 'enterprise_users'; is_active: boolean }
    | null
    | undefined;
  entitlements: Array<{
    __typename?: 'user_entitlement_access';
    entitlement: {
      __typename?: 'user_entitlement_catalogue';
      name: string;
      type: User_Entitlement_Types_Enum;
      config_is_enabled: boolean;
      config_limit?: number | null | undefined;
    };
  }>;
  projects_aggregate: {
    __typename?: 'projects_aggregate';
    aggregate?:
      | { __typename?: 'projects_aggregate_fields'; count: number }
      | null
      | undefined;
  };
};

export type ProjectsListFieldsFragment = {
  __typename?: 'projects';
  assigned_at?: any | null | undefined;
  created_at: any;
  current_month_data_usage?: any | null | undefined;
  endpoint: string;
  id: any;
  invoice_attempt_count?: number | null | undefined;
  invoice_status?: string | null | undefined;
  name: string;
  number_of_requests_this_month?: any | null | undefined;
  plan_name?: string | null | undefined;
  updated_at: any;
  billing_manager?:
    | {
        __typename?: 'users_public';
        email?: string | null | undefined;
        id?: any | null | undefined;
      }
    | null
    | undefined;
  collaborators: Array<{
    __typename?: 'project_collaborators';
    collaborator_id: any;
    id: any;
    status: string;
    project_collaborator_privileges: Array<{
      __typename?: 'project_collaborator_privileges';
      privilege_slug: string;
    }>;
  }>;
  entitlements: Array<{
    __typename?: 'project_entitlement_access';
    entitlement_id: any;
    id: any;
    entitlement: {
      __typename?: 'project_entitlement_catalogue';
      config_is_enabled: boolean;
      config_limit?: any | null | undefined;
      name: string;
      type: Project_Entitlement_Types_Enum;
      id: any;
    };
  }>;
  jobs: Array<{
    __typename?: 'jobs';
    id: any;
    job_type: string;
    status: string;
  }>;
  labels: Array<{
    __typename?: 'project_labels';
    label: {
      __typename?: 'label';
      id: any;
      name: string;
      color?: string | null | undefined;
      created_by: any;
    };
  }>;
  owner?:
    | {
        __typename?: 'users_public';
        id?: any | null | undefined;
        email?: string | null | undefined;
        enterprise_user?:
          | {
              __typename?: 'enterprise_users';
              is_active: boolean;
              is_exempt_from_billing: boolean;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  tenant?:
    | {
        __typename?: 'tenant';
        active: boolean;
        active_status_reason?: string | null | undefined;
        health_status?: any | null | undefined;
        id: any;
        major_version: number;
        maintenance_mode: boolean;
        updated_at: any;
        config?: { __typename?: 'config'; updated_at: any } | null | undefined;
        dedicated_vpc?:
          | { __typename?: 'dedicated_vpc'; name: string; id: any }
          | null
          | undefined;
        region_info?:
          | { __typename?: 'region'; name: string; cloud: Cloud_Enum }
          | null
          | undefined;
      }
    | null
    | undefined;
  vercel_integration_connections: Array<{
    __typename?: 'vercel_integration_connections';
    status: string;
  }>;
};

export type ProjectsListBaseFieldsFragment = {
  __typename?: 'projects';
  assigned_at?: any | null | undefined;
  created_at: any;
  endpoint: string;
  id: any;
  name: string;
  plan_name?: string | null | undefined;
  updated_at: any;
  billing_manager?:
    | {
        __typename?: 'users_public';
        email?: string | null | undefined;
        id?: any | null | undefined;
      }
    | null
    | undefined;
  collaborators: Array<{
    __typename?: 'project_collaborators';
    collaborator_id: any;
    id: any;
    status: string;
    project_collaborator_privileges: Array<{
      __typename?: 'project_collaborator_privileges';
      privilege_slug: string;
    }>;
  }>;
  entitlements: Array<{
    __typename?: 'project_entitlement_access';
    entitlement_id: any;
    id: any;
    entitlement: {
      __typename?: 'project_entitlement_catalogue';
      config_is_enabled: boolean;
      config_limit?: any | null | undefined;
      name: string;
      type: Project_Entitlement_Types_Enum;
      id: any;
    };
  }>;
  jobs: Array<{
    __typename?: 'jobs';
    id: any;
    job_type: string;
    status: string;
  }>;
  labels: Array<{
    __typename?: 'project_labels';
    label: {
      __typename?: 'label';
      id: any;
      name: string;
      color?: string | null | undefined;
      created_by: any;
    };
  }>;
  owner?:
    | {
        __typename?: 'users_public';
        id?: any | null | undefined;
        email?: string | null | undefined;
      }
    | null
    | undefined;
  tenant?:
    | {
        __typename?: 'tenant';
        active: boolean;
        active_status_reason?: string | null | undefined;
        health_status?: any | null | undefined;
        id: any;
        major_version: number;
        maintenance_mode: boolean;
        updated_at: any;
        config?: { __typename?: 'config'; updated_at: any } | null | undefined;
        dedicated_vpc?:
          | { __typename?: 'dedicated_vpc'; name: string; id: any }
          | null
          | undefined;
        region_info?:
          | { __typename?: 'region'; name: string; cloud: Cloud_Enum }
          | null
          | undefined;
      }
    | null
    | undefined;
  vercel_integration_connections: Array<{
    __typename?: 'vercel_integration_connections';
    status: string;
  }>;
};

export type InitiateStripeAddCardProcessMutationVariables = Exact<{
  [key: string]: never;
}>;

export type InitiateStripeAddCardProcessMutation = {
  __typename?: 'mutation_root';
  initiateStripeAddCardProcess: {
    __typename?: 'clientSecretInfo';
    client_secret: string;
  };
};

export type AddCardMutationVariables = Exact<{
  id: Scalars['String'];
  isDefault: Scalars['Boolean'];
}>;

export type AddCardMutation = {
  __typename?: 'mutation_root';
  addCard?:
    | { __typename?: 'StripeCardResponse'; status: string; id: string }
    | null
    | undefined;
};

export type DeleteCardMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeleteCardMutation = {
  __typename?: 'mutation_root';
  deleteCard?:
    | { __typename?: 'DeleteCardResponse'; msg: string }
    | null
    | undefined;
};

export type SetDefaultPaymentMethodMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type SetDefaultPaymentMethodMutation = {
  __typename?: 'mutation_root';
  setDefaultPaymentMethod?:
    | { __typename?: 'DefaultPaymentResponse'; status: string }
    | null
    | undefined;
};

export type SetBillingAddressMutationVariables = Exact<{
  object: Billing_Address_Insert_Input;
}>;

export type SetBillingAddressMutation = {
  __typename?: 'mutation_root';
  insert_billing_address_one?:
    | {
        __typename?: 'billing_address';
        id: any;
        name: string;
        customer_id: string;
        address_line_1: string;
        address_line_2?: string | null | undefined;
        pin_code?: string | null | undefined;
        city: string;
        state: string;
        country: string;
        last_updated_to_stripe: any;
        updated_at?: any | null | undefined;
      }
    | null
    | undefined;
};

export type ChangePlanMutationVariables = Exact<{
  plan: Plan;
  projectId: Scalars['uuid'];
}>;

export type ChangePlanMutation = {
  __typename?: 'mutation_root';
  changePlan?:
    | { __typename?: 'PlanResponse'; projectId: any; newPlan: Plan }
    | null
    | undefined;
};

export type ApplyCouponMutationVariables = Exact<{
  couponCode: Scalars['String'];
}>;

export type ApplyCouponMutation = {
  __typename?: 'mutation_root';
  applyCouponCode?:
    | { __typename?: 'CouponStatus'; status: string }
    | null
    | undefined;
};

export type SetBillingEmailMutationVariables = Exact<{
  email: Scalars['String'];
  userId: Scalars['uuid'];
}>;

export type SetBillingEmailMutation = {
  __typename?: 'mutation_root';
  update_users?:
    | {
        __typename?: 'users_mutation_response';
        returning: Array<{
          __typename?: 'users';
          invoice_receipt_email?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type ModifyCollaboratorPrivilegeMutationVariables = Exact<{
  insertArgs:
    | Array<Project_Collaborator_Privileges_Insert_Input>
    | Project_Collaborator_Privileges_Insert_Input;
  deleteWhere: Project_Collaborator_Privileges_Bool_Exp;
}>;

export type ModifyCollaboratorPrivilegeMutation = {
  __typename?: 'mutation_root';
  delete_project_collaborator_privileges?:
    | {
        __typename?: 'project_collaborator_privileges_mutation_response';
        affected_rows: number;
      }
    | null
    | undefined;
  insert_project_collaborator_privileges?:
    | {
        __typename?: 'project_collaborator_privileges_mutation_response';
        affected_rows: number;
      }
    | null
    | undefined;
};

export type InviteCollaboratorMutationVariables = Exact<{
  email: Scalars['String'];
  privileges: Array<Scalars['String']> | Scalars['String'];
  projectID: Scalars['uuid'];
}>;

export type InviteCollaboratorMutation = {
  __typename?: 'mutation_root';
  inviteCollaborator?:
    | {
        __typename?: 'ProjectCollaboratorInvitation';
        id: any;
        collaborator_email: string;
        project_id: any;
        key: string;
        invited_at: string;
        invited_by: any;
        privileges: Array<string>;
      }
    | null
    | undefined;
};

export type DeleteCollaboratorsMutationVariables = Exact<{
  id: Array<Scalars['uuid']> | Scalars['uuid'];
}>;

export type DeleteCollaboratorsMutation = {
  __typename?: 'mutation_root';
  update_project_collaborators?:
    | {
        __typename?: 'project_collaborators_mutation_response';
        affected_rows: number;
        returning: Array<{
          __typename?: 'project_collaborators';
          id: any;
          collaborator?:
            | {
                __typename?: 'users_public';
                id?: any | null | undefined;
                email?: string | null | undefined;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type LeaveCollaborationMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type LeaveCollaborationMutation = {
  __typename?: 'mutation_root';
  update_project_collaborators?:
    | {
        __typename?: 'project_collaborators_mutation_response';
        affected_rows: number;
        returning: Array<{
          __typename?: 'project_collaborators';
          id: any;
          collaborator?:
            | {
                __typename?: 'users_public';
                id?: any | null | undefined;
                email?: string | null | undefined;
              }
            | null
            | undefined;
        }>;
      }
    | null
    | undefined;
};

export type UpdateTenantMutationVariables = Exact<{
  tenantId: Scalars['uuid'];
  currentHash: Scalars['String'];
  envs: Array<UpdateEnvObject> | UpdateEnvObject;
}>;

export type UpdateTenantMutation = {
  __typename?: 'mutation_root';
  updateTenantEnv?:
    | { __typename?: 'TenantEnv'; hash: string; envVars: any }
    | null
    | undefined;
};

export type DeleteTenantEnvMutationVariables = Exact<{
  id: Scalars['uuid'];
  currentHash: Scalars['String'];
  env: Array<Scalars['String']> | Scalars['String'];
}>;

export type DeleteTenantEnvMutation = {
  __typename?: 'mutation_root';
  deleteTenantEnv?:
    | { __typename?: 'TenantEnv'; hash: string; envVars: any }
    | null
    | undefined;
};

export type CreateTenantMutationVariables = Exact<{
  region: Scalars['String'];
  cloud: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  plan?: InputMaybe<Scalars['String']>;
  vpcId?: InputMaybe<Scalars['String']>;
}>;

export type CreateTenantMutation = {
  __typename?: 'mutation_root';
  createTenant?:
    | {
        __typename?: 'CreateTenantResponse';
        id: any;
        name: string;
        tenant?:
          | {
              __typename?: 'tenant';
              id: any;
              created_at: any;
              active: boolean;
              health_status?: any | null | undefined;
              project: {
                __typename?: 'projects';
                id: any;
                name: string;
                endpoint: string;
                plan_name?: string | null | undefined;
                owner?:
                  | {
                      __typename?: 'users_public';
                      email?: string | null | undefined;
                    }
                  | null
                  | undefined;
              };
              tenant_public_status: Array<{
                __typename?: 'tenant_public_status';
                endpoint: string;
                type: string;
                is_active: boolean;
                message?: string | null | undefined;
              }>;
              custom_domains: Array<{
                __typename?: 'custom_domain';
                id: any;
                fqdn: string;
              }>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type DeleteTenantMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteTenantMutation = {
  __typename?: 'mutation_root';
  deleteTenant?:
    | { __typename?: 'TenantDeleteResponse'; status: string }
    | null
    | undefined;
};

export type AcceptInviteMutationVariables = Exact<{
  key: Scalars['String'];
}>;

export type AcceptInviteMutation = {
  __typename?: 'mutation_root';
  acceptInvite: {
    __typename?: 'ProjectCollaboratorInvitation';
    project_id: any;
  };
};

export type DeactivatePersonalAccessTokenMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeactivatePersonalAccessTokenMutation = {
  __typename?: 'mutation_root';
  update_user_personal_access_tokens?:
    | {
        __typename?: 'user_personal_access_tokens_mutation_response';
        affected_rows: number;
        returning: Array<{
          __typename?: 'user_personal_access_tokens';
          id: any;
          is_active: boolean;
        }>;
      }
    | null
    | undefined;
};

export type RevokeInviteMutationVariables = Exact<{
  key: Scalars['String'];
}>;

export type RevokeInviteMutation = {
  __typename?: 'mutation_root';
  declineInvite: {
    __typename?: 'ProjectCollaboratorInvitation';
    project_id: any;
    id: any;
    collaborator_email: string;
  };
};

export type ResendInvitationMutationVariables = Exact<{
  args: ResendInvitationRequest;
}>;

export type ResendInvitationMutation = {
  __typename?: 'mutation_root';
  resendInvite: {
    __typename?: 'ProjectCollaboratorInvitation';
    id: any;
    collaborator_email: string;
  };
};

export type DeleteProjectMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteProjectMutation = {
  __typename?: 'mutation_root';
  deleteProject: { __typename?: 'DeleteProjectResponse'; id: any };
};

export type RegenerateTokenMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type RegenerateTokenMutation = {
  __typename?: 'mutation_root';
  regenerateKey: {
    __typename?: 'AddProjectResponse';
    hasura_pro_key: string;
    id: any;
  };
};

export type UpdateServerEndpointMutationVariables = Exact<{
  id: Scalars['uuid'];
  endpoint: Scalars['String'];
}>;

export type UpdateServerEndpointMutation = {
  __typename?: 'mutation_root';
  updateServerEndpoint: {
    __typename?: 'UpdateServerEndpointResponse';
    id: any;
    endpoint: string;
  };
};

export type DisconnectHerokUAppMutationVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type DisconnectHerokUAppMutation = {
  __typename?: 'mutation_root';
  herokuUnregisterWebhook: {
    __typename?: 'WebhookUnregisterStatus';
    status: string;
  };
  update_project_metadata_by_pk?:
    | {
        __typename?: 'project_metadata';
        project: {
          __typename?: 'projects';
          id: any;
          name: string;
          updated_at: any;
          plan_name?: string | null | undefined;
          invoice_status?: string | null | undefined;
          invoice_attempt_count?: number | null | undefined;
          endpoint: string;
          created_at: any;
          assigned_at?: any | null | undefined;
          current_month_data_usage?: any | null | undefined;
          number_of_requests_this_month?: any | null | undefined;
          owner?:
            | {
                __typename?: 'users_public';
                id?: any | null | undefined;
                email?: string | null | undefined;
                enterprise_user?:
                  | {
                      __typename?: 'enterprise_users';
                      is_active: boolean;
                      is_exempt_from_billing: boolean;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          tenant?:
            | {
                __typename?: 'tenant';
                id: any;
                created_at: any;
                updated_at: any;
                health_status?: any | null | undefined;
                major_version: number;
                active: boolean;
                active_status_reason?: string | null | undefined;
                dedicated_vpc_id?: any | null | undefined;
                maintenance_mode: boolean;
                region_info?:
                  | {
                      __typename?: 'region';
                      name: string;
                      nat_ip?: string | null | undefined;
                      nat_ips?: any | null | undefined;
                      cloud: Cloud_Enum;
                    }
                  | null
                  | undefined;
                config?:
                  | {
                      __typename?: 'config';
                      updated_at: any;
                      status: Array<{
                        __typename?: 'config_status';
                        is_active: boolean;
                        message?: string | null | undefined;
                      }>;
                    }
                  | null
                  | undefined;
                custom_domains: Array<{
                  __typename?: 'custom_domain';
                  id: any;
                  fqdn: string;
                }>;
                tenant_public_status: Array<{
                  __typename?: 'tenant_public_status';
                  endpoint: string;
                  type: string;
                  is_active: boolean;
                  message?: string | null | undefined;
                }>;
                dedicated_vpc?:
                  | {
                      __typename?: 'dedicated_vpc';
                      id: any;
                      plan_name: string;
                      cloud: string;
                      created_at: any;
                      owner_id: any;
                      region: string;
                      name: string;
                      status: Vpc_Status_Enum;
                      gcp_network_name?: string | null | undefined;
                      nat_ip?: string | null | undefined;
                      cidr?: string | null | undefined;
                      nat_ips?: any | null | undefined;
                      vpc_id?: string | null | undefined;
                      vpc_cidr?: string | null | undefined;
                      owner: { __typename?: 'users'; id: any; email: string };
                      collaborators: Array<{
                        __typename?: 'vpc_collaborators';
                        collaborator_id: any;
                        privilege_slug: string;
                        status: string;
                        collaborator?:
                          | {
                              __typename?: 'users_public';
                              email?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }>;
                      collaborator_invitations: Array<{
                        __typename?: 'vpc_collaborator_invitations';
                        status: string;
                        privilege_slug: string;
                        collaborator_email: any;
                        id: any;
                      }>;
                      region_info?:
                        | {
                            __typename?: 'region';
                            name: string;
                            nat_ip?: string | null | undefined;
                            nat_ips?: any | null | undefined;
                            cloud: Cloud_Enum;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          metadata?:
            | {
                __typename?: 'project_metadata';
                connected_heroku_app_name?: string | null | undefined;
                heroku_managed_mode: boolean;
                environment_name?: string | null | undefined;
                environment_color?: string | null | undefined;
              }
            | null
            | undefined;
          collaborators: Array<{
            __typename?: 'project_collaborators';
            id: any;
            status: string;
            collaborator_id: any;
            project_collaborator_privileges: Array<{
              __typename?: 'project_collaborator_privileges';
              privilege_slug: string;
            }>;
          }>;
          ownership_transfer_invites: Array<{
            __typename?: 'project_ownership_transfer_invitations';
            invitee_email: string;
            key: string;
          }>;
          datadog_config?:
            | { __typename?: 'datadog_config'; project_id: any }
            | null
            | undefined;
          newrelic_config?:
            | { __typename?: 'newrelic_config'; project_id: any }
            | null
            | undefined;
          azuremonitor_config?:
            | { __typename?: 'azuremonitor_config'; project_id: any }
            | null
            | undefined;
          labels: Array<{
            __typename?: 'project_labels';
            label: {
              __typename?: 'label';
              id: any;
              name: string;
              color?: string | null | undefined;
              created_by: any;
            };
          }>;
          heroku_integrations: Array<{
            __typename?: 'heroku_integrations';
            app_name: string;
            var_name: string;
          }>;
          billing_manager?:
            | {
                __typename?: 'users_public';
                email?: string | null | undefined;
                id?: any | null | undefined;
              }
            | null
            | undefined;
          project_usages: Array<{
            __typename?: 'project_data_usage';
            usage: any;
          }>;
          jobs: Array<{
            __typename?: 'jobs';
            id: any;
            status: string;
            job_type: string;
          }>;
          vercel_integration_connections: Array<{
            __typename?: 'vercel_integration_connections';
            id: any;
            status: string;
          }>;
          entitlements: Array<{
            __typename?: 'project_entitlement_access';
            entitlement_id: any;
            id: any;
            entitlement: {
              __typename?: 'project_entitlement_catalogue';
              config_is_enabled: boolean;
              config_limit?: any | null | undefined;
              name: string;
              type: Project_Entitlement_Types_Enum;
              id: any;
            };
          }>;
        };
      }
    | null
    | undefined;
};

export type CreateCustomDomainMutationVariables = Exact<{
  tenantId: Scalars['uuid'];
  fqdn: Scalars['String'];
}>;

export type CreateCustomDomainMutation = {
  __typename?: 'mutation_root';
  insert_custom_domain_one?:
    | {
        __typename?: 'custom_domain';
        id: any;
        created_at: any;
        dns_validation: string;
        fqdn: string;
        cert: string;
      }
    | null
    | undefined;
};

export type DeleteCustomDomainMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteCustomDomainMutation = {
  __typename?: 'mutation_root';
  delete_custom_domain_by_pk?:
    | { __typename?: 'custom_domain'; id: any }
    | null
    | undefined;
};

export type AddProjectLabelMutationVariables = Exact<{
  labelName: Scalars['String'];
  projectId: Scalars['uuid'];
  color?: InputMaybe<Scalars['String']>;
}>;

export type AddProjectLabelMutation = {
  __typename?: 'mutation_root';
  insert_label?:
    | { __typename?: 'label_mutation_response'; affected_rows: number }
    | null
    | undefined;
};

export type ConnectHerokuAppMutationVariables = Exact<{
  herokuAppName: Scalars['String'];
  projectID: Scalars['uuid'];
}>;

export type ConnectHerokuAppMutation = {
  __typename?: 'mutation_root';
  insert_project_metadata_one?:
    | { __typename?: 'project_metadata'; project_id: any }
    | null
    | undefined;
};

export type DeleteProjectLabelMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  labelId: Scalars['uuid'];
}>;

export type DeleteProjectLabelMutation = {
  __typename?: 'mutation_root';
  delete_project_labels?:
    | { __typename?: 'project_labels_mutation_response'; affected_rows: number }
    | null
    | undefined;
};

export type RegisterHerokuWebhookMutationVariables = Exact<{
  herokuAppId: Scalars['String'];
  projectID: Scalars['uuid'];
  herokuAppName: Scalars['String'];
}>;

export type RegisterHerokuWebhookMutation = {
  __typename?: 'mutation_root';
  herokuRegisterWebhook: {
    __typename?: 'WebhookRegisterStatus';
    status: string;
  };
};

export type StartDburlSyncMutationVariables = Exact<{
  appName: Scalars['String'];
  appID: Scalars['String'];
  projectID: Scalars['uuid'];
  env: Scalars['String'];
  agent: ActionAgent;
}>;

export type StartDburlSyncMutation = {
  __typename?: 'mutation_root';
  herokuRegisterWebhookVar: {
    __typename?: 'WebhookRegisterStatus';
    status: string;
  };
};

export type UnregisterHerokuWebhookMutationVariables = Exact<{
  projectID: Scalars['uuid'];
}>;

export type UnregisterHerokuWebhookMutation = {
  __typename?: 'mutation_root';
  herokuUnregisterWebhook: {
    __typename?: 'WebhookUnregisterStatus';
    status: string;
  };
  update_project_metadata?:
    | {
        __typename?: 'project_metadata_mutation_response';
        affected_rows: number;
      }
    | null
    | undefined;
};

export type UnregisterHerokuWebhookVarMutationVariables = Exact<{
  env: Scalars['String'];
  projectID: Scalars['uuid'];
}>;

export type UnregisterHerokuWebhookVarMutation = {
  __typename?: 'mutation_root';
  herokuUnregisterWebhookVar: {
    __typename?: 'WebhookUnregisterStatus';
    status: string;
  };
};

export type RetryCustomDomainValidationMutationVariables = Exact<{
  id: Scalars['uuid'];
  fqdn: Scalars['String'];
  tenantId: Scalars['uuid'];
}>;

export type RetryCustomDomainValidationMutation = {
  __typename?: 'mutation_root';
  delete_custom_domain_by_pk?:
    | { __typename?: 'custom_domain'; id: any }
    | null
    | undefined;
  insert_custom_domain_one?:
    | {
        __typename?: 'custom_domain';
        id: any;
        created_at: any;
        dns_validation: string;
        fqdn: string;
        cert: string;
      }
    | null
    | undefined;
};

export type GenerateProKeyMutationVariables = Exact<{
  name: Scalars['String'];
  serverEndpoint: Scalars['String'];
}>;

export type GenerateProKeyMutation = {
  __typename?: 'mutation_root';
  addProject: {
    __typename?: 'AddProjectResponse';
    hasura_pro_key: string;
    id: any;
  };
};

export type UpdateProjectNameMutationVariables = Exact<{
  name: Scalars['String'];
  tenantId: Scalars['uuid'];
}>;

export type UpdateProjectNameMutation = {
  __typename?: 'mutation_root';
  updateTenantName?:
    | {
        __typename?: 'UpdateTenantNameResponse';
        tenant?:
          | {
              __typename?: 'tenant';
              project: {
                __typename?: 'projects';
                id: any;
                name: string;
                updated_at: any;
                plan_name?: string | null | undefined;
                invoice_status?: string | null | undefined;
                invoice_attempt_count?: number | null | undefined;
                endpoint: string;
                created_at: any;
                assigned_at?: any | null | undefined;
                current_month_data_usage?: any | null | undefined;
                number_of_requests_this_month?: any | null | undefined;
                owner?:
                  | {
                      __typename?: 'users_public';
                      id?: any | null | undefined;
                      email?: string | null | undefined;
                      enterprise_user?:
                        | {
                            __typename?: 'enterprise_users';
                            is_active: boolean;
                            is_exempt_from_billing: boolean;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
                tenant?:
                  | {
                      __typename?: 'tenant';
                      id: any;
                      created_at: any;
                      updated_at: any;
                      health_status?: any | null | undefined;
                      major_version: number;
                      active: boolean;
                      active_status_reason?: string | null | undefined;
                      dedicated_vpc_id?: any | null | undefined;
                      maintenance_mode: boolean;
                      region_info?:
                        | {
                            __typename?: 'region';
                            name: string;
                            nat_ip?: string | null | undefined;
                            nat_ips?: any | null | undefined;
                            cloud: Cloud_Enum;
                          }
                        | null
                        | undefined;
                      config?:
                        | {
                            __typename?: 'config';
                            updated_at: any;
                            status: Array<{
                              __typename?: 'config_status';
                              is_active: boolean;
                              message?: string | null | undefined;
                            }>;
                          }
                        | null
                        | undefined;
                      custom_domains: Array<{
                        __typename?: 'custom_domain';
                        id: any;
                        fqdn: string;
                      }>;
                      tenant_public_status: Array<{
                        __typename?: 'tenant_public_status';
                        endpoint: string;
                        type: string;
                        is_active: boolean;
                        message?: string | null | undefined;
                      }>;
                      dedicated_vpc?:
                        | {
                            __typename?: 'dedicated_vpc';
                            id: any;
                            plan_name: string;
                            cloud: string;
                            created_at: any;
                            owner_id: any;
                            region: string;
                            name: string;
                            status: Vpc_Status_Enum;
                            gcp_network_name?: string | null | undefined;
                            nat_ip?: string | null | undefined;
                            cidr?: string | null | undefined;
                            nat_ips?: any | null | undefined;
                            vpc_id?: string | null | undefined;
                            vpc_cidr?: string | null | undefined;
                            owner: {
                              __typename?: 'users';
                              id: any;
                              email: string;
                            };
                            collaborators: Array<{
                              __typename?: 'vpc_collaborators';
                              collaborator_id: any;
                              privilege_slug: string;
                              status: string;
                              collaborator?:
                                | {
                                    __typename?: 'users_public';
                                    email?: string | null | undefined;
                                  }
                                | null
                                | undefined;
                            }>;
                            collaborator_invitations: Array<{
                              __typename?: 'vpc_collaborator_invitations';
                              status: string;
                              privilege_slug: string;
                              collaborator_email: any;
                              id: any;
                            }>;
                            region_info?:
                              | {
                                  __typename?: 'region';
                                  name: string;
                                  nat_ip?: string | null | undefined;
                                  nat_ips?: any | null | undefined;
                                  cloud: Cloud_Enum;
                                }
                              | null
                              | undefined;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
                metadata?:
                  | {
                      __typename?: 'project_metadata';
                      connected_heroku_app_name?: string | null | undefined;
                      heroku_managed_mode: boolean;
                      environment_name?: string | null | undefined;
                      environment_color?: string | null | undefined;
                    }
                  | null
                  | undefined;
                collaborators: Array<{
                  __typename?: 'project_collaborators';
                  id: any;
                  status: string;
                  collaborator_id: any;
                  project_collaborator_privileges: Array<{
                    __typename?: 'project_collaborator_privileges';
                    privilege_slug: string;
                  }>;
                }>;
                ownership_transfer_invites: Array<{
                  __typename?: 'project_ownership_transfer_invitations';
                  invitee_email: string;
                  key: string;
                }>;
                datadog_config?:
                  | { __typename?: 'datadog_config'; project_id: any }
                  | null
                  | undefined;
                newrelic_config?:
                  | { __typename?: 'newrelic_config'; project_id: any }
                  | null
                  | undefined;
                azuremonitor_config?:
                  | { __typename?: 'azuremonitor_config'; project_id: any }
                  | null
                  | undefined;
                labels: Array<{
                  __typename?: 'project_labels';
                  label: {
                    __typename?: 'label';
                    id: any;
                    name: string;
                    color?: string | null | undefined;
                    created_by: any;
                  };
                }>;
                heroku_integrations: Array<{
                  __typename?: 'heroku_integrations';
                  app_name: string;
                  var_name: string;
                }>;
                billing_manager?:
                  | {
                      __typename?: 'users_public';
                      email?: string | null | undefined;
                      id?: any | null | undefined;
                    }
                  | null
                  | undefined;
                project_usages: Array<{
                  __typename?: 'project_data_usage';
                  usage: any;
                }>;
                jobs: Array<{
                  __typename?: 'jobs';
                  id: any;
                  status: string;
                  job_type: string;
                }>;
                vercel_integration_connections: Array<{
                  __typename?: 'vercel_integration_connections';
                  id: any;
                  status: string;
                }>;
                entitlements: Array<{
                  __typename?: 'project_entitlement_access';
                  entitlement_id: any;
                  id: any;
                  entitlement: {
                    __typename?: 'project_entitlement_catalogue';
                    config_is_enabled: boolean;
                    config_limit?: any | null | undefined;
                    name: string;
                    type: Project_Entitlement_Types_Enum;
                    id: any;
                  };
                }>;
              };
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ChangeProjectRegionMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  cloud: Scalars['String'];
  region: Scalars['String'];
}>;

export type ChangeProjectRegionMutation = {
  __typename?: 'mutation_root';
  moveProjectRegion: {
    __typename?: 'MoveProjectRegionResponse';
    projectId: any;
  };
};

export type TransferOwnershipInviteMutationVariables = Exact<{
  inviteeEmail: Scalars['String'];
  projectId: Scalars['uuid'];
}>;

export type TransferOwnershipInviteMutation = {
  __typename?: 'mutation_root';
  transferOwnershipInvite: {
    __typename?: 'ProjectOwnershipTransferInvitation';
    invitee_email: string;
  };
};

export type ResendTransferOwnershipInviteMutationVariables = Exact<{
  inviteeEmail: Scalars['String'];
  projectId: Scalars['uuid'];
}>;

export type ResendTransferOwnershipInviteMutation = {
  __typename?: 'mutation_root';
  resendTransferOwnershipInvite: {
    __typename?: 'ProjectOwnershipTransferInvitation';
    invitee_email: string;
  };
};

export type InsertDatadogApiKeyMutationVariables = Exact<{
  id: Scalars['uuid'];
  key: Scalars['String'];
  host: Scalars['String'];
  service: Scalars['String'];
  tags: Scalars['String'];
  region: Scalars['String'];
}>;

export type InsertDatadogApiKeyMutation = {
  __typename?: 'mutation_root';
  addDatadogConfig?:
    | { __typename?: 'DatadogConfigResponse'; project_id: any }
    | null
    | undefined;
};

export type AddNewrelicConfigMutationVariables = Exact<{
  id: Scalars['uuid'];
  key: Scalars['String'];
  region: Scalars['String'];
  attributes: Scalars['jsonb'];
}>;

export type AddNewrelicConfigMutation = {
  __typename?: 'mutation_root';
  addNewrelicConfig?:
    | { __typename?: 'NewrelicConfigResponse'; project_id: any }
    | null
    | undefined;
};

export type AddAzureMonitorConfigMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  azuremonitor_workspace_id: Scalars['String'];
  shared_key: Scalars['String'];
  resource_id: Scalars['String'];
  log_type: Scalars['String'];
  metric_namespace: Scalars['String'];
  activedirectory_tenant_id: Scalars['String'];
  activedirectory_client_id: Scalars['String'];
  activedirectory_client_secret: Scalars['String'];
  azure_region: Scalars['String'];
  attributes?: InputMaybe<Scalars['jsonb']>;
  instrumentation_key?: InputMaybe<Scalars['String']>;
}>;

export type AddAzureMonitorConfigMutation = {
  __typename?: 'mutation_root';
  addAzureMonitorConfig?:
    | { __typename?: 'AzureMonitorConfigResponse'; project_id: any }
    | null
    | undefined;
};

export type AddPrometheusConfigMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  namespace?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Scalars['jsonb']>;
}>;

export type AddPrometheusConfigMutation = {
  __typename?: 'mutation_root';
  addPrometheusConfig: {
    __typename?: 'PrometheusConfigResponse';
    project_id: any;
    access_token?: string | null | undefined;
  };
};

export type AddOpentelemetryConfigMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  attributes?: InputMaybe<Scalars['jsonb']>;
  batch_size?: InputMaybe<Scalars['Int']>;
  connection_type: Scalars['String'];
  endpoint: Scalars['String'];
  export_traces?: InputMaybe<Scalars['Boolean']>;
  headers?: InputMaybe<Scalars['jsonb']>;
}>;

export type AddOpentelemetryConfigMutation = {
  __typename?: 'mutation_root';
  addOpentelemetryConfig?:
    | { __typename?: 'OpentelemetryConfigResponse'; project_id: any }
    | null
    | undefined;
};

export type AcceptTransferOwnershipInviteMutationVariables = Exact<{
  inviteKey: Scalars['String'];
}>;

export type AcceptTransferOwnershipInviteMutation = {
  __typename?: 'mutation_root';
  acceptTransferOwnershipInvite: {
    __typename?: 'ProjectOwnershipTransferInvitation';
    invitee_email: string;
    project_id: any;
  };
};

export type DeclineTransferOwnershipInviteMutationVariables = Exact<{
  inviteKey: Scalars['String'];
}>;

export type DeclineTransferOwnershipInviteMutation = {
  __typename?: 'mutation_root';
  declineTransferOwnershipInvite: {
    __typename?: 'ProjectOwnershipTransferInvitation';
    invitee_email: string;
    project_id: any;
  };
};

export type UpdateDatadogConfigDataMutationVariables = Exact<{
  id: Scalars['uuid'];
  host: Scalars['String'];
  service: Scalars['String'];
  tags: Scalars['String'];
  key: Scalars['String'];
  region: Scalars['String'];
}>;

export type UpdateDatadogConfigDataMutation = {
  __typename?: 'mutation_root';
  updateDatadogConfig?:
    | { __typename?: 'DatadogConfigResponse'; project_id: any }
    | null
    | undefined;
};

export type UpdateNewrelicConfigMutationVariables = Exact<{
  id: Scalars['uuid'];
  key: Scalars['String'];
  region: Scalars['String'];
  attributes: Scalars['jsonb'];
}>;

export type UpdateNewrelicConfigMutation = {
  __typename?: 'mutation_root';
  updateNewrelicConfig?:
    | { __typename?: 'NewrelicConfigResponse'; project_id: any }
    | null
    | undefined;
};

export type UpdateAzureMonitorConfigMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  azuremonitor_workspace_id: Scalars['String'];
  shared_key: Scalars['String'];
  resource_id: Scalars['String'];
  log_type: Scalars['String'];
  metric_namespace: Scalars['String'];
  activedirectory_tenant_id: Scalars['String'];
  activedirectory_client_id: Scalars['String'];
  activedirectory_client_secret: Scalars['String'];
  azure_region: Scalars['String'];
  attributes?: InputMaybe<Scalars['jsonb']>;
  instrumentation_key?: InputMaybe<Scalars['String']>;
}>;

export type UpdateAzureMonitorConfigMutation = {
  __typename?: 'mutation_root';
  updateAzureMonitorConfig?:
    | { __typename?: 'AzureMonitorConfigResponse'; project_id: any }
    | null
    | undefined;
};

export type UpdatePrometheusConfigMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  namespace?: InputMaybe<Scalars['String']>;
  labels?: InputMaybe<Scalars['jsonb']>;
}>;

export type UpdatePrometheusConfigMutation = {
  __typename?: 'mutation_root';
  updatePrometheusConfig: {
    __typename?: 'PrometheusConfigResponse';
    project_id: any;
  };
};

export type UpdateOpentelemetryConfigMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  attributes?: InputMaybe<Scalars['jsonb']>;
  batch_size?: InputMaybe<Scalars['Int']>;
  connection_type?: InputMaybe<Scalars['String']>;
  endpoint?: InputMaybe<Scalars['String']>;
  export_traces?: InputMaybe<Scalars['Boolean']>;
  headers?: InputMaybe<Scalars['jsonb']>;
}>;

export type UpdateOpentelemetryConfigMutation = {
  __typename?: 'mutation_root';
  updateOpentelemetryConfig?:
    | { __typename?: 'OpentelemetryConfigResponse'; project_id: any }
    | null
    | undefined;
};

export type UpdatePrometheusAccessTokenMutationVariables = Exact<{
  project_id: Scalars['uuid'];
}>;

export type UpdatePrometheusAccessTokenMutation = {
  __typename?: 'mutation_root';
  updatePrometheusAccessToken: {
    __typename?: 'PrometheusConfigResponse';
    project_id: any;
    access_token?: string | null | undefined;
  };
};

export type DisconnectDatadogMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DisconnectDatadogMutation = {
  __typename?: 'mutation_root';
  delete_datadog_config?:
    | {
        __typename?: 'datadog_config_mutation_response';
        returning: Array<{ __typename?: 'datadog_config'; project_id: any }>;
      }
    | null
    | undefined;
};

export type DisconnectNewRelicMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DisconnectNewRelicMutation = {
  __typename?: 'mutation_root';
  delete_newrelic_config?:
    | {
        __typename?: 'newrelic_config_mutation_response';
        returning: Array<{ __typename?: 'newrelic_config'; project_id: any }>;
      }
    | null
    | undefined;
};

export type AcceptBillingOwnerInviteMutationVariables = Exact<{
  key: Scalars['String'];
}>;

export type AcceptBillingOwnerInviteMutation = {
  __typename?: 'mutation_root';
  acceptBillingManagerInvite?:
    | { __typename?: 'BillingManagerInvitation'; project_id: any }
    | null
    | undefined;
};

export type Delete_Azuremonitor_ConfigMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type Delete_Azuremonitor_ConfigMutation = {
  __typename?: 'mutation_root';
  delete_azuremonitor_config?:
    | {
        __typename?: 'azuremonitor_config_mutation_response';
        returning: Array<{
          __typename?: 'azuremonitor_config';
          project_id: any;
        }>;
      }
    | null
    | undefined;
};

export type Delete_Prometheus_ConfigMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type Delete_Prometheus_ConfigMutation = {
  __typename?: 'mutation_root';
  delete_prometheus_config?:
    | {
        __typename?: 'prometheus_config_mutation_response';
        returning: Array<{ __typename?: 'prometheus_config'; project_id: any }>;
      }
    | null
    | undefined;
};

export type DeleteOpentelemetryConfigMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteOpentelemetryConfigMutation = {
  __typename?: 'mutation_root';
  delete_opentelemetry_config?:
    | {
        __typename?: 'opentelemetry_config_mutation_response';
        returning: Array<{
          __typename?: 'opentelemetry_config';
          project_id: any;
        }>;
      }
    | null
    | undefined;
};

export type UpdateProjectVersionMutationVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type UpdateProjectVersionMutation = {
  __typename?: 'mutation_root';
  createJob: { __typename?: 'CreateJobResponse'; job_id: any };
};

export type DeclineBillingOwnerInviteMutationVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type DeclineBillingOwnerInviteMutation = {
  __typename?: 'mutation_root';
  declineBillingManagerInvite?:
    | { __typename?: 'BillingManagerInvitation'; project_id: any }
    | null
    | undefined;
};

export type GithubTokenExchangeMutationVariables = Exact<{
  code: Scalars['String'];
  projectId: Scalars['uuid'];
}>;

export type GithubTokenExchangeMutation = {
  __typename?: 'mutation_root';
  githubTokenExchange?:
    | { __typename?: 'GithubSession'; status: string }
    | null
    | undefined;
};

export type RemoveBillingOwnerMutationVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type RemoveBillingOwnerMutation = {
  __typename?: 'mutation_root';
  updateBillingManager?:
    | { __typename?: 'UpdateBillingManagerResponse'; status: string }
    | null
    | undefined;
};

export type GetCurrentGithubLoginMutationVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetCurrentGithubLoginMutation = {
  __typename?: 'mutation_root';
  fetchCurrentGithubLogin: {
    __typename?: 'GithubLoginResponse';
    login: string;
  };
};

export type GetGithubAppInstallationsMutationVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetGithubAppInstallationsMutation = {
  __typename?: 'mutation_root';
  fetchGithubAppInstallations: {
    __typename?: 'GithubAppInstallations';
    installations: Array<{
      __typename?: 'GithubAppInstallation';
      app_slug: string;
      id: number;
    }>;
  };
};

export type GetGithubReposMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  installationId: Scalars['Int'];
}>;

export type GetGithubReposMutation = {
  __typename?: 'mutation_root';
  fetchGithubAppIntegrationAccessibleRepos?:
    | {
        __typename?: 'GithubAppIntegrationAccessibleRepos';
        total_count: number;
        repositories: Array<{
          __typename?: 'GithubAppIntegrationAccessibleRepo';
          installation_id: number;
          name: string;
          owner_login: string;
        }>;
      }
    | null
    | undefined;
};

export type GetGithubRepoBranchesMutationVariables = Exact<{
  owner: Scalars['String'];
  projectId: Scalars['uuid'];
  repo: Scalars['String'];
}>;

export type GetGithubRepoBranchesMutation = {
  __typename?: 'mutation_root';
  fetchGithubRepoBranches: {
    __typename?: 'GithubBranches';
    branches: Array<{ __typename?: 'GithubBranch'; name: string }>;
  };
};

export type CreateGithubIntegrationMutationVariables = Exact<{
  projectId: Scalars['String'];
  branch: Scalars['String'];
  org: Scalars['String'];
  repo: Scalars['String'];
  installationId: Scalars['Int'];
  directory: Scalars['String'];
  mode?: InputMaybe<ModeEnum>;
  email_status: Scalars['String'];
}>;

export type CreateGithubIntegrationMutation = {
  __typename?: 'mutation_root';
  createGithubIntegration?:
    | { __typename?: 'GithubIntegrationStatus'; status: string }
    | null
    | undefined;
};

export type RemoveGithubIntegrationMutationVariables = Exact<{
  integrationId: Scalars['String'];
}>;

export type RemoveGithubIntegrationMutation = {
  __typename?: 'mutation_root';
  removeGithubIntegration?:
    | { __typename?: 'DeleteGithubIntegrationStatus'; status: string }
    | null
    | undefined;
};

export type UpdateGithubIntegrationMutationVariables = Exact<{
  branch: Scalars['String'];
  org: Scalars['String'];
  repo: Scalars['String'];
  directory: Scalars['String'];
  installationId: Scalars['Int'];
  integrationId: Scalars['String'];
  mode?: InputMaybe<ModeEnum>;
  email_status: Scalars['String'];
}>;

export type UpdateGithubIntegrationMutation = {
  __typename?: 'mutation_root';
  updateGithubIntegration?:
    | { __typename?: 'UpdateGithubIntegrationStatus'; status: string }
    | null
    | undefined;
};

export type RemoveGithubSessionMutationVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type RemoveGithubSessionMutation = {
  __typename?: 'mutation_root';
  removeGithubSession?:
    | { __typename?: 'GithubIntegrationStatus'; status: string }
    | null
    | undefined;
};

export type DeployLatestCommitMutationVariables = Exact<{
  id: Scalars['String'];
}>;

export type DeployLatestCommitMutation = {
  __typename?: 'mutation_root';
  deployLatestGithubCommit?:
    | { __typename?: 'DeployLatestCommitStatus'; status: string }
    | null
    | undefined;
};

export type ChangeRegionMutationVariables = Exact<{
  projectId: Scalars['uuid'];
  cloud?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
}>;

export type ChangeRegionMutation = {
  __typename?: 'mutation_root';
  createJob: { __typename?: 'CreateJobResponse'; job_id: any };
};

export type UpsertProjectEnvironmentMutationVariables = Exact<{
  project_id: Scalars['uuid'];
  environment_name?: InputMaybe<Scalars['String']>;
  environment_color?: InputMaybe<Scalars['String']>;
}>;

export type UpsertProjectEnvironmentMutation = {
  __typename?: 'mutation_root';
  insert_project_metadata?:
    | {
        __typename?: 'project_metadata_mutation_response';
        returning: Array<{
          __typename?: 'project_metadata';
          project_id: any;
          environment_name?: string | null | undefined;
          environment_color?: string | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type ReactivateProjectMutationVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type ReactivateProjectMutation = {
  __typename?: 'mutation_root';
  reactivateSuspendedProject: {
    __typename?: 'ProjectReactivationResponse';
    success: boolean;
    projectId: any;
    project?:
      | {
          __typename?: 'projects';
          id: any;
          name: string;
          updated_at: any;
          plan_name?: string | null | undefined;
          invoice_status?: string | null | undefined;
          invoice_attempt_count?: number | null | undefined;
          endpoint: string;
          created_at: any;
          assigned_at?: any | null | undefined;
          current_month_data_usage?: any | null | undefined;
          number_of_requests_this_month?: any | null | undefined;
          owner?:
            | {
                __typename?: 'users_public';
                id?: any | null | undefined;
                email?: string | null | undefined;
                enterprise_user?:
                  | {
                      __typename?: 'enterprise_users';
                      is_active: boolean;
                      is_exempt_from_billing: boolean;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          tenant?:
            | {
                __typename?: 'tenant';
                id: any;
                created_at: any;
                updated_at: any;
                health_status?: any | null | undefined;
                major_version: number;
                active: boolean;
                active_status_reason?: string | null | undefined;
                dedicated_vpc_id?: any | null | undefined;
                maintenance_mode: boolean;
                region_info?:
                  | {
                      __typename?: 'region';
                      name: string;
                      nat_ip?: string | null | undefined;
                      nat_ips?: any | null | undefined;
                      cloud: Cloud_Enum;
                    }
                  | null
                  | undefined;
                config?:
                  | {
                      __typename?: 'config';
                      updated_at: any;
                      status: Array<{
                        __typename?: 'config_status';
                        is_active: boolean;
                        message?: string | null | undefined;
                      }>;
                    }
                  | null
                  | undefined;
                custom_domains: Array<{
                  __typename?: 'custom_domain';
                  id: any;
                  fqdn: string;
                }>;
                tenant_public_status: Array<{
                  __typename?: 'tenant_public_status';
                  endpoint: string;
                  type: string;
                  is_active: boolean;
                  message?: string | null | undefined;
                }>;
                dedicated_vpc?:
                  | {
                      __typename?: 'dedicated_vpc';
                      id: any;
                      plan_name: string;
                      cloud: string;
                      created_at: any;
                      owner_id: any;
                      region: string;
                      name: string;
                      status: Vpc_Status_Enum;
                      gcp_network_name?: string | null | undefined;
                      nat_ip?: string | null | undefined;
                      cidr?: string | null | undefined;
                      nat_ips?: any | null | undefined;
                      vpc_id?: string | null | undefined;
                      vpc_cidr?: string | null | undefined;
                      owner: { __typename?: 'users'; id: any; email: string };
                      collaborators: Array<{
                        __typename?: 'vpc_collaborators';
                        collaborator_id: any;
                        privilege_slug: string;
                        status: string;
                        collaborator?:
                          | {
                              __typename?: 'users_public';
                              email?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }>;
                      collaborator_invitations: Array<{
                        __typename?: 'vpc_collaborator_invitations';
                        status: string;
                        privilege_slug: string;
                        collaborator_email: any;
                        id: any;
                      }>;
                      region_info?:
                        | {
                            __typename?: 'region';
                            name: string;
                            nat_ip?: string | null | undefined;
                            nat_ips?: any | null | undefined;
                            cloud: Cloud_Enum;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          metadata?:
            | {
                __typename?: 'project_metadata';
                connected_heroku_app_name?: string | null | undefined;
                heroku_managed_mode: boolean;
                environment_name?: string | null | undefined;
                environment_color?: string | null | undefined;
              }
            | null
            | undefined;
          collaborators: Array<{
            __typename?: 'project_collaborators';
            id: any;
            status: string;
            collaborator_id: any;
            project_collaborator_privileges: Array<{
              __typename?: 'project_collaborator_privileges';
              privilege_slug: string;
            }>;
          }>;
          ownership_transfer_invites: Array<{
            __typename?: 'project_ownership_transfer_invitations';
            invitee_email: string;
            key: string;
          }>;
          datadog_config?:
            | { __typename?: 'datadog_config'; project_id: any }
            | null
            | undefined;
          newrelic_config?:
            | { __typename?: 'newrelic_config'; project_id: any }
            | null
            | undefined;
          azuremonitor_config?:
            | { __typename?: 'azuremonitor_config'; project_id: any }
            | null
            | undefined;
          labels: Array<{
            __typename?: 'project_labels';
            label: {
              __typename?: 'label';
              id: any;
              name: string;
              color?: string | null | undefined;
              created_by: any;
            };
          }>;
          heroku_integrations: Array<{
            __typename?: 'heroku_integrations';
            app_name: string;
            var_name: string;
          }>;
          billing_manager?:
            | {
                __typename?: 'users_public';
                email?: string | null | undefined;
                id?: any | null | undefined;
              }
            | null
            | undefined;
          project_usages: Array<{
            __typename?: 'project_data_usage';
            usage: any;
          }>;
          jobs: Array<{
            __typename?: 'jobs';
            id: any;
            status: string;
            job_type: string;
          }>;
          vercel_integration_connections: Array<{
            __typename?: 'vercel_integration_connections';
            id: any;
            status: string;
          }>;
          entitlements: Array<{
            __typename?: 'project_entitlement_access';
            entitlement_id: any;
            id: any;
            entitlement: {
              __typename?: 'project_entitlement_catalogue';
              config_is_enabled: boolean;
              config_limit?: any | null | undefined;
              name: string;
              type: Project_Entitlement_Types_Enum;
              id: any;
            };
          }>;
        }
      | null
      | undefined;
  };
};

export type CreateOneClickDeploymentMutationVariables = Exact<{
  githubURL: Scalars['String'];
  branch?: InputMaybe<Scalars['String']>;
  hasuraDir: Scalars['String'];
  additionalInfo: Scalars['jsonb'];
}>;

export type CreateOneClickDeploymentMutation = {
  __typename?: 'mutation_root';
  createOneClickDeployment: {
    __typename?: 'CreateOneClickDeploymentOutput';
    project_id: any;
    project?:
      | {
          __typename?: 'projects';
          id: any;
          name: string;
          updated_at: any;
          plan_name?: string | null | undefined;
          invoice_status?: string | null | undefined;
          invoice_attempt_count?: number | null | undefined;
          endpoint: string;
          created_at: any;
          assigned_at?: any | null | undefined;
          current_month_data_usage?: any | null | undefined;
          number_of_requests_this_month?: any | null | undefined;
          owner?:
            | {
                __typename?: 'users_public';
                id?: any | null | undefined;
                email?: string | null | undefined;
                enterprise_user?:
                  | {
                      __typename?: 'enterprise_users';
                      is_active: boolean;
                      is_exempt_from_billing: boolean;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          tenant?:
            | {
                __typename?: 'tenant';
                id: any;
                created_at: any;
                updated_at: any;
                health_status?: any | null | undefined;
                major_version: number;
                active: boolean;
                active_status_reason?: string | null | undefined;
                dedicated_vpc_id?: any | null | undefined;
                maintenance_mode: boolean;
                region_info?:
                  | {
                      __typename?: 'region';
                      name: string;
                      nat_ip?: string | null | undefined;
                      nat_ips?: any | null | undefined;
                      cloud: Cloud_Enum;
                    }
                  | null
                  | undefined;
                config?:
                  | {
                      __typename?: 'config';
                      updated_at: any;
                      status: Array<{
                        __typename?: 'config_status';
                        is_active: boolean;
                        message?: string | null | undefined;
                      }>;
                    }
                  | null
                  | undefined;
                custom_domains: Array<{
                  __typename?: 'custom_domain';
                  id: any;
                  fqdn: string;
                }>;
                tenant_public_status: Array<{
                  __typename?: 'tenant_public_status';
                  endpoint: string;
                  type: string;
                  is_active: boolean;
                  message?: string | null | undefined;
                }>;
                dedicated_vpc?:
                  | {
                      __typename?: 'dedicated_vpc';
                      id: any;
                      plan_name: string;
                      cloud: string;
                      created_at: any;
                      owner_id: any;
                      region: string;
                      name: string;
                      status: Vpc_Status_Enum;
                      gcp_network_name?: string | null | undefined;
                      nat_ip?: string | null | undefined;
                      cidr?: string | null | undefined;
                      nat_ips?: any | null | undefined;
                      vpc_id?: string | null | undefined;
                      vpc_cidr?: string | null | undefined;
                      owner: { __typename?: 'users'; id: any; email: string };
                      collaborators: Array<{
                        __typename?: 'vpc_collaborators';
                        collaborator_id: any;
                        privilege_slug: string;
                        status: string;
                        collaborator?:
                          | {
                              __typename?: 'users_public';
                              email?: string | null | undefined;
                            }
                          | null
                          | undefined;
                      }>;
                      collaborator_invitations: Array<{
                        __typename?: 'vpc_collaborator_invitations';
                        status: string;
                        privilege_slug: string;
                        collaborator_email: any;
                        id: any;
                      }>;
                      region_info?:
                        | {
                            __typename?: 'region';
                            name: string;
                            nat_ip?: string | null | undefined;
                            nat_ips?: any | null | undefined;
                            cloud: Cloud_Enum;
                          }
                        | null
                        | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          metadata?:
            | {
                __typename?: 'project_metadata';
                connected_heroku_app_name?: string | null | undefined;
                heroku_managed_mode: boolean;
                environment_name?: string | null | undefined;
                environment_color?: string | null | undefined;
              }
            | null
            | undefined;
          collaborators: Array<{
            __typename?: 'project_collaborators';
            id: any;
            status: string;
            collaborator_id: any;
            project_collaborator_privileges: Array<{
              __typename?: 'project_collaborator_privileges';
              privilege_slug: string;
            }>;
          }>;
          ownership_transfer_invites: Array<{
            __typename?: 'project_ownership_transfer_invitations';
            invitee_email: string;
            key: string;
          }>;
          datadog_config?:
            | { __typename?: 'datadog_config'; project_id: any }
            | null
            | undefined;
          newrelic_config?:
            | { __typename?: 'newrelic_config'; project_id: any }
            | null
            | undefined;
          azuremonitor_config?:
            | { __typename?: 'azuremonitor_config'; project_id: any }
            | null
            | undefined;
          labels: Array<{
            __typename?: 'project_labels';
            label: {
              __typename?: 'label';
              id: any;
              name: string;
              color?: string | null | undefined;
              created_by: any;
            };
          }>;
          heroku_integrations: Array<{
            __typename?: 'heroku_integrations';
            app_name: string;
            var_name: string;
          }>;
          billing_manager?:
            | {
                __typename?: 'users_public';
                email?: string | null | undefined;
                id?: any | null | undefined;
              }
            | null
            | undefined;
          project_usages: Array<{
            __typename?: 'project_data_usage';
            usage: any;
          }>;
          jobs: Array<{
            __typename?: 'jobs';
            id: any;
            status: string;
            job_type: string;
          }>;
          vercel_integration_connections: Array<{
            __typename?: 'vercel_integration_connections';
            id: any;
            status: string;
          }>;
          entitlements: Array<{
            __typename?: 'project_entitlement_access';
            entitlement_id: any;
            id: any;
            entitlement: {
              __typename?: 'project_entitlement_catalogue';
              config_is_enabled: boolean;
              config_limit?: any | null | undefined;
              name: string;
              type: Project_Entitlement_Types_Enum;
              id: any;
            };
          }>;
        }
      | null
      | undefined;
  };
};

export type GetNewPlansProjectUsageMutationVariables = Exact<{
  month: Scalars['Int'];
  project_id: Scalars['uuid'];
  year: Scalars['Int'];
}>;

export type GetNewPlansProjectUsageMutation = {
  __typename?: 'mutation_root';
  newPlansProjectUsage?:
    | {
        __typename?: 'NewPlansProjectOutput';
        data_usage?: number | null | undefined;
        data_usage_amount?: number | null | undefined;
        project_id?: any | null | undefined;
        project_name?: string | null | undefined;
        db_usage?:
          | Array<
              | {
                  __typename?: 'ProjectDBUsage';
                  amount: number;
                  type: string;
                  usage: number;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type ConfigureSsoMutationVariables = Exact<{
  objects: Array<Saml_Idp_Insert_Input> | Saml_Idp_Insert_Input;
}>;

export type ConfigureSsoMutation = {
  __typename?: 'mutation_root';
  insert_saml_idp?:
    | { __typename?: 'saml_idp_mutation_response'; affected_rows: number }
    | null
    | undefined;
};

export type UpdateSsoConfigMutationVariables = Exact<{
  domain: Scalars['String'];
  provider: Scalars['String'];
  disable_request_signing: Scalars['Boolean'];
  idp_metadata_xml?: InputMaybe<Scalars['String']>;
  idp_metadata_url?: InputMaybe<Scalars['String']>;
  config_id: Scalars['uuid'];
}>;

export type UpdateSsoConfigMutation = {
  __typename?: 'mutation_root';
  update_saml_idp?:
    | {
        __typename?: 'saml_idp_mutation_response';
        returning: Array<{ __typename?: 'saml_idp'; provider: string }>;
      }
    | null
    | undefined;
};

export type AddSurveyAnswerV2MutationVariables = Exact<{
  responses: Array<InputMaybe<SurveyResponseV2>> | InputMaybe<SurveyResponseV2>;
  surveyName: Scalars['String'];
  projectID?: InputMaybe<Scalars['uuid']>;
}>;

export type AddSurveyAnswerV2Mutation = {
  __typename?: 'mutation_root';
  saveSurveyAnswerV2?:
    | { __typename?: 'SuccessOrError'; status: string }
    | null
    | undefined;
};

export type DeleteUserAccountMutationVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type DeleteUserAccountMutation = {
  __typename?: 'mutation_root';
  delete_users?:
    | { __typename?: 'users_mutation_response'; affected_rows: number }
    | null
    | undefined;
};

export type UpdateUserProfileMutationVariables = Exact<{
  object: User_Profile_Insert_Input;
}>;

export type UpdateUserProfileMutation = {
  __typename?: 'mutation_root';
  insert_user_profile_one?:
    | {
        __typename?: 'user_profile';
        name?: string | null | undefined;
        company?: string | null | undefined;
        email?: string | null | undefined;
        phone_number?: string | null | undefined;
        title?: string | null | undefined;
        updated_at: any;
      }
    | null
    | undefined;
};

export type GetHerokuSessionMutationVariables = Exact<{ [key: string]: never }>;

export type GetHerokuSessionMutation = {
  __typename?: 'mutation_root';
  getHerokuSession?:
    | {
        __typename?: 'HerokuSession';
        access_token: string;
        refresh_token: string;
        expires_in: number;
        token_type: string;
      }
    | null
    | undefined;
};

export type ClearHerokuSessionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type ClearHerokuSessionMutation = {
  __typename?: 'mutation_root';
  removeHerokuSession: { __typename?: 'HerokuSessionStatus'; status: string };
};

export type ExchangeHerokuTokenMutationVariables = Exact<{
  code: Scalars['String'];
}>;

export type ExchangeHerokuTokenMutation = {
  __typename?: 'mutation_root';
  herokuTokenExchange?:
    | {
        __typename?: 'HerokuSession';
        access_token: string;
        expires_in: number;
        token_type: string;
        refresh_token: string;
      }
    | null
    | undefined;
};

export type RefreshHerokuTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;

export type RefreshHerokuTokenMutation = {
  __typename?: 'mutation_root';
  herokuTokenExchange?:
    | {
        __typename?: 'HerokuSession';
        access_token: string;
        expires_in: number;
        token_type: string;
        refresh_token: string;
      }
    | null
    | undefined;
};

export type CreatePersonalAccessTokenMutationVariables = Exact<{
  description: Scalars['String'];
}>;

export type CreatePersonalAccessTokenMutation = {
  __typename?: 'mutation_root';
  createPersonalAccessToken: {
    __typename?: 'PersonalAccessToken';
    id: any;
    token: string;
  };
};

export type OnboardUserMutationVariables = Exact<{
  target: Scalars['String'];
  details?: InputMaybe<Scalars['String']>;
}>;

export type OnboardUserMutation = {
  __typename?: 'mutation_root';
  insert_user_onboarding_one?:
    | {
        __typename?: 'user_onboarding';
        user_id: any;
        user: {
          __typename?: 'users';
          id: any;
          email: string;
          customer_id?: string | null | undefined;
          invoice_receipt_email?: string | null | undefined;
          onboarding: Array<{
            __typename?: 'user_onboarding';
            details?: string | null | undefined;
            is_onboarded: boolean;
            target: string;
          }>;
          billing_address?:
            | {
                __typename?: 'billing_address';
                id: any;
                name: string;
                customer_id: string;
                address_line_1: string;
                address_line_2?: string | null | undefined;
                pin_code?: string | null | undefined;
                city: string;
                state: string;
                country: string;
                last_updated_to_stripe: any;
                updated_at?: any | null | undefined;
              }
            | null
            | undefined;
          feature_accesses: Array<{
            __typename?: 'feature_access';
            email: string;
            feature: Feature_Enum;
          }>;
          payment_methods: Array<{
            __typename?: 'payment_method';
            id: string;
            status?: string | null | undefined;
          }>;
          providers: Array<{ __typename?: 'providers'; user_id: any }>;
          invoices: Array<{
            __typename?: 'invoice';
            attempt_count?: number | null | undefined;
            stripe_invoice_id: string;
            invoice_url?: string | null | undefined;
            collection_method: string;
          }>;
          user_profile?:
            | {
                __typename?: 'user_profile';
                name?: string | null | undefined;
                company?: string | null | undefined;
                title?: string | null | undefined;
                email?: string | null | undefined;
                phone_number?: string | null | undefined;
              }
            | null
            | undefined;
          enterprise_users?:
            | { __typename?: 'enterprise_users'; is_active: boolean }
            | null
            | undefined;
          entitlements: Array<{
            __typename?: 'user_entitlement_access';
            entitlement: {
              __typename?: 'user_entitlement_catalogue';
              name: string;
              type: User_Entitlement_Types_Enum;
              config_is_enabled: boolean;
              config_limit?: number | null | undefined;
            };
          }>;
          projects_aggregate: {
            __typename?: 'projects_aggregate';
            aggregate?:
              | { __typename?: 'projects_aggregate_fields'; count: number }
              | null
              | undefined;
          };
        };
      }
    | null
    | undefined;
};

export type RequestEmailChangeMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type RequestEmailChangeMutation = {
  __typename?: 'mutation_root';
  changeUserEmail: { __typename?: 'ChangeUserEmailRequest'; id: any };
};

export type ResendEmailChangeVerificationMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type ResendEmailChangeVerificationMutation = {
  __typename?: 'mutation_root';
  resendChangeUserEmailVerification: {
    __typename?: 'ChangeUserEmailRequest';
    id: any;
  };
};

export type RevokeEmailChangeMutationVariables = Exact<{
  email: Scalars['String'];
}>;

export type RevokeEmailChangeMutation = {
  __typename?: 'mutation_root';
  revokeChangeUserEmailRequest: {
    __typename?: 'ChangeUserEmailRequest';
    id: any;
  };
};

export type VerifyEmailChangeMutationVariables = Exact<{
  token: Scalars['String'];
}>;

export type VerifyEmailChangeMutation = {
  __typename?: 'mutation_root';
  completeChangeUserEmailRequest: {
    __typename?: 'ChangeUserEmailRequest';
    id: any;
    new_email: string;
  };
};

export type RequestRegionMutationVariables = Exact<{
  details: Scalars['jsonb'];
}>;

export type RequestRegionMutation = {
  __typename?: 'mutation_root';
  addFeatureRequest?:
    | { __typename?: 'FeatureRequestResponse'; status: string }
    | null
    | undefined;
};

export type InviteBillingOwnerMutationVariables = Exact<{
  email: Scalars['String'];
  projectId: Scalars['uuid'];
}>;

export type InviteBillingOwnerMutation = {
  __typename?: 'mutation_root';
  inviteBillingManager?:
    | { __typename?: 'BillingManagerInvitation'; project_id: any }
    | null
    | undefined;
};

export type ResendBillingOwnerInviteMutationVariables = Exact<{
  email: Scalars['String'];
  projectId: Scalars['uuid'];
}>;

export type ResendBillingOwnerInviteMutation = {
  __typename?: 'mutation_root';
  resendBillingManagerInvite?:
    | { __typename?: 'BillingManagerInvitation'; project_id: any }
    | null
    | undefined;
};

export type RequestFeatureAccessMutationVariables = Exact<{
  type: Scalars['String'];
  details: Scalars['jsonb'];
}>;

export type RequestFeatureAccessMutation = {
  __typename?: 'mutation_root';
  addFeatureRequest?:
    | { __typename?: 'FeatureRequestResponse'; status: string }
    | null
    | undefined;
};

export type PayPendingInvoiceMutationVariables = Exact<{
  paymentMethodId: Scalars['String'];
  invoiceId: Scalars['String'];
}>;

export type PayPendingInvoiceMutation = {
  __typename?: 'mutation_root';
  payInvoice?:
    | { __typename?: 'PayInvoiceStatus'; status: string }
    | null
    | undefined;
};

export type CreateSupportTicketMutationVariables = Exact<{
  body: Scalars['String'];
  subject: Scalars['String'];
  project_id?: InputMaybe<Scalars['uuid']>;
  priority?: InputMaybe<SupportTicketPriority>;
}>;

export type CreateSupportTicketMutation = {
  __typename?: 'mutation_root';
  createZendeskSupportTicket: { __typename?: 'SuccessOrError'; status: string };
};

export type RequestGcpAccessMutationVariables = Exact<{
  requestDetails: Scalars['jsonb'];
}>;

export type RequestGcpAccessMutation = {
  __typename?: 'mutation_root';
  addFeatureRequest?:
    | { __typename?: 'FeatureRequestResponse'; status: string }
    | null
    | undefined;
};

export type RemoveNeonSessionMutationVariables = Exact<{
  [key: string]: never;
}>;

export type RemoveNeonSessionMutation = {
  __typename?: 'mutation_root';
  neonRemoveSession?:
    | { __typename?: 'SuccessOrError'; status: string }
    | null
    | undefined;
};

export type VercelTokenExchangeMutationVariables = Exact<{
  code: Scalars['String'];
  configurationId: Scalars['String'];
  redirectURI: Scalars['String'];
  teamId?: InputMaybe<Scalars['String']>;
}>;

export type VercelTokenExchangeMutation = {
  __typename?: 'mutation_root';
  vercelTokenExchange?:
    | { __typename?: 'SuccessOrError'; status: string }
    | null
    | undefined;
};

export type VercelSetIntegrationMutationVariables = Exact<{
  input: SetVercelIntegrationInput;
}>;

export type VercelSetIntegrationMutation = {
  __typename?: 'mutation_root';
  vercelSetIntegration?:
    | { __typename?: 'SetVercelIntegrationOutput'; id: string }
    | null
    | undefined;
};

export type UpdateVpcDetailsMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type UpdateVpcDetailsMutation = {
  __typename?: 'mutation_root';
  update_dedicated_vpc?:
    | {
        __typename?: 'dedicated_vpc_mutation_response';
        returning: Array<{
          __typename?: 'dedicated_vpc';
          id: any;
          name: string;
        }>;
      }
    | null
    | undefined;
};

export type InsertVpcMutationVariables = Exact<{
  cidr: Scalars['String'];
  region: Scalars['String'];
  name: Scalars['String'];
  cloud: Scalars['String'];
  planName: Scalars['String'];
}>;

export type InsertVpcMutation = {
  __typename?: 'mutation_root';
  createDedicatedVPC?:
    | { __typename?: 'CreateDedicatedVPCResponse'; id: any }
    | null
    | undefined;
};

export type CreateAwsPeeringRequestMutationVariables = Exact<{
  awsId?: InputMaybe<Scalars['String']>;
  awsVPCId?: InputMaybe<Scalars['String']>;
  awsRegion?: InputMaybe<Scalars['String']>;
  awsCIDR?: InputMaybe<Scalars['String']>;
  awsAdditionalCIDRs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  direction?: InputMaybe<Vpc_Peering_Direction_Enum>;
  name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type CreateAwsPeeringRequestMutation = {
  __typename?: 'mutation_root';
  insert_vpc_peering?:
    | {
        __typename?: 'vpc_peering_mutation_response';
        returning: Array<{
          __typename?: 'vpc_peering';
          name: string;
          status: Vpc_Status_Enum;
          id: any;
          direction: Vpc_Peering_Direction_Enum;
          aws_customer_vpc_id?: string | null | undefined;
          gcp_project_id?: string | null | undefined;
          gcp_vpc_network?: string | null | undefined;
          output_variables?: any | null | undefined;
          azure_input?: any | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type CreateGcpPeeringRequestMutationVariables = Exact<{
  direction: Vpc_Peering_Direction_Enum;
  name: Scalars['String'];
  vpcId: Scalars['uuid'];
  gcpProjectId: Scalars['String'];
  gcpNetwork: Scalars['String'];
}>;

export type CreateGcpPeeringRequestMutation = {
  __typename?: 'mutation_root';
  insert_vpc_peering?:
    | {
        __typename?: 'vpc_peering_mutation_response';
        returning: Array<{
          __typename?: 'vpc_peering';
          name: string;
          status: Vpc_Status_Enum;
          id: any;
          direction: Vpc_Peering_Direction_Enum;
          aws_customer_vpc_id?: string | null | undefined;
          gcp_project_id?: string | null | undefined;
          gcp_vpc_network?: string | null | undefined;
          output_variables?: any | null | undefined;
          azure_input?: any | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type CreateAzurePeeringRequestMutationVariables = Exact<{
  direction: Vpc_Peering_Direction_Enum;
  name: Scalars['String'];
  vpcId: Scalars['uuid'];
  azureInput: Scalars['jsonb'];
}>;

export type CreateAzurePeeringRequestMutation = {
  __typename?: 'mutation_root';
  insert_vpc_peering?:
    | {
        __typename?: 'vpc_peering_mutation_response';
        returning: Array<{
          __typename?: 'vpc_peering';
          name: string;
          status: Vpc_Status_Enum;
          id: any;
          direction: Vpc_Peering_Direction_Enum;
          aws_customer_vpc_id?: string | null | undefined;
          gcp_project_id?: string | null | undefined;
          gcp_vpc_network?: string | null | undefined;
          output_variables?: any | null | undefined;
          azure_input?: any | null | undefined;
        }>;
      }
    | null
    | undefined;
};

export type GetCardDetailsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetCardDetailsSubscription = {
  __typename?: 'subscription_root';
  payment_method: Array<{
    __typename?: 'payment_method';
    id: string;
    is_default: boolean;
    name: string;
    exp_year: number;
    exp_month: number;
    last4: string;
    brand: string;
    status?: string | null | undefined;
  }>;
};

export type GetDefaultCardDetailsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetDefaultCardDetailsSubscription = {
  __typename?: 'subscription_root';
  payment_method: Array<{
    __typename?: 'payment_method';
    id: string;
    is_default: boolean;
    name: string;
    exp_year: number;
    exp_month: number;
    last4: string;
    brand: string;
    status?: string | null | undefined;
  }>;
};

export type GetProjectUsageAndReportSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;

export type GetProjectUsageAndReportSubscription = {
  __typename?: 'subscription_root';
  projects: Array<{
    __typename?: 'projects';
    project_usages: Array<{
      __typename?: 'project_data_usage';
      usage: any;
      date: any;
    }>;
    usage_reports: Array<{
      __typename?: 'project_data_usage_report';
      key: string;
      report_date: any;
    }>;
  }>;
};

export type GetProjectUsageSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;

export type GetProjectUsageSubscription = {
  __typename?: 'subscription_root';
  projects: Array<{
    __typename?: 'projects';
    project_usages: Array<{
      __typename?: 'project_data_usage';
      usage: any;
      date: any;
    }>;
  }>;
};

export type GetReportUrlQueryVariables = Exact<{
  key: Scalars['String'];
}>;

export type GetReportUrlQuery = {
  __typename?: 'query_root';
  getReportURL?:
    | { __typename?: 'GetReportURLResponse'; url: string }
    | null
    | undefined;
};

export type GetLatestInvoiceSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetLatestInvoiceSubscription = {
  __typename?: 'subscription_root';
  invoice: Array<{
    __typename?: 'invoice';
    stripe_invoice_id: string;
    month: number;
    year: number;
    invoice_url?: string | null | undefined;
    receipt_url?: string | null | undefined;
    status: string;
    due_amount?: any | null | undefined;
    collection_method: string;
    items: Array<{ __typename?: 'invoice_item'; amount: any }>;
  }>;
};

export type FetchCardDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type FetchCardDetailsQuery = {
  __typename?: 'query_root';
  payment_method: Array<{
    __typename?: 'payment_method';
    id: string;
    is_default: boolean;
    name: string;
    exp_year: number;
    exp_month: number;
    last4: string;
    brand: string;
    status?: string | null | undefined;
  }>;
};

export type GetUsedCouponsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetUsedCouponsSubscription = {
  __typename?: 'subscription_root';
  user_coupon: Array<{
    __typename?: 'user_coupon';
    amount_off: number;
    is_applied: boolean;
    promotion_code: any;
    created_at: any;
    percentage_off?: any | null | undefined;
    coupon: {
      __typename?: 'coupon';
      duration?: Coupon_Duration_Enum | null | undefined;
      duration_in_months?: number | null | undefined;
    };
  }>;
};

export type GetUsedCreditSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetUsedCreditSubscription = {
  __typename?: 'subscription_root';
  customer_usage: Array<{
    __typename?: 'customer_usage';
    amount: any;
    code?: any | null | undefined;
    created_at: any;
    coupon?:
      | {
          __typename?: 'coupon';
          duration_in_months?: number | null | undefined;
          duration?: Coupon_Duration_Enum | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetInvoicedCouponsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetInvoicedCouponsSubscription = {
  __typename?: 'subscription_root';
  invoice_coupon_discount: Array<{
    __typename?: 'invoice_coupon_discount';
    coupon_code: string;
    created_at: any;
    invoice?:
      | { __typename?: 'invoice'; month: number; year: number }
      | null
      | undefined;
  }>;
};

export type GetPendingInvoiceStatusSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetPendingInvoiceStatusSubscription = {
  __typename?: 'subscription_root';
  invoice: Array<{
    __typename?: 'invoice';
    invoice_url?: string | null | undefined;
    stripe_invoice_id: string;
    attempt_count?: number | null | undefined;
    collection_method: string;
  }>;
};

export type GetInvoiceAndReceiptUrLsQueryVariables = Exact<{
  invoice_id: Scalars['String'];
}>;

export type GetInvoiceAndReceiptUrLsQuery = {
  __typename?: 'query_root';
  getInvoiceAndReceiptURLs?:
    | {
        __typename?: 'invoiceAndReceiptURLsResponse';
        invoice_url?: string | null | undefined;
        receipt_url?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetProjectUsagePrometheusSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;

export type GetProjectUsagePrometheusSubscription = {
  __typename?: 'subscription_root';
  projects: Array<{
    __typename?: 'projects';
    project_usage_prometheus: Array<{
      __typename?: 'project_data_usage_prometheus';
      usage: any;
      date: any;
    }>;
  }>;
};

export type GetProjectModelsQueryVariables = Exact<{
  projectIds: Array<Scalars['String']> | Scalars['String'];
  startDate?: InputMaybe<Scalars['Timestamp']>;
  endDate?: InputMaybe<Scalars['Timestamp']>;
}>;

export type GetProjectModelsQuery = {
  __typename?: 'query_root';
  model_based_pricing_model_snapshot_statistics: Array<{
    __typename?: 'model_based_pricing_model_snapshot_statistics';
    project_id?: string | null | undefined;
    timestamp?: any | null | undefined;
    tables?: number | null | undefined;
    collections?: number | null | undefined;
    logical_models?: number | null | undefined;
  }>;
};

export type FetchProjectAndPrivilegesCollaboratorsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type FetchProjectAndPrivilegesCollaboratorsQuery = {
  __typename?: 'query_root';
  projectCollaboratorsMetadata?:
    | {
        __typename?: 'projects';
        id: any;
        invitations: Array<{
          __typename?: 'project_collaborator_invitations';
          id: any;
          collaborator_email: any;
          privileges?: any | null | undefined;
          key: string;
        }>;
        collaborators: Array<{
          __typename?: 'project_collaborators';
          id: any;
          status: string;
          user?:
            | {
                __typename?: 'users_public';
                id?: any | null | undefined;
                email?: string | null | undefined;
              }
            | null
            | undefined;
          project_collaborator_allowed_schemas: Array<{
            __typename?: 'project_collaborator_allowed_schemas';
            schema: string;
          }>;
          privileges: Array<{
            __typename?: 'project_collaborator_privileges';
            slug: string;
          }>;
        }>;
        project_billing_manager_invitations: Array<{
          __typename?: 'project_billing_manager_invitations';
          manager_email: string;
          key: any;
        }>;
        billing_manager?:
          | { __typename?: 'users_public'; email?: string | null | undefined }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type FetchInvitationProjectCollaborationStatusQueryVariables = Exact<{
  key: Scalars['String'];
  userEmail: Scalars['String'];
  projectId: Scalars['uuid'];
}>;

export type FetchInvitationProjectCollaborationStatusQuery = {
  __typename?: 'query_root';
  project_collaborator_invitations: Array<{
    __typename?: 'project_collaborator_invitations';
    accepted_at?: any | null | undefined;
  }>;
  users: Array<{
    __typename?: 'users';
    email: string;
    collaborated_projects: Array<{
      __typename?: 'project_collaborators';
      project_id: any;
      status: string;
    }>;
  }>;
};

export type FetchBillingOwnerStatusSubscriptionVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type FetchBillingOwnerStatusSubscription = {
  __typename?: 'subscription_root';
  projects: Array<{ __typename?: 'projects'; billing_manager_id: any }>;
};

export type GetFeatureAccessDetailsQueryVariables = Exact<{
  feature?: InputMaybe<Feature_Enum>;
}>;

export type GetFeatureAccessDetailsQuery = {
  __typename?: 'query_root';
  feature_access: Array<{
    __typename?: 'feature_access';
    feature: Feature_Enum;
  }>;
};

export type FetchAllSurveysDataQueryVariables = Exact<{
  currentTime: Scalars['timestamptz'];
}>;

export type FetchAllSurveysDataQuery = {
  __typename?: 'query_root';
  survey_v2: Array<{
    __typename?: 'survey_v2';
    survey_name: string;
    survey_title?: string | null | undefined;
    survey_description?: string | null | undefined;
    template_config?: any | null | undefined;
    survey_questions: Array<{
      __typename?: 'survey_v2_question';
      id: any;
      position: number;
      question: string;
      kind: Survey_V2_Question_Kind_Enum;
      is_mandatory: boolean;
      survey_question_options: Array<{
        __typename?: 'survey_v2_question_option';
        id: any;
        position: number;
        option: string;
        template_config?: any | null | undefined;
        additional_info_config?:
          | {
              __typename?: 'survey_v2_question_option_additional_info_config';
              info_description?: string | null | undefined;
              is_mandatory: boolean;
            }
          | null
          | undefined;
      }>;
    }>;
  }>;
};

export type GetDedicatedCloudCommitmentDetailsQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type GetDedicatedCloudCommitmentDetailsQuery = {
  __typename?: 'query_root';
  dedicated_cloud_commitments: Array<{
    __typename?: 'dedicated_cloud_commitments';
    commitment_start: any;
    commitment_end?: any | null | undefined;
    support_plan: Support_Plan_Types_Enum;
  }>;
};

export type FetchDedicatedCloudBillsQueryVariables = Exact<{
  month?: InputMaybe<Scalars['Int']>;
  year?: InputMaybe<Scalars['Int']>;
}>;

export type FetchDedicatedCloudBillsQuery = {
  __typename?: 'query_root';
  dedicated_cloud_bills: Array<{
    __typename?: 'dedicated_cloud_bills';
    compute_unit_config_id: any;
    cu_cost: number;
    data_passthrough_cost: number;
    month: number;
    year: number;
    details: Array<{
      __typename?: 'dedicated_cloud_bills_details';
      details?: any | null | undefined;
    }>;
  }>;
};

export type GetProjectDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetProjectDetailsQuery = {
  __typename?: 'query_root';
  project?:
    | {
        __typename?: 'projects';
        id: any;
        name: string;
        updated_at: any;
        plan_name?: string | null | undefined;
        invoice_status?: string | null | undefined;
        invoice_attempt_count?: number | null | undefined;
        endpoint: string;
        created_at: any;
        assigned_at?: any | null | undefined;
        current_month_data_usage?: any | null | undefined;
        number_of_requests_this_month?: any | null | undefined;
        owner?:
          | {
              __typename?: 'users_public';
              id?: any | null | undefined;
              email?: string | null | undefined;
              enterprise_user?:
                | {
                    __typename?: 'enterprise_users';
                    is_active: boolean;
                    is_exempt_from_billing: boolean;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        tenant?:
          | {
              __typename?: 'tenant';
              id: any;
              created_at: any;
              updated_at: any;
              health_status?: any | null | undefined;
              major_version: number;
              active: boolean;
              active_status_reason?: string | null | undefined;
              dedicated_vpc_id?: any | null | undefined;
              maintenance_mode: boolean;
              region_info?:
                | {
                    __typename?: 'region';
                    name: string;
                    nat_ip?: string | null | undefined;
                    nat_ips?: any | null | undefined;
                    cloud: Cloud_Enum;
                  }
                | null
                | undefined;
              config?:
                | {
                    __typename?: 'config';
                    updated_at: any;
                    status: Array<{
                      __typename?: 'config_status';
                      is_active: boolean;
                      message?: string | null | undefined;
                    }>;
                  }
                | null
                | undefined;
              custom_domains: Array<{
                __typename?: 'custom_domain';
                id: any;
                fqdn: string;
              }>;
              tenant_public_status: Array<{
                __typename?: 'tenant_public_status';
                endpoint: string;
                type: string;
                is_active: boolean;
                message?: string | null | undefined;
              }>;
              dedicated_vpc?:
                | {
                    __typename?: 'dedicated_vpc';
                    id: any;
                    plan_name: string;
                    cloud: string;
                    created_at: any;
                    owner_id: any;
                    region: string;
                    name: string;
                    status: Vpc_Status_Enum;
                    gcp_network_name?: string | null | undefined;
                    nat_ip?: string | null | undefined;
                    cidr?: string | null | undefined;
                    nat_ips?: any | null | undefined;
                    vpc_id?: string | null | undefined;
                    vpc_cidr?: string | null | undefined;
                    owner: { __typename?: 'users'; id: any; email: string };
                    collaborators: Array<{
                      __typename?: 'vpc_collaborators';
                      collaborator_id: any;
                      privilege_slug: string;
                      status: string;
                      collaborator?:
                        | {
                            __typename?: 'users_public';
                            email?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }>;
                    collaborator_invitations: Array<{
                      __typename?: 'vpc_collaborator_invitations';
                      status: string;
                      privilege_slug: string;
                      collaborator_email: any;
                      id: any;
                    }>;
                    region_info?:
                      | {
                          __typename?: 'region';
                          name: string;
                          nat_ip?: string | null | undefined;
                          nat_ips?: any | null | undefined;
                          cloud: Cloud_Enum;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        metadata?:
          | {
              __typename?: 'project_metadata';
              connected_heroku_app_name?: string | null | undefined;
              heroku_managed_mode: boolean;
              environment_name?: string | null | undefined;
              environment_color?: string | null | undefined;
            }
          | null
          | undefined;
        collaborators: Array<{
          __typename?: 'project_collaborators';
          id: any;
          status: string;
          collaborator_id: any;
          project_collaborator_privileges: Array<{
            __typename?: 'project_collaborator_privileges';
            privilege_slug: string;
          }>;
        }>;
        ownership_transfer_invites: Array<{
          __typename?: 'project_ownership_transfer_invitations';
          invitee_email: string;
          key: string;
        }>;
        datadog_config?:
          | { __typename?: 'datadog_config'; project_id: any }
          | null
          | undefined;
        newrelic_config?:
          | { __typename?: 'newrelic_config'; project_id: any }
          | null
          | undefined;
        azuremonitor_config?:
          | { __typename?: 'azuremonitor_config'; project_id: any }
          | null
          | undefined;
        labels: Array<{
          __typename?: 'project_labels';
          label: {
            __typename?: 'label';
            id: any;
            name: string;
            color?: string | null | undefined;
            created_by: any;
          };
        }>;
        heroku_integrations: Array<{
          __typename?: 'heroku_integrations';
          app_name: string;
          var_name: string;
        }>;
        billing_manager?:
          | {
              __typename?: 'users_public';
              email?: string | null | undefined;
              id?: any | null | undefined;
            }
          | null
          | undefined;
        project_usages: Array<{
          __typename?: 'project_data_usage';
          usage: any;
        }>;
        jobs: Array<{
          __typename?: 'jobs';
          id: any;
          status: string;
          job_type: string;
        }>;
        vercel_integration_connections: Array<{
          __typename?: 'vercel_integration_connections';
          id: any;
          status: string;
        }>;
        entitlements: Array<{
          __typename?: 'project_entitlement_access';
          entitlement_id: any;
          id: any;
          entitlement: {
            __typename?: 'project_entitlement_catalogue';
            config_is_enabled: boolean;
            config_limit?: any | null | undefined;
            name: string;
            type: Project_Entitlement_Types_Enum;
            id: any;
          };
        }>;
      }
    | null
    | undefined;
  allLabels: Array<{
    __typename?: 'label';
    id: any;
    name: string;
    color?: string | null | undefined;
    created_by: any;
  }>;
};

export type GetSupportProjectsListQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetSupportProjectsListQuery = {
  __typename?: 'query_root';
  projects: Array<{
    __typename?: 'projects';
    id: any;
    name: string;
    plan_name?: string | null | undefined;
  }>;
};

export type GetProjectTenantDetailsQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetProjectTenantDetailsQuery = {
  __typename?: 'query_root';
  project?:
    | {
        __typename?: 'projects';
        id: any;
        name: string;
        updated_at: any;
        plan_name?: string | null | undefined;
        invoice_status?: string | null | undefined;
        invoice_attempt_count?: number | null | undefined;
        endpoint: string;
        created_at: any;
        assigned_at?: any | null | undefined;
        current_month_data_usage?: any | null | undefined;
        number_of_requests_this_month?: any | null | undefined;
        owner?:
          | {
              __typename?: 'users_public';
              id?: any | null | undefined;
              email?: string | null | undefined;
              enterprise_user?:
                | {
                    __typename?: 'enterprise_users';
                    is_active: boolean;
                    is_exempt_from_billing: boolean;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        tenant?:
          | {
              __typename?: 'tenant';
              id: any;
              created_at: any;
              updated_at: any;
              health_status?: any | null | undefined;
              major_version: number;
              active: boolean;
              active_status_reason?: string | null | undefined;
              dedicated_vpc_id?: any | null | undefined;
              maintenance_mode: boolean;
              region_info?:
                | {
                    __typename?: 'region';
                    name: string;
                    nat_ip?: string | null | undefined;
                    nat_ips?: any | null | undefined;
                    cloud: Cloud_Enum;
                  }
                | null
                | undefined;
              config?:
                | {
                    __typename?: 'config';
                    updated_at: any;
                    status: Array<{
                      __typename?: 'config_status';
                      is_active: boolean;
                      message?: string | null | undefined;
                    }>;
                  }
                | null
                | undefined;
              custom_domains: Array<{
                __typename?: 'custom_domain';
                id: any;
                fqdn: string;
              }>;
              tenant_public_status: Array<{
                __typename?: 'tenant_public_status';
                endpoint: string;
                type: string;
                is_active: boolean;
                message?: string | null | undefined;
              }>;
              dedicated_vpc?:
                | {
                    __typename?: 'dedicated_vpc';
                    id: any;
                    plan_name: string;
                    cloud: string;
                    created_at: any;
                    owner_id: any;
                    region: string;
                    name: string;
                    status: Vpc_Status_Enum;
                    gcp_network_name?: string | null | undefined;
                    nat_ip?: string | null | undefined;
                    cidr?: string | null | undefined;
                    nat_ips?: any | null | undefined;
                    vpc_id?: string | null | undefined;
                    vpc_cidr?: string | null | undefined;
                    owner: { __typename?: 'users'; id: any; email: string };
                    collaborators: Array<{
                      __typename?: 'vpc_collaborators';
                      collaborator_id: any;
                      privilege_slug: string;
                      status: string;
                      collaborator?:
                        | {
                            __typename?: 'users_public';
                            email?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }>;
                    collaborator_invitations: Array<{
                      __typename?: 'vpc_collaborator_invitations';
                      status: string;
                      privilege_slug: string;
                      collaborator_email: any;
                      id: any;
                    }>;
                    region_info?:
                      | {
                          __typename?: 'region';
                          name: string;
                          nat_ip?: string | null | undefined;
                          nat_ips?: any | null | undefined;
                          cloud: Cloud_Enum;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        metadata?:
          | {
              __typename?: 'project_metadata';
              connected_heroku_app_name?: string | null | undefined;
              heroku_managed_mode: boolean;
              environment_name?: string | null | undefined;
              environment_color?: string | null | undefined;
            }
          | null
          | undefined;
        collaborators: Array<{
          __typename?: 'project_collaborators';
          id: any;
          status: string;
          collaborator_id: any;
          project_collaborator_privileges: Array<{
            __typename?: 'project_collaborator_privileges';
            privilege_slug: string;
          }>;
        }>;
        ownership_transfer_invites: Array<{
          __typename?: 'project_ownership_transfer_invitations';
          invitee_email: string;
          key: string;
        }>;
        datadog_config?:
          | { __typename?: 'datadog_config'; project_id: any }
          | null
          | undefined;
        newrelic_config?:
          | { __typename?: 'newrelic_config'; project_id: any }
          | null
          | undefined;
        azuremonitor_config?:
          | { __typename?: 'azuremonitor_config'; project_id: any }
          | null
          | undefined;
        labels: Array<{
          __typename?: 'project_labels';
          label: {
            __typename?: 'label';
            id: any;
            name: string;
            color?: string | null | undefined;
            created_by: any;
          };
        }>;
        heroku_integrations: Array<{
          __typename?: 'heroku_integrations';
          app_name: string;
          var_name: string;
        }>;
        billing_manager?:
          | {
              __typename?: 'users_public';
              email?: string | null | undefined;
              id?: any | null | undefined;
            }
          | null
          | undefined;
        project_usages: Array<{
          __typename?: 'project_data_usage';
          usage: any;
        }>;
        jobs: Array<{
          __typename?: 'jobs';
          id: any;
          status: string;
          job_type: string;
        }>;
        vercel_integration_connections: Array<{
          __typename?: 'vercel_integration_connections';
          id: any;
          status: string;
        }>;
        entitlements: Array<{
          __typename?: 'project_entitlement_access';
          entitlement_id: any;
          id: any;
          entitlement: {
            __typename?: 'project_entitlement_catalogue';
            config_is_enabled: boolean;
            config_limit?: any | null | undefined;
            name: string;
            type: Project_Entitlement_Types_Enum;
            id: any;
          };
        }>;
      }
    | null
    | undefined;
};

export type GetTenantEnvQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetTenantEnvQuery = {
  __typename?: 'query_root';
  getTenantEnv?:
    | { __typename?: 'TenantEnv'; hash: string; envVars: any }
    | null
    | undefined;
};

export type FetchPendingInvitationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchPendingInvitationsQuery = {
  __typename?: 'query_root';
  fetchPendingInvitations: Array<{
    __typename?: 'UserInvitation';
    id: any;
    invitedAt: string;
    projectName: string;
    invitedBy: string;
    key: string;
    privileges: Array<string>;
    isBillable: boolean;
    projectID: any;
  }>;
};

export type GetAdminSecretQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetAdminSecretQuery = {
  __typename?: 'query_root';
  getTenantEnv?:
    | { __typename?: 'TenantEnv'; hash: string; envVars: any }
    | null
    | undefined;
};

export type RealtimeTenantStatusSubscriptionVariables = Exact<{
  tenantId: Scalars['uuid'];
}>;

export type RealtimeTenantStatusSubscription = {
  __typename?: 'subscription_root';
  tenant?:
    | {
        __typename?: 'tenant';
        id: any;
        created_at: any;
        updated_at: any;
        health_status?: any | null | undefined;
        major_version: number;
        active: boolean;
        active_status_reason?: string | null | undefined;
        dedicated_vpc_id?: any | null | undefined;
        maintenance_mode: boolean;
        region_info?:
          | {
              __typename?: 'region';
              name: string;
              nat_ip?: string | null | undefined;
              nat_ips?: any | null | undefined;
              cloud: Cloud_Enum;
            }
          | null
          | undefined;
        config?:
          | {
              __typename?: 'config';
              updated_at: any;
              status: Array<{
                __typename?: 'config_status';
                is_active: boolean;
                message?: string | null | undefined;
              }>;
            }
          | null
          | undefined;
        custom_domains: Array<{
          __typename?: 'custom_domain';
          id: any;
          fqdn: string;
        }>;
        tenant_public_status: Array<{
          __typename?: 'tenant_public_status';
          endpoint: string;
          type: string;
          is_active: boolean;
          message?: string | null | undefined;
        }>;
        dedicated_vpc?:
          | {
              __typename?: 'dedicated_vpc';
              id: any;
              plan_name: string;
              cloud: string;
              created_at: any;
              owner_id: any;
              region: string;
              name: string;
              status: Vpc_Status_Enum;
              gcp_network_name?: string | null | undefined;
              nat_ip?: string | null | undefined;
              cidr?: string | null | undefined;
              nat_ips?: any | null | undefined;
              vpc_id?: string | null | undefined;
              vpc_cidr?: string | null | undefined;
              owner: { __typename?: 'users'; id: any; email: string };
              collaborators: Array<{
                __typename?: 'vpc_collaborators';
                collaborator_id: any;
                privilege_slug: string;
                status: string;
                collaborator?:
                  | {
                      __typename?: 'users_public';
                      email?: string | null | undefined;
                    }
                  | null
                  | undefined;
              }>;
              collaborator_invitations: Array<{
                __typename?: 'vpc_collaborator_invitations';
                status: string;
                privilege_slug: string;
                collaborator_email: any;
                id: any;
              }>;
              region_info?:
                | {
                    __typename?: 'region';
                    name: string;
                    nat_ip?: string | null | undefined;
                    nat_ips?: any | null | undefined;
                    cloud: Cloud_Enum;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type GetCustomDomainsSubscriptionVariables = Exact<{
  tenantId: Scalars['uuid'];
}>;

export type GetCustomDomainsSubscription = {
  __typename?: 'subscription_root';
  domains: Array<{
    __typename?: 'custom_domain';
    id: any;
    fqdn: string;
    dns_validation: string;
    ssl_status?: string | null | undefined;
    ssl_message?: string | null | undefined;
  }>;
};

export type GetUsageDetailsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetUsageDetailsSubscription = {
  __typename?: 'subscription_root';
  projects: Array<{
    __typename?: 'projects';
    plan_name?: string | null | undefined;
    created_at: any;
    collaborators_aggregate: {
      __typename?: 'project_collaborators_aggregate';
      aggregate?:
        | {
            __typename?: 'project_collaborators_aggregate_fields';
            count: number;
          }
        | null
        | undefined;
    };
    policies: Array<{
      __typename?: 'policies';
      log_retention: number;
      caching_enabled: boolean;
    }>;
  }>;
};

export type GetRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetRegionsQuery = {
  __typename?: 'query_root';
  region: Array<{
    __typename?: 'region';
    name: string;
    nat_ip?: string | null | undefined;
    nat_ips?: any | null | undefined;
    cloud: Cloud_Enum;
  }>;
  region_v2: Array<{
    __typename?: 'region_v2';
    region?:
      | {
          __typename?: 'region';
          name: string;
          nat_ip?: string | null | undefined;
          nat_ips?: any | null | undefined;
          cloud: Cloud_Enum;
        }
      | null
      | undefined;
  }>;
};

export type GetLabelSuggestionsQueryVariables = Exact<{
  name: Scalars['String'];
}>;

export type GetLabelSuggestionsQuery = {
  __typename?: 'query_root';
  label: Array<{ __typename?: 'label'; name: string }>;
};

export type ValidateTenantNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;

export type ValidateTenantNameQuery = {
  __typename?: 'query_root';
  validTenantName?:
    | {
        __typename?: 'ValidTenantNameResponse';
        message: string;
        valid: boolean;
      }
    | null
    | undefined;
};

export type FetchPendingOwnershipInvitationsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FetchPendingOwnershipInvitationsQuery = {
  __typename?: 'query_root';
  fetchPendingOwnershipInvitations: Array<{
    __typename?: 'ProjectOwnershipInvitation';
    id: any;
    invitedAt: string;
    invitedBy: string;
    key: string;
    projectName: string;
    projectPlan: string;
  }>;
};

export type GetProjectOwnerSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type GetProjectOwnerSubscription = {
  __typename?: 'subscription_root';
  projects: Array<{
    __typename?: 'projects';
    owner?:
      | { __typename?: 'users_public'; email?: string | null | undefined }
      | null
      | undefined;
  }>;
};

export type GetDatadogConfigSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type GetDatadogConfigSubscription = {
  __typename?: 'subscription_root';
  datadog_config: Array<{
    __typename?: 'datadog_config';
    host?: string | null | undefined;
    service_name?: string | null | undefined;
    tags?: string | null | undefined;
    project_id: any;
    logs_last_logged: any;
    key: string;
    datadog_region: string;
    logs_response_code?: number | null | undefined;
  }>;
};

export type Newrelic_ConfigSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type Newrelic_ConfigSubscription = {
  __typename?: 'subscription_root';
  newrelic_config: Array<{
    __typename?: 'newrelic_config';
    attributes: any;
    key: string;
    logs_last_logged: any;
    logs_response_code?: number | null | undefined;
    metrics_last_logged: any;
    metrics_response_code?: number | null | undefined;
    newrelic_region: string;
    project_id: any;
  }>;
};

export type Azuremonitor_ConfigSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type Azuremonitor_ConfigSubscription = {
  __typename?: 'subscription_root';
  azuremonitor_config: Array<{
    __typename?: 'azuremonitor_config';
    attributes: any;
    azure_region: string;
    activedirectory_client_id: string;
    activedirectory_client_secret: string;
    activedirectory_tenant_id: string;
    azuremonitor_workspace_id: string;
    log_type: string;
    metric_namespace: string;
    shared_key: string;
    resource_id: string;
    logs_last_logged: any;
    logs_response_code?: number | null | undefined;
    metrics_last_logged: any;
    metrics_response_code?: number | null | undefined;
    project_id: any;
    instrumentation_key?: string | null | undefined;
    traces_last_logged: any;
    traces_response_code?: number | null | undefined;
    traces_response_message?: string | null | undefined;
  }>;
};

export type Prometheus_ConfigSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type Prometheus_ConfigSubscription = {
  __typename?: 'subscription_root';
  prometheus_config: Array<{
    __typename?: 'prometheus_config';
    project_id: any;
    labels: any;
    metrics_last_logged?: any | null | undefined;
    metrics_response_code?: number | null | undefined;
    namespace?: string | null | undefined;
  }>;
};

export type Opentelemetry_ConfigSubscriptionVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
}>;

export type Opentelemetry_ConfigSubscription = {
  __typename?: 'subscription_root';
  opentelemetry_config: Array<{
    __typename?: 'opentelemetry_config';
    project_id: any;
    endpoint: string;
    headers?: any | null | undefined;
    attributes?: any | null | undefined;
    batch_size?: number | null | undefined;
    connection_type: any;
    export_traces: boolean;
    traces_last_exported?: any | null | undefined;
    traces_response_code?: number | null | undefined;
    traces_response_message?: string | null | undefined;
  }>;
};

export type Heroku_IntegrationsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type Heroku_IntegrationsSubscription = {
  __typename?: 'subscription_root';
  heroku_integrations: Array<{
    __typename?: 'heroku_integrations';
    var_name: string;
    app_name: string;
  }>;
};

export type GetVpcProjectsQueryVariables = Exact<{
  vpcId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetVpcProjectsQuery = {
  __typename?: 'query_root';
  projects: Array<{
    __typename?: 'projects';
    id: any;
    name: string;
    updated_at: any;
    plan_name?: string | null | undefined;
    invoice_status?: string | null | undefined;
    invoice_attempt_count?: number | null | undefined;
    endpoint: string;
    created_at: any;
    assigned_at?: any | null | undefined;
    current_month_data_usage?: any | null | undefined;
    number_of_requests_this_month?: any | null | undefined;
    owner?:
      | {
          __typename?: 'users_public';
          id?: any | null | undefined;
          email?: string | null | undefined;
          enterprise_user?:
            | {
                __typename?: 'enterprise_users';
                is_active: boolean;
                is_exempt_from_billing: boolean;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    tenant?:
      | {
          __typename?: 'tenant';
          id: any;
          created_at: any;
          updated_at: any;
          health_status?: any | null | undefined;
          major_version: number;
          active: boolean;
          active_status_reason?: string | null | undefined;
          dedicated_vpc_id?: any | null | undefined;
          maintenance_mode: boolean;
          region_info?:
            | {
                __typename?: 'region';
                name: string;
                nat_ip?: string | null | undefined;
                nat_ips?: any | null | undefined;
                cloud: Cloud_Enum;
              }
            | null
            | undefined;
          config?:
            | {
                __typename?: 'config';
                updated_at: any;
                status: Array<{
                  __typename?: 'config_status';
                  is_active: boolean;
                  message?: string | null | undefined;
                }>;
              }
            | null
            | undefined;
          custom_domains: Array<{
            __typename?: 'custom_domain';
            id: any;
            fqdn: string;
          }>;
          tenant_public_status: Array<{
            __typename?: 'tenant_public_status';
            endpoint: string;
            type: string;
            is_active: boolean;
            message?: string | null | undefined;
          }>;
          dedicated_vpc?:
            | {
                __typename?: 'dedicated_vpc';
                id: any;
                plan_name: string;
                cloud: string;
                created_at: any;
                owner_id: any;
                region: string;
                name: string;
                status: Vpc_Status_Enum;
                gcp_network_name?: string | null | undefined;
                nat_ip?: string | null | undefined;
                cidr?: string | null | undefined;
                nat_ips?: any | null | undefined;
                vpc_id?: string | null | undefined;
                vpc_cidr?: string | null | undefined;
                owner: { __typename?: 'users'; id: any; email: string };
                collaborators: Array<{
                  __typename?: 'vpc_collaborators';
                  collaborator_id: any;
                  privilege_slug: string;
                  status: string;
                  collaborator?:
                    | {
                        __typename?: 'users_public';
                        email?: string | null | undefined;
                      }
                    | null
                    | undefined;
                }>;
                collaborator_invitations: Array<{
                  __typename?: 'vpc_collaborator_invitations';
                  status: string;
                  privilege_slug: string;
                  collaborator_email: any;
                  id: any;
                }>;
                region_info?:
                  | {
                      __typename?: 'region';
                      name: string;
                      nat_ip?: string | null | undefined;
                      nat_ips?: any | null | undefined;
                      cloud: Cloud_Enum;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    metadata?:
      | {
          __typename?: 'project_metadata';
          connected_heroku_app_name?: string | null | undefined;
          heroku_managed_mode: boolean;
          environment_name?: string | null | undefined;
          environment_color?: string | null | undefined;
        }
      | null
      | undefined;
    collaborators: Array<{
      __typename?: 'project_collaborators';
      id: any;
      status: string;
      collaborator_id: any;
      project_collaborator_privileges: Array<{
        __typename?: 'project_collaborator_privileges';
        privilege_slug: string;
      }>;
    }>;
    ownership_transfer_invites: Array<{
      __typename?: 'project_ownership_transfer_invitations';
      invitee_email: string;
      key: string;
    }>;
    datadog_config?:
      | { __typename?: 'datadog_config'; project_id: any }
      | null
      | undefined;
    newrelic_config?:
      | { __typename?: 'newrelic_config'; project_id: any }
      | null
      | undefined;
    azuremonitor_config?:
      | { __typename?: 'azuremonitor_config'; project_id: any }
      | null
      | undefined;
    labels: Array<{
      __typename?: 'project_labels';
      label: {
        __typename?: 'label';
        id: any;
        name: string;
        color?: string | null | undefined;
        created_by: any;
      };
    }>;
    heroku_integrations: Array<{
      __typename?: 'heroku_integrations';
      app_name: string;
      var_name: string;
    }>;
    billing_manager?:
      | {
          __typename?: 'users_public';
          email?: string | null | undefined;
          id?: any | null | undefined;
        }
      | null
      | undefined;
    project_usages: Array<{ __typename?: 'project_data_usage'; usage: any }>;
    jobs: Array<{
      __typename?: 'jobs';
      id: any;
      status: string;
      job_type: string;
    }>;
    vercel_integration_connections: Array<{
      __typename?: 'vercel_integration_connections';
      id: any;
      status: string;
    }>;
    entitlements: Array<{
      __typename?: 'project_entitlement_access';
      entitlement_id: any;
      id: any;
      entitlement: {
        __typename?: 'project_entitlement_catalogue';
        config_is_enabled: boolean;
        config_limit?: any | null | undefined;
        name: string;
        type: Project_Entitlement_Types_Enum;
        id: any;
      };
    }>;
  }>;
  allLabels: Array<{
    __typename?: 'label';
    id: any;
    name: string;
    color?: string | null | undefined;
    created_by: any;
  }>;
  dedicated_vpc: Array<{
    __typename?: 'dedicated_vpc';
    id: any;
    plan_name: string;
    cloud: string;
    created_at: any;
    owner_id: any;
    region: string;
    name: string;
    status: Vpc_Status_Enum;
    gcp_network_name?: string | null | undefined;
    nat_ip?: string | null | undefined;
    cidr?: string | null | undefined;
    nat_ips?: any | null | undefined;
    vpc_id?: string | null | undefined;
    vpc_cidr?: string | null | undefined;
    vpc_peerings: Array<{
      __typename?: 'vpc_peering';
      name: string;
      status: Vpc_Status_Enum;
      id: any;
      direction: Vpc_Peering_Direction_Enum;
      aws_customer_vpc_id?: string | null | undefined;
      gcp_project_id?: string | null | undefined;
      gcp_vpc_network?: string | null | undefined;
      output_variables?: any | null | undefined;
      azure_input?: any | null | undefined;
    }>;
    owner: { __typename?: 'users'; id: any; email: string };
    collaborators: Array<{
      __typename?: 'vpc_collaborators';
      collaborator_id: any;
      privilege_slug: string;
      status: string;
      collaborator?:
        | { __typename?: 'users_public'; email?: string | null | undefined }
        | null
        | undefined;
    }>;
    collaborator_invitations: Array<{
      __typename?: 'vpc_collaborator_invitations';
      status: string;
      privilege_slug: string;
      collaborator_email: any;
      id: any;
    }>;
    region_info?:
      | {
          __typename?: 'region';
          name: string;
          nat_ip?: string | null | undefined;
          nat_ips?: any | null | undefined;
          cloud: Cloud_Enum;
        }
      | null
      | undefined;
  }>;
};

export type GetJobStatusSubscriptionVariables = Exact<{
  jobId: Scalars['uuid'];
}>;

export type GetJobStatusSubscription = {
  __typename?: 'subscription_root';
  jobs_by_pk?:
    | {
        __typename?: 'jobs';
        id: any;
        job_type: string;
        project_id?: any | null | undefined;
        status: string;
        created_at: any;
        tasks: Array<{
          __typename?: 'tasks';
          id: any;
          name: string;
          cloud: string;
          region: string;
          task_events: Array<{
            __typename?: 'task_event';
            event_type: string;
            id: any;
            error?: string | null | undefined;
            github_detail?: string | null | undefined;
            public_event_data?: any | null | undefined;
          }>;
        }>;
        github_push_event_jobs: Array<{
          __typename?: 'github_push_event_job';
          github_push_event: {
            __typename?: 'github_push_event';
            commit: string;
            commit_message: string;
            commit_timestamp: any;
            github_integration_config: {
              __typename?: 'github_integration_config';
              organisation: string;
              repository: string;
              branch: string;
              directory: string;
              id: any;
            };
          };
        }>;
      }
    | null
    | undefined;
};

export type FetchJobIdQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type FetchJobIdQuery = {
  __typename?: 'query_root';
  jobs: Array<{
    __typename?: 'jobs';
    id: any;
    status: string;
    job_type: string;
  }>;
};

export type FetchGithubConfigSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type FetchGithubConfigSubscription = {
  __typename?: 'subscription_root';
  github_integration_config: Array<{
    __typename?: 'github_integration_config';
    directory: string;
    branch: string;
    organisation: string;
    repository: string;
    app_installation_id?: number | null | undefined;
    id: any;
    mode: Github_Integration_Mode_Enum;
    email_status: Github_Email_Type_Enum;
  }>;
};

export type FetchGithubCommitsSubscriptionVariables = Exact<{
  id: Scalars['uuid'];
}>;

export type FetchGithubCommitsSubscription = {
  __typename?: 'subscription_root';
  github_push_event_job: Array<{
    __typename?: 'github_push_event_job';
    job_id: any;
    github_push_event: {
      __typename?: 'github_push_event';
      commit: string;
      commit_message: string;
      commit_timestamp: any;
      id: any;
    };
    job: { __typename?: 'jobs'; status: string; created_at: any };
  }>;
};

export type FetchLatestCommitSubscriptionVariables = Exact<{
  integrationId: Scalars['uuid'];
  pushMetadata: Scalars['jsonb'];
}>;

export type FetchLatestCommitSubscription = {
  __typename?: 'subscription_root';
  github_push_event: Array<{
    __typename?: 'github_push_event';
    id: any;
    commit: string;
    commit_message: string;
    commit_timestamp: any;
    github_push_event_jobs: Array<{
      __typename?: 'github_push_event_job';
      job: { __typename?: 'jobs'; status: string };
    }>;
  }>;
};

export type GetProjectMigrationStatusQueryVariables = Exact<{
  projectId: Scalars['String'];
}>;

export type GetProjectMigrationStatusQuery = {
  __typename?: 'query_root';
  getProjectMigrationStatus?:
    | Array<
        | {
            __typename?: 'ProjectMigrationStatus';
            datasource: string;
            migration: string;
          }
        | null
        | undefined
      >
    | null
    | undefined;
};

export type RealtimeProjectStatusSubscriptionVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type RealtimeProjectStatusSubscription = {
  __typename?: 'subscription_root';
  projects_by_pk?:
    | {
        __typename?: 'projects';
        id: any;
        name: string;
        updated_at: any;
        plan_name?: string | null | undefined;
        invoice_status?: string | null | undefined;
        invoice_attempt_count?: number | null | undefined;
        endpoint: string;
        created_at: any;
        assigned_at?: any | null | undefined;
        current_month_data_usage?: any | null | undefined;
        number_of_requests_this_month?: any | null | undefined;
        owner?:
          | {
              __typename?: 'users_public';
              id?: any | null | undefined;
              email?: string | null | undefined;
              enterprise_user?:
                | {
                    __typename?: 'enterprise_users';
                    is_active: boolean;
                    is_exempt_from_billing: boolean;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        tenant?:
          | {
              __typename?: 'tenant';
              id: any;
              created_at: any;
              updated_at: any;
              health_status?: any | null | undefined;
              major_version: number;
              active: boolean;
              active_status_reason?: string | null | undefined;
              dedicated_vpc_id?: any | null | undefined;
              maintenance_mode: boolean;
              region_info?:
                | {
                    __typename?: 'region';
                    name: string;
                    nat_ip?: string | null | undefined;
                    nat_ips?: any | null | undefined;
                    cloud: Cloud_Enum;
                  }
                | null
                | undefined;
              config?:
                | {
                    __typename?: 'config';
                    updated_at: any;
                    status: Array<{
                      __typename?: 'config_status';
                      is_active: boolean;
                      message?: string | null | undefined;
                    }>;
                  }
                | null
                | undefined;
              custom_domains: Array<{
                __typename?: 'custom_domain';
                id: any;
                fqdn: string;
              }>;
              tenant_public_status: Array<{
                __typename?: 'tenant_public_status';
                endpoint: string;
                type: string;
                is_active: boolean;
                message?: string | null | undefined;
              }>;
              dedicated_vpc?:
                | {
                    __typename?: 'dedicated_vpc';
                    id: any;
                    plan_name: string;
                    cloud: string;
                    created_at: any;
                    owner_id: any;
                    region: string;
                    name: string;
                    status: Vpc_Status_Enum;
                    gcp_network_name?: string | null | undefined;
                    nat_ip?: string | null | undefined;
                    cidr?: string | null | undefined;
                    nat_ips?: any | null | undefined;
                    vpc_id?: string | null | undefined;
                    vpc_cidr?: string | null | undefined;
                    owner: { __typename?: 'users'; id: any; email: string };
                    collaborators: Array<{
                      __typename?: 'vpc_collaborators';
                      collaborator_id: any;
                      privilege_slug: string;
                      status: string;
                      collaborator?:
                        | {
                            __typename?: 'users_public';
                            email?: string | null | undefined;
                          }
                        | null
                        | undefined;
                    }>;
                    collaborator_invitations: Array<{
                      __typename?: 'vpc_collaborator_invitations';
                      status: string;
                      privilege_slug: string;
                      collaborator_email: any;
                      id: any;
                    }>;
                    region_info?:
                      | {
                          __typename?: 'region';
                          name: string;
                          nat_ip?: string | null | undefined;
                          nat_ips?: any | null | undefined;
                          cloud: Cloud_Enum;
                        }
                      | null
                      | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
        metadata?:
          | {
              __typename?: 'project_metadata';
              connected_heroku_app_name?: string | null | undefined;
              heroku_managed_mode: boolean;
              environment_name?: string | null | undefined;
              environment_color?: string | null | undefined;
            }
          | null
          | undefined;
        collaborators: Array<{
          __typename?: 'project_collaborators';
          id: any;
          status: string;
          collaborator_id: any;
          project_collaborator_privileges: Array<{
            __typename?: 'project_collaborator_privileges';
            privilege_slug: string;
          }>;
        }>;
        ownership_transfer_invites: Array<{
          __typename?: 'project_ownership_transfer_invitations';
          invitee_email: string;
          key: string;
        }>;
        datadog_config?:
          | { __typename?: 'datadog_config'; project_id: any }
          | null
          | undefined;
        newrelic_config?:
          | { __typename?: 'newrelic_config'; project_id: any }
          | null
          | undefined;
        azuremonitor_config?:
          | { __typename?: 'azuremonitor_config'; project_id: any }
          | null
          | undefined;
        labels: Array<{
          __typename?: 'project_labels';
          label: {
            __typename?: 'label';
            id: any;
            name: string;
            color?: string | null | undefined;
            created_by: any;
          };
        }>;
        heroku_integrations: Array<{
          __typename?: 'heroku_integrations';
          app_name: string;
          var_name: string;
        }>;
        billing_manager?:
          | {
              __typename?: 'users_public';
              email?: string | null | undefined;
              id?: any | null | undefined;
            }
          | null
          | undefined;
        project_usages: Array<{
          __typename?: 'project_data_usage';
          usage: any;
        }>;
        jobs: Array<{
          __typename?: 'jobs';
          id: any;
          status: string;
          job_type: string;
        }>;
        vercel_integration_connections: Array<{
          __typename?: 'vercel_integration_connections';
          id: any;
          status: string;
        }>;
        entitlements: Array<{
          __typename?: 'project_entitlement_access';
          entitlement_id: any;
          id: any;
          entitlement: {
            __typename?: 'project_entitlement_catalogue';
            config_is_enabled: boolean;
            config_limit?: any | null | undefined;
            name: string;
            type: Project_Entitlement_Types_Enum;
            id: any;
          };
        }>;
      }
    | null
    | undefined;
};

export type GetGcpRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetGcpRegionsQuery = {
  __typename?: 'query_root';
  region_v2: Array<{
    __typename?: 'region_v2';
    name?: string | null | undefined;
    region?:
      | {
          __typename?: 'region';
          name: string;
          cloud: Cloud_Enum;
          nat_ips?: any | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type GetProjectDbCountQueryVariables = Exact<{
  projectId: Scalars['uuid'];
}>;

export type GetProjectDbCountQuery = {
  __typename?: 'query_root';
  getNumberOfConnectedSources?:
    | {
        __typename?: 'NumberOfConnectedSourcesOutput';
        databases_connected?: number | null | undefined;
        project_id?: any | null | undefined;
        plan_name?: string | null | undefined;
        unsupported?: boolean | null | undefined;
      }
    | null
    | undefined;
};

export type ProjectDbUsageQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;

export type ProjectDbUsageQuery = {
  __typename?: 'query_root';
  project_db_usage: Array<{
    __typename?: 'project_db_usage';
    no_db_usage: number;
    non_pg_usage: number;
    pg_usage: number;
    created_at: any;
    updated_at: any;
  }>;
};

export type GetProjectDataUsagePrometheusQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;

export type GetProjectDataUsagePrometheusQuery = {
  __typename?: 'query_root';
  projects: Array<{
    __typename?: 'projects';
    project_usage_prometheus: Array<{
      __typename?: 'project_data_usage_prometheus';
      date: any;
      usage_updated_at: any;
      usage: any;
      data_usage_components?:
        | {
            __typename?: 'project_data_usage_components';
            date: any;
            project_id: any;
            hasura_action_request_bytes_total: any;
            hasura_action_response_bytes_total: any;
            hasura_event_trigger_request_bytes_total: any;
            hasura_event_trigger_response_bytes_total: any;
            hasura_http_request_bytes_total: any;
            hasura_http_response_bytes_total: any;
            hasura_scheduled_trigger_request_bytes_total: any;
            hasura_scheduled_trigger_response_bytes_total: any;
            hasura_websocket_messages_received_bytes_total: any;
            hasura_websocket_messages_sent_bytes_total: any;
            usage_updated_at: any;
          }
        | null
        | undefined;
    }>;
  }>;
};

export type GetProjectRequestsCountQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  startDate: Scalars['date'];
  endDate: Scalars['date'];
}>;

export type GetProjectRequestsCountQuery = {
  __typename?: 'query_root';
  project_requests_count: Array<{
    __typename?: 'project_requests_count';
    hasura_graphql_requests_total: any;
    created_at: any;
    updated_at: any;
  }>;
};

export type GetProjectsListQueryVariables = Exact<{
  offset: Scalars['Int'];
  nameRegex?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetProjectsListQuery = {
  __typename?: 'query_root';
  projects_aggregate: {
    __typename?: 'projects_aggregate';
    aggregate?:
      | { __typename?: 'projects_aggregate_fields'; count: number }
      | null
      | undefined;
  };
  projects: Array<{
    __typename?: 'projects';
    assigned_at?: any | null | undefined;
    created_at: any;
    current_month_data_usage?: any | null | undefined;
    endpoint: string;
    id: any;
    invoice_attempt_count?: number | null | undefined;
    invoice_status?: string | null | undefined;
    name: string;
    number_of_requests_this_month?: any | null | undefined;
    plan_name?: string | null | undefined;
    updated_at: any;
    billing_manager?:
      | {
          __typename?: 'users_public';
          email?: string | null | undefined;
          id?: any | null | undefined;
        }
      | null
      | undefined;
    collaborators: Array<{
      __typename?: 'project_collaborators';
      collaborator_id: any;
      id: any;
      status: string;
      project_collaborator_privileges: Array<{
        __typename?: 'project_collaborator_privileges';
        privilege_slug: string;
      }>;
    }>;
    entitlements: Array<{
      __typename?: 'project_entitlement_access';
      entitlement_id: any;
      id: any;
      entitlement: {
        __typename?: 'project_entitlement_catalogue';
        config_is_enabled: boolean;
        config_limit?: any | null | undefined;
        name: string;
        type: Project_Entitlement_Types_Enum;
        id: any;
      };
    }>;
    jobs: Array<{
      __typename?: 'jobs';
      id: any;
      job_type: string;
      status: string;
    }>;
    labels: Array<{
      __typename?: 'project_labels';
      label: {
        __typename?: 'label';
        id: any;
        name: string;
        color?: string | null | undefined;
        created_by: any;
      };
    }>;
    owner?:
      | {
          __typename?: 'users_public';
          id?: any | null | undefined;
          email?: string | null | undefined;
          enterprise_user?:
            | {
                __typename?: 'enterprise_users';
                is_active: boolean;
                is_exempt_from_billing: boolean;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
    tenant?:
      | {
          __typename?: 'tenant';
          active: boolean;
          active_status_reason?: string | null | undefined;
          health_status?: any | null | undefined;
          id: any;
          major_version: number;
          maintenance_mode: boolean;
          updated_at: any;
          config?:
            | { __typename?: 'config'; updated_at: any }
            | null
            | undefined;
          dedicated_vpc?:
            | { __typename?: 'dedicated_vpc'; name: string; id: any }
            | null
            | undefined;
          region_info?:
            | { __typename?: 'region'; name: string; cloud: Cloud_Enum }
            | null
            | undefined;
        }
      | null
      | undefined;
    vercel_integration_connections: Array<{
      __typename?: 'vercel_integration_connections';
      status: string;
    }>;
  }>;
  allLabels: Array<{
    __typename?: 'label';
    id: any;
    name: string;
    color?: string | null | undefined;
    created_by: any;
  }>;
};

export type GetCloudRegionsByPlanQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCloudRegionsByPlanQuery = {
  __typename?: 'query_root';
  region: Array<{
    __typename?: 'region';
    name: string;
    nat_ip?: string | null | undefined;
    nat_ips?: any | null | undefined;
    cloud: Cloud_Enum;
  }>;
  cloud_regions_by_plan: Array<{
    __typename?: 'cloud_regions_by_plan';
    plan_name?: string | null | undefined;
    region?:
      | {
          __typename?: 'region';
          name: string;
          nat_ip?: string | null | undefined;
          nat_ips?: any | null | undefined;
          cloud: Cloud_Enum;
        }
      | null
      | undefined;
  }>;
};

export type GetProjectsListBaseQueryVariables = Exact<{
  offset: Scalars['Int'];
  nameRegex?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;

export type GetProjectsListBaseQuery = {
  __typename?: 'query_root';
  user_project_map_aggregate: {
    __typename?: 'user_project_map_aggregate';
    aggregate?:
      | { __typename?: 'user_project_map_aggregate_fields'; count: number }
      | null
      | undefined;
  };
  user_project_map: Array<{
    __typename?: 'user_project_map';
    project?:
      | {
          __typename?: 'projects';
          assigned_at?: any | null | undefined;
          created_at: any;
          endpoint: string;
          id: any;
          name: string;
          plan_name?: string | null | undefined;
          updated_at: any;
          billing_manager?:
            | {
                __typename?: 'users_public';
                email?: string | null | undefined;
                id?: any | null | undefined;
              }
            | null
            | undefined;
          collaborators: Array<{
            __typename?: 'project_collaborators';
            collaborator_id: any;
            id: any;
            status: string;
            project_collaborator_privileges: Array<{
              __typename?: 'project_collaborator_privileges';
              privilege_slug: string;
            }>;
          }>;
          entitlements: Array<{
            __typename?: 'project_entitlement_access';
            entitlement_id: any;
            id: any;
            entitlement: {
              __typename?: 'project_entitlement_catalogue';
              config_is_enabled: boolean;
              config_limit?: any | null | undefined;
              name: string;
              type: Project_Entitlement_Types_Enum;
              id: any;
            };
          }>;
          jobs: Array<{
            __typename?: 'jobs';
            id: any;
            job_type: string;
            status: string;
          }>;
          labels: Array<{
            __typename?: 'project_labels';
            label: {
              __typename?: 'label';
              id: any;
              name: string;
              color?: string | null | undefined;
              created_by: any;
            };
          }>;
          owner?:
            | {
                __typename?: 'users_public';
                id?: any | null | undefined;
                email?: string | null | undefined;
              }
            | null
            | undefined;
          tenant?:
            | {
                __typename?: 'tenant';
                active: boolean;
                active_status_reason?: string | null | undefined;
                health_status?: any | null | undefined;
                id: any;
                major_version: number;
                maintenance_mode: boolean;
                updated_at: any;
                config?:
                  | { __typename?: 'config'; updated_at: any }
                  | null
                  | undefined;
                dedicated_vpc?:
                  | { __typename?: 'dedicated_vpc'; name: string; id: any }
                  | null
                  | undefined;
                region_info?:
                  | { __typename?: 'region'; name: string; cloud: Cloud_Enum }
                  | null
                  | undefined;
              }
            | null
            | undefined;
          vercel_integration_connections: Array<{
            __typename?: 'vercel_integration_connections';
            status: string;
          }>;
        }
      | null
      | undefined;
  }>;
  allLabels: Array<{
    __typename?: 'label';
    id: any;
    name: string;
    color?: string | null | undefined;
    created_by: any;
  }>;
};

export type GetProjectCardInformationQueryVariables = Exact<{
  projectId: Scalars['uuid'];
  tenantId: Scalars['uuid'];
}>;

export type GetProjectCardInformationQuery = {
  __typename?: 'query_root';
  getNumberOfConnectedSources?:
    | {
        __typename?: 'NumberOfConnectedSourcesOutput';
        databases_connected?: number | null | undefined;
        project_id?: any | null | undefined;
        plan_name?: string | null | undefined;
        unsupported?: boolean | null | undefined;
      }
    | null
    | undefined;
  getTenantEnv?:
    | { __typename?: 'TenantEnv'; hash: string; envVars: any }
    | null
    | undefined;
  project?:
    | {
        __typename?: 'projects';
        id: any;
        number_of_requests_this_month?: any | null | undefined;
        invoice_status?: string | null | undefined;
        invoice_attempt_count?: number | null | undefined;
        current_month_data_usage?: any | null | undefined;
      }
    | null
    | undefined;
};

export type ModelBasedProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type ModelBasedProjectsQuery = {
  __typename?: 'query_root';
  projects: Array<{ __typename?: 'projects'; id: any; name: string }>;
};

export type GetSsoConfigQueryVariables = Exact<{ [key: string]: never }>;

export type GetSsoConfigQuery = {
  __typename?: 'query_root';
  saml_idp: Array<{
    __typename?: 'saml_idp';
    id: any;
    provider: string;
    domain: string;
    idp_metadata_url?: string | null | undefined;
    idp_metadata_xml?: string | null | undefined;
    disable_request_signing: boolean;
  }>;
};

export type UserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type UserInfoQuery = {
  __typename?: 'query_root';
  users: Array<{
    __typename?: 'users';
    id: any;
    email: string;
    customer_id?: string | null | undefined;
    invoice_receipt_email?: string | null | undefined;
    onboarding: Array<{
      __typename?: 'user_onboarding';
      details?: string | null | undefined;
      is_onboarded: boolean;
      target: string;
    }>;
    billing_address?:
      | {
          __typename?: 'billing_address';
          id: any;
          name: string;
          customer_id: string;
          address_line_1: string;
          address_line_2?: string | null | undefined;
          pin_code?: string | null | undefined;
          city: string;
          state: string;
          country: string;
          last_updated_to_stripe: any;
          updated_at?: any | null | undefined;
        }
      | null
      | undefined;
    feature_accesses: Array<{
      __typename?: 'feature_access';
      email: string;
      feature: Feature_Enum;
    }>;
    payment_methods: Array<{
      __typename?: 'payment_method';
      id: string;
      status?: string | null | undefined;
    }>;
    providers: Array<{ __typename?: 'providers'; user_id: any }>;
    invoices: Array<{
      __typename?: 'invoice';
      attempt_count?: number | null | undefined;
      stripe_invoice_id: string;
      invoice_url?: string | null | undefined;
      collection_method: string;
    }>;
    user_profile?:
      | {
          __typename?: 'user_profile';
          name?: string | null | undefined;
          company?: string | null | undefined;
          title?: string | null | undefined;
          email?: string | null | undefined;
          phone_number?: string | null | undefined;
        }
      | null
      | undefined;
    enterprise_users?:
      | { __typename?: 'enterprise_users'; is_active: boolean }
      | null
      | undefined;
    entitlements: Array<{
      __typename?: 'user_entitlement_access';
      entitlement: {
        __typename?: 'user_entitlement_catalogue';
        name: string;
        type: User_Entitlement_Types_Enum;
        config_is_enabled: boolean;
        config_limit?: number | null | undefined;
      };
    }>;
    projects_aggregate: {
      __typename?: 'projects_aggregate';
      aggregate?:
        | { __typename?: 'projects_aggregate_fields'; count: number }
        | null
        | undefined;
    };
  }>;
};

export type GetPersonalAccessTokensSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type GetPersonalAccessTokensSubscription = {
  __typename?: 'subscription_root';
  user_personal_access_tokens: Array<{
    __typename?: 'user_personal_access_tokens';
    id: any;
    description: string;
    is_active: boolean;
    created_at: any;
    last_used?: any | null | undefined;
    last_used_ip_address?: string | null | undefined;
  }>;
};

export type GetEmailChangeRequestsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetEmailChangeRequestsQuery = {
  __typename?: 'query_root';
  change_email_request: Array<{
    __typename?: 'change_email_request';
    id: any;
    status: string;
    new_email: string;
    verified: boolean;
    resend_count: number;
    user?:
      | {
          __typename?: 'users';
          id: any;
          email: string;
          customer_id?: string | null | undefined;
          invoice_receipt_email?: string | null | undefined;
          onboarding: Array<{
            __typename?: 'user_onboarding';
            details?: string | null | undefined;
            is_onboarded: boolean;
            target: string;
          }>;
          billing_address?:
            | {
                __typename?: 'billing_address';
                id: any;
                name: string;
                customer_id: string;
                address_line_1: string;
                address_line_2?: string | null | undefined;
                pin_code?: string | null | undefined;
                city: string;
                state: string;
                country: string;
                last_updated_to_stripe: any;
                updated_at?: any | null | undefined;
              }
            | null
            | undefined;
          feature_accesses: Array<{
            __typename?: 'feature_access';
            email: string;
            feature: Feature_Enum;
          }>;
          payment_methods: Array<{
            __typename?: 'payment_method';
            id: string;
            status?: string | null | undefined;
          }>;
          providers: Array<{ __typename?: 'providers'; user_id: any }>;
          invoices: Array<{
            __typename?: 'invoice';
            attempt_count?: number | null | undefined;
            stripe_invoice_id: string;
            invoice_url?: string | null | undefined;
            collection_method: string;
          }>;
          user_profile?:
            | {
                __typename?: 'user_profile';
                name?: string | null | undefined;
                company?: string | null | undefined;
                title?: string | null | undefined;
                email?: string | null | undefined;
                phone_number?: string | null | undefined;
              }
            | null
            | undefined;
          enterprise_users?:
            | { __typename?: 'enterprise_users'; is_active: boolean }
            | null
            | undefined;
          entitlements: Array<{
            __typename?: 'user_entitlement_access';
            entitlement: {
              __typename?: 'user_entitlement_catalogue';
              name: string;
              type: User_Entitlement_Types_Enum;
              config_is_enabled: boolean;
              config_limit?: number | null | undefined;
            };
          }>;
          projects_aggregate: {
            __typename?: 'projects_aggregate';
            aggregate?:
              | { __typename?: 'projects_aggregate_fields'; count: number }
              | null
              | undefined;
          };
        }
      | null
      | undefined;
  }>;
};

export type FetchUserSupportTicketDetailsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;

export type FetchUserSupportTicketDetailsQuery = {
  __typename?: 'query_root';
  fetchUserSupportTicketDetails?:
    | Array<{
        __typename?: 'SupportTicketDetails';
        id: number;
        body: string;
        status: string;
        subject: string;
        created_at: string;
        project_id?: any | null | undefined;
        project_name?: string | null | undefined;
        zendesk_link: string;
        total_tickets: number;
      }>
    | null
    | undefined;
};

export type GetNeonUserInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetNeonUserInfoQuery = {
  __typename?: 'query_root';
  neonGetUserInfo?:
    | {
        __typename?: 'NeonGetUserInfoResponse';
        isAuthenticated: boolean;
        email?: string | null | undefined;
      }
    | null
    | undefined;
};

export type GetUserActionActivityQueryVariables = Exact<{
  user_id: Scalars['uuid'];
  action: Scalars['user_action'];
}>;

export type GetUserActionActivityQuery = {
  __typename?: 'query_root';
  user_activity: Array<{
    __typename?: 'user_activity';
    metadata?: any | null | undefined;
  }>;
};

export type ModelBasedCommitmentsQueryVariables = Exact<{
  userId: Scalars['uuid'];
}>;

export type ModelBasedCommitmentsQuery = {
  __typename?: 'query_root';
  model_based_enterprise_cloud_commitments: Array<{
    __typename?: 'model_based_enterprise_cloud_commitments';
    support_plan: Support_Plan_Types_Enum;
    commitment_start: any;
    commitment_end: any;
    yearly_commitment?: number | null | undefined;
    logical_model_unit_price?: number | null | undefined;
    table_unit_price?: number | null | undefined;
    collection_unit_price?: number | null | undefined;
  }>;
};

export type GetVercelConfigurationStatusSubscriptionSubscriptionVariables = Exact<{
  configurationId: Scalars['String'];
}>;

export type GetVercelConfigurationStatusSubscriptionSubscription = {
  __typename?: 'subscription_root';
  vercel_integration: Array<{
    __typename?: 'vercel_integration';
    status?: string | null | undefined;
    vercel_integration_connections: Array<{
      __typename?: 'vercel_integration_connections';
      status: string;
      hasura_cloud_project_id?: any | null | undefined;
      vercel_project_id: string;
      project?:
        | { __typename?: 'projects'; id: any; name: string }
        | null
        | undefined;
    }>;
  }>;
};

export type VercelProjectsQueryVariables = Exact<{
  configurationId: Scalars['String'];
  teamId?: InputMaybe<Scalars['String']>;
}>;

export type VercelProjectsQuery = {
  __typename?: 'query_root';
  vercelGetProjects?:
    | {
        __typename?: 'VercelGetProjectsOutput';
        vercelProjects: Array<{
          __typename?: 'VercelProjectInfo';
          projectId: string;
          projectName: string;
        }>;
      }
    | null
    | undefined;
};

export type GetVercelConfigurationStatusQueryVariables = Exact<{
  configurationId: Scalars['String'];
}>;

export type GetVercelConfigurationStatusQuery = {
  __typename?: 'query_root';
  vercel_integration: Array<{
    __typename?: 'vercel_integration';
    status?: string | null | undefined;
    vercel_integration_connections: Array<{
      __typename?: 'vercel_integration_connections';
      id: any;
      hasura_cloud_project_id?: any | null | undefined;
      vercel_project_id: string;
      status: string;
    }>;
  }>;
};

export type GetHasuraProjectsQueryVariables = Exact<{ [key: string]: never }>;

export type GetHasuraProjectsQuery = {
  __typename?: 'query_root';
  projects: Array<{ __typename?: 'projects'; id: any; name: string }>;
};

export type WatchDedicatedVpCsSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type WatchDedicatedVpCsSubscription = {
  __typename?: 'subscription_root';
  dedicated_vpc: Array<{
    __typename?: 'dedicated_vpc';
    id: any;
    plan_name: string;
    cloud: string;
    created_at: any;
    owner_id: any;
    region: string;
    name: string;
    status: Vpc_Status_Enum;
    gcp_network_name?: string | null | undefined;
    nat_ip?: string | null | undefined;
    cidr?: string | null | undefined;
    nat_ips?: any | null | undefined;
    vpc_id?: string | null | undefined;
    vpc_cidr?: string | null | undefined;
    vpc_peerings: Array<{
      __typename?: 'vpc_peering';
      name: string;
      status: Vpc_Status_Enum;
      id: any;
      direction: Vpc_Peering_Direction_Enum;
      aws_customer_vpc_id?: string | null | undefined;
      gcp_project_id?: string | null | undefined;
      gcp_vpc_network?: string | null | undefined;
      output_variables?: any | null | undefined;
      azure_input?: any | null | undefined;
    }>;
    tenants_aggregate: {
      __typename?: 'tenant_aggregate';
      aggregate?:
        | { __typename?: 'tenant_aggregate_fields'; count: number }
        | null
        | undefined;
    };
    owner: { __typename?: 'users'; id: any; email: string };
    collaborators: Array<{
      __typename?: 'vpc_collaborators';
      collaborator_id: any;
      privilege_slug: string;
      status: string;
      collaborator?:
        | { __typename?: 'users_public'; email?: string | null | undefined }
        | null
        | undefined;
    }>;
    collaborator_invitations: Array<{
      __typename?: 'vpc_collaborator_invitations';
      status: string;
      privilege_slug: string;
      collaborator_email: any;
      id: any;
    }>;
    region_info?:
      | {
          __typename?: 'region';
          name: string;
          nat_ip?: string | null | undefined;
          nat_ips?: any | null | undefined;
          cloud: Cloud_Enum;
        }
      | null
      | undefined;
  }>;
};

export type GetAwsIdQueryVariables = Exact<{ [key: string]: never }>;

export type GetAwsIdQuery = {
  __typename?: 'query_root';
  cloud_metadata: Array<{
    __typename?: 'cloud_metadata';
    aws_account_id?: string | null | undefined;
  }>;
};

export type GetAwsRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAwsRegionsQuery = {
  __typename?: 'query_root';
  region_v2: Array<{
    __typename?: 'region_v2';
    region?:
      | {
          __typename?: 'region';
          name: string;
          nat_ip?: string | null | undefined;
          nat_ips?: any | null | undefined;
          cloud: Cloud_Enum;
        }
      | null
      | undefined;
  }>;
};

export type GetUserVpcLimitQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetUserVpcLimitQuery = {
  __typename?: 'query_root';
  user_vpc_policy: Array<{
    __typename?: 'user_vpc_policy';
    vpc_limit: number;
    plan_name: string;
  }>;
};

export type GetVpcCountQueryVariables = Exact<{
  userId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetVpcCountQuery = {
  __typename?: 'query_root';
  dedicated_vpc_aggregate: {
    __typename?: 'dedicated_vpc_aggregate';
    aggregate?:
      | { __typename?: 'dedicated_vpc_aggregate_fields'; count: number }
      | null
      | undefined;
  };
};

export type GetAwsVpcPeeringDetailsQueryVariables = Exact<{
  id?: InputMaybe<Scalars['uuid']>;
  dedicatedVpcId?: InputMaybe<Scalars['uuid']>;
}>;

export type GetAwsVpcPeeringDetailsQuery = {
  __typename?: 'query_root';
  vpc_peering: Array<{
    __typename?: 'vpc_peering';
    id: any;
    aws_customer_account_id?: string | null | undefined;
    aws_customer_vpc_additional_cidrs: Array<string>;
    aws_customer_vpc_cidr?: string | null | undefined;
    aws_customer_vpc_id?: string | null | undefined;
    aws_customer_vpc_region?: string | null | undefined;
    dedicated_vpc_id: any;
    name: string;
  }>;
};

export type InviteVpcCollaboratorMutationVariables = Exact<{
  invitee_email: Scalars['String'];
  access_level: VpcCollaboratorAccessLevel;
  vpc_id: Scalars['uuid'];
}>;

export type InviteVpcCollaboratorMutation = {
  __typename?: 'mutation_root';
  inviteVPCCollaborator?:
    | {
        __typename?: 'InviteVPCCollaboratorResponse';
        message: string;
        status: string;
      }
    | null
    | undefined;
};

export type FetchVpcPendingInvitationsQueryVariables = Exact<{
  collaborator_email: Scalars['citext'];
  status: Scalars['String'];
}>;

export type FetchVpcPendingInvitationsQuery = {
  __typename?: 'query_root';
  vpc_collaborator_invitations: Array<{
    __typename?: 'vpc_collaborator_invitations';
    accepted_at?: any | null | undefined;
    collaborator_email: any;
    id: any;
    invited_by: any;
    privilege_slug: string;
    status: string;
    vpc_id: any;
    vpc_name: string;
    invited_by_email: any;
    dedicated_vpc: {
      __typename?: 'dedicated_vpc';
      id: any;
      name: string;
      owner_id: any;
      region: string;
      region_info?:
        | { __typename?: 'region'; cloud: Cloud_Enum; name: string }
        | null
        | undefined;
    };
    invited_by_user: { __typename?: 'users'; email: string };
  }>;
};

export type AcceptVpcInviteMutationVariables = Exact<{
  invitation_id: Scalars['uuid'];
}>;

export type AcceptVpcInviteMutation = {
  __typename?: 'mutation_root';
  acceptVPCCollaboratorInvitation?:
    | {
        __typename?: 'AcceptVPCCollaboratorInvitationResponse';
        message: string;
        status: string;
      }
    | null
    | undefined;
};

export type DeclineVpcInviteMutationVariables = Exact<{
  invitation_id: Scalars['uuid'];
}>;

export type DeclineVpcInviteMutation = {
  __typename?: 'mutation_root';
  declineVPCCollaboratorInvitation?:
    | {
        __typename?: 'DeclineVPCCollaboratorInvitationResponse';
        message: string;
        status: string;
      }
    | null
    | undefined;
};

export type RevokeVpcInviteMutationVariables = Exact<{
  invitation_id: Scalars['uuid'];
}>;

export type RevokeVpcInviteMutation = {
  __typename?: 'mutation_root';
  revokeVPCCollaboratorInvitation?:
    | {
        __typename?: 'RevokeVPCCollaboratorInvitationResponse';
        message: string;
        status: string;
      }
    | null
    | undefined;
};

export type DeleteVpcCollaboratorMutationVariables = Exact<{
  collaborator_id: Scalars['uuid'];
  vpc_id: Scalars['uuid'];
  remove_collaborator_from_projects_list:
    | Array<Scalars['uuid']>
    | Scalars['uuid'];
}>;

export type DeleteVpcCollaboratorMutation = {
  __typename?: 'mutation_root';
  removeVPCCollaborator?:
    | {
        __typename?: 'RemoveVPCCollaboratorResponse';
        message: string;
        status: string;
      }
    | null
    | undefined;
};

export type GetVpcOwnerQueryVariables = Exact<{
  domain: Scalars['String'];
}>;

export type GetVpcOwnerQuery = {
  __typename?: 'query_root';
  dedicated_vpc_owner: Array<{
    __typename?: 'dedicated_vpc_owner';
    domain?: string | null | undefined;
    vpc_owner_email?: string | null | undefined;
  }>;
};

export const VpcPeeringFieldsFragmentDoc = gql`
  fragment VPCPeeringFields on vpc_peering {
    name
    status
    id
    direction
    aws_customer_vpc_id
    gcp_project_id
    gcp_vpc_network
    output_variables
    azure_input
  }
`;
export const RegionFieldsFragmentDoc = gql`
  fragment RegionFields on region {
    name
    nat_ip
    nat_ips
    cloud
  }
`;
export const DedicateVpcFieldsFragmentDoc = gql`
  fragment DedicateVPCFields on dedicated_vpc {
    id
    plan_name
    cloud
    created_at
    owner_id
    owner {
      id
      email
    }
    collaborators {
      collaborator_id
      privilege_slug
      status
      collaborator {
        email
      }
    }
    collaborator_invitations {
      status
      privilege_slug
      collaborator_email
      id
    }
    region
    region_info {
      ...RegionFields
    }
    name
    status
    gcp_network_name
    nat_ip
    cidr
    nat_ips
    vpc_id
    vpc_cidr
  }
  ${RegionFieldsFragmentDoc}
`;
export const TenantFieldsFragmentDoc = gql`
  fragment TenantFields on tenant {
    id
    created_at
    updated_at
    health_status
    region_info {
      ...RegionFields
    }
    config {
      status {
        is_active
        message
      }
      updated_at
    }
    major_version
    active
    active_status_reason
    custom_domains {
      id
      fqdn
    }
    tenant_public_status {
      endpoint
      type
      is_active
      message
    }
    dedicated_vpc_id
    dedicated_vpc {
      ...DedicateVPCFields
    }
    maintenance_mode
  }
  ${RegionFieldsFragmentDoc}
  ${DedicateVpcFieldsFragmentDoc}
`;
export const LabelFieldsFragmentDoc = gql`
  fragment LabelFields on label {
    id
    name
    color
    created_by
  }
`;
export const ProjectFieldsFragmentDoc = gql`
  fragment ProjectFields on projects {
    id
    name
    updated_at
    plan_name
    invoice_status
    invoice_attempt_count
    owner {
      id
      email
      enterprise_user {
        is_active
        is_exempt_from_billing
      }
    }
    endpoint
    created_at
    assigned_at
    tenant {
      ...TenantFields
    }
    metadata {
      connected_heroku_app_name
      heroku_managed_mode
      environment_name
      environment_color
    }
    collaborators(where: { status: { _eq: "active" } }) {
      id
      status
      project_collaborator_privileges {
        privilege_slug
      }
      collaborator_id
    }
    ownership_transfer_invites(
      where: {
        accepted_at: { _is_null: true }
        declined_at: { _is_null: true }
      }
    ) {
      invitee_email
      key
    }
    datadog_config {
      project_id
    }
    newrelic_config {
      project_id
    }
    azuremonitor_config {
      project_id
    }
    labels {
      label {
        ...LabelFields
      }
    }
    heroku_integrations {
      app_name
      var_name
    }
    billing_manager {
      email
      id
    }
    project_usages {
      usage
    }
    jobs(order_by: { updated_at: desc }) {
      id
      status
      job_type
    }
    vercel_integration_connections {
      id
      status
    }
    entitlements {
      entitlement_id
      id
      entitlement {
        config_is_enabled
        config_limit
        name
        type
        id
      }
    }
    current_month_data_usage
    number_of_requests_this_month
  }
  ${TenantFieldsFragmentDoc}
  ${LabelFieldsFragmentDoc}
`;
export const UserFieldsFragmentDoc = gql`
  fragment UserFields on users {
    id
    email
    onboarding {
      details
      is_onboarded
      target
    }
    customer_id
    billing_address {
      id
      name
      customer_id
      address_line_1
      address_line_2
      pin_code
      city
      state
      country
      last_updated_to_stripe
      updated_at
    }
    feature_accesses {
      email
      feature
    }
    payment_methods {
      id
      status
    }
    providers {
      user_id
    }
    invoice_receipt_email
    invoices(
      where: { status: { _eq: "open" } }
      order_by: { created_at: desc }
    ) {
      attempt_count
      stripe_invoice_id
      invoice_url
      collection_method
    }
    user_profile {
      name
      company
      title
      email
      phone_number
    }
    enterprise_users {
      is_active
    }
    entitlements {
      entitlement {
        name
        type
        config_is_enabled
        config_limit
      }
    }
    projects_aggregate(where: { plan_name: { _eq: "cloud_free_v2" } }) {
      aggregate {
        count
      }
    }
  }
`;
export const ProjectsListFieldsFragmentDoc = gql`
  fragment ProjectsListFields on projects {
    assigned_at
    billing_manager {
      email
      id
    }
    collaborators(where: { status: { _eq: "active" } }) {
      collaborator_id
      id
      status
      project_collaborator_privileges {
        privilege_slug
      }
    }
    created_at
    current_month_data_usage
    endpoint
    entitlements {
      entitlement {
        config_is_enabled
        config_limit
        name
        type
        id
      }
      entitlement_id
      id
    }
    id
    invoice_attempt_count
    invoice_status
    jobs(order_by: { updated_at: desc }) {
      id
      job_type
      status
    }
    labels {
      label {
        ...LabelFields
      }
    }
    name
    number_of_requests_this_month
    owner {
      id
      email
      enterprise_user {
        is_active
        is_exempt_from_billing
      }
    }
    plan_name
    tenant {
      active
      active_status_reason
      config {
        updated_at
      }
      dedicated_vpc {
        name
        id
      }
      health_status
      id
      major_version
      maintenance_mode
      region_info {
        name
        cloud
      }
      updated_at
    }
    updated_at
    vercel_integration_connections {
      status
    }
  }
  ${LabelFieldsFragmentDoc}
`;
export const ProjectsListBaseFieldsFragmentDoc = gql`
  fragment ProjectsListBaseFields on projects {
    assigned_at
    billing_manager {
      email
      id
    }
    collaborators(where: { status: { _eq: "active" } }) {
      collaborator_id
      id
      status
      project_collaborator_privileges {
        privilege_slug
      }
    }
    created_at
    endpoint
    entitlements {
      entitlement {
        config_is_enabled
        config_limit
        name
        type
        id
      }
      entitlement_id
      id
    }
    id
    jobs(order_by: { updated_at: desc }) {
      id
      job_type
      status
    }
    labels {
      label {
        ...LabelFields
      }
    }
    name
    owner {
      id
      email
    }
    plan_name
    tenant {
      active
      active_status_reason
      config {
        updated_at
      }
      dedicated_vpc {
        name
        id
      }
      health_status
      id
      major_version
      maintenance_mode
      region_info {
        name
        cloud
      }
      updated_at
    }
    updated_at
    vercel_integration_connections {
      status
    }
  }
  ${LabelFieldsFragmentDoc}
`;
export const InitiateStripeAddCardProcessDocument = gql`
  mutation initiateStripeAddCardProcess {
    initiateStripeAddCardProcess {
      client_secret
    }
  }
`;
export type InitiateStripeAddCardProcessMutationFn = Apollo.MutationFunction<
  InitiateStripeAddCardProcessMutation,
  InitiateStripeAddCardProcessMutationVariables
>;

/**
 * __useInitiateStripeAddCardProcessMutation__
 *
 * To run a mutation, you first call `useInitiateStripeAddCardProcessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateStripeAddCardProcessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateStripeAddCardProcessMutation, { data, loading, error }] = useInitiateStripeAddCardProcessMutation({
 *   variables: {
 *   },
 * });
 */
export function useInitiateStripeAddCardProcessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InitiateStripeAddCardProcessMutation,
    InitiateStripeAddCardProcessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InitiateStripeAddCardProcessMutation,
    InitiateStripeAddCardProcessMutationVariables
  >(InitiateStripeAddCardProcessDocument, options);
}
export type InitiateStripeAddCardProcessMutationHookResult = ReturnType<
  typeof useInitiateStripeAddCardProcessMutation
>;
export type InitiateStripeAddCardProcessMutationResult = Apollo.MutationResult<
  InitiateStripeAddCardProcessMutation
>;
export type InitiateStripeAddCardProcessMutationOptions = Apollo.BaseMutationOptions<
  InitiateStripeAddCardProcessMutation,
  InitiateStripeAddCardProcessMutationVariables
>;
export const AddCardDocument = gql`
  mutation addCard($id: String!, $isDefault: Boolean!) {
    addCard(id: $id, is_default: $isDefault) {
      status
      id
    }
  }
`;
export type AddCardMutationFn = Apollo.MutationFunction<
  AddCardMutation,
  AddCardMutationVariables
>;

/**
 * __useAddCardMutation__
 *
 * To run a mutation, you first call `useAddCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCardMutation, { data, loading, error }] = useAddCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isDefault: // value for 'isDefault'
 *   },
 * });
 */
export function useAddCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddCardMutation,
    AddCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddCardMutation, AddCardMutationVariables>(
    AddCardDocument,
    options
  );
}
export type AddCardMutationHookResult = ReturnType<typeof useAddCardMutation>;
export type AddCardMutationResult = Apollo.MutationResult<AddCardMutation>;
export type AddCardMutationOptions = Apollo.BaseMutationOptions<
  AddCardMutation,
  AddCardMutationVariables
>;
export const DeleteCardDocument = gql`
  mutation deleteCard($id: String!) {
    deleteCard(id: $id) {
      msg
    }
  }
`;
export type DeleteCardMutationFn = Apollo.MutationFunction<
  DeleteCardMutation,
  DeleteCardMutationVariables
>;

/**
 * __useDeleteCardMutation__
 *
 * To run a mutation, you first call `useDeleteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardMutation, { data, loading, error }] = useDeleteCardMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCardMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCardMutation,
    DeleteCardMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCardMutation, DeleteCardMutationVariables>(
    DeleteCardDocument,
    options
  );
}
export type DeleteCardMutationHookResult = ReturnType<
  typeof useDeleteCardMutation
>;
export type DeleteCardMutationResult = Apollo.MutationResult<
  DeleteCardMutation
>;
export type DeleteCardMutationOptions = Apollo.BaseMutationOptions<
  DeleteCardMutation,
  DeleteCardMutationVariables
>;
export const SetDefaultPaymentMethodDocument = gql`
  mutation setDefaultPaymentMethod($id: String!) {
    setDefaultPaymentMethod(id: $id) {
      status
    }
  }
`;
export type SetDefaultPaymentMethodMutationFn = Apollo.MutationFunction<
  SetDefaultPaymentMethodMutation,
  SetDefaultPaymentMethodMutationVariables
>;

/**
 * __useSetDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useSetDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDefaultPaymentMethodMutation, { data, loading, error }] = useSetDefaultPaymentMethodMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetDefaultPaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetDefaultPaymentMethodMutation,
    SetDefaultPaymentMethodMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetDefaultPaymentMethodMutation,
    SetDefaultPaymentMethodMutationVariables
  >(SetDefaultPaymentMethodDocument, options);
}
export type SetDefaultPaymentMethodMutationHookResult = ReturnType<
  typeof useSetDefaultPaymentMethodMutation
>;
export type SetDefaultPaymentMethodMutationResult = Apollo.MutationResult<
  SetDefaultPaymentMethodMutation
>;
export type SetDefaultPaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  SetDefaultPaymentMethodMutation,
  SetDefaultPaymentMethodMutationVariables
>;
export const SetBillingAddressDocument = gql`
  mutation setBillingAddress($object: billing_address_insert_input!) {
    insert_billing_address_one(
      object: $object
      on_conflict: {
        constraint: billing_address_customer_id_key
        update_columns: [
          name
          address_line_1
          address_line_2
          pin_code
          city
          state
          country
        ]
      }
    ) {
      id
      name
      customer_id
      address_line_1
      address_line_2
      pin_code
      city
      state
      country
      last_updated_to_stripe
      updated_at
    }
  }
`;
export type SetBillingAddressMutationFn = Apollo.MutationFunction<
  SetBillingAddressMutation,
  SetBillingAddressMutationVariables
>;

/**
 * __useSetBillingAddressMutation__
 *
 * To run a mutation, you first call `useSetBillingAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBillingAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBillingAddressMutation, { data, loading, error }] = useSetBillingAddressMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useSetBillingAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetBillingAddressMutation,
    SetBillingAddressMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetBillingAddressMutation,
    SetBillingAddressMutationVariables
  >(SetBillingAddressDocument, options);
}
export type SetBillingAddressMutationHookResult = ReturnType<
  typeof useSetBillingAddressMutation
>;
export type SetBillingAddressMutationResult = Apollo.MutationResult<
  SetBillingAddressMutation
>;
export type SetBillingAddressMutationOptions = Apollo.BaseMutationOptions<
  SetBillingAddressMutation,
  SetBillingAddressMutationVariables
>;
export const ChangePlanDocument = gql`
  mutation changePlan($plan: Plan!, $projectId: uuid!) {
    changePlan(plan: $plan, projectId: $projectId) {
      projectId
      newPlan
    }
  }
`;
export type ChangePlanMutationFn = Apollo.MutationFunction<
  ChangePlanMutation,
  ChangePlanMutationVariables
>;

/**
 * __useChangePlanMutation__
 *
 * To run a mutation, you first call `useChangePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePlanMutation, { data, loading, error }] = useChangePlanMutation({
 *   variables: {
 *      plan: // value for 'plan'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useChangePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangePlanMutation,
    ChangePlanMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ChangePlanMutation, ChangePlanMutationVariables>(
    ChangePlanDocument,
    options
  );
}
export type ChangePlanMutationHookResult = ReturnType<
  typeof useChangePlanMutation
>;
export type ChangePlanMutationResult = Apollo.MutationResult<
  ChangePlanMutation
>;
export type ChangePlanMutationOptions = Apollo.BaseMutationOptions<
  ChangePlanMutation,
  ChangePlanMutationVariables
>;
export const ApplyCouponDocument = gql`
  mutation applyCoupon($couponCode: String!) {
    applyCouponCode(coupon: $couponCode) {
      status
    }
  }
`;
export type ApplyCouponMutationFn = Apollo.MutationFunction<
  ApplyCouponMutation,
  ApplyCouponMutationVariables
>;

/**
 * __useApplyCouponMutation__
 *
 * To run a mutation, you first call `useApplyCouponMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyCouponMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyCouponMutation, { data, loading, error }] = useApplyCouponMutation({
 *   variables: {
 *      couponCode: // value for 'couponCode'
 *   },
 * });
 */
export function useApplyCouponMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ApplyCouponMutation,
    ApplyCouponMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ApplyCouponMutation, ApplyCouponMutationVariables>(
    ApplyCouponDocument,
    options
  );
}
export type ApplyCouponMutationHookResult = ReturnType<
  typeof useApplyCouponMutation
>;
export type ApplyCouponMutationResult = Apollo.MutationResult<
  ApplyCouponMutation
>;
export type ApplyCouponMutationOptions = Apollo.BaseMutationOptions<
  ApplyCouponMutation,
  ApplyCouponMutationVariables
>;
export const SetBillingEmailDocument = gql`
  mutation setBillingEmail($email: String!, $userId: uuid!) {
    update_users(
      _set: { invoice_receipt_email: $email }
      where: { id: { _eq: $userId } }
    ) {
      returning {
        invoice_receipt_email
      }
    }
  }
`;
export type SetBillingEmailMutationFn = Apollo.MutationFunction<
  SetBillingEmailMutation,
  SetBillingEmailMutationVariables
>;

/**
 * __useSetBillingEmailMutation__
 *
 * To run a mutation, you first call `useSetBillingEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetBillingEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setBillingEmailMutation, { data, loading, error }] = useSetBillingEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetBillingEmailMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetBillingEmailMutation,
    SetBillingEmailMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    SetBillingEmailMutation,
    SetBillingEmailMutationVariables
  >(SetBillingEmailDocument, options);
}
export type SetBillingEmailMutationHookResult = ReturnType<
  typeof useSetBillingEmailMutation
>;
export type SetBillingEmailMutationResult = Apollo.MutationResult<
  SetBillingEmailMutation
>;
export type SetBillingEmailMutationOptions = Apollo.BaseMutationOptions<
  SetBillingEmailMutation,
  SetBillingEmailMutationVariables
>;
export const ModifyCollaboratorPrivilegeDocument = gql`
  mutation modifyCollaboratorPrivilege(
    $insertArgs: [project_collaborator_privileges_insert_input!]!
    $deleteWhere: project_collaborator_privileges_bool_exp!
  ) {
    delete_project_collaborator_privileges(where: $deleteWhere) {
      affected_rows
    }
    insert_project_collaborator_privileges(objects: $insertArgs) {
      affected_rows
    }
  }
`;
export type ModifyCollaboratorPrivilegeMutationFn = Apollo.MutationFunction<
  ModifyCollaboratorPrivilegeMutation,
  ModifyCollaboratorPrivilegeMutationVariables
>;

/**
 * __useModifyCollaboratorPrivilegeMutation__
 *
 * To run a mutation, you first call `useModifyCollaboratorPrivilegeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyCollaboratorPrivilegeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyCollaboratorPrivilegeMutation, { data, loading, error }] = useModifyCollaboratorPrivilegeMutation({
 *   variables: {
 *      insertArgs: // value for 'insertArgs'
 *      deleteWhere: // value for 'deleteWhere'
 *   },
 * });
 */
export function useModifyCollaboratorPrivilegeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ModifyCollaboratorPrivilegeMutation,
    ModifyCollaboratorPrivilegeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ModifyCollaboratorPrivilegeMutation,
    ModifyCollaboratorPrivilegeMutationVariables
  >(ModifyCollaboratorPrivilegeDocument, options);
}
export type ModifyCollaboratorPrivilegeMutationHookResult = ReturnType<
  typeof useModifyCollaboratorPrivilegeMutation
>;
export type ModifyCollaboratorPrivilegeMutationResult = Apollo.MutationResult<
  ModifyCollaboratorPrivilegeMutation
>;
export type ModifyCollaboratorPrivilegeMutationOptions = Apollo.BaseMutationOptions<
  ModifyCollaboratorPrivilegeMutation,
  ModifyCollaboratorPrivilegeMutationVariables
>;
export const InviteCollaboratorDocument = gql`
  mutation inviteCollaborator(
    $email: String!
    $privileges: [String!]!
    $projectID: uuid!
  ) {
    inviteCollaborator(
      invite: {
        project_id: $projectID
        collaborator_email: $email
        privileges: $privileges
      }
    ) {
      id
      collaborator_email
      project_id
      key
      invited_at
      invited_by
      privileges
    }
  }
`;
export type InviteCollaboratorMutationFn = Apollo.MutationFunction<
  InviteCollaboratorMutation,
  InviteCollaboratorMutationVariables
>;

/**
 * __useInviteCollaboratorMutation__
 *
 * To run a mutation, you first call `useInviteCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteCollaboratorMutation, { data, loading, error }] = useInviteCollaboratorMutation({
 *   variables: {
 *      email: // value for 'email'
 *      privileges: // value for 'privileges'
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useInviteCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteCollaboratorMutation,
    InviteCollaboratorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteCollaboratorMutation,
    InviteCollaboratorMutationVariables
  >(InviteCollaboratorDocument, options);
}
export type InviteCollaboratorMutationHookResult = ReturnType<
  typeof useInviteCollaboratorMutation
>;
export type InviteCollaboratorMutationResult = Apollo.MutationResult<
  InviteCollaboratorMutation
>;
export type InviteCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  InviteCollaboratorMutation,
  InviteCollaboratorMutationVariables
>;
export const DeleteCollaboratorsDocument = gql`
  mutation deleteCollaborators($id: [uuid!]!) {
    update_project_collaborators(where: { id: { _in: $id } }) {
      returning {
        id
        collaborator {
          id
          email
        }
      }
      affected_rows
    }
  }
`;
export type DeleteCollaboratorsMutationFn = Apollo.MutationFunction<
  DeleteCollaboratorsMutation,
  DeleteCollaboratorsMutationVariables
>;

/**
 * __useDeleteCollaboratorsMutation__
 *
 * To run a mutation, you first call `useDeleteCollaboratorsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCollaboratorsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCollaboratorsMutation, { data, loading, error }] = useDeleteCollaboratorsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCollaboratorsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCollaboratorsMutation,
    DeleteCollaboratorsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCollaboratorsMutation,
    DeleteCollaboratorsMutationVariables
  >(DeleteCollaboratorsDocument, options);
}
export type DeleteCollaboratorsMutationHookResult = ReturnType<
  typeof useDeleteCollaboratorsMutation
>;
export type DeleteCollaboratorsMutationResult = Apollo.MutationResult<
  DeleteCollaboratorsMutation
>;
export type DeleteCollaboratorsMutationOptions = Apollo.BaseMutationOptions<
  DeleteCollaboratorsMutation,
  DeleteCollaboratorsMutationVariables
>;
export const LeaveCollaborationDocument = gql`
  mutation leaveCollaboration($id: uuid!) {
    update_project_collaborators(where: { id: { _eq: $id } }) {
      returning {
        id
        collaborator {
          id
          email
        }
      }
      affected_rows
    }
  }
`;
export type LeaveCollaborationMutationFn = Apollo.MutationFunction<
  LeaveCollaborationMutation,
  LeaveCollaborationMutationVariables
>;

/**
 * __useLeaveCollaborationMutation__
 *
 * To run a mutation, you first call `useLeaveCollaborationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLeaveCollaborationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [leaveCollaborationMutation, { data, loading, error }] = useLeaveCollaborationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLeaveCollaborationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    LeaveCollaborationMutation,
    LeaveCollaborationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    LeaveCollaborationMutation,
    LeaveCollaborationMutationVariables
  >(LeaveCollaborationDocument, options);
}
export type LeaveCollaborationMutationHookResult = ReturnType<
  typeof useLeaveCollaborationMutation
>;
export type LeaveCollaborationMutationResult = Apollo.MutationResult<
  LeaveCollaborationMutation
>;
export type LeaveCollaborationMutationOptions = Apollo.BaseMutationOptions<
  LeaveCollaborationMutation,
  LeaveCollaborationMutationVariables
>;
export const UpdateTenantDocument = gql`
  mutation updateTenant(
    $tenantId: uuid!
    $currentHash: String!
    $envs: [UpdateEnvObject!]!
  ) {
    updateTenantEnv(
      currentHash: $currentHash
      tenantId: $tenantId
      envs: $envs
    ) {
      hash
      envVars
    }
  }
`;
export type UpdateTenantMutationFn = Apollo.MutationFunction<
  UpdateTenantMutation,
  UpdateTenantMutationVariables
>;

/**
 * __useUpdateTenantMutation__
 *
 * To run a mutation, you first call `useUpdateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTenantMutation, { data, loading, error }] = useUpdateTenantMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      currentHash: // value for 'currentHash'
 *      envs: // value for 'envs'
 *   },
 * });
 */
export function useUpdateTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTenantMutation,
    UpdateTenantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateTenantMutation,
    UpdateTenantMutationVariables
  >(UpdateTenantDocument, options);
}
export type UpdateTenantMutationHookResult = ReturnType<
  typeof useUpdateTenantMutation
>;
export type UpdateTenantMutationResult = Apollo.MutationResult<
  UpdateTenantMutation
>;
export type UpdateTenantMutationOptions = Apollo.BaseMutationOptions<
  UpdateTenantMutation,
  UpdateTenantMutationVariables
>;
export const DeleteTenantEnvDocument = gql`
  mutation deleteTenantEnv(
    $id: uuid!
    $currentHash: String!
    $env: [String!]!
  ) {
    deleteTenantEnv(
      tenantId: $id
      currentHash: $currentHash
      deleteEnvs: $env
    ) {
      hash
      envVars
    }
  }
`;
export type DeleteTenantEnvMutationFn = Apollo.MutationFunction<
  DeleteTenantEnvMutation,
  DeleteTenantEnvMutationVariables
>;

/**
 * __useDeleteTenantEnvMutation__
 *
 * To run a mutation, you first call `useDeleteTenantEnvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantEnvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantEnvMutation, { data, loading, error }] = useDeleteTenantEnvMutation({
 *   variables: {
 *      id: // value for 'id'
 *      currentHash: // value for 'currentHash'
 *      env: // value for 'env'
 *   },
 * });
 */
export function useDeleteTenantEnvMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTenantEnvMutation,
    DeleteTenantEnvMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTenantEnvMutation,
    DeleteTenantEnvMutationVariables
  >(DeleteTenantEnvDocument, options);
}
export type DeleteTenantEnvMutationHookResult = ReturnType<
  typeof useDeleteTenantEnvMutation
>;
export type DeleteTenantEnvMutationResult = Apollo.MutationResult<
  DeleteTenantEnvMutation
>;
export type DeleteTenantEnvMutationOptions = Apollo.BaseMutationOptions<
  DeleteTenantEnvMutation,
  DeleteTenantEnvMutationVariables
>;
export const CreateTenantDocument = gql`
  mutation createTenant(
    $region: String!
    $cloud: String!
    $name: String
    $plan: String
    $vpcId: String
  ) {
    createTenant(
      cloud: $cloud
      region: $region
      name: $name
      plan: $plan
      dedicatedVPCID: $vpcId
    ) {
      id
      name
      tenant {
        id
        created_at
        active
        project {
          id
          name
          endpoint
          owner {
            email
          }
          plan_name
        }
        health_status
        tenant_public_status {
          endpoint
          type
          is_active
          message
        }
        custom_domains {
          id
          fqdn
        }
      }
    }
  }
`;
export type CreateTenantMutationFn = Apollo.MutationFunction<
  CreateTenantMutation,
  CreateTenantMutationVariables
>;

/**
 * __useCreateTenantMutation__
 *
 * To run a mutation, you first call `useCreateTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTenantMutation, { data, loading, error }] = useCreateTenantMutation({
 *   variables: {
 *      region: // value for 'region'
 *      cloud: // value for 'cloud'
 *      name: // value for 'name'
 *      plan: // value for 'plan'
 *      vpcId: // value for 'vpcId'
 *   },
 * });
 */
export function useCreateTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateTenantMutation,
    CreateTenantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateTenantMutation,
    CreateTenantMutationVariables
  >(CreateTenantDocument, options);
}
export type CreateTenantMutationHookResult = ReturnType<
  typeof useCreateTenantMutation
>;
export type CreateTenantMutationResult = Apollo.MutationResult<
  CreateTenantMutation
>;
export type CreateTenantMutationOptions = Apollo.BaseMutationOptions<
  CreateTenantMutation,
  CreateTenantMutationVariables
>;
export const DeleteTenantDocument = gql`
  mutation deleteTenant($id: uuid!) {
    deleteTenant(tenantId: $id) {
      status
    }
  }
`;
export type DeleteTenantMutationFn = Apollo.MutationFunction<
  DeleteTenantMutation,
  DeleteTenantMutationVariables
>;

/**
 * __useDeleteTenantMutation__
 *
 * To run a mutation, you first call `useDeleteTenantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTenantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTenantMutation, { data, loading, error }] = useDeleteTenantMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTenantMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteTenantMutation,
    DeleteTenantMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteTenantMutation,
    DeleteTenantMutationVariables
  >(DeleteTenantDocument, options);
}
export type DeleteTenantMutationHookResult = ReturnType<
  typeof useDeleteTenantMutation
>;
export type DeleteTenantMutationResult = Apollo.MutationResult<
  DeleteTenantMutation
>;
export type DeleteTenantMutationOptions = Apollo.BaseMutationOptions<
  DeleteTenantMutation,
  DeleteTenantMutationVariables
>;
export const AcceptInviteDocument = gql`
  mutation acceptInvite($key: String!) {
    acceptInvite(inviteKey: $key) {
      project_id
    }
  }
`;
export type AcceptInviteMutationFn = Apollo.MutationFunction<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>;

/**
 * __useAcceptInviteMutation__
 *
 * To run a mutation, you first call `useAcceptInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptInviteMutation, { data, loading, error }] = useAcceptInviteMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useAcceptInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptInviteMutation,
    AcceptInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptInviteMutation,
    AcceptInviteMutationVariables
  >(AcceptInviteDocument, options);
}
export type AcceptInviteMutationHookResult = ReturnType<
  typeof useAcceptInviteMutation
>;
export type AcceptInviteMutationResult = Apollo.MutationResult<
  AcceptInviteMutation
>;
export type AcceptInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptInviteMutation,
  AcceptInviteMutationVariables
>;
export const DeactivatePersonalAccessTokenDocument = gql`
  mutation deactivatePersonalAccessToken($id: uuid!) {
    update_user_personal_access_tokens(
      where: { id: { _eq: $id } }
      _set: { is_active: false }
    ) {
      returning {
        id
        is_active
      }
      affected_rows
    }
  }
`;
export type DeactivatePersonalAccessTokenMutationFn = Apollo.MutationFunction<
  DeactivatePersonalAccessTokenMutation,
  DeactivatePersonalAccessTokenMutationVariables
>;

/**
 * __useDeactivatePersonalAccessTokenMutation__
 *
 * To run a mutation, you first call `useDeactivatePersonalAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeactivatePersonalAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deactivatePersonalAccessTokenMutation, { data, loading, error }] = useDeactivatePersonalAccessTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeactivatePersonalAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeactivatePersonalAccessTokenMutation,
    DeactivatePersonalAccessTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeactivatePersonalAccessTokenMutation,
    DeactivatePersonalAccessTokenMutationVariables
  >(DeactivatePersonalAccessTokenDocument, options);
}
export type DeactivatePersonalAccessTokenMutationHookResult = ReturnType<
  typeof useDeactivatePersonalAccessTokenMutation
>;
export type DeactivatePersonalAccessTokenMutationResult = Apollo.MutationResult<
  DeactivatePersonalAccessTokenMutation
>;
export type DeactivatePersonalAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  DeactivatePersonalAccessTokenMutation,
  DeactivatePersonalAccessTokenMutationVariables
>;
export const RevokeInviteDocument = gql`
  mutation revokeInvite($key: String!) {
    declineInvite(inviteKey: $key) {
      project_id
      id
      collaborator_email
    }
  }
`;
export type RevokeInviteMutationFn = Apollo.MutationFunction<
  RevokeInviteMutation,
  RevokeInviteMutationVariables
>;

/**
 * __useRevokeInviteMutation__
 *
 * To run a mutation, you first call `useRevokeInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeInviteMutation, { data, loading, error }] = useRevokeInviteMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useRevokeInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeInviteMutation,
    RevokeInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeInviteMutation,
    RevokeInviteMutationVariables
  >(RevokeInviteDocument, options);
}
export type RevokeInviteMutationHookResult = ReturnType<
  typeof useRevokeInviteMutation
>;
export type RevokeInviteMutationResult = Apollo.MutationResult<
  RevokeInviteMutation
>;
export type RevokeInviteMutationOptions = Apollo.BaseMutationOptions<
  RevokeInviteMutation,
  RevokeInviteMutationVariables
>;
export const ResendInvitationDocument = gql`
  mutation resendInvitation($args: ResendInvitationRequest!) {
    resendInvite(args: $args) {
      id
      collaborator_email
    }
  }
`;
export type ResendInvitationMutationFn = Apollo.MutationFunction<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>;

/**
 * __useResendInvitationMutation__
 *
 * To run a mutation, you first call `useResendInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendInvitationMutation, { data, loading, error }] = useResendInvitationMutation({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useResendInvitationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendInvitationMutation,
    ResendInvitationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendInvitationMutation,
    ResendInvitationMutationVariables
  >(ResendInvitationDocument, options);
}
export type ResendInvitationMutationHookResult = ReturnType<
  typeof useResendInvitationMutation
>;
export type ResendInvitationMutationResult = Apollo.MutationResult<
  ResendInvitationMutation
>;
export type ResendInvitationMutationOptions = Apollo.BaseMutationOptions<
  ResendInvitationMutation,
  ResendInvitationMutationVariables
>;
export const DeleteProjectDocument = gql`
  mutation deleteProject($id: uuid!) {
    deleteProject(project: { id: $id }) {
      id
    }
  }
`;
export type DeleteProjectMutationFn = Apollo.MutationFunction<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectMutation,
    DeleteProjectMutationVariables
  >(DeleteProjectDocument, options);
}
export type DeleteProjectMutationHookResult = ReturnType<
  typeof useDeleteProjectMutation
>;
export type DeleteProjectMutationResult = Apollo.MutationResult<
  DeleteProjectMutation
>;
export type DeleteProjectMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectMutation,
  DeleteProjectMutationVariables
>;
export const RegenerateTokenDocument = gql`
  mutation regenerateToken($id: uuid!) {
    regenerateKey(project: { id: $id }) {
      hasura_pro_key
      id
    }
  }
`;
export type RegenerateTokenMutationFn = Apollo.MutationFunction<
  RegenerateTokenMutation,
  RegenerateTokenMutationVariables
>;

/**
 * __useRegenerateTokenMutation__
 *
 * To run a mutation, you first call `useRegenerateTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegenerateTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [regenerateTokenMutation, { data, loading, error }] = useRegenerateTokenMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRegenerateTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegenerateTokenMutation,
    RegenerateTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegenerateTokenMutation,
    RegenerateTokenMutationVariables
  >(RegenerateTokenDocument, options);
}
export type RegenerateTokenMutationHookResult = ReturnType<
  typeof useRegenerateTokenMutation
>;
export type RegenerateTokenMutationResult = Apollo.MutationResult<
  RegenerateTokenMutation
>;
export type RegenerateTokenMutationOptions = Apollo.BaseMutationOptions<
  RegenerateTokenMutation,
  RegenerateTokenMutationVariables
>;
export const UpdateServerEndpointDocument = gql`
  mutation updateServerEndpoint($id: uuid!, $endpoint: String!) {
    updateServerEndpoint(project: { id: $id, endpoint: $endpoint }) {
      id
      endpoint
    }
  }
`;
export type UpdateServerEndpointMutationFn = Apollo.MutationFunction<
  UpdateServerEndpointMutation,
  UpdateServerEndpointMutationVariables
>;

/**
 * __useUpdateServerEndpointMutation__
 *
 * To run a mutation, you first call `useUpdateServerEndpointMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateServerEndpointMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateServerEndpointMutation, { data, loading, error }] = useUpdateServerEndpointMutation({
 *   variables: {
 *      id: // value for 'id'
 *      endpoint: // value for 'endpoint'
 *   },
 * });
 */
export function useUpdateServerEndpointMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateServerEndpointMutation,
    UpdateServerEndpointMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateServerEndpointMutation,
    UpdateServerEndpointMutationVariables
  >(UpdateServerEndpointDocument, options);
}
export type UpdateServerEndpointMutationHookResult = ReturnType<
  typeof useUpdateServerEndpointMutation
>;
export type UpdateServerEndpointMutationResult = Apollo.MutationResult<
  UpdateServerEndpointMutation
>;
export type UpdateServerEndpointMutationOptions = Apollo.BaseMutationOptions<
  UpdateServerEndpointMutation,
  UpdateServerEndpointMutationVariables
>;
export const DisconnectHerokUAppDocument = gql`
  mutation disconnectHerokUApp($projectId: uuid!) {
    herokuUnregisterWebhook(projectID: $projectId) {
      status
    }
    update_project_metadata_by_pk(
      pk_columns: { project_id: $projectId }
      _set: { connected_heroku_app_name: null }
    ) {
      project {
        ...ProjectFields
      }
    }
  }
  ${ProjectFieldsFragmentDoc}
`;
export type DisconnectHerokUAppMutationFn = Apollo.MutationFunction<
  DisconnectHerokUAppMutation,
  DisconnectHerokUAppMutationVariables
>;

/**
 * __useDisconnectHerokUAppMutation__
 *
 * To run a mutation, you first call `useDisconnectHerokUAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectHerokUAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectHerokUAppMutation, { data, loading, error }] = useDisconnectHerokUAppMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDisconnectHerokUAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectHerokUAppMutation,
    DisconnectHerokUAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectHerokUAppMutation,
    DisconnectHerokUAppMutationVariables
  >(DisconnectHerokUAppDocument, options);
}
export type DisconnectHerokUAppMutationHookResult = ReturnType<
  typeof useDisconnectHerokUAppMutation
>;
export type DisconnectHerokUAppMutationResult = Apollo.MutationResult<
  DisconnectHerokUAppMutation
>;
export type DisconnectHerokUAppMutationOptions = Apollo.BaseMutationOptions<
  DisconnectHerokUAppMutation,
  DisconnectHerokUAppMutationVariables
>;
export const CreateCustomDomainDocument = gql`
  mutation createCustomDomain($tenantId: uuid!, $fqdn: String!) {
    insert_custom_domain_one(object: { fqdn: $fqdn, tenant_id: $tenantId }) {
      id
      created_at
      dns_validation
      fqdn
      cert
    }
  }
`;
export type CreateCustomDomainMutationFn = Apollo.MutationFunction<
  CreateCustomDomainMutation,
  CreateCustomDomainMutationVariables
>;

/**
 * __useCreateCustomDomainMutation__
 *
 * To run a mutation, you first call `useCreateCustomDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomDomainMutation, { data, loading, error }] = useCreateCustomDomainMutation({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *      fqdn: // value for 'fqdn'
 *   },
 * });
 */
export function useCreateCustomDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateCustomDomainMutation,
    CreateCustomDomainMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateCustomDomainMutation,
    CreateCustomDomainMutationVariables
  >(CreateCustomDomainDocument, options);
}
export type CreateCustomDomainMutationHookResult = ReturnType<
  typeof useCreateCustomDomainMutation
>;
export type CreateCustomDomainMutationResult = Apollo.MutationResult<
  CreateCustomDomainMutation
>;
export type CreateCustomDomainMutationOptions = Apollo.BaseMutationOptions<
  CreateCustomDomainMutation,
  CreateCustomDomainMutationVariables
>;
export const DeleteCustomDomainDocument = gql`
  mutation deleteCustomDomain($id: uuid!) {
    delete_custom_domain_by_pk(id: $id) {
      id
    }
  }
`;
export type DeleteCustomDomainMutationFn = Apollo.MutationFunction<
  DeleteCustomDomainMutation,
  DeleteCustomDomainMutationVariables
>;

/**
 * __useDeleteCustomDomainMutation__
 *
 * To run a mutation, you first call `useDeleteCustomDomainMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomDomainMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomDomainMutation, { data, loading, error }] = useDeleteCustomDomainMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCustomDomainMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteCustomDomainMutation,
    DeleteCustomDomainMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteCustomDomainMutation,
    DeleteCustomDomainMutationVariables
  >(DeleteCustomDomainDocument, options);
}
export type DeleteCustomDomainMutationHookResult = ReturnType<
  typeof useDeleteCustomDomainMutation
>;
export type DeleteCustomDomainMutationResult = Apollo.MutationResult<
  DeleteCustomDomainMutation
>;
export type DeleteCustomDomainMutationOptions = Apollo.BaseMutationOptions<
  DeleteCustomDomainMutation,
  DeleteCustomDomainMutationVariables
>;
export const AddProjectLabelDocument = gql`
  mutation AddProjectLabel(
    $labelName: String!
    $projectId: uuid!
    $color: String
  ) {
    insert_label(
      objects: {
        name: $labelName
        project_labels: { data: { project_id: $projectId } }
        color: $color
      }
      on_conflict: {
        constraint: label_name_created_by_key
        update_columns: [name]
      }
    ) {
      affected_rows
    }
  }
`;
export type AddProjectLabelMutationFn = Apollo.MutationFunction<
  AddProjectLabelMutation,
  AddProjectLabelMutationVariables
>;

/**
 * __useAddProjectLabelMutation__
 *
 * To run a mutation, you first call `useAddProjectLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProjectLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProjectLabelMutation, { data, loading, error }] = useAddProjectLabelMutation({
 *   variables: {
 *      labelName: // value for 'labelName'
 *      projectId: // value for 'projectId'
 *      color: // value for 'color'
 *   },
 * });
 */
export function useAddProjectLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddProjectLabelMutation,
    AddProjectLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddProjectLabelMutation,
    AddProjectLabelMutationVariables
  >(AddProjectLabelDocument, options);
}
export type AddProjectLabelMutationHookResult = ReturnType<
  typeof useAddProjectLabelMutation
>;
export type AddProjectLabelMutationResult = Apollo.MutationResult<
  AddProjectLabelMutation
>;
export type AddProjectLabelMutationOptions = Apollo.BaseMutationOptions<
  AddProjectLabelMutation,
  AddProjectLabelMutationVariables
>;
export const ConnectHerokuAppDocument = gql`
  mutation connectHerokuApp($herokuAppName: String!, $projectID: uuid!) {
    insert_project_metadata_one(
      object: {
        project_id: $projectID
        heroku_managed_mode: false
        connected_heroku_app_name: $herokuAppName
      }
      on_conflict: {
        constraint: project_metadata_pkey
        update_columns: [connected_heroku_app_name, heroku_managed_mode]
      }
    ) {
      project_id
    }
  }
`;
export type ConnectHerokuAppMutationFn = Apollo.MutationFunction<
  ConnectHerokuAppMutation,
  ConnectHerokuAppMutationVariables
>;

/**
 * __useConnectHerokuAppMutation__
 *
 * To run a mutation, you first call `useConnectHerokuAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectHerokuAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectHerokuAppMutation, { data, loading, error }] = useConnectHerokuAppMutation({
 *   variables: {
 *      herokuAppName: // value for 'herokuAppName'
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useConnectHerokuAppMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConnectHerokuAppMutation,
    ConnectHerokuAppMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConnectHerokuAppMutation,
    ConnectHerokuAppMutationVariables
  >(ConnectHerokuAppDocument, options);
}
export type ConnectHerokuAppMutationHookResult = ReturnType<
  typeof useConnectHerokuAppMutation
>;
export type ConnectHerokuAppMutationResult = Apollo.MutationResult<
  ConnectHerokuAppMutation
>;
export type ConnectHerokuAppMutationOptions = Apollo.BaseMutationOptions<
  ConnectHerokuAppMutation,
  ConnectHerokuAppMutationVariables
>;
export const DeleteProjectLabelDocument = gql`
  mutation deleteProjectLabel($projectId: uuid!, $labelId: uuid!) {
    delete_project_labels(
      where: {
        label: { id: { _eq: $labelId } }
        project: { id: { _eq: $projectId } }
      }
    ) {
      affected_rows
    }
  }
`;
export type DeleteProjectLabelMutationFn = Apollo.MutationFunction<
  DeleteProjectLabelMutation,
  DeleteProjectLabelMutationVariables
>;

/**
 * __useDeleteProjectLabelMutation__
 *
 * To run a mutation, you first call `useDeleteProjectLabelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectLabelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectLabelMutation, { data, loading, error }] = useDeleteProjectLabelMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      labelId: // value for 'labelId'
 *   },
 * });
 */
export function useDeleteProjectLabelMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteProjectLabelMutation,
    DeleteProjectLabelMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteProjectLabelMutation,
    DeleteProjectLabelMutationVariables
  >(DeleteProjectLabelDocument, options);
}
export type DeleteProjectLabelMutationHookResult = ReturnType<
  typeof useDeleteProjectLabelMutation
>;
export type DeleteProjectLabelMutationResult = Apollo.MutationResult<
  DeleteProjectLabelMutation
>;
export type DeleteProjectLabelMutationOptions = Apollo.BaseMutationOptions<
  DeleteProjectLabelMutation,
  DeleteProjectLabelMutationVariables
>;
export const RegisterHerokuWebhookDocument = gql`
  mutation registerHerokuWebhook(
    $herokuAppId: String!
    $projectID: uuid!
    $herokuAppName: String!
  ) {
    herokuRegisterWebhook(
      appID: $herokuAppId
      projectID: $projectID
      appName: $herokuAppName
    ) {
      status
    }
  }
`;
export type RegisterHerokuWebhookMutationFn = Apollo.MutationFunction<
  RegisterHerokuWebhookMutation,
  RegisterHerokuWebhookMutationVariables
>;

/**
 * __useRegisterHerokuWebhookMutation__
 *
 * To run a mutation, you first call `useRegisterHerokuWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterHerokuWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerHerokuWebhookMutation, { data, loading, error }] = useRegisterHerokuWebhookMutation({
 *   variables: {
 *      herokuAppId: // value for 'herokuAppId'
 *      projectID: // value for 'projectID'
 *      herokuAppName: // value for 'herokuAppName'
 *   },
 * });
 */
export function useRegisterHerokuWebhookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RegisterHerokuWebhookMutation,
    RegisterHerokuWebhookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RegisterHerokuWebhookMutation,
    RegisterHerokuWebhookMutationVariables
  >(RegisterHerokuWebhookDocument, options);
}
export type RegisterHerokuWebhookMutationHookResult = ReturnType<
  typeof useRegisterHerokuWebhookMutation
>;
export type RegisterHerokuWebhookMutationResult = Apollo.MutationResult<
  RegisterHerokuWebhookMutation
>;
export type RegisterHerokuWebhookMutationOptions = Apollo.BaseMutationOptions<
  RegisterHerokuWebhookMutation,
  RegisterHerokuWebhookMutationVariables
>;
export const StartDburlSyncDocument = gql`
  mutation startDBURLSync(
    $appName: String!
    $appID: String!
    $projectID: uuid!
    $env: String!
    $agent: ActionAgent!
  ) {
    herokuRegisterWebhookVar(
      varName: $env
      appID: $appID
      projectID: $projectID
      appName: $appName
      agent: $agent
    ) {
      status
    }
  }
`;
export type StartDburlSyncMutationFn = Apollo.MutationFunction<
  StartDburlSyncMutation,
  StartDburlSyncMutationVariables
>;

/**
 * __useStartDburlSyncMutation__
 *
 * To run a mutation, you first call `useStartDburlSyncMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartDburlSyncMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startDburlSyncMutation, { data, loading, error }] = useStartDburlSyncMutation({
 *   variables: {
 *      appName: // value for 'appName'
 *      appID: // value for 'appID'
 *      projectID: // value for 'projectID'
 *      env: // value for 'env'
 *      agent: // value for 'agent'
 *   },
 * });
 */
export function useStartDburlSyncMutation(
  baseOptions?: Apollo.MutationHookOptions<
    StartDburlSyncMutation,
    StartDburlSyncMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    StartDburlSyncMutation,
    StartDburlSyncMutationVariables
  >(StartDburlSyncDocument, options);
}
export type StartDburlSyncMutationHookResult = ReturnType<
  typeof useStartDburlSyncMutation
>;
export type StartDburlSyncMutationResult = Apollo.MutationResult<
  StartDburlSyncMutation
>;
export type StartDburlSyncMutationOptions = Apollo.BaseMutationOptions<
  StartDburlSyncMutation,
  StartDburlSyncMutationVariables
>;
export const UnregisterHerokuWebhookDocument = gql`
  mutation unregisterHerokuWebhook($projectID: uuid!) {
    herokuUnregisterWebhook(projectID: $projectID) {
      status
    }
    update_project_metadata(
      _set: { connected_heroku_app_name: null, heroku_managed_mode: false }
      where: { project_id: { _eq: $projectID } }
    ) {
      affected_rows
    }
  }
`;
export type UnregisterHerokuWebhookMutationFn = Apollo.MutationFunction<
  UnregisterHerokuWebhookMutation,
  UnregisterHerokuWebhookMutationVariables
>;

/**
 * __useUnregisterHerokuWebhookMutation__
 *
 * To run a mutation, you first call `useUnregisterHerokuWebhookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnregisterHerokuWebhookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unregisterHerokuWebhookMutation, { data, loading, error }] = useUnregisterHerokuWebhookMutation({
 *   variables: {
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useUnregisterHerokuWebhookMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnregisterHerokuWebhookMutation,
    UnregisterHerokuWebhookMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnregisterHerokuWebhookMutation,
    UnregisterHerokuWebhookMutationVariables
  >(UnregisterHerokuWebhookDocument, options);
}
export type UnregisterHerokuWebhookMutationHookResult = ReturnType<
  typeof useUnregisterHerokuWebhookMutation
>;
export type UnregisterHerokuWebhookMutationResult = Apollo.MutationResult<
  UnregisterHerokuWebhookMutation
>;
export type UnregisterHerokuWebhookMutationOptions = Apollo.BaseMutationOptions<
  UnregisterHerokuWebhookMutation,
  UnregisterHerokuWebhookMutationVariables
>;
export const UnregisterHerokuWebhookVarDocument = gql`
  mutation unregisterHerokuWebhookVar($env: String!, $projectID: uuid!) {
    herokuUnregisterWebhookVar(projectID: $projectID, varName: $env) {
      status
    }
  }
`;
export type UnregisterHerokuWebhookVarMutationFn = Apollo.MutationFunction<
  UnregisterHerokuWebhookVarMutation,
  UnregisterHerokuWebhookVarMutationVariables
>;

/**
 * __useUnregisterHerokuWebhookVarMutation__
 *
 * To run a mutation, you first call `useUnregisterHerokuWebhookVarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnregisterHerokuWebhookVarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unregisterHerokuWebhookVarMutation, { data, loading, error }] = useUnregisterHerokuWebhookVarMutation({
 *   variables: {
 *      env: // value for 'env'
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useUnregisterHerokuWebhookVarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UnregisterHerokuWebhookVarMutation,
    UnregisterHerokuWebhookVarMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UnregisterHerokuWebhookVarMutation,
    UnregisterHerokuWebhookVarMutationVariables
  >(UnregisterHerokuWebhookVarDocument, options);
}
export type UnregisterHerokuWebhookVarMutationHookResult = ReturnType<
  typeof useUnregisterHerokuWebhookVarMutation
>;
export type UnregisterHerokuWebhookVarMutationResult = Apollo.MutationResult<
  UnregisterHerokuWebhookVarMutation
>;
export type UnregisterHerokuWebhookVarMutationOptions = Apollo.BaseMutationOptions<
  UnregisterHerokuWebhookVarMutation,
  UnregisterHerokuWebhookVarMutationVariables
>;
export const RetryCustomDomainValidationDocument = gql`
  mutation retryCustomDomainValidation(
    $id: uuid!
    $fqdn: String!
    $tenantId: uuid!
  ) {
    delete_custom_domain_by_pk(id: $id) {
      id
    }
    insert_custom_domain_one(object: { fqdn: $fqdn, tenant_id: $tenantId }) {
      id
      created_at
      dns_validation
      fqdn
      cert
    }
  }
`;
export type RetryCustomDomainValidationMutationFn = Apollo.MutationFunction<
  RetryCustomDomainValidationMutation,
  RetryCustomDomainValidationMutationVariables
>;

/**
 * __useRetryCustomDomainValidationMutation__
 *
 * To run a mutation, you first call `useRetryCustomDomainValidationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryCustomDomainValidationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryCustomDomainValidationMutation, { data, loading, error }] = useRetryCustomDomainValidationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      fqdn: // value for 'fqdn'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useRetryCustomDomainValidationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RetryCustomDomainValidationMutation,
    RetryCustomDomainValidationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RetryCustomDomainValidationMutation,
    RetryCustomDomainValidationMutationVariables
  >(RetryCustomDomainValidationDocument, options);
}
export type RetryCustomDomainValidationMutationHookResult = ReturnType<
  typeof useRetryCustomDomainValidationMutation
>;
export type RetryCustomDomainValidationMutationResult = Apollo.MutationResult<
  RetryCustomDomainValidationMutation
>;
export type RetryCustomDomainValidationMutationOptions = Apollo.BaseMutationOptions<
  RetryCustomDomainValidationMutation,
  RetryCustomDomainValidationMutationVariables
>;
export const GenerateProKeyDocument = gql`
  mutation generateProKey($name: String!, $serverEndpoint: String!) {
    addProject(project: { name: $name, endpoint: $serverEndpoint }) {
      hasura_pro_key
      id
    }
  }
`;
export type GenerateProKeyMutationFn = Apollo.MutationFunction<
  GenerateProKeyMutation,
  GenerateProKeyMutationVariables
>;

/**
 * __useGenerateProKeyMutation__
 *
 * To run a mutation, you first call `useGenerateProKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateProKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateProKeyMutation, { data, loading, error }] = useGenerateProKeyMutation({
 *   variables: {
 *      name: // value for 'name'
 *      serverEndpoint: // value for 'serverEndpoint'
 *   },
 * });
 */
export function useGenerateProKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GenerateProKeyMutation,
    GenerateProKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GenerateProKeyMutation,
    GenerateProKeyMutationVariables
  >(GenerateProKeyDocument, options);
}
export type GenerateProKeyMutationHookResult = ReturnType<
  typeof useGenerateProKeyMutation
>;
export type GenerateProKeyMutationResult = Apollo.MutationResult<
  GenerateProKeyMutation
>;
export type GenerateProKeyMutationOptions = Apollo.BaseMutationOptions<
  GenerateProKeyMutation,
  GenerateProKeyMutationVariables
>;
export const UpdateProjectNameDocument = gql`
  mutation updateProjectName($name: String!, $tenantId: uuid!) {
    updateTenantName(name: $name, tenantId: $tenantId) {
      tenant {
        project {
          ...ProjectFields
        }
      }
    }
  }
  ${ProjectFieldsFragmentDoc}
`;
export type UpdateProjectNameMutationFn = Apollo.MutationFunction<
  UpdateProjectNameMutation,
  UpdateProjectNameMutationVariables
>;

/**
 * __useUpdateProjectNameMutation__
 *
 * To run a mutation, you first call `useUpdateProjectNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectNameMutation, { data, loading, error }] = useUpdateProjectNameMutation({
 *   variables: {
 *      name: // value for 'name'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useUpdateProjectNameMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectNameMutation,
    UpdateProjectNameMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectNameMutation,
    UpdateProjectNameMutationVariables
  >(UpdateProjectNameDocument, options);
}
export type UpdateProjectNameMutationHookResult = ReturnType<
  typeof useUpdateProjectNameMutation
>;
export type UpdateProjectNameMutationResult = Apollo.MutationResult<
  UpdateProjectNameMutation
>;
export type UpdateProjectNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectNameMutation,
  UpdateProjectNameMutationVariables
>;
export const ChangeProjectRegionDocument = gql`
  mutation changeProjectRegion(
    $projectId: uuid!
    $cloud: String!
    $region: String!
  ) {
    moveProjectRegion(projectId: $projectId, cloud: $cloud, region: $region) {
      projectId
    }
  }
`;
export type ChangeProjectRegionMutationFn = Apollo.MutationFunction<
  ChangeProjectRegionMutation,
  ChangeProjectRegionMutationVariables
>;

/**
 * __useChangeProjectRegionMutation__
 *
 * To run a mutation, you first call `useChangeProjectRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeProjectRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeProjectRegionMutation, { data, loading, error }] = useChangeProjectRegionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      cloud: // value for 'cloud'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useChangeProjectRegionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeProjectRegionMutation,
    ChangeProjectRegionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeProjectRegionMutation,
    ChangeProjectRegionMutationVariables
  >(ChangeProjectRegionDocument, options);
}
export type ChangeProjectRegionMutationHookResult = ReturnType<
  typeof useChangeProjectRegionMutation
>;
export type ChangeProjectRegionMutationResult = Apollo.MutationResult<
  ChangeProjectRegionMutation
>;
export type ChangeProjectRegionMutationOptions = Apollo.BaseMutationOptions<
  ChangeProjectRegionMutation,
  ChangeProjectRegionMutationVariables
>;
export const TransferOwnershipInviteDocument = gql`
  mutation transferOwnershipInvite($inviteeEmail: String!, $projectId: uuid!) {
    transferOwnershipInvite(
      input: { invitee_email: $inviteeEmail, project_id: $projectId }
    ) {
      invitee_email
    }
  }
`;
export type TransferOwnershipInviteMutationFn = Apollo.MutationFunction<
  TransferOwnershipInviteMutation,
  TransferOwnershipInviteMutationVariables
>;

/**
 * __useTransferOwnershipInviteMutation__
 *
 * To run a mutation, you first call `useTransferOwnershipInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTransferOwnershipInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [transferOwnershipInviteMutation, { data, loading, error }] = useTransferOwnershipInviteMutation({
 *   variables: {
 *      inviteeEmail: // value for 'inviteeEmail'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useTransferOwnershipInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TransferOwnershipInviteMutation,
    TransferOwnershipInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TransferOwnershipInviteMutation,
    TransferOwnershipInviteMutationVariables
  >(TransferOwnershipInviteDocument, options);
}
export type TransferOwnershipInviteMutationHookResult = ReturnType<
  typeof useTransferOwnershipInviteMutation
>;
export type TransferOwnershipInviteMutationResult = Apollo.MutationResult<
  TransferOwnershipInviteMutation
>;
export type TransferOwnershipInviteMutationOptions = Apollo.BaseMutationOptions<
  TransferOwnershipInviteMutation,
  TransferOwnershipInviteMutationVariables
>;
export const ResendTransferOwnershipInviteDocument = gql`
  mutation resendTransferOwnershipInvite(
    $inviteeEmail: String!
    $projectId: uuid!
  ) {
    resendTransferOwnershipInvite(
      args: { invitee_email: $inviteeEmail, project_id: $projectId }
    ) {
      invitee_email
    }
  }
`;
export type ResendTransferOwnershipInviteMutationFn = Apollo.MutationFunction<
  ResendTransferOwnershipInviteMutation,
  ResendTransferOwnershipInviteMutationVariables
>;

/**
 * __useResendTransferOwnershipInviteMutation__
 *
 * To run a mutation, you first call `useResendTransferOwnershipInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendTransferOwnershipInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendTransferOwnershipInviteMutation, { data, loading, error }] = useResendTransferOwnershipInviteMutation({
 *   variables: {
 *      inviteeEmail: // value for 'inviteeEmail'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useResendTransferOwnershipInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendTransferOwnershipInviteMutation,
    ResendTransferOwnershipInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendTransferOwnershipInviteMutation,
    ResendTransferOwnershipInviteMutationVariables
  >(ResendTransferOwnershipInviteDocument, options);
}
export type ResendTransferOwnershipInviteMutationHookResult = ReturnType<
  typeof useResendTransferOwnershipInviteMutation
>;
export type ResendTransferOwnershipInviteMutationResult = Apollo.MutationResult<
  ResendTransferOwnershipInviteMutation
>;
export type ResendTransferOwnershipInviteMutationOptions = Apollo.BaseMutationOptions<
  ResendTransferOwnershipInviteMutation,
  ResendTransferOwnershipInviteMutationVariables
>;
export const InsertDatadogApiKeyDocument = gql`
  mutation insertDatadogAPIKey(
    $id: uuid!
    $key: String!
    $host: String!
    $service: String!
    $tags: String!
    $region: String!
  ) {
    addDatadogConfig(
      project_id: $id
      key: $key
      host: $host
      service_name: $service
      tags: $tags
      datadog_region: $region
    ) {
      project_id
    }
  }
`;
export type InsertDatadogApiKeyMutationFn = Apollo.MutationFunction<
  InsertDatadogApiKeyMutation,
  InsertDatadogApiKeyMutationVariables
>;

/**
 * __useInsertDatadogApiKeyMutation__
 *
 * To run a mutation, you first call `useInsertDatadogApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertDatadogApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertDatadogApiKeyMutation, { data, loading, error }] = useInsertDatadogApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      key: // value for 'key'
 *      host: // value for 'host'
 *      service: // value for 'service'
 *      tags: // value for 'tags'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useInsertDatadogApiKeyMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertDatadogApiKeyMutation,
    InsertDatadogApiKeyMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InsertDatadogApiKeyMutation,
    InsertDatadogApiKeyMutationVariables
  >(InsertDatadogApiKeyDocument, options);
}
export type InsertDatadogApiKeyMutationHookResult = ReturnType<
  typeof useInsertDatadogApiKeyMutation
>;
export type InsertDatadogApiKeyMutationResult = Apollo.MutationResult<
  InsertDatadogApiKeyMutation
>;
export type InsertDatadogApiKeyMutationOptions = Apollo.BaseMutationOptions<
  InsertDatadogApiKeyMutation,
  InsertDatadogApiKeyMutationVariables
>;
export const AddNewrelicConfigDocument = gql`
  mutation addNewrelicConfig(
    $id: uuid!
    $key: String!
    $region: String!
    $attributes: jsonb!
  ) {
    addNewrelicConfig(
      project_id: $id
      key: $key
      attributes: $attributes
      newrelic_region: $region
    ) {
      project_id
    }
  }
`;
export type AddNewrelicConfigMutationFn = Apollo.MutationFunction<
  AddNewrelicConfigMutation,
  AddNewrelicConfigMutationVariables
>;

/**
 * __useAddNewrelicConfigMutation__
 *
 * To run a mutation, you first call `useAddNewrelicConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNewrelicConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNewrelicConfigMutation, { data, loading, error }] = useAddNewrelicConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      key: // value for 'key'
 *      region: // value for 'region'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useAddNewrelicConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNewrelicConfigMutation,
    AddNewrelicConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddNewrelicConfigMutation,
    AddNewrelicConfigMutationVariables
  >(AddNewrelicConfigDocument, options);
}
export type AddNewrelicConfigMutationHookResult = ReturnType<
  typeof useAddNewrelicConfigMutation
>;
export type AddNewrelicConfigMutationResult = Apollo.MutationResult<
  AddNewrelicConfigMutation
>;
export type AddNewrelicConfigMutationOptions = Apollo.BaseMutationOptions<
  AddNewrelicConfigMutation,
  AddNewrelicConfigMutationVariables
>;
export const AddAzureMonitorConfigDocument = gql`
  mutation addAzureMonitorConfig(
    $project_id: uuid!
    $azuremonitor_workspace_id: String!
    $shared_key: String!
    $resource_id: String!
    $log_type: String!
    $metric_namespace: String!
    $activedirectory_tenant_id: String!
    $activedirectory_client_id: String!
    $activedirectory_client_secret: String!
    $azure_region: String!
    $attributes: jsonb
    $instrumentation_key: String
  ) {
    addAzureMonitorConfig(
      project_id: $project_id
      azuremonitor_workspace_id: $azuremonitor_workspace_id
      shared_key: $shared_key
      resource_id: $resource_id
      log_type: $log_type
      metric_namespace: $metric_namespace
      activedirectory_tenant_id: $activedirectory_tenant_id
      activedirectory_client_id: $activedirectory_client_id
      activedirectory_client_secret: $activedirectory_client_secret
      azure_region: $azure_region
      attributes: $attributes
      instrumentation_key: $instrumentation_key
    ) {
      project_id
    }
  }
`;
export type AddAzureMonitorConfigMutationFn = Apollo.MutationFunction<
  AddAzureMonitorConfigMutation,
  AddAzureMonitorConfigMutationVariables
>;

/**
 * __useAddAzureMonitorConfigMutation__
 *
 * To run a mutation, you first call `useAddAzureMonitorConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAzureMonitorConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAzureMonitorConfigMutation, { data, loading, error }] = useAddAzureMonitorConfigMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      azuremonitor_workspace_id: // value for 'azuremonitor_workspace_id'
 *      shared_key: // value for 'shared_key'
 *      resource_id: // value for 'resource_id'
 *      log_type: // value for 'log_type'
 *      metric_namespace: // value for 'metric_namespace'
 *      activedirectory_tenant_id: // value for 'activedirectory_tenant_id'
 *      activedirectory_client_id: // value for 'activedirectory_client_id'
 *      activedirectory_client_secret: // value for 'activedirectory_client_secret'
 *      azure_region: // value for 'azure_region'
 *      attributes: // value for 'attributes'
 *      instrumentation_key: // value for 'instrumentation_key'
 *   },
 * });
 */
export function useAddAzureMonitorConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddAzureMonitorConfigMutation,
    AddAzureMonitorConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddAzureMonitorConfigMutation,
    AddAzureMonitorConfigMutationVariables
  >(AddAzureMonitorConfigDocument, options);
}
export type AddAzureMonitorConfigMutationHookResult = ReturnType<
  typeof useAddAzureMonitorConfigMutation
>;
export type AddAzureMonitorConfigMutationResult = Apollo.MutationResult<
  AddAzureMonitorConfigMutation
>;
export type AddAzureMonitorConfigMutationOptions = Apollo.BaseMutationOptions<
  AddAzureMonitorConfigMutation,
  AddAzureMonitorConfigMutationVariables
>;
export const AddPrometheusConfigDocument = gql`
  mutation addPrometheusConfig(
    $project_id: uuid!
    $namespace: String
    $labels: jsonb
  ) {
    addPrometheusConfig(
      project_id: $project_id
      namespace: $namespace
      labels: $labels
    ) {
      project_id
      access_token
    }
  }
`;
export type AddPrometheusConfigMutationFn = Apollo.MutationFunction<
  AddPrometheusConfigMutation,
  AddPrometheusConfigMutationVariables
>;

/**
 * __useAddPrometheusConfigMutation__
 *
 * To run a mutation, you first call `useAddPrometheusConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPrometheusConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPrometheusConfigMutation, { data, loading, error }] = useAddPrometheusConfigMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      namespace: // value for 'namespace'
 *      labels: // value for 'labels'
 *   },
 * });
 */
export function useAddPrometheusConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddPrometheusConfigMutation,
    AddPrometheusConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddPrometheusConfigMutation,
    AddPrometheusConfigMutationVariables
  >(AddPrometheusConfigDocument, options);
}
export type AddPrometheusConfigMutationHookResult = ReturnType<
  typeof useAddPrometheusConfigMutation
>;
export type AddPrometheusConfigMutationResult = Apollo.MutationResult<
  AddPrometheusConfigMutation
>;
export type AddPrometheusConfigMutationOptions = Apollo.BaseMutationOptions<
  AddPrometheusConfigMutation,
  AddPrometheusConfigMutationVariables
>;
export const AddOpentelemetryConfigDocument = gql`
  mutation addOpentelemetryConfig(
    $project_id: uuid!
    $attributes: jsonb
    $batch_size: Int
    $connection_type: String!
    $endpoint: String!
    $export_traces: Boolean
    $headers: jsonb
  ) {
    addOpentelemetryConfig(
      project_id: $project_id
      attributes: $attributes
      batch_size: $batch_size
      connection_type: $connection_type
      endpoint: $endpoint
      export_traces: $export_traces
      headers: $headers
    ) {
      project_id
    }
  }
`;
export type AddOpentelemetryConfigMutationFn = Apollo.MutationFunction<
  AddOpentelemetryConfigMutation,
  AddOpentelemetryConfigMutationVariables
>;

/**
 * __useAddOpentelemetryConfigMutation__
 *
 * To run a mutation, you first call `useAddOpentelemetryConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOpentelemetryConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOpentelemetryConfigMutation, { data, loading, error }] = useAddOpentelemetryConfigMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      attributes: // value for 'attributes'
 *      batch_size: // value for 'batch_size'
 *      connection_type: // value for 'connection_type'
 *      endpoint: // value for 'endpoint'
 *      export_traces: // value for 'export_traces'
 *      headers: // value for 'headers'
 *   },
 * });
 */
export function useAddOpentelemetryConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddOpentelemetryConfigMutation,
    AddOpentelemetryConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddOpentelemetryConfigMutation,
    AddOpentelemetryConfigMutationVariables
  >(AddOpentelemetryConfigDocument, options);
}
export type AddOpentelemetryConfigMutationHookResult = ReturnType<
  typeof useAddOpentelemetryConfigMutation
>;
export type AddOpentelemetryConfigMutationResult = Apollo.MutationResult<
  AddOpentelemetryConfigMutation
>;
export type AddOpentelemetryConfigMutationOptions = Apollo.BaseMutationOptions<
  AddOpentelemetryConfigMutation,
  AddOpentelemetryConfigMutationVariables
>;
export const AcceptTransferOwnershipInviteDocument = gql`
  mutation acceptTransferOwnershipInvite($inviteKey: String!) {
    acceptTransferOwnershipInvite(inviteKey: $inviteKey) {
      invitee_email
      project_id
    }
  }
`;
export type AcceptTransferOwnershipInviteMutationFn = Apollo.MutationFunction<
  AcceptTransferOwnershipInviteMutation,
  AcceptTransferOwnershipInviteMutationVariables
>;

/**
 * __useAcceptTransferOwnershipInviteMutation__
 *
 * To run a mutation, you first call `useAcceptTransferOwnershipInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptTransferOwnershipInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptTransferOwnershipInviteMutation, { data, loading, error }] = useAcceptTransferOwnershipInviteMutation({
 *   variables: {
 *      inviteKey: // value for 'inviteKey'
 *   },
 * });
 */
export function useAcceptTransferOwnershipInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptTransferOwnershipInviteMutation,
    AcceptTransferOwnershipInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptTransferOwnershipInviteMutation,
    AcceptTransferOwnershipInviteMutationVariables
  >(AcceptTransferOwnershipInviteDocument, options);
}
export type AcceptTransferOwnershipInviteMutationHookResult = ReturnType<
  typeof useAcceptTransferOwnershipInviteMutation
>;
export type AcceptTransferOwnershipInviteMutationResult = Apollo.MutationResult<
  AcceptTransferOwnershipInviteMutation
>;
export type AcceptTransferOwnershipInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptTransferOwnershipInviteMutation,
  AcceptTransferOwnershipInviteMutationVariables
>;
export const DeclineTransferOwnershipInviteDocument = gql`
  mutation declineTransferOwnershipInvite($inviteKey: String!) {
    declineTransferOwnershipInvite(inviteKey: $inviteKey) {
      invitee_email
      project_id
    }
  }
`;
export type DeclineTransferOwnershipInviteMutationFn = Apollo.MutationFunction<
  DeclineTransferOwnershipInviteMutation,
  DeclineTransferOwnershipInviteMutationVariables
>;

/**
 * __useDeclineTransferOwnershipInviteMutation__
 *
 * To run a mutation, you first call `useDeclineTransferOwnershipInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineTransferOwnershipInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineTransferOwnershipInviteMutation, { data, loading, error }] = useDeclineTransferOwnershipInviteMutation({
 *   variables: {
 *      inviteKey: // value for 'inviteKey'
 *   },
 * });
 */
export function useDeclineTransferOwnershipInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineTransferOwnershipInviteMutation,
    DeclineTransferOwnershipInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeclineTransferOwnershipInviteMutation,
    DeclineTransferOwnershipInviteMutationVariables
  >(DeclineTransferOwnershipInviteDocument, options);
}
export type DeclineTransferOwnershipInviteMutationHookResult = ReturnType<
  typeof useDeclineTransferOwnershipInviteMutation
>;
export type DeclineTransferOwnershipInviteMutationResult = Apollo.MutationResult<
  DeclineTransferOwnershipInviteMutation
>;
export type DeclineTransferOwnershipInviteMutationOptions = Apollo.BaseMutationOptions<
  DeclineTransferOwnershipInviteMutation,
  DeclineTransferOwnershipInviteMutationVariables
>;
export const UpdateDatadogConfigDataDocument = gql`
  mutation updateDatadogConfigData(
    $id: uuid!
    $host: String!
    $service: String!
    $tags: String!
    $key: String!
    $region: String!
  ) {
    updateDatadogConfig(
      project_id: $id
      key: $key
      datadog_region: $region
      tags: $tags
      service_name: $service
      host: $host
    ) {
      project_id
    }
  }
`;
export type UpdateDatadogConfigDataMutationFn = Apollo.MutationFunction<
  UpdateDatadogConfigDataMutation,
  UpdateDatadogConfigDataMutationVariables
>;

/**
 * __useUpdateDatadogConfigDataMutation__
 *
 * To run a mutation, you first call `useUpdateDatadogConfigDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDatadogConfigDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDatadogConfigDataMutation, { data, loading, error }] = useUpdateDatadogConfigDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      host: // value for 'host'
 *      service: // value for 'service'
 *      tags: // value for 'tags'
 *      key: // value for 'key'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useUpdateDatadogConfigDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateDatadogConfigDataMutation,
    UpdateDatadogConfigDataMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateDatadogConfigDataMutation,
    UpdateDatadogConfigDataMutationVariables
  >(UpdateDatadogConfigDataDocument, options);
}
export type UpdateDatadogConfigDataMutationHookResult = ReturnType<
  typeof useUpdateDatadogConfigDataMutation
>;
export type UpdateDatadogConfigDataMutationResult = Apollo.MutationResult<
  UpdateDatadogConfigDataMutation
>;
export type UpdateDatadogConfigDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateDatadogConfigDataMutation,
  UpdateDatadogConfigDataMutationVariables
>;
export const UpdateNewrelicConfigDocument = gql`
  mutation updateNewrelicConfig(
    $id: uuid!
    $key: String!
    $region: String!
    $attributes: jsonb!
  ) {
    updateNewrelicConfig(
      project_id: $id
      key: $key
      attributes: $attributes
      newrelic_region: $region
    ) {
      project_id
    }
  }
`;
export type UpdateNewrelicConfigMutationFn = Apollo.MutationFunction<
  UpdateNewrelicConfigMutation,
  UpdateNewrelicConfigMutationVariables
>;

/**
 * __useUpdateNewrelicConfigMutation__
 *
 * To run a mutation, you first call `useUpdateNewrelicConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNewrelicConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNewrelicConfigMutation, { data, loading, error }] = useUpdateNewrelicConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *      key: // value for 'key'
 *      region: // value for 'region'
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useUpdateNewrelicConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNewrelicConfigMutation,
    UpdateNewrelicConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateNewrelicConfigMutation,
    UpdateNewrelicConfigMutationVariables
  >(UpdateNewrelicConfigDocument, options);
}
export type UpdateNewrelicConfigMutationHookResult = ReturnType<
  typeof useUpdateNewrelicConfigMutation
>;
export type UpdateNewrelicConfigMutationResult = Apollo.MutationResult<
  UpdateNewrelicConfigMutation
>;
export type UpdateNewrelicConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateNewrelicConfigMutation,
  UpdateNewrelicConfigMutationVariables
>;
export const UpdateAzureMonitorConfigDocument = gql`
  mutation updateAzureMonitorConfig(
    $project_id: uuid!
    $azuremonitor_workspace_id: String!
    $shared_key: String!
    $resource_id: String!
    $log_type: String!
    $metric_namespace: String!
    $activedirectory_tenant_id: String!
    $activedirectory_client_id: String!
    $activedirectory_client_secret: String!
    $azure_region: String!
    $attributes: jsonb
    $instrumentation_key: String
  ) {
    updateAzureMonitorConfig(
      project_id: $project_id
      azuremonitor_workspace_id: $azuremonitor_workspace_id
      shared_key: $shared_key
      resource_id: $resource_id
      log_type: $log_type
      metric_namespace: $metric_namespace
      activedirectory_tenant_id: $activedirectory_tenant_id
      activedirectory_client_id: $activedirectory_client_id
      activedirectory_client_secret: $activedirectory_client_secret
      azure_region: $azure_region
      attributes: $attributes
      instrumentation_key: $instrumentation_key
    ) {
      project_id
    }
  }
`;
export type UpdateAzureMonitorConfigMutationFn = Apollo.MutationFunction<
  UpdateAzureMonitorConfigMutation,
  UpdateAzureMonitorConfigMutationVariables
>;

/**
 * __useUpdateAzureMonitorConfigMutation__
 *
 * To run a mutation, you first call `useUpdateAzureMonitorConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAzureMonitorConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAzureMonitorConfigMutation, { data, loading, error }] = useUpdateAzureMonitorConfigMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      azuremonitor_workspace_id: // value for 'azuremonitor_workspace_id'
 *      shared_key: // value for 'shared_key'
 *      resource_id: // value for 'resource_id'
 *      log_type: // value for 'log_type'
 *      metric_namespace: // value for 'metric_namespace'
 *      activedirectory_tenant_id: // value for 'activedirectory_tenant_id'
 *      activedirectory_client_id: // value for 'activedirectory_client_id'
 *      activedirectory_client_secret: // value for 'activedirectory_client_secret'
 *      azure_region: // value for 'azure_region'
 *      attributes: // value for 'attributes'
 *      instrumentation_key: // value for 'instrumentation_key'
 *   },
 * });
 */
export function useUpdateAzureMonitorConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAzureMonitorConfigMutation,
    UpdateAzureMonitorConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateAzureMonitorConfigMutation,
    UpdateAzureMonitorConfigMutationVariables
  >(UpdateAzureMonitorConfigDocument, options);
}
export type UpdateAzureMonitorConfigMutationHookResult = ReturnType<
  typeof useUpdateAzureMonitorConfigMutation
>;
export type UpdateAzureMonitorConfigMutationResult = Apollo.MutationResult<
  UpdateAzureMonitorConfigMutation
>;
export type UpdateAzureMonitorConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateAzureMonitorConfigMutation,
  UpdateAzureMonitorConfigMutationVariables
>;
export const UpdatePrometheusConfigDocument = gql`
  mutation updatePrometheusConfig(
    $project_id: uuid!
    $namespace: String
    $labels: jsonb
  ) {
    updatePrometheusConfig(
      project_id: $project_id
      namespace: $namespace
      labels: $labels
    ) {
      project_id
    }
  }
`;
export type UpdatePrometheusConfigMutationFn = Apollo.MutationFunction<
  UpdatePrometheusConfigMutation,
  UpdatePrometheusConfigMutationVariables
>;

/**
 * __useUpdatePrometheusConfigMutation__
 *
 * To run a mutation, you first call `useUpdatePrometheusConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrometheusConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrometheusConfigMutation, { data, loading, error }] = useUpdatePrometheusConfigMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      namespace: // value for 'namespace'
 *      labels: // value for 'labels'
 *   },
 * });
 */
export function useUpdatePrometheusConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrometheusConfigMutation,
    UpdatePrometheusConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePrometheusConfigMutation,
    UpdatePrometheusConfigMutationVariables
  >(UpdatePrometheusConfigDocument, options);
}
export type UpdatePrometheusConfigMutationHookResult = ReturnType<
  typeof useUpdatePrometheusConfigMutation
>;
export type UpdatePrometheusConfigMutationResult = Apollo.MutationResult<
  UpdatePrometheusConfigMutation
>;
export type UpdatePrometheusConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrometheusConfigMutation,
  UpdatePrometheusConfigMutationVariables
>;
export const UpdateOpentelemetryConfigDocument = gql`
  mutation updateOpentelemetryConfig(
    $project_id: uuid!
    $attributes: jsonb
    $batch_size: Int
    $connection_type: String
    $endpoint: String
    $export_traces: Boolean
    $headers: jsonb
  ) {
    updateOpentelemetryConfig(
      project_id: $project_id
      attributes: $attributes
      batch_size: $batch_size
      connection_type: $connection_type
      endpoint: $endpoint
      export_traces: $export_traces
      headers: $headers
    ) {
      project_id
    }
  }
`;
export type UpdateOpentelemetryConfigMutationFn = Apollo.MutationFunction<
  UpdateOpentelemetryConfigMutation,
  UpdateOpentelemetryConfigMutationVariables
>;

/**
 * __useUpdateOpentelemetryConfigMutation__
 *
 * To run a mutation, you first call `useUpdateOpentelemetryConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOpentelemetryConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOpentelemetryConfigMutation, { data, loading, error }] = useUpdateOpentelemetryConfigMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      attributes: // value for 'attributes'
 *      batch_size: // value for 'batch_size'
 *      connection_type: // value for 'connection_type'
 *      endpoint: // value for 'endpoint'
 *      export_traces: // value for 'export_traces'
 *      headers: // value for 'headers'
 *   },
 * });
 */
export function useUpdateOpentelemetryConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateOpentelemetryConfigMutation,
    UpdateOpentelemetryConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateOpentelemetryConfigMutation,
    UpdateOpentelemetryConfigMutationVariables
  >(UpdateOpentelemetryConfigDocument, options);
}
export type UpdateOpentelemetryConfigMutationHookResult = ReturnType<
  typeof useUpdateOpentelemetryConfigMutation
>;
export type UpdateOpentelemetryConfigMutationResult = Apollo.MutationResult<
  UpdateOpentelemetryConfigMutation
>;
export type UpdateOpentelemetryConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateOpentelemetryConfigMutation,
  UpdateOpentelemetryConfigMutationVariables
>;
export const UpdatePrometheusAccessTokenDocument = gql`
  mutation updatePrometheusAccessToken($project_id: uuid!) {
    updatePrometheusAccessToken(project_id: $project_id) {
      project_id
      access_token
    }
  }
`;
export type UpdatePrometheusAccessTokenMutationFn = Apollo.MutationFunction<
  UpdatePrometheusAccessTokenMutation,
  UpdatePrometheusAccessTokenMutationVariables
>;

/**
 * __useUpdatePrometheusAccessTokenMutation__
 *
 * To run a mutation, you first call `useUpdatePrometheusAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrometheusAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrometheusAccessTokenMutation, { data, loading, error }] = useUpdatePrometheusAccessTokenMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *   },
 * });
 */
export function useUpdatePrometheusAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePrometheusAccessTokenMutation,
    UpdatePrometheusAccessTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdatePrometheusAccessTokenMutation,
    UpdatePrometheusAccessTokenMutationVariables
  >(UpdatePrometheusAccessTokenDocument, options);
}
export type UpdatePrometheusAccessTokenMutationHookResult = ReturnType<
  typeof useUpdatePrometheusAccessTokenMutation
>;
export type UpdatePrometheusAccessTokenMutationResult = Apollo.MutationResult<
  UpdatePrometheusAccessTokenMutation
>;
export type UpdatePrometheusAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  UpdatePrometheusAccessTokenMutation,
  UpdatePrometheusAccessTokenMutationVariables
>;
export const DisconnectDatadogDocument = gql`
  mutation disconnectDatadog($id: uuid!) {
    delete_datadog_config(where: { project_id: { _eq: $id } }) {
      returning {
        project_id
      }
    }
  }
`;
export type DisconnectDatadogMutationFn = Apollo.MutationFunction<
  DisconnectDatadogMutation,
  DisconnectDatadogMutationVariables
>;

/**
 * __useDisconnectDatadogMutation__
 *
 * To run a mutation, you first call `useDisconnectDatadogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectDatadogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectDatadogMutation, { data, loading, error }] = useDisconnectDatadogMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisconnectDatadogMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectDatadogMutation,
    DisconnectDatadogMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectDatadogMutation,
    DisconnectDatadogMutationVariables
  >(DisconnectDatadogDocument, options);
}
export type DisconnectDatadogMutationHookResult = ReturnType<
  typeof useDisconnectDatadogMutation
>;
export type DisconnectDatadogMutationResult = Apollo.MutationResult<
  DisconnectDatadogMutation
>;
export type DisconnectDatadogMutationOptions = Apollo.BaseMutationOptions<
  DisconnectDatadogMutation,
  DisconnectDatadogMutationVariables
>;
export const DisconnectNewRelicDocument = gql`
  mutation disconnectNewRelic($id: uuid!) {
    delete_newrelic_config(where: { project_id: { _eq: $id } }) {
      returning {
        project_id
      }
    }
  }
`;
export type DisconnectNewRelicMutationFn = Apollo.MutationFunction<
  DisconnectNewRelicMutation,
  DisconnectNewRelicMutationVariables
>;

/**
 * __useDisconnectNewRelicMutation__
 *
 * To run a mutation, you first call `useDisconnectNewRelicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisconnectNewRelicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disconnectNewRelicMutation, { data, loading, error }] = useDisconnectNewRelicMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisconnectNewRelicMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DisconnectNewRelicMutation,
    DisconnectNewRelicMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DisconnectNewRelicMutation,
    DisconnectNewRelicMutationVariables
  >(DisconnectNewRelicDocument, options);
}
export type DisconnectNewRelicMutationHookResult = ReturnType<
  typeof useDisconnectNewRelicMutation
>;
export type DisconnectNewRelicMutationResult = Apollo.MutationResult<
  DisconnectNewRelicMutation
>;
export type DisconnectNewRelicMutationOptions = Apollo.BaseMutationOptions<
  DisconnectNewRelicMutation,
  DisconnectNewRelicMutationVariables
>;
export const AcceptBillingOwnerInviteDocument = gql`
  mutation acceptBillingOwnerInvite($key: String!) {
    acceptBillingManagerInvite(key: $key) {
      project_id
    }
  }
`;
export type AcceptBillingOwnerInviteMutationFn = Apollo.MutationFunction<
  AcceptBillingOwnerInviteMutation,
  AcceptBillingOwnerInviteMutationVariables
>;

/**
 * __useAcceptBillingOwnerInviteMutation__
 *
 * To run a mutation, you first call `useAcceptBillingOwnerInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBillingOwnerInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBillingOwnerInviteMutation, { data, loading, error }] = useAcceptBillingOwnerInviteMutation({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useAcceptBillingOwnerInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptBillingOwnerInviteMutation,
    AcceptBillingOwnerInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptBillingOwnerInviteMutation,
    AcceptBillingOwnerInviteMutationVariables
  >(AcceptBillingOwnerInviteDocument, options);
}
export type AcceptBillingOwnerInviteMutationHookResult = ReturnType<
  typeof useAcceptBillingOwnerInviteMutation
>;
export type AcceptBillingOwnerInviteMutationResult = Apollo.MutationResult<
  AcceptBillingOwnerInviteMutation
>;
export type AcceptBillingOwnerInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptBillingOwnerInviteMutation,
  AcceptBillingOwnerInviteMutationVariables
>;
export const Delete_Azuremonitor_ConfigDocument = gql`
  mutation delete_azuremonitor_config($id: uuid!) {
    delete_azuremonitor_config(where: { project_id: { _eq: $id } }) {
      returning {
        project_id
      }
    }
  }
`;
export type Delete_Azuremonitor_ConfigMutationFn = Apollo.MutationFunction<
  Delete_Azuremonitor_ConfigMutation,
  Delete_Azuremonitor_ConfigMutationVariables
>;

/**
 * __useDelete_Azuremonitor_ConfigMutation__
 *
 * To run a mutation, you first call `useDelete_Azuremonitor_ConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Azuremonitor_ConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAzuremonitorConfigMutation, { data, loading, error }] = useDelete_Azuremonitor_ConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_Azuremonitor_ConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Delete_Azuremonitor_ConfigMutation,
    Delete_Azuremonitor_ConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Delete_Azuremonitor_ConfigMutation,
    Delete_Azuremonitor_ConfigMutationVariables
  >(Delete_Azuremonitor_ConfigDocument, options);
}
export type Delete_Azuremonitor_ConfigMutationHookResult = ReturnType<
  typeof useDelete_Azuremonitor_ConfigMutation
>;
export type Delete_Azuremonitor_ConfigMutationResult = Apollo.MutationResult<
  Delete_Azuremonitor_ConfigMutation
>;
export type Delete_Azuremonitor_ConfigMutationOptions = Apollo.BaseMutationOptions<
  Delete_Azuremonitor_ConfigMutation,
  Delete_Azuremonitor_ConfigMutationVariables
>;
export const Delete_Prometheus_ConfigDocument = gql`
  mutation delete_prometheus_config($id: uuid!) {
    delete_prometheus_config(where: { project_id: { _eq: $id } }) {
      returning {
        project_id
      }
    }
  }
`;
export type Delete_Prometheus_ConfigMutationFn = Apollo.MutationFunction<
  Delete_Prometheus_ConfigMutation,
  Delete_Prometheus_ConfigMutationVariables
>;

/**
 * __useDelete_Prometheus_ConfigMutation__
 *
 * To run a mutation, you first call `useDelete_Prometheus_ConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDelete_Prometheus_ConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePrometheusConfigMutation, { data, loading, error }] = useDelete_Prometheus_ConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDelete_Prometheus_ConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Delete_Prometheus_ConfigMutation,
    Delete_Prometheus_ConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Delete_Prometheus_ConfigMutation,
    Delete_Prometheus_ConfigMutationVariables
  >(Delete_Prometheus_ConfigDocument, options);
}
export type Delete_Prometheus_ConfigMutationHookResult = ReturnType<
  typeof useDelete_Prometheus_ConfigMutation
>;
export type Delete_Prometheus_ConfigMutationResult = Apollo.MutationResult<
  Delete_Prometheus_ConfigMutation
>;
export type Delete_Prometheus_ConfigMutationOptions = Apollo.BaseMutationOptions<
  Delete_Prometheus_ConfigMutation,
  Delete_Prometheus_ConfigMutationVariables
>;
export const DeleteOpentelemetryConfigDocument = gql`
  mutation deleteOpentelemetryConfig($id: uuid!) {
    delete_opentelemetry_config(where: { project_id: { _eq: $id } }) {
      returning {
        project_id
      }
    }
  }
`;
export type DeleteOpentelemetryConfigMutationFn = Apollo.MutationFunction<
  DeleteOpentelemetryConfigMutation,
  DeleteOpentelemetryConfigMutationVariables
>;

/**
 * __useDeleteOpentelemetryConfigMutation__
 *
 * To run a mutation, you first call `useDeleteOpentelemetryConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOpentelemetryConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOpentelemetryConfigMutation, { data, loading, error }] = useDeleteOpentelemetryConfigMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteOpentelemetryConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteOpentelemetryConfigMutation,
    DeleteOpentelemetryConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteOpentelemetryConfigMutation,
    DeleteOpentelemetryConfigMutationVariables
  >(DeleteOpentelemetryConfigDocument, options);
}
export type DeleteOpentelemetryConfigMutationHookResult = ReturnType<
  typeof useDeleteOpentelemetryConfigMutation
>;
export type DeleteOpentelemetryConfigMutationResult = Apollo.MutationResult<
  DeleteOpentelemetryConfigMutation
>;
export type DeleteOpentelemetryConfigMutationOptions = Apollo.BaseMutationOptions<
  DeleteOpentelemetryConfigMutation,
  DeleteOpentelemetryConfigMutationVariables
>;
export const UpdateProjectVersionDocument = gql`
  mutation updateProjectVersion($projectId: uuid!) {
    createJob(
      input: { type: v1_to_v2_upgrade, payload: { project_id: $projectId } }
    ) {
      job_id
    }
  }
`;
export type UpdateProjectVersionMutationFn = Apollo.MutationFunction<
  UpdateProjectVersionMutation,
  UpdateProjectVersionMutationVariables
>;

/**
 * __useUpdateProjectVersionMutation__
 *
 * To run a mutation, you first call `useUpdateProjectVersionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectVersionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectVersionMutation, { data, loading, error }] = useUpdateProjectVersionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useUpdateProjectVersionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProjectVersionMutation,
    UpdateProjectVersionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateProjectVersionMutation,
    UpdateProjectVersionMutationVariables
  >(UpdateProjectVersionDocument, options);
}
export type UpdateProjectVersionMutationHookResult = ReturnType<
  typeof useUpdateProjectVersionMutation
>;
export type UpdateProjectVersionMutationResult = Apollo.MutationResult<
  UpdateProjectVersionMutation
>;
export type UpdateProjectVersionMutationOptions = Apollo.BaseMutationOptions<
  UpdateProjectVersionMutation,
  UpdateProjectVersionMutationVariables
>;
export const DeclineBillingOwnerInviteDocument = gql`
  mutation declineBillingOwnerInvite($projectId: uuid!) {
    declineBillingManagerInvite(project_id: $projectId) {
      project_id
    }
  }
`;
export type DeclineBillingOwnerInviteMutationFn = Apollo.MutationFunction<
  DeclineBillingOwnerInviteMutation,
  DeclineBillingOwnerInviteMutationVariables
>;

/**
 * __useDeclineBillingOwnerInviteMutation__
 *
 * To run a mutation, you first call `useDeclineBillingOwnerInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineBillingOwnerInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineBillingOwnerInviteMutation, { data, loading, error }] = useDeclineBillingOwnerInviteMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeclineBillingOwnerInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineBillingOwnerInviteMutation,
    DeclineBillingOwnerInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeclineBillingOwnerInviteMutation,
    DeclineBillingOwnerInviteMutationVariables
  >(DeclineBillingOwnerInviteDocument, options);
}
export type DeclineBillingOwnerInviteMutationHookResult = ReturnType<
  typeof useDeclineBillingOwnerInviteMutation
>;
export type DeclineBillingOwnerInviteMutationResult = Apollo.MutationResult<
  DeclineBillingOwnerInviteMutation
>;
export type DeclineBillingOwnerInviteMutationOptions = Apollo.BaseMutationOptions<
  DeclineBillingOwnerInviteMutation,
  DeclineBillingOwnerInviteMutationVariables
>;
export const GithubTokenExchangeDocument = gql`
  mutation githubTokenExchange($code: String!, $projectId: uuid!) {
    githubTokenExchange(code: $code, project_id: $projectId) {
      status
    }
  }
`;
export type GithubTokenExchangeMutationFn = Apollo.MutationFunction<
  GithubTokenExchangeMutation,
  GithubTokenExchangeMutationVariables
>;

/**
 * __useGithubTokenExchangeMutation__
 *
 * To run a mutation, you first call `useGithubTokenExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGithubTokenExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [githubTokenExchangeMutation, { data, loading, error }] = useGithubTokenExchangeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGithubTokenExchangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GithubTokenExchangeMutation,
    GithubTokenExchangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GithubTokenExchangeMutation,
    GithubTokenExchangeMutationVariables
  >(GithubTokenExchangeDocument, options);
}
export type GithubTokenExchangeMutationHookResult = ReturnType<
  typeof useGithubTokenExchangeMutation
>;
export type GithubTokenExchangeMutationResult = Apollo.MutationResult<
  GithubTokenExchangeMutation
>;
export type GithubTokenExchangeMutationOptions = Apollo.BaseMutationOptions<
  GithubTokenExchangeMutation,
  GithubTokenExchangeMutationVariables
>;
export const RemoveBillingOwnerDocument = gql`
  mutation removeBillingOwner($projectId: uuid!) {
    updateBillingManager(project_id: $projectId) {
      status
    }
  }
`;
export type RemoveBillingOwnerMutationFn = Apollo.MutationFunction<
  RemoveBillingOwnerMutation,
  RemoveBillingOwnerMutationVariables
>;

/**
 * __useRemoveBillingOwnerMutation__
 *
 * To run a mutation, you first call `useRemoveBillingOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveBillingOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeBillingOwnerMutation, { data, loading, error }] = useRemoveBillingOwnerMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRemoveBillingOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveBillingOwnerMutation,
    RemoveBillingOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveBillingOwnerMutation,
    RemoveBillingOwnerMutationVariables
  >(RemoveBillingOwnerDocument, options);
}
export type RemoveBillingOwnerMutationHookResult = ReturnType<
  typeof useRemoveBillingOwnerMutation
>;
export type RemoveBillingOwnerMutationResult = Apollo.MutationResult<
  RemoveBillingOwnerMutation
>;
export type RemoveBillingOwnerMutationOptions = Apollo.BaseMutationOptions<
  RemoveBillingOwnerMutation,
  RemoveBillingOwnerMutationVariables
>;
export const GetCurrentGithubLoginDocument = gql`
  mutation getCurrentGithubLogin($projectId: uuid!) {
    fetchCurrentGithubLogin(project_id: $projectId) {
      login
    }
  }
`;
export type GetCurrentGithubLoginMutationFn = Apollo.MutationFunction<
  GetCurrentGithubLoginMutation,
  GetCurrentGithubLoginMutationVariables
>;

/**
 * __useGetCurrentGithubLoginMutation__
 *
 * To run a mutation, you first call `useGetCurrentGithubLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentGithubLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCurrentGithubLoginMutation, { data, loading, error }] = useGetCurrentGithubLoginMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetCurrentGithubLoginMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetCurrentGithubLoginMutation,
    GetCurrentGithubLoginMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetCurrentGithubLoginMutation,
    GetCurrentGithubLoginMutationVariables
  >(GetCurrentGithubLoginDocument, options);
}
export type GetCurrentGithubLoginMutationHookResult = ReturnType<
  typeof useGetCurrentGithubLoginMutation
>;
export type GetCurrentGithubLoginMutationResult = Apollo.MutationResult<
  GetCurrentGithubLoginMutation
>;
export type GetCurrentGithubLoginMutationOptions = Apollo.BaseMutationOptions<
  GetCurrentGithubLoginMutation,
  GetCurrentGithubLoginMutationVariables
>;
export const GetGithubAppInstallationsDocument = gql`
  mutation getGithubAppInstallations($projectId: uuid!) {
    fetchGithubAppInstallations(project_id: $projectId) {
      installations {
        app_slug
        id
      }
    }
  }
`;
export type GetGithubAppInstallationsMutationFn = Apollo.MutationFunction<
  GetGithubAppInstallationsMutation,
  GetGithubAppInstallationsMutationVariables
>;

/**
 * __useGetGithubAppInstallationsMutation__
 *
 * To run a mutation, you first call `useGetGithubAppInstallationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetGithubAppInstallationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getGithubAppInstallationsMutation, { data, loading, error }] = useGetGithubAppInstallationsMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetGithubAppInstallationsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetGithubAppInstallationsMutation,
    GetGithubAppInstallationsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetGithubAppInstallationsMutation,
    GetGithubAppInstallationsMutationVariables
  >(GetGithubAppInstallationsDocument, options);
}
export type GetGithubAppInstallationsMutationHookResult = ReturnType<
  typeof useGetGithubAppInstallationsMutation
>;
export type GetGithubAppInstallationsMutationResult = Apollo.MutationResult<
  GetGithubAppInstallationsMutation
>;
export type GetGithubAppInstallationsMutationOptions = Apollo.BaseMutationOptions<
  GetGithubAppInstallationsMutation,
  GetGithubAppInstallationsMutationVariables
>;
export const GetGithubReposDocument = gql`
  mutation GetGithubRepos($projectId: uuid!, $installationId: Int!) {
    fetchGithubAppIntegrationAccessibleRepos(
      installation_id: $installationId
      project_id: $projectId
    ) {
      repositories {
        installation_id
        name
        owner_login
      }
      total_count
    }
  }
`;
export type GetGithubReposMutationFn = Apollo.MutationFunction<
  GetGithubReposMutation,
  GetGithubReposMutationVariables
>;

/**
 * __useGetGithubReposMutation__
 *
 * To run a mutation, you first call `useGetGithubReposMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetGithubReposMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getGithubReposMutation, { data, loading, error }] = useGetGithubReposMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      installationId: // value for 'installationId'
 *   },
 * });
 */
export function useGetGithubReposMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetGithubReposMutation,
    GetGithubReposMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetGithubReposMutation,
    GetGithubReposMutationVariables
  >(GetGithubReposDocument, options);
}
export type GetGithubReposMutationHookResult = ReturnType<
  typeof useGetGithubReposMutation
>;
export type GetGithubReposMutationResult = Apollo.MutationResult<
  GetGithubReposMutation
>;
export type GetGithubReposMutationOptions = Apollo.BaseMutationOptions<
  GetGithubReposMutation,
  GetGithubReposMutationVariables
>;
export const GetGithubRepoBranchesDocument = gql`
  mutation GetGithubRepoBranches(
    $owner: String!
    $projectId: uuid!
    $repo: String!
  ) {
    fetchGithubRepoBranches(
      owner: $owner
      project_id: $projectId
      repo: $repo
    ) {
      branches {
        name
      }
    }
  }
`;
export type GetGithubRepoBranchesMutationFn = Apollo.MutationFunction<
  GetGithubRepoBranchesMutation,
  GetGithubRepoBranchesMutationVariables
>;

/**
 * __useGetGithubRepoBranchesMutation__
 *
 * To run a mutation, you first call `useGetGithubRepoBranchesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetGithubRepoBranchesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getGithubRepoBranchesMutation, { data, loading, error }] = useGetGithubRepoBranchesMutation({
 *   variables: {
 *      owner: // value for 'owner'
 *      projectId: // value for 'projectId'
 *      repo: // value for 'repo'
 *   },
 * });
 */
export function useGetGithubRepoBranchesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetGithubRepoBranchesMutation,
    GetGithubRepoBranchesMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetGithubRepoBranchesMutation,
    GetGithubRepoBranchesMutationVariables
  >(GetGithubRepoBranchesDocument, options);
}
export type GetGithubRepoBranchesMutationHookResult = ReturnType<
  typeof useGetGithubRepoBranchesMutation
>;
export type GetGithubRepoBranchesMutationResult = Apollo.MutationResult<
  GetGithubRepoBranchesMutation
>;
export type GetGithubRepoBranchesMutationOptions = Apollo.BaseMutationOptions<
  GetGithubRepoBranchesMutation,
  GetGithubRepoBranchesMutationVariables
>;
export const CreateGithubIntegrationDocument = gql`
  mutation createGithubIntegration(
    $projectId: String!
    $branch: String!
    $org: String!
    $repo: String!
    $installationId: Int!
    $directory: String!
    $mode: ModeEnum
    $email_status: String!
  ) {
    createGithubIntegration(
      payload: {
        branch: $branch
        org: $org
        project_id: $projectId
        repo: $repo
        installation_id: $installationId
        directory: $directory
        mode: $mode
        email_status: $email_status
      }
    ) {
      status
    }
  }
`;
export type CreateGithubIntegrationMutationFn = Apollo.MutationFunction<
  CreateGithubIntegrationMutation,
  CreateGithubIntegrationMutationVariables
>;

/**
 * __useCreateGithubIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateGithubIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGithubIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGithubIntegrationMutation, { data, loading, error }] = useCreateGithubIntegrationMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      branch: // value for 'branch'
 *      org: // value for 'org'
 *      repo: // value for 'repo'
 *      installationId: // value for 'installationId'
 *      directory: // value for 'directory'
 *      mode: // value for 'mode'
 *      email_status: // value for 'email_status'
 *   },
 * });
 */
export function useCreateGithubIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGithubIntegrationMutation,
    CreateGithubIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGithubIntegrationMutation,
    CreateGithubIntegrationMutationVariables
  >(CreateGithubIntegrationDocument, options);
}
export type CreateGithubIntegrationMutationHookResult = ReturnType<
  typeof useCreateGithubIntegrationMutation
>;
export type CreateGithubIntegrationMutationResult = Apollo.MutationResult<
  CreateGithubIntegrationMutation
>;
export type CreateGithubIntegrationMutationOptions = Apollo.BaseMutationOptions<
  CreateGithubIntegrationMutation,
  CreateGithubIntegrationMutationVariables
>;
export const RemoveGithubIntegrationDocument = gql`
  mutation removeGithubIntegration($integrationId: String!) {
    removeGithubIntegration(payload: { integration_id: $integrationId }) {
      status
    }
  }
`;
export type RemoveGithubIntegrationMutationFn = Apollo.MutationFunction<
  RemoveGithubIntegrationMutation,
  RemoveGithubIntegrationMutationVariables
>;

/**
 * __useRemoveGithubIntegrationMutation__
 *
 * To run a mutation, you first call `useRemoveGithubIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGithubIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGithubIntegrationMutation, { data, loading, error }] = useRemoveGithubIntegrationMutation({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *   },
 * });
 */
export function useRemoveGithubIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGithubIntegrationMutation,
    RemoveGithubIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGithubIntegrationMutation,
    RemoveGithubIntegrationMutationVariables
  >(RemoveGithubIntegrationDocument, options);
}
export type RemoveGithubIntegrationMutationHookResult = ReturnType<
  typeof useRemoveGithubIntegrationMutation
>;
export type RemoveGithubIntegrationMutationResult = Apollo.MutationResult<
  RemoveGithubIntegrationMutation
>;
export type RemoveGithubIntegrationMutationOptions = Apollo.BaseMutationOptions<
  RemoveGithubIntegrationMutation,
  RemoveGithubIntegrationMutationVariables
>;
export const UpdateGithubIntegrationDocument = gql`
  mutation updateGithubIntegration(
    $branch: String!
    $org: String!
    $repo: String!
    $directory: String!
    $installationId: Int!
    $integrationId: String!
    $mode: ModeEnum
    $email_status: String!
  ) {
    updateGithubIntegration(
      payload: {
        branch: $branch
        org: $org
        repo: $repo
        directory: $directory
        installation_id: $installationId
        integration_id: $integrationId
        mode: $mode
        email_status: $email_status
      }
    ) {
      status
    }
  }
`;
export type UpdateGithubIntegrationMutationFn = Apollo.MutationFunction<
  UpdateGithubIntegrationMutation,
  UpdateGithubIntegrationMutationVariables
>;

/**
 * __useUpdateGithubIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateGithubIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGithubIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGithubIntegrationMutation, { data, loading, error }] = useUpdateGithubIntegrationMutation({
 *   variables: {
 *      branch: // value for 'branch'
 *      org: // value for 'org'
 *      repo: // value for 'repo'
 *      directory: // value for 'directory'
 *      installationId: // value for 'installationId'
 *      integrationId: // value for 'integrationId'
 *      mode: // value for 'mode'
 *      email_status: // value for 'email_status'
 *   },
 * });
 */
export function useUpdateGithubIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGithubIntegrationMutation,
    UpdateGithubIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateGithubIntegrationMutation,
    UpdateGithubIntegrationMutationVariables
  >(UpdateGithubIntegrationDocument, options);
}
export type UpdateGithubIntegrationMutationHookResult = ReturnType<
  typeof useUpdateGithubIntegrationMutation
>;
export type UpdateGithubIntegrationMutationResult = Apollo.MutationResult<
  UpdateGithubIntegrationMutation
>;
export type UpdateGithubIntegrationMutationOptions = Apollo.BaseMutationOptions<
  UpdateGithubIntegrationMutation,
  UpdateGithubIntegrationMutationVariables
>;
export const RemoveGithubSessionDocument = gql`
  mutation removeGithubSession($projectId: uuid!) {
    removeGithubSession(project_id: $projectId) {
      status
    }
  }
`;
export type RemoveGithubSessionMutationFn = Apollo.MutationFunction<
  RemoveGithubSessionMutation,
  RemoveGithubSessionMutationVariables
>;

/**
 * __useRemoveGithubSessionMutation__
 *
 * To run a mutation, you first call `useRemoveGithubSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveGithubSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeGithubSessionMutation, { data, loading, error }] = useRemoveGithubSessionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRemoveGithubSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveGithubSessionMutation,
    RemoveGithubSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveGithubSessionMutation,
    RemoveGithubSessionMutationVariables
  >(RemoveGithubSessionDocument, options);
}
export type RemoveGithubSessionMutationHookResult = ReturnType<
  typeof useRemoveGithubSessionMutation
>;
export type RemoveGithubSessionMutationResult = Apollo.MutationResult<
  RemoveGithubSessionMutation
>;
export type RemoveGithubSessionMutationOptions = Apollo.BaseMutationOptions<
  RemoveGithubSessionMutation,
  RemoveGithubSessionMutationVariables
>;
export const DeployLatestCommitDocument = gql`
  mutation deployLatestCommit($id: String!) {
    deployLatestGithubCommit(payload: { github_intergation_config_id: $id }) {
      status
    }
  }
`;
export type DeployLatestCommitMutationFn = Apollo.MutationFunction<
  DeployLatestCommitMutation,
  DeployLatestCommitMutationVariables
>;

/**
 * __useDeployLatestCommitMutation__
 *
 * To run a mutation, you first call `useDeployLatestCommitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeployLatestCommitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deployLatestCommitMutation, { data, loading, error }] = useDeployLatestCommitMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeployLatestCommitMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeployLatestCommitMutation,
    DeployLatestCommitMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeployLatestCommitMutation,
    DeployLatestCommitMutationVariables
  >(DeployLatestCommitDocument, options);
}
export type DeployLatestCommitMutationHookResult = ReturnType<
  typeof useDeployLatestCommitMutation
>;
export type DeployLatestCommitMutationResult = Apollo.MutationResult<
  DeployLatestCommitMutation
>;
export type DeployLatestCommitMutationOptions = Apollo.BaseMutationOptions<
  DeployLatestCommitMutation,
  DeployLatestCommitMutationVariables
>;
export const ChangeRegionDocument = gql`
  mutation changeRegion($projectId: uuid!, $cloud: String, $region: String) {
    createJob(
      input: {
        payload: {
          project_id: $projectId
          target_cloud: $cloud
          target_region: $region
        }
        type: region_migration
      }
    ) {
      job_id
    }
  }
`;
export type ChangeRegionMutationFn = Apollo.MutationFunction<
  ChangeRegionMutation,
  ChangeRegionMutationVariables
>;

/**
 * __useChangeRegionMutation__
 *
 * To run a mutation, you first call `useChangeRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRegionMutation, { data, loading, error }] = useChangeRegionMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      cloud: // value for 'cloud'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useChangeRegionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ChangeRegionMutation,
    ChangeRegionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ChangeRegionMutation,
    ChangeRegionMutationVariables
  >(ChangeRegionDocument, options);
}
export type ChangeRegionMutationHookResult = ReturnType<
  typeof useChangeRegionMutation
>;
export type ChangeRegionMutationResult = Apollo.MutationResult<
  ChangeRegionMutation
>;
export type ChangeRegionMutationOptions = Apollo.BaseMutationOptions<
  ChangeRegionMutation,
  ChangeRegionMutationVariables
>;
export const UpsertProjectEnvironmentDocument = gql`
  mutation upsertProjectEnvironment(
    $project_id: uuid!
    $environment_name: String
    $environment_color: String
  ) {
    insert_project_metadata(
      objects: [
        {
          project_id: $project_id
          environment_name: $environment_name
          environment_color: $environment_color
        }
      ]
      on_conflict: {
        constraint: project_metadata_pkey
        update_columns: [environment_name, environment_color]
      }
    ) {
      returning {
        project_id
        environment_name
        environment_color
      }
    }
  }
`;
export type UpsertProjectEnvironmentMutationFn = Apollo.MutationFunction<
  UpsertProjectEnvironmentMutation,
  UpsertProjectEnvironmentMutationVariables
>;

/**
 * __useUpsertProjectEnvironmentMutation__
 *
 * To run a mutation, you first call `useUpsertProjectEnvironmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertProjectEnvironmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertProjectEnvironmentMutation, { data, loading, error }] = useUpsertProjectEnvironmentMutation({
 *   variables: {
 *      project_id: // value for 'project_id'
 *      environment_name: // value for 'environment_name'
 *      environment_color: // value for 'environment_color'
 *   },
 * });
 */
export function useUpsertProjectEnvironmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpsertProjectEnvironmentMutation,
    UpsertProjectEnvironmentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpsertProjectEnvironmentMutation,
    UpsertProjectEnvironmentMutationVariables
  >(UpsertProjectEnvironmentDocument, options);
}
export type UpsertProjectEnvironmentMutationHookResult = ReturnType<
  typeof useUpsertProjectEnvironmentMutation
>;
export type UpsertProjectEnvironmentMutationResult = Apollo.MutationResult<
  UpsertProjectEnvironmentMutation
>;
export type UpsertProjectEnvironmentMutationOptions = Apollo.BaseMutationOptions<
  UpsertProjectEnvironmentMutation,
  UpsertProjectEnvironmentMutationVariables
>;
export const ReactivateProjectDocument = gql`
  mutation ReactivateProject($projectId: uuid!) {
    reactivateSuspendedProject(projectId: $projectId) {
      success
      projectId
      project {
        ...ProjectFields
      }
    }
  }
  ${ProjectFieldsFragmentDoc}
`;
export type ReactivateProjectMutationFn = Apollo.MutationFunction<
  ReactivateProjectMutation,
  ReactivateProjectMutationVariables
>;

/**
 * __useReactivateProjectMutation__
 *
 * To run a mutation, you first call `useReactivateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReactivateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reactivateProjectMutation, { data, loading, error }] = useReactivateProjectMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useReactivateProjectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ReactivateProjectMutation,
    ReactivateProjectMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ReactivateProjectMutation,
    ReactivateProjectMutationVariables
  >(ReactivateProjectDocument, options);
}
export type ReactivateProjectMutationHookResult = ReturnType<
  typeof useReactivateProjectMutation
>;
export type ReactivateProjectMutationResult = Apollo.MutationResult<
  ReactivateProjectMutation
>;
export type ReactivateProjectMutationOptions = Apollo.BaseMutationOptions<
  ReactivateProjectMutation,
  ReactivateProjectMutationVariables
>;
export const CreateOneClickDeploymentDocument = gql`
  mutation createOneClickDeployment(
    $githubURL: String!
    $branch: String
    $hasuraDir: String!
    $additionalInfo: jsonb!
  ) {
    createOneClickDeployment(
      payload: {
        github_url: $githubURL
        hasura_dir: $hasuraDir
        branch: $branch
        additional_info: $additionalInfo
      }
    ) {
      project_id
      project {
        ...ProjectFields
      }
    }
  }
  ${ProjectFieldsFragmentDoc}
`;
export type CreateOneClickDeploymentMutationFn = Apollo.MutationFunction<
  CreateOneClickDeploymentMutation,
  CreateOneClickDeploymentMutationVariables
>;

/**
 * __useCreateOneClickDeploymentMutation__
 *
 * To run a mutation, you first call `useCreateOneClickDeploymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOneClickDeploymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOneClickDeploymentMutation, { data, loading, error }] = useCreateOneClickDeploymentMutation({
 *   variables: {
 *      githubURL: // value for 'githubURL'
 *      branch: // value for 'branch'
 *      hasuraDir: // value for 'hasuraDir'
 *      additionalInfo: // value for 'additionalInfo'
 *   },
 * });
 */
export function useCreateOneClickDeploymentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateOneClickDeploymentMutation,
    CreateOneClickDeploymentMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateOneClickDeploymentMutation,
    CreateOneClickDeploymentMutationVariables
  >(CreateOneClickDeploymentDocument, options);
}
export type CreateOneClickDeploymentMutationHookResult = ReturnType<
  typeof useCreateOneClickDeploymentMutation
>;
export type CreateOneClickDeploymentMutationResult = Apollo.MutationResult<
  CreateOneClickDeploymentMutation
>;
export type CreateOneClickDeploymentMutationOptions = Apollo.BaseMutationOptions<
  CreateOneClickDeploymentMutation,
  CreateOneClickDeploymentMutationVariables
>;
export const GetNewPlansProjectUsageDocument = gql`
  mutation getNewPlansProjectUsage(
    $month: Int!
    $project_id: uuid!
    $year: Int!
  ) {
    newPlansProjectUsage(month: $month, project_id: $project_id, year: $year) {
      data_usage
      data_usage_amount
      project_id
      project_name
      db_usage {
        amount
        type
        usage
      }
    }
  }
`;
export type GetNewPlansProjectUsageMutationFn = Apollo.MutationFunction<
  GetNewPlansProjectUsageMutation,
  GetNewPlansProjectUsageMutationVariables
>;

/**
 * __useGetNewPlansProjectUsageMutation__
 *
 * To run a mutation, you first call `useGetNewPlansProjectUsageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetNewPlansProjectUsageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getNewPlansProjectUsageMutation, { data, loading, error }] = useGetNewPlansProjectUsageMutation({
 *   variables: {
 *      month: // value for 'month'
 *      project_id: // value for 'project_id'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useGetNewPlansProjectUsageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetNewPlansProjectUsageMutation,
    GetNewPlansProjectUsageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetNewPlansProjectUsageMutation,
    GetNewPlansProjectUsageMutationVariables
  >(GetNewPlansProjectUsageDocument, options);
}
export type GetNewPlansProjectUsageMutationHookResult = ReturnType<
  typeof useGetNewPlansProjectUsageMutation
>;
export type GetNewPlansProjectUsageMutationResult = Apollo.MutationResult<
  GetNewPlansProjectUsageMutation
>;
export type GetNewPlansProjectUsageMutationOptions = Apollo.BaseMutationOptions<
  GetNewPlansProjectUsageMutation,
  GetNewPlansProjectUsageMutationVariables
>;
export const ConfigureSsoDocument = gql`
  mutation configureSSO($objects: [saml_idp_insert_input!]!) {
    insert_saml_idp(objects: $objects) {
      affected_rows
    }
  }
`;
export type ConfigureSsoMutationFn = Apollo.MutationFunction<
  ConfigureSsoMutation,
  ConfigureSsoMutationVariables
>;

/**
 * __useConfigureSsoMutation__
 *
 * To run a mutation, you first call `useConfigureSsoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigureSsoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configureSsoMutation, { data, loading, error }] = useConfigureSsoMutation({
 *   variables: {
 *      objects: // value for 'objects'
 *   },
 * });
 */
export function useConfigureSsoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ConfigureSsoMutation,
    ConfigureSsoMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ConfigureSsoMutation,
    ConfigureSsoMutationVariables
  >(ConfigureSsoDocument, options);
}
export type ConfigureSsoMutationHookResult = ReturnType<
  typeof useConfigureSsoMutation
>;
export type ConfigureSsoMutationResult = Apollo.MutationResult<
  ConfigureSsoMutation
>;
export type ConfigureSsoMutationOptions = Apollo.BaseMutationOptions<
  ConfigureSsoMutation,
  ConfigureSsoMutationVariables
>;
export const UpdateSsoConfigDocument = gql`
  mutation updateSSOConfig(
    $domain: String!
    $provider: String!
    $disable_request_signing: Boolean!
    $idp_metadata_xml: String
    $idp_metadata_url: String
    $config_id: uuid!
  ) {
    update_saml_idp(
      _set: {
        domain: $domain
        provider: $provider
        disable_request_signing: $disable_request_signing
        idp_metadata_url: $idp_metadata_url
        idp_metadata_xml: $idp_metadata_xml
      }
      where: { id: { _eq: $config_id } }
    ) {
      returning {
        provider
      }
    }
  }
`;
export type UpdateSsoConfigMutationFn = Apollo.MutationFunction<
  UpdateSsoConfigMutation,
  UpdateSsoConfigMutationVariables
>;

/**
 * __useUpdateSsoConfigMutation__
 *
 * To run a mutation, you first call `useUpdateSsoConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSsoConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSsoConfigMutation, { data, loading, error }] = useUpdateSsoConfigMutation({
 *   variables: {
 *      domain: // value for 'domain'
 *      provider: // value for 'provider'
 *      disable_request_signing: // value for 'disable_request_signing'
 *      idp_metadata_xml: // value for 'idp_metadata_xml'
 *      idp_metadata_url: // value for 'idp_metadata_url'
 *      config_id: // value for 'config_id'
 *   },
 * });
 */
export function useUpdateSsoConfigMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateSsoConfigMutation,
    UpdateSsoConfigMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateSsoConfigMutation,
    UpdateSsoConfigMutationVariables
  >(UpdateSsoConfigDocument, options);
}
export type UpdateSsoConfigMutationHookResult = ReturnType<
  typeof useUpdateSsoConfigMutation
>;
export type UpdateSsoConfigMutationResult = Apollo.MutationResult<
  UpdateSsoConfigMutation
>;
export type UpdateSsoConfigMutationOptions = Apollo.BaseMutationOptions<
  UpdateSsoConfigMutation,
  UpdateSsoConfigMutationVariables
>;
export const AddSurveyAnswerV2Document = gql`
  mutation addSurveyAnswerV2(
    $responses: [SurveyResponseV2]!
    $surveyName: String!
    $projectID: uuid
  ) {
    saveSurveyAnswerV2(
      payload: {
        responses: $responses
        surveyName: $surveyName
        projectID: $projectID
      }
    ) {
      status
    }
  }
`;
export type AddSurveyAnswerV2MutationFn = Apollo.MutationFunction<
  AddSurveyAnswerV2Mutation,
  AddSurveyAnswerV2MutationVariables
>;

/**
 * __useAddSurveyAnswerV2Mutation__
 *
 * To run a mutation, you first call `useAddSurveyAnswerV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddSurveyAnswerV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addSurveyAnswerV2Mutation, { data, loading, error }] = useAddSurveyAnswerV2Mutation({
 *   variables: {
 *      responses: // value for 'responses'
 *      surveyName: // value for 'surveyName'
 *      projectID: // value for 'projectID'
 *   },
 * });
 */
export function useAddSurveyAnswerV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddSurveyAnswerV2Mutation,
    AddSurveyAnswerV2MutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddSurveyAnswerV2Mutation,
    AddSurveyAnswerV2MutationVariables
  >(AddSurveyAnswerV2Document, options);
}
export type AddSurveyAnswerV2MutationHookResult = ReturnType<
  typeof useAddSurveyAnswerV2Mutation
>;
export type AddSurveyAnswerV2MutationResult = Apollo.MutationResult<
  AddSurveyAnswerV2Mutation
>;
export type AddSurveyAnswerV2MutationOptions = Apollo.BaseMutationOptions<
  AddSurveyAnswerV2Mutation,
  AddSurveyAnswerV2MutationVariables
>;
export const DeleteUserAccountDocument = gql`
  mutation deleteUserAccount($id: uuid!) {
    delete_users(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;
export type DeleteUserAccountMutationFn = Apollo.MutationFunction<
  DeleteUserAccountMutation,
  DeleteUserAccountMutationVariables
>;

/**
 * __useDeleteUserAccountMutation__
 *
 * To run a mutation, you first call `useDeleteUserAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserAccountMutation, { data, loading, error }] = useDeleteUserAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserAccountMutation,
    DeleteUserAccountMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteUserAccountMutation,
    DeleteUserAccountMutationVariables
  >(DeleteUserAccountDocument, options);
}
export type DeleteUserAccountMutationHookResult = ReturnType<
  typeof useDeleteUserAccountMutation
>;
export type DeleteUserAccountMutationResult = Apollo.MutationResult<
  DeleteUserAccountMutation
>;
export type DeleteUserAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserAccountMutation,
  DeleteUserAccountMutationVariables
>;
export const UpdateUserProfileDocument = gql`
  mutation updateUserProfile($object: user_profile_insert_input!) {
    insert_user_profile_one(
      object: $object
      on_conflict: {
        constraint: user_profile_user_id_key
        update_columns: [name, company, title, email, phone_number]
      }
    ) {
      name
      company
      email
      phone_number
      title
      updated_at
    }
  }
`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserProfileMutation,
    UpdateUserProfileMutationVariables
  >(UpdateUserProfileDocument, options);
}
export type UpdateUserProfileMutationHookResult = ReturnType<
  typeof useUpdateUserProfileMutation
>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<
  UpdateUserProfileMutation
>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserProfileMutation,
  UpdateUserProfileMutationVariables
>;
export const GetHerokuSessionDocument = gql`
  mutation getHerokuSession {
    getHerokuSession {
      access_token
      refresh_token
      expires_in
      token_type
    }
  }
`;
export type GetHerokuSessionMutationFn = Apollo.MutationFunction<
  GetHerokuSessionMutation,
  GetHerokuSessionMutationVariables
>;

/**
 * __useGetHerokuSessionMutation__
 *
 * To run a mutation, you first call `useGetHerokuSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetHerokuSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getHerokuSessionMutation, { data, loading, error }] = useGetHerokuSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useGetHerokuSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    GetHerokuSessionMutation,
    GetHerokuSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    GetHerokuSessionMutation,
    GetHerokuSessionMutationVariables
  >(GetHerokuSessionDocument, options);
}
export type GetHerokuSessionMutationHookResult = ReturnType<
  typeof useGetHerokuSessionMutation
>;
export type GetHerokuSessionMutationResult = Apollo.MutationResult<
  GetHerokuSessionMutation
>;
export type GetHerokuSessionMutationOptions = Apollo.BaseMutationOptions<
  GetHerokuSessionMutation,
  GetHerokuSessionMutationVariables
>;
export const ClearHerokuSessionDocument = gql`
  mutation clearHerokuSession {
    removeHerokuSession {
      status
    }
  }
`;
export type ClearHerokuSessionMutationFn = Apollo.MutationFunction<
  ClearHerokuSessionMutation,
  ClearHerokuSessionMutationVariables
>;

/**
 * __useClearHerokuSessionMutation__
 *
 * To run a mutation, you first call `useClearHerokuSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearHerokuSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearHerokuSessionMutation, { data, loading, error }] = useClearHerokuSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearHerokuSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ClearHerokuSessionMutation,
    ClearHerokuSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ClearHerokuSessionMutation,
    ClearHerokuSessionMutationVariables
  >(ClearHerokuSessionDocument, options);
}
export type ClearHerokuSessionMutationHookResult = ReturnType<
  typeof useClearHerokuSessionMutation
>;
export type ClearHerokuSessionMutationResult = Apollo.MutationResult<
  ClearHerokuSessionMutation
>;
export type ClearHerokuSessionMutationOptions = Apollo.BaseMutationOptions<
  ClearHerokuSessionMutation,
  ClearHerokuSessionMutationVariables
>;
export const ExchangeHerokuTokenDocument = gql`
  mutation exchangeHerokuToken($code: String!) {
    herokuTokenExchange(payload: { type: code, value: $code }) {
      access_token
      expires_in
      token_type
      refresh_token
    }
  }
`;
export type ExchangeHerokuTokenMutationFn = Apollo.MutationFunction<
  ExchangeHerokuTokenMutation,
  ExchangeHerokuTokenMutationVariables
>;

/**
 * __useExchangeHerokuTokenMutation__
 *
 * To run a mutation, you first call `useExchangeHerokuTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExchangeHerokuTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exchangeHerokuTokenMutation, { data, loading, error }] = useExchangeHerokuTokenMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useExchangeHerokuTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ExchangeHerokuTokenMutation,
    ExchangeHerokuTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ExchangeHerokuTokenMutation,
    ExchangeHerokuTokenMutationVariables
  >(ExchangeHerokuTokenDocument, options);
}
export type ExchangeHerokuTokenMutationHookResult = ReturnType<
  typeof useExchangeHerokuTokenMutation
>;
export type ExchangeHerokuTokenMutationResult = Apollo.MutationResult<
  ExchangeHerokuTokenMutation
>;
export type ExchangeHerokuTokenMutationOptions = Apollo.BaseMutationOptions<
  ExchangeHerokuTokenMutation,
  ExchangeHerokuTokenMutationVariables
>;
export const RefreshHerokuTokenDocument = gql`
  mutation refreshHerokuToken($refreshToken: String!) {
    herokuTokenExchange(payload: { type: refresh, value: $refreshToken }) {
      access_token
      expires_in
      token_type
      refresh_token
    }
  }
`;
export type RefreshHerokuTokenMutationFn = Apollo.MutationFunction<
  RefreshHerokuTokenMutation,
  RefreshHerokuTokenMutationVariables
>;

/**
 * __useRefreshHerokuTokenMutation__
 *
 * To run a mutation, you first call `useRefreshHerokuTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshHerokuTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshHerokuTokenMutation, { data, loading, error }] = useRefreshHerokuTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useRefreshHerokuTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RefreshHerokuTokenMutation,
    RefreshHerokuTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RefreshHerokuTokenMutation,
    RefreshHerokuTokenMutationVariables
  >(RefreshHerokuTokenDocument, options);
}
export type RefreshHerokuTokenMutationHookResult = ReturnType<
  typeof useRefreshHerokuTokenMutation
>;
export type RefreshHerokuTokenMutationResult = Apollo.MutationResult<
  RefreshHerokuTokenMutation
>;
export type RefreshHerokuTokenMutationOptions = Apollo.BaseMutationOptions<
  RefreshHerokuTokenMutation,
  RefreshHerokuTokenMutationVariables
>;
export const CreatePersonalAccessTokenDocument = gql`
  mutation createPersonalAccessToken($description: String!) {
    createPersonalAccessToken(token: { description: $description }) {
      id
      token
    }
  }
`;
export type CreatePersonalAccessTokenMutationFn = Apollo.MutationFunction<
  CreatePersonalAccessTokenMutation,
  CreatePersonalAccessTokenMutationVariables
>;

/**
 * __useCreatePersonalAccessTokenMutation__
 *
 * To run a mutation, you first call `useCreatePersonalAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePersonalAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPersonalAccessTokenMutation, { data, loading, error }] = useCreatePersonalAccessTokenMutation({
 *   variables: {
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreatePersonalAccessTokenMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreatePersonalAccessTokenMutation,
    CreatePersonalAccessTokenMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreatePersonalAccessTokenMutation,
    CreatePersonalAccessTokenMutationVariables
  >(CreatePersonalAccessTokenDocument, options);
}
export type CreatePersonalAccessTokenMutationHookResult = ReturnType<
  typeof useCreatePersonalAccessTokenMutation
>;
export type CreatePersonalAccessTokenMutationResult = Apollo.MutationResult<
  CreatePersonalAccessTokenMutation
>;
export type CreatePersonalAccessTokenMutationOptions = Apollo.BaseMutationOptions<
  CreatePersonalAccessTokenMutation,
  CreatePersonalAccessTokenMutationVariables
>;
export const OnboardUserDocument = gql`
  mutation onboardUser($target: String!, $details: String) {
    insert_user_onboarding_one(
      object: { is_onboarded: true, target: $target, details: $details }
      on_conflict: {
        constraint: user_onboarding_pkey
        update_columns: [is_onboarded]
      }
    ) {
      user_id
      user {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;
export type OnboardUserMutationFn = Apollo.MutationFunction<
  OnboardUserMutation,
  OnboardUserMutationVariables
>;

/**
 * __useOnboardUserMutation__
 *
 * To run a mutation, you first call `useOnboardUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOnboardUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [onboardUserMutation, { data, loading, error }] = useOnboardUserMutation({
 *   variables: {
 *      target: // value for 'target'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useOnboardUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    OnboardUserMutation,
    OnboardUserMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<OnboardUserMutation, OnboardUserMutationVariables>(
    OnboardUserDocument,
    options
  );
}
export type OnboardUserMutationHookResult = ReturnType<
  typeof useOnboardUserMutation
>;
export type OnboardUserMutationResult = Apollo.MutationResult<
  OnboardUserMutation
>;
export type OnboardUserMutationOptions = Apollo.BaseMutationOptions<
  OnboardUserMutation,
  OnboardUserMutationVariables
>;
export const RequestEmailChangeDocument = gql`
  mutation requestEmailChange($email: String!) {
    changeUserEmail(input: { new_email: $email }) {
      id
    }
  }
`;
export type RequestEmailChangeMutationFn = Apollo.MutationFunction<
  RequestEmailChangeMutation,
  RequestEmailChangeMutationVariables
>;

/**
 * __useRequestEmailChangeMutation__
 *
 * To run a mutation, you first call `useRequestEmailChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestEmailChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestEmailChangeMutation, { data, loading, error }] = useRequestEmailChangeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestEmailChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestEmailChangeMutation,
    RequestEmailChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestEmailChangeMutation,
    RequestEmailChangeMutationVariables
  >(RequestEmailChangeDocument, options);
}
export type RequestEmailChangeMutationHookResult = ReturnType<
  typeof useRequestEmailChangeMutation
>;
export type RequestEmailChangeMutationResult = Apollo.MutationResult<
  RequestEmailChangeMutation
>;
export type RequestEmailChangeMutationOptions = Apollo.BaseMutationOptions<
  RequestEmailChangeMutation,
  RequestEmailChangeMutationVariables
>;
export const ResendEmailChangeVerificationDocument = gql`
  mutation resendEmailChangeVerification($email: String!) {
    resendChangeUserEmailVerification(input: { new_email: $email }) {
      id
    }
  }
`;
export type ResendEmailChangeVerificationMutationFn = Apollo.MutationFunction<
  ResendEmailChangeVerificationMutation,
  ResendEmailChangeVerificationMutationVariables
>;

/**
 * __useResendEmailChangeVerificationMutation__
 *
 * To run a mutation, you first call `useResendEmailChangeVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailChangeVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailChangeVerificationMutation, { data, loading, error }] = useResendEmailChangeVerificationMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResendEmailChangeVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendEmailChangeVerificationMutation,
    ResendEmailChangeVerificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendEmailChangeVerificationMutation,
    ResendEmailChangeVerificationMutationVariables
  >(ResendEmailChangeVerificationDocument, options);
}
export type ResendEmailChangeVerificationMutationHookResult = ReturnType<
  typeof useResendEmailChangeVerificationMutation
>;
export type ResendEmailChangeVerificationMutationResult = Apollo.MutationResult<
  ResendEmailChangeVerificationMutation
>;
export type ResendEmailChangeVerificationMutationOptions = Apollo.BaseMutationOptions<
  ResendEmailChangeVerificationMutation,
  ResendEmailChangeVerificationMutationVariables
>;
export const RevokeEmailChangeDocument = gql`
  mutation revokeEmailChange($email: String!) {
    revokeChangeUserEmailRequest(input: { new_email: $email }) {
      id
    }
  }
`;
export type RevokeEmailChangeMutationFn = Apollo.MutationFunction<
  RevokeEmailChangeMutation,
  RevokeEmailChangeMutationVariables
>;

/**
 * __useRevokeEmailChangeMutation__
 *
 * To run a mutation, you first call `useRevokeEmailChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeEmailChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeEmailChangeMutation, { data, loading, error }] = useRevokeEmailChangeMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRevokeEmailChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeEmailChangeMutation,
    RevokeEmailChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeEmailChangeMutation,
    RevokeEmailChangeMutationVariables
  >(RevokeEmailChangeDocument, options);
}
export type RevokeEmailChangeMutationHookResult = ReturnType<
  typeof useRevokeEmailChangeMutation
>;
export type RevokeEmailChangeMutationResult = Apollo.MutationResult<
  RevokeEmailChangeMutation
>;
export type RevokeEmailChangeMutationOptions = Apollo.BaseMutationOptions<
  RevokeEmailChangeMutation,
  RevokeEmailChangeMutationVariables
>;
export const VerifyEmailChangeDocument = gql`
  mutation verifyEmailChange($token: String!) {
    completeChangeUserEmailRequest(input: { key: $token }) {
      id
      new_email
    }
  }
`;
export type VerifyEmailChangeMutationFn = Apollo.MutationFunction<
  VerifyEmailChangeMutation,
  VerifyEmailChangeMutationVariables
>;

/**
 * __useVerifyEmailChangeMutation__
 *
 * To run a mutation, you first call `useVerifyEmailChangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailChangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailChangeMutation, { data, loading, error }] = useVerifyEmailChangeMutation({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyEmailChangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VerifyEmailChangeMutation,
    VerifyEmailChangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VerifyEmailChangeMutation,
    VerifyEmailChangeMutationVariables
  >(VerifyEmailChangeDocument, options);
}
export type VerifyEmailChangeMutationHookResult = ReturnType<
  typeof useVerifyEmailChangeMutation
>;
export type VerifyEmailChangeMutationResult = Apollo.MutationResult<
  VerifyEmailChangeMutation
>;
export type VerifyEmailChangeMutationOptions = Apollo.BaseMutationOptions<
  VerifyEmailChangeMutation,
  VerifyEmailChangeMutationVariables
>;
export const RequestRegionDocument = gql`
  mutation requestRegion($details: jsonb!) {
    addFeatureRequest(payload: { type: "region-request", details: $details }) {
      status
    }
  }
`;
export type RequestRegionMutationFn = Apollo.MutationFunction<
  RequestRegionMutation,
  RequestRegionMutationVariables
>;

/**
 * __useRequestRegionMutation__
 *
 * To run a mutation, you first call `useRequestRegionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestRegionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestRegionMutation, { data, loading, error }] = useRequestRegionMutation({
 *   variables: {
 *      details: // value for 'details'
 *   },
 * });
 */
export function useRequestRegionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestRegionMutation,
    RequestRegionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestRegionMutation,
    RequestRegionMutationVariables
  >(RequestRegionDocument, options);
}
export type RequestRegionMutationHookResult = ReturnType<
  typeof useRequestRegionMutation
>;
export type RequestRegionMutationResult = Apollo.MutationResult<
  RequestRegionMutation
>;
export type RequestRegionMutationOptions = Apollo.BaseMutationOptions<
  RequestRegionMutation,
  RequestRegionMutationVariables
>;
export const InviteBillingOwnerDocument = gql`
  mutation inviteBillingOwner($email: String!, $projectId: uuid!) {
    inviteBillingManager(manager_email: $email, project_id: $projectId) {
      project_id
    }
  }
`;
export type InviteBillingOwnerMutationFn = Apollo.MutationFunction<
  InviteBillingOwnerMutation,
  InviteBillingOwnerMutationVariables
>;

/**
 * __useInviteBillingOwnerMutation__
 *
 * To run a mutation, you first call `useInviteBillingOwnerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteBillingOwnerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteBillingOwnerMutation, { data, loading, error }] = useInviteBillingOwnerMutation({
 *   variables: {
 *      email: // value for 'email'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useInviteBillingOwnerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteBillingOwnerMutation,
    InviteBillingOwnerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteBillingOwnerMutation,
    InviteBillingOwnerMutationVariables
  >(InviteBillingOwnerDocument, options);
}
export type InviteBillingOwnerMutationHookResult = ReturnType<
  typeof useInviteBillingOwnerMutation
>;
export type InviteBillingOwnerMutationResult = Apollo.MutationResult<
  InviteBillingOwnerMutation
>;
export type InviteBillingOwnerMutationOptions = Apollo.BaseMutationOptions<
  InviteBillingOwnerMutation,
  InviteBillingOwnerMutationVariables
>;
export const ResendBillingOwnerInviteDocument = gql`
  mutation resendBillingOwnerInvite($email: String!, $projectId: uuid!) {
    resendBillingManagerInvite(manager_email: $email, project_id: $projectId) {
      project_id
    }
  }
`;
export type ResendBillingOwnerInviteMutationFn = Apollo.MutationFunction<
  ResendBillingOwnerInviteMutation,
  ResendBillingOwnerInviteMutationVariables
>;

/**
 * __useResendBillingOwnerInviteMutation__
 *
 * To run a mutation, you first call `useResendBillingOwnerInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendBillingOwnerInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendBillingOwnerInviteMutation, { data, loading, error }] = useResendBillingOwnerInviteMutation({
 *   variables: {
 *      email: // value for 'email'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useResendBillingOwnerInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResendBillingOwnerInviteMutation,
    ResendBillingOwnerInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ResendBillingOwnerInviteMutation,
    ResendBillingOwnerInviteMutationVariables
  >(ResendBillingOwnerInviteDocument, options);
}
export type ResendBillingOwnerInviteMutationHookResult = ReturnType<
  typeof useResendBillingOwnerInviteMutation
>;
export type ResendBillingOwnerInviteMutationResult = Apollo.MutationResult<
  ResendBillingOwnerInviteMutation
>;
export type ResendBillingOwnerInviteMutationOptions = Apollo.BaseMutationOptions<
  ResendBillingOwnerInviteMutation,
  ResendBillingOwnerInviteMutationVariables
>;
export const RequestFeatureAccessDocument = gql`
  mutation requestFeatureAccess($type: String!, $details: jsonb!) {
    addFeatureRequest(payload: { type: $type, details: $details }) {
      status
    }
  }
`;
export type RequestFeatureAccessMutationFn = Apollo.MutationFunction<
  RequestFeatureAccessMutation,
  RequestFeatureAccessMutationVariables
>;

/**
 * __useRequestFeatureAccessMutation__
 *
 * To run a mutation, you first call `useRequestFeatureAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestFeatureAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestFeatureAccessMutation, { data, loading, error }] = useRequestFeatureAccessMutation({
 *   variables: {
 *      type: // value for 'type'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useRequestFeatureAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestFeatureAccessMutation,
    RequestFeatureAccessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestFeatureAccessMutation,
    RequestFeatureAccessMutationVariables
  >(RequestFeatureAccessDocument, options);
}
export type RequestFeatureAccessMutationHookResult = ReturnType<
  typeof useRequestFeatureAccessMutation
>;
export type RequestFeatureAccessMutationResult = Apollo.MutationResult<
  RequestFeatureAccessMutation
>;
export type RequestFeatureAccessMutationOptions = Apollo.BaseMutationOptions<
  RequestFeatureAccessMutation,
  RequestFeatureAccessMutationVariables
>;
export const PayPendingInvoiceDocument = gql`
  mutation payPendingInvoice($paymentMethodId: String!, $invoiceId: String!) {
    payInvoice(payment_method_id: $paymentMethodId, invoice_id: $invoiceId) {
      status
    }
  }
`;
export type PayPendingInvoiceMutationFn = Apollo.MutationFunction<
  PayPendingInvoiceMutation,
  PayPendingInvoiceMutationVariables
>;

/**
 * __usePayPendingInvoiceMutation__
 *
 * To run a mutation, you first call `usePayPendingInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayPendingInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payPendingInvoiceMutation, { data, loading, error }] = usePayPendingInvoiceMutation({
 *   variables: {
 *      paymentMethodId: // value for 'paymentMethodId'
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function usePayPendingInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    PayPendingInvoiceMutation,
    PayPendingInvoiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    PayPendingInvoiceMutation,
    PayPendingInvoiceMutationVariables
  >(PayPendingInvoiceDocument, options);
}
export type PayPendingInvoiceMutationHookResult = ReturnType<
  typeof usePayPendingInvoiceMutation
>;
export type PayPendingInvoiceMutationResult = Apollo.MutationResult<
  PayPendingInvoiceMutation
>;
export type PayPendingInvoiceMutationOptions = Apollo.BaseMutationOptions<
  PayPendingInvoiceMutation,
  PayPendingInvoiceMutationVariables
>;
export const CreateSupportTicketDocument = gql`
  mutation createSupportTicket(
    $body: String!
    $subject: String!
    $project_id: uuid
    $priority: SupportTicketPriority
  ) {
    createZendeskSupportTicket(
      body: $body
      subject: $subject
      project_id: $project_id
      priority: $priority
    ) {
      status
    }
  }
`;
export type CreateSupportTicketMutationFn = Apollo.MutationFunction<
  CreateSupportTicketMutation,
  CreateSupportTicketMutationVariables
>;

/**
 * __useCreateSupportTicketMutation__
 *
 * To run a mutation, you first call `useCreateSupportTicketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSupportTicketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSupportTicketMutation, { data, loading, error }] = useCreateSupportTicketMutation({
 *   variables: {
 *      body: // value for 'body'
 *      subject: // value for 'subject'
 *      project_id: // value for 'project_id'
 *      priority: // value for 'priority'
 *   },
 * });
 */
export function useCreateSupportTicketMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateSupportTicketMutation,
    CreateSupportTicketMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateSupportTicketMutation,
    CreateSupportTicketMutationVariables
  >(CreateSupportTicketDocument, options);
}
export type CreateSupportTicketMutationHookResult = ReturnType<
  typeof useCreateSupportTicketMutation
>;
export type CreateSupportTicketMutationResult = Apollo.MutationResult<
  CreateSupportTicketMutation
>;
export type CreateSupportTicketMutationOptions = Apollo.BaseMutationOptions<
  CreateSupportTicketMutation,
  CreateSupportTicketMutationVariables
>;
export const RequestGcpAccessDocument = gql`
  mutation requestGCPAccess($requestDetails: jsonb!) {
    addFeatureRequest(
      payload: { type: "gcp-support-request", details: $requestDetails }
    ) {
      status
    }
  }
`;
export type RequestGcpAccessMutationFn = Apollo.MutationFunction<
  RequestGcpAccessMutation,
  RequestGcpAccessMutationVariables
>;

/**
 * __useRequestGcpAccessMutation__
 *
 * To run a mutation, you first call `useRequestGcpAccessMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestGcpAccessMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestGcpAccessMutation, { data, loading, error }] = useRequestGcpAccessMutation({
 *   variables: {
 *      requestDetails: // value for 'requestDetails'
 *   },
 * });
 */
export function useRequestGcpAccessMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestGcpAccessMutation,
    RequestGcpAccessMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestGcpAccessMutation,
    RequestGcpAccessMutationVariables
  >(RequestGcpAccessDocument, options);
}
export type RequestGcpAccessMutationHookResult = ReturnType<
  typeof useRequestGcpAccessMutation
>;
export type RequestGcpAccessMutationResult = Apollo.MutationResult<
  RequestGcpAccessMutation
>;
export type RequestGcpAccessMutationOptions = Apollo.BaseMutationOptions<
  RequestGcpAccessMutation,
  RequestGcpAccessMutationVariables
>;
export const RemoveNeonSessionDocument = gql`
  mutation removeNeonSession {
    neonRemoveSession {
      status
    }
  }
`;
export type RemoveNeonSessionMutationFn = Apollo.MutationFunction<
  RemoveNeonSessionMutation,
  RemoveNeonSessionMutationVariables
>;

/**
 * __useRemoveNeonSessionMutation__
 *
 * To run a mutation, you first call `useRemoveNeonSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNeonSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNeonSessionMutation, { data, loading, error }] = useRemoveNeonSessionMutation({
 *   variables: {
 *   },
 * });
 */
export function useRemoveNeonSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveNeonSessionMutation,
    RemoveNeonSessionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RemoveNeonSessionMutation,
    RemoveNeonSessionMutationVariables
  >(RemoveNeonSessionDocument, options);
}
export type RemoveNeonSessionMutationHookResult = ReturnType<
  typeof useRemoveNeonSessionMutation
>;
export type RemoveNeonSessionMutationResult = Apollo.MutationResult<
  RemoveNeonSessionMutation
>;
export type RemoveNeonSessionMutationOptions = Apollo.BaseMutationOptions<
  RemoveNeonSessionMutation,
  RemoveNeonSessionMutationVariables
>;
export const VercelTokenExchangeDocument = gql`
  mutation vercelTokenExchange(
    $code: String!
    $configurationId: String!
    $redirectURI: String!
    $teamId: String
  ) {
    vercelTokenExchange(
      payload: {
        code: $code
        configuration_id: $configurationId
        redirect_uri: $redirectURI
        team_id: $teamId
      }
    ) {
      status
    }
  }
`;
export type VercelTokenExchangeMutationFn = Apollo.MutationFunction<
  VercelTokenExchangeMutation,
  VercelTokenExchangeMutationVariables
>;

/**
 * __useVercelTokenExchangeMutation__
 *
 * To run a mutation, you first call `useVercelTokenExchangeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVercelTokenExchangeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vercelTokenExchangeMutation, { data, loading, error }] = useVercelTokenExchangeMutation({
 *   variables: {
 *      code: // value for 'code'
 *      configurationId: // value for 'configurationId'
 *      redirectURI: // value for 'redirectURI'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useVercelTokenExchangeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VercelTokenExchangeMutation,
    VercelTokenExchangeMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VercelTokenExchangeMutation,
    VercelTokenExchangeMutationVariables
  >(VercelTokenExchangeDocument, options);
}
export type VercelTokenExchangeMutationHookResult = ReturnType<
  typeof useVercelTokenExchangeMutation
>;
export type VercelTokenExchangeMutationResult = Apollo.MutationResult<
  VercelTokenExchangeMutation
>;
export type VercelTokenExchangeMutationOptions = Apollo.BaseMutationOptions<
  VercelTokenExchangeMutation,
  VercelTokenExchangeMutationVariables
>;
export const VercelSetIntegrationDocument = gql`
  mutation VercelSetIntegration($input: SetVercelIntegrationInput!) {
    vercelSetIntegration(payload: $input) {
      id
    }
  }
`;
export type VercelSetIntegrationMutationFn = Apollo.MutationFunction<
  VercelSetIntegrationMutation,
  VercelSetIntegrationMutationVariables
>;

/**
 * __useVercelSetIntegrationMutation__
 *
 * To run a mutation, you first call `useVercelSetIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVercelSetIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vercelSetIntegrationMutation, { data, loading, error }] = useVercelSetIntegrationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVercelSetIntegrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    VercelSetIntegrationMutation,
    VercelSetIntegrationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    VercelSetIntegrationMutation,
    VercelSetIntegrationMutationVariables
  >(VercelSetIntegrationDocument, options);
}
export type VercelSetIntegrationMutationHookResult = ReturnType<
  typeof useVercelSetIntegrationMutation
>;
export type VercelSetIntegrationMutationResult = Apollo.MutationResult<
  VercelSetIntegrationMutation
>;
export type VercelSetIntegrationMutationOptions = Apollo.BaseMutationOptions<
  VercelSetIntegrationMutation,
  VercelSetIntegrationMutationVariables
>;
export const UpdateVpcDetailsDocument = gql`
  mutation updateVPCDetails($name: String, $id: uuid) {
    update_dedicated_vpc(_set: { name: $name }, where: { id: { _eq: $id } }) {
      returning {
        id
        name
      }
    }
  }
`;
export type UpdateVpcDetailsMutationFn = Apollo.MutationFunction<
  UpdateVpcDetailsMutation,
  UpdateVpcDetailsMutationVariables
>;

/**
 * __useUpdateVpcDetailsMutation__
 *
 * To run a mutation, you first call `useUpdateVpcDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVpcDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVpcDetailsMutation, { data, loading, error }] = useUpdateVpcDetailsMutation({
 *   variables: {
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpdateVpcDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateVpcDetailsMutation,
    UpdateVpcDetailsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateVpcDetailsMutation,
    UpdateVpcDetailsMutationVariables
  >(UpdateVpcDetailsDocument, options);
}
export type UpdateVpcDetailsMutationHookResult = ReturnType<
  typeof useUpdateVpcDetailsMutation
>;
export type UpdateVpcDetailsMutationResult = Apollo.MutationResult<
  UpdateVpcDetailsMutation
>;
export type UpdateVpcDetailsMutationOptions = Apollo.BaseMutationOptions<
  UpdateVpcDetailsMutation,
  UpdateVpcDetailsMutationVariables
>;
export const InsertVpcDocument = gql`
  mutation insertVPC(
    $cidr: String!
    $region: String!
    $name: String!
    $cloud: String!
    $planName: String!
  ) {
    createDedicatedVPC(
      cidr: $cidr
      cloud: $cloud
      region: $region
      name: $name
      plan_name: $planName
    ) {
      id
    }
  }
`;
export type InsertVpcMutationFn = Apollo.MutationFunction<
  InsertVpcMutation,
  InsertVpcMutationVariables
>;

/**
 * __useInsertVpcMutation__
 *
 * To run a mutation, you first call `useInsertVpcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertVpcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertVpcMutation, { data, loading, error }] = useInsertVpcMutation({
 *   variables: {
 *      cidr: // value for 'cidr'
 *      region: // value for 'region'
 *      name: // value for 'name'
 *      cloud: // value for 'cloud'
 *      planName: // value for 'planName'
 *   },
 * });
 */
export function useInsertVpcMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertVpcMutation,
    InsertVpcMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertVpcMutation, InsertVpcMutationVariables>(
    InsertVpcDocument,
    options
  );
}
export type InsertVpcMutationHookResult = ReturnType<
  typeof useInsertVpcMutation
>;
export type InsertVpcMutationResult = Apollo.MutationResult<InsertVpcMutation>;
export type InsertVpcMutationOptions = Apollo.BaseMutationOptions<
  InsertVpcMutation,
  InsertVpcMutationVariables
>;
export const CreateAwsPeeringRequestDocument = gql`
  mutation createAwsPeeringRequest(
    $awsId: String
    $awsVPCId: String
    $awsRegion: String
    $awsCIDR: String
    $awsAdditionalCIDRs: [String!] = "{}"
    $direction: vpc_peering_direction_enum
    $name: String
    $id: uuid
  ) {
    insert_vpc_peering(
      objects: {
        aws_customer_account_id: $awsId
        aws_customer_vpc_cidr: $awsCIDR
        aws_customer_vpc_additional_cidrs: $awsAdditionalCIDRs
        aws_customer_vpc_id: $awsVPCId
        aws_customer_vpc_region: $awsRegion
        name: $name
        direction: $direction
        dedicated_vpc_id: $id
      }
    ) {
      returning {
        ...VPCPeeringFields
      }
    }
  }
  ${VpcPeeringFieldsFragmentDoc}
`;
export type CreateAwsPeeringRequestMutationFn = Apollo.MutationFunction<
  CreateAwsPeeringRequestMutation,
  CreateAwsPeeringRequestMutationVariables
>;

/**
 * __useCreateAwsPeeringRequestMutation__
 *
 * To run a mutation, you first call `useCreateAwsPeeringRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAwsPeeringRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAwsPeeringRequestMutation, { data, loading, error }] = useCreateAwsPeeringRequestMutation({
 *   variables: {
 *      awsId: // value for 'awsId'
 *      awsVPCId: // value for 'awsVPCId'
 *      awsRegion: // value for 'awsRegion'
 *      awsCIDR: // value for 'awsCIDR'
 *      awsAdditionalCIDRs: // value for 'awsAdditionalCIDRs'
 *      direction: // value for 'direction'
 *      name: // value for 'name'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCreateAwsPeeringRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAwsPeeringRequestMutation,
    CreateAwsPeeringRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAwsPeeringRequestMutation,
    CreateAwsPeeringRequestMutationVariables
  >(CreateAwsPeeringRequestDocument, options);
}
export type CreateAwsPeeringRequestMutationHookResult = ReturnType<
  typeof useCreateAwsPeeringRequestMutation
>;
export type CreateAwsPeeringRequestMutationResult = Apollo.MutationResult<
  CreateAwsPeeringRequestMutation
>;
export type CreateAwsPeeringRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateAwsPeeringRequestMutation,
  CreateAwsPeeringRequestMutationVariables
>;
export const CreateGcpPeeringRequestDocument = gql`
  mutation createGcpPeeringRequest(
    $direction: vpc_peering_direction_enum!
    $name: String!
    $vpcId: uuid!
    $gcpProjectId: String!
    $gcpNetwork: String!
  ) {
    insert_vpc_peering(
      objects: {
        aws_customer_account_id: ""
        aws_customer_vpc_id: ""
        name: $name
        direction: $direction
        dedicated_vpc_id: $vpcId
        gcp_project_id: $gcpProjectId
        gcp_vpc_network: $gcpNetwork
      }
    ) {
      returning {
        ...VPCPeeringFields
      }
    }
  }
  ${VpcPeeringFieldsFragmentDoc}
`;
export type CreateGcpPeeringRequestMutationFn = Apollo.MutationFunction<
  CreateGcpPeeringRequestMutation,
  CreateGcpPeeringRequestMutationVariables
>;

/**
 * __useCreateGcpPeeringRequestMutation__
 *
 * To run a mutation, you first call `useCreateGcpPeeringRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGcpPeeringRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGcpPeeringRequestMutation, { data, loading, error }] = useCreateGcpPeeringRequestMutation({
 *   variables: {
 *      direction: // value for 'direction'
 *      name: // value for 'name'
 *      vpcId: // value for 'vpcId'
 *      gcpProjectId: // value for 'gcpProjectId'
 *      gcpNetwork: // value for 'gcpNetwork'
 *   },
 * });
 */
export function useCreateGcpPeeringRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateGcpPeeringRequestMutation,
    CreateGcpPeeringRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateGcpPeeringRequestMutation,
    CreateGcpPeeringRequestMutationVariables
  >(CreateGcpPeeringRequestDocument, options);
}
export type CreateGcpPeeringRequestMutationHookResult = ReturnType<
  typeof useCreateGcpPeeringRequestMutation
>;
export type CreateGcpPeeringRequestMutationResult = Apollo.MutationResult<
  CreateGcpPeeringRequestMutation
>;
export type CreateGcpPeeringRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateGcpPeeringRequestMutation,
  CreateGcpPeeringRequestMutationVariables
>;
export const CreateAzurePeeringRequestDocument = gql`
  mutation createAzurePeeringRequest(
    $direction: vpc_peering_direction_enum!
    $name: String!
    $vpcId: uuid!
    $azureInput: jsonb!
  ) {
    insert_vpc_peering(
      objects: {
        name: $name
        direction: $direction
        dedicated_vpc_id: $vpcId
        azure_input: $azureInput
      }
    ) {
      returning {
        ...VPCPeeringFields
      }
    }
  }
  ${VpcPeeringFieldsFragmentDoc}
`;
export type CreateAzurePeeringRequestMutationFn = Apollo.MutationFunction<
  CreateAzurePeeringRequestMutation,
  CreateAzurePeeringRequestMutationVariables
>;

/**
 * __useCreateAzurePeeringRequestMutation__
 *
 * To run a mutation, you first call `useCreateAzurePeeringRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAzurePeeringRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAzurePeeringRequestMutation, { data, loading, error }] = useCreateAzurePeeringRequestMutation({
 *   variables: {
 *      direction: // value for 'direction'
 *      name: // value for 'name'
 *      vpcId: // value for 'vpcId'
 *      azureInput: // value for 'azureInput'
 *   },
 * });
 */
export function useCreateAzurePeeringRequestMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateAzurePeeringRequestMutation,
    CreateAzurePeeringRequestMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    CreateAzurePeeringRequestMutation,
    CreateAzurePeeringRequestMutationVariables
  >(CreateAzurePeeringRequestDocument, options);
}
export type CreateAzurePeeringRequestMutationHookResult = ReturnType<
  typeof useCreateAzurePeeringRequestMutation
>;
export type CreateAzurePeeringRequestMutationResult = Apollo.MutationResult<
  CreateAzurePeeringRequestMutation
>;
export type CreateAzurePeeringRequestMutationOptions = Apollo.BaseMutationOptions<
  CreateAzurePeeringRequestMutation,
  CreateAzurePeeringRequestMutationVariables
>;
export const GetCardDetailsDocument = gql`
  subscription getCardDetails {
    payment_method(
      where: { status: { _eq: "active" } }
      order_by: { created_at: desc }
    ) {
      id
      is_default
      name
      exp_year
      exp_month
      last4
      brand
      status
    }
  }
`;

/**
 * __useGetCardDetailsSubscription__
 *
 * To run a query within a React component, call `useGetCardDetailsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetCardDetailsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardDetailsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetCardDetailsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetCardDetailsSubscription,
    GetCardDetailsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetCardDetailsSubscription,
    GetCardDetailsSubscriptionVariables
  >(GetCardDetailsDocument, options);
}
export type GetCardDetailsSubscriptionHookResult = ReturnType<
  typeof useGetCardDetailsSubscription
>;
export type GetCardDetailsSubscriptionResult = Apollo.SubscriptionResult<
  GetCardDetailsSubscription
>;
export const GetDefaultCardDetailsDocument = gql`
  subscription getDefaultCardDetails {
    payment_method(
      where: { status: { _eq: "active" }, is_default: { _eq: true } }
      order_by: { created_at: desc }
    ) {
      id
      is_default
      name
      exp_year
      exp_month
      last4
      brand
      status
    }
  }
`;

/**
 * __useGetDefaultCardDetailsSubscription__
 *
 * To run a query within a React component, call `useGetDefaultCardDetailsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetDefaultCardDetailsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDefaultCardDetailsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetDefaultCardDetailsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetDefaultCardDetailsSubscription,
    GetDefaultCardDetailsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetDefaultCardDetailsSubscription,
    GetDefaultCardDetailsSubscriptionVariables
  >(GetDefaultCardDetailsDocument, options);
}
export type GetDefaultCardDetailsSubscriptionHookResult = ReturnType<
  typeof useGetDefaultCardDetailsSubscription
>;
export type GetDefaultCardDetailsSubscriptionResult = Apollo.SubscriptionResult<
  GetDefaultCardDetailsSubscription
>;
export const GetProjectUsageAndReportDocument = gql`
  subscription getProjectUsageAndReport(
    $id: uuid!
    $startDate: date!
    $endDate: date!
  ) {
    projects(where: { id: { _eq: $id } }) {
      project_usages(
        where: { date: { _gte: $startDate, _lte: $endDate } }
        order_by: { date: asc }
      ) {
        usage
        date
      }
      usage_reports(
        where: { report_date: { _gte: $startDate, _lte: $endDate } }
        order_by: { report_date: asc }
      ) {
        key
        report_date
      }
    }
  }
`;

/**
 * __useGetProjectUsageAndReportSubscription__
 *
 * To run a query within a React component, call `useGetProjectUsageAndReportSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectUsageAndReportSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectUsageAndReportSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetProjectUsageAndReportSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetProjectUsageAndReportSubscription,
    GetProjectUsageAndReportSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetProjectUsageAndReportSubscription,
    GetProjectUsageAndReportSubscriptionVariables
  >(GetProjectUsageAndReportDocument, options);
}
export type GetProjectUsageAndReportSubscriptionHookResult = ReturnType<
  typeof useGetProjectUsageAndReportSubscription
>;
export type GetProjectUsageAndReportSubscriptionResult = Apollo.SubscriptionResult<
  GetProjectUsageAndReportSubscription
>;
export const GetProjectUsageDocument = gql`
  subscription getProjectUsage($id: uuid!, $startDate: date!, $endDate: date!) {
    projects(where: { id: { _eq: $id } }) {
      project_usages(
        where: { date: { _gte: $startDate, _lte: $endDate } }
        order_by: { date: asc }
      ) {
        usage
        date
      }
    }
  }
`;

/**
 * __useGetProjectUsageSubscription__
 *
 * To run a query within a React component, call `useGetProjectUsageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectUsageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectUsageSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetProjectUsageSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetProjectUsageSubscription,
    GetProjectUsageSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetProjectUsageSubscription,
    GetProjectUsageSubscriptionVariables
  >(GetProjectUsageDocument, options);
}
export type GetProjectUsageSubscriptionHookResult = ReturnType<
  typeof useGetProjectUsageSubscription
>;
export type GetProjectUsageSubscriptionResult = Apollo.SubscriptionResult<
  GetProjectUsageSubscription
>;
export const GetReportUrlDocument = gql`
  query getReportURL($key: String!) {
    getReportURL(key: $key) {
      url
    }
  }
`;

/**
 * __useGetReportUrlQuery__
 *
 * To run a query within a React component, call `useGetReportUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportUrlQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useGetReportUrlQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetReportUrlQuery,
    GetReportUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetReportUrlQuery, GetReportUrlQueryVariables>(
    GetReportUrlDocument,
    options
  );
}
export function useGetReportUrlLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReportUrlQuery,
    GetReportUrlQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetReportUrlQuery, GetReportUrlQueryVariables>(
    GetReportUrlDocument,
    options
  );
}
export type GetReportUrlQueryHookResult = ReturnType<
  typeof useGetReportUrlQuery
>;
export type GetReportUrlLazyQueryHookResult = ReturnType<
  typeof useGetReportUrlLazyQuery
>;
export type GetReportUrlQueryResult = Apollo.QueryResult<
  GetReportUrlQuery,
  GetReportUrlQueryVariables
>;
export const GetLatestInvoiceDocument = gql`
  subscription getLatestInvoice {
    invoice(
      order_by: [{ year: desc }, { month: desc }]
      where: { status: { _in: ["paid", "open"] } }
    ) {
      stripe_invoice_id
      month
      year
      invoice_url
      receipt_url
      status
      due_amount
      collection_method
      items {
        amount
      }
    }
  }
`;

/**
 * __useGetLatestInvoiceSubscription__
 *
 * To run a query within a React component, call `useGetLatestInvoiceSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestInvoiceSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestInvoiceSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestInvoiceSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetLatestInvoiceSubscription,
    GetLatestInvoiceSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetLatestInvoiceSubscription,
    GetLatestInvoiceSubscriptionVariables
  >(GetLatestInvoiceDocument, options);
}
export type GetLatestInvoiceSubscriptionHookResult = ReturnType<
  typeof useGetLatestInvoiceSubscription
>;
export type GetLatestInvoiceSubscriptionResult = Apollo.SubscriptionResult<
  GetLatestInvoiceSubscription
>;
export const FetchCardDetailsDocument = gql`
  query fetchCardDetails {
    payment_method(
      where: { is_default: { _eq: true }, status: { _eq: "active" } }
    ) {
      id
      is_default
      name
      exp_year
      exp_month
      last4
      brand
      status
    }
  }
`;

/**
 * __useFetchCardDetailsQuery__
 *
 * To run a query within a React component, call `useFetchCardDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCardDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCardDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchCardDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchCardDetailsQuery,
    FetchCardDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchCardDetailsQuery, FetchCardDetailsQueryVariables>(
    FetchCardDetailsDocument,
    options
  );
}
export function useFetchCardDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchCardDetailsQuery,
    FetchCardDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchCardDetailsQuery,
    FetchCardDetailsQueryVariables
  >(FetchCardDetailsDocument, options);
}
export type FetchCardDetailsQueryHookResult = ReturnType<
  typeof useFetchCardDetailsQuery
>;
export type FetchCardDetailsLazyQueryHookResult = ReturnType<
  typeof useFetchCardDetailsLazyQuery
>;
export type FetchCardDetailsQueryResult = Apollo.QueryResult<
  FetchCardDetailsQuery,
  FetchCardDetailsQueryVariables
>;
export const GetUsedCouponsDocument = gql`
  subscription getUsedCoupons {
    user_coupon {
      amount_off
      is_applied
      promotion_code
      created_at
      percentage_off
      coupon {
        duration
        duration_in_months
      }
    }
  }
`;

/**
 * __useGetUsedCouponsSubscription__
 *
 * To run a query within a React component, call `useGetUsedCouponsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUsedCouponsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsedCouponsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetUsedCouponsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetUsedCouponsSubscription,
    GetUsedCouponsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetUsedCouponsSubscription,
    GetUsedCouponsSubscriptionVariables
  >(GetUsedCouponsDocument, options);
}
export type GetUsedCouponsSubscriptionHookResult = ReturnType<
  typeof useGetUsedCouponsSubscription
>;
export type GetUsedCouponsSubscriptionResult = Apollo.SubscriptionResult<
  GetUsedCouponsSubscription
>;
export const GetUsedCreditDocument = gql`
  subscription getUsedCredit {
    customer_usage {
      amount
      code
      created_at
      coupon {
        duration_in_months
        duration
      }
    }
  }
`;

/**
 * __useGetUsedCreditSubscription__
 *
 * To run a query within a React component, call `useGetUsedCreditSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUsedCreditSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsedCreditSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetUsedCreditSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetUsedCreditSubscription,
    GetUsedCreditSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetUsedCreditSubscription,
    GetUsedCreditSubscriptionVariables
  >(GetUsedCreditDocument, options);
}
export type GetUsedCreditSubscriptionHookResult = ReturnType<
  typeof useGetUsedCreditSubscription
>;
export type GetUsedCreditSubscriptionResult = Apollo.SubscriptionResult<
  GetUsedCreditSubscription
>;
export const GetInvoicedCouponsDocument = gql`
  subscription getInvoicedCoupons {
    invoice_coupon_discount {
      coupon_code
      created_at
      invoice {
        month
        year
      }
    }
  }
`;

/**
 * __useGetInvoicedCouponsSubscription__
 *
 * To run a query within a React component, call `useGetInvoicedCouponsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicedCouponsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicedCouponsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetInvoicedCouponsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetInvoicedCouponsSubscription,
    GetInvoicedCouponsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetInvoicedCouponsSubscription,
    GetInvoicedCouponsSubscriptionVariables
  >(GetInvoicedCouponsDocument, options);
}
export type GetInvoicedCouponsSubscriptionHookResult = ReturnType<
  typeof useGetInvoicedCouponsSubscription
>;
export type GetInvoicedCouponsSubscriptionResult = Apollo.SubscriptionResult<
  GetInvoicedCouponsSubscription
>;
export const GetPendingInvoiceStatusDocument = gql`
  subscription getPendingInvoiceStatus {
    invoice(
      order_by: [{ year: desc }, { month: desc }]
      where: { status: { _eq: "open" } }
      limit: 1
    ) {
      invoice_url
      stripe_invoice_id
      attempt_count
      collection_method
    }
  }
`;

/**
 * __useGetPendingInvoiceStatusSubscription__
 *
 * To run a query within a React component, call `useGetPendingInvoiceStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPendingInvoiceStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPendingInvoiceStatusSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetPendingInvoiceStatusSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetPendingInvoiceStatusSubscription,
    GetPendingInvoiceStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetPendingInvoiceStatusSubscription,
    GetPendingInvoiceStatusSubscriptionVariables
  >(GetPendingInvoiceStatusDocument, options);
}
export type GetPendingInvoiceStatusSubscriptionHookResult = ReturnType<
  typeof useGetPendingInvoiceStatusSubscription
>;
export type GetPendingInvoiceStatusSubscriptionResult = Apollo.SubscriptionResult<
  GetPendingInvoiceStatusSubscription
>;
export const GetInvoiceAndReceiptUrLsDocument = gql`
  query getInvoiceAndReceiptURLs($invoice_id: String!) {
    getInvoiceAndReceiptURLs(invoice_id: $invoice_id) {
      invoice_url
      receipt_url
    }
  }
`;

/**
 * __useGetInvoiceAndReceiptUrLsQuery__
 *
 * To run a query within a React component, call `useGetInvoiceAndReceiptUrLsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceAndReceiptUrLsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceAndReceiptUrLsQuery({
 *   variables: {
 *      invoice_id: // value for 'invoice_id'
 *   },
 * });
 */
export function useGetInvoiceAndReceiptUrLsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInvoiceAndReceiptUrLsQuery,
    GetInvoiceAndReceiptUrLsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInvoiceAndReceiptUrLsQuery,
    GetInvoiceAndReceiptUrLsQueryVariables
  >(GetInvoiceAndReceiptUrLsDocument, options);
}
export function useGetInvoiceAndReceiptUrLsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInvoiceAndReceiptUrLsQuery,
    GetInvoiceAndReceiptUrLsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInvoiceAndReceiptUrLsQuery,
    GetInvoiceAndReceiptUrLsQueryVariables
  >(GetInvoiceAndReceiptUrLsDocument, options);
}
export type GetInvoiceAndReceiptUrLsQueryHookResult = ReturnType<
  typeof useGetInvoiceAndReceiptUrLsQuery
>;
export type GetInvoiceAndReceiptUrLsLazyQueryHookResult = ReturnType<
  typeof useGetInvoiceAndReceiptUrLsLazyQuery
>;
export type GetInvoiceAndReceiptUrLsQueryResult = Apollo.QueryResult<
  GetInvoiceAndReceiptUrLsQuery,
  GetInvoiceAndReceiptUrLsQueryVariables
>;
export const GetProjectUsagePrometheusDocument = gql`
  subscription getProjectUsagePrometheus(
    $id: uuid!
    $startDate: date!
    $endDate: date!
  ) {
    projects(where: { id: { _eq: $id } }) {
      project_usage_prometheus(
        where: { date: { _gte: $startDate, _lte: $endDate } }
        order_by: { date: asc }
      ) {
        usage
        date
      }
    }
  }
`;

/**
 * __useGetProjectUsagePrometheusSubscription__
 *
 * To run a query within a React component, call `useGetProjectUsagePrometheusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectUsagePrometheusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectUsagePrometheusSubscription({
 *   variables: {
 *      id: // value for 'id'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetProjectUsagePrometheusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetProjectUsagePrometheusSubscription,
    GetProjectUsagePrometheusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetProjectUsagePrometheusSubscription,
    GetProjectUsagePrometheusSubscriptionVariables
  >(GetProjectUsagePrometheusDocument, options);
}
export type GetProjectUsagePrometheusSubscriptionHookResult = ReturnType<
  typeof useGetProjectUsagePrometheusSubscription
>;
export type GetProjectUsagePrometheusSubscriptionResult = Apollo.SubscriptionResult<
  GetProjectUsagePrometheusSubscription
>;
export const GetProjectModelsDocument = gql`
  query getProjectModels(
    $projectIds: [String!]!
    $startDate: Timestamp
    $endDate: Timestamp
  ) {
    model_based_pricing_model_snapshot_statistics(
      where: {
        project_id: { _in: $projectIds }
        timestamp: { _gte: $startDate, _lt: $endDate }
      }
    ) {
      project_id
      timestamp
      tables
      collections
      logical_models
    }
  }
`;

/**
 * __useGetProjectModelsQuery__
 *
 * To run a query within a React component, call `useGetProjectModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectModelsQuery({
 *   variables: {
 *      projectIds: // value for 'projectIds'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetProjectModelsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectModelsQuery,
    GetProjectModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectModelsQuery, GetProjectModelsQueryVariables>(
    GetProjectModelsDocument,
    options
  );
}
export function useGetProjectModelsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectModelsQuery,
    GetProjectModelsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectModelsQuery,
    GetProjectModelsQueryVariables
  >(GetProjectModelsDocument, options);
}
export type GetProjectModelsQueryHookResult = ReturnType<
  typeof useGetProjectModelsQuery
>;
export type GetProjectModelsLazyQueryHookResult = ReturnType<
  typeof useGetProjectModelsLazyQuery
>;
export type GetProjectModelsQueryResult = Apollo.QueryResult<
  GetProjectModelsQuery,
  GetProjectModelsQueryVariables
>;
export const FetchProjectAndPrivilegesCollaboratorsDocument = gql`
  query fetchProjectAndPrivilegesCollaborators($id: uuid!) {
    projectCollaboratorsMetadata: projects_by_pk(id: $id) {
      id
      invitations(
        where: {
          accepted_at: { _is_null: true }
          declined_at: { _is_null: true }
        }
        distinct_on: collaborator_email
        order_by: [{ collaborator_email: desc }, { id: desc }]
      ) {
        id
        collaborator_email
        privileges
        key
      }
      collaborators(where: { status: { _eq: "active" } }) {
        id
        status
        user: collaborator {
          id
          email
        }
        project_collaborator_allowed_schemas {
          schema
        }
        privileges: project_collaborator_privileges {
          slug: privilege_slug
        }
      }
      project_billing_manager_invitations(
        where: {
          accepted_at: { _is_null: true }
          declined_at: { _is_null: true }
        }
        distinct_on: manager_email
        order_by: [{ manager_email: desc }, { id: desc }]
      ) {
        manager_email
        key
      }
      billing_manager {
        email
      }
    }
  }
`;

/**
 * __useFetchProjectAndPrivilegesCollaboratorsQuery__
 *
 * To run a query within a React component, call `useFetchProjectAndPrivilegesCollaboratorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProjectAndPrivilegesCollaboratorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProjectAndPrivilegesCollaboratorsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchProjectAndPrivilegesCollaboratorsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchProjectAndPrivilegesCollaboratorsQuery,
    FetchProjectAndPrivilegesCollaboratorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchProjectAndPrivilegesCollaboratorsQuery,
    FetchProjectAndPrivilegesCollaboratorsQueryVariables
  >(FetchProjectAndPrivilegesCollaboratorsDocument, options);
}
export function useFetchProjectAndPrivilegesCollaboratorsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchProjectAndPrivilegesCollaboratorsQuery,
    FetchProjectAndPrivilegesCollaboratorsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchProjectAndPrivilegesCollaboratorsQuery,
    FetchProjectAndPrivilegesCollaboratorsQueryVariables
  >(FetchProjectAndPrivilegesCollaboratorsDocument, options);
}
export type FetchProjectAndPrivilegesCollaboratorsQueryHookResult = ReturnType<
  typeof useFetchProjectAndPrivilegesCollaboratorsQuery
>;
export type FetchProjectAndPrivilegesCollaboratorsLazyQueryHookResult = ReturnType<
  typeof useFetchProjectAndPrivilegesCollaboratorsLazyQuery
>;
export type FetchProjectAndPrivilegesCollaboratorsQueryResult = Apollo.QueryResult<
  FetchProjectAndPrivilegesCollaboratorsQuery,
  FetchProjectAndPrivilegesCollaboratorsQueryVariables
>;
export const FetchInvitationProjectCollaborationStatusDocument = gql`
  query fetchInvitationProjectCollaborationStatus(
    $key: String!
    $userEmail: String!
    $projectId: uuid!
  ) {
    project_collaborator_invitations(where: { key: { _eq: $key } }) {
      accepted_at
    }
    users(where: { email: { _eq: $userEmail } }) {
      email
      collaborated_projects(
        where: { status: { _eq: "active" }, project_id: { _eq: $projectId } }
      ) {
        project_id
        status
      }
    }
  }
`;

/**
 * __useFetchInvitationProjectCollaborationStatusQuery__
 *
 * To run a query within a React component, call `useFetchInvitationProjectCollaborationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchInvitationProjectCollaborationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchInvitationProjectCollaborationStatusQuery({
 *   variables: {
 *      key: // value for 'key'
 *      userEmail: // value for 'userEmail'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFetchInvitationProjectCollaborationStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchInvitationProjectCollaborationStatusQuery,
    FetchInvitationProjectCollaborationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchInvitationProjectCollaborationStatusQuery,
    FetchInvitationProjectCollaborationStatusQueryVariables
  >(FetchInvitationProjectCollaborationStatusDocument, options);
}
export function useFetchInvitationProjectCollaborationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchInvitationProjectCollaborationStatusQuery,
    FetchInvitationProjectCollaborationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchInvitationProjectCollaborationStatusQuery,
    FetchInvitationProjectCollaborationStatusQueryVariables
  >(FetchInvitationProjectCollaborationStatusDocument, options);
}
export type FetchInvitationProjectCollaborationStatusQueryHookResult = ReturnType<
  typeof useFetchInvitationProjectCollaborationStatusQuery
>;
export type FetchInvitationProjectCollaborationStatusLazyQueryHookResult = ReturnType<
  typeof useFetchInvitationProjectCollaborationStatusLazyQuery
>;
export type FetchInvitationProjectCollaborationStatusQueryResult = Apollo.QueryResult<
  FetchInvitationProjectCollaborationStatusQuery,
  FetchInvitationProjectCollaborationStatusQueryVariables
>;
export const FetchBillingOwnerStatusDocument = gql`
  subscription fetchBillingOwnerStatus($projectId: uuid!) {
    projects(where: { id: { _eq: $projectId } }) {
      billing_manager_id
    }
  }
`;

/**
 * __useFetchBillingOwnerStatusSubscription__
 *
 * To run a query within a React component, call `useFetchBillingOwnerStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFetchBillingOwnerStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBillingOwnerStatusSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useFetchBillingOwnerStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    FetchBillingOwnerStatusSubscription,
    FetchBillingOwnerStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    FetchBillingOwnerStatusSubscription,
    FetchBillingOwnerStatusSubscriptionVariables
  >(FetchBillingOwnerStatusDocument, options);
}
export type FetchBillingOwnerStatusSubscriptionHookResult = ReturnType<
  typeof useFetchBillingOwnerStatusSubscription
>;
export type FetchBillingOwnerStatusSubscriptionResult = Apollo.SubscriptionResult<
  FetchBillingOwnerStatusSubscription
>;
export const GetFeatureAccessDetailsDocument = gql`
  query getFeatureAccessDetails($feature: feature_enum) {
    feature_access {
      feature
    }
  }
`;

/**
 * __useGetFeatureAccessDetailsQuery__
 *
 * To run a query within a React component, call `useGetFeatureAccessDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeatureAccessDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeatureAccessDetailsQuery({
 *   variables: {
 *      feature: // value for 'feature'
 *   },
 * });
 */
export function useGetFeatureAccessDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetFeatureAccessDetailsQuery,
    GetFeatureAccessDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetFeatureAccessDetailsQuery,
    GetFeatureAccessDetailsQueryVariables
  >(GetFeatureAccessDetailsDocument, options);
}
export function useGetFeatureAccessDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFeatureAccessDetailsQuery,
    GetFeatureAccessDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetFeatureAccessDetailsQuery,
    GetFeatureAccessDetailsQueryVariables
  >(GetFeatureAccessDetailsDocument, options);
}
export type GetFeatureAccessDetailsQueryHookResult = ReturnType<
  typeof useGetFeatureAccessDetailsQuery
>;
export type GetFeatureAccessDetailsLazyQueryHookResult = ReturnType<
  typeof useGetFeatureAccessDetailsLazyQuery
>;
export type GetFeatureAccessDetailsQueryResult = Apollo.QueryResult<
  GetFeatureAccessDetailsQuery,
  GetFeatureAccessDetailsQueryVariables
>;
export const FetchAllSurveysDataDocument = gql`
  query fetchAllSurveysData($currentTime: timestamptz!) {
    survey_v2(
      where: {
        _or: [
          { ended_at: { _gte: $currentTime } }
          { ended_at: { _is_null: true } }
        ]
      }
    ) {
      survey_name
      survey_title
      survey_description
      survey_questions(
        where: {
          _or: [
            { ended_at: { _gte: $currentTime } }
            { ended_at: { _is_null: true } }
          ]
        }
      ) {
        id
        position
        question
        kind
        is_mandatory
        survey_question_options(
          where: {
            _or: [
              { ended_at: { _gte: $currentTime } }
              { ended_at: { _is_null: true } }
            ]
          }
        ) {
          id
          position
          option
          template_config
          additional_info_config {
            info_description
            is_mandatory
          }
        }
      }
      template_config
    }
  }
`;

/**
 * __useFetchAllSurveysDataQuery__
 *
 * To run a query within a React component, call `useFetchAllSurveysDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllSurveysDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllSurveysDataQuery({
 *   variables: {
 *      currentTime: // value for 'currentTime'
 *   },
 * });
 */
export function useFetchAllSurveysDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchAllSurveysDataQuery,
    FetchAllSurveysDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchAllSurveysDataQuery,
    FetchAllSurveysDataQueryVariables
  >(FetchAllSurveysDataDocument, options);
}
export function useFetchAllSurveysDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchAllSurveysDataQuery,
    FetchAllSurveysDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchAllSurveysDataQuery,
    FetchAllSurveysDataQueryVariables
  >(FetchAllSurveysDataDocument, options);
}
export type FetchAllSurveysDataQueryHookResult = ReturnType<
  typeof useFetchAllSurveysDataQuery
>;
export type FetchAllSurveysDataLazyQueryHookResult = ReturnType<
  typeof useFetchAllSurveysDataLazyQuery
>;
export type FetchAllSurveysDataQueryResult = Apollo.QueryResult<
  FetchAllSurveysDataQuery,
  FetchAllSurveysDataQueryVariables
>;
export const GetDedicatedCloudCommitmentDetailsDocument = gql`
  query getDedicatedCloudCommitmentDetails($userId: uuid!) {
    dedicated_cloud_commitments(where: { user_id: { _eq: $userId } }) {
      commitment_start
      commitment_end
      support_plan
    }
  }
`;

/**
 * __useGetDedicatedCloudCommitmentDetailsQuery__
 *
 * To run a query within a React component, call `useGetDedicatedCloudCommitmentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDedicatedCloudCommitmentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDedicatedCloudCommitmentDetailsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetDedicatedCloudCommitmentDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetDedicatedCloudCommitmentDetailsQuery,
    GetDedicatedCloudCommitmentDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDedicatedCloudCommitmentDetailsQuery,
    GetDedicatedCloudCommitmentDetailsQueryVariables
  >(GetDedicatedCloudCommitmentDetailsDocument, options);
}
export function useGetDedicatedCloudCommitmentDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDedicatedCloudCommitmentDetailsQuery,
    GetDedicatedCloudCommitmentDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDedicatedCloudCommitmentDetailsQuery,
    GetDedicatedCloudCommitmentDetailsQueryVariables
  >(GetDedicatedCloudCommitmentDetailsDocument, options);
}
export type GetDedicatedCloudCommitmentDetailsQueryHookResult = ReturnType<
  typeof useGetDedicatedCloudCommitmentDetailsQuery
>;
export type GetDedicatedCloudCommitmentDetailsLazyQueryHookResult = ReturnType<
  typeof useGetDedicatedCloudCommitmentDetailsLazyQuery
>;
export type GetDedicatedCloudCommitmentDetailsQueryResult = Apollo.QueryResult<
  GetDedicatedCloudCommitmentDetailsQuery,
  GetDedicatedCloudCommitmentDetailsQueryVariables
>;
export const FetchDedicatedCloudBillsDocument = gql`
  query fetchDedicatedCloudBills($month: Int, $year: Int) {
    dedicated_cloud_bills(
      where: { month: { _eq: $month }, year: { _eq: $year } }
    ) {
      compute_unit_config_id
      cu_cost
      data_passthrough_cost
      month
      year
      details {
        details
      }
    }
  }
`;

/**
 * __useFetchDedicatedCloudBillsQuery__
 *
 * To run a query within a React component, call `useFetchDedicatedCloudBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchDedicatedCloudBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchDedicatedCloudBillsQuery({
 *   variables: {
 *      month: // value for 'month'
 *      year: // value for 'year'
 *   },
 * });
 */
export function useFetchDedicatedCloudBillsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchDedicatedCloudBillsQuery,
    FetchDedicatedCloudBillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchDedicatedCloudBillsQuery,
    FetchDedicatedCloudBillsQueryVariables
  >(FetchDedicatedCloudBillsDocument, options);
}
export function useFetchDedicatedCloudBillsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchDedicatedCloudBillsQuery,
    FetchDedicatedCloudBillsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchDedicatedCloudBillsQuery,
    FetchDedicatedCloudBillsQueryVariables
  >(FetchDedicatedCloudBillsDocument, options);
}
export type FetchDedicatedCloudBillsQueryHookResult = ReturnType<
  typeof useFetchDedicatedCloudBillsQuery
>;
export type FetchDedicatedCloudBillsLazyQueryHookResult = ReturnType<
  typeof useFetchDedicatedCloudBillsLazyQuery
>;
export type FetchDedicatedCloudBillsQueryResult = Apollo.QueryResult<
  FetchDedicatedCloudBillsQuery,
  FetchDedicatedCloudBillsQueryVariables
>;
export const GetProjectDetailsDocument = gql`
  query getProjectDetails($id: uuid!) {
    project: projects_by_pk(id: $id) {
      ...ProjectFields
    }
    allLabels: label(limit: 50, order_by: { created_at: desc }) {
      ...LabelFields
    }
  }
  ${ProjectFieldsFragmentDoc}
  ${LabelFieldsFragmentDoc}
`;

/**
 * __useGetProjectDetailsQuery__
 *
 * To run a query within a React component, call `useGetProjectDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectDetailsQuery,
    GetProjectDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectDetailsQuery,
    GetProjectDetailsQueryVariables
  >(GetProjectDetailsDocument, options);
}
export function useGetProjectDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectDetailsQuery,
    GetProjectDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectDetailsQuery,
    GetProjectDetailsQueryVariables
  >(GetProjectDetailsDocument, options);
}
export type GetProjectDetailsQueryHookResult = ReturnType<
  typeof useGetProjectDetailsQuery
>;
export type GetProjectDetailsLazyQueryHookResult = ReturnType<
  typeof useGetProjectDetailsLazyQuery
>;
export type GetProjectDetailsQueryResult = Apollo.QueryResult<
  GetProjectDetailsQuery,
  GetProjectDetailsQueryVariables
>;
export const GetSupportProjectsListDocument = gql`
  query getSupportProjectsList {
    projects(where: { plan_name: { _neq: "pro" } }) {
      id
      name
      plan_name
    }
  }
`;

/**
 * __useGetSupportProjectsListQuery__
 *
 * To run a query within a React component, call `useGetSupportProjectsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportProjectsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportProjectsListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSupportProjectsListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSupportProjectsListQuery,
    GetSupportProjectsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetSupportProjectsListQuery,
    GetSupportProjectsListQueryVariables
  >(GetSupportProjectsListDocument, options);
}
export function useGetSupportProjectsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSupportProjectsListQuery,
    GetSupportProjectsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetSupportProjectsListQuery,
    GetSupportProjectsListQueryVariables
  >(GetSupportProjectsListDocument, options);
}
export type GetSupportProjectsListQueryHookResult = ReturnType<
  typeof useGetSupportProjectsListQuery
>;
export type GetSupportProjectsListLazyQueryHookResult = ReturnType<
  typeof useGetSupportProjectsListLazyQuery
>;
export type GetSupportProjectsListQueryResult = Apollo.QueryResult<
  GetSupportProjectsListQuery,
  GetSupportProjectsListQueryVariables
>;
export const GetProjectTenantDetailsDocument = gql`
  query getProjectTenantDetails($id: uuid!) {
    project: projects_by_pk(id: $id) {
      ...ProjectFields
    }
  }
  ${ProjectFieldsFragmentDoc}
`;

/**
 * __useGetProjectTenantDetailsQuery__
 *
 * To run a query within a React component, call `useGetProjectTenantDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectTenantDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectTenantDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectTenantDetailsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectTenantDetailsQuery,
    GetProjectTenantDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectTenantDetailsQuery,
    GetProjectTenantDetailsQueryVariables
  >(GetProjectTenantDetailsDocument, options);
}
export function useGetProjectTenantDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectTenantDetailsQuery,
    GetProjectTenantDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectTenantDetailsQuery,
    GetProjectTenantDetailsQueryVariables
  >(GetProjectTenantDetailsDocument, options);
}
export type GetProjectTenantDetailsQueryHookResult = ReturnType<
  typeof useGetProjectTenantDetailsQuery
>;
export type GetProjectTenantDetailsLazyQueryHookResult = ReturnType<
  typeof useGetProjectTenantDetailsLazyQuery
>;
export type GetProjectTenantDetailsQueryResult = Apollo.QueryResult<
  GetProjectTenantDetailsQuery,
  GetProjectTenantDetailsQueryVariables
>;
export const GetTenantEnvDocument = gql`
  query getTenantEnv($id: uuid!) {
    getTenantEnv: getTenantEnv(tenantId: $id) {
      hash
      envVars
    }
  }
`;

/**
 * __useGetTenantEnvQuery__
 *
 * To run a query within a React component, call `useGetTenantEnvQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTenantEnvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTenantEnvQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTenantEnvQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTenantEnvQuery,
    GetTenantEnvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTenantEnvQuery, GetTenantEnvQueryVariables>(
    GetTenantEnvDocument,
    options
  );
}
export function useGetTenantEnvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTenantEnvQuery,
    GetTenantEnvQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTenantEnvQuery, GetTenantEnvQueryVariables>(
    GetTenantEnvDocument,
    options
  );
}
export type GetTenantEnvQueryHookResult = ReturnType<
  typeof useGetTenantEnvQuery
>;
export type GetTenantEnvLazyQueryHookResult = ReturnType<
  typeof useGetTenantEnvLazyQuery
>;
export type GetTenantEnvQueryResult = Apollo.QueryResult<
  GetTenantEnvQuery,
  GetTenantEnvQueryVariables
>;
export const FetchPendingInvitationsDocument = gql`
  query fetchPendingInvitations {
    fetchPendingInvitations {
      id
      invitedAt
      projectName
      invitedBy
      key
      privileges
      isBillable
      projectID
    }
  }
`;

/**
 * __useFetchPendingInvitationsQuery__
 *
 * To run a query within a React component, call `useFetchPendingInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPendingInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPendingInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchPendingInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchPendingInvitationsQuery,
    FetchPendingInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchPendingInvitationsQuery,
    FetchPendingInvitationsQueryVariables
  >(FetchPendingInvitationsDocument, options);
}
export function useFetchPendingInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPendingInvitationsQuery,
    FetchPendingInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchPendingInvitationsQuery,
    FetchPendingInvitationsQueryVariables
  >(FetchPendingInvitationsDocument, options);
}
export type FetchPendingInvitationsQueryHookResult = ReturnType<
  typeof useFetchPendingInvitationsQuery
>;
export type FetchPendingInvitationsLazyQueryHookResult = ReturnType<
  typeof useFetchPendingInvitationsLazyQuery
>;
export type FetchPendingInvitationsQueryResult = Apollo.QueryResult<
  FetchPendingInvitationsQuery,
  FetchPendingInvitationsQueryVariables
>;
export const GetAdminSecretDocument = gql`
  query getAdminSecret($id: uuid!) {
    getTenantEnv(tenantId: $id) {
      hash
      envVars
    }
  }
`;

/**
 * __useGetAdminSecretQuery__
 *
 * To run a query within a React component, call `useGetAdminSecretQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAdminSecretQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAdminSecretQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAdminSecretQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAdminSecretQuery,
    GetAdminSecretQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAdminSecretQuery, GetAdminSecretQueryVariables>(
    GetAdminSecretDocument,
    options
  );
}
export function useGetAdminSecretLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAdminSecretQuery,
    GetAdminSecretQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAdminSecretQuery, GetAdminSecretQueryVariables>(
    GetAdminSecretDocument,
    options
  );
}
export type GetAdminSecretQueryHookResult = ReturnType<
  typeof useGetAdminSecretQuery
>;
export type GetAdminSecretLazyQueryHookResult = ReturnType<
  typeof useGetAdminSecretLazyQuery
>;
export type GetAdminSecretQueryResult = Apollo.QueryResult<
  GetAdminSecretQuery,
  GetAdminSecretQueryVariables
>;
export const RealtimeTenantStatusDocument = gql`
  subscription realtimeTenantStatus($tenantId: uuid!) {
    tenant: tenant_by_pk(id: $tenantId) {
      ...TenantFields
    }
  }
  ${TenantFieldsFragmentDoc}
`;

/**
 * __useRealtimeTenantStatusSubscription__
 *
 * To run a query within a React component, call `useRealtimeTenantStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRealtimeTenantStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealtimeTenantStatusSubscription({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useRealtimeTenantStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    RealtimeTenantStatusSubscription,
    RealtimeTenantStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    RealtimeTenantStatusSubscription,
    RealtimeTenantStatusSubscriptionVariables
  >(RealtimeTenantStatusDocument, options);
}
export type RealtimeTenantStatusSubscriptionHookResult = ReturnType<
  typeof useRealtimeTenantStatusSubscription
>;
export type RealtimeTenantStatusSubscriptionResult = Apollo.SubscriptionResult<
  RealtimeTenantStatusSubscription
>;
export const GetCustomDomainsDocument = gql`
  subscription getCustomDomains($tenantId: uuid!) {
    domains: custom_domain(
      where: { tenant_id: { _eq: $tenantId } }
      order_by: { created_at: desc }
    ) {
      id
      fqdn
      dns_validation
      ssl_status
      ssl_message
    }
  }
`;

/**
 * __useGetCustomDomainsSubscription__
 *
 * To run a query within a React component, call `useGetCustomDomainsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomDomainsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomDomainsSubscription({
 *   variables: {
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetCustomDomainsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetCustomDomainsSubscription,
    GetCustomDomainsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetCustomDomainsSubscription,
    GetCustomDomainsSubscriptionVariables
  >(GetCustomDomainsDocument, options);
}
export type GetCustomDomainsSubscriptionHookResult = ReturnType<
  typeof useGetCustomDomainsSubscription
>;
export type GetCustomDomainsSubscriptionResult = Apollo.SubscriptionResult<
  GetCustomDomainsSubscription
>;
export const GetUsageDetailsDocument = gql`
  subscription getUsageDetails($id: uuid!) {
    projects(where: { id: { _eq: $id } }) {
      plan_name
      created_at
      collaborators_aggregate {
        aggregate {
          count
        }
      }
      policies {
        log_retention
        caching_enabled
      }
    }
  }
`;

/**
 * __useGetUsageDetailsSubscription__
 *
 * To run a query within a React component, call `useGetUsageDetailsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetUsageDetailsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsageDetailsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUsageDetailsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetUsageDetailsSubscription,
    GetUsageDetailsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetUsageDetailsSubscription,
    GetUsageDetailsSubscriptionVariables
  >(GetUsageDetailsDocument, options);
}
export type GetUsageDetailsSubscriptionHookResult = ReturnType<
  typeof useGetUsageDetailsSubscription
>;
export type GetUsageDetailsSubscriptionResult = Apollo.SubscriptionResult<
  GetUsageDetailsSubscription
>;
export const GetRegionsDocument = gql`
  query getRegions {
    region(where: { is_active: { _eq: true } }) {
      ...RegionFields
    }
    region_v2(where: { is_active: { _eq: true } }) {
      region {
        ...RegionFields
      }
    }
  }
  ${RegionFieldsFragmentDoc}
`;

/**
 * __useGetRegionsQuery__
 *
 * To run a query within a React component, call `useGetRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRegionsQuery,
    GetRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRegionsQuery, GetRegionsQueryVariables>(
    GetRegionsDocument,
    options
  );
}
export function useGetRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRegionsQuery,
    GetRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRegionsQuery, GetRegionsQueryVariables>(
    GetRegionsDocument,
    options
  );
}
export type GetRegionsQueryHookResult = ReturnType<typeof useGetRegionsQuery>;
export type GetRegionsLazyQueryHookResult = ReturnType<
  typeof useGetRegionsLazyQuery
>;
export type GetRegionsQueryResult = Apollo.QueryResult<
  GetRegionsQuery,
  GetRegionsQueryVariables
>;
export const GetLabelSuggestionsDocument = gql`
  query getLabelSuggestions($name: String!) {
    label(where: { name: { _like: $name } }) {
      name
    }
  }
`;

/**
 * __useGetLabelSuggestionsQuery__
 *
 * To run a query within a React component, call `useGetLabelSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLabelSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLabelSuggestionsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetLabelSuggestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLabelSuggestionsQuery,
    GetLabelSuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLabelSuggestionsQuery,
    GetLabelSuggestionsQueryVariables
  >(GetLabelSuggestionsDocument, options);
}
export function useGetLabelSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLabelSuggestionsQuery,
    GetLabelSuggestionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLabelSuggestionsQuery,
    GetLabelSuggestionsQueryVariables
  >(GetLabelSuggestionsDocument, options);
}
export type GetLabelSuggestionsQueryHookResult = ReturnType<
  typeof useGetLabelSuggestionsQuery
>;
export type GetLabelSuggestionsLazyQueryHookResult = ReturnType<
  typeof useGetLabelSuggestionsLazyQuery
>;
export type GetLabelSuggestionsQueryResult = Apollo.QueryResult<
  GetLabelSuggestionsQuery,
  GetLabelSuggestionsQueryVariables
>;
export const ValidateTenantNameDocument = gql`
  query validateTenantName($name: String!) {
    validTenantName(name: $name) {
      message
      valid
    }
  }
`;

/**
 * __useValidateTenantNameQuery__
 *
 * To run a query within a React component, call `useValidateTenantNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateTenantNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateTenantNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useValidateTenantNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    ValidateTenantNameQuery,
    ValidateTenantNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ValidateTenantNameQuery,
    ValidateTenantNameQueryVariables
  >(ValidateTenantNameDocument, options);
}
export function useValidateTenantNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ValidateTenantNameQuery,
    ValidateTenantNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ValidateTenantNameQuery,
    ValidateTenantNameQueryVariables
  >(ValidateTenantNameDocument, options);
}
export type ValidateTenantNameQueryHookResult = ReturnType<
  typeof useValidateTenantNameQuery
>;
export type ValidateTenantNameLazyQueryHookResult = ReturnType<
  typeof useValidateTenantNameLazyQuery
>;
export type ValidateTenantNameQueryResult = Apollo.QueryResult<
  ValidateTenantNameQuery,
  ValidateTenantNameQueryVariables
>;
export const FetchPendingOwnershipInvitationsDocument = gql`
  query fetchPendingOwnershipInvitations {
    fetchPendingOwnershipInvitations {
      id
      invitedAt
      invitedBy
      key
      projectName
      projectPlan
    }
  }
`;

/**
 * __useFetchPendingOwnershipInvitationsQuery__
 *
 * To run a query within a React component, call `useFetchPendingOwnershipInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPendingOwnershipInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPendingOwnershipInvitationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchPendingOwnershipInvitationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchPendingOwnershipInvitationsQuery,
    FetchPendingOwnershipInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchPendingOwnershipInvitationsQuery,
    FetchPendingOwnershipInvitationsQueryVariables
  >(FetchPendingOwnershipInvitationsDocument, options);
}
export function useFetchPendingOwnershipInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchPendingOwnershipInvitationsQuery,
    FetchPendingOwnershipInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchPendingOwnershipInvitationsQuery,
    FetchPendingOwnershipInvitationsQueryVariables
  >(FetchPendingOwnershipInvitationsDocument, options);
}
export type FetchPendingOwnershipInvitationsQueryHookResult = ReturnType<
  typeof useFetchPendingOwnershipInvitationsQuery
>;
export type FetchPendingOwnershipInvitationsLazyQueryHookResult = ReturnType<
  typeof useFetchPendingOwnershipInvitationsLazyQuery
>;
export type FetchPendingOwnershipInvitationsQueryResult = Apollo.QueryResult<
  FetchPendingOwnershipInvitationsQuery,
  FetchPendingOwnershipInvitationsQueryVariables
>;
export const GetProjectOwnerDocument = gql`
  subscription getProjectOwner($id: uuid!) {
    projects(where: { id: { _eq: $id } }) {
      owner {
        email
      }
    }
  }
`;

/**
 * __useGetProjectOwnerSubscription__
 *
 * To run a query within a React component, call `useGetProjectOwnerSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectOwnerSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectOwnerSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectOwnerSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetProjectOwnerSubscription,
    GetProjectOwnerSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetProjectOwnerSubscription,
    GetProjectOwnerSubscriptionVariables
  >(GetProjectOwnerDocument, options);
}
export type GetProjectOwnerSubscriptionHookResult = ReturnType<
  typeof useGetProjectOwnerSubscription
>;
export type GetProjectOwnerSubscriptionResult = Apollo.SubscriptionResult<
  GetProjectOwnerSubscription
>;
export const GetDatadogConfigDocument = gql`
  subscription getDatadogConfig($id: uuid) {
    datadog_config(where: { project_id: { _eq: $id } }) {
      host
      service_name
      tags
      project_id
      logs_last_logged
      key
      datadog_region
      logs_response_code
    }
  }
`;

/**
 * __useGetDatadogConfigSubscription__
 *
 * To run a query within a React component, call `useGetDatadogConfigSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetDatadogConfigSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDatadogConfigSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDatadogConfigSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetDatadogConfigSubscription,
    GetDatadogConfigSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetDatadogConfigSubscription,
    GetDatadogConfigSubscriptionVariables
  >(GetDatadogConfigDocument, options);
}
export type GetDatadogConfigSubscriptionHookResult = ReturnType<
  typeof useGetDatadogConfigSubscription
>;
export type GetDatadogConfigSubscriptionResult = Apollo.SubscriptionResult<
  GetDatadogConfigSubscription
>;
export const Newrelic_ConfigDocument = gql`
  subscription newrelic_config($id: uuid) {
    newrelic_config(where: { project_id: { _eq: $id } }) {
      attributes
      key
      logs_last_logged
      logs_response_code
      metrics_last_logged
      metrics_response_code
      newrelic_region
      project_id
    }
  }
`;

/**
 * __useNewrelic_ConfigSubscription__
 *
 * To run a query within a React component, call `useNewrelic_ConfigSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewrelic_ConfigSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewrelic_ConfigSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNewrelic_ConfigSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    Newrelic_ConfigSubscription,
    Newrelic_ConfigSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Newrelic_ConfigSubscription,
    Newrelic_ConfigSubscriptionVariables
  >(Newrelic_ConfigDocument, options);
}
export type Newrelic_ConfigSubscriptionHookResult = ReturnType<
  typeof useNewrelic_ConfigSubscription
>;
export type Newrelic_ConfigSubscriptionResult = Apollo.SubscriptionResult<
  Newrelic_ConfigSubscription
>;
export const Azuremonitor_ConfigDocument = gql`
  subscription azuremonitor_config($id: uuid) {
    azuremonitor_config(where: { project_id: { _eq: $id } }) {
      attributes
      azure_region
      activedirectory_client_id
      activedirectory_client_secret
      activedirectory_tenant_id
      azuremonitor_workspace_id
      log_type
      metric_namespace
      shared_key
      resource_id
      logs_last_logged
      logs_response_code
      metrics_last_logged
      metrics_response_code
      project_id
      instrumentation_key
      traces_last_logged
      traces_response_code
      traces_response_message
    }
  }
`;

/**
 * __useAzuremonitor_ConfigSubscription__
 *
 * To run a query within a React component, call `useAzuremonitor_ConfigSubscription` and pass it any options that fit your needs.
 * When your component renders, `useAzuremonitor_ConfigSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAzuremonitor_ConfigSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAzuremonitor_ConfigSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    Azuremonitor_ConfigSubscription,
    Azuremonitor_ConfigSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Azuremonitor_ConfigSubscription,
    Azuremonitor_ConfigSubscriptionVariables
  >(Azuremonitor_ConfigDocument, options);
}
export type Azuremonitor_ConfigSubscriptionHookResult = ReturnType<
  typeof useAzuremonitor_ConfigSubscription
>;
export type Azuremonitor_ConfigSubscriptionResult = Apollo.SubscriptionResult<
  Azuremonitor_ConfigSubscription
>;
export const Prometheus_ConfigDocument = gql`
  subscription prometheus_config($id: uuid) {
    prometheus_config(where: { project_id: { _eq: $id } }) {
      project_id
      labels
      metrics_last_logged
      metrics_response_code
      namespace
    }
  }
`;

/**
 * __usePrometheus_ConfigSubscription__
 *
 * To run a query within a React component, call `usePrometheus_ConfigSubscription` and pass it any options that fit your needs.
 * When your component renders, `usePrometheus_ConfigSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrometheus_ConfigSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePrometheus_ConfigSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    Prometheus_ConfigSubscription,
    Prometheus_ConfigSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Prometheus_ConfigSubscription,
    Prometheus_ConfigSubscriptionVariables
  >(Prometheus_ConfigDocument, options);
}
export type Prometheus_ConfigSubscriptionHookResult = ReturnType<
  typeof usePrometheus_ConfigSubscription
>;
export type Prometheus_ConfigSubscriptionResult = Apollo.SubscriptionResult<
  Prometheus_ConfigSubscription
>;
export const Opentelemetry_ConfigDocument = gql`
  subscription opentelemetry_config($id: uuid) {
    opentelemetry_config(where: { project_id: { _eq: $id } }) {
      project_id
      endpoint
      headers
      attributes
      batch_size
      connection_type
      export_traces
      traces_last_exported
      traces_response_code
      traces_response_message
    }
  }
`;

/**
 * __useOpentelemetry_ConfigSubscription__
 *
 * To run a query within a React component, call `useOpentelemetry_ConfigSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOpentelemetry_ConfigSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOpentelemetry_ConfigSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOpentelemetry_ConfigSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    Opentelemetry_ConfigSubscription,
    Opentelemetry_ConfigSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Opentelemetry_ConfigSubscription,
    Opentelemetry_ConfigSubscriptionVariables
  >(Opentelemetry_ConfigDocument, options);
}
export type Opentelemetry_ConfigSubscriptionHookResult = ReturnType<
  typeof useOpentelemetry_ConfigSubscription
>;
export type Opentelemetry_ConfigSubscriptionResult = Apollo.SubscriptionResult<
  Opentelemetry_ConfigSubscription
>;
export const Heroku_IntegrationsDocument = gql`
  subscription heroku_integrations($id: uuid!) {
    heroku_integrations(where: { project_id: { _eq: $id } }) {
      var_name
      app_name
    }
  }
`;

/**
 * __useHeroku_IntegrationsSubscription__
 *
 * To run a query within a React component, call `useHeroku_IntegrationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useHeroku_IntegrationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeroku_IntegrationsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHeroku_IntegrationsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    Heroku_IntegrationsSubscription,
    Heroku_IntegrationsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    Heroku_IntegrationsSubscription,
    Heroku_IntegrationsSubscriptionVariables
  >(Heroku_IntegrationsDocument, options);
}
export type Heroku_IntegrationsSubscriptionHookResult = ReturnType<
  typeof useHeroku_IntegrationsSubscription
>;
export type Heroku_IntegrationsSubscriptionResult = Apollo.SubscriptionResult<
  Heroku_IntegrationsSubscription
>;
export const GetVpcProjectsDocument = gql`
  query getVPCProjects($vpcId: uuid) {
    projects(
      where: { tenant: { dedicated_vpc_id: { _eq: $vpcId } } }
      order_by: { created_at: desc }
    ) {
      ...ProjectFields
    }
    allLabels: label(limit: 50, order_by: { created_at: desc }) {
      ...LabelFields
    }
    dedicated_vpc(where: { id: { _eq: $vpcId } }) {
      ...DedicateVPCFields
      vpc_peerings {
        ...VPCPeeringFields
      }
    }
  }
  ${ProjectFieldsFragmentDoc}
  ${LabelFieldsFragmentDoc}
  ${DedicateVpcFieldsFragmentDoc}
  ${VpcPeeringFieldsFragmentDoc}
`;

/**
 * __useGetVpcProjectsQuery__
 *
 * To run a query within a React component, call `useGetVpcProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVpcProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVpcProjectsQuery({
 *   variables: {
 *      vpcId: // value for 'vpcId'
 *   },
 * });
 */
export function useGetVpcProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVpcProjectsQuery,
    GetVpcProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVpcProjectsQuery, GetVpcProjectsQueryVariables>(
    GetVpcProjectsDocument,
    options
  );
}
export function useGetVpcProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVpcProjectsQuery,
    GetVpcProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVpcProjectsQuery, GetVpcProjectsQueryVariables>(
    GetVpcProjectsDocument,
    options
  );
}
export type GetVpcProjectsQueryHookResult = ReturnType<
  typeof useGetVpcProjectsQuery
>;
export type GetVpcProjectsLazyQueryHookResult = ReturnType<
  typeof useGetVpcProjectsLazyQuery
>;
export type GetVpcProjectsQueryResult = Apollo.QueryResult<
  GetVpcProjectsQuery,
  GetVpcProjectsQueryVariables
>;
export const GetJobStatusDocument = gql`
  subscription getJobStatus($jobId: uuid!) {
    jobs_by_pk(id: $jobId) {
      id
      job_type
      project_id
      status
      created_at
      tasks(order_by: { updated_at: asc }) {
        id
        name
        cloud
        region
        task_events(order_by: { updated_at: desc }, limit: 1) {
          event_type
          id
          error
          github_detail
          public_event_data
        }
      }
      github_push_event_jobs {
        github_push_event {
          commit
          commit_message
          commit_timestamp
          github_integration_config {
            organisation
            repository
            branch
            directory
            id
          }
        }
      }
    }
  }
`;

/**
 * __useGetJobStatusSubscription__
 *
 * To run a query within a React component, call `useGetJobStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetJobStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobStatusSubscription({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetJobStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetJobStatusSubscription,
    GetJobStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetJobStatusSubscription,
    GetJobStatusSubscriptionVariables
  >(GetJobStatusDocument, options);
}
export type GetJobStatusSubscriptionHookResult = ReturnType<
  typeof useGetJobStatusSubscription
>;
export type GetJobStatusSubscriptionResult = Apollo.SubscriptionResult<
  GetJobStatusSubscription
>;
export const FetchJobIdDocument = gql`
  query fetchJobId($id: uuid!) {
    jobs(
      where: {
        project_id: { _eq: $id }
        job_type: { _in: ["v1_to_v2_upgrade", "region_migration"] }
      }
      order_by: { updated_at: desc }
    ) {
      id
      status
      job_type
    }
  }
`;

/**
 * __useFetchJobIdQuery__
 *
 * To run a query within a React component, call `useFetchJobIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchJobIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchJobIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchJobIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchJobIdQuery,
    FetchJobIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchJobIdQuery, FetchJobIdQueryVariables>(
    FetchJobIdDocument,
    options
  );
}
export function useFetchJobIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchJobIdQuery,
    FetchJobIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchJobIdQuery, FetchJobIdQueryVariables>(
    FetchJobIdDocument,
    options
  );
}
export type FetchJobIdQueryHookResult = ReturnType<typeof useFetchJobIdQuery>;
export type FetchJobIdLazyQueryHookResult = ReturnType<
  typeof useFetchJobIdLazyQuery
>;
export type FetchJobIdQueryResult = Apollo.QueryResult<
  FetchJobIdQuery,
  FetchJobIdQueryVariables
>;
export const FetchGithubConfigDocument = gql`
  subscription fetchGithubConfig($id: uuid!) {
    github_integration_config(
      where: { project_id: { _eq: $id } }
      order_by: { updated_at: desc }
    ) {
      directory
      branch
      organisation
      repository
      app_installation_id
      id
      mode
      email_status
    }
  }
`;

/**
 * __useFetchGithubConfigSubscription__
 *
 * To run a query within a React component, call `useFetchGithubConfigSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFetchGithubConfigSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchGithubConfigSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchGithubConfigSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    FetchGithubConfigSubscription,
    FetchGithubConfigSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    FetchGithubConfigSubscription,
    FetchGithubConfigSubscriptionVariables
  >(FetchGithubConfigDocument, options);
}
export type FetchGithubConfigSubscriptionHookResult = ReturnType<
  typeof useFetchGithubConfigSubscription
>;
export type FetchGithubConfigSubscriptionResult = Apollo.SubscriptionResult<
  FetchGithubConfigSubscription
>;
export const FetchGithubCommitsDocument = gql`
  subscription fetchGithubCommits($id: uuid!) {
    github_push_event_job(
      limit: 10
      order_by: { job: { updated_at: desc } }
      where: {
        github_push_event: { github_integration_config_id: { _eq: $id } }
      }
    ) {
      github_push_event {
        commit
        commit_message
        commit_timestamp
        id
      }
      job_id
      job {
        status
        created_at
      }
    }
  }
`;

/**
 * __useFetchGithubCommitsSubscription__
 *
 * To run a query within a React component, call `useFetchGithubCommitsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFetchGithubCommitsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchGithubCommitsSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchGithubCommitsSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    FetchGithubCommitsSubscription,
    FetchGithubCommitsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    FetchGithubCommitsSubscription,
    FetchGithubCommitsSubscriptionVariables
  >(FetchGithubCommitsDocument, options);
}
export type FetchGithubCommitsSubscriptionHookResult = ReturnType<
  typeof useFetchGithubCommitsSubscription
>;
export type FetchGithubCommitsSubscriptionResult = Apollo.SubscriptionResult<
  FetchGithubCommitsSubscription
>;
export const FetchLatestCommitDocument = gql`
  subscription fetchLatestCommit($integrationId: uuid!, $pushMetadata: jsonb!) {
    github_push_event(
      where: {
        _and: [
          { push_metadata: { _contains: $pushMetadata } }
          { github_integration_config_id: { _eq: $integrationId } }
        ]
      }
      order_by: { commit_timestamp: desc }
      limit: 1
    ) {
      id
      github_push_event_jobs(order_by: { job: { created_at: desc } }) {
        job {
          status
        }
      }
      commit
      commit_message
      commit_timestamp
      id
    }
  }
`;

/**
 * __useFetchLatestCommitSubscription__
 *
 * To run a query within a React component, call `useFetchLatestCommitSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFetchLatestCommitSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchLatestCommitSubscription({
 *   variables: {
 *      integrationId: // value for 'integrationId'
 *      pushMetadata: // value for 'pushMetadata'
 *   },
 * });
 */
export function useFetchLatestCommitSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    FetchLatestCommitSubscription,
    FetchLatestCommitSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    FetchLatestCommitSubscription,
    FetchLatestCommitSubscriptionVariables
  >(FetchLatestCommitDocument, options);
}
export type FetchLatestCommitSubscriptionHookResult = ReturnType<
  typeof useFetchLatestCommitSubscription
>;
export type FetchLatestCommitSubscriptionResult = Apollo.SubscriptionResult<
  FetchLatestCommitSubscription
>;
export const GetProjectMigrationStatusDocument = gql`
  query getProjectMigrationStatus($projectId: String!) {
    getProjectMigrationStatus(payload: { project_id: $projectId }) {
      datasource
      migration
    }
  }
`;

/**
 * __useGetProjectMigrationStatusQuery__
 *
 * To run a query within a React component, call `useGetProjectMigrationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectMigrationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectMigrationStatusQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectMigrationStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectMigrationStatusQuery,
    GetProjectMigrationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectMigrationStatusQuery,
    GetProjectMigrationStatusQueryVariables
  >(GetProjectMigrationStatusDocument, options);
}
export function useGetProjectMigrationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectMigrationStatusQuery,
    GetProjectMigrationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectMigrationStatusQuery,
    GetProjectMigrationStatusQueryVariables
  >(GetProjectMigrationStatusDocument, options);
}
export type GetProjectMigrationStatusQueryHookResult = ReturnType<
  typeof useGetProjectMigrationStatusQuery
>;
export type GetProjectMigrationStatusLazyQueryHookResult = ReturnType<
  typeof useGetProjectMigrationStatusLazyQuery
>;
export type GetProjectMigrationStatusQueryResult = Apollo.QueryResult<
  GetProjectMigrationStatusQuery,
  GetProjectMigrationStatusQueryVariables
>;
export const RealtimeProjectStatusDocument = gql`
  subscription realtimeProjectStatus($projectId: uuid!) {
    projects_by_pk(id: $projectId) {
      ...ProjectFields
    }
  }
  ${ProjectFieldsFragmentDoc}
`;

/**
 * __useRealtimeProjectStatusSubscription__
 *
 * To run a query within a React component, call `useRealtimeProjectStatusSubscription` and pass it any options that fit your needs.
 * When your component renders, `useRealtimeProjectStatusSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRealtimeProjectStatusSubscription({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useRealtimeProjectStatusSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    RealtimeProjectStatusSubscription,
    RealtimeProjectStatusSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    RealtimeProjectStatusSubscription,
    RealtimeProjectStatusSubscriptionVariables
  >(RealtimeProjectStatusDocument, options);
}
export type RealtimeProjectStatusSubscriptionHookResult = ReturnType<
  typeof useRealtimeProjectStatusSubscription
>;
export type RealtimeProjectStatusSubscriptionResult = Apollo.SubscriptionResult<
  RealtimeProjectStatusSubscription
>;
export const GetGcpRegionsDocument = gql`
  query getGCPRegions {
    region_v2(where: { is_active: { _eq: true }, cloud: { _eq: "gcp" } }) {
      name
      region {
        name
        cloud
        nat_ips
      }
    }
  }
`;

/**
 * __useGetGcpRegionsQuery__
 *
 * To run a query within a React component, call `useGetGcpRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGcpRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGcpRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGcpRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetGcpRegionsQuery,
    GetGcpRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGcpRegionsQuery, GetGcpRegionsQueryVariables>(
    GetGcpRegionsDocument,
    options
  );
}
export function useGetGcpRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGcpRegionsQuery,
    GetGcpRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGcpRegionsQuery, GetGcpRegionsQueryVariables>(
    GetGcpRegionsDocument,
    options
  );
}
export type GetGcpRegionsQueryHookResult = ReturnType<
  typeof useGetGcpRegionsQuery
>;
export type GetGcpRegionsLazyQueryHookResult = ReturnType<
  typeof useGetGcpRegionsLazyQuery
>;
export type GetGcpRegionsQueryResult = Apollo.QueryResult<
  GetGcpRegionsQuery,
  GetGcpRegionsQueryVariables
>;
export const GetProjectDbCountDocument = gql`
  query getProjectDbCount($projectId: uuid!) {
    getNumberOfConnectedSources(projectId: $projectId) {
      databases_connected
      project_id
      plan_name
      unsupported
    }
  }
`;

/**
 * __useGetProjectDbCountQuery__
 *
 * To run a query within a React component, call `useGetProjectDbCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDbCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDbCountQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useGetProjectDbCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectDbCountQuery,
    GetProjectDbCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectDbCountQuery,
    GetProjectDbCountQueryVariables
  >(GetProjectDbCountDocument, options);
}
export function useGetProjectDbCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectDbCountQuery,
    GetProjectDbCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectDbCountQuery,
    GetProjectDbCountQueryVariables
  >(GetProjectDbCountDocument, options);
}
export type GetProjectDbCountQueryHookResult = ReturnType<
  typeof useGetProjectDbCountQuery
>;
export type GetProjectDbCountLazyQueryHookResult = ReturnType<
  typeof useGetProjectDbCountLazyQuery
>;
export type GetProjectDbCountQueryResult = Apollo.QueryResult<
  GetProjectDbCountQuery,
  GetProjectDbCountQueryVariables
>;
export const ProjectDbUsageDocument = gql`
  query projectDbUsage($projectId: uuid!, $startDate: date!, $endDate: date!) {
    project_db_usage(
      where: {
        project_id: { _eq: $projectId }
        created_at: { _gte: $startDate, _lte: $endDate }
      }
    ) {
      no_db_usage
      non_pg_usage
      pg_usage
      created_at
      updated_at
    }
  }
`;

/**
 * __useProjectDbUsageQuery__
 *
 * To run a query within a React component, call `useProjectDbUsageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDbUsageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDbUsageQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useProjectDbUsageQuery(
  baseOptions: Apollo.QueryHookOptions<
    ProjectDbUsageQuery,
    ProjectDbUsageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ProjectDbUsageQuery, ProjectDbUsageQueryVariables>(
    ProjectDbUsageDocument,
    options
  );
}
export function useProjectDbUsageLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ProjectDbUsageQuery,
    ProjectDbUsageQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ProjectDbUsageQuery, ProjectDbUsageQueryVariables>(
    ProjectDbUsageDocument,
    options
  );
}
export type ProjectDbUsageQueryHookResult = ReturnType<
  typeof useProjectDbUsageQuery
>;
export type ProjectDbUsageLazyQueryHookResult = ReturnType<
  typeof useProjectDbUsageLazyQuery
>;
export type ProjectDbUsageQueryResult = Apollo.QueryResult<
  ProjectDbUsageQuery,
  ProjectDbUsageQueryVariables
>;
export const GetProjectDataUsagePrometheusDocument = gql`
  query getProjectDataUsagePrometheus(
    $projectId: uuid!
    $startDate: date!
    $endDate: date!
  ) {
    projects(where: { id: { _eq: $projectId } }) {
      project_usage_prometheus(
        where: { date: { _gte: $startDate, _lte: $endDate } }
        order_by: { date: asc }
      ) {
        date
        usage_updated_at
        usage
        data_usage_components {
          date
          project_id
          hasura_action_request_bytes_total
          hasura_action_response_bytes_total
          hasura_event_trigger_request_bytes_total
          hasura_event_trigger_response_bytes_total
          hasura_http_request_bytes_total
          hasura_http_response_bytes_total
          hasura_scheduled_trigger_request_bytes_total
          hasura_scheduled_trigger_response_bytes_total
          hasura_websocket_messages_received_bytes_total
          hasura_websocket_messages_sent_bytes_total
          usage_updated_at
        }
      }
    }
  }
`;

/**
 * __useGetProjectDataUsagePrometheusQuery__
 *
 * To run a query within a React component, call `useGetProjectDataUsagePrometheusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectDataUsagePrometheusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectDataUsagePrometheusQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetProjectDataUsagePrometheusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectDataUsagePrometheusQuery,
    GetProjectDataUsagePrometheusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectDataUsagePrometheusQuery,
    GetProjectDataUsagePrometheusQueryVariables
  >(GetProjectDataUsagePrometheusDocument, options);
}
export function useGetProjectDataUsagePrometheusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectDataUsagePrometheusQuery,
    GetProjectDataUsagePrometheusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectDataUsagePrometheusQuery,
    GetProjectDataUsagePrometheusQueryVariables
  >(GetProjectDataUsagePrometheusDocument, options);
}
export type GetProjectDataUsagePrometheusQueryHookResult = ReturnType<
  typeof useGetProjectDataUsagePrometheusQuery
>;
export type GetProjectDataUsagePrometheusLazyQueryHookResult = ReturnType<
  typeof useGetProjectDataUsagePrometheusLazyQuery
>;
export type GetProjectDataUsagePrometheusQueryResult = Apollo.QueryResult<
  GetProjectDataUsagePrometheusQuery,
  GetProjectDataUsagePrometheusQueryVariables
>;
export const GetProjectRequestsCountDocument = gql`
  query getProjectRequestsCount(
    $projectId: uuid!
    $startDate: date!
    $endDate: date!
  ) {
    project_requests_count(
      where: {
        project_id: { _eq: $projectId }
        created_at: { _gte: $startDate, _lte: $endDate }
      }
    ) {
      hasura_graphql_requests_total
      created_at
      updated_at
    }
  }
`;

/**
 * __useGetProjectRequestsCountQuery__
 *
 * To run a query within a React component, call `useGetProjectRequestsCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectRequestsCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectRequestsCountQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetProjectRequestsCountQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectRequestsCountQuery,
    GetProjectRequestsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectRequestsCountQuery,
    GetProjectRequestsCountQueryVariables
  >(GetProjectRequestsCountDocument, options);
}
export function useGetProjectRequestsCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectRequestsCountQuery,
    GetProjectRequestsCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectRequestsCountQuery,
    GetProjectRequestsCountQueryVariables
  >(GetProjectRequestsCountDocument, options);
}
export type GetProjectRequestsCountQueryHookResult = ReturnType<
  typeof useGetProjectRequestsCountQuery
>;
export type GetProjectRequestsCountLazyQueryHookResult = ReturnType<
  typeof useGetProjectRequestsCountLazyQuery
>;
export type GetProjectRequestsCountQueryResult = Apollo.QueryResult<
  GetProjectRequestsCountQuery,
  GetProjectRequestsCountQueryVariables
>;
export const GetProjectsListDocument = gql`
  query getProjectsList($offset: Int!, $nameRegex: String, $limit: Int) {
    projects_aggregate(where: { name: { _iregex: $nameRegex } }) {
      aggregate {
        count
      }
    }
    projects(
      where: { name: { _iregex: $nameRegex } }
      order_by: { created_at: desc }
      offset: $offset
      limit: $limit
    ) {
      ...ProjectsListFields
    }
    allLabels: label(limit: 50, order_by: { created_at: desc }) {
      ...LabelFields
    }
  }
  ${ProjectsListFieldsFragmentDoc}
  ${LabelFieldsFragmentDoc}
`;

/**
 * __useGetProjectsListQuery__
 *
 * To run a query within a React component, call `useGetProjectsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsListQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      nameRegex: // value for 'nameRegex'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProjectsListQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectsListQuery,
    GetProjectsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProjectsListQuery, GetProjectsListQueryVariables>(
    GetProjectsListDocument,
    options
  );
}
export function useGetProjectsListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsListQuery,
    GetProjectsListQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectsListQuery,
    GetProjectsListQueryVariables
  >(GetProjectsListDocument, options);
}
export type GetProjectsListQueryHookResult = ReturnType<
  typeof useGetProjectsListQuery
>;
export type GetProjectsListLazyQueryHookResult = ReturnType<
  typeof useGetProjectsListLazyQuery
>;
export type GetProjectsListQueryResult = Apollo.QueryResult<
  GetProjectsListQuery,
  GetProjectsListQueryVariables
>;
export const GetCloudRegionsByPlanDocument = gql`
  query getCloudRegionsByPlan {
    region(where: { is_active: { _eq: true } }) {
      ...RegionFields
    }
    cloud_regions_by_plan(where: { is_active: { _eq: true } }) {
      region {
        ...RegionFields
      }
      plan_name
    }
  }
  ${RegionFieldsFragmentDoc}
`;

/**
 * __useGetCloudRegionsByPlanQuery__
 *
 * To run a query within a React component, call `useGetCloudRegionsByPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCloudRegionsByPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCloudRegionsByPlanQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCloudRegionsByPlanQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCloudRegionsByPlanQuery,
    GetCloudRegionsByPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCloudRegionsByPlanQuery,
    GetCloudRegionsByPlanQueryVariables
  >(GetCloudRegionsByPlanDocument, options);
}
export function useGetCloudRegionsByPlanLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCloudRegionsByPlanQuery,
    GetCloudRegionsByPlanQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCloudRegionsByPlanQuery,
    GetCloudRegionsByPlanQueryVariables
  >(GetCloudRegionsByPlanDocument, options);
}
export type GetCloudRegionsByPlanQueryHookResult = ReturnType<
  typeof useGetCloudRegionsByPlanQuery
>;
export type GetCloudRegionsByPlanLazyQueryHookResult = ReturnType<
  typeof useGetCloudRegionsByPlanLazyQuery
>;
export type GetCloudRegionsByPlanQueryResult = Apollo.QueryResult<
  GetCloudRegionsByPlanQuery,
  GetCloudRegionsByPlanQueryVariables
>;
export const GetProjectsListBaseDocument = gql`
  query getProjectsListBase($offset: Int!, $nameRegex: String, $limit: Int) {
    user_project_map_aggregate(
      where: { project: { name: { _iregex: $nameRegex } } }
    ) {
      aggregate {
        count
      }
    }
    user_project_map(
      where: { project: { name: { _iregex: $nameRegex } } }
      order_by: { project: { created_at: desc } }
      offset: $offset
      limit: $limit
    ) {
      project {
        ...ProjectsListBaseFields
      }
    }
    allLabels: label(limit: 50, order_by: { created_at: desc }) {
      ...LabelFields
    }
  }
  ${ProjectsListBaseFieldsFragmentDoc}
  ${LabelFieldsFragmentDoc}
`;

/**
 * __useGetProjectsListBaseQuery__
 *
 * To run a query within a React component, call `useGetProjectsListBaseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectsListBaseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectsListBaseQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      nameRegex: // value for 'nameRegex'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetProjectsListBaseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectsListBaseQuery,
    GetProjectsListBaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectsListBaseQuery,
    GetProjectsListBaseQueryVariables
  >(GetProjectsListBaseDocument, options);
}
export function useGetProjectsListBaseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectsListBaseQuery,
    GetProjectsListBaseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectsListBaseQuery,
    GetProjectsListBaseQueryVariables
  >(GetProjectsListBaseDocument, options);
}
export type GetProjectsListBaseQueryHookResult = ReturnType<
  typeof useGetProjectsListBaseQuery
>;
export type GetProjectsListBaseLazyQueryHookResult = ReturnType<
  typeof useGetProjectsListBaseLazyQuery
>;
export type GetProjectsListBaseQueryResult = Apollo.QueryResult<
  GetProjectsListBaseQuery,
  GetProjectsListBaseQueryVariables
>;
export const GetProjectCardInformationDocument = gql`
  query getProjectCardInformation($projectId: uuid!, $tenantId: uuid!) {
    getNumberOfConnectedSources(projectId: $projectId) {
      databases_connected
      project_id
      plan_name
      unsupported
    }
    getTenantEnv(tenantId: $tenantId) {
      hash
      envVars
    }
    project: projects_by_pk(id: $projectId) {
      id
      number_of_requests_this_month
      invoice_status
      invoice_attempt_count
      current_month_data_usage
    }
  }
`;

/**
 * __useGetProjectCardInformationQuery__
 *
 * To run a query within a React component, call `useGetProjectCardInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCardInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCardInformationQuery({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      tenantId: // value for 'tenantId'
 *   },
 * });
 */
export function useGetProjectCardInformationQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProjectCardInformationQuery,
    GetProjectCardInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetProjectCardInformationQuery,
    GetProjectCardInformationQueryVariables
  >(GetProjectCardInformationDocument, options);
}
export function useGetProjectCardInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProjectCardInformationQuery,
    GetProjectCardInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetProjectCardInformationQuery,
    GetProjectCardInformationQueryVariables
  >(GetProjectCardInformationDocument, options);
}
export type GetProjectCardInformationQueryHookResult = ReturnType<
  typeof useGetProjectCardInformationQuery
>;
export type GetProjectCardInformationLazyQueryHookResult = ReturnType<
  typeof useGetProjectCardInformationLazyQuery
>;
export type GetProjectCardInformationQueryResult = Apollo.QueryResult<
  GetProjectCardInformationQuery,
  GetProjectCardInformationQueryVariables
>;
export const ModelBasedProjectsDocument = gql`
  query ModelBasedProjects {
    projects(where: { plan_name: { _eq: "model_based_enterprise_cloud" } }) {
      id
      name
    }
  }
`;

/**
 * __useModelBasedProjectsQuery__
 *
 * To run a query within a React component, call `useModelBasedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelBasedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelBasedProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useModelBasedProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    ModelBasedProjectsQuery,
    ModelBasedProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ModelBasedProjectsQuery,
    ModelBasedProjectsQueryVariables
  >(ModelBasedProjectsDocument, options);
}
export function useModelBasedProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModelBasedProjectsQuery,
    ModelBasedProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ModelBasedProjectsQuery,
    ModelBasedProjectsQueryVariables
  >(ModelBasedProjectsDocument, options);
}
export type ModelBasedProjectsQueryHookResult = ReturnType<
  typeof useModelBasedProjectsQuery
>;
export type ModelBasedProjectsLazyQueryHookResult = ReturnType<
  typeof useModelBasedProjectsLazyQuery
>;
export type ModelBasedProjectsQueryResult = Apollo.QueryResult<
  ModelBasedProjectsQuery,
  ModelBasedProjectsQueryVariables
>;
export const GetSsoConfigDocument = gql`
  query getSSOConfig {
    saml_idp {
      id
      provider
      domain
      idp_metadata_url
      idp_metadata_xml
      disable_request_signing
    }
  }
`;

/**
 * __useGetSsoConfigQuery__
 *
 * To run a query within a React component, call `useGetSsoConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSsoConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSsoConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSsoConfigQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetSsoConfigQuery,
    GetSsoConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSsoConfigQuery, GetSsoConfigQueryVariables>(
    GetSsoConfigDocument,
    options
  );
}
export function useGetSsoConfigLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetSsoConfigQuery,
    GetSsoConfigQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSsoConfigQuery, GetSsoConfigQueryVariables>(
    GetSsoConfigDocument,
    options
  );
}
export type GetSsoConfigQueryHookResult = ReturnType<
  typeof useGetSsoConfigQuery
>;
export type GetSsoConfigLazyQueryHookResult = ReturnType<
  typeof useGetSsoConfigLazyQuery
>;
export type GetSsoConfigQueryResult = Apollo.QueryResult<
  GetSsoConfigQuery,
  GetSsoConfigQueryVariables
>;
export const UserInfoDocument = gql`
  query userInfo {
    users {
      ...UserFields
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(
    UserInfoDocument,
    options
  );
}
export function useUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    UserInfoQuery,
    UserInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(
    UserInfoDocument,
    options
  );
}
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<
  typeof useUserInfoLazyQuery
>;
export type UserInfoQueryResult = Apollo.QueryResult<
  UserInfoQuery,
  UserInfoQueryVariables
>;
export const GetPersonalAccessTokensDocument = gql`
  subscription getPersonalAccessTokens {
    user_personal_access_tokens(
      where: { is_active: { _eq: true } }
      order_by: { created_at: desc }
    ) {
      id
      description
      is_active
      created_at
      last_used
      last_used_ip_address
    }
  }
`;

/**
 * __useGetPersonalAccessTokensSubscription__
 *
 * To run a query within a React component, call `useGetPersonalAccessTokensSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetPersonalAccessTokensSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPersonalAccessTokensSubscription({
 *   variables: {
 *   },
 * });
 */
export function useGetPersonalAccessTokensSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    GetPersonalAccessTokensSubscription,
    GetPersonalAccessTokensSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetPersonalAccessTokensSubscription,
    GetPersonalAccessTokensSubscriptionVariables
  >(GetPersonalAccessTokensDocument, options);
}
export type GetPersonalAccessTokensSubscriptionHookResult = ReturnType<
  typeof useGetPersonalAccessTokensSubscription
>;
export type GetPersonalAccessTokensSubscriptionResult = Apollo.SubscriptionResult<
  GetPersonalAccessTokensSubscription
>;
export const GetEmailChangeRequestsDocument = gql`
  query getEmailChangeRequests {
    change_email_request(
      where: {
        _and: [{ status: { _eq: "active" } }, { verified: { _eq: false } }]
      }
    ) {
      id
      status
      new_email
      verified
      resend_count
      user {
        ...UserFields
      }
    }
  }
  ${UserFieldsFragmentDoc}
`;

/**
 * __useGetEmailChangeRequestsQuery__
 *
 * To run a query within a React component, call `useGetEmailChangeRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmailChangeRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmailChangeRequestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetEmailChangeRequestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmailChangeRequestsQuery,
    GetEmailChangeRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmailChangeRequestsQuery,
    GetEmailChangeRequestsQueryVariables
  >(GetEmailChangeRequestsDocument, options);
}
export function useGetEmailChangeRequestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmailChangeRequestsQuery,
    GetEmailChangeRequestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmailChangeRequestsQuery,
    GetEmailChangeRequestsQueryVariables
  >(GetEmailChangeRequestsDocument, options);
}
export type GetEmailChangeRequestsQueryHookResult = ReturnType<
  typeof useGetEmailChangeRequestsQuery
>;
export type GetEmailChangeRequestsLazyQueryHookResult = ReturnType<
  typeof useGetEmailChangeRequestsLazyQuery
>;
export type GetEmailChangeRequestsQueryResult = Apollo.QueryResult<
  GetEmailChangeRequestsQuery,
  GetEmailChangeRequestsQueryVariables
>;
export const FetchUserSupportTicketDetailsDocument = gql`
  query fetchUserSupportTicketDetails($limit: Int, $offset: Int) {
    fetchUserSupportTicketDetails(limit: $limit, offset: $offset) {
      id
      body
      status
      subject
      body
      created_at
      project_id
      project_name
      zendesk_link
      total_tickets
    }
  }
`;

/**
 * __useFetchUserSupportTicketDetailsQuery__
 *
 * To run a query within a React component, call `useFetchUserSupportTicketDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchUserSupportTicketDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchUserSupportTicketDetailsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useFetchUserSupportTicketDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    FetchUserSupportTicketDetailsQuery,
    FetchUserSupportTicketDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchUserSupportTicketDetailsQuery,
    FetchUserSupportTicketDetailsQueryVariables
  >(FetchUserSupportTicketDetailsDocument, options);
}
export function useFetchUserSupportTicketDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchUserSupportTicketDetailsQuery,
    FetchUserSupportTicketDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchUserSupportTicketDetailsQuery,
    FetchUserSupportTicketDetailsQueryVariables
  >(FetchUserSupportTicketDetailsDocument, options);
}
export type FetchUserSupportTicketDetailsQueryHookResult = ReturnType<
  typeof useFetchUserSupportTicketDetailsQuery
>;
export type FetchUserSupportTicketDetailsLazyQueryHookResult = ReturnType<
  typeof useFetchUserSupportTicketDetailsLazyQuery
>;
export type FetchUserSupportTicketDetailsQueryResult = Apollo.QueryResult<
  FetchUserSupportTicketDetailsQuery,
  FetchUserSupportTicketDetailsQueryVariables
>;
export const GetNeonUserInfoDocument = gql`
  query getNeonUserInfo {
    neonGetUserInfo {
      isAuthenticated
      email
    }
  }
`;

/**
 * __useGetNeonUserInfoQuery__
 *
 * To run a query within a React component, call `useGetNeonUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNeonUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNeonUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNeonUserInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetNeonUserInfoQuery,
    GetNeonUserInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNeonUserInfoQuery, GetNeonUserInfoQueryVariables>(
    GetNeonUserInfoDocument,
    options
  );
}
export function useGetNeonUserInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetNeonUserInfoQuery,
    GetNeonUserInfoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetNeonUserInfoQuery,
    GetNeonUserInfoQueryVariables
  >(GetNeonUserInfoDocument, options);
}
export type GetNeonUserInfoQueryHookResult = ReturnType<
  typeof useGetNeonUserInfoQuery
>;
export type GetNeonUserInfoLazyQueryHookResult = ReturnType<
  typeof useGetNeonUserInfoLazyQuery
>;
export type GetNeonUserInfoQueryResult = Apollo.QueryResult<
  GetNeonUserInfoQuery,
  GetNeonUserInfoQueryVariables
>;
export const GetUserActionActivityDocument = gql`
  query GetUserActionActivity($user_id: uuid!, $action: user_action!) {
    user_activity(
      where: { user_id: { _eq: $user_id }, action: { _eq: $action } }
    ) {
      metadata
    }
  }
`;

/**
 * __useGetUserActionActivityQuery__
 *
 * To run a query within a React component, call `useGetUserActionActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserActionActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserActionActivityQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useGetUserActionActivityQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUserActionActivityQuery,
    GetUserActionActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetUserActionActivityQuery,
    GetUserActionActivityQueryVariables
  >(GetUserActionActivityDocument, options);
}
export function useGetUserActionActivityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserActionActivityQuery,
    GetUserActionActivityQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserActionActivityQuery,
    GetUserActionActivityQueryVariables
  >(GetUserActionActivityDocument, options);
}
export type GetUserActionActivityQueryHookResult = ReturnType<
  typeof useGetUserActionActivityQuery
>;
export type GetUserActionActivityLazyQueryHookResult = ReturnType<
  typeof useGetUserActionActivityLazyQuery
>;
export type GetUserActionActivityQueryResult = Apollo.QueryResult<
  GetUserActionActivityQuery,
  GetUserActionActivityQueryVariables
>;
export const ModelBasedCommitmentsDocument = gql`
  query ModelBasedCommitments($userId: uuid!) {
    model_based_enterprise_cloud_commitments(
      where: { user_id: { _eq: $userId } }
    ) {
      support_plan
      commitment_start
      commitment_end
      yearly_commitment
      logical_model_unit_price
      table_unit_price
      collection_unit_price
    }
  }
`;

/**
 * __useModelBasedCommitmentsQuery__
 *
 * To run a query within a React component, call `useModelBasedCommitmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelBasedCommitmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelBasedCommitmentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useModelBasedCommitmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    ModelBasedCommitmentsQuery,
    ModelBasedCommitmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    ModelBasedCommitmentsQuery,
    ModelBasedCommitmentsQueryVariables
  >(ModelBasedCommitmentsDocument, options);
}
export function useModelBasedCommitmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ModelBasedCommitmentsQuery,
    ModelBasedCommitmentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    ModelBasedCommitmentsQuery,
    ModelBasedCommitmentsQueryVariables
  >(ModelBasedCommitmentsDocument, options);
}
export type ModelBasedCommitmentsQueryHookResult = ReturnType<
  typeof useModelBasedCommitmentsQuery
>;
export type ModelBasedCommitmentsLazyQueryHookResult = ReturnType<
  typeof useModelBasedCommitmentsLazyQuery
>;
export type ModelBasedCommitmentsQueryResult = Apollo.QueryResult<
  ModelBasedCommitmentsQuery,
  ModelBasedCommitmentsQueryVariables
>;
export const GetVercelConfigurationStatusSubscriptionDocument = gql`
  subscription GetVercelConfigurationStatusSubscription(
    $configurationId: String!
  ) {
    vercel_integration(where: { configuration_id: { _eq: $configurationId } }) {
      status
      vercel_integration_connections(
        where: {
          _and: [
            { status: { _neq: "disconnected" } }
            { status: { _neq: "outofscope" } }
            { status: { _neq: "failed" } }
          ]
        }
      ) {
        status
        hasura_cloud_project_id
        vercel_project_id
        project {
          id
          name
        }
      }
    }
  }
`;

/**
 * __useGetVercelConfigurationStatusSubscriptionSubscription__
 *
 * To run a query within a React component, call `useGetVercelConfigurationStatusSubscriptionSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetVercelConfigurationStatusSubscriptionSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVercelConfigurationStatusSubscriptionSubscription({
 *   variables: {
 *      configurationId: // value for 'configurationId'
 *   },
 * });
 */
export function useGetVercelConfigurationStatusSubscriptionSubscription(
  baseOptions: Apollo.SubscriptionHookOptions<
    GetVercelConfigurationStatusSubscriptionSubscription,
    GetVercelConfigurationStatusSubscriptionSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    GetVercelConfigurationStatusSubscriptionSubscription,
    GetVercelConfigurationStatusSubscriptionSubscriptionVariables
  >(GetVercelConfigurationStatusSubscriptionDocument, options);
}
export type GetVercelConfigurationStatusSubscriptionSubscriptionHookResult = ReturnType<
  typeof useGetVercelConfigurationStatusSubscriptionSubscription
>;
export type GetVercelConfigurationStatusSubscriptionSubscriptionResult = Apollo.SubscriptionResult<
  GetVercelConfigurationStatusSubscriptionSubscription
>;
export const VercelProjectsDocument = gql`
  query VercelProjects($configurationId: String!, $teamId: String) {
    vercelGetProjects(configuration_id: $configurationId, team_id: $teamId) {
      vercelProjects {
        projectId
        projectName
      }
    }
  }
`;

/**
 * __useVercelProjectsQuery__
 *
 * To run a query within a React component, call `useVercelProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVercelProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVercelProjectsQuery({
 *   variables: {
 *      configurationId: // value for 'configurationId'
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useVercelProjectsQuery(
  baseOptions: Apollo.QueryHookOptions<
    VercelProjectsQuery,
    VercelProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<VercelProjectsQuery, VercelProjectsQueryVariables>(
    VercelProjectsDocument,
    options
  );
}
export function useVercelProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VercelProjectsQuery,
    VercelProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<VercelProjectsQuery, VercelProjectsQueryVariables>(
    VercelProjectsDocument,
    options
  );
}
export type VercelProjectsQueryHookResult = ReturnType<
  typeof useVercelProjectsQuery
>;
export type VercelProjectsLazyQueryHookResult = ReturnType<
  typeof useVercelProjectsLazyQuery
>;
export type VercelProjectsQueryResult = Apollo.QueryResult<
  VercelProjectsQuery,
  VercelProjectsQueryVariables
>;
export const GetVercelConfigurationStatusDocument = gql`
  query GetVercelConfigurationStatus($configurationId: String!) {
    vercel_integration(where: { configuration_id: { _eq: $configurationId } }) {
      status
      vercel_integration_connections(where: { status: { _eq: "connected" } }) {
        id
        hasura_cloud_project_id
        vercel_project_id
        status
      }
    }
  }
`;

/**
 * __useGetVercelConfigurationStatusQuery__
 *
 * To run a query within a React component, call `useGetVercelConfigurationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVercelConfigurationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVercelConfigurationStatusQuery({
 *   variables: {
 *      configurationId: // value for 'configurationId'
 *   },
 * });
 */
export function useGetVercelConfigurationStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVercelConfigurationStatusQuery,
    GetVercelConfigurationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetVercelConfigurationStatusQuery,
    GetVercelConfigurationStatusQueryVariables
  >(GetVercelConfigurationStatusDocument, options);
}
export function useGetVercelConfigurationStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVercelConfigurationStatusQuery,
    GetVercelConfigurationStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetVercelConfigurationStatusQuery,
    GetVercelConfigurationStatusQueryVariables
  >(GetVercelConfigurationStatusDocument, options);
}
export type GetVercelConfigurationStatusQueryHookResult = ReturnType<
  typeof useGetVercelConfigurationStatusQuery
>;
export type GetVercelConfigurationStatusLazyQueryHookResult = ReturnType<
  typeof useGetVercelConfigurationStatusLazyQuery
>;
export type GetVercelConfigurationStatusQueryResult = Apollo.QueryResult<
  GetVercelConfigurationStatusQuery,
  GetVercelConfigurationStatusQueryVariables
>;
export const GetHasuraProjectsDocument = gql`
  query GetHasuraProjects {
    projects(where: { plan_name: { _neq: "pro" } }) {
      id
      name
    }
  }
`;

/**
 * __useGetHasuraProjectsQuery__
 *
 * To run a query within a React component, call `useGetHasuraProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHasuraProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHasuraProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetHasuraProjectsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHasuraProjectsQuery,
    GetHasuraProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetHasuraProjectsQuery,
    GetHasuraProjectsQueryVariables
  >(GetHasuraProjectsDocument, options);
}
export function useGetHasuraProjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHasuraProjectsQuery,
    GetHasuraProjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetHasuraProjectsQuery,
    GetHasuraProjectsQueryVariables
  >(GetHasuraProjectsDocument, options);
}
export type GetHasuraProjectsQueryHookResult = ReturnType<
  typeof useGetHasuraProjectsQuery
>;
export type GetHasuraProjectsLazyQueryHookResult = ReturnType<
  typeof useGetHasuraProjectsLazyQuery
>;
export type GetHasuraProjectsQueryResult = Apollo.QueryResult<
  GetHasuraProjectsQuery,
  GetHasuraProjectsQueryVariables
>;
export const WatchDedicatedVpCsDocument = gql`
  subscription watchDedicatedVPCs {
    dedicated_vpc(order_by: [{ created_at: desc }]) {
      ...DedicateVPCFields
      vpc_peerings {
        ...VPCPeeringFields
      }
      tenants_aggregate {
        aggregate {
          count
        }
      }
    }
  }
  ${DedicateVpcFieldsFragmentDoc}
  ${VpcPeeringFieldsFragmentDoc}
`;

/**
 * __useWatchDedicatedVpCsSubscription__
 *
 * To run a query within a React component, call `useWatchDedicatedVpCsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useWatchDedicatedVpCsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWatchDedicatedVpCsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useWatchDedicatedVpCsSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    WatchDedicatedVpCsSubscription,
    WatchDedicatedVpCsSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    WatchDedicatedVpCsSubscription,
    WatchDedicatedVpCsSubscriptionVariables
  >(WatchDedicatedVpCsDocument, options);
}
export type WatchDedicatedVpCsSubscriptionHookResult = ReturnType<
  typeof useWatchDedicatedVpCsSubscription
>;
export type WatchDedicatedVpCsSubscriptionResult = Apollo.SubscriptionResult<
  WatchDedicatedVpCsSubscription
>;
export const GetAwsIdDocument = gql`
  query getAWSId {
    cloud_metadata {
      aws_account_id
    }
  }
`;

/**
 * __useGetAwsIdQuery__
 *
 * To run a query within a React component, call `useGetAwsIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwsIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwsIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAwsIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAwsIdQuery, GetAwsIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAwsIdQuery, GetAwsIdQueryVariables>(
    GetAwsIdDocument,
    options
  );
}
export function useGetAwsIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAwsIdQuery,
    GetAwsIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAwsIdQuery, GetAwsIdQueryVariables>(
    GetAwsIdDocument,
    options
  );
}
export type GetAwsIdQueryHookResult = ReturnType<typeof useGetAwsIdQuery>;
export type GetAwsIdLazyQueryHookResult = ReturnType<
  typeof useGetAwsIdLazyQuery
>;
export type GetAwsIdQueryResult = Apollo.QueryResult<
  GetAwsIdQuery,
  GetAwsIdQueryVariables
>;
export const GetAwsRegionsDocument = gql`
  query getAWSRegions {
    region_v2(where: { is_active: { _eq: true }, cloud: { _eq: "aws" } }) {
      region {
        ...RegionFields
      }
    }
  }
  ${RegionFieldsFragmentDoc}
`;

/**
 * __useGetAwsRegionsQuery__
 *
 * To run a query within a React component, call `useGetAwsRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwsRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwsRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAwsRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAwsRegionsQuery,
    GetAwsRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAwsRegionsQuery, GetAwsRegionsQueryVariables>(
    GetAwsRegionsDocument,
    options
  );
}
export function useGetAwsRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAwsRegionsQuery,
    GetAwsRegionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAwsRegionsQuery, GetAwsRegionsQueryVariables>(
    GetAwsRegionsDocument,
    options
  );
}
export type GetAwsRegionsQueryHookResult = ReturnType<
  typeof useGetAwsRegionsQuery
>;
export type GetAwsRegionsLazyQueryHookResult = ReturnType<
  typeof useGetAwsRegionsLazyQuery
>;
export type GetAwsRegionsQueryResult = Apollo.QueryResult<
  GetAwsRegionsQuery,
  GetAwsRegionsQueryVariables
>;
export const GetUserVpcLimitDocument = gql`
  query getUserVPCLimit($userId: uuid) {
    user_vpc_policy(where: { user: { _eq: $userId } }) {
      vpc_limit
      plan_name
    }
  }
`;

/**
 * __useGetUserVpcLimitQuery__
 *
 * To run a query within a React component, call `useGetUserVpcLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserVpcLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserVpcLimitQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserVpcLimitQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetUserVpcLimitQuery,
    GetUserVpcLimitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserVpcLimitQuery, GetUserVpcLimitQueryVariables>(
    GetUserVpcLimitDocument,
    options
  );
}
export function useGetUserVpcLimitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserVpcLimitQuery,
    GetUserVpcLimitQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetUserVpcLimitQuery,
    GetUserVpcLimitQueryVariables
  >(GetUserVpcLimitDocument, options);
}
export type GetUserVpcLimitQueryHookResult = ReturnType<
  typeof useGetUserVpcLimitQuery
>;
export type GetUserVpcLimitLazyQueryHookResult = ReturnType<
  typeof useGetUserVpcLimitLazyQuery
>;
export type GetUserVpcLimitQueryResult = Apollo.QueryResult<
  GetUserVpcLimitQuery,
  GetUserVpcLimitQueryVariables
>;
export const GetVpcCountDocument = gql`
  query getVPCCount($userId: uuid) {
    dedicated_vpc_aggregate(where: { owner_id: { _eq: $userId } }) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useGetVpcCountQuery__
 *
 * To run a query within a React component, call `useGetVpcCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVpcCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVpcCountQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetVpcCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetVpcCountQuery,
    GetVpcCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVpcCountQuery, GetVpcCountQueryVariables>(
    GetVpcCountDocument,
    options
  );
}
export function useGetVpcCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVpcCountQuery,
    GetVpcCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVpcCountQuery, GetVpcCountQueryVariables>(
    GetVpcCountDocument,
    options
  );
}
export type GetVpcCountQueryHookResult = ReturnType<typeof useGetVpcCountQuery>;
export type GetVpcCountLazyQueryHookResult = ReturnType<
  typeof useGetVpcCountLazyQuery
>;
export type GetVpcCountQueryResult = Apollo.QueryResult<
  GetVpcCountQuery,
  GetVpcCountQueryVariables
>;
export const GetAwsVpcPeeringDetailsDocument = gql`
  query getAwsVpcPeeringDetails($id: uuid, $dedicatedVpcId: uuid) {
    vpc_peering(
      where: { id: { _eq: $id }, dedicated_vpc_id: { _eq: $dedicatedVpcId } }
    ) {
      id
      aws_customer_account_id
      aws_customer_vpc_additional_cidrs
      aws_customer_vpc_cidr
      aws_customer_vpc_id
      aws_customer_vpc_region
      dedicated_vpc_id
      name
    }
  }
`;

/**
 * __useGetAwsVpcPeeringDetailsQuery__
 *
 * To run a query within a React component, call `useGetAwsVpcPeeringDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAwsVpcPeeringDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAwsVpcPeeringDetailsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      dedicatedVpcId: // value for 'dedicatedVpcId'
 *   },
 * });
 */
export function useGetAwsVpcPeeringDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAwsVpcPeeringDetailsQuery,
    GetAwsVpcPeeringDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAwsVpcPeeringDetailsQuery,
    GetAwsVpcPeeringDetailsQueryVariables
  >(GetAwsVpcPeeringDetailsDocument, options);
}
export function useGetAwsVpcPeeringDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAwsVpcPeeringDetailsQuery,
    GetAwsVpcPeeringDetailsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAwsVpcPeeringDetailsQuery,
    GetAwsVpcPeeringDetailsQueryVariables
  >(GetAwsVpcPeeringDetailsDocument, options);
}
export type GetAwsVpcPeeringDetailsQueryHookResult = ReturnType<
  typeof useGetAwsVpcPeeringDetailsQuery
>;
export type GetAwsVpcPeeringDetailsLazyQueryHookResult = ReturnType<
  typeof useGetAwsVpcPeeringDetailsLazyQuery
>;
export type GetAwsVpcPeeringDetailsQueryResult = Apollo.QueryResult<
  GetAwsVpcPeeringDetailsQuery,
  GetAwsVpcPeeringDetailsQueryVariables
>;
export const InviteVpcCollaboratorDocument = gql`
  mutation inviteVPCCollaborator(
    $invitee_email: String!
    $access_level: VPCCollaboratorAccessLevel!
    $vpc_id: uuid!
  ) {
    inviteVPCCollaborator(
      vpc_id: $vpc_id
      invitee_email: $invitee_email
      access_level: $access_level
    ) {
      message
      status
    }
  }
`;
export type InviteVpcCollaboratorMutationFn = Apollo.MutationFunction<
  InviteVpcCollaboratorMutation,
  InviteVpcCollaboratorMutationVariables
>;

/**
 * __useInviteVpcCollaboratorMutation__
 *
 * To run a mutation, you first call `useInviteVpcCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteVpcCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteVpcCollaboratorMutation, { data, loading, error }] = useInviteVpcCollaboratorMutation({
 *   variables: {
 *      invitee_email: // value for 'invitee_email'
 *      access_level: // value for 'access_level'
 *      vpc_id: // value for 'vpc_id'
 *   },
 * });
 */
export function useInviteVpcCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InviteVpcCollaboratorMutation,
    InviteVpcCollaboratorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    InviteVpcCollaboratorMutation,
    InviteVpcCollaboratorMutationVariables
  >(InviteVpcCollaboratorDocument, options);
}
export type InviteVpcCollaboratorMutationHookResult = ReturnType<
  typeof useInviteVpcCollaboratorMutation
>;
export type InviteVpcCollaboratorMutationResult = Apollo.MutationResult<
  InviteVpcCollaboratorMutation
>;
export type InviteVpcCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  InviteVpcCollaboratorMutation,
  InviteVpcCollaboratorMutationVariables
>;
export const FetchVpcPendingInvitationsDocument = gql`
  query fetchVPCPendingInvitations(
    $collaborator_email: citext!
    $status: String!
  ) {
    vpc_collaborator_invitations(
      where: {
        collaborator_email: { _eq: $collaborator_email }
        status: { _eq: $status }
      }
    ) {
      accepted_at
      collaborator_email
      dedicated_vpc {
        id
        name
        owner_id
        region
        region_info {
          cloud
          name
        }
      }
      id
      invited_by
      privilege_slug
      status
      vpc_id
      invited_by_user {
        email
      }
      vpc_name
      invited_by_email
    }
  }
`;

/**
 * __useFetchVpcPendingInvitationsQuery__
 *
 * To run a query within a React component, call `useFetchVpcPendingInvitationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchVpcPendingInvitationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchVpcPendingInvitationsQuery({
 *   variables: {
 *      collaborator_email: // value for 'collaborator_email'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useFetchVpcPendingInvitationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    FetchVpcPendingInvitationsQuery,
    FetchVpcPendingInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    FetchVpcPendingInvitationsQuery,
    FetchVpcPendingInvitationsQueryVariables
  >(FetchVpcPendingInvitationsDocument, options);
}
export function useFetchVpcPendingInvitationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FetchVpcPendingInvitationsQuery,
    FetchVpcPendingInvitationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    FetchVpcPendingInvitationsQuery,
    FetchVpcPendingInvitationsQueryVariables
  >(FetchVpcPendingInvitationsDocument, options);
}
export type FetchVpcPendingInvitationsQueryHookResult = ReturnType<
  typeof useFetchVpcPendingInvitationsQuery
>;
export type FetchVpcPendingInvitationsLazyQueryHookResult = ReturnType<
  typeof useFetchVpcPendingInvitationsLazyQuery
>;
export type FetchVpcPendingInvitationsQueryResult = Apollo.QueryResult<
  FetchVpcPendingInvitationsQuery,
  FetchVpcPendingInvitationsQueryVariables
>;
export const AcceptVpcInviteDocument = gql`
  mutation acceptVPCInvite($invitation_id: uuid!) {
    acceptVPCCollaboratorInvitation(invitation_id: $invitation_id) {
      message
      status
    }
  }
`;
export type AcceptVpcInviteMutationFn = Apollo.MutationFunction<
  AcceptVpcInviteMutation,
  AcceptVpcInviteMutationVariables
>;

/**
 * __useAcceptVpcInviteMutation__
 *
 * To run a mutation, you first call `useAcceptVpcInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptVpcInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptVpcInviteMutation, { data, loading, error }] = useAcceptVpcInviteMutation({
 *   variables: {
 *      invitation_id: // value for 'invitation_id'
 *   },
 * });
 */
export function useAcceptVpcInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AcceptVpcInviteMutation,
    AcceptVpcInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AcceptVpcInviteMutation,
    AcceptVpcInviteMutationVariables
  >(AcceptVpcInviteDocument, options);
}
export type AcceptVpcInviteMutationHookResult = ReturnType<
  typeof useAcceptVpcInviteMutation
>;
export type AcceptVpcInviteMutationResult = Apollo.MutationResult<
  AcceptVpcInviteMutation
>;
export type AcceptVpcInviteMutationOptions = Apollo.BaseMutationOptions<
  AcceptVpcInviteMutation,
  AcceptVpcInviteMutationVariables
>;
export const DeclineVpcInviteDocument = gql`
  mutation declineVPCInvite($invitation_id: uuid!) {
    declineVPCCollaboratorInvitation(invitation_id: $invitation_id) {
      message
      status
    }
  }
`;
export type DeclineVpcInviteMutationFn = Apollo.MutationFunction<
  DeclineVpcInviteMutation,
  DeclineVpcInviteMutationVariables
>;

/**
 * __useDeclineVpcInviteMutation__
 *
 * To run a mutation, you first call `useDeclineVpcInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineVpcInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineVpcInviteMutation, { data, loading, error }] = useDeclineVpcInviteMutation({
 *   variables: {
 *      invitation_id: // value for 'invitation_id'
 *   },
 * });
 */
export function useDeclineVpcInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeclineVpcInviteMutation,
    DeclineVpcInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeclineVpcInviteMutation,
    DeclineVpcInviteMutationVariables
  >(DeclineVpcInviteDocument, options);
}
export type DeclineVpcInviteMutationHookResult = ReturnType<
  typeof useDeclineVpcInviteMutation
>;
export type DeclineVpcInviteMutationResult = Apollo.MutationResult<
  DeclineVpcInviteMutation
>;
export type DeclineVpcInviteMutationOptions = Apollo.BaseMutationOptions<
  DeclineVpcInviteMutation,
  DeclineVpcInviteMutationVariables
>;
export const RevokeVpcInviteDocument = gql`
  mutation revokeVPCInvite($invitation_id: uuid!) {
    revokeVPCCollaboratorInvitation(invitation_id: $invitation_id) {
      message
      status
    }
  }
`;
export type RevokeVpcInviteMutationFn = Apollo.MutationFunction<
  RevokeVpcInviteMutation,
  RevokeVpcInviteMutationVariables
>;

/**
 * __useRevokeVpcInviteMutation__
 *
 * To run a mutation, you first call `useRevokeVpcInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeVpcInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeVpcInviteMutation, { data, loading, error }] = useRevokeVpcInviteMutation({
 *   variables: {
 *      invitation_id: // value for 'invitation_id'
 *   },
 * });
 */
export function useRevokeVpcInviteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RevokeVpcInviteMutation,
    RevokeVpcInviteMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RevokeVpcInviteMutation,
    RevokeVpcInviteMutationVariables
  >(RevokeVpcInviteDocument, options);
}
export type RevokeVpcInviteMutationHookResult = ReturnType<
  typeof useRevokeVpcInviteMutation
>;
export type RevokeVpcInviteMutationResult = Apollo.MutationResult<
  RevokeVpcInviteMutation
>;
export type RevokeVpcInviteMutationOptions = Apollo.BaseMutationOptions<
  RevokeVpcInviteMutation,
  RevokeVpcInviteMutationVariables
>;
export const DeleteVpcCollaboratorDocument = gql`
  mutation deleteVPCCollaborator(
    $collaborator_id: uuid!
    $vpc_id: uuid!
    $remove_collaborator_from_projects_list: [uuid!]!
  ) {
    removeVPCCollaborator(
      collaborator_id: $collaborator_id
      vpc_id: $vpc_id
      remove_collaborator_from_projects_list: $remove_collaborator_from_projects_list
    ) {
      message
      status
    }
  }
`;
export type DeleteVpcCollaboratorMutationFn = Apollo.MutationFunction<
  DeleteVpcCollaboratorMutation,
  DeleteVpcCollaboratorMutationVariables
>;

/**
 * __useDeleteVpcCollaboratorMutation__
 *
 * To run a mutation, you first call `useDeleteVpcCollaboratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVpcCollaboratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVpcCollaboratorMutation, { data, loading, error }] = useDeleteVpcCollaboratorMutation({
 *   variables: {
 *      collaborator_id: // value for 'collaborator_id'
 *      vpc_id: // value for 'vpc_id'
 *      remove_collaborator_from_projects_list: // value for 'remove_collaborator_from_projects_list'
 *   },
 * });
 */
export function useDeleteVpcCollaboratorMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteVpcCollaboratorMutation,
    DeleteVpcCollaboratorMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteVpcCollaboratorMutation,
    DeleteVpcCollaboratorMutationVariables
  >(DeleteVpcCollaboratorDocument, options);
}
export type DeleteVpcCollaboratorMutationHookResult = ReturnType<
  typeof useDeleteVpcCollaboratorMutation
>;
export type DeleteVpcCollaboratorMutationResult = Apollo.MutationResult<
  DeleteVpcCollaboratorMutation
>;
export type DeleteVpcCollaboratorMutationOptions = Apollo.BaseMutationOptions<
  DeleteVpcCollaboratorMutation,
  DeleteVpcCollaboratorMutationVariables
>;
export const GetVpcOwnerDocument = gql`
  query getVPCOwner($domain: String!) {
    dedicated_vpc_owner(where: { domain: { _eq: $domain } }) {
      domain
      vpc_owner_email
    }
  }
`;

/**
 * __useGetVpcOwnerQuery__
 *
 * To run a query within a React component, call `useGetVpcOwnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVpcOwnerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVpcOwnerQuery({
 *   variables: {
 *      domain: // value for 'domain'
 *   },
 * });
 */
export function useGetVpcOwnerQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetVpcOwnerQuery,
    GetVpcOwnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetVpcOwnerQuery, GetVpcOwnerQueryVariables>(
    GetVpcOwnerDocument,
    options
  );
}
export function useGetVpcOwnerLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetVpcOwnerQuery,
    GetVpcOwnerQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetVpcOwnerQuery, GetVpcOwnerQueryVariables>(
    GetVpcOwnerDocument,
    options
  );
}
export type GetVpcOwnerQueryHookResult = ReturnType<typeof useGetVpcOwnerQuery>;
export type GetVpcOwnerLazyQueryHookResult = ReturnType<
  typeof useGetVpcOwnerLazyQuery
>;
export type GetVpcOwnerQueryResult = Apollo.QueryResult<
  GetVpcOwnerQuery,
  GetVpcOwnerQueryVariables
>;
