import React from 'react';
import { Github, ChevronRight, Google, Loader } from '../images';
import { twjoin } from '../utils';

type ButtonProps = Omit<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  'className'
>;

interface PrimaryButtonProps extends ButtonProps {
  variant: 'github' | 'google' | 'primary';
  title?: string;
  loading?: boolean;
}

const PrimaryButton = (props: PrimaryButtonProps): JSX.Element => {
  const buttonTitle = props?.title ?? 'continue';

  if (props.variant === 'github') {
    return (
      <button
        className="w-full h-12 rounded-[100px] bg-v3-button-github text-white font-inter text-[14px] font-medium hover:cursor-pointer"
        {...props}
      >
        <span className="flex items-center justify-center">
          <Github className="m-1" />
          Github
          <ChevronRight className="w-[14px] h-[15px] mt-[2px]" />
        </span>
      </button>
    );
  }

  if (props.variant === 'google') {
    return (
      <button
        className="w-full h-12 rounded-[100px] bg-v3-button-google text-bg-v3-button-github font-inter text-[14px] font-medium hover:cursor-pointer"
        {...props}
      >
        <span className="flex items-center justify-center">
          <Google className="m-1 w-[22px] h-[22px]" />
          Google
          <ChevronRight className="w-[14px] h-[15px] mt-[2px]" color="#000" />
        </span>
      </button>
    );
  }

  return (
    <button
      className={twjoin(
        'capitalize w-full h-12 rounded-[100px] text-bg-v3-button-github font-inter text-[14px] font-medium text-white',
        props.disabled
          ? ' hover:cursor-not-allowed bg-v3-button-disabledBg text-v3-button-textDisabled'
          : 'bg-v3-button-default hover:cursor-pointer'
      )}
      {...props}
    >
      <span className="flex items-center justify-center">
        {props.loading ? <Loader /> : null}
        {buttonTitle}
        <ChevronRight
          className="w-[14px] h-[15px] mt-[2px]"
          fill={props.disabled ? '#9DA4AE' : '#fff'}
        />
      </span>
    </button>
  );
};

export default PrimaryButton;
