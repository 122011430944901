import React from 'react';
import { ErroredInputIcon } from '../images';
import { twjoin } from '../utils';

type InputElementProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  'className'
>;

interface InputProps extends InputElementProps {
  isErrored?: boolean;
  elementStyles?: string;
  // NOTE: use either of these components
  // and both at the same time [errorText, errorComponent]
  errorText?: string;
  errorComponent?: React.ReactNode;
  child?: React.ReactNode;
}

const Input = (props: InputProps): JSX.Element => {
  return (
    <>
      <div
        className={twjoin(
          'flex flex-row px-4 py-3 border border-v3-input-border rounded-[100px]',
          props.isErrored ? '!border-v3-input-error-border' : '',
          props?.elementStyles ?? ''
        )}
      >
        <input
          className="outline-none w-full font-inter font-medium text-[14px] leading-5"
          {...props}
        />
        {props.isErrored ? (
          <ErroredInputIcon
            className={props?.placeholder === 'Password' ? 'mr-8' : 'ml-1'}
          />
        ) : null}
      </div>
      {props?.child ?? null}
      {props.isErrored && props.errorText ? (
        <span className="font-inter text-[14px] mt-2 font-normal ml-2 flex text-center">
          {props.errorText ?? 'Please enter a valid input'}
        </span>
      ) : null}
      {props.isErrored && props.errorComponent ? props.errorComponent : null}
    </>
  );
};

export default Input;
