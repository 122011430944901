import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { authEndpoints } from '../../../utils/constants';
import { PrimaryButton } from '../components';
import { getRedir } from '../utils';
import { paths } from '../../../utils/routeUtils';

interface SocialSignInProps {
  ssoError: boolean;
}

const ErrorMessage = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <div className="text-center font-inter mt-4">
      <div
        className="p-2 bg-red-800 items-center text-red-100 leading-none flex lg:inline-flex"
        role="error-box"
      >
        <span className="flex rounded-full bg-red-500 uppercase px-2 py-1 text-xs font-bold mr-3">
          error
        </span>
        <span className="font-semibold mr-2 text-left flex-auto leading-relaxed text-sm">
          {children}
        </span>
      </div>
    </div>
  );
};

const SocialSignIn = (props: SocialSignInProps): JSX.Element => {
  const { search } = useLocation();
  const redir = getRedir(search);

  const { githubOAuthUrl, googleOAuthUrl } = authEndpoints;

  const oauthUrl = (oauth: string) => {
    const params = new URLSearchParams(window.location.search);
    const redirectUrl = params.get('redirect_url');

    if (
      redirectUrl &&
      (redirectUrl.includes('console.hasura.io') ||
        redirectUrl.includes('console.arusah.com'))
    ) {
      return `${oauth}?redir=${redir}?platform=hasura_v3_console`;
    }

    return `${oauth}?redir=${redir}`;
  };

  return (
    <div className="flex flex-col">
      <PrimaryButton
        variant="github"
        style={{ marginTop: '20px', marginBottom: '8px' }}
        onClick={() => {
          window.location.replace(oauthUrl(githubOAuthUrl));
        }}
      />
      <PrimaryButton
        variant="google"
        style={{ marginTop: '8px', marginBottom: '8px' }}
        onClick={() => {
          window.location.replace(oauthUrl(googleOAuthUrl));
        }}
      />
      {props.ssoError ? (
        <ErrorMessage>
          OAuth2 login failed because your organization probably has{' '}
          <Link
            to={{
              pathname: paths.v3.login.sso(),
              search,
            }}
          >
            <u>SSO/SAML</u>
          </Link>{' '}
          enabled. For any other issues, please reach out to our support team
          for further assistance.
        </ErrorMessage>
      ) : null}
    </div>
  );
};

export default SocialSignIn;
