import {
  Cloud_Enum,
  Exact,
  Feature_Enum,
  FetchProjectAndPrivilegesCollaboratorsQuery,
  GetProjectDetailsQuery,
  Maybe,
  Plan,
  RealtimeTenantStatusSubscription,
  UserInfoQuery,
} from './graphqlTypes';

export * from './graphqlTypes';

export type TenantHealthStatus = {
  is_active: Maybe<boolean>;
  active_workers: Maybe<number>;
  total_workers: Maybe<number>;
  last_updated_at: Maybe<string>;
  errors: Maybe<
    {
      config: Maybe<string>;
      health: Maybe<string>;
    }[]
  >;
};

interface TenantHealthMetadata
  extends NonNullable<RealtimeTenantStatusSubscription['tenant']> {
  health_status: TenantHealthStatus;
}

export type ProjectHealthMetadata = Maybe<TenantHealthMetadata>;

export type Nullable<T> = T | null;

type SessionVariable =
  | 'X-Hasura-Role'
  | 'X-Hasura-User-Email'
  | 'X-Hasura-User-Id';

type SessionVariables = Record<SessionVariable, string>;

export interface UserInfo extends SessionVariables {
  status: string;
}

export type CollaboratorPrivilege =
  | 'graphql_admin'
  | 'view_metrics'
  | 'admin'
  | 'billing_owner';

export type Collaborator = NonNullable<
  FetchProjectAndPrivilegesCollaboratorsQuery['projectCollaboratorsMetadata']
>['collaborators'][0];

export type NonNullable<T> = Exclude<T, null | undefined>;

export type ProjectDetails = NonNullable<GetProjectDetailsQuery['project']>;

export type ProjectRole = 'owner' | 'admin' | 'user';

export type VPCRole = 'owner' | 'manager' | 'viewer';

export type DNSValidation = 'pending' | 'succeeded' | 'failed';

export type ProjectHealth =
  | 'success'
  | 'config-error'
  | 'warn'
  | 'initialising'
  | 'updating'
  | 'not-found'
  | 'conn-error'
  | 'changing-region'
  | 'changing-plan'
  | 'provisioning'
  | 'verifying-healthcheck'
  | 'deactivated'
  | 'suspended'
  | 'waking-up'
  | 'unknown';

export type User = UserInfoQuery['users'][0];

export type SelectOnChange = React.ChangeEvent<HTMLSelectElement>;
export type TextOnChange = React.ChangeEvent<HTMLInputElement>;
export type ButtonOnClick = React.MouseEvent<HTMLButtonElement>;
export type DivOnClick = React.MouseEvent<HTMLDivElement>;
export type CheckboxOnChange = React.BaseSyntheticEvent;

export const TENANT_ENDPOINT_DEFAULT = 'default';
export const TENANT_ENDPOINT_CUSTOM = 'custom';

export type TenantEndpointType =
  | typeof TENANT_ENDPOINT_DEFAULT
  | typeof TENANT_ENDPOINT_CUSTOM;

export type CollaboratorState = {
  email: string;
  role: string;
  privileges: Record<string, boolean | undefined>;
};
export type FeatureAccessMap = Partial<
  Record<Feature_Enum, boolean | undefined>
>;

export type VPCCollaboratorState = Exact<{
  invitee_email: String;
  access_level: String;
  projects: {
    id: number;
    name: string;
    role: string;
    permissions: string[];
    selected: boolean;
  }[];
}>;

export type UserFormServiceType =
  | 'ADD_COLLABORATOR'
  | 'MODIFY_COLLABORATOR'
  | 'ADD_BILLING_OWNER';

export type Window = typeof window & {
  analytics?: SegmentAnalytics.AnalyticsJS;
};
export type UserOnboardingVariables = {
  target: string;
  details?: string;
};

export type SegmentTrackingType = {
  event: string;
  properties?: Record<string, any>;
  options?: SegmentAnalytics.SegmentOpts;
  callback?: () => void;
};

export type InvoiceType = {
  collection_method: string | null | undefined;
  invoice_url?: Maybe<string> | null | undefined;
  stripe_invoice_id: string | null | undefined;
};

export type VercelIntegrationParams = {
  code: Maybe<string>;
  configurationId: Maybe<string>;
  teamId: Maybe<string>;
  next: Maybe<string>;
};

export type RuntimeErrorKind = 'config-errors';

export type Region = {
  name: string;
  cloud: Cloud_Enum;
  plan?: Plan;
};

export enum FeatureFlags {
  PricingM0 = 'pricing-m0',
  SameTabConsole = 'same-tab-console',
  OldPlansPrometheusDataPassThroughChart = 'old-plans-prometheus-data-pass-through-chart',
  UseCaseExperiment = 'use-case-experiment',
  GetProjectsQueryV2 = 'cloud-projects-query-v2',
  HasuraRewind = 'hasura-rewind',
  ReoIntegration = 'reo-integration',
  ActiveBanner = 'cloud-dashboard-active-banner',
  DdnLaunchModal = 'cloud-dashboard-show-ddn-prompt',
}

export type DynamicImportType = () => Promise<{
  default: React.ComponentType<any>;
}>;

export type LazyComponentType = React.LazyExoticComponent<
  React.ComponentType<any>
>;

export type ProjectTierRegions = Array<{
  label?: string;
  options?: any;
  name?: string;
}>;

export enum UserEntitlements {
  DedicatedCloudEnabled = 'dedicated_cloud_enabled',
  ModelBasedEnterpriseCloudEnabled = 'model_based_enterprise_cloud_enabled',
}
