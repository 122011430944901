import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { authEndpoints } from '../../../utils/constants';
import { validateEmail } from '../../../utils/helpers';
import { APIRequestParams, api } from '../../../utils/request';
import { createQueryParamString, paths } from '../../../utils/routeUtils';
import { NotificationsContext } from '../../Notifications';
import { Input, PrimaryButton } from '../components';
import { useForm } from '../hooks';
import { ArrowLeft } from '../images';
import { getRedir } from '../utils';

const formConfig = {
  email: {
    default: '',
    validator: (value: unknown) => {
      if (value === '') {
        return true;
      }

      if (!value) {
        return false;
      }
      if (typeof value !== 'string') {
        return false;
      }

      return validateEmail(value);
    },
  },
} as const;

const ForgotPassword = (): JSX.Element => {
  const [passwordFormState, onChangeHandler, resetForm] = useForm(formConfig);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { showErrorNotification, showSuccessNotification } = React.useContext(
    NotificationsContext
  );
  const [requestLoadingStatus, updateRequestLoadingStatus] = React.useState(
    false
  );

  const onSubmitForm = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const emailBody = {
      email: passwordFormState.email.value.toLowerCase().trim(),
    };

    const options: APIRequestParams = {
      credentials: 'include',
      body: JSON.stringify(emailBody),
      headers: {
        'content-type': 'application/json',
      },
    };

    const queryParamStringForRecoverUrl = createQueryParamString([
      { name: 'redirect_url' },
      { name: 'login_challenge' },
    ]);

    const url = queryParamStringForRecoverUrl
      ? `${authEndpoints.recover}?${queryParamStringForRecoverUrl}`
      : authEndpoints.recover;

    updateRequestLoadingStatus(true);

    return api
      .post<{ message?: string }>(url, options, () =>
        updateRequestLoadingStatus(false)
      )
      .then(({ data }) => {
        showSuccessNotification(
          `${
            data?.message ?? 'Please check your email for further instructions!'
          }`
        );
        resetForm();
        navigate({
          pathname: paths.signup(),
          search: getRedir(search),
        });
      })
      .catch(err => {
        showErrorNotification(
          `failed to initiate action to recover your password: ${err}`
        );
      });
  };

  const isInputFilledAndValid = React.useMemo(() => {
    return Boolean(
      passwordFormState.email.value && passwordFormState.email.isValid
    );
  }, [passwordFormState.email]);

  return (
    <div className="flex flex-col font-inter">
      <span
        onClick={() => navigate({ pathname: paths.v3.login.email(), search })}
        className="flex items-center justify-center w-[32px] h-[32px] p-[6px] rounded-[100px] bg-[#F3F4F6] hover:bg-slate-200 hover:cursor-pointer"
      >
        <ArrowLeft />
      </span>
      <h3 className="font-semibold text-xl text-black mb-2 mt-6">
        Recover your password
      </h3>
      <span className="font-normal text-[15px] text-[#6C737F] mb-6">
        Please enter your registered email address. An email with a reset link
        will be sent to your this address.
      </span>
      <form onSubmit={onSubmitForm} className="contents">
        <Input
          placeholder="Email"
          type="email"
          value={passwordFormState.email.value}
          onChange={onChangeHandler('email')}
          isErrored={!passwordFormState.email.isValid}
          errorText="Please enter a valid email address"
        />
        <PrimaryButton
          type="submit"
          variant="primary"
          style={{ marginTop: '16px' }}
          disabled={!isInputFilledAndValid}
          loading={requestLoadingStatus}
        />
      </form>
    </div>
  );
};

export default ForgotPassword;
