import React from 'react';
import { useLocation } from 'react-router-dom';

import { Copyright, Tabs } from './components';
import { Logo, SeeYou } from './images';
import { twjoin } from './utils';
import { paths } from '../../utils/routeUtils';
import { SignUp } from './pages';
import { LandingPageProps } from './pages/SignUp';
import { Banner } from '../Banners';

const onClickLogo = () => {
  if (window?.open) {
    window.open('https://hasura.io/', '_blank', 'noopener')?.focus();
  }
};

const V3SignUpHeader = ({
  children,
}: {
  children: React.ReactNode;
}): JSX.Element => {
  return (
    <h5 className="font-inter text-[20px] leading-[32px] font-semibold mb-4 self-center md:self-start">
      {children}
    </h5>
  );
};

const V3SignUp = (props: LandingPageProps): JSX.Element => {
  const { pathname } = useLocation();

  const showSignInContentHeading = React.useMemo(() => {
    return (
      pathname !== paths.v3.register.newUserCloud() &&
      pathname !== paths.v3.login.forgotPassword()
    );
  }, [pathname]);

  const showSignUpContentHeading = React.useMemo(() => {
    return (
      pathname !== paths.v3.login.forgotPassword() &&
      pathname === paths.v3.register.newUserCloud()
    );
  }, [pathname]);

  return (
    <div
      className="bg-v3-background w-screen flex items-center justify-center flex-col md:h-screen"
      id="new-sign-up-page-root"
    >
      <Banner />
      <div
        className={twjoin(
          'flex bg-white md:rounded-[24px] shadow-md md:p-4 xl:p-16 p-4 justify-between flex-col w-screen lg:w-4/5 md:w-[90%] mt-0 md:flex-row md:mt-16',
          pathname === paths.v3.register.newUserCloud()
            ? 'md:h-[85%]'
            : 'md:h-4/5'
        )}
      >
        <div className="flex w-full justify-between flex-col md:w-2/5 mb-5 md:mb-0 items-center md:items-start">
          <div className="hover:cursor-pointer" onClick={onClickLogo}>
            <Logo />
          </div>
          <div className="flex h-4/5 flex-col md:justify-end justify-center">
            <SeeYou className="ml-[85px] md:ml-0" />
            <h1 className="mt-8 mb-3 flex-col flex">
              <span className="font-bold font-inter text-[36px] leading-[48px] block md:truncate mt-3 md:mt-0">
                Try Hasura today
              </span>
              <span className="font-bold font-inter text-[36px] leading-[48px] md:truncate mt-3 md:mt-0 hidden lg:block">
                It's absolutely free 🫰
              </span>
            </h1>
            <span className="font-normal font-inter text-[24px] leading-[36px] text-[#0D41C6] md:truncate mt-3 md:mt-0">
              No Credit Card required
            </span>
          </div>
        </div>
        <div
          className={twjoin(
            'flex md:w-1/2 md:max-w-lg	flex-col border border-v3-menuBorder p-4 rounded-2xl md:justify-start justify-center min-h-0',
            pathname === paths.v3.register.newUserCloud()
              ? 'lg:px-16 lg:py-3'
              : 'lg:p-16'
          )}
        >
          {showSignInContentHeading ? (
            <V3SignUpHeader>Sign in to your account</V3SignUpHeader>
          ) : null}
          {showSignUpContentHeading ? (
            <V3SignUpHeader>Create a new Hasura Account</V3SignUpHeader>
          ) : null}
          {showSignInContentHeading ? <Tabs currentRoute={pathname} /> : null}
          <SignUp currentRoute={pathname} landingProps={props} />
        </div>
      </div>
      <div className="flex justify-between items-center w-screen lg:w-4/5 md:w-[90%] md:h-24 flex-col md:flex-row mt-4 md:mt-0 h-14 mb-4 md:mb-0">
        <div className="w-16" />
        <Copyright />
      </div>
    </div>
  );
};

export default V3SignUp;
