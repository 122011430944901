import { CLOUD_DOCS_URL, HASURA_DOCS_URL } from './constants';

export const PRO_DOCS = `https://docs.pro.hasura.io`;
export const EE_DOCS = `https://docs.ee.hasura.io`;
export const docs = {
  cloud: () => {
    return {
      root: () => CLOUD_DOCS_URL,
      postgresPermissions: () =>
        `${CLOUD_DOCS_URL}/projects/create.html#postgres-permissions`,
      dbConnectionIp: () =>
        `${CLOUD_DOCS_URL}reference/index.html#database-connection-ip`,
      v2RegionChangeLimitation: () =>
        `${CLOUD_DOCS_URL}/projects/regions.html#project-regions-v2-limitation`,
      githubIntegration: () =>
        `${CLOUD_DOCS_URL}/projects/github-integration.html`,
      dedicatedVPC: () => `${CLOUD_DOCS_URL}/dedicated-vpc.html`,
      vpcPeering: () => `${CLOUD_DOCS_URL}/dedicated-vpc/#vpc-peering`,
      vpcPeeringAcceptRequest: () =>
        `https://hasura.io/docs/latest/hasura-cloud/dedicated-vpc/aws-network-peering/#step-2-accept-the-peering-request`,
      awsPeeringCustomerToHasura: () =>
        `https://hasura.io/docs/latest/hasura-cloud/dedicated-vpc/aws-network-peering/#customer-to-hasura`,
      multipleAdminSecrets: () =>
        `${CLOUD_DOCS_URL}/security/multiple-admin-secrets.html`,
      openTelemetry: () =>
        `${CLOUD_DOCS_URL}/metrics/integrations/opentelemetry`,
      openTelemetryNew: () =>
        `https://hasura.io/docs/latest/observability/opentelemetry`,
    };
  },
  hasura: () => {
    return {
      root: () => HASURA_DOCS_URL,
      jwtMode: () =>
        `${HASURA_DOCS_URL}/auth/authentication/jwt.html#configuring-jwt-mode`,
      serverEnvVars: (hash = 'command-flags') =>
        `${HASURA_DOCS_URL}/deployment/graphql-engine-flags/reference.html#${hash}`,
      configureCors: () =>
        `${HASURA_DOCS_URL}/deployment/graphql-engine-flags/config-examples.html#configure-cors`,
      jwkUrl: () => `${HASURA_DOCS_URL}/auth/authentication/jwt.html#jwk-url`,
      jwtKey: () => `${HASURA_DOCS_URL}/auth/authentication/jwt.html#key`,
      jwtType: () => `${HASURA_DOCS_URL}/auth/authentication/jwt.html#type`,
      mySQL: () => `${HASURA_DOCS_URL}/guides/mysql-preview.html`,
      quickStartDocker: () =>
        `${HASURA_DOCS_URL}/getting-started/docker-simple.html`,
    };
  },
  pro: () => {
    return {
      root: () => PRO_DOCS,
      getStarted: () =>
        `${PRO_DOCS}/getting-started/#step-1-setup-your-local-hasura-project`,
      addProject: () => `${PRO_DOCS}/getting-started/#step-2-create-a-project`,
      upgradePro: () =>
        `${PRO_DOCS}/getting-started/#step-3-run-hasura-with-pro-key`,
    };
  },
  ee: () => {
    return {
      root: () => EE_DOCS,
      addProject: () =>
        `${EE_DOCS}/graphql-installation/installation/#step-1-create-a-project-and-generate-the-pro-key`,
      upgradePro: () =>
        `${EE_DOCS}/graphql-installation/installation/#step-2-run-hasura-ee-data-plane-graphql-engine-with-the-pro-key`,
    };
  },
};

export const LEARN = `https://hasura.io/learn`;

export const HASURA_DATAHUB = `https://hasura.io/hub/`;

export const CHANGELOG = `https://hasura.io/changelog/cloud`;

export const CLOUD_TERMS_OF_SERVICE =
  'https://hasura.io/legal/hasura-cloud-terms-of-service';
export const HASURA_PRIVACY_POLICY =
  'https://hasura.io/legal/hasura-privacy-policy';

export const POSTGRES_CONNECTION_URI =
  'https://www.postgresql.org/docs/current/libpq-connect.html#id-1.7.3.8.3.6';

export const HEROKU_POSTGRES = `https://devcenter.heroku.com/articles/heroku-postgresql#provisioning-heroku-postgres`;

export const HASURA_GQL_ENGINE_GITHUB_DISCUSSIONS =
  'https://github.com/hasura/graphql-engine/discussions';

export const HASURA_ZENDESK_NEW_QUERY =
  'https://hasurahelp.zendesk.com/hc/en-us/requests/new';

export const GITHUB_INTEGRATION_STARTER_KIT = `https://github.com/hasura/github-integration-starter`;
